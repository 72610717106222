<div class="flex cursor-pointer items-center gap-2" data-testid="answer-review-see-templates-btn" (click)="toggleDisplayTemplates()">
    <span class="malou-color-primary malou-text-12--medium">
        {{ 'reviews.reply_modal.templates.show_review_templates' | translate: { templateCount: templatesAutocompleteOptions().length } }}
    </span>
    @if (shouldDisplayTemplates()) {
        <mat-icon class="!w-3" color="primary" [svgIcon]="SvgIcon.CHEVRON_UP"></mat-icon>
    } @else {
        <mat-icon class="!w-3" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
    }
</div>

@if (shouldDisplayTemplates()) {
    <div class="flex gap-x-4">
        <div>
            <span class="malou-text-10--regular malou-color-text-2 flex py-1">{{ 'common.filters' | translate }}</span>
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matTooltip]="'common.filters' | translate">
                @if (filterCount() > 0) {
                    <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                        {{ filterCount() }}
                    </span>
                }
                <mat-icon color="primary" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
            </button>
        </div>
        <div class="w-full">
            <app-select-templates
                [testId]="'answer-review-templates-autocomplete-input'"
                [formControl]="templateForm"
                [title]="'reviews.reply_modal.templates.template_name' | translate"
                [placeholder]="'common.search' | translate"
                [values]="templatesAutocompleteOptions()"
                [templateReplacer]="templateReplacer"
                [errorMessage]="
                    templatesAutocompleteOptions().length === 0 ? ('reviews.reply_modal.templates.no_templates' | translate) : ''
                "
                (selectTemplatesChange)="onTemplateChange($event)"></app-select-templates>
        </div>
    </div>
}

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #menu="matMenu">
    <div class="w-[440px] md:w-full" (click)="$event.stopPropagation()">
        <div class="mb-3 flex w-full justify-between p-6">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>
        </div>

        <div class="flex flex-col gap-y-5 px-6 pb-6">
            <ng-container [ngTemplateOutlet]="ratingsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="commentsFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #ratingsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.ratings' | translate }}
        </div>
        <app-ratings-filters [availableRatings]="AVAILABLE_RATINGS" [ratings]="ratings()" (onToggleRating)="toggleRating($event)">
        </app-ratings-filters>
    </div>
</ng-template>

<ng-template #commentsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.comments' | translate }}
        </div>
        <div class="flex gap-x-4">
            @for (commentFilter of AVAILABLE_COMMENTS_FILTERS; track commentFilter) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                    <mat-button-toggle
                        class="!h-12.5 rounded-[10px]"
                        value="{{ commentFilter }}"
                        [checked]="isCommentFilterChecked(commentFilter)"
                        (change)="toggleCommentFilter(commentFilter)">
                        <div class="malou-text-12--medium">
                            {{ 'reviews.filters.' + commentFilter | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>
