import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { RightArrowWithContentComponent } from ':shared/components/right-arrow-with-text/right-arrow-with-content.component';

@Component({
    selector: 'app-facebook-instagram-connection-step-2',
    templateUrl: './facebook-instagram-connection-step-2.component.html',
    styleUrls: ['./facebook-instagram-connection-step-2.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, RightArrowWithContentComponent, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookInstagramConnectionStep2Component extends BaseStepComponent<
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult
> {
    readonly ButtonStyle = ButtonStyle;
}
