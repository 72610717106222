import { PartialRecord } from '../utility-types';
import { CountryCode } from './countries';

/**
 * Country code top-level domains
 * From https://www.worldstandards.eu/other/tlds/
 * The only difference with CountryCode is for United kingdoms
 */
export enum CcTld {
    AFGHANISTAN = 'af',
    ALBANIA = 'al',
    ALGERIA = 'dz',
    AMERICAN_SAMOA = 'as',
    ANDORRA = 'ad',
    ANGOLA = 'ao',
    ANGUILLA = 'ai',
    ANTARCTICA = 'aq',
    ANTIGUA_AND_BARBUDA = 'ag',
    ARGENTINA = 'ar',
    ARMENIA = 'am',
    ARUBA = 'aw',
    AUSTRALIA = 'au',
    AUSTRIA = 'at',
    AZERBAIJAN = 'az',
    BAHAMAS = 'bs',
    BAHRAIN = 'bh',
    BANGLADESH = 'bd',
    BARBADOS = 'bb',
    BELARUS = 'by',
    BELGIUM = 'be',
    BELIZE = 'bz',
    BENIN = 'bj',
    BERMUDA = 'bm',
    BHUTAN = 'bt',
    BOLIVIA = 'bo',
    BOSNIA_AND_HERZEGOVINA = 'ba',
    BOTSWANA = 'bw',
    BRAZIL = 'br',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'io',
    BRUNEI_DARUSSALAM = 'bn',
    BULGARIA = 'bg',
    BURKINA_FASO = 'bf',
    BURUNDI = 'bi',
    CAMBODIA = 'kh',
    CAMEROON = 'cm',
    CANADA = 'ca',
    CAPE_VERDE = 'cv',
    CAYMAN_ISLANDS = 'ky',
    CENTRAL_AFRICAN_REPUBLIC = 'cf',
    CHAD = 'td',
    CHILE = 'cl',
    CHINA = 'cn',
    CHRISTMAS_ISLAND = 'cx',
    COCOS_ISLANDS = 'cc',
    COLOMBIA = 'co',
    COMOROS = 'km',
    CONGO = 'cg',
    COOK_ISLANDS = 'ck',
    COSTA_RICA = 'cr',
    COTE_D_IVOIRE = 'ci',
    CROATIA = 'hr',
    CUBA = 'cu',
    CYPRUS = 'cy',
    CZECH_REPUBLIC = 'cz',
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'cd',
    DENMARK = 'dk',
    DJIBOUTI = 'dj',
    DOMINICA = 'dm',
    DOMINICAN_REPUBLIC = 'do',
    ECUADOR = 'ec',
    EGYPT = 'eg',
    EL_SALVADOR = 'sv',
    EQUATORIAL_GUINEA = 'gq',
    ERITREA = 'er',
    ESTONIA = 'ee',
    ETHIOPIA = 'et',
    FALKLAND_ISLANDS = 'fk',
    FAROE_ISLANDS = 'fo',
    FIJI = 'fj',
    FINLAND = 'fi',
    FRANCE = 'fr',
    FRENCH_GUIANA = 'gf',
    FRENCH_POLYNESIA = 'pf',
    GABON = 'ga',
    GAMBIA = 'gm',
    GEORGIA = 'ge',
    GERMANY = 'de',
    GHANA = 'gh',
    GIBRALTAR = 'gi',
    GREECE = 'gr',
    GREENLAND = 'gl',
    GRENADA = 'gd',
    GUADELOUPE = 'gp',
    GUAM = 'gu',
    GUATEMALA = 'gt',
    GUERNSEY = 'gg',
    GUINEA = 'gn',
    GUINEA_BISSAU = 'gw',
    GUYANA = 'gy',
    HAITI = 'ht',
    HONDURAS = 'hn',
    HONG_KONG = 'hk',
    HUNGARY = 'hu',
    ICELAND = 'is',
    INDIA = 'in',
    INDONESIA = 'id',
    IRAN = 'ir',
    IRAQ = 'iq',
    IRELAND = 'ie',
    ISLE_OF_MAN = 'im',
    ISRAEL = 'il',
    ITALY = 'it',
    JAMAICA = 'jm',
    JAPAN = 'jp',
    JERSEY = 'je',
    JORDAN = 'jo',
    KAZAKHSTAN = 'kz',
    KENYA = 'ke',
    KIRIBATI = 'ki',
    KUWAIT = 'kw',
    KYRGYZSTAN = 'kg',
    LAND_ISLANDS = 'ax',
    LAOS = 'la',
    LATVIA = 'lv',
    LEBANON = 'lb',
    LESOTHO = 'ls',
    LIBERIA = 'lr',
    LIBYA = 'ly',
    LIECHTENSTEIN = 'li',
    LITHUANIA = 'lt',
    LUXEMBOURG = 'lu',
    MACAO = 'mo',
    MACEDONIA = 'mk',
    MADAGASCAR = 'mg',
    MALAWI = 'mw',
    MALAYSIA = 'my',
    MALDIVES = 'mv',
    MALI = 'ml',
    MALTA = 'mt',
    MARSHALL_ISLANDS = 'mh',
    MARTINIQUE = 'mq',
    MAURITANIA = 'mr',
    MAURITIUS = 'mu',
    MAYOTTE = 'yt',
    MEXICO = 'mx',
    MICRONESIA = 'fm',
    MOLDOVA = 'md',
    MONACO = 'mc',
    MONGOLIA = 'mn',
    MONTENEGRO = 'me',
    MONTSERRAT = 'ms',
    MOROCCO = 'ma',
    MOZAMBIQUE = 'mz',
    MYANMAR = 'mm',
    NAMIBIA = 'na',
    NAURU = 'nr',
    NEPAL = 'np',
    NETHERLANDS = 'nl',
    NETHERLANDS_ANTILLES = 'an',
    NEW_CALEDONIA = 'nc',
    NEW_ZEALAND = 'nz',
    NICARAGUA = 'ni',
    NIGER = 'ne',
    NIGERIA = 'ng',
    NIUE = 'nu',
    NORFOLK_ISLAND = 'nf',
    NORTH_KOREA = 'kp',
    NORTHERN_MARIANA_ISLANDS = 'mp',
    NORWAY = 'no',
    OMAN = 'om',
    PAKISTAN = 'pk',
    PALAU = 'pw',
    PALESTINIAN = 'ps',
    PANAMA = 'pa',
    PAPUA_NEW_GUINEA = 'pg',
    PARAGUAY = 'py',
    PERU = 'pe',
    PHILIPPINES = 'ph',
    PITCAIRN = 'pn',
    POLAND = 'pl',
    PORTUGAL = 'pt',
    PUERTO_RICO = 'pr',
    QATAR = 'qa',
    RÉUNION = 're',
    ROMANIA = 'ro',
    RUSSIA = 'ru',
    RWANDA = 'rw',
    SAINT_BARTHÉLEMY = 'bl', // or gp (Guadeloupe) of fr (France)
    SAINT_HELENA = 'sh',
    SAINT_KITTS_AND_NEVIS = 'kn',
    SAINT_LUCIA = 'lc',
    SAINT_MARTIN = 'mf', // or gp (Guadeloupe) of fr (France)
    SAINT_PIERRE_AND_MIQUELON = 'pm',
    SAINT_VINCENT_AND_THE_GRENADINES = 'vc',
    SAMOA = 'ws',
    SAN_MARINO = 'sm',
    SAO_TOME_AND_PRINCIPE = 'st',
    SAUDI_ARABIA = 'sa',
    SENEGAL = 'sn',
    SERBIA = 'rs',
    SEYCHELLES = 'sc',
    SIERRA_LEONE = 'sl',
    SINGAPORE = 'sg',
    SLOVAKIA = 'sk',
    SLOVENIA = 'si',
    SOLOMON_ISLANDS = 'sb',
    SOMALIA = 'so',
    SOUTH_AFRICA = 'za',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'gs',
    SOUTH_KOREA = 'kr',
    SPAIN = 'es',
    SRI_LANKA = 'lk',
    SUDAN = 'sd',
    SURINAME = 'sr',
    SVALBARD_AND_JAN_MAYEN = 'sj',
    SWAZILAND = 'sz',
    SWEDEN = 'se',
    SWITZERLAND = 'ch',
    SYRIA = 'sy',
    TAIWAN = 'tw',
    TAJIKISTAN = 'tj',
    TANZANIA = 'tz',
    THAILAND = 'th',
    TIMOR_LESTE = 'tl', // old tp still in use
    TOGO = 'tg',
    TOKELAU = 'tk',
    TONGA = 'to',
    TRINIDAD_AND_TOBAGO = 'tt',
    TUNISIA = 'tn',
    TURKEY = 'tr',
    TURKMENISTAN = 'tm',
    TURKS_AND_CAICOS_ISLANDS = 'tc',
    TUVALU = 'tv',
    UGANDA = 'ug',
    UKRAINE = 'ua',
    UNITED_ARAB_EMIRATES = 'ae',
    UNITED_KINGDOM = 'uk', // Different from country code
    UNITED_STATES = 'us',
    URUGUAY = 'uy',
    UZBEKISTAN = 'uz',
    VANUATU = 'vu',
    VATICAN = 'va',
    VENEZUELA = 've',
    VIETNAM = 'vn',
    VIRGIN_ISLANDS_BRITISH = 'vg',
    VIRGIN_ISLANDS_US = 'vi',
    WALLIS_AND_FUTUNA = 'wf',
    YEMEN = 'ye',
    ZAMBIA = 'zm',
    ZIMBABWE = 'zw',
}

export const ccTldByCountryCode: PartialRecord<CountryCode, CcTld> = {
    [CountryCode.AFGHANISTAN]: CcTld.AFGHANISTAN,
    [CountryCode.ALBANIA]: CcTld.ALBANIA,
    [CountryCode.ALGERIA]: CcTld.ALGERIA,
    [CountryCode.AMERICAN_SAMOA]: CcTld.AMERICAN_SAMOA,
    [CountryCode.ANDORRA]: CcTld.ANDORRA,
    [CountryCode.ANGOLA]: CcTld.ANGOLA,
    [CountryCode.ANGUILLA]: CcTld.ANGUILLA,
    [CountryCode.ANTARCTICA]: CcTld.ANTARCTICA,
    [CountryCode.ANTIGUA_AND_BARBUDA]: CcTld.ANTIGUA_AND_BARBUDA,
    [CountryCode.ARGENTINA]: CcTld.ARGENTINA,
    [CountryCode.ARMENIA]: CcTld.ARMENIA,
    [CountryCode.ARUBA]: CcTld.ARUBA,
    [CountryCode.AUSTRALIA]: CcTld.AUSTRALIA,
    [CountryCode.AUSTRIA]: CcTld.AUSTRIA,
    [CountryCode.AZERBAIJAN]: CcTld.AZERBAIJAN,
    [CountryCode.BAHAMAS]: CcTld.BAHAMAS,
    [CountryCode.BAHRAIN]: CcTld.BAHRAIN,
    [CountryCode.BANGLADESH]: CcTld.BANGLADESH,
    [CountryCode.BARBADOS]: CcTld.BARBADOS,
    [CountryCode.BELARUS]: CcTld.BELARUS,
    [CountryCode.BELGIUM]: CcTld.BELGIUM,
    [CountryCode.BELIZE]: CcTld.BELIZE,
    [CountryCode.BENIN]: CcTld.BENIN,
    [CountryCode.BERMUDA]: CcTld.BERMUDA,
    [CountryCode.BHUTAN]: CcTld.BHUTAN,
    [CountryCode.BOLIVIA]: CcTld.BOLIVIA,
    [CountryCode.BOSNIA_AND_HERZEGOVINA]: CcTld.BOSNIA_AND_HERZEGOVINA,
    [CountryCode.BOTSWANA]: CcTld.BOTSWANA,
    [CountryCode.BRAZIL]: CcTld.BRAZIL,
    [CountryCode.BRITISH_INDIAN_OCEAN_TERRITORY]: CcTld.BRITISH_INDIAN_OCEAN_TERRITORY,
    [CountryCode.BRUNEI_DARUSSALAM]: CcTld.BRUNEI_DARUSSALAM,
    [CountryCode.BULGARIA]: CcTld.BULGARIA,
    [CountryCode.BURKINA_FASO]: CcTld.BURKINA_FASO,
    [CountryCode.BURUNDI]: CcTld.BURUNDI,
    [CountryCode.CAMBODIA]: CcTld.CAMBODIA,
    [CountryCode.CAMEROON]: CcTld.CAMEROON,
    [CountryCode.CANADA]: CcTld.CANADA,
    [CountryCode.CAPE_VERDE]: CcTld.CAPE_VERDE,
    [CountryCode.CAYMAN_ISLANDS]: CcTld.CAYMAN_ISLANDS,
    [CountryCode.CENTRAL_AFRICAN_REPUBLIC]: CcTld.CENTRAL_AFRICAN_REPUBLIC,
    [CountryCode.CHAD]: CcTld.CHAD,
    [CountryCode.CHILE]: CcTld.CHILE,
    [CountryCode.CHINA]: CcTld.CHINA,
    [CountryCode.CHRISTMAS_ISLAND]: CcTld.CHRISTMAS_ISLAND,
    [CountryCode.COCOS_ISLANDS]: CcTld.COCOS_ISLANDS,
    [CountryCode.COLOMBIA]: CcTld.COLOMBIA,
    [CountryCode.COMOROS]: CcTld.COMOROS,
    [CountryCode.CONGO]: CcTld.CONGO,
    [CountryCode.COOK_ISLANDS]: CcTld.COOK_ISLANDS,
    [CountryCode.COSTA_RICA]: CcTld.COSTA_RICA,
    [CountryCode.COTE_D_IVOIRE]: CcTld.COTE_D_IVOIRE,
    [CountryCode.CROATIA]: CcTld.CROATIA,
    [CountryCode.CUBA]: CcTld.CUBA,
    [CountryCode.CYPRUS]: CcTld.CYPRUS,
    [CountryCode.CZECH_REPUBLIC]: CcTld.CZECH_REPUBLIC,
    [CountryCode.DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: CcTld.DEMOCRATIC_REPUBLIC_OF_THE_CONGO,
    [CountryCode.DENMARK]: CcTld.DENMARK,
    [CountryCode.DJIBOUTI]: CcTld.DJIBOUTI,
    [CountryCode.DOMINICA]: CcTld.DOMINICA,
    [CountryCode.DOMINICAN_REPUBLIC]: CcTld.DOMINICAN_REPUBLIC,
    [CountryCode.ECUADOR]: CcTld.ECUADOR,
    [CountryCode.EGYPT]: CcTld.EGYPT,
    [CountryCode.EL_SALVADOR]: CcTld.EL_SALVADOR,
    [CountryCode.EQUATORIAL_GUINEA]: CcTld.EQUATORIAL_GUINEA,
    [CountryCode.ERITREA]: CcTld.ERITREA,
    [CountryCode.ESTONIA]: CcTld.ESTONIA,
    [CountryCode.ETHIOPIA]: CcTld.ETHIOPIA,
    [CountryCode.FALKLAND_ISLANDS]: CcTld.FALKLAND_ISLANDS,
    [CountryCode.FAROE_ISLANDS]: CcTld.FAROE_ISLANDS,
    [CountryCode.FIJI]: CcTld.FIJI,
    [CountryCode.FINLAND]: CcTld.FINLAND,
    [CountryCode.FRANCE]: CcTld.FRANCE,
    [CountryCode.FRENCH_GUIANA]: CcTld.FRENCH_GUIANA,
    [CountryCode.FRENCH_POLYNESIA]: CcTld.FRENCH_POLYNESIA,
    [CountryCode.GABON]: CcTld.GABON,
    [CountryCode.GAMBIA]: CcTld.GAMBIA,
    [CountryCode.GEORGIA]: CcTld.GEORGIA,
    [CountryCode.GERMANY]: CcTld.GERMANY,
    [CountryCode.GHANA]: CcTld.GHANA,
    [CountryCode.GIBRALTAR]: CcTld.GIBRALTAR,
    [CountryCode.GREECE]: CcTld.GREECE,
    [CountryCode.GREENLAND]: CcTld.GREENLAND,
    [CountryCode.GRENADA]: CcTld.GRENADA,
    [CountryCode.GUADELOUPE]: CcTld.GUADELOUPE,
    [CountryCode.GUAM]: CcTld.GUAM,
    [CountryCode.GUATEMALA]: CcTld.GUATEMALA,
    [CountryCode.GUERNSEY]: CcTld.GUERNSEY,
    [CountryCode.GUINEA]: CcTld.GUINEA,
    [CountryCode.GUINEA_BISSAU]: CcTld.GUINEA_BISSAU,
    [CountryCode.GUYANA]: CcTld.GUYANA,
    [CountryCode.HAITI]: CcTld.HAITI,
    [CountryCode.HONDURAS]: CcTld.HONDURAS,
    [CountryCode.HONG_KONG]: CcTld.HONG_KONG,
    [CountryCode.HUNGARY]: CcTld.HUNGARY,
    [CountryCode.ICELAND]: CcTld.ICELAND,
    [CountryCode.INDIA]: CcTld.INDIA,
    [CountryCode.INDONESIA]: CcTld.INDONESIA,
    [CountryCode.IRAN]: CcTld.IRAN,
    [CountryCode.IRAQ]: CcTld.IRAQ,
    [CountryCode.IRELAND]: CcTld.IRELAND,
    [CountryCode.ISLE_OF_MAN]: CcTld.ISLE_OF_MAN,
    [CountryCode.ISRAEL]: CcTld.ISRAEL,
    [CountryCode.ITALY]: CcTld.ITALY,
    [CountryCode.JAMAICA]: CcTld.JAMAICA,
    [CountryCode.JAPAN]: CcTld.JAPAN,
    [CountryCode.JERSEY]: CcTld.JERSEY,
    [CountryCode.JORDAN]: CcTld.JORDAN,
    [CountryCode.KAZAKHSTAN]: CcTld.KAZAKHSTAN,
    [CountryCode.KENYA]: CcTld.KENYA,
    [CountryCode.KIRIBATI]: CcTld.KIRIBATI,
    [CountryCode.KUWAIT]: CcTld.KUWAIT,
    [CountryCode.KYRGYZSTAN]: CcTld.KYRGYZSTAN,
    [CountryCode.LAND_ISLANDS]: CcTld.LAND_ISLANDS,
    [CountryCode.LAOS]: CcTld.LAOS,
    [CountryCode.LATVIA]: CcTld.LATVIA,
    [CountryCode.LEBANON]: CcTld.LEBANON,
    [CountryCode.LESOTHO]: CcTld.LESOTHO,
    [CountryCode.LIBERIA]: CcTld.LIBERIA,
    [CountryCode.LIBYA]: CcTld.LIBYA,
    [CountryCode.LIECHTENSTEIN]: CcTld.LIECHTENSTEIN,
    [CountryCode.LITHUANIA]: CcTld.LITHUANIA,
    [CountryCode.LUXEMBOURG]: CcTld.LUXEMBOURG,
    [CountryCode.MACAO]: CcTld.MACAO,
    [CountryCode.MACEDONIA]: CcTld.MACEDONIA,
    [CountryCode.MADAGASCAR]: CcTld.MADAGASCAR,
    [CountryCode.MALAWI]: CcTld.MALAWI,
    [CountryCode.MALAYSIA]: CcTld.MALAYSIA,
    [CountryCode.MALDIVES]: CcTld.MALDIVES,
    [CountryCode.MALI]: CcTld.MALI,
    [CountryCode.MALTA]: CcTld.MALTA,
    [CountryCode.MARSHALL_ISLANDS]: CcTld.MARSHALL_ISLANDS,
    [CountryCode.MARTINIQUE]: CcTld.MARTINIQUE,
    [CountryCode.MAURITANIA]: CcTld.MAURITANIA,
    [CountryCode.MAURITIUS]: CcTld.MAURITIUS,
    [CountryCode.MAYOTTE]: CcTld.MAYOTTE,
    [CountryCode.MEXICO]: CcTld.MEXICO,
    [CountryCode.MICRONESIA]: CcTld.MICRONESIA,
    [CountryCode.MOLDOVA]: CcTld.MOLDOVA,
    [CountryCode.MONACO]: CcTld.MONACO,
    [CountryCode.MONGOLIA]: CcTld.MONGOLIA,
    [CountryCode.MONTENEGRO]: CcTld.MONTENEGRO,
    [CountryCode.MONTSERRAT]: CcTld.MONTSERRAT,
    [CountryCode.MOROCCO]: CcTld.MOROCCO,
    [CountryCode.MOZAMBIQUE]: CcTld.MOZAMBIQUE,
    [CountryCode.MYANMAR]: CcTld.MYANMAR,
    [CountryCode.NAMIBIA]: CcTld.NAMIBIA,
    [CountryCode.NAURU]: CcTld.NAURU,
    [CountryCode.NEPAL]: CcTld.NEPAL,
    [CountryCode.NETHERLANDS]: CcTld.NETHERLANDS,
    [CountryCode.NETHERLANDS_ANTILLES]: CcTld.NETHERLANDS_ANTILLES,
    [CountryCode.NEW_CALEDONIA]: CcTld.NEW_CALEDONIA,
    [CountryCode.NEW_ZEALAND]: CcTld.NEW_ZEALAND,
    [CountryCode.NICARAGUA]: CcTld.NICARAGUA,
    [CountryCode.NIGER]: CcTld.NIGER,
    [CountryCode.NIGERIA]: CcTld.NIGERIA,
    [CountryCode.NIUE]: CcTld.NIUE,
    [CountryCode.NORFOLK_ISLAND]: CcTld.NORFOLK_ISLAND,
    [CountryCode.NORTH_KOREA]: CcTld.NORTH_KOREA,
    [CountryCode.NORTHERN_MARIANA_ISLANDS]: CcTld.NORTHERN_MARIANA_ISLANDS,
    [CountryCode.NORWAY]: CcTld.NORWAY,
    [CountryCode.OMAN]: CcTld.OMAN,
    [CountryCode.PAKISTAN]: CcTld.PAKISTAN,
    [CountryCode.PALAU]: CcTld.PALAU,
    [CountryCode.PALESTINIAN]: CcTld.PALESTINIAN,
    [CountryCode.PANAMA]: CcTld.PANAMA,
    [CountryCode.PAPUA_NEW_GUINEA]: CcTld.PAPUA_NEW_GUINEA,
    [CountryCode.PARAGUAY]: CcTld.PARAGUAY,
    [CountryCode.PERU]: CcTld.PERU,
    [CountryCode.PHILIPPINES]: CcTld.PHILIPPINES,
    [CountryCode.PITCAIRN]: CcTld.PITCAIRN,
    [CountryCode.POLAND]: CcTld.POLAND,
    [CountryCode.PORTUGAL]: CcTld.PORTUGAL,
    [CountryCode.PUERTO_RICO]: CcTld.PUERTO_RICO,
    [CountryCode.QATAR]: CcTld.QATAR,
    [CountryCode.RÉUNION]: CcTld.RÉUNION,
    [CountryCode.ROMANIA]: CcTld.ROMANIA,
    [CountryCode.RUSSIA]: CcTld.RUSSIA,
    [CountryCode.RWANDA]: CcTld.RWANDA,
    [CountryCode.SAINT_BARTHÉLEMY]: CcTld.SAINT_BARTHÉLEMY,
    [CountryCode.SAINT_HELENA]: CcTld.SAINT_HELENA,
    [CountryCode.SAINT_KITTS_AND_NEVIS]: CcTld.SAINT_KITTS_AND_NEVIS,
    [CountryCode.SAINT_LUCIA]: CcTld.SAINT_LUCIA,
    [CountryCode.SAINT_MARTIN]: CcTld.SAINT_MARTIN,
    [CountryCode.SAINT_PIERRE_AND_MIQUELON]: CcTld.SAINT_PIERRE_AND_MIQUELON,
    [CountryCode.SAINT_VINCENT_AND_THE_GRENADINES]: CcTld.SAINT_VINCENT_AND_THE_GRENADINES,
    [CountryCode.SAMOA]: CcTld.SAMOA,
    [CountryCode.SAN_MARINO]: CcTld.SAN_MARINO,
    [CountryCode.SAO_TOME_AND_PRINCIPE]: CcTld.SAO_TOME_AND_PRINCIPE,
    [CountryCode.SAUDI_ARABIA]: CcTld.SAUDI_ARABIA,
    [CountryCode.SENEGAL]: CcTld.SENEGAL,
    [CountryCode.SERBIA]: CcTld.SERBIA,
    [CountryCode.SEYCHELLES]: CcTld.SEYCHELLES,
    [CountryCode.SIERRA_LEONE]: CcTld.SIERRA_LEONE,
    [CountryCode.SINGAPORE]: CcTld.SINGAPORE,
    [CountryCode.SLOVAKIA]: CcTld.SLOVAKIA,
    [CountryCode.SLOVENIA]: CcTld.SLOVENIA,
    [CountryCode.SOLOMON_ISLANDS]: CcTld.SOLOMON_ISLANDS,
    [CountryCode.SOMALIA]: CcTld.SOMALIA,
    [CountryCode.SOUTH_AFRICA]: CcTld.SOUTH_AFRICA,
    [CountryCode.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: CcTld.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
    [CountryCode.SOUTH_KOREA]: CcTld.SOUTH_KOREA,
    [CountryCode.SPAIN]: CcTld.SPAIN,
    [CountryCode.SRI_LANKA]: CcTld.SRI_LANKA,
    [CountryCode.SUDAN]: CcTld.SUDAN,
    [CountryCode.SURINAME]: CcTld.SURINAME,
    [CountryCode.SVALBARD_AND_JAN_MAYEN]: CcTld.SVALBARD_AND_JAN_MAYEN,
    [CountryCode.SWAZILAND]: CcTld.SWAZILAND,
    [CountryCode.SWEDEN]: CcTld.SWEDEN,
    [CountryCode.SWITZERLAND]: CcTld.SWITZERLAND,
    [CountryCode.SYRIA]: CcTld.SYRIA,
    [CountryCode.TAIWAN]: CcTld.TAIWAN,
    [CountryCode.TAJIKISTAN]: CcTld.TAJIKISTAN,
    [CountryCode.TANZANIA]: CcTld.TANZANIA,
    [CountryCode.THAILAND]: CcTld.THAILAND,
    [CountryCode.TIMOR_LESTE]: CcTld.TIMOR_LESTE,
    [CountryCode.TOGO]: CcTld.TOGO,
    [CountryCode.TOKELAU]: CcTld.TOKELAU,
    [CountryCode.TONGA]: CcTld.TONGA,
    [CountryCode.TRINIDAD_AND_TOBAGO]: CcTld.TRINIDAD_AND_TOBAGO,
    [CountryCode.TUNISIA]: CcTld.TUNISIA,
    [CountryCode.TURKEY]: CcTld.TURKEY,
    [CountryCode.TURKMENISTAN]: CcTld.TURKMENISTAN,
    [CountryCode.TURKS_AND_CAICOS_ISLANDS]: CcTld.TURKS_AND_CAICOS_ISLANDS,
    [CountryCode.TUVALU]: CcTld.TUVALU,
    [CountryCode.UGANDA]: CcTld.UGANDA,
    [CountryCode.UKRAINE]: CcTld.UKRAINE,
    [CountryCode.UNITED_ARAB_EMIRATES]: CcTld.UNITED_ARAB_EMIRATES,
    [CountryCode.UNITED_KINGDOM]: CcTld.UNITED_KINGDOM,
    [CountryCode.UNITED_STATES]: CcTld.UNITED_STATES,
    [CountryCode.URUGUAY]: CcTld.URUGUAY,
    [CountryCode.UZBEKISTAN]: CcTld.UZBEKISTAN,
    [CountryCode.VANUATU]: CcTld.VANUATU,
    [CountryCode.VATICAN]: CcTld.VATICAN,
    [CountryCode.VENEZUELA]: CcTld.VENEZUELA,
    [CountryCode.VIETNAM]: CcTld.VIETNAM,
    [CountryCode.VIRGIN_ISLANDS_BRITISH]: CcTld.VIRGIN_ISLANDS_BRITISH,
    [CountryCode.VIRGIN_ISLANDS_US]: CcTld.VIRGIN_ISLANDS_US,
    [CountryCode.WALLIS_AND_FUTUNA]: CcTld.WALLIS_AND_FUTUNA,
    [CountryCode.YEMEN]: CcTld.YEMEN,
    [CountryCode.ZAMBIA]: CcTld.ZAMBIA,
    [CountryCode.ZIMBABWE]: CcTld.ZIMBABWE,
};
