export enum StylingProperties {
    FontFamily = 'fontFamily',
    FontWeight = 'fontWeight',
    FontSize = 'fontSize',
    Color = 'color',
    TextShadow = 'textShadow',
    LetterSpacing = 'letterSpacing',
    FontStyle = 'fontStyle',
    Transform = 'transform',
    PaddingTop = 'paddingTop',
    PaddingLeft = 'paddingLeft',
    PaddingRight = 'paddingRight',
    BoxShadow = 'boxShadow',
    Background = 'background',
    BackgroundColor = 'backgroundColor',
    BorderRadius = 'borderRadius',
    LineHeight = 'lineHeight',
    TextTransform = 'textTransform',
}
