import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const updateKeywordLanguageParamsValidator = z
    .object({
        keyword_id: objectIdValidator,
    })
    .transform((data) => ({
        keywordId: data.keyword_id,
    }));
export type UpdateKeywordLanguageParamsDto = z.infer<typeof updateKeywordLanguageParamsValidator>;

export const updateKeywordLanguageBodyValidator = z.object({
    language: z.string(),
    restaurantId: objectIdValidator,
});
export type UpdateKeywordLanguageBodyDto = z.infer<typeof updateKeywordLanguageBodyValidator>;
