import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, contentChild, forwardRef, input, output, TemplateRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select',
    templateUrl: 'select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectComponent),
        },
    ],
    standalone: true,
    imports: [SelectBaseComponent, FormsModule, ReactiveFormsModule, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> extends ControlValueAccessorConnectorComponent {
    /**
     * Use this template to override the input and show custom html when a value is selected.
     * Only for multiSelection = false
     * Takes 1 parameters:
     *      - value: the value (an element of 'values' array)
     */
    readonly simpleSelectedValueTemplateInput = contentChild<TemplateRef<any>>('simpleSelectedValueTemplate');

    // ------------ Custom functions ------------ //

    /** If exists, will create new values (not in values input) */
    readonly itemBuilder = input<any | undefined>();

    /**
     * Use this template to customize each option.
     * By default, each option is the string computed with the 'displayWith' function.
     * Takes 2 parameters:
     *      - value: the value (an element of 'values' array)
     *      - isValueSelected: a function that will return if a value is currently selected
     */
    readonly optionTemplateInput = contentChild<TemplateRef<any>>('optionTemplate');

    // ------------ Event ------------//

    /** On change event */
    readonly selectChange = output<T>();

    // ------------ CORE ------------//

    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<T[]>([]);

    /** Readonly input */
    readonly inputReadOnly = input<boolean>(false);

    readonly idPrefix = input<string | undefined>();

    readonly displayedOptionCount = input<number>(Number.MAX_SAFE_INTEGER);

    readonly getIdSuffixFn = input<((value: any) => string) | undefined>();

    /** Map each value from the 'values' array to a string to display (autocomplete works with these strings) */
    readonly displayWith = input<(option: any) => string>((option: any) => option);

    readonly selectedValueDisplayWith = input<(option: any) => string>((option: any) => option);

    readonly testId = input<string>();

    readonly inputWithFixedWidth = input<boolean>(false);
}
