import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getKeywordsByRestaurantIdParamsValidator = z.object({ restaurant_id: objectIdValidator }).transform((data) => ({
    restaurantId: data.restaurant_id,
}));
export type GetKeywordsByRestaurantIdParamsDto = z.infer<typeof getKeywordsByRestaurantIdParamsValidator>;

export const getKeywordsForRestaurantRequestBodyValidator = z.object({ restaurant_ids: z.array(objectIdValidator) }).transform((data) => ({
    restaurantIds: data.restaurant_ids,
}));

export type GetKeywordsForRestaurantRequestBodyDto = z.infer<typeof getKeywordsForRestaurantRequestBodyValidator>;
