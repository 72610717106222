@if (isEmptyState()) {
    <div class="flex items-center gap-x-2">
        <div class="shrink-0">
            <div class="relative">
                <img class="malou-avatar--small !rounded-md" [src]="'default_restaurant_logo' | imagePathResolver" />
            </div>
        </div>
        <div class="min-w-0 grow">
            <div class="ml-2 flex flex-col items-start">
                <div class="malou-text-13--semibold malou-color-text-1 truncate">-</div>
                <div class="malou-text-10--regular malou-color-text-2 truncate italic">-</div>
            </div>
        </div>
    </div>
} @else {
    <div class="flex items-center gap-x-2" [class.justify-center]="hideLines()">
        <div class="shrink-0">
            <ng-container [ngTemplateOutlet]="logoTemplate"></ng-container>
        </div>
        @if (!hideLines()) {
            <div class="min-w-0 grow">
                <ng-container [ngTemplateOutlet]="linesTemplate"></ng-container>
            </div>
        }
    </div>
}

<ng-template #logoTemplate>
    <div class="relative">
        <img
            class="malou-avatar--small !rounded-md"
            [class.brightness-50]="countLogoOverlay()"
            [src]="logo() ?? ('default_logo' | imagePathResolver)" />
        @if (countLogoOverlay()) {
            <div class="malou-color-white malou-text-12--semibold" appAbsoluteCentering>{{ countLogoOverlay() }}</div>
        }
    </div>
</ng-template>

<ng-template #linesTemplate>
    <div class="flex flex-col">
        <ng-container [ngTemplateOutlet]="line1Template"></ng-container>
        @if (line2()) {
            <ng-container [ngTemplateOutlet]="line2Template"></ng-container>
        }
    </div>
</ng-template>

<ng-template #line1Template>
    <div class="flex items-center gap-x-1">
        <div class="malou-text-13--semibold malou-color-text-1 truncate" [matTooltip]="line1()" [matTooltipShowDelay]="700">
            {{ line1() }}
        </div>
        @if (svgIconOnLine1()) {
            <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="svgIconOnLine1()!"></mat-icon>
        }
    </div>
</ng-template>

<ng-template #line2Template>
    <div class="malou-text-10--regular malou-color-text-2 truncate italic" [matTooltip]="line2() ?? ''" [matTooltipShowDelay]="700">
        {{ line2() }}
    </div>
</ng-template>
