import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, contentChild, input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
    readonly nestedSkeletonTemplateRef = contentChild<TemplateRef<any>>('nestedSkeletonTemplate');

    /**
     * Number of the same skeleton to display
     */
    readonly count = input<number>(1);

    /**
     * Appearance
     */
    readonly appearance = input<'line' | 'circle'>('line');

    /**
     * When count is > 1, determine how the skeletons will be positioned in the flex parent container
     */
    readonly flexDirection = input<'flex-row' | 'flex-col'>('flex-col');

    /**
     * The flex wrap property of the container
     */
    readonly flexWrap = input<'flex-wrap' | 'flex-nowrap'>('flex-nowrap');

    /**
     * The justify-content property of the container
     */
    readonly justifyContent = input<
        'justify-start' | 'justify-end' | 'justify-center' | 'justify-between' | 'justify-around' | 'justify-evenly'
    >('justify-start');

    /**
     * Space separated class names that will be applied to each skeleton.
     * Don't forget to add !important on already applied style in this component (border-radius, width, height)
     */
    readonly skeletonClass = input<string>('h-6');

    /**
     * Replace the default gap (on the parent container) with the given class
     *
     * For example if you don't want gap at all:
     * <app-skeleton gap="gap-0"></app-skeleton>
     */
    readonly gapClass = input<string>('gap-4');

    /**
     * Replace the default height h-full with the given class
     */
    readonly heightClass = input<string>('h-full');

    /**
     * Set to false to only use a single skeleton with no container. Useful for inserting skeletons as parts of existing lists for infinite scrolls
     */
    readonly useContainer = input<boolean>(true);

    readonly animate = input<boolean>(true);

    readonly countArray = computed(() => Array(this.count()).fill(1));
}
