import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AggregationTimeScale, isNotNil } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsByPlatformByRestaurant, Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    platformsRatingsByRestaurant: InsightsByPlatformByRestaurant;
    restaurants: Restaurant[];
}

@Injectable({ providedIn: 'root' })
export class PlatformsRatingsAggregatedCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return Object.keys(data.platformsRatingsByRestaurant).length !== 0;
    }

    protected override _getData$(): Observable<Data> {
        return forkJoin({
            platformsRatingsByRestaurant: this._store
                .select(AggregatedStatisticsSelectors.selectPlatformsRatingsByRestaurantData)
                .pipe(take(1)),
            restaurants: this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
        });
    }

    protected override _getCsvDataRows({ platformsRatingsByRestaurant, restaurants }: Data): CsvAsStringArrays {
        return Object.entries(platformsRatingsByRestaurant)
            .map(([restaurantId, platformsRatings]) => {
                const restaurant = restaurants.find((r) => r._id === restaurantId);
                if (!restaurant) {
                    return null;
                }
                const restaurantName = restaurant.name;
                const restaurantInternalName = restaurant.internalName ?? '';
                const restaurantAddress = restaurant.getFullFormattedAddress();
                return Object.entries(platformsRatings)
                    .map(
                        ([platformKey, platformRatings]) =>
                            platformRatings[AggregationTimeScale.BY_DAY]?.platform_rating?.map((rating) => {
                                const date = new Date(rating.date).toISOString();
                                const ratingValue = this._shortNumberPipe.transform(rating.value);
                                const platformKeyTranslated = this._enumTranslatePipe.transform(platformKey, 'platform_key');
                                return [
                                    date,
                                    restaurantName,
                                    restaurantInternalName,
                                    restaurantAddress,
                                    ratingValue,
                                    platformKeyTranslated,
                                ];
                            }) ?? []
                    )
                    .flat();
            })
            .filter(isNotNil)
            .flat();
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Location', 'Location Internal Name', 'Location Address', 'Rating', 'Platform'];
    }
}
