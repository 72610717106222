import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface TotalGiftsData {
    totalWinningGifts: number | null;
    totalRetrievedGifts: number | null;
    totalWinningGiftsDifferenceWithPreviousPeriod: number | null;
    totalRetrievedGiftsDifferenceWithPreviousPeriod: number | null;
}

@Component({
    selector: 'app-wheel-of-fortune-gifts-kpis',
    templateUrl: './wheel-of-fortune-gifts-kpis.component.html',
    styleUrls: ['./wheel-of-fortune-gifts-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        SkeletonComponent,
        ShortNumberPipe,
        NumberEvolutionComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGiftsKpisComponent implements OnInit {
    @Input() data$: Observable<WheelOfFortuneGiftsStatisticsData>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;

    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    hasData: WritableSignal<boolean> = signal(true);
    readonly totalGiftsData: WritableSignal<TotalGiftsData> = signal({
        totalWinningGifts: null,
        totalRetrievedGifts: null,
        totalWinningGiftsDifferenceWithPreviousPeriod: null,
        totalRetrievedGiftsDifferenceWithPreviousPeriod: null,
    });

    ngOnInit(): void {
        this.data$.subscribe(({ giftsInsightsPerGift }) => {
            this.hasData.set(giftsInsightsPerGift.length > 0);
            this.hasDataChange.emit(giftsInsightsPerGift.length > 0);
            const totalWinningGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.giftDrawCount ?? 0), 0);
            const totalRetrievedGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.retrievedGiftDrawCount ?? 0), 0);
            const previousTotalWinningGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.previousGiftDrawCount ?? 0), 0);
            const previousTotalRetrievedGifts = giftsInsightsPerGift.reduce(
                (acc, curr) => acc + (curr.previousRetrievedGiftDrawCount ?? 0),
                0
            );

            this.totalGiftsData.set({
                totalWinningGifts,
                totalRetrievedGifts,
                totalWinningGiftsDifferenceWithPreviousPeriod: totalWinningGifts - previousTotalWinningGifts,
                totalRetrievedGiftsDifferenceWithPreviousPeriod: totalRetrievedGifts - previousTotalRetrievedGifts,
            });
        });
    }
}
