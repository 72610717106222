import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { RateWithStarsComponent } from ':shared/components/rate-with-stars/rate-with-stars.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { PrivateReview } from ':shared/models/private-review';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

export interface ScanPrivateReviewParams {
    restaurantId: string;
    rating: number;
    scanId: string;
}

@Component({
    selector: 'app-scan-private-review',
    templateUrl: './scan-private-review.component.html',
    styleUrls: ['./scan-private-review.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        MalouSpinnerComponent,
        AsyncPipe,
        IncludesPipe,
        MatIconModule,
        RateWithStarsComponent,
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        MatButtonModule,
        TextAreaComponent,
    ],
})
export class ScanPrivateReviewComponent {
    @Input() params: ScanPrivateReviewParams;
    readonly Illustration = Illustration;
    isError = false;
    textAreaControl = new FormControl<string>('', Validators.required);
    isReviewSubmitted = false;

    constructor(
        private readonly _reviewsService: ReviewsService,
        private _translate: TranslateService
    ) {}

    submitReview(): void {
        this.isReviewSubmitted = true;
        const privateReview: Partial<PrivateReview> = {
            restaurantId: this.params.restaurantId,
            text: this.textAreaControl.value || '',
            lang: this._translate.currentLang,
            scanId: this.params.scanId,
            socialCreatedAt: new Date(),
            rating: this.params.rating,
            archived: false,
        };
        this._reviewsService.createPrivateReview(privateReview).subscribe({
            error: (err) => {
                console.error('Error when submitting private review', err);
            },
        });
    }
}
