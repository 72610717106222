import { z } from 'zod';

export const getFacebookPagesQueryValidator = z.object({
    q: z.string(),
    onlyWithLocation: z
        .string()
        .optional()
        .refine((value) => !value || ['true', 'false'].includes(value))
        .transform((value) => value === 'true'),
    whitelistedPageIds: z.array(z.string()).optional(),
});
export type GetFacebookPagesQueryDto = z.infer<typeof getFacebookPagesQueryValidator>;
