import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'hasAttachments',
    standalone: true,
})
export class HasAttachmentsPipe implements PipeTransform {
    transform(review: Review | PrivateReview): boolean {
        return review.hasAttachments();
    }
}
