@if (hasAnsweredToForm() || hasSelectedKeywords()) {
    <div class="rounded-xl bg-malou-color-background-dark p-6.5">
        <div class="relative flex items-center justify-between">
            <div class="grow">
                <div class="malou-text-14--medium text-malou-color-text-1 md:mr-2 md:max-w-[50%]">
                    {{ 'keywords.generator_form_title' | translate }}
                </div>
                <div class="malou-text-10--regular mt-3">
                    {{
                        hasAnsweredToForm()
                            ? ('keywords.generator_form_subtitle' | translate)
                            : ('keywords.generator_form_subtitle_no_answer' | translate)
                    }}
                </div>
            </div>
            @if (hasAnsweredToForm()) {
                <div class="flex items-center gap-x-4 md:absolute md:right-0 md:top-0">
                    @if (!isPhoneScreen()) {
                        <button
                            class="malou-btn-raised--primary btn-xl !h-12.5 opacity-0 transition-all"
                            id="tracking_keywords_form_modification"
                            data-testid="keywords-open-edit-form-btn"
                            mat-raised-button
                            [class.opacity-100]="formToggled()"
                            (click)="onOpenGeneratorFormModal()">
                            {{ 'common.edit' | translate }}
                        </button>
                    } @else {
                        <button
                            class="malou-btn-icon--primary btn-xl opacity-0 transition-all"
                            mat-icon-button
                            [class.opacity-100]="formToggled()"
                            (click)="onOpenGeneratorFormModal()">
                            <mat-icon [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        </button>
                    }
                    <button
                        class="malou-btn-icon--secondary btn-xl opacity-0 transition-all"
                        mat-icon-button
                        [class.opacity-100]="formToggled()"
                        [matTooltip]="'common.duplicate' | translate"
                        (click)="openDuplicateGeneratorModal()">
                        <mat-icon class="transition-all" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    </button>
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        data-testid="keywords-chevron-down-btn"
                        mat-icon-button
                        (click)="toggleForm()">
                        <mat-icon
                            class="transition-all"
                            color="primary"
                            [svgIcon]="SvgIcon.CHEVRON_DOWN"
                            [class.rotate-180]="formToggled()"></mat-icon>
                    </button>
                </div>
            } @else {
                <button class="malou-btn-raised--primary btn-xl" mat-raised-button (click)="onOpenGeneratorFormModal()">
                    {{ 'keywords.answer_to_form' | translate }}
                </button>
            }
        </div>
        @if (formToggled()) {
            <div
                class="flex h-[0] w-full flex-wrap justify-between gap-4 pt-0 opacity-0 transition-all"
                [ngClass]="{ '!h-full pt-4 opacity-100': formToggled() }">
                @for (key of generatorKeys(); track key) {
                    <div class="w-[30%] md:w-[52%]">
                        <div class="malou-text-10--regular mb-2 text-malou-color-text-2">{{ 'generator.' + key | translate }}</div>
                        <div class="flex flex-wrap gap-2">
                            @for (brick of generatorFormAnswers()[key]; track trackByIdFn(i, brick); let i = $index) {
                                <div class="malou-chip malou-chip--primary">
                                    <span class="truncate"> {{ brick | applySelfPure: 'getDisplayedValue' }} </span>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div class="min-w-[30%]">
                    <div class="malou-text-10--regular mb-2 text-malou-color-text-2">{{ 'generator.locality' | translate }}</div>
                    <div class="flex gap-x-2">
                        <div class="malou-chip malou-chip--primary">
                            <span> {{ postalCode() }} </span>
                        </div>
                    </div>
                </div>
                @if (city()) {
                    <div class="min-w-[30%]">
                        <div class="malou-text-10--regular mb-2 text-malou-color-text-2">{{ 'generator.city' | translate }}</div>
                        <div class="flex gap-x-2">
                            <div class="malou-chip malou-chip--primary">
                                <span> {{ city() }} </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}
