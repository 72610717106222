import { Sort } from '@angular/material/sort';

import { CsvInsightChart, InsightsChart, InsightsTab, PartialRecord, PlatformKey } from '@malou-io/package-utils';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';

export enum DownloadFormat {
    PDF = 'PDF',
    CSV = 'CSV',
}

export interface DownloadInsightsQueryParamParams {
    displayedCharts: InsightsChart[];
    chartOptions?: ChartOptions;
    platformKeys?: PlatformKey[];
    restaurantIds?: string[];
    nfcIds?: string[];
    startDate: string;
    endDate: string;
    timeZone: string;
}

export enum StatisticsDataViewMode {
    CHART = 'CHART',
    TABLE = 'TABLE',
}

export type ChartOptions = PartialRecord<
    InsightsChart,
    {
        viewBy?: ViewBy;
        chartSortBy?: ChartSortBy;
        hiddenDatasetIndexes?: number[];
        tableSortOptions?: Sort;
        viewMode?: StatisticsDataViewMode;
    }
>;

export type ChartByTab = Record<InsightsTab, InsightsChart[]>;

export enum FileExtension {
    PDF = 'pdf',
    CSV = 'csv',
    ZIP = 'zip',
}

export const chartByTab: ChartByTab = {
    [InsightsTab.SEO]: [InsightsChart.ACTIONS, InsightsChart.APPARITIONS, InsightsChart.KEYWORDS],
    [InsightsTab.E_REPUTATION]: [
        InsightsChart.REVIEW_KPIS,
        InsightsChart.REVIEW_RATINGS_KPIS,
        InsightsChart.REVIEW_RATING_TOTAL,
        InsightsChart.REVIEW_RATING_EVOLUTION,
        InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION,
        InsightsChart.REVIEW_ANALYSES_TAG_CHARTS,
    ],
    [InsightsTab.SOCIAL_NETWORKS]: [
        InsightsChart.COMMUNITY,
        InsightsChart.ENGAGEMENT,
        InsightsChart.POST_INSIGHTS,
        InsightsChart.REEL_INSIGHTS,
        InsightsChart.STORY_INSIGHTS,
    ],
    [InsightsTab.BOOSTERS]: [
        InsightsChart.BOOSTERS_SCAN_COUNT,
        InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT,
        InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION,
        InsightsChart.BOOSTERS_PRIVATE_REVIEWS_COUNT,
    ],
    [InsightsTab.AGGREGATED_SEO]: [
        InsightsChart.AGGREGATED_RANKINGS,
        InsightsChart.AGGREGATED_ACTIONS,
        InsightsChart.AGGREGATED_APPARITIONS,
    ],
    [InsightsTab.AGGREGATED_E_REPUTATION]: [
        InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS,
        InsightsChart.AGGREGATED_REVIEWS_COUNT,
        InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE,
        InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_CHARTS,
        InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION,
    ],
    [InsightsTab.AGGREGATED_SOCIAL_NETWORKS]: [InsightsChart.AGGREGATED_TOP_POSTS_CARDS, InsightsChart.AGGREGATED_PUBLICATIONS_TABLE],
    [InsightsTab.AGGREGATED_BOOSTERS]: [
        InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT,
        InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT,
    ],
};

export const csvChartDataByTab: Record<InsightsTab, CsvInsightChart[]> = {
    [InsightsTab.SEO]: [CsvInsightChart.KEYWORDS, CsvInsightChart.GMB_VISIBILITY],
    [InsightsTab.SOCIAL_NETWORKS]: [
        CsvInsightChart.PUBLICATIONS,
        CsvInsightChart.ALL_FOLLOWERS,
        CsvInsightChart.FB_FOLLOWERS,
        CsvInsightChart.IG_FOLLOWERS,
        CsvInsightChart.STORIES,
    ],
    [InsightsTab.E_REPUTATION]: [
        CsvInsightChart.PLATFORMS_RATINGS,
        CsvInsightChart.REVIEWS_RATINGS_EVOLUTION,
        CsvInsightChart.REVIEWS_RATINGS_TOTAL,
    ],
    [InsightsTab.BOOSTERS]: [
        CsvInsightChart.BOOSTERS_SCAN_COUNT,
        CsvInsightChart.BOOSTERS_REVIEWS_COUNT,
        CsvInsightChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION,
    ],
    [InsightsTab.AGGREGATED_SEO]: [CsvInsightChart.AGGREGATED_GMB_VISIBILITY],
    [InsightsTab.AGGREGATED_E_REPUTATION]: [
        CsvInsightChart.AGGREGATED_PLATFORMS_RATINGS,
        CsvInsightChart.AGGREGATED_AVERAGE_REVIEWS_RATINGS,
        CsvInsightChart.AGGREGATED_REVIEW_COUNT,
    ],
    [InsightsTab.AGGREGATED_SOCIAL_NETWORKS]: [
        CsvInsightChart.AGGREGATED_ALL_FOLLOWERS,
        CsvInsightChart.AGGREGATED_FB_FOLLOWERS,
        CsvInsightChart.AGGREGATED_IG_FOLLOWERS,
        CsvInsightChart.AGGREGATED_PUBLICATIONS,
        CsvInsightChart.AGGREGATED_STORIES,
    ],
    [InsightsTab.AGGREGATED_BOOSTERS]: [
        CsvInsightChart.AGGREGATED_BOOSTERS_SCAN_COUNT,
        CsvInsightChart.AGGREGATED_BOOSTERS_REVIEWS_COUNT,
        CsvInsightChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION,
    ],
};

export interface DownloadStatsFormData {
    pdfCharts: InsightsChart[];
    csvCharts: CsvInsightChart[];
    downloadFormat: DownloadFormat;
}
