import { Routes } from '@angular/router';

import { TotemsComponent } from './totems.component';

export const TOTEMS_ROUTES: Routes = [
    {
        path: '',
        component: TotemsComponent,
    },
];
