import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { SpinnerOverlayComponent } from ':core/components/spinner/spinner-overlay/spinner-overlay.component';

// DOCUMENTATION: https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    private _overlayRef: OverlayRef;
    private _isShown = false;

    constructor(private readonly _overlay: Overlay) {}

    public show(): void {
        if (this._isShown) {
            return;
        }
        this._isShown = true;

        this._initOverlayRef();

        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
        this._overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }

    public hide(): void {
        if (!!this._overlayRef) {
            this._overlayRef.detach();
            this._isShown = false;
        }
    }

    /**
     * Create an OverlayRef (which is a PortalHost)
     */
    private _initOverlayRef(): void {
        if (!this._overlayRef) {
            this._overlayRef = this._overlay.create();
        }
    }
}
