import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const updateRestaurantOrganizationParamsValidator = z.object({
    restaurantId: objectIdValidator,
});
export type UpdateRestaurantOrganizationParamsDto = z.infer<typeof updateRestaurantOrganizationParamsValidator>;

export const updateRestaurantOrganizationRequestBodyValidator = z.object({
    organizationId: objectIdValidator,
});
export type UpdateRestaurantOrganizationRequestBodyDto = z.infer<typeof updateRestaurantOrganizationRequestBodyValidator>;
