<div class="malou-card container m-8" style="padding: 60px">
    @if (!errorOccured) {
        <div>
            <div class="flex flex-col items-center justify-center">
                <div class="flex items-center" style="margin-bottom: 40px">
                    <i class="material-icons malou-color-success malou-text--big-icon mr-5">check_circle</i>
                    <div class="malou-title__kevin malou-color-success title">
                        {{ 'unsubscribe.unsubscribed' | translate }}
                    </div>
                </div>
                <br />
                <p class="malou-title__kevin">{{ 'unsubscribe.unsubscribed_details' | translate: { restaurantName } }}</p>
            </div>
        </div>
    } @else {
        <div class="flex flex-col items-center justify-center">
            <div class="flex items-center" style="margin-bottom: 40px">
                <i class="material-icons malou-color-success malou-text--big-icon mr-5">highlight_off</i>
                <div class="malou-title__kevin malou-color-success malou-text--2x-large malou-text--semi-bold">
                    {{ 'unsubscribe.error' | translate }}
                </div>
            </div>
            <br />
            <p class="malou-title__kevin">{{ 'unsubscribe.error_detail' | translate: { restaurantName } }}</p>
        </div>
    }
</div>
