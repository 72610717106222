import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
    AVAILABLE_MESSAGE_TEMPLATE_VARIABLES,
    TemplateReplacer,
    TemplateReplacerType,
} from ':modules/templates/template-replacer/template-replacer';
import { SelectComponent } from ':shared/components/select/select.component';
import { Template } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-templates-picker-modal',
    templateUrl: './templates-picker-modal.component.html',
    styleUrls: ['./templates-picker-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, SelectComponent, NgTemplateOutlet, TranslateModule, ApplyPurePipe],
})
export class TemplatesPickerModalComponent {
    readonly SvgIcon = SvgIcon;
    private readonly _TEMPLATE_REPLACER = new TemplateReplacer(this._translate, AVAILABLE_MESSAGE_TEMPLATE_VARIABLES);
    selectedTemplate: Template;

    constructor(
        private readonly _translate: TranslateService,
        private readonly _dialogRef: MatDialogRef<TemplatesPickerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { templates: Template[] }
    ) {}

    close(): void {
        this._dialogRef.close();
    }

    selectTemplate(template: Template): void {
        this.selectedTemplate = template;
    }

    useTemplate(): void {
        this._dialogRef.close(this.selectedTemplate);
    }

    displayWith = (template: Template): string => `${template.name} - ${template.text}`;

    replaceText = (text: string): string =>
        this._TEMPLATE_REPLACER.replaceText(text, {
            from: TemplateReplacerType.VARIABLE_TEXT,
            to: TemplateReplacerType.CHIP_TEXT,
        });
}
