import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniqBy, uniqWith } from 'lodash';
import { catchError, filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { isNotNil, PlatformKey, Role } from '@malou-io/package-utils';

import { adminAuthList } from ':core/constants';
import { CredentialsService } from ':core/services/credentials.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { Credential } from ':shared/models';

export type AuthorizedPlatformKeys =
    | PlatformKey.GMB
    | PlatformKey.FACEBOOK
    | PlatformKey.INSTAGRAM
    | PlatformKey.ZENCHEF
    | PlatformKey.DELIVEROO
    | PlatformKey.TIKTOK;

@Injectable({
    providedIn: 'root',
})
export class GetCredentialsService {
    private readonly _store = inject(Store);
    private readonly _credentialsService = inject(CredentialsService);
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: AuthorizedPlatformKeys): Observable<Credential[]> {
        const adjustedPlatformKey = platformKey === PlatformKey.INSTAGRAM ? PlatformKey.FACEBOOK : platformKey;

        const user$ = this._store.select(selectUserInfos).pipe(filter(isNotNil));
        return user$.pipe(
            take(1),
            switchMap((user) => {
                const organizationCredentials = this._restaurantsService.currentRestaurant.organizationId
                    ? this._credentialsService.getOrganizationCredentials(this._restaurantsService.currentRestaurant.organizationId)
                    : of({ data: [] as Credential[] });
                const adminCredentials =
                    user.role === Role.ADMIN
                        ? this._credentialsService.getAdminCredentials().pipe(catchError(() => of({ data: [] })))
                        : of({ data: [] as Credential[] });

                return forkJoin([organizationCredentials, adminCredentials, of(user)]);
            }),
            map(([organizationCredentials, adminCredentials, user]) => {
                const credentials = uniqBy(organizationCredentials.data.concat(adminCredentials.data), '_id');
                const filteredCredentials = credentials
                    .filter((cred: Credential) => adjustedPlatformKey === cred.key)
                    .filter((cred: Credential) => (user.role === Role.ADMIN ? true : !adminAuthList.includes(cred.authId)));
                const sortedCredentials = filteredCredentials.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1));
                return uniqWith(sortedCredentials, (a: Credential, b: Credential) => a.authId === b.authId);
            })
        );
    }
}
