import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { Day } from '@malou-io/package-utils';

import { SelectOpenHoursComponent } from ':shared/components/select-open-hours/select-open-hours.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { Period } from ':shared/models/periods';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-select-days-and-hours',
    templateUrl: './select-days-and-hours.component.html',
    styleUrls: ['./select-days-and-hours.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        SimpleSelectComponent,
        SelectOpenHoursComponent,
        ApplyPurePipe,
        FormatTimePipe,
        IncludesPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EnumTranslatePipe],
})
export class SelectDaysAndHoursComponent {
    readonly selectedDays = model.required<Day[]>();
    readonly availableDays = input<Day[]>(Object.values(Day));
    readonly periods = model<Period[]>([]);
    readonly isRestaurantClosedTemporarily = input<boolean>(false);
    readonly isRestaurantClosedDuringPeriods = model<boolean>(false);
    readonly withIsClosedSelection = input<boolean>(true);
    readonly disabled = input<boolean>(false);
    readonly canDeleteSelect = input<boolean>(false);

    readonly delete = output<void>();

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly DAYS_OPTIONS = Object.values(Day);

    readonly SvgIcon = SvgIcon;

    getWeekdayLabel = (day: Day): string => this._enumTranslatePipe.transform(day, 'days')[0].toUpperCase();

    toggleDay = (day: Day): void => {
        if (!this.availableDays().includes(day) || this.disabled()) {
            return;
        }
        this.selectedDays.update((days) => {
            if (days.includes(day)) {
                return days.filter((d) => d !== day);
            } else {
                return [...days, day];
            }
        });
    };

    deleteSelect(): void {
        this.delete.emit();
    }
}
