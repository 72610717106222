import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { AddressDto } from '../restaurant';
import { objectIdValidator } from '../utils/validators';

export const getReviewsEvolutionTotalBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
    previousPeriod: z.boolean(),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
});

export type GetReviewsEvolutionTotalBodyDto = z.infer<typeof getReviewsEvolutionTotalBodyValidator>;

export type GetReviewsEvolutionTotalResponseBodyDto = {
    restaurant: {
        _id: string;
        name: string;
        address: AddressDto | null | undefined;
        internalName?: string;
    };
    averageRating?: number;
    total?: number;
    reviews: {
        key: string;
        socialId: string;
        rating: number;
    }[];
}[];
