import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';

import { FoldersService } from ':core/services/folders.service';
import { ToastService } from ':core/services/toast.service';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { GalleryFilters, IFolder, Media, Restaurant } from ':shared/models';
import { Pagination } from ':shared/models/pagination';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ToRangePipe } from ':shared/pipes/to-range.pipe';

import * as GalleryActions from '../../gallery/gallery.actions';
import { selectGalleryFilters } from '../../gallery/gallery.reducer';
import { FolderPickerComponent } from '../folder-picker/folder-picker.component';
import { MediaPickerComponent } from '../media-picker/media-picker.component';
import { MediaService } from '../media.service';
import { MediaPickerFilter } from './media-picker-modal.interface';

const DEFAULT_PAGE_PAGINATION: Pagination = { pageSize: 40, pageNumber: 0, total: 0 };

@Component({
    selector: 'app-media-picker-modal',
    templateUrl: './media-picker-modal.component.html',
    styleUrls: ['./media-picker-modal.component.scss'],
    standalone: true,
    imports: [
        InfiniteScrollModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        TranslateModule,
        FolderPickerComponent,
        MediaPickerComponent,
        SearchComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ToRangePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPickerModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly pagination$ = new BehaviorSubject(DEFAULT_PAGE_PAGINATION);
    readonly filters$: Observable<GalleryFilters> = this._store.select(selectGalleryFilters);

    multi: boolean;
    filter: string;
    readonly medias: WritableSignal<Media[]> = signal([]);
    pagination: Pagination = DEFAULT_PAGE_PAGINATION;
    selectedMedias: Media[] = [];

    readonly folders: WritableSignal<IFolder[]> = signal([]);
    readonly currentFolder$: BehaviorSubject<IFolder | null> = new BehaviorSubject(null);

    readonly isLoadingFolder = signal(false);
    readonly isLoadingMedias = signal(false);
    readonly maxMediaReachedMessage = signal<string | undefined>(undefined);
    readonly maxMedia = signal<number>(10);

    private readonly _maxVideoSize: number | undefined;
    private _restaurant: Restaurant;
    private _filters: GalleryFilters;

    constructor(
        private readonly _dialogRef: MatDialogRef<MediaPickerModalComponent>,
        private readonly _store: Store,
        private readonly _foldersService: FoldersService,
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            multi: boolean;
            filter: string;
            selectedMedias: Media[];
            maxVideoSize?: number;
            maxMedia?: number;
            maxMediaReachedMessage?: string;
        }
    ) {
        this._maxVideoSize = this.data.maxVideoSize;
        this._restaurant = this.data.restaurant;
        this.multi = this.data.multi;
        this.selectedMedias = this.data.selectedMedias || [];

        if (data.maxMedia !== undefined && data.maxMedia !== null) {
            this.maxMedia.set(data.maxMedia);
            this.maxMediaReachedMessage.set(data.maxMediaReachedMessage);
        }

        this.currentFolder$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.isLoadingFolder.set(true);
            this.pagination$.next(DEFAULT_PAGE_PAGINATION);
        });
    }

    ngOnInit(): void {
        const mediaType = this.data.filter || MediaPickerFilter.ALL;

        combineLatest([this.filters$, this.pagination$])
            .pipe(
                switchMap(([filters, pagination]) => {
                    if (!isEqual(filters, this._filters)) {
                        this.isLoadingMedias.set(true);
                    }
                    this._filters = filters;
                    const currentFolderId = this.currentFolder$.value ? this.currentFolder$.value.id : null;

                    const media$ = this._mediaService.getRestaurantMediasPaginated(
                        this._restaurant._id,
                        pagination,
                        {
                            ...filters,
                            mediaType,
                            maxVideoSize: this._maxVideoSize,
                        },
                        currentFolderId
                    );

                    const folders$ = this._foldersService.getRestaurantFolders(
                        this._restaurant._id,
                        currentFolderId,
                        filters.isNeverUsed,
                        filters.title
                    );

                    return combineLatest([media$, folders$, this.pagination$, this.filters$]);
                })
            )
            .subscribe({
                next: ([mediaResult, foldersResult, pagination]) => {
                    this.isLoadingFolder.set(false);
                    this.isLoadingMedias.set(false);
                    if (pagination.pageNumber === 0) {
                        this.medias.set(mediaResult.data.medias);
                    } else {
                        this.medias.update((currentMedia) => currentMedia.concat(mediaResult.data.medias));
                    }
                    this.folders.set(this._sortFolders(foldersResult.data));
                },
                error: (err) => {
                    this.isLoadingFolder.set(false);
                    this.isLoadingMedias.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    onMediaSelect(medias: Media[]): void {
        this.selectedMedias = medias;
        if (!this.multi) {
            this.submit();
        }
    }

    submit(): void {
        this._dialogRef.close(this.selectedMedias);
    }

    onScrollDown(): void {
        this.pagination$.next({ ...this.pagination$.value, pageNumber: this.pagination$.value.pageNumber + 1 });
    }

    cancel(): void {
        this._dialogRef.close(false);
    }

    onOpenFolder(folder: IFolder | null): void {
        this.currentFolder$.next(folder);
    }

    onSearchByMediaTitle(title: string): void {
        const filters = { title };
        this._store.dispatch({ type: GalleryActions.editGalleryFilters.type, filters });
        this.pagination$.next(DEFAULT_PAGE_PAGINATION);
    }

    onEditNeverPosterOnlyFilter(event: MatButtonToggleChange): void {
        const filters = { isNeverUsed: event.value as boolean };
        this._store.dispatch({ type: GalleryActions.editGalleryFilters.type, filters });
        this.pagination$.next(DEFAULT_PAGE_PAGINATION);
    }

    private _sortFolders(folders: IFolder[]): IFolder[] {
        return folders.sort((folderA, folderB) => folderA.name.localeCompare(folderB.name));
    }
}
