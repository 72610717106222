import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

enum DurationUnit {
    MILLISECONDS = 'milliseconds',
    SECONDS = 'seconds',
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
}

const durationUnitsOrdered: DurationUnit[] = [
    DurationUnit.DAYS,
    DurationUnit.HOURS,
    DurationUnit.MINUTES,
    DurationUnit.SECONDS,
    DurationUnit.MILLISECONDS,
];

@Pipe({
    name: 'formatMillisecondsDuration',
    standalone: true,
})
export class FormatMillisecondsDurationPipe implements PipeTransform {
    constructor(private readonly _pluralTranslatePipe: PluralTranslatePipe) {}

    transform(milliseconds?: number, options?: { onlyValue?: boolean; onlyUnit?: boolean }): string {
        if (typeof milliseconds !== 'number' || isNaN(milliseconds)) {
            if (options?.onlyValue) {
                return '-';
            }
            if (options?.onlyUnit) {
                return '';
            }
            return '-';
        }

        milliseconds = Math.abs(milliseconds);

        const durationObject = Duration.fromMillis(milliseconds)
            .shiftTo(...durationUnitsOrdered)
            .toObject();

        let durationUnitSelected: DurationUnit = DurationUnit.SECONDS;
        let durationValueSelected = 0;
        for (const durationUnit of durationUnitsOrdered) {
            const durationValue = durationObject[durationUnit] ?? 0;
            if (durationValue !== 0) {
                durationUnitSelected = durationUnit;
                durationValueSelected = durationValue;
                break;
            }
        }

        const translateKey = `common.${durationUnitSelected}`;
        const unit = this._pluralTranslatePipe.transform(translateKey, durationValueSelected);
        if (options?.onlyValue) {
            return durationValueSelected.toString();
        }
        if (options?.onlyUnit) {
            return unit;
        }
        return `${durationValueSelected} ${unit}`;
    }
}
