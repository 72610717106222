<div class="malou-simple-card flex flex-col gap-y-2 p-5">
    <ng-container [ngTemplateOutlet]="dateTemplate"></ng-container>
    @if (topPostCardInputData.restaurantName) {
        <ng-container [ngTemplateOutlet]="restaurantInfoTemplate"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="imgTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="kpisTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="kpisCardTemplate"></ng-container>
</div>

<ng-template #dateTemplate>
    <div class="flex gap-x-2">
        <app-platform-logo imgClasses="!h-4 !w-4" [logo]="topPostCardInputData.platformKey"></app-platform-logo>
        <div class="malou-text-13--semibold malou-color-text-1">
            {{ topPostCardInputData.createdAt | formatDate: 'medium' }}
        </div>
    </div>
</ng-template>

<ng-template #restaurantInfoTemplate>
    <div class="flex flex-col">
        <div class="malou-text-13--semibold malou-color-text-1">{{ topPostCardInputData.restaurantName }}</div>
        <div class="malou-text-12--regular malou-color-text-2 italic">{{ topPostCardInputData.restaurantAddress }}</div>
    </div>
</ng-template>

<ng-template #imgTemplate>
    <div class="h-32">
        <app-social-post-media
            [postType]="topPostCardInputData.postType"
            [workingMedia]="topPostCardInputData.url"
            [thumbnailUrl]="topPostCardInputData.thumbnailUrl ?? null"></app-social-post-media>
    </div>
</ng-template>

<ng-template #kpisTemplate>
    <div class="malou-text-15--bold malou-color-text-1 flex justify-between py-2">
        <div class="flex items-center gap-x-1">
            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.HEART"></mat-icon>
            <div>{{ topPostCardInputData.likes }}</div>
        </div>
        <div class="flex items-center gap-x-1">
            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.CONVERSATION"></mat-icon>
            <div>{{ topPostCardInputData.comments }}</div>
        </div>
        <div class="flex items-center gap-x-1">
            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.SHARE"></mat-icon>
            <div>{{ topPostCardInputData.shares }}</div>
        </div>
        <div class="flex items-center gap-x-1">
            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.SAVE"></mat-icon>
            <div>{{ topPostCardInputData.saves }}</div>
        </div>
    </div>
</ng-template>

<ng-template #kpisCardTemplate>
    <div class="malou-simple-card--light flex justify-around gap-x-2 p-4">
        <div class="flex flex-col">
            <div class="malou-text-30--bold malou-color-text-1">{{ topPostCardInputData.impressions }}</div>
            <div class="malou-text-12--regular malou-color-text-2">{{ 'top_post_card.impressions' | translate }}</div>
        </div>
        <div class="flex flex-col">
            <div class="malou-text-30--bold malou-color-text-1">
                {{ topPostCardInputData.engagementRate | shortNumber: { content: '%', fixNumber: 2 } }}
            </div>
            <div class="malou-text-12--regular malou-color-text-2">{{ 'top_post_card.engagement' | translate }}</div>
        </div>
    </div>
</ng-template>
