import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { MenuButtonV2Component } from ':shared/components/menu-button-v2/menu-button-v2.component';
import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-create-social-post-menu-button',
    templateUrl: './create-social-post-menu-button.component.html',
    styleUrls: ['./create-social-post-menu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatIconModule, MatMenuModule, TranslateModule, MenuButtonV2Component],
})
export class CreateSocialPostMenuButtonComponent {
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();

    readonly SvgIcon = SvgIcon;
    readonly MenuButtonSize = MenuButtonSize;

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }
}
