import { z } from 'zod';

import { objectIdValidator } from '../../utils/validators';

export const getAssociatedLocationsQueryValidator = z
    .object({
        account_id: objectIdValidator.nullish(),
    })
    .transform((data) => ({
        accountId: data.account_id ?? null,
    }));
export type GetAssociatedLocationsQueryDto = z.infer<typeof getAssociatedLocationsQueryValidator>;

export const getAssociatedLocationsParamsValidator = z
    .object({
        credential_id: objectIdValidator.nullish(),
    })
    .transform((data) => ({
        credentialId: data.credential_id ?? null,
    }));
export type GetAssociatedLocationsParamsDto = z.infer<typeof getAssociatedLocationsParamsValidator>;
