import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';

import { ClientVariable, PlatformKey, RedirectStar } from '@malou-io/package-utils';

import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { User } from ':modules/user/user';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { Campaign, campaignOpeningStatisticsDate } from ':shared/models/campaign';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-campaign-report-modal',
    templateUrl: './campaign-report-modal.component.html',
    styleUrls: ['./campaign-report-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, StarGaugeComponent, MatTooltipModule, DatePipe, TranslateModule, PlatformLogoComponent],
})
export class CampaignReportModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    campaign: Campaign;
    positiveReviews: number;
    negativeReviews: number;
    reviewsCount: number;
    sentEmails: number;
    deliveredEmails: number;
    responseRateTooltip: string;
    unsubscribedRate: number;
    sentEmailsTooltipText = '';
    positiveReviewsTooltip: string;
    readonly RATING_LIST = [1, 2, 3, 4, 5];
    readonly ALL_STARS_CLICKS = this.RATING_LIST.reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
    }, {});
    hasOpeningStats = true;
    readonly PlatformKey = PlatformKey;
    responseRate: number;

    constructor(
        private readonly _dialogRef: MatDialogRef<CampaignReportModalComponent>,
        private readonly _campaignsService: CampaignsService,
        private readonly _translate: TranslateService,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            campaign: Campaign;
            user: User;
        }
    ) {
        this.campaign = this.data.campaign;
    }

    ngOnInit(): void {
        this._initStatistics();
    }

    transformText(text: string): string {
        return text.replace(ClientVariable.FIRSTNAME, this.data.user?.name).replace(ClientVariable.LASTNAME, this.data.user?.lastname);
    }

    cancel(result = false): void {
        this._dialogRef.close(result);
    }

    getDisplayedValueOrNone(value: number, { isPercentage }: { isPercentage?: boolean }): string {
        return value.toString() + (isPercentage ? '%' : '');
    }

    private _initStatistics(): void {
        this.hasOpeningStats = campaignOpeningStatisticsDate.getTime() <= new Date(this.campaign.startDate).getTime();
        const redirectFromNumber = this.campaign.redirectFrom === RedirectStar.FIVE_STARS ? 5 : 4;
        this.positiveReviews = this.campaign.contactInteractions.filter(
            (client) => client.lastStarRating && client.lastStarRating >= redirectFromNumber
        )?.length;
        this.positiveReviewsTooltip =
            this.campaign.redirectFrom === RedirectStar.FIVE_STARS
                ? this._translate.instant('campaigns.report.positive_click_number_five')
                : this._translate.instant('campaigns.report.positive_click_number_four_five');

        this.negativeReviews = this.campaign.contactInteractions.filter(
            (client) => client.lastStarRating && client.lastStarRating < redirectFromNumber
        )?.length;

        this.reviewsCount = this.positiveReviews + this.negativeReviews;
        const groupedReviews = groupBy(this.campaign.contactInteractions, 'lastStarRating');
        this.RATING_LIST.forEach((nb) => (this.ALL_STARS_CLICKS[nb] = groupedReviews[nb]?.length || 0));

        this.sentEmails = this.campaign.contactInteractions?.length;
        this._setSentEmailsTooltipText();

        this.deliveredEmails = this.campaign.contactInteractions.filter((client) => !!client.deliveredDate)?.length;

        const respondedMails = this.campaign.contactInteractions.filter((client) => !!client.lastStarRating)?.length;
        this.responseRate = this.deliveredEmails !== 0 ? Math.round((respondedMails / this.deliveredEmails) * 100) : 0;
        this.responseRateTooltip =
            this.responseRate === 0
                ? null
                : this._translate.instant('campaigns.report.response_rate_tooltip', {
                      responded: respondedMails,
                      delivered: this.deliveredEmails,
                  });

        const unsubscribedNumber = this.campaign.contactInteractions.filter((client) => !!client.unsubscribedDate)?.length;
        this.unsubscribedRate = this.sentEmails !== 0 ? Math.round((unsubscribedNumber / this.sentEmails) * 100) : 0;
    }

    private _setSentEmailsTooltipText(): void {
        this._campaignsService.getClientsSources(this.campaign._id).subscribe({
            next: (res) => {
                this.campaign.audience.sources.forEach((source) => {
                    this.sentEmailsTooltipText += this._pluralTranslatePipe.transform(
                        `campaigns.report.client_sources.${source}`,
                        res.data[source]
                    );
                });
            },
            error: (err) => {
                console.warn('err: >>', err);
            },
        });
    }
}
