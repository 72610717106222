import { z } from 'zod';

import { CaslRole } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const userRestaurantValidator = z.object({
    _id: objectIdValidator,
    restaurantId: objectIdValidator,
    userId: objectIdValidator,
    createdAt: z.string(),
    updatedAt: z.string(),
    caslRole: z.nativeEnum(CaslRole).nullish(),
    displayPermissionsModal: z.boolean().nullish(),
    displayHashtagsModal: z.boolean().nullish(),
    displaySemanticAnalyses: z.boolean().nullish(),
    displaySyncWarningModal: z.boolean().nullish(),
});
