<div class="flex h-full flex-col gap-y-4 overflow-y-auto py-4" #topOfComponent>
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }
    <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if ((platformKeys$ | async)?.length !== 0) {
            <div class="flex flex-col gap-4">
                <app-statistics-seo-ratings (isLoadingEvent)="isSeoRatingLoading.set($event)"></app-statistics-seo-ratings>
                <app-reviews-kpis (isLoadingEvent)="isReviewsKpisLoading.set($event)"></app-reviews-kpis>
                <div class="mb-4 flex gap-4 md:flex-col">
                    <div class="min-w-0 flex-1">
                        <app-reviews-ratings-evolution
                            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.REVIEW_RATING_EVOLUTION, $event)"
                            (viewByChange)="onViewByChange(InsightsChart.REVIEW_RATING_EVOLUTION, $event)"
                            (isLoadingEvent)="isReviewsRatingsEvolutionLoading.set($event)">
                        </app-reviews-ratings-evolution>
                    </div>
                    <div class="min-w-0 flex-1">
                        <app-reviews-ratings-total (isLoadingEvent)="isReviewsRatingsTotalLoading.set($event)"></app-reviews-ratings-total>
                    </div>
                </div>
                <app-review-analyses
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
                    (viewByChange)="onViewByChange(InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
                    (isLoadingEvent)="isReviewAnalysesLoading.set($event)">
                </app-review-analyses>
            </div>
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'statistics.errors.platforms_not_connected' | translate }}</span>
            </div>
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 sm:flex-col sm:items-center md:px-0">
        <div class="flex-1">
            <app-statistics-filters [showPlatformsFilter]="true" [platformFilterPage]="PlatformFilterPage.E_REPUTATION">
            </app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="(platformKeys$ | async)?.length === 0 || isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
