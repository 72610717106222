import { CommentOptionValue, RatingCategory, ReviewReplyAutomationComment, ReviewReplyAutomationRatingCategory } from './modules';

export function fromRatingCategoryToRatings(ratingCategory: ReviewReplyAutomationRatingCategory): RatingCategory[] {
    switch (ratingCategory) {
        case ReviewReplyAutomationRatingCategory.REVIEW_1_2:
            return [1, 2];
        case ReviewReplyAutomationRatingCategory.REVIEW_3:
            return [3];
        case ReviewReplyAutomationRatingCategory.REVIEW_4:
            return [4];
        case ReviewReplyAutomationRatingCategory.REVIEW_5:
            return [5];
        default:
            return [];
    }
}

export function fromAutomationWithCommentToCommentOptionValue(automationComment: ReviewReplyAutomationComment): CommentOptionValue {
    switch (automationComment) {
        case ReviewReplyAutomationComment.WITH_COMMENT:
            return CommentOptionValue.WITH;
        case ReviewReplyAutomationComment.WITHOUT_COMMENT:
            return CommentOptionValue.WITHOUT;
    }
}
