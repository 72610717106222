import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'flagPathResolver',
    standalone: true,
})
export class FlagPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/flags/${key.toLowerCase()}.png`;
    }
}
