<mat-checkbox
    class="w-full"
    [attr.data-testid]="testId()"
    [color]="color()"
    [class]="classInput()"
    [checked]="checked()"
    [disabled]="disabled()"
    [indeterminate]="indeterminate()"
    [ngClass]="disabled() ? 'opacity-50' : 'opacity-100'">
    <ng-content></ng-content>
</mat-checkbox>
