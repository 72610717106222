import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'applyPure',
    standalone: true,
})
export class ApplyPurePipe implements PipeTransform {
    transform<T extends (...args: any[]) => any>(fn: T, ...args: [...Parameters<T>, ...any[]]): ReturnType<T> {
        return fn(...args);
    }
}

@Pipe({
    name: 'applySelfPure',
    pure: true,
    standalone: true,
})
export class ApplySelfPurePipe implements PipeTransform {
    transform(value: any, fn: string, ...args: any[]): any {
        return value?.[fn]?.(...args);
    }
}
