import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, merge, skip } from 'rxjs';

import { PictureSize } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import { SidenavState } from ':modules/sidenav-router/store/sidenav.reducer';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { SidenavContentRestaurantInfoComponent } from '../sidenav-content-restaurant-info/sidenav-content-restaurant-info.component';
import { SidenavContentSelectRestaurantPanelComponent } from './sidenav-content-select-restaurant-panel/sidenav-content-select-restaurant-panel.component';

@Component({
    selector: 'app-sidenav-content-select-restaurant',
    templateUrl: './sidenav-content-select-restaurant.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        MatTooltipModule,
        NgTemplateOutlet,
        SidenavToggleButtonComponent,
        MatIconModule,
        TranslateModule,
        SidenavContentRestaurantInfoComponent,
        SidenavContentSelectRestaurantPanelComponent,
        ClickOutsideDirective,
    ],
})
export class SidenavContentSelectRestaurantComponent {
    readonly isEmptyState = input(false);
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly SvgIcon = SvgIcon;

    readonly isSidenavOpened = this._store.selectSignal(SidenavSelectors.selectIsOpened);
    readonly ownRestaurants$ = merge<SidenavState['ownRestaurants'][]>(
        this._store.select(SidenavSelectors.selectOwnRestaurants).pipe(skip(1)),
        this._store.select(selectOwnRestaurants).pipe(skip(1))
    ).pipe(filter((array) => array.length !== 0));
    readonly ownRestaurants = toSignal(this.ownRestaurants$, { initialValue: [] });
    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly isAllRestaurantsSelected = computed(() => this.selectedRestaurant() === null);

    readonly restaurantLogo = computed(() =>
        this.isAllRestaurantsSelected()
            ? (this.ownRestaurants()[0]?.logo?.urls?.[PictureSize.SMALL] ?? this.ownRestaurants()[0]?.logo?.urls?.[PictureSize.ORIGINAL])
            : this.selectedRestaurant()?.logo?.getMediaUrl('small')
    );

    readonly isSelectBusinessesPanelOpen = this._store.selectSignal(SidenavSelectors.selectIsSelectBusinessesPanelOpen);

    togglePanel(): void {
        this._store.dispatch(SidenavActions.toggleSelectBusinessesPanel());
    }
}
