export enum Day {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export const mapLuxonWeekdayToDay = (weekday: number): Day => {
    switch (weekday) {
        case 1:
            return Day.MONDAY;
        case 2:
            return Day.TUESDAY;
        case 3:
            return Day.WEDNESDAY;
        case 4:
            return Day.THURSDAY;
        case 5:
            return Day.FRIDAY;
        case 6:
            return Day.SATURDAY;
        case 7:
            return Day.SUNDAY;
        default:
            throw new Error(`Invalid weekday: ${weekday}`);
    }
};

export const DAYS: Day[] = Object.values(Day);

export const DAYS_FR = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

/**
 * Enum that represents a duration in minute(s).
 *
 * !!! Not precise, do not use for date computing !!!.
 */
export enum TimeInMinutes {
    MINUTE = 1,
    HOUR = 60,
    DAY = 24 * 60,
    WEEK = 7 * 24 * 60,
    MONTH = 30 * 24 * 60,
    YEAR = 365 * 24 * 60,
}

export enum TimeInSeconds {
    SECOND = 1,
    MINUTE = 60,
    HOUR = 3600,
    DAY = 24 * 3600,
    WEEK = 7 * 24 * 3600,
    MONTH = 31 * 24 * 3600,
    YEAR = 365 * 24 * 3600,
}

export enum TimeInMilliseconds {
    MILLISECOND = 1,
    SECOND = TimeInSeconds.SECOND * 1000,
    MINUTE = TimeInSeconds.MINUTE * 1000,
    HOUR = TimeInSeconds.HOUR * 1000,
    DAY = TimeInSeconds.DAY * 1000,
    WEEK = TimeInSeconds.WEEK * 1000,
    MONTH = TimeInSeconds.MONTH * 1000,
    YEAR = TimeInSeconds.YEAR * 1000,
}
