import { Component, Inject, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { RoiSettings } from ':shared/models/roi-settings.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { UpsertRoiSettingsComponent } from '../upsert-roi-settings/upsert-roi-settings.component';

@Component({
    selector: 'app-update-roi-settings-modal',
    templateUrl: './update-roi-settings-modal.component.html',
    styleUrls: ['./update-roi-settings-modal.component.scss'],
    standalone: true,
    imports: [TranslateModule, MatIconModule, UpsertRoiSettingsComponent],
})
export class UpdateRoiSettingsModalComponent {
    readonly SvgIcon = SvgIcon;
    constructor(
        private readonly _dialogRef: MatDialogRef<UpdateRoiSettingsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            roiSettings: WritableSignal<RoiSettings>;
        }
    ) {}

    close(needsToBeUpdated: boolean = false): void {
        this._dialogRef.close({ needsToBeUpdated });
    }
}
