<div class="flex w-full flex-col" [class.opacity-50]="control.disabled">
    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>

    <ng-container [ngTemplateOutlet]="selectContainerTemplate"></ng-container>

    <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
</div>

<ng-template #titleTemplate>
    @if (title || required || subtitle) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (required) {
                <span> *</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 ml-1 italic">{{ subtitle }}</span>
            }
        </div>
    }
</ng-template>

<ng-template #selectContainerTemplate>
    <div class="malou-border-dark rounded-md bg-malou-color-background-white">
        <mat-select
            class="malou-text-12--regular malou-color-text-2 simple-select-padding h-12.5"
            panelClass="custom-option-panel"
            [attr.data-testid]="testId()"
            [hideSingleSelectionIndicator]="true"
            [formControl]="control"
            [placeholder]="placeholder"
            (change)="onSelectChange($event)"
            #selectTrigger>
            @for (option of values; track option) {
                @let isOptionDisabled = disabledValuesWithTooltip | map: 'value' | includes: option;
                <div [matTooltip]="isOptionDisabled ? (getDisabledOptionTooltip | applyPure: option) : ''">
                    <mat-option [value]="option" [disabled]="isOptionDisabled">
                        @if (optionTemplate) {
                            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option }"></ng-container>
                        } @else if (displayWith) {
                            {{ displayWith | applyPure: option }}
                        } @else {
                            {{ option }}
                        }
                    </mat-option>
                </div>
            }
            @if (selectedValueTemplate) {
                <mat-select-trigger>
                    <ng-container
                        [ngTemplateOutlet]="selectedValueTemplate"
                        [ngTemplateOutletContext]="{ value: control.value }"></ng-container>
                </mat-select-trigger>
            }
        </mat-select>
    </div>
</ng-template>

<ng-template #errorTemplate>
    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
</ng-template>
