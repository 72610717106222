<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [selectedValues]="selectedValues()"
    [displayWith]="displayWith()"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [groupSelectedValuesAtTop]="false"
    (selectBaseChange)="selectPlatformsChange.emit($event)">
    <ng-template let-value="value" #selectedValueTemplate>
        <app-platform-logo imgClasses="h-9 w-9" [logo]="value"></app-platform-logo>
    </ng-template>
    <ng-template let-value="value" #optionTemplate>
        <span class="flex items-center">
            <app-platform-logo imgClasses="h-8 w-8" [logo]="value" [withLazyLoading]="true"></app-platform-logo>
            <span>{{ displayWith() | applyPure: value }}</span>
        </span>
    </ng-template>
</app-select-base>
