@if (isPhoneScreen()) {
    <div class="w-full pb-1" [class.show-advanced-filters]="showAdvancedFilters">
        <div class="mb-2 w-full items-center gap-x-4 md:flex">
            <ng-container *ngTemplateOutlet="isNeverUsedToggleButton"></ng-container>
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [disabled]="!hasSelectedMedias && !hasSelectedFolders"
                [matMenuTriggerFor]="actionsMenu">
                <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
            </button>
            @if (!currentFolder) {
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="onCreateFolder()">
                    <mat-icon [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                </button>
            }
            <button class="malou-btn-icon--primary btn-xl" mat-icon-button (click)="onUploadFile()">
                <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
            </button>
        </div>
        <button class="malou-btn-flat !px-0" mat-button (click)="toggleAdvancedFilters()">
            {{ 'common.advanced_options' | translate }}
            <mat-icon class="chevron-icon !h-[16px] !w-[16px]" iconPositionEnd [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </button>
        <div class="search mt-2 h-0 grow opacity-0">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearch($event)"></app-search>
        </div>
    </div>
} @else {
    <div class="flex w-full items-center gap-x-4 pb-5">
        <div class="grow">
            <app-search
                [placeholder]="
                    currentFolder
                        ? ('gallery.actions_header.search_folder' | translate: { folder: currentFolder.name })
                        : ('gallery.actions_header.search' | translate)
                "
                (searchChange)="onSearch($event)"></app-search>
        </div>
        <ng-container *ngTemplateOutlet="isNeverUsedToggleButton"></ng-container>
        <div
            [matTooltip]="
                !hasSelectedMedias && !hasSelectedFolders
                    ? ('gallery.must_select_items_before_duplicate' | translate)
                    : ('common.duplicate' | translate)
            ">
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [matMenuTriggerFor]="duplicateMenu"
                [disabled]="!hasSelectedMedias && !hasSelectedFolders">
                <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
            </button>
        </div>
        <div [matTooltip]="moveActionTooltip()">
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [disabled]="!hasSelectedMedias || hasSelectedFolders"
                (click)="move()">
                <mat-icon [svgIcon]="SvgIcon.SYNC_ALT"></mat-icon>
            </button>
        </div>
        <div
            [matTooltip]="
                !hasSelectedMedias && !hasSelectedFolders
                    ? ('gallery.must_select_items_before_download' | translate)
                    : ('gallery.download' | translate)
            ">
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [disabled]="!hasSelectedMedias && !hasSelectedFolders"
                (click)="download()">
                <mat-icon [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
            </button>
        </div>
        <div
            [matTooltip]="
                !hasSelectedMedias && !hasSelectedFolders
                    ? ('gallery.must_select_items_before_delete' | translate)
                    : ('common.delete' | translate)
            ">
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [disabled]="!hasSelectedMedias && !hasSelectedFolders"
                (click)="delete()">
                <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
            </button>
        </div>
        @if (!currentFolder) {
            <button class="malou-btn-raised--secondary--alt btn-xl !h-12.5" mat-raised-button (click)="onCreateFolder()">
                {{ 'gallery.folders.create' | translate }}
            </button>
        }
        <button class="malou-btn-raised--primary btn-xl !h-12.5" mat-raised-button (click)="onUploadFile()">
            {{ 'gallery.import_media' | translate }}
        </button>
    </div>
}

<ng-template #isNeverUsedToggleButton>
    <div>
        <mat-button-toggle-group
            class="malou-group-toggle-btn"
            [value]="(filters$ | async)?.isNeverUsed"
            (change)="onEditNeverPosterOnlyFilter($event)">
            <mat-button-toggle [value]="false">
                {{ 'media_picker_modal.all_media' | translate }}
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
                {{ 'media_picker_modal.never_posted_only' | translate }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow" #actionsMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="duplicateMenu" [disabled]="!hasSelectedMedias && !hasSelectedFolders">
        <mat-icon class="malou-color-primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
        <span class="ml-2">{{ 'common.duplicate' | translate }}</span>
    </button>
    <button mat-menu-item [disabled]="!hasSelectedMedias && !hasSelectedFolders" (click)="download()">
        <mat-icon class="malou-color-primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
        <span class="ml-2">{{ 'gallery.download' | translate }}</span>
    </button>
    <button mat-menu-item [disabled]="!hasSelectedMedias || hasSelectedFolders" (click)="move()">
        <mat-icon class="malou-color-primary" [svgIcon]="SvgIcon.SYNC_ALT"></mat-icon>
        <span class="ml-2">{{ 'common.move' | translate }}</span>
    </button>
    <button mat-menu-item [disabled]="!hasSelectedMedias && !hasSelectedFolders" (click)="delete()">
        <mat-icon class="malou-color-primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        <span class="ml-2">{{ 'gallery.delete' | translate }}</span>
    </button>
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
    <button mat-menu-item (click)="duplicate(DuplicationDestination.HERE)">
        <span class="malou-text-14--regular">
            {{ 'gallery.duplicate_here' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="duplicate(DuplicationDestination.OUT)">
        <span class="malou-text-14--regular">
            {{ 'common.to_other_venues' | translate }}
        </span>
    </button>
</mat-menu>
