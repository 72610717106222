import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MapstrCtaButtonType } from '@malou-io/package-utils';

import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-social-post-cta',
    templateUrl: './social-post-cta.component.html',
    styleUrls: ['./social-post-cta.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, InputTextComponent, SimpleSelectComponent],
    providers: [EnumTranslatePipe],
})
export class SocialPostCtaComponent {
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);

    readonly postCta = computed(
        ():
            | {
                  actionType: MapstrCtaButtonType;
                  url: string;
              }
            | undefined => this._upsertSocialPostContext.upsertSocialPostState.post().callToAction
    );
    readonly actionType = computed((): MapstrCtaButtonType | undefined => this.postCta()?.actionType);
    readonly url = computed((): string | undefined => this.postCta()?.url);

    readonly urlErrorMessage = computed((): string | undefined => {
        const url = this.url();
        if (!url) {
            return this._translateService.instant('common.required_field');
        }
        return !url.startsWith('https://')
            ? this._translateService.instant('social_posts.upsert_social_post_modal.content_form.cta.errors.bad_url')
            : undefined;
    });

    readonly MapstrCtaButtonTypeValues = [null, ...Object.values(MapstrCtaButtonType)];
    readonly SvgIcon = SvgIcon;

    mapstrCtaButtonTypeDisplayWith = (mapstrCtaButtonType: MapstrCtaButtonType | null): string =>
        mapstrCtaButtonType ? this._enumTranslatePipe.transform(mapstrCtaButtonType, 'mapstr_cta_button_type') : '--';

    onActionTypeChange(actionType: MapstrCtaButtonType | null): void {
        this._upsertSocialPostContext.updateCtaActionType(actionType);
    }

    onUrlChange(url: string): void {
        this._upsertSocialPostContext.updateCtaUrl(url);
    }
}
