import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { Restaurant } from ':shared/models';

import * as ReviewsSelector from './store/reviews.selectors';

@Injectable({
    providedIn: 'root',
})
export class ReviewsContext {
    private readonly _store = inject(Store);

    private readonly _destroyRef = inject(DestroyRef);

    readonly selectedRestaurants$ = combineLatest([
        this._store.select(ReviewsSelector.selectRestaurantsFilter),
        this._store.select(selectOwnRestaurants),
    ]).pipe(
        filter(([_, userRestaurants]: [string[], Restaurant[]]) => !!userRestaurants.length),
        map(
            ([selectedRestaurantsIds, userRestaurants]: [string[], Restaurant[]]) =>
                selectedRestaurantsIds.map((restaurantId) => userRestaurants.find((r) => r._id === restaurantId)).filter(isNotNil) ?? []
        ),
        takeUntilDestroyed(this._destroyRef)
    );
}
