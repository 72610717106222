import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { CommentsFilters, MalouDateFilters, MalouPeriod } from ':shared/models';

import * as CommentsActions from './comments.actions';

export interface CommentsState {
    unansweredCommentCount: number;
    unansweredMentionCount: number;
    commentsFilters: CommentsFilters;
    replies: any;
    areFiltersDefault: boolean;
    pagination: {
        pageSize: number;
    };
    commentCount: number;
    mentionCount: number;
}

const dateFilters = new MalouDateFilters();

export const initialState: CommentsState = {
    unansweredCommentCount: 0,
    unansweredMentionCount: 0,
    commentsFilters: {
        platforms: [],
        ...dateFilters.getFilter({ period: MalouPeriod.LAST_SIX_MONTHS }),
        sortBy: 'date',
        sortOrder: -1,
        text: '',
        answered: true,
        notAnswered: true,
        withoutOwnComment: true,
        archived: false,
        isComment: true,
        isMention: true,
    },
    areFiltersDefault: true,
    replies: {},
    pagination: {
        pageSize: 24,
    },
    commentCount: 0,
    mentionCount: 0,
};

const areFiltersDefault = (filters: CommentsFilters): boolean => JSON.stringify(filters) === JSON.stringify(initialState.commentsFilters);

const commentsReducer = createReducer(
    initialState,
    on(CommentsActions.editCommentsFilters, (state, { filterDiffs }) => {
        const newFilters = {
            ...state.commentsFilters,
            ...filterDiffs,
        };
        return {
            ...state,
            commentsFilters: newFilters,
            areFiltersDefault: areFiltersDefault(newFilters),
        };
    }),
    on(CommentsActions.resetCommentsFilters, (state, { availablePlatforms }) => ({
        ...state,
        commentsFilters: { ...initialState.commentsFilters, platforms: availablePlatforms },
        areFiltersDefault: true,
    })),
    on(CommentsActions.editUnansweredCommentCount, (state, { unansweredCommentCount }) => ({
        ...state,
        unansweredCommentCount: unansweredCommentCount,
    })),
    on(CommentsActions.editUnansweredMentionCount, (state, { unansweredMentionCount }) => ({
        ...state,
        unansweredMentionCount: unansweredMentionCount,
    })),
    on(CommentsActions.editPagination, (state, { pagination }) => ({
        ...state,
        pagination,
    })),
    on(CommentsActions.editCommentCount, (state, { commentCount }) => ({
        ...state,
        commentCount,
    })),
    on(CommentsActions.editMentionCount, (state, { mentionCount }) => ({
        ...state,
        mentionCount,
    }))
);

export const selectCommentsState = createFeatureSelector<CommentsState>('comments');

export const selectUnansweredCommentCount = createSelector(selectCommentsState, (state: CommentsState) => state.unansweredCommentCount);

export const selectUnansweredMentionCount = createSelector(selectCommentsState, (state: CommentsState) => state.unansweredMentionCount);

export const selectAreFiltersDefault = createSelector(selectCommentsState, (state: CommentsState) => state.areFiltersDefault);

export const selectCommentsFilters = createSelector(selectCommentsState, (state: CommentsState) => state.commentsFilters);

export const selectPagination = createSelector(selectCommentsState, (state: CommentsState) => state.pagination);

export const selectCommentCount = createSelector(selectCommentsState, (state: CommentsState) => state.commentCount);
export const selectMentionCount = createSelector(selectCommentsState, (state: CommentsState) => state.mentionCount);

export function reducer(state: CommentsState | undefined, action: Action): CommentsState {
    return commentsReducer(state, action);
}
