<div class="h-screen w-screen overflow-y-auto py-4">
    @if (!isError) {
        @if (data$ | async; as data) {
            @if (!isRedirectionInProgress) {
                @if (!showPrivateReviewPage) {
                    <ng-container [ngTemplateOutlet]="pageTemplate" [ngTemplateOutletContext]="{ restaurant: data.nfc.restaurant }">
                    </ng-container>
                }
                @if (showPrivateReviewPage) {
                    <ng-container
                        [ngTemplateOutlet]="privateReviewTemplate"
                        [ngTemplateOutletContext]="{ restaurant: data.nfc.restaurant, scan: data.scan }">
                    </ng-container>
                }
            } @else {
                <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
                    {{ 'scan.redirection_in_progress' | translate }}
                </div>
            }
        } @else {
            <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
                <app-malou-spinner></app-malou-spinner>
            </div>
        }
    } @else {
        <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
            <div><img class="w-24" [src]="Illustration.Cook | illustrationPathResolver" /></div>
            <div>{{ 'scan.error' | translate }}</div>
        </div>
    }
</div>

<ng-template let-restaurant="restaurant" #pageTemplate>
    <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
        <div>
            <img
                class="h-24 w-24 rounded-full"
                alt="logo"
                [src]="restaurant.logoPopulated?.urls?.original || ('default_logo' | imagePathResolver)" />
        </div>
        <div class="malou-text-32--bold malou-color-text-1 text-center">
            {{ getTemplateTitle | applyPure: restaurant }}
        </div>
        <div class="malou-text-13--regular malou-color-text-2 text-center">
            {{ getTemplateDescription | applyPure: restaurant }}
        </div>
        <div class="mt-8 h-10 w-60">
            <app-rate-with-stars (starClicked)="onStarClicked($event)"></app-rate-with-stars>
        </div>
    </div>
</ng-template>

<ng-template let-restaurant="restaurant" let-scan="scan" #privateReviewTemplate>
    <app-scan-private-review [params]="{ restaurantId: restaurant._id, rating: starNumber, scanId: scan.id }"></app-scan-private-review>
</ng-template>
