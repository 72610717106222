import { TranslateService } from '@ngx-translate/core';
import { assignWith, cloneDeep, isNil, omit } from 'lodash';

import {
    AggregationTimeScale,
    BusinessCategory,
    MalouErrorCode,
    MalouMetric,
    PartialRecord,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

export interface InsightError {
    error?: boolean;
    message?: string;
}

export interface Value {
    value: number;
}

export type DailyValue = {
    date: string;
} & Value;

export type WeeklyValue = {
    weekStart: string;
} & Value;

export type MonthlyValue = {
    monthStart: string;
} & Value;

export type MetricToDataValue<DateValue extends Value | DailyValue | WeeklyValue | MonthlyValue = DailyValue> = {
    [K in MalouMetric]?: DateValue;
} & InsightError;

export type MetricToDataValues<DateValue extends Value | DailyValue | WeeklyValue | MonthlyValue> = {
    [K in MalouMetric]?: DateValue[];
} & InsightError;

export type TimeScaleToMetricToDataValues = {
    [AggregationTimeScale.TOTAL]?: MetricToDataValue<Value>;
    [AggregationTimeScale.BY_DAY]?: MetricToDataValues<DailyValue>;
    [AggregationTimeScale.BY_WEEK]?: MetricToDataValues<WeeklyValue>;
    [AggregationTimeScale.BY_MONTH]?: MetricToDataValues<MonthlyValue>;
} & InsightError;

export type InsightsByPlatform = PartialRecord<PlatformKey, TimeScaleToMetricToDataValues>;
export type InsightsByPlatformByRestaurant = Record<string, InsightsByPlatform>;

export interface SocialPageInsights {
    impressions?: number;
    engagements?: number;
    followers?: number;
    posts?: number;
    engagementRate?: number | null;
}

export interface SocialPlatformsInsights {
    facebook?: SocialPageInsights;
    instagram?: SocialPageInsights;
    restaurant?: {
        _id: string;
        name: string;
        type: string;
    };
}

export interface MappedInsights {
    current: SocialPageInsights;
    diff: SocialPageInsights;
}

export interface CombinedSocialPageInsights {
    facebook?: SocialPageInsights | { error: boolean; message: string };
    instagram?: SocialPageInsights | { error: boolean; message: string };
    restaurant?: {
        _id: string;
        name: string;
        type: string;
    };
}

export function getInsightsErrorText(message?: string): string {
    if (!message || typeof message !== 'string') {
        return 'aggregated_statistics.social_networks.unknown_error';
    }
    if (message.match(MalouErrorCode.PLATFORM_NOT_FOUND)) {
        return 'aggregated_statistics.social_networks.platform_not_connected';
    }
    if (message.match(MalouErrorCode.PLATFORM_CREDENTIAL_NOT_FOUND)) {
        return 'aggregated_statistics.social_networks.platform_not_connected';
    }
    if (message.match(MalouErrorCode.PLATFORM_MISSING_PERMISSIONS)) {
        return 'aggregated_statistics.social_networks.missing_permissions';
    }
    if (message.match(/missing_platform_permissions/)) {
        return 'aggregated_statistics.social_networks.missing_permissions';
    }
    if (message.match(/not_authorized/)) {
        return 'aggregated_statistics.social_networks.missing_permissions';
    }
    if (message.match(/no_insights_found/)) {
        return 'aggregated_statistics.social_networks.no_insights_found';
    }
    if (message?.match(MalouErrorCode.QUERY_VALIDATION_ERROR) || message.match(/platform_required/)) {
        return 'statistics.social_network.errors.change_filters';
    }
    if (message.match(/There cannot be more than/)) {
        return 'statistics.social_network.errors.time_range_too_long';
    }
    return 'aggregated_statistics.social_networks.unknown_error';
}

export class DiffCombinedSocialPageInsights {
    restaurantName: string | null;
    restaurantId: string | null;
    type: string | null;
    chartAxisName: string;

    errors: { platformKey: string; error: boolean; message: string }[] = [];

    current: SocialPageInsights;

    difference: SocialPageInsights | undefined;

    firstPlatformTranslatedError: string | null;

    public constructor(
        readonly currentCombined: CombinedSocialPageInsights,
        readonly previousCombined: CombinedSocialPageInsights | undefined,
        private readonly _translate: TranslateService
    ) {
        this.restaurantName = currentCombined.restaurant?.name ?? null;
        this.restaurantId = currentCombined.restaurant?._id ?? null;
        this.type = currentCombined.restaurant?.type ?? null;
        this.chartAxisName = currentCombined.restaurant?.name + ':ID:' + currentCombined.restaurant?._id;

        this._storeErrors(currentCombined);

        this.firstPlatformTranslatedError = this._getFirstPlatformTranslatedError();

        if (this.errors.length) {
            return;
        }

        const currentMergedInsights: SocialPageInsights = this._computeSocialPageInsightFromCombinedSocialPageInsights(currentCombined);

        this.current = currentMergedInsights;

        if (previousCombined) {
            const previousMergedInsights: SocialPageInsights =
                this._computeSocialPageInsightFromCombinedSocialPageInsights(previousCombined);

            this.difference = this._computeDifference(currentMergedInsights, previousMergedInsights);
        }
    }

    // It is used, do not remove
    public hasErrors(): boolean {
        return !!this.errors.length;
    }

    // It is used, do not remove
    public isBrandBusiness(): boolean {
        return this.type === BusinessCategory.BRAND;
    }

    private _getFirstPlatformTranslatedError(): string | null {
        if (!this.errors.length) {
            return null;
        }
        const message = this.errors[0].message;
        const translatedError = getInsightsErrorText(message);
        const platformName = PlatformDefinitions.getPlatformDefinition(this.errors[0].platformKey)?.fullName;
        return this._translate.instant(translatedError, { platformName: platformName });
    }

    /**
     * Compute the difference between two SocialPageInsights.
     */
    private _computeDifference(current: SocialPageInsights, previous: SocialPageInsights): SocialPageInsights {
        const currentCopy = cloneDeep(current);
        const difference: SocialPageInsights = assignWith(currentCopy, previous, (a, b, _key) => {
            if (isNil(a) || isNil(b)) {
                return null;
            }
            return a - b;
        });
        return difference;
    }

    /**
     * Merge multiple SocialPageInsights object into one with added values.
     *
     * The idea with the undefined check is to keep the merged value to undefined if all values are undefined.
     * If at least one value is not undefined, we consider that undefined values are 0 (-> ignoring them).
     *
     * We do this to separate the following cases :
     *      - a stat in the merged object is equal to undefined : we have no data
     *      - a stat in the merged object is equal to number : we have at least one platform that send us data
     */
    private _computeAddition(socialPageInsights: SocialPageInsights[]): SocialPageInsights {
        const firstObjectCopy = cloneDeep(socialPageInsights[0]);
        const mergeObject: SocialPageInsights = assignWith(firstObjectCopy, ...socialPageInsights.slice(1), (a, b, _key) => {
            if (isNil(a) && isNil(b)) {
                return null;
            }
            return (a ?? 0) + (b ?? 0);
        });
        return mergeObject;
    }

    private _storeErrors(values: CombinedSocialPageInsights): void {
        for (const [key, value] of Object.entries(values)) {
            if (!!value['error']) {
                const v = value as { error: boolean; message: string };
                this.errors.push({ ...v, platformKey: key });
            }
        }
    }

    private _computeSocialPageInsightFromCombinedSocialPageInsights(
        combinedSocialPageInsights: CombinedSocialPageInsights
    ): SocialPageInsights {
        const combinedWithoutRestaurant: Omit<CombinedSocialPageInsights, 'restaurant'> = omit(combinedSocialPageInsights, 'restaurant');
        const insightsArray: SocialPageInsights[] = Object.values(combinedWithoutRestaurant) as SocialPageInsights[];
        const mergedInsights: SocialPageInsights = this._computeAddition(insightsArray);
        const engagementRate = this._computeEngagementRate(mergedInsights);
        mergedInsights.engagementRate = engagementRate;
        return mergedInsights;
    }

    private _computeEngagementRate(socialPageInsights: SocialPageInsights): number | null {
        // engagement rate = ((total likes + total comments + total shares) / nb followers / nb posts) * 100
        if (socialPageInsights.engagements && socialPageInsights.followers && socialPageInsights.posts) {
            return (socialPageInsights.engagements / socialPageInsights.followers / socialPageInsights.posts) * 100;
        }
        return null;
    }
}
