import { ClipboardModule } from '@angular/cdk/clipboard';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FolderRenamingModalComponent } from '../modals/folder-renaming-modal/folder-renaming-modal.component';

@Component({
    selector: 'app-gallery-folder',
    templateUrl: './gallery-folder.component.html',
    styleUrls: ['./gallery-folder.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ClipboardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryFolderComponent {
    @Input() folder: IFolder;
    @Input() isSelected: boolean;
    @Output() toggleFolderSelection: EventEmitter<IFolder> = new EventEmitter();
    @Output() deleteFolder: EventEmitter<IFolder> = new EventEmitter();
    @Output() folderRenamed: EventEmitter<IFolder> = new EventEmitter();
    @Output() downloadFolder: EventEmitter<IFolder> = new EventEmitter();
    @Output() duplicateFolder: EventEmitter<{ to: DuplicationDestination; folder: IFolder }> = new EventEmitter();
    @Output() openFolder: EventEmitter<string> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly DuplicationDestination = DuplicationDestination;

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    toggleSelection(): void {
        this.toggleFolderSelection.emit(this.folder);
    }

    onFolderClick(): void {
        this.openFolder.emit(this.folder.id);
    }

    renameFolder(): void {
        this._customDialogService
            .open(FolderRenamingModalComponent, {
                width: this.screenSizeService.isPhoneScreen ? '100%' : '600px',
                height: 'unset',
                data: { folder: this.folder },
            })
            .afterClosed()
            .subscribe({
                next: (value?: IFolder) => {
                    if (value) {
                        this.folderRenamed.emit(value);
                    }
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._translateService.instant('gallery.there_is_error') + String(err));
                },
            });
    }

    onDownloadFolderClick(): void {
        this.downloadFolder.emit(this.folder);
    }

    getFolderLink = (): string => {
        const currentUrl = window.location.href;
        return `${currentUrl}?folderId=${this.folder.id}&sharedLink=true`;
    };

    onCopyToClipboard(success: boolean): void {
        if (success) {
            this._toastService.openSuccessToast(this._translateService.instant('gallery.copy_to_clipboard.success'));
        } else {
            this._toastService.openErrorToast(this._translateService.instant('gallery.copy_to_clipboard.fail'));
        }
    }

    onDeleteFolder(): void {
        this.deleteFolder.emit(this.folder);
    }

    onDuplicateFolder(to: DuplicationDestination, folder: IFolder): void {
        this.duplicateFolder.emit({ to, folder });
    }
}
