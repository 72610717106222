import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from ':environments/environment';
import { ApiResult } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class GoogleService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/google`;

    constructor(private readonly _httpClient: HttpClient) {}

    accountLocations(accountId: string, credentialId?: string): Observable<ApiResult> {
        return this._httpClient.get<ApiResult>(`${this.API_BASE_URL}/locations/${credentialId}`, {
            params: { account_id: accountId },
        });
    }

    listMediasByLocId(accountId: string, locationId: string, all: boolean): Observable<ApiResult> {
        return this._httpClient.get<ApiResult>(`${this.API_BASE_URL}/locations/${locationId}/media`, {
            params: { account_id: accountId, all: all.toString() },
        });
    }

    getLocationAttributes(locationId: string, credentialId: string): Observable<ApiResult> {
        return this._httpClient.get<ApiResult>(`${this.API_BASE_URL}/locations/${locationId}/attributes?credentialId=${credentialId}`);
    }

    accounts(credentialId?: string): Observable<ApiResult> {
        return this._httpClient.get<ApiResult>(`${this.API_BASE_URL}/accounts/${credentialId}`);
    }
}
