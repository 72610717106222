import {
    RestaurantAiSettingsDto,
    UpdateRestaurantAiGeneralSettingsBodyDto,
    UpdateRestaurantAiPostSettingsBodyDto,
    UpdateRestaurantAiReviewSettingsBodyDto,
} from '@malou-io/package-dto';
import {
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    ApplicationLanguage,
    CustomerNaming,
    FrenchTutoiementVouvoiement,
    RemoveMethodsFromClass,
} from '@malou-io/package-utils';

export enum RestaurantAiSettingsDispatch {
    general = 'general',
    post = 'post',
    review = 'review',
}

export class RestaurantAiSettings {
    restaurantId: string;
    restaurantName: string;
    defaultLanguageResponse: ApplicationLanguage;

    reviewSettings: {
        replyTone: FrenchTutoiementVouvoiement;
        customerNaming: CustomerNaming;
        restaurantKeywordIds: string[];
        forbiddenWords: string[];
        catchphrase?: string;
        shouldTranslateCatchphrase: boolean;
        signatures: string[];
        shouldTranslateSignature: boolean;
        signatureTranslationIds?: string[];
    };

    postSettings: {
        seo: {
            denomination: FrenchTutoiementVouvoiement;
            tone: AiPostSettingsTone[];
            length: AiPostSettingsLength;
            emoji: AiPostGenerationEmojiStatus;
            prompt?: string | null;
        };
        social: {
            denomination: FrenchTutoiementVouvoiement;
            tone: AiPostSettingsTone[];
            length: AiPostSettingsLength;
            emoji: AiPostGenerationEmojiStatus;
            prompt?: string | null;
        };
    };

    constructor(init: RemoveMethodsFromClass<RestaurantAiSettings>) {
        this.restaurantId = init.restaurantId;
        this.restaurantName = init.restaurantName;
        this.defaultLanguageResponse = init.defaultLanguageResponse;
        this.postSettings = init.postSettings ?? null;
        this.reviewSettings = init.reviewSettings ?? null;
    }

    static fromRestaurantAiSettingsDto(dto: RestaurantAiSettingsDto): RestaurantAiSettings {
        return new RestaurantAiSettings({
            restaurantId: dto.restaurantId,
            restaurantName: dto.restaurantName,
            defaultLanguageResponse: dto.defaultLanguageResponse,
            postSettings: dto.postSettings,
            reviewSettings: dto.reviewSettings,
        });
    }

    toGeneralSettingsUpdateDto(): UpdateRestaurantAiGeneralSettingsBodyDto {
        return {
            restaurantName: this.restaurantName,
            defaultLanguageResponse: this.defaultLanguageResponse,
        };
    }

    toPostSettingsUpdateDto(): UpdateRestaurantAiPostSettingsBodyDto {
        return {
            postSettings: this.postSettings,
        };
    }

    toReviewSettingsUpdateDto(): UpdateRestaurantAiReviewSettingsBodyDto {
        return {
            reviewSettings: this.reviewSettings,
        };
    }
}

export interface AiSettingsSignatureWithId {
    id: string;
    text: string;
}
