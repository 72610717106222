import { NgClass } from '@angular/common';
import { Component, inject, input, InputSignal, OnInit, output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, MatPaginatorSelectConfig, PageEvent } from '@angular/material/paginator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PaginatorPagesRangeDirective } from ':shared/directives/pages-range-paginator.directive';

const DEFAULT_MAT_PAGINATOR_SELECT_CONFIG: MatPaginatorSelectConfig = {
    panelClass: 'custom-option-panel',
};

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule, MatPaginatorModule, PaginatorPagesRangeDirective],
})
export class PaginatorComponent implements OnInit {
    @ViewChild(MatPaginator) matPaginator: MatPaginator;
    readonly pageSize: InputSignal<number> = input.required();
    readonly pageSizeOptions: InputSignal<number[]> = input.required();
    readonly pageIndex: InputSignal<number> = input(0);
    readonly length: InputSignal<number> = input(0);
    readonly shouldBeHidden: InputSignal<boolean> = input(false);
    readonly pageEvent = output<PageEvent>();

    readonly matPaginatorSelectConfig = DEFAULT_MAT_PAGINATOR_SELECT_CONFIG;

    private readonly _translateService = inject(TranslateService);
    private readonly _matPaginatorIntl = inject(MatPaginatorIntl);

    ngOnInit(): void {
        this._matPaginatorIntl.itemsPerPageLabel = this._translateService.instant('common.items_per_page');
    }

    onPageEvent(event: PageEvent): void {
        this.pageEvent.emit(event);
    }
}
