import { Component, computed, effect, EventEmitter, Output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { GmbActionsComponent } from ':modules/statistics/seo/gmb-actions/gmb-actions.component';
import { PlatformsRatingsComponent } from ':shared/components/platforms-ratings/platforms-ratings.component';

import { ReviewRatingsAverageKpiComponent } from '../review-ratings-average-kpi/review-ratings-average-kpi.component';

@Component({
    selector: 'app-statistics-seo-ratings',
    templateUrl: './ratings.component.html',
    styleUrls: ['./ratings.component.scss'],
    standalone: true,
    imports: [PlatformsRatingsComponent, ReviewRatingsAverageKpiComponent, TranslateModule, GmbActionsComponent],
})
export class StatisticsSeoRatingsComponent {
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    isPlatformRatingsLoading = signal(true);
    isReviewRatingsLoading = signal(true);

    isLoading = computed(() => this.isPlatformRatingsLoading() || this.isReviewRatingsLoading());

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }
}
