import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { InformationUpdateState } from ':modules/informations/informations-updates-state/information-update-state.interface';
import { InformationsContext } from ':modules/informations/informations.context';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { InformationUpdatesStateModalComponent } from './information-updates-state-modal/information-updates-state-modal.component';

@Component({
    selector: 'app-informations-updates-state',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, FormatDatePipe, PluralTranslatePipe],
    templateUrl: './informations-updates-state.component.html',
    styleUrl: './informations-updates-state.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationsUpdatesStateComponent {
    readonly SvgIcon = SvgIcon;
    readonly informationUpdateOthers = signal<InformationUpdateState[]>([]);
    readonly informationUpdateErrors = signal<InformationUpdateState[]>([]);

    private readonly _customDialogService = inject(CustomDialogService);
    readonly informationsContext = inject(InformationsContext);

    openUpdateStateModal(): void {
        const states = this.informationsContext.restaurantInformationUpdateStates();

        if (!states) {
            return;
        }

        this._customDialogService
            .open(InformationUpdatesStateModalComponent, {
                width: '100%',
                panelClass: 'malou-dialog-panel--full',
                height: undefined,
                data: {
                    informationUpdateOthers: states.others,
                    informationUpdateErrors: states.errors,
                    isAboutToSendUpdates: this.informationsContext.isAboutToSendUpdates(),
                    latestValidatedAt: states.latestValidatedAt,
                },
            })
            .afterClosed()
            .subscribe({
                next: () => {},
            });
    }
}
