import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'isPrivate',
    standalone: true,
})
export class IsPrivatePipe implements PipeTransform {
    transform(review: Review | PrivateReview): boolean {
        return review.isPrivate();
    }
}
