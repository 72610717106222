import { Component, computed, effect, inject, input, OnInit, output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { of } from 'rxjs/internal/observable/of';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { CredentialsService } from ':core/services/credentials.service';
import { WHITELISTED_PAGE_IDS } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { FbLocation } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-social-post-place',
    templateUrl: './social-post-place.component.html',
    styleUrls: ['./social-post-place.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        ApplyPurePipe,
        TranslateModule,
    ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'malou-autocomplete-panel' },
        },
    ],
})
export class SocialPostPlaceComponent implements OnInit {
    readonly platformSocialId = input.required<string | null>();
    readonly isDisabled = input<boolean>(false);
    readonly isLoadingLocation = input<boolean>(false);
    readonly location = input<FbLocation | null>(null);

    readonly locationChange = output<FbLocation | null>();

    readonly SvgIcon = SvgIcon;

    readonly locationCtrl = new FormControl<string>({ value: '', disabled: this.isDisabled() });

    readonly locationOptions = signal<FbLocation[]>([]);
    readonly isLoadingHere = signal(false);
    readonly isLoading = computed((): boolean => this.isLoadingHere() || this.isLoadingLocation());

    private readonly _credentialService = inject(CredentialsService);

    constructor() {
        effect(() => {
            const location = this.location();
            const locationName = location?.name ?? '';
            this.locationCtrl.setValue(locationName);
        });

        effect(() => {
            if (!this.platformSocialId() || this.isDisabled()) {
                this.locationCtrl.disable();
            } else {
                this.locationCtrl.enable();
            }
        });
    }

    ngOnInit(): void {
        this.locationCtrl.valueChanges
            .pipe(
                map((query) => query?.toLowerCase().trim()),
                distinctUntilChanged(),
                filter((query) => query?.length === 0 || (query?.length ?? 0) >= 3),
                tap(() => this.isLoadingHere.set(true)),
                debounceTime(500),
                switchMap((query) => {
                    if (!query || query.length === 0 || !this.platformSocialId()) {
                        const data: FbLocation[] = [];
                        return of({ data });
                    }
                    // Special case, we whitelist the "Very French Beans" page because it does not contain location
                    return this._credentialService.pagesSearch(query, {
                        onlyWithLocation: true,
                        whitelistedPageIds: WHITELISTED_PAGE_IDS,
                    });
                }),
                map((res) => res.data)
            )
            .subscribe((locations) => {
                this.locationOptions.set(locations);
                this.isLoadingHere.set(false);
            });

        this.locationCtrl.valueChanges.subscribe((value) => {
            if (!value) {
                this.locationChange.emit(null);
            }
        });
    }

    remove(): void {
        this.locationCtrl.setValue('');
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const { value } = event.option;
        const displayedValue = `${value.name}${value.location ? ' - ' + this.displayLocationOptionAddress(value) : ''}`;
        this.locationCtrl.setValue(displayedValue);
        this.locationChange.emit(value);
    }

    displayLocationOptionAddress(locationOption: FbLocation): string {
        const street = locationOption.location?.street;
        const zipAndCity = compact([locationOption.location?.zip, locationOption.location?.city]).join(' ');
        const country = locationOption.location?.country;
        const addressAsArray = compact([street, zipAndCity, country]);

        return addressAsArray.join(', ');
    }
}
