import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Injector,
    input,
    Input,
    TemplateRef,
} from '@angular/core';

import { TooltipPosition } from '@malou-io/package-utils';

import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';

@Directive({
    selector: '[appCustomTooltip]',
    standalone: true,
})
export class CustomTooltipDirective {
    @Input('appCustomTooltip') content: TemplateRef<any> | string;
    readonly position = input<TooltipPosition>(TooltipPosition.TOP);

    private _tooltipRef!: ComponentRef<CustomTooltipComponent> | null;
    private _showTimeoutId!: number | null;
    private _hideTimeoutId!: number | null;
    private readonly _TRANSITION_DELAY = 200;

    constructor(
        private _elementRef: ElementRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _appRef: ApplicationRef,
        private _injector: Injector
    ) {}

    @HostListener('mouseenter')
    onMouseEnter(): void {
        if (this._hideTimeoutId) {
            clearTimeout(this._hideTimeoutId);
            this._hideTimeoutId = null;
        }

        if (this._tooltipRef) {
            return;
        }

        this._showTimeoutId = window.setTimeout(() => {
            const factory = this._componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent);
            this._tooltipRef = factory.create(this._injector);

            const instance = this._tooltipRef.instance;
            instance.content = this.content;
            instance.position = this.position();

            document.body.appendChild(this._tooltipRef.location.nativeElement);

            const target = this._elementRef.nativeElement;
            instance.setPosition(target);

            this._appRef.attachView(this._tooltipRef.hostView);

            setTimeout(() => instance.show(), 50); // for smooth transition
        }, this._TRANSITION_DELAY);
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        clearTimeout(this._showTimeoutId!);

        if (this._tooltipRef) {
            const instance = this._tooltipRef.instance;
            instance.hide();

            this._hideTimeoutId = window.setTimeout(() => {
                if (this._tooltipRef?.hostView) {
                    this._appRef.detachView(this._tooltipRef.hostView);
                }
                this._tooltipRef?.destroy();
                this._tooltipRef = null;
            }, this._TRANSITION_DELAY);
        }
    }
}
