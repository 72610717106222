import { Pipe, PipeTransform } from '@angular/core';
import { range } from 'lodash';

@Pipe({
    name: 'toRange',
    standalone: true,
})
export class ToRangePipe implements PipeTransform {
    transform(value: number): number[] {
        return range(value);
    }
}
