<div class="malou-dialog">
    <!-- justify end close button cross icon -->
    <div class="malou-dialog__header !justify-end !pb-0">
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <!-- dialog content -->
    <div class="malou-dialog__body flex flex-row items-center !overflow-hidden">
        <div class="h-full flex-3 place-items-center pb-6">
            <img class="h-full" [src]="Illustration.NotFound | illustrationPathResolver" />
        </div>
        <div class="flex-9 flex flex-col gap-y-5">
            <div class="malou-text-18--bold mb-4 text-malou-color-text-1">
                {{ 'template_info_modal.title' | translate }}
            </div>

            <div class="malou-text-13--regular text-malou-color-text-2">
                {{ 'template_info_modal.description' | translate }}
            </div>

            <!-- dialog footer -->
            <div class="malou-dialog__footer !justify-start gap-x-2 !p-0">
                <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="close()">
                    {{ 'common.later' | translate }}
                </button>
                <button class="malou-btn-raised--primary !h-11" mat-raised-button (click)="createOtherTemplate()">
                    {{ 'common.add_now' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
