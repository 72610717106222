import { Component, computed, Inject, OnInit, signal } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MalouErrorCode } from '@malou-io/package-utils';

import { FoldersService } from ':core/services/folders.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Component({
    selector: 'app-folder-renaming-modal',
    templateUrl: './folder-renaming-modal.component.html',
    standalone: true,
    imports: [FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, TranslateModule, InputTextComponent],
})
export class FolderRenamingModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    nameForm: UntypedFormGroup = this._fb.group({
        name: ['', [Validators.required]],
    });
    formError = signal('');
    isLoading = signal(false);
    currentName = signal('');
    wrongName = signal('');
    disableSubmit = computed(() => this.isLoading() || ['', this.wrongName()].includes(this.currentName()));

    constructor(
        private readonly _foldersService: FoldersService,
        private readonly _translateService: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _fb: UntypedFormBuilder,
        private readonly _dialogRef: MatDialogRef<FolderRenamingModalComponent>,
        private readonly _restaurantService: RestaurantsService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { folder: IFolder }
    ) {
        this.wrongName.set(data.folder.name);
    }

    get name(): AbstractControl<string> {
        return this.nameForm.get('name') as AbstractControl<string>;
    }

    ngOnInit(): void {
        this.name.setValue(this.data.folder.name);

        this.name.valueChanges.subscribe((value) => {
            this.currentName.set(value);
        });
    }

    close(folder?: IFolder): void {
        this._dialogRef.close(folder);
    }

    rename(): void {
        if (!this.name.value) {
            return;
        }
        this.isLoading.set(true);
        this.name.disable();
        this._foldersService
            .updateFolder(this.data.folder, {
                name: this.name.value,
                restaurantId: this._restaurantService.restaurantSelected$.value?._id!,
            })
            .subscribe({
                next: (result) => {
                    this.close(result.data);
                },
                error: (error) => {
                    this.formError.set(this._handleError(error));
                    this.isLoading.set(false);
                    this.name.enable();
                },
            });
    }

    private _handleError(err: any): string {
        if (err?.error?.malouErrorCode === MalouErrorCode.FOLDER_WITH_SAME_NAME_ALREADY_EXISTS) {
            this.wrongName.set(this.name.value);
            return this._translateService.instant('gallery.folders.folder_renaming_modal.same_name_error');
        } else {
            this.wrongName.set('');
            return this._httpErrorPipe.transform(err?.error?.message || err?.message || err);
        }
    }
}
