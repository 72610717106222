<div class="malou-border-color-dark malou-background-white flex flex-col rounded-[10px] border">
    <div class="flex items-center justify-between p-5">
        <span class="malou-text-18--bold malou-color-text-1">{{ title() }}</span>

        <div class="flex items-center gap-2">
            <div class="malou-chip" [class]="getAutomationStateClassColor()">
                <span class="malou-text-12">{{ getAutomationStateTitle() }}</span>
            </div>

            <button class="malou-btn-icon--secondary ml-2" mat-icon-button (click)="emitClick()">
                <mat-icon svgIcon="edit"></mat-icon>
            </button>
        </div>
    </div>

    <hr class="h-px border-0 bg-malou-color-background-dark" />

    @for (automation of automations(); track $index) {
        <div class="flex flex-col gap-7 p-5">
            <div class="flex justify-between">
                <span class="malou-text-12--regular">{{ getRatingCategoryTitle | applyPure: automation.ratingCategory }}</span>

                <div class="flex items-center gap-2">
                    @if (automation.active) {
                        <div class="malou-status--check">
                            <mat-icon [svgIcon]="SvgIcon.CHECK"></mat-icon>
                        </div>
                        <span class="malou-text-12--semibold text-malou-color-text-1">
                            {{ getFeatureTitle | applyPure: automation.replyMethod }}
                        </span>
                    } @else {
                        <div class="malou-status--cross">
                            <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
                        </div>
                        <span class="malou-text-12--semibold text-malou-color-text-1">
                            {{ 'automations.reviewReplies.replyMethod.disabled' | translate }}
                        </span>
                    }
                </div>
            </div>
        </div>
    }
</div>
