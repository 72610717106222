import { MAX_KEYWORD_LENGTH, MAX_WORDS_COUNT, MIN_KEYWORD_LENGTH } from './constants';

export function isValidKeywordText(keyword: string): boolean {
    if (!keyword?.trim()?.length) {
        return false;
    }
    const hasKeywordTooManyWords = keyword.split(' ').length > MAX_WORDS_COUNT;
    const isKeywordTooShort = keyword.length < MIN_KEYWORD_LENGTH;
    const isKeywordTooLong = keyword.length > MAX_KEYWORD_LENGTH;

    return !hasKeywordTooManyWords && !isKeywordTooShort && !isKeywordTooLong;
}
