@if (!isParentError) {
    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
        <div>
            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
        </div>
        @if (hasData()) {
            @if (!isParentLoading) {
                <div>
                    <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="cardsTemplate"></ng-container>
                </div>
            } @else {
                <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
            }
        } @else {
            <div class="flex h-[442px] flex-col items-center justify-center gap-3 py-6">
                <img class="mb-6 h-40" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
                <div class="malou-text-14--bold malou-color-text-1 mb-2">{{ 'statistics.errors.no_result' | translate }}</div>
                <div class="malou-text-10--regular malou-color-text-2">{{ 'statistics.errors.change_period' | translate }}</div>
            </div>
        }
    </div>
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'statistics.totems.scan_count.scan_count' | translate }}</span>
        @if (hasData()) {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--medium md:hidden">{{ 'common.view_by' | translate }}</span>
                <app-select [values]="VIEW_BY_FILTER_VALUES" [displayWith]="viewByDisplayWith" [formControl]="viewByControl"></app-select>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-statistics-boosters-scan-count-chart
        [hiddenDatasetIndexes]="hiddenDatasetIndexes"
        [totemsData]="totemsData"
        [viewBy]="viewByControlValue"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-statistics-boosters-scan-count-chart>
</ng-template>

<ng-template #cardsTemplate>
    <div class="flex">
        <div class="malou-simple-card--light mr-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ scanCountOnPeriod | shortNumber }}
                </div>
                @if (scanCountOnPeriod) {
                    <app-number-evolution
                        [value]="scanCountDifferenceWithPreviousPeriod"
                        [displayedValue]="scanCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.totems.scan_count.direct_scans' | translate }}
            </div>
        </div>
        <div class="malou-simple-card--light ml-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ wheelOfFortuneCountOnPeriod | shortNumber }}
                </div>
                @if (wheelOfFortuneCountOnPeriod) {
                    <app-number-evolution
                        [value]="wheelOfFortuneCountDifferenceWithPreviousPeriod"
                        [displayedValue]="wheelOfFortuneCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.totems.scan_count.wheel_of_fortune' | translate }}
            </div>
        </div>
    </div>
</ng-template>
