import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'millisecondsToHourMinutePipe',
    standalone: true,
})
export class MillisecondsToHourMinutePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}
    transform(milliseconds: number, options?: { round: boolean }): string {
        const hours = Math.floor(milliseconds / 1000 / 60 / 60);
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

        // if less than 1 hour and more than 0 minutes, return only minutes every time
        if (hours < 1 && minutes > 0) {
            return minutes + this._translate.instant('common.minutes_short');
        }

        if (options?.round) {
            if (minutes >= 30) {
                return hours + 1 + this._translate.instant('common.hours_short');
            }

            return hours + this._translate.instant('common.hours_short');
        }

        if (hours === 0 && minutes === 0) {
            return '0' + this._translate.instant('common.hours_short');
        }

        const min = minutes ? minutes + this._translate.instant('common.minutes_short') : '';
        return hours + this._translate.instant('common.hours_short') + ' ' + min;
    }
}
