import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, filter, map, Observable, switchMap } from 'rxjs';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import { BusinessCategory, isNotNil, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { Nfc, Restaurant, RestaurantWithTooltipProperties } from ':shared/models';

import * as AggregatedStatisticsSelector from '../store/aggregated-statistics.selectors';

@Injectable({
    providedIn: 'root',
})
export class AggregatedStatisticsFiltersContext {
    private readonly _store = inject(Store);
    private readonly _roiContext = inject(RoiContext);
    private readonly _translateService = inject(TranslateService);
    private readonly _nfcService = inject(NfcService);
    private readonly _destroyRef = inject(DestroyRef);

    private readonly _currentUser$ = this._store.select(selectUserInfos).pipe(filter(isNotNil));

    readonly roiRestaurants$ = combineLatest([
        this._store.select(selectOwnRestaurants),
        this._currentUser$,
        this._roiContext.restaurantsRoiSettings$,
    ]).pipe(
        filter(([_, user, restaurantsRoiSettings]) => isNotNil(user) && !!restaurantsRoiSettings),
        map(
            ([restaurants, user]) =>
                restaurants
                    .sort(sortRestaurantsByInternalNameThenName)
                    .filter((restaurant) => restaurant.roiActivated || user?.isAdmin())
                    .map(
                        (restaurant) =>
                            new RestaurantWithTooltipProperties(
                                restaurant,
                                restaurant.type === BusinessCategory.BRAND ||
                                    !this._roiContext.isRestaurantRoiSettingsComplete(restaurant._id),
                                restaurant.type === BusinessCategory.BRAND
                                    ? this._translateService.instant('roi.no_roi_for_brand_restaurants')
                                    : this._translateService.instant('roi.restaurant_settings_not_configured')
                            )
                    ) ?? []
        )
    );

    readonly savedRestaurantsWithRoiSettings$ = combineLatest([
        this._store.select(AggregatedStatisticsSelector.selectRoiRestaurantIdsFilter),
        this.roiRestaurants$,
    ]).pipe(
        debounceTime(100),
        filter(([_, currentRestaurantsList]: [string[], Restaurant[]]) => !!currentRestaurantsList.length),
        map(([restaurantIds, currentRestaurantsList]: [string[], Restaurant[]]) =>
            restaurantIds.map((restaurantId) => currentRestaurantsList.find((r) => r._id === restaurantId)).filter(isNotNil)
        ),
        map((restaurants) => restaurants.sort(sortRestaurantsByInternalNameThenName)),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly selectedRestaurants$ = combineLatest([
        this._store.select(AggregatedStatisticsSelector.selectRestaurantIdsFilter),
        this._store.select(selectOwnRestaurants),
    ]).pipe(
        debounceTime(100),
        filter(([_, userRestaurants]: [string[], Restaurant[]]) => !!userRestaurants.length),
        map(
            ([selectedRestaurantIds, userRestaurants]: [string[], Restaurant[]]) =>
                selectedRestaurantIds.map((restaurantId) => userRestaurants.find((r) => r._id === restaurantId)).filter(isNotNil) ?? []
        ),
        map((restaurants) => restaurants.sort(sortRestaurantsByInternalNameThenName)),
        takeUntilDestroyed(this._destroyRef)
    );

    restaurantsTotems$: Observable<NfcWithRestaurantDto[]> = this._store.select(selectOwnRestaurants).pipe(
        switchMap((restaurants) =>
            this._nfcService.search(
                {},
                restaurants.map((restaurant) => restaurant._id)
            )
        ),
        map((res) => res.data || []),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly selectedTotems$ = combineLatest([
        this._store.select(AggregatedStatisticsSelector.selectTotemsFilter),
        this.restaurantsTotems$,
    ]).pipe(
        map(([totemIds, nfcsWithRestaurant]) =>
            nfcsWithRestaurant
                .filter((nfcWithRestaurant) => totemIds.includes(nfcWithRestaurant.id))
                .map((nfcWithRestaurant) => Nfc.fromNfcWithRestaurantDto(nfcWithRestaurant))
        ),
        takeUntilDestroyed(this._destroyRef)
    );
}
