import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
    DEFAULT_MAX_IMAGE_SIZE,
    InputFileType,
    InputMediaType,
    MediaCategory,
    PictureSize,
    SizeInBytes,
    STORY_MAX_VIDEO_SIZE,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { MediaEditionState } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import * as StoriesActions from ':modules/stories/store/stories.actions';
import { selectImageEditions } from ':modules/stories/store/stories.selector';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { DndDirective } from '../../directives/dnd.directive';
import { CropOption } from '../../enums/crop-options';
import { PictureFormat } from '../../enums/picture-format';
import { StylingProperties } from '../../enums/styling-properties';
import { hexToRgb, interpolateColor, Rgb, rgbToHex } from '../../helpers/color';
import { FontStyle } from '../../interfaces/font-style';
import { DEFAULT_IMAGE_EDITION, ImageEdition, ImageLabel } from '../../models/image-edition';
import { ImageProperties } from '../../models/image-properties';
import { CustomDialogService } from '../../services/custom-dialog.service';
import { MediaFileUploaderComponent } from '../media-file-uploader/media-file-uploader.component';
import { SimpleSelectComponent } from '../simple-select/simple-select.component';
import { TextAreaComponent } from '../text-area/text-area.component';

const EDIT_TEXT_ID = 'currentTextEditDiv';
const TEXT_EDITION_CLASS_NAME = 'text-edition-tag';
const EDIT_IMAGE_CONTAINER_ID = 'editImageContainer';
const CROSS_TAG_CLASS_NAME = 'cross-tag';
const MALOU_TAG_ID_ATTRIBUTE = 'malou-tag-id';

enum EditionMode {
    NONE,
    CROPPING,
    ROTATING,
    TEXT_EDITING,
    BACKGROUND_EDITING,
}

export const DEFAULT_MAX_MEDIAS = 1;

export const DEFAULT_ACCEPTED_MEDIA_TYPES = [InputMediaType.IMAGE, InputMediaType.VIDEO];
export const DEFAULT_BACKGROUND_COLOR = '#000000';
export const DEFAULT_BACKGROUND_PRESET_COLORS = [DEFAULT_BACKGROUND_COLOR, '#FFF'];
export const DEFAULT_FONT_COLOR = '#000000';
export const DEFAULT_FONT_SIZE = 24;

@Component({
    selector: 'app-story-media-editor',
    templateUrl: './story-media-editor.component.html',
    styleUrls: ['./story-media-editor.component.scss'],
    standalone: true,
    imports: [
        CdkDropList,
        CdkDrag,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        ColorPickerModule,
        FormsModule,
        ImageCropperModule,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatOptionModule,
        ReactiveFormsModule,
        TranslateModule,
        DndDirective,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        IncludesPipe,
        ShortTextPipe,
        MediaFileUploaderComponent,
        MalouSpinnerComponent,
        SimpleSelectComponent,
        TextAreaComponent,
        ApplySelfPurePipe,
    ],
    providers: [ColorPickerService],
})
export class StoryMediaEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() acceptedMediaTypes: InputMediaType[] = DEFAULT_ACCEPTED_MEDIA_TYPES;
    @Input() isTextEditable = false;
    @Input() disabled = false;

    @Output() mediasSelected: EventEmitter<Media[]> = new EventEmitter<Media[]>();
    @Output() fileChanged: EventEmitter<Media[]> = new EventEmitter<Media[]>();
    @Output() editing: EventEmitter<MediaEditionState> = new EventEmitter<MediaEditionState>();

    readonly DEFAULT_BACKGROUND_PRESET_COLORS = DEFAULT_BACKGROUND_PRESET_COLORS;
    readonly EditionMode = EditionMode;
    readonly CropOption = CropOption;
    readonly PictureSize = PictureSize;
    readonly STORY_MAX_VIDEO_SIZE = STORY_MAX_VIDEO_SIZE;
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    hasMedias = false;
    cropOption = CropOption.SQUARE;
    rotation = 0;
    format: PictureFormat = PictureFormat.PNG;

    currentEditionMode = EditionMode.NONE;

    displayImageCropper = false;
    draggingOver = false;

    fontStyleForm: FormControl<FontStyle>;
    fontSizeForm = new FormControl(DEFAULT_FONT_SIZE);
    fontStyles: FontStyle[] = [
        {
            key: 'Courier',
            styles: {
                fontFamily: 'Courier New',
                fontWeight: 700,
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                borderRadius: (): string => '0px',
                lineHeight: (context: typeof this): string => context._getFontSizeValue() * 0.6 + 'px',
                paddingTop: (context: typeof this): string => context._getFontSizeValue() * 0.33 + 'px',
            },
        },
        {
            key: 'Modern',
            styles: {
                fontFamily: 'Dosis',
                fontWeight: 600,
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                borderRadius: (): string => '6px',
                background: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return `rgba(${interpolated[7][0]},${interpolated[7][1]},${interpolated[7][2]},0.7)`;
                },
            },
        },
        {
            key: 'Strong',
            styles: {
                fontFamily: 'Roboto',
                fontWeight: 900,
                borderRadius: '.15em',
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                background: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return `rgba(${interpolated[8][0]},${interpolated[8][1]},${interpolated[8][2]},1)`;
                },
                paddingLeft: (context: typeof this): string => `${context._getFontSizeValue() * 0.4}px`,
                paddingRight: (context: typeof this): string => `${context._getFontSizeValue() * 0.4}px`,
            },
        },
        {
            key: 'Cursive',
            styles: {
                fontFamily: 'Cosmopolitan Script',
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                color: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return rgbToHex(interpolated[9]) ?? DEFAULT_FONT_COLOR;
                },
                background: (): string => 'transparent',
                textShadow: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }

                    return `0 0 7px ${rgbToHex(interpolated[4])},
                    0 0 10px ${rgbToHex(interpolated[4])},
                    0 0 21px ${rgbToHex(interpolated[4])},
                    0 0 42px ${rgbToHex(interpolated[8])},
                    0 0 82px ${rgbToHex(interpolated[8])},
                    0 0 92px ${rgbToHex(interpolated[8])},
                    0 0 102px ${rgbToHex(interpolated[8])},
                    0 0 151px ${rgbToHex(interpolated[8])}`;
                },
            },
        },
        {
            key: 'Bro',
            styles: {
                fontFamily: 'Proxima Nova Condensed',
                fontWeight: 900,
                letterSpacing: '1px',
                fontStyle: 'italic',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                textShadow: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const fontSize = context._getFontSizeValue();
                    const shadowGap = fontSize > 30 ? ['2px 2px', '4px 4px'] : ['1px 1px', '2px 2px'];
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return `${shadowGap[0]} 0 ${rgbToHex(interpolated[5])}, ${shadowGap[1]} 0 ${rgbToHex(interpolated[9])}`;
                },
            },
        },
        {
            key: 'Cut',
            styles: {
                fontFamily: 'Serif',
                fontWeight: 400,
                textTransform: 'uppercase',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                backgroundColor: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return `rgba(${interpolated[8][0]},${interpolated[8][1]},${interpolated[8][2]},1)`;
                },
                transform: (): string => 'skewX(-14deg)',
                boxShadow: (context: typeof this): string => {
                    const fontSize = context._getFontSizeValue();
                    const shadowGap = fontSize > 30 ? '-5px' : '-3px';
                    return `${shadowGap} ${shadowGap} ${context.fontColor}`;
                },
            },
        },
        {
            key: 'Android',
            styles: {
                fontFamily: 'Roboto',
                fontWeight: 400,
                borderRadius: '1em',
                textTransform: 'uppercase',
                color: (context: typeof this): string => context.fontColor ?? DEFAULT_FONT_COLOR,
                fontSize: (context: typeof this): string => context._getFontSizeValue() + 'px',
                backgroundColor: (context: typeof this): string => {
                    const fontColor = context.fontColor ?? DEFAULT_FONT_COLOR;
                    const rgb = hexToRgb(fontColor);
                    const interpolated: Rgb[] = [];
                    const factorStep = 1 / 10;
                    for (let i = 0; i < 10; i++) {
                        const shadowColor = interpolateColor(rgb, [256, 256, 256], factorStep * i);
                        interpolated.push(shadowColor);
                    }
                    return `rgba(${interpolated[9][0]},${interpolated[9][1]},${interpolated[9][2]},1)`;
                },
                paddingLeft: (context: typeof this): string => `${context._getFontSizeValue() * 0.6}px`,
                paddingRight: (context: typeof this): string => `${context._getFontSizeValue() * 0.6}px`,
            },
        },
    ];

    fontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72];
    loading = false;
    fontColor = '#000000';
    fontBgColor: string;
    bgColor: string = DEFAULT_BACKGROUND_COLOR;

    videoPlayerElement?: HTMLVideoElement;

    readonly SvgIcon = SvgIcon;

    private _editedImageContainer: ElementRef;
    private _croppedImage: string | null = '';
    private _previousCropOption = this.cropOption;

    private _currentMediaIndex = 0;
    private _originalMedias: Record<string, Media> = {};
    private _newCroppedMediaId: string | null = null;

    private _initialAngle: number;

    private _fontStyle: FontStyle;

    private _imageEditionByMediaId: Record<string, ImageEdition> = {};
    private _applyModificationOnInit = true;
    private _medias: Media[];

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _mediaService: MediaService,
        private readonly _spinnerService: SpinnerService,
        private readonly _dialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {
        this._initialAngle = this.rotation + 0;
        this.fontStyleForm = new FormControl(this.fontStyles[0]) as FormControl<FontStyle>;
    }

    get angle(): number {
        return this.rotation - this._initialAngle;
    }

    get currentMedia(): Media {
        return this.medias[this._currentMediaIndex];
    }

    get currentMediaUrl(): string {
        return this.medias?.[this._currentMediaIndex]?.getMediaUrl(PictureSize.IG_FIT);
    }

    get isCurrentMediaVideo(): boolean {
        return this.medias?.[this._currentMediaIndex]?.isVideo();
    }

    get medias(): Media[] {
        return this._medias;
    }

    @Input() set medias(value: Media[]) {
        this._onMediaChange(value);
    }

    @ViewChild('editedImage', { static: false }) set content(imageContainer: ElementRef) {
        if (imageContainer) {
            this._editedImageContainer = imageContainer;
        }
    }

    ngOnInit(): void {
        this.fontStyleForm.valueChanges.subscribe((style) => {
            this._fontStyle = style;
            this._resetFontStyle();
            this._updateFontStyle();
            this._getParentOfCurrentElementAndUpdateTextModification();
        });
        this.fontSizeForm.valueChanges.subscribe(() => {
            this._updateFontStyle();
            this._getParentOfCurrentElementAndUpdateTextModification();
        });
        this._store
            .select(selectImageEditions)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((imageEditions) => {
                this._imageEditionByMediaId = this.medias.reduce((acc, media) => {
                    const imageEdition = imageEditions?.[media.id] ?? cloneDeep(DEFAULT_IMAGE_EDITION);
                    acc[media.id] = imageEdition;
                    return acc;
                }, this._imageEditionByMediaId ?? {});
                if (this._applyModificationOnInit) {
                    this._applyModificationOnInit = false;
                    const currentImageEdition = this._imageEditionByMediaId[this.currentMedia?.id];
                    if (!currentImageEdition) {
                        return;
                    }
                    this._applyImageEdition(currentImageEdition);
                }
            });
    }

    ngAfterViewInit(): void {
        this.videoPlayerElement = this._getVideoPlayerElement();
        this._changeDetectorRef.detectChanges(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    }

    ngOnDestroy(): void {
        this._closeEditor(this.currentEditionMode);
    }

    onImageCropped(event: ImageCroppedEvent): void {
        this._croppedImage = event.base64 ?? null;
    }

    getAcceptedInputFileTypes(acceptedMediaType: InputMediaType[]): string {
        const inputFileTypes: InputFileType[] = [];
        if (acceptedMediaType.includes(InputMediaType.IMAGE)) {
            inputFileTypes.push(InputFileType.PNG, InputFileType.JPEG, InputFileType.HEIF, InputFileType.HEIC);
        }
        if (acceptedMediaType.includes(InputMediaType.VIDEO)) {
            inputFileTypes.push(InputFileType.VIDEO, InputFileType.QUICKTIME);
        }
        return inputFileTypes.join(',');
    }

    changeAspectRatio(option: CropOption, shouldKeepPreviousCropOption = false): void {
        if (shouldKeepPreviousCropOption) {
            this._previousCropOption = this.cropOption;
        } else {
            this._previousCropOption = option;
        }
        this.cropOption = option;
    }

    openCropEditor(): void {
        if (!this._canDoAction()) {
            return;
        }
        this.editing.emit(MediaEditionState.EDITING);
        this.currentEditionMode = EditionMode.CROPPING;
        this.displayImageCropper = true;
        this.changeAspectRatio(this._previousCropOption);
    }

    closeDimensionEditors(): void {
        this.editing.emit(MediaEditionState.FINISHED_EDITING);
        this.currentEditionMode = EditionMode.NONE;
        this.displayImageCropper = false;
    }

    commitCurrentEdition(): void {
        if (!this._croppedImage) {
            this.rotation = 0;
            this._initialAngle = this.rotation + 0;
            this.closeDimensionEditors();
            return;
        }
        this._saveCroppedMedia$(this.currentMedia, this._croppedImage).subscribe({
            next: (newMedia) => {
                this._originalMedias[newMedia.id] = this.currentMedia;
                this._copyOldImageEditionToNewCroppedMedia(newMedia);
                this.medias[this.medias.findIndex((media) => media.id === this.currentMedia.id)] = newMedia;
                this.fileChanged.emit(this.medias);
                this._croppedImage = null;
                this.rotation = 0;
                this._initialAngle = this.rotation + 0;
                this.closeDimensionEditors();
                this.loading = false;
                this.changeAspectRatio(this._previousCropOption);
            },
            error: (err) => {
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                this.loading = false;
            },
        });
    }

    toggleTextEdition(): void {
        if (!this._canDoAction()) {
            return;
        }
        this.currentEditionMode = this.currentEditionMode === EditionMode.TEXT_EDITING ? EditionMode.NONE : EditionMode.TEXT_EDITING;
        const isEditing = this.currentEditionMode === EditionMode.TEXT_EDITING;
        this._toggleAllTextsEditability(isEditing);
        this._toggleAllTextsDraggable(isEditing);
        this.displayImageCropper = false;
        if (isEditing) {
            this._addCrossesToText();
        } else {
            this._removeCrossesFromMedia();
        }
    }

    editTextColor(color: string): void {
        this.fontColor = color;
        this._updateFontStyle();
        this._getParentOfCurrentElementAndUpdateTextModification();
    }

    editTextBackgroundColor(color: string): void {
        const element = document.getElementById(EDIT_TEXT_ID);
        if (!element) {
            return;
        }
        element.style.backgroundColor = color;
        this._getParentAndUpdateTextModification(element);
    }

    addTextTag(event: MouseEvent): void {
        if (this.currentEditionMode !== EditionMode.TEXT_EDITING) {
            return;
        }
        const imageContainer = document.getElementById(EDIT_IMAGE_CONTAINER_ID);
        if (!imageContainer) {
            return;
        }
        const rect = imageContainer.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        this._addTextEditTag(x, y);
    }

    openRotationEditor(): void {
        if (!this._canDoAction()) {
            return;
        }
        this._closeEditor(this.currentEditionMode);
        this.editing.emit(MediaEditionState.EDITING);
        this.currentEditionMode = EditionMode.ROTATING;
        this.displayImageCropper = true;
        this.changeAspectRatio(CropOption.VERTICAL, true);
    }

    editBackgroundColor(backgroundColor: string): void {
        this._setBgColor(backgroundColor, { emitChanges: true });
        this._originalMedias[this.currentMedia.id] = this.currentMedia;
    }

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: false,
                    selectedMedias: this.medias,
                    filter: this._getMediaTypeFilter(),
                    maxVideoSize: STORY_MAX_VIDEO_SIZE,
                },
            })
            .afterClosed()
            .pipe(
                switchMap((medias: Media[]) => {
                    if (medias) {
                        this.mediasSelected.emit(medias);
                        this.medias = medias;
                        this._changeCurrentSelectedMedia(this._currentMediaIndex);
                        return this._mediaService.fetchMediaDescription(this.medias?.map((media) => media.id));
                    }
                    return of({ data: null });
                })
            )
            .subscribe({
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    dragOver(): void {
        if (this._canAddMoreMedia()) {
            this.draggingOver = true;
        }
    }

    dragLeave(): void {
        this.draggingOver = false;
    }

    playVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        if (this.videoPlayerElement?.paused) {
            this.videoPlayerElement.play();
        } else {
            this.videoPlayerElement.pause();
        }
    }

    muteUnmuteVideo(): void {
        if (!this.videoPlayerElement) {
            return;
        }
        this.videoPlayerElement.muted = !this.videoPlayerElement.muted;
    }

    isVideoMuted(): boolean {
        return !!this.videoPlayerElement?.muted;
    }

    isVideoPlaying(): boolean {
        return !this.videoPlayerElement?.paused;
    }

    resetCurrentImage(): void {
        this.medias[this.medias.findIndex((media) => media.id === this.currentMedia.id)] = this._originalMedias[this.currentMedia.id];
        this.fileChanged.emit(this.medias);
        delete this._originalMedias[this.currentMedia.id];
        this._resetImageModification({ emitChanges: true });
    }

    canResetCurrentImage(): boolean {
        return !!this._originalMedias[this.currentMedia?.id];
    }

    onFileProcessed(createdMedias: Media[]): void {
        this._spinnerService.hide();
        if (createdMedias.length > 0) {
            this._onMediasUploaded(createdMedias);
        }
    }

    startReadingFile(): void {
        this._spinnerService.show();
    }

    processError(error: Error | string): void {
        this._spinnerService.hide();
        const errorMessage = typeof error === 'string' ? error : (error.message ?? error.toString());
        this._toastService.openErrorToast(errorMessage);
    }

    displayRotationWith(value: number): string {
        return `${value}°`;
    }

    displayWithKey = (elem: { key: string }): string => elem.key;

    onSliderInput(event: any): void {
        this.rotation = event.target.value ?? 0;
    }

    private _resetFontStyle(): void {
        const element = document.getElementById(EDIT_TEXT_ID);
        if (!element) {
            return;
        }
        for (const style of Object.values(StylingProperties)) {
            element.style[style] = '';
        }
    }

    private _updateFontStyle(): void {
        const element = document.getElementById(EDIT_TEXT_ID);
        if (!element) {
            return;
        }
        for (const [key, value] of Object.entries(this._fontStyle.styles)) {
            if (typeof value === 'function') {
                element.style[key] = value(this);
            } else {
                element.style[key] = value;
            }
        }
    }

    private _addTextEditTag(x = 20, y = 20, text = 'Lorem'): void {
        this._fontStyle = this.fontStyleForm.value;
        const element = document.getElementById(EDIT_TEXT_ID);
        if (element) {
            element.id = '';
        }

        const containerTag = this._createContainerTag(x, y);
        const textTag = this._createTextTag({ text });
        const crossTag = this._createCrossTag();
        this._editedImageContainer.nativeElement.appendChild(containerTag);
        containerTag.appendChild(textTag);
        containerTag.appendChild(crossTag);
        this._registerDragElement(containerTag);
        this._updateFontStyle();
        this._originalMedias[this.currentMedia.id] = this.currentMedia;
        const malouTagId = this._getMalouTagId(containerTag);
        if (!malouTagId) {
            return;
        }
        const newImageEdition: ImageEdition = {
            ...(this._imageEditionByMediaId[this.currentMedia.id] ?? {}),
            labels: [
                ...(this._imageEditionByMediaId[this.currentMedia.id]?.labels ?? []),
                {
                    text,
                    left: x,
                    top: y,
                    style: textTag.style.cssText,
                    malouTagId,
                },
            ],
            isNew: true,
        };
        this._imageEditionByMediaId[this.currentMedia.id] = newImageEdition;
        this._registerImageEdition(this.currentMedia.id, newImageEdition);
    }

    private _getMediaTypeFilter(): MediaPickerFilter {
        if (this.acceptedMediaTypes.length === 1 && this.acceptedMediaTypes[0] === InputMediaType.IMAGE) {
            return MediaPickerFilter.ONLY_IMAGE;
        }
        if (this.acceptedMediaTypes.length === 1 && this.acceptedMediaTypes[0] === InputMediaType.VIDEO) {
            return MediaPickerFilter.ONLY_VIDEO;
        }
        return MediaPickerFilter.ALL;
    }

    private _canAddMoreMedia(): boolean {
        return this.medias.length < DEFAULT_MAX_MEDIAS;
    }

    private _changeCurrentSelectedMedia(mediaIndex: number): void {
        this._currentMediaIndex = mediaIndex;
    }

    private _saveCroppedMedia$(oldMedia: Media, croppedImageBase64: string): Observable<Media> {
        return forkJoin([this._transformMedia(croppedImageBase64), this._store.select(selectUserInfos).pipe(take(1))]).pipe(
            switchMap(([file, user]) =>
                this._mediaService.uploadAndCreateMedia([
                    {
                        data: file,
                        metadata: {
                            restaurantId: oldMedia.restaurantId,
                            title: oldMedia.title,
                            userId: user?._id,
                            originalMediaId: oldMedia.id,
                            category: MediaCategory.ADDITIONAL,
                        },
                    },
                ])
            ),
            map((res) => new Media(res.data[0])),
            switchMap((newMedia: Media) =>
                newMedia.getProperties$().pipe(
                    map((properties) => {
                        if (!properties) {
                            return newMedia;
                        }
                        const mediaErrors = this._getImageErrors(properties);
                        newMedia.setErrors(mediaErrors);
                        return newMedia;
                    })
                )
            )
        );
    }

    private _getVideoPlayerElement(): HTMLVideoElement {
        return document.getElementById('videoPlayer') as HTMLVideoElement;
    }

    private _onMediaChange(medias: Media[]): void {
        this._closeEditor(this.currentEditionMode);
        this._medias = medias;
        this.hasMedias = !!this._medias?.length;
        const mediaId = medias[0]?.id;
        this._imageEditionByMediaId = this._medias.reduce((acc, media) => {
            const imageEdition = this._imageEditionByMediaId[media.id] ?? cloneDeep(DEFAULT_IMAGE_EDITION);
            acc[media.id] = imageEdition;
            return acc;
        }, this._imageEditionByMediaId ?? {});

        this._resetImageModification({ emitChanges: false });
        const imageModification = this._imageEditionByMediaId[mediaId];
        if (imageModification && !isEqual(imageModification, DEFAULT_IMAGE_EDITION)) {
            const isNew = this._newCroppedMediaId === mediaId;
            this._newCroppedMediaId = null;
            this._applyImageEdition({
                ...this._imageEditionByMediaId[mediaId],
                isNew,
            });
        }

        this._changeDetectorRef.detectChanges();
        this.videoPlayerElement = this._getVideoPlayerElement();
    }

    private _registerDragElement(element: HTMLElement): void {
        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        const dragMouseDown = (e): void => {
            e = e || window.event;
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        };

        const elementDrag = (e): void => {
            e = e || window.event;
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            element.style.top = element.offsetTop - pos2 + 'px';
            element.style.left = element.offsetLeft - pos1 + 'px';
        };

        const closeDragElement = (): void => {
            document.onmouseup = null;
            document.onmousemove = null;
            const malouTagId = this._getMalouTagId(element);
            if (!malouTagId) {
                return;
            }
            const imageEdition = this._imageEditionByMediaId[this.currentMedia.id];
            this._updateTextModification({ malouTagId, imageEdition });
        };

        const elementHeader = document.getElementById(element.id + 'header');
        if (elementHeader) {
            elementHeader.onmousedown = dragMouseDown;
        } else {
            element.onmousedown = dragMouseDown;
        }
    }

    private _unregisterDragElement(element: HTMLElement): void {
        const elementHeader = document.getElementById(element.id + 'header');
        if (elementHeader) {
            elementHeader.onmousedown = null;
        } else {
            element.onmousedown = null;
        }
    }

    private _transformMedia(mediaBase64: string): Observable<File> {
        return from(
            fetch(mediaBase64)
                .then((res) => res.blob())
                .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
        );
    }

    private _openErrorDialog(title: string, message: string): void {
        this._dialogService.open({
            title,
            message,
            variant: DialogVariant.ERROR,
            primaryButton: {
                label: this._translate.instant('common.understood'),
            },
        });
    }

    private _getImageErrors(imgProperties: ImageProperties): string[] {
        const errors: string[] = [];

        const minimumSizeInBytes = 10 * SizeInBytes.KILO_BYTES;
        if (imgProperties.isTooSmallSizeImage(minimumSizeInBytes)) {
            const actualSizeInKiloBytes = imgProperties.bytes / SizeInBytes.KILO_BYTES;
            const minimumSizeInKiloBytes = minimumSizeInBytes / SizeInBytes.KILO_BYTES;
            errors.push(
                this._translate.instant('posts.new_post.too_small_size_image', {
                    actualSize: actualSizeInKiloBytes,
                    minimumSize: minimumSizeInKiloBytes,
                })
            );
        }

        return errors;
    }

    private _resetImageModification({ emitChanges = true }: { emitChanges: boolean }): void {
        this._croppedImage = null;
        this._resetBackgroundToOriginal({ emitChanges });
        this._removeTextAndCrossesFromMedia({ emitChanges });
    }

    private _resetBackgroundToOriginal({ emitChanges = true }: { emitChanges: boolean } = { emitChanges: true }): void {
        this._setBgColor(DEFAULT_IMAGE_EDITION.backgroundColor, { emitChanges });
    }

    private _removeTextAndCrossesFromMedia({ emitChanges = true }: { emitChanges: boolean } = { emitChanges: true }): void {
        this._removeTextFromMedia({ emitChanges });
        this._removeCrossesFromMedia();
    }

    private _removeTextFromMedia({ emitChanges = true }: { emitChanges: boolean } = { emitChanges: true }): void {
        const elements = document.getElementsByClassName(TEXT_EDITION_CLASS_NAME);
        while (elements?.length > 0) {
            elements[0].remove();
        }
        if (emitChanges) {
            const imageEdition = this._imageEditionByMediaId[this.currentMedia.id];
            if (imageEdition) {
                this._registerImageEdition(this.currentMedia.id, {
                    ...imageEdition,
                    labels: [],
                    isNew: true,
                });
            }
        }
    }

    private _removeCrossesFromMedia(): void {
        const crosses = document.getElementsByClassName(CROSS_TAG_CLASS_NAME);
        while (crosses?.length > 0) {
            crosses[0].remove();
        }
    }

    private _addCrossesToText(): void {
        const elements = document.getElementsByClassName(TEXT_EDITION_CLASS_NAME);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const crossTag = this._createCrossTag();
            element.appendChild(crossTag);
        }
    }

    private _createContainerTag(x = 20, y = 20, malouTagId?: string): HTMLDivElement {
        const containerTag = document.createElement('div');
        containerTag.className = `absolute cursor-pointer ${TEXT_EDITION_CLASS_NAME}`;
        containerTag.style.top = y + 'px';
        containerTag.style.left = x + 'px';
        containerTag.style.zIndex = '10';
        containerTag.onclick = (elt): void => {
            elt.stopPropagation();
        };
        const tagId = malouTagId ?? uuidv4();
        containerTag.setAttribute(MALOU_TAG_ID_ATTRIBUTE, tagId);
        return containerTag;
    }

    private _createTextTag(
        { text = 'Lorem', active = true }: { text?: string; active?: boolean } = { text: 'Lorem', active: true }
    ): HTMLParagraphElement {
        const textTag = document.createElement('p');
        textTag.id = active ? EDIT_TEXT_ID : '';

        textTag.className = 'h-fit whitespace-pre-wrap px-1';
        textTag.contentEditable = 'true';
        textTag.oninput = (e): void => {
            this._getParentAndUpdateTextModification(e.target as HTMLElement);
        };

        textTag.spellcheck = false;
        textTag.style.backgroundColor = 'transparent';
        textTag.innerHTML = text;
        textTag.onclick = (elt): void => {
            if ((elt.target as HTMLElement).tagName !== 'P') {
                elt.stopPropagation();
                return;
            }
            const current = document.getElementById(EDIT_TEXT_ID);
            if (current) {
                current.id = '';
            }
            (elt.target as HTMLElement).id = EDIT_TEXT_ID;
            elt.stopPropagation();
        };
        textTag.onkeydown = (e): void => {
            if (e.key === 'Enter') {
                this._insertBrTag();
                e.preventDefault();
            }
        };
        return textTag;
    }

    private _toggleAllTextsEditability(editable: boolean): void {
        const elements = document.querySelectorAll(`.${TEXT_EDITION_CLASS_NAME} p`);
        for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i) as HTMLElement;
            element.contentEditable = editable ? 'true' : 'false';
        }
    }

    private _toggleAllTextsDraggable(draggable: boolean): void {
        const elements = document.getElementsByClassName(TEXT_EDITION_CLASS_NAME);
        for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i) as HTMLElement;
            if (draggable) {
                this._registerDragElement(element);
            } else {
                this._unregisterDragElement(element);
            }
        }
    }

    private _createCrossTag(): HTMLSpanElement {
        const crossTag = document.createElement('span');

        crossTag.innerHTML = 'x';
        crossTag.className = CROSS_TAG_CLASS_NAME;
        crossTag.contentEditable = 'false';
        crossTag.setAttribute('data-html2canvas-ignore', 'true');
        crossTag.onclick = (elt): void => {
            const parent = (elt.target as HTMLElement).parentElement;
            if (!parent) {
                return;
            }
            parent.remove();
            elt.stopPropagation();
            const malouTagId = this._getMalouTagId(parent);
            if (!malouTagId) {
                return;
            }
            this._removeTextFromImageEdition(malouTagId);
        };
        return crossTag;
    }

    private _applyImageEdition(modification: ImageEdition): void {
        if (!this._editedImageContainer) {
            this._changeDetectorRef.detectChanges();
        }
        if (modification.backgroundColor) {
            this._setBgColor(modification.backgroundColor, { emitChanges: false });
        }
        if (modification.labels?.length) {
            modification.labels.forEach((textModification) => {
                const containerTag = this._createContainerTag(textModification.left, textModification.top, textModification.malouTagId);
                const textTag = this._createTextTag({ text: textModification.text, active: false });
                textTag.style.cssText = textModification.style;
                this._editedImageContainer.nativeElement.appendChild(containerTag);
                containerTag.appendChild(textTag);
                this._originalMedias[this.currentMedia.id] = this.currentMedia;
            });
            this._toggleAllTextsEditability(false);
        }
        if (modification.backgroundColor || modification.labels?.length) {
            this._registerImageEdition(this.currentMedia.id, modification);
        }
    }

    private _getMalouTagId(element: HTMLElement): string | null {
        return element.getAttribute(MALOU_TAG_ID_ATTRIBUTE);
    }

    private _updateTextModification({ malouTagId, imageEdition }: { malouTagId: string; imageEdition: ImageEdition }): void {
        const labels = imageEdition.labels ?? [];
        const textModificationIndex = labels.findIndex((text) => text.malouTagId === malouTagId);

        const divElement = document.querySelector(`div[${MALOU_TAG_ID_ATTRIBUTE}="${malouTagId}"]`) as HTMLElement;
        const childPElement = divElement.children[0] as HTMLElement;
        const newMediaTextModification: ImageLabel = {
            left: divElement.offsetLeft,
            top: divElement.offsetTop,
            style: childPElement.style.cssText,
            malouTagId,
            text: childPElement.innerHTML,
        };
        if (textModificationIndex === -1) {
            this._registerImageEdition(this.currentMedia.id, {
                ...imageEdition,
                labels: [...labels, newMediaTextModification],
                isNew: true,
            });
            return;
        }
        this._registerImageEdition(this.currentMedia.id, {
            ...imageEdition,
            labels: [...labels.slice(0, textModificationIndex), newMediaTextModification, ...labels.slice(textModificationIndex + 1)],
            isNew: true,
        });
    }

    private _removeTextFromImageEdition(malouTagId: string): void {
        const imageEdition = this._imageEditionByMediaId[this.currentMedia.id];
        const labels = imageEdition?.labels ?? [];
        const textModificationIndex = labels.findIndex((text) => text.malouTagId === malouTagId);
        if (textModificationIndex !== -1) {
            this._registerImageEdition(this.currentMedia.id, {
                ...imageEdition,
                labels: [...labels.slice(0, textModificationIndex), ...labels.slice(textModificationIndex + 1)],
                isNew: true,
            });
        }
    }

    private _setBgColor(bgColor: string, { emitChanges }: { emitChanges: boolean }): void {
        if (!this.currentMedia?.id) {
            return;
        }
        this.bgColor = bgColor;
        if (emitChanges) {
            const imageEdition = this._imageEditionByMediaId[this.currentMedia.id];
            if (imageEdition) {
                this._registerImageEdition(this.currentMedia.id, {
                    ...imageEdition,
                    backgroundColor: bgColor,
                    isNew: true,
                });
            }
        }
    }

    private _closeEditor(editionMode: EditionMode): void {
        switch (editionMode) {
            case EditionMode.TEXT_EDITING:
                this.toggleTextEdition();
                break;
            case EditionMode.ROTATING:
            case EditionMode.CROPPING:
                this.closeDimensionEditors();
                break;
        }
    }

    private _registerImageEdition(mediaId: string, imageEdition: ImageEdition): void {
        this._store.dispatch(StoriesActions.editImageEdition({ mediaId, imageEdition }));
    }

    private _getParent(element: HTMLElement): HTMLElement | null {
        return element.parentElement;
    }

    private _getParentAndUpdateTextModification(element: HTMLElement): void {
        const parent = this._getParent(element);
        if (!parent) {
            return;
        }
        const malouTagId = this._getMalouTagId(parent);
        const imageEdition = this._imageEditionByMediaId[this.currentMedia.id];
        if (!malouTagId || !imageEdition) {
            return;
        }
        this._updateTextModification({ malouTagId, imageEdition });
    }

    private _getParentOfCurrentElementAndUpdateTextModification(): void {
        const element = document.getElementById(EDIT_TEXT_ID);
        if (!element) {
            return;
        }
        this._getParentAndUpdateTextModification(element);
    }

    private _onMediasUploaded(medias: Media[]): void {
        this.medias = [...this.medias, ...medias];
        this._changeCurrentSelectedMedia(this._currentMediaIndex);
        this.fileChanged.emit(this.medias);
        this._spinnerService.hide();
    }

    private _canDoAction(): boolean {
        return !this.disabled && !this.isCurrentMediaVideo;
    }

    private _copyOldImageEditionToNewCroppedMedia(newMedia: Media): void {
        this._newCroppedMediaId = newMedia.id;
        this._imageEditionByMediaId[newMedia.id] = {
            ...this._imageEditionByMediaId[this.currentMedia.id],
            isNew: true,
        };
        this._registerImageEdition(newMedia.id, this._imageEditionByMediaId[newMedia.id]);
    }

    private _insertBrTag(): void {
        // https://stackoverflow.com/a/61237402
        document.execCommand('insertLineBreak');
    }

    private _getFontSizeValue(): number {
        return this.fontSizeForm.value ?? DEFAULT_FONT_SIZE;
    }
}
