import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';

/**
 * Material Checkbox that does not update when clicking on it.
 * You must use the 'checked' @Input to update the checkbox view.
 */
@Component({
    selector: 'app-noop-mat-checkbox',
    templateUrl: 'noop-mat-checkbox.component.html',
    styleUrls: ['./noop-mat-checkbox.component.scss'],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
        },
    ],
    standalone: true,
    imports: [NgClass, MatCheckboxModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoopMatCheckboxComponent {
    /**
     * Color
     */
    readonly color = input<ThemePalette>('primary');

    /**
     * Classes
     */
    readonly classInput = input<string>('');

    /**
     * Checked
     */
    readonly checked = input.required<boolean>();

    /**
     * Disabled
     */
    readonly disabled = input<boolean>(false);

    /**
     * Indeterminate
     */
    readonly indeterminate = input<boolean>(false);

    readonly testId = input<string>('');
}
