import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';
import { FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, Nfc, Restaurant } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedBoostersStatisticsData } from '../booster.interface';
import {
    AggregatedBoosterData,
    AggregatedBoostersScanCountChartComponent,
    AggregatedScansData,
    AggregatedTotemsData,
} from './aggregated-boosters-scan-count-chart/aggregated-boosters-scan-count-chart.component';

@Component({
    selector: 'app-aggregated-boosters-scan-count',
    templateUrl: './aggregated-boosters-scan-count.component.html',
    styleUrls: ['./aggregated-boosters-scan-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        AggregatedBoostersScanCountChartComponent,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedBoostersScanCountComponent implements OnInit {
    @Input() chartSortBy?: ChartSortBy;
    @Input() data$: Observable<AggregatedBoostersStatisticsData>;
    @Input() restaurants$: Observable<Restaurant[]>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() chartSortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly restaurants: WritableSignal<Restaurant[]> = signal([]);
    readonly Illustration = Illustration;
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    boostersData: WritableSignal<AggregatedTotemsData> = signal([]);
    hasData: WritableSignal<boolean> = signal(true);
    scanCountOnPeriod: WritableSignal<number | null> = signal(null);
    scanCountDifferenceWithPreviousPeriod: WritableSignal<number | null> = signal(null);
    wheelOfFortuneCountOnPeriod: WritableSignal<number | null> = signal(null);
    wheelOfFortuneCountDifferenceWithPreviousPeriod: WritableSignal<number | null> = signal(null);
    sortByChanged$ = new BehaviorSubject(false);

    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);

    constructor(private readonly _enumTranslate: EnumTranslatePipe) {}

    ngOnInit(): void {
        if (this.chartSortBy) {
            this.sortByControl.setValue(this.chartSortBy);
        }
        combineLatest([this.data$, this.restaurants$, this.sortByChanged$]).subscribe(([{ nfcs, scans }, restaurants, _]) => {
            if (!scans.length) {
                this.hasData.set(false);
                this.hasDataChange.emit(false);
                this.boostersData.set([]);
                return;
            }
            const sortedRestaurants = this._sortRestaurants(this.sortByControl.value, [...restaurants], scans);
            this.restaurants.set(sortedRestaurants);

            const restaurantIds = sortedRestaurants.map(({ _id }) => _id.toString());
            const wheelOfFortuneScansByRestaurant: (AggregatedScansData | null)[] = Array.from({ length: restaurantIds.length }).fill(
                null
            ) as (AggregatedScansData | null)[];

            const scansByNfc = nfcs.map((nfc) => {
                const restaurantIndex = restaurantIds.indexOf(nfc.restaurantId);
                const nfcScans = scans.filter((scan) => scan.nfcId === nfc.id && scan.nfcSnapshot?.restaurantId === nfc.restaurantId);
                if (nfc.id === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS) {
                    wheelOfFortuneScansByRestaurant[restaurantIndex] = nfcScans.length > 0 ? this._getScansByPlatform(nfc, nfcScans) : null;
                    return null;
                }
                const emptyRestaurantArray: (AggregatedScansData | null)[] = Array.from({ length: restaurantIds.length }).fill(
                    null
                ) as (AggregatedScansData | null)[];
                emptyRestaurantArray[restaurantIndex] = nfcScans.length > 0 ? this._getScansByPlatform(nfc, nfcScans) : null;
                return {
                    name: nfc.name ?? nfc.chipName,
                    scans: [...emptyRestaurantArray],
                };
            });
            const wheelOfFortuneName = nfcs.find((nfc) => nfc.id === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS)?.name ?? '';
            this.boostersData.set([
                { name: wheelOfFortuneName, scans: wheelOfFortuneScansByRestaurant },
                ...(scansByNfc.filter(Boolean) as AggregatedBoosterData[]),
            ]);
        });

        this.data$.subscribe(({ scans, previousScans, wheelOfFortuneCount, previousWheelOfFortuneCount }) => {
            this.wheelOfFortuneCountOnPeriod.set(wheelOfFortuneCount);
            this.wheelOfFortuneCountDifferenceWithPreviousPeriod.set(wheelOfFortuneCount - previousWheelOfFortuneCount);
            this.scanCountOnPeriod.set(scans.length);
            this.scanCountDifferenceWithPreviousPeriod.set(scans.length - previousScans.length);
            this.hasData.set(!!this.scanCountOnPeriod());
            this.hasDataChange.emit(!!this.scanCountOnPeriod());
        });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onSortByChange(sortByElt: ChartSortBy): void {
        this.sortByControl.setValue(sortByElt);
        this.chartSortByChange.emit(sortByElt);
        this.sortByChanged$.next(true);
    }

    private _sortRestaurants(sortByElt: ChartSortBy, restaurants: Restaurant[], scans: ScanForStats[]): Restaurant[] {
        if (sortByElt === ChartSortBy.ALPHABETICAL) {
            return restaurants.sort(sortRestaurantsByInternalNameThenName);
        }
        const totalByRestaurants = restaurants.map((restaurant) => ({
            restaurantId: restaurant.id,
            name: restaurant.name,
            total: scans.filter((scan) => scan.nfcSnapshot?.restaurantId === restaurant.id).length,
        }));
        const sortedTotalByRestaurants = orderBy(totalByRestaurants, 'total', sortByElt);
        const sortRestaurantByTotal = sortedTotalByRestaurants.map(
            ({ restaurantId }) => restaurants.find((rest) => rest.id === restaurantId) as Restaurant
        );
        return sortRestaurantByTotal;
    }

    private _getScansByPlatform(nfc: Nfc, nfcScans: ScanForStats[]): AggregatedScansData {
        const nfcScansByPlatformKey = groupBy(nfcScans, 'nfcSnapshot.statsPlatformKey');
        return Object.entries(nfcScansByPlatformKey).reduce(
            (acc, [platformKey, scans]) => ({
                ...acc,
                [platformKey]: scans.length,
            }),
            { total: nfcScans.length }
        );
    }
}
