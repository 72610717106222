import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadService {
    constructor(private readonly _http: HttpClient) {}

    downloadFile(url: string): Observable<Blob> {
        return this._http.get(`${url}?fingerprint=${Math.random()}`, { responseType: 'blob' });
    }
}
