import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    Injector,
    input,
    OnInit,
    output,
    runInInjectionContext,
    signal,
} from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { failedAccessStatus, PlatformCategory, PlatformGroup } from '@malou-io/package-utils';

import { PlatformsContext } from ':modules/platforms/platforms.context';
import { SearchComponent } from ':shared/components/search/search.component';
import { MappedPlatforms, PlatformState } from ':shared/models';

export enum ReconnectFilterOption {
    ALL = 'all',
    FAILED = 'failed',
}

@Component({
    selector: 'app-platforms-filters',
    templateUrl: './platforms-filters.component.html',
    styleUrls: ['./platforms-filters.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule, TranslateModule, SearchComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsFiltersComponent implements OnInit {
    readonly ReconnectFilterOption = ReconnectFilterOption;

    platforms = input.required<MappedPlatforms<PlatformCategory | PlatformGroup>>();
    searchChange = output<string>();
    reconnectFilterChange = output<ReconnectFilterOption>();

    private readonly _injector = inject(Injector);
    private readonly _platformsContext = inject(PlatformsContext);

    readonly shouldReconnectFilterBeVisible = computed(() => this._countPlatformsInError(this.platforms()) > 0);
    readonly text = signal('');

    reconnectFilter = ReconnectFilterOption.ALL;

    ngOnInit(): void {
        runInInjectionContext(this._injector, () =>
            effect(
                () => {
                    const platforms = this.platforms();
                    const connectedPlatformCount = this._countConnectedPlatforms(platforms);
                    const allPlatformCount = this._countPlatforms(platforms);
                    this._platformsContext.connectedPlatformCount.set(connectedPlatformCount);
                    this._platformsContext.allPlatformCount.set(allPlatformCount);
                },
                { allowSignalWrites: true }
            )
        );
    }

    onSearchChange(search: string): void {
        this.searchChange.emit(search);
    }

    changeReconnectFilterOption(option: ReconnectFilterOption): void {
        if (this.reconnectFilter !== option) {
            this.reconnectFilterChange.emit(option);
        }
        this.reconnectFilter = option;
    }

    private _countConnectedPlatforms(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce(
            (acc, platforms) => acc + platforms.filter((platform) => platform.state === PlatformState.CONNECTED).length,
            0
        );
    }

    private _countPlatforms(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce((acc, platforms) => acc + platforms.length, 0);
    }

    private _countPlatformsInError(platformsCategory: MappedPlatforms<PlatformCategory | PlatformGroup>): number {
        return Object.values(platformsCategory).reduce((acc, platforms) => {
            const failedPlatforms = platforms.filter((platform) => {
                const isConnectedPlatform = platform.state && [PlatformState.CONNECTED, PlatformState.FOUND].includes(platform.state);
                const hasFailedAccessStatus = platform.accessStatus && failedAccessStatus.includes(platform.accessStatus);
                return isConnectedPlatform && hasFailedAccessStatus;
            });
            return acc + failedPlatforms.length;
        }, 0);
    }
}
