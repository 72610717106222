/* eslint-disable @typescript-eslint/member-ordering */
import { Directive, ElementRef, HostListener, inject, input, output } from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
    standalone: true,
})
export class ClickOutsideDirective {
    private readonly _elementRef = inject(ElementRef);

    /**
     * You can add an additional element where the click is considered 'inside'
     * By default the element that contains the directive is considered 'inside'
     */
    additionalElement = input<Element>();
    /**
     * Use this if you want to omit a element, ie : if the click is inside this element, the click will be considered 'outside'.
     */
    omitElement = input<Element>();
    appClickOutside = output<Element>();

    @HostListener('document:click', ['$event.target'])
    onDocumentMouseClick(targetElement: Element): void {
        const omitElement = this.omitElement();
        const additionalElement = this.additionalElement();
        const insideOmitElement = omitElement ? omitElement.contains(targetElement) : false;
        const insideAdditionalElement = additionalElement ? additionalElement.contains(targetElement) : false;
        const insideMainElement = this._elementRef.nativeElement.contains(targetElement);
        const clickedInside = (insideMainElement || insideAdditionalElement) && !insideOmitElement;
        if (!clickedInside) {
            this.appClickOutside.emit(targetElement);
        }
    }
}
