<div class="malou-dialog malou-dialog-mobile flex h-full flex-col">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex w-full items-center justify-end">
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>
    </div>

    <div class="malou-dialog__body !m-0 flex flex-col gap-y-3 !overflow-y-hidden">
        <div class="mb-3 flex justify-center">
            <img class="mr-4 !h-[97px]" alt="Goggles" [src]="Illustration.Goggles | illustrationPathResolver" />
        </div>
        <div class="malou-color-text-1 malou-text-18--semibold text-center">
            {{ 'wheel_of_fortune.gift_out_of_stock.out_of_stock' | translate: { name: data.giftName } }}
        </div>
        <div class="malou-color-text-2 malou-text-13--regular text-center">
            {{ 'wheel_of_fortune.gift_out_of_stock.change_stocks_to_offer_gift' | translate }}
        </div>
    </div>

    <div class="malou-dialog__footer !justify-center">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.later' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="close(true)">
            {{ 'wheel_of_fortune.gift_out_of_stock.change_stocks' | translate }}
        </button>
    </div>
</div>
