import { createAction, props } from '@ngrx/store';

import { CommentsFilters } from ':shared/models';

export const editCommentsFilters = createAction('[Reviews] Edit_Comments_Filters', props<{ filterDiffs: CommentsFilters }>());

export const resetCommentsFilters = createAction('[Reviews] Reset_Comments_Filters', props<{ availablePlatforms: string[] }>());

export const editUnansweredCommentCount = createAction(
    '[Comments] Edit_Unanswered_Comments_Count',
    props<{ unansweredCommentCount: number }>()
);

export const editUnansweredMentionCount = createAction(
    '[Comments] Edit_Unanswered_Mentions_Count',
    props<{ unansweredMentionCount: number }>()
);

export const editPagination = createAction('[Comments] Edit_Pagination', props<{ pagination: { pageSize: number } }>());

export const editCommentCount = createAction('[Comments] Edit_Comment_Count', props<{ commentCount: number }>());
export const editMentionCount = createAction('[Comments] Edit_Mention_Count', props<{ mentionCount: number }>());
