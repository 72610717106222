<div
    class="width-transition flex h-full flex-col md:!w-screen"
    [ngClass]="{ 'w-[250px]': isSidenavOpened(), 'w-[80px]': !isSidenavOpened() }">
    <div class="flex min-h-0 grow flex-col">
        @if (!isSidenavOpened()) {
            <ng-container [ngTemplateOutlet]="openPanelButtonTemplate"></ng-container>
        }
        <div [class.mt-4]="isSidenavOpened()">
            <app-sidenav-content-select-restaurant [isEmptyState]="!!isRestaurantsListRoute()"></app-sidenav-content-select-restaurant>
        </div>
        @if (isAllRestaurantsSelected() && !isRestaurantsListRoute()) {
            <ng-container [ngTemplateOutlet]="groupRoutesTemplate"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="routesTemplate"></ng-container>
        }
    </div>
    <div class="flex flex-col gap-y-2">
        <ng-container [ngTemplateOutlet]="separatorTemplate"></ng-container>
        <div class="mx-4 flex flex-col gap-y-2">
            <ng-container [ngTemplateOutlet]="adminRouteTemplate"></ng-container>
            @if (doesCurrentLangSupportChatbot()) {
                <ng-container [ngTemplateOutlet]="needHelpTemplate"></ng-container>
            }
            <ng-container [ngTemplateOutlet]="referYourFriendsTemplate"></ng-container>
            <div class="h-2 shrink-0"></div>
            <ng-container [ngTemplateOutlet]="malouLogoTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #openPanelButtonTemplate>
    <div class="mx-4 my-1 flex justify-end">
        <app-sidenav-toggle-button
            [svgIconWhenClosed]="SvgIcon.LEFT_PANEL_CLOSE"
            [svgIconWhenOpen]="SvgIcon.LEFT_PANEL_CLOSE"></app-sidenav-toggle-button>
    </div>
</ng-template>

<ng-template #routesTemplate>
    @let restaurant = selectedRestaurant();
    <div class="hide-scrollbar m-4 flex flex-col gap-y-2 overflow-y-auto">
        @let base = ['/restaurants', restaurant?._id ?? ''];

        <app-sidenav-content-route
            [options]="{
                text: 'sidenav_content.calendar' | translate,
                routerLink: base | concat: ['dashboard'],
                svgIcon: SvgIcon.CALENDAR,
            }" />

        <app-sidenav-content-seo-routes></app-sidenav-content-seo-routes>

        <app-sidenav-content-boosters-routes></app-sidenav-content-boosters-routes>

        <app-sidenav-content-e-reputation-routes></app-sidenav-content-e-reputation-routes>

        <app-sidenav-content-social-networks-routes></app-sidenav-content-social-networks-routes>

        <app-sidenav-content-interactions-routes></app-sidenav-content-interactions-routes>

        <app-sidenav-content-insights-routes></app-sidenav-content-insights-routes>

        <div class="h-2 shrink-0"></div>

        <app-sidenav-content-resources-routes></app-sidenav-content-resources-routes>

        <app-sidenav-content-settings-routes></app-sidenav-content-settings-routes>
    </div>
</ng-template>

<ng-template #groupRoutesTemplate>
    <div class="hide-scrollbar m-4 flex flex-col gap-y-2 overflow-y-auto">
        <app-sidenav-content-route
            [options]="{
                text: 'sidenav_content.e_reputation' | translate,
                routerLink: ['/groups', 'reputation'],
                svgIcon: SvgIcon.STAR_BORDER,
            }" />

        <app-sidenav-content-aggregated-insights-routes></app-sidenav-content-aggregated-insights-routes>

        @if (!this.isPhoneScreen()) {
            <app-sidenav-content-route
                [options]="{
                    text: 'sidenav_content.boosters' | translate,
                    routerLink: ['/groups', 'boosters'],
                    svgIcon: SvgIcon.ROCKET,
                    secondarySvgIcon: !hasOneRestaurantWithBoosterPackActivated() ? SvgIcon.CROWN : undefined,
                }" />
        }
    </div>
</ng-template>

<ng-template #separatorTemplate>
    <div class="h-[1px] bg-malou-color-background-dark" (click)="onNeedHelpButtonClick()"></div>
</ng-template>

<ng-template #adminRouteTemplate>
    @if (userInfos()?.role === Role.ADMIN) {
        <app-sidenav-content-route
            [options]="{
                text: 'sidenav_content.admin' | translate,
                routerLink: ['admin'],
                svgIcon: SvgIcon.FOLDER_CHECK,
            }" />
    }
</ng-template>

<ng-template #needHelpTemplate>
    <div
        class="flex cursor-pointer items-center gap-x-3 rounded-md px-4 py-2 hover:bg-malou-color-background-dark"
        [ngClass]="{ '!justify-center !px-0': !isSidenavOpened() }"
        (click)="onNeedHelpButtonClick()">
        <div class="flex h-4 w-4 shrink-0 items-center justify-center rounded-full bg-malou-color-primary">
            <mat-icon class="!h-3 !w-3 text-white" [svgIcon]="SvgIcon.FRONT_CHAT"></mat-icon>
        </div>
        @if (isSidenavOpened()) {
            <div class="malou-color-text-2 malou-text-12--regular truncate">{{ 'sidenav_content.need_help' | translate }}</div>
        }
    </div>
</ng-template>

<ng-template #referYourFriendsTemplate>
    <div class="self-center" [class.w-full]="!isSidenavOpened()">
        <app-refer-your-friends [showOnlyIcon]="!isSidenavOpened()"></app-refer-your-friends>
    </div>
</ng-template>

<ng-template #malouLogoTemplate>
    <div class="mb-4 flex justify-center rounded">
        <img class="h-6 w-auto" [src]="(isSidenavOpened() ? 'malou_logo_black' : 'malou_initial_logo') | imagePathResolver" />
    </div>
</ng-template>
