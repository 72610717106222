import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, contentChild, forwardRef, input, output, TemplateRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-chip-list',
    templateUrl: 'select-chip-list.component.html',
    styleUrls: ['./select-chip-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectChipListComponent),
        },
    ],
    standalone: true,
    imports: [SelectBaseComponent, FormsModule, ReactiveFormsModule, MatIconModule, NgTemplateOutlet, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectChipListComponent<T> extends ControlValueAccessorConnectorComponent {
    /**
     * Use this template to customize each option.
     * By default, each option is the string computed with the 'displayWith' function.
     * Takes 2 parameters:
     *      - value: the value (an element of 'values' array)
     *      - isValueSelected: a function that will return if a value is currently selected
     */
    readonly optionTemplateInput = contentChild<TemplateRef<any>>('optionTemplate');
    readonly selectedValueTemplateInput = contentChild<TemplateRef<any>>('selectedValueTemplateInput');

    // ------------ CORE ------------//

    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<T[]>([]);

    readonly hideArrow = input<boolean>(false);

    /** Map each value from the 'values' array to a string to display (autocomplete works with these strings) */
    readonly displayWith = input<(option: any) => string>((option: any) => option);

    /** Compute unique hash key for an object */
    readonly computeObjectHash = input<((a?: any) => any) | undefined>();

    /** Track by function for @for */
    readonly filteredValuesTrackByFn = input<(index: number, item: any) => any>((_index: number, item: any) => item);

    // ------------ Multiple selected values ------------//

    /**
     * Determine if selected values will be displayed on one line or will wrap on multiple lines.
     * If false and the box is not wide enough to display all the selected values,
     * the maximum amount of selected values will be displayed and the rest will be counted with a '+X' displayed at the end of the line,
     * with X the count of all selected values that overflow.
     */
    readonly multiSelectionElementWrap = input<boolean>(false);

    /**
     * Maximum values to display in the input.
     * Infinite if null/undefined. At Maximum, equal to the length of 'values' array
     */
    readonly maxSelectedValuesToDisplay = input<number>(Number.MAX_SAFE_INTEGER);

    readonly shouldSwitchToWrapModeOnClick = input<boolean>(false);

    // ------------ Selection panel ------------//

    /**
     * Maximum values selectable.
     * Infinite if null/undefined. At Maximum, equal to the length of 'values' array
     */
    readonly maxSelectableValues = input<number | undefined>();

    /** Hide already selected values in panel */
    readonly hideSelectedValues = input<boolean>(false);

    readonly groupSelectedValuesAtTop = input<boolean>(false);

    // ------------ Options ------------//

    /**
     * Show checkbox to select options.
     * If true, the panel will not close when an option is selected.
     * If true, unique values cannot be selected more than one time.
     * Only take effect if multiSelection is true
     */
    readonly checkboxOption = input<boolean>(false);

    /** Show a checkbox to select all option at once in the selection panel header */
    readonly showSelectAllCheckbox = input<boolean>(false);

    readonly buildValueFromText = input<((text: string) => T) | undefined>();

    readonly selectedValues = input<any[]>([]);

    readonly selectAllCheckboxMessage = input<string | undefined>();

    readonly displayedOptionCount = input<number>(Number.MAX_SAFE_INTEGER);

    // ------------ other ------------//
    readonly testId = input<string>();

    // ------------ Event ------------//

    /** On change event */
    readonly selectChipListChange = output<T[]>();

    readonly SvgIcon = SvgIcon;

    readonly inputAutoCompleteControl = new UntypedFormControl('');

    doesItemExist = (controlValue: any, values: T[]): boolean => {
        if (!controlValue) {
            return false;
        }

        return !!values?.find((item) => this.displayWith()(item)?.toLowerCase()?.trim() === controlValue?.toLowerCase()?.trim());
    };
}
