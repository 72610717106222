import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PlatformAccessStatus, PlatformAccessType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { AuthorizedAccountManagedPlatformKeys } from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import { ApiResult, Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpdatePlatformAccessService {
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: AuthorizedAccountManagedPlatformKeys): Observable<ApiResult<Restaurant>> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        return this._restaurantsService.createPlatformAccess(restaurantId, {
            platformKey: platformKey,
            accessType: PlatformAccessType.MANAGER,
            status: PlatformAccessStatus.NEED_REVIEW,
            lastUpdated: new Date(),
            lastVerified: undefined,
            active: true,
        });
    }
}
