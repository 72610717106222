<div class="malou-dialog malou-dialog-mobile flex h-full flex-col">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex w-full items-center justify-end">
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>
    </div>

    <div class="malou-dialog__body !m-0 flex flex-col gap-y-3 !overflow-y-hidden">
        <div class="mb-3 flex justify-center">
            <img class="mr-4 !h-[97px]" alt="Goggles" [src]="Illustration.Goggles | illustrationPathResolver" />
        </div>
        <div class="malou-color-text-1 malou-text-18--semibold text-center">
            {{ 'wheel_of_fortune.new_wheel_modal.create_aggregated_or_restaurant_wheel_modal.did_you_know' | translate }}
        </div>
        <div class="malou-color-text-2 malou-text-13--regular text-center">
            {{ 'wheel_of_fortune.new_wheel_modal.create_aggregated_or_restaurant_wheel_modal.go_to_all_locations' | translate }}
        </div>
        <div class="mt-3 pl-10">
            <mat-checkbox color="primary" [checked]="doNotShowAgain()" (change)="setDoNotShowAgain($event)">
                <div class="malou-color-text-2 malou-text-10--regular">
                    {{ 'wheel_of_fortune.new_wheel_modal.create_aggregated_or_restaurant_wheel_modal.do_not_show_again' | translate }}
                </div>
            </mat-checkbox>
        </div>
    </div>

    <div class="malou-dialog__footer !justify-center">
        <button
            class="malou-btn-raised--secondary !h-11 md:grow"
            data-testid="create-wheel-for-every-businesses-btn"
            mat-raised-button
            (click)="close(WheelOfFortuneType.AGGREGATED)">
            {{ 'wheel_of_fortune.new_wheel_modal.create_aggregated_or_restaurant_wheel_modal.create_for_all' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11 md:grow"
            data-testid="create-wheel-for-this-business-btn"
            mat-raised-button
            (click)="close(WheelOfFortuneType.RESTAURANT)">
            {{ 'wheel_of_fortune.new_wheel_modal.create_aggregated_or_restaurant_wheel_modal.create_for_this_location' | translate }}
        </button>
    </div>
</div>
