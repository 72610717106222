<div class="flex h-screen w-full flex-col items-center bg-malou-color-background-light">
    <img class="mt-24 h-8" [src]="logoUrl()" />
    <div class="malou-text-24--bold mt-20 text-malou-color-text-1">
        {{ 'play_wheel_of_fortune.desktop.go_to_smartphone' | translate }}
    </div>
    <div class="malou-text-13--regular mt-4 max-w-[550px] text-center italic text-malou-color-text-2">
        {{ 'play_wheel_of_fortune.desktop.scan_qr_code' | translate }}
    </div>
    <img class="mt-4 h-[300px] w-[300px]" [src]="qrCodeImage()" />
</div>
