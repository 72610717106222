import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectTotemsFilter } from ':modules/statistics/store/statistics.selectors';
import { MAX_TOTEM_NUMBER_FOR_FILTER } from ':shared/constants/filters';
import { Nfc } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class StatisticsFiltersContext {
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _nfcService = inject(NfcService);

    readonly currentSelectedRestaurant$ = this._restaurantsService.restaurantSelected$;
    readonly currentSelectedRestaurantId = toSignal(
        this.currentSelectedRestaurant$.pipe(
            filter(isNotNil),
            map((restaurant) => restaurant._id)
        ),
        {
            initialValue: '',
        }
    );

    readonly restaurantTotems$: Observable<Nfc[]> = this._nfcService
        .search({ page: 1, limit: MAX_TOTEM_NUMBER_FOR_FILTER, restaurantId: this._restaurantsService.currentRestaurant._id })
        .pipe(
            map((nfcApiResult) => nfcApiResult.data.map((e) => Nfc.fromNfcDto(e))),
            takeUntilDestroyed(this._destroyRef)
        );

    readonly selectedTotems$ = combineLatest([this.restaurantTotems$, this._store.select(selectTotemsFilter)]).pipe(
        filter(([totems, _]) => totems.length > 0),
        map(([totems, selectedTotemIds]) => totems.filter((totem) => selectedTotemIds.includes(totem.id)))
    );
}
