import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ButtonComponent } from ':shared/components/button/button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum ButtonStyle {
    // In row, right aligned, one gray background, one purple background
    CLASSIC = 'CLASSIC',
    // In column, all gray background
    LONG_TEXT = 'LONG_TEXT',
}

/**
 * Component that represent our modal structure.
 *
 * It contains:
 * - A header with title and close button
 * - A scrollable body
 * - A footer with 2 buttons
 */
@Component({
    selector: 'app-modal-structure',
    templateUrl: './modal-structure.component.html',
    styleUrls: ['./modal-structure.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatButtonModule, ButtonComponent],
})
export class ModalStructureComponent {
    readonly title = input.required<string>();
    readonly titleImage = input<{ class: string; src: string } | null>(null);
    readonly buttonStyle = input.required<ButtonStyle>();

    readonly primaryButtonText = input.required<string>();
    readonly primaryButtonSubText = input<string | null>(null);
    readonly primaryButtonDisabled = input<boolean>(false);

    readonly secondaryButtonText = input.required<string>();
    readonly secondaryButtonSubText = input<string | null>(null);

    readonly tertiaryButtonText = input('');

    readonly SvgIcon = SvgIcon;
    readonly showTertiaryButton = input<boolean>(false);

    readonly submitting = input<boolean>(false);

    readonly ButtonStyle = ButtonStyle;
    readonly close = output<void>();
    readonly primaryClick = output<void>();
    readonly secondaryClick = output<void>();
    readonly tertiaryClick = output<void>();

    onClose(): void {
        this.close.emit();
    }

    onPrimaryButtonClick(): void {
        this.primaryClick.emit();
    }

    onSecondaryButtonClick(): void {
        this.secondaryClick.emit();
    }

    onTertiaryButtonClick(): void {
        this.tertiaryClick.emit();
    }
}
