import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getSelectedKeywordsCountByRestaurantIdParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type GetSelectedKeywordsCountByRestaurantIdParamsDto = z.infer<typeof getSelectedKeywordsCountByRestaurantIdParamsValidator>;
