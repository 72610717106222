import { EntityState } from '@ngrx/entity';

import { Brick } from ':shared/models';

export interface GenerationForm {
    categoryList: Brick[];
    touristics: Brick[];
    specials: Brick[];
    offers: Brick[];
    equipment: Brick[];
    attributes: Brick[];
    audience: Brick[];
    postalCode?: string;
    apiLocationId?: string;
}

export const formKeysOrder: (keyof GenerationForm)[] = [
    'categoryList',
    'specials',
    'touristics',
    'offers',
    'attributes',
    'equipment',
    'audience',
    'postalCode',
];

export interface GenerationFormWithRestaurantId extends GenerationForm {
    restaurantId: string;
}

export interface GenerationFormWithCountry extends GenerationForm {
    country: string;
}

export interface GenerationFormsState extends EntityState<GenerationFormWithRestaurantId> {
    selectedFormId: string | null;
}

export const DEFAULT_GENERATION_FORM: GenerationForm = {
    categoryList: [],
    touristics: [],
    specials: [],
    offers: [],
    equipment: [],
    attributes: [],
    audience: [],
};
