import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StarIconsPipe } from ':shared/pipes/star-icon.pipe';

@Component({
    selector: 'app-rate-with-stars',
    templateUrl: './rate-with-stars.component.html',
    styleUrls: ['./rate-with-stars.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, IncludesPipe, StarIconsPipe, CreateArrayPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateWithStarsComponent {
    readonly starClicked = output<number>();

    readonly SvgIcon = SvgIcon;

    readonly starHoveredIndex = signal<number | null>(null);

    onStarClicked(index: number): void {
        this.starClicked.emit(index + 1);
    }
}
