<div class="malou-dialog">
    <div class="malou-dialog__header">
        <span>
            {{ 'notification-center.popins.special_hour.title' | translate }}
        </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"> </mat-icon>
    </div>

    <div class="malou-dialog__body !overflow-hidden">
        <div class="flex flex-col gap-y-8">
            <div class="flex">
                <span class="malou-color-text-2 malou-text-12 italic">
                    {{ 'notification-center.popins.special_hour.subtext' | translate }}
                </span>
            </div>
            <div class="flex items-center justify-center gap-x-6">
                <span class="malou-text-50">
                    {{ data.notification.getNotificationEmoji | applyPure }}
                </span>
                <div class="flex flex-col gap-y-2">
                    <span class="malou-color-text-1 malou-text-18--semibold">
                        {{ data.notification.getFormattedEventDate | applyPure }}
                    </span>
                    <span class="malou-color-text-2 malou-text-12">
                        {{ data.notification.getNotificationName | applyPure }}
                    </span>
                </div>
            </div>

            <div class="flex flex-col gap-y-3">
                <button
                    class="malou-btn-raised--secondary--alt button-selected !py-7"
                    mat-raised-button
                    [ngClass]="{ 'option-selected': selectedOption() === SpecialHourNotificationAction.OPEN_AS_USUAL }"
                    (click)="selectOption(SpecialHourNotificationAction.OPEN_AS_USUAL)">
                    {{ 'notification-center.popins.special_hour.open_as_usual' | translate }}
                </button>
                <button
                    class="malou-btn-raised--secondary--alt !py-7"
                    mat-raised-button
                    [ngClass]="{ 'option-selected': selectedOption() === SpecialHourNotificationAction.CLOSED }"
                    (click)="selectOption(SpecialHourNotificationAction.CLOSED)">
                    {{ 'notification-center.popins.special_hour.closed' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="malou-dialog__footer">
        <button class="malou-btn-raised--secondary !py-6" mat-raised-button (click)="navigateToInformation()">
            {{ 'notification-center.popins.special_hour.update_special_hours' | translate }}
        </button>
        <button class="malou-btn-raised--primary !py-6" mat-raised-button [disabled]="!selectedOption()" (click)="updateHours()">
            {{ 'notification-center.popins.special_hour.update' | translate }}
        </button>
    </div>
</div>
