import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, TooltipItem } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ReviewsRating } from ':modules/reviews/reviews.interface';
import {
    ChartDataArray,
    malouChartColorBluePurple,
    malouChartColorLighterBlue,
    malouChartColorLighterPink,
    malouChartColorLightPink,
    malouChartColorLightPurple,
    malouChartColorPink,
    malouChartColorText2,
} from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

type DoughnutChartType = Extract<ChartType, 'doughnut'>;

const colors = [
    malouChartColorBluePurple,
    malouChartColorLightPurple,
    malouChartColorLightPink,
    malouChartColorLighterPink,
    malouChartColorPink,
    malouChartColorLighterBlue,
];

@Component({
    selector: 'app-reviews-ratings-total-chart',
    templateUrl: './reviews-ratings-total-chart.component.html',
    styleUrls: ['./reviews-ratings-total-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [PluralTranslatePipe, EnumTranslatePipe],
})
export class ReviewsRatingsTotalChartComponent implements OnInit, OnChanges {
    @Input() reviewsRatings: ReviewsRating[];
    @Input() labels: string[];

    readonly CHART_TYPE: DoughnutChartType = 'doughnut';

    chartDataSets: ChartDataset<DoughnutChartType, ChartDataArray>[];
    chartLabels: string[];
    chartOption: ChartOptions<DoughnutChartType>;
    showChartAnnotationTooltip = false;
    totalReviewRatingsCount = 0;

    isPhoneScreen = this._screenSizeService.isPhoneScreen;

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions(this.isPhoneScreen);

        this._screenSizeService.resize$.subscribe((elt) => {
            const isPhoneScreen = elt.size === ScreenSize.IsSmallScreen;
            if (isPhoneScreen !== this.isPhoneScreen) {
                this.isPhoneScreen = isPhoneScreen;
                this._computeChartOptions(isPhoneScreen);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviewsRatings')) {
            this.totalReviewRatingsCount = this.reviewsRatings.reduce((acc, curr) => acc + curr.total, 0);
            this.chartDataSets = this._computeChartData(this.reviewsRatings);
        }
    }

    private _computeChartData(data: ReviewsRating[]): ChartDataset<DoughnutChartType, ChartDataArray>[] {
        return [
            {
                backgroundColor: colors,
                borderColor: colors,
                data: data.map((rating) => rating.total),
                borderWidth: 0,
            },
        ];
    }

    private _computeChartOptions(isPhoneScreen: boolean): ChartOptions<DoughnutChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (tooltipItems: TooltipItem<any>) => this._computeTooltipLabel(tooltipItems),
                    },
                },
                legend: {
                    position: isPhoneScreen ? 'top' : 'right',
                    labels: {
                        generateLabels: (chart: Chart) => this._computeLegendLabels(chart),
                    },
                    onHover: (_event, _legendItem, legend): void => {
                        legend.chart.canvas.style.cursor = 'normal';
                    },
                },
            },
            cutout: '80%',
            layout: {
                padding: {
                    top: 10,
                    bottom: 20,
                },
            },
        };
    }

    private _computeLegendLabels(chart: Chart): LegendItem[] {
        return (
            chart.data.labels?.map((label: string, index: number) => {
                const rating: number | null = this._labelToRating(label);
                const title = rating
                    ? rating.toString()
                    : this._translateService.instant('statistics.e_reputation.reviews_ratings.without_rating');
                const starsText = this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_ratings.stars', rating as number);
                const platformRatingPercentage = Math.round((this.reviewsRatings[index].total * 100) / this.totalReviewRatingsCount);
                const legendText = `${title} ${starsText}: ${this.reviewsRatings[index].total} (${platformRatingPercentage}%)`;
                return {
                    text: legendText,
                    fillStyle: colors[index],
                    lineWidth: 0,
                    fontColor: malouChartColorText2,
                };
            }) || []
        );
    }

    private _computeTooltipLabel(item: TooltipItem<DoughnutChartType>): string[] {
        const rating: number | null = this._labelToRating(item.label);
        const labels: string[] = [];

        if (rating === null) {
            labels.push(this._translateService.instant('statistics.e_reputation.reviews_ratings.without_rating'));
        } else {
            labels.push(new Array(rating).fill('★').join(''));
        }

        const reviewsRating: ReviewsRating | undefined = this.reviewsRatings.find((e) => e.rating === rating);
        const otherLines: string[] =
            reviewsRating?.platforms.map((e) => `${this._enumTranslate.transform(e.key, 'platform_key')}: ${e.nbReviews}`) || [];
        labels.push(...otherLines);

        return labels;
    }

    private _labelToRating(label: string): number | null {
        return label === '0' ? null : parseInt(label, 10);
    }
}
