<div class="malou-dialog">
    <div class="malou-dialog__body !p-8">
        <div class="flex flex-col items-center">
            <img class="mb-6 h-28" [src]="data.picture | illustrationPathResolver" />
            <p class="malou-text-18--bold text-center text-malou-color-text-1">{{ data.title }}</p>
            @for (detail of data.details; track detail) {
                <p class="malou-text-13--medium mt-6 w-[410px] text-center md:!w-full">{{ detail }}</p>
            }
        </div>
        <div class="my-5 flex justify-center gap-4">
            @if (data.secondaryButtonLabel) {
                <button
                    class="malou-btn-raised--secondary btn-xl !h-11"
                    data-testid="after-create-restaurant-secondary-btn"
                    mat-raised-button
                    (click)="data.onClickSecondaryButton && data.onClickSecondaryButton(); close()">
                    {{ data.secondaryButtonLabel }}
                </button>
            }
            <button
                class="malou-btn-raised--primary btn-xl !h-11"
                mat-raised-button
                (click)="data.onClickPrimaryButton && data.onClickPrimaryButton(); close()">
                {{ data.primaryButtonLabel }}
            </button>
        </div>
    </div>
</div>
