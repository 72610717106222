<div
    class="absolute right-0 top-0 z-20 h-full w-full translate-x-[110%] overflow-hidden bg-malou-color-background-light p-5 transition-all duration-300 ease-in-out md:z-30 md:h-[80vh] md:w-[100vw] md:overflow-y-auto md:bg-white md:pt-0"
    [class.!translate-x-0]="isOpen()"
    (click)="$event.stopPropagation()">
    <div class="flex items-center gap-x-2 md:sticky md:left-0 md:top-0 md:z-10 md:bg-white md:p-5">
        <mat-icon
            class="!w-4 rotate-180 cursor-pointer"
            color="primary"
            [svgIcon]="SvgIcon.ARROW_RIGHT"
            (click)="upsertSocialPostHashtagsContext.closePostHashtagsPanel()"></mat-icon>
        <span class="malou-text-14--bold text-malou-color-text-2">{{ 'social_posts.new_social_post.hashtags' | translate }}</span>
    </div>

    <div class="relative mt-4 flex flex-col gap-y-2 rounded-lg border border-malou-color-border-primary bg-white p-3.5 md:mt-0">
        <div class="flex items-center gap-x-2">
            @if (isSelectedHashtagsLengthInRecommandations()) {
                <mat-icon class="!w-4 !text-malou-color-state-success" [svgIcon]="SvgIcon.CHECK"></mat-icon>
            } @else {
                <mat-icon class="!w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            }
            <span class="malou-text-10--medium text-malou-color-text-1">{{
                'social_posts.new_social_post.hashtags_number' | translate
            }}</span>
        </div>
        <div class="flex items-center gap-x-2">
            @if (isMainHashtagSelected()) {
                <mat-icon class="!w-4 !text-malou-color-state-success" [svgIcon]="SvgIcon.CHECK"></mat-icon>
            } @else {
                <mat-icon class="!w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            }
            <span class="malou-text-10--medium text-malou-color-text-1">{{
                'social_posts.new_social_post.main_hashtag_selected' | translate
            }}</span>
        </div>
        <div class="flex items-center gap-x-2">
            @if (doHashtagsContainRestaurantHashtags()) {
                <mat-icon class="!w-4 !text-malou-color-state-success" [svgIcon]="SvgIcon.CHECK"></mat-icon>
            } @else {
                <mat-icon class="!w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            }

            <span class="malou-text-10--medium gap-x-2 text-malou-color-text-1"
                >{{ 'social_posts.new_social_post.hashtags_type' | translate
                }}<span class="malou-text-9--regular italic text-malou-color-text-2"> (ex: #italianparis14) </span>
            </span>
        </div>
        @if (upsertSocialPostHashtagsContext.isGeneratingHashtags()) {
            <div class="absolute right-3 top-3">
                <app-malou-spinner color="primary" [size]="'xs'"></app-malou-spinner>
            </div>
        }
    </div>

    <mat-tab-group class="mt-2.5 grow" mat-align-tabs="start" [(selectedIndex)]="currentTabIndex">
        <mat-tab label="{{ 'social_posts.new_social_post.hashtags_recommendations' | translate }}">
            <app-search
                [shouldBuildValueIfNone]="true"
                [debounceTime]="0"
                [placeholder]="'common.search' | translate"
                (valueBuilt)="onNewSuggestionAdded($event)"
                (searchChange)="searchChanged($event, true)"></app-search>
            <div class="hide-scrollbar mt-2.5 flex h-[40vh] flex-col gap-y-1 overflow-auto pb-3 md:h-[24vh] md:pb-3">
                @if (upsertSocialPostHashtagsContext.isGeneratingHashtags()) {
                    <app-skeleton gapClass="gap-2" skeletonClass="!h-[2rem] !w-full secondary-bg" [count]="10"></app-skeleton>
                } @else {
                    @for (hashtag of displayedGeneratedHashtags(); track $index) {
                        @if (hashtag.isMain) {
                            <ng-container *ngTemplateOutlet="mainHashtag; context: { hashtag: hashtag }"></ng-container>
                        } @else {
                            <ng-container *ngTemplateOutlet="hashtagTemplate; context: { hashtag: hashtag }"></ng-container>
                        }
                    }
                }
            </div>
        </mat-tab>
        <mat-tab label="{{ 'common.favorites' | translate }}">
            <app-search
                [shouldBuildValueIfNone]="true"
                [debounceTime]="0"
                [placeholder]="'common.search' | translate"
                (valueBuilt)="onNewBookmarkedHashtagsAdded($event)"
                (searchChange)="searchChanged($event, false)"></app-search>
            <div class="hide-scrollbar mt-2.5 flex h-[40vh] flex-col gap-y-1 overflow-auto pb-3 md:h-[24vh]">
                @for (hashtag of displayedBookmarkedHashtags(); track $index) {
                    @if (hashtag.isMain) {
                        <ng-container *ngTemplateOutlet="mainHashtag; context: { hashtag: hashtag }"></ng-container>
                    } @else {
                        <ng-container *ngTemplateOutlet="hashtagTemplate; context: { hashtag: hashtag }"></ng-container>
                    }
                }
            </div>
        </mat-tab>
    </mat-tab-group>
    <button
        class="malou-btn-raised--primary sticky bottom-0 left-0 mt-2 !hidden !h-auto w-full items-center md:!flex md:items-center md:justify-center"
        mat-raised-button
        (click)="upsertSocialPostHashtagsContext.closePostHashtagsPanel()">
        {{ 'common.validate' | translate }}
    </button>
</div>

<ng-template let-hashtag="hashtag" #mainHashtag>
    <div class="main-hashtag flex items-center justify-between rounded-sm bg-white px-4 py-2.5">
        @if (isEditingMainHashtag()) {
            <div class="flex w-full items-center gap-x-2">
                <img class="h-4 w-4 rounded-full" [src]="restaurantLogo()" />
                <input
                    class="malou-text-10--medium mr-4 box-border h-full w-full border-b border-malou-color-border-secondary bg-white pb-1 !text-malou-color-text-1 outline-none placeholder:italic"
                    [class.border-malou-color-state-error]="mainHashtagTextFormControl.invalid"
                    [formControl]="mainHashtagTextFormControl"
                    [defaultValue]="mainHashtagTextFormControl.value" />
            </div>
            <div class="flex items-center gap-x-2">
                <div
                    class="grid h-4 w-4 cursor-pointer place-items-center rounded-full bg-malou-color-text-1"
                    (click)="toggleEditingMainHashtag()">
                    <mat-icon class="!h-2.5 !w-2.5 cursor-pointer !text-white" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </div>
                <div
                    class="grid h-4 w-4 cursor-pointer place-items-center rounded-full bg-malou-color-state-success"
                    (click)="updateMainHashtag()">
                    <mat-icon class="!h-2.5 !w-2.5 !text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                </div>
            </div>
        } @else {
            <div class="flex items-center gap-x-2 truncate">
                <img class="h-4 w-4 rounded-full" [src]="restaurantLogo()" />
                <div class="malou-text-10--medium max-w-[80%] truncate text-malou-color-text-1">{{ hashtag.text }}</div>
            </div>

            <div class="flex items-center gap-x-3">
                <mat-icon
                    class="!h-4 !w-4 cursor-pointer opacity-0"
                    id="edit-main-hashtag"
                    color="primary"
                    [svgIcon]="SvgIcon.EDIT"
                    (click)="toggleEditingMainHashtag()"></mat-icon>
                @if (isHashtagSelected(hashtag)) {
                    <div
                        class="grid h-4 w-4 cursor-pointer place-items-center rounded-full bg-malou-color-primary"
                        (click)="toggleChooseHashtag(hashtag)">
                        <mat-icon class="!h-2.5 !w-2.5 !text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    </div>
                } @else {
                    <mat-icon
                        class="!h-4 !w-4 cursor-pointer text-malou-color-border-secondary"
                        id="add-main-hashtag"
                        [svgIcon]="SvgIcon.ADD"
                        (click)="toggleChooseHashtag(hashtag)"></mat-icon>
                }
            </div>
        }
    </div>
</ng-template>

<ng-template let-hashtag="hashtag" #hashtagTemplate>
    <div class="flex items-center justify-between rounded-sm bg-white px-4 py-2.5">
        <div class="flex items-center gap-x-2">
            @if (isHashtagBookmarked(hashtag)) {
                <mat-icon
                    class="bookmark active cursor-pointer"
                    [svgIcon]="SvgIcon.BOOKMARK_FILLED"
                    (click)="toggleBookmarkHashtag(hashtag)"></mat-icon>
            } @else {
                <mat-icon
                    class="bookmark cursor-pointer"
                    [svgIcon]="SvgIcon.BOOKMARK"
                    [class.active]="false"
                    (click)="toggleBookmarkHashtag(hashtag)"></mat-icon>
            }
            <span class="malou-text-10--medium text-malou-color-text-1">{{ hashtag.text }}</span>
        </div>
        @if (isHashtagSelected(hashtag)) {
            <div
                class="grid h-4 w-4 cursor-pointer place-items-center rounded-full bg-malou-color-primary"
                (click)="toggleChooseHashtag(hashtag)">
                <mat-icon class="!h-2.5 !w-2.5 !text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
            </div>
        } @else {
            <mat-icon
                class="!h-4 !w-4 cursor-pointer text-malou-color-border-secondary"
                [svgIcon]="SvgIcon.ADD"
                (click)="toggleChooseHashtag(hashtag)"></mat-icon>
        }
    </div>
</ng-template>
