import { DatesAndPeriod, MalouDateFilters, MalouPeriod } from ':shared/models';

export function getRealDateByPeriod(dateFilter: DatesAndPeriod, startOrEndDate: 'startDate' | 'endDate'): Date | null {
    // We should use this function because we save those filters in local storage
    // But we can have last_7_days with startDate and endDate that are not the last 7 days
    // So we need to get the real date by period
    if (dateFilter.period === MalouPeriod.CUSTOM) {
        return dateFilter[startOrEndDate];
    }
    const malouDateFilters = new MalouDateFilters();
    return malouDateFilters.getFilter({ period: dateFilter.period })[startOrEndDate];
}
