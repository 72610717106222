<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex gap-x-2">
            @if (titleImage()) {
                <img [class]="titleImage()?.class" [src]="titleImage()?.src" />
            }
            <div>
                {{ title() }}
            </div>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="onClose()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <ng-content></ng-content>
    </div>

    @if (buttonStyle() === ButtonStyle.CLASSIC) {
        <div class="malou-dialog__footer md:justify-between">
            @if (showTertiaryButton()) {
                <button class="malou-btn-flat !h-10 md:grow" mat-button (click)="onTertiaryButtonClick()">
                    {{ tertiaryButtonText() }}
                </button>
            }
            <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="onSecondaryButtonClick()">
                <span>{{ secondaryButtonText() }}</span>
                @if (secondaryButtonSubText()) {
                    <span class="malou-text-10--regular italic">&nbsp;{{ secondaryButtonSubText() }}</span>
                }
            </button>
            <app-button
                class="md:grow"
                buttonClasses="!h-10 md:w-full"
                testId="modal-primary-button"
                [disabled]="primaryButtonDisabled()"
                [loading]="submitting()"
                (buttonClick)="onPrimaryButtonClick()">
                <ng-template #textTemplate>
                    <span>{{ primaryButtonText() }}</span>
                    @if (primaryButtonSubText()) {
                        <span class="malou-text-10--regular italic">&nbsp;{{ primaryButtonSubText() }}</span>
                    }
                </ng-template>
            </app-button>
        </div>
    } @else if (buttonStyle() === ButtonStyle.LONG_TEXT) {
        <div class="malou-dialog__footer flex-col">
            <button
                class="malou-btn-raised--secondary malou-color-text-1 !h-10 w-full"
                data-testid="modal-long-text-primary-btn"
                mat-raised-button
                [disabled]="primaryButtonDisabled()"
                (click)="onPrimaryButtonClick()">
                <span>{{ primaryButtonText() }}</span>
                @if (primaryButtonSubText()) {
                    <span class="malou-text-10--regular italic">&nbsp;{{ primaryButtonSubText() }}</span>
                }
            </button>

            <button
                class="malou-btn-raised--secondary malou-color-text-1 !h-10 w-full"
                data-testid="modal-long-text-secondary-btn"
                mat-raised-button
                (click)="onSecondaryButtonClick()">
                <span>{{ secondaryButtonText() }}</span>
                @if (secondaryButtonSubText()) {
                    <span class="malou-text-10--regular italic">&nbsp;{{ secondaryButtonSubText() }}</span>
                }
            </button>
        </div>
    }
</div>
