import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Tick } from 'chart.js/dist/types';
import { NgChartsModule } from 'ng2-charts';

import { SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { ChartDataArray, ChartDataElement, malouChartColorGreen, malouChartColorRed } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

const RESTAURANT_NAME_MAX_LENGTH = 20;

type BarChartType = Extract<ChartType, 'bar'>;

export interface TagEvolutionData {
    [SemanticAnalysisSentiment.POSITIVE]: ChartDataArray;
    [SemanticAnalysisSentiment.NEGATIVE]: ChartDataArray;
}

@Component({
    selector: 'app-tags-evolution-chart',
    templateUrl: './tags-evolution-chart.component.html',
    styleUrls: ['./tags-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
})
export class TagsEvolutionChartComponent implements OnChanges {
    @Input() tagEvolutionData: TagEvolutionData;
    @Input() labels: string[] = [];
    @Input() sortBy: ChartSortBy;

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: string[];
    chartOptions: ChartOptions<BarChartType>;

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _shortTextPipe: ShortTextPipe
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'tagEvolutionData') && this.tagEvolutionData) {
            this.chartDataSets = this._computeChartData(this.tagEvolutionData);
            this.chartOptions = this._computeChartOptions();
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'labels')) {
            this.chartLabels = this._computeChartLabels(this.labels);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'sortBy')) {
            const { evolutionData, labels } = this._computeSortedData(this.sortBy);
            this.tagEvolutionData = evolutionData;
            this.labels = labels ?? [];
            this.chartDataSets = this._computeChartData(this.tagEvolutionData);
            this.chartLabels = this._computeChartLabels(this.labels);
        }
    }

    private _computeChartData(tagEvolutionData: TagEvolutionData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.negative_sentiments'),
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[SemanticAnalysisSentiment.NEGATIVE],
            },
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments'),
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[SemanticAnalysisSentiment.POSITIVE],
            },
        ];
    }

    private _computeSortedData(sortBy: ChartSortBy): { evolutionData: TagEvolutionData; labels: string[] } {
        const data = (this.labels ?? []).map((label, index) => ({
            label,
            positive: this.tagEvolutionData[SemanticAnalysisSentiment.POSITIVE][index],
            negative: this.tagEvolutionData[SemanticAnalysisSentiment.NEGATIVE][index],
            total:
                (this.tagEvolutionData[SemanticAnalysisSentiment.POSITIVE][index] ?? 0) +
                (this.tagEvolutionData[SemanticAnalysisSentiment.NEGATIVE][index] ?? 0),
        }));
        switch (sortBy) {
            case ChartSortBy.ALPHABETICAL:
                const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));
                return this._reduceSortedData(sortedData);
            case ChartSortBy.ASC:
                const sortedDataAsc = data.sort((a, b) => a.total - b.total);
                return this._reduceSortedData(sortedDataAsc);
            case ChartSortBy.DESC:
                const sortedDataDesc = data.sort((a, b) => b.total - a.total);
                return this._reduceSortedData(sortedDataDesc);
            default:
                return { evolutionData: this.tagEvolutionData, labels: this.labels };
        }
    }

    private _reduceSortedData(data: { label: string; positive: ChartDataElement; negative: ChartDataElement; total: number }[]): {
        evolutionData: TagEvolutionData;
        labels: string[];
    } {
        return data.reduce(
            (acc, { label, positive, negative }) => ({
                evolutionData: {
                    [SemanticAnalysisSentiment.POSITIVE]: [...acc.evolutionData[SemanticAnalysisSentiment.POSITIVE], positive],
                    [SemanticAnalysisSentiment.NEGATIVE]: [...acc.evolutionData[SemanticAnalysisSentiment.NEGATIVE], negative],
                },
                labels: [...acc.labels, label],
            }),
            { evolutionData: { [SemanticAnalysisSentiment.POSITIVE]: [], [SemanticAnalysisSentiment.NEGATIVE]: [] }, labels: [] }
        );
    }

    private _computeChartLabels(labels: string[]): string[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number, index: number, _ticks: Tick[]): string => {
                            const label = this.labels[index];
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }
}
