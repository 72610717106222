import { PlatformKey } from '@malou-io/package-utils';

import { IStoryVideoCheck } from ':core/constants';

const MAX_DURATION_VIDEO = 59;
const MIN_DURATION_VIDEO = 3;
const MAX_WIDTH_VIDEO = 1920;
const MIN_HEIGHT_VIDEO_FACEBOOK = 960;

const MAX_RATIO_VIDEO_FACEBOOK = 9 / 16;
const MAX_RATIO_VIDEO_INSTAGRAM = 10 / 1;
const MIN_RATIO_VIDEO_INSTAGRAM = 1 / 10;

export const getStoryVideoValidity = (video: HTMLVideoElement, keys: PlatformKey[]): IStoryVideoCheck => {
    const duration = Math.floor(video.duration);
    const isDurationValid = duration <= MAX_DURATION_VIDEO && duration >= MIN_DURATION_VIDEO;

    const width = video.videoWidth;
    const height = video.videoHeight;
    const isWidthValid = width <= MAX_WIDTH_VIDEO;
    const isHeightValid = keys.includes(PlatformKey.FACEBOOK) ? height >= MIN_HEIGHT_VIDEO_FACEBOOK : true;

    const extension = video.getElementsByTagName('source')?.[0]?.type?.split('/')?.pop() ?? '';
    const isExtensionValid = ['mp4', 'mov'].includes(extension);

    const ratio = width / height;
    const maxRatio = keys.includes(PlatformKey.FACEBOOK) ? MAX_RATIO_VIDEO_FACEBOOK : MAX_RATIO_VIDEO_INSTAGRAM;
    const isRatioValid = ratio <= maxRatio && ratio >= MIN_RATIO_VIDEO_INSTAGRAM;

    return {
        duration,
        maxDuration: MAX_DURATION_VIDEO,
        minDuration: MIN_DURATION_VIDEO,
        isDurationValid,
        width,
        maxWidth: MAX_WIDTH_VIDEO,
        isWidthValid,
        height,
        extension,
        isExtensionValid,
        ratio,
        maxRatio,
        minRatio: MIN_RATIO_VIDEO_INSTAGRAM,
        isRatioValid,
        isHeightValid,
        error: !isDurationValid || !isWidthValid || !isExtensionValid || !isHeightValid || !isRatioValid,
    };
};
