import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { FolderWithMediaCountDto, UpdateFolderBodyDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { objectToQueryParams } from ':shared/helpers/query-params';
import { IFolder, Media } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class FoldersService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/folders`;

    constructor(private readonly _http: HttpClient) {}

    createFolder(restaurantId: string, parentFolderId: string | null, name: string): Observable<ApiResultV2<FolderWithMediaCountDto>> {
        const body = { name, parentFolderId };
        return this._http.post<ApiResultV2<FolderWithMediaCountDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, body, {
            withCredentials: true,
        });
    }

    getRestaurantFolders(
        restaurantId: string,
        parentFolderId: string | null,
        countOnlyNeverUsedMedia: boolean,
        nameFilter?: string
    ): Observable<ApiResultV2<FolderWithMediaCountDto[]>> {
        const params = objectToQueryParams({ parentFolderId, nameFilter, countOnlyNeverUsedMedia });
        return this._http.get<ApiResultV2<FolderWithMediaCountDto[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, {
            withCredentials: true,
            params,
        });
    }

    getFolderById(folderId: string): Observable<ApiResultV2<FolderWithMediaCountDto>> {
        return this._http.get<ApiResultV2<FolderWithMediaCountDto>>(`${this.API_BASE_URL}/${folderId}`);
    }

    getFolderMedia(folderId: string): Observable<Media[]> {
        return this._http
            .get<ApiResultV2<Media[]>>(`${this.API_BASE_URL}/${folderId}/medias`)
            .pipe(map((result) => result.data.map((media) => new Media(media))));
    }

    getPublishedMediaCountInFolders(folderIds: string[], restaurantId: string): Observable<number> {
        const params = objectToQueryParams({ folderIds });
        return this._http
            .get<
                ApiResultV2<number>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/media/publishedCount`, { params, withCredentials: true })
            .pipe(map((res) => res.data));
    }

    deleteFolders(folderIds: string[]): Observable<ApiResultV2> {
        const params = objectToQueryParams({ folderIds });
        return this._http.delete<ApiResultV2>(this.API_BASE_URL, { params });
    }

    updateFolder(folder: IFolder, payload: UpdateFolderBodyDto): Observable<ApiResultV2<FolderWithMediaCountDto>> {
        return this._http.put<ApiResultV2<FolderWithMediaCountDto>>(`${this.API_BASE_URL}/${folder.id}`, payload, {
            withCredentials: true,
        });
    }

    duplicateFoldersForRestaurants(
        restaurantId: string,
        foldersToDuplicate: IFolder[],
        restaurantIds: string[]
    ): Observable<ApiResultV2<IFolder[]>> {
        return this._http.post<ApiResultV2<FolderWithMediaCountDto[]>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/duplicate`,
            { foldersToDuplicate, restaurantIds },
            { withCredentials: true }
        );
    }
}
