import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, Observable } from 'rxjs';

import { isNotNil, SocialPostsListFilter } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { Pagination, Restaurant } from ':shared/models';

const DEFAULT_PAGINATION: Pagination = { pageSize: 10, pageNumber: 0, total: 0 };

@Injectable({
    providedIn: 'root',
})
export class SocialPostsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);

    readonly posts = signal<SocialPostItem[]>([]);
    readonly isFetchingPosts = signal<boolean>(false);
    readonly isFetchingMorePosts = signal<boolean>(false);
    readonly hasFetchedPostsAtLeastOnce = signal<boolean>(false);

    readonly selectedFilter = signal<SocialPostsListFilter>(SocialPostsListFilter.ALL);
    readonly pagination = signal<Pagination>(DEFAULT_PAGINATION);

    readonly restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    readonly restaurant = toSignal(this.restaurant$, { initialValue: this._restaurantsService.currentRestaurant });

    readonly restaurantHasNoPost = computed(
        (): boolean =>
            this.hasFetchedPostsAtLeastOnce() && this.posts().length === 0 && !this.isFetchingPosts() && !this.isFetchingMorePosts()
    );

    init(): void {
        this.hasFetchedPostsAtLeastOnce.set(false);
    }

    fetchPosts(postsFilter: SocialPostsListFilter, pagination: Pagination, restaurantId: string): void {
        this.isFetchingPosts.set(pagination.pageNumber === 0);
        this.isFetchingMorePosts.set(pagination.pageNumber > 0);

        this._socialPostsService.getSocialPosts$(postsFilter, pagination, restaurantId).subscribe((posts) => {
            const newPosts = posts.map((post) => SocialPostItem.fromDto(post));
            if (pagination.pageNumber === 0) {
                this.posts.set(newPosts);
            } else {
                this.posts.update((currentPosts) => [...currentPosts, ...newPosts]);
            }
            this.isFetchingPosts.set(false);
            this.isFetchingMorePosts.set(false);
            this.hasFetchedPostsAtLeastOnce.set(true);
        });
    }

    resetPagination(): void {
        this.pagination.set(DEFAULT_PAGINATION);
    }

    resetFilter(): void {
        this.selectedFilter.set(SocialPostsListFilter.ALL);
    }

    goNextPage(): void {
        this.pagination.update((currentPagination) => ({ ...currentPagination, pageNumber: currentPagination.pageNumber + 1 }));
    }
}
