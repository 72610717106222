<div class="malou-color-background-light flex min-h-full items-center">
    <div class="page-not-found-container max-w-5xl lg:max-w-xl">
        <div class="malou-logo">
            <img alt="" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="flex p-12 lg:flex-col-reverse lg:justify-normal lg:py-0 xl:justify-around">
            <div class="flex w-1/2 flex-col justify-center lg:w-full lg:pt-12">
                <div class="malou-chip malou-chip--warn mb-2.5">
                    <span>{{ 'page_not_found.page_not_found' | translate }}</span>
                </div>
                <h1 class="malou-text-heading malou-color-text-1">{{ 'page_not_found.oh_no' | translate }}</h1>
                <app-star-gauge class="mb-7" [stars]="1" [size]="'large'"></app-star-gauge>
                <div class="malou-text-15--regular mb-8">{{ 'page_not_found.do_not_exist_malou' | translate }} 🤭</div>
                <div>
                    <button class="malou-btn-raised--primary" mat-raised-button (click)="redirectToHome()">
                        {{ 'page_not_found.go_home' | translate }}
                    </button>
                    <button class="malou-btn-flat" mat-button>
                        <a class="malou-color-primary" target="_blank" [href]="faqLink">{{ 'page_not_found.go_faq' | translate }}</a>
                    </button>
                </div>
            </div>
            <div class="ml-8 flex justify-center">
                <img
                    class="max-w-[400px] sm:!max-w-[120px] lg:max-w-[220px]"
                    alt="404 illustration"
                    [src]="'404' | illustrationPathResolver" />
            </div>
        </div>
    </div>
</div>
