import { CountryCode } from '../../constants';

export enum ReviewType {
    RATING = 'rating',
    VISITOR_POST = 'visitor_post',
}

export enum UbereatsPromotionValue {
    NONE = 'NONE',
    TIER_1 = 'TIER_1',
    TIER_2 = 'TIER_2',
    TIER_3 = 'TIER_3',
}

export const countryCodeToUbereatsOfferTiersMap: Record<
    CountryCode.FRANCE | CountryCode.BELGIUM | CountryCode.UNITED_STATES | CountryCode.SWITZERLAND,
    { [UbereatsPromotionValue.TIER_1]: number; [UbereatsPromotionValue.TIER_2]: number; [UbereatsPromotionValue.TIER_3]: number }
> = {
    [CountryCode.FRANCE]: { [UbereatsPromotionValue.TIER_1]: 5, [UbereatsPromotionValue.TIER_2]: 10, [UbereatsPromotionValue.TIER_3]: 18 },
    [CountryCode.BELGIUM]: { [UbereatsPromotionValue.TIER_1]: 5, [UbereatsPromotionValue.TIER_2]: 12, [UbereatsPromotionValue.TIER_3]: 21 },
    [CountryCode.UNITED_STATES]: {
        [UbereatsPromotionValue.TIER_1]: 5,
        [UbereatsPromotionValue.TIER_2]: 11,
        [UbereatsPromotionValue.TIER_3]: 20,
    },
    [CountryCode.SWITZERLAND]: {
        [UbereatsPromotionValue.TIER_1]: 8,
        [UbereatsPromotionValue.TIER_2]: 17,
        [UbereatsPromotionValue.TIER_3]: 31,
    },
};

export const DEFAULT_LANG_UNKNOWN = 'unknown';
