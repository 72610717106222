import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, effect, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsAverageAnswerTime, ReviewsByAnswerStatus, ReviewsEvolutionTotalWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { FormatMillisecondsDurationPipe } from ':shared/pipes/format-millisecond-duration.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import { PlatformFilterPage } from '../../store/statistics.interface';
import * as StatisticsSelectors from '../../store/statistics.selectors';

const MAX_PERCENTAGE = 100;

@Component({
    selector: 'app-reviews-kpis',
    templateUrl: './reviews-kpis.component.html',
    styleUrls: ['./reviews-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatIconModule,
        StarGaugeComponent,
        MatTooltipModule,
        NumberEvolutionComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        FormatMillisecondsDurationPipe,
        StatisticsHttpErrorPipe,
        AsyncPipe,
    ],
    providers: [PluralTranslatePipe],
})
@AutoUnsubscribeOnDestroy()
export class ReviewsKpisComponent implements OnInit, KillSubscriptions {
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    httpError: any;
    isLoading = signal(true);

    currentTotalReviews?: number;
    diffTotalReviews?: number;
    currentAnsweredRepliesRate?: number;
    diffAnsweredRepliesRate?: number;
    currentAverageAnswerTime?: number;
    diffAverageAnswerTime?: number;
    gmbTotalReviewCountTooltip$: Observable<string>;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _translateService: TranslateService
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this.gmbTotalReviewCountTooltip$ = this._getTotalReviewCountForPlatform$(PlatformKey.GMB);
        combineLatest([this.dates$, this.platformKeys$, this._restaurantsService.restaurantSelected$])
            .pipe(
                filter(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]): Observable<
                        [
                            ReviewsEvolutionTotalWithRange,
                            ReviewsEvolutionTotalWithRange,
                            ReviewsByAnswerStatus,
                            ReviewsByAnswerStatus,
                            ReviewsAverageAnswerTime,
                            ReviewsAverageAnswerTime,
                        ]
                    > => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;

                        return forkJoin([
                            this._reviewsService.getChartRestaurantsReviewsTotal(restaurantId, platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService.getChartRestaurantsReviewsTotal(restaurantId, platforms, startDate, endDate, true).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService.getChartRestaurantsReviewsReplied(restaurantId, platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService.getChartRestaurantsReviewsReplied(restaurantId, platforms, startDate, endDate, true).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService.getChartAverageAnswerTime(restaurantId, platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService.getChartAverageAnswerTime(restaurantId, platforms, startDate, endDate, true).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        ]);
                    }
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(
                ([
                    currentReviewsEvolutionTotal,
                    previousReviewsEvolutionTotal,
                    currentReviewsReplies,
                    previousReviewsReplies,
                    currentAverageAnswerTime,
                    previousAverageAnswerTime,
                ]: [
                    ReviewsEvolutionTotalWithRange,
                    ReviewsEvolutionTotalWithRange,
                    ReviewsByAnswerStatus,
                    ReviewsByAnswerStatus,
                    ReviewsAverageAnswerTime,
                    ReviewsAverageAnswerTime,
                ]) => {
                    this.currentTotalReviews = this._computeCurrentTotalReviews(currentReviewsEvolutionTotal);
                    this.diffTotalReviews = this._computeDiffTotalReviews(this.currentTotalReviews, previousReviewsEvolutionTotal);

                    this.currentAnsweredRepliesRate = this._computeCurrentAnsweredRepliesRate(currentReviewsReplies);
                    this.diffAnsweredRepliesRate = this._computeDiffAnsweredRepliesRate(
                        this.currentAnsweredRepliesRate,
                        previousReviewsReplies
                    );

                    this.currentAverageAnswerTime = this._computeCurrentAverageAnswerTime(currentAverageAnswerTime);
                    this.diffAverageAnswerTime = this._computeDiffAverageAnswerTime(
                        this.currentAverageAnswerTime,
                        previousAverageAnswerTime
                    );

                    if (!this.currentTotalReviews && !this.currentAnsweredRepliesRate && !this.currentAverageAnswerTime) {
                        this.hasDataChange.emit(false);
                    }

                    this.isLoading.set(false);
                }
            );
    }

    private _computeCurrentTotalReviews(currentReviewsEvolutionTotal: ReviewsEvolutionTotalWithRange): number | undefined {
        return currentReviewsEvolutionTotal?.results?.[0]?.total;
    }

    private _computeDiffTotalReviews(
        currentTotalReviews: number | undefined,
        previousReviewsEvolutionTotal: ReviewsEvolutionTotalWithRange
    ): number | undefined {
        const previousTotalReviews = previousReviewsEvolutionTotal?.results?.[0]?.total;
        if (currentTotalReviews && previousTotalReviews) {
            return currentTotalReviews - previousTotalReviews;
        }
    }

    private _computeCurrentAnsweredRepliesRate(currentReviewsReplies: ReviewsByAnswerStatus): number | undefined {
        const currentAnsweredTotal = currentReviewsReplies?.results?.answered?.length;
        const currentNotAnsweredTotal = currentReviewsReplies?.results?.notAnswered?.length;
        if (currentAnsweredTotal != null && currentNotAnsweredTotal != null) {
            if (currentNotAnsweredTotal === 0) {
                return currentAnsweredTotal > 0 ? MAX_PERCENTAGE : 0;
            }
            return (currentAnsweredTotal / (currentAnsweredTotal + currentNotAnsweredTotal)) * 100;
        }
    }

    private _computeDiffAnsweredRepliesRate(
        currentAnsweredReviewsReplies: number | undefined,
        previousReviewsReplies: ReviewsByAnswerStatus
    ): number | undefined {
        const previousAnsweredTotal = previousReviewsReplies?.results?.answered?.length;
        const previousNotAnsweredTotal = previousReviewsReplies?.results?.notAnswered?.length;
        let previousAnsweredRepliesRate: number | undefined;
        if (previousAnsweredTotal != null && previousNotAnsweredTotal != null) {
            if (previousNotAnsweredTotal === 0) {
                previousAnsweredRepliesRate = previousAnsweredTotal > 0 ? MAX_PERCENTAGE : 0;
            } else {
                previousAnsweredRepliesRate = (previousAnsweredTotal / (previousAnsweredTotal + previousNotAnsweredTotal)) * 100;
            }
        }
        if (currentAnsweredReviewsReplies != null && previousAnsweredRepliesRate != null) {
            return currentAnsweredReviewsReplies - previousAnsweredRepliesRate;
        }
    }

    private _computeCurrentAverageAnswerTime(currentReviewsEvolutionTotal: ReviewsAverageAnswerTime): number | undefined {
        return currentReviewsEvolutionTotal ?? undefined;
    }

    private _computeDiffAverageAnswerTime(
        currentAverageAnswerTime: number | undefined,
        previousAverageAnswerTime: ReviewsAverageAnswerTime
    ): number | undefined {
        if (previousAverageAnswerTime && currentAverageAnswerTime) {
            return previousAverageAnswerTime - currentAverageAnswerTime;
        }
    }

    private _reset(): void {
        this.httpError = undefined;
        this.isLoading.set(true);
    }

    private _getTotalReviewCountForPlatform$(platformKey: PlatformKey): Observable<string> {
        return this._restaurantsService.restaurantSelected$.pipe(
            switchMap((restaurant: Restaurant) => this._reviewsService.getTotalReviewCountForPlatform(restaurant._id, platformKey)),
            catchError((error) => {
                console.warn('fetch total review count error :>>', error);
                return EMPTY;
            }),
            map((data) =>
                data?.count
                    ? this._translateService.instant('statistics.e_reputation.reviews_kpis.total_reviews', { count: data?.count })
                    : ''
            ),
            takeUntil(this.killSubscriptions$)
        );
    }
}
