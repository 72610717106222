import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ApplicationLanguage, KeywordScoreTextType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { Description, Keyword, Restaurant } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import { KeywordsScoreGaugeComponent } from '../../keywords-score-gauge/keywords-score-gauge.component';

@Component({
    selector: 'app-restaurant-description',
    templateUrl: './restaurant-description.component.html',
    styleUrls: ['./restaurant-description.component.scss'],
    standalone: true,
    imports: [KeywordsScoreGaugeComponent, NgStyle, ApplySelfPurePipe],
})
@AutoUnsubscribeOnDestroy()
export class RestaurantDescriptionComponent implements KillSubscriptions {
    @Output() changeDescriptions = new EventEmitter<{ descriptions: Description[] }>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly text$ = new BehaviorSubject('');
    readonly descriptionType$: BehaviorSubject<KeywordScoreTextType> = new BehaviorSubject<KeywordScoreTextType>(
        KeywordScoreTextType.LONG_DESCRIPTION
    );

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;

    currentDescription: Description | null;
    currentRestaurant: Restaurant;
    currentSize: string;
    descriptionHtml: String;
    isLongDescription: boolean;
    restaurantKeywords$: Observable<Keyword[]>;
    isBrandBusiness: boolean;

    constructor(
        public dialog: MatDialog,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store
    ) {}

    @Input() set restaurant(value: Restaurant) {
        this.currentRestaurant = value;
        const currentDescriptionFr = value.descriptions.find(
            (desc) => desc.size === this.currentSize && desc.language === ApplicationLanguage.FR
        );
        const currentDescriptionEn = value.descriptions.find(
            (desc) => desc.size === this.currentSize && desc.language === ApplicationLanguage.EN
        );
        const currentDescription = currentDescriptionFr?.text?.length ? currentDescriptionFr : currentDescriptionEn;
        this.descriptionHtml = currentDescription?.text?.replace(/\n\n/g, '<br><br>') ?? '';
        this.text$.next(currentDescription?.text ?? '');
        this.isLongDescription = currentDescription?.size === 'long';
        this.descriptionType$.next(this.isLongDescription ? KeywordScoreTextType.LONG_DESCRIPTION : KeywordScoreTextType.SHORT_DESCRIPTION);
        this.restaurantKeywords$ = this._store.select(selectCurrentKeywords);

        this.isBrandBusiness = value.isBrandBusiness();
    }
    @Input() set size(value: string) {
        this.currentSize = value;
        const currentSize = value;
        const currentDescriptionFr = this.currentRestaurant.descriptions.find(
            (desc) => desc.size === this.currentSize && desc.language === ApplicationLanguage.FR
        );
        const currentDescriptionEn = this.currentRestaurant.descriptions.find(
            (desc) => desc.size === this.currentSize && desc.language === ApplicationLanguage.EN
        );
        const currentDescription = currentDescriptionFr?.text?.length ? currentDescriptionFr : currentDescriptionEn;
        this.currentDescription = currentDescription ?? null;
        this.descriptionHtml = currentDescription?.text?.replace(/\n\n/g, '<br><br>') ?? '';
        this.text$.next(currentDescription?.text ?? '');
        this.isLongDescription = currentSize === 'long';
        this.descriptionType$.next(this.isLongDescription ? KeywordScoreTextType.LONG_DESCRIPTION : KeywordScoreTextType.SHORT_DESCRIPTION);
    }
}
