import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, from, map, Observable, switchMap, take } from 'rxjs';

import { MediaCategory, SizeInBytes } from '@malou-io/package-utils';

import { MediaService } from ':modules/media/media.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';

import { Media } from '../models';
import { ImageProperties } from '../models/image-properties';

const html2canvas = require('../../core/vendors/html2canvas.js');

@Injectable({ providedIn: 'root' })
export class HtmlToMedia {
    constructor(
        private _mediaService: MediaService,
        private readonly _store: Store,
        private readonly _translate: TranslateService
    ) {}

    transform(htmlElement: HTMLElement, currentMedia: Media): Observable<Media> {
        // prevent white lines on the sides of the image
        const width = Math.round(htmlElement.clientWidth);
        const height = Math.round(htmlElement.clientHeight);
        return from(html2canvas(htmlElement, { scale: 4, allowTaint: true, useCORS: true, width, height })).pipe(
            switchMap((canvas: HTMLCanvasElement) => {
                const dataUrl = canvas.toDataURL('image/png');
                return this._saveCroppedMedia$(currentMedia, dataUrl);
            })
        );
    }

    private _saveCroppedMedia$(oldMedia: Media, croppedImageBase64: string): Observable<Media> {
        return forkJoin([this._transformMedia$(croppedImageBase64), this._store.select(selectUserInfos).pipe(take(1))]).pipe(
            switchMap(([file, user]) =>
                this._mediaService.uploadAndCreateMedia([
                    {
                        data: file,
                        metadata: {
                            restaurantId: oldMedia.restaurantId,
                            title: oldMedia.title,
                            userId: user?._id,
                            originalMediaId: oldMedia.id,
                            category: MediaCategory.ADDITIONAL,
                        },
                    },
                ])
            ),
            map((res) => new Media(res.data[0])),
            switchMap((newMedia: Media) =>
                newMedia.getProperties$().pipe(
                    map((properties) => {
                        if (!properties) {
                            return newMedia;
                        }
                        const mediaErrors = this._getImageErrors(properties);
                        newMedia.setErrors(mediaErrors);
                        return newMedia;
                    })
                )
            )
        );
    }

    private _transformMedia$(mediaBase64: string): Observable<File> {
        return from(
            fetch(mediaBase64)
                .then((res) => res.blob())
                .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
        );
    }

    private _getImageErrors(imgProperties: ImageProperties): string[] {
        const errors: string[] = [];

        const minimumSizeInBytes = 10 * SizeInBytes.KILO_BYTES;
        if (imgProperties.isTooSmallSizeImage(minimumSizeInBytes)) {
            const actualSizeInKiloBytes = imgProperties.bytes / SizeInBytes.KILO_BYTES;
            const minimumSizeInKiloBytes = minimumSizeInBytes / SizeInBytes.KILO_BYTES;
            errors.push(
                this._translate.instant('posts.new_post.too_small_size_image', {
                    actualSize: actualSizeInKiloBytes,
                    minimumSize: minimumSizeInKiloBytes,
                })
            );
        }
        const minimumWidth = 250;
        const minimumHeight = 250;
        if (imgProperties.isTooSmallDimensionsImage(minimumWidth, minimumHeight)) {
            const actualWidth = imgProperties.width;
            const actualHeight = imgProperties.height;
            const dimensionsErrorMessage = this._translate.instant('posts.new_post.too_small_dimensions_image', {
                minimumWidth,
                minimumHeight,
                actualWidth,
                actualHeight,
            });
            errors.push(dimensionsErrorMessage);
        }

        return errors;
    }
}
