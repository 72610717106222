import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { Restaurant } from ':shared/models';

import { Suggestion } from '../information-suggestions-modal/store/suggestions.interface';
import { selectCurrentSuggestion } from '../information-suggestions-modal/store/suggestions.reducer';

@Component({
    selector: 'app-information-suggestions',
    standalone: true,
    imports: [MatButtonModule, TranslateModule, PlatformLogoComponent],
    templateUrl: './information-suggestions.component.html',
    styleUrl: './information-suggestions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationSuggestionsComponent {
    readonly openSuggestions = output<{ restaurant: Restaurant; suggestion: Suggestion }>();
    readonly suggestion = toSignal(inject(Store).select(selectCurrentSuggestion));
    private readonly _restaurant = toSignal(inject(RestaurantsService).restaurantSelected$);

    openModalSuggestions(): void {
        const suggestion = this.suggestion();
        const restaurant = this._restaurant() ?? this.suggestion()?.restaurant;
        if (restaurant && suggestion && suggestion?.comparisons?.length) {
            this.openSuggestions.emit({ restaurant, suggestion });
        }
    }
}
