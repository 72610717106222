<div
    class="malou-box-shadow fixed bottom-0 right-16 z-10 rounded-t-lg bg-white p-4"
    [@appearDisappearFromBottomAnimation]="
        isOpen() ? appearDisappearFromBottomAnimation.openState : appearDisappearFromBottomAnimation.closedState
    "
    [hidden]="isHidden()"
    (@appearDisappearFromBottomAnimation.done)="onAnimationDone($event)">
    <div class="flex gap-x-2">
        <ng-template #dynamicTemplate></ng-template>
        <mat-icon
            class="malou-color-primary !h-[16px] !w-[16px] cursor-pointer"
            [svgIcon]="SvgIcon.CROSS"
            (click)="onCrossClick()"></mat-icon>
    </div>
</div>
