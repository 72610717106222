import { z } from 'zod';

import { booleanAsStringValidator, objectIdValidator } from '../utils/validators';

export const getRestaurantKeywordsCountAndAverageScoreParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type GetRestaurantKeywordsCountAndAverageScoreParamsDto = z.infer<typeof getRestaurantKeywordsCountAndAverageScoreParamsValidator>;

// ----------------------------------------------------------------------

export const getRestaurantKeywordsCountAndAverageScoreQueryValidator = z
    .object({
        start_date: z.coerce.date(),
        end_date: z.coerce.date(),
        previous_period: booleanAsStringValidator,
    })
    .transform((data) => ({
        startDate: data.start_date,
        endDate: data.end_date,
        previousPeriod: data.previous_period ? data.previous_period === 'true' : undefined,
    }));
export type GetRestaurantKeywordsCountAndAverageScoreQueryDto = z.infer<typeof getRestaurantKeywordsCountAndAverageScoreQueryValidator>;

// ----------------------------------------------------------------------

export type RestaurantKeywordCountAndAverageScoreDto = {
    average: number;
    count: {
        posts: number;
        reviews: number;
    };
};
