import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import {
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { RightArrowWithContentComponent } from ':shared/components/right-arrow-with-text/right-arrow-with-content.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-facebook-instagram-connection-step-1',
    templateUrl: './facebook-instagram-connection-step-1.component.html',
    styleUrls: ['./facebook-instagram-connection-step-1.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MatIconModule,
        NgTemplateOutlet,
        ImagePathResolverPipe,
        RightArrowWithContentComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookInstagramConnectionStep1Component extends BaseStepComponent<
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult
> {
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;

    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));
}
