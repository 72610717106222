<div class="mb-3 flex w-full items-center justify-between sm:flex-wrap">
    <app-search
        class="grow pr-[15px] sm:basis-full sm:pb-2 sm:pr-0 md:!hidden"
        [value]="text()"
        [placeholder]="'common.search' | translate"
        [debounceTime]="500"
        (searchChange)="onSearchChange($event)"></app-search>
    @if (shouldReconnectFilterBeVisible()) {
        <div class="flex items-center justify-between gap-x-4 md:w-full">
            <mat-button-toggle-group class="malou-group-toggle-btn">
                <mat-button-toggle checked="true" (click)="changeReconnectFilterOption(ReconnectFilterOption.ALL)">
                    <span>{{ 'platforms.connection.filters.all' | translate }} </span>
                </mat-button-toggle>
                <mat-button-toggle (click)="changeReconnectFilterOption(ReconnectFilterOption.FAILED)">
                    <span>{{ 'platforms.connection.filters.to_reconnect' | translate }}</span>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    }
</div>
