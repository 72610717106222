import {
    ApplicationLanguage,
    FileFormat,
    MediaCategory,
    MediaConvertedStatus,
    MediaTagCategory,
    MediaTagSubcategory,
    MediaType,
    PictureSize,
} from '@malou-io/package-utils';

export interface AiTagDto {
    tag: string;
    language: ApplicationLanguage;
    category: MediaTagCategory;
    subcategory?: MediaTagSubcategory | null;
}
export interface MediaDto {
    id: string;
    restaurantId?: string;
    userId?: string;
    title?: string;
    name?: string;
    duplicatedFromRestaurantId?: string;
    category: MediaCategory;
    format: FileFormat;
    type: MediaType;
    urls: PictureSizeRecordDto<string>;
    sizes: PictureSizeRecordDto<number>;
    postIds?: string[];
    originalMediaId?: string;
    socialId?: string;
    convertedStatus?: MediaConvertedStatus;
    dimensions?: PictureSizeRecordDto<MediaDimensionDto>;
    duration?: number;
    createdAt: Date;
    updatedAt: Date;
    thumbnail?: string;
    resizeMetadata?: {
        width: number;
        height: number;
        aspectRatio: number;
        cropPosition: {
            left: number;
            top: number;
        };
    };
    folderId?: string | null;
    aiDescription?: string;
    aiTags?: AiTagDto[];
    hasDisplayedText?: boolean;
}

export interface PictureSizeRecordDto<T extends string | number | MediaDimensionDto> {
    [PictureSize.ORIGINAL]?: T;
    [PictureSize.SMALL]?: T;
    [PictureSize.COVER]?: T;
    [PictureSize.SMALL_COVER]?: T;
    [PictureSize.IG_FIT]?: T;
}

export interface MediaDimensionDto {
    width: number;
    height: number;
}

export interface GetVideoInformationResponseDto {
    height: number;
    width: number;
    durationInSeconds: number;
}
