import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ToastDuration } from ':shared/components-v3/toast/toast-item/toast-item.component';

export enum ToastVariant {
    SUCCESS = 'success',
    WARN = 'warn',
    ERROR = 'error',
}

export interface ToastItemData {
    type: ToastVariant;
    message: string;
    duration: ToastDuration;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
    items$: Subject<ToastItemData> = new Subject();

    openSuccessToast(message: string, duration = ToastDuration.SHORT): void {
        this.items$.next({ type: ToastVariant.SUCCESS, message, duration });
    }

    openWarnToast(message: string, duration = ToastDuration.SHORT): void {
        this.items$.next({ type: ToastVariant.WARN, message, duration });
    }

    openErrorToast(message: string, duration = ToastDuration.SHORT): void {
        this.items$.next({ type: ToastVariant.ERROR, message, duration });
    }
}
