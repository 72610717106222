<div>
    <p
        class="text-container post-text mt-2"
        [innerText]="displayedText"
        [matTooltip]="showMoreMode === ShowMoreMode.TOOLTIP && showMoreLessButtons ? text : ''"></p>
    @if (showMoreLessButtons) {
        @if (showMoreMode === ShowMoreMode.EXPAND) {
            <button class="malou-btn-flat btn-normal" mat-button (click)="toggleTextSize()">
                {{
                    (showMoreLabel ? 'single_post.legend.show_more_button_label' : 'single_post.legend.show_less_button_label') | translate
                }}
            </button>
        }
    }
</div>
