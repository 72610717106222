import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { v4 } from 'uuid';

type ComponentType = Type<any>;
type Inputs = Record<string, unknown>;
interface OpenEvent {
    componentType: ComponentType;
    inputs: Inputs;
    id: string;
}
type CloseEvent = string;
type UpdateInputEvent = Inputs;

// We do not use the ngrx store but this class
// because the ngrx store make objects immutable
// but componentType need to stay mutable
// to make the angular function createComponent (in FooterPopinComponent) works
@Injectable({ providedIn: 'root' })
export class FooterPopinService {
    openEvent$: Subject<OpenEvent> = new Subject();
    closeEvent$: Subject<CloseEvent> = new Subject();
    updateInputEvent$: Subject<UpdateInputEvent> = new Subject();

    open(componentType: ComponentType, inputs: Inputs): string {
        const id = this._generateId();
        this.openEvent$.next({ componentType, inputs, id });
        return id;
    }

    close(id: string): void {
        this.closeEvent$.next(id);
    }

    updateInputs(inputs: Inputs): void {
        this.updateInputEvent$.next(inputs);
    }

    getOpenEvent$(): Observable<OpenEvent> {
        return this.openEvent$.asObservable();
    }

    getCloseEvent$(): Observable<CloseEvent> {
        return this.closeEvent$.asObservable();
    }

    getUpdateInputEvent$(): Observable<UpdateInputEvent> {
        return this.updateInputEvent$.asObservable();
    }

    private _generateId(): string {
        return v4();
    }
}
