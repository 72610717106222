import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';

import {
    canUpdateSpecialHoursFromNotification,
    Environment,
    isSameDay,
    mapLuxonWeekdayToDay,
    SpecialHourNotificationAction,
} from '@malou-io/package-utils';

import { InformationService } from ':core/services/information.service';
import { environment } from ':environments/environment';
import { MyDate, Restaurant, SpecialTimePeriod, TimePeriod } from ':shared/models';

import { SpecialHourNotification } from '../models/special-hour-notification.model';

@Injectable({ providedIn: 'root' })
export class SpecialHoursNotificationService {
    constructor(private readonly _informationsService: InformationService) {}

    updateSpecialHours$(
        restaurant: Restaurant,
        notification: SpecialHourNotification,
        action: SpecialHourNotificationAction
    ): Observable<Restaurant | null> {
        if (!canUpdateSpecialHoursFromNotification(environment.environment as Environment, restaurant._id)) {
            return of(null);
        }

        if (this.isRestaurantSpecialHoursDefinedForEventDate(restaurant, notification)) {
            return of(null);
        }

        const eventDate = new Date(notification.data.event.startDate);
        const eventName = `${notification.getNotificationEmoji()} ${notification.getNotificationName()}`;
        const newSpecialHours: SpecialTimePeriod[] = [];

        if (action === SpecialHourNotificationAction.CLOSED) {
            newSpecialHours.push(
                new SpecialTimePeriod({
                    startDate: MyDate.fromDate(eventDate),
                    endDate: MyDate.fromDate(eventDate),
                    isClosed: true,
                    name: eventName,
                    openTime: null,
                    closeTime: null,
                    isFromCalendarEvent: true,
                })
            );
        }

        if (action === SpecialHourNotificationAction.OPEN_AS_USUAL) {
            const regularHours = this._getRegularHoursForDate(eventDate, restaurant.regularHours);
            regularHours.forEach((hour) => {
                newSpecialHours.push(
                    new SpecialTimePeriod({
                        startDate: MyDate.fromDate(eventDate),
                        endDate: MyDate.fromDate(eventDate),
                        isClosed: hour.isClosed,
                        name: eventName,
                        openTime: hour.openTime ?? null,
                        closeTime: hour.closeTime ?? null,
                        isFromCalendarEvent: true,
                    })
                );
            });
        }

        const specialHoursUpdate = [...restaurant.specialHours, ...newSpecialHours];
        return this._informationsService.updateRestaurant$(restaurant, { specialHours: specialHoursUpdate });
    }

    isRestaurantSpecialHoursDefinedForEventDate(restaurant: Restaurant, notification: SpecialHourNotification): boolean {
        const specialHours = restaurant.specialHours;
        if (!specialHours?.length) {
            return false;
        }

        const eventStartDate = new Date(notification.data.event.startDate);

        const isRestaurantSpecialHoursDefined = specialHours.some((specialHour) => {
            const specialHourStartDate = DateTime.fromObject({
                day: specialHour.startDate.day,
                month: specialHour.startDate.month + 1,
                year: specialHour.startDate.year,
            }).toJSDate();
            return isSameDay(eventStartDate, specialHourStartDate);
        });

        return isRestaurantSpecialHoursDefined;
    }

    private _getRegularHoursForDate(date: Date, regularHours: TimePeriod[]): TimePeriod[] {
        const dateWeekNumber = DateTime.fromJSDate(date).weekday;
        const day = mapLuxonWeekdayToDay(dateWeekNumber);
        return regularHours.filter((hour) => hour.openDay === day);
    }
}
