import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-aggregated-boosters',
    templateUrl: './aggregated-boosters.component.html',
    styleUrls: ['./aggregated-boosters.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedBoostersComponent {}
