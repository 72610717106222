import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { keyBy } from 'lodash';
import { catchError, combineLatest, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { isNotNil, MalouMetric } from '@malou-io/package-utils';

import { PostsInsightsService } from ':core/services/post-insights.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { selectDatesFilter } from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { StoriesAndInsights } from ':modules/statistics/social-networks/posts-insights-table/stories/stories.component';
import { Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

import { AbstractCsvService, CsvAsStringArrays, DEFAULT_CSV_PAGINATION } from '../csv-service.abstract';

interface Data {
    restaurant: Restaurant;
    storiesData: StoriesAndInsights | null;
}

@Injectable({ providedIn: 'root' })
export class AggregatedStoriesCsvInsightsService extends AbstractCsvService<Data[]> {
    constructor(
        private readonly _store: Store,
        private readonly _postsInsightsService: PostsInsightsService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        super();
    }

    protected override _getData$(_options: void): Observable<Data[]> {
        return this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
            take(1),
            switchMap((restaurants) =>
                forkJoin(restaurants.map((restaurant) => this._postsInsightsService.synchronizeStoriesAndFetchInsights$(restaurant._id)))
            ),
            switchMap(() =>
                combineLatest([
                    this._store.select(selectDatesFilter).pipe(take(1)),
                    this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
                ])
            ),
            switchMap(([dates, restaurants]) =>
                forkJoin(
                    restaurants.map((restaurant) =>
                        this._postsInsightsService
                            .getStoriesAndInsights$(restaurant._id, DEFAULT_CSV_PAGINATION, dates.startDate, dates.endDate)
                            .pipe(
                                map((res) => ({ restaurant, storiesData: res })),
                                catchError(() => of(null))
                            )
                    )
                ).pipe(map((e) => e.filter(isNotNil)))
            )
        );
    }

    protected override _isDataValid(data: Data[]): boolean {
        return data.filter((d) => !!d.storiesData).length > 0;
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return [
            'Date',
            'Hour',
            'Location',
            'Location Internal Name',
            'Location Address',
            'Platform',
            'Impressions',
            'Reach',
            'Exits',
            'Next story',
            'Previous story',
        ];
    }

    protected override _getCsvDataRows(data: Data[]): string[][] {
        return data
            .map(({ restaurant, storiesData }) => {
                if (!storiesData) {
                    return null;
                }
                const locationName = restaurant.name;
                const locationInternalName = restaurant.internalName ?? '';
                const locationAddress = restaurant.getFullFormattedAddress().replace(/,/g, '');
                const { stories, insights } = storiesData;

                const insightsMap = keyBy(insights, 'socialId');
                return stories.map((story) => {
                    const storyInsights = insightsMap[story.socialId];
                    const date = new Date(story.socialCreatedAt).toLocaleDateString();
                    const hour = `${new Date(story.socialCreatedAt).getHours()}:${new Date(story.socialCreatedAt).getMinutes()}`;
                    const platform = this._enumTranslatePipe.transform(story.key, 'platform_key');
                    const impressions = storyInsights?.data?.find((el) => el.metric === MalouMetric.IMPRESSIONS)?.value?.toString() ?? '0';
                    const reach = storyInsights?.data?.find((el) => el.metric === MalouMetric.REACH)?.value?.toString() ?? '0';
                    const tapsExits = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_EXITS)?.value?.toString() ?? '0';
                    const tapsForward = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_FORWARD)?.value?.toString() ?? '0';
                    const tapsBack = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_BACK)?.value?.toString() ?? '0';
                    return [
                        date,
                        hour,
                        locationName,
                        locationInternalName,
                        locationAddress,
                        platform,
                        impressions,
                        reach,
                        tapsExits,
                        tapsForward,
                        tapsBack,
                    ];
                });
            })
            .filter(isNotNil)
            .flat();
    }
}
