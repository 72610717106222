import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { BulkWriteResult } from ':shared/interfaces/bulkwrite.interface';
import { ApiResult } from ':shared/models';
import { Client } from ':shared/models/client';

interface FileCheckResult {
    isValid: boolean;
    fileLength: number;
    clients: Client[];
    err?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/clients`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    getClientsByRestaurantId(restaurantId: string): Observable<ApiResult<Client[]>> {
        return this._http.get<ApiResult<Client[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { withCredentials: true }).pipe(
            map((res) => {
                res.data = res.data.map((c) => new Client(c));
                return res;
            })
        );
    }

    getClient(clientId: string): Observable<ApiResult<Client>> {
        return this._http.get<ApiResult<Client>>(`${this.API_BASE_URL}/${clientId}`, { withCredentials: true });
    }

    create(data: Partial<Client>): Observable<ApiResult<Client>> {
        return this._http.post<ApiResult<Client>>(`${this.API_BASE_URL}`, { ...data }, { withCredentials: true });
    }

    update(
        clientId: string,
        data: Partial<Client>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Client>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.put<ApiResult<Client>>(`${this.API_BASE_URL}/${clientId}`, { data }, { withCredentials: true, params });
    }

    unsubscribe(clientId: string, contactMode: string): Observable<ApiResult<Client>> {
        return this._http.put<ApiResult<Client>>(
            `${this.API_BASE_URL}/${clientId}/unsubscribe`,
            { contactMode },
            { withCredentials: true }
        );
    }

    importClientsFile(
        clients: Array<Omit<Client, '_id'>>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<
        ApiResult<{
            imported: Client[];
            duplicates: Client[];
        }>
    > {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.post<ApiResult<{ imported: Client[]; duplicates: Client[] }>>(
            `${this.API_BASE_URL}/import`,
            { clients },
            { withCredentials: true, params }
        );
    }

    updateClient(
        filter: {
            restaurantId: string;
            email: string;
        },
        data: Partial<Client>
    ): Observable<ApiResult<Client>> {
        return this._http.put<ApiResult<Client>>(`${this.API_BASE_URL}`, { data, filter });
    }

    parseAndCheckFile(restaurantId: string, source: string, file: File): Observable<ApiResult<FileCheckResult>> {
        const uploadData = new FormData();
        uploadData.append('client_file', file);
        return this._http.post<ApiResult<FileCheckResult>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/source/${source}/check`,
            uploadData,
            { withCredentials: true }
        );
    }

    updateDuplicates(
        duplicates: Client[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<BulkWriteResult>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.post<ApiResult<BulkWriteResult>>(
            `${this.API_BASE_URL}/duplicates`,
            { duplicates },
            { withCredentials: true, params }
        );
    }

    deleteManyClients(clientIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/delete`, { clientIds }, { withCredentials: true, params });
    }

    deleteClient(clientId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${clientId}`, { withCredentials: true, params });
    }

    duplicateClientsForRestaurants(
        restaurantId: string,
        clients: Partial<Client>[],
        restaurantIds: string[]
    ): Observable<ApiResult<Client[]>> {
        return this._http.post<ApiResult<Client[]>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/duplicate`,
            { clients, restaurantIds },
            { withCredentials: true }
        );
    }

    /* Public endpoint */
    editClientLeftReview(restaurantId: string, clientId: string, platformKey: string, apiKey: string): Observable<ApiResult<Client>> {
        return this._http.put<ApiResult<Client>>(
            `${this.API_BASE_URL}/${clientId}/restaurants/${restaurantId}/platforms/${platformKey}/left_review`,
            {},
            { params: { api_key: apiKey }, withCredentials: true }
        );
    }

    /* Public endpoint */
    getHasClientLeftNegativeReview(clientId: string, apiKey: string): Observable<ApiResult<{ hasLeftReview: boolean; isTest?: boolean }>> {
        return this._http.get<ApiResult<{ hasLeftReview: boolean; isTest?: boolean }>>(`${this.API_BASE_URL}/${clientId}/has_left_review`, {
            params: { api_key: apiKey },
            withCredentials: true,
        });
    }

    getDuplicateClients(restaurantId: string, clients: Partial<Client[]>): Observable<ApiResult<Client[]>> {
        return this._http.post<ApiResult<Client[]>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/duplicates`,
            { clients },
            { withCredentials: true }
        );
    }

    getClientsBeforeCreatingCampaign(
        restaurantId: string,
        filters: {
            startDate: Date;
            endDate: Date;
            minDaysFromLastContactedAt: number;
            accepts: string[];
            sources: string[];
        }
    ): Observable<ApiResult<Client[]>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http.get<ApiResult<Client[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/campaign_range`, {
            withCredentials: true,
            params,
        });
    }
}
