import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import {
    ApplicationLanguage,
    KeywordScoreTextType,
    mapLanguageStringToApplicationLanguage,
    PostPublicationStatus,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { times } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { onlyFutureDate } from ':shared/components/duplicate-posts-preview-modal/validators/publication-date-in-the-future';
import { DuplicateSinglePostCardComponent } from ':shared/components/duplicate-single-post-card/duplicate-single-post-card.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { Indication, KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { MultipleStepsLoaderComponent } from ':shared/components/multiple-steps-loader/multiple-steps-loader.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { isPastHour } from ':shared/helpers';
import { highlightKeywordsInText, Keyword, PostWithJob, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

interface DuplicatePostPreviewModalInputData {
    selectedRestaurants: Restaurant[];
}

interface DuplicatePostPreviewModalSharedData {
    restaurantKeywords$: Observable<Keyword[]>;
    restaurantKeywords: Keyword[];
    postToDuplicate: PostWithJob;
}

export interface DuplicatePostRestaurantData {
    restaurant: Restaurant;
    postCaption?: string;
    postCaption$: BehaviorSubject<string>;
    keywords?: Keyword[];
    keywords$: Observable<Keyword[]>;
    restaurant$: Observable<Restaurant>;
}

export type DuplicatePostPreviewModalSubmitData = {
    restaurantId: string;
    postCaption: string;
    status: PostPublicationStatus.PENDING | PostPublicationStatus.DRAFT;
    plannedPublicationDate?: Date;
}[];

const BaseStepDuplicatePostPreviewComponent = BaseStepComponent<DuplicatePostPreviewModalInputData, DuplicatePostPreviewModalSharedData>;

@Component({
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatDividerModule,
        MatIconModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        InputTextComponent,
        InputDatePickerComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatFormFieldModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        KeywordsScoreGaugeComponent,
        AsyncPipe,
        FormatTimePipe,
        ImagePathResolverPipe,
        SelectComponent,
        ApplySelfPurePipe,
        ApplyPurePipe,
        MultipleStepsLoaderComponent,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        LazyLoadImageModule,
        IllustrationPathResolverPipe,
        SlideToggleComponent,
        NgClass,
        DuplicateSinglePostCardComponent,
    ],
    templateUrl: './duplicate-posts-preview-modal.component.html',
    styleUrl: './duplicate-posts-preview-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicatePostsPreviewModalComponent extends BaseStepDuplicatePostPreviewComponent implements OnInit {
    // todo use form builder to have cleaner validation built in
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _postsService = inject(PostsService);
    private readonly _destroyRef = inject(DestroyRef);
    readonly PostDateStatus = Object.values(PostDateStatus);
    readonly TIMES = times;
    readonly MIN_DATE = new Date();
    readonly SvgIcon = SvgIcon;
    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);
    readonly willPostAllAtSameTime = signal(true);
    readonly langForKeywords = signal<ApplicationLanguage | undefined>(undefined);

    readonly loaderSteps = [
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_1'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_2'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_3'),
    ];

    readonly customizedDatePostForm = new FormGroup(
        {
            status: new FormControl<PostDateStatus>(PostDateStatus.LATER),
            plannedPublicationDate: new FormControl<Date>(new Date()),
            postTime: new FormControl(''),
        },
        {
            validators: [onlyFutureDate()],
        }
    );

    readonly statusNowIsSelected$ = this.customizedDatePostForm.valueChanges.pipe(
        map((formValue) => formValue.status === PostDateStatus.NOW)
    );

    get plannedPublicationDate(): Date {
        return this.customizedDatePostForm.get('plannedPublicationDate')!.value!;
    }

    get postTime(): string {
        return this.customizedDatePostForm.get('postTime')!.value!;
    }

    // todo use form builder to have cleaner validation built in
    readonly restaurantsData: WritableSignal<DuplicatePostRestaurantData[]> = signal([]);

    newPostsDateForms: FormGroup[] = [];
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly textType$ = of(KeywordScoreTextType.POST);

    readonly isLoading = signal(false);
    readonly isLoaderMinDurationReached = signal(false);
    readonly isCaptionsGenerationError = signal(false);
    readonly _LOADER_MIN_DURATION = 6 * TimeInMilliseconds.SECOND;

    readonly initialRestaurantsHtmlCaptions: WritableSignal<string[]> = signal([]);
    readonly indicationsList = signal<Indication[]>([]);
    readonly originalPostCaptionHighlighted = signal('');
    originalPostCaption$: Observable<string>;
    postLang$ = new BehaviorSubject<string | null>(null);

    isPastHour = isPastHour;
    readonly _isStepValid = signal(false);

    constructor() {
        super();

        // IDK why but i cant use combineLatest (or a computed) like combineLatest([toObservable(this.restaurantsData), this.customizedDatePostForm.valueChanges])
        // so i have to use effect & valueChanges.subscribe (see ngOnInit) to listen to changes and set the validity of the step
        effect(
            () => {
                // todo use form builder to have cleaner validation built in
                this._updateStepValidity(this.restaurantsData());
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.originalPostCaption$ = of(this.sharedData.postToDuplicate.text);
        this.postLang$.next(this.sharedData.postToDuplicate.language ?? null);
        this.langForKeywords.set(this.postLang$.value ? mapLanguageStringToApplicationLanguage(this.postLang$.value) : this.currentLang());
        const originalPostCaptionHighlighted = highlightKeywordsInText({
            text: this.sharedData.postToDuplicate.text,
            keywords: this.sharedData.restaurantKeywords,
            restaurantName: this._restaurantsService.restaurantSelected$.value!.name,
            currentLang: this.langForKeywords(),
        });
        this.originalPostCaptionHighlighted.set(originalPostCaptionHighlighted);

        // IDK why but i cant use combineLatest (or a computed) like combineLatest([toObservable(this.restaurantsData), this.customizedDatePostForm.valueChanges])
        // so i have to use effect (see constructor) & valueChanges.subscribe to listen to changes and set the validity of the step
        this.customizedDatePostForm.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._updateStepValidity();
        });

        this._initializePostForm();
        this._startSeoPostDuplication();
    }

    toggleWillPostAllAtSameTime(): void {
        this.willPostAllAtSameTime.update((willPostAllAtSameTime) => !willPostAllAtSameTime);
        this._updateStepValidity();
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.customizedDatePostForm.patchValue({
            postTime: event.value,
        });
    }

    statusDisplayWith = (status: PostDateStatus): string => this._translateService.instant('posts.duplication_modal.status.' + status);

    onUpdateRestaurantsData({ newText, index }: { newText: string; index: number }): void {
        this.restaurantsData.update((restaurantsData) => {
            restaurantsData[index].postCaption$.next(this.restaurantsData()[index].postCaption ?? '');
            restaurantsData[index] = {
                ...restaurantsData[index],
                postCaption: newText,
            };
            return [...restaurantsData];
        });
        this.initialRestaurantsHtmlCaptions.update((captions) => [
            ...captions.map((caption, i) =>
                i === index
                    ? highlightKeywordsInText({
                          text: newText,
                          keywords: this.restaurantsData()[index].keywords,
                          restaurantName: this.restaurantsData()[index].restaurant.name,
                          currentLang: this.langForKeywords(),
                      })
                    : caption
            ),
        ]);
    }

    private _startSeoPostDuplication(): void {
        this.isLoading.set(true);
        setTimeout(() => {
            this.isLoaderMinDurationReached.set(true);
        }, this._LOADER_MIN_DURATION);
        const postId = this.sharedData.postToDuplicate._id;
        const restaurantIds = this.inputData.selectedRestaurants.map((restaurant) => restaurant._id.toString());
        this._postsService
            .duplicatePostTextsForRestaurants({ postIdToDuplicate: postId, restaurantIds })
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: (res) => {
                    this.restaurantsData.set(
                        this.inputData.selectedRestaurants
                            .map((restaurant) => {
                                const associatedData = res.data.find((d) => d.restaurantId === restaurant._id);
                                const keywords = associatedData?.restaurantKeywords.map((k) => Keyword.fromRestaurantKeywordDto(k)) ?? [];
                                return {
                                    ...associatedData,
                                    restaurant,
                                    keywords,
                                    postCaption$: new BehaviorSubject(associatedData?.postCaption ?? ''),
                                    keywords$: of(keywords),
                                    restaurant$: of(restaurant),
                                };
                            })
                            .sort((a, b) => {
                                const diffA = this._countDifferenceBetweenStrings(this.sharedData.postToDuplicate.text, a.postCaption);
                                const diffB = this._countDifferenceBetweenStrings(this.sharedData.postToDuplicate.text, b.postCaption);
                                return diffB - diffA;
                            })
                    );
                    this._initNewPostsDateForms();
                    this.initialRestaurantsHtmlCaptions.set(
                        this.restaurantsData().map((data) =>
                            highlightKeywordsInText({
                                text: data.postCaption,
                                keywords: data.keywords,
                                restaurantName: data.restaurant.name,
                                currentLang: this.langForKeywords(),
                            })
                        )
                    );
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                    this.isCaptionsGenerationError.set(true);
                },
            });
    }

    protected _submitData(): DuplicatePostPreviewModalSubmitData {
        const commonMappedData = this._mapFormData({
            plannedPublicationDate: this.customizedDatePostForm.value.plannedPublicationDate!,
            postTime: this.customizedDatePostForm.value.postTime!,
            status: this.customizedDatePostForm.value.status!,
        });
        return this.restaurantsData().map((data, index) => ({
            restaurantId: data.restaurant._id,
            postCaption: data.postCaption!,
            ...(this.willPostAllAtSameTime()
                ? commonMappedData
                : this._mapFormData({
                      plannedPublicationDate: this.newPostsDateForms[index].value.plannedPublicationDate!,
                      postTime: this.newPostsDateForms[index].value.postTime!,
                      status: this.newPostsDateForms[index].value.status!,
                  })),
        }));
    }

    private _mapFormData(formValue: { status: PostDateStatus; plannedPublicationDate: Date; postTime: string }): {
        status: PostPublicationStatus.DRAFT | PostPublicationStatus.PENDING;
        plannedPublicationDate: Date;
    } {
        switch (formValue.status) {
            case PostDateStatus.NOW:
                return {
                    status: PostPublicationStatus.PENDING,
                    plannedPublicationDate: new Date(),
                };
            case PostDateStatus.LATER:
            case PostDateStatus.DRAFT:
                const formDate = this._buildDate({
                    plannedPublicationDate: formValue.plannedPublicationDate,
                    postTime: formValue.postTime,
                });
                return {
                    status: formValue.status === PostDateStatus.LATER ? PostPublicationStatus.PENDING : PostPublicationStatus.DRAFT,
                    plannedPublicationDate: formDate,
                };
        }
    }

    protected _isValid(): boolean {
        return this._isStepValid();
    }

    private _buildDate({ plannedPublicationDate, postTime }: { plannedPublicationDate: Date; postTime: string }): Date {
        const date = new Date(plannedPublicationDate);
        const [hours, minutes] = postTime.split(':').map((n) => parseInt(n, 10));
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }

    private _countDifferenceBetweenStrings(str1?: string, str2?: string): number {
        str1 = str1 || '';
        str2 = str2 || '';

        const maxLength = Math.max(str1.length, str2.length);
        let differences = 0;

        for (let i = 0; i < maxLength; i++) {
            if (str1[i] !== str2[i]) {
                differences++;
            }
        }
        return differences;
    }

    private _initializePostForm(): void {
        const postDate = this.sharedData.postToDuplicate.plannedPublicationDate;
        const status =
            this.sharedData.postToDuplicate.published === PostPublicationStatus.PENDING ? PostDateStatus.LATER : PostDateStatus.DRAFT;
        if (postDate >= new Date()) {
            const postTime = DateTime.fromJSDate(postDate).toFormat('HH:mm');
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: postDate,
                postTime,
            });
        } else {
            const DELAY_IN_MINUTES = 5;
            const now = DateTime.now();
            const minutes = now.minute;
            const plus15RoundedMinutes = minutes % 5 === 0 ? minutes + DELAY_IN_MINUTES : minutes + 5 - (minutes % 5) + DELAY_IN_MINUTES;
            const plannedPublicationDate = now.set({ minute: plus15RoundedMinutes });
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: plannedPublicationDate.toJSDate(),
                postTime: plannedPublicationDate.toFormat('HH:mm'),
            });
        }
    }

    private _initNewPostsDateForms(): void {
        this.newPostsDateForms = this.restaurantsData().map(() => {
            const form = new FormGroup(
                {
                    status: new FormControl<PostDateStatus>(PostDateStatus.LATER),
                    plannedPublicationDate: new FormControl<Date>(
                        this.customizedDatePostForm.get('plannedPublicationDate')?.value ?? new Date()
                    ),
                    postTime: new FormControl(this.customizedDatePostForm.get('postTime')?.value),
                },
                {
                    validators: [onlyFutureDate()],
                }
            );
            form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
                this._updateStepValidity();
            });
            return form;
        });
    }

    private _updateStepValidity(restaurantsData?: DuplicatePostRestaurantData[]): void {
        const areAllFormsValid = this.willPostAllAtSameTime()
            ? this.customizedDatePostForm.valid
            : this.newPostsDateForms.every((f) => f.valid);

        this._isStepValid.set(
            this.isLoaderMinDurationReached() &&
                !this.isLoading() &&
                areAllFormsValid &&
                (restaurantsData ?? this.restaurantsData()).every((data) => !!data.postCaption && data.postCaption.length > 0)
        );

        this.valid.emit(this._isValid());
    }
}
