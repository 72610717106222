import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from ':core/services/dialog.service';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';

@Component({
    selector: 'app-missing-organization',
    templateUrl: './missing-organization.component.html',
    styleUrls: ['./missing-organization.component.scss'],
    standalone: true,
})
export class MissingOrganizationComponent implements OnInit {
    constructor(
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _dialogService: DialogService
    ) {}

    ngOnInit(): void {
        const { redirect: redirectRaw } = this._route.snapshot.queryParams;
        const redirect = redirectRaw
            ? redirectRaw.replace(/__/g, '/') // redirect url should be encoded as restaurants__123__platforms
            : null;
        this._dialogService.open({
            title: this._translate.instant('credentials.missing_organization.title'),
            message: this._translate.instant('credentials.missing_organization.text'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translate.instant('common.ok'),
                action: () => {
                    this._router.navigate([redirect || '/restaurants/list'], { relativeTo: this._route });
                },
            },
        });
    }
}
