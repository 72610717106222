import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-url-redirect',
    templateUrl: './url-redirect.component.html',
    styleUrls: ['./url-redirect.component.scss'],
    standalone: true,
})
export class UrlRedirectComponent {
    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router
    ) {
        const currentUrl = this._router.url;
        if (currentUrl.includes('/resources/totems') || currentUrl.includes('/resources/booster')) {
            this._router.navigate(['../../boosters'], { relativeTo: this._route });
        }
    }
}
