import { z } from 'zod';

import { NotificationChannel, NotificationType, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { notificationDTOValidator } from './notifications.dto';

export const notificationsEmailOpenedQueryValidator = z.object({
    notificationId: objectIdValidator,
    receiverEmail: z.string(),
    type: z.nativeEnum(NotificationType),
});

export type NotificationsEmailOpenedQueryDto = z.infer<typeof notificationsEmailOpenedQueryValidator>;

export const getNotificationsQueryValidator = z.object({
    pageNumber: z.coerce.number().int().gte(0),
    pageSize: z.coerce.number().int().gt(0),
    notificationTypes: z.array(z.nativeEnum(NotificationType)),
    channel: z.nativeEnum(NotificationChannel),
    restaurantIds: z.array(objectIdValidator).optional(),
});

export type GetNotificationsQueryDto = z.infer<typeof getNotificationsQueryValidator>;

export const updateNotificationsBodyValidator = z.object({
    notificationIds: z.array(objectIdValidator),
    update: notificationDTOValidator.pick({ readAt: true, completedAt: true }).refine((value) => {
        return (
            Object.keys(value).length > 0,
            {
                message: 'At least one field must be provided',
            }
        );
    }),
});

export type UpdateNotificationsBodyDto = z.infer<typeof updateNotificationsBodyValidator>;

export const sendTestNotificationBodyValidator = z.object({
    notificationType: z.nativeEnum(NotificationType),
});

export type SendTestNotificationBodyDto = z.infer<typeof sendTestNotificationBodyValidator>;

export const getNotificationByIdParamsValidator = z.object({
    notificationId: objectIdValidator,
});

export type GetNotificationByIdParamsDto = z.infer<typeof getNotificationByIdParamsValidator>;

export const getActiveNotificationsQueryValidator = z.object({
    notificationTypes: z.array(z.nativeEnum(NotificationType)),
    channel: z.nativeEnum(NotificationChannel),
    restaurantIds: z.array(objectIdValidator),
});

export type GetActiveNotificationsQueryDto = z.infer<typeof getActiveNotificationsQueryValidator>;

// ------------------------------------------------------------
export const updateSpecialHourNotificationParamsValidator = z
    .object({
        notification_id: objectIdValidator,
    })
    .transform((data) => ({
        notificationId: data.notification_id,
    }));
export type UpdateSpecialHourNotificationParamsDto = z.infer<typeof updateSpecialHourNotificationParamsValidator>;

export const updateSpecialHourNotificationBodyValidator = z.object({
    action: z.nativeEnum(SpecialHourNotificationAction),
});
export type UpdateSpecialHourNotificationBodyDto = z.infer<typeof updateSpecialHourNotificationBodyValidator>;

// ------------------------------------------------------------

export const getUnreadNotificationCountQueryValidator = z.object({
    notificationTypes: z.array(z.nativeEnum(NotificationType)),
    channel: z.nativeEnum(NotificationChannel),
    restaurantIds: z.array(objectIdValidator).optional(),
});

export type GetUnreadNotificationCountQueryDto = z.infer<typeof getUnreadNotificationCountQueryValidator>;

// ------------------------------------------------------------
