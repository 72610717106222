@if (isLoading || isUpdating) {
    <div class="h-full overflow-y-auto px-7.5 md:px-6">
        @if (!screenSizeService.isPhoneScreen) {
            <app-skeleton gapClass="gap-1" skeletonClass="secondary-bg !h-24" [count]="12"></app-skeleton>
        } @else {
            <app-skeleton gapClass="gap-2" skeletonClass="secondary-bg !h-20" [count]="12"> </app-skeleton>
        }
    </div>
} @else {
    @if (!!fetchCommentsError || !comments || comments.length === 0) {
        <app-no-comment-view [error]="fetchCommentsError" [hasSearch]="!!(filters$ | async)?.text"></app-no-comment-view>
    } @else {
        <div
            class="flex h-full flex-col gap-y-1 overflow-y-auto px-7.5 md:gap-y-2 md:px-6"
            id="comments-list-infinite-scroll-container"
            infinite-scroll
            infiniteScrollContainer="#comments-list-infinite-scroll-container"
            [infiniteScrollDistance]="2"
            [fromRoot]="true"
            (scrolled)="onScroll()">
            @for (comment of comments; track trackByIdFn($index, comment)) {
                <app-comment-preview [comment]="comment" [isAnswered]="comment.hasBeenAnswered" (archived)="onCommentToggleArchive($event)">
                </app-comment-preview>
            }
            @if (loadingMore$ | async) {
                @if (!screenSizeService.isPhoneScreen) {
                    <app-skeleton gapClass="gap-1" skeletonClass="secondary-bg !h-24" [count]="2"> </app-skeleton>
                } @else {
                    <app-skeleton gapClass="gap-2" skeletonClass="secondary-bg !h-20" [count]="2"> </app-skeleton>
                }
            }
            @if (pagination.pageNumber * pagination.pageSize >= pagination.total) {
                <div class="my-9 flex w-full justify-center md:mb-36">
                    <div class="mr-1 h-4 w-4">
                        <img alt="Goggles illustration" [lazyLoad]="'Goggles' | illustrationPathResolver" />
                    </div>
                    <div class="malou-text-13--semibold malou-color-text-2">
                        {{ 'moderation.end_of_comments' | translate }}
                    </div>
                </div>
            }
            <app-scroll-to-top
                class="fixed bottom-7.5 right-7.5 md:bottom-20"
                container="#comments-list-infinite-scroll-container"></app-scroll-to-top>
        </div>
    }
}
