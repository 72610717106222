import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { KeywordsService } from ':core/services/keywords.service';
import { ToastService } from ':core/services/toast.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { Keyword } from ':shared/models/keyword';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { RankingTableDataRow } from '../keywords-list/keywords-list.component.interface';

@Component({
    selector: 'app-update-keyword-modal',
    templateUrl: './update-keyword-modal.component.html',
    styleUrls: ['./update-keyword-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, InputTextComponent, FormsModule, ReactiveFormsModule, MatButtonModule, TranslateModule],
})
export class UpdateKeywordModalComponent {
    readonly SvgIcon = SvgIcon;
    keyword: RankingTableDataRow;
    newVolume = new UntypedFormControl('', [Validators.required]);
    private _keywords: Keyword[] = [];

    constructor(
        private readonly _dialogRef: MatDialogRef<UpdateKeywordModalComponent>,
        private readonly _keywordsService: KeywordsService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            keywordToUpdate: RankingTableDataRow;
            keywords: Keyword[];
            restaurantId: string;
        }
    ) {
        this.keyword = this.data.keywordToUpdate;
        this._keywords = this.data.keywords;
    }

    save(): void {
        const volume = this.newVolume.value;
        this._keywordsService.updateKeywordVolumeFromAdmin(this.keyword.keywordId, Number(volume), this.data.restaurantId).subscribe({
            next: (keyword) => {
                this.close(keyword.toRankingTableDataRows(this._keywords));
            },
            error: (err) => {
                console.error(err);
                this._toastService.openErrorToast(this._translate.instant('keywords.update_modal.error_occurred'));
            },
        });
    }

    close(newValue: RankingTableDataRow | null = null): void {
        this._dialogRef.close(newValue);
    }
}
