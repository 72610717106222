<div
    class="flex items-center justify-between rounded-lg border border-malou-color-border-primary bg-malou-color-background-light px-4 py-2">
    <div>
        <span class="malou-text-14--semibold text-malou-color-text-1">
            {{ calendarEvent() | applySelfPure: 'getNameForLang' : LANG }} -
        </span>
        <span class="malou-text-14--regular italic text-malou-color-text-2">
            {{ calendarEvent() | applySelfPure: 'getDisplayStartDate' : LANG }}
        </span>
    </div>

    <button class="malou-btn-flat !p-0" mat-button [disabled]="disabled()" (click)="validateHoursForCalendarEvent()">
        {{ 'informations.special_hours_calendar_event_validate_hours' | translate }}
    </button>
</div>
