<div class="malou-dialog malou-dialog-mobile flex h-full flex-col !overflow-hidden">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex w-full items-center justify-between">
            <span class="malou-text-18--bold text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.title' | translate: { gift: data.giftName } }}
            </span>
            <mat-icon
                class="close cursor-pointer"
                id="tracking_wof_cancel_stocks_modal"
                [svgIcon]="SvgIcon.CROSS"
                (click)="close()"></mat-icon>
        </div>
    </div>

    <div class="malou-dialog__body !m-0 flex max-h-[60vh] flex-col gap-y-4">
        <mat-tab-group
            class="h-full"
            animationDuration="5ms"
            mat-align-tabs="start"
            [selectedIndex]="selectedTabIndex()"
            (selectedIndexChange)="handleTabChange($event)">
            @for (tab of TABS(); track tab.key) {
                <mat-tab [labelClass]="'tracking_edit_custom_ai_settings_modal_' + tab.key + '_tab'">
                    <ng-template mat-tab-label>
                        <div class="malou-text-14--regular flex items-center text-malou-color-text-2">
                            <span class="tab-title" [class]="{ 'malou-text-14--semibold': selectedTab().key === tab.key }">{{
                                tab.label
                            }}</span>
                        </div>
                    </ng-template>
                    @if (selectedTab().key === tab.key) {
                        <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                            @switch (selectedTab().key) {
                                @case (TabKeys.STOCKS) {
                                    <ng-container [ngTemplateOutlet]="stocksTabTemplate"></ng-container>
                                }
                                @case (TabKeys.CONDITIONS) {
                                    <ng-container [ngTemplateOutlet]="conditionsTabTemplate"></ng-container>
                                }
                            }
                        </div>
                    }
                </mat-tab>
            }
        </mat-tab-group>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" id="tracking_wof_cancel_stocks_modal" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11 md:grow"
            id="tracking_wof_save_stocks_modal"
            mat-raised-button
            [disabled]="disableSubmit()"
            (click)="editStocks()">
            {{ 'common.validate' | translate }}
        </button>
    </div>
</div>

<ng-template #stocksTabTemplate>
    @for (restaurantGiftStock of restaurantGiftStocks(); track restaurantGiftStock; let index = $index) {
        <div class="malou-card !m-0 !flex-auto !p-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <img class="malou-avatar--small" [lazyLoad]="restaurantGiftStock.restaurant.getLogoUrl('small')" />
                    <div class="ml-3">
                        <div class="malou-text-14--semibold text-malou-color-text-1">
                            {{ restaurantGiftStock.restaurant.name }}
                        </div>
                        <div class="malou-text-10--regular italic text-malou-color-text-2">
                            {{ restaurantGiftStock.restaurant.address | applySelfPure: 'getFullFormattedAddress' }}
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="malou-text-10--regular mr-2 text-malou-color-text-2">
                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.unlimited_stocks' | translate }}
                    </div>
                    <app-slide-toggle
                        id="tracking_wof_unlimited_stocks_toggle"
                        [checked]="restaurantGiftStock.unlimited"
                        (onToggle)="toggleUnlimited(index)">
                    </app-slide-toggle>
                    @if (restaurantGiftStocks().length > 1) {
                        <div class="relative ml-2 flex items-center gap-x-3">
                            <button
                                class="malou-btn-icon--secondary btn-xl"
                                id="copyGiftStock"
                                mat-icon-button
                                matTooltip="{{
                                    'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.copy_stocks' | translate
                                }}"
                                (click)="copy($event, restaurantGiftStock, index)">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.COPY"></mat-icon>
                            </button>
                            @if (showCopyModal() && currentCopyModalIndex() === index) {
                                <div
                                    class="malou-card copy-quantity-modal-box-shadow !absolute right-[-9px] z-10 w-[270px] !px-0 !py-2"
                                    id="copyModal">
                                    <ng-container *ngTemplateOutlet="copymodal"></ng-container>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            @if (!restaurantGiftStock.unlimited) {
                <div class="stock-quantity-container mt-2 flex items-center gap-x-3">
                    @if (showCurrentStock()(restaurantGiftStock.restaurant.id)) {
                        <div class="grow">
                            <ng-container
                                [ngTemplateOutlet]="currentStockTemplate"
                                [ngTemplateOutletContext]="{
                                    value: restaurantGiftStock.unlimited ? null : restaurantGiftStock.quantity,
                                    restaurantId: restaurantGiftStock.restaurant.id,
                                }"></ng-container>
                        </div>
                    } @else {
                        <ng-container
                            [ngTemplateOutlet]="editStockTemplate"
                            [ngTemplateOutletContext]="{ quantity: restaurantGiftStock.quantity, index }"></ng-container>
                    }
                </div>
            }
        </div>
    }
</ng-template>

<ng-template #conditionsTabTemplate>
    <div class="malou-text-12 mb-3 italic text-malou-color-text-2">
        {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.tabs.conditions.subtitle' | translate }}
    </div>
    <div class="flex flex-col gap-3">
        @for (lang of APPLICATION_LANGUAGES; track lang) {
            <app-input-text
                class="w-full"
                [placeholder]="
                    ('wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.tabs.conditions.placeholder'
                        | translateIn: lang
                        | async) ?? ''
                "
                [svgImg]="getFlagImg | applyPure: lang"
                [defaultValue]="giftConditions()?.[lang] ?? undefined"
                (inputTextChange)="onEditCondition($event, lang)"></app-input-text>
        }
    </div>
</ng-template>

<ng-template let-quantity="quantity" let-index="index" #editStockTemplate>
    <app-input-number
        class="w-full"
        [title]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.quantity_input.title' | translate"
        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.quantity_input.placeholder' | translate"
        [required]="true"
        [min]="GIFT_STOCK_MIN_QUANTITY"
        [value]="quantity"
        (inputNumberChange)="onQuantityChange(index, $event)">
    </app-input-number>
</ng-template>

<ng-template let-value="value" let-restaurantId="restaurantId" #currentStockTemplate>
    <div class="flex w-full flex-col">
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.current_stock' | translate }}
        </div>

        <div class="malou-border-primary flex items-center self-stretch rounded-lg !bg-malou-color-background-dark">
            <div
                class="malou-text-12--medium flex h-12 w-full items-center justify-between px-5 text-malou-color-text-2"
                [ngClass]="{ italic: value === null }">
                <div>
                    @if (value !== null) {
                        <span>{{ value }}</span>
                    } @else {
                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.quantity_input.unlimited' | translate }}
                    }
                </div>

                <button class="malou-btn-flat" mat-button (click)="onEditStock(restaurantId)">{{ 'common.edit' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #copymodal>
    <div class="malou-card__body flex flex-col gap-y-1 !p-0">
        <div class="flex items-center px-3 py-2">
            <mat-checkbox color="primary" [checked]="areAllRestaurantsChecked()" (change)="toggleAllCopy($event)"> </mat-checkbox>
            <div class="malou-text-14--semibold ml-3 text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.your_establishments' | translate }}
            </div>
        </div>
        @for (restaurant of restaurants(); track trackByIdFn($index, restaurant)) {
            <div
                class="flex items-center px-3 py-2"
                [ngClass]="{
                    'opacity-50': restaurant.id === currentModalGiftStock()?.restaurant?.id,
                    'bg-malou-color-background-dark': isChecked()(restaurant),
                }">
                <mat-checkbox
                    color="primary"
                    [checked]="isChecked()(restaurant)"
                    [disabled]="restaurant.id === currentModalGiftStock()?.restaurant?.id"
                    (change)="toggleCopy($event, restaurant)">
                </mat-checkbox>
                <div class="ml-3">
                    <div class="malou-text-14--semibold text-malou-color-text-1">
                        {{ restaurant.name }}
                    </div>
                    <div class="malou-text-10--regular italic text-malou-color-text-2">
                        {{ restaurant.address | applySelfPure: 'getFullFormattedAddress' }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
