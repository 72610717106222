<div class="hide-scrollbar flex h-[80vh] flex-col items-center justify-around pb-[10vh] pt-[5vh]">
    <app-malou-spinner size="large" [color]="primaryColor()"></app-malou-spinner>
    <div class="flex flex-col text-center">
        <div class="malou-text-22--semibold flex justify-center" [ngStyle]="{ color: secondaryColor() }">
            <mat-icon class="mr-3" [svgIcon]="SvgIcon.COPY"></mat-icon>
            <span>{{ 'play_wheel_of_fortune.leave_review.come_back_game_page' | translate }}</span>
        </div>
        <p class="malou-text-22--semibold malou-color-text-1 mt-5">
            {{
                (redirectionPlatform() === PlatformKey.INSTAGRAM
                    ? 'play_wheel_of_fortune.leave_review.after_subscribing'
                    : 'play_wheel_of_fortune.leave_review.after_leaving_review'
                ) | translate
            }}
        </p>
        <p class="malou-text-16--medium malou-color-text-2 mt-10 italic">
            {{ 'play_wheel_of_fortune.leave_review.redirected_to' | translate }}
            {{ redirectionPlatform() | enumTranslate: 'platform_key' }}.
        </p>
    </div>
</div>
