<div class="mb-3">
    <app-search [debounceTime]="0" [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
</div>
<div class="hide-scrollbar flex h-[60vh] flex-col overflow-auto">
    <ng-container [ngTemplateOutlet]="restaurantsAndAiSettingsTableTemplate"> </ng-container>
    <app-paginator
        [shouldBeHidden]="restaurantAndAiSettingsDataSource.filteredData.length < DEFAULT_PAGINATION_SIZE"
        [pageSize]="DEFAULT_PAGINATION_SIZE"
        [pageSizeOptions]="[DEFAULT_PAGINATION_SIZE]">
    </app-paginator>
</div>

<ng-template #restaurantsAndAiSettingsTableTemplate>
    <mat-table class="malou-mat-table" matSort matSortDisableClear [dataSource]="restaurantAndAiSettingsDataSource" #table="matTable">
        <ng-container matColumnDef="restaurant">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let restaurantAndAiSettings; table: table" class="mb-[2px] w-full py-1 pr-1">
                <ng-container
                    [ngTemplateOutlet]="restaurantTemplate"
                    [ngTemplateOutletContext]="{ restaurantAndAiSettings }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="restaurantName">
            <mat-header-cell *matHeaderCellDef
                >{{ 'restaurant_ai_settings.modals.duplicate.personalize_duplication.restaurant_name' | translate }} *</mat-header-cell
            >
            <mat-cell
                *matCellDef="let restaurantAndAiSettings; table: table; let index = index"
                class="mb-[2px] w-full rounded-[10px] py-1 pr-1">
                <ng-container
                    [ngTemplateOutlet]="restaurantNameTemplate"
                    [ngTemplateOutletContext]="{ restaurantAndAiSettings, index }"></ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="signature">
            <mat-header-cell *matHeaderCellDef>{{
                'restaurant_ai_settings.modals.duplicate.personalize_duplication.signature' | translate
            }}</mat-header-cell>
            <mat-cell
                *matCellDef="let restaurantAndAiSettings; table: table; let index = index"
                class="mb-[2px] w-full rounded-[10px] py-1 pr-1">
                <ng-container
                    [ngTemplateOutlet]="signatureTemplate"
                    [ngTemplateOutletContext]="{ restaurantAndAiSettings, index }"></ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="!min-h-fit"></mat-header-row>
        <mat-row
            *matRowDef="let restaurantAndAiSettings; columns: displayedColumns; table: table; let i = index"
            class="row !border-0 hover:!cursor-default hover:!bg-white"></mat-row>
    </mat-table>
</ng-template>

<ng-template let-restaurantAndAiSettings="restaurantAndAiSettings" #restaurantTemplate>
    <div class="ml-2 flex w-full items-center gap-x-2">
        <img
            class="malou-avatar--medium h-[45px] w-[45px]"
            [lazyLoad]="
                (restaurantAndAiSettings.restaurant.logo | applySelfPure: 'getMediaUrl' : 'small') ??
                ('default-picture-grey' | imagePathResolver)
            " />
        <div class="mx-2 min-w-0 grow">
            <div class="flex items-center">
                <div
                    class="mr-1 flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                    [matTooltip]="
                        ((restaurantAndAiSettings.restaurant | applySelfPure: 'isBrandBusiness')
                            ? 'common.brand_account'
                            : 'common.business'
                        ) | translate
                    "
                    [ngClass]="
                        (restaurantAndAiSettings.restaurant | applySelfPure: 'isBrandBusiness')
                            ? 'bg-malou-color-state-warn'
                            : 'bg-malou-color-chart-pink--light'
                    ">
                    <mat-icon
                        class="!h-2 text-white"
                        [svgIcon]="
                            (restaurantAndAiSettings.restaurant | applySelfPure: 'isBrandBusiness') ? 'heart' : 'localisation'
                        "></mat-icon>
                </div>
                <div class="malou-text-12--bold truncate leading-6 text-malou-color-text-1">
                    {{ restaurantAndAiSettings.restaurant.name }}
                </div>
            </div>
            @if (!(restaurantAndAiSettings.restaurant | applySelfPure: 'isBrandBusiness')) {
                <div class="malou-text-12 truncate italic leading-6">
                    {{ restaurantAndAiSettings.restaurant.address }}
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template let-restaurantAndAiSettings="restaurantAndAiSettings" let-index="index" #restaurantNameTemplate>
    <form class="w-full" [formGroup]="restaurantAndAiSettings.form">
        <div class="malou-text-10--regular">
            <app-input-text
                class="grow"
                formControlName="restaurantName"
                [errorMessage]="!restaurantAndAiSettings.form.controls.restaurantName.value ? ('common.field_required' | translate) : ''"
                [showRequiredStar]="false"
                (input)="onRestaurantNameInputChange($event, index)"></app-input-text>
        </div>
    </form>
</ng-template>

<ng-template let-restaurantAndAiSettings="restaurantAndAiSettings" let-index="index" #signatureTemplate>
    <form class="flex w-full gap-x-8" [formGroup]="restaurantAndAiSettings.form">
        <div class="grow">
            <app-input-text
                class="grow"
                formControlName="signature"
                [showRequiredStar]="false"
                [placeholder]="
                    'restaurant_ai_settings.modals.duplicate.personalize_duplication.signature_placeholder'
                        | translate: { restaurantName: restaurantAndAiSettings.form.controls.restaurantName.value }
                "
                (input)="onSignatureInputChange($event, index)"></app-input-text>
        </div>
    </form>
</ng-template>
