import { Action, createReducer, on } from '@ngrx/store';

import * as MessageTemplatesActions from './message-templates.actions';

export interface MessageTemplateState {
    templateCount: number;
    sort: {
        sortBy: string;
        sortOrder: string;
    };
}

export const initialState: MessageTemplateState = {
    templateCount: 0,
    sort: {
        sortBy: 'name',
        sortOrder: 'asc',
    },
};

const messageTemplatesReducer = createReducer(
    initialState,
    on(MessageTemplatesActions.setTemplateCount, (state, { count }) => ({
        ...state,
        templateCount: count,
    })),
    on(MessageTemplatesActions.editMessageTemplatesSort, (state, sort) => ({
        ...state,
        sort: {
            ...state.sort,
            ...sort,
        },
    }))
);

export function reducer(state: MessageTemplateState | undefined, action: Action): MessageTemplateState {
    return messageTemplatesReducer(state, action);
}
