import { AbstractControl } from '@angular/forms';

import { urlRegex } from '@malou-io/package-utils';

// custom validator to check that two fields match
export function IsUrl() {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }
        return control.value.match(urlRegex) ? null : { error: 'URL non valide' };
    };
}

// warning, getting file type from distant url is not possible, this is just a guess
// https://stackoverflow.com/questions/3234090/how-to-determine-the-file-extension-of-a-file-from-a-uri/14604637
export function getfileExtensionFromUrl(url: string): { type: string | null; extension: string | null } {
    if (url.includes('.jpg')) {
        return {
            type: 'image',
            extension: 'jpg',
        };
    }
    if (url.includes('.jpeg')) {
        return {
            type: 'image',
            extension: 'jpeg',
        };
    }
    if (url.includes('.png')) {
        return {
            type: 'image',
            extension: 'png',
        };
    }
    if (url.includes('.mp4')) {
        return {
            type: 'video',
            extension: 'mp4',
        };
    }
    return { type: null, extension: null };
}
