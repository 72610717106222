import { objectToSnakeCase } from './camel-to-snake';
import { removeNullOrUndefinedField } from './remove-null-or-undefined-field';

export function objectToQueryParams(obj: Record<string, any>): Record<string, any> {
    const definedFields = removeNullOrUndefinedField(obj);
    const snakeCaseFields = objectToSnakeCase(definedFields);

    return formatArrayKeysForQueryParams(snakeCaseFields);
}

export function formatArrayKeysForQueryParams(obj: Record<string, any>): Record<string, any> {
    const newObj = {};

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            newObj[`${key}[]`] = obj[key];
        } else {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
