import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { generateDbId, HashtagType, PictureSize } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { HashtagsService } from ':core/services/hashtags.service';
import { HeapService } from ':core/services/heap.service';
import { NewSocialPostHashtagsContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-hashtags.context';
import { NewSocialPostContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post.context';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Hashtag } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-post-choose-hashtags-panel',
    templateUrl: './post-choose-hashtags-panel.component.html',
    styleUrls: ['./post-choose-hashtags-panel.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        MatTabsModule,
        TranslateModule,
        SearchComponent,
        ApplyPurePipe,
        NgTemplateOutlet,
        SkeletonComponent,
        MalouSpinnerComponent,
        ReactiveFormsModule,
        MatButtonModule,
    ],
})
export class PostChooseHashtagsPanelComponent {
    readonly newSocialPostHashtagsContext = inject(NewSocialPostHashtagsContext);
    readonly newSocialPostContext = inject(NewSocialPostContext);
    readonly _hashtagsService = inject(HashtagsService);
    readonly _trackingService = inject(HeapService);

    readonly SvgIcon = SvgIcon;
    readonly currentTabIndex = signal(0);
    readonly generatedHashtagsSearchInputText = signal('');
    readonly bookmarkedHashtagsSearchInputText = signal('');

    readonly isOpen = computed(
        () =>
            this.newSocialPostHashtagsContext.chooseHashtagsPanelDisplayState() ===
            this.newSocialPostHashtagsContext.ChooseHashtagsPanelDisplayState.OPEN
    );

    readonly restaurantLogo = computed(() => this.newSocialPostContext.currentRestaurant().logo?.getMediaUrl(PictureSize.SMALL));
    readonly MAX_RECOMMENDED_HASHTAGS_LENGTH = 6;
    readonly MIN_RECOMMENDED_HASHTAGS_LENGTH = 3;
    readonly isSelectedHashtagsLengthInRecommandations = computed(
        () =>
            this.newSocialPostHashtagsContext.selectedHashtags().length >= this.MIN_RECOMMENDED_HASHTAGS_LENGTH &&
            this.newSocialPostHashtagsContext.selectedHashtags().length <= this.MAX_RECOMMENDED_HASHTAGS_LENGTH
    );
    readonly isMainHashtagSelected = computed(
        () => this.newSocialPostHashtagsContext.selectedHashtags().find((h) => h.isMain) !== undefined
    );
    readonly doHashtagsContainRestaurantHashtags = computed(
        () => this.newSocialPostHashtagsContext.selectedHashtags().filter((h) => h.type === HashtagType.RESTAURANT).length > 0
    );

    readonly displayedGeneratedHashtags = computed(() => {
        this.mainHashtagTextFormControl.setValue(this.newSocialPostHashtagsContext.generatedHashtags().find((h) => h.isMain)?.text || '');

        if (this.generatedHashtagsSearchInputText().length === 0) {
            return this.newSocialPostHashtagsContext.generatedHashtags();
        }

        return this.newSocialPostHashtagsContext
            .selectedHashtags()
            .filter((hashtag) => hashtag.text.toLowerCase().includes(this.generatedHashtagsSearchInputText().toLowerCase()));
    });

    readonly displayedBookmarkedHashtags = computed(() => {
        if (this.bookmarkedHashtagsSearchInputText().length === 0) {
            return this.newSocialPostHashtagsContext.bookmarkedHashtags();
        }

        return this.newSocialPostHashtagsContext
            .bookmarkedHashtags()
            .filter((hashtag) => hashtag.text.toLowerCase().includes(this.bookmarkedHashtagsSearchInputText().toLowerCase()));
    });
    readonly isEditingMainHashtag = signal(false);
    readonly mainHashtagTextFormControl = new FormControl('', [Validators.required]);

    toggleEditingMainHashtag(): void {
        this.isEditingMainHashtag.set(!this.isEditingMainHashtag());
    }

    isHashtagSelected = (hashtag: Hashtag): boolean =>
        !!this.newSocialPostHashtagsContext
            .selectedHashtags()
            .find((postGeneratedHashtag) => postGeneratedHashtag.text.toLowerCase() === hashtag.text.toLowerCase());

    isHashtagBookmarked = (hashtag: Hashtag): boolean =>
        this.newSocialPostHashtagsContext
            .bookmarkedHashtags()
            .find((h) => h.text.toLocaleLowerCase() === hashtag.text.toLocaleLowerCase()) !== undefined;

    toggleChooseHashtag(hashtag: Hashtag): void {
        if (this.isHashtagSelected(hashtag)) {
            this._trackingService.track('tracking_hashtags_remove');
            this.newSocialPostHashtagsContext.selectedHashtags.update((prev) =>
                prev.filter((h) => h.text.toLowerCase() !== hashtag.text.toLowerCase())
            );
        } else {
            this._trackingService.track('tracking_hashtags_add');
            this.newSocialPostHashtagsContext.selectedHashtags.update((prev) => [...prev, hashtag]);
        }
    }

    toggleBookmarkHashtag(hashtag: Hashtag): void {
        if (this.isHashtagBookmarked(hashtag)) {
            this._hashtagsService.deleteHashtag(hashtag.id).subscribe({
                error: (err) => console.error('err >', err),
            });
            this.newSocialPostHashtagsContext.bookmarkedHashtags.update((prev) => prev.filter((h) => h !== hashtag));
        } else {
            this._trackingService.track('tracking_hashtags_add_favorites');
            this._hashtagsService
                .saveHashtag({
                    ...hashtag,
                    restaurantId: this.newSocialPostContext.currentRestaurant().id,
                })
                .subscribe({
                    error: (err) => console.error('err >', err),
                });
            this.newSocialPostHashtagsContext.bookmarkedHashtags.update((prev) => [...prev, hashtag]);
        }
    }

    searchChanged(value: string, fromSelected: boolean): void {
        if (fromSelected) {
            this.generatedHashtagsSearchInputText.set(value);
        } else {
            this.bookmarkedHashtagsSearchInputText.set(value);
        }
    }

    onNewSuggestionAdded(hashtagsAsText: string): void {
        this._trackingService.track('tracking_hashtags_add');
        const newHashtags = this._cleanAndMapHashtagsTextToHashtags(hashtagsAsText);
        this.newSocialPostHashtagsContext.selectedHashtags.update((prev) => [...prev, ...newHashtags]);
        this.newSocialPostHashtagsContext.generatedHashtags.update((prev) => [...prev, ...newHashtags]);
    }

    onNewBookmarkedHashtagsAdded(hashtagsAsText: string): void {
        this._trackingService.track('tracking_hashtags_add');
        const newBookmarkedHashtags = this._cleanAndMapHashtagsTextToHashtags(hashtagsAsText);
        this.newSocialPostHashtagsContext.bookmarkedHashtags.update((prev) => [...prev, ...newBookmarkedHashtags]);
        this.newSocialPostHashtagsContext.selectedHashtags.update((prev) => [...prev, ...newBookmarkedHashtags]);
        newBookmarkedHashtags.forEach((hashtag) => {
            this._hashtagsService
                .saveHashtag({
                    ...hashtag,
                    restaurantId: this.newSocialPostContext.currentRestaurant().id,
                })
                .subscribe({
                    error: (err) => console.error('err >', err),
                });
        });
    }

    updateMainHashtag(): void {
        this.toggleEditingMainHashtag();
        const mainHashtag = this.newSocialPostHashtagsContext.mainHashtag()!;
        mainHashtag.text = this.mainHashtagTextFormControl.value?.includes('#')
            ? this.mainHashtagTextFormControl.value
            : `#${this.mainHashtagTextFormControl.value}`;
        if (this.newSocialPostHashtagsContext.selectedHashtags().find((h) => h.isMain)) {
            this.newSocialPostHashtagsContext.selectedHashtags.update((prev) => {
                const newSelectedHashtags = prev.filter((h) => h.isMain !== mainHashtag.isMain);
                newSelectedHashtags.unshift(mainHashtag);
                return newSelectedHashtags;
            });
        }
        this.newSocialPostHashtagsContext.generatedHashtags.update((prev) => {
            const newGeneratedHashtags = prev.filter((h) => h.isMain !== mainHashtag.isMain);
            newGeneratedHashtags.unshift(mainHashtag);
            return newGeneratedHashtags;
        });

        this._hashtagsService.editMainHashtagText(mainHashtag.id, mainHashtag.text).subscribe({
            error: (err) => console.error('err >', err),
        });
    }

    private _cleanAndMapHashtagsTextToHashtags(hashtagsAsText: string): Hashtag[] {
        const hashtagsTextCleaned = hashtagsAsText.replace(/#/g, ' ').trim().replace(/\s+/g, ' ').split(' ');
        return hashtagsTextCleaned.map(
            (text) =>
                new Hashtag({
                    text: `#${text}`,
                    id: generateDbId().toHexString(),
                    isMain: false,
                    isCustomerInput: true,
                    type: HashtagType.UNKNOWN,
                })
        );
    }
}
