import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';
import {
    ROUTER_LINK_ACTIVE_OPTIONS,
    SidenavContentRouteOptions,
} from '../../sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';

@Component({
    selector: 'app-sidenav-content-resources-routes',
    templateUrl: './sidenav-content-resources-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule, ConcatPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentResourcesRoutesComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    private readonly _selectedRestaurant$ = this._restaurantsService.restaurantSelected$;
    private readonly _isBrand$ = this._selectedRestaurant$.pipe(map((restaurant) => restaurant?.isBrandBusiness()));
    private readonly _baseUrl$ = this._selectedRestaurant$.pipe(map((restaurant) => ['/restaurants', restaurant?._id ?? '']));
    readonly baseUrl = toSignal(this._baseUrl$);

    private readonly _galleryRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['resources', 'gallery'])));
    private readonly _galleryRouterLink = toSignal(this._galleryRouterLink$);
    private readonly _reviewTemplatesRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['resources', 'review-templates'])));
    private readonly _reviewTemplatesRouterLink = toSignal(this._reviewTemplatesRouterLink$);
    private readonly _messageTemplatesRouterLink$ = this._baseUrl$.pipe(
        map((baseUrl) => baseUrl.concat(['resources', 'message-templates']))
    );
    private readonly _messageTemplatesRouterLink = toSignal(this._messageTemplatesRouterLink$);
    private readonly _clientsRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['resources', 'clients'])));
    private readonly _clientsRouterLink = toSignal(this._clientsRouterLink$);
    private readonly _keywordsRouterLink$ = this._baseUrl$.pipe(map((baseUrl) => baseUrl.concat(['resources', 'keywords'])));
    private readonly _keywordsRouterLink = toSignal(this._keywordsRouterLink$);

    private readonly _galleryOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.gallery'),
        routerLink: this._galleryRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _reviewTemplatesOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.review_templates'),
        routerLink: this._reviewTemplatesRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _messageTemplatesOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.message_templates'),
        routerLink: this._messageTemplatesRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _clientsOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.clients'),
        routerLink: this._clientsRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));
    private readonly _keywordsOptions = computed(() => ({
        text: this._translateService.instant('sidenav_content.keywords'),
        routerLink: this._keywordsRouterLink() ?? [],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    }));

    private readonly _isBrand = toSignal(this._isBrand$);
    private readonly _shouldShowReviewTemplatesNavigation = computed(() => !this._isBrand());
    private readonly _shouldShowClientsNavigation = computed(() => !this._isBrand());
    private readonly _shouldShowKeywordsNavigation = computed(() => !this._isBrand());

    readonly childrenOptions = computed(() => {
        const options: SidenavContentRouteOptions[] = [];
        options.push(this._galleryOptions());
        if (this._shouldShowReviewTemplatesNavigation()) {
            options.push(this._reviewTemplatesOptions());
        }
        options.push(this._messageTemplatesOptions());
        if (this._shouldShowClientsNavigation()) {
            options.push(this._clientsOptions());
        }
        if (this._shouldShowKeywordsNavigation()) {
            options.push(this._keywordsOptions());
        }
        return options;
    });

    private readonly _onNavigationEnd$ = this._router.events.pipe(filter((event) => event.type === EventType.NavigationEnd));
    private readonly _onNavigationEnd = toSignal(this._onNavigationEnd$);

    constructor() {
        this._initEffectRedirection();
    }

    private _initEffectRedirection(): void {
        effect(() => {
            this._onNavigationEnd(); // Used to trigger this effect
            const reviewTemplatesUrl = this._reviewTemplatesRouterLink()?.join('/');
            const isReviewTemplatesRouteActive = reviewTemplatesUrl
                ? this._router.isActive(reviewTemplatesUrl, ROUTER_LINK_ACTIVE_OPTIONS)
                : false;
            const shouldRedirectFromReviewTemplates = !this._shouldShowReviewTemplatesNavigation() && isReviewTemplatesRouteActive;

            const clientsUrl = this._clientsRouterLink()?.join('/');
            const isClientsRouteActive = clientsUrl ? this._router.isActive(clientsUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromClients = !this._shouldShowClientsNavigation() && isClientsRouteActive;

            const keywordsUrl = this._keywordsRouterLink()?.join('/');
            const isKeywordsRouteActive = keywordsUrl ? this._router.isActive(keywordsUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
            const shouldRedirectFromKeywords = !this._shouldShowKeywordsNavigation() && isKeywordsRouteActive;

            if (shouldRedirectFromReviewTemplates || shouldRedirectFromClients || shouldRedirectFromKeywords) {
                const redirectRouterLink = this._galleryRouterLink(); // because it's a route accessible without condition
                this._router.navigate(redirectRouterLink ?? []);
            }
        });
    }
}
