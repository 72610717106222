import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';

@Component({
    selector: 'app-campaigns',
    templateUrl: './campaigns.component.html',
    styleUrls: ['./campaigns.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
@AutoUnsubscribeOnDestroy()
export class CampaignsComponent implements OnInit, KillSubscriptions {
    restaurant: Restaurant | null;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router
    ) {}

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$.pipe(takeUntil(this.killSubscriptions$)).subscribe((res) => {
            this.restaurant = res;
            if (this.restaurant?.isBrandBusiness()) {
                this._router.navigate([`./restaurants/${this.restaurant._id}/dashboard`]);
            }
        });
    }
}
