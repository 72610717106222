<app-text-area
    title=""
    [id]="TEXT_AREA_ID"
    [testId]="'social-post-caption-input'"
    [shouldDisplayAiButton]="true"
    [formControl]="postDescriptionFormControl"
    [placeholder]="'social_posts.upsert_social_post_modal.content_form.caption.write_here_or' | translate"
    [autoResizeOnContentOverflow]="true"
    [resizable]="false"
    [maxLength]="isMapstrPlatformChecked() ? MAPSTR_POST_TEXT_LIMIT : POST_CAPTION_TEXT_LIMIT"
    [showMaxLength]="isMapstrPlatformChecked()"
    [isLoadingAnimationEnabled]="upsertSocialPostAiContext.isTextAreaLoadingAnimationEnabled()"
    [errorMessage]="
        isMapstrPlatformChecked() && postDescriptionFormControl.value.length > MAPSTR_POST_TEXT_LIMIT
            ? ('social_posts.new_social_post.mapstr_text_limit' | translate)
            : postDescriptionFormControl.invalid &&
                postDescriptionFormControl.touched &&
                !upsertSocialPostAiContext.isGeneratingPostTextFromAI()
              ? ('common.required_field' | translate)
              : null
    "
    [omitElement]="postHashtagsComponent()?.nativeElement"
    (appClickOutside)="handleClickOutside($event)"
    (click)="openPostCaptionAiGeneration()">
    <ng-template #loadingAnimationTemplate>
        <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
        </app-infinite-text-slide>
    </ng-template>
    @if (!upsertSocialPostAiContext.isTextAreaLoadingAnimationEnabled() && postDescriptionFormControl.value.length === 0) {
        <div class="mt-3 h-full w-full" afterTextAreaAiButtonTemplate (click)="focusTextArea()">
            <button class="malou-btn-flat !ml-1 mb-0.5 !pl-0 !pr-0" id="aiBtnInsideTextarea" mat-button (click)="onWriteWithAiClick()">
                <mat-icon class="mr-1 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>

                <span class="!malou-text-10--semibold text-malou-color-chart-purple--accent">{{
                    'social_posts.new_social_post.write_with_ai' | translate
                }}</span>
            </button>
        </div>
    }
    <div class="flex flex-col" footerTemplate>
        <app-ai-generation-actions
            optimizeButtonId="tracking_new_social_post_modal_optimize_post_with_ai_button"
            translateButtonIdPrefix="tracking_new_social_post_modal_translate_post_to_"
            [trackerId]="'tracking_new_social_post_modal_translate_' + (isReel() ? 'reel' : 'post') + '_to_custom_language_with_ai_button'"
            [disabled]="upsertSocialPostAiContext.isGeneratingPostTextFromAI()"
            [interactions]="upsertSocialPostAiContext.interactions()"
            [onReset]="upsertSocialPostAiContext.resetBrowser()"
            (onWandIconClick)="onWriteWithAiClick()"
            (onTranslate)="upsertSocialPostAiContext.translateText($event)"
            (onOptimize)="upsertSocialPostAiContext.optimizePostCaption()"
            (interactionChanged)="upsertSocialPostAiContext.onInteractionChanged($event)"></app-ai-generation-actions>
        <app-social-post-hashtags [postText]="postDescriptionFormControl.value"></app-social-post-hashtags>
    </div>
</app-text-area>
