import { DateTime } from 'luxon';

import {
    FULL_ROI_HIDDEN_FIRST_MONTHS_NUMBER,
    isValidDate,
    PARTIAL_ROI_HIDDEN_FIRST_MONTHS_NUMBER,
    ROI_ADDITIONAL_CLIENTS_BANNER_HIDDEN_FIRST_MONTHS_NUMBER,
    ROI_HIDDEN_FIRST_DAYS_NUMBER,
} from '@malou-io/package-utils';

import { Restaurant } from ':shared/models';

interface RoiPageDateLimits {
    limitDateToShowRoi: Date;
    limitDateToShowPartialRoi: Date;
    limitDateToShowAdditionalClientsBanner: Date;
}
export const getRoiPageDateLimits = (restaurant: Restaurant | null): RoiPageDateLimits | null => {
    if (!restaurant) {
        return null;
    }
    const mostRecentLimitDate = new Restaurant(restaurant).getMostRecentDateBetweenCreationAndOpening();
    if (!mostRecentLimitDate || !isValidDate(mostRecentLimitDate)) {
        return null;
    }
    const endOfMonthMostRecentLimitDate = DateTime.fromJSDate(mostRecentLimitDate).endOf('month').endOf('day');

    return {
        limitDateToShowRoi: endOfMonthMostRecentLimitDate.plus({ month: FULL_ROI_HIDDEN_FIRST_MONTHS_NUMBER }).toJSDate(),
        limitDateToShowPartialRoi: endOfMonthMostRecentLimitDate.plus({ month: PARTIAL_ROI_HIDDEN_FIRST_MONTHS_NUMBER }).toJSDate(),
        limitDateToShowAdditionalClientsBanner: endOfMonthMostRecentLimitDate
            .plus({ month: ROI_ADDITIONAL_CLIENTS_BANNER_HIDDEN_FIRST_MONTHS_NUMBER })
            .toJSDate(),
    };
};

export const isBeforeLimitDate = (limitDate: Date | undefined, options: { addMonths?: number } = { addMonths: 0 }): boolean => {
    if (!limitDate) {
        return false;
    }

    return (
        new Date().getTime() <
        DateTime.fromJSDate(limitDate)
            .plus({ month: options.addMonths, days: ROI_HIDDEN_FIRST_DAYS_NUMBER - 1 })
            .toJSDate()
            .getTime()
    );
};
