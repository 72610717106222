import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, forkJoin, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { RoiAdditionalCustomersDto, RoiTipsResponseDto } from '@malou-io/package-dto';
import {
    isNotNil,
    MAX_TIPS_TO_SHOW_CONGRATS,
    MAX_TIPS_TO_SHOW_WHEN_ROI_ACTIVATED,
    MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS,
    PlatformKey,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TipItemComponent } from ':modules/roi/roi-tip-item/tip-item.component';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { RoiTipItem } from ':shared/models/roi-tip.model';
import { RoiTipsPathResolver } from ':shared/pipes/roi-tips-path-resolver.pipe';

@Component({
    selector: 'app-tips',
    templateUrl: './tips.component.html',
    styleUrls: ['./tips.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RoiTipsPathResolver, TranslateModule, TipItemComponent, SkeletonComponent, NgTemplateOutlet, SkeletonComponent],
})
export class TipsComponent implements OnInit, KillSubscriptions {
    readonly isParentLoading = input.required<boolean>();
    readonly isBeforeRoiActivation = input.required<boolean>();

    private readonly _roiService = inject(RoiService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _roiContext = inject(RoiContext);
    public readonly screenSizeService = inject(ScreenSizeService);
    public roiContext = inject(RoiContext);

    readonly isError: WritableSignal<boolean> = signal(false);
    readonly PlatformKey = PlatformKey;
    readonly isTipsLoading = signal(false);

    readonly roiTips: WritableSignal<RoiTipItem[]> = signal([]);
    readonly MAX_TIPS_TO_SHOW_CONGRATS = MAX_TIPS_TO_SHOW_CONGRATS;
    readonly MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS = MIN_PERFORMANCE_SCORE_TO_SHOW_CONGRATS;

    readonly isLowMonthlyGainedAdditionalClientsPerMonth: WritableSignal<boolean> = signal(false);

    estimatedCustomersForNLastMonths$: Observable<RoiAdditionalCustomersDto | null> = toObservable(
        this._roiContext.estimatedCustomersForNLastMonths
    );
    killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.estimatedCustomersForNLastMonths$])
            .pipe(
                tap(() => this.isTipsLoading.set(true)),
                filter(([restaurant, _]: [Restaurant, RoiAdditionalCustomersDto | null]) => isNotNil(restaurant)),
                switchMap(([restaurant, estimatedCustomersForNLastMonths]: [Restaurant, RoiAdditionalCustomersDto | null]) =>
                    forkJoin([of(estimatedCustomersForNLastMonths), this._roiService.getTipsForRestaurant(restaurant.id), of(restaurant)])
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([estimatedCustomersDetails, tips, restaurant]: [
                    RoiAdditionalCustomersDto | null,
                    { data: RoiTipsResponseDto },
                    Restaurant,
                ]) => {
                    if (estimatedCustomersDetails) {
                        this.isLowMonthlyGainedAdditionalClientsPerMonth.set(
                            this._roiContext.getIsLowMonthlyGainedAdditionalClientsPerMonth({
                                restaurantRoiSettings: this._roiContext.roiSettings(),
                                estimatedAdditionalClients: estimatedCustomersDetails.totalCustomers,
                            })
                        );
                    }
                    const tipsToShow =
                        this.isBeforeRoiActivation() || !restaurant.roiActivated
                            ? tips.data
                            : tips.data.slice(0, MAX_TIPS_TO_SHOW_WHEN_ROI_ACTIVATED);
                    this.roiTips.set(tipsToShow.map((tip) => new RoiTipItem({ ...tip, restaurantId: restaurant.id })));
                    this.isTipsLoading.set(false);
                },
                error: (err) => {
                    this.isTipsLoading.set(false);
                    this.isError.set(true);
                    console.warn(err);
                },
            });
    }
}
