<div class="flex h-full flex-col">
    <div class="flex flex-1 flex-col gap-5 overflow-auto px-9 py-6">
        <div class="flex justify-between">
            <div class="malou-text-18--bold text-malou-color-text-1">{{ 'admin.report-settings.title' | translate }}</div>
        </div>

        <ng-container *ngTemplateOutlet="isLoading() ? loader : reportsSettings"></ng-container>
    </div>
</div>

<ng-template #loader>
    <div class="flex h-full flex-col items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
</ng-template>

<ng-template #reportsSettings>
    <app-reviews-reports-settings
        [dailyReport]="dailyReviewsReport"
        [weeklyReport]="weeklyReviewsReport"
        [currentUser]="currentUser$ | async"
        [candidates]="candidates()"
        [restaurants]="activeRestaurantsWithoutBrandBusinesses()"></app-reviews-reports-settings>
    <app-performance-reports-settings
        [dailyReport]="weeklyPerformanceReport"
        [weeklyReport]="monthlyPerformanceReport"
        [currentUser]="currentUser$ | async"
        [candidates]="candidates()"
        [restaurants]="activeRestaurants()"></app-performance-reports-settings>
</ng-template>
