import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AbsoluteCenteringDirective } from ':shared/directives/absolute-centering.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-sidenav-content-restaurant-info',
    templateUrl: './sidenav-content-restaurant-info.component.html',
    standalone: true,
    imports: [AbsoluteCenteringDirective, ImagePathResolverPipe, MatTooltipModule, MatIconModule, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'data-testid': 'sidenav-content-restaurant-info',
    },
})
export class SidenavContentRestaurantInfoComponent {
    readonly isEmptyState = input(false);
    readonly logo = input<string>();
    readonly countLogoOverlay = input<number>();
    readonly line1 = input.required<string>();
    readonly line2 = input<string>();
    readonly svgIconOnLine1 = input<SvgIcon>();
    readonly hideLines = input<boolean>(false);
}
