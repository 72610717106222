import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { days } from ':core/constants';
import { ValueWithDifference } from ':shared/interfaces/value-with-difference.interface';
import { TimePeriod } from ':shared/models/periods';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { DisplayHourPipe } from ':shared/pipes/display-hour.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

interface DayWithHours<T> {
    day: string;
    hours: T[];
}

@Component({
    selector: 'app-comparison-regular-hours',
    templateUrl: './comparison-regular-hours.component.html',
    styleUrls: ['./comparison-regular-hours.component.scss'],
    standalone: true,
    imports: [NgClass, TitleCasePipe, TranslateModule, DisplayHourPipe, EnumTranslatePipe, ApplyPurePipe],
})
export class ComparisonRegularHoursComponent implements OnInit {
    @Input() public hours: TimePeriod[];
    @Input() public referenceHours: TimePeriod[];

    hoursWithDifference: DayWithHours<ValueWithDifference<TimePeriod>>[] = [];

    dayWithHours: DayWithHours<TimePeriod>[];
    referenceDayWithHours: DayWithHours<TimePeriod>[];

    ngOnInit(): void {
        this.dayWithHours = this.computeDayWithHours(this.hours ?? []);
        this.referenceDayWithHours = this.referenceHours?.length ? this.computeDayWithHours(this.referenceHours) : [];
        this.hoursWithDifference = this.computeHoursWithDifference(this.dayWithHours, this.referenceDayWithHours);
    }

    computeDayWithHours(hours: TimePeriod[]): DayWithHours<TimePeriod>[] {
        return Object.keys(days).map((day) => ({
            day: day.toLowerCase(),
            hours: hours.filter((hour) => hour.openDay === day),
        }));
    }

    hoursHaveDifference = (hours: ValueWithDifference<TimePeriod>[]): boolean => !!hours.find((hour) => hour.hasDifference);

    computeHoursWithDifference(
        dayWithHours: DayWithHours<TimePeriod>[],
        referenceDayWithHours: DayWithHours<TimePeriod>[]
    ): DayWithHours<ValueWithDifference<TimePeriod>>[] {
        return dayWithHours.map((dayWithHour, i) => {
            const hours: ValueWithDifference<TimePeriod>[] = dayWithHour.hours.map((hour, j) => ({
                value: hour,
                hasDifference: this.checkDifference(dayWithHours, referenceDayWithHours, i, j),
            }));
            return {
                day: dayWithHour.day,
                hours,
                hasDifference: !!hours.find((h) => h.hasDifference) || !hours.length,
            };
        });
    }

    checkDifference(
        dayWithHours: DayWithHours<TimePeriod>[],
        referenceDayWithHours: DayWithHours<TimePeriod>[],
        indexDayWithHours: number,
        indexHours: number
    ): boolean {
        const dayWithHour = dayWithHours[indexDayWithHours];
        const currentHour = dayWithHour?.hours[indexHours];
        const referenceDayWithHour = referenceDayWithHours[indexDayWithHours];
        if (!referenceDayWithHour) {
            return false;
        }
        return (
            referenceDayWithHour &&
            (!referenceDayWithHour.hours.find((referenceHour) => referenceHour.equals(currentHour)) ||
                dayWithHour?.hours.length < referenceDayWithHour?.hours?.length)
        );
    }
}
