import { TranslateService } from '@ngx-translate/core';
import { snakeCase, uniqueId } from 'lodash';

import {
    AggregatedInformationUpdateProviderKey,
    InformationUpdateProvider,
    InformationUpdatesStatus,
    InformationUpdateStateError,
    InformationUpdateSupportedPlatformKey,
    PlatformDefinitions,
    platformKeyToProvider,
    YextPublisherId,
    yextPublishers,
} from '@malou-io/package-utils';

import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

export class InformationUpdateState {
    readonly id: string;
    readonly platformKey: InformationUpdateSupportedPlatformKey;
    readonly status: InformationUpdatesStatus;
    readonly details: string;
    readonly provider: InformationUpdateProvider;
    readonly providerKey: AggregatedInformationUpdateProviderKey;
    readonly translatedPlatformName: string;
    readonly translatedUpdateTime: string;
    readonly socialLink: string | null;
    readonly supportedFields: string[];
    readonly errors: InformationUpdateStateError[] = [];

    readonly _translationService: TranslateService;
    private readonly _platformNamePipe: PlatformNamePipe;

    constructor(data: {
        platformKey: InformationUpdateSupportedPlatformKey;
        status: InformationUpdatesStatus;
        provider: InformationUpdateProvider;
        socialLink: string | null;
        platformNamePipe: PlatformNamePipe;
        translationService: TranslateService;
        supportedFields: string[];
        errors?: InformationUpdateStateError[];
    }) {
        this.id = uniqueId();
        this.platformKey = data.platformKey;
        this.status = data.status;
        this.provider = data.provider;
        this.socialLink = data.socialLink;
        this.supportedFields = data.supportedFields;
        this._translationService = data.translationService;
        this._platformNamePipe = data.platformNamePipe;
        this.details = this._getTranslatedFields().sort().join(', ');
        this.providerKey = this._mapToProviderKey(this.platformKey);
        this.translatedPlatformName = this._translatePlatformName();
        this.translatedUpdateTime = this._getTranslatedUpdatePrevisionDate();
        this.errors = data.errors ?? [];
    }

    static isYextKey(key: AggregatedInformationUpdateProviderKey): key is YextPublisherId {
        return yextPublishers[key] !== undefined;
    }

    static orderedStatuses(): InformationUpdatesStatus[] {
        return [
            InformationUpdatesStatus.DONE,
            InformationUpdatesStatus.SUCCESS,
            InformationUpdatesStatus.VERIFIED,
            InformationUpdatesStatus.PENDING,
            InformationUpdatesStatus.IN_PROGRESS,
            InformationUpdatesStatus.NEED_REVIEW,
            InformationUpdatesStatus.TO_BE_SENT,
            InformationUpdatesStatus.ERROR,
        ];
    }

    isOk(): boolean {
        return (
            this.status === InformationUpdatesStatus.SUCCESS ||
            this.status === InformationUpdatesStatus.IN_PROGRESS ||
            this.status === InformationUpdatesStatus.VERIFIED ||
            this.status === InformationUpdatesStatus.PENDING ||
            this.status === InformationUpdatesStatus.TO_BE_SENT ||
            this.status === InformationUpdatesStatus.DONE ||
            this.status === InformationUpdatesStatus.NEED_REVIEW
        );
    }

    isAboutToBeSent(): boolean {
        return this.status === InformationUpdatesStatus.TO_BE_SENT;
    }

    isDone(): boolean {
        return this.status === InformationUpdatesStatus.DONE || this.status === InformationUpdatesStatus.SUCCESS;
    }

    private _translatePlatformName(): string {
        if (InformationUpdateState.isYextKey(this.providerKey)) {
            return yextPublishers[this.providerKey].name;
        }
        return this._platformNamePipe.transform(this.providerKey);
    }

    private _mapToProviderKey(key: InformationUpdateSupportedPlatformKey): AggregatedInformationUpdateProviderKey {
        return platformKeyToProvider[this.provider][key];
    }

    private _getTranslatedFields(): string[] {
        return this.supportedFields.map((key) =>
            this._translationService.instant(`platforms.connection.update_information.${snakeCase(key)}`)
        );
    }

    private _getTranslatedUpdatePrevisionDate(): string {
        const timeInterval = InformationUpdateState.isYextKey(this.providerKey)
            ? yextPublishers[this.providerKey].updateTime
            : PlatformDefinitions.getPlatformDefinition(this.providerKey)?.updateTime;

        return timeInterval ? this._translationService.instant(`platforms.connection.update_prevision_time.${timeInterval}`) : '';
    }
}
