import { PlatformDto } from '@malou-io/package-dto';
import { changePlatformUrlDomain, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { Address } from './address';
import { Category } from './category';
import { Credential } from './credential';
import { Description } from './description';
import { Menu } from './menu';
import { OtherPeriod, SpecialTimePeriod, TimePeriod } from './periods';
import { Phone } from './phone';

export enum ConnectionMode {
    API_TOKEN = 'apiToken',
    CREDENTIAL = 'credential',
}

export class Platform {
    _id: string;
    id?: string;
    restaurantId: string;
    name: string;
    key: PlatformKey;
    createdAt: Date;
    updatedAt: Date;
    website: string;
    attributes: string[];
    address: Address;
    email: string;
    openingDate: Date;
    phone: Phone = new Phone();
    regularHours: TimePeriod[] = [];
    specialHours: SpecialTimePeriod[] = [];
    otherHours: OtherPeriod[] = [];
    isClosedTemporarily: boolean;
    descriptions: Description[] = [];
    menu?: Menu;
    category: Category;
    categoryList: Category[] = [];
    socialId: string;
    parentSocialId?: string;
    socialLink?: string;
    businessSocialLinks: { home: string; media: string };
    access: {
        status: string;
        data: any;
    };
    credentials: Credential[] | string[];
    isClaimed: boolean;
    menuUrl: string;
    lockedFields: string[] = [];
    userCredentialId?: string;
    platformPropertiesToUpdate: string[];
    apiEndpoint: string;
    apiEndpointV2: string;

    public constructor(init?: Partial<Platform> | PlatformDto) {
        Object.assign(this, init);
        this.id = init?.id;
        this.regularHours = (this.regularHours || []).map((u: any) => new TimePeriod(u));
        this.specialHours = (this.specialHours || []).map((u: any) => new SpecialTimePeriod(u));
        this.otherHours = (this.otherHours || []).map((u: any) => new OtherPeriod(u));
        this.address = new Address(this.address);
        this.phone = new Phone(this.phone);
        this.descriptions = this.descriptions?.map((u: any) => new Description(u));
        if (this.menu) {
            this.menu = new Menu(this.menu);
        }
        if (this.category) {
            this.category = new Category(this.category);
        }
        this.categoryList = (this.categoryList || []).map((u: any) => new Category(u));
        this.openingDate = new Date(this.openingDate);
        this.createdAt = new Date(this.createdAt);
        this.updatedAt = new Date(this.updatedAt);
    }

    hasReviewRedirectionLink(): boolean {
        return this.key !== PlatformKey.UBEREATS;
    }

    getNfcRedirectionLink(): string | undefined {
        const link = PlatformDefinitions.getPlatformDefinition(this.key)?.externalReviewLink;
        const userLanguage = (
            navigator as {
                userLanguage?: string;
            }
        ).userLanguage;
        const langs = [...(navigator.languages ? navigator.languages : []), ...(userLanguage ? [userLanguage] : [])];

        switch (this.key) {
            case PlatformKey.GMB:
            case PlatformKey.YELP:
            case PlatformKey.PAGESJAUNES:
                return link + (this?.socialId ?? '');
            case PlatformKey.TRIPADVISOR:
                return changePlatformUrlDomain(
                    this.socialLink?.replace(/Restaurant_Review/, 'UserReviewEdit').replace(/Reviews-/, '') ?? '',
                    this.key,
                    langs
                );
            default:
                // handle website case
                return this.socialLink;
        }
    }

    getConnectionMode(): ConnectionMode | null {
        switch (this.key) {
            case PlatformKey.ZENCHEF:
                return ConnectionMode.API_TOKEN;
            case PlatformKey.DELIVEROO:
                return ConnectionMode.CREDENTIAL;
            default:
                return null;
        }
    }
}

export enum PlatformState {
    EMPTY = 'empty',
    CONNECTED = 'connected',
    FOUND = 'found',
    WAITING = 'waiting',
}
