<div class="relative h-full w-full">
    <div class="h-full w-full" [ngClass]="{ 'cursor-pointer': socialLink() }" (click)="openSocialLink()">
        @switch (postType()) {
            @case (PostType.IMAGE) {
                <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: workingMedia() }"></ng-container>
            }
            @case (PostType.VIDEO) {
                <ng-container [ngTemplateOutlet]="videoMedia"></ng-container>
            }
            @case (PostType.REEL) {
                <ng-container [ngTemplateOutlet]="videoMedia"></ng-container>
            }
            @case (PostType.CAROUSEL) {
                <ng-container [ngTemplateOutlet]="carouselMedia"></ng-container>
            }
            @default {
                <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: workingMedia() }"></ng-container>
            }
        }
    </div>
    @if (postType() !== PostType.IMAGE) {
        <mat-icon class="white-icon absolute !right-2 !top-2 !h-4 !w-4" color="white" [ngClass]="customIconClass()" [svgIcon]="iconKey()">
        </mat-icon>
    }

    @if (postStatus(); as postStatus) {
        <div class="status-icon-container absolute left-2 top-2 rounded p-1" [ngStyle]="{ background: postStatus.backgroundColor }">
            <mat-icon class="white-icon !h-4 !w-4" color="white" [style]="customIconStyle()" [svgIcon]="postStatus.icon"></mat-icon>
        </div>
    }
</div>

<ng-template #carouselMedia>
    @switch (firstAttachmentType() | applySelfPure: 'toUpperCase') {
        @case (PostType.IMAGE) {
            <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: workingMedia() }"></ng-container>
        }
        @case (PostType.VIDEO) {
            <ng-container [ngTemplateOutlet]="videoMedia"></ng-container>
        }
        @default {
            <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: workingMedia() }"></ng-container>
        }
    }
</ng-template>

<ng-template #videoMedia>
    @if (workingMedia()) {
        <div class="relative h-full w-full">
            @if (thumbnailUrl(); as thumbnailUrl) {
                @if (shouldLoadVideo()) {
                    <video
                        class="malou-post__picture"
                        preload="none"
                        playsinline
                        muted
                        [poster]="thumbnailUrl"
                        [ngClass]="customMediaClass()"
                        [ngStyle]="customMediaStyle()">
                        <source
                            type="video/mp4"
                            [src]="workingMedia()"
                            (error)="refresh($event)"
                            (loadedmetadata)="onLoadedVideoMetadata($event)" />
                        @if (backupMediaUrl()?.length) {
                            <source type="video/mp4" [src]="backupMediaUrl()" />
                        }
                    </video>
                } @else {
                    <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: thumbnailUrl }"></ng-container>
                }
            } @else {
                <video
                    class="malou-post__picture"
                    preload="metadata"
                    playsinline
                    muted
                    [ngClass]="customMediaClass()"
                    [ngStyle]="customMediaStyle()"
                    (loadedmetadata)="onLoadedVideoMetadata($event)">
                    <source type="video/mp4" [src]="workingMedia() + '#t=0.1'" (error)="refresh($event)" />
                    @if (backupMediaUrl()?.length) {
                        <source type="video/mp4" [src]="backupMediaUrl() + '#t=0.1'" />
                    }
                </video>
            }
            @if (showVideoControls()) {
                <div class="absolute bottom-2 left-2 grid place-items-center rounded-full" matRipple (click)="playMedia($event)">
                    <mat-icon class="!h-[30px] !w-[30px] !text-[30px] text-white"> play_arrow </mat-icon>
                </div>
            }
        </div>
    } @else {
        @if (thumbnailUrl(); as thumbnailUrl) {
            <img
                class="malou-post__picture"
                alt="{{ 'posts.image_alt' | translate }}"
                [ngClass]="customMediaClass()"
                [src]="thumbnailUrl"
                [ngStyle]="customMediaStyle()" />
        } @else {
            <img
                class="malou-post__picture"
                alt="{{ 'posts.image_alt' | translate }}"
                [ngClass]="customMediaClass()"
                [lazyLoad]="'default_post' | imagePathResolver"
                [ngStyle]="customMediaStyle()" />
        }
    }
</ng-template>

<ng-template let-imageUrl="imageUrl" #imageMedia>
    @if (shouldLazyLoadMedia()) {
        <img
            class="malou-post__picture"
            alt="{{ 'posts.image_alt' | translate }}"
            style="margin-top: -1px"
            [defaultImage]="'default_post' | imagePathResolver"
            [lazyLoad]="imageUrl"
            [ngStyle]="customMediaStyle()"
            [ngClass]="customMediaClass()"
            [matTooltip]="tooltipText()"
            [matTooltipClass]="'multiline-tooltip'"
            [matTooltipDisabled]="!tooltipText()"
            (onStateChange)="refreshIfFailed($event)" />
    } @else {
        <img
            class="malou-post__picture"
            alt="{{ 'posts.image_alt' | translate }}"
            style="margin-top: -1px"
            [src]="imageUrl"
            [ngStyle]="customMediaStyle()"
            [ngClass]="customMediaClass()"
            [matTooltip]="tooltipText()"
            [matTooltipClass]="'multiline-tooltip'"
            [matTooltipDisabled]="!tooltipText()" />
    }
</ng-template>
