<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction">
        <ng-container [matColumnDef]="TableField.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef class="!flex-2" mat-sort-header>
                {{ 'roi.aggregated_performance.restaurant_name' | translate }}
            </mat-header-cell>
            <mat-cell
                *matCellDef="let data"
                class="malou-text-10--bold w-28 !flex-2 !pl-0.5"
                [matTooltip]="data[TableField.RESTAURANT_NAME]">
                <img
                    class="mr-1 h-10 w-10 rounded-md"
                    alt="restaurant logo"
                    [src]="data.restaurantLogo || ('default_cover' | imagePathResolver)" />
                @if (data.isMissingData) {
                    <mat-icon
                        class="malou-bg-state-warn malou-color-white malou-border-color-warn relative mr-1 !h-3.5 !w-4 rounded-full border-2"
                        [svgIcon]="SvgIcon.EXCLAMATION_MARK"
                        [matTooltipPosition]="'below'"
                        [matTooltip]="'roi.aggregated_performance.errors.one.no_data' | translate"></mat-icon>
                }
                <span class="w-28 truncate text-ellipsis"> {{ data[TableField.RESTAURANT_NAME] }} </span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.PERFORMANCE_SCORE">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.performance_score' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                <div class="flex gap-x-2">
                    <div>
                        {{ data[TableField.PERFORMANCE_SCORE] | shortNumber }}
                    </div>
                    <div>
                        <app-number-evolution
                            size="xs"
                            [value]="data.evolution"
                            [displayedValue]="data.evolution | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
                    </div>
                </div>
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.performance_score' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.REVIEW_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.review_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.REVIEW_COUNT] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.review_count' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.ANSWERED_REVIEW_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.answered_reviews_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.ANSWERED_REVIEW_COUNT] | shortNumber }}%
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.answered_reviews_count' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.GOOGLE_SCORE">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.google_score' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.GOOGLE_SCORE] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.google_score' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.POSITIVE_REVIEW_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.positive_review_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.POSITIVE_REVIEW_COUNT] | shortNumber }}%
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.positive_review_count' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.AVERAGE_ANSWER_TIME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.average_answer_time' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.AVERAGE_ANSWER_TIME] | shortNumber }}h
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.average_answer_time' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.KEYWORD_SCORE">
            <mat-header-cell *matHeaderCellDef class="!w-[500px]" mat-sort-header>
                {{ 'roi.aggregated_performance.keywords_score' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.KEYWORD_SCORE] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.keywords_score' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.KEYWORDS_IN_TOP_TEN">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.keyword_in_top_ten' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.KEYWORDS_IN_TOP_TEN] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.keyword_in_top_ten' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.GOOGLE_POST_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.google_post_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.GOOGLE_POST_COUNT] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.google_post_count' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.SOCIAL_MEDIA_POST_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.social_media_post_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.SOCIAL_MEDIA_POST_COUNT] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.social_media_post_count' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.SOCIAL_IMPRESSIONS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'roi.aggregated_performance.social_impressions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                {{ data[TableField.SOCIAL_IMPRESSIONS] | shortNumber }}
                @if (screenSizeService.isPhoneScreen) {
                    <span>
                        {{ 'roi.aggregated_performance.social_impressions' | translate }}
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.VIEW_TIPS">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let data">
                <span class="malou-text-13--semibold malou-color-text-primary" (click)="openRestaurantTips(data.restaurant)">{{
                    'roi.tips.short_title' | translate
                }}</span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: displayedColumns" class="!relative"></mat-row>
    </mat-table>
</div>
