import { RoiSettingsDto } from '@malou-io/package-dto';
import { Currency, getCurrencySymbol } from '@malou-io/package-utils';

export interface IRoiSettings {
    id?: string;
    restaurantId: string;
    currency: Currency;
    averageTicket: number;
    minRevenue: number;
    maxRevenue: number;
    duplicatedFromRestaurantId?: string;
    restaurant?: { _id: string };
    createdAt: Date;
    updatedAt: Date;
}

export class RoiSettings implements IRoiSettings {
    id?: string;
    restaurantId: string;
    currency: Currency;
    averageTicket: number;
    minRevenue: number;
    maxRevenue: number;
    createdAt: Date;
    updatedAt: Date;
    duplicatedFromRestaurantId?: string;
    restaurant?: { _id: string };

    constructor(init: RoiSettingsDto) {
        this.id = init.id;
        this.restaurantId = init.restaurantId;
        this.currency = init.currency;
        this.averageTicket = init.averageTicket;
        this.minRevenue = init.minRevenue;
        this.maxRevenue = init.maxRevenue;
        this.duplicatedFromRestaurantId = init.duplicatedFromRestaurantId;
        this.createdAt = init.createdAt;
        this.updatedAt = init.updatedAt;
        this.restaurant = init.restaurant ? { _id: init.restaurant._id } : undefined;
    }

    displayCurrency(): string {
        return getCurrencySymbol(this.currency);
    }
}
