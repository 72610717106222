import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const createNewCalendarEventBodyValidator = z.object({
    startDate: z.coerce.date(),
    name: z.object({
        fr: z.string().nullable(),
        en: z.string().nullable(),
        es: z.string().nullable(),
        it: z.string().nullable(),
    }),
    key: z.string(),
    emoji: z.string(),
    country: z.string(),
    byDefault: z.boolean(),
});

export type CreateNewCalendarEventBodyDto = z.infer<typeof createNewCalendarEventBodyValidator>;

export const createNewCalendarEventParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type CreateNewCalendarEventParamsDto = z.infer<typeof createNewCalendarEventParamsValidator>;
