import { ApplicationLanguage } from '../../constants';
import { TranslationSource } from '../translations';

export interface IBreakdown<Category = string> {
    text: string;
    category: Category;
    translations?: ITranslations;
}

export interface ITranslations {
    id: string;
    fr?: string;
    en?: string;
    es?: string;
    it?: string;
    language: ApplicationLanguage;
    source: TranslationSource;
}

export enum KeywordPopularity {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    PENDING = 'pending',
}
