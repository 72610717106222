import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import Autolinker from 'autolinker';
import { DateTime } from 'luxon';

import { PlatformKey } from '@malou-io/package-utils';

import { User } from ':modules/user/user';

export interface MessageAttachment {
    url: string;
    type: string;
    name: string;
}

interface UserInfo {
    displayName: string;
    userSocialId: string;
}

export interface Reaction {
    reactionType: string;
    userInfo: UserInfo;
}

export class Message {
    _id: string;
    key: PlatformKey;
    socialMessageId: string;
    text: string;
    socialCreatedAt: string;
    isUnsupportedAttachment: boolean;
    isFromRestaurant: boolean;
    userInfo: {
        displayName: string;
        userDeviceLocale: string;
        userSocialId?: string;
        userSocialUrl?: string;
        profilePictureUrl?: string;
    };
    socialConversationId: string;
    conversationId: string;
    malouAuthorId: string;
    malouAuthor: User;
    attachments: MessageAttachment[] = [];
    isDeleted: boolean;
    reactions: Reaction[] = [];
    story: {
        storyType: string;
        storySocialUrl: string;
        storySocialId: string;
    };
    status: string;
    templateIdUsed?: string;

    public constructor(init?: Partial<Message>) {
        Object.assign(this, init);
        this.reactions = init?.reactions ?? [];
        this.attachments = init?.attachments ?? [];
        if (init?.malouAuthor) {
            this.malouAuthor = new User(init.malouAuthor);
        }
    }

    getAvatarDisplayName(restaurantName: string): string {
        return this.isFromRestaurant ? restaurantName : this.userInfo?.displayName;
    }

    getUserDisplayName(restaurantName: string): string {
        if (!this.isFromRestaurant) {
            return this.userInfo?.displayName;
        }
        return this.malouAuthor?.getFullname() ? `${restaurantName} (${this?.malouAuthor?.getFullname()})` : restaurantName;
    }

    getFirstAttachmentType(): string {
        return this.attachments?.[0]?.type === 'image' ? 'photo' : 'video';
    }

    getMessageTextHtml(): SafeHtml {
        return this.text?.match(/__THUMB_UP__/)
            ? '<i class="material-icons malou-color-facebook">thumb_up</i>'
            : Autolinker.link(this.text);
    }

    getMessageSenderAvatar(): string {
        return this.userInfo.profilePictureUrl ?? '';
    }

    getReplyToStoryText(translate: TranslateService): string {
        return this.isFromRestaurant ? translate.instant('messages.story_reply_to') : translate.instant('messages.story_reply_to_external');
    }

    getMentionStoryText(translate: TranslateService): string {
        return this.isFromRestaurant ? translate.instant('messages.story_mention') : translate.instant('messages.story_mention_external');
    }

    getMessageDate(translate: TranslateService): string {
        return DateTime.fromISO(this?.socialCreatedAt).toFormat(translate.instant('messages.date_format'));
    }

    getReactionIcon(): string {
        switch (this.reactions?.[0]?.reactionType) {
            case 'like':
                return '👍';
            case 'love':
            default:
                return '❤️';
        }
    }

    hasReactionsNotFromRestaurant(): boolean {
        return this.getReactionsFromUsers()?.length > 0;
    }

    hasReactionsFromRestaurant(): boolean {
        const reactions = this.getReactionsFromRestaurant();
        return reactions?.length > 0;
    }

    canSendReaction(): boolean {
        return this.key === PlatformKey.INSTAGRAM && this.status !== 'ERROR' && this.status !== 'PENDING' && !this.isDeleted;
    }

    getReactionsFromUsers(): Reaction[] {
        if (this.isFromRestaurant) {
            return this.reactions?.filter((reaction) => reaction && reaction.userInfo.userSocialId !== this.userInfo.userSocialId);
        }
        return this.reactions?.filter((reaction) => reaction && reaction.userInfo.userSocialId === this.userInfo.userSocialId);
    }

    getReactionsFromRestaurant(): Reaction[] {
        if (this.isFromRestaurant) {
            return this.reactions?.filter((reaction) => reaction?.userInfo?.userSocialId === this.userInfo.userSocialId);
        }
        return this.reactions?.filter((reaction) => reaction?.userInfo?.userSocialId !== this.userInfo.userSocialId);
    }

    removeReactionFromRestaurant(): void {
        this.reactions = this.reactions.filter((reaction) =>
            !this.isFromRestaurant
                ? reaction.userInfo.userSocialId === this.userInfo.userSocialId
                : reaction.userInfo.userSocialId !== this.userInfo.userSocialId
        );
    }

    addReaction(reaction: Reaction): void {
        this.reactions = [reaction, ...(this.reactions ?? [])];
    }
}

export interface MessageAttachmentUploading {
    urls: {
        original: string | null; // used to be sent to server
        small: string; // used to display in textarea
    };
    name: string;
    type: string;
    isUploadingOnS3: boolean;
    randomId: string | null; // used to retrieve attachment after uploaded on aws S3 (cf onFileChange)
    hasFinishedToLoadFromBrowser: boolean; // used to not display the spinner animation before small url or blob url is loaded
}

export interface MessageAttachmentMapped {
    url: string;
    type: string;
}
