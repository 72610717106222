import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formatTime',
    standalone: true,
})
export class FormatTimePipe implements PipeTransform {
    constructor(private readonly _translateService: TranslateService) {}

    transform(timeString: string, shouldConvert?: boolean): string {
        if (shouldConvert === false || this._translateService.currentLang !== 'en') {
            return timeString;
        }

        const [hours, minutes] = timeString.split(':');
        let period = 'am';
        let formattedHours = parseInt(hours, 10);

        if (formattedHours >= 12) {
            period = 'pm';
            formattedHours = formattedHours === 12 ? formattedHours : formattedHours - 12;
        }

        return `${formattedHours}:${minutes} ${period}`;
    }
}
