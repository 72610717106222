import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-shared-folder-modal',
    templateUrl: './shared-folder-modal.component.html',
    standalone: true,
    imports: [NgClass, FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, TranslateModule, InputTextComponent],
})
export class SharedFolderModalComponent {
    readonly SvgIcon = SvgIcon;
    folderName = this._translateService.instant('gallery.my_gallery');

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _dialogRef: MatDialogRef<SharedFolderModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { folder: IFolder | null },
        public readonly screenSizeService: ScreenSizeService
    ) {
        if (data.folder?.name) {
            this.folderName = data.folder.name;
        }
    }

    close(folder: IFolder | null = null): void {
        this._dialogRef.close({ folder });
    }
}
