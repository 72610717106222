import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { NotificationData } from '../notifications.interface';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@Component({
    selector: 'app-notifications-modal',
    templateUrl: './notifications-modal.component.html',
    styleUrls: ['./notifications-modal.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, TranslateModule, NotificationItemComponent],
})
export class NotificationsModalComponent {
    private readonly _dialogRef = inject(DialogRef);

    readonly SvgIcon = SvgIcon;
    readonly data = inject<{
        notifications: WritableSignal<NotificationData[]>;
    }>(MAT_DIALOG_DATA);

    close(): void {
        this._dialogRef.close();
    }
}
