import { z } from 'zod';

import {
    BaseEmailProps,
    BaseReportEmailProps,
    boosterStatsPerformanceReportValidator,
    gmbPerformanceReportValidator,
    keywordsPerformanceReportValidator,
    reviewsStatsPerformanceReportValidator,
    socialMediaPerformanceReportValidator,
} from '../common';

export const monthlyPerformanceReportValidator = z.object({
    gmb: gmbPerformanceReportValidator.optional(),
    keywords: keywordsPerformanceReportValidator.optional(),
    reviews: reviewsStatsPerformanceReportValidator.optional(),
    booster: boosterStatsPerformanceReportValidator.optional(),
    socialMedia: socialMediaPerformanceReportValidator.optional(),
});

export type MonthlyPerformanceReport = z.infer<typeof monthlyPerformanceReportValidator>;

export type MonthlyPerformanceReportProps = MonthlyPerformanceReport & BaseEmailProps & BaseReportEmailProps;
