import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnInit,
    output,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { asapScheduler, Subject } from 'rxjs';

import { ApplicationLanguage, HeapEventName, isValidKeywordText, mapLanguageStringToApplicationLanguage } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { HeapService } from ':core/services/heap.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { DISPLAYED_COLUMNS, MAX_KEYWORDS_SELECTED } from ':modules/keywords/keywords-validation-modal/keywords-validation-modal.component';
import { UpdateKeywordLangComponent } from ':modules/keywords/keywords-validation-modal/update-keyword-lang/update-keyword-lang.component';
import { SelectLanguageComponent } from ':shared/components-v3/select-language/select-language.component';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Keyword } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Component({
    selector: 'app-unselected-keywords-validation-modal',
    templateUrl: './unselected-keywords-validation-modal.component.html',
    styleUrls: ['./unselected-keywords-validation-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        LazyLoadImageModule,
        KeywordsPopularityComponent,
        MalouSpinnerComponent,
        NoopMatCheckboxComponent,
        SearchComponent,
        SelectLanguageComponent,
        SkeletonComponent,
        UpdateKeywordLangComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        CreateArrayPipe,
        FlagPathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnselectedKeywordsValidationModalComponent implements OnInit {
    readonly dataSource: ModelSignal<MatTableDataSource<Keyword>> = model.required();
    readonly selectedKeywordsDataSource: ModelSignal<MatTableDataSource<Keyword>> = model.required();
    readonly selection: InputSignal<SelectionModel<Keyword>> = input.required();
    readonly allKeywords: InputSignal<Keyword[]> = input.required();
    readonly unselectedKeywordLangs: InputSignal<ApplicationLanguage[]> = input.required();
    readonly loading: InputSignal<boolean> = input.required();
    readonly selectedLangs: InputSignal<ApplicationLanguage[]> = input.required();
    readonly updatingKeywordLangs: InputSignal<string[]> = input.required();
    readonly isSaving: InputSignal<boolean> = input.required();

    readonly updateKeywordLanguage = output<{ keyword: Keyword; lang: ApplicationLanguage }>();
    readonly selectKeywords = output<Keyword[]>();
    readonly selectedLangsChange = output<ApplicationLanguage[]>();
    readonly close = output<void>();
    readonly save = output<void>();

    private readonly _keywordsService = inject(KeywordsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _heapService = inject(HeapService);

    readonly SvgIcon = SvgIcon;
    readonly matSort = viewChild(MatSort);

    readonly selectionCount$: Subject<number> = new Subject<number>();
    readonly selectionCount: Signal<number> = toSignal(this.selectionCount$, { initialValue: 0 });
    readonly isAllSelected = computed(() => this.dataSource().data.length === this.selectionCount());
    readonly lastSelectedSegmentRow = signal(0);

    readonly DISPLAYED_COLUMNS = DISPLAYED_COLUMNS;

    readonly currentLang: WritableSignal<ApplicationLanguage> = signal(LocalStorage.getLang());

    readonly createKeywordErrorMessage = signal<string | undefined>(undefined);

    readonly showMoreOptions = signal(false);

    readonly textFilter = signal<string | null>(null);

    readonly canCreateNewKeyword = computed(() => (unselectedKeywords: Keyword[], selectedKeywords: Keyword[]): boolean => {
        const data = [...(unselectedKeywords ?? []), ...(selectedKeywords ?? [])];

        const textFilter = this.textFilter();

        if (!textFilter) {
            asapScheduler.schedule(() => {
                this.createKeywordErrorMessage.set(undefined);
            });
            return false;
        }
        const isValidKeyword = isValidKeywordText(textFilter);
        const isKeywordInTheList = data?.map((keyword) => keyword.text.trim().toLowerCase()).includes(textFilter);

        if (!isValidKeyword) {
            asapScheduler.schedule(() => {
                this.createKeywordErrorMessage.set(this._translateService.instant('validation.modal.invalid_keyword_text'));
            });
        } else {
            asapScheduler.schedule(() => {
                this.createKeywordErrorMessage.set(undefined);
            });
        }

        return isValidKeyword && !isKeywordInTheList;
    });

    readonly isKeywordLangUpdating = computed(
        () =>
            (keyword: Keyword): boolean =>
                this.updatingKeywordLangs().includes(keyword.restaurantKeywordId)
    );

    constructor() {
        effect(() => {
            const sort = this.matSort();

            const sortingDataAccessor = (item: Keyword, property: string): string | number => {
                switch (property) {
                    case 'text':
                        return item.text
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                    default:
                        return item[property];
                }
            };
            this.dataSource.update((currentDataSource) => {
                currentDataSource.sort = sort || null;
                currentDataSource.sortingDataAccessor = sortingDataAccessor;
                return currentDataSource;
            });
        });
    }

    ngOnInit(): void {
        this.selection()
            .getCount$()
            .subscribe((count) => this.selectionCount$.next(count));

        this._translateService.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            this.currentLang.set(mapLanguageStringToApplicationLanguage(res.lang));
        });
    }

    getPrettyLang = (lang: ApplicationLanguage | string): string => this._translateService.instant(`header.langs.${lang}`);

    chooseKeyword(keyword: Keyword): void {
        this.selectKeywords.emit([keyword]);
    }

    chooseSelectedKeywords(): void {
        this.selectKeywords.emit(this.selection().getSelection());
    }

    updateKeywordLang = ({ keyword, lang }: { keyword: Keyword; lang: ApplicationLanguage }): void => {
        this.updateKeywordLanguage.emit({ keyword, lang });
    };

    maxSelectedKeywordsReached = (selectedKeywordsCount: number): boolean => selectedKeywordsCount >= MAX_KEYWORDS_SELECTED;

    createNewKeyword(keywordText: string): void {
        const cleanedKeywordText = keywordText.replace(/\t/g, '');
        const newKeyword = new Keyword({
            text: cleanedKeywordText,
            isCustomerInput: true,
            language: this.currentLang(),
            isLoadingVolume: true,
        });
        this.dataSource.update((currentDataSource) => {
            currentDataSource.data.unshift(newKeyword);
            return currentDataSource;
        });
        this.dataSource()._updateChangeSubscription();
        this.applyFilter(null);

        if (!this._restaurantsService.currentRestaurant?.address) {
            throw new Error('No address');
        }

        const createKeyword$ = this._keywordsService.createKeyword(this._restaurantsService.currentRestaurant._id, {
            text: cleanedKeywordText,
            language: this.currentLang(),
        });

        createKeyword$.subscribe({
            next: (createdKeyword) => {
                this._heapService.track(HeapEventName.MANUALLY_CREATE_KEYWORD);
                const index = this.dataSource().data.findIndex((keyword) => keyword.text === cleanedKeywordText);
                if (index !== -1) {
                    this.dataSource.update((currentDataSource) => {
                        currentDataSource.data[index] = createdKeyword;
                        return currentDataSource;
                    });
                    this.dataSource()._updateChangeSubscription();

                    const selectionWithCleanKeywordText = this.selection()
                        .getSelection()
                        .filter((e) => e.text !== cleanedKeywordText);
                    this.selection().unselectAll();
                    this.selection().select([...selectionWithCleanKeywordText, this.dataSource().data[index]]);
                } else {
                    const indexRight = this.selectedKeywordsDataSource().data.findIndex((keyword) => keyword.text === cleanedKeywordText);
                    if (indexRight !== -1) {
                        this.selectedKeywordsDataSource.update((currentDataSource) => {
                            currentDataSource.data[indexRight] = createdKeyword;
                            return currentDataSource;
                        });
                        this.selectedKeywordsDataSource()._updateChangeSubscription();
                    }
                }
            },
            error: (err) => {
                console.warn(err);
                if (err.status === 409) {
                    this._toastService.openErrorToast(this._translateService.instant('keywords.validation.keyword_already_exists'));
                    return;
                }
                if (err.status !== 403) {
                    this._toastService.openErrorToast(
                        this._translateService.instant('keywords.validation.unknown_error') + this._httpErrorPipe.transform(err)
                    );
                }
            },
        });
    }

    applyLangFilter(selectedLangs: ApplicationLanguage[]): void {
        this.selectedLangsChange.emit(selectedLangs);
        this.dataSource.update((currentDataSource) => {
            currentDataSource.filterPredicate = (data: Keyword, filter: string): boolean => filter.includes(data.language);
            currentDataSource.filter = selectedLangs.join(' ');
            return currentDataSource;
        });
        this.dataSource()._updateChangeSubscription();
        this.lastSelectedSegmentRow.set(0);
    }

    selectMultiple(event: MouseEvent, lastRow: number): void {
        if (event.shiftKey) {
            const rowsToSelect = this.dataSource().filteredData.filter((_val, i) => i >= this.lastSelectedSegmentRow() && i < lastRow);

            this.selection().select(rowsToSelect);
        }
        this.lastSelectedSegmentRow.set(lastRow);
    }

    toggleAllFiltered(): void {
        if (this._isAllFilteredSelected()) {
            this.selection().unselect(this.dataSource().filteredData);
        } else {
            this.selection().select(this.dataSource().filteredData);
            this.lastSelectedSegmentRow.set(0);
        }
    }

    toggleSelected(keyword: Keyword): void {
        this.selection().toggle(keyword);
    }

    toggleShowMoreOptions(): void {
        this.showMoreOptions.update((currentShowMoreOptions) => !currentShowMoreOptions);
    }

    applyFilter(filterValue: string | null): void {
        this.dataSource.update((currentDataSource) => {
            currentDataSource.filterPredicate = (data: Keyword, filter: string): boolean => data.text.toLowerCase().includes(filter);
            currentDataSource.filter = (filterValue || '').trim().toLowerCase();
            return currentDataSource;
        });
        this.dataSource()._updateChangeSubscription();
        this.textFilter.set(filterValue);
    }

    onClose(): void {
        this.close.emit();
    }

    onSave(): void {
        this.save.emit();
    }

    private _isAllFilteredSelected(): boolean {
        return this.dataSource().filteredData.length === this.selectionCount();
    }
}
