import { z } from 'zod';

import {
    GrowthVariation,
    Locale,
    MaloupeLocale,
    PlatformKey,
    PostType,
    ReviewAnalysisSentiment,
    ReviewAnalysisTag,
} from '@malou-io/package-utils';

//########################################################################
//#######################      SHARED TYPES      #########################
//########################################################################

export const ctaValidator = z.object({
    noticeText: z.string().optional(),
    subNoticeText: z.string().optional(),
    link: z.string(),
});

export type CtaProps = z.infer<typeof ctaValidator>;

/** */

export const ctaSectionValidator = z.object({
    title: z.string().optional(),
    subtitle: z.string().optional(),
    link: z.string(),
});

export type CtaSectionProps = z.infer<typeof ctaSectionValidator>;

/** */

export const concernedRestaurantPropsValidator = z.object({
    name: z.string(),
    address: z.string().optional(),
    image: z.string().optional(),
});

export type ConcernedRestaurantProps = z.infer<typeof concernedRestaurantPropsValidator>;

export const growthStatsPropsValidator = z.object({
    rate: z.number(),
    variation: z.nativeEnum(GrowthVariation),
    flipped: z.boolean().optional(),
    isSemantic: z.boolean().optional(),
    isPercentage: z.boolean().optional(),
});

export type GrowthStatsProps = z.infer<typeof growthStatsPropsValidator>;

/** */

export const segmentAnalysisPropsValidator = z.object({
    segment: z.string(),
    sentiment: z.nativeEnum(ReviewAnalysisSentiment),
    tag: z.nativeEnum(ReviewAnalysisTag),
});

export type SegmentAnalysisProps = z.infer<typeof segmentAnalysisPropsValidator>;

/** */

export const reviewPropsValidator = z.object({
    reviewerProfilePhoto: z.string(),
    isIcon: z.boolean().optional(),
    platformImage: z.string().optional(),
    reviewerName: z.string(),
    rating: z.number(),
    text: z.string().optional(),
    segmentAnalysis: z.array(segmentAnalysisPropsValidator).optional(),
    translatedFrom: z.string().optional(),
});

export type ReviewProps = z.infer<typeof reviewPropsValidator>;

/** */

export const categoryAnalysisPropsValidator = z.object({
    title: z.string(),
    totalSentimentsCount: z.number(),
    positiveSentiments: z.number(),
    negativeSentiments: z.number(),
});

export type CategoryAnalysisProps = z.infer<typeof categoryAnalysisPropsValidator>;

/** */

export const baseEmailValidator = z.object({
    locale: z.nativeEnum(Locale),
    receiver: z.string().optional(),
    unsubscribeLink: z.string().optional(),
    trackingUrl: z.string().optional(),
});

export type BaseEmailProps = z.infer<typeof baseEmailValidator>;

/** */

export const baseMaloupeEmailValidator = z.object({
    locale: z.nativeEnum(MaloupeLocale),
    receiver: z.string().optional(),
    unsubscribeLink: z.string().optional(),
    trackingUrl: z.string().optional(),
});

export type BaseMaloupeEmailProps = z.infer<typeof baseMaloupeEmailValidator>;

//########################################################################
//#######################     REPORTS     #########################
//########################################################################

export const baseReportEmailValidator = z.object({
    concernedRestaurants: z.array(concernedRestaurantPropsValidator),
    period: z.object({
        startDate: z.date(),
        endDate: z.date(),
    }),
});

export type BaseReportEmailProps = z.infer<typeof baseReportEmailValidator> & BaseEmailProps;

//########################################################################
//#######################     REVIEWS REPORT     #########################
//########################################################################

export const ratingPropsValidator = z.object({
    totalRated: z.number(),
    totalLowRated: z.number().optional(),
    growth: growthStatsPropsValidator,
});

export type RatingProps = z.infer<typeof ratingPropsValidator>;

/** */

export const notAnsweredReviewsPropsValidator = z.object({
    value: z.number(),
    growth: growthStatsPropsValidator,
});

export type NotAnsweredReviewsProps = z.infer<typeof notAnsweredReviewsPropsValidator>;

/** */

export const averageRatingPropsValidator = z.object({
    value: z.number(),
    growth: growthStatsPropsValidator,
});

export type AverageRatingProps = z.infer<typeof averageRatingPropsValidator>;

/** */

export const reviewsStatsPropsValidator = z.object({
    rating: ratingPropsValidator.optional(),
    averageRating: averageRatingPropsValidator.optional(),
    notAnswered: notAnsweredReviewsPropsValidator.optional(),
});

export type ReviewsStatsProps = z.infer<typeof reviewsStatsPropsValidator>;

/** */

export const restaurantPlatformDataValidator = z.object({
    rating: ratingPropsValidator,
    averageRating: averageRatingPropsValidator,
    metaData: z.object({ key: z.nativeEnum(PlatformKey), fullName: z.string() }),
    topReviews: z.array(reviewPropsValidator).optional(),
    flopReviews: z.array(reviewPropsValidator).optional(),
});

export type PlatformDataProps = z.infer<typeof restaurantPlatformDataValidator>;

/** */

export const semanticAnalysisPropsValidator = z.object({
    analysis: ratingPropsValidator.optional(),
    categoriesAnalysis: z.array(categoryAnalysisPropsValidator).optional(),
    cta: ctaValidator,
});

export type SemanticAnalysisProps = z.infer<typeof semanticAnalysisPropsValidator>;

/** */

export const restaurantHeaderPropsValidator = z.object({
    name: z.string(),
    address: z.string(),
    image: z.string(),
    rating: ratingPropsValidator,
    alt: z.string().optional(),
    reviewsCount: z
        .object({
            totalReviewsCount: z.number(),
            nonAnsweredReviewsCount: z.number(),
        })
        .optional(),
});

export type RestaurantHeaderProps = z.infer<typeof restaurantHeaderPropsValidator>;

/** */

export const restaurantPropsValidator = z.object({
    header: restaurantHeaderPropsValidator.optional(),
    stats: reviewsStatsPropsValidator,
    reviews: z.array(reviewPropsValidator),
    cta: ctaValidator,
});

export type RestaurantProps = z.infer<typeof restaurantPropsValidator>;

//########################################################################
//#######################   PERFORMANCE REPORT   #########################
//########################################################################

/** */

export const keywordPerformanceReportDataValidator = z.object({
    title: z.string(),
    subtitle: z.string().optional(),
    growth: growthStatsPropsValidator,
    rank: z.number(),
});

export type KeywordPerformanceReportDataProps = z.infer<typeof keywordPerformanceReportDataValidator>;

/** */

export const keywordsPerformanceReportValidator = z.object({
    keywordsData: z.array(keywordPerformanceReportDataValidator),
    link: z.string().optional(),
});

export type KeywordsPerformanceReportProps = z.infer<typeof keywordsPerformanceReportValidator>;

/** */

export const restaurantPerformanceValidator = z.object({
    metaData: concernedRestaurantPropsValidator,
    value: z.number().optional(),
    stats: z
        .object({
            averageNote: z.number(),
            totalReviewsCount: z.number(),
        })
        .optional(),
});

export type RestaurantPerformanceProps = z.infer<typeof restaurantPerformanceValidator>;

/** */

export const gmbPerformanceReportValidator = z.object({
    bestPerformingRestaurant: restaurantPerformanceValidator.optional(),
    worstPerformingRestaurant: restaurantPerformanceValidator.optional(),
    totalImpressions: ratingPropsValidator,
    totalActions: ratingPropsValidator,
    ratioActionsOverImpressions: ratingPropsValidator,
    postsMonthlyRecommendation: ctaValidator.optional(),
    postsWeeklyRecommendation: ctaSectionValidator.optional(),
    variation: z.nativeEnum(GrowthVariation),
    publishedPosts: z
        .object({
            count: z.number(),
            growth: growthStatsPropsValidator,
        })
        .optional(),
});

export type GmbPerformanceReportValidatorProps = z.infer<typeof gmbPerformanceReportValidator>;

/** */

export const reviewsStatsPerformanceReportValidator = z.object({
    totalReviewsCount: z.number().optional(),
    nonAnsweredReviewsCount: z.number().optional(),
    semanticAnalysis: ratingPropsValidator.optional(),
    topReviewsRestaurants: z.array(restaurantPerformanceValidator).optional(),
    flopReviewsRestaurants: z.array(restaurantPerformanceValidator).optional(),
    reviewsCta: ctaValidator,
    cta: ctaValidator.optional(),
    variation: z.nativeEnum(GrowthVariation).optional(),
});

export type ReviewsStatsPerformanceReportProps = z.infer<typeof reviewsStatsPerformanceReportValidator>;

/** */

export const nfcStatsValidator = z.object({
    currentTotalReviews: z.number(),
    lastTotalReviews: z.number(),
});

export type NfcStatsProps = z.infer<typeof nfcStatsValidator>;

/** */

export const boosterStatsPerformanceReportValidator = z.object({
    nfc: nfcStatsValidator,
    bestRestaurantReviews: restaurantPerformanceValidator.optional(),
    worstRestaurantReviews: restaurantPerformanceValidator.optional(),
    totalGainedReviewsCount: z.number(),
    cta: ctaValidator,
    // TODO: Add WoF
    // wof: {};
});

export type BoosterStatsPerformanceReportProps = z.infer<typeof boosterStatsPerformanceReportValidator>;

/** */
export const socialMediaPerformanceReportBestPostValidator = z.object({
    metadata: z.object({
        restaurant: z
            .object({
                name: z.string(),
                address: z.string(),
            })
            .optional(),
        image: z.string(),
        type: z.nativeEnum(PostType),
    }),
    engagement: z.number(),
    impressions: z.number(),
});
export type SocialMediaPerformanceReportBestPostProps = z.infer<typeof socialMediaPerformanceReportBestPostValidator>;

export const socialMediaPerformanceReportValidator = z.object({
    instagram: z.object({
        subscriptions: ratingPropsValidator.optional(),
        impressions: ratingPropsValidator.optional(),
        engagement: ratingPropsValidator.optional(),
        nbOfPosts: z.number(),
        bestPost: socialMediaPerformanceReportBestPostValidator.optional(),
        postsMonthlyRecommendation: ctaValidator.optional(),
        postsWeeklyRecommendation: ctaSectionValidator.optional(),
        variation: z.nativeEnum(GrowthVariation).optional(),
    }),
    // TODO: Add other social media platfom
});

export type SocialMediaPerformanceReportProps = z.infer<typeof socialMediaPerformanceReportValidator>;

/** */

export const calendarEventPerformanceReportValidator = z.object({
    metadata: z.object({
        date: z.date(),
        name: z.string(),
    }),
    isHoliday: z.boolean(),
    concernedRestaurants: z.array(z.string()).optional(),
    icon: z.string().optional(),
});

export type CalendarEventPerformanceReportProps = z.infer<typeof calendarEventPerformanceReportValidator>;

/** */

export const incomingEventsPerformanceReportValidator = z.object({
    eventsCount: z.number(),
    events: z.array(calendarEventPerformanceReportValidator),
    bankHolidayEventDates: z.array(z.string()),
    cta: ctaValidator,
    moreEventsCta: ctaValidator.optional(),
});

export type IncomingEventsPerformanceReportProps = z.infer<typeof incomingEventsPerformanceReportValidator>;

/** */

export const notAnsweredMessagesPerformanceReportValidator = z.object({
    metadata: z.object({
        count: z.number(),
        stats: notAnsweredReviewsPropsValidator,
    }),
    cta: ctaValidator.optional(),
});

export type NotAnsweredMessagesPerformanceReportProps = z.infer<typeof notAnsweredMessagesPerformanceReportValidator>;
