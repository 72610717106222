@if (!isSmallScreen) {
    <div>
        <!-- card format for weekly view -->
        @if (postFormat === PostDisplayFormat.CARD) {
            <div>
                @if (post.postStatus) {
                    <div
                        class="calendar-post mx-3.5 !mb-4 rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light p-1.5"
                        [ngClass]="{ '!border-malou-color-state-error': post.postStatus.type === ExtendedPostPublicationStatus.ERROR }">
                        <ng-container
                            [ngTemplateOutlet]="postCardTemplate"
                            [ngTemplateOutletContext]="{ status: post.postStatus }"></ng-container>
                    </div>
                }

                <ng-template let-status="status" #postCardTemplate>
                    <div class="malou-card__header !pb-0">
                        <div class="flex w-full items-center justify-between">
                            <div class="flex">
                                @if (getPostKeys().length > 0) {
                                    <div class="relative w-[35px]">
                                        @for (key of getPostKeys(); track key; let i = $index) {
                                            <div class="absolute !h-4 !w-4" [ngStyle]="{ left: i * 10 + 'px' }">
                                                <app-platform-logo
                                                    [withBorder]="post.isStory"
                                                    [borderWidthClass]="key === PlatformKey.FACEBOOK ? 'border-2' : 'border'"
                                                    [logo]="getPostLogoSrc | applyPure: key"></app-platform-logo>
                                            </div>
                                        }
                                    </div>
                                }

                                <span
                                    class="malou-text-10--semibold flex justify-between self-center"
                                    [ngClass]="getPostKeys().length > 2 ? 'ml-3' : 'ml-0'">
                                    {{ status.subtextHours }}
                                </span>

                                <mat-icon
                                    class="ml-1.5 !h-4 !w-4"
                                    [ngStyle]="{ color: status.iconColor }"
                                    [matTooltip]="getPostTooltip | applyPure: post"
                                    [svgIcon]="status.icon"></mat-icon>
                            </div>

                            <button
                                class="malou-btn-icon !h-10 !w-4 !p-0"
                                data-cy="postMenuBtn"
                                mat-icon-button
                                [matMenuTriggerFor]="postActionsMenu">
                                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="malou-card__body flex grow-2 flex-col items-center">
                        @if (showFeedbackBadge) {
                            <div class="malou-text-9--semibold malou-color-state-warn cursor-pointer !pb-2" (click)="openPost()">
                                {{ 'dashboard.calendar_post.feedback_not_completed' | translate }}
                            </div>
                        }
                        <div class="relative flex w-full">
                            <div class="h-[80px] w-full">
                                <ng-container
                                    [ngTemplateOutlet]="postImg"
                                    [ngTemplateOutletContext]="{ class: 'max-h-[80px] !rounded' }"></ng-container>
                            </div>
                        </div>
                        <div class="mt-1.5 flex w-full break-all p-0.5">
                            <span class="malou-color-text-1 malou-text-10--regular" [matTooltip]="post.text">{{
                                post.text | shortText: 40
                            }}</span>
                        </div>
                    </div>
                </ng-template>
            </div>
        } @else {
            <ng-container [ngTemplateOutlet]="rowPost"></ng-container>
        }

        <!-- row format for monthly view -->
        <ng-template let-status="status" #rowPost>
            @if (post.postStatus) {
                <div class="p-0.5">
                    <ng-container
                        [ngTemplateOutlet]="rowPostTemplate"
                        [ngTemplateOutletContext]="{ status: post.postStatus }"></ng-container>
                </div>
            }
            <ng-template let-status="status" #rowPostTemplate>
                <div class="flex items-center justify-between p-2">
                    <div class="flex items-center">
                        <ng-container
                            [ngTemplateOutlet]="postImg"
                            [ngTemplateOutletContext]="{ class: '!h-12.5 !w-[50px] !rounded' }"></ng-container>
                        <div class="flex flex-col">
                            <div class="flex">
                                <div class="relative w-[35px]">
                                    @for (key of getPostKeys(); track key; let i = $index) {
                                        @let logoLeftPositionInPx = i * 10 * 7;
                                        <app-platform-logo
                                            [imgClasses]="'absolute !h-4 !w-4 left-[' + logoLeftPositionInPx + 'px]'"
                                            [logo]="key"></app-platform-logo>
                                    }
                                </div>

                                <span class="malou-text-10--semibold flex justify-between self-center"> {{ status.subtextHours }} </span>

                                <mat-icon
                                    class="ml-1.5 !h-4 !w-4"
                                    [ngStyle]="{ color: status.iconColor }"
                                    [matTooltipDisabled]="status.type !== ExtendedPostPublicationStatus.ERROR"
                                    [matTooltip]="getPostTooltip | applyPure: post"
                                    [svgIcon]="status.icon"></mat-icon>
                            </div>
                            @if (showFeedbackBadge) {
                                <div
                                    class="malou-text-9--semibold malou-color-state-warn ml-2.5 mt-1.5 cursor-pointer"
                                    (click)="openPost()">
                                    {{ 'dashboard.calendar_post.feedback_not_completed' | translate }}
                                </div>
                            }
                        </div>
                    </div>
                    <div class="flex">
                        @if (isPostEditable) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-1.5"
                                matTooltip="{{ 'posts.edit' | translate }}"
                                (click)="openPost()">
                                <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                            </button>
                        }
                        @if (isPostDuplicable) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-1.5"
                                matTooltip="{{ 'posts.duplicate' | translate }}"
                                [matMenuTriggerFor]="duplicateMenu">
                                <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                            </button>
                        }
                        @if (isPostDeletable) {
                            <button
                                class="malou-btn-icon--secondary btn-xl"
                                matTooltip="{{ 'posts.delete' | translate }}"
                                (click)="deletePost()">
                                <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                            </button>
                        }
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
} @else {
    <ng-container [ngTemplateOutlet]="mobileView"></ng-container>
}

<!-- row format for mobile/daily view -->
<ng-template #mobileView>
    @if (post.postStatus) {
        <div
            class="mt-3.5 rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-light p-0.5"
            [ngClass]="{ '!border-malou-color-state-error': post.postStatus.type === ExtendedPostPublicationStatus.ERROR }">
            <ng-container
                [ngTemplateOutlet]="smallScreenPostTemplate"
                [ngTemplateOutletContext]="{ status: post.postStatus }"></ng-container>
        </div>
    }
    <ng-template let-status="status" #smallScreenPostTemplate>
        <div class="flex items-center justify-between p-2">
            <div class="flex">
                <div>
                    <ng-container
                        [ngTemplateOutlet]="postImg"
                        [ngTemplateOutletContext]="{ class: '!h-12.5 !w-[50px] !rounded' }"></ng-container>
                </div>
                <div class="ml-4 flex flex-col justify-around">
                    <div class="flex items-center">
                        @if (getPostKeys().length > 0) {
                            <div class="relative w-[35px]">
                                @for (key of getPostKeys(); track key; let i = $index) {
                                    @let logoLeftPositionInPx = i * 10 * 7;
                                    <app-platform-logo
                                        [imgClasses]="'absolute top-[-8px] !h-4 !w-4 left-[' + logoLeftPositionInPx + 'px]'"
                                        [logo]="key"></app-platform-logo>
                                }
                            </div>
                        }

                        <span
                            class="malou-text-10--semibold ml-2 flex justify-between self-center"
                            [ngClass]="getPostKeys().length > 2 ? 'ml-3' : 'ml-0'">
                            {{ status.subtextHours }}
                        </span>
                        <mat-icon
                            class="ml-2 !h-4 !w-4"
                            [ngStyle]="{ color: status.iconColor }"
                            [matTooltipDisabled]="status.type !== ExtendedPostPublicationStatus.ERROR"
                            [matTooltip]="getPostTooltip | applyPure: post"
                            [svgIcon]="status.icon"
                            (click)="tooltip.show()"
                            #tooltip="matTooltip"></mat-icon>

                        @if (showFeedbackBadge) {
                            <div class="malou-text-9--semibold malou-color-state-warn ml-2.5 cursor-pointer" (click)="openPost()">
                                {{ 'dashboard.calendar_post.feedback_not_completed' | translate }}
                            </div>
                        }
                    </div>
                    <div class="ml-1.5 flex w-full break-all p-0.5">
                        <span class="malou-color-text-1 malou-text-10--regular">{{ post.text | shortText: 45 }}</span>
                    </div>
                </div>
            </div>
            <div class="flex items-center">
                <button class="malou-btn-icon !h-10 !w-4 !p-0" data-cy="postMenuBtn" mat-icon-button [matMenuTriggerFor]="postActionsMenu">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                </button>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template let-class="class" #postImg>
    @if (isSeoPost | applyPure: post) {
        <div>
            @if (isGmbConnected$ | async) {
                <div class="flex w-full">
                    <img
                        class="w-full rounded-lg object-cover"
                        [ngClass]="class"
                        [defaultImage]="'default-picture-grey' | imagePathResolver"
                        [lazyLoad]="post.getMalouMediaUrl() || post.getSocialMediaUrl()"
                        (onStateChange)="refreshPostIfFailed($event)" />
                </div>
            } @else {
                <div class="flex w-full">
                    <img
                        class="w-full rounded-lg object-cover"
                        [ngClass]="class"
                        [defaultImage]="'default-picture-grey' | imagePathResolver"
                        [lazyLoad]="post.getMalouMediaUrl() || post.getSocialMediaUrl()"
                        (onStateChange)="refreshPostIfFailed($event)" />
                </div>
            }
        </div>
    } @else {
        <div [ngClass]="class">
            <app-social-post-media
                customIconClass="!left-2"
                [customMediaClass]="class"
                [postType]="post.postType"
                [workingMedia]="post.getMalouMediaUrl() || post.getSocialMediaUrl()"
                [firstAttachmentType]="post.getFirstMediumType()"
                [thumbnailUrl]="post | applySelfPure: 'getThumbnailUrl' | async"
                (refreshMedia)="refreshPost()"></app-social-post-media>
        </div>
    }
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #postActionsMenu="matMenu">
    @if (isPostEditable) {
        <button class="flex gap-x-3" mat-menu-item (click)="openPost()">
            <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
            <span class="malou-text-12--regular">{{ 'posts.edit' | translate }}</span>
        </button>
    }
    @if (isPostDuplicable) {
        <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateMenu">
            <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
            <span class="malou-text-12--regular">{{ 'posts.duplicate' | translate }}</span>
        </button>
    }
    @if (isPostDeletable) {
        <button class="flex gap-x-3" mat-menu-item (click)="deletePost()">
            <mat-icon class="!h-4 w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
            <span class="malou-text-12--regular">{{ 'posts.delete' | translate }}</span>
        </button>
    }
    @if (post.socialLink) {
        <a class="flex gap-x-3" mat-menu-item target="_blank" [href]="post.socialLink">
            <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.LAPTOP"></mat-icon>
            <span class="malou-text-12--regular">{{ 'posts.open_platform' | translate }}</span>
        </a>
    }
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
    @if (!restaurant.isBrandBusiness() && !([PostType.VIDEO, PostType.REEL] | includes: post.postType)) {
        <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, platformsKeysWithSeoPost)">
            <span class="malou-text-12--regular">
                <span>{{ 'posts.duplicate_in_seo' | translate }}</span>
            </span>
        </button>
    }
    <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, platformsKeysWithSocialPost)">
        <span class="malou-text-12--regular">
            {{ 'posts.duplicate_in_social' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="duplicatePost(DuplicationDestination.OUT, getPostKeys())">
        <span class="malou-text-12--regular">
            {{ 'common.to_other_venues' | translate }}
        </span>
    </button>
</mat-menu>
