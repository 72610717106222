import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, take } from 'rxjs';

import { ReviewResponseDto } from '@malou-io/package-dto';

import { STARS_RATING } from ':core/constants';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NfcNameToCsvNameMapper } from ':shared/helpers';
import { Nfc } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';

import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from '../csv-service.abstract';

interface Data {
    nfcs: Nfc[] | undefined;
    scans: ScanForStats[] | undefined;
    privateReviews: ReviewResponseDto[] | undefined;
}

@Injectable({ providedIn: 'root' })
export class BoostersReviewsCountCsvInsightService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _nfcNameToCsvNameMapper: NfcNameToCsvNameMapper
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin({
            boostersData: this._store.select(StatisticsSelectors.selectBoosterStatsData).pipe(take(1)),
            privateReviews: this._store.select(StatisticsSelectors.selectPrivateReviewsData).pipe(take(1)),
        }).pipe(map(({ boostersData, privateReviews }) => ({ nfcs: boostersData?.nfcs, scans: boostersData?.scans, privateReviews })));
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Booster', 'Rating', 'Private Review Count', 'Collected Reviews Estimate'];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { nfcs, scans, privateReviews } = data;
        const groupedData = nfcs.reduce((acc: string[][], nfc) => {
            const nfcScans = scans.filter((scan) => nfc.id === scan.nfcId);
            const nfcPrivateReviewsScan: ReviewResponseDto[] = [];
            privateReviews.forEach((privateReview) => {
                const scan = scans.find((s) => s.id === privateReview.scanId);
                if (scan && scan.nfcId === nfc.id) {
                    nfcPrivateReviewsScan.push(privateReview);
                }
            });
            const nfcName = this._nfcNameToCsvNameMapper.mapNfcName(nfc);
            STARS_RATING.forEach((rating) => {
                const scansFiltered = nfcScans?.filter(
                    (scan) => scan.matchedReviewId && (scan.starClicked === rating || scan.matchedReview?.rating === rating)
                );
                const privateReviewsFiltered = nfcPrivateReviewsScan.filter((privateReview) => privateReview.rating === rating);
                acc.push([nfcName, rating.toString(), privateReviewsFiltered.length.toString(), scansFiltered?.length.toString() ?? '0']);
            });
            return acc;
        }, []);
        return groupedData ?? [];
    }
}
