import { DateTime } from 'luxon';

import { getNumberWithSpaces, getTimeDifferenceInDays, ReviewAnalysisTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { Review, SemanticAnalysis } from ':shared/models';

export const getReviewCsvRowData = (review: Review, rating: string, platform: string): string[] => {
    const date = review.socialCreatedAt.toISOString();
    const reviewText = review.text ?? '';
    const reviewer = review.reviewer?.displayName ?? '';
    const answer1 = review.comments[0]?.text ?? '';
    const answerDate1 = review.comments[0]?.socialUpdatedAt?.toISOString() ?? '';
    const answer2 = review.comments[1]?.text ?? '';
    const answerDate2 = review.comments[1]?.socialUpdatedAt?.toISOString() ?? '';
    const answer3 = review.comments[2]?.text ?? '';
    const answerDate3 = review.comments[2]?.socialUpdatedAt?.toISOString() ?? '';
    const atmospherePositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.ATMOSPHERE,
        SemanticAnalysisSentiment.POSITIVE
    );
    const atmosphereNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.ATMOSPHERE,
        SemanticAnalysisSentiment.NEGATIVE
    );
    const servicePositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.SERVICE,
        SemanticAnalysisSentiment.POSITIVE
    );
    const serviceNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.SERVICE,
        SemanticAnalysisSentiment.NEGATIVE
    );
    const foodPositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.FOOD,
        SemanticAnalysisSentiment.POSITIVE
    );
    const foodNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.FOOD,
        SemanticAnalysisSentiment.NEGATIVE
    );
    const pricePositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.PRICE,
        SemanticAnalysisSentiment.POSITIVE
    );
    const priceNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.PRICE,
        SemanticAnalysisSentiment.NEGATIVE
    );
    const hygienePositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.HYGIENE,
        SemanticAnalysisSentiment.POSITIVE
    );
    const hygieneNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.HYGIENE,
        SemanticAnalysisSentiment.NEGATIVE
    );
    const expeditiousnessPositive = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.EXPEDITIOUSNESS,
        SemanticAnalysisSentiment.POSITIVE
    );
    const expeditiousnessNegative = _getJoinedSemanticAnalysisSegments(
        review.semanticAnalysis,
        ReviewAnalysisTag.EXPEDITIOUSNESS,
        SemanticAnalysisSentiment.NEGATIVE
    );
    return [
        date,
        reviewText,
        reviewer,
        rating,
        platform,
        answer1,
        answerDate1,
        answer2,
        answerDate2,
        answer3,
        answerDate3,
        atmospherePositive,
        atmosphereNegative,
        servicePositive,
        serviceNegative,
        foodPositive,
        foodNegative,
        pricePositive,
        priceNegative,
        hygienePositive,
        hygieneNegative,
        expeditiousnessPositive,
        expeditiousnessNegative,
    ];
};

const _getJoinedSemanticAnalysisSegments = (
    semanticAnalysis: SemanticAnalysis | null,
    tag: ReviewAnalysisTag,
    sentiment: SemanticAnalysisSentiment
): string =>
    semanticAnalysis?.segmentAnalyses
        ?.filter((e) => e.tag === tag && e.sentiment === sentiment)
        .map((e) => e.segment)
        .join(' & ') ?? '';

export const createDateIntervalWithInDaysDurationCondition = (
    startDate: Date | null = null,
    endDate: Date | null = null,
    durationInDays: number
): { startDate: Date; endDate: Date } => {
    const now = DateTime.now();
    if (startDate && endDate) {
        if (endDate > now.toJSDate()) {
            endDate = now.toJSDate();
        }
        const diffInDays = getTimeDifferenceInDays(endDate, startDate);
        if (diffInDays > durationInDays) {
            startDate = DateTime.fromJSDate(endDate).minus({ days: durationInDays }).toJSDate();
        }
        return {
            startDate,
            endDate,
        };
    } else {
        return {
            startDate: now.minus({ days: durationInDays }).toJSDate(),
            endDate: now.toJSDate(),
        };
    }
};

export const displayRevenue = (revenue: { min: number; max: number }): string => {
    if (revenue.min === 2_000_000 && revenue.max === 2_000_000) {
        return `+ ${getNumberWithSpaces(revenue.min)}`;
    }
    return `${getNumberWithSpaces(revenue.min)} - ${getNumberWithSpaces(revenue.max)}`;
};
