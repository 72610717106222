import { ChangeDetectionStrategy, Component, effect, inject, signal, viewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PlatformKey } from '@malou-io/package-utils';

import {
    BaseStepComponent,
    GoToStepParam,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { PlatformsConnectionStepperComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-stepper/platforms-connection-stepper.component';
import { Step } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/step.interface';

export type ParentStepperComponentDialogData<SharedData = undefined, ModalReturnType = void> = {
    steps: Step<BaseStepComponent<SharedData, ModalReturnType>>[];
    stepIndex: number;
    stepperPlatformKeyIcon?: PlatformKey;
    stepperTitle: string;
    stepperSubtitle?: string;
} & (SharedData extends undefined ? { sharedData?: undefined } : { sharedData: SharedData });

export const PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT = '80vh';
export const PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH = '780px';

@Component({
    selector: 'app-platforms-connection-parent-stepper',
    standalone: true,
    templateUrl: './platforms-connection-parent-stepper.component.html',
    styleUrls: ['./platforms-connection-parent-stepper.component.scss'],
    imports: [PlatformsConnectionStepperComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsConnectionParentStepperComponent {
    readonly data = inject<ParentStepperComponentDialogData>(MAT_DIALOG_DATA);
    private readonly _dialogRef = inject(MatDialogRef<PlatformsConnectionParentStepperComponent>);

    readonly dynamicTemplate = viewChild('dynamicTemplate', { read: ViewContainerRef });

    readonly initialModalData: ParentStepperComponentDialogData;
    readonly currentStepIndex = signal<number>(0);
    readonly stepIndexesHistory = signal<number[]>([]);
    readonly stepNames: string[];
    readonly steps: Step<BaseStepComponent>[];
    isDynamicComponentInitialized = false;

    constructor() {
        effect(
            () => {
                this.stepIndexesHistory.update((value) => [...value, this.currentStepIndex()]);
            },
            { allowSignalWrites: true }
        );

        this.initialModalData = this.data;
        this.currentStepIndex.set(this.data.stepIndex);
        this.steps = this.data.steps;
        this.stepNames = this.steps.map((step) => step.stepName);

        effect(() => {
            const dynamicTemplate = this.dynamicTemplate();
            if (!dynamicTemplate || this.isDynamicComponentInitialized) {
                return;
            }
            this._loadComponentWithCurrentStep();
            this.isDynamicComponentInitialized = true;
        });
    }

    onClose(...args: any[]): void {
        this._dialogRef.close(...args);
    }

    onGoToStep(param: GoToStepParam): void {
        let computedStepIndex = this.currentStepIndex();
        if (param.type === 'absolute') {
            computedStepIndex = param.value;
        } else if (param.type === 'relative') {
            computedStepIndex += param.value;
        } else if (param.type === 'history') {
            const historyLength = this.stepIndexesHistory().length;
            if (historyLength === 0) {
                computedStepIndex = 0;
            } else {
                const value = this._limitBetween(param.value, 1, historyLength - 1);
                computedStepIndex = this.stepIndexesHistory()[historyLength - value - 1] ?? 0;
            }
        }
        const limitedIndex = this._limitBetween(computedStepIndex, 0, this.steps.length - 1);
        this.currentStepIndex.set(limitedIndex);
        this._loadComponentWithCurrentStep();
    }

    private _limitBetween(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(max, value));
    }

    private _loadComponentWithCurrentStep(): void {
        this._loadComponent(this.currentStepIndex());
    }

    private _loadComponent(stepIndex: number): void {
        const dynamicTemplate = this.dynamicTemplate();
        if (!dynamicTemplate) {
            return;
        }
        dynamicTemplate.clear();
        const step = this.initialModalData.steps[stepIndex];
        const componentRef = dynamicTemplate.createComponent(step.component);
        Object.entries(step.componentInputs).forEach(([key, value]) => {
            componentRef.setInput(key, value);
        });
        componentRef.setInput('sharedData', this.initialModalData.sharedData);
        componentRef.instance.close.subscribe((...args: any[]) => this.onClose(...args));
        componentRef.instance.goToStep.subscribe((params) => this.onGoToStep(params));
        componentRef.instance.updateSharedData.subscribe((newSharedData) => (this.initialModalData.sharedData = newSharedData));
    }
}
