import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, of, timer } from 'rxjs';
import { concatMap, filter, mapTo, switchMap } from 'rxjs/operators';

import { PictureSize, PostType } from '@malou-io/package-utils';

import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Story } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

interface StoryCarouselElt {
    url: string;
    type: string;
    postedAt: string;
}

const DEFAULT_DELAY = 3000;
@Component({
    selector: 'app-story-carousel',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AsyncPipe,
        TranslateModule,
        MatIconModule,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
    ],
    templateUrl: './story-carousel.component.html',
    styleUrls: ['./story-carousel.component.scss'],
    providers: [ImagePathResolverPipe],
})
export class StoryCarouselComponent {
    @Input() igAccountName: string; // can be extracted from story socialLink
    @Input() profilePictureUrl?: string | null;

    readonly trackByUrlFn = TrackByFunctionFactory.get('url');

    readonly mediaDelay$ = new BehaviorSubject<number>(0);

    storyCarousel: StoryCarouselElt[] = [];
    currentStory: StoryCarouselElt | undefined;
    currentStoryIdx: number;
    PostType = PostType;
    currentStories: Story[] = [];
    accountProfilePicUrl: string;
    slideAnimationOn = true;

    readonly SvgIcon = SvgIcon;

    constructor(
        private readonly _imagePathResolverPipe: ImagePathResolverPipe,
        private readonly _translate: TranslateService
    ) {}
    @Input() set stories(values: Story[]) {
        if (JSON.stringify(values) === JSON.stringify(this.currentStories)) {
            return;
        }
        if (!values) {
            return;
        }
        this.onCarouselUpdate(values);
        const initialDelay = this.currentStory?.type === PostType.VIDEO ? 0 : DEFAULT_DELAY;
        this.mediaDelay$.next(initialDelay);

        this.mediaDelay$
            .pipe(
                // emit 0 to pause timer
                filter(Boolean),
                switchMap((delay) => of(delay).pipe(concatMap((time) => timer(time).pipe(mapTo(time)))))
            )
            .subscribe({
                next: (res) => {
                    // emit 1 when user slide manually. currentStoryIdx already updated
                    if (res !== 1 && this.slideAnimationOn) {
                        this.currentStoryIdx = (this.currentStoryIdx + 1) % this.storyCarousel.length;
                    }
                    this.currentStory = this.storyCarousel[this.currentStoryIdx];
                    if (this.currentStory?.type === PostType.VIDEO || !this.slideAnimationOn) {
                        this.mediaDelay$.next(0);
                    } else {
                        this.mediaDelay$.next(DEFAULT_DELAY);
                    }
                },
            });
    }

    onCarouselUpdate(stories: Story[]): void {
        this.currentStories = stories;
        this.storyCarousel = stories.map((story) => this._mapToStoryCarouselElt(story));
        this.currentStoryIdx = 0;
        this.currentStory = this.storyCarousel[0];
    }

    previousMedia(): void {
        if (this.currentStoryIdx <= 0) {
            this.currentStoryIdx = this.storyCarousel.length - 1;
        } else {
            this.currentStoryIdx -= 1;
        }
        this.mediaDelay$.next(1);
    }

    nextMedia(): void {
        this.currentStoryIdx = (this.currentStoryIdx + 1) % this.storyCarousel.length;
        this.mediaDelay$.next(1);
    }

    onVideoEnd(): void {
        this.mediaDelay$.next(2);
    }

    onPlayerClick(): void {
        this.slideAnimationOn = !this.slideAnimationOn;
        if (this.slideAnimationOn) {
            (document.querySelector('#videoMedia') as HTMLVideoElement)?.play();
            if (this.currentStory?.type === PostType.IMAGE) {
                this.mediaDelay$.next(1500);
            }
        } else {
            (document.querySelector('#videoMedia') as HTMLVideoElement)?.pause();
        }
    }

    onImgError(event: Event): void {
        (event.target as HTMLImageElement).src = this._imagePathResolverPipe.transform('default-picture-grey');
    }

    private _mapToStoryCarouselElt(story: Story): StoryCarouselElt {
        const mediumType = story.postType;
        const socialCreatedAt = DateTime.fromJSDate(new Date(story.socialCreatedAt));
        const differenceInHours = DateTime.now().diff(socialCreatedAt, ['hours']).hours;
        return {
            url:
                story.getSocialMediaUrl(PictureSize.ORIGINAL) ||
                story.getMalouMediaUrl(PictureSize.IG_FIT) ||
                story.getMalouMediaUrl(PictureSize.ORIGINAL),
            type: mediumType,
            postedAt: `${Math.round(differenceInHours)}${this._translate.instant('common.hours_abbrev')}`,
        };
    }
}
