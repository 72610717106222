import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { combineLatest, concat, EMPTY, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { CredentialsService } from ':core/services/credentials.service';
import { PlatformsService } from ':core/services/platforms.service';
import { selectCurrentPlatforms, selectFetchPermissionsPlatforms } from ':modules/platforms/store/platforms.reducer';
import { Platform } from ':shared/models';

import * as PermissionsAction from './permissions.actions';

@Injectable()
export class PermissionsEffect {
    loadPermissions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(PermissionsAction.loadPermissions),
            mergeMap((action) =>
                combineLatest([this._store.select(selectCurrentPlatforms), this._store.select(selectFetchPermissionsPlatforms)]).pipe(
                    filter(([currentPlatforms]) => !!currentPlatforms?.length && currentPlatforms[0].restaurantId === action.restaurantId),
                    distinctUntilChanged((a, b) => isEqual(a, b)),
                    map(([currentPlatforms, fetchPermissionsPlatforms]) =>
                        currentPlatforms.filter((cp) => {
                            const oauthPlatformsKeys = Object.values(fetchPermissionsPlatforms).map((p) => p.key);
                            return oauthPlatformsKeys.includes(cp.key);
                        })
                    ),
                    switchMap((platforms) => {
                        this._store.dispatch({ type: PermissionsAction.resetPermissionsData.type });
                        return concat(
                            ...platforms.map((pl) =>
                                this._credentialsService.fetchPlatformPagePermissions({ platformId: pl._id }).pipe(
                                    map((res) => {
                                        const data = res?.data;
                                        if (!data) {
                                            return throwError(() => 'No permissions data');
                                        }
                                        return data;
                                    }),
                                    catchError(() => EMPTY)
                                )
                            )
                        );
                    }),
                    map((platformPermissions) => PermissionsAction.editPermissionsData({ permissions: platformPermissions })),
                    catchError((err) => {
                        console.warn(err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    loadPlatformPermissions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(PermissionsAction.loadPlatformPermissions),
            mergeMap((action) =>
                this._platformsService.getPlatformAndUpsert(action.platformKey, action.restaurantId).pipe(
                    switchMap((result) => {
                        const platform: Platform = result.data;
                        return this._credentialsService.fetchPlatformPagePermissions({ platformId: platform._id }).pipe(
                            map((res) => PermissionsAction.editPermissionsData({ permissions: res?.data })),
                            catchError(() => EMPTY)
                        );
                    }),
                    catchError((err) => {
                        console.warn(err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _credentialsService: CredentialsService,
        private readonly _store: Store,
        private readonly _platformsService: PlatformsService
    ) {}
}
