<div class="malou-dialog">
    <div class="malou-dialog__header fixed flex w-full !justify-end">
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body malou-color-text-1 !mr-0 mt-6 !p-0">
        <ng-container *ngTemplateOutlet="leaveReviewTemplate"></ng-container>
    </div>
</div>

<ng-template #leaveReviewTemplate>
    <div class="hide-scrollbar flex h-[52vh] flex-col items-center justify-between gap-y-5 overflow-y-auto px-6 pb-10">
        <div class="mt-4 flex flex-col">
            <ng-container [ngTemplateOutlet]="imageWithBackground"></ng-container>
            <span class="malou-text-24--bold mb-4">{{ 'play_wheel_of_fortune.leave_review.to_participate' | translate }}</span>
        </div>
        <div class="mb-4 flex h-[20vh] flex-col justify-between gap-y-4">
            <div class="malou-text-15--semibold flex items-center">
                <ng-container [ngTemplateOutlet]="roundNumber" [ngTemplateOutletContext]="{ number: 1 }"></ng-container>
                @if (data.redirectionPlatform === PlatformKey.INSTAGRAM) {
                    <div>
                        <div class="max-w-[55vw]">
                            <span [ngStyle]="{ color: data.secondaryColor }">{{
                                'play_wheel_of_fortune.leave_review.subscribe' | translate
                            }}</span
                            >&nbsp;
                            <span>{{ 'play_wheel_of_fortune.leave_review.to_instagram_page' | translate }}</span>
                        </div>
                    </div>
                } @else {
                    <div class="max-w-[55vw]">
                        <span>{{ 'play_wheel_of_fortune.leave_review.leave_us' | translate }}&nbsp;</span>
                        <span [ngStyle]="{ color: data.secondaryColor }"
                            >{{ 'play_wheel_of_fortune.leave_review.review' | translate }}&nbsp;</span
                        >
                        <span
                            >{{ 'play_wheel_of_fortune.leave_review.on' | translate }}&nbsp;{{
                                data.redirectionPlatform | enumTranslate: 'platform_key'
                            }}</span
                        >
                    </div>
                }
            </div>
            <div class="malou-text-15--semibold flex items-center">
                <ng-container [ngTemplateOutlet]="roundNumber" [ngTemplateOutletContext]="{ number: 2 }"></ng-container>
                <span class="max-w-[55vw]">{{ 'play_wheel_of_fortune.leave_review.come_back' | translate }}</span>
                <mat-icon class="ml-2" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
            </div>
            <div class="malou-text-15--semibold flex items-center">
                <ng-container [ngTemplateOutlet]="roundNumber" [ngTemplateOutletContext]="{ number: 3 }"></ng-container>
                <div class="max-w-[55vw]">
                    <span>{{ 'play_wheel_of_fortune.leave_review.start_wheel' | translate }}</span
                    >&nbsp;<span [ngStyle]="{ color: data.secondaryColor }">{{
                        'play_wheel_of_fortune.leave_review.discover_gift' | translate
                    }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="px-6">
        <button
            class="malou-btn-raised--dark btn-xl !mt-3 mb-8 !h-[69px] w-full"
            mat-raised-button
            [id]="data.redirectionPlatform === PlatformKey.INSTAGRAM ? 'tracking_wof_subscribe_cta' : 'tracking_wof_leave_review_cta'"
            (click)="openRedirectionLink()">
            {{
                (data.redirectionPlatform === PlatformKey.INSTAGRAM
                    ? 'play_wheel_of_fortune.leave_review.to_subscribe'
                    : 'play_wheel_of_fortune.leave_review.leave_review'
                ) | translate
            }}
        </button>
    </div>
    <div class="malou-text-12--semibold left-0 flex w-full items-baseline justify-center pb-3 text-center">
        <span>{{ 'play_wheel_of_fortune.powered_by' | translate }}</span>
        <img class="ml-1 max-h-2" [src]="'malou_logo_black' | imagePathResolver" />
    </div>
</ng-template>

<ng-template let-number="number" #roundNumber>
    <div
        class="malou-color-white malou-text-20--semibold mr-8 flex h-11 w-11 items-center justify-center rounded-full"
        [ngStyle]="{ 'background-color': data.primaryColor }">
        <span class="flex w-3 items-center justify-center">{{ number }}</span>
    </div>
</ng-template>

<ng-template #imageWithBackground>
    <div class="relative flex justify-center">
        <div
            class="absolute bottom-[5px] left-[22px] h-[100px] w-[100px] rounded-full"
            [ngStyle]="{ 'background-color': data.primaryColor }"></div>
        <img class="relative w-[120px]" alt="trumpet" [src]="'trumpet' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
    </div>
</ng-template>
