import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MalouErrorCode } from '@malou-io/package-utils';

import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Pipe({
    name: 'campaignsHttpError',
    standalone: true,
})
export class CampaignsHttpErrorPipe implements PipeTransform {
    constructor(
        private readonly _translate: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    transform(error: any): string {
        if (error?.error?.malouErrorCode === MalouErrorCode.EMAIL_NOT_SENT) {
            return this._translate.instant('campaigns.new_campaign.some_emails_not_sent_text');
        } else if (error.status === 400 && error?.error?.message?.match(/duplicate record error/)) {
            return this._translate.instant('campaigns.new_campaign.campaign_already_exist');
        }
        return this._httpErrorPipe.transform(error);
    }
}
