import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { isObservable, Subscription } from 'rxjs';

interface Params {
    startDate: Date;
    endDate: Date;
}

interface InterpolatedParams {
    startDate: string;
    endDate: string;
}

@Pipe({
    name: 'fromToDateFormatter',
    standalone: true,
    pure: false,
})
export class FromToDateFormatterPipe implements PipeTransform, OnDestroy {
    value = '';
    translateKey = 'common.from_date_to_date';
    lastParams: Params | undefined = undefined;
    interpolateParams: InterpolatedParams | undefined = undefined;

    onLangChange: Subscription | undefined;

    constructor(
        private readonly _translateService: TranslateService,
        private _ref: ChangeDetectorRef
    ) {}

    ngOnDestroy() {
        this._dispose();
    }

    updateValue(interpolateParams?: Object, translations?: any): void {
        const onTranslation = (res: string): void => {
            this.value = res !== undefined ? res : this.translateKey;
            // for rerendering the pipe
            this._ref.markForCheck();
        };

        if (translations) {
            const res = this._translateService.getParsedResult(translations, this.translateKey, interpolateParams);

            // Because parseResult can return an Observable
            if (isObservable(res.subscribe)) {
                res.subscribe(onTranslation);
            } else {
                onTranslation(res);
            }
        }

        // get the translations
        this._translateService.get(this.translateKey, interpolateParams).subscribe(onTranslation);
    }

    transform(dates: { startDate: Date; endDate: Date }): string {
        // store params
        this.lastParams = dates;

        const formattedStartDate = DateTime.fromJSDate(dates.startDate).toLocaleString(DateTime.DATE_FULL);
        const formattedEndDate = DateTime.fromJSDate(dates.endDate).toLocaleString(DateTime.DATE_FULL);

        this.interpolateParams = { startDate: formattedStartDate, endDate: formattedEndDate };

        // update the value
        this.updateValue(this.interpolateParams);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                const formattedStartDateOnLangChange = DateTime.fromJSDate(dates.startDate).toLocaleString(DateTime.DATE_FULL);
                const formattedEndDateOnLangChange = DateTime.fromJSDate(dates.endDate).toLocaleString(DateTime.DATE_FULL);
                this.interpolateParams = { startDate: formattedStartDateOnLangChange, endDate: formattedEndDateOnLangChange };
                this.updateValue(this.interpolateParams, event.translations);
            });
        }

        return this.value;
    }

    private _dispose(): void {
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
    }
}
