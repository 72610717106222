import { ChartDataArray, ChartDataElement, mergeArrays } from ':shared/helpers';

const insightValueToNumber = (value: ChartDataElement): number => {
    if (value === null) {
        return 0;
    }
    return value;
};

const computeTotalValue = (insights: ChartDataArray): ChartDataElement => {
    if (!insights || !insights.length) {
        return null;
    }
    if (insights.every((i) => i === null)) {
        return null;
    }
    return insights.reduce<number>((acc, curr) => {
        if (typeof curr === 'number' && !isNaN(curr)) {
            return (acc as number) + curr;
        }
        return acc;
    }, 0);
};

export class GmbInsights {
    websiteClicks: ChartDataArray;
    phoneClicks: ChartDataArray;
    drivingClicks: ChartDataArray;
    bookingClicks: ChartDataArray;
    menuClicks: ChartDataArray;
    foodOrderClicks: ChartDataArray;

    impressionsDesktopMaps: ChartDataArray;
    impressionsDesktopSearch: ChartDataArray;
    impressionsMobileMaps: ChartDataArray;
    impressionsMobileSearch: ChartDataArray;

    impressionsDesktop: ChartDataArray;
    impressionsMobile: ChartDataArray;
    impressionsSearch: ChartDataArray;
    impressionsMaps: ChartDataArray;
    totalWebsiteClicks: ChartDataElement;
    totalPhoneClicks: ChartDataElement;
    totalDrivingClicks: ChartDataElement;
    totalBookingClicks: ChartDataElement;
    totalMenuClicks: ChartDataElement;
    totalFoodOrderClicks: ChartDataElement;
    totalImpressionsDesktopMaps: ChartDataElement;
    totalImpressionsDesktopSearch: ChartDataElement;
    totalImpressionsMobileMaps: ChartDataElement;
    totalImpressionsMobileSearch: ChartDataElement;
    totalImpressionsMaps: ChartDataElement;
    totalImpressionsSearch: ChartDataElement;
    totalWebsiteClicksNumber: number;
    totalPhoneClicksNumber: number;
    totalDrivingClicksNumber: number;
    totalBookingClicksNumber: number;
    totalMenuClicksNumber: number;

    totalImpressionsDesktopMapsNumber: number;
    totalImpressionsDesktopSearchNumber: number;
    totalImpressionsMobileMapsNumber: number;
    totalImpressionsMobileSearchNumber: number;

    totalImpressions: number;

    totalActions: number;
    ratioActionsOverImpressions: number | null;

    dates: Date[];

    constructor(init?: Partial<GmbInsights>) {
        Object.assign(this, init);

        this.dates = init?.dates ?? [];
        this.impressionsDesktop = mergeArrays(this.impressionsDesktopMaps, this.impressionsDesktopSearch);
        this.impressionsMobile = mergeArrays(this.impressionsMobileMaps, this.impressionsMobileSearch);
        this.impressionsSearch = mergeArrays(this.impressionsDesktopSearch, this.impressionsMobileSearch);
        this.impressionsMaps = mergeArrays(this.impressionsDesktopMaps, this.impressionsMobileMaps);
        this.totalImpressionsDesktopMaps = computeTotalValue(this.impressionsDesktopMaps);
        this.totalImpressionsDesktopSearch = computeTotalValue(this.impressionsDesktopSearch);
        this.totalImpressionsMobileMaps = computeTotalValue(this.impressionsMobileMaps);
        this.totalImpressionsMobileSearch = computeTotalValue(this.impressionsMobileSearch);
        this.totalImpressionsMaps = computeTotalValue(this.impressionsMaps);
        this.totalImpressionsSearch = computeTotalValue(this.impressionsSearch);
        this.totalWebsiteClicks = computeTotalValue(this.websiteClicks);
        this.totalPhoneClicks = computeTotalValue(this.phoneClicks);
        this.totalDrivingClicks = computeTotalValue(this.drivingClicks);
        this.totalBookingClicks = computeTotalValue(this.bookingClicks);
        this.totalMenuClicks = computeTotalValue(this.menuClicks);
        this.totalFoodOrderClicks = computeTotalValue(this.foodOrderClicks);
        this.totalImpressionsDesktopMapsNumber = insightValueToNumber(this.totalImpressionsDesktopMaps);
        this.totalImpressionsDesktopSearchNumber = insightValueToNumber(this.totalImpressionsDesktopSearch);
        this.totalImpressionsMobileMapsNumber = insightValueToNumber(this.totalImpressionsMobileMaps);
        this.totalImpressionsMobileSearchNumber = insightValueToNumber(this.totalImpressionsMobileSearch);

        this.totalWebsiteClicksNumber = insightValueToNumber(this.totalWebsiteClicks);
        this.totalPhoneClicksNumber = insightValueToNumber(this.totalPhoneClicks);
        this.totalDrivingClicksNumber = insightValueToNumber(this.totalDrivingClicks);
        this.totalBookingClicksNumber = insightValueToNumber(this.totalBookingClicks);
        this.totalMenuClicksNumber = insightValueToNumber(this.totalMenuClicks);
        this.totalFoodOrderClicks = insightValueToNumber(this.totalFoodOrderClicks);

        this.totalImpressions =
            this.totalImpressionsDesktopMapsNumber +
            this.totalImpressionsDesktopSearchNumber +
            this.totalImpressionsMobileMapsNumber +
            this.totalImpressionsMobileSearchNumber;

        this.totalActions =
            this.totalDrivingClicksNumber +
            this.totalPhoneClicksNumber +
            this.totalWebsiteClicksNumber +
            this.totalBookingClicksNumber +
            this.totalMenuClicksNumber +
            this.totalFoodOrderClicks;

        this.ratioActionsOverImpressions = this.totalImpressions === 0 ? null : (this.totalActions / this.totalImpressions) * 100;
    }
}
