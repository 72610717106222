import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { CredentialsService } from ':core/services/credentials.service';
import { CustomerIOService } from ':core/services/customerio.service';
import { HeapService } from ':core/services/heap.service';
import { LocalStorage } from ':core/storage/local-storage';
import { UsersService } from ':modules/user/users.service';
import { LocalStorageKey } from ':shared/enums/local-storage-key';

import * as userActions from './user.actions';

@Injectable()
export class UserEffect {
    readonly loadUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(userActions.loadUser),
            mergeMap(() => {
                const token = LocalStorage.getItem(LocalStorageKey.JWT_TOKEN);
                if (token) {
                    const jwtToken = this._jwtHelper.decodeToken(token);
                    return this._usersService.getUser(jwtToken?._id).pipe(
                        switchMap((user) =>
                            user.defaultLanguage
                                ? of({ data: user })
                                : this._usersService.updateUser$(user._id, {
                                      defaultLanguage: LocalStorage.getLang(),
                                  })
                        ),
                        map((res) => {
                            this._heapService.identify(res.data?.email);
                            this._heapService.addUserProperties({ role: res.data?.role });
                            if (res.data?.email) {
                                this._customerIOService.identify(res.data?.email, {
                                    first_name: res.data?.name,
                                    last_name: res.data?.lastname,
                                    role: res.data?.role,
                                });
                            }
                            if (!res.data?.lastname) {
                                this._router.navigate(['auth/' + res.data?._id + '/edit-misc']);
                            }
                            return userActions.editUserInfos({ infos: res.data });
                        }),
                        catchError(() => EMPTY)
                    );
                }
                return EMPTY;
            })
        )
    );

    loadUserPlatformPermissions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(userActions.loadUserPlatformPermissions),
            mergeMap((action) =>
                this._credentialsService.getPermissionsForAllPlatformConnectionTypes$(action.key).pipe(
                    map((result) => (result ? userActions.editUserPlatformPermissions(result) : { type: '' })),
                    catchError((err) => {
                        console.warn(err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _usersService: UsersService,
        private readonly _credentialsService: CredentialsService,
        private readonly _jwtHelper: JwtHelperService,
        private readonly _heapService: HeapService,
        private readonly _customerIOService: CustomerIOService,
        private readonly _router: Router
    ) {
        this._customerIOService.initialize();
    }
}
