import { Pipe, PipeTransform } from '@angular/core';

import { PlatformKey } from '@malou-io/package-utils';

@Pipe({
    name: 'profileLinkPlatform',
    standalone: true,
})
export class ProfileLinkPlatformPipe implements PipeTransform {
    transform(socialId: string, platformKey: string): string | null {
        return this._getProfileLinkPlatform(socialId, platformKey);
    }

    private _getProfileLinkPlatform(socialId: string, platformKey: string): string | null {
        switch (platformKey) {
            case PlatformKey.GMB:
                return `https://business.google.com/n/${socialId}/profile`;
            default:
                return null;
        }
    }
}
