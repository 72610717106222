@let base = ['/restaurants', selectedRestaurant()?._id ?? ''];
<app-sidenav-content-route-group
    [options]="{
        text: 'sidenav_content.interactions' | translate,
        routerLink: base | concat: ['interactions'],
        svgIcon: SvgIcon.CONVERSATION,
    }"
    [childrenOptions]="[
        {
            text: 'sidenav_content.messages' | translate,
            routerLink: base | concat: ['interactions', 'messages'],
            notificationCount: unreadConversationCount(),
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.comments' | translate,
            routerLink: base | concat: ['interactions', 'comments'],
            notificationCount: unreadCommentsCount() + unreadMentionsCount(),
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
    ]" />
