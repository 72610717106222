import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NfcDisplayMode, SharedNfcComponent } from ':shared/components/shared-nfc/shared-nfc.component';

@Component({
    selector: 'app-totems',
    templateUrl: './totems.component.html',
    styleUrls: ['./totems.component.scss'],
    imports: [SharedNfcComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotemsComponent {
    NfcDisplayMode = NfcDisplayMode;
}
