import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, forkJoin, map, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { RoiSavedTimeDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { RoiService } from ':modules/roi/roi.service';
import { SavedTimeDetailsModalComponent } from ':modules/roi/saved-time-details-modal/saved-time-details-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { KillSubscriptions } from ':shared/interfaces';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { MillisecondsToHourMinutePipe } from ':shared/pipes/milliseconds-to-hour-minute.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-aggregated-saved-time',
    templateUrl: './aggregated-saved-time.component.html',
    styleUrls: ['./aggregated-saved-time.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EmojiPathResolverPipe,
        MillisecondsToHourMinutePipe,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        SkeletonComponent,
        NgTemplateOutlet,
    ],
})
export class AggregatedSavedTimeComponent implements OnInit, KillSubscriptions {
    readonly isParentLoading = input.required<boolean>();

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _roiService = inject(RoiService);
    private readonly _store = inject(Store);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    public readonly platformService = inject(PlatformsService);

    readonly PlatformKey = PlatformKey;

    readonly aggregatedRoiSavedTime: WritableSignal<RoiSavedTimeDto | null> = signal(null);
    readonly previousPeriodAggregatedRoiSavedTime: WritableSignal<RoiSavedTimeDto | null> = signal(null);

    readonly selectedTimeScaleFilter$ = this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly isLoading: WritableSignal<boolean> = signal(false);

    killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._initAggregatedRoiSavedTime();
    }

    private _initAggregatedRoiSavedTime(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.selectedTimeScaleFilter$])
            .pipe(
                filter(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) => !!restaurants.length),
                tap(() => this.isLoading.set(true)),
                switchMap(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) =>
                    forkJoin([
                        this._roiService.getSavedTimeForRestaurantsForNLastMonths(
                            restaurants.map((restaurant) => restaurant._id),
                            this.selectedMonths()
                        ),
                        this._roiService.getSavedTimeForRestaurantsForNLastMonths(
                            restaurants.map((restaurant) => restaurant._id),
                            this.selectedMonths(),
                            {
                                previousPeriod: true,
                            }
                        ),
                    ])
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([currentData, previousData]) => {
                    if (currentData.data) {
                        this.aggregatedRoiSavedTime.set(currentData.data);
                    }
                    if (previousData.data) {
                        this.previousPeriodAggregatedRoiSavedTime.set(previousData.data);
                    }
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }

    openDetails(): void {
        this._customDialogService.open(
            SavedTimeDetailsModalComponent,
            {
                height: 'unset',
                maxHeight: '90vh',
                data: {
                    currentData: this.aggregatedRoiSavedTime(),
                    previousData: this.previousPeriodAggregatedRoiSavedTime(),
                    isAggregatedView: true,
                },
            },
            { closeOnOutsideClick: true }
        );
    }
}
