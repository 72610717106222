import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { differenceWith } from 'lodash';

import { Day } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { HoursModalContext } from ':modules/informations/hours-modal/hours-modal.context';
import { OtherServiceSchedules } from ':modules/informations/hours-modal/hours-modal.interface';
import { HoursModalService } from ':modules/informations/hours-modal/hours-modal.service';
import { SelectDaysAndHoursComponent } from ':shared/components/select-days-and-hours/select-days-and-hours.component';
import { HoursType, Period } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-other-hours-form',
    templateUrl: './other-hours-form.component.html',
    styleUrls: ['./other-hours-form.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatDividerModule,
        TranslateModule,
        SelectDaysAndHoursComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        IncludesPipe,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherHoursFormComponent {
    readonly disabled = input<boolean>(false);

    private readonly _hoursModalService = inject(HoursModalService);
    private readonly _hoursModalContext = inject(HoursModalContext);

    readonly SvgIcon = SvgIcon;

    readonly otherHoursServices: Signal<OtherServiceSchedules[]> = this._hoursModalContext.hoursModalState.otherHours.services;

    readonly hourTypes = computed((): HoursType[] =>
        differenceWith(
            this._hoursModalContext.hoursModalState.otherHours.availableHoursTypes(),
            this.otherHoursServices().map((otherHoursService) => otherHoursService.type),
            (availableHour, otherHour) => availableHour.hoursType === otherHour.hoursType
        )
    );
    readonly expandedPanelIndex = signal(-1);
    readonly LANG = LocalStorage.getLang();

    onOpenedPanel(index: number): void {
        this.expandedPanelIndex.set(index);
    }

    removeService(serviceIndex: number): void {
        if (this.disabled()) {
            return;
        }
        this._hoursModalContext.removeServiceFromOtherHours(serviceIndex);
        const expandedPanelIndex = this.expandedPanelIndex();
        if (expandedPanelIndex === serviceIndex) {
            this.expandedPanelIndex.set(-1);
        } else if (expandedPanelIndex > serviceIndex) {
            this.expandedPanelIndex.set(expandedPanelIndex - 1);
        }
    }

    addService(type: HoursType): void {
        if (this.disabled()) {
            return;
        }
        this._hoursModalContext.addServiceToOtherHours(type);
        this.expandedPanelIndex.set(this.otherHoursServices().length - 1);
    }

    updateScheduleSelectedDays(serviceIndex: number, scheduleIndex: number, days: Day[]): void {
        this._hoursModalContext.updateOtherHoursServiceScheduleSelectedDays(serviceIndex, scheduleIndex, days);
    }

    updateSchedulePeriods(serviceIndex: number, scheduleIndex: number, periods: Period[]): void {
        this._hoursModalContext.updateOtherHoursServiceSchedulePeriods(serviceIndex, scheduleIndex, periods);
    }

    addScheduleToService(serviceIndex: number): void {
        this._hoursModalContext.addScheduleToOtherHoursService(serviceIndex);
    }

    removeScheduleFromService(serviceIndex: number, scheduleIndex: number): void {
        this._hoursModalContext.removeScheduleFromOtherHoursService(serviceIndex, scheduleIndex);
    }

    missingDays = (serviceIndex: number): Signal<Day[]> =>
        this._hoursModalService.getAvailableDaysForOtherHoursService(
            serviceIndex,
            [],
            this._hoursModalContext.hoursModalState.otherHours.services
        );
}
