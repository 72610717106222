<div class="mt-3 flex w-full flex-col gap-3">
    @for (day of DAYS; track day) {
        @if (displayUnspecifiedDays() || restaurantHours()[day]) {
            <app-day-or-date-hours
                [dayOrDateTitle]="day | enumTranslate: 'days' | titlecase"
                [timePeriods]="restaurantHours()[day]"
                [isClosed]="closedDays() | includes: day"></app-day-or-date-hours>
        }
    }
</div>
