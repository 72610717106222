<div class="malou-expansion-panel flex flex-col gap-2" id="otherHours">
    @if (hourTypes().length > 0 && !disabled()) {
        <ng-container [ngTemplateOutlet]="addHoursTemplate"></ng-container>
    }

    <mat-accordion>
        @for (service of otherHoursServices(); track $index; let serviceIndex = $index) {
            <mat-expansion-panel [expanded]="expandedPanelIndex() === serviceIndex" (opened)="onOpenedPanel(serviceIndex)">
                <mat-expansion-panel-header>
                    <mat-panel-title class="malou-text-13--semibold malou-color-text-1">
                        {{ service.type | applySelfPure: 'getLocaleName' : LANG }}
                    </mat-panel-title>
                    <mat-panel-description class="flex flex-row-reverse pr-3">
                        <mat-icon
                            class="icon-btn mr-2 h-4 !w-4"
                            color="primary"
                            [ngClass]="{ '!cursor-default opacity-30': disabled() }"
                            [svgIcon]="SvgIcon.TRASH"
                            (click)="removeService(serviceIndex)"></mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="my-3 flex flex-col gap-4">
                    @for (schedule of service.schedules; track schedule; let scheduleIndex = $index; let last = $last) {
                        <app-select-days-and-hours
                            class="px-4"
                            [selectedDays]="schedule.selectedDays"
                            [periods]="schedule.periods"
                            [availableDays]="schedule.availableDays"
                            [withIsClosedSelection]="false"
                            [disabled]="disabled()"
                            [canDeleteSelect]="service.schedules.length > 1"
                            (selectedDaysChange)="updateScheduleSelectedDays(serviceIndex, scheduleIndex, $event)"
                            (periodsChange)="updateSchedulePeriods(serviceIndex, scheduleIndex, $event)"
                            (delete)="removeScheduleFromService(serviceIndex, scheduleIndex)"></app-select-days-and-hours>
                        @if (!last || ((missingDays | applyPure: serviceIndex)().length > 0 && !disabled())) {
                            <mat-divider class="!border-malou-color-border-primary"></mat-divider>
                        }
                    }

                    <div class="px-4">
                        @if ((missingDays | applyPure: serviceIndex)().length > 0 && !disabled()) {
                            <button
                                class="malou-btn-flat !p-0"
                                mat-button
                                matTooltip="{{ 'information.business_hours.add_schedule' | translate }}"
                                (click)="addScheduleToService(serviceIndex)">
                                <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                                {{ 'information.business_hours.add_schedule' | translate }}
                            </button>
                        }
                    </div>
                </div>
            </mat-expansion-panel>
        }
    </mat-accordion>
</div>

<ng-template #addHoursTemplate>
    <div class="mt-1 flex items-center justify-between">
        <div class="malou-text-10--regular italic text-malou-color-text-2">
            {{ 'information.other_hours.keep_your_clients_informed' | translate }}
        </div>
        <button
            class="malou-btn-flat flex items-center !pl-0"
            mat-button
            matTooltip="{{ 'information.other_hours.add_hour' | translate }}"
            [matMenuTriggerFor]="hourTypeSelectionMenu">
            <mat-icon class="icon-btn mr-2" [svgIcon]="SvgIcon.ADD"></mat-icon>
            <span>
                {{ 'information.other_hours.add_hour' | translate }}
            </span>
        </button>
    </div>
    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #hourTypeSelectionMenu="matMenu">
        @for (item of hourTypes(); track item) {
            <button class="!flex !h-8 items-center" mat-menu-item (click)="addService(item)">
                <span class="malou-text-12--regular malou-color-text-2">
                    {{ item | applySelfPure: 'getLocaleName' : LANG }}
                </span>
            </button>
        }
    </mat-menu>
</ng-template>
