import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AspectRatio } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/upload-and-edit-medias/components/edit-media-modal/edit-media-modal.component';
import { linkedSignal } from ':shared/helpers/linked-signal';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum RotationDirection {
    CLOCKWISE = 'CLOCKWISE',
    COUNTERCLOCKWISE = 'COUNTERCLOCKWISE',
}

@Component({
    selector: 'app-image-transform-buttons',
    templateUrl: './image-transform-buttons.component.html',
    standalone: true,
    imports: [MatIconModule, TranslateModule, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTransformButtonsComponent {
    readonly aspectRatio = input<AspectRatio | undefined>();
    readonly aspectRatioChange = output<AspectRatio>();
    readonly rotationDirectionChange = output<RotationDirection>();

    readonly currentAspectRatio = linkedSignal<AspectRatio | undefined>(() => this.aspectRatio());

    readonly SvgIcon = SvgIcon;
    readonly AspectRatio = AspectRatio;
    readonly RotationDirection = RotationDirection;
    readonly displayedAspectRatios = [
        { aspectRatio: AspectRatio.SQUARE, i18nKey: 'image_transform_buttons.square' },
        { aspectRatio: AspectRatio.PORTRAIT, i18nKey: 'image_transform_buttons.portrait' },
        { aspectRatio: AspectRatio.LANDSCAPE, i18nKey: 'image_transform_buttons.landscape' },
    ];

    onAspectRatioClick(aspectRatio: AspectRatio): void {
        this.currentAspectRatio.set(aspectRatio);
        this.aspectRatioChange.emit(aspectRatio);
    }

    onRotate(direction: RotationDirection): void {
        this.rotationDirectionChange.emit(direction);
    }
}
