import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'values',
    standalone: true,
})
export class ValuesPipe implements PipeTransform {
    transform(object: Object): string[] {
        return Object.values(object);
    }
}
