<div class="flex h-screen flex-col items-center justify-center gap-4 bg-malou-color-backdrop">
    <div class="malou-box-shadow flex flex-col items-center justify-center gap-3 rounded-xl bg-white p-16">
        <img class="h-40" alt="Cape" [src]="Illustration.KarlOk | illustrationPathResolver" />
        <app-malou-spinner class="my-2" size="medium"></app-malou-spinner>
        <div class="malou-text-14--bold text-center text-malou-color-text-1">
            {{ 'reviews.text_copied' | translate }}
        </div>
        <div class="malou-text-12 text-center text-malou-color-text-2">
            {{ 'reviews.redirect_to_platform' | translate }}
        </div>
    </div>
</div>
