import { SimpleRestaurant } from '@malou-io/package-dto';
import { generateDbId, ReportType } from '@malou-io/package-utils';

import { User } from ':modules/user/user';

export class Report {
    type: ReportType;
    configurations: {
        id: string;
        recipients: string[];
        restaurantsIds: string[];
        restaurants: SimpleRestaurant[];
    }[];
    userId: string;
    user: User;
    active: boolean;
    id: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(report: Report) {
        this.type = report.type;
        this.configurations = report.configurations.map((config) => ({
            ...config,
            id: config.id,
        }));
        this.userId = report.userId;
        this.user = new User(report.user);
        this.active = report.active;
        this.id = report.id;
        this.createdAt = report.createdAt;
        this.updatedAt = report.updatedAt;
    }

    copyWith(report: Partial<Report>): Report {
        return new Report({
            ...this,
            ...report,
        });
    }

    addConfiguration(): void {
        this.configurations.push({
            id: generateDbId().toString(),
            recipients: [],
            restaurantsIds: [],
            restaurants: [],
        });
    }

    removeConfiguration(index: number): void {
        this.configurations.splice(index, 1);
    }

    toggleReportState(): void {
        this.active = !this.active;
    }

    hasEmptyConfigurations(): boolean {
        return this.configurations.some((config) => !config.restaurants?.length || !config.recipients.length);
    }
}
