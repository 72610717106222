import { z } from 'zod';

export const getUsableCategoriesQueryValidator = z
    .object({
        selectBrandBusinessCategories: z.enum(['true', 'false']),
    })
    .transform((data) => ({
        selectBrandBusinessCategories: data.selectBrandBusinessCategories === 'true',
    }));
export type GetUsableCategoriesQueryDto = z.infer<typeof getUsableCategoriesQueryValidator>;

export const getGoogleCategoryQueryValidator = z
    .object({
        place_id: z.string(),
    })
    .transform((data) => ({ placeId: data.place_id }));
