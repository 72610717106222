export enum AvailableFooterType {
    INFORMATION_UPDATE = 'information_update',
    REVIEW_SYNCHRONIZATION = 'review_synchronization',
    POSTING_STATUS = 'posting_status',
    CAMPAIGN = 'campaign',
}

export interface FooterManagerState {
    openedFootersOrder: AvailableFooterType[];
}
