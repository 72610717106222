import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import {
    ChartDataArray,
    malouChartColorBlue,
    malouChartColorBluePurple,
    malouChartColorLighterPink,
    malouChartColorLightPurple,
    malouChartColorPink,
    malouChartColorPurple,
} from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { Restaurant } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

type BarChartType = Extract<ChartType, 'bar'>;

const RESTAURANT_NAME_MAX_LENGTH = 20;

export interface GmbActionsData {
    websiteClicks: ChartDataArray;
    phoneClicks: ChartDataArray;
    drivingClicks: ChartDataArray;
    menuClicks: ChartDataArray;
    bookingClicks: ChartDataArray;
    foodOrderClicks: ChartDataArray;
}

@Component({
    selector: 'app-gmb-actions-chart',
    templateUrl: './gmb-actions-chart.component.html',
    styleUrls: ['./gmb-actions-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbActionsChartComponent implements OnChanges {
    @Input() gmbActionsData: GmbActionsData;
    @Input() previousGmbActionsData: GmbActionsData;
    @Input() restaurants: Restaurant[];
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() hiddenDatasetIndexesChange = new EventEmitter<number[]>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: string[];
    chartOption: ChartOptions<BarChartType>;

    readonly DATA_SETS_TITLES: string[] = [
        this._translateService.instant('aggregated_statistics.seo.gmb_actions.visit_website'),
        this._translateService.instant('aggregated_statistics.seo.gmb_actions.phone_call'),
        this._translateService.instant('aggregated_statistics.seo.gmb_actions.itinerary_request'),
        this._translateService.instant('statistics.seo.gmb_actions.menu_click'),
        this._translateService.instant('statistics.seo.gmb_actions.booking_click'),
        this._translateService.instant('statistics.seo.gmb_actions.food_order_click'),
    ];
    private _hiddenDatasetIndexes: number[] = [];

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _shortTextPipe: ShortTextPipe,
        private readonly _translateService: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'gmbActionsData')) {
            this.chartDataSets = this._computeChartData(this.gmbActionsData);
            if (this.hiddenDatasetIndexes.length) {
                this.chartDataSets = this.chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes.includes(index));
            }
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'restaurants')) {
            this.chartLabels = this._computeChartLabels(this.restaurants);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'gmbActionsData', 'previousGmbActionsData')) {
            this.chartOption = this._computeChartOptions();
        }
    }

    private _computeChartData(data: GmbActionsData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this.DATA_SETS_TITLES[0],
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.websiteClicks,
            },
            {
                label: this.DATA_SETS_TITLES[1],
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.phoneClicks,
            },
            {
                label: this.DATA_SETS_TITLES[2],
                borderColor: malouChartColorLightPurple,
                backgroundColor: malouChartColorLightPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.drivingClicks,
            },
            {
                label: this.DATA_SETS_TITLES[3],
                borderColor: malouChartColorLighterPink,
                backgroundColor: malouChartColorLighterPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.menuClicks,
            },
            {
                label: this.DATA_SETS_TITLES[4],
                borderColor: malouChartColorPurple,
                backgroundColor: malouChartColorPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.bookingClicks,
            },
            {
                label: this.DATA_SETS_TITLES[5],
                borderColor: malouChartColorBlue,
                backgroundColor: malouChartColorBlue,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.foodOrderClicks,
            },
        ];
    }

    private _computeChartLabels(restaurants: Restaurant[]): string[] {
        return restaurants.map((e) => e.internalName);
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        afterTitle: (tooltipItem: TooltipItem<BarChartType>[]) => this._computeTooltipAfterLabel(tooltipItem),
                        label: (tooltipItem: TooltipItem<BarChartType>): string => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            this._hiddenDatasetIndexes.push(index);
                            legendItem.hidden = true;
                        } else {
                            ci.show(index);
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (tickValue: number, _index: number, _ticks: Tick[]): string => {
                            const label = this.restaurants[_index].internalName;
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number, _index: number, _ticks: Tick[]) => this._shortNumberPipe.transform(tickValue),
                    },
                },
            },
        };
    }

    private _computeTooltipAfterLabel(item: TooltipItem<BarChartType>[]): string | undefined {
        const index = item[0].dataIndex;
        return this.restaurants[index].address?.getAddressWithDistrict();
    }

    private _indexToGmbActionDataKey(index: number): string {
        return ['websiteClicks', 'phoneClicks', 'drivingClicks', 'menuClicks', 'bookingClicks'][index];
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string {
        const previousValue = this.previousGmbActionsData[this._indexToGmbActionDataKey(item.datasetIndex)]?.[item.dataIndex];
        const difference = item.parsed.y - previousValue;
        const sign = difference > 0 ? '+' : '-';
        return `${this.DATA_SETS_TITLES[item.datasetIndex]}: ${item.parsed.y} (${sign}${Math.abs(difference)})`;
    }
}
