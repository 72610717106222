import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';

import { RoiSavedTimeDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { selectCurrentPlatformKeys } from ':modules/platforms/store/platforms.reducer';
import { RoiSavedTimeDetails, RoiSavedTimeRow } from ':shared/models/roi-saved-time.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SavedTimeItemComponent } from './saved-time-item/saved-time-item.component';

@Component({
    selector: 'app-saved-time-details-modal',
    templateUrl: './saved-time-details-modal.component.html',
    styleUrls: ['./saved-time-details-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, SavedTimeItemComponent, TranslateModule, AsyncPipe],
})
export class SavedTimeDetailsModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly savedTimeRows$: Observable<RoiSavedTimeRow[]>;

    constructor(
        private readonly _dialogRef: MatDialogRef<SavedTimeDetailsModalComponent>,
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            currentData: RoiSavedTimeDto;
            previousData: RoiSavedTimeDto;
            isAggregatedView: boolean;
        }
    ) {
        const platforms$ = this.data.isAggregatedView
            ? of([PlatformKey.GMB, PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM])
            : this._store.select(selectCurrentPlatformKeys);
        this.savedTimeRows$ = platforms$.pipe(
            map((platformKeys) => {
                const current = new RoiSavedTimeDetails(this.data.currentData ?? {}, platformKeys);
                const previous = new RoiSavedTimeDetails(this.data.previousData ?? {}, platformKeys);
                return current.buildRoiSavedTimeRows(previous, this._translate);
            })
        );
    }

    close(): void {
        this._dialogRef.close();
    }
}
