<div>
    <button
        class="malou-btn-icon mb-0 mr-2 flex h-[35px] w-[35px] items-center justify-center rounded-[50%] bg-malou-color-background-light"
        data-testid="header-notifications-btn"
        mat-icon-button
        (click)="openNotificationsModal()">
        <mat-icon
            class="!h-5 !w-5"
            color="primary"
            matBadgeSize="small"
            aria-hidden="false"
            [svgIcon]="SvgIcon.NOTIFICATIONS"
            [matBadgeHidden]="(notificationCount$ | async) === 0"
            [matBadge]="0"></mat-icon>
    </button>
</div>
