import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { CallToActionType } from '@malou-io/package-utils';

import { SelectComponent } from ':shared/components/select/select.component';
import { Restaurant } from ':shared/models';

import { CallToActionOption } from '../new-post-modal.component';

@Component({
    selector: 'app-post-call-to-action',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        SelectComponent,
        TranslateModule,
    ],
    templateUrl: './post-call-to-action.component.html',
    styleUrl: './post-call-to-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCallToActionComponent implements OnInit {
    readonly currentCallToActionType = input.required<CallToActionType | null>();
    readonly currentCallToActionUrl = input.required<string | null>();
    readonly restaurant = input.required<Restaurant>();
    readonly callToActions = input.required<CallToActionOption[]>();
    readonly onSelectUrl = output<string | null>();
    readonly onSelectCallToAction = output<CallToActionType>();
    readonly onNavigateToInformations = output<void>();

    readonly currentCallToAction = computed<CallToActionOption | null>(
        () => this.callToActions().find((cta) => cta.type === this.currentCallToActionType()) ?? null
    );
    readonly shouldShowAddUrlInInformation = computed<boolean>(() => {
        const callToAction = this.currentCallToAction();
        return (
            !!callToAction?.type &&
            [CallToActionType.LEARN_MORE, CallToActionType.SIGN_UP].includes(callToAction?.type) &&
            !callToAction?.actionLink
        );
    });
    readonly showExistingUrl = signal(false);
    readonly shouldShowCallToActionUrl = computed<boolean>(() => {
        if (this.showExistingUrl()) {
            return true;
        }
        const callToAction = this.currentCallToAction();
        const callToActionType = callToAction?.type;
        if (!callToAction || !callToActionType) {
            return false;
        }
        switch (callToAction.type) {
            case CallToActionType.LEARN_MORE:
            case CallToActionType.SIGN_UP:
                return true;
            default:
                return false;
        }
    });

    readonly urlsAutocompleteOptions = computed(() => {
        const url = this.currentCallToActionUrl();
        return this._filterUrls(url);
    });

    readonly CallToActionType = CallToActionType;

    callToActionFormGroup: FormGroup<{
        actionType: FormControl<CallToActionOption | null>;
        url: FormControl<string | null>;
    }>;

    ngOnInit(): void {
        this.callToActionFormGroup = new FormGroup({
            actionType: new FormControl(this.currentCallToAction()),
            url: new FormControl(this.currentCallToActionUrl()),
        });

        if (this.currentCallToActionUrl() && this.currentCallToActionUrl() !== this.currentCallToAction()?.actionLink) {
            this.showExistingUrl.set(true);
        }

        this.callToActionFormGroup.get('url')?.valueChanges.subscribe((value) => {
            this.onSelectUrl.emit(value);
        });
    }

    selectChange(event: CallToActionOption): void {
        this.onSelectCallToAction.emit(event.type);
        this.showExistingUrl.set(false);
    }

    displayWithCallToAction(cta: { type: CallToActionType; text: string }): string {
        return cta.text;
    }

    onUrlOptionSelected(event): void {
        this.onSelectUrl.emit(event.option?.value);
    }

    openInformations(): void {
        this.onNavigateToInformations.emit();
    }

    private _filterUrls(value: string | null): string[] {
        return value && typeof value === 'string'
            ? this.restaurant().relatedUrls.filter((url) => url?.toLowerCase().indexOf(value?.toLowerCase()) !== -1)
            : this.restaurant().relatedUrls;
    }
}
