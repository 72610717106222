import { IDisplayable } from '../interfaces';

export class Label implements IDisplayable {
    _id: string;
    restaurantId: string;
    text: string;
    clickRate: number;
    createdAt: Date;
    updatedAt: Date;
    isDefault?: boolean;

    public constructor(init?: Partial<Label>) {
        Object.assign(this, init);
    }
    getDisplayedValue(): string {
        return this.text;
    }
}
