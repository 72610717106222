export enum MalouPeriod {
    DEFAULT = 'default',
    LAST_SEVEN_DAYS = 'last_seven_days',
    LAST_THIRTY_DAYS = 'last_thirty_days',
    LAST_AND_COMING_THIRTY_DAYS = 'last_and_coming_thirty_days',
    LAST_THREE_MONTHS = 'last_three_months',
    LAST_SIX_MONTHS = 'last_six_months',
    LAST_AND_COMING_SIX_MONTH = 'last_and_coming_six_month',
    LAST_TWELVE_MONTHS = 'last_twelve_months',
    CUSTOM = 'custom',
    ALL = 'all',
}

export enum PlatformFilterPage {
    BOOSTERS = 'BOOSTERS',
    E_REPUTATION = 'E_REPUTATION',
    SEO = 'SEO',
    SOCIAL_NETWORKS = 'SOCIAL_NETWORKS',
    ROI = 'ROI',
}

export enum ReviewRepliedStatus {
    ANSWERED = 'answered',
    NOT_ANSWERED = 'notAnswered',
    PENDING = 'pending',
    NOT_ANSWERABLE = 'notAnswerable',
}

export enum MalouTimeScalePeriod {
    DEFAULT = 'default',
    LAST_SEVEN_DAYS = 'last_seven_days',
    LAST_THIRTY_DAYS = 'last_thirty_days',
    LAST_AND_COMING_THIRTY_DAYS = 'last_and_coming_thirty_days',
    LAST_AND_COMING_SIX_MONTH = 'last_and_coming_six_month',
    CUSTOM = 'custom',
    ALL = 'all',
    LAST_MONTH = 'last_month',
    LAST_TWO_MONTHS = 'last_two_months',
    LAST_THREE_MONTHS = 'last_three_months',
    LAST_FOUR_MONTHS = 'last_four_months',
    LAST_FIVE_MONTHS = 'last_five_months',
    LAST_SIX_MONTHS = 'last_six_months',
    LAST_SEVEN_MONTHS = 'last_seven_months',
    LAST_EIGHT_MONTHS = 'last_eight_months',
    LAST_NINE_MONTHS = 'last_nine_months',
    LAST_TEN_MONTHS = 'last_ten_months',
    LAST_ELEVEN_MONTHS = 'last_eleven_months',
    LAST_TWELVE_MONTHS = 'last_twelve_months',
}

export const DEFAULT_REVIEWS_RATINGS = [1, 2, 3, 4, 5, 0] as const;
