<form class="flex flex-col gap-y-4 pr-1" [formGroup]="aiReviewSettingsForm()">
    <div [class.hidden]="!isFrench()">
        <div class="malou-text-10--regular malou-color-text-2 mb-3">
            {{ 'restaurant_ai_settings.modals.upsert.tabs.general.reply_tone.title' | translate }} *
        </div>

        <mat-radio-group class="flex gap-x-5" formControlName="replyTone" color="primary">
            <mat-radio-button
                class="malou-small-radio-button"
                data-testid="general-ai-settings-formal-input"
                [value]="FrenchTutoiementVouvoiement.FORMAL">
                <span class="malou-text-10--semibold">
                    {{ FrenchTutoiementVouvoiement.FORMAL | enumTranslate: 'reply_tone' }}
                </span>
            </mat-radio-button>
            <mat-radio-button
                class="malou-small-radio-button"
                data-testid="general-ai-settings-informal-input"
                [value]="FrenchTutoiementVouvoiement.INFORMAL">
                <span class="malou-text-10--semibold">
                    {{ FrenchTutoiementVouvoiement.INFORMAL | enumTranslate: 'reply_tone' }}
                </span>
            </mat-radio-button>
            <mat-radio-button
                class="malou-small-radio-button"
                data-testid="general-ai-settings-does-not-matter-input"
                [value]="FrenchTutoiementVouvoiement.DOES_NOT_MATTER">
                <span class="malou-text-10--semibold">
                    {{ FrenchTutoiementVouvoiement.DOES_NOT_MATTER | enumTranslate: 'reply_tone' }}
                </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="flex flex-col gap-3 rounded bg-malou-color-background-light p-4">
        <div class="malou-text-12--semibold malou-color-text-1">
            {{ 'restaurant_ai_settings.modals.upsert.tabs.general.catchphrase.title' | translate }}
        </div>
        <div class="flex gap-x-3 p-1 md:flex-col md:gap-y-4">
            <div class="flex-1">
                <app-input-text
                    formControlName="catchphrase"
                    placeholder="{{ 'restaurant_ai_settings.modals.upsert.tabs.advanced.catchphrase.placeholder' | translate }}">
                </app-input-text>
            </div>
            <div class="flex-1 rounded-lg bg-malou-color-background-white">
                <app-simple-select
                    formControlName="customerNaming"
                    [testId]="'general-ai-settings-customer-name-input'"
                    [values]="CUSTOMER_NAMING_OPTIONS">
                    <ng-template let-value="value" #selectedValueTemplate>
                        <div class="malou-text-12--medium text-malou-color-text-1">
                            {{ value | enumTranslate: 'customer_naming' }}
                        </div>
                    </ng-template>

                    <ng-template let-option="option" #optionTemplate>
                        <div class="malou-text-13--regular text-malou-color-text-2">
                            {{ option | enumTranslate: 'customer_naming' }}
                        </div>
                    </ng-template>
                </app-simple-select>
            </div>
        </div>
        <div class="flex items-center gap-x-2">
            <mat-checkbox formControlName="shouldTranslateCatchphrase" color="primary"></mat-checkbox>
            <span class="malou-text-10--regular malou-color-text-2">
                {{ 'restaurant_ai_settings.modals.upsert.tabs.general.do_not_translate' | translate }}</span
            >
        </div>
    </div>

    <!-- Signature -->
    <div class="flex flex-col gap-3 rounded bg-malou-color-background-light p-4">
        <div class="flex flex-col">
            <span class="malou-text-12--semibold malou-color-text-1">{{
                'restaurant_ai_settings.modals.upsert.tabs.general.signature.title' | translate
            }}</span>
            <span class="malou-text-10--regular malou-color-text-2 mt-1 italic">{{
                'restaurant_ai_settings.modals.upsert.tabs.general.signature.add_one_or_several' | translate
            }}</span>
        </div>

        <ng-container formArrayName="signatures">
            @for (signatureForm of signatures.controls; track signatures; let idx = $index; let last = $last; let count = $count) {
                <div class="flex w-full items-center">
                    <app-input-text
                        class="w-full"
                        [formControl]="signatureForm"
                        [testId]="'general-ai-settings-signature-input'"
                        [showRequiredStar]="false"
                        [placeholder]="
                            'restaurant_ai_settings.modals.upsert.tabs.general.signature.placeholder'
                                | translate: { restaurantName: aiSettingsContext.restaurantAiSettings()!.restaurantName }
                        ">
                    </app-input-text>
                    <mat-icon
                        class="ml-2 !h-4 !w-4 cursor-pointer text-malou-color-state-error"
                        [svgIcon]="SvgIcon.ROUNDED_MINUS"
                        (click)="removeSignature(idx, count === 1)"></mat-icon>
                    @if (last) {
                        <mat-icon
                            class="ml-2 !h-4 !w-4 cursor-pointer"
                            color="primary"
                            [svgIcon]="SvgIcon.ADD"
                            (click)="addSignature()"></mat-icon>
                    } @else {
                        <div class="ml-2 !h-4 !w-4"></div>
                    }
                </div>
            }
        </ng-container>

        <div class="flex items-center gap-x-2">
            <mat-checkbox formControlName="shouldTranslateSignature" color="primary"></mat-checkbox>
            <span class="malou-text-10--regular malou-color-text-2">
                {{ 'restaurant_ai_settings.modals.upsert.tabs.general.do_not_translate' | translate }}</span
            >
        </div>
    </div>
</form>
