import { Subject } from 'rxjs';

import { KillSubscriptions } from ':shared/interfaces';

export function AutoUnsubscribeOnDestroy() {
    return function <T extends new (...args: any[]) => KillSubscriptions>(constructor: T): void {
        const originalOnDestroy = constructor.prototype.ngOnDestroy;
        const enhancedOnDestroy = function (this: KillSubscriptions): void {
            if (this.killSubscriptions$ && this.killSubscriptions$ instanceof Subject) {
                this.killSubscriptions$.next();
                this.killSubscriptions$.complete();
            } else {
                console.warn(
                    '[@AutoUnsubscribeOnDestroy decorator] Decorated class must implement KillSubscriptions interface and declare : killSubscriptions$ = new Subject()'
                );
            }
            if (originalOnDestroy) {
                originalOnDestroy.apply(this);
            }
        };
        constructor.prototype.ngOnDestroy = enhancedOnDestroy;
    };
}
