import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidFacebookUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.FACEBOOK })) {
            return false;
        }

        // Try to extract facebook username
        const userName = getFacebookUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getFacebookUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = decodeURIComponent(trimSlashes(parsedUrl.pathname)); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters, latin characters, hyphens and dots are allowed for facebook usernames
        const userNameRegex = /^[-a-zA-ZÀ-ž0-9\.]+$/;

        // profile.php?id=userId
        const path = `${pathname}${parsedUrl.search}${parsedUrl.hash}`;
        if (path.startsWith('profile.php?id=')) {
            const id = parsedUrl.searchParams.get('id');
            return id && userNameRegex.test(id) ? encodeURIComponent(id) : null;
        }

        // /p/:username
        if (pathname.startsWith('p/')) {
            const userNameStartingWithP = pathname.replace('p/', '');
            return userNameStartingWithP && userNameRegex.test(userNameStartingWithP) ? encodeURIComponent(userNameStartingWithP) : null;
        }

        // /people/:username/:userId
        const peopleRegex = /^people\/[-a-zA-ZÀ-ž0-9\.]+\/(\d+)$/;
        const userId = pathname.match(peopleRegex)?.[1];
        if (userId) {
            return userId;
        }

        // /username
        return pathname && userNameRegex.test(pathname) ? encodeURIComponent(pathname) : null;
    } catch {
        return null;
    }
}

export function transformFacebookUrl(url: string): string | null {
    if (!isValidFacebookUrl(url)) return null;
    const userName = getFacebookUserName(url);
    return `https://www.facebook.com/${userName}`;
}
