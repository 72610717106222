import { AiInteractionDto } from '@malou-io/package-dto';
import { AiInteractionRelatedEntityCollection, AiInteractionType } from '@malou-io/package-utils';

export class AiInteraction {
    relatedEntityCollection: AiInteractionRelatedEntityCollection;
    relatedEntityId: string;
    type: AiInteractionType;
    restaurantId?: string;
    userId?: string;
    promptText?: string;
    promptTokenCount?: number;
    completionText?: string;
    completionTokenCount?: number;
    responseTimeInMilliseconds?: number;
    completionTimeInMilliseconds?: number;
    error?: {
        malouErrorCode?: string;
        message?: string;
        stack?: string;
    };
    createdAt: Date;
    updatedAt: Date;

    constructor(data: AiInteractionDto) {
        Object.assign(this, data);
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
    }

    static getLastSuccessfulAiInteraction(aiInteractions: AiInteraction[]): AiInteraction | undefined {
        return aiInteractions
            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
            .find((aiInteraction) => aiInteraction.isSuccessful());
    }

    static getSuccesfulAiInteractions(aiInteractions: AiInteraction[]): AiInteraction[] {
        return aiInteractions.filter((aiInteraction) => aiInteraction.isSuccessful());
    }

    static getFilteredAiInteractions(
        aiInteractions: AiInteraction[],
        filterFunction?: (aiInteraction: AiInteraction) => boolean
    ): AiInteraction[] {
        const sortedAiInteractions = aiInteractions.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
        return filterFunction ? sortedAiInteractions.filter(filterFunction) : sortedAiInteractions;
    }

    isSuccessful(): boolean {
        return !this.error && !!this.completionText && this.completionText.length > 0;
    }
}
