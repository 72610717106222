import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Pusher, { Channel } from 'pusher-js';
import { map, Observable, Subject, switchMap } from 'rxjs';

import {
    GetActiveNotificationsQueryDto,
    GetNotificationsQueryDto,
    GetUnreadNotificationCountQueryDto,
    NotificationDTO,
    SendTestNotificationBodyDto,
    SuccessResponse,
    TrackNotificationActionBodyDto,
    UpdateNotificationsBodyDto,
    UpdateSpecialHourNotificationBodyDto,
} from '@malou-io/package-dto';
import { AiInteractionRelatedEntityCollection, AiInteractionType, ApiResultV2, NotificationType } from '@malou-io/package-utils';

import { AiInteractionsService } from ':core/services/ai-interactions.service';
import { IRealtimeMessagingService } from ':core/services/realtime-messaging/realtime-messaging.interface';
import { environment } from ':environments/environment';
import { selectUserState } from ':modules/user/store/user.selectors';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { formatArrayKeysForQueryParams } from ':shared/helpers/query-params';
import { KillSubscriptions } from ':shared/interfaces';

import { NegativeReviewReminderNotification } from '../models/negative-review-reminder-notification.model';
import { NotificationFactory } from '../models/notification.factory';
import { Notification } from '../models/notification.model';

@Injectable({
    providedIn: 'root',
})
@AutoUnsubscribeOnDestroy()
export class NotificationService implements KillSubscriptions, IRealtimeMessagingService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/notifications`;

    private readonly _http = inject(HttpClient);
    private readonly _store = inject(Store);
    private readonly _aiInteractionsService = inject(AiInteractionsService);

    private _channel?: Channel;
    killSubscriptions$: Subject<void>;

    readonly notificationReceived$ = new Subject<Notification>();

    markAllNotificationsAsRead(): Observable<SuccessResponse> {
        return this._http.put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/read_all`, {}).pipe(map((res) => res.data));
    }

    getNotifications(params: GetNotificationsQueryDto): Observable<Notification[]> {
        return this._http
            .get<ApiResultV2<NotificationDTO[]>>(`${this.API_BASE_URL}`, {
                params: formatArrayKeysForQueryParams(params),
            })
            .pipe(map((res) => res.data.map((notification) => NotificationFactory.create(notification))));
    }

    getUnreadNotificationCount(params: GetUnreadNotificationCountQueryDto): Observable<number> {
        return this._http
            .get<ApiResultV2<number>>(`${this.API_BASE_URL}/unread_count`, {
                params: formatArrayKeysForQueryParams(params),
            })
            .pipe(map((res) => res.data));
    }

    getActiveNotifications(params: GetActiveNotificationsQueryDto): Observable<Notification[]> {
        return this._http
            .get<ApiResultV2<NotificationDTO[]>>(`${this.API_BASE_URL}/active`, {
                params: formatArrayKeysForQueryParams(params),
            })
            .pipe(map((res) => res.data.map((notification) => NotificationFactory.create(notification))));
    }

    updateNotifications(payload: UpdateNotificationsBodyDto): Observable<SuccessResponse> {
        return this._http.put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}`, payload).pipe(map((res) => res.data));
    }

    sendTestNotification(payload: SendTestNotificationBodyDto): Observable<SuccessResponse> {
        return this._http.post<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/test`, payload).pipe(map((res) => res.data));
    }

    getNotificationById(notificationId: string): Observable<Notification> {
        return this._http
            .get<ApiResultV2<NotificationDTO>>(`${this.API_BASE_URL}/${notificationId}`)
            .pipe(map((res) => NotificationFactory.create(res.data)));
    }

    initRealtimeNotifications(realtimeMessagingClient: Pusher): void {
        this._store.select(selectUserState).subscribe({
            next: ({ infos: user }) => {
                if (!user) {
                    this._channel?.unbind_all();
                    this._channel?.unsubscribe();
                    this._channel = undefined;
                    return;
                }
                this._channel = realtimeMessagingClient.subscribe(`notifications-${user.id}`);

                this._channel.bind(NotificationType.REVIEW, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.SPECIAL_HOUR, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.REVIEW_REPLY_REMINDER, (data: { notificationId: string }) => {
                    this.initReviewReplyReminderNotification(data).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.POST_SUGGESTION, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.PLATFORM_DISCONNECTED, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.POST_ERROR, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.COMMENT, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });
                this._channel.bind(NotificationType.MENTION, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });

                this._channel.bind(NotificationType.NEW_MESSAGE, (data: { notificationId: string }) => {
                    this.getNotificationById(data.notificationId).subscribe({
                        next: (notification) => this.notificationReceived$.next(notification),
                    });
                });
            },
        });
    }

    initReviewReplyReminderNotification(data: { notificationId: string }): Observable<NegativeReviewReminderNotification> {
        return this.getNotificationById(data.notificationId).pipe(
            switchMap((notification: NegativeReviewReminderNotification) =>
                this._aiInteractionsService
                    .getAiInteractions(notification.getMainReviewToNotify().id, AiInteractionRelatedEntityCollection.REVIEWS)
                    .pipe(
                        map((aiInteractions) => {
                            const interactions = aiInteractions.filter(
                                (interaction) =>
                                    interaction.isSuccessful() &&
                                    [
                                        AiInteractionType.ANSWER_REVIEW,
                                        AiInteractionType.ANSWER_REVIEW_ADVANCED_SETTINGS,
                                        AiInteractionType.OPTIMIZE_REVIEW_ANSWER,
                                        AiInteractionType.OPTIMIZE_REVIEW_ANSWER_ADVANCED_SETTINGS,
                                        AiInteractionType.REVIEW_ANSWER_TRANSLATION,
                                    ].includes(interaction.type)
                            );
                            notification.data.suggestedReply = interactions.length > 0 ? interactions[0].completionText : undefined;
                            return notification;
                        })
                    )
            )
        );
    }

    trackNotificationAction$(payload: TrackNotificationActionBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/track`, { ...payload });
    }

    updateSpecialHourNotification(notificationId: string, update: UpdateSpecialHourNotificationBodyDto): Observable<Notification> {
        return this._http
            .put<ApiResultV2<NotificationDTO>>(`${this.API_BASE_URL}/${notificationId}/special_hour`, update)
            .pipe(map((res) => NotificationFactory.create(res.data)));
    }
}
