import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const createRestaurantKeywordParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type CreateRestaurantKeywordParamsDto = z.infer<typeof createRestaurantKeywordParamsValidator>;

export const createRestaurantKeywordBodyValidator = z.object({
    text: z.string(),
    language: z.string(),
});
export type CreateRestaurantKeywordBodyDto = z.infer<typeof createRestaurantKeywordBodyValidator>;
