import { ChangeDetectionStrategy, Component, forwardRef, inject, Injector, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PlatformKey } from '@malou-io/package-utils';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { ClientsSource } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-select-platforms',
    templateUrl: 'select-platforms.component.html',
    styleUrls: ['./select-platforms.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectPlatformsComponent),
        },
        EnumTranslatePipe,
    ],
    standalone: true,
    imports: [SelectBaseComponent, FormsModule, ReactiveFormsModule, PlatformLogoComponent, LazyLoadImageModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlatformsComponent extends ControlValueAccessorConnectorComponent {
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<(PlatformKey | ClientsSource)[]>([]);

    readonly selectedValues = input<(PlatformKey | ClientsSource)[]>([]);

    readonly multiSelection = input<boolean>(true);

    readonly displayWith = input<(option: any) => string>((option: any) =>
        option ? this._enumTranslatePipe.transform(option, 'platform_key') : ''
    );

    // ------------ Event ------------//

    /** On change event */
    readonly selectPlatformsChange = output<PlatformKey[]>();

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    constructor(protected readonly injector: Injector) {
        super(injector);
    }
}
