<div class="flex h-full w-full flex-1 items-center justify-center md:p-6">
    <div class="flex flex-col items-center">
        <img id="img-no-results" loading="lazy" [src]="picture" />
        <p class="malou-text-14--bold mb-4 mt-9 text-center text-malou-color-text-1">{{ title }}</p>
        @for (detail of details; track detail) {
            <span class="malou-text-10--regular mb-6 text-center">{{ detail }}</span>
        }
        @if (buttonLabel) {
            <button mat-raised-button [class]="buttonClass" (click)="onClickButton.emit()">
                {{ buttonLabel }}
            </button>
        }
    </div>
</div>
