<div class="flex w-full flex-col">
    @if (title || subtitle) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
        </div>
    }

    <div
        class="malou-border-primary flex cursor-pointer items-center rounded-lg bg-white"
        [(colorPicker)]="value"
        [cpPositionOffset]="positionOffset"
        [cpPositionRelativeToArrow]="positionRelativeToArrow"
        [class.opacity-50]="disabled"
        [cpCancelButton]="true"
        [cpPresetColors]="presetColors"
        [cpAlphaChannel]="showAlphaCursor ? 'enabled' : 'disabled'"
        [cpEyeDropper]="true"
        [cpDisabled]="disabled"
        [cpPosition]="position"
        [ngStyle]="{ color: value }"
        (colorPickerChange)="onColorChange($event)">
        <div class="h-12 w-full">
            <input
                class="malou-text-12--medium box-border h-full w-full cursor-pointer rounded-lg border-0 bg-white px-5 !text-malou-color-text-1 outline-none placeholder:italic"
                [ngClass]="{ '!cursor-not-allowed': disabled }"
                [class.bg-white]="disabled"
                [disabled]="disabled"
                [readonly]="true"
                [placeholder]="placeholder"
                [value]="transformValue | applyPure: value" />
        </div>

        <div class="flex flex-col px-2">
            <div class="h-6 w-6 rounded-lg" [ngStyle]="{ 'background-color': value }"></div>
        </div>
    </div>
</div>
