import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RoiSettingsDto, UpsertRoiSettingsBodyDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RoiSettingsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/roi-settings`;

    constructor(private readonly _http: HttpClient) {}

    getByRestaurantId(restaurantId: string): Observable<ApiResultV2<RoiSettingsDto>> {
        return this._http.get<ApiResultV2<RoiSettingsDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`);
    }

    getForRestaurantIds(restaurantIds: string[]): Observable<ApiResultV2<RoiSettingsDto[]>> {
        return this._http.post<ApiResultV2<RoiSettingsDto[]>>(`${this.API_BASE_URL}/restaurants/search`, { restaurantIds });
    }

    upsert(restaurantId: string, data: UpsertRoiSettingsBodyDto): Observable<ApiResultV2<RoiSettingsDto>> {
        return this._http.put<ApiResultV2<RoiSettingsDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, data);
    }
}
