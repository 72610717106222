import confetti from 'canvas-confetti';

interface ConfettiConfiguration {
    particleCount: number;
    angle: number;
    spread: number;
    origin: { x: number; y: number };
    colors: string[];
    zIndex: number;
}

const DEFAULT_CONFETTIS_COLORS = ['#EBD6B1'];

export const DEFAULT_CONFETTIS_CONFIGURATION = [
    {
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 0.7 },
        colors: DEFAULT_CONFETTIS_COLORS,
        zIndex: 10000,
    },
    {
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 0.7 },
        colors: DEFAULT_CONFETTIS_COLORS,
        zIndex: 1000,
    },
];

export const launchConfettis = (configurations: ConfettiConfiguration[]): void => {
    configurations.forEach((configuration) => {
        confetti(configuration);
    });
};
