import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return platform name given a platform key
 */
@Pipe({
    name: 'platformLogoPathResolver',
    standalone: true,
})
export class PlatformLogoPathResolverPipe implements PipeTransform {
    transform(key: string, options?: { folder?: string }): string {
        if (options?.folder) {
            return `/assets/platforms/logos/${options.folder}/${key}.png`;
        }
        return `/assets/platforms/logos/${key}.png`;
    }
}
