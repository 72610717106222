import { BricksCategory } from '@malou-io/package-utils';

import { IDisplayable } from '../interfaces';

export class Brick implements IDisplayable {
    _id: string;
    brickId: string;
    key: {
        fr?: string;
        en?: string;
        es?: string;
        it?: string;
    };
    category: BricksCategory;
    subCategory: string;
    nature: string;
    gender: string;
    number: string;
    isProper: boolean;
    text: {
        fr?: string;
        en?: string;
        es?: string;
        it?: string;
    };
    isHidden: boolean;
    needReview: boolean;
    meanSearchVol: number;

    currentLang?: string;

    constructor(init: Partial<Brick> = {}, currentLang = 'fr') {
        Object.assign(this, init);
        this.currentLang = currentLang;
    }

    getDisplayedValue(): string {
        if (this.currentLang) {
            return this.text?.[this.currentLang];
        }
        return this.text?.['en'] ?? this.text?.['fr'] ?? '';
    }
}

export interface BrickGenerator {
    _id: string;
    key: string; // example: gcid:bar_pmu or 75116
    type: string; // example: categoryId or postalCode
    bricks: Brick[];
}
