<div class="animated-wheel-of-fortune">
    <div
        class="malou-border-wheel-of-fortune absolute left-[45%] top-[34%] z-10 border-x-[18px] border-b-[50px] !border-x-transparent"
        [ngClass]="{
            '!top-[33%]': isSmallScreen(),
            '!border-x-[13px] !border-b-[32px]': isPreview(),
        }"></div>
    <div
        class="malou-box-shadow malou-color-bg-white relative left-[-6%] rotate-[7.5deg] transform overflow-hidden rounded-full border-[15px] border-white"
        [ngClass]="{ '!border-[10px]': isPreview() }"
        [ngStyle]="{ height: wheelContainerSize, width: wheelContainerSize }"
        #wheelDisplay>
        <div
            class="malou-box-shadow malou-border-wheel-of-fortune absolute left-[42%] top-[42%] z-10 h-[16%] w-[16%] rounded-full border-[10px]"
            [ngClass]="{ '!border-[7px]': isPreview() }"
            [ngStyle]="{ 'background-color': currentlyHoveredColor() }"></div>
        @for (index of allSubSectors(); track index) {
            <div>
                <div
                    class="absolute left-[43%] flex h-[50%] w-[14%] origin-bottom rotate-[-50%] transform items-center justify-center"
                    style="clip-path: polygon(100% 0, 50% 100%, 0 0)"
                    [ngStyle]="{
                        transform: getRotateDegrees() | applyPure: index,
                        'background-color':
                            (isSectorWithText | applyPure: index)
                                ? (addOpacityToColor | applyPure: (getColor() | applyPure: index))
                                : (getColor() | applyPure: index),
                    }"></div>
                @if (isSectorWithText | applyPure: index) {
                    <span
                        class="malou-text-15--bold malou-color-white absolute right-[0%] top-[44.5%] z-10 flex h-[11%] w-[100%] transform items-center pl-[62%] text-start"
                        [ngClass]="{ '!malou-text-14--bold': isSmallScreen(), '!malou-text-10--bold': isPreview() }"
                        [ngStyle]="{ transform: getRotateDegreesText() | applyPure: index }">
                        {{ sectors()[(index - 1) / 3].name | sentenceCase: { capitalizeOnly: true } }}
                    </span>
                }
            </div>
        }
    </div>
</div>
