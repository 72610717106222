import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'map',
    standalone: true,
})
export class MapPipe implements PipeTransform {
    transform(array: any[], key: string): any[] {
        return array.map((value: any) => value[key]);
    }
}
