import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';

@Component({
    selector: 'app-reputation',
    templateUrl: './reputation.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class ReputationComponent {
    private _redirecting = false;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router
    ) {
        this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil), takeUntilDestroyed()).subscribe((restaurant) => {
            if (!this._redirecting && restaurant?.isBrandBusiness()) {
                this._redirecting = true;
                this._router.navigate(['/restaurants', restaurant._id, 'dashboard']);
            }
        });
    }
}
