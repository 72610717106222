import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidYoutubeUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.YOUTUBE })) {
            return false;
        }

        // Try to extract youtube username
        const userName = getYoutubeUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getYoutubeUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = trimSlashes(parsedUrl.pathname); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters, dots, hyphens and underscores are allowed for youtube usernames
        // Starts with channel/
        const channelRegex = /^channel\/[-a-zA-Z0-9_\.]+$/;
        if (channelRegex.test(pathname)) {
            return pathname;
        }

        // Starts with user/ (legacy)
        const legacyUserRegex = /^user\/[-a-zA-Z0-9_\.]+$/;
        if (legacyUserRegex.test(pathname)) {
            return pathname;
        }

        // Starts with @
        const userRegex = /^@[-a-zA-Z0-9_\.]+$/;
        if (userRegex.test(pathname)) {
            return pathname;
        }

        return null;
    } catch {
        return null;
    }
}

export function transformYoutubeUrl(url: string): string | null {
    if (!isValidYoutubeUrl(url)) return null;
    const userName = getYoutubeUserName(url);
    return `https://www.youtube.com/${userName}`;
}
