<div class="malou-dialog__header !mb-0">
    <span class="malou-text-18--bold"> {{ 'notifications.title' | translate }} </span>

    <button class="malou-btn-icon" data-testid-="close-notifications-modal" mat-icon-button (click)="close()">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
    </button>
</div>
<div class="mb-2 !overflow-hidden">
    @for (notification of data.notifications(); track $index) {
        <app-notification-item [notification]="notification.id" [receptionDate]="notification.date"></app-notification-item>
    }
</div>
