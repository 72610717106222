<div class="flex flex-col gap-4">
    <div class="malou-text-14--bold text-malou-color-text-1">
        {{ 'informations.optimization' | translate }}
    </div>

    <div class="w-[70%] sm:w-full">
        <app-loader-progress
            backgroundColor="bg-malou-color-background-white"
            [progressBarColorClass]="progressBarColorClass()"
            [progress]="scoreGauge"></app-loader-progress>
        @if (scoreGauge() === 100) {
            <div class="malou-text-10--regular mt-2 italic text-malou-color-text-2">
                {{ 'informations.gauge.completed' | translate }}
            </div>
        } @else {
            <div class="mt-2 flex flex-col items-start gap-1">
                @if (ctaText().subtitle; as subtitle) {
                    <div class="malou-text-10--regular italic text-malou-color-text-2">{{ subtitle }}</div>
                }
                <button
                    class="malou-btn-flat !malou-text-10--semibold"
                    id="tracking_informations_gauge_link"
                    (click)="openInformationsModal()">
                    {{ ctaText().title }}
                </button>
            </div>
        }
    </div>
</div>
