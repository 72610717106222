import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { HeapService } from ':core/services/heap.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';

interface RouterListenerOptions {
    event2Class: new (...args: any[]) => any;
    matchUrl: string;
    callback: () => void;
}

/**
 * This service is used to listen to router events and execute a side effect when a specific event is triggered
 */
@Injectable({ providedIn: 'root' })
export class RouterListenerService {
    optionsArray: RouterListenerOptions[] = [
        {
            event2Class: NavigationEnd,
            matchUrl: '/groups',
            callback: this._sendHeapProperties,
        },
    ];

    initialized = false;

    constructor(
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _heapService: HeapService
    ) {}

    init(): void {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        this._router.events.subscribe((event) => {
            this.optionsArray.forEach((options) => {
                if (event instanceof options.event2Class && this._router.url.includes(options.matchUrl)) {
                    options.callback.call(this);
                }
            });
        });
    }

    private _sendHeapProperties(): void {
        this._store
            .select(selectUserInfos)
            .pipe(take(1))
            .subscribe((currentUser: User) => {
                this._heapService.addEventProperties({
                    userId: currentUser._id,
                    userName: currentUser.name,
                    userRestaurantsCount: currentUser.restaurants.length,
                    organisations: currentUser.organizations.map((org) => org.name).join(', '),
                    role: currentUser.role,
                    userCaslRole: currentUser.caslRole,
                    userEmail: currentUser.email,
                    mobileAppSession: false,
                    allBusinesses: true,
                });
            });
    }
}
