import { AfterViewInit, Directive, DoCheck, Host, Optional, Renderer2, Self, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

// https://github.com/AzizStark/angular-custom-material-paginator/blob/master/src/app/pagination.directive.ts
@Directive({
    selector: '[appPagesRangePaginator]',
    standalone: true,
})
export class PaginatorPagesRangeDirective implements DoCheck, AfterViewInit {
    currentPage: number;
    pageGapTxt: string[];
    rangeStart: number;
    rangeEnd: number;
    buttons: MatButton[] = [];
    showTotalPages: number;
    checkPage: number[];

    constructor(
        @Host() @Self() @Optional() private readonly _matPag: MatPaginator,
        private readonly _viewContainer: ViewContainerRef,
        private readonly _renderer: Renderer2
    ) {
        this.currentPage = 1;
        this.pageGapTxt = ['•••', '---'];
        this.showTotalPages = 3;
        this.checkPage = [0, 0, 0];
        // Subscribe to rerender buttons when next page and last page button is used
        this._matPag.page.subscribe((paginator: PageEvent) => {
            this.currentPage = paginator.pageIndex;
            this._matPag.pageIndex = paginator.pageIndex;
            this._initPageRange();
        });
    }

    ngDoCheck(): void {
        // Reset paginator if the pageSize, pageIndex, length changes
        if (this._isPageChanging()) {
            const pageCount = this._matPag.getNumberOfPages();
            if (this.currentPage > pageCount && pageCount !== 0) {
                this.currentPage = 1;
                this._matPag.pageIndex = 0;
            }
            this.currentPage = this._matPag.pageIndex;
            this._initPageRange();
            this.checkPage = [this._matPag.length, this._matPag.pageSize, this._matPag.pageIndex];
        }
    }

    ngAfterViewInit(): void {
        this.rangeStart = 0;
        this.rangeEnd = this.showTotalPages - 1;
        this._initPageRange();
    }

    private _isPageChanging(): boolean {
        return (
            this._matPag?.length !== this.checkPage[0] ||
            this._matPag?.pageSize !== this.checkPage[1] ||
            this._matPag?.pageIndex !== this.checkPage[2]
        );
    }

    private _buildPageNumbers(): void {
        const dots = [false, false];
        const totalPages = this._matPag.getNumberOfPages();
        // Container div with paginator elements
        const actionContainer = this._viewContainer.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
        // Button that triggers the next page action
        const nextPageNode = this._viewContainer.element.nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');
        // Label showing the page range
        const pageRange = this._viewContainer.element.nativeElement.querySelector('div.mat-mdc-paginator-range-label');
        // Page size selector
        const pageSize = this._viewContainer.element.nativeElement.querySelector('mat-form-field.mat-mdc-paginator-page-size-select');

        let prevButtonCount = this.buttons.length;

        // Remove buttons before creating new ones
        if (prevButtonCount > 0) {
            this.buttons.forEach((button) => {
                this._renderer.removeChild(actionContainer, button);
            });
            // Empty state array
            prevButtonCount = 0;
        }

        if (pageRange) {
            this._renderer.addClass(pageRange, 'custom-paginator-counter');
        }
        if (actionContainer) {
            this._renderer.addClass(actionContainer, 'custom-paginator-container');
        }
        if (pageSize) {
            this._renderer.addClass(pageSize, 'custom-malou-filled-form-field--no-border');
        }

        if (totalPages > 0) {
            this._renderer.insertBefore(actionContainer, this._createButton('0', this._matPag.pageIndex), nextPageNode);
        }

        const page = this.showTotalPages + 2;
        const pageDifference = totalPages - page;
        const startIndex = Math.max(this.currentPage - this.showTotalPages - 2, 1);

        for (let index = startIndex; index < totalPages - 1; index++) {
            if (
                (index < page && this.currentPage <= this.showTotalPages) ||
                (index >= this.rangeStart && index <= this.rangeEnd) ||
                (this.currentPage > pageDifference && index >= pageDifference) ||
                totalPages < this.showTotalPages + page
            ) {
                this._renderer.insertBefore(actionContainer, this._createButton(`${index}`, this._matPag.pageIndex), nextPageNode);
            } else {
                if (index > this.rangeEnd && !dots[0]) {
                    this._renderer.insertBefore(
                        actionContainer,
                        this._createButton(this.pageGapTxt[0], this._matPag.pageIndex),
                        nextPageNode
                    );
                    dots[0] = true;
                    break;
                }
                if (index < this.rangeEnd && !dots[1]) {
                    this._renderer.insertBefore(
                        actionContainer,
                        this._createButton(this.pageGapTxt[1], this._matPag.pageIndex),
                        nextPageNode
                    );
                    dots[1] = true;
                }
            }
        }

        if (totalPages > 1) {
            this._renderer.insertBefore(actionContainer, this._createButton(`${totalPages - 1}`, this._matPag.pageIndex), nextPageNode);
        }
    }

    private _createButton(index: string, pageIndex: number): MatButton {
        const linkBtn: MatButton = this._renderer.createElement('button');
        this._renderer.setAttribute(linkBtn, 'class', 'custom-paginator-page');
        this._renderer.addClass(linkBtn, 'custom-paginator-page-enabled');
        if (index === this.pageGapTxt[0] || index === this.pageGapTxt[1]) {
            this._renderer.addClass(linkBtn, 'custom-paginator-arrow-enabled');
        }
        const pagingTxt = isNaN(+index) ? this.pageGapTxt[0] : +index + 1;

        const span = this._renderer.createElement('span');
        const text = this._renderer.createText(pagingTxt + '');

        this._renderer.appendChild(span, text);

        this._renderer.addClass(linkBtn, 'mat-custom-page');
        switch (index) {
            case `${pageIndex}`:
                this._renderer.setAttribute(linkBtn, 'disabled', 'disabled');
                this._renderer.removeClass(linkBtn, 'custom-paginator-page-enabled');
                this._renderer.addClass(linkBtn, 'custom-paginator-page-disabled');
                break;
            case this.pageGapTxt[0]:
                this._renderer.listen(linkBtn, 'click', () => {
                    this._switchPage(
                        this.currentPage < this.showTotalPages + 1 ? this.showTotalPages + 2 : this.currentPage + this.showTotalPages - 1
                    );
                });
                break;
            case this.pageGapTxt[1]:
                this._renderer.listen(linkBtn, 'click', () => {
                    this._switchPage(
                        this.currentPage > this._matPag.getNumberOfPages() - this.showTotalPages - 2
                            ? this._matPag.getNumberOfPages() - this.showTotalPages - 3
                            : this.currentPage - this.showTotalPages + 1
                    );
                });
                break;
            default:
                this._renderer.listen(linkBtn, 'click', () => {
                    this._switchPage(+index);
                });
                break;
        }
        this._renderer.appendChild(linkBtn, span);
        // Add button to private array for state
        this.buttons.push(linkBtn);
        return linkBtn;
    }

    /**
     * @description calculates the button range based on class input parameters and based on current page index value.
     */
    private _initPageRange(): void {
        this.rangeStart = this.currentPage - this.showTotalPages / 2;
        this.rangeEnd = this.currentPage + this.showTotalPages / 2;
        this._buildPageNumbers();
    }

    private _switchPage(index: number): void {
        this._matPag.pageIndex = index;
        this._matPag.page.emit({
            previousPageIndex: this.currentPage,
            pageIndex: index,
            pageSize: this._matPag.pageSize,
            length: this._matPag.length,
        });
        this.currentPage = index;
        this._initPageRange();
    }
}
