import { ChecklistDto } from '@malou-io/package-dto';
import { ChecklistTaskName, ChecklistType } from '@malou-io/package-utils';

export interface ChecklistTask {
    name: ChecklistTaskName;
    completed: boolean;
}

export class Checklist {
    id: string;
    restaurantId: string;
    tasks: ChecklistTask[];
    type: ChecklistType;
    isDisplayed: boolean;

    public constructor(init: Exclude<ChecklistDto, null>) {
        this.id = init.id;
        this.restaurantId = init.restaurantId;
        this.tasks = init.tasks;
        this.type = init.type;
        this.isDisplayed = init.isDisplayed;
    }

    isCompleted(): boolean {
        return this.tasks.every((task) => task.completed);
    }
}
