import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Credential, Platform } from ':shared/models';

@Injectable({ providedIn: 'root' })
export class ConnectMapstrBasicService {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _platformsService = inject(PlatformsService);
    private readonly _credentialsService = inject(CredentialsService);

    execute(platform: Platform | undefined): Observable<Platform> {
        const restaurant = this._restaurantsService.currentRestaurant;
        const platformToUpsert = {
            key: PlatformKey.MAPSTR,
            restaurantId: restaurant.id,
            socialId: restaurant.placeId,
            credentials: [],
        };

        const platformUpsertRes$ = this._platformsService.upsert(restaurant._id, platformToUpsert);

        const credential = platform?.credentials?.[0] as Credential | undefined;
        // If it was connected as premium, delete the credential
        const deleteCredentialRes$ = credential ? this._credentialsService.delete(credential._id) : of(undefined);

        return forkJoin([platformUpsertRes$, deleteCredentialRes$]).pipe(map(([platformRes, _]) => platformRes.data));
    }
}
