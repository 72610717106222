/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { SuccessResponse, UserFiltersResponseDto, UserRestaurantFiltersResponseDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { AggregatedUserFilters, UserRestaurantFilters } from ':shared/models/user-filters.model';

export interface RoiInsightsCreationState {
    wasLastResultSeen: boolean;
    creationStartDate: Date | null;
    creationEstimatedTime: number;
}

@Injectable({
    providedIn: 'root',
})
export class UserFiltersService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/user-filters`;

    private readonly _http = inject(HttpClient);

    getAggregatedUserFilters(userId: string): Observable<AggregatedUserFilters> {
        return this._http
            .get<ApiResultV2<UserFiltersResponseDto>>(`${this.API_BASE_URL}/${userId}`)
            .pipe(map((res) => new AggregatedUserFilters(res.data)));
    }

    getUserRestaurantFilters(userId: string, restaurantId): Observable<UserRestaurantFilters> {
        return this._http
            .get<ApiResultV2<UserRestaurantFiltersResponseDto>>(`${this.API_BASE_URL}/${userId}/${restaurantId}`)
            .pipe(map((res) => new UserRestaurantFilters(res.data)));
    }

    updateAggregatedStatisticsFilters(
        userId: string,
        filters: AggregatedUserFilters['aggregatedStatisticsFilters']
    ): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/aggregated-statistics/${userId}`, filters)
            .pipe(map((res) => res.data));
    }

    updateAggregatedReviewsFilters(userId: string, data: AggregatedUserFilters['aggregatedReviewsFilters']): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/aggregated-reviews/${userId}`, data)
            .pipe(map((res) => res.data));
    }

    updateUserRestaurantStatisticsFilters(
        userId: string,
        restaurantId: string,
        data: UserRestaurantFilters['statisticsFilters']
    ): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/statistics/${userId}/${restaurantId}`, data)
            .pipe(map((res) => res.data));
    }

    updateUserRestaurantReviewsFilters(
        userId: string,
        restaurantId: string,
        data: UserRestaurantFilters['reviewsFilters']
    ): Observable<SuccessResponse> {
        return this._http
            .put<ApiResultV2<SuccessResponse>>(`${this.API_BASE_URL}/reviews/${userId}/${restaurantId}`, data)
            .pipe(map((res) => res.data));
    }
}
