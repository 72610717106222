import { z } from 'zod';

// !!! WARNING !!!
// if you make change to these validators, you must also make change to the interfaces
// Because on strict false, zod make all properties optional
// So when strict true, remove interfaces and infer type with zod

export interface PhoneDto {
    prefix?: number | null;
    digits?: number | null;
}

export const phoneDtoValidator = z.object({
    prefix: z.number().nullish(),
    digits: z.number().nullish(),
});
