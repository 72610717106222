import { Action, createReducer, on } from '@ngrx/store';

import { UserPermissionsByPlatformDto } from '@malou-io/package-dto';

import { User } from '../user';
import * as UserActions from './user.actions';

export interface UserState {
    infos: User | null;
    platformsPermissions: UserPermissionsByPlatformDto[];
}

export const initialState: UserState = {
    infos: null,
    platformsPermissions: [],
};

const _userReducer = createReducer(
    initialState,
    on(UserActions.editUserInfos, (state, { infos }) => ({
        ...state,
        infos,
    })),
    on(UserActions.editUserPlatformPermissions, (state, { key, permissions }) => {
        const alreadyHasPermissions = state.platformsPermissions.find((oldPermissions) => oldPermissions.key === key);
        const newPermissions = alreadyHasPermissions
            ? state.platformsPermissions.map((oldPermissions) => (oldPermissions.key === key ? { key, permissions } : oldPermissions))
            : [...state.platformsPermissions, { key, permissions }];
        return {
            ...state,
            platformsPermissions: newPermissions,
        };
    }),
    on(UserActions.removeSinglePlatformPermissions, (state, { restaurantId, key }) => ({
        ...state,
        platformsPermissions: state.platformsPermissions.map((platformPermission) => {
            if (platformPermission.key === key) {
                return {
                    ...platformPermission,
                    permissions: platformPermission.permissions.map((permission) =>
                        permission.restaurantId === restaurantId ? { restaurantId, isValid: false } : permission
                    ),
                };
            }
            return platformPermission;
        }),
    }))
);

export function reducer(state: UserState, action: Action): UserState {
    return _userReducer(state, action);
}
