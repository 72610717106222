import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ApiResult, HoursType } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class OtherHoursService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/hour-types`;

    constructor(private readonly _http: HttpClient) {}

    getHoursTypes(restaurantId: string, platformKey: PlatformKey): Observable<ApiResult<HoursType[]>> {
        return this._http
            .get<
                ApiResult<HoursType[]>
            >(`${this.API_BASE_URL}/${restaurantId}`, { params: { platform_key: platformKey }, withCredentials: true })
            .pipe(
                map((result) => {
                    result.data = result.data.map((hourType) => new HoursType(hourType));
                    return result;
                })
            );
    }
}
