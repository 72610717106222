import { SocialPostItemDto } from '@malou-io/package-dto';
import { PlatformKey, PostPublicationStatus, PostType, RemoveMethodsFromClass } from '@malou-io/package-utils';

import { Hashtag, Media, PostHashtags } from ':shared/models';

export type ISocialPostItem = RemoveMethodsFromClass<SocialPostItem> & { id: string };

export class SocialPostItem implements ISocialPostItem {
    id: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    postType: PostType;
    feedbackMessagesCount: number;
    plannedPublicationDate: Date;
    thumbnail: Media;
    hashtags?: PostHashtags;
    error?: PostError;
    socialLink?: string;
    socialCreatedAt?: Date;

    constructor(data: ISocialPostItem) {
        this.id = data.id;
        this.text = data.text;
        this.platformKeys = data.platformKeys;
        this.published = data.published;
        this.postType = data.postType;
        this.feedbackMessagesCount = data.feedbackMessagesCount;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.thumbnail = data.thumbnail;
        this.hashtags = data.hashtags;
        this.error = data.error;
        this.socialLink = data.socialLink;
        this.socialCreatedAt = data.socialCreatedAt;
    }

    static fromDto(dto: SocialPostItemDto): SocialPostItem {
        return new SocialPostItem({
            id: dto.id,
            text: dto.text,
            platformKeys: dto.platformKeys,
            published: dto.published,
            postType: dto.postType,
            feedbackMessagesCount: dto.feedbackMessagesCount,
            plannedPublicationDate: new Date(dto.plannedPublicationDate),
            thumbnail: new Media(dto.thumbnail),
            hashtags: dto.hashtags
                ? {
                      selected: dto.hashtags.selected.map((hashtag) => new Hashtag(hashtag)),
                      suggested: dto.hashtags.suggested.map((hashtag) => new Hashtag(hashtag)),
                  }
                : undefined,
            error: dto.error,
            socialLink: dto.socialLink,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
        });
    }

    getHashtagsText(): string {
        return this.hashtags ? this.hashtags.selected.map((hashtag) => hashtag.text).join(' ') : '';
    }
}

interface PostError {
    code: string;
    rawData: string;
}
