import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { Restaurant } from ':shared/models';

import * as RestaurantsActions from './restaurant-list.actions';

export interface RestaurantState {
    restaurants: Restaurant[];
}

export const initialState: RestaurantState = {
    restaurants: [],
};

const restaurantsReducer = createReducer(
    initialState,
    on(RestaurantsActions.editRestaurants, (state, { restaurants }) => ({ ...state, restaurants })),
    on(RestaurantsActions.removeRestaurantsByIds, (state, { restaurantIds }) => ({
        ...state,
        restaurants: state.restaurants.filter((rest) => !restaurantIds.includes(rest._id)),
    })),
    on(RestaurantsActions.editRestaurant, (state, { restaurant }) => ({
        ...state,
        restaurants: state.restaurants.map((rest) => (rest._id === restaurant._id ? restaurant : rest)),
    }))
);

export const selectOwnRestaurantsState = createFeatureSelector<RestaurantState>('restaurants');

export const selectOwnRestaurants = createSelector(selectOwnRestaurantsState, (state): Restaurant[] =>
    state.restaurants.map((rest) => new Restaurant(rest))
);

export function reducer(state: RestaurantState | undefined, action: Action): RestaurantState {
    return restaurantsReducer(state, action);
}
