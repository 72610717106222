import { Routes } from '@angular/router';

import { ReviewsComponent } from ':modules/reviews/reviews/reviews.component';

export const REVIEWS_ROUTES: Routes = [
    {
        path: '',
        component: ReviewsComponent,
    },
];
