import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { GoogleConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-google-modal/google-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { GetOauthUrlService } from ':modules/platforms/platforms-connection-modals/shared/services/get-oauth-url.service';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';

@Component({
    selector: 'app-google-connection-step-0',
    templateUrl: './google-connection-step-0.component.html',
    styleUrls: ['./google-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleConnectionStep0Component extends BaseStepComponent<undefined, GoogleConnectionModalResult> {
    readonly ButtonStyle = ButtonStyle;
    private readonly _getOauthUrlService = inject(GetOauthUrlService);

    constructor() {
        super();
        this._getOauthUrlService.execute(PlatformKey.GMB).subscribe((url) => {
            window.location.href = url;
        });
    }
}
