<div
    class="flex items-center rounded-[10px] px-4 py-3"
    [ngClass]="{
        'border border-malou-color-border-primary bg-malou-color-background-light': !isClosed(),
        'bg-malou-color-state-error--light/10': isClosed(),
    }">
    <div class="malou-color-text-1 malou-text-12--semibold" [ngClass]="{ 'min-w-[150px]': !largeTitle(), 'min-w-[40%]': largeTitle() }">
        <span>{{ dayOrDateTitle() }}</span>
        @if (showStartDate() && startDate()) {
            <span> - {{ startDate() }}</span>
        }
    </div>
    <div class="flex gap-x-2">
        @if (timePeriods(); as timePeriods) {
            <mat-icon class="!h-4" color="primary" [svgIcon]="isClosed() ? SvgIcon.CLOCK : SvgIcon.ALARM"></mat-icon>
            @if (isClosed()) {
                <span class="malou-text-12--regular">{{ 'information.business_hours.closed' | translate }}</span>
            } @else {
                <div class="flex flex-wrap gap-x-2">
                    @for (timePeriod of timePeriods; track timePeriod; let isLast = $last) {
                        @if (!!timePeriod) {
                            <span class="malou-text-12--regular whitespace-pre break-keep">
                                {{
                                    timePeriod.openTime === '00:00' && timePeriod.closeTime === '24:00'
                                        ? ('common.open_24h' | translate)
                                        : ((timePeriod.openTime === '24:00' ? '00:00' : (timePeriod.openTime ?? '')) | formatTime) +
                                          '-' +
                                          ((timePeriod.closeTime === '24:00' ? '00:00' : (timePeriod.closeTime ?? '')) | formatTime)
                                }}
                            </span>
                        } @else {
                            <span class="malou-color-text-2 malou-text-12--regular">
                                {{ 'information.business_hours.not_specified' | translate }}
                            </span>
                        }
                    }
                </div>
            }
        } @else {
            <span class="malou-color-text-2 malou-text-12--regular"> {{ 'information.business_hours.not_specified' | translate }}</span>
        }
    </div>
</div>
