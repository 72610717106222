import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'getEaterTotalOrders',
    standalone: true,
})
export class GetEaterTotalOrdersPipe implements PipeTransform {
    transform(review: Review | PrivateReview): number {
        return review.getEaterTotalOrders() ?? 0;
    }
}
