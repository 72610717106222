<div class="malou-dialog">
    <div class="malou-dialog__header">
        <span class="malou-text-18--bold">{{ 'roi.saved_time.hours_modal_title' | translate }}</span>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-8 mt-5 flex max-h-[65vh] flex-col gap-3 overflow-y-scroll">
        @for (row of savedTimeRows$ | async; track $index) {
            <app-saved-time-item [item]="row"> </app-saved-time-item>
        }
    </div>
</div>
