@if (!isParentError()) {
    @if (!isParentLoading()) {
        <div class="malou-simple-card flex h-full flex-col justify-between px-6 py-3 md:px-2">
            <div>
                <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            </div>
            @if (hasData()) {
                <div>
                    <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="cardTemplate"></ng-container>
                </div>
            } @else {
                <div class="flex h-[442px] flex-col items-center justify-center gap-3 py-6">
                    <img class="mb-6 h-40" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
                    <div class="malou-text-14--bold malou-color-text-1 mb-2">{{ 'statistics.errors.no_result' | translate }}</div>
                    <div class="malou-text-10--regular malou-color-text-2">{{ 'statistics.errors.change_period' | translate }}</div>
                </div>
            }
        </div>
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #titleTemplate>
    <div class="flex flex-wrap items-center">
        <span class="malou-text-section-title malou-color-text-1 flex h-[50px] items-center">{{
            'statistics.totems.estimated_review_count.estimated_review_count' | translate
        }}</span>
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-statistics-totems-estimated-review-count-chart
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        [reviewsCountData]="reviewsCountData()"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-statistics-totems-estimated-review-count-chart>
</ng-template>

<ng-template #cardTemplate>
    <div class="flex">
        <div class="malou-simple-card--light mr-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ estimatedReviewCountOnPeriod() | shortNumber }}
                </div>
                @if (estimatedReviewCountOnPeriod()) {
                    <app-number-evolution
                        [value]="estimatedReviewCountDifferenceWithPreviousPeriod()"
                        [displayedValue]="estimatedReviewCountDifferenceWithPreviousPeriod() | shortNumber"></app-number-evolution>
                }
            </div>
            <div class="flex">
                <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                    {{ 'statistics.totems.estimated_review_count.estimated_review' | translate }}
                </div>
            </div>
        </div>
        <div class="malou-simple-card--light ml-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ estimatedWofReviewCountOnPeriod() | shortNumber }}
                </div>
                @if (estimatedWofReviewCountOnPeriod()) {
                    <app-number-evolution
                        [value]="estimatedWofReviewCountDifferenceWithPreviousPeriod()"
                        [displayedValue]="estimatedWofReviewCountDifferenceWithPreviousPeriod() | shortNumber"></app-number-evolution>
                }
            </div>
            <div class="flex">
                <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                    {{ 'statistics.totems.estimated_review_count.estimated_review_wheel_of_fortune' | translate }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
