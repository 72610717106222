import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual, uniqBy } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import {
    errorReplacer,
    isNotNil,
    PlatformDefinition,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    PostType,
    Role,
} from '@malou-io/package-utils';

import { periodOptions } from ':core/constants';
import { PostCategory } from ':core/constants/post-category';
import { DialogService } from ':core/services/dialog.service';
import { DuplicatePostModalService } from ':core/services/duplicate-post-modal.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { PostDuplication } from ':modules/posts/posts-list/posts-list.component';
import { defaultPublicationStatus } from ':modules/posts/posts.reducer';
import { selectCurrentlyPublishingPosts } from ':modules/posts/posts.selectors';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { IsPublishedFiltersComponent } from ':shared/components/is-published-filters/is-published-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    PersonalizePostDuplicationComponent,
    PersonalizePostDuplicationData,
} from ':shared/components/personalize-post-duplication/personalize-post-duplication.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { PostSkeletonComponent } from ':shared/components/skeleton/templates/post-skeleton/post-skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { addMinutes, getTimeStringFromDate, isControlValueInTimeFormat } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Step } from ':shared/interfaces/step.interface';
import {
    ApiResult,
    AvailablePlatform,
    MalouDateFilters,
    MalouPeriod,
    Pagination,
    Platform,
    Post,
    PostsFilters,
    PostWithJob,
    Restaurant,
    SocialPost,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';
import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

import { PostDateStatus } from '../new-social-post-modal/context/types';
import { NewSocialPostModalComponent } from '../new-social-post-modal/new-social-post-modal.component';
import { SocialPostsFeedComponent } from '../social-posts-feed/social-posts-feed.component';
import * as SocialPostsActions from '../social-posts.actions';
import { SocialPostsService } from '../social-posts.service';
import { SocialPostComponent } from './social-post/social-post.component';

interface PlatformsStore {
    platformsData: {
        [key: string]: Platform[];
    };
    platformsKeys: {
        [key: string]: PlatformDefinition;
    };
}

const DEFAULT_PAGINATION = { pageSize: 24, pageNumber: 0, total: 0 };
const DEFAULT_PERIOD = MalouPeriod.LAST_AND_COMING_SIX_MONTH;
const DEFAULT_PAGE_EVENT = { pageIndex: 0, pageSize: 24, length: 0 };

@Component({
    selector: 'app-social-posts-list',
    templateUrl: './social-posts-list.component.html',
    styleUrls: ['./social-posts-list.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        InfiniteScrollModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        MatTabsModule,
        MatTooltipModule,
        MatButtonToggleModule,
        GroupedDateFiltersComponent,
        IsPublishedFiltersComponent,
        PostSkeletonComponent,
        SearchComponent,
        SocialPostComponent,
        SocialPostsFeedComponent,
        ApplyPurePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
        PlatformLogoComponent,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostsListComponent implements OnInit {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _dialogService = inject(DialogService);
    private readonly _duplicatePostModalService = inject(DuplicatePostModalService);
    private readonly _postsService = inject(PostsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _socialPostsService = inject(SocialPostsService);
    private readonly _spinnerService = inject(SpinnerService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);
    private readonly _heapEmailEventsTrackerService = inject(HeapEmailEventsTrackerService);

    readonly SvgIcon = SvgIcon;
    readonly socialPlatformKeys = PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts();
    readonly trackByIdFn = TrackByFunctionFactory.get('id');
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    readonly PlatformKey = PlatformKey;
    readonly DuplicationDestination = DuplicationDestination;
    readonly PostType = PostType;

    readonly posts: WritableSignal<PostWithJob[]> = signal([]);

    readonly filters$: Observable<PostsFilters> = this._store
        .select((state) => state.socialposts.socialFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    readonly filters = toSignal(this.filters$);
    readonly activeFiltersCount = computed(() => {
        const filters = this.filters();
        if (filters) {
            return this._computeFilterCount(filters);
        }
        return 0;
    });

    readonly pageEvent$: BehaviorSubject<PageEvent> = new BehaviorSubject(DEFAULT_PAGE_EVENT);

    readonly restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    readonly restaurant = toSignal(this.restaurant$, { initialValue: this._restaurantsService.currentRestaurant });

    readonly period$: Observable<MalouPeriod> = this._store.select(
        (state) => state.socialposts.socialFilters.period
    ) as Observable<MalouPeriod>;
    readonly start$: Observable<Date> = this._store.select((state) => state.socialposts.socialFilters.startDate) as Observable<Date>;
    readonly end$: Observable<Date> = this._store.select((state) => state.socialposts.socialFilters.endDate) as Observable<Date>;

    readonly postFilterStatus$: Observable<string[]> = this._store
        .select((state) => state.socialposts.socialFilters.publicationStatus)
        .pipe(map((publicationStatus) => publicationStatus ?? []));
    readonly postFilterStatus = toSignal(this.postFilterStatus$, { initialValue: [] });

    readonly PERIOD_OPTIONS = periodOptions.map((period) => period.key);
    readonly textFilter$ = new BehaviorSubject('');

    readonly platformsStatus$ = new BehaviorSubject({});

    readonly selectedPosts: WritableSignal<PostWithJob[]> = signal([]);
    readonly selectedPostsLength = computed(() => this.selectedPosts().length);
    // META bug : reels with copyrighted audio don't have media_url
    // https://developers.facebook.com/support/bugs/404495981650858/
    readonly selectedPostsForDuplication = computed(() => this.selectedPosts().filter((p) => !p.isInstagramReelWithoutVideo()));
    readonly selectedPostsForDuplicationLength = computed(() => this.selectedPostsForDuplication().length);

    readonly loading = signal(true);
    readonly shouldDisplayNoMoreResultsText = signal(false);
    readonly platformsStore$: Observable<PlatformsStore> = this._store.select((state) => state.platforms);
    readonly availablePlatforms$: BehaviorSubject<AvailablePlatform[]> = new BehaviorSubject([]);
    readonly availablePlatforms = toSignal(this.availablePlatforms$, { initialValue: this.availablePlatforms$.value });
    readonly isAnyPlatformConnected = computed(() => this.availablePlatforms().some((platform) => platform.connected));
    readonly selectedIndex = signal(0);

    readonly moreOptionsToggled = signal(false);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly currentUser$: Observable<UserState> = this._store.select('user');
    private readonly _currentUser: Signal<UserState | undefined> = toSignal(this.currentUser$);

    private _pagination: Pagination = DEFAULT_PAGINATION;
    private _hasSynced = false;
    private _loadedMoreWithPagination = false;

    ngOnInit(): void {
        this._heapEmailEventsTrackerService.trackEmailEvent();
        this.getAvailablePlatforms$().subscribe((platforms) => {
            this.availablePlatforms$.next(platforms);
        });

        this.restaurant$
            .pipe(
                switchMap((restaurant) => this._restaurantsService.show(restaurant._id).pipe(map((res) => res.data))),
                switchMap((restaurantWithManagers) => {
                    const restaurantManagers = restaurantWithManagers?.managers.map((ru) => new User(ru.user));
                    return this._activatedRoute.queryParams.pipe(
                        filter((params) => !!params.postId),
                        map((params) => params.postId),
                        switchMap((postId) =>
                            this._postsService.getPost(postId).pipe(
                                catchError((err): any => {
                                    if (err.status === 404) {
                                        this._toastService.openErrorToast(
                                            this._translateService.instant('posts.posts_list.post_not_found')
                                        );
                                        this._router.navigate(['./'], {
                                            relativeTo: this._activatedRoute,
                                            queryParams: null,
                                        });
                                        return of(null);
                                    }
                                }),
                                filter((res) => !!res),
                                map((res: ApiResult<Post>) => new Post(res.data))
                            )
                        ),
                        switchMap((post): Observable<any> => {
                            if (post) {
                                post.filterOutFeedbackMessageDependingOnRole(this._currentUser()?.infos?.role || Role.MALOU_BASIC);
                                return this._customDialogService
                                    .open(
                                        NewSocialPostModalComponent,
                                        {
                                            width: '100%',
                                            height: undefined,
                                            panelClass: 'malou-dialog-panel--full',
                                            data: {
                                                postId: post.id,
                                                post: post,
                                                isDisabled: post.published === PostPublicationStatus.PUBLISHED,
                                                allPostIds: null,
                                                restaurantManagers,
                                            },
                                        },
                                        { animateScreenSize: DialogScreenSize.ALL }
                                    )
                                    .afterClosed()
                                    .pipe(
                                        switchMap(({ next, shouldDeleteAfterClose }) => {
                                            this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                                            if (shouldDeleteAfterClose && !next.savedAsDraft) {
                                                this._socialPostsService.deletePost([post]);
                                                return this._postsService.deletePost(post.id);
                                            }
                                            if (next?.reload) {
                                                this.emptyPostsListAndShowLoader();
                                                this.pageEvent$.next(DEFAULT_PAGE_EVENT);
                                                this._socialPostsService.reloadFeed();
                                                return EMPTY;
                                            }
                                            if (next?.duplicatedPostId) {
                                                this._duplicatePostModalService.openDuplicateModal(
                                                    next?.duplicatedPostId,
                                                    PostCategory.SEO
                                                );
                                                return EMPTY;
                                            }
                                            return this._postsService.getPost(post.id, true).pipe(
                                                map((res) => new SocialPost(res.data)),
                                                tap((socialPost) => this._socialPostsService.editPosts([socialPost]))
                                            );
                                        }),
                                        takeUntilDestroyed(this._destroyRef)
                                    );
                            }
                            return EMPTY;
                        })
                    );
                })
            )
            .subscribe({
                error: (err) => console.warn('err :>> ', err),
            });

        this.restaurant$
            .pipe(
                tap(() => {
                    this.emptyPostsListAndShowLoader();
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: () => {
                    this.pageEvent$.next(DEFAULT_PAGE_EVENT);
                },
                error: (err) => console.warn('err :>> ', err),
            });

        this.filters$.pipe().subscribe(() => {
            this.emptyPostsListAndShowLoader();
            this.pageEvent$.next(DEFAULT_PAGE_EVENT);
        });

        this._store
            .select((state) => state.socialposts.postsSync)
            .pipe(filter((sync) => sync.completed))
            .subscribe(() => {
                this.emptyPostsListAndShowLoader();
                this.pageEvent$.next(DEFAULT_PAGE_EVENT);
            });

        this.textFilter$.pipe(filter((t) => t.length === 0 || t.length >= 3)).subscribe(() => {
            this.emptyPostsListAndShowLoader();
            this._loadedMoreWithPagination = false;
            this.shouldDisplayNoMoreResultsText.set(false);
            this.pageEvent$.next(DEFAULT_PAGE_EVENT);
        });

        this.pageEvent$
            .pipe(
                tap(() => {
                    this._loadedMoreWithPagination = true;
                })
            )
            .pipe(
                switchMap((pageEvent) => {
                    this.shouldDisplayNoMoreResultsText.set(false);
                    this._pagination = {
                        ...this._pagination,
                        pageNumber: pageEvent.pageIndex,
                        pageSize: pageEvent.pageSize,
                    };
                    return this._postsService
                        .getRestaurantPostsPaginated(this.restaurant()._id, this._pagination, {
                            ...this.filters(),
                            text: this.textFilter$.value,
                            category: PostSource.SOCIAL,
                            source: PostSource.SOCIAL,
                            isStory: false,
                        })
                        .pipe(map((res) => res.data));
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ({ posts, pagination, jobs }) => {
                    if (posts.length === 0 && this._loadedMoreWithPagination) {
                        this.shouldDisplayNoMoreResultsText.set(true);
                    }
                    this.posts.update((currentPosts) =>
                        uniqBy(
                            [
                                ...currentPosts,
                                ...posts.map(
                                    (p) =>
                                        new PostWithJob({
                                            ...p,
                                            job: (jobs || []).filter((j) => j.data?.postId === p.id)[0],
                                        })
                                ),
                            ],
                            'id'
                        )
                    );
                    this._pagination = pagination;
                    const isFilterEquivalentToHideEverything = !this.filters()?.publicationStatus?.length;
                    if (
                        this.posts().length <= 0 &&
                        !this._hasSynced &&
                        this.textFilter$.value === '' &&
                        !isFilterEquivalentToHideEverything
                    ) {
                        this._hasSynced = true;
                        this.synchronize();
                    }
                    this.loading.set(false);
                },
                error: (err) => {
                    this.loading.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });

        this._store
            .select(selectCurrentlyPublishingPosts)
            .pipe(
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (newPosts) => {
                    const newSocialPosts = newPosts.map((p) => new SocialPost(p));
                    this._socialPostsService.editPosts(newSocialPosts);
                    const setupPosts = this.posts().filter((p) => p.bindingId === newPosts?.[0].bindingId && !p.key);
                    this._socialPostsService.deletePost(setupPosts);
                },
            });

        // effects
        this._onPostDeleted();
        this._onPostsDuplicated();
        this._onPostEdited();
    }

    onRefreshPostData(post: Partial<PostWithJob>): void {
        this.posts.update((currentPosts) =>
            currentPosts.map((p) => {
                if (p.id === post.id) {
                    return new PostWithJob({ ...p, ...post });
                }
                return p;
            })
        );
    }

    emptyPostsListAndShowLoader(): void {
        this.posts.set([]);
        this.loading.set(true);
    }

    onScrollDown(): void {
        this.onPageEvent({
            pageIndex: this._pagination.pageNumber + 1,
            pageSize: this._pagination.pageSize,
            length: this._pagination.total,
        });
    }

    onEditPost(postId: string): void {
        this._router.navigate(['./'], { queryParams: { postId }, relativeTo: this._activatedRoute });
    }

    onDeletePost(postId: string): void {
        this.selectedPosts.set(this.posts().filter((post) => post.id === postId));
        this.deleteSelectedPosts();
    }

    toggleSelectedPlatforms(platform: string, currentPlatforms: AvailablePlatform[]): void {
        const platforms = currentPlatforms.map((p) => {
            if (p.key === platform) {
                p.checked = !p.checked;
            }
            return p;
        });

        this.availablePlatforms$.next(platforms);
        this._store.dispatch({
            type: SocialPostsActions.editSocialPostsFiltersPlatforms.type,
            platforms: platforms.filter((p) => p.checked).map((p) => p.key),
        });
    }

    toggleMoreOptionsToggled = (): void => {
        this.moreOptionsToggled.update((currentMoreOptionsToggled) => !currentMoreOptionsToggled);
    };

    onPageEvent($event: PageEvent): void {
        this.pageEvent$.next($event);
    }

    editTextFilter(searchText: string): void {
        this.textFilter$.next(searchText);
    }

    clarifyError(error: string): string {
        if (typeof error === 'object') {
            error = JSON.stringify(error, errorReplacer);
        }
        if (!error) {
            return '';
        }
        if (error.match(/aspect ratio/)) {
            return this._translateService.instant('social_posts.ratio_error');
        }
        if (error.match(/session has been invalidated/)) {
            return this._translateService.instant('social_posts.reconnect_platform');
        }
        if (error.match(/An unknown error occurred/)) {
            return this._translateService.instant('social_posts.facebook_error');
        }
        return error;
    }

    editPostsFilters($event: { [key: string]: any }): void {
        this._store.dispatch({ type: SocialPostsActions.editSocialPostsFilters.type, filters: $event });
    }

    resetFilters(): void {
        this._store.dispatch({ type: SocialPostsActions.resetSocialPostsFilters.type });
    }

    isChecked = (post: Post): boolean => this.selectedPosts().some((p) => p.id === post.id);

    duplicateSelectedPosts(to: string, platformsKeys: PlatformKey[]): void {
        return this._prepareDuplicatePosts({
            isSinglePost: false,
            to,
            postsToDuplicate: this.selectedPostsForDuplication(),
            platformsKeys,
            inBackground: false,
        });
    }

    duplicateSinglePost({
        to,
        post: fromPost,
        platformsKeys,
        inBackground,
    }: {
        to: string;
        post: PostWithJob;
        platformsKeys: PlatformKey[];
        inBackground: boolean;
    }): void {
        return this._prepareDuplicatePosts({
            isSinglePost: true,
            to,
            postsToDuplicate: [fromPost],
            platformsKeys,
            inBackground,
        });
    }

    getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.platformsStore$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([platforms, restaurant]) => !!restaurant?._id && !!platforms.platformsData[restaurant._id]),
            map(([platforms, restaurant]) => {
                const connectedPlatforms = platforms.platformsData[(restaurant as Restaurant)._id].map((plat) => plat.key);
                return this.socialPlatformKeys.map((p) => ({
                    key: p,
                    connected: connectedPlatforms.includes(p),
                    checked: true,
                }));
            }),
            catchError((err) => {
                console.warn('err :>> ', err);
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    synchronize(): void {
        const syncStatus = {};
        this._spinnerService.show();
        this._updatePostsSyncState({ loading: true, completed: false });
        this.getAvailablePlatforms$()
            .pipe(
                take(1),
                tap((platforms) => {
                    this.availablePlatforms$.next(platforms);
                    platforms.forEach((p) => {
                        if (p.connected) {
                            syncStatus[p.key] = { status: 'pending', lastTried: null, error: null };
                        }
                    });
                    this.platformsStatus$.next(syncStatus);
                }),
                switchMap((platforms) =>
                    forkJoin([
                        of(platforms),
                        this._postsService.synchronizePosts(
                            this.restaurant()._id,
                            platforms.filter((p) => p.connected).map((p) => p.key)
                        ),
                    ])
                ),
                switchMap(([platforms]) =>
                    forkJoin([
                        of(platforms),

                        this._restaurantsService.update(this.restaurant()._id, {
                            currentState: { ...this.restaurant().currentState, posts: { lastSync: new Date() } },
                        }),
                    ])
                )
            )
            .subscribe({
                next: ([platforms]) => {
                    platforms.forEach((p) => {
                        if (p.connected) {
                            syncStatus[p.key] = { status: 'success', lastTried: new Date(), error: null };
                        }
                    });
                    this.platformsStatus$.next(syncStatus);
                    this._updatePostsSyncState({ loading: false, completed: true });
                    this._spinnerService.hide();
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    if (
                        err.error?.message?.match(/does not have permission/) ||
                        err.error?.message?.match(/No access to this page/) ||
                        err.error?.message?.match(/Need credentialId/) ||
                        err.error?.message?.match(/not authorized application/) ||
                        err.error?.message?.match(/hasn't authorized/) ||
                        err.error?.message?.match(/pages_read_user_content/)
                    ) {
                        this._dialogService.open({
                            title: this._translateService.instant('common.unknown_error'),
                            message: this._translateService.instant('social_posts.reconnect_platform'),
                            variant: DialogVariant.INFO,
                            illustration: 'Cape',
                            primaryButton: {
                                label: this._translateService.instant('common.reconnect'),
                                action: () => {
                                    this._router.navigate(['/restaurants', this.restaurant()._id, 'settings', 'platforms', 'connection'], {
                                        relativeTo: this._activatedRoute,
                                    });
                                },
                            },
                            secondaryButton: {
                                label: this._translateService.instant('common.later'),
                            },
                        });
                    } else {
                        this._toastService.openErrorToast(this.clarifyError(err.error?.message));
                    }
                    this._updatePostsSyncState({ loading: false, completed: false });
                    this._spinnerService.hide();
                },
            });
    }

    openPost(postType: PostType = PostType.IMAGE): void {
        const malouDateFilters = new MalouDateFilters();
        const dateFilters = malouDateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_SIX_MONTH });
        this.editPostsFilters({
            ...dateFilters,
            publicationStatus: Array.from(new Set([...this.postFilterStatus(), PostPublicationStatus.DRAFT])),
        });
        this._postsService.createEmptySocialDraft$(this.restaurant()._id, { postType }).subscribe({
            next: ({ data: post }) => {
                this._router.navigate(['./'], { queryParams: { postId: post.id }, relativeTo: this._activatedRoute });
            },
            error: (err) => console.warn('err :>> ', err),
        });
    }

    hasVideoInSelection = (): boolean => this.selectedPosts().some((p) => p.attachments.some((a) => a?.isVideo()));

    postChecked(checked: boolean, post: PostWithJob): void {
        if (checked) {
            this.selectedPosts.update((currentSelectedPosts) => [...currentSelectedPosts, post]);
        } else {
            this.selectedPosts.update((currentSelectedPosts) => currentSelectedPosts.filter((p) => p.id !== post.id));
        }
    }

    deleteSelectedPosts(): void {
        // cannot delete posts on instagram
        this.selectedPosts.update((currentSelectedPosts) => currentSelectedPosts.filter((post) => this._canPostBeDeleted(post)));
        if (!this.selectedPosts().length) {
            this._toastService.openErrorToast(this._translateService.instant('social_posts.cannot_delete_ig_posts'));
            return;
        }
        this._dialogService.open({
            title: this._translateService.instant('social_posts.delete_posts'),
            message: this._translateService.instant('social_posts.want_delete_posts'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translateService.instant('common.delete'),
                action: () => {
                    this._socialPostsService.deletePost(this.selectedPosts());
                    this._postsService.deletePosts(this.selectedPosts().map((p) => p.id)).subscribe({
                        next: () => {
                            this.selectedPosts.set([]);
                            this._toastService.openSuccessToast(this._translateService.instant('social_posts.posts_deleted'));
                        },
                        error: (err) => {
                            console.warn('err :>> ', err);
                            this._toastService.openErrorToast(this._translateService.instant('social_posts.error_delete_post'));
                        },
                    });
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    private _updatePostsSyncState(postsSync: { loading?: boolean; completed?: boolean }): void {
        this._store.dispatch({
            type: SocialPostsActions.editHasSyncState.type,
            postsSync,
        });
    }

    private _getSortedPosts<T extends Post>(posts: T[]): T[] {
        return posts.sort((p1, p2) => p2.getPostDate().getTime() - p1.getPostDate().getTime());
    }

    private _canPostBeDeleted(post: PostWithJob): boolean {
        return post.published !== PostPublicationStatus.PUBLISHED || post.key !== PlatformKey.INSTAGRAM;
    }

    private _onPostDeleted(): void {
        this._socialPostsService.deletedPost$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((deletedPosts) => {
            deletedPosts.forEach((deletedPost) => {
                this.posts.update((currentPosts) => {
                    const postIdx = currentPosts.findIndex((post) => post.id === deletedPost.id);
                    if (postIdx > -1) {
                        currentPosts.splice(postIdx, 1);
                    }
                    return [...currentPosts];
                });
            });
        });
    }

    private _computeFilterCount(postsFilters: PostsFilters): number {
        const isDefaultPeriod = postsFilters.period === DEFAULT_PERIOD;
        const isDefaultStatuses = postsFilters.publicationStatus?.length === defaultPublicationStatus.length;
        const isDefaultPlatforms = postsFilters.platforms?.length === PlatformDefinitions.getSocialPlatformKeys().length;
        return [!isDefaultPeriod, !isDefaultStatuses, !isDefaultPlatforms].filter(Boolean).length;
    }

    private _onPostsDuplicated(): void {
        this._socialPostsService.duplicatePosts$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(({ posts: duplicatedPosts, destination }) => {
                if (destination === DuplicationDestination.HERE) {
                    duplicatedPosts.forEach((duplicate) => {
                        if (duplicate.keys.some((key) => this.socialPlatformKeys.includes(key))) {
                            this.posts.update((currentPosts) => [new SocialPost(duplicate), ...currentPosts]);
                        }
                    });
                }
            });
    }

    private _onPostEdited(): void {
        this._socialPostsService.editedPosts$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async (posts: SocialPost[]) => {
            this.posts.update((currentPosts) => {
                posts.forEach((post) => {
                    const postIdx = currentPosts.findIndex((p) => p.id === post.id);
                    if (postIdx > -1) {
                        currentPosts[postIdx] = new SocialPost(post);
                    } else {
                        const bindingIdIndex = currentPosts.findIndex((p) => p.bindingId === post.bindingId && p.key === post.key);
                        if (bindingIdIndex !== -1) {
                            currentPosts[bindingIdIndex] = new SocialPost(post);
                        } else {
                            currentPosts.unshift(post as PostWithJob);
                        }
                    }
                });
                const sortedPosts = this._getSortedPosts([...currentPosts]);
                return sortedPosts;
            });
        });
    }

    private _prepareDuplicatePosts({ isSinglePost, to, postsToDuplicate, platformsKeys, inBackground }: PostDuplication): void {
        if (to === DuplicationDestination.HERE) {
            return this._duplicatePostsHere(postsToDuplicate, platformsKeys, inBackground);
        }

        const steps = this._getStepsForDuplication(postsToDuplicate, platformsKeys, isSinglePost, inBackground);

        const initialData: RestaurantsSelectionData | undefined = isSinglePost
            ? {
                  skipOwnRestaurant: false,
                  withoutBrandBusiness: false,
                  selectedRestaurants: [],
                  hasPlatform: platformsKeys,
                  disableRestaurantWithoutMapstrPremium: postsToDuplicate.some((post) => post.keys.includes(PlatformKey.MAPSTR)),
              }
            : undefined;

        this._customDialogService.open(StepperModalComponent, {
            width: isSinglePost ? '900px' : '600px',
            height: 'unset',
            data: {
                steps,
                initialData,
                title: this._translateService.instant('duplicate_to_restaurants_dialog.title'),
                onSuccess: (posts: Post[]) => {
                    this._onDuplicationSuccess({
                        posts,
                        inBackground,
                        duplicateOutside: true,
                    });
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error, inBackground);
                },
                shouldDisplayConfirmationCloseModalAfterClosed: true,
            },
        });
    }

    private _onDuplicationSuccess = ({
        posts,
        inBackground,
        duplicateOutside,
    }: {
        posts: Post[];
        inBackground: boolean;
        duplicateOutside: boolean;
    }): void => {
        if (!inBackground) {
            this._spinnerService.hide();
        }
        if (posts.length) {
            if (duplicateOutside) {
                this._toastService.openSuccessToast(this._translateService.instant('social_posts.posts_duplicated'));
            }
            this._socialPostsService.duplicatePosts(posts, duplicateOutside ? DuplicationDestination.OUT : DuplicationDestination.HERE);
        }
        this.selectedPosts.set([]);

        this._socialPostsService.reloadFeed();

        if (
            !duplicateOutside &&
            posts.some((post) => post.keys.some((key) => PlatformDefinitions.getSeoPlatformKeysWithPost().includes(key)))
        ) {
            const isManyPosts = posts.length > 1;
            const postId = isManyPosts ? null : posts[0].id;
            this._duplicatePostModalService.openDuplicateModal(postId, PostCategory.SEO, isManyPosts);
        } else {
            this._customDialogService.closeAll();
        }
    };

    private _onDuplicationError = (error: unknown, inBackground: boolean): void => {
        console.warn('err :>>', error);
        this.selectedPosts.set([]);
        if (!inBackground) {
            this._spinnerService.hide();
        }
        if (error && error['status'] === 403 && !!error['error']?.casl) {
            return;
        }
        if (error?.['message']?.match(/Unsupported file/)) {
            this._toastService.openErrorToast(this._translateService.instant('social_posts.format_not_supported'));
        } else {
            this._toastService.openErrorToast(this._translateService.instant('social_posts.duplication_failed'));
        }
    };

    private _duplicatePostsHere(postsToDuplicate: PostWithJob[], platformsKeys: PlatformKey[], inBackground: boolean): void {
        if (!inBackground) {
            this._spinnerService.show();
        }
        forkJoin(postsToDuplicate.map((postToDuplicate) => this._postsService.duplicatePost$(postToDuplicate, platformsKeys))).subscribe({
            next: (posts) => {
                this._onDuplicationSuccess({
                    posts,
                    inBackground,
                    duplicateOutside: false,
                });
            },
            error: (err) => {
                this._onDuplicationError(err, inBackground);
            },
        });
    }

    private _duplicatePostOutside(
        restaurantSelectionData: RestaurantsSelectionData,
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        inBackground: boolean,
        isDraft = false
    ): Observable<Post[]> {
        if (restaurantSelectionData?.selectedRestaurants?.length) {
            type CustomPostWithJob = PostWithJob & { forceDraft: boolean; platformKeys: PlatformKey[] };
            const allPosts: CustomPostWithJob[] = [];

            restaurantSelectionData.selectedRestaurants?.forEach(({ _id, platformKeys }) => {
                // separate valid and invalid platform keys for the restaurant if duplication is not as draft
                const validPlatforms = isDraft ? platformsKeys : platformsKeys.filter((key) => platformKeys.includes(key));
                const invalidPlatforms = isDraft ? [] : platformsKeys.filter((key) => !platformKeys.includes(key));

                postsToDuplicate.forEach((post) => {
                    // if post to duplicate is draft than duplicate it in all platforms
                    if (post.published === PostPublicationStatus.DRAFT) {
                        const newPost = new Post(post) as CustomPostWithJob;
                        newPost.restaurantId = _id;
                        newPost.forceDraft = false;
                        newPost.platformKeys = platformsKeys;
                        delete newPost.attachmentsName;

                        allPosts.push(newPost);
                    } else {
                        // else duplicate it as draft in invalid platforms
                        if (invalidPlatforms.length > 0) {
                            const newPost = new Post(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.published = PostPublicationStatus.DRAFT;
                            newPost.forceDraft = true;
                            newPost.platformKeys = invalidPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }

                        if (validPlatforms.length > 0) {
                            const newPost = new Post(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.forceDraft = false;
                            newPost.platformKeys = validPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }
                    }
                });
            });
            if (!inBackground) {
                this._spinnerService.show();
            }
            return forkJoin(
                allPosts.map((postToDuplicate) =>
                    this._postsService.duplicatePost$(
                        postToDuplicate,
                        postToDuplicate.platformKeys,
                        isDraft || postToDuplicate.forceDraft || postToDuplicate.published === PostPublicationStatus.DRAFT
                    )
                )
            );
        } else {
            this.selectedPosts.set([]);
            return of([]);
        }
    }

    private _populateSelectedRestaurantsWithPost(
        restaurantSelectionData: RestaurantsSelectionData,
        postToDuplicate: PostWithJob
    ): PersonalizePostDuplicationData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const date = new Date(
                    postToDuplicate.job?.nextRunAt || postToDuplicate.plannedPublicationDate || addMinutes(2, new Date())
                );

                const post = this._formBuilder.group({
                    status: [
                        postToDuplicate.published === PostPublicationStatus.DRAFT ? PostDateStatus.DRAFT : PostDateStatus.LATER,
                        Validators.required,
                    ],
                    text: [postToDuplicate.text, Validators.required],
                    date: [date],
                    time: [getTimeStringFromDate(date), isControlValueInTimeFormat(this._translateService)],
                });
                return {
                    restaurant,
                    post,
                };
            }) || []
        );
    }

    private _duplicatePersonalizedPostOutside(
        postDuplicationDataPerRestaurant: PersonalizePostDuplicationData[],
        initialPost: PostWithJob,
        platformsKeys: PlatformKey[],
        inBackground: boolean
    ): Observable<Post[]> {
        return forkJoin(
            postDuplicationDataPerRestaurant.map(({ restaurant, post }) => {
                const postToDuplicate = initialPost;
                const date = this._getPostDuplicationDate(post);

                postToDuplicate.text = post.value.text || '';
                postToDuplicate.plannedPublicationDate = date;
                postToDuplicate.published =
                    post.value.status === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;

                return this._duplicatePostOutside(
                    { selectedRestaurants: [restaurant], skipOwnRestaurant: false, withoutBrandBusiness: false },
                    [postToDuplicate],
                    platformsKeys,
                    inBackground
                );
            })
        ).pipe(mergeMap((results) => results));
    }

    private _getPostDuplicationDate(
        post: INullableFormGroup<{
            status: PostDateStatus;
            date: Date;
            text: string;
            time: string;
        }>
    ): Date {
        switch (post.value.status) {
            case PostDateStatus.DRAFT:
            case PostDateStatus.LATER:
                const date = post.value.date ?? new Date();
                if (date && post.value.time) {
                    date.setHours(parseInt(post.value.time.substring(0, 2), 10));
                    date.setMinutes(parseInt(post.value.time.substring(3), 10));
                }
                return date;

            case PostDateStatus.NOW:
            default:
                return new Date();
        }
    }

    private _getStepsForDuplication(
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        isSinglePost: boolean,
        inBackground: boolean
    ): Step[] {
        return isSinglePost
            ? [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translateService.instant('common.next'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizePostDuplicationData[]> =>
                          of(this._populateSelectedRestaurantsWithPost(data, postsToDuplicate[0])),
                  },
                  {
                      component: PersonalizePostDuplicationComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.personalize_post_data.subtitle'),
                      primaryButtonText: this._translateService.instant('common.duplicate'),
                      nextFunction$: (data: PersonalizePostDuplicationData[]): Observable<Post[]> =>
                          this._duplicatePersonalizedPostOutside(data, postsToDuplicate[0], platformsKeys, inBackground),
                  },
              ]
            : [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translateService.instant('common.duplicate'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<Post[]> => {
                          const isDraft = true;
                          return this._duplicatePostOutside(data, postsToDuplicate, platformsKeys, inBackground, isDraft);
                      },
                  },
              ];
    }
}
