import { uniqBy } from 'lodash';
import { BehaviorSubject, map, Observable } from 'rxjs';

export class SelectionModel<T> {
    private readonly _selection$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    constructor(hashFn?: (item: T) => any) {
        if (hashFn) {
            this._hashFn = hashFn;
        }
    }

    private readonly _hashFn = (item: T): any => item;

    getSelection(): T[] {
        return this._selection$.value;
    }

    getSelection$(): Observable<T[]> {
        return this._selection$.asObservable();
    }

    getCount = (): number => this.getSelection().length;

    getCount$(): Observable<number> {
        return this.getSelection$().pipe(map((e) => e.length));
    }

    isSelected = (value: T): boolean => this._isSelected(value, this.getSelection());

    private _isSelected(value: T, selection: T[]): boolean {
        return selection.some((item) => this._hashFn(item) === this._hashFn(value));
    }

    select(values: T | T[]): void {
        if (!Array.isArray(values)) {
            values = [values];
        }
        const uniqValues = uniqBy(values, this._hashFn);

        const selection = this.getSelection();
        const newSelection = this._select(uniqValues, selection);
        if (selection.length !== newSelection.length) {
            this._selection$.next(newSelection);
        }
    }

    private _select(values: T[], selection: T[]): T[] {
        return uniqBy([...selection, ...values], this._hashFn);
    }

    unselect(values: T | T[]): void {
        if (!Array.isArray(values)) {
            values = [values];
        }
        const uniqValues = uniqBy(values, this._hashFn);

        const selection = this.getSelection();
        const newSelection = this._unselect(uniqValues, selection);
        if (selection.length !== newSelection.length) {
            this._selection$.next(newSelection);
        }
    }

    private _unselect(values: T[], selection: T[]): T[] {
        return selection.filter((item) => !values.some((value) => this._hashFn(item) === this._hashFn(value)));
    }

    toggle(values: T | T[]): void {
        if (!Array.isArray(values)) {
            values = [values];
        }
        const uniqValues = uniqBy(values, this._hashFn);

        const toAdd: T[] = [];
        const toRemove: T[] = [];
        for (const value of uniqValues) {
            if (this.isSelected(value)) {
                toRemove.push(value);
            } else {
                toAdd.push(value);
            }
        }

        const currentSelection = this.getSelection();

        const selectionAfterSelect = this._select(toAdd, currentSelection);
        const wereItemsAdded = selectionAfterSelect.length !== currentSelection.length;

        const selectionAfterUnselect = this._unselect(toRemove, selectionAfterSelect);
        const wereItemsRemoved = selectionAfterUnselect.length !== selectionAfterSelect.length;

        if (wereItemsAdded || wereItemsRemoved) {
            this._selection$.next(selectionAfterUnselect);
        }
    }

    unselectAll(): void {
        this._selection$.next([]);
    }
}
