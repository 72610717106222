import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DialogDataType, MalouDialogComponent } from ':shared/components/malou-dialog/malou-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private readonly _dialog: MatDialog) {}

    open<Result = any>(data: DialogDataType): MatDialogRef<MalouDialogComponent, Result> {
        return this._dialog.open<MalouDialogComponent, any, Result>(MalouDialogComponent, {
            width: data.width || '550px',
            maxWidth: data.maxWidth || '80vw',
            height: data.height || 'auto',
            backdropClass: 'malou-backdrop',
            data,
        });
    }
}
