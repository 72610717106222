<div class="malou-card container m-8" style="padding: 60px">
    <div class="flex flex-col items-center justify-center">
        <div class="flex items-center" style="margin-bottom: 40px">
            <i class="material-icons malou-color-fail malou-text--big-icon mr-5">highlight_off</i>
            <div class="malou-title__kevin malou-color-fail malou-text--2x-large malou-text--semi-bold">
                {{ 'campaigns.email_verification_pages.error' | translate }}
            </div>
        </div>
        <br />
        <p class="malou-title__kevin">{{ 'campaigns.email_verification_pages.error_text' | translate }}</p>
    </div>
</div>
