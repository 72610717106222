import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@NgModule()
export class IconModule {
    private readonly _PATH = '../../../../assets/svg-icons';

    constructor(
        private readonly _domSanitizer: DomSanitizer,
        public readonly matIconRegistry: MatIconRegistry
    ) {
        this._setIcons();
    }
    private _setPath(url: string): SafeResourceUrl {
        return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    private _setIcons(): void {
        Object.values(SvgIcon).forEach((icon) => {
            this.matIconRegistry.addSvgIcon(icon, this._setPath(`${this._PATH}/${icon}.svg`));
        });
    }
}
