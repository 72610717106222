import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, switchMap, take } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ToastService } from ':core/services/toast.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import * as ReviewsSelectors from ':modules/reviews/store/reviews.selectors';
import * as UserActions from ':modules/user/store/user.actions';
import { selectCurrentUserRestaurant } from ':modules/user/store/user.selectors';
import { UsersService } from ':modules/user/users.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Component({
    selector: 'app-reviews-header-semantic-analysis',
    templateUrl: './reviews-header-semantic-analysis.component.html',
    styleUrls: ['./reviews-header-semantic-analysis.component.scss'],
    standalone: true,
    imports: [TranslateModule, AsyncPipe, SlideToggleComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderSemanticAnalysisComponent implements OnInit {
    readonly isAggregatedView = input.required<boolean>();

    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _usersService = inject(UsersService);

    readonly storedSemanticAnalysisToggle$ = this._store.select(ReviewsSelectors.selectSemanticAnalysisToggle);

    ngOnInit(): void {
        this._initValue$();
    }

    toggleSemanticAnalysis(isChecked: boolean): void {
        this._editSemanticAnalysisToggle(isChecked);
        if (this.isAggregatedView()) {
            return;
        }
        this._store
            .select(selectCurrentUserRestaurant)
            .pipe(
                filter(Boolean),
                take(1),
                switchMap((userRestaurant) =>
                    this._usersService.updateUserRestaurantById(userRestaurant._id, { displaySemanticAnalyses: isChecked })
                )
            )
            .subscribe({
                next: () => {
                    this._store.dispatch(UserActions.loadUser());
                },
                error: (err) => {
                    console.warn('err >', err);
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translateService).transform(err));
                },
            });
    }

    private _editSemanticAnalysisToggle(isChecked: boolean): void {
        this._store.dispatch(ReviewsActions.editSemanticAnalysisToggle({ toggle: isChecked }));
    }

    private _initValue$(): void {
        if (this.isAggregatedView()) {
            this._editSemanticAnalysisToggle(true);
        } else {
            this._store
                .select(selectCurrentUserRestaurant)
                .pipe(
                    filter(Boolean),
                    distinctUntilChanged((previous, current) => previous.restaurantId === current.restaurantId)
                )
                .subscribe((userRestaurant) => {
                    this._editSemanticAnalysisToggle(userRestaurant.displaySemanticAnalyses ?? false);
                });
        }
    }
}
