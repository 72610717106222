<div class="malou-card-container">
    <div class="malou-card">
        <div class="malou-card__header">
            <h1>Credentials</h1>
        </div>
        <div class="malou-card__body">
            <div class="item">
                <app-gmb-credentials [credential]="getCredential | applyPure: PlatformKey.GMB"></app-gmb-credentials>
            </div>
            <div class="item">
                <app-yelp-credentials></app-yelp-credentials>
            </div>
            <div class="item">
                <app-facebook-credentials [credential]="getCredential | applyPure: PlatformKey.FACEBOOK"></app-facebook-credentials>
            </div>
            <div class="item">
                <app-foursquare-credentials [credential]="getCredential | applyPure: PlatformKey.FOURSQUARE"></app-foursquare-credentials>
            </div>
            <div id="back-link">
                <a routerLink="/">Go back</a>
                <a routerLink="validate">test</a>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
