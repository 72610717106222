import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListingDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PublishersService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/publishers`;

    constructor(private readonly _http: HttpClient) {}

    getYextListingsForRestaurant$(restaurantId: string): Observable<ApiResultV2<ListingDto[]>> {
        return this._http.get<ApiResultV2<ListingDto[]>>(`${this.API_BASE_URL}/yext/restaurants/${restaurantId}/listings`);
    }
}
