import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const organizationValidator = z.object({
    _id: objectIdValidator.nullish(),
    name: z.string().nullish(),
    verifiedEmailsForCampaigns: z.array(z.string()).nullish(),
    limit: z.number().nullish(),
    createdAt: z.string().nullish(),
    updatedAt: z.string().nullish(),
});
