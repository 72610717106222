import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TemplateTranslationLang } from '@malou-io/package-utils';

import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-generate-review-templates',
    templateUrl: './generate-review-templates.component.html',
    styleUrls: [],
    standalone: true,
    imports: [
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        ApplyPurePipe,
        FlagPathResolverPipe,
        IllustrationPathResolverPipe,
    ],
})
export class GenerateReviewTemplatesComponent {
    @Output()
    generate: EventEmitter<TemplateTranslationLang[]> = new EventEmitter<TemplateTranslationLang[]>();

    readonly Illustration = Illustration;
    readonly TemplateTranslationLang = TemplateTranslationLang;

    constructor(private readonly _translate: TranslateService) {}

    getPrettyLang = (lang: TemplateTranslationLang): string => this._translate.instant(`header.countries.${lang}`);

    generateTemplates(langs: TemplateTranslationLang[]): void {
        this.generate.emit(langs);
    }
}
