import { z } from 'zod';

import {
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    ApplicationLanguage,
    CustomerNaming,
    FrenchTutoiementVouvoiement,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const updateRestaurantAiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type UpdateRestaurantAiSettingsParamsDto = z.infer<typeof updateRestaurantAiSettingsParamsValidator>;

export const updateRestaurantAiReviewSettingsBodyValidator = z.object({
    reviewSettings: z.object({
        replyTone: z.nativeEnum(FrenchTutoiementVouvoiement),
        customerNaming: z.nativeEnum(CustomerNaming),
        restaurantKeywordIds: z.array(z.string()),
        forbiddenWords: z.array(z.string()),
        catchphrase: z.string().nullish(),
        shouldTranslateCatchphrase: z.boolean(),
        signatures: z.array(z.string()),
        shouldTranslateSignature: z.boolean(),
    }),
});

export type UpdateRestaurantAiReviewSettingsBodyDto = z.infer<typeof updateRestaurantAiReviewSettingsBodyValidator>;

export const updateRestaurantAiGeneralSettingsBodyValidator = z.object({
    restaurantName: z.string(),
    defaultLanguageResponse: z.nativeEnum(ApplicationLanguage),
});

export type UpdateRestaurantAiGeneralSettingsBodyDto = z.infer<typeof updateRestaurantAiGeneralSettingsBodyValidator>;

export const updateRestaurantAiPostSettingsBodyValidator = z.object({
    postSettings: z.object({
        social: z.object({
            denomination: z.nativeEnum(FrenchTutoiementVouvoiement),
            tone: z.array(z.nativeEnum(AiPostSettingsTone)),
            length: z.nativeEnum(AiPostSettingsLength),
            emoji: z.nativeEnum(AiPostGenerationEmojiStatus),
            prompt: z.string().nullish(),
        }),
        seo: z.object({
            denomination: z.nativeEnum(FrenchTutoiementVouvoiement),
            tone: z.array(z.nativeEnum(AiPostSettingsTone)),
            length: z.nativeEnum(AiPostSettingsLength),
            emoji: z.nativeEnum(AiPostGenerationEmojiStatus),
            prompt: z.string().nullish(),
        }),
    }),
});

export type UpdateRestaurantAiPostSettingsBodyDto = z.infer<typeof updateRestaurantAiPostSettingsBodyValidator>;
