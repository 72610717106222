import { createAction, props } from '@ngrx/store';

import { CommentReviewsFilters } from ':shared/models';
import { AutomationTemplateFilters, ReviewTemplatesFilters } from ':shared/models/template-filters';

export const editReviewTemplatesFilters = createAction(
    '[Template-Reviews] Edit_Reviews_Filters',
    props<{ filters: ReviewTemplatesFilters }>()
);

export const editReviewTemplatesFiltersRatings = createAction(
    '[Template-Reviews] Edit_Reviews_Filters_Ratings',
    props<{ ratings: number[] }>()
);

export const toggleReviewTemplatesFilters = createAction(
    '[Template-Reviews] Toggle_Reviews_Filters_Comment',
    props<{ filter: CommentReviewsFilters | AutomationTemplateFilters }>()
);

export const resetReviewTemplatesFilters = createAction('[Template-Reviews] Reset_Reviews_Filters');

export const editReviewTemplatesSort = createAction(
    '[Template-Reviews] Edit_Reviews_Sort',
    props<{ sortBy?: string; sortOrder?: string }>()
);

export const setTemplateCount = createAction('[Template-Reviews] Set_Template_Count', props<{ count: number }>());
