export class Category {
    _id: string;
    platformKey: string;
    categoryName: {
        backup: string;
        fr: string;
        en: string;
        es: string;
        it: string;
    };
    isFood: boolean;
    categoryId: string;
    subcategoryName: string;
    subcategoryId: string;
    platformCategories: Category[] = [];

    public constructor(init: Partial<Category> = {}) {
        Object.assign(this, init);
        this.platformCategories = this.platformCategories.map((e) => new Category(e));
    }

    getMetric(): string {
        throw new Error('Method not implemented.');
    }

    getCategoryNameForLang(lang: string): string {
        return this.categoryName[lang] || this.categoryName.backup || '';
    }
}
