<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{ 'notifications.at' | translate: { date: receptionDate() | date: 'dd.MM.yyyy', time: receptionDate() | date: 'HH:mm' } }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div class="flex flex-col">
            <div class="malou-text-11--semibold flex items-center gap-x-2 text-malou-color-text-1">
                {{ notificationTranslationsKey().title | translate }}
            </div>
            <div class="malou-text-11 text-malou-color-text-2">
                {{ notificationTranslationsKey().subTitle | translate }}
            </div>
        </div>
        <div class="ml-auto">
            <button class="malou-btn-raised--primary btn-xs" mat-raised-button>
                {{ 'common.fill' | translate }}
            </button>
        </div>
    </div>
</div>
