<div class="flex h-full w-[204px] flex-col justify-between rounded-l-lg bg-malou-color-gradient-2 pb-4 pt-6">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</div>

<ng-template #headerTemplate>
    <div class="flex items-center justify-center gap-x-2 pl-4 pr-2">
        @if (platformKeyIcon(); as platformKeyIcon) {
            <app-platform-logo imgClasses="h-[50px] w-[50px]" [logo]="platformKeyIcon"></app-platform-logo>
        }
        <div class="flex flex-col gap-y-2">
            <div class="malou-text-18--bold leading-none text-malou-color-text-white">{{ title() }}</div>
            @if (subtitle()) {
                <div class="malou-text-15--bold pl-3 leading-none text-malou-color-text-white">{{ subtitle() }}</div>
            }
        </div>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    <div class="relative my-6 flex flex-grow flex-col">
        <ng-container *ngTemplateOutlet="dashedBorderWithDotsTemplate" />
        @for (step of steps(); track step; let index = $index) {
            <div
                class="flex min-h-0 flex-1 items-center"
                [attr.data-testid]="'platform-connection-step-' + index + '-btn'"
                (click)="onStepClick(index)">
                <div
                    class="flex items-center w-full {{ paddingClass() }}"
                    [ngClass]="{
                        'bg-white/10': currentStep() === index,
                        'cursor-pointer hover:bg-white/10': currentStep() > index,
                    }">
                    <div
                        class="line-clamp-3 pl-12 pr-6 text-malou-color-text-white"
                        [class.malou-text-10--bold]="currentStep() === index"
                        [class.malou-text-10--regular]="currentStep() !== index">
                        {{ step }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #footerTemplate>
    <div class="flex justify-center">
        <div class="flex cursor-pointer items-center gap-x-2 p-1" (click)="onContactCLick()">
            <mat-icon class="!h-[16px] !w-[16px] text-malou-color-text-white" [svgIcon]="SvgIcon.CONVERSATION" />
            <div class="malou-text-10--semibold text-malou-color-text-white">
                {{ 'platforms.connection_new.parent_stepper.stepper.contact' | translate }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dashedBorderWithDotsTemplate>
    <!--be careful before updating this - @cyril-->
    <div class="absolute ml-6 flex h-full flex-col">
        <div class="flex flex-1 flex-col items-center justify-end">
            <ng-container [ngTemplateOutlet]="dotTemplate" [ngTemplateOutletContext]="{ colorClass: 'bg-white' }" />
        </div>
        @for (_ of steps().length - 1 | createArray; track _; let index = $index) {
            <div class="relative flex flex-2 flex-col items-center">
                <div class="flex grow">
                    <div
                        class="flex-1 border-r border-dashed border-white"
                        [ngClass]="{
                            '!border-[--disabled-color]': index >= currentStep(),
                        }"></div>
                    <div class="flex-1"></div>
                </div>
                <div class="absolute bottom-0">
                    <ng-container
                        [ngTemplateOutlet]="dotTemplate"
                        [ngTemplateOutletContext]="{ colorClass: index >= currentStep() ? 'bg-[--disabled-color]' : 'bg-white' }" />
                </div>
            </div>
        }
        <div class="flex-1"></div>
    </div>
</ng-template>

<ng-template let-colorClass="colorClass" #dotTemplate>
    <div class="relative z-10 h-[12px] w-[12px] shrink-0 translate-y-1/2 rounded-full {{ colorClass }}"></div>
</ng-template>
