@if (!shouldDetailTagsEvolutionCharts) {
    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
        <div>
            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
        </div>
        @if (!noData) {
            <div>
                <ng-container [ngTemplateOutlet]="navigationTemplate"></ng-container>
            </div>
            <div>
                <ng-container [ngTemplateOutlet]="shouldDetailTagsEvolutionCharts ? detailedChartsTemplate : chartTemplate"></ng-container>
            </div>
        } @else {
            <div class="flex flex-col items-center py-4">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
            </div>
        }
    </div>
} @else {
    @if (!noData) {
        @for (item of tagEvolutionData | keyvalue; track item) {
            <div class="malou-simple-card my-3 flex break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="malou-text-section-title malou-color-text-1">
                        {{ 'statistics.e_reputation.reviews_analysis.tag_evolution_of_semantic_analysis_of_reviews' | translate }} :
                        {{ getChartTitleByKey | applyPure: item.key }}
                    </span>
                    <div class="flex items-center gap-2">
                        <span class="malou-text-13--regular italic">
                            {{ 'common.view_by' | translate }}
                            {{ viewByDisplayWith | applyPure: chartViewBy() | lowercase }}
                        </span>
                    </div>
                </div>
                <app-tags-evolution-chart
                    [tagEvolutionData]="tagEvolutionData[item.key]"
                    [labels]="labels"
                    [viewBy]="chartViewBy()"
                    (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
                </app-tags-evolution-chart>
            </div>
        }
    } @else {
        <div class="flex flex-col items-center py-4">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
        </div>
    }
}

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{
            'statistics.e_reputation.reviews_analysis.tag_evolution_of_semantic_analysis_of_reviews' | translate
        }}</span>
        <div class="flex items-center gap-2">
            <span class="malou-text-14--medium md:hidden">{{ 'common.view_by' | translate }}</span>
            <app-select [values]="VIEW_BY_FILTER_VALUES" [displayWith]="viewByDisplayWith" [formControl]="viewByControl"></app-select>
        </div>
    </div>
</ng-template>

<ng-template #navigationTemplate>
    <app-tab-nav-bar [tabs]="tabs" (tabChange)="onTabChange($event)"></app-tab-nav-bar>
</ng-template>

<ng-template #chartTemplate>
    <app-tags-evolution-chart
        [tagEvolutionData]="tagEvolutionData[currentTag]"
        [labels]="labels"
        [viewBy]="chartViewBy()"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
    </app-tags-evolution-chart>
</ng-template>

<ng-template #detailedChartsTemplate>
    @if (!noData) {
        @for (item of tagEvolutionData | keyvalue; track item) {
            <div class="malou-simple-card my-3 flex break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="malou-text-section-title malou-color-text-1">
                        {{ 'statistics.e_reputation.reviews_analysis.tag_evolution_of_semantic_analysis_of_reviews' | translate }} :
                        {{ getChartTitleByKey | applyPure: item.key }}
                    </span>
                    <div class="flex items-center gap-2">
                        <span class="malou-text-13--regular italic">
                            {{ 'common.view_by' | translate }}
                            {{ viewByDisplayWith | applyPure: chartViewBy() | lowercase }}
                        </span>
                    </div>
                </div>
                <app-tags-evolution-chart
                    [tagEvolutionData]="tagEvolutionData[item.key]"
                    [labels]="labels"
                    [viewBy]="chartViewBy()"
                    (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
                </app-tags-evolution-chart>
            </div>
        }
    } @else {
        <div class="flex flex-col items-center py-4">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
        </div>
    }
</ng-template>
