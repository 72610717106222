import { Component, Inject, Signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LowerUpperPagination } from ':shared/interfaces/lower-upper-pagination.interface';
import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

import { AnswerReviewContainerComponent } from '../answer-review-container/answer-review-container.component';

@Component({
    selector: 'app-answer-review-modal',
    templateUrl: './answer-review-modal.component.html',
    styleUrls: ['./answer-review-modal.component.scss'],
    standalone: true,
    imports: [AnswerReviewContainerComponent],
})
export class AnswerReviewModalComponent {
    constructor(
        private readonly _dialogRef: MatDialogRef<AnswerReviewModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            review: Review | PrivateReview;
            pagination: LowerUpperPagination;
            isAggregatedView: boolean;
            displaySemanticAnalyses: Signal<boolean>;
        }
    ) {}

    close(event: any): void {
        this._dialogRef.close(event);
    }
}
