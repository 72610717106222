import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CreateSocialPostMenuButtonComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/create-social-post-menu-button/create-social-post-menu-button.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-no-social-post',
    templateUrl: './no-social-post.component.html',
    styleUrls: ['./no-social-post.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, CreateSocialPostMenuButtonComponent, IllustrationPathResolverPipe],
})
export class NoSocialPostComponent {
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();

    readonly Illustration = Illustration;

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }
}
