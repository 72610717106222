export enum FrenchTutoiementVouvoiement {
    FORMAL = 'FORMAL', // Vouvoiement
    INFORMAL = 'INFORMAL', // Tutoiement
    DOES_NOT_MATTER = 'DOES_NOT_MATTER',
}

export enum CustomerNaming {
    FIRSTNAME = 'FIRSTNAME',
    FULLNAME = 'FULLNAME',
    TITLE_AND_LASTNAME = 'TITLE_AND_LASTNAME',
}

export enum AiPostSettingsTone {
    sophisticated = 'sophisticated',
    casual = 'casual',
    formal = 'formal',
    humoristic = 'humoristic',
    engaging = 'engaging',
    inspiring = 'inspiring',
    nostalgic = 'nostalgic',
    shocking = 'shocking',
}

export enum AiPostSettingsLength {
    very_short = 'very_short',
    short = 'short',
    medium = 'medium',
    long = 'long',
}

export enum AiPostGenerationEmojiStatus {
    none = 'none',
    some = 'some',
    a_lot = 'a_lot',
}
