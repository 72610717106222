import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { Media } from ':shared/models';

import * as GalleryImportMediaActions from './gallery-import-media.actions';

export interface GalleryImportMediaState {
    isGalleryOpen: boolean;
    createdMedia: Media[];
    currentFolderId: null | string;
    filesErrors: string[];
}

export const initialState: GalleryImportMediaState = {
    isGalleryOpen: false,
    createdMedia: [],
    currentFolderId: null,
    filesErrors: [],
};

const galleryImportMediaReducer = createReducer(
    initialState,
    on(GalleryImportMediaActions.setIsGalleryOpen, (state, { isGalleryOpen }) => ({
        ...state,
        isGalleryOpen,
    })),
    on(GalleryImportMediaActions.setCreatedMedia, (state, { createdMedia }) => ({
        ...state,
        createdMedia,
    })),
    on(GalleryImportMediaActions.setCurrentFolderId, (state, { currentFolderId }) => ({
        ...state,
        currentFolderId,
    })),
    on(GalleryImportMediaActions.setFilesErrors, (state, { filesErrors }) => ({
        ...state,
        filesErrors,
    }))
);

export const selectGalleryImportMediaState = createFeatureSelector<GalleryImportMediaState>('galleryImportMedia');

export const selectIsGalleryOpen = createSelector(selectGalleryImportMediaState, (state: GalleryImportMediaState) => state.isGalleryOpen);

export const selectCreatedMedia = createSelector(selectGalleryImportMediaState, (state: GalleryImportMediaState) => state.createdMedia);

export const selectCurrentFolderId = createSelector(
    selectGalleryImportMediaState,
    (state: GalleryImportMediaState) => state.currentFolderId
);

export const selectFilesErrors = createSelector(selectGalleryImportMediaState, (state: GalleryImportMediaState) => state.filesErrors);

export function reducer(state: GalleryImportMediaState | undefined, action: Action): GalleryImportMediaState {
    return galleryImportMediaReducer(state, action);
}
