import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import * as InformationsActions from './informations.actions';
import { InformationState, InformationsUpdateStatus } from './informations.interface';

const initialState: InformationState = {
    restaurantId: '',
    isFooterVisible: true,
    shouldStartUpdate: false,
    platformPropertiesToUpdate: [],
    updateLoadingState: InformationsUpdateStatus.NOT_STARTED,
};

const informationsReducer = createReducer(
    initialState,
    on(InformationsActions.selectInformation, (state, { restaurantId }) => ({ ...state, selectedInformationId: restaurantId })),
    on(InformationsActions.editInformation, (state, { information }) => ({ ...state, ...information }))
);

export function reducer(state: InformationState, action: Action): InformationState {
    return informationsReducer(state, action);
}

export const selectInformationsState = createFeatureSelector<InformationState>('informations');

export const selectCurrentInformations = createSelector(selectInformationsState, (state: InformationState) => state);

export const selectCurrentInformationIsDirty = createSelector(
    selectCurrentInformations,
    (information: InformationState) => information.isDirty
);
