import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';

import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-sidenav-toggle-button',
    templateUrl: './sidenav-toggle-button.component.html',
    standalone: true,
    imports: [MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavToggleButtonComponent {
    private readonly _store = inject(Store);

    svgIconWhenOpen = input(SvgIcon.CROSS);
    svgIconWhenClosed = input(SvgIcon.BURGER);

    isSidenavOpened = toSignal(this._store.select(SidenavSelectors.selectIsOpened));

    toggleSidenav(): void {
        this._store.dispatch(SidenavActions.toggle());
    }
}
