import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs';

import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import { StaticFeedPost } from ':shared/components/static-feed/static-feed.component';
import { Hashtag, Media, Restaurant, SocialPost } from ':shared/models';
import { FeedbackMessage } from ':shared/models/feedback';

import { DialogStep, RightPanel } from './enums';
import { initialPostForm, NewSocialPostInitialData } from './types';

@Injectable({
    providedIn: 'any',
})
export class NewSocialPostContext {
    readonly _screenSizeService = inject(ScreenSizeService);
    readonly _translate = inject(TranslateService);
    readonly _destroyRef = inject(DestroyRef);
    readonly _fb = inject(UntypedFormBuilder);

    readonly postInitialData = signal<NewSocialPostInitialData>({
        isDisabled: false,
        post: new SocialPost({}),
        postDate: new Date(),
        postId: '',
        restaurantManagers: [],
        allPostIds: [],
    });
    readonly isPostTextEmpty = signal(true);
    readonly isSmallScreen = signal(false);
    readonly isReel = signal(false);

    readonly selectedHashtagsList = signal<Hashtag[]>([]);
    readonly post = signal<SocialPost>(new SocialPost({}));
    readonly currentRestaurant = signal<Restaurant>(new Restaurant({}));

    readonly shouldAutoSave$ = new BehaviorSubject<{ field?: string; value?: any }>({});
    readonly DialogStep = DialogStep;
    readonly RightPanel = RightPanel;
    readonly dialogStep = signal(DialogStep.ONE);
    readonly displayedRightPanel = signal(RightPanel.Feed);
    readonly postForm = signal(this._fb.group(initialPostForm));
    readonly currentLang = signal(LocalStorage.getLang());
    readonly selectedText = signal('');
    readonly postMedias = signal<Media[]>([]);

    partialPost: WritableSignal<Partial<StaticFeedPost>>;
    killSubscriptions$: Subject<void> = new Subject();

    readonly displayedFeedbackMessages = signal<FeedbackMessage[]>([]);

    readonly shouldShowFeedbacks = toSignal(
        combineLatest([
            this._screenSizeService.resize$.pipe(
                map((resized) => resized.size),
                takeUntilDestroyed(this._destroyRef)
            ),
            toObservable(this.dialogStep),
        ]).pipe(
            map(([size, step]) => {
                if (size !== ScreenSize.IsSmallScreen) {
                    return true;
                }
                return step === DialogStep.TWO;
            })
        )
    );

    readonly showPicEditor = toSignal(
        combineLatest([
            this._screenSizeService.resize$.pipe(
                map((resized) => resized.size),
                takeUntil(this.killSubscriptions$)
            ),
            toObservable(this.dialogStep),
        ]).pipe(
            map(([size, step]) => {
                if (size !== ScreenSize.IsSmallScreen) {
                    return true;
                }
                return step === DialogStep.ONE;
            })
        )
    );

    changeDialogStep(step: DialogStep): void {
        this.dialogStep.update(() => step);
    }
}
