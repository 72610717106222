import { GeoSampleDto } from '@malou-io/package-dto';
import { RestaurantRankingFormat } from '@malou-io/package-utils';

export class GeoSample {
    _id: string;
    keyword: string;
    lat: number;
    lng: number;
    platformKey: string;
    ranking: RestaurantRankingFormat[];
    week: number;
    year: number;
    error: boolean;
    errorData: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(init?: Partial<GeoSample>) {
        Object.assign(this, init);
    }

    static fromGeoSampleDto(geoSampleDto: GeoSampleDto): GeoSample {
        return new GeoSample({
            _id: geoSampleDto._id,
            keyword: geoSampleDto.keyword,
            lat: geoSampleDto.lat,
            lng: geoSampleDto.lng,
            platformKey: geoSampleDto.platformKey,
            ranking: geoSampleDto.ranking,
            week: geoSampleDto.week,
            year: geoSampleDto.year,
            error: geoSampleDto.error,
            errorData: geoSampleDto.errorData,
            createdAt: new Date(geoSampleDto.createdAt),
            updatedAt: new Date(geoSampleDto.updatedAt),
        });
    }
}
