import { CaslRole } from '../../constants';

export enum NotificationChannel {
    EMAIL = 'email',
    MOBILE = 'mobile',
    WEB = 'web',
}

export enum NotificationType {
    REVIEW = 'review',
    REVIEW_REPLY_REMINDER = 'review_reply_reminder',
    SPECIAL_HOUR = 'special_hour',
    POST_SUGGESTION = 'post_suggestion',
    COMMENT = 'comment',
    MENTION = 'mention',
    POST_ERROR = 'post_error',
    NEW_MESSAGE = 'new_message',
    ROI_ACTIVATED = 'roi_activated',
    SUMMARY = 'summary',
    PLATFORM_DISCONNECTED = 'platform_disconnected',
}

export enum NotificationSettingsProperty {
    REVIEW_REPLY_REMINDER = 'reviewReplyReminder',
    SPECIAL_HOUR = 'specialHourReminder',
    POST_SUGGESTION = 'postSuggestion',
    SUMMARY = 'summary',
}

export const NEGATIVE_REVIEW_MAX_RATING = 3;
export const NEGATIVE_REVIEW_MAX_DAYS_NOTIFICATION = 30;
export const NEGATIVE_REVIEW_MIN_DAYS_NOTIFICATION = 2;

export const PERIOD_FOR_EMAIL_POSTS_SUGGESTION = 10;
export const PERIOD_FOR_WEB_POSTS_SUGGESTION = 14;

export const PERIOD_FOR_EMAIL_SPECIAL_HOUR = 10;
export const PERIOD_FOR_WEB_SPECIAL_HOUR = 14;

export const NOTIFICATIONS_ROLE = Object.freeze({
    [NotificationType.REVIEW_REPLY_REMINDER]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.NEW_MESSAGE]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.POST_SUGGESTION]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR],
    [NotificationType.SPECIAL_HOUR]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR],
    [NotificationType.COMMENT]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.MENTION]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.ROI_ACTIVATED]: [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR, CaslRole.MODERATOR],
    [NotificationType.PLATFORM_DISCONNECTED]: [CaslRole.ADMIN, CaslRole.OWNER],
});

export enum SpecialHourNotificationAction {
    OPEN_AS_USUAL = 'OPEN_AS_USUAL',
    CLOSED = 'CLOSED',
    NOTHING = 'NOTHING',
}

export const NOTIFICATIONS_TO_SUMMARIZE_SORTED_BY_PRIORITY = [
    NotificationType.SPECIAL_HOUR,
    NotificationType.POST_SUGGESTION,
    NotificationType.REVIEW,
    NotificationType.NEW_MESSAGE,
    NotificationType.POST_ERROR,
    NotificationType.COMMENT,
    NotificationType.MENTION,
];

export const MAX_SUMMARY_NOTIFICATIONS_IN_EMAIL = 3;

/*
 * Admins that should not be included in the notifications, because they take too much space and time
 */
export const ADMIN_EMAILS_TO_EXCLUDE_FROM_NOTIFICATIONS = [
    // admin-app
    'admin-app@malou.io',
];
