import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { loadMaintenance } from ':modules/admin/store/admin.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';

import { MaintenanceService } from './maintenance.service';

@Injectable()
export class MaintenanceGuard {
    constructor(
        private readonly _maintenanceService: MaintenanceService,
        private readonly _router: Router,
        private readonly _store: Store
    ) {}

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        this._store.dispatch({ type: loadMaintenance.type }); // needed to be sure we are synced to the db
        return combineLatest([
            this._maintenanceService.getMaintenanceStatus(),
            this._store.select(selectUserInfos).pipe(
                filter(isNotNil),
                map((infos) => infos.email)
            ),
        ]).pipe(
            map(([mode, email]) => {
                if (mode?.status === 'maintenance' && !mode?.trespass.includes(email)) {
                    this._router.navigate(['/maintenance'], { queryParams: { until: mode.until } });
                    return false;
                }
                return true;
            })
        );
    }
}
@Injectable()
export class RedirectToProductionGuard {
    constructor(
        private readonly _maintenanceService: MaintenanceService,
        private readonly _store: Store
    ) {}

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest([
            this._maintenanceService.getMaintenanceStatus(),
            this._store.select(selectUserInfos).pipe(
                filter(isNotNil),
                map((infos) => infos.email)
            ),
        ]).pipe(
            map(([mode, email]) => {
                if (mode?.redirect && !email.includes('@malou.io') && !document.location.href.includes('localhost')) {
                    document.location.href = 'https://v2.app.malou.io';
                    return false;
                }
                return true;
            })
        );
    }
}
