<form [formGroup]="nameForm" (ngSubmit)="rename()">
    <div class="malou-dialog malou-dialog-mobile flex h-full flex-col !overflow-hidden">
        <div class="malou-dialog__header gap-x-5">
            <div class="flex w-full items-center justify-between">
                <span class="malou-text-18--bold text-malou-color-text-1">{{
                    'gallery.folders.folder_renaming_modal.title' | translate
                }}</span>
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
            </div>
        </div>

        <div class="malou-dialog__body !m-0 flex grow gap-x-5 !overflow-hidden !pr-0">
            <div class="my-3 w-full pr-6.5">
                <app-input-text
                    formControlName="name"
                    [title]="'gallery.folders.folder_renaming_modal.folder_name' | translate"
                    [errorMessage]="formError()">
                </app-input-text>
            </div>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11 md:grow" type="button" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <button class="malou-btn-raised--primary !h-11 md:grow" type="submit" mat-raised-button [disabled]="disableSubmit()">
                {{ 'common.validate' | translate }}
            </button>
        </div>
    </div>
</form>
