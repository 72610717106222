import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MalouErrorCode } from '@malou-io/package-utils';

import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Pipe({
    name: 'statisticsHttpError',
    standalone: true,
})
export class StatisticsHttpErrorPipe implements PipeTransform {
    constructor(
        private readonly _translate: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    transform(error: any): string {
        if (error?.error?.malouErrorCode === MalouErrorCode.PLATFORM_INSIGHTS_ERROR) {
            return this._translate.instant('statistics.errors.requires_permissions', { platformName: error?.error?.metadata.key });
        }
        if (error?.error?.message?.match(/Requires/g) ?? error?.error?.message?.match(/permission/g)) {
            return this._translate.instant('statistics.errors.requires_permissions');
        }
        if (
            error?.error?.malouErrorCode === MalouErrorCode.QUERY_VALIDATION_ERROR ||
            error?.error?.message?.match(/invalid_platform/g) ||
            error?.error?.message?.match(/platform_required/g) ||
            error?.error?.message?.match(/change_filters/g)
        ) {
            return this._translate.instant('statistics.errors.change_filters');
        }
        return this._httpErrorPipe.transform(error);
    }
}
