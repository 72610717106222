import { PlatformKey, PlatformUpdateTime } from '@malou-io/package-utils';

enum Feature {
    INFORMATION = 'information',
    SEO_POSTS = 'seo_posts',
    SOCIAL_MEDIA_POSTS = 'social_media_posts',
    REVIEWS = 'reviews',
    BOOSTERS = 'boosters',
    MESSAGES = 'messages',
    COMMENTS_AND_INTERACTIONS = 'messages',
    STATISTICS = 'statistics',
}

enum InformationSent {
    NAME = 'name',
    COVER = 'cover',
    PROFILE_PICTURE = 'profile_picture',
    ADDRESS = 'address',
    SITE = 'site',
    MAIN_CATEGORY = 'main_category',
    SECONDARY_CATEGORY = 'secondary_category',
    MENU = 'menu',
    PHONE = 'phone',
    OPENING_DATE = 'opening_date',
    OPENING_HOURS = 'opening_hours',
    SPECIAL_HOURS = 'special_hours',
    SERVICE_HOURS = 'service_hours',
    LONG_DESCRIPTION = 'long_description',
    SHORT_DESCRIPTION = 'short_description',
    CHARACTERISTICS = 'characteristics',
    ORDER_URL = 'order_url',
    RESERVATION_URL = 'reservation_url',
    TIKTOK_URL = 'tiktok_url',
    PINTEREST_URL = 'pinterest_url',
    LINKEDIN_URL = 'linkedin_url',
    X_URL = 'x_url',
    INSTAGRAM_URL = 'instagram_url',
    YOUTUBE_URL = 'youtube_url',
    FACEBOOK_URL = 'facebook_url',
}

export class PlatformTutorial {
    cost: string;
    fullName: string;
    key: PlatformKey;
    registrationTime: string;
    validationPeriod: string;
    features?: Set<Feature>;
    updateTime?: PlatformUpdateTime;
    informationSent?: Set<InformationSent>;
    notionLink?: string;

    constructor(props: PlatformTutorial) {
        this.cost = props.cost;
        this.fullName = props.fullName;
        this.key = props.key;
        this.notionLink = props.notionLink;
        this.updateTime = props.updateTime;
        this.features = props.features;
        this.informationSent = props.informationSent;
        this.registrationTime = props.registrationTime;
        this.validationPeriod = props.validationPeriod;
    }
}

export const platformTutorials = [
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'Google',
        key: PlatformKey.GMB,
        updateTime: PlatformUpdateTime.REAL_TIME,
        features: new Set<Feature>([Feature.INFORMATION, Feature.SEO_POSTS, Feature.REVIEWS, Feature.BOOSTERS, Feature.STATISTICS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.SITE,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SPECIAL_HOURS,
            InformationSent.SERVICE_HOURS,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.CHARACTERISTICS,
            InformationSent.ORDER_URL,
            InformationSent.RESERVATION_URL,
            InformationSent.FACEBOOK_URL,
            InformationSent.INSTAGRAM_URL,
            InformationSent.LINKEDIN_URL,
            InformationSent.PINTEREST_URL,
            InformationSent.TIKTOK_URL,
            InformationSent.X_URL,
            InformationSent.YOUTUBE_URL,
        ]),
        registrationTime: '10 minutes',
        // eslint-disable-next-line @typescript-eslint/quotes
        validationPeriod: "Jusqu'à 5 jours",
    }),
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'TripAdvisor',
        key: PlatformKey.TRIPADVISOR,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SPECIAL_HOURS,
            InformationSent.CHARACTERISTICS,
            InformationSent.COVER,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PROFILE_PICTURE,
            InformationSent.MENU,
            InformationSent.SITE,
        ]),
        registrationTime: '15 minutes',
        // eslint-disable-next-line @typescript-eslint/quotes
        validationPeriod: "Jusqu'à 5 jours",
        notionLink: 'https://welcomehomemalou.notion.site/TripAdvisor-5c7d8b3e1fa0450f93ddd6b490c4646b',
    }),
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'Facebook',
        key: PlatformKey.FACEBOOK,
        updateTime: PlatformUpdateTime.REAL_TIME,
        features: new Set<Feature>([
            Feature.INFORMATION,
            Feature.REVIEWS,
            Feature.SOCIAL_MEDIA_POSTS,
            Feature.MESSAGES,
            Feature.STATISTICS,
        ]),
        informationSent: new Set<InformationSent>([
            InformationSent.PROFILE_PICTURE,
            InformationSent.MAIN_CATEGORY,
            InformationSent.OPENING_HOURS,
            InformationSent.SERVICE_HOURS,
            InformationSent.SPECIAL_HOURS,
            InformationSent.SHORT_DESCRIPTION,
            InformationSent.CHARACTERISTICS,
            InformationSent.ORDER_URL,
            InformationSent.PHONE,
            InformationSent.ADDRESS,
        ]),
        registrationTime: '10 minutes',
        validationPeriod: 'Immédiat',
        notionLink: 'https://welcomehomemalou.notion.site/Facebook-50af956bc7d546dfa982b1d6f0e3996a',
    }),
    new PlatformTutorial({
        cost: 'Module de réservation obligatoire (commission de 2€ par réservation)',
        fullName: 'TheFork',
        key: PlatformKey.LAFOURCHETTE,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS]),
        informationSent: new Set<InformationSent>([
            InformationSent.CHARACTERISTICS,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SITE,
        ]),
        registrationTime: '10 minutes',
        validationPeriod: 'Environ 1 semaine pour qu’un conseiller finalise avec vous l’inscription du restaurant sur TheFork',
        notionLink: 'https://welcomehomemalou.notion.site/Lafourchette-10190266a025440e9f500d0c5c666ab6',
    }),
    new PlatformTutorial({
        cost: 'À partir de 109€/mois',
        fullName: 'Zenchef',
        key: PlatformKey.ZENCHEF,
        features: new Set<Feature>([Feature.REVIEWS]),
        registrationTime: '60 minutes',
        validationPeriod: 'Quelques jours',
        notionLink: 'https://welcomehomemalou.notion.site/Zenchef-d6357048f6514e1db3f1269ba3152cb3',
    }),
    new PlatformTutorial({
        cost: 'Frais de 20$ pour revendiquer la page',
        fullName: 'Foursquare',
        key: PlatformKey.FOURSQUARE,
        updateTime: PlatformUpdateTime.MONTHLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.CHARACTERISTICS,
            InformationSent.COVER,
            InformationSent.SHORT_DESCRIPTION,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PROFILE_PICTURE,
            InformationSent.SITE,
        ]),
        registrationTime: '20 minutes',
        validationPeriod: 'Plusieurs semaines',
        notionLink: 'https://welcomehomemalou.notion.site/Foursquare-5b9cab78284d45dab5d3e2ee2a5c3461',
    }),
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'Yelp',
        key: PlatformKey.YELP,
        updateTime: PlatformUpdateTime.MONTHLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SPECIAL_HOURS,
            InformationSent.CHARACTERISTICS,
            InformationSent.COVER,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.SHORT_DESCRIPTION,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PROFILE_PICTURE,
            InformationSent.MENU,
            InformationSent.SITE,
        ]),
        registrationTime: '5 minutes',
        validationPeriod: 'Plusieurs semaines',
        notionLink: 'https://welcomehomemalou.notion.site/Yelp-1400fa912b2145a79d6744adff37fbe4',
    }),
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'PagesJaunes',
        key: PlatformKey.PAGESJAUNES,
        updateTime: PlatformUpdateTime.MONTHLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SPECIAL_HOURS,
            InformationSent.CHARACTERISTICS,
            InformationSent.COVER,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PROFILE_PICTURE,
            InformationSent.MENU,
            InformationSent.SITE,
        ]),
        registrationTime: '10 minutes',
        validationPeriod: 'Plusieurs semaines',
        notionLink: 'https://welcomehomemalou.notion.site/Page-Jaune-c6797a00ebda4cd88c06d9e771b6c889',
    }),
    new PlatformTutorial({
        cost: 'Voir avec UberEats',
        fullName: 'UberEats',
        key: PlatformKey.UBEREATS,
        features: new Set<Feature>([Feature.REVIEWS]),
        registrationTime: 'Quelques jours (en fonction de UberEats)',
        validationPeriod: 'Quelques jours (en fonction de UberEats)',
        notionLink: 'https://welcomehomemalou.notion.site/Uber-Eats-a7a2edff44444bfc909096ab1f6ece0e',
    }),
    new PlatformTutorial({
        cost: 'Voir avec Deliveroo',
        fullName: 'Deliveroo',
        key: PlatformKey.DELIVEROO,
        features: new Set<Feature>([Feature.REVIEWS]),
        registrationTime: 'Quelques jours (en fonction de Deliveroo)',
        validationPeriod: 'Quelques jours (en fonction de Deliveroo)',
    }),
    new PlatformTutorial({
        cost: 'Gratuit',
        fullName: 'Instagram',
        key: PlatformKey.INSTAGRAM,
        features: new Set<Feature>([Feature.SOCIAL_MEDIA_POSTS, Feature.STATISTICS, Feature.MESSAGES, Feature.BOOSTERS]),
        registrationTime: '10 minutes',
        validationPeriod: '10 minutes',
        notionLink: 'https://welcomehomemalou.notion.site/Instagram-5601193c94714a2495005b40441800e2',
    }),
    new PlatformTutorial({
        cost: 'Gratuit avec une option freemium pour créer et diffuser des posts',
        fullName: 'Mapstr',
        key: PlatformKey.MAPSTR,
        updateTime: PlatformUpdateTime.DAILY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.SOCIAL_MEDIA_POSTS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.SITE,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PHONE,
            InformationSent.OPENING_HOURS,
            InformationSent.SPECIAL_HOURS,
        ]),
        registrationTime: 'Instantané',
        validationPeriod: 'Instantané',
    }),
    new PlatformTutorial({
        cost: 'A partir de 249$ par mois',
        fullName: 'Resy',
        key: PlatformKey.RESY,
        updateTime: PlatformUpdateTime.REAL_TIME,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS]),
        informationSent: new Set<InformationSent>([
            InformationSent.NAME,
            InformationSent.ADDRESS,
            InformationSent.SITE,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PHONE,
            InformationSent.LONG_DESCRIPTION,
        ]),
        registrationTime: '60 minutes',
        validationPeriod: 'Quelques jours',
        notionLink: 'https://welcomehomemalou.notion.site/Connecter-manuellement-Resy-e06546c84d554d559d9d3a1d1d529046',
    }),
    new PlatformTutorial({
        cost: 'A partir de 39$ par mois',
        fullName: 'Opentable',
        key: PlatformKey.OPENTABLE,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: new Set<Feature>([Feature.INFORMATION, Feature.REVIEWS]),
        informationSent: new Set<InformationSent>([
            InformationSent.PHONE,
            InformationSent.CHARACTERISTICS,
            InformationSent.COVER,
            InformationSent.LONG_DESCRIPTION,
            InformationSent.MAIN_CATEGORY,
            InformationSent.SECONDARY_CATEGORY,
            InformationSent.PROFILE_PICTURE,
            InformationSent.MENU,
            InformationSent.SITE,
        ]),
        registrationTime: '60 minutes',
        validationPeriod: 'Quelques jours',
        notionLink: 'https://help.malou.io/en/articles/1967618',
    }),
];
