import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonComponent } from ':shared/components/button/button.component';
import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-menu-button-v2',
    templateUrl: './menu-button-v2.component.html',
    styleUrls: ['./menu-button-v2.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, MatMenuModule, ButtonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonV2Component {
    readonly id = input<string>('');
    readonly text = input<string>('');
    readonly loading = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly buttonClasses = input<string>('');
    readonly tooltip = input<string>('');
    readonly testId = input<string>('');
    readonly size = input<MenuButtonSize>(MenuButtonSize.MEDIUM);

    readonly customButtonClasses = computed(
        () => `!shadow-none !py-0 !rounded-[5px] ${this.size() === MenuButtonSize.LARGE ? 'min-h-[50px]' : ''} ${this.buttonClasses()}`
    );
    readonly customStyle = 'box-shadow: none !important;';

    readonly MenuButtonSize = MenuButtonSize;
    readonly SvgIcon = SvgIcon;
}
