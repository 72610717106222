import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emojiPathResolver',
    standalone: true,
})
export class EmojiPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/${key}.png`;
    }
}
