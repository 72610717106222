<app-button
    [id]="id()"
    [text]="text()"
    [tooltip]="tooltip()"
    [disabled]="disabled()"
    [loading]="loading()"
    [buttonClasses]="customButtonClasses()"
    [customStyle]="customStyle"
    [testId]="testId()"
    [matMenuTriggerFor]="matMenu"
    [ngClass]="{ 'pointer-events-none': disabled() || loading() }">
    <ng-template #textTemplate>
        <div
            class="flex items-center"
            [ngClass]="{ 'h-[36px]': size() === MenuButtonSize.MEDIUM, 'h-[50px]': size() === MenuButtonSize.LARGE }">
            <div class="malou-text-12--medium flex h-full items-center border-r border-malou-color-background-white/50 pr-5">
                {{ text() }}
            </div>
            <div class="flex items-center pl-5">
                <mat-icon class="!w-4" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
            </div>
        </div>
    </ng-template>
</app-button>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #matMenu="matMenu">
    <ng-content></ng-content>
</mat-menu>
