<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex flex-col">
            <span class="malou-text-18--bold">{{ 'roi.estimated_customers.customers_details_modal_title' | translate }}</span>
            <span class="malou-text-10--regular italic text-malou-color-text-2">{{
                'roi.estimated_customers.customers_details_modal_subtitle' | translate
            }}</span>
        </div>

        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-8 mt-5 flex max-h-[65vh] flex-col gap-3 overflow-y-auto">
        @for (panel of estimatedCustomersDetailsTabs(); track panel) {
            <app-estimated-customer-panel
                [customerItems]="panel.customerItems"
                [explanation]="panel.explanation"
                [panelTitle]="panel.panelTitle"
                [previousCustomersEvolution]="panel.previousCustomersEvolution"
                [currentCustomerRange]="panel.currentCustomerRange"></app-estimated-customer-panel>
        }
    </div>
</div>
