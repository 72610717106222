import { z } from 'zod';

import { CampaignType, ClientSource, ContactMode, PlatformKey, RedirectStar } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getVerifiedEmailsInSESBodyValidator = z.object({
    emails: z.array(z.string()),
});
export type GetVerifiedEmailsInSESBodyDto = z.infer<typeof getVerifiedEmailsInSESBodyValidator>;

// ------------------------------------------------------------------------------------------

export const createCampaignBodyValidator = z.object({
    contactInteractions: z.array(
        z.object({
            clientId: objectIdValidator,
            lastStarRating: z.number().optional(),
            unsubscribedDate: z.coerce.date().optional(),
            openedDate: z.coerce.date().optional(),
            deliveredDate: z.coerce.date().optional(),
            lastStarRatingDate: z.coerce.date().optional(),
            _id: objectIdValidator.optional(),
        })
    ),
    name: z.string(),
    contactMode: z.nativeEnum(ContactMode),
    platformKey: z.nativeEnum(PlatformKey),
    redirectFrom: z.nativeEnum(RedirectStar),
    audience: z.object({
        sources: z.array(z.nativeEnum(ClientSource)),
        minDaysFromLastContactedAt: z.number().optional(),
        startLastVisitedAt: z.coerce.date().optional(),
        endLastVisitedAt: z.coerce.date().optional(),
    }),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    minDaysFromLastContactedAt: z.string().optional(),
    content: z.object({
        object: z.string(),
        messageHTML: z.string().optional(),
        from: z.object({
            name: z.string(),
            email: z.string().email(),
        }),
    }),
    type: z.nativeEnum(CampaignType).optional(),
    restaurantId: objectIdValidator,
});

export type CreateCampaignDto = z.infer<typeof createCampaignBodyValidator>;

// ------------------------------------------------------------------------------------------

export const deleteCampaignsByIdsBodyValidator = z.object({
    campaignIds: z.array(objectIdValidator),
});
export type DeleteCampaignsByIdsBodyDto = z.infer<typeof deleteCampaignsByIdsBodyValidator>;

// ------------------------------------------------------------------------------------------

export const deleteCampaignsByRestaurantIdQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type DeleteCampaignsByRestaurantIdQueryDto = z.infer<typeof deleteCampaignsByRestaurantIdQueryValidator>;
