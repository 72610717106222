import { TranslateService } from '@ngx-translate/core';

import { RoiSavedTimeDto } from '@malou-io/package-dto';
import { getNumberWithSpaces, PlatformKey, ReportType, UserTimedAction } from '@malou-io/package-utils';

export enum RoiSavedTimeMetric {
    REVIEW_REPLY = 'review_reply',
    SEO_POST = 'seo_post',
    SOCIAL_POST = 'social_post',
    INTERACTION = 'interaction',
    DUPLICATION = 'duplication',
    INFORMATION_UPDATE = 'information_update',
    CAMPAIGN = 'campaign',
    REPORTS = 'reports',
    STORIES = 'stories',
}

export interface RoiSavedTimeRow {
    title: string;
    details: string;
    currentTime: number;
    previousTime: number;
    emoji: RoiSavedTimeMetric;
}

export class RoiSavedTimeDetails {
    totalTime: number;

    // Reviews
    reviewsAnsweredAutomatically: UserTimedAction;
    reviewsAnsweredWithTemplate: UserTimedAction;
    reviewsAnsweredManually: UserTimedAction;
    reviewsAnsweredManuallyWithAi: UserTimedAction;
    reviewsAnsweredManuallyWithAiOnAggregatedView: UserTimedAction;
    reviewsAnsweredManuallyOnAggregatedView: UserTimedAction;
    reviewsAnsweredWithTemplateOnAggregatedView: UserTimedAction;

    // Seo posts
    publishedSeoPosts: UserTimedAction;
    aiGeneratedLegendSeoPosts: UserTimedAction;

    // Social posts
    publishedSocialPosts: UserTimedAction;
    publishedStories: UserTimedAction;
    aiGeneratedLegendSocialPosts: UserTimedAction;
    aiGeneratedHashtags: UserTimedAction;

    // Interactions
    answeredMentions: UserTimedAction;
    answeredComments: UserTimedAction;
    sentMessages: UserTimedAction;
    usedMessageTemplates: UserTimedAction;

    // Duplication
    duplicatedSeoPosts: UserTimedAction;
    duplicatedSocialPosts: UserTimedAction;
    duplicatedStories: UserTimedAction;
    duplicatedDescriptions: UserTimedAction;
    duplicatedAttributes: UserTimedAction;
    duplicatedKeywords: UserTimedAction;
    duplicatedMedia: UserTimedAction;
    duplicatedMessageTemplates: UserTimedAction;
    duplicatedReviewTemplates: UserTimedAction;
    duplicatedClients: UserTimedAction;

    // Information update
    informationUpdateClicks: UserTimedAction;

    // Campaigns
    launchedCampaigns: UserTimedAction;
    launchedCampaignsClients: UserTimedAction;

    // Reports
    reviewsReports: UserTimedAction;
    aggregatedReviewsReports: UserTimedAction;
    performanceReports: UserTimedAction;
    aggregatedPerformanceReports: UserTimedAction;
    activatedReports: UserTimedAction;

    connectedPlatforms: PlatformKey[];

    constructor(init: RoiSavedTimeDto, connectedPlatforms: PlatformKey[] = []) {
        this.totalTime = init.totalTime;

        // Reviews
        this.reviewsAnsweredAutomatically = init.reviewsAnsweredAutomatically;
        this.reviewsAnsweredWithTemplate = init.reviewsAnsweredWithTemplate;
        this.reviewsAnsweredManually = init.reviewsAnsweredManually;
        this.reviewsAnsweredManuallyWithAi = init.reviewsAnsweredManuallyWithAi;
        this.reviewsAnsweredManuallyWithAiOnAggregatedView = init.reviewsAnsweredManuallyWithAiOnAggregatedView;
        this.reviewsAnsweredManuallyOnAggregatedView = init.reviewsAnsweredManuallyOnAggregatedView;
        this.reviewsAnsweredWithTemplateOnAggregatedView = init.reviewsAnsweredWithTemplateOnAggregatedView;

        // Seo posts
        this.publishedSeoPosts = init.publishedSeoPosts;
        this.aiGeneratedLegendSeoPosts = init.aiGeneratedLegendSeoPosts;

        // Social posts
        this.publishedSocialPosts = init.publishedSocialPosts;
        this.publishedStories = init.publishedStories;
        this.aiGeneratedLegendSocialPosts = init.aiGeneratedLegendSocialPosts;
        this.aiGeneratedHashtags = init.aiGeneratedHashtags;

        // Interactions
        this.answeredMentions = init.answeredMentions;
        this.answeredComments = init.answeredComments;
        this.sentMessages = init.sentMessages;
        this.usedMessageTemplates = init.usedMessageTemplates;

        // Duplication
        this.duplicatedSeoPosts = init.duplicatedSeoPosts;
        this.duplicatedSocialPosts = init.duplicatedSocialPosts;
        this.duplicatedStories = init.duplicatedStories;
        this.duplicatedDescriptions = init.duplicatedDescriptions;
        this.duplicatedAttributes = init.duplicatedAttributes;
        this.duplicatedKeywords = init.duplicatedKeywords;
        this.duplicatedMedia = init.duplicatedMedia;
        this.duplicatedMessageTemplates = init.duplicatedMessageTemplates;
        this.duplicatedReviewTemplates = init.duplicatedReviewTemplates;
        this.duplicatedClients = init.duplicatedClients;

        // Information update
        this.informationUpdateClicks = init.informationUpdateClicks;

        // Campaigns
        this.launchedCampaigns = init.launchedCampaigns;
        this.launchedCampaignsClients = init.launchedCampaignsClients;
        this.connectedPlatforms = connectedPlatforms;

        // Reports
        this.reviewsReports = init.reviewsReports;
        this.aggregatedReviewsReports = init.aggregatedReviewsReports;
        this.performanceReports = init.performanceReports;
        this.aggregatedPerformanceReports = init.aggregatedPerformanceReports;
        this.activatedReports = init.activatedReports;
    }

    buildRoiSavedTimeRows(previousData: RoiSavedTimeDetails, translate: TranslateService): RoiSavedTimeRow[] {
        const rows: RoiSavedTimeRow[] = [];
        for (const metric of this._getAvailableMetrics()) {
            const title = this._getTitleMetric(metric, translate);
            const details = this._getDetailsForMetric(metric, translate);
            const currentTime = this._computeMetricTime(metric);
            const previousTime = previousData ? previousData._computeMetricTime(metric) : 0;
            rows.push({
                title,
                details,
                currentTime,
                previousTime,
                emoji: metric,
            });
        }
        return rows;
    }

    private _computeMetricTime(metric: RoiSavedTimeMetric): number {
        switch (metric) {
            case RoiSavedTimeMetric.REVIEW_REPLY:
                return (
                    this.reviewsAnsweredAutomatically.totalTime +
                    this.reviewsAnsweredManually.totalTime +
                    this.reviewsAnsweredWithTemplate.totalTime +
                    this.reviewsAnsweredManuallyWithAi.totalTime +
                    this.reviewsAnsweredManuallyOnAggregatedView.totalTime +
                    this.reviewsAnsweredWithTemplateOnAggregatedView.totalTime +
                    this.reviewsAnsweredManuallyWithAiOnAggregatedView.totalTime
                );
            case RoiSavedTimeMetric.SEO_POST:
                return this.publishedSeoPosts.totalTime + this.aiGeneratedLegendSeoPosts.totalTime;
            case RoiSavedTimeMetric.SOCIAL_POST:
                return (
                    this.publishedSocialPosts.totalTime + this.aiGeneratedLegendSocialPosts.totalTime + this.aiGeneratedHashtags.totalTime
                );
            case RoiSavedTimeMetric.STORIES:
                return this.publishedStories.totalTime;
            case RoiSavedTimeMetric.INTERACTION:
                return (
                    this.answeredMentions.totalTime +
                    this.answeredComments.totalTime +
                    this.sentMessages.totalTime +
                    this.usedMessageTemplates.totalTime
                );
            case RoiSavedTimeMetric.DUPLICATION:
                return (
                    this.duplicatedSeoPosts.totalTime +
                    this.duplicatedSocialPosts.totalTime +
                    this.duplicatedStories.totalTime +
                    this.duplicatedDescriptions.totalTime +
                    this.duplicatedAttributes.totalTime +
                    this.duplicatedKeywords.totalTime +
                    this.duplicatedMedia.totalTime +
                    this.duplicatedMessageTemplates.totalTime +
                    this.duplicatedReviewTemplates.totalTime +
                    this.duplicatedClients.totalTime
                );
            case RoiSavedTimeMetric.INFORMATION_UPDATE:
                return this.informationUpdateClicks.totalTime;
            case RoiSavedTimeMetric.CAMPAIGN:
                return this.launchedCampaignsClients.totalTime;
            case RoiSavedTimeMetric.REPORTS:
                return (
                    this.reviewsReports.totalTime +
                    this.aggregatedReviewsReports.totalTime +
                    this.performanceReports.totalTime +
                    this.aggregatedPerformanceReports.totalTime
                );
            default:
                return 0;
        }
    }

    private _getTitleMetric(metric: RoiSavedTimeMetric, translate: TranslateService): string {
        switch (metric) {
            case RoiSavedTimeMetric.REVIEW_REPLY:
                return translate.instant('roi.saved_time.review_reply_title');
            case RoiSavedTimeMetric.SEO_POST:
                return translate.instant('roi.saved_time.seo_post_title');
            case RoiSavedTimeMetric.SOCIAL_POST:
                return translate.instant('roi.saved_time.social_post_title');
            case RoiSavedTimeMetric.STORIES:
                return translate.instant('roi.saved_time.stories_title');
            case RoiSavedTimeMetric.INTERACTION:
                return translate.instant('roi.saved_time.interaction_title');
            case RoiSavedTimeMetric.DUPLICATION:
                return translate.instant('roi.saved_time.duplication_title');
            case RoiSavedTimeMetric.INFORMATION_UPDATE:
                return translate.instant('roi.saved_time.information_update_title');
            case RoiSavedTimeMetric.CAMPAIGN:
                return this.launchedCampaigns?.count > 1
                    ? translate.instant('roi.saved_time.campaign_title.multiple', {
                          nbCampaign: this.launchedCampaigns?.count,
                      })
                    : translate.instant('roi.saved_time.campaign_title.one', {
                          nbCampaign: this.launchedCampaigns?.count,
                      });
            case RoiSavedTimeMetric.REPORTS:
                return translate.instant('roi.saved_time.reports', {
                    nbReports: this.activatedReports.count,
                    totalReports: Object.keys(ReportType).length,
                });
            default:
                return '';
        }
    }

    private _getDetailsForMetric(metric: RoiSavedTimeMetric, translate: TranslateService): string {
        switch (metric) {
            case RoiSavedTimeMetric.REVIEW_REPLY:
                return translate.instant('roi.saved_time.review_reply', {
                    nbReviews:
                        this.reviewsAnsweredAutomatically.count +
                        this.reviewsAnsweredManually.count +
                        this.reviewsAnsweredManuallyWithAi.count +
                        this.reviewsAnsweredWithTemplate.count +
                        this.reviewsAnsweredManuallyWithAiOnAggregatedView.count +
                        this.reviewsAnsweredManuallyOnAggregatedView.count +
                        this.reviewsAnsweredWithTemplateOnAggregatedView.count,
                });
            case RoiSavedTimeMetric.SEO_POST:
                const additionalText = this.aiGeneratedLegendSeoPosts.count
                    ? translate.instant('roi.saved_time.seo_post_details', { nbLegends: this.aiGeneratedLegendSeoPosts.count })
                    : '';
                return `${translate.instant('roi.saved_time.seo_post', {
                    nbPosts: this.publishedSeoPosts.count,
                })} ${additionalText}`;
            case RoiSavedTimeMetric.SOCIAL_POST:
                const legends = this.aiGeneratedLegendSocialPosts.count
                    ? translate.instant('roi.saved_time.social_post_legend_detail', { nbLegends: this.aiGeneratedLegendSocialPosts.count })
                    : '';
                const hashtags = this.aiGeneratedHashtags.count
                    ? translate.instant('roi.saved_time.social_post_hashtags_detail', {
                          aiGeneratedHashtags: this.aiGeneratedHashtags.count,
                      })
                    : '';

                const aiGenerated = translate.instant('roi.saved_time.generated_by_ia');
                const including = translate.instant('roi.saved_time.including');
                const and = translate.instant('roi.saved_time.and');
                let middleText = '';
                if (legends && hashtags) {
                    middleText = `${including} ${legends} ${and} ${hashtags} ${aiGenerated}`;
                } else if (legends) {
                    middleText = `${including} ${legends} ${aiGenerated}`;
                } else if (hashtags) {
                    middleText = `${including} ${hashtags} ${aiGenerated}`;
                }

                const posts = translate.instant('roi.saved_time.social_post', { nbPosts: this.publishedSocialPosts.count });
                return `${posts} ${middleText}`;
            case RoiSavedTimeMetric.STORIES:
                return translate.instant('roi.saved_time.stories', { nbStories: this.publishedStories.count });
            case RoiSavedTimeMetric.INTERACTION:
                return translate.instant('roi.saved_time.interaction', {
                    nbComments: this.answeredComments.count,
                    nbMentions: this.answeredMentions.count,
                    nbMessagesSent: this.sentMessages.count,
                });
            case RoiSavedTimeMetric.DUPLICATION:
                return translate.instant('roi.saved_time.duplication', {
                    nbPosts: this.duplicatedSeoPosts.count + this.duplicatedSocialPosts.count + this.duplicatedStories.count,
                    nbElements:
                        this.duplicatedDescriptions.count +
                        this.duplicatedAttributes.count +
                        this.duplicatedKeywords.count +
                        this.duplicatedMedia.count +
                        this.duplicatedMessageTemplates.count +
                        this.duplicatedReviewTemplates.count +
                        this.duplicatedClients.count,
                });
            case RoiSavedTimeMetric.INFORMATION_UPDATE:
                return translate.instant('roi.saved_time.information_update', {
                    nbUpdates: this.informationUpdateClicks.count,
                });
            case RoiSavedTimeMetric.CAMPAIGN:
                return translate.instant('roi.saved_time.campaign', {
                    nbClients: this.launchedCampaignsClients.count,
                });
            case RoiSavedTimeMetric.REPORTS:
                return translate.instant('roi.saved_time.sent_reports', {
                    sentEmails: getNumberWithSpaces(
                        this.reviewsReports.count +
                            this.aggregatedReviewsReports.count +
                            this.performanceReports.count +
                            this.aggregatedPerformanceReports.count
                    ),
                });
            default:
                return '';
        }
    }

    private _getAvailableMetrics(): RoiSavedTimeMetric[] {
        const roiSavedTimeMetric: RoiSavedTimeMetric[] = [];

        if (this.connectedPlatforms.includes(PlatformKey.GMB) || this.connectedPlatforms.includes(PlatformKey.FACEBOOK)) {
            roiSavedTimeMetric.push(RoiSavedTimeMetric.REVIEW_REPLY);
        }

        if (this.connectedPlatforms.includes(PlatformKey.GMB)) {
            roiSavedTimeMetric.push(RoiSavedTimeMetric.SEO_POST);
        }

        if (this.connectedPlatforms.includes(PlatformKey.FACEBOOK) || this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiSavedTimeMetric.push(RoiSavedTimeMetric.SOCIAL_POST);
        }

        if (this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiSavedTimeMetric.push(RoiSavedTimeMetric.STORIES);
        }

        if (this.connectedPlatforms.includes(PlatformKey.FACEBOOK) || this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiSavedTimeMetric.push(RoiSavedTimeMetric.INTERACTION);
        }

        roiSavedTimeMetric.push(RoiSavedTimeMetric.DUPLICATION);
        roiSavedTimeMetric.push(RoiSavedTimeMetric.INFORMATION_UPDATE);
        roiSavedTimeMetric.push(RoiSavedTimeMetric.CAMPAIGN);
        roiSavedTimeMetric.push(RoiSavedTimeMetric.REPORTS);

        return roiSavedTimeMetric;
    }
}
