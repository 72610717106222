import { Routes } from '@angular/router';

import { AutomationsComponent } from './automations.component';

export const AUTOMATIONS_ROUTES: Routes = [
    {
        path: '',
        component: AutomationsComponent,
    },
];
