import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';

type AuthorizedPlatformKeys =
    | PlatformKey.TRIPADVISOR
    | PlatformKey.FOURSQUARE
    | PlatformKey.YELP
    | PlatformKey.LAFOURCHETTE
    | PlatformKey.PAGESJAUNES
    | PlatformKey.RESY;

export const URLS_REGEXES: Record<AuthorizedPlatformKeys | 'yelpPro', RegExp> = {
    [PlatformKey.TRIPADVISOR]: new RegExp(/^https:\/\/[a-z]{0,3}\.?tripadvisor\.[a-z]{2,3}\/.*(d\d{1,10})-?/),
    [PlatformKey.FOURSQUARE]: new RegExp(/https:\/\/([a-z]{2}\.)?foursquare\.com\/(v\/)?([a-z0-9]+)\/?/),
    [PlatformKey.YELP]: new RegExp(/^https:\/\/.*yelp\.(fr|com)\/biz\/([^?]+)\??/),
    yelpPro: new RegExp(/^https:\/\/(www\.)?biz.yelp\.(fr|com)\/biz_info\/([a-zA-Z0-9-_]+)+$/),
    [PlatformKey.LAFOURCHETTE]: new RegExp(/^https:\/\/(www\.)?(thefork|lafourchette)\.(fr|com|co\.uk)\/restaurant\/.*r(\d{1,7})/),
    [PlatformKey.PAGESJAUNES]: new RegExp(/^https:\/\/www\.pagesjaunes\.fr\/pros\/(detail\?bloc_id=)?(FCP)?(\d*)/),
    [PlatformKey.RESY]: new RegExp(/^https:\/\/resy\.com\/cities\/([a-z-]+\/venues\/[a-z-]+)/),
};
@Injectable({
    providedIn: 'root',
})
export class ExtractSocialIdFromUrlService {
    private readonly _platformsService = inject(PlatformsService);

    execute(platformKey: AuthorizedPlatformKeys, url: string): Observable<string | null> {
        switch (platformKey) {
            case PlatformKey.TRIPADVISOR: {
                return of(this._extractTripadvisorSocialId(url));
            }
            case PlatformKey.FOURSQUARE: {
                return of(this._extractFoursquareSocialId(url));
            }
            case PlatformKey.YELP: {
                return this._extractYelpSocialId(url);
            }
            case PlatformKey.LAFOURCHETTE: {
                return of(this._extractLaFourchetteSocialId(url));
            }
            case PlatformKey.PAGESJAUNES: {
                return this._extractPageJaunesSocialId(url);
            }
            case PlatformKey.RESY: {
                return this._extractResySocialId(url);
            }
        }
        return of(null);
    }

    private _extractTripadvisorSocialId(url: string): string | null {
        const match = url.match(URLS_REGEXES[PlatformKey.TRIPADVISOR]);

        const socialIdRaw = match?.[1];
        if (!socialIdRaw) {
            return null;
        }
        if (socialIdRaw.match(/^details\=\d{1,10}$/)) {
            return socialIdRaw.replace('details=', '');
        }
        if (socialIdRaw.match(/^detail\=\d{1,10}$/)) {
            return socialIdRaw.replace('detail=', '');
        }
        if (socialIdRaw.match(/^d\d{1,10}$/)) {
            return socialIdRaw.replace('d', '');
        }
        return null;
    }

    private _extractFoursquareSocialId(url: string): string | null {
        const match = url.match(URLS_REGEXES[PlatformKey.FOURSQUARE]);

        const socialIdRaw = match?.[3];
        if (!socialIdRaw) {
            return null;
        }
        if (socialIdRaw.match(/[a-z0-9]/)) {
            return socialIdRaw;
        }
        return null;
    }

    private _extractYelpSocialId(url: string): Observable<string | null> {
        let urlToScrap: string;
        const socialIdPro = url.match(URLS_REGEXES.yelpPro)?.[3];
        if (socialIdPro) {
            urlToScrap = `https://www.yelp.fr/biz/${socialIdPro}`;
        } else {
            urlToScrap = url;
        }
        const businessId$: Observable<string | null> = this._platformsService.scrapPlatformEndpoint(PlatformKey.YELP, urlToScrap).pipe(
            map((res) => {
                const html = res.data;
                const socialIdCaptureRegex = /(?<=writeareview\/biz\/)((?:\d|-|_|\w)+)/;
                const socialId = html.match(socialIdCaptureRegex)?.[0];
                return socialId ?? null;
            }),
            catchError(() => {
                // if scrapping fails, try to find the alias in the url ->
                // https://www.yelp.fr/biz/le-souffl%C3%A9-paris-2 => le-souffl%C3%A9-paris-2
                if (socialIdPro) {
                    return of(socialIdPro);
                }

                const socialIdPublic = url.match(URLS_REGEXES[PlatformKey.YELP])?.[2];
                if (socialIdPublic) {
                    return of(socialIdPublic);
                }

                return of(null);
            })
        );
        return businessId$;
    }

    private _extractLaFourchetteSocialId(url: string): string | null {
        const match = url.match(URLS_REGEXES[PlatformKey.LAFOURCHETTE])?.[4];
        if (!match) {
            return null;
        }
        if (!match.match(/^\d{1,7}$/)) {
            return null;
        }
        return match;
    }

    private _extractPageJaunesSocialId(url: string): Observable<string | null> {
        const directSocialId = url.match(URLS_REGEXES[PlatformKey.PAGESJAUNES])?.[3];
        if (!directSocialId) {
            return of(null);
        }
        if (directSocialId?.match(/^\d{0,9}$/)) {
            return of(directSocialId);
        }
        return this._platformsService.scrapPlatformEndpoint(PlatformKey.PAGESJAUNES, url).pipe(
            map((res) => {
                const regExp2 = new RegExp(/"code_etab": "(\d{0,9})"/);
                return res.data.match(regExp2)?.[1] ?? null;
            })
        );
    }

    private _extractResySocialId(url: string): Observable<string | null> {
        const match = url.match(URLS_REGEXES[PlatformKey.RESY])?.[1];
        if (!match) {
            return of(null);
        }
        if (!match.match(/^[a-z-]+\/venues\/[a-z-]+/)) {
            return of(null);
        }
        return of(match);
    }
}
