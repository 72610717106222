export enum GiftClaimStartDateOption {
    NOW = 'NOW',
    TOMORROW = 'TOMORROW',
}

export enum NextDrawEnabledDelay {
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
    AFTER_24_HOURS = 'AFTER_24_HOURS',
    AFTER_48_HOURS = 'AFTER_48_HOURS',
    AFTER_ONE_WEEK = 'AFTER_ONE_WEEK',
}

export const GiftClaimDurationInDaysOption = [
    2,
    7,
    15,
    30, // 1 month
    60, // 2 months
    182, // 6 months
];
