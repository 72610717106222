import { z } from 'zod';

import { Locale, WatcherStatus } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const startKeywordsGenerationParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type StartKeywordsGenerationParamsDto = z.infer<typeof startKeywordsGenerationParamsValidator>;

export const startKeywordsGenerationQueryValidator = z.object({
    langs: z.array(z.nativeEnum(Locale)),
});
export type StartKeywordsGenerationQueryDto = z.infer<typeof startKeywordsGenerationQueryValidator>;

export interface StartKeywordsGenerationResponseDto {
    status: WatcherStatus;
}
