import { z } from 'zod';

export const getBricksPaginatedQueryValidator = z
    .object({
        page_number: z.coerce.number().optional(),
        page_size: z.coerce.number().optional(),
        total: z.coerce.number().optional(),
    })
    .transform((data) => ({
        pageNumber: data.page_number,
        pageSize: data.page_size,
        total: data.total,
    }));
export type GetBricksQueryDto = z.infer<typeof getBricksPaginatedQueryValidator>;
