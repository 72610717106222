import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, input, Signal, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

export enum LoaderProgressSize {
    SM = 'small',
    MD = 'medium',
    LG = 'large',
}

export enum LoaderProgressColorClass {
    PRIMARY = 'bg-malou-color-gradient-primary',
    SUCCESS = 'bg-malou-color-gradient-success',
    WARNING = 'bg-malou-color-gradient-warning',
    ERROR = 'bg-malou-color-gradient-error',
}

@Component({
    selector: 'app-loader-progress',
    templateUrl: './loader-progress.component.html',
    styleUrls: ['./loader-progress.component.scss'],
    standalone: true,
    imports: [NgClass, MatButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderProgressComponent {
    @Input() progress: Signal<number>;
    @Input() set loaderSize(value: LoaderProgressSize) {
        this.size.set(value);
    }
    @Input() showPercentage = true;

    readonly progressCompletedAction = input<{ message: string; action: () => void }>();
    readonly progressCompletedText = input<string>();
    readonly progressBarColorClass = input<string>(LoaderProgressColorClass.PRIMARY);
    readonly backgroundColor = input('bg-malou-color-background-dark');

    readonly LoaderProgressSize = LoaderProgressSize;

    readonly height = computed(() => {
        switch (this.size()) {
            case LoaderProgressSize.SM:
                return '10px';
            case LoaderProgressSize.LG:
                return '20px';
            default:
                return '15px';
        }
    });
    readonly progressPercentage = computed(() => (this.progress ? `${this.progress()}%` : '0%'));
    readonly size = signal(LoaderProgressSize.MD);

    readonly action = computed(() => {
        const progressCompletedAction = this.progressCompletedAction();
        return progressCompletedAction ? progressCompletedAction.action : this._defaultAction;
    });

    readonly shouldDisplayProgressCompletedAction = computed(
        () => !!this.progressCompletedAction() && !this.progressCompletedText() && this.progress() === 100
    );
    readonly shouldDisplayProgressCompletedMessage = computed(() => !!this.progressCompletedText() && this.progress() === 100);
    readonly actionMessage = computed(() => {
        const progressCompletedAction = this.progressCompletedAction();
        return progressCompletedAction ? progressCompletedAction.message : '';
    });

    private _defaultAction = (): void => {
        console.error('Not implemented');
    };
}
