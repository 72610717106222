<div class="h-full w-full px-7.5 pb-3">
    <div class="malou-color-bg-white flex h-full w-full items-center justify-center rounded-xl rounded-r-[10px]">
        <div class="flex w-full max-w-[550px] flex-col items-center">
            <div class="malou-text-18--bold mb-2 text-malou-color-text-1">
                {{ title() }}
            </div>
            <div class="malou-text-13--regular text-malou-color-text-2">
                {{ 'keywords.keywords_generating_loader.this_may_take_several_minutes' | translate }}
            </div>
            <div class="my-8 w-full max-w-[420px]">
                <app-loader-progress [loaderSize]="LoaderProgressSize.LG" [progress]="generationProgress"></app-loader-progress>
            </div>
            <div class="malou-text-13 text-center italic text-malou-color-text-2">
                {{ footerText() }}
            </div>
        </div>
    </div>
</div>
