import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { FeedbacksPanelComponent } from ':shared/components/feedbacks-panel/feedbacks-panel.component';
import { PostCaptionAiGenerationComponent } from ':shared/components/post-caption-ai-generation/post-caption-ai-generation.component';
import { StaticFeedComponent } from ':shared/components/static-feed/static-feed.component';
import { FeedbackMessage } from ':shared/models/feedback';
import { DefaultPrompt } from ':shared/openai-prompt/openai-prompt.component';

import { RightPanel } from '../../context/enums';
import { NewSocialPostAiContext } from '../../context/new-social-post-ai.context';
import { NewSocialPostContext } from '../../context/new-social-post.context';
import { PostChooseHashtagsPanelComponent } from './post-choose-hashtags-panel/post-choose-hashtags-panel.component';

@Component({
    selector: 'app-post-right-side-panel',
    templateUrl: './post-right-side-panel.component.html',
    styleUrls: ['./post-right-side-panel.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        TranslateModule,
        MatButtonModule,
        FeedbacksPanelComponent,
        StaticFeedComponent,
        PostCaptionAiGenerationComponent,
        PostChooseHashtagsPanelComponent,
    ],
})
export class PostRightSidePanelComponent {
    readonly newSocialPostContext = inject(NewSocialPostContext);
    readonly newSocialPostAiContext = inject(NewSocialPostAiContext);
    readonly _translate = inject(TranslateService);
    readonly _screenSizeService = inject(ScreenSizeService);

    readonly defaultPrompt = signal(DefaultPrompt.GENERATE_POST);

    readonly isSmallScreen = toSignal(this._screenSizeService.resize$.pipe(map((elt) => elt.size === ScreenSize.IsSmallScreen)));

    changeDisplayedRightPanel(panel: RightPanel): void {
        this.newSocialPostContext.displayedRightPanel.update(() => panel);
    }

    onUpdateDisplayedMessages(messages: FeedbackMessage[]): void {
        this.newSocialPostContext.displayedFeedbackMessages.update(() => messages);
    }
}
