import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, effect, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, isNumber } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, retry, switchMap, tap } from 'rxjs/operators';

import { AggregationTimeScale, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    createDateWithClientTimeZoneDifference,
    getDaysFromCurrentRange,
    getMonthsFromPeriod,
    getWeeksFromCurrentRange,
    isDateSetOrGenericPeriod,
    Month,
    WeekRange,
} from ':shared/helpers';
import {
    DailyValue,
    DatesAndPeriod,
    getInsightsErrorText,
    InsightsByPlatform,
    MetricToDataValues,
    MonthlyValue,
    Restaurant,
    WeeklyValue,
} from ':shared/models';
import { GmbInsights } from ':shared/models/gmb-insight';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { InsightsService } from '../../insights.service';
import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import * as StatisticsSelector from '../../store/statistics.selectors';
import { GmbDiscoveriesData, GmbImpressionsChartComponent } from './gmb-impressions-chart/gmb-impressions-chart.component';

interface MetricAndKey {
    metric: MalouMetric;
    key: string;
}

const GMB_DATA_RETENTION_IN_MONTHS = 18;
const GMB_DATA_FETCH_DELAY_IN_DAYS = 4;

const GMB_METRICS: MetricAndKey[] = [
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
        key: 'impressionsDesktopMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
        key: 'impressionsDesktopSearch',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
        key: 'impressionsMobileMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
        key: 'impressionsMobileSearch',
    },
];

@Component({
    selector: 'app-statistics-seo-gmb-discoveries',
    templateUrl: './gmb-impressions.component.html',
    styleUrls: ['./gmb-impressions.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatTooltipModule,
        NgTemplateOutlet,
        SkeletonComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        GmbImpressionsChartComponent,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        LowerCasePipe,
        ApplyPurePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class GmbImpressionsComponent implements OnInit {
    @Input() showViewByTextInsteadOfSelector = false;
    @Input() viewBy?: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() viewByChange = new EventEmitter<ViewBy>();
    @Output() hiddenDatasetIndexesChange = new EventEmitter<number[]>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly DATE_NOW_MINUS_4_DAYS = DateTime.now()
        .minus(Duration.fromObject({ days: 4 }))
        .toFormat('DD');
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    readonly ViewBy = ViewBy;

    httpError: any;
    insightsError: string | null = null;
    readonly isLoading = signal(true);
    readonly isPreviousPeriodMaxRangeReached = signal(false);

    currentGmbInsights: GmbInsights | null = null;
    previousGmbInsights: GmbInsights | null = null;
    impressionsEvolutionPercentage: number | null = null;

    isGmbConnected$ = of(true);

    gmbDiscoveriesData: GmbDiscoveriesData;
    dateLabels: Date[] = [];

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _insightsService: InsightsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByFilterSubject$.next(this.viewBy);
        }

        this.isGmbConnected$ = this._store
            .select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB }))
            .pipe(map((platform) => !!platform?.credentials?.length && platform.credentials.length > 0));

        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.viewByFilterSubject$])
            .pipe(
                filter(
                    ([_restaurant, dates, actionsViewBy]) =>
                        isDateSetOrGenericPeriod(dates) && Object.values(ViewBy).includes(actionsViewBy)
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, actionsViewBy]: [Restaurant, DatesAndPeriod, ViewBy]) => {
                    this.viewByChange.emit(actionsViewBy);
                    const { startDate, endDate } = this._adjustEndDateBasedOnDelay(dates);
                    const insightAggregator = this._computeInsightAggregatorFromViewByFilter(actionsViewBy);
                    const { _id: restaurantId } = restaurant;
                    return forkJoin([
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: [PlatformKey.GMB],
                                metrics: GMB_METRICS.map((m) => m.metric),
                                aggregators: compact([insightAggregator]),
                                startDate,
                                endDate,
                            })
                            .pipe(
                                retry({
                                    count: 2,
                                    delay: () => timer(1000),
                                }),
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformsKeys: [PlatformKey.GMB],
                                metrics: GMB_METRICS.map((m) => m.metric),
                                aggregators: [AggregationTimeScale.TOTAL, ...compact([insightAggregator])],
                                startDate,
                                endDate,
                                previousPeriod: true,
                            })
                            .pipe(
                                retry({
                                    count: 2,
                                    delay: () => timer(1000),
                                }),
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    if (!error.error?.message?.match(/Time range too long. Maximum start time is 18 months ago/)) {
                                        this.httpError = error;
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    }
                                    return of({});
                                })
                            ),
                        of(startDate),
                        of(endDate),
                        of(actionsViewBy),
                    ]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([currentInsights, previousInsights, startDate, endDate, actionsViewBy]: [
                    InsightsByPlatform,
                    InsightsByPlatform,
                    Date,
                    Date,
                    ViewBy,
                ]) => {
                    const currentPlatformInsights = currentInsights[PlatformKey.GMB];
                    const previousPlatformInsights = previousInsights[PlatformKey.GMB];
                    const datesRange = endDate.getTime() - startDate.getTime();
                    const previousStartDate = new Date(startDate.getTime() - datesRange);
                    const previousEndDate = new Date(endDate.getTime() - datesRange);

                    const isStartBefore18Months =
                        DateTime.fromJSDate(previousStartDate) < DateTime.now().minus({ month: GMB_DATA_RETENTION_IN_MONTHS });
                    if (isStartBefore18Months) {
                        this.isPreviousPeriodMaxRangeReached.set(true);
                    }

                    // Data and Labels computing for chart
                    if (actionsViewBy === ViewBy.DAY) {
                        const gmbInsightsByDay = currentPlatformInsights?.by_day;
                        if (!gmbInsightsByDay) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const days = getDaysFromCurrentRange(startDate, endDate);
                        this.dateLabels = [...days];
                        this.currentGmbInsights = this._mapDailyInsightsToChart(gmbInsightsByDay, days);
                        if (previousPlatformInsights?.by_day) {
                            const previousDays = getDaysFromCurrentRange(previousStartDate, previousEndDate);
                            this.previousGmbInsights = this._mapDailyInsightsToChart(previousPlatformInsights?.by_day, previousDays);
                        }
                    } else if (actionsViewBy === ViewBy.WEEK) {
                        const gmbInsightsByWeek = currentPlatformInsights?.by_week;
                        if (!gmbInsightsByWeek) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const weeks = getWeeksFromCurrentRange(startDate, endDate);
                        this.dateLabels = weeks.map((week) => week.start);
                        this.currentGmbInsights = this._mapWeeklyInsightsToChart(gmbInsightsByWeek, weeks);
                        if (previousPlatformInsights?.by_week) {
                            const previousWeeks = getWeeksFromCurrentRange(previousStartDate, previousEndDate);
                            this.previousGmbInsights = this._mapWeeklyInsightsToChart(previousPlatformInsights?.by_week, previousWeeks);
                        }
                    } else if (actionsViewBy === ViewBy.MONTH) {
                        const gmbInsightsByMonth = currentPlatformInsights?.by_month;
                        if (!gmbInsightsByMonth) {
                            this._setInsightsError(currentPlatformInsights?.message);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return;
                        }
                        const months: Month[] = getMonthsFromPeriod(startDate, endDate);
                        this.dateLabels = months.map((e) => e.start);
                        this.currentGmbInsights = this._mapMonthlyInsightsToChart(gmbInsightsByMonth, months);
                        if (previousPlatformInsights?.by_month) {
                            const previousMonths: Month[] = getMonthsFromPeriod(previousStartDate, previousEndDate);
                            this.previousGmbInsights = this._mapMonthlyInsightsToChart(previousPlatformInsights?.by_month, previousMonths);
                        }
                    }

                    this.gmbDiscoveriesData = {
                        previousPeriodDates: this.previousGmbInsights?.dates || [],
                        impressionsMaps: this.currentGmbInsights?.impressionsMaps || [],
                        impressionsSearch: this.currentGmbInsights?.impressionsSearch || [],
                        previousImpressionsMaps: this.previousGmbInsights?.impressionsMaps || [],
                        previousImpressionsSearch: this.previousGmbInsights?.impressionsSearch || [],
                    };

                    const totalPreviousGmbInsights = previousInsights ? this._mapTotalInsights(previousInsights) : null;

                    // Value computing for Actions card
                    const currentImpressions = this.currentGmbInsights?.totalImpressions;
                    const previousImpressions = totalPreviousGmbInsights?.totalImpressions;
                    if (currentImpressions === undefined || previousImpressions === undefined || previousImpressions === 0) {
                        this.impressionsEvolutionPercentage = null;
                    } else {
                        this.impressionsEvolutionPercentage = ((currentImpressions - previousImpressions) / previousImpressions) * 100;
                    }

                    this.isLoading.set(false);
                }
            );
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _setInsightsError(message?: string): void {
        this.insightsError = this._translate.instant(getInsightsErrorText(message), {
            platformName: this._enumTranslate.transform(PlatformKey.GMB, 'platform_key'),
        });
    }

    private _mapMonthlyInsightsToChart(gmbInsightsByMonth: MetricToDataValues<MonthlyValue>, months: Month[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            dates: months.map((m) => m.start),
        };

        const gmbInsightsByMonthByGmbDate: Partial<Record<MalouMetric, Record<string, MonthlyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByMonth).forEach((key) => {
            gmbInsightsByMonthByGmbDate[key] = (gmbInsightsByMonth[key] as MonthlyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.monthStart)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < months.length; index++) {
            const monthStart = months[index].start;
            const monthStartKey = monthStart.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByMonthByGmbDate[metric]?.[monthStartKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }
        return new GmbInsights(partialGmbInsights);
    }

    private _mapWeeklyInsightsToChart(gmbInsightsByWeek: MetricToDataValues<WeeklyValue>, weeks: WeekRange[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            dates: weeks.map((m) => m.start),
        };

        if (gmbInsightsByWeek.error) {
            return new GmbInsights(partialGmbInsights);
        }

        const gmbInsightsByWeekByGmbDate: Partial<Record<MalouMetric, Record<string, WeeklyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByWeek).forEach((key) => {
            gmbInsightsByWeekByGmbDate[key] = (gmbInsightsByWeek[key] as WeeklyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.weekStart)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < weeks.length; index++) {
            const weekStart = weeks[index].start;
            const weekStartKey = weekStart.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByWeekByGmbDate[metric]?.[weekStartKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }

        return new GmbInsights(partialGmbInsights);
    }

    private _mapDailyInsightsToChart(gmbInsightsByDay: MetricToDataValues<DailyValue>, days: Date[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            dates: days,
        };

        if (gmbInsightsByDay.error) {
            return new GmbInsights(partialGmbInsights);
        }

        const gmbInsightsByDayByGmbDate: Partial<Record<MalouMetric, Record<string, DailyValue>>> = {}; // { [metric]: { [2024-04-08]: value } }

        Object.keys(gmbInsightsByDay).forEach((key) => {
            gmbInsightsByDayByGmbDate[key] = (gmbInsightsByDay[key] as DailyValue[]).reduce(
                (acc, value) => ({
                    ...acc,
                    [createDateWithClientTimeZoneDifference(new Date(value.date)).toISOString().slice(0, 10)]: value,
                }),
                {}
            );
        });

        for (let index = 0; index < days.length; index++) {
            const day = days[index];
            const dayKey = day.toISOString().slice(0, 10);
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsByDayByGmbDate[metric]?.[dayKey]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }

        return new GmbInsights(partialGmbInsights);
    }

    private _mapTotalInsights(insights: InsightsByPlatform): GmbInsights | null {
        const totalInsights = insights[PlatformKey.GMB]?.total;
        if (!totalInsights) {
            return null;
        }

        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
        };

        for (const { metric, key: metricKey } of GMB_METRICS) {
            const metricByWeek = totalInsights[metric];
            partialGmbInsights[metricKey] = [metricByWeek?.value];
            if (!isNumber(partialGmbInsights[metricKey]?.[0])) {
                partialGmbInsights[metricKey] = [0];
            }
        }
        return new GmbInsights(partialGmbInsights);
    }

    private _reset(): void {
        this.httpError = null;
        this.insightsError = null;
        this.isLoading.set(true);
        this.isPreviousPeriodMaxRangeReached.set(false);
        this.currentGmbInsights = null;
        this.previousGmbInsights = null;
        this.dateLabels = [];
    }

    private _computeInsightAggregatorFromViewByFilter(viewBy: ViewBy): AggregationTimeScale | undefined {
        if (viewBy === ViewBy.DAY) {
            return AggregationTimeScale.BY_DAY;
        }
        if (viewBy === ViewBy.WEEK) {
            return AggregationTimeScale.BY_WEEK;
        }
        if (viewBy === ViewBy.MONTH) {
            return AggregationTimeScale.BY_MONTH;
        }
    }

    private _adjustEndDateBasedOnDelay(dates: DatesAndPeriod): { startDate: Date | null; endDate: Date | null } {
        const { startDate, endDate } = dates;
        if (!startDate || !endDate) {
            return { startDate, endDate };
        }
        const delayDate = DateTime.now().minus({ days: GMB_DATA_FETCH_DELAY_IN_DAYS });
        const newEndDate = DateTime.fromJSDate(endDate) < delayDate ? endDate : delayDate.toJSDate();

        return {
            startDate,
            endDate: newEndDate,
        };
    }
}
