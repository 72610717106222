import { AppFeatureName } from '../experimentation/types';
import { YextPublisherId } from '../modules/yext';
import { LanguageCodeISO_1 } from './languages';
import { PlatformCategory, PlatformGroup, PlatformKey, PlatformUpdateTime } from './platforms';

const informationKeySent = Object.freeze({
    name: 'name',
    coverUrl: 'coverUrl',
    logoUrl: 'logoUrl',
    address: 'address',
    website: 'website',
    categoryName: 'categoryName',
    secondaryCategoriesNames: 'secondaryCategoriesNames',
    menuUrl: 'menuUrl',
    orderUrl: 'orderUrl',
    reservationUrl: 'reservationUrl',
    phone: 'phone',
    openingDate: 'openingDate',
    regularHours: 'regularHours', // (monday, tuesday ...)
    specialHours: 'specialHours', // exceptional (holidays ...)
    otherHours: 'otherHours', // service (happy hours, delivery ...)
    longDescription: 'longDescription',
    isClosedTemporarily: 'isClosedTemporarily',
    shortDescription: 'shortDescription',
    attributes: 'attributes',
    xUrl: 'xUrl',
    instagramUrl: 'instagramUrl',
    facebookUrl: 'facebookUrl',
    linkedinUrl: 'linkedinUrl',
    youtubeUrl: 'youtubeUrl',
    pinterestUrl: 'pinterestUrl',
    tiktokUrl: 'tiktokUrl',
});

export interface PlatformDefinition {
    key: PlatformKey;
    fullName: string;

    // Urls
    apiUrl: string;
    apiParams?: string;
    apiVersion?: string;
    baseUrl: string;
    bizUrl: string;
    reviewsUrl: string;
    socialUrl: string;
    notionAdminLink: string;
    notionLink: string;
    updateLink?: (socialId: string) => string;
    accessLink?: (socialId: string) => string;
    externalReviewLink?: string;
    canRedirectToExternalReview: boolean;
    domainMap?: { [key in LanguageCodeISO_1]?: string };

    // Malou configs
    oauth: boolean;
    isAsynchronouslyScrapped: boolean;
    isApiConnectedForPlatformConnection: boolean;
    shouldFetchPermissions: boolean;
    category: PlatformCategory;
    group?: PlatformGroup;
    featureFlagKey?: AppFeatureName;
    allowsReviewReplyAutomation: boolean;

    // Informations
    shouldCompareInformation: boolean;
    hasInformationCategories: boolean;
    hasInformationHours: boolean;
    hasInformationSuggestions: boolean;
    canUpdateInformationWithYext: boolean;
    yextPublisherId?: YextPublisherId; // Add it when we have the platform handled by Yext and also by ourselves
    updateTime?: PlatformUpdateTime;

    // Review
    hasReviews: boolean;
    canReviewBeModified?: boolean;
    hasComments: boolean;
    hasRating: boolean;
    hasRatingOutOfTen?: boolean;
    hasReviewSemanticAnalysis: boolean;
    shouldDeleteReviewsBeforeUpsert: boolean;
    shouldDetectReviewsLang: boolean;
    // only used for frontend right now
    reviewCanHaveMultipleReplies: boolean;
    maxReviewsDaysInterval?: number;

    // Others
    hasMessages: boolean;
    hasPosts: boolean;
    hasDuplicablePosts?: boolean;
    hasStory: boolean;
    hasFeed: boolean;
    canDeleteStory?: boolean;
    hasMentions: boolean;
    canRedirectInWheelOfFortune: boolean; // Warning: if you change a platform to true, you need to add it in WheelOfFortuneRedirectionAcceptedPlatformKeys as well
    isRatedForTotems: boolean;
    hasFollowers: boolean;
    hasRSStats: boolean;
    hasReel: boolean;

    informationSent: string[];
}

export const platformsKeys: Record<keyof typeof PlatformKey, PlatformDefinition> = Object.freeze({
    GMB: {
        key: PlatformKey.GMB,
        fullName: 'Google',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        externalReviewLink: 'https://search.google.com/local/writereview?placeid=',
        canRedirectToExternalReview: true,

        accessLink: (socialId: string) => `https://search.google.com/local/writereview?placeid=${socialId}`,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SEO,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: true,
        hasInformationCategories: true,
        hasInformationHours: true,
        hasInformationSuggestions: true,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.DAILY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.address,
            informationKeySent.website,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.specialHours,
            informationKeySent.otherHours,
            informationKeySent.longDescription,
            informationKeySent.attributes,
            informationKeySent.menuUrl,
            informationKeySent.orderUrl,
            informationKeySent.reservationUrl,
            informationKeySent.tiktokUrl,
            informationKeySent.instagramUrl,
            informationKeySent.facebookUrl,
            informationKeySent.linkedinUrl,
            informationKeySent.youtubeUrl,
            informationKeySent.pinterestUrl,
            informationKeySent.xUrl,
        ],
    },
    TRIPADVISOR: {
        key: PlatformKey.TRIPADVISOR,
        fullName: 'TripAdvisor',
        baseUrl: 'https://www.tripadvisor.fr',
        apiUrl: 'https://www.tripadvisor.fr/data/graphql',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/TripAdvisor-5c7d8b3e1fa0450f93ddd6b490c4646b',
        notionAdminLink: 'https://help.malou.io/en/articles/1118658',
        updateLink: (socialId: string) => `https://www.tripadvisor.fr/businessinfo?locationId=${socialId}`,
        accessLink: (socialId: string) => `https://www.tripadvisor.fr/overview?locationId=${socialId}`,
        canRedirectToExternalReview: true,
        domainMap: {
            [LanguageCodeISO_1.FR]: 'www.tripadvisor.fr',
            [LanguageCodeISO_1.EN]: 'www.tripadvisor.com',
            [LanguageCodeISO_1.ES]: 'www.tripadvisor.es',
            [LanguageCodeISO_1.IT]: 'www.tripadvisor.it',
            [LanguageCodeISO_1.PT]: 'www.tripadvisor.pt',
            [LanguageCodeISO_1.RU]: 'www.tripadvisor.ru',
            // Japanese
            [LanguageCodeISO_1.JA]: 'www.tripadvisor.jp',
            // Chinese (simplified)
            [LanguageCodeISO_1.ZH]: 'www.tripadvisor.cn',
            // Vietnamese
            [LanguageCodeISO_1.VI]: 'www.tripadvisor.com.vn',
        },

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: true,
        yextPublisherId: YextPublisherId.TRIPADVISOR,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.longDescription,
            informationKeySent.address,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.specialHours,
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.coverUrl,
            informationKeySent.logoUrl,
            informationKeySent.isClosedTemporarily,
            informationKeySent.menuUrl,
            informationKeySent.website,
        ],
    },
    INSTAGRAM: {
        key: PlatformKey.INSTAGRAM,
        fullName: 'Instagram',
        apiUrl: 'https://graph.facebook.com',
        baseUrl: 'https://www.instagram.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Instagram-5601193c94714a2495005b40441800e2',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: false,
        hasComments: true,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: true,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: true,
        hasFeed: true,
        canDeleteStory: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: true,
        hasReel: true,

        informationSent: [],
    },
    FACEBOOK: {
        key: PlatformKey.FACEBOOK,
        fullName: 'Facebook',
        apiUrl: 'https://graph.facebook.com',
        baseUrl: 'https://www.facebook.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Facebook-50af956bc7d546dfa982b1d6f0e3996a',
        notionAdminLink: '',
        externalReviewLink: 'https://www.facebook.com/',
        canRedirectToExternalReview: false,

        accessLink: (socialId: string) => `https://www.facebook.com/${socialId}`,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: true,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: true,
        hasRating: false, // fb has rating but fb api is broken, we cant access this data: https://developers.facebook.com/community/threads/539633527347097/
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: true,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: true,
        hasFeed: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: true,
        hasReel: true,

        informationSent: [
            informationKeySent.logoUrl,
            informationKeySent.categoryName,
            informationKeySent.regularHours,
            informationKeySent.otherHours,
            informationKeySent.specialHours,
            informationKeySent.shortDescription,
            informationKeySent.attributes,
            informationKeySent.orderUrl,
            informationKeySent.website,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.phone,
            informationKeySent.address,
        ],
    },
    MAPSTR: {
        key: PlatformKey.MAPSTR,
        fullName: 'Mapstr',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.DAILY,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: true,
        hasDuplicablePosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.address,
            informationKeySent.website,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.specialHours,
        ],
    },
    TIKTOK: {
        key: PlatformKey.TIKTOK,
        fullName: 'TikTok',
        baseUrl: 'https://www.tiktok.com/',
        apiUrl: 'https://open.tiktokapis.com/v2',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        featureFlagKey: 'release-tiktok-platform',
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: false,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: true,
        hasStory: false,
        // TODO: set this to true when videos list is available ?
        hasFeed: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: false,
        hasReel: false,

        informationSent: [],
    },
    YELP: {
        key: PlatformKey.YELP,
        fullName: 'Yelp',
        baseUrl: 'https://yelp.fr',
        bizUrl: 'https://biz.yelp.fr',
        apiUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Yelp-1400fa912b2145a79d6744adff37fbe4',
        notionAdminLink: 'https://welcomehomemalou.notion.site/Nommer-un-nouvel-administrateur-sur-Yelp-abbc46052f05422e906122346435aa47',
        updateLink: (socialId: string) => `https://biz.yelp.fr/biz_info/${socialId}`,
        accessLink: (socialId: string) => `https://biz.yelp.fr/home/${socialId}`,
        externalReviewLink: 'https://www.yelp.fr/writeareview/biz/',
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: true,
        yextPublisherId: YextPublisherId.YELP,
        updateTime: PlatformUpdateTime.DAILY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.shortDescription,
            informationKeySent.longDescription,
            informationKeySent.address,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.specialHours,
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.coverUrl,
            informationKeySent.logoUrl,
            informationKeySent.isClosedTemporarily,
            informationKeySent.menuUrl,
            informationKeySent.website,
        ],
    },
    LAFOURCHETTE: {
        key: PlatformKey.LAFOURCHETTE,
        fullName: 'TheFork',
        baseUrl: 'https://www.lafourchette.com',
        bizUrl: ' https://manager.lafourchette.com',
        apiUrl: 'https://www.lafourchette.com/api/search/autocomplete',
        apiParams:
            '&filter%5Btypes%5D%5B0%5D=RESTAURANT&filter%5Btypes%5D%5B1%5D=RESTAURANT_TAG&filter%5Btypes%5D%5B2%5D=SALE_TYPE_TAG&sort%5BbrandId%5D=1',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Lafourchette-10190266a025440e9f500d0c5c666ab6',
        notionAdminLink: '',
        updateLink: (_socialId: string) => 'https://manager.lafourchette.com/login',
        accessLink: (_socialId: string) => 'https://manager.lafourchette.com/login',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasRatingOutOfTen: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.longDescription,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.website,
        ],
    },
    FOURSQUARE: {
        key: PlatformKey.FOURSQUARE,
        fullName: 'Foursquare',
        apiUrl: 'https://api.foursquare.com/v2/venues',
        apiVersion: '20180323',
        baseUrl: 'https://fr.foursquare.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Foursquare-5b9cab78284d45dab5d3e2ee2a5c3461',
        notionAdminLink: 'https://help.malou.io/en/articles/936706',
        updateLink: (socialId: string) => `https://fr.foursquare.com/v/${socialId}`,
        accessLink: (socialId: string) => `https://fr.foursquare.com/v/${socialId}`,
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: true,
        yextPublisherId: YextPublisherId.FOURSQUARE,
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasRatingOutOfTen: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.shortDescription,
            informationKeySent.address,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.coverUrl,
            informationKeySent.logoUrl,
            informationKeySent.website,
        ],
    },
    PAGESJAUNES: {
        key: PlatformKey.PAGESJAUNES,
        fullName: 'PagesJaunes',
        baseUrl: 'https://pagesjaunes.fr',
        reviewsUrl: 'https://manager.solocal.com/',
        apiUrl: 'https://dsk3ufaxut-dsn.algolia.net/1/indexes/*/queries?x-algolia-application-id=DSK3UFAXUT&x-algolia-api-key=30a9c866c7245bafc39b9d3612ca1a95',
        bizUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Page-Jaune-c6797a00ebda4cd88c06d9e771b6c889',
        notionAdminLink:
            'https://welcomehomemalou.notion.site/Nommer-un-nouvel-administrateur-sur-Les-Pages-Jaunes-SoLocal-1c14ce19eea0439f86ac04222379d111 ',
        updateLink: (socialId: string) => `https://manager.solocal.com/#/app/2/content/info?epj=${socialId}`,
        accessLink: (socialId: string) => `https://manager.solocal.com/#/app/2/content/info?epj=${socialId}`,
        externalReviewLink: 'https://www.pagesjaunes.fr/pros/',
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.longDescription,
            informationKeySent.address,
            informationKeySent.phone,
            informationKeySent.regularHours,
            informationKeySent.specialHours,
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.coverUrl,
            informationKeySent.logoUrl,
            informationKeySent.isClosedTemporarily,
            informationKeySent.menuUrl,
            informationKeySent.website,
        ],
    },
    ZENCHEF: {
        key: PlatformKey.ZENCHEF,
        fullName: 'Zenchef',
        baseUrl: 'https://www.zenchef.com/',
        bizUrl: 'https://app.zenchef.com/',
        apiUrl: 'https://api.zenchef.com/api/v1/',
        socialUrl: 'https://atable.zenchef.com/restaurant/',
        reviewsUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Zenchef-d6357048f6514e1db3f1269ba3152cb3',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        informationSent: [],
    },
    DELIVEROO: {
        key: PlatformKey.DELIVEROO,
        fullName: 'Deliveroo',
        apiUrl: 'https://partner-hub.deliveroo.com/api/',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.DELIVERY,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        informationSent: [],
    },
    UBEREATS: {
        key: PlatformKey.UBEREATS,
        fullName: 'Uber Eats',
        apiUrl: 'https://api.uber.com/',
        baseUrl: 'https://www.ubereats.com/',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://welcomehomemalou.notion.site/Uber-Eats-a7a2edff44444bfc909096ab1f6ece0e',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        updateLink: (socialId: string) => `'https://merchants.ubereats.com/manager/stores/${socialId}`,
        accessLink: (socialId: string) => `'https://merchants.ubereats.com/manager/stores/${socialId}`,

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.DELIVERY,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        yextPublisherId: YextPublisherId.UBEREATS,
        updateTime: PlatformUpdateTime.DAILY,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        informationSent: [informationKeySent.isClosedTemporarily, informationKeySent.specialHours],
    },
    OPENTABLE: {
        key: PlatformKey.OPENTABLE,
        fullName: 'OpenTable',
        apiUrl: 'https://guestcenter.opentable.com/gateway/proxies',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: 'https://help.malou.io/en/articles/1967618',
        notionAdminLink: 'https://help.malou.io/en/articles/1967618',
        updateLink: (socialId: string) => `https://guestcenter.opentable.com/restaurant/${socialId}/profile/basic-information`,
        accessLink: (socialId: string) => `https://guestcenter.opentable.com/restaurant/${socialId}/home`,
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        yextPublisherId: YextPublisherId.OPENTABLE,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.longDescription,
            informationKeySent.phone,
            informationKeySent.attributes,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.coverUrl,
            informationKeySent.logoUrl,
            informationKeySent.menuUrl,
            informationKeySent.website,
        ],
    },
    RESY: {
        key: PlatformKey.RESY,
        fullName: 'Resy',
        baseUrl: 'https://resy.com/',
        apiUrl: '',
        bizUrl: 'https://os.resy.com/portal/',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        updateLink: (socialId: string) => {
            const slugs = socialId.split('/venues/');
            const locationSlug = slugs[0].split('-').pop();
            const venueSlug = slugs[1];
            return `https://os.resy.com/portal/${locationSlug}/${venueSlug}/venue/listing-info`;
        },
        accessLink: (socialId: string) => {
            const slugs = socialId.split('/venues/');
            const locationSlug = slugs[0].split('-').pop();
            const venueSlug = slugs[1];
            return `https://os.resy.com/portal/${locationSlug}/${venueSlug}/analytics`;
        },
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        updateTime: PlatformUpdateTime.WEEKLY,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [
            informationKeySent.name,
            informationKeySent.address,
            informationKeySent.website,
            informationKeySent.categoryName,
            informationKeySent.secondaryCategoriesNames,
            informationKeySent.phone,
            informationKeySent.longDescription,
        ],
    },
    ABC: {
        // TODO: @Hugo @Maxime, fill with correct values, this is a placeholder
        key: PlatformKey.ABC,
        fullName: 'Apple',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        featureFlagKey: 'release-abc-platform',
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,
        // yextPublisherId: YextPublisherId.APPLE, // TODO: Uncomment when APPLE will be released on our side
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: false,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasDuplicablePosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [],
    },
    PRIVATE: {
        key: PlatformKey.PRIVATE,
        fullName: 'Private',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.PRIVATE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [],
    },
    WEBSITE: {
        key: PlatformKey.WEBSITE,
        fullName: 'Website',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        notionLink: '',
        notionAdminLink: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.PRIVATE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        canUpdateInformationWithYext: false,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,

        informationSent: [],
    },
});
