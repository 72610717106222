import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Subject } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformTutorial, platformTutorials } from ':assets/tutorials/platforms_tutorials';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { PlatformState } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-platforms-connection-help-modal',
    templateUrl: './platforms-connection-help-modal.component.html',
    styleUrls: ['./platforms-connection-help-modal.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        PlatformLogoComponent,
        EmojiPathResolverPipe,
        EnumTranslatePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class PlatformsConnectionHelpModalComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly PlatformState = PlatformState;
    private readonly _platformTutorials = platformTutorials;

    platformKey: PlatformKey;
    platformState: PlatformState;
    platformHelp: PlatformTutorial;

    featureCountByCol = 4;
    informationCountByCol = 3;

    constructor(
        private readonly _dialogRef: MatDialogRef<PlatformsConnectionHelpModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            platformKey: PlatformKey;
            platformState: PlatformState;
        }
    ) {
        this.platformKey = this.data.platformKey;
        this.platformState = this.data.platformState;
    }

    ngOnInit(): void {
        this.platformHelp = this._platformTutorials.find((pt) => pt.key === this.platformKey)!;

        if (this.platformHelp.features && this.platformHelp.features.size > 4) {
            this.featureCountByCol = Math.ceil(this.platformHelp.features.size / 2);
        }
        if (this.platformHelp.informationSent) {
            this.informationCountByCol = Math.ceil(this.platformHelp.informationSent.size / 2);
        }
    }

    close(openPlatformConnectModal?: boolean): void {
        this._dialogRef.close(openPlatformConnectModal);
    }
}
