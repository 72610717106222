/* eslint-disable @typescript-eslint/member-ordering */
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, shuffle, take } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map } from 'rxjs';

import { ApplicationLanguage, HeapEventName, KeywordPopularity, mapLanguageStringToApplicationLanguage } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { HeapService } from ':core/services/heap.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { SelectedKeywordsValidationModalComponent } from ':modules/keywords/keywords-validation-modal/selected-keywords-validation-modal/selected-keywords-validation-modal.component';
import { UnselectedKeywordsValidationModalComponent } from ':modules/keywords/keywords-validation-modal/unselected-keywords-validation-modal/unselected-keywords-validation-modal.component';
import { SelectLanguageComponent } from ':shared/components-v3/select-language/select-language.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Keyword } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

export enum SideSelection {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export const DISPLAYED_COLUMNS: string[] = ['select', 'text', 'volume', 'language', 'move'];
export const MAX_KEYWORDS_SELECTED = 10;

@Component({
    selector: 'app-keywords-validation-modal',
    templateUrl: './keywords-validation-modal.component.html',
    styleUrls: ['./keywords-validation-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        MatTabsModule,
        TranslateModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        CreateArrayPipe,
        FlagPathResolverPipe,
        CloseWithoutSavingModalComponent,
        KeywordsPopularityComponent,
        MalouSpinnerComponent,
        NoopMatCheckboxComponent,
        SearchComponent,
        SelectLanguageComponent,
        SelectedKeywordsValidationModalComponent,
        SkeletonComponent,
        UnselectedKeywordsValidationModalComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsValidationModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly SideSelection = SideSelection;

    readonly hashKeywordFn = (item: Keyword): string => `${item.keywordId}_${item.restaurantKeywordId}`;
    readonly leftSource = signal(new MatTableDataSource<Keyword>([]));
    readonly rightSource = signal(new MatTableDataSource<Keyword>([]));
    readonly leftSelection = signal(new SelectionModel<Keyword>(this.hashKeywordFn));
    readonly rightSelection = signal(new SelectionModel<Keyword>(this.hashKeywordFn));

    readonly allKeywords: WritableSignal<Keyword[]> = signal([]);

    readonly currentTabIndex = signal(0);

    readonly loading = signal(false);
    readonly isSaving = signal(false);

    readonly displayCloseModal = signal(false);
    hasTouched = false;
    readonly unselectedKeywordLangs: WritableSignal<ApplicationLanguage[]> = signal([]);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly updatingKeywordLangs: WritableSignal<string[]> = signal([]);
    readonly selectedLangs = signal([] as ApplicationLanguage[]);
    private _currentLang: ApplicationLanguage = LocalStorage.getLang();

    constructor(
        private readonly _dialogRef: MatDialogRef<KeywordsValidationModalComponent>,
        private readonly _translate: TranslateService,
        private readonly _keywordsService: KeywordsService,
        private readonly _restaurantService: RestaurantsService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _heapService: HeapService,
        private readonly _destroyRef: DestroyRef,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            languages: ApplicationLanguage[];
        }
    ) {}

    ngOnInit(): void {
        this._keywordsService.seeLastGenerationResultByRestaurantId(this._restaurantService.currentRestaurant._id);

        this._translate.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            this._currentLang = mapLanguageStringToApplicationLanguage(res.lang);
        });

        this._initKeywords();
    }

    setCurrentTabIndex(index: number): void {
        this.currentTabIndex.set(index);
    }

    close(): void {
        if (this.hasTouched) {
            this.displayCloseModal.set(true);
        } else {
            this._dialogRef.close();
        }
    }

    selectKeywords(keywords: Keyword[]): void {
        const selectedKeywordsCount = this.rightSource().data.length;
        if (keywords.length + selectedKeywordsCount > MAX_KEYWORDS_SELECTED) {
            this._toastService.openErrorToast(this._translate.instant('keywords.validation.selection_exceed_limit'));
            return;
        }

        this.rightSource.update((currentDataSource) => {
            currentDataSource.data.unshift(...keywords);
            return currentDataSource;
        });
        this.leftSource.update((currentDataSource) => {
            currentDataSource.data = currentDataSource.data.filter((keyword) =>
                keywords.every((kw) => this.hashKeywordFn(kw) !== this.hashKeywordFn(keyword))
            );
            return currentDataSource;
        });
        this.rightSource()._updateChangeSubscription();
        this.leftSource()._updateChangeSubscription();

        this.leftSelection().unselect(keywords);

        this.hasTouched = true;
    }

    selectedLangsChange(langs: ApplicationLanguage[]): void {
        this.selectedLangs.set(langs);
    }

    unselectKeywords(keywords: Keyword[]): void {
        this.leftSource.update((currentDataSource) => {
            currentDataSource.data.unshift(...keywords);
            return currentDataSource;
        });
        this.rightSource.update((currentDataSource) => {
            currentDataSource.data = currentDataSource.data.filter((keyword) =>
                keywords.every((kw) => this.hashKeywordFn(kw) !== this.hashKeywordFn(keyword))
            );
            return currentDataSource;
        });
        this.rightSource()._updateChangeSubscription();
        this.leftSource()._updateChangeSubscription();

        this.rightSelection().unselect(keywords);

        this.hasTouched = true;
    }

    save(): void {
        this.isSaving.set(true);
        this._keywordsService
            .setSelectedKeywords(this._restaurantService.currentRestaurant._id, this.rightSource().filteredData)
            .subscribe({
                next: (res) => {
                    this._dialogRef.close(res.data);
                },
                error: (err) => {
                    console.warn(err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(
                        this._translate.instant('keywords.validation.unknown_error') + this._httpErrorPipe.transform(err)
                    );
                    this.isSaving.set(false);
                },
            });
    }

    chooseKeywordsForMe(): void {
        const suggestedList = this._getSuggestedList();

        this.rightSource.update((currentDataSource) => {
            currentDataSource.data = [...suggestedList];
            return cloneDeep(currentDataSource);
        });
        const rightSourceIds = new Set(this.rightSource().data.map((elem) => elem.restaurantKeywordId));
        const leftSourceFiltered = this.leftSource().data.filter((keyword) => !rightSourceIds.has(keyword.restaurantKeywordId));
        this.leftSource.update((currentDataSource) => {
            currentDataSource.data = leftSourceFiltered;
            return currentDataSource;
        });
        this.rightSource()._updateChangeSubscription();
        this.leftSource()._updateChangeSubscription();
    }

    updateKeywordLanguage(keyword: Keyword, lang: ApplicationLanguage, sideSelection: SideSelection): void {
        if (lang === keyword.language) {
            return;
        }

        this.updatingKeywordLangs.update((currentUpdatingKeywordLangs) => {
            currentUpdatingKeywordLangs.push(keyword.restaurantKeywordId);
            return currentUpdatingKeywordLangs;
        });

        this._trackUpdateKeywordLanguageEvent(lang);

        const oldLanguage = keyword.language;
        keyword.language = lang; // optimistic update

        this._keywordsService.updateKeywordLanguage(keyword.keywordId, this._restaurantService.currentRestaurant._id, lang).subscribe({
            next: (updatedKeyword) => {
                if (sideSelection === SideSelection.LEFT) {
                    const index = this.leftSource().data.findIndex((k) => k.restaurantKeywordId === keyword.restaurantKeywordId);
                    if (index !== -1) {
                        this.leftSource.update((currentDataSource) => {
                            currentDataSource.data[index] = updatedKeyword;
                            return currentDataSource;
                        });
                        this.leftSource()._updateChangeSubscription();
                    }
                    if (this.leftSelection().isSelected(keyword)) {
                        this.leftSelection().unselect(keyword);
                        this.leftSelection().select(updatedKeyword);
                    }
                    this.unselectedKeywordLangs.set(this._getUnselectedKeywordLangs(this.leftSource().data));
                } else {
                    const index = this.rightSource().data.findIndex((k) => k.restaurantKeywordId === keyword.restaurantKeywordId);
                    if (index !== -1) {
                        this.rightSource.update((currentDataSource) => {
                            currentDataSource.data[index] = updatedKeyword;
                            return currentDataSource;
                        });
                        this.rightSource()._updateChangeSubscription();
                    }
                    if (this.rightSelection().isSelected(keyword)) {
                        this.rightSelection().unselect(keyword);
                        this.rightSelection().select(updatedKeyword);
                    }
                    this.hasTouched = true;
                }
                this.updatingKeywordLangs.update((currentUpdatingKeywordLangs) =>
                    currentUpdatingKeywordLangs.filter((id) => id !== keyword.restaurantKeywordId)
                );
            },
            error: (err) => {
                console.warn(err);
                keyword.language = oldLanguage; // revert optimistic update
                this._toastService.openErrorToast(this._translate.instant('common.unknown_error') + this._httpErrorPipe.transform(err));
            },
        });
    }

    private _initKeywords(): void {
        this.loading.set(true);
        this._keywordsService
            .getKeywordsByRestaurantId(this._restaurantService.currentRestaurant._id)
            .pipe(
                map((keywords) => {
                    const selectedKeywords = keywords.data.filter((k) => k.selected).map((k) => new Keyword(k));
                    const unselectedKeywords = keywords.data.filter((k) => !k.selected).map((k) => new Keyword(k));
                    this.unselectedKeywordLangs.set(this._getUnselectedKeywordLangs(unselectedKeywords));

                    this.allKeywords.set(unselectedKeywords.concat(selectedKeywords).sort((a, b) => a.text?.localeCompare(b?.text)));

                    this.rightSource.update((currentDataSource) => {
                        currentDataSource.data = selectedKeywords.sort((a, b) => a.text?.localeCompare(b?.text));
                        return currentDataSource;
                    });
                    this.rightSource()._updateChangeSubscription();

                    return unselectedKeywords;
                })
            )
            .subscribe({
                next: (unselectedWithVolume: Keyword[]) => {
                    this.loading.set(false);

                    this.leftSource.update((currentDataSource) => {
                        currentDataSource.data = unselectedWithVolume.sort((a, b) => a.text?.localeCompare(b?.text));
                        return currentDataSource;
                    });
                    this.leftSource()._updateChangeSubscription();
                    this._initLangFilter(this.data.languages ?? [this._currentLang]);
                },
                error: (err) => {
                    console.error(err);
                    this._toastService.openErrorToast(
                        this._translate.instant('keywords.validation.unknown_error') + this._httpErrorPipe.transform(err)
                    );
                    this.loading.set(false);
                    this.close();
                },
            });
    }

    private _initLangFilter(selectedLangs: ApplicationLanguage[]): void {
        this.selectedLangs.set(selectedLangs.length ? selectedLangs : [this._currentLang]);
        this.leftSource.update((currentDataSource) => {
            currentDataSource.filterPredicate = (data: Keyword, filter: string): boolean => filter.includes(data.language);
            currentDataSource.filter = selectedLangs.join(' ');
            return currentDataSource;
        });
        this.leftSource()._updateChangeSubscription();
    }

    private _getUnselectedKeywordLangs(keywords: Keyword[]): ApplicationLanguage[] {
        return keywords
            .map((k) => mapLanguageStringToApplicationLanguage(k.language))
            .filter((lang, index, self) => self.indexOf(lang) === index);
    }

    private _trackUpdateKeywordLanguageEvent(lang: ApplicationLanguage): void {
        switch (lang) {
            case ApplicationLanguage.FR:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_FR);
                break;
            case ApplicationLanguage.EN:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_EN);
                break;
            case ApplicationLanguage.ES:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_ES);
                break;
            case ApplicationLanguage.IT:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_IT);
                break;
            default:
                break;
        }
    }

    private _getSuggestedList(): Keyword[] {
        const keywordsWithVolumeAndSelectedLangs = this.leftSource().data.filter(
            (k) => k.isFetched && this.selectedLangs().includes(mapLanguageStringToApplicationLanguage(k.language))
        );
        const lowPopularityKeywords = keywordsWithVolumeAndSelectedLangs.filter(
            (k) => k.getPopularity(this.allKeywords()) === KeywordPopularity.LOW
        );
        const mediumPopularityKeywords = keywordsWithVolumeAndSelectedLangs.filter(
            (k) => k.getPopularity(this.allKeywords()) === KeywordPopularity.MEDIUM
        );
        const highPopularityKeywords = keywordsWithVolumeAndSelectedLangs.filter(
            (k) => k.getPopularity(this.allKeywords()) === KeywordPopularity.HIGH
        );

        const PROPORTION_LOW_POPULARITY = 0.2;
        const PROPORTION_MEDIUM_POPULARITY = 0.4;

        const suggestedLowPopularityKeywords = take(
            shuffle(lowPopularityKeywords),
            Math.ceil(PROPORTION_LOW_POPULARITY * MAX_KEYWORDS_SELECTED)
        );
        const suggestedMediumPopularityKeywords = take(
            shuffle(mediumPopularityKeywords),
            Math.ceil(PROPORTION_MEDIUM_POPULARITY * MAX_KEYWORDS_SELECTED)
        );
        const suggestedHighPopularityKeywords = take(
            shuffle(highPopularityKeywords),
            MAX_KEYWORDS_SELECTED - suggestedLowPopularityKeywords.length - suggestedMediumPopularityKeywords.length
        );

        const suggestedList = [...suggestedHighPopularityKeywords, ...suggestedMediumPopularityKeywords, ...suggestedLowPopularityKeywords];

        if (suggestedList.length < MAX_KEYWORDS_SELECTED) {
            const remainingKeywords = keywordsWithVolumeAndSelectedLangs.filter(
                (keyword) => !suggestedList.some((suggestedKeyword) => this.hashKeywordFn(suggestedKeyword) === this.hashKeywordFn(keyword))
            );
            const remainingKeywordsCount = MAX_KEYWORDS_SELECTED - suggestedList.length;
            const remainingKeywordsToSelect = take(shuffle(remainingKeywords), remainingKeywordsCount);
            suggestedList.push(...remainingKeywordsToSelect);
        }

        return suggestedList;
    }
}
