import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-needs-confirm',
    templateUrl: './needs-confirm.component.html',
    styleUrls: ['./needs-confirm.component.scss'],
    standalone: true,
})
export class NeedsConfirmComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
