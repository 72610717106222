import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUnansweredCommentCount, selectUnansweredMentionCount } from ':modules/comments/store/comments.reducer';
import { selectUnreadConversations } from ':modules/messages/messages.reducer';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';

@Component({
    selector: 'app-sidenav-content-interactions-routes',
    templateUrl: './sidenav-content-interactions-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule, ConcatPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentInteractionsRoutesComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);

    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly SvgIcon = SvgIcon;

    readonly unreadConversationCount = this._store.selectSignal(selectUnreadConversations);
    readonly unreadCommentsCount = this._store.selectSignal(selectUnansweredCommentCount);
    readonly unreadMentionsCount = this._store.selectSignal(selectUnansweredMentionCount);
}
