<div class="mb-6 text-malou-color-text-1">
    <span class="malou-text-14--semibold">{{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.title' | translate }}</span>
    <span class="malou-text-14--regular italic">
        {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.subtitle' | translate: { maxCount: MAX_GIFT_COUNT } }}
    </span>
</div>

@if (allControlsAreSet()) {
    <div class="flex flex-wrap gap-[15px] pb-5">
        @for (gift of gifts(); track gift; let index = $index) {
            <div class="malou-card !m-0 min-w-[190px] max-w-[240px] !p-0">
                <div class="malou-card__header flex items-center justify-between bg-malou-color-background-light !p-3">
                    <div class="malou-text-14--semibold">
                        {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.title' | translate: { index: index + 1 } }}
                    </div>
                    <button
                        [disabled]="gifts().length <= MIN_GIFT_COUNT || isDisabled"
                        [ngClass]="{ 'opacity-25': gifts().length <= MIN_GIFT_COUNT || isDisabled, '!cursor-not-allowed': isDisabled }"
                        (click)="onRemoveGift(index)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    </button>
                </div>
                <div class="malou-card__body flex w-full grow flex-col gap-y-3 p-3">
                    <app-input-text
                        [title]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.name_input.title' | translate"
                        [inputId]="'inputId_' + index"
                        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.name_input.placeholder' | translate"
                        [isEmojiPickerEnabled]="true"
                        [emojiButtonColor]="'malou-color-text-2'"
                        [required]="true"
                        [defaultValue]="gift.name"
                        [formControl]="giftNameControls()[index]"
                        [maxLength]="GIFT_NAME_MAX_LENGTH"
                        [showMaxLengthHelper]="true"
                        (inputTextChange)="onNameChange(index, $event)">
                    </app-input-text>
                    <app-input-number
                        [title]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.weight_input.title' | translate"
                        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.weight_input.placeholder' | translate"
                        [required]="true"
                        [min]="0.1"
                        [value]="gift.weight"
                        [disabled]="isDisabled"
                        [icon]="SvgIcon.SIMPLE_PERCENT"
                        [iconClass]="'malou-color-text-1'"
                        (inputNumberChange)="onWeightChange(index, $event)">
                    </app-input-number>
                    <button class="my-1 flex items-center" id="tracking_wof_edit_stocks_upsert_modal" (click)="onEditStock(gift, index)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span class="malou-text-9--semibold malou-color-primary">
                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.gift_card.change_stocks' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        }
        @if (gifts().length < MAX_GIFT_COUNT && !isDisabled) {
            <div class="malou-card !m-0 min-w-[190px] max-w-[250px] cursor-pointer !border-dashed !p-0" (click)="onAddGift()">
                <div class="malou-card__body flex w-full grow items-center justify-center p-3">
                    <mat-icon class="!h-4/5 !w-4/5 opacity-25" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </div>
            </div>
        }
    </div>
}
