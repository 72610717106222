<ng-container [ngTemplateOutlet]="isPerformanceLoading() || isParentLoading() ? loadingTemplate : performanceTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton
        skeletonClass="secondary-bg px-4 py-6 flex flex-col gap-2 min-h-[218px] flex justify-center items-center gap-2 min-h-[335px] h-full">
        <app-skeleton appearance="circle" skeletonClass="h-[134px] w-[134px] rounded-full"></app-skeleton>
        <app-skeleton class="!w-full" skeletonClass="!h-[40px]"></app-skeleton>
        <app-skeleton skeletonClass="!h-[20px] !w-[200px]"></app-skeleton>
    </app-skeleton>
</ng-template>

<ng-template #performanceTemplate>
    <div class="malou-simple-card flex h-full flex-col gap-6 p-3">
        @if (!performanceScoreData()) {
            <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        } @else {
            <div class="flex h-full flex-col items-center justify-center gap-5">
                <div class="flex w-full justify-center">
                    <app-performance-gauge-chart [performanceScore]="performanceScore() ?? 0"></app-performance-gauge-chart>
                </div>
                <div class="flex flex-col items-center pb-4">
                    <div class="flex h-12 items-center gap-2">
                        <span class="malou-text-32--bold malou-color-text-1">{{ performanceScore() ?? '--' }}/100</span>
                        <div class="flex h-8 items-end">
                            <span class="malou-text-12--regular malou-color-text-1">{{ 'roi.performance_score.title' | translate }}</span>
                        </div>
                        @if (!isPerformanceScoreEvolutionTooHigh()) {
                            <app-number-evolution
                                [value]="performanceScoreEvolution()"
                                [displayedValue]="
                                    performanceScoreEvolution() | shortNumber: { shouldDisplayMinusSign: true }
                                "></app-number-evolution>
                        }
                    </div>
                    @if (hasSimilarLocations()) {
                        <div class="flex items-center gap-1">
                            <span class="malou-text-10 malou-color-text-2">{{
                                'roi.performance_score.vs_other_similar_businesses'
                                    | translate: { score: similarLocationsPerformanceScore() }
                            }}</span>

                            <mat-icon
                                class="!h-3 !w-3"
                                [svgIcon]="SvgIcon.INFO"
                                [matTooltip]="'roi.estimated_customers.tooltip_sales' | translate"></mat-icon>
                        </div>
                    }
                    @if (performanceScore()) {
                        <button class="malou-btn-flat !malou-text-10--semibold mt-4" (click)="openDetails()">
                            {{ 'roi.performance_score.reach_hundred' | translate }}
                        </button>
                    }
                </div>
            </div>
        }
    </div>

    <ng-template #noDataTemplate>
        <div class="flex h-[442px] flex-col items-center justify-center py-6 text-center">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <div class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</div>
        </div>
    </ng-template>
</ng-template>
