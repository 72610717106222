import { PlatformAccessType, PlatformKey } from '@malou-io/package-utils';

import { Platform, PlatformState } from './platform';
import { Comparison, ComparisonKey, DEFAULT_COMPARISON_KEYS, PlatformComparison } from './platform-comparison';
import { Restaurant } from './restaurant';

export enum PlatformStatus {
    EMPTY_PLATFORM = 'empty_platform',
    NO_STATUS = 'no_status',
}

export class PlatformComparisonWithStatus {
    key: PlatformKey;
    fullName: string | null;
    platformStatus: PlatformStatus;
    data: Comparison[] | null;
    accessType: string | null;
    isPlatformAccessActive: boolean;
    platformData: Platform | null;

    constructor(
        restaurant: Restaurant,
        restaurantPlatform: Platform | null,
        platform: { key: PlatformKey; fullName: string },
        comparisonKeys: ComparisonKey[] = DEFAULT_COMPARISON_KEYS
    ) {
        this.key = platform.key;
        this.fullName = this._capitalizeFirstLetter(platform.fullName);
        this.platformStatus = restaurantPlatform ? PlatformStatus.NO_STATUS : PlatformStatus.EMPTY_PLATFORM;
        this.data = restaurantPlatform
            ? new PlatformComparison(restaurant, restaurantPlatform, comparisonKeys).getDifferentComparisonsByKeys(comparisonKeys)
            : null;
        this.accessType = restaurant.access.find((access) => access.platformKey === platform.key)?.accessType ?? null;
        this.isPlatformAccessActive = !!restaurant.access.find((access) => access.platformKey === platform.key && access.active);
        this.platformData = restaurantPlatform ?? null;
    }

    getPlatformState(): string {
        const accessTypesValues: string[] = Object.values(PlatformAccessType);
        if (this.key === PlatformKey.MAPSTR) {
            return PlatformState.CONNECTED;
        } else if (this.platformStatus === PlatformStatus.EMPTY_PLATFORM) {
            return PlatformState.WAITING;
        } else if (!accessTypesValues.includes(this.accessType as string) || !this.isPlatformAccessActive) {
            return PlatformState.FOUND;
        } else if (accessTypesValues.includes(this.accessType as string)) {
            return PlatformState.CONNECTED;
        } else {
            return PlatformState.EMPTY;
        }
    }

    getNbDiff(): number {
        return this.data ? this.data.length : 0;
    }

    isConnected(): boolean {
        return this.getPlatformState() === PlatformState.CONNECTED;
    }

    isOauthPlatform(oauthPlatforms: string[]): boolean {
        return oauthPlatforms.includes(this.key);
    }

    private _capitalizeFirstLetter(string: string): string | null {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    }
}
