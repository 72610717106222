import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { isNumber } from 'lodash';

/**
 * Hide horizontal-overflowing children elements.
 *
 * Check the presence of the scrollbar to determine how much children need to be hidden.
 * (the 'overview: scroll' css rule is automatically applied)
 *
 * Ex:
 * // the div with the directive must have a fixed width !
 * <div class="flex" appHideOverflowingChildren>
 *     <div>First child</div>
 *     <div>Second child</div>
 *     <div>Third child</div>
 * </div>
 */
@Directive({
    selector: '[appHideOverflowingChildren]',
    standalone: true,
})
export class HideOverflowingChildrenDirective implements OnDestroy, AfterViewInit {
    /**
     * To cap the maximum number of displayed children
     */
    @Input()
    maxDisplayedChildren: number = Number.MAX_SAFE_INTEGER;

    /**
     * Mostly useful to prevent computing for nothing
     */
    _disableObserver = false;
    @Input() set disableObserver(value: boolean) {
        this._disableObserver = value;
        if (this._disableObserver) {
            this._renderer.removeClass(this._el.nativeElement, 'overflow-auto');
        } else {
            this._renderer.addClass(this._el.nativeElement, 'overflow-auto');
        }
    }

    /**
     * Will output the number of hidden children elements each time a computing is done (on resize)
     */
    @Output()
    childrenHidden: EventEmitter<number> = new EventEmitter();

    /**
     * Emit (only once) the refresh function used to trigger the directive
     */
    @Output()
    refreshFn: EventEmitter<() => void> = new EventEmitter();

    observer: ResizeObserver;

    readonly HIDDEN_CLASS = '!hidden';

    constructor(
        private readonly _renderer: Renderer2,
        private readonly _el: ElementRef<HTMLElement>,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {
        this.observer = new ResizeObserver((_entries) => {
            if (!this._disableObserver) {
                this._removeOverflowingChildren();
            }
        });
    }

    refresh = (): void => {
        this._removeOverflowingChildren();
    };

    ngAfterViewInit(): void {
        this.observer.observe(this._el.nativeElement);
        this.observer.observe(this._el.nativeElement.parentElement as HTMLElement);

        this.refreshFn.emit(this.refresh);
        if (!this._disableObserver) {
            this._removeOverflowingChildren();
        }
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    isElementScrolling(element: Element): boolean {
        return element.scrollWidth > element.clientWidth;
    }

    private _showHiddenChildren(): void {
        const contextElement: Element = this._el.nativeElement;
        Array.from(contextElement.children).forEach((e) => e.classList.remove(this.HIDDEN_CLASS));
    }

    private _removeOverflowingChildren(): void {
        this._showHiddenChildren();
        const contextElement: Element = this._el.nativeElement;

        const childrenCount = contextElement.children.length;
        let i = childrenCount - 1;
        let hiddenChildrenCount = 0;
        while (this.isElementScrolling(contextElement) && i >= 0) {
            const elementToHide = contextElement.children.item(i);
            elementToHide?.classList.add(this.HIDDEN_CLASS);
            this._changeDetectorRef.detectChanges();
            hiddenChildrenCount++;
            i--;
        }

        const displayedChildren = childrenCount - hiddenChildrenCount;
        if (isNumber(this.maxDisplayedChildren) && displayedChildren > this.maxDisplayedChildren) {
            const childrenToHide = Array.from(contextElement.children).slice(this.maxDisplayedChildren);
            childrenToHide.forEach((e) => {
                e.classList.add(this.HIDDEN_CLASS);
            });
            this._changeDetectorRef.detectChanges();
            hiddenChildrenCount += childrenToHide.length;
        }

        this.childrenHidden.emit(hiddenChildrenCount);
    }
}
