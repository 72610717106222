import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const selectRestaurantKeywordsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type SelectRestaurantKeywordsParamsDto = z.infer<typeof selectRestaurantKeywordsParamsValidator>;

export const selectRestaurantKeywordsBodyValidator = z.object({
    keywordIds: z.array(objectIdValidator),
});
export type SelectRestaurantKeywordsBodyDto = z.infer<typeof selectRestaurantKeywordsBodyValidator>;
