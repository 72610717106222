@if (!isBrandBusiness()) {
    @let base = ['/restaurants', selectedRestaurant()?._id ?? ''];
    <app-sidenav-content-route-group
        [options]="{
            text: 'sidenav_content.e_reputation' | translate,
            routerLink: base | concat: ['reputation'],
            svgIcon: SvgIcon.STAR_BORDER,
        }"
        [childrenOptions]="[
            {
                text: 'sidenav_content.reviews' | translate,
                routerLink: base | concat: ['reputation', 'reviews'],
                svgIcon: SvgIcon.DOT,
                isSmallSvgIcon: true,
                shouldHideSvgIconOnOpenedSidenav: true,
            },
            {
                text: 'sidenav_content.campaigns' | translate,
                routerLink: base | concat: ['reputation', 'campaigns'],
                svgIcon: SvgIcon.DOT,
                isSmallSvgIcon: true,
                shouldHideSvgIconOnOpenedSidenav: true,
            },
        ]" />
}
