<ng-container [ngTemplateOutlet]="isPhoneScreen() ? mobileTemplate : desktopTemplate"></ng-container>

<ng-template #mobileTemplate>
    <div class="malou-color-bg-white hide-scrollbar flex h-screen min-h-[750px] flex-col justify-between overflow-y-auto px-7">
        <div class="flex h-full grow flex-col">
            <ng-container [ngTemplateOutlet]="isOnSite() ? claimGiftTemplate : isPlayerOnSiteToClaimGiftTemplate"></ng-container>
        </div>

        <div class="malou-text-12--semibold flex w-full items-baseline justify-center pb-3 text-center">
            <span class="text-malou-color-text-1">{{ 'play_wheel_of_fortune.powered_by' | translate }}</span>
            <img class="ml-1 max-h-2" [src]="'malou_logo_black' | imagePathResolver" />
        </div>
    </div>
</ng-template>

<ng-template #isPlayerOnSiteToClaimGiftTemplate>
    <div
        class="with-margin flex h-full flex-col items-center justify-between"
        [ngStyle]="{ marginBottom: giftRestaurantsWhereItCanBeRetrieved().length > 1 ? '5%' : 'inherit' }">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'heart' }"></ng-container>

        <div
            class="malou-text-24--semibold malou-color-text-1 text-center"
            [innerHTML]="
                'get_my_gift.image_with_background_templates.claimable.must_be_there.title'
                    | translate: { color: giftDraw()?.wheelOfFortune?.parameters?.primaryColor, gift: giftDraw()?.gift?.name }
                    | htmlTag
            "></div>
        <div class="mt-8 flex flex-col">
            @for (restaurant of giftRestaurantsWhereItCanBeRetrieved(); track restaurant?.id; let isLast = $last; let isFirst = $first) {
                <div class="mb-3 flex flex-col">
                    <span
                        class="malou-text-24--semibold text-center"
                        [ngStyle]="{
                            color: giftDraw()?.wheelOfFortune?.parameters?.primaryColor,
                            fontSize: giftRestaurantsWhereItCanBeRetrieved().length > 1 ? '20px' : '24px',
                            marginTop: giftRestaurantsWhereItCanBeRetrieved().length > 1 && !isFirst ? '10px' : '0',
                        }"
                        >{{ restaurant.name }}</span
                    >
                    <span
                        class="malou-text-18--regular mt-3 text-center italic"
                        [ngStyle]="{
                            fontSize: giftRestaurantsWhereItCanBeRetrieved().length > 2 ? '15px' : '18px',
                            marginTop: giftRestaurantsWhereItCanBeRetrieved().length > 1 ? '5px' : '12px',
                        }">
                        {{ restaurant | applySelfPure: 'getAddressDisplayedValue' }}
                    </span>
                </div>
                @if (giftRestaurantsWhereItCanBeRetrieved().length && !isLast) {
                    <span class="malou-text-15--bold mt-1 text-center text-malou-color-text-1">{{
                        ('common.or' | translate).toLowerCase()
                    }}</span>
                }
            }
        </div>

        <button
            class="malou-btn-raised--dark btn-xl my-4 !h-[69px] w-full"
            id="tracking_wof_im_here_cta"
            mat-raised-button
            (click)="isOnSite.set(true)">
            {{ 'get_my_gift.image_with_background_templates.claimable.must_be_there.cta' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #claimGiftTemplate>
    <div class="with-margin--small flex h-full flex-col items-center justify-between">
        <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'hand-phone' }"></ng-container>

        <ng-container
            [ngTemplateOutlet]="stepTemplate"
            [ngTemplateOutletContext]="{
                stepCount: 1,
                textHtml: 'get_my_gift.image_with_background_templates.claimable.claim_my_gift.first_step' | translate,
            }"></ng-container>

        <div class="my-4 text-center">
            <div class="malou-text-24--semibold mb-2" [ngStyle]="{ color: giftDraw()?.wheelOfFortune?.parameters?.primaryColor }">
                {{ giftDraw()?.gift?.name }}
            </div>

            <!-- TODO EPHEMERA : delete when no longer need custom settings -->
            @if (giftDraw()?.restaurant?.id !== SPECIAL_RESTAURANT_ID || giftDraw()?.giftId === SPECIAL_GIFT_ID) {
                <div class="malou-text-14--regular italic text-malou-color-text-2">
                    <div>{{ giftDraw()?.restaurant?.name }}</div>
                    <div>{{ giftDraw() | applySelfPure: 'getRestaurantFullFormattedAddress' }}</div>
                </div>
            }
        </div>

        <ng-container
            [ngTemplateOutlet]="stepTemplate"
            [ngTemplateOutletContext]="{
                stepCount: 2,
                textHtml: 'get_my_gift.image_with_background_templates.claimable.claim_my_gift.second_step' | translate,
            }"></ng-container>

        <button
            class="malou-btn-raised--dark btn-xl mb-4 mt-8 !h-[69px] w-full"
            id="tracking_wof_retrieved_gift_cta"
            mat-raised-button
            (click)="onGiftRetrieved()">
            {{ 'get_my_gift.image_with_background_templates.claimable.claim_my_gift.cta' | translate }}
        </button>
    </div>
</ng-template>

<ng-template let-img="img" #imageWithBackground>
    <div class="relative mb-5 ml-5 mt-10">
        <div
            class="absolute bottom-0 right-[20px] h-[140px] w-[140px] rounded-full"
            [ngClass]="{
                '!right-[10px]': ['hand-phone', 'pray'] | includes: img,
                '!right-[14px]': img === 'heart',
            }"
            [ngStyle]="{ 'background-color': giftDraw()?.wheelOfFortune?.parameters?.primaryColor }"></div>
        <img
            class="relative w-[140px]"
            [ngClass]="{
                'mb-[16px] !w-[110px]': img === 'hand-phone',
                'mb-[2px] !w-[124px]': img === 'heart',
                'mb-[12px] !w-[120px]': img === 'pray',
            }"
            [alt]="img"
            [src]="img | imagePathResolver: { folder: 'wheels-of-fortune' }" />
    </div>
</ng-template>

<ng-template let-stepCount="stepCount" let-textHtml="textHtml" #stepTemplate>
    <div class="flex flex-col items-center justify-center">
        <div
            class="malou-text-20--semibold flex h-[43px] w-[43px] items-center justify-center rounded-full text-white"
            [ngStyle]="{ 'background-color': giftDraw()?.wheelOfFortune?.parameters?.primaryColor }">
            {{ stepCount }}
        </div>
        <div class="malou-text-18--semibold malou-color-text-1 mt-3 text-center" [innerHTML]="textHtml"></div>
    </div>
</ng-template>

<ng-template #desktopTemplate>
    <app-redirect-wheel-of-fortune-mobile [logoUrl]="logo"></app-redirect-wheel-of-fortune-mobile>
</ng-template>
