import { PlatformKey } from '../../constants';
import { MalouPeriod, MalouTimeScalePeriod, PlatformFilterPage } from './constants';

export interface AggregatedStatisticsFilters<DatesType = UserFiltersDates> {
    dates: DatesType;
    platforms: UserFiltersAggregatedStatisticsPlatforms;
    restaurantIds: string[];
    roiRestaurantIds: string[];
    totemIds: string[];
    timeScale: MalouTimeScalePeriod;
}

export interface AggregatedReviewsFilters {
    filters: ReviewsFilters;
    restaurantIds: string[];
}

export interface StatisticsPerRestaurantFilters<DatesType = UserFiltersDates> {
    restaurantId: string;
    filters: {
        dates: DatesType;
        platforms: UserFiltersStatisticsPlatforms;
        totemIds: string[];
        timeScale: MalouTimeScalePeriod;
    };
}

export interface ReviewsPerRestaurantFilters {
    restaurantId: string;
    filters: ReviewsFilters;
}

export interface ReviewsFilters {
    period: MalouPeriod;
    platforms: PlatformKey[];
    text: string;
    ratings: number[];
    answered: boolean;
    notAnswered: boolean;
    pending: boolean;
    notAnswerable: boolean;
    showPrivate: boolean;
    withText: boolean;
    withoutText: boolean;
    archived: boolean;
    unarchived: boolean;
}

export interface UserFiltersAggregatedStatisticsPlatforms {
    [PlatformFilterPage.BOOSTERS]: PlatformKey[];
    [PlatformFilterPage.E_REPUTATION]: PlatformKey[];
    [PlatformFilterPage.SEO]: PlatformKey[];
    [PlatformFilterPage.SOCIAL_NETWORKS]: PlatformKey[];
}

export interface UserFiltersStatisticsPlatforms {
    [PlatformFilterPage.E_REPUTATION]: PlatformKey[];
    [PlatformFilterPage.SOCIAL_NETWORKS]: PlatformKey[];
}

export interface UserFiltersDates {
    startDate: Date | null;
    endDate: Date | null;
    period: MalouPeriod;
}
