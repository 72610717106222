import { z } from 'zod';

import { checkSocialNetworkUrls, SocialNetworkKey } from '@malou-io/package-utils';

import { urlValidator } from '../../utils/validators';

export const socialNetworkUrlsValidator = z
    .array(
        z.object({
            key: z.nativeEnum(SocialNetworkKey),
            url: urlValidator(),
        })
    )
    .refine(checkSocialNetworkUrls);
export type SocialNetworkUrlsDto = z.infer<typeof socialNetworkUrlsValidator>;
