import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GenerationForm, GenerationFormsState } from './generation-forms.interface';

export const selectGeneratorsState = createFeatureSelector<GenerationFormsState>('generator');

export const selectFormEntities = createSelector(selectGeneratorsState, (state) => state.entities);

export const selectCurrentFormId = createSelector(selectGeneratorsState, (state) => state.selectedFormId);

export const selectCurrentForm = createSelector(selectFormEntities, selectCurrentFormId, (formEntities, formId) => {
    if (!formId) {
        return null;
    }
    return formEntities[formId];
});

export const selectCurrentFormCategoryList = createSelector(selectCurrentForm, (form: GenerationForm) => form.categoryList);
export const selectCurrentFormTourisics = createSelector(selectCurrentForm, (form: GenerationForm) => form.touristics);
export const selectCurrentFormSpecials = createSelector(selectCurrentForm, (form: GenerationForm) => form.specials);
export const selectCurrentFormOffers = createSelector(selectCurrentForm, (form: GenerationForm) => form.offers);
export const selectCurrentFormEquipment = createSelector(selectCurrentForm, (form: GenerationForm) => form.equipment);
export const selectCurrentFormAttributes = createSelector(selectCurrentForm, (form: GenerationForm) => form.attributes);
export const selectCurrentFormAudience = createSelector(selectCurrentForm, (form: GenerationForm) => form.audience);
