import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { CredentialsService } from ':core/services/credentials.service';
import { ToastService } from ':core/services/toast.service';
import { ZenchefConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { SearchPlatformRestaurantsService } from ':modules/platforms/platforms-connection-modals/shared/services/search-platform-restaurants.service';
import { UpdateCredentialsBasedPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/update-credentials-based-platform.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-zenchef-connection-step-1',
    templateUrl: './zenchef-connection-step-1.component.html',
    styleUrls: ['./zenchef-connection-step-1.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        InputTextComponent,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZenchefConnectionStep1Component extends BaseStepComponent<undefined, ZenchefConnectionModalResult> {
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _searchPlatformRestaurantsService = inject(SearchPlatformRestaurantsService);
    private readonly _updateCredentialsBasedPlatformService = inject(UpdateCredentialsBasedPlatformService);
    private readonly _credentialsService = inject(CredentialsService);

    readonly ButtonStyle = ButtonStyle;

    readonly zenchefRestaurantIdControl = new FormControl<string>('', Validators.required);

    readonly isUpdatingPlatform = signal<boolean>(false);

    onPrimaryClick(): void {
        const zenchefRestaurantId = this.zenchefRestaurantIdControl.value;
        if (!zenchefRestaurantId) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }
        this.isUpdatingPlatform.set(true);
        this._searchPlatformRestaurantsService
            .execute(PlatformKey.ZENCHEF, undefined, zenchefRestaurantId)
            .pipe(
                catchError(() => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('platforms.connection_new.zenchef.step_1.restaurant_not_found')
                    );
                    return EMPTY;
                }),
                switchMap((platformsSearch) => forkJoin([of(platformsSearch), this._credentialsService.getZenchefSuperCredentialId()])),
                switchMap(([platformsSearch, superCredentialIdData]) => {
                    const platformSearch = platformsSearch[0];
                    if (!platformSearch) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('platforms.connection_new.zenchef.step_1.restaurant_not_found')
                        );
                        return EMPTY;
                    }
                    return of([platformSearch, superCredentialIdData] as const);
                }),
                switchMap(([platformSearch, superCredentialIdData]) =>
                    this._updateCredentialsBasedPlatformService.execute({
                        platformKey: PlatformKey.ZENCHEF,
                        platformSearch: platformSearch,
                        credentialId: superCredentialIdData.data,
                    })
                )
            )
            .subscribe({
                next: () => {
                    this.isUpdatingPlatform.set(false);
                    this.close.emit({ hasDataChanged: true });
                    this._toastService.openSuccessToast(
                        this._translateService.instant('platforms.connection_new.zenchef.step_1.connection_success')
                    );
                },
                error: (err) => {
                    console.error(err);
                    this.isUpdatingPlatform.set(false);
                    this._toastService.openErrorToast(this._translateService.instant('common.error'));
                },
                complete: () => this.isUpdatingPlatform.set(false),
            });
    }

    onTertiaryClick(): void {
        window.open(
            'https://welcomehomemalou.notion.site/Comment-connecter-Zenchef-la-MalouApp-b547bf0932c845168991144a9775170f',
            '_blank'
        );
    }
}
