import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { SynchronizationStatus } from ':modules/reviews/store/reviews.interface';
import { selectReviewsSynchronizationStatus } from ':modules/reviews/store/reviews.selectors';
import { formatStringDate } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-reviews-header-synchronize',
    templateUrl: './reviews-header-synchronize.component.html',
    styleUrls: ['./reviews-header-synchronize.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderSynchronizeComponent {
    readonly id = input<string>();
    readonly isAggregatedView = input.required<boolean>();

    private readonly _translate = inject(TranslateService);
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly SvgIcon = SvgIcon;

    readonly synchronizationStatus = toSignal(this._store.select(selectReviewsSynchronizationStatus));
    readonly isSynchronizationLoading = computed(() => this.synchronizationStatus() === SynchronizationStatus.LOADING);
    readonly currentRestaurant$ = this._restaurantsService.restaurantSelected$;
    readonly currentRestaurant = toSignal(this.currentRestaurant$, { initialValue: null });

    readonly syncTooltip = computed(() => {
        const synchronizationStatus = this.synchronizationStatus();
        const isAggregatedView = this.isAggregatedView();
        const restaurant = this.currentRestaurant();

        if (synchronizationStatus === SynchronizationStatus.LOADING) {
            return this._translate.instant('common.synchronizing');
        } else {
            if (isAggregatedView) {
                return this._translate.instant('common.synchronize');
            } else {
                const lastReviewUpdate = restaurant?.reviewsLastUpdate
                    ? formatStringDate(restaurant.reviewsLastUpdate)
                    : this._translate.instant('reviews.never');
                return this._translate.instant('reviews.updated_on') + lastReviewUpdate;
            }
        }
    });

    onClick(): void {
        this._store.dispatch(ReviewsActions.synchronizeReviews({ forceSynchronize: true }));
    }
}
