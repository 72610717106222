<div class="flex h-16 w-full gap-3 rounded border border-malou-color-background-dark bg-white p-1 pr-3 sm:h-20">
    <div class="flex w-11 items-center justify-center rounded bg-malou-color-background-darker">
        <img class="m-3 h-4" [src]="item().emoji | roiSavedTimePathResolver" />
    </div>
    <div class="flex flex-1 flex-col justify-center">
        <span class="malou-text-12--bold text-malou-color-text-1">{{ item().title }}</span>
        <span class="malou-text-10 italic text-malou-color-text-2">{{ item().details }}</span>
    </div>
    <div class="flex items-center gap-1">
        <span class="malou-text-12--bold text-malou-color-text-1">{{ item().currentTime | millisecondsToHourMinutePipe }}</span>
        <app-number-evolution
            size="xs"
            [value]="evolution()"
            [displayedValue]="
                evolution() | shortNumber: { content: '%', shouldDisplayMinusSign: false, fixNumber: 0 }
            "></app-number-evolution>
    </div>
</div>
