@if (currentFolder) {
    <div class="mb-5 flex items-center">
        <span class="malou-text-14 cursor-pointer italic text-malou-color-text-2" (click)="onOpenFolder(null)">
            {{ 'gallery.folders.gallery' | translate }}
        </span>
        <mat-icon class="mx-1 !h-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
        <span class="malou-text-14--semibold text-malou-color-text-1">{{ currentFolder.name }}</span>
    </div>
} @else {
    @if (folders.length > 0) {
        <div class="mb-6 flex flex-col">
            <div class="malou-text__card-title mb-4 text-malou-color-text-1">
                {{ 'media_picker_modal.folders' | translate }}
            </div>
            <div class="folders-list-grid grid gap-5 md:!grid-cols-2 md:gap-3">
                @for (folder of folders; track trackByIdFn($index, folder)) {
                    <div class="malou-card !m-0 cursor-pointer !py-4 md:!p-4" (click)="onOpenFolder(folder)">
                        <div class="malou-card__body malou-text-12--semibold flex w-full grow p-0">
                            <div class="mr-1 max-w-fit truncate" [matTooltip]="folder.name + ' (' + folder.mediaCount + ')'">
                                {{ folder.name }}
                            </div>
                            <div>({{ folder.mediaCount }})</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    }
}
