<div class="lines-legend malou-text-8--regular mx-5 mt-3 flex">
    <div class="mr-5 flex items-center">
        <span class="line--solid mr-2.5 flex h-[3px] w-6"></span>
        <span>{{ 'statistics.common.current_period' | translate }}</span>
    </div>
    <div class="mr-5 flex items-center">
        <span class="line--dashed relative mr-2.5 flex h-[3px] w-6"></span>
        <span class="flex items-center">
            {{ 'statistics.common.previous_period' | translate }}
            @if (showMaxRangeError()) {
                <mat-icon
                    class="ml-1 !h-4 !w-4"
                    [svgIcon]="SvgIcon.INFO"
                    [matTooltip]="'statistics.seo.gmb_impressions.no_data_before_18_months' | translate">
                </mat-icon>
            }
        </span>
    </div>
</div>
