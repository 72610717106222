import { Routes } from '@angular/router';

import { PostsListComponent } from ':modules/posts/posts-list/posts-list.component';

import { PostsRootComponent } from './posts-root.component';

export const POSTS_ROUTES: Routes = [
    {
        path: '',
        component: PostsRootComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: PostsListComponent,
            },
        ],
    },
];
