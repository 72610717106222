import { createAction, props } from '@ngrx/store';

export const initializeAggregatedFilters = createAction(
    '[APP] Initialize aggregated statistics and reviews filters',
    props<{ userId: string }>()
);
export const initializeRestaurantFilters = createAction(
    '[APP] Initialize restaurant statistics and reviews filters',
    props<{ userId: string; restaurantId: string }>()
);
