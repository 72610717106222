import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, exhaustMap, filter, first, interval, Observable, race, skip, switchMap, tap, timer } from 'rxjs';

import { TimeInMilliseconds } from '@malou-io/package-utils';

import { NewVersionModalComponent } from ':shared/components/new-version-modal/new-version-modal.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

/**
 * We use Service workers to check if the app has been updated and prompt the user to reload the page to update his version.
 *
 * By default, angular check for updates when the SW is initialized or when we first load the app
 * (https://angular.dev/ecosystem/service-workers/communications#checking-for-updates,
 * for further explanations, see https://github.com/angular/angular/issues/39652#issuecomment-729576739).
 * This is why we also check with a 1 minute interval manually in this code.
 *
 * At this point, we ensure that the SW make some call to the server to check for updates,
 * so we just subscribe to the 'VERSION_READY' event (https://angular.dev/ecosystem/service-workers/communications#version-updates)
 * that is triggered when a new version is installed in the SW cache and ready to be served,
 * then, we prompt the user to reload the page.
 */
@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
    constructor(
        private readonly _swUpdates: SwUpdate,
        private readonly _customDialogService: CustomDialogService,
        private readonly _applicationRef: ApplicationRef
    ) {}

    init(): void {
        this._listenToVersionReadyEventAndShowPopup();
        this._checkForUpdatesAtInterval();
        this._listenToEventsAndLog();
    }

    private _checkForUpdatesAtInterval(): void {
        const appIsStable$ = this._applicationRef.isStable.pipe(first((isStable) => isStable === true));
        const timer$ = timer(30 * TimeInMilliseconds.SECOND);
        const whenAppIsStableOrAfter30Seconds$ = race(appIsStable$, timer$);
        const everyMinute$ = interval(TimeInMilliseconds.MINUTE);
        const everyMinuteAfterDelay$ = concat(whenAppIsStableOrAfter30Seconds$, everyMinute$).pipe(skip(1));
        everyMinuteAfterDelay$.subscribe({
            next: async () => {
                try {
                    const updateFound = await this._swUpdates.checkForUpdate();
                    console.info(
                        updateFound
                            ? '[CheckForUpdateService] Check for update : A new version is available.'
                            : '[CheckForUpdateService] Check for update : Already on the latest version.'
                    );
                } catch (err) {
                    console.error('[CheckForUpdateService] Check for update : Failed:', err);
                }
            },
        });
    }

    /**
     * We want to skip the first emission if it appears within one minute
     * because if an emission (aka new version ready) appears within one minute after the user enter the app,
     * it's just the Service Worker updating its cache and due too the ngsw-config.json "navigationRequestStrategy": "freshness",
     * we are sure that the user already got the latest version of the app,
     * so we do not want to trigger the popup that asks to reload the page
     *
     * This Service Worker behavior might be intended or might be a bug, follow https://github.com/angular/angular/issues/54036 for more info.
     */
    private _listenToVersionReadyEventAndShowPopup(): void {
        const versionUpdate$ = this._swUpdates.versionUpdates.pipe(filter((evt) => evt.type === 'VERSION_READY'));
        const firstUpdate$ = versionUpdate$.pipe(skip(1), first());
        const oneMinute$ = timer(TimeInMilliseconds.MINUTE);
        const onFirstEmissionOrAfterOneMinute$ = race(firstUpdate$, oneMinute$);

        onFirstEmissionOrAfterOneMinute$
            .pipe(
                tap(() => console.info('[CheckForUpdateService] Start to listen to updates')),
                switchMap(() => versionUpdate$),
                exhaustMap(() => this._promptUserToReload())
            )
            .subscribe();
    }

    private _promptUserToReload(): Observable<void> {
        return this._customDialogService
            .open(NewVersionModalComponent, {
                width: '600px',
                height: 'auto',
                disableClose: false,
            })
            .afterClosed();
    }

    private _listenToEventsAndLog(): void {
        this._swUpdates.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
                case 'NO_NEW_VERSION_DETECTED':
                    console.info(`[CheckForUpdateService] No new version detected : ${evt.version.hash}`);
                    break;
                case 'VERSION_DETECTED':
                    console.info(`[CheckForUpdateService] New version detected, start downloading... : ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.info(`[CheckForUpdateService] Current app version: ${evt.currentVersion.hash}`);
                    console.info(`[CheckForUpdateService] New app version ready for use: ${evt.latestVersion.hash}`);
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.warn(`[CheckForUpdateService] Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });

        this._swUpdates.unrecoverable.subscribe((event) => {
            console.warn(
                '[CheckForUpdateService] An error occurred that we cannot recover from:\n' + event.reason + '\n\nPlease reload the page.'
            );
        });
    }
}
