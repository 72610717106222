<div class="flex w-full flex-col">
    @if (title || subtitle || required) {
        <div class="malou-text-10--regular malou-color-text-2 gap-1">
            @if (title) {
                <div>
                    <span>{{ title }}</span>
                    @if (required) {
                        <span> *</span>
                    }
                </div>
            }
            @if (subtitle) {
                <div class="malou-text-10 italic">{{ subtitle }}</div>
            }
        </div>
    }

    <div
        class="malou-border-primary relative flex h-12 self-stretch rounded-lg bg-white"
        [class.malou-border-secondary]="!errorMessage && isFocused"
        [class.malou-border-error]="!!errorMessage">
        <div class="w-full px-5">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                type="text"
                [id]="id"
                [class.italic]="isEmptyValue()"
                [class.malou-text-12]="isEmptyValue()"
                [placeholder]="placeholder"
                [formControl]="control"
                [attr.data-testid]="testId()"
                (focus)="onFocus()"
                (blur)="isFocused.set(false)" />
        </div>

        @if (icon) {
            <div class="flex items-center pr-5">
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="icon"></mat-icon>
            </div>
        }

        @if (isFocused()) {
            <div
                class="options-container absolute top-12 z-10 w-full rounded-lg bg-white py-2.5"
                [ngClass]="{ 'pb-1': messageWhenFewResults && filteredAndSlicedOptions().length < maxOptionsDisplayed }">
                @for (option of filteredAndSlicedOptions(); track option) {
                    <div
                        class="options-item flex h-12 cursor-pointer items-center border-0 px-4"
                        [ngClass]="{ 'options-item-selected malou-text-14--semibold': isSelected | applyPure: option }"
                        (pointerdown)="onOptionSelected(option)">
                        <div class="malou-text-15--regular overflow-hidden">{{ displayWithFn | applyPure: option }}</div>
                    </div>
                }
                @if (messageWhenFewResults && filteredAndSlicedOptions().length < maxOptionsDisplayed) {
                    <div class="malou-text-10 flex h-10 items-center border-0 px-4 italic">
                        {{ messageWhenFewResults }}
                    </div>
                }
            </div>
        }
    </div>

    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
</div>
