import { Component, input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BubbleDataPoint,
    Chart,
    ChartDataset,
    ChartEvent,
    ChartOptions,
    ChartType,
    ChartTypeRegistry,
    Plugin,
    Point,
    Tick,
    TooltipItem,
} from 'chart.js';
import { groupBy } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { isNotNil, PartialRecord, ReviewAnalysisTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { SegmentAnalysisModalComponent } from ':shared/components/review-analyses/tags-bar-chart/segment-analysis-modal/segment-analysis-modal.component';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorGreen, malouChartColorRed, malouChartColorText2 } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ReviewWithAnalysis, SegmentWithReview } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_DISPLAYED_TAGS: ReviewAnalysisTag[] = [
    ReviewAnalysisTag.FOOD,
    ReviewAnalysisTag.SERVICE,
    ReviewAnalysisTag.ATMOSPHERE,
    ReviewAnalysisTag.PRICE,
    ReviewAnalysisTag.EXPEDITIOUSNESS,
    ReviewAnalysisTag.HYGIENE,
];

type BarChartType = Extract<ChartType, 'bar'>;

@Component({
    selector: 'app-tags-bar-chart',
    templateUrl: './tags-bar-chart.component.html',
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe],
})
export class TagsBarChartComponent implements OnInit, OnChanges {
    reviews = input<ReviewWithAnalysis[]>([]);
    shouldDisplayAnalysesTagClickableLabels = input.required<boolean>();
    isFromAggregatedStatistics = input.required<boolean>();

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly LABEL_CLICK_PLUGIN: Plugin = this._getLabelClickPlugin();

    segments: SegmentWithReview[];
    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: string[][];
    chartOption: ChartOptions<BarChartType>;
    displayedTags: ReviewAnalysisTag[] = [];

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _zone: NgZone,
        private readonly _translate: TranslateService,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviews')) {
            this.displayedTags = DEFAULT_DISPLAYED_TAGS;
            this.segments = this._buildSegmentsWithReview(this.reviews());
            this.chartDataSets = this._computeChartData(this.segments);
            this.chartLabels = this._computeChartLabels(this.segments);
        }
    }

    private _buildSegmentsWithReview(reviews: ReviewWithAnalysis[]): SegmentWithReview[] {
        return reviews
            .map((review) =>
                review?.semanticAnalysis?.segmentAnalyses.map((segment) => ({
                    ...segment,
                    review,
                }))
            )
            .flat()
            .filter(isNotNil)
            .filter((el) => el?.tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE);
    }

    private _computeChartLabels(segments: SegmentWithReview[]): string[][] {
        const tagsMap = this._buildTagsMap(segments);
        return this.shouldDisplayAnalysesTagClickableLabels()
            ? this.displayedTags.map((tag) => [
                  `${this._translate.instant(`review_analysis_tags.${tag}`)}  (${tagsMap[tag]?.length})`,
                  this._translate.instant('statistics.e_reputation.reviews_analysis.see_detail'),
              ])
            : this.displayedTags.map((tag) => [`${this._translate.instant(`review_analysis_tags.${tag}`)}  (${tagsMap[tag]?.length})`]);
    }

    private _computeChartData(data: SegmentWithReview[]): ChartDataset<BarChartType, ChartDataArray>[] {
        const positiveSegments = data.filter((s) => s?.sentiment === SemanticAnalysisSentiment.POSITIVE);
        const negativeSegments = data.filter((s) => s?.sentiment === SemanticAnalysisSentiment.NEGATIVE);

        const positiveTagsMap = this._buildTagsMap(positiveSegments);
        const negativeTagsMap = this._buildTagsMap(negativeSegments);

        this.displayedTags = this.displayedTags.filter((tag) => positiveTagsMap[tag]?.length || negativeTagsMap[tag]?.length);

        return [
            {
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: this.displayedTags.map((label) => positiveTagsMap[label]?.length || 0),
            },
            {
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: this.displayedTags.map((label) => negativeTagsMap[label]?.length || 0),
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (tooltipItems: TooltipItem<any>) => this._computeTooltipLabel(tooltipItems),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        crossAlign: 'center',
                        color: this.shouldDisplayAnalysesTagClickableLabels() ? malouChartColorBluePurple : malouChartColorText2,
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItems: TooltipItem<any>): string {
        const sentiment =
            tooltipItems.datasetIndex === 0
                ? this._translate.instant('statistics.e_reputation.reviews_analysis.positive')
                : this._translate.instant('statistics.e_reputation.reviews_analysis.negative');
        return this._translate.instant('statistics.e_reputation.reviews_analysis.feeling', {
            sentimentsNum: tooltipItems.formattedValue,
            sentiment,
        });
    }

    private _buildTagsMap(segmentsWithReview: SegmentWithReview[]): PartialRecord<ReviewAnalysisTag, SegmentWithReview[]> {
        const object = groupBy(segmentsWithReview, 'tag');
        Object.values(ReviewAnalysisTag)
            .filter((tag) => tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE)
            .forEach((tag) => (object[tag] = object[tag] || []));
        return object;
    }

    private _openSegmentAnalysisModal(tag: ReviewAnalysisTag): void {
        const tagsMap = this._buildTagsMap(this.segments);
        this._customDialogService.open(SegmentAnalysisModalComponent, {
            width: '80vw',
            height: '80vh',
            disableClose: true,
            data: {
                segments: tagsMap[tag],
                isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
            },
        });
    }

    private _getLabelClickPlugin(): Plugin {
        return {
            id: 'labelClick',
            afterEvent: (chart: Chart, args: { event: ChartEvent }): void => {
                const { event } = args;
                const detailsBtnOffset = 27;
                const datasets = chart.data.datasets;
                if (event.type !== 'click') {
                    return;
                }
                const { x, y } = args.event;

                if (
                    !x ||
                    x < chart.scales.xAxis.left ||
                    x > chart.scales.xAxis.right ||
                    !y ||
                    y < chart.scales.xAxis.top - detailsBtnOffset
                ) {
                    return;
                }

                const index = chart.scales.xAxis.getValueForPixel(x);

                if (index !== undefined && !this._isDataPointEqualToZero(datasets, index)) {
                    const clickedTag = this.displayedTags[index];
                    this._zone.run(() => this._openSegmentAnalysisModal(clickedTag));
                }
            },
        };
    }

    private _isDataPointEqualToZero(
        datasets: ChartDataset<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[]>[],
        index: number
    ): boolean {
        if (index < 0) {
            return false;
        }
        return datasets.reduce((acc, next) => (next.data[index] as number) + acc, 0) === 0;
    }
}
