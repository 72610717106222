import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'createArray',
    standalone: true,
})
export class CreateArrayPipe implements PipeTransform {
    transform(length: number): Array<any> {
        return Array(length);
    }
}
