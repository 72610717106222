import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-wheel-of-fortune-not-found',
    templateUrl: './wheel-of-fortune-not-found.component.html',
    styleUrls: ['./wheel-of-fortune-not-found.component.scss'],
    standalone: true,
    imports: [TranslateModule, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneNotFoundComponent {}
