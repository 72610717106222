<div class="grid h-full w-full place-items-center">
    <div class="flex w-full flex-col items-center gap-y-6">
        <img height="100" width="100" [lazyLoad]="Illustration.Karl | illustrationPathResolver" />
        <div class="malou-text-14--bold malou-color-text-1 max-w-[70%] text-center">
            {{ 'templates.review.no_templates.title' | translate }}
            <div class="malou-text-10--regular malou-color-text-2 mt-3 text-center">
                {{ 'templates.review.no_templates.subtitle' | translate }}
            </div>
        </div>
        <div class="text-center">
            <div class="mb-2 flex gap-x-2">
                <button
                    class="malou-btn-raised--secondary !h-[43px] !bg-white"
                    mat-raised-button
                    (click)="generateTemplates([TemplateTranslationLang.FR])">
                    <div class="!flex !items-center">
                        <img
                            class="mr-3 !h-4 !w-4"
                            [src]="TemplateTranslationLang.FR | flagPathResolver"
                            [alt]="getPrettyLang | applyPure: TemplateTranslationLang.FR" />
                        {{ 'templates.review.no_templates.add_french_templates' | translate }}
                    </div>
                </button>
                <button
                    class="malou-btn-raised--secondary !h-[43px] !bg-white"
                    mat-raised-button
                    (click)="generateTemplates([TemplateTranslationLang.EN])">
                    <div class="!flex !items-center">
                        <img
                            class="mr-3 !h-4 !w-4"
                            [src]="TemplateTranslationLang.EN | flagPathResolver"
                            [alt]="getPrettyLang | applyPure: TemplateTranslationLang.EN" />
                        {{ 'templates.review.no_templates.add_english_templates' | translate }}
                    </div>
                </button>
            </div>
            <button
                class="malou-btn-raised--secondary !h-[43px] !bg-white"
                mat-raised-button
                (click)="generateTemplates([TemplateTranslationLang.FR, TemplateTranslationLang.EN])">
                {{ 'templates.review.no_templates.add_french_and_english_templates' | translate }}
            </button>
        </div>
    </div>
</div>
