<div class="flex w-full items-center gap-x-2">
    <div class="grow rounded-full" [style]="{ height: height() }" [ngClass]="backgroundColor()">
        <div class="h-full rounded-full {{ progressBarColorClass() }}" [style]="{ width: progressPercentage() }"></div>
    </div>
    @if (showPercentage && !shouldDisplayProgressCompletedAction() && !shouldDisplayProgressCompletedMessage()) {
        <div
            class="text-right"
            [ngClass]="{
                'malou-text-12--semibold w-[28px]': size() === LoaderProgressSize.MD || size() === LoaderProgressSize.LG,
                'malou-text-8--semibold w-[24px]': size() === LoaderProgressSize.SM,
            }">
            {{ progressPercentage() }}
        </div>
    }
    @if (shouldDisplayProgressCompletedAction()) {
        <button
            class="malou-btn-flat !min-w-0 !px-0"
            mat-button
            [ngClass]="{
                'min-w-[28px]': size() === LoaderProgressSize.MD || size() === LoaderProgressSize.LG,
                'min-w-[24px]': size() === LoaderProgressSize.SM,
            }"
            [style]="{ height: height() }"
            (click)="action()()">
            {{ actionMessage() }}
        </button>
    }
    @if (shouldDisplayProgressCompletedMessage()) {
        <div
            class="malou-text-10--regular !min-w-0 text-center"
            [ngClass]="{
                'malou-text-12--semibold min-w-[28px]': size() === LoaderProgressSize.MD || size() === LoaderProgressSize.LG,
                'malou-text-8--semibold min-w-[24px]': size() === LoaderProgressSize.SM,
            }"
            [style]="{ height: height() }">
            {{ progressCompletedText() }}
        </div>
    }
</div>
