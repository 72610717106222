import { ChangeDetectionStrategy, Component, computed, DestroyRef, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { combineLatest, map, Observable } from 'rxjs';

import { KeywordsGenerationState, KeywordsService } from ':core/services/keywords.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { Restaurant } from ':shared/models/restaurant';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { KeywordsGeneratingFooterPopinItemComponent } from './keywords-generating-footer-popin-item/keywords-generating-footer-popin-item.component';

export interface KeywordsGenerationStateWithRestaurant extends KeywordsGenerationState {
    restaurant: Restaurant;
}

@Component({
    selector: 'app-keywords-generating-footer-popin',
    templateUrl: './keywords-generating-footer-popin.component.html',
    styleUrls: ['./keywords-generating-footer-popin.component.scss'],
    standalone: true,
    imports: [MatIconModule, TranslateModule, KeywordsGeneratingFooterPopinItemComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsGeneratingFooterPopinComponent {
    readonly SvgIcon = SvgIcon;
    readonly keywordsGenerationStateWithRestaurants$ = this._initKeywordsGenerationStateWithRestaurants$();
    readonly keywordsGenerationStateWithRestaurants: Signal<KeywordsGenerationStateWithRestaurant[]> = toSignal(
        this.keywordsGenerationStateWithRestaurants$,
        { initialValue: [] as KeywordsGenerationStateWithRestaurant[] }
    );

    readonly allGenerationAreOver = computed(() =>
        this.keywordsGenerationStateWithRestaurants().every((state) => state.generationStartDate === null)
    );

    readonly atLeastOneGenerationState = computed(() => this.keywordsGenerationStateWithRestaurants().length > 0);

    constructor(
        private readonly _keywordsService: KeywordsService,
        private readonly _store: Store,
        private readonly _destroyRef: DestroyRef
    ) {}

    private _initKeywordsGenerationStateWithRestaurants$(): Observable<KeywordsGenerationStateWithRestaurant[]> {
        return combineLatest([this._keywordsService.keywordsGenerationState$, this._store.select(selectOwnRestaurants)]).pipe(
            map(([keywordsGenerationState, restaurants]) => {
                const restaurantIds = Object.keys(keywordsGenerationState);
                const keywordsGenerationStateWithRestaurants = restaurantIds.map((restaurantId) => {
                    const restaurant = restaurants.find((r) => r._id === restaurantId);
                    return restaurant ? { ...keywordsGenerationState[restaurantId], restaurant: new Restaurant(restaurant) } : undefined;
                });
                return compact(keywordsGenerationStateWithRestaurants);
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }
}
