<mat-icon class="ml-3 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN" [matMenuTriggerFor]="actionsMenu"></mat-icon>
<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
    @for (lang of applicationLanguages; track lang) {
        <div mat-menu-item [disabled]="disabled()">
            <button class="!flex items-center gap-x-3" (click)="updateKeywordLanguage(keyword(), lang)">
                <img class="h-4 w-4" [lazyLoad]="lang | flagPathResolver" [alt]="getPrettyLang | applyPure: lang" />
                <span class="malou-text-14--regular">{{ getPrettyLang | applyPure: lang }}</span>
            </button>
        </div>
    }
</mat-menu>
