<app-modal-structure
    [title]="'platforms.connection_new.facebook_instagram.step_1.title' | translate"
    [primaryButtonText]="'common.next' | translate"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="goToStep.emit({ type: 'relative', value: 1 })"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex gap-x-4">
        <div class="flex flex-2 flex-col justify-center gap-y-6">
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text:
                            'platforms.connection_new.facebook_instagram.step_1.description_1'
                            | translate: { class: 'malou-text-13--bold' },
                        showUrl: true,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text:
                            'platforms.connection_new.facebook_instagram.step_1.description_2'
                            | translate: { class: 'malou-text-13--bold' },
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_1.description_3' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_1.description_4' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
        </div>
        <div class="flex flex-1 items-center">
            <img [src]="'instagram-download-page' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
        </div>
    </div>
</app-modal-structure>

<ng-template let-showUrl="showUrl" let-text="text" #descriptionTemplate>
    <div class="malou-text-13--medium">
        <ng-container [ngTemplateOutlet]="descriptionTextTemplate" [ngTemplateOutletContext]="{ text }"></ng-container>
        @if (showUrl) {
            &nbsp;<ng-container *ngTemplateOutlet="description1UrlTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-text="text" #descriptionTextTemplate>
    <span class="malou-color-text-1" [innerHtml]="text"></span>
</ng-template>

<ng-template #description1UrlTemplate>
    <a class="malou-color-text-primary whitespace-nowrap" href="https://instagram.com" target="_blank">{{
        'platforms.connection_new.facebook_instagram.step_1.description_1_url' | translate
    }}</a>
</ng-template>
