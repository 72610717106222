import { Action, createReducer, on } from '@ngrx/store';
import { xor } from 'lodash';

import { PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { BindingIdKey } from ':core/constants';
import { MalouDateFilters, MalouPeriod, Post, PostsFilters } from ':shared/models';

import * as PostsActions from './posts.actions';

export interface PostsState {
    filters: PostsFilters;
    postsBindingId: string | null;
    bindingIdKey: BindingIdKey;
    currentlyPublishingPosts: Post[];
    refreshDates: Record<string, Date>;
}

export const defaultPublicationStatus = [PostPublicationStatus.PUBLISHED, PostPublicationStatus.PENDING, PostPublicationStatus.DRAFT];

const dateFilters = new MalouDateFilters();

export const initialState: PostsState = {
    filters: {
        platforms: [PlatformKey.GMB],
        ...dateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_SIX_MONTH }),
        sortBy: 'date',
        sortOrder: -1,
        publicationStatus: defaultPublicationStatus,
    },
    bindingIdKey: BindingIdKey.BINDING_ID,
    postsBindingId: null,
    currentlyPublishingPosts: [],
    refreshDates: {},
};

const postsReducer = createReducer(
    initialState,
    on(
        PostsActions.editPostsFilters,
        (state, { filters }) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }) // better way ? https://github.com/paularmstrong/normalizr https://github.com/reduxjs/redux/issues/994
    ),
    on(PostsActions.editPostsFiltersPlatforms, (state, { platforms }) => ({
        ...state,
        filters: {
            ...state.filters,
            platforms: xor(state.filters.platforms, platforms),
        },
    })),
    on(PostsActions.setPostsFiltersPlatforms, (state, { platforms }) => ({
        ...state,
        filters: {
            ...state.filters,
            platforms,
        },
    })),
    on(PostsActions.changeSortOrder, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            sortOrder: (state.filters.sortOrder || -1) * -1,
        },
    })),
    on(PostsActions.resetPostsFiltersDates, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            ...dateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_SIX_MONTH }),
        },
    })),
    on(PostsActions.resetPostsFiltersStatus, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            publicationStatus: defaultPublicationStatus,
        },
    })),
    on(PostsActions.updatePostsBindingId, (state, { bindingId, bindingIdKey }) => ({
        ...state,
        postsBindingId: bindingId,
        bindingIdKey,
    })),
    on(PostsActions.resetPostsBindingId, (state) => ({
        ...state,
        postsBindingId: initialState.postsBindingId,
        bindingIdKey: initialState.bindingIdKey,
    })),
    on(PostsActions.setCurrentlyPublishingPosts, (state, { posts }) => ({
        ...state,
        currentlyPublishingPosts: posts,
    })),
    on(PostsActions.updateRefreshDates, (state, { postId }) => ({
        ...state,
        refreshDates: {
            ...state.refreshDates,
            [postId]: new Date(),
        },
    }))
);

export function reducer(state: PostsState | undefined, action: Action): PostsState {
    return postsReducer(state, action);
}
