import { z } from 'zod';

import { BaseEmailProps } from '../index';

export const publicationFailedEmailValidator = z.object({
    link: z.string(),
    fullPlatformKey: z.string(),
    platformSlug: z.string(),
    postImgUrl: z.string(),
    postText: z.string(),
    errorMessage: z.string().optional(),
    ctaText: z.string(),
    isStory: z.boolean().optional(),
});

export type PublicationFailedEmailProps = z.infer<typeof publicationFailedEmailValidator> & BaseEmailProps;
