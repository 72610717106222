import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getFolderByIdParamsValidator = z.object({ folder_id: objectIdValidator }).transform((data) => ({ folderId: data.folder_id }));
export type GetFolderByIdParamsDto = z.infer<typeof getFolderByIdParamsValidator>;
// ------------------------------------------------------------------------------------------
export const getFolderMediaParamsValidator = z.object({ folder_id: objectIdValidator }).transform((data) => ({ folderId: data.folder_id }));
export type GetFolderMediaParamsDto = z.infer<typeof getFolderMediaParamsValidator>;
// ------------------------------------------------------------------------------------------
export const getRestaurantFoldersParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetRestaurantFoldersParamsDto = z.infer<typeof getRestaurantFoldersParamsValidator>;

export const getRestaurantFoldersQueryValidator = z
    .object({
        parent_folder_id: objectIdValidator.nullish(),
        name_filter: z.string().optional(),
        count_only_never_used_media: z.string().optional(),
    })
    .transform((data) => ({
        parentFolderId: data.parent_folder_id ?? null,
        nameFilter: data.name_filter,
        countOnlyNeverUsedMedia: data.count_only_never_used_media === 'true',
    }));
export type GetRestaurantFoldersQueryDto = z.infer<typeof getRestaurantFoldersQueryValidator>;
// ------------------------------------------------------------------------------------------
export const createFolderParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type CreateFolderParamsDto = z.infer<typeof createFolderParamsValidator>;

export const createFolderBodyValidator = z.object({ name: z.string(), parentFolderId: objectIdValidator.nullish() });
export type CreateFolderBodyDto = z.infer<typeof createFolderBodyValidator>;
// ------------------------------------------------------------------------------------------
export const deleteFoldersQueryValidator = z
    .object({ folder_ids: z.array(objectIdValidator) })
    .transform((data) => ({ folderIds: data.folder_ids }));
export type DeleteFoldersQueryDto = z.infer<typeof deleteFoldersQueryValidator>;
// ------------------------------------------------------------------------------------------
export const getFoldersPublishedMediaCountQueryValidator = z
    .object({ folder_ids: z.array(objectIdValidator) })
    .transform((data) => ({ folderIds: data.folder_ids }));
export const getFoldersPublishedMediaCountParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetFoldersPublishedMediaCountQueryDto = z.infer<typeof getFoldersPublishedMediaCountQueryValidator>;
export type GetFoldersPublishedMediaCountParamsDto = z.infer<typeof getFoldersPublishedMediaCountParamsValidator>;
// ------------------------------------------------------------------------------------------
export const updateFolderParamsValidator = z.object({ folder_id: objectIdValidator }).transform((data) => ({ folderId: data.folder_id }));
export const updateFolderBodyValidator = z.object({
    name: z.string(),
    restaurantId: objectIdValidator,
});

export type UpdateFolderParamsDto = z.infer<typeof updateFolderParamsValidator>;
export type UpdateFolderBodyDto = z.infer<typeof updateFolderBodyValidator>;
// ------------------------------------------------------------------------------------------
export const duplicateFoldersParamsValidator = z.object({ restaurant_id: objectIdValidator });
export const duplicateFoldersBodyValidator = z.object({
    foldersToDuplicate: z.array(
        z
            .object({
                id: objectIdValidator,
                name: z.string(),
                parentFolderId: objectIdValidator.nullish(),
            })
            .transform((data) => ({ ...data, parentFolderId: data.parentFolderId ?? null }))
    ),
    restaurantIds: z.array(objectIdValidator),
});

export type DuplicateFoldersParamsDto = z.infer<typeof duplicateFoldersParamsValidator>;
export type DuplicateFoldersBodyDto = z.infer<typeof duplicateFoldersBodyValidator>;
