import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';

@Pipe({
    name: 'translateIn',
    pure: true,
    standalone: true,
})
/**
 * !!! Be careful when using this pipe, it can cause unexpected behavior.
 * (On a page when this pipe was used, the header was translated in another language as the one wanted)
 * https://airtable.com/appIqBldyX7wZlWnp/tblbOxMTpexQyxSTV/viwVSdtBlz857nQiA/reccEObUwMdOSA130
 */
export class TranslateInPipe implements PipeTransform {
    constructor(protected translateService: TranslateService) {
        translateService.setDefaultLang('en');
    }

    transform(key: string, langCode: string): Observable<string> {
        if (key) {
            return this.translateService.getTranslation(langCode).pipe(map((translations) => this._getTranslation(translations, key)));
        }
        return of('');
    }

    private _getTranslation(translations: any, key: string): string {
        return key.split('.').reduce((acc, curr) => acc?.[curr], translations) ?? key;
    }
}
