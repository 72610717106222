import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Ex :
 * // component.html
 * {{ 'GMB' | enumTranslate:'platform_key' }}
 * {{ category | enumTranslate:'category' : 'subtext' }}
 */
@Pipe({
    name: 'enumTranslate',
    standalone: true,
})
export class EnumTranslatePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(enumName: string, enumValue: string, translateKeySuffix?: string): string {
        if (translateKeySuffix) {
            return this._translate.instant(`enums.${enumValue}.${enumName.toLowerCase()}.${translateKeySuffix}`);
        }
        return this._translate.instant(`enums.${enumValue}.${enumName.toLowerCase()}`);
    }
}
