<div class="flex h-full w-full flex-col justify-end gap-y-2">
    <div class="flex flex-col gap-y-3 rounded-md pb-4">
        @for (option of options; track option; let i = $index) {
            <button class="malou-btn-raised--secondary !h-12.5" mat-raised-button (click)="emitAction(option)">
                <span class="malou-text-14--semibold">{{ option.name }}</span>
            </button>
        }

        <button class="malou-btn-raised--primary !h-12.5" mat-raised-button (click)="close()">
            <span class="malou-text-14--semibold">{{ 'common.close' | translate }}</span>
        </button>
    </div>
</div>
