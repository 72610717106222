import { NfcsPlatformKey, PlatformCategory, PlatformDefinition, PlatformKey, platformsKeys, SocialNetworkKey } from '../../constants';
import { isNotNil, mapLanguageStringsToISO } from '../../functions';
import {
    changeUrlDomain,
    isValidFacebookUrl,
    isValidInstagramUrl,
    isValidLinkedinUrl,
    isValidPinterestUrl,
    isValidTiktokUrl,
    isValidXUrl,
    isValidYoutubeUrl,
    transformFacebookUrl,
    transformInstagramUrl,
    transformLinkedinUrl,
    transformPinterestUrl,
    transformTiktokUrl,
    transformXUrl,
    transformYoutubeUrl,
} from '../../helpers';
import { YextPublisherId } from '../yext';

export namespace PlatformDefinitions {
    export const getPlatformDefinitions = (): PlatformDefinition[] => Object.values(platformsKeys);

    export const getPlatformDefinition = (platformKey: string): PlatformDefinition | undefined =>
        Object.entries(platformsKeys).find(([key, p]) => key === platformKey || p.key === platformKey)?.[1];

    // Configs
    export const getNonPrivatePlatforms = (): PlatformDefinition[] =>
        getPlatformDefinitions().filter((p) => p.category !== PlatformCategory.PRIVATE);

    export const getFeatureFlaggedPlatforms = (): PlatformDefinition[] => getPlatformDefinitions().filter((p) => p.featureFlagKey);

    export const getNonFeatureFlaggedPlatforms = (): PlatformDefinition[] => getPlatformDefinitions().filter((p) => !p.featureFlagKey);

    export const getPlatformsWithOauth = (): PlatformDefinition[] => getPlatformDefinitions().filter((p) => p.oauth);

    export const isOauthPlatform = (key: string): boolean => getPlatformDefinition(key)?.oauth ?? false;

    export const getPlatformsWithShouldFetchPermissions = (): PlatformDefinition[] =>
        getPlatformDefinitions().filter((p) => p.shouldFetchPermissions);

    export const getPlatformsKeysWithShouldDetectReviewsLang = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.shouldDetectReviewsLang)
            .map((p) => p.key);

    // Platform types
    export const getSocialPlatformKeys = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.category === PlatformCategory.SOCIAL)
            .map((p) => p.key);

    export const getDeliveryPlatformKeys = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.category === PlatformCategory.DELIVERY)
            .map((p) => p.key);

    export const getPlatformKeysLinkedWithMeta = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM].includes(p.key))
            .map((p) => p.key);

    // Posts
    export const getSeoPlatformKeysWithPost = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasPosts && p.category === PlatformCategory.SEO)
            .map((p) => p.key);

    export const getSocialPlatformKeysWithPost = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasPosts && p.category === PlatformCategory.SOCIAL)
            .map((p) => p.key);

    export const getSocialDefaultPlatformKeysForPost = (): PlatformKey[] =>
        getSocialPlatformKeysWithPost().filter((key) => key !== PlatformKey.MAPSTR);

    export const getPlatformKeysWithMentions = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasMentions)
            .map((p) => p.key);

    export const getPlatformKeysWithStories = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasStory)
            .map((p) => p.key);

    export const getSocialPlatformKeysWithDuplicablePosts = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.category === PlatformCategory.SOCIAL && p.hasPosts && p.hasDuplicablePosts)
            .map((p) => p.key);

    export const getSeoPlatformKeysWithDuplicablePosts = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.category === PlatformCategory.SEO && p.hasPosts && p.hasDuplicablePosts)
            .map((p) => p.key);

    export const getPlatformKeysWithFeed = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasFeed)
            .map((p) => p.key);

    export const canDeleteStory = (key: string): boolean => getPlatformDefinition(key)?.canDeleteStory ?? true;

    // Stats
    export const getPlatformKeysWithFollowers = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasFollowers)
            .map((p) => p.key);

    export const getPlatformKeysWithRSStats = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasRSStats)
            .map((p) => p.key);

    // Informations
    export const getPlatformKeysWithCategories = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasInformationCategories)
            .map((p) => p.key);

    export const getPlatformKeysWithHours = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasInformationHours)
            .map((p) => p.key);

    export const getPlatformKeysWithSuggestions = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasInformationSuggestions)
            .map((p) => p.key);

    export const shouldCompareInformation = (key: string): boolean => getPlatformDefinition(key)?.shouldCompareInformation ?? false;

    export const getPlatformKeysWithYextPublisherIds = (): YextPublisherId[] =>
        getPlatformDefinitions()
            .filter((p) => p.yextPublisherId)
            .map((p) => p.yextPublisherId as YextPublisherId);

    export const getPlatformKeysForYextPublisherIds = (yextPublisherIds: string[]): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => yextPublisherIds.includes(p.yextPublisherId ?? ''))
            .map((p) => p.key);

    // Reviews
    export const getPlatformKeysForReviewReplyAutomation = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.allowsReviewReplyAutomation)
            .map((p) => p.key);

    export const canReviewBeModified = (key: string): boolean => {
        const platform = getPlatformDefinition(key);
        if (!platform) {
            return false;
        }
        if (platform.hasReviews && platform.canReviewBeModified) {
            return true;
        }
        return false;
    };

    export const getPlatformKeysWithReview = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasReviews)
            .map((p) => p.key);

    export const getNonFeatureFlaggedPlatformsWithReviews = (): PlatformKey[] =>
        getNonFeatureFlaggedPlatforms()
            .filter((p) => p.hasReviews)
            .map((p) => p.key);

    export const getPlatformKeysWithRating = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasRating)
            .map((p) => p.key);

    export const hasRatingOutOfTen = (key: string): boolean => getPlatformDefinition(key)?.hasRatingOutOfTen ?? false;

    export const hasDelayToReplyReview = (key: string): boolean => !!getPlatformDefinition(key)?.maxReviewsDaysInterval;

    export const canHaveMultipleReplies = (key: string): boolean => getPlatformDefinition(key)?.reviewCanHaveMultipleReplies ?? false;

    export const getPlatformKeysWithComment = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasComments)
            .map((p) => p.key);

    // Messages
    export const getPlatformKeysWithMessages = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasMessages)
            .map((p) => p.key);

    export const getSocialPlatformKeysWithMessages = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasMessages && p.category === PlatformCategory.SOCIAL)
            .map((p) => p.key);

    // Totems
    export const getPlatformKeysNotRatedForTotems = (): NfcsPlatformKey[] => [
        ...getPlatformDefinitions()
            .filter((p) => !p.isRatedForTotems)
            .map((p) => p.key),
        NfcsPlatformKey.WHEEL_OF_FORTUNE_PLATFORM_KEY,
    ];

    export const getPlatformKeysForWheelOfFortuneRedirection = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.canRedirectInWheelOfFortune)
            .map((p) => p.key);

    export const getPlatformKeysWithReel = (): PlatformKey[] =>
        getPlatformDefinitions()
            .filter((p) => p.hasReel)
            .map((p) => p.key);
}

export const changePlatformUrlDomain = (url: string, platformKey: PlatformKey, langs: string[]): string => {
    const domainMap = PlatformDefinitions.getPlatformDefinition(platformKey)?.domainMap;

    if (!domainMap) {
        return url;
    }

    if (!url.includes(platformKey)) {
        return url;
    }

    // Find first language to match the domain map
    const isoLanguageCodes = mapLanguageStringsToISO(langs);
    const matchingLanguage = isoLanguageCodes.find((lang) => !!domainMap[lang]);

    if (!matchingLanguage) {
        return url;
    }

    return changeUrlDomain(url, domainMap[matchingLanguage] as string);
};

export const checkSocialNetworkUrl = (url: string, socialNetworkKey: SocialNetworkKey): boolean => {
    switch (socialNetworkKey) {
        case SocialNetworkKey.FACEBOOK:
            return isValidFacebookUrl(url);
        case SocialNetworkKey.INSTAGRAM:
            return isValidInstagramUrl(url);
        case SocialNetworkKey.TIKTOK:
            return isValidTiktokUrl(url);
        case SocialNetworkKey.LINKEDIN:
            return isValidLinkedinUrl(url);
        case SocialNetworkKey.PINTEREST:
            return isValidPinterestUrl(url);
        case SocialNetworkKey.YOUTUBE:
            return isValidYoutubeUrl(url);
        case SocialNetworkKey.X:
            return isValidXUrl(url);
        default:
            return false;
    }
};

export const checkSocialNetworkUrls = (socialNetworkUrls: { key: SocialNetworkKey; url: string }[]): boolean => {
    return socialNetworkUrls.every(({ key, url }) => checkSocialNetworkUrl(url, key));
};

export const formatSocialNetworkUrls = (
    socialNetworkUrls: { key: SocialNetworkKey; url: string }[]
): { key: SocialNetworkKey; url: string }[] => {
    const formatSocialNetworkUrl: Record<SocialNetworkKey, (url: string) => string | null> = {
        [SocialNetworkKey.FACEBOOK]: transformFacebookUrl,
        [SocialNetworkKey.INSTAGRAM]: transformInstagramUrl,
        [SocialNetworkKey.LINKEDIN]: transformLinkedinUrl,
        [SocialNetworkKey.PINTEREST]: transformPinterestUrl,
        [SocialNetworkKey.TIKTOK]: transformTiktokUrl,
        [SocialNetworkKey.X]: transformXUrl,
        [SocialNetworkKey.YOUTUBE]: transformYoutubeUrl,
    };

    return socialNetworkUrls
        .map(({ key, url }) => {
            const formattedUrl = formatSocialNetworkUrl[key]?.(url);

            if (!formattedUrl) {
                return undefined;
            }

            return { key, url: formattedUrl };
        })
        .filter(isNotNil);
};
