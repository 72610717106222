import { z } from 'zod';

export const handleCancelVerificationBodyValidator = z.object({
    brandId: z.string(),
    agentId: z.string(),
});
export type HandleCancelVerificationBodyDto = z.infer<typeof handleCancelVerificationBodyValidator>;

export const handleUpdateAgentBodyValidator = z.object({
    update: z.any(), // TODO match this with gmb agent properties
});
export type HandleUpdateAgentBodyDto = z.infer<typeof handleUpdateAgentBodyValidator>;

export const handleUpdateAgentUnlaunchBodyValidator = z.object({
    agentName: z.string(),
});
export type HandleUpdateAgentUnlaunchBodyDto = z.infer<typeof handleUpdateAgentUnlaunchBodyValidator>;
