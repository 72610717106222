import { z } from 'zod';

import { NfcStar } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { nfcSnapshotDtoValidator } from './scan.response.dto';

export const searchScanQueryValidator = z.object({
    nfcIds: z.array(z.string()).optional(),
    startScannedAt: z.string().datetime().optional(),
    endScannedAt: z.string().datetime().optional(),
    restaurantIds: z.array(objectIdValidator).optional(),
    ledToPublishedReview: z.boolean().optional(),
});
export type SearchScanQueryDto = z.infer<typeof searchScanQueryValidator>;

export const createScanBodyValidator = z.object({
    nfcId: objectIdValidator,
    scannedAt: z.string().datetime(),
    nfcSnapshot: nfcSnapshotDtoValidator.optional(),
    redirectedAt: z.string().datetime().optional(),
    starClicked: z.nativeEnum(NfcStar).optional(),
});

export type CreateScanBodyDto = z.infer<typeof createScanBodyValidator>;

export const patchScanParamsValidator = z.object({
    scanId: objectIdValidator,
});

export type PatchScanParamsDto = z.infer<typeof patchScanParamsValidator>;

export const patchScanBodyValidator = z.object({
    nfcId: objectIdValidator.optional(),
    scannedAt: z.string().datetime().optional(),
    nfcSnapshot: nfcSnapshotDtoValidator.optional(),
    redirectedAt: z.string().datetime().optional(),
    starClicked: z.nativeEnum(NfcStar).optional(),
});

export type PatchScanBodyDto = z.infer<typeof patchScanBodyValidator>;
