import { Routes } from '@angular/router';

import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import { CampaignsComponent } from './campaigns.component';

export const CAMPAIGNS_ROUTES: Routes = [
    {
        path: '',
        component: CampaignsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: CampaignsListComponent,
            },
        ],
    },
];
