export interface UserTimedAction {
    count: number;
    totalTime: number;
}

export enum SavedTimeKey {
    publishedSeoPosts = 'publishedSeoPosts',
    publishedSocialPosts = 'publishedSocialPosts',
    publishedStories = 'publishedStories',
    duplicatedDescriptions = 'duplicatedDescriptions',
    duplicatedAttributes = 'duplicatedAttributes',
    duplicatedKeywords = 'duplicatedKeywords',
    duplicatedSeoPosts = 'duplicatedSeoPosts',
    duplicatedSocialPosts = 'duplicatedSocialPosts',
    duplicatedStories = 'duplicatedStories',
    duplicatedMedia = 'duplicatedMedia',
    duplicatedMessageTemplates = 'duplicatedMessageTemplates',
    duplicatedReviewTemplates = 'duplicatedReviewTemplates',
    duplicatedClients = 'duplicatedClients',
    reviewsAnsweredAutomatically = 'reviewsAnsweredAutomatically',
    reviewsAnsweredWithTemplate = 'reviewsAnsweredWithTemplate',
    reviewsAnsweredManually = 'reviewsAnsweredManually',
    reviewsAnsweredManuallyWithAi = 'reviewsAnsweredManuallyWithAi',
    reviewsAnsweredManuallyWithAiOnAggregatedView = 'reviewsAnsweredManuallyWithAiOnAggregatedView',
    reviewsAnsweredManuallyOnAggregatedView = 'reviewsAnsweredManuallyOnAggregatedView',
    reviewsAnsweredWithTemplateOnAggregatedView = 'reviewsAnsweredWithTemplateOnAggregatedView',
    aiGeneratedLegendSeoPosts = 'aiGeneratedLegendSeoPosts',
    aiGeneratedLegendSocialPosts = 'aiGeneratedLegendSocialPosts',
    aiGeneratedHashtags = 'aiGeneratedHashtags',
    answeredMentions = 'answeredMentions',
    answeredComments = 'answeredComments',
    sentMessages = 'sentMessages',
    usedMessageTemplates = 'usedMessageTemplates',
    launchedCampaigns = 'launchedCampaigns',
    launchedCampaignsClients = 'launchedCampaignsClients',
    informationUpdateClicks = 'informationUpdateClicks',
    reviewsReports = 'reviewsReports',
    performanceReports = 'performanceReports',
    aggregatedReviewsReports = 'aggregatedReviewsReports',
    aggregatedPerformanceReports = 'aggregatedPerformanceReports',
    activatedReports = 'activatedReports',
}

export interface RoiSavedTimePublishedPosts {
    [SavedTimeKey.publishedSeoPosts]: UserTimedAction;
    [SavedTimeKey.publishedSocialPosts]: UserTimedAction;
    [SavedTimeKey.publishedStories]: UserTimedAction;
}

export interface RoiSavedTimeDuplication {
    [SavedTimeKey.duplicatedDescriptions]: UserTimedAction;
    [SavedTimeKey.duplicatedAttributes]: UserTimedAction;
    [SavedTimeKey.duplicatedKeywords]: UserTimedAction;
    [SavedTimeKey.duplicatedSeoPosts]: UserTimedAction;
    [SavedTimeKey.duplicatedSocialPosts]: UserTimedAction;
    [SavedTimeKey.duplicatedStories]: UserTimedAction;
    [SavedTimeKey.duplicatedMedia]: UserTimedAction;
    [SavedTimeKey.duplicatedMessageTemplates]: UserTimedAction;
    [SavedTimeKey.duplicatedReviewTemplates]: UserTimedAction;
    [SavedTimeKey.duplicatedClients]: UserTimedAction;
}

export interface RoiSavedTimeAnsweredReviews {
    [SavedTimeKey.reviewsAnsweredAutomatically]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredWithTemplate]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredManually]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredManuallyWithAi]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredManuallyWithAiOnAggregatedView]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredManuallyOnAggregatedView]: UserTimedAction;
    [SavedTimeKey.reviewsAnsweredWithTemplateOnAggregatedView]: UserTimedAction;
}

export interface RoiSavedTimeAiGeneration {
    [SavedTimeKey.aiGeneratedLegendSeoPosts]: UserTimedAction;
    [SavedTimeKey.aiGeneratedLegendSocialPosts]: UserTimedAction;
    [SavedTimeKey.aiGeneratedHashtags]: UserTimedAction;
}

export interface RoiSavedTimeInteractions {
    [SavedTimeKey.answeredMentions]: UserTimedAction;
    [SavedTimeKey.answeredComments]: UserTimedAction;
    [SavedTimeKey.sentMessages]: UserTimedAction;
    [SavedTimeKey.usedMessageTemplates]: UserTimedAction;
}

export interface RoiSavedTimeCampaigns {
    [SavedTimeKey.launchedCampaigns]: UserTimedAction;
    [SavedTimeKey.launchedCampaignsClients]: UserTimedAction;
}

export interface RoiSavedTimeInformationUpdate {
    [SavedTimeKey.informationUpdateClicks]: UserTimedAction;
}

export interface RoiSavedTimeReports {
    [SavedTimeKey.reviewsReports]: UserTimedAction;
    [SavedTimeKey.performanceReports]: UserTimedAction;
    [SavedTimeKey.aggregatedReviewsReports]: UserTimedAction;
    [SavedTimeKey.aggregatedPerformanceReports]: UserTimedAction;
    [SavedTimeKey.activatedReports]: UserTimedAction;
}

export type RoiSavedTime = {
    totalTime: number;
} & RoiSavedTimePublishedPosts &
    RoiSavedTimeDuplication &
    RoiSavedTimeAnsweredReviews &
    RoiSavedTimeAiGeneration &
    RoiSavedTimeInteractions &
    RoiSavedTimeCampaigns &
    RoiSavedTimeInformationUpdate &
    RoiSavedTimeReports;

export type RecordOfNumbersFromKeys<T> = Record<keyof T, number>;

export interface RevenueOption {
    min: number;
    max: number;
}

export type DateFilter = {
    $gte: Date;
    $lte: Date;
};

export type PerformanceScoreCriteria = {
    goal?: number;
    weight: number;
};

export type PerformanceCriteria = {
    score?: number;
    value: number;
    goal: number;
};

export enum PerformanceScoreKey {
    receivedReviewsCount = 'receivedReviewsCount',
    answeredReviewsRatio = 'answeredReviewsRatio',
    gmbRating = 'gmbRating',
    positiveReviewsRatio = 'positiveReviewsRatio',
    averageAnswerTime = 'averageAnswerTime',
    keywordScore = 'keywordScore',
    keywordsInTopTen = 'keywordsInTopTen',
    gmbPostCount = 'gmbPostCount',
    facebookPostCount = 'facebookPostCount',
    instagramPostCount = 'instagramPostCount',
    socialImpressionsCount = 'socialImpressionsCount',
}

export type PerformanceScoreDetails = Record<PerformanceScoreKey, PerformanceCriteria>;

export type PerformanceScoreCriteriaDetails = Record<PerformanceScoreKey, PerformanceScoreCriteria>;

export enum RoiTipCategory {
    SOCIAL_MEDIA = 'SOCIAL_MEDIA',
    GOOGLE = 'GOOGLE',
    BOOSTER = 'BOOSTER',
    KEYWORDS = 'KEYWORDS',
    INFORMATION = 'INFORMATION',
    REVIEWS = 'REVIEWS',
    INTERACTION = 'INTERACTION',
    MOBILE_APP = 'MOBILE_APP',
}

export enum RoiTip {
    // RS
    RS_NO_ACCOUNT_IN_INSPIRATION = 'RS_NO_ACCOUNT_IN_INSPIRATION',
    RS_POST_COUNT_PER_PLATFORM_TOO_LOW = 'RS_POST_COUNT_PER_PLATFORM_TOO_LOW',
    RS_POSTS_OF_THE_SAME_TYPE = 'RS_POSTS_OF_THE_SAME_TYPE',
    RS_STORY_COUNT_TOO_LOW = 'RS_STORY_COUNT_TOO_LOW',
    RS_HASHTAG_COUNT_TOO_LOW_OR_TOO_HIGH = 'RS_HASHTAG_COUNT_TOO_LOW_OR_TOO_HIGH',

    // GOOGLE
    GOOGLE_SAME_SEO_POST_TYPE = 'GOOGLE_SAME_SEO_POST_TYPE',
    GOOGLE_NO_ACTION_BUTTON_IN_POSTS = 'GOOGLE_NO_ACTION_BUTTON_IN_POSTS',
    GOOGLE_AVERAGE_KEYWORDS_SCORE_TOO_LOW = 'GOOGLE_AVERAGE_KEYWORDS_SCORE_TOO_LOW',
    GOOGLE_POST_COUNT_TOO_LOW = 'GOOGLE_POST_COUNT_TOO_LOW',

    // BOOSTER
    BOOSTER_NO_TOTEMS = 'BOOSTER_NO_TOTEMS',
    BOOSTER_NO_WHEEL_OF_FORTUNE = 'BOOSTER_NO_WHEEL_OF_FORTUNE',
    BOOSTER_NO_CAMPAIGNS = 'BOOSTER_NO_CAMPAIGNS',

    // KEYWORDS
    KEYWORDS_NOT_UPDATED_FOR_3_MONTHS = 'KEYWORDS_NOT_UPDATED_FOR_3_MONTHS',
    KEYWORDS_SAME_POPULARITY = 'KEYWORDS_SAME_POPULARITY',

    // INFORMATION
    INFORMATION_MISSING_DATA = 'INFORMATION_MISSING_DATA',
    INFORMATION_EMPTY_DESCRIPTION = 'INFORMATION_EMPTY_DESCRIPTION',
    INFORMATION_DATA_NOT_UPDATED_FOR_3_MONTHS = 'INFORMATION_DATA_NOT_UPDATED_FOR_3_MONTHS',

    // REVIEWS
    REVIEWS_NOT_ENOUGH = 'REVIEWS_NOT_ENOUGH',
    REVIEWS_PRICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_PRICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_QUALITY_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_QUALITY_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_SERVICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_SERVICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_HYGIENE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_HYGIENE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_KITCHEN_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_KITCHEN_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_ATMOSPHERE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS = 'REVIEWS_ATMOSPHERE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS',
    REVIEWS_AVERAGE_RESPONSE_TIME_TOO_HIGH = 'REVIEWS_AVERAGE_RESPONSE_TIME_TOO_HIGH',
    REVIEWS_AUTOMATION_NOT_ACTIVATED = 'REVIEWS_AUTOMATION_NOT_ACTIVATED',

    // INTERACTION
    INTERACTION_UNANSWERED_COMMENT_AND_MENTION_COUNT_TOO_HIGH = 'INTERACTION_UNANSWERED_COMMENT_AND_MENTION_COUNT_TOO_HIGH',
    INTERACTION_UNANSWERED_MESSAGE_COUNT_TOO_HIGH = 'INTERACTION_UNANSWERED_MESSAGE_COUNT_TOO_HIGH',

    // MOBILE APP
    MOBILE_APP_NOT_BEING_USED = 'MOBILE_APP_NOT_BEING_USED',
}

export enum RoiTipAction {
    TRY = 'TRY',
    POST = 'POST',
    MODIFY = 'MODIFY',
    COMPLETE = 'COMPLETE',
    CHECK = 'CHECK',
    ANSWER = 'ANSWER',
    ACTIVATE = 'ACTIVATE',
    DOWNLOAD = 'DOWNLOAD',
    BOOST = 'BOOST',
}

export enum RoiTipPriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export interface RoiTipData {
    id: RoiTip;
    action: RoiTipAction;
    priority: RoiTipPriority;
    category: RoiTipCategory;
    value?: number;
}

export interface AdditionalCustomersAction {
    value: number;
    reference: number;
    customers: number;
    openedDaysRatio?: number;
}

export enum AdditionalCustomerComputationMethod {
    DEFAULT = 'default',
    LOW_ADJUSTMENT_BY_ACTIONS = 'low_value_adjustment__percentage_by_action',
    LOW_ADJUSTMENT_BY_CUSTOMERS = 'low_value_adjustment__percentage_by_customers',
    HIGH_ADJUSTMENT_BY_ACTIONS = 'high_value_adjustment__percentage_by_action',
    HIGH_ADJUSTMENT_BY_CUSTOMERS = 'high_value_adjustment__percentage_by_customers',
}

export enum AdditionalCustomerKey {
    gmbDirectionRequests = 'gmbDirectionRequests',
    gmbBooking = 'gmbBooking',
    gmbPhoneCallClicks = 'gmbPhoneCallClicks',
    gmbFoodMenuClicks = 'gmbFoodMenuClicks',
    gmbWebsiteClicks = 'gmbWebsiteClicks',
    gmbFoodOrders = 'gmbFoodOrders',
    socialDirectionRequests = 'socialDirectionRequests',
    socialPhoneCallClicks = 'socialPhoneCallClicks',
    socialWebsiteClicks = 'socialWebsiteClicks',
    socialEmailContacts = 'socialEmailContacts',
    socialMessageSent = 'socialMessageSent',
    socialImpressions = 'socialImpressions',
    socialShares = 'socialShares',
    socialSaves = 'socialSaves',
}

export interface AdditionalCustomersRange {
    min: number;
    max: number;
    clicks: number;
}

export interface ExactAdditionalCustomers {
    customers: number;
    clicks: number;
}

export type ExactRoiAdditionalCustomers = {
    totalCustomers: number;
    isMissingData: boolean;
    gmbDirectionRequests: ExactAdditionalCustomers;
    gmbBooking: ExactAdditionalCustomers;
    gmbPhoneCallClicks: ExactAdditionalCustomers;
    gmbFoodMenuClicks: ExactAdditionalCustomers;
    gmbWebsiteClicks: ExactAdditionalCustomers;
    gmbFoodOrders: ExactAdditionalCustomers;
    socialDirectionRequests: ExactAdditionalCustomers;
    socialPhoneCallClicks: ExactAdditionalCustomers;
    socialWebsiteClicks: ExactAdditionalCustomers;
    socialEmailContacts: ExactAdditionalCustomers;
    socialMessageSent: ExactAdditionalCustomers;
    socialImpressions: ExactAdditionalCustomers;
    socialShares: ExactAdditionalCustomers;
    socialSaves: ExactAdditionalCustomers;
};

export type RoiDetailedAdditionalCustomers = {
    totalCustomers: number;
    gmbDirectionRequests?: AdditionalCustomersAction | null;
    gmbBooking?: AdditionalCustomersAction | null;
    gmbPhoneCallClicks?: AdditionalCustomersAction | null;
    gmbFoodMenuClicks?: AdditionalCustomersAction | null;
    gmbWebsiteClicks?: AdditionalCustomersAction | null;
    gmbFoodOrders?: AdditionalCustomersAction | null;
    socialDirectionRequests?: AdditionalCustomersAction | null;
    socialPhoneCallClicks?: AdditionalCustomersAction | null;
    socialWebsiteClicks?: AdditionalCustomersAction | null;
    socialEmailContacts?: AdditionalCustomersAction | null;
    socialMessageSent?: AdditionalCustomersAction | null;
    socialImpressions?: AdditionalCustomersAction | null;
    socialShares?: AdditionalCustomersAction | null;
    socialSaves?: AdditionalCustomersAction | null;

    defaultTotalCustomers: number;
    socialUsedComputationMethod?: AdditionalCustomerComputationMethod | null;
    seoUsedComputationMethod?: AdditionalCustomerComputationMethod | null;
    hasCompleteRoiSettings?: boolean;

    isMissingData: boolean;
};
