import { ChangeDetectionStrategy, Component, forwardRef, Injector, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { StarWithTextChipComponent } from ':shared/components/star-with-text-chip/star-with-text-chip.component';

import { ControlValueAccessorConnectorComponent } from '../control-value-accessor-connector/control-value-accessor-connector';
import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-star-with-number',
    templateUrl: './select-star-with-number.component.html',
    styleUrls: ['./select-star-with-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectStarWithNumberComponent),
        },
    ],
    standalone: true,
    imports: [SelectBaseComponent, FormsModule, ReactiveFormsModule, TranslateModule, StarWithTextChipComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStarWithNumberComponent extends ControlValueAccessorConnectorComponent {
    readonly title = input<string>('');
    readonly values = input<(number | null)[]>([]);
    readonly required = input<boolean>(false);
    readonly errorMessage = input<string>('');

    constructor(protected readonly injector: Injector) {
        super(injector);
    }

    displayWith(value: number | null): string {
        return value ? value.toString() : '';
    }
}
