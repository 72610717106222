import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-aggregated-statistics',
    templateUrl: './statistics.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class StatisticsComponent {}
