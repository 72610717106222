import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';

import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { UpsertKind } from ':shared/enums/upsert-kind.enum';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord } from ':shared/interfaces/form-control-record.interface';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export interface OrganizationUpsertForm {
    name: string;
    limit: number;
    users: User[];
}

interface UpsertOrganizationModalInputData {
    upsertKind: UpsertKind;
    organization?: Organization;
    users?: User[];
}

@Component({
    selector: 'app-upsert-organization-modal',
    templateUrl: './upsert-organization-modal.component.html',
    styleUrls: ['./upsert-organization-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        TranslateModule,
        SelectComponent,
        SelectChipListComponent,
        ReactiveFormsModule,
        MatButtonModule,
        InputTextComponent,
    ],
})
export class UpsertOrganizationModalComponent {
    readonly SvgIcon = SvgIcon;
    formGroup: FormGroup<INullableFormControlRecord<OrganizationUpsertForm>>;
    allUsers: User[];
    readonly UpsertKind = UpsertKind;

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertOrganizationModalComponent>,
        private readonly _usersService: UsersService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public data: UpsertOrganizationModalInputData
    ) {
        const formControlRecord: INullableFormControlRecord<OrganizationUpsertForm> = this._getFormControlRecord(data);
        this.formGroup = this._formBuilder.group(formControlRecord);

        this._usersService.index().subscribe((res) => {
            this.allUsers = res;
            const newValues = (this.data.users ?? []).map((user) => this.allUsers.find((u) => u._id === user._id));
            const newValuesFiltered = compact(newValues);
            this.formGroup.get('users')?.setValue(newValuesFiltered);
        });
    }

    save(): void {
        this._dialogRef.close(this.formGroup.getRawValue());
    }

    close(): void {
        this._dialogRef.close();
    }

    userDisplayWith(user: User): string {
        return user.email ?? user.fullname ?? `${user.name} ${user.lastname}`;
    }

    private _getFormControlRecord(data: UpsertOrganizationModalInputData): INullableFormControlRecord<OrganizationUpsertForm> {
        return getFormControlRecordFromDefaultValue(
            {
                name: data.organization?.name ?? '',
                limit: data.organization?.limit ?? 30,
                users: data.users ?? [],
            },
            {
                name: { validators: Validators.required },
                limit: { validators: Validators.required },
            }
        );
    }
}
