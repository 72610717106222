import { Mention } from './mention';
import { Post } from './post';

export enum CommentOrigin {
    COMMENT = 'comment',
    MENTION_IN_COMMENT = 'mention_in_comment',
    MENTION_IN_POST = 'mention_in_post',
}
export class Comment {
    _id: string;
    platformId: string;
    restaurantId: string;
    platformKey: string;
    socialId: string;
    postSocialId: string;
    socialCreatedAt: Date;
    text: string;
    likeCount: string;
    language: string;
    reviewer: {
        socialId: string;
        socialUrl?: string;
        displayName: string;
    };
    replies: CommentReply[] = [];
    updatedAt: Date;
    createdAt: Date;
    post?: Post;
    archived: boolean;
    type: CommentOrigin;
    hasBeenAnswered: boolean;

    public constructor(init?: Partial<Comment & Mention & { isMention: boolean }>) {
        Object.assign(this, init);
        this.socialCreatedAt = new Date(this.socialCreatedAt);
        this.createdAt = new Date(this.createdAt);
        this.updatedAt = new Date(this.updatedAt);
        if (this.replies) {
            this.replies = this.replies.map((c) => new CommentReply(c));
        }
        if (this.post) {
            this.post = new Post(this.post);
        }
        if (!init?.isMention) {
            this.type = CommentOrigin.COMMENT;
        } else {
            this.type = init.mentionType === 'comment' ? CommentOrigin.MENTION_IN_COMMENT : CommentOrigin.MENTION_IN_POST;
            if (this.type === CommentOrigin.MENTION_IN_COMMENT) {
                this.reviewer = {
                    socialId: init.author?.socialId as string,
                    displayName: init.author?.name as string,
                };
            } else {
                this.reviewer = {
                    socialId: init.post?.author?.socialId as string,
                    displayName: init.post?.author?.name as string,
                };
                this.text = this.post?.text as string;
            }
        }
    }

    hasReplies(): boolean {
        return this.replies.length > 0;
    }
}

const isMentionReply = (reply: CommentReply | MentionReply): reply is MentionReply => (reply as MentionReply).author !== undefined;

export class ModerationTarget extends Comment {
    isMention: boolean;
    mentionType?: 'comment' | 'post';
}

export interface MentionReply {
    _id: string;
    socialId: string;
    text: string;
    socialCreatedAt: Date;
    likeCount: string;
    isMention: true;
    author: {
        picture?: string;
        name: string;
    };
}

export class CommentReply {
    _id: string;
    socialId: string;
    text: string;
    socialCreatedAt: Date;
    likeCount: string;
    reviewer: {
        socialId?: string;
        socialUrl?: string;
        displayName: string;
    };

    public constructor(init?: CommentReply | MentionReply) {
        Object.assign(this, init);
        if (init && isMentionReply(init)) {
            this.reviewer = {
                socialUrl: init.author.picture,
                displayName: init.author.name,
            };
        }
    }
}
