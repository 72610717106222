import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { Day } from '@malou-io/package-utils';

import { HoursModalContext } from ':modules/informations/hours-modal/hours-modal.context';
import { ScheduleWithIsClosed } from ':modules/informations/hours-modal/hours-modal.interface';
import { HoursModalService } from ':modules/informations/hours-modal/hours-modal.service';
import { SelectDaysAndHoursComponent } from ':shared/components/select-days-and-hours/select-days-and-hours.component';
import { Period } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-business-hours-form',
    templateUrl: './business-hours-form.component.html',
    styleUrls: ['./business-hours-form.component.scss'],
    standalone: true,
    imports: [MatDividerModule, MatButtonModule, MatTooltipModule, MatIconModule, TranslateModule, SelectDaysAndHoursComponent],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessHoursFormComponent {
    readonly disabled = input.required<boolean>();

    private readonly _hoursModalService = inject(HoursModalService);
    private readonly _hoursModalContext = inject(HoursModalContext);

    readonly SvgIcon = SvgIcon;

    readonly businessHoursSchedules: Signal<ScheduleWithIsClosed[]> = this._hoursModalContext.hoursModalState.businessHours.schedules;

    readonly missingDays = computed(() => this.getAvailableDays([], this.businessHoursSchedules()));

    addSchedule(): void {
        this._hoursModalContext.addScheduleToBusinessHours();
    }

    updateScheduleSelectedDays(index: number, days: Day[]): void {
        this._hoursModalContext.updateBusinessHoursScheduleSelectedDays(index, days);
    }

    updateSchedulePeriods(index: number, periods: Period[]): void {
        this._hoursModalContext.updateBusinessHoursSchedulePeriods(index, periods);
    }

    updateScheduleIsClosed(index: number, isClosed: boolean): void {
        this._hoursModalContext.updateBusinessHoursScheduleIsClosed(index, isClosed);
    }

    getAvailableDays(scheduleDays: Day[], schedules: ScheduleWithIsClosed[]): Day[] {
        return this._hoursModalService.getAvailableDaysForBusinessHours(scheduleDays, schedules);
    }

    deleteSchedule(index: number): void {
        this._hoursModalContext.removeScheduleFromBusinessHours(index);
    }
}
