import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { MALOU_MANAGER_EMAIL_ADDRESS } from ':core/constants';
import { ToastService } from ':core/services/toast.service';
import {
    AuthorizedSimpleAccountManagedPlatformKeys,
    SimpleAccountManagedConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-simple-account-managed-modal/simple-account-managed-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-simple-account-managed-connection-step-0',
    templateUrl: './simple-account-managed-connection-step-0.component.html',
    styleUrls: ['./simple-account-managed-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent, ImagePathResolverPipe, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleAccountManagedConnectionStep0Component extends BaseStepComponent<
    { platformKey: AuthorizedSimpleAccountManagedPlatformKeys },
    SimpleAccountManagedConnectionModalResult
> {
    private readonly _clipboard = inject(Clipboard);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;
    readonly MALOU_MANAGER_EMAIL_ADDRESS = MALOU_MANAGER_EMAIL_ADDRESS;
    readonly SvgIcon = SvgIcon;

    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });

    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));

    platformKey = computed(() => this.sharedData().platformKey);

    imageName = computed(() => {
        const platformKey = this.platformKey();
        return {
            [PlatformKey.OPENTABLE]: 'opentable-add-manager',
            [PlatformKey.UBEREATS]: 'ubereats-add-manager',
        }[platformKey];
    });

    translations = computed(() => {
        const base = 'platforms.connection_new.simple_account_managed';
        const platformKey = this.platformKey();
        return {
            title: `${base}.${platformKey}.step_0.step_name`,
            description1: `${base}.${platformKey}.step_0.description_1`,
            description2: `${base}.${platformKey}.step_0.description_2`,
            primaryButton: `${base}.${platformKey}.step_0.primary_button`,
        };
    });

    shouldDisplayTertiaryButton = computed(() => this.platformKey() === PlatformKey.OPENTABLE);

    onPrimaryClick(): void {
        const url = this._getUrl(this.platformKey());
        if (url) {
            window.open(url, '_blank');
        }
        this.goToStep.emit({ type: 'relative', value: 1 });
    }

    onTertiaryClick(): void {
        const notionLink = PlatformDefinitions.getPlatformDefinition(this.platformKey())?.notionLink;
        if (notionLink) {
            window.open(notionLink, '_blank');
        }
    }

    onCopyButtonClick(): void {
        this._clipboard.copy(this.MALOU_MANAGER_EMAIL_ADDRESS);
        this._toastService.openSuccessToast(this._translateService.instant('common.copied_to_the_clipboard'));
    }

    private _getUrl(platformKey: PlatformKey): string | null {
        switch (platformKey) {
            case PlatformKey.OPENTABLE:
                return 'https://guestcenter.opentable.com/login';
            case PlatformKey.UBEREATS:
                return 'https://merchants.ubereats.com/manager/users';
        }
        return null;
    }
}
