import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ToastService } from ':core/services/toast.service';
import { Restaurant } from ':shared/models';

interface Result {
    restaurantId: string;
    platformKeys: PlatformKey[];
}

@Injectable()
export class PlatformCacheService {
    cachedResults: Record<string, PlatformKey[]> = {};

    constructor(
        private readonly _platformsService: PlatformsService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    getPlatformKeysForRestaurants(restaurants: Restaurant[]): Observable<PlatformKey[]> {
        if (restaurants.length === 0) {
            return of([]);
        }

        const observables: Observable<Result>[] = restaurants.map((restaurant) => {
            if (this.cachedResults[restaurant._id]) {
                return of({
                    restaurantId: restaurant._id,
                    platformKeys: this.cachedResults[restaurant._id],
                });
            }
            return this._platformsService.getPlatformsForRestaurant(restaurant._id).pipe(
                map((res) => ({
                    restaurantId: restaurant._id,
                    platformKeys: res.data.map((e) => e.key) as PlatformKey[],
                })),
                catchError(() => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('aggregated_statistics.filters.fetch_platforms_error')
                    );
                    return of({
                        restaurantId: restaurant._id,
                        platformKeys: [],
                    });
                })
            );
        });

        return forkJoin(observables).pipe(
            map((results: Result[]) => {
                results.forEach((result) => (this.cachedResults[result.restaurantId] = result.platformKeys));
                return uniq(results.flatMap((result) => result.platformKeys));
            })
        );
    }
}
