<div id="restaurant-overview-container">
    <ng-container [ngTemplateOutlet]="nameTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="categoryTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="categoryListTemplate"></ng-container>
    @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
        <ng-container [ngTemplateOutlet]="addressTemplate"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="websiteTemplate"></ng-container>
    @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
        <ng-container [ngTemplateOutlet]="menuUrlTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="orderUrlTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="reservationUrlTemplate"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="socialNetworkUrlsTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="phoneTemplate"></ng-container>
    @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
        <ng-container [ngTemplateOutlet]="openingDateTemplate"></ng-container>
    }
</div>

<ng-template #nameTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="icon-btn malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.HOUSE"></mat-icon>
        <span class="malou-text-12--bold">
            {{ restaurant.name || '--' }}
        </span>
    </div>
</ng-template>

<ng-template #categoryTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light !mr-4 !w-4" [svgIcon]="SvgIcon.TAG"></mat-icon>
        <div class="malou-chip malou-chip--primary" matTooltip="{{ 'information.information.main_category' | translate }}">
            {{ restaurant.category | applySelfPure: 'getCategoryNameForLang' : currentLang }}
        </div>
    </div>
</ng-template>

<ng-template #categoryListTemplate>
    <div class="!relative my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.TAG"></mat-icon>
        <div class="flex flex-wrap">
            @for (cat of filteredCategories; track cat._id) {
                <div
                    class="malou-chip malou-chip--primary !ml-0 mb-2 mr-3 leading-4"
                    matTooltip="{{ 'information.information.secondary_category' | translate }}">
                    {{ (cat | applySelfPure: 'getCategoryNameForLang' : currentLang) ?? '' | shortText: 25 }}
                </div>
            }
        </div>

        @if (showAllCategories) {
            <div class="malou-card !absolute right-[-30px] top-[33px] z-10 max-h-[300px]" id="categoriesCard">
                <div class="malou-card__body overflow-y-auto">
                    @for (cat of remainingCategories; track cat._id) {
                        <div class="malou-chip malou-chip--primary !ml-0 mb-2 mr-1">
                            {{ (cat | applySelfPure: 'getCategoryNameForLang' : currentLang) ?? '' | shortText: 25 }}
                        </div>
                    }
                </div>
            </div>
        }

        @if (hasTooManyCategories) {
            <span
                class="malou-color-primary malou-text-12--semibold mb-2 cursor-pointer whitespace-nowrap"
                id="moreCatBtn"
                (click)="showAll()">
                {{ showAllCategories ? '-' : '+' }} {{ restaurant.categoryList.length - maxCategoriesToShow }}
            </span>
        }
    </div>
</ng-template>

<ng-template #addressTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.LETTER"></mat-icon>
        <span class="malou-text-12--medium truncate" [matTooltip]="formattedAddress">
            {{ formattedAddress }}
        </span>
    </div>
</ng-template>

<ng-template #websiteTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.LAPTOP"></mat-icon>
        <span class="malou-break-word malou-text-12--medium malou-color-primary truncate" [matTooltip]="restaurant.website">
            {{ restaurant.website || '--' }}
        </span>
    </div>
</ng-template>

<ng-template #menuUrlTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.MENU_BOOK"></mat-icon>
        @if (restaurant.menuUrl) {
            <a class="malou-color-primary malou-text-12--medium" target="_blank" [href]="restaurant.menuUrl | externalHref">
                {{ 'information.information.see_menu' | translate }}
            </a>
        } @else {
            --
        }
    </div>
</ng-template>

<ng-template #orderUrlTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.FOLDER_CHECK"></mat-icon>
        @if (restaurant.orderUrl) {
            <a class="malou-color-primary malou-text-12--medium" target="_blank" [href]="restaurant.orderUrl | externalHref">
                {{ 'information.information.order_link' | translate }}
            </a>
        } @else {
            --
        }
    </div>
</ng-template>

<ng-template #reservationUrlTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.HANDSHAKE"></mat-icon>
        @if (restaurant.reservationUrl) {
            <a class="malou-color-primary malou-text-12--medium" target="_blank" [href]="restaurant.reservationUrl | externalHref">
                {{ 'information.information.reservation_link' | translate }}
            </a>
        } @else {
            --
        }
    </div>
</ng-template>

<ng-template #socialNetworkUrlsTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.SOCIAL_NETWORKS"></mat-icon>
        @if (restaurant.socialNetworkUrls?.length) {
            @for (socialNetwork of restaurant.socialNetworkUrls; track socialNetwork.key) {
                <a class="malou-color-primary malou-text-12--medium" target="_blank" [href]="socialNetwork.url | externalHref">
                    <app-platform-logo imgClasses="w-4 h-4 mr-1" [logo]="socialNetwork.key"></app-platform-logo>
                </a>
            }
        } @else {
            --
        }
    </div>
</ng-template>

<ng-template #phoneTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.PHONE"></mat-icon>
        <span class="malou-text-12--medium">
            {{ (restaurant | applySelfPure: 'getDisplayPhone') || '--' }}
        </span>
    </div>
</ng-template>

<ng-template #openingDateTemplate>
    <div class="my-3 flex items-center justify-start">
        <mat-icon class="malou-color-purple-light mr-4 !w-4" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
        <span class="malou-text-12--medium">
            {{ (restaurant.openingDate ? 'information.information.opening_date' : '--') | translate }}
            {{ restaurant.openingDate ? (restaurant.openingDate | formatDate: 'shortDate') : '--' }}
        </span>
    </div>
</ng-template>
