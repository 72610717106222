import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    CountOrganizationsDto,
    CreateOrganizationRequestBodyDto,
    CreateOrganizationResponseBodyDto,
    DeleteOrganizationRequestBodyDto,
    DeleteOrganizationResponseBodyDto,
    LinkVerifiedEmailToOrganizationBodyDto,
    OrganizationDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { User } from ':modules/user/user';
import { ApiResult, Restaurant } from ':shared/models';
import { Organization } from ':shared/models/organization';

export interface PingedOrganization {
    organization: Organization;
    users: Partial<User>[];
    restaurants: Partial<Restaurant>[];
    verifiedEmailsForCampaigns: string[];
}

@Injectable({
    providedIn: 'root',
})
export class OrganizationsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/organizations`;

    constructor(private readonly _http: HttpClient) {}

    update(id: string, data: Partial<Organization> | object): Observable<ApiResult<Organization>> {
        return this._http.put<ApiResult<Organization>>(`${this.API_BASE_URL}/${id}`, data, { withCredentials: true });
    }

    delete(body: DeleteOrganizationRequestBodyDto): Observable<ApiResultV2<DeleteOrganizationResponseBodyDto>> {
        return this._http.post<ApiResultV2<DeleteOrganizationResponseBodyDto>>(`${this.API_BASE_URL}/delete-organization`, body, {
            withCredentials: true,
        });
    }

    index(): Observable<ApiResult<Organization[]>> {
        return this._http.get<ApiResult<Organization[]>>(this.API_BASE_URL);
    }

    create(data: CreateOrganizationRequestBodyDto): Observable<ApiResultV2<CreateOrganizationResponseBodyDto>> {
        return this._http.post<ApiResultV2<CreateOrganizationResponseBodyDto>>(`${this.API_BASE_URL}/create-organization`, data, {
            withCredentials: true,
        });
    }

    ping(id: string): Observable<ApiResult<PingedOrganization>> {
        return this._http.get<ApiResult<PingedOrganization>>(`${this.API_BASE_URL}/ping/${id}`, { withCredentials: true });
    }

    getRestaurantCountInOrganizationsForUser(): Observable<ApiResult<number>> {
        return this._http.get<ApiResult<number>>(`${this.API_BASE_URL}/restaurants/count`);
    }

    getUserCountInOrganizationsForUser(): Observable<ApiResult<number>> {
        return this._http.get<ApiResult<number>>(`${this.API_BASE_URL}/users/count`);
    }

    getOrganizationRestaurantsCount(organizationId: string): Observable<CountOrganizationsDto> {
        return this._http
            .get<ApiResultV2<CountOrganizationsDto>>(`${this.API_BASE_URL}/${organizationId}/restaurants/count`)
            .pipe(map((res) => res.data));
    }

    linkVerifiedEmailToOrganization(
        organizationId: string,
        body: LinkVerifiedEmailToOrganizationBodyDto
    ): Observable<ApiResultV2<OrganizationDto>> {
        return this._http.post<ApiResultV2<OrganizationDto>>(`${this.API_BASE_URL}/link-verified-email/${organizationId}`, body);
    }
}
