<div class="h-[30px]" [matTooltip]="aiPromptButtonTooltip()">
    <button
        class="malou-btn-flat !pr-0"
        data-testid="answer-review-edit-ai-btn"
        mat-button
        [disabled]="disabled()"
        [matMenuTriggerFor]="aiActionsMenu">
        <mat-icon class="mb-1 mr-1 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
        <span class="text-malou-color-chart-purple--accent">{{ 'reviews.reply_modal.edit_with_ai' | translate }}</span>
    </button>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #aiActionsMenu="matMenu">
    <button
        class="flex gap-x-3"
        id="tracking_answer_review_modal_edit_answer_with_ai_button"
        mat-menu-item
        (click)="onAnswerReviewWithAiClick()">
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'common.retry' | translate }}</span>
    </button>

    @if (displayAiOptimizeButton()) {
        <button
            class="flex gap-x-3"
            id="tracking_answer_review_modal_optimize_answer_with_ai_button"
            mat-menu-item
            (click)="onAiOptimizeClick()">
            <span class="malou-text-14--regular text-malou-color-text-2">{{ 'reviews.reply_modal.optimize' | translate }}</span>
        </button>
    }

    <button class="flex min-w-[170px] gap-x-3" data-testid="answer-review-translate-btn" mat-menu-item [matMenuTriggerFor]="langsMenu">
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'common.translate' | translate }}</span>
    </button>
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #langsMenu="matMenu">
    <app-input-text
        class="mx-2 block h-[50px] w-[180px] py-2"
        data-testid="answer-review-language-input"
        [showConfirmIcon]="true"
        [placeholder]="'common.language' | translate"
        [min]="3"
        [confirmationIconId]="'tracking_answer_review_modal_translate_answer_to_custom_language_with_ai_button'"
        (confirmControlValue)="onTranslateTextWithAiConfirm($event)"
        (click)="$event.stopPropagation()">
    </app-input-text>

    @for (lang of aiTranslateLangs(); track lang) {
        <button
            mat-menu-item
            [attr.data-testid]="'answer-review-translate-to-' + lang + '-button'"
            [id]="'tracking_answer_review_modal_translate_answer_to_' + lang + '_language_with_ai_button'"
            (click)="onLangChange(lang)">
            <span class="malou-text-14--regular">{{ 'common.langs.' + lang | translate }}</span>
        </button>
    }
</mat-menu>
