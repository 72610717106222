import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, of } from 'rxjs';

import { RestaurantAiSettingsDto, RestaurantAiSettingsDuplicationDataDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';

@Injectable({
    providedIn: 'root',
})
export class RestaurantAiSettingsService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/restaurant-ai-settings`;

    constructor(private readonly _http: HttpClient) {}

    getRestaurantAiSettings(restaurantId: string): Observable<RestaurantAiSettings> {
        return this._http
            .get<ApiResultV2<RestaurantAiSettingsDto>>(`${this.API_BASE_URL}/${restaurantId}`)
            .pipe(map((res) => RestaurantAiSettings.fromRestaurantAiSettingsDto(res.data)));
    }

    getRestaurantAiSettingsList(restaurantIds: string[]): Observable<RestaurantAiSettings[]> {
        if (restaurantIds.length === 0) {
            return of([]);
        }

        return this._http
            .post<ApiResultV2<RestaurantAiSettingsDto[]>>(`${this.API_BASE_URL}/list`, { restaurantIds })
            .pipe(map((res) => (res.data ? res.data.map((dto) => RestaurantAiSettings.fromRestaurantAiSettingsDto(dto)) : [])));
    }

    updateRestaurantAiSettings({
        restaurantAiSettings,
        restaurantId,
        restaurantAiSettingsDispatch,
    }: {
        restaurantAiSettings: RestaurantAiSettings;
        restaurantId: string;
        restaurantAiSettingsDispatch: RestaurantAiSettingsDispatch;
    }) {
        switch (restaurantAiSettingsDispatch) {
            case 'general':
                return firstValueFrom(
                    this._http.post<void>(
                        `${this.API_BASE_URL}/${restaurantId}/update-general-settings`,
                        restaurantAiSettings.toGeneralSettingsUpdateDto()
                    )
                );
            case 'post':
                return firstValueFrom(
                    this._http.post<void>(
                        `${this.API_BASE_URL}/${restaurantId}/update-post-settings`,
                        restaurantAiSettings.toPostSettingsUpdateDto()
                    )
                );
            case 'review':
                return firstValueFrom(
                    this._http.post<void>(
                        `${this.API_BASE_URL}/${restaurantId}/update-review-settings`,
                        restaurantAiSettings.toReviewSettingsUpdateDto()
                    )
                );
        }
    }

    duplicateRestaurantAiSettings(
        fromRestaurantId: string,
        duplicationData: RestaurantAiSettingsDuplicationDataDto[]
    ): Observable<RestaurantAiSettings[]> {
        return this._http
            .post<ApiResultV2<RestaurantAiSettingsDto[]>>(`${this.API_BASE_URL}/duplicate/${fromRestaurantId}`, { duplicationData })
            .pipe(map((res) => res.data.map(RestaurantAiSettings.fromRestaurantAiSettingsDto)));
    }
}
