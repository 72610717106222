<form class="flex flex-col gap-y-4" [formGroup]="aiReviewSettingsForm()">
    <div>
        <div class="malou-text-10--regular malou-color-text-2 mb-2">
            {{ 'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.title' | translate }}
        </div>

        @if (selectedKeywords().length) {
            <div class="grid grid-cols-2 gap-2">
                @for (keyword of selectedKeywords(); track keyword.keywordId) {
                    <div class="flex items-center gap-x-2">
                        <mat-checkbox
                            color="primary"
                            [checked]="isKeywordChecked(keyword)"
                            (change)="onCheckKeywordChange(keyword, $event.checked)"></mat-checkbox>
                        <span class="malou-text-10--regular malou-color-text-1">
                            {{ keyword.text }}
                        </span>
                    </div>
                }
            </div>
        } @else {
            <div>
                <span class="malou-text-10--regular malou-color-text-2 italic">{{
                    'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.empty' | translate
                }}</span>
                <span class="malou-text-10--semibold malou-color-text-primary cursor-pointer" (click)="onKeywordsCtaClick()">{{
                    'restaurant_ai_settings.modals.upsert.tabs.advanced.keywords.empty_cta' | translate
                }}</span>
            </div>
        }
    </div>

    <!-- Forbidden words -->
    <app-select-chip-list
        formControlName="forbiddenWords"
        [title]="'restaurant_ai_settings.modals.upsert.tabs.advanced.forbidden_words.title' | translate"
        [multiSelectionElementWrap]="true"
        [displayWith]="displayForbiddenWordWith"
        [buildValueFromText]="buildForbiddenWordFromText"
        [maxSelectableValues]="MAX_FORBIDDEN_WORDS"
        [placeholder]="'restaurant_ai_settings.modals.upsert.tabs.advanced.forbidden_words.placeholder' | translate"
        [hideArrow]="true">
        <ng-template let-value="value" #optionTemplate>
            <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                <span>
                    {{ displayForbiddenWordWith | applyPure: value }}
                </span>
            </div>
        </ng-template>
    </app-select-chip-list>
</form>
