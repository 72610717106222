import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { cloneDeep } from 'lodash';
import { catchError, EMPTY, Observable, of, tap } from 'rxjs';

import { MapstrCtaButtonType, PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { WHITELISTED_PAGE_IDS } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { UpsertSocialPostState } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.interface';
import { UpsertSocialPostService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.service';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { FbLocation, Hashtag, Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostContext {
    private readonly _credentialService = inject(CredentialsService);
    private readonly _upsertSocialPostService = inject(UpsertSocialPostService);

    readonly upsertSocialPostState = signalState<UpsertSocialPostState>(this._getInitialState(null));
    readonly initialPost: WritableSignal<UpsertSocialPost | null> = signal(null);

    init(postId?: string): void {
        this._setIsLoadingPost(true);
        (postId ? this._upsertSocialPostService.getPost(postId) : of(null)).subscribe((post) => {
            this.initialPost.set(post ?? null);
            patchState(this.upsertSocialPostState, this._getInitialState(post));
            this._initConnectedSocialPlatformsAndLocation({ isNewSocialPost: !post });
            this._setIsLoadingPost(false);
        });
    }

    autosave(): void {
        const post = this.upsertSocialPostState.post();
        this._setIsAutoSaving(true);
        this._updatePost(post)
            .pipe(catchError((error) => this._handleError(error)))
            .subscribe(() => {
                this._setIsAutoSaving(false);
            });
    }

    savePost(post: IUpsertSocialPost): Observable<UpsertSocialPost> {
        this._setIsSubmitting(true);
        return this._updatePost(post).pipe(tap(() => this._setIsSubmitting(false)));
    }

    deletePost(): Observable<null> {
        const postId = this.upsertSocialPostState.post.id();
        this._setIsSubmitting(true);
        return this._upsertSocialPostService.deletePost(postId).pipe(tap(() => this._setIsSubmitting(false)));
    }

    selectPlatforms(platformKeys: PlatformKey[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, platformKeys } }));
    }

    updateTitle(title: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, title } }));
    }

    updateCaption(caption: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, text: caption } }));
    }

    updateLocation(location: FbLocation | null): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, location } }));
    }

    updateSelectedHashtags(selectedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, selected: selectedHashtags } },
        }));
    }

    updateSuggestedHashtags(suggestedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, suggested: suggestedHashtags } },
        }));
    }

    updateCtaActionType(actionType: MapstrCtaButtonType | null): void {
        if (!actionType) {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: undefined },
            }));
        } else {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { url: '' }), actionType } },
            }));
        }
    }

    updateCtaUrl(url: string): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { actionType: MapstrCtaButtonType.SEE }), url } },
        }));
    }

    private _getInitialState(initialPost: UpsertSocialPost | null): UpsertSocialPostState {
        return cloneDeep({
            post: initialPost ? initialPost.toInterface() : UpsertSocialPost.create().toInterface(),
            isAutoSaving: false,
            isSubmitting: false,
            isLoadingLocation: false,
            duplicateToPlatforms: [],
            connectedSocialPlatforms: [],
        });
    }

    private _initConnectedSocialPlatformsAndLocation(options: { isNewSocialPost: boolean }): void {
        this._upsertSocialPostService.getConnectedSocialPlatforms().subscribe((platforms) => {
            const connectedPlatformKeys = platforms.map((platform) => platform.key);
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                connectedSocialPlatforms: platforms,
            }));
            if (options.isNewSocialPost) {
                this.selectPlatforms(connectedPlatformKeys);

                // If Facebook is connected, we initialize the location with the one specified in the Facebook page
                const fbPlatform = platforms.find((platform) => platform.key === PlatformKey.FACEBOOK);
                if (fbPlatform) {
                    this._initLocation(fbPlatform);
                }
            }
        });
    }

    private _initLocation(fbPlatform: Platform): void {
        this._setIsLoadingLocation(true);

        // Special case, we whitelist the "Very French Beans" page because it does not contain location
        this._credentialService
            .pagesSearch(fbPlatform.name, {
                onlyWithLocation: true,
                whitelistedPageIds: WHITELISTED_PAGE_IDS,
            })
            .pipe(
                catchError((error) => {
                    this._setIsLoadingLocation(false);
                    return this._handleError(error);
                })
            )
            .subscribe((res) => {
                const locations = res.data;
                const currentLocation = locations.find((l) => l.id === fbPlatform.socialId) || null;

                if (currentLocation) {
                    this.updateLocation(currentLocation);
                }
                this._setIsLoadingLocation(false);
            });
    }

    private _updatePost(post: IUpsertSocialPost): Observable<UpsertSocialPost> {
        return this._upsertSocialPostService.updatePost(post).pipe(catchError((error) => this._handleError(error)));
    }

    private _setIsAutoSaving(isAutoSaving: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isAutoSaving }));
    }

    private _setIsSubmitting(isSubmitting: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isSubmitting }));
    }

    private _setIsLoadingPost(isLoadingPost: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingPost }));
    }

    private _setIsLoadingLocation(isLoadingLocation: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingLocation }));
    }

    private _handleError(_error: unknown): Observable<never> {
        // TODO handle error with toast and error mapping
        return EMPTY;
    }
}
