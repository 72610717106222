import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-ai-modify-reply',
    standalone: true,
    imports: [InputTextComponent, MatButtonModule, MatIconModule, MatTooltipModule, MatMenuModule, TranslateModule],
    templateUrl: './ai-modify-reply.component.html',
    styleUrls: ['./ai-modify-reply.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiModifyReplyComponent {
    readonly aiTranslateLangs = input<(ApplicationLanguage | string)[]>(Object.values(ApplicationLanguage));
    readonly aiPromptButtonTooltip = input<string>('');
    readonly disabled = input<boolean>(true);
    readonly displayAiOptimizeButton = input<boolean>(false);

    readonly answerReviewWithAi = output<void>();
    readonly aiOptimize = output<void>();
    readonly translateTextWithAi = output<string>();

    readonly SvgIcon = SvgIcon;

    onAnswerReviewWithAiClick(): void {
        this.answerReviewWithAi.emit();
    }

    onAiOptimizeClick(): void {
        this.aiOptimize.emit();
    }

    onTranslateTextWithAiConfirm($event: string): void {
        this.translateTextWithAi.emit($event);
    }

    onLangChange(lang: ApplicationLanguage | string): void {
        this.onTranslateTextWithAiConfirm(lang);
    }
}
