import { BaseEntity, EntityConstructor, NotificationChannel, NotificationType } from '@malou-io/package-utils';

export type NotificationProps = EntityConstructor<Notification>;

export class Notification extends BaseEntity {
    id: string;
    userId: string;
    restaurantId?: string;
    data: Record<string, any> | any;
    readAt?: Date | null;
    type: NotificationType;
    error?: string | null;
    channel: NotificationChannel;
    completedAt?: Date | null;

    constructor(props: NotificationProps) {
        super(props);
        this.userId = props.userId;
        this.restaurantId = props.restaurantId;
        this.data = props.data;
        this.readAt = props.readAt;
        this.type = props.type;
        this.error = props.error;
        this.channel = props.channel;
        this.completedAt = props.completedAt;
    }

    copyWith(props: Partial<NotificationProps>): Notification {
        return new Notification({ ...this, ...props });
    }

    isRead(): boolean {
        return !!this.readAt;
    }
}
