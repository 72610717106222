<div class="malou-dialog !flex-row">
    @if (!isSmallScreen()) {
        <app-platforms-connection-stepper
            [steps]="STEP_NAMES"
            [currentStep]="selectedStep()"
            [platformKeyIcon]="undefined"
            [title]="'restaurants_list.connect_restaurant.add_a_restaurant' | translate"></app-platforms-connection-stepper>
    }
    <div class="w-full">
        <div class="malou-dialog__header items-baseline">
            <div class="flex flex-col">
                <span class="malou-text-18--bold">
                    {{ getStepTitle() }}
                </span>
            </div>
            @if (!isAboveEmptyScreen()) {
                <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            }
        </div>
        <div class="malou-dialog__body !overflow-hidden md:max-h-[unset]" id="connectRestaurantModalBody">
            <mat-stepper
                class="h-full overflow-y-auto"
                labelPosition="bottom"
                [@.disabled]="true"
                [ngClass]="{
                    '!h-[70vh]': selectedStep() === Step.ChooseRestaurantType,
                    '!h-[58vh]': Step.ChooseRestaurantType !== selectedStep(),
                }"
                [linear]="true"
                [selectedIndex]="selectedStep()"
                #stepper>
                <mat-step>
                    @if (isLoading$ | async) {
                        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
                    } @else {
                        <div class="flex h-full flex-1 flex-col justify-between">
                            <div class="md:mt-5 md:flex md:flex-col">
                                <div class="mt-6 flex gap-8 md:flex-col">
                                    @for (option of VENUE_TYPE_OPTIONS; track $index) {
                                        <div
                                            class="flex h-20 flex-1 cursor-pointer flex-col items-center justify-center rounded-xl border-[1px] border-solid border-malou-color-border-primary md:!h-20 md:!flex-none"
                                            [attr.data-testid]="'connect-restaurant-modal-' + $index + '-type-option'"
                                            [ngClass]="{ 'bg-malou-color-background-light': selectedVenueType() === option.value }"
                                            (click)="selectRestaurantType(option.value)">
                                            <p class="malou-text-12--semibold text-malou-color-text-1">{{ option.name }}</p>
                                            <span class="malou-text-12 italic text-malou-color-text-2">{{ option.description }}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <p
                                class="malou-text-12--regular my-10 mt-6 text-center text-malou-color-text-2 md:my-auto md:mt-9"
                                [innerHTML]="venueTypeDetails()?.detailsHTML"></p>
                            <div class="mb-4 md:mt-auto">
                                <div class="flex justify-center md:w-full">
                                    <button
                                        class="!mb-2 !h-12.5 !w-full !rounded-xl !px-4 !py-2 md:w-full min-md:w-[300px]"
                                        data-testid="connect-restaurant-modal-btn"
                                        mat-button
                                        [ngClass]="{
                                            'gmb-button': venueTypeDetails()?.associatedPlatform === PlatformKey.GMB,
                                            'facebook-button': venueTypeDetails()?.associatedPlatform === PlatformKey.FACEBOOK,
                                        }"
                                        (click)="openConnectionOrGoToNextStep({})">
                                        <div class="flex items-center justify-center gap-3">
                                            @if (venueTypeDetails(); as venueTypeDetails) {
                                                <app-platform-logo
                                                    imgClasses="h-8 w-8"
                                                    [logo]="venueTypeDetails.associatedPlatform"></app-platform-logo>
                                            }
                                            <span class="malou-text-12--semibold">{{ venueTypeDetails()?.connexionText }}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </mat-step>

                <mat-step>
                    <div class="flex flex-1 flex-col">
                        <app-choose-organization-modal
                            class="h-full"
                            [organizations]="organizations()"
                            [defaultOrganization]="selectedOrganization()"
                            (setOrganization)="setOrganization($event)">
                        </app-choose-organization-modal>
                    </div>
                </mat-step>

                <mat-step>
                    @if (isLoading$ | async) {
                        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
                    } @else {
                        @if (credentials(); as credentials) {
                            <div class="flex h-full flex-1 flex-col">
                                <app-create-restaurant-from-platform-modal
                                    class="flex h-full flex-1 flex-col"
                                    [selectedAuth]="selectedAuthId()"
                                    [currentPlatformKey]="currentPlatformKey()"
                                    [credentials]="credentials"
                                    [selectedStep]="selectedStep()"
                                    (close)="cancel()"
                                    (next)="openConnectionOrGoToNextStep($event)"
                                    (setAddressGmbWarningMessage)="setAddressGmbWarningMessage($event)"
                                    (validationChange)="onCreateRestaurantFromPlatformValidation($event)"
                                    (editRestaurantConnection)="onEditRestaurantConnection()"
                                    #appCreateRestaurantPlatformModal>
                                </app-create-restaurant-from-platform-modal>
                            </div>
                        }
                    }
                </mat-step>
            </mat-stepper>
        </div>
        @if (stepper.selectedIndex > 0) {
            <div class="malou-dialog__footer flex-wrap !pb-0">
                @if (addressGmbWarningMessage(); as addressGmbWarningMessage) {
                    <div class="mr-auto mt-2 flex gap-2">
                        <div class="malou-status--waiting malou-text-10--regular">!</div>
                        <div class="malou-text-10--regular flex-1 text-malou-color-state-warn">
                            {{ addressGmbWarningMessage }}
                        </div>
                    </div>
                }
                <button class="malou-btn-raised--secondary !h-11 md:!flex-1" mat-raised-button (click)="goPreviousStep()">
                    {{ 'common.back' | translate }}
                </button>
                @if (organizations()) {
                    <button
                        class="malou-btn-raised--primary !h-11 md:!flex-1"
                        data-testid="connect-restaurant-modal-next-btn"
                        mat-raised-button
                        [disabled]="shouldDisablePrimaryButton()"
                        (click)="createRestaurant(selectedStep())">
                        {{ getStepButtonLabel | applyPure: selectedStep() }}
                    </button>
                }
            </div>
        }
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="m-8 flex justify-center">
        <app-malou-spinner size="medium"></app-malou-spinner></div
></ng-template>
