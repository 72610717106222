<div class="flex h-full flex-col gap-4 overflow-y-auto px-7.5 py-4">
    <div class="mb-2 flex items-center justify-between">
        <h1 class="malou-text-18--bold malou-color-text-1">{{ 'automations.ai.title' | translate }}</h1>
        <button class="malou-btn-icon--secondary !h-12.5 !w-12.5" mat-icon-button (click)="openDuplicateModal()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        </button>
    </div>

    @let restaurantAiSettings = aiSettingsContext.restaurantAiSettings();

    @if (restaurantAiSettings) {
        <app-ai-general-settings></app-ai-general-settings>
        <app-ai-review-settings></app-ai-review-settings>
        <app-ai-post-settings></app-ai-post-settings>
    } @else {
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
    }
</div>
