<app-modal-structure
    [title]="'platforms.connection_new.zenchef.step_1.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="zenchefRestaurantIdControl.invalid"
    [secondaryButtonText]="'common.previous' | translate"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isUpdatingPlatform()"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col gap-y-4">
        <app-input-text
            [testId]="'zenchef-connection-restaurant-id-input'"
            [formControl]="zenchefRestaurantIdControl"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.zenchef.step_1.restaurant_id' | translate"
            [placeholder]="'349768'"
            [autocapitalize]="'none'"
            [disabled]="isUpdatingPlatform()">
        </app-input-text>
    </div>
</app-modal-structure>
