<a
    class="group flex cursor-pointer items-center justify-between rounded-md px-4 py-2 hover:bg-malou-color-background-dark"
    routerLinkActive
    [routerLink]="options().routerLink"
    [ngClass]="{
        'ml-[30px]': options().shouldHideSvgIconOnOpenedSidenav && isSidenavOpened(),
        '!justify-center !px-0': !isSidenavOpened(),
        'bg-malou-color-background-dark': isActiveComputed(),
    }"
    [routerLinkActiveOptions]="ROUTER_LINK_ACTIVE_OPTIONS"
    (click)="onClick()">
    <ng-container [ngTemplateOutlet]="leftPartTemplate"></ng-container>

    @if (isSidenavOpened()) {
        <ng-container [ngTemplateOutlet]="rightPartTemplate"></ng-container>
    }
</a>

<ng-template #leftPartTemplate>
    <div class="flex items-center gap-x-3" [ngClass]="{ 'justify-center': !isSidenavOpened() }">
        <ng-container [ngTemplateOutlet]="leftPartIconTemplate"></ng-container>

        @if (isSidenavOpened()) {
            <ng-container [ngTemplateOutlet]="leftPartTextTemplate"></ng-container>
            @if (options().secondarySvgIcon) {
                <ng-container [ngTemplateOutlet]="leftPartSecondaryIconTemplate"></ng-container>
            }
        }
    </div>
</ng-template>

<ng-template #leftPartIconTemplate>
    @if (shouldShowIcon()) {
        <div class="flex h-4 w-4 items-center justify-center">
            <mat-icon
                mat-list-icon
                [class]="svgIconClass()"
                [svgIcon]="options().svgIcon!"
                [ngClass]="{
                    'malou-color-text-1': isActiveComputed() && isSidenavOpened(),
                    'malou-color-primary': isActiveComputed() && !isSidenavOpened(),
                    'malou-color-text-2': !isActiveComputed(),
                }"></mat-icon>
        </div>
    }
</ng-template>

<ng-template #leftPartTextTemplate>
    <div
        class="truncate group-hover:font-semibold"
        [ngClass]="{
            'malou-color-text-1 malou-text-12--semibold': isActiveComputed(),
            'malou-color-text-2 malou-text-12--regular': !isActiveComputed(),
        }">
        {{ options().text }}
    </div>
</ng-template>

<ng-template #leftPartSecondaryIconTemplate>
    <div class="leading-none">
        <mat-icon class="malou-color-text-1 !h-4 !w-4" [svgIcon]="options().secondarySvgIcon!"></mat-icon>
    </div>
</ng-template>

<ng-template #rightPartTemplate>
    <div class="leading-none">
        @if (this.options().rightIconOnHover) {
            <mat-icon
                class="!hidden !h-3 !w-3 text-malou-color-primary group-hover:!block"
                [svgIcon]="this.options().rightIconOnHover!"></mat-icon>
        }
        @if (showNotificationCount() && (options().notificationCount ?? 0 > 0)) {
            <div
                class="malou-color-background-dark malou-color-text-2 malou-text-10--medium rounded-md px-2 py-1"
                [class.group-hover:hidden]="this.options().rightIconOnHover">
                {{ options().notificationCount ?? 0 | threeDigitNumber }}
            </div>
        }
    </div>
</ng-template>
