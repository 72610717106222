import { inject, Injectable } from '@angular/core';

import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import {
    ConnectRestaurantModalComponent,
    ConnectRestaurantModalProps,
    LastStep,
} from './connect-restaurant-modal/connect-restaurant-modal.component';
import { AfterCreateRestaurantModalComponent } from './create-restaurant-from-platform-modal/after-create-restaurant-modal/after-create-restaurant-modal.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';

@Injectable({ providedIn: 'root' })
export class RestaurantsModalsService {
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _customDialogService = inject(CustomDialogService);
    openConnectRestaurantModal(data: ConnectRestaurantModalProps = {}): void {
        this._customDialogService
            .open(ConnectRestaurantModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: '85vh',
                width: '800px',
                data,
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res.lastStep) {
                        this._openAfterCreateRestaurantModal(res.lastStep);
                        this._roiNotificationsContext.emitReload();
                    }
                },
            });
    }

    openAddNewRestaurantModal(): void {
        this._customDialogService
            .open(WelcomeModalComponent, {
                height: '480px',
                width: '850px',
            })
            .afterClosed()
            .subscribe((res) => {
                if (!res.preventOpenRestaurantModal) {
                    this.openConnectRestaurantModal();
                }
            });
    }

    private _openAfterCreateRestaurantModal(props: Partial<LastStep>): void {
        this._customDialogService
            .open(AfterCreateRestaurantModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: 'fit-content',
                width: '600px',
                data: {
                    picture: props.picture,
                    primaryButtonLabel: props.primaryButtonLabel,
                    secondaryButtonLabel: props.secondaryButtonLabel,
                    title: props.title,
                    details: props.details,
                    onClickSecondaryButton: props.onClickSecondaryButton,
                    onClickPrimaryButton: props.onClickPrimaryButton,
                    isErrorMessage: props.isErrorMessage,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.shouldOpenWelcomeModal) {
                    this.openAddNewRestaurantModal();
                }
            });
    }
}
