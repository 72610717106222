import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';

@Injectable()
export class UserResolver {
    constructor(private readonly _users: UsersService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<User> {
        return this._users.getUser(route.params.user_id);
    }
}
