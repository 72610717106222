import { Routes } from '@angular/router';

import { GalleryComponent } from './gallery.component';

export const GALLERY_ROUTES: Routes = [
    {
        path: '',
        component: GalleryComponent,
    },
];
