@if (!(screenSizeService.isPhoneScreen$ | async)) {
    <div class="mx-8 py-4">
        <ng-template
            [ngTemplateOutlet]="
                !hasBoosterPackActivated() ||
                (hasBoosterPackActivated() && !activeRestaurantWheelOfFortune() && !activeAggregatedWheelOfFortune())
                    ? createWheelOfFortuneTemplate
                    : wheelOfFortuneDataCardTemplate
            "
            [ngTemplateOutletContext]="{
                activeWheelOfFortune: activeRestaurantWheelOfFortune() || activeAggregatedWheelOfFortune(),
                isAggregatedWheelOfFortune: !activeRestaurantWheelOfFortune(),
            }"></ng-template>
    </div>
}

<ng-template
    let-activeWheelOfFortune="activeWheelOfFortune"
    let-isAggregatedWheelOfFortune="isAggregatedWheelOfFortune"
    #wheelOfFortuneDataCardTemplate>
    <app-wheel-of-fortune-data-card
        [isAggregatedView]="false"
        [isAggregatedWheelOfFortune]="isAggregatedWheelOfFortune"
        [wheelOfFortune]="activeWheelOfFortune"
        (reloadWheelsOfFortune)="onReload()"></app-wheel-of-fortune-data-card>
</ng-template>

<ng-template let-activeWheelOfFortune="activeWheelOfFortune" #createWheelOfFortuneTemplate>
    <app-create-wheel-of-fortune-card
        [restaurantsIdsWithoutWheels]="restaurantsIdsWithoutWheels"
        [activeAggregatedWheelOfFortune]="activeWheelOfFortune"
        [isAggregatedView]="false"
        [hasBoosterPackActivated]="hasBoosterPackActivated"
        (reloadWheelsOfFortune)="onReload()"></app-create-wheel-of-fortune-card>
</ng-template>
