import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ratingEval, ratingText } from ':core/constants';

@Injectable({
    providedIn: 'root',
})
export class NiceDisplayTemplateService {
    constructor(private readonly _translate: TranslateService) {}

    associatedRatingIcon(rating: number[]): string {
        switch (this.getRatingEvaluation(rating)) {
            case ratingEval.NEUTRAL_RATING:
                return 'sentiment_satisfied';
            case ratingEval.GOOD_RATING:
                return 'sentiment_very_satisfied';
            case ratingEval.BAD_RATING:
                return 'sentiment_very_dissatisfied';
            default:
                return '';
        }
    }

    getRatingEvaluation(rating: number[]): string {
        if (!rating?.length || rating[0] === 0) {
            return ratingEval.NO_RATING;
        }
        if (rating.length === 1 && rating[0] === 3) {
            return ratingEval.NEUTRAL_RATING;
        }
        const lastRating = rating[rating.length - 1];
        if (lastRating >= 4) {
            return ratingEval.GOOD_RATING;
        }
        return ratingEval.BAD_RATING;
    }

    shortRatingText(text: string): string | Object {
        switch (text) {
            case ratingText.RATING_GOOD:
                return this._translate.instant('templates.satisfied');
            case ratingText.RATING_NEUTRAL:
                return this._translate.instant('templates.neutral');
            case ratingText.RATING_BAD:
                return this._translate.instant('templates.unsatisfied');
            default:
                return '';
        }
    }
}
