import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { User } from ':modules/user/user';
import { SearchComponent } from ':shared/components/search/search.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-users-list-modal',
    templateUrl: './users-list-modal.component.html',
    styleUrls: ['./users-list-modal.component.scss'],
    standalone: true,
    imports: [SearchComponent, TranslateModule, MatButtonModule, MatIconModule],
})
export class UsersListModalComponent {
    readonly SvgIcon = SvgIcon;
    filteredUsers: User[] = [];
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    constructor(
        public dialogRef: MatDialogRef<UsersListModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            users: User[];
        }
    ) {
        this.filteredUsers = this.data.users;
    }

    onValueChange(value: string): void {
        this.filteredUsers = this.data.users.filter((user) => user.email.toLowerCase().includes(value.toLowerCase()));
    }

    close(): void {
        this.dialogRef.close();
    }
}
