/* eslint-disable @typescript-eslint/member-ordering */
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, isNumber, meanBy, orderBy, round } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { RoiAggregatedPerformanceScoreDto } from '@malou-io/package-dto';
import { HeapEventName, isNotNil, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import * as AggregatedStatisticsSelector from '../../store/aggregated-statistics.selectors';
import { AggregatedPerformanceData, AggregatedPerformanceKpisData, AggregatedRoiDataChartErrors } from '../roi.interface';
import { AggregatedPerformanceChartComponent } from './aggregated-performance-chart/aggregated-performance-chart.component';
import { AggregatedPerformanceTableComponent } from './aggregated-performance-table/aggregated-performance-table.component';

@Component({
    selector: 'app-aggregated-performance',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        TranslateModule,
        SelectComponent,
        SkeletonComponent,
        AggregatedPerformanceChartComponent,
        MatButtonToggleModule,
        MatIconModule,
        AggregatedPerformanceTableComponent,
        MatIconModule,
        MatTooltipModule,
    ],
    templateUrl: './aggregated-performance.component.html',
    styleUrl: './aggregated-performance.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedPerformanceComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();

    private readonly _store = inject(Store);
    private readonly _roiService = inject(RoiService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslate = inject(EnumTranslatePipe);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _roiContext = inject(RoiContext);
    private readonly _heapService = inject(HeapService);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly selectedTimeScaleFilter$ = this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    readonly Illustration = Illustration;

    readonly isChartLoading: WritableSignal<boolean> = signal(true);
    readonly chartError: WritableSignal<AggregatedRoiDataChartErrors> = signal({
        restaurantsWithoutData: [],
        hasError: false,
        noDataError: false,
        errorMessage: '',
    });

    readonly performanceChartData: WritableSignal<AggregatedPerformanceData> = signal([]);
    readonly performanceKpisTableData: WritableSignal<AggregatedPerformanceKpisData> = signal([]);

    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);
    readonly MAX_RESTAURANTS_TO_SHOW_CUSTOM_LABEL = 15;

    sortByChanged$ = new BehaviorSubject(false);

    selectedViewMode = StatisticsDataViewMode.CHART;
    StatisticsDataViewMode = StatisticsDataViewMode;

    killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._getPerformanceKpisData();

        this.sortByChanged$.subscribe(() => {
            if (!this.performanceChartData().length) {
                this.chartError.update((chartError) => cloneDeep({ ...chartError, noDataError: true }));
                return;
            }

            const sortedChartData = this._sortChartData(this.sortByControl.value, this.performanceChartData());

            this.performanceChartData.set(sortedChartData);
        });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onSortByChange(sortByElt: ChartSortBy): void {
        this.sortByControl.setValue(sortByElt);
        this.sortByChanged$.next(true);
    }

    onViewModeChange(value: StatisticsDataViewMode): void {
        this.selectedViewMode = value;
        this._heapService.track(HeapEventName.ROI_AGGREGATED_PERFORMANCE_VIEW_MODE_CHANGE, { viewMode: value });
    }

    private _getPerformanceKpisData(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.selectedTimeScaleFilter$])
            .pipe(
                tap(() => this.isChartLoading.set(true)),
                switchMap(([restaurants, _]: [Restaurant[], MalouTimeScalePeriod | undefined]) =>
                    forkJoin({
                        restaurants: of(restaurants),
                        aggregatedPerformanceScoresForRestaurants: this._roiService
                            .getPerformanceScoreForRestaurantsForNLastMonths(
                                restaurants.map((restaurant) => restaurant._id),
                                this.selectedMonths()
                            )
                            .pipe(map((res) => res.data)),
                    })
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ restaurants, aggregatedPerformanceScoresForRestaurants }) => {
                    this._computePerformanceChartData(restaurants, aggregatedPerformanceScoresForRestaurants);
                    this._computePerformanceKpiTableData(restaurants, aggregatedPerformanceScoresForRestaurants);

                    this.isChartLoading.set(false);
                },
                error: () => {
                    this.performanceChartData.set([]);
                    this.chartError.update((chartError) =>
                        cloneDeep({ ...chartError, hasError: true, errorMessage: this._translateService.instant('common.server_error') })
                    );
                    this.isChartLoading.set(false);
                },
            });
    }

    private _sortChartData(sortByElt: ChartSortBy, data: AggregatedPerformanceData): AggregatedPerformanceData {
        if (sortByElt === ChartSortBy.ALPHABETICAL) {
            return cloneDeep(
                data.sort((a, b) =>
                    sortRestaurantsByInternalNameThenName(
                        { name: a.restaurant?.name, internalName: a.restaurant?.internalName },
                        { name: b.restaurant?.name, internalName: b.restaurant?.internalName }
                    )
                )
            );
        }
        return orderBy(data, 'averagePerformanceScore', sortByElt);
    }

    private _computePerformanceChartData(
        restaurants: Restaurant[],
        performanceScoreForRestaurantsByMonth: RoiAggregatedPerformanceScoreDto[]
    ): void {
        const performanceScoreData: AggregatedPerformanceData = [];
        const restaurantsWithoutData: string[] = [];

        performanceScoreForRestaurantsByMonth.map(({ performanceScoresByMonth, restaurantId, isMissingData }) => {
            const concernedRestaurant = restaurants.find((restaurant) => restaurant._id === restaurantId);
            if (concernedRestaurant) {
                performanceScoreData.push({
                    restaurant: concernedRestaurant,
                    performanceScoresByMonth:
                        performanceScoresByMonth?.map((performanceScore) =>
                            isNumber(performanceScore.performanceScore) ? round(performanceScore.performanceScore, 0) : null
                        ) ?? [],
                    averagePerformanceScore: round(meanBy(performanceScoresByMonth, 'performanceScore'), 0),
                    dates: performanceScoresByMonth?.map((performanceScore) => performanceScore.monthStartDate) ?? [],
                    isMissingData: isMissingData ?? false,
                });
                if (isMissingData) {
                    restaurantsWithoutData.push(concernedRestaurant.internalName ?? concernedRestaurant.name);
                }
            }

            return null;
        });

        this.performanceChartData.set(performanceScoreData.filter(isNotNil));

        this.chartError.set({
            restaurantsWithoutData,
            hasError: false,
            noDataError: !!performanceScoreForRestaurantsByMonth.length,
            errorMessage: '',
        });
    }

    private _computePerformanceKpiTableData(
        restaurants: Restaurant[],
        performanceKpiForRestaurantsByMonth: RoiAggregatedPerformanceScoreDto[]
    ): void {
        const performanceKpiData: AggregatedPerformanceKpisData = [];

        performanceKpiForRestaurantsByMonth.map(({ performanceScoreKpis, restaurantId, isMissingData }) => {
            const concernedRestaurant = restaurants.find((restaurant) => restaurant._id === restaurantId);
            if (concernedRestaurant) {
                performanceKpiData.push({
                    restaurant: concernedRestaurant,
                    performanceKpis: performanceScoreKpis,
                    isMissingData: isMissingData ?? false,
                });
                this._roiContext.performanceScorePerRestaurant.set([
                    ...this._roiContext.performanceScorePerRestaurant(),
                    { restaurantId, performanceScore: round(performanceScoreKpis?.performanceScore ?? 0, 0) },
                ]);
            }

            return null;
        });

        this.performanceKpisTableData.set(performanceKpiData.filter(isNotNil));
    }
}
