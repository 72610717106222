import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const deleteOrganizationRequestBodyValidator = z.object({
    organizationId: objectIdValidator,
});
export type DeleteOrganizationRequestBodyDto = z.infer<typeof deleteOrganizationRequestBodyValidator>;

export type DeleteOrganizationResponseBodyDto = undefined;
