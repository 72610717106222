<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div>
            <span>{{ data.mergedInformationUpdate.platformState.key | enumTranslate: 'platform_key' }}</span>
            <span class="malou-color-text-primary malou-text-12--regular ml-2 cursor-pointer underline" (click)="openPlatformTab()">{{
                'common.link' | translate
            }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        @if (dataSource.data.length) {
            <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        }
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
            {{ 'common.close' | translate }}
        </button>
    </div>
</div>

<ng-template #bodyTemplate>
    <mat-table class="malou-mat-table" [dataSource]="dataSource" #table="matTable">
        <ng-container [matColumnDef]="UpdatesComparisonTableFieldName.FIELD_NAME">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.update.updates_comparison.field_name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data; table: table" class="malou-text-12--bold">{{ data.fieldName }}</mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="UpdatesComparisonTableFieldName.PREVIOUS_VALUE">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.update.updates_comparison.previous_value' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data; table: table">
                @if (data.tooltip) {
                    <span [matTooltip]="data.previousValue">{{ data.tooltip }}</span>
                } @else {
                    {{ data.previousValue }}
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="UpdatesComparisonTableFieldName.CURRENT_VALUE">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.update.updates_comparison.current_value' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data; table: table">
                @if (data.tooltip) {
                    <span [matTooltip]="data.currentValue">{{ data.tooltip }}</span>
                } @else {
                    {{ data.currentValue }}
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="UpdatesComparisonTableFieldName.ACTIONS">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content">
                <div class="w-4"></div>
            </mat-header-cell>
            <mat-cell *matCellDef="let data; table: table" class="malou-mat-table-cell-fit-content">
                <div>
                    <button (click)="copy(data.currentValue)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let nfc; columns: displayedColumns; table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noDataTemplate>
    <div class="malou-text-12--regular malou-color-text-secondary">
        {{ 'admin.update.updates_comparison.no_update_to_do' | translate }}
    </div>
</ng-template>
