export function errorReplacer(_key: string, value: any): any {
    if (value instanceof Error) {
        const error = {};
        Object.getOwnPropertyNames(value).forEach((propName) => {
            error[propName] = value[propName];
        });
        return error;
    }
    return value;
}
