@if (!isError) {
    @if (!isReviewSubmitted) {
        <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
            <div><img class="w-24" [src]="Illustration.Cook | illustrationPathResolver" /></div>
            <div class="malou-text-32--bold malou-color-text-1 text-center">
                {{ 'scan.scan_private_review.title' | translate }}
            </div>
            <div class="malou-text-13--regular malou-color-text-2 text-center">
                {{ 'scan.scan_private_review.description' | translate }}
            </div>
            <div class="mt-8 w-full">
                <app-text-area [formControl]="textAreaControl"></app-text-area>
            </div>
            <div>
                <button mat-raised-button color="primary" [disabled]="textAreaControl.invalid" (click)="submitReview()">
                    {{ 'common.validate' | translate }}
                </button>
            </div>
        </div>
    } @else {
        <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
            <img class="w-24" [src]="Illustration.KarlOk | illustrationPathResolver" />
            <div class="malou-text-32--bold malou-color-text-1 text-center">
                {{ 'scan.scan_private_review.review_submitted.title' | translate }}
            </div>
            <div class="malou-text-13--regular malou-color-text-2 text-center">
                {{ 'scan.scan_private_review.review_submitted.description' | translate }}
            </div>
        </div>
    }
} @else {
    <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
        <img class="w-24" [src]="Illustration.Cook | illustrationPathResolver" />
        {{ 'scan.scan_private_review.error' | translate }}
    </div>
}
