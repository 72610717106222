<div class="grid min-h-[68vh] place-items-center">
    <div class="flex flex-col items-center">
        <div class="mb-10 w-32 md:mb-5 md:w-24">
            <img alt="Googles illustration" [src]="illustration() | illustrationPathResolver" />
        </div>
        <div class="px-6 text-center">
            <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                <span [outerHTML]="'messages.no_connected_platforms' | translate"></span>
            </h3>
            <p class="malou-text-10--regular">{{ message() }}</p>
            <div class="mt-12 md:mt-6">
                <button class="malou-btn-raised--primary btn-normal" mat-raised-button aria-label="sync reviews" (click)="goToPlatforms()">
                    {{ 'messages.connect_platforms' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
