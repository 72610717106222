import { AbstractControl, FormControl, Validators } from '@angular/forms';

import { emailRegex } from '@malou-io/package-utils';

export function isValidEmail(email: AbstractControl<string | null> | FormControl<string | null>): boolean {
    if (!email || !email.value) {
        return false;
    }
    if (Validators.email(email)) {
        return false;
    }
    return !!email.value.match(emailRegex);
}
