import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { CustomRoiChartLabelComponent } from ':modules/roi/custom-roi-chart-label/custom-roi-chart-label.component';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorLighterBlue } from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedPerformanceData } from '../../roi.interface';

type BarChartType = Extract<ChartType, 'bar'>;
export interface ChartMetaData {
    metadata: {
        dates: Date[][];
        performanceScore: ChartDataArray[];
    };
}

@Component({
    selector: 'app-aggregated-performance-chart',
    standalone: true,
    imports: [NgChartsModule, TranslateModule, CustomRoiChartLabelComponent],
    templateUrl: './aggregated-performance-chart.component.html',
    styleUrl: './aggregated-performance-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedPerformanceChartComponent {
    performanceChartData = input.required<AggregatedPerformanceData>();
    showCustomChartLabel = input.required<boolean>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.performanceChartData())
    );
    chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.performanceChartData()));
    chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());

    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _translateService = inject(TranslateService);

    private _computeChartData(data: AggregatedPerformanceData): (ChartDataset<BarChartType, ChartDataArray> & ChartMetaData)[] {
        if (!data?.length) {
            return [];
        }
        return [
            {
                label: this._translateService.instant('roi.estimated_customers_and_performance_chart.performance_score'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 5,
                data: data.map((d) => d.averagePerformanceScore),
                metadata: {
                    dates: data.map(({ dates }) => [...dates]),
                    performanceScore: data.map((d) => d.performanceScoresByMonth),
                },
            },
        ];
    }

    private _computeChartLabels(data?: AggregatedPerformanceData): string[] {
        if (!data) {
            return [];
        }
        return data.map(({ restaurant }) => capitalize(restaurant.internalName ?? restaurant.name));
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<any>): boolean => tooltipItem.formattedValue !== '0',
                    itemSort: (a: TooltipItem<any>, b: TooltipItem<any>): number => b.datasetIndex - a.datasetIndex,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    display: !this.showCustomChartLabel(),
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    beginAtZero: true,
                    max: 100,
                    ticks: {
                        stepSize: 20,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                    position: 'left',
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItem: TooltipItem<any>): string {
        return `${SMALL_TOOLTIP_TAB}${tooltipItem.label}: ${tooltipItem.formattedValue}`;
    }

    private _computeTooltipAfterLabel(tooltipItem: TooltipItem<any>): string[] {
        const performanceScore = tooltipItem.dataset.metadata.performanceScore[tooltipItem.dataIndex];
        const dates = tooltipItem.dataset.metadata.dates[tooltipItem.dataIndex].map((date) => new Date(date));
        const isSameYear = dates[0].getFullYear() === dates[dates.length - 1].getFullYear();
        return dates.map((date, index) =>
            isSameYear
                ? `${LARGE_TOOLTIP_TAB}${capitalize(DateTime.fromJSDate(date).toFormat('LLLL'))}: ${performanceScore[index]}`
                : `${LARGE_TOOLTIP_TAB}${capitalize(DateTime.fromJSDate(date).toFormat('LLLL, yyyy'))}: ${performanceScore[index]}`
        );
    }
}
