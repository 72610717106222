import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ReviewReplyAutomationDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { buildReviewReplyAutomationFromDto, ReviewReplyAutomation } from ':shared/models/automations';

@Injectable({
    providedIn: 'root',
})
export class AutomationsService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/automations`;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly REPLY_REVIEW_BASE_URL = `${this.API_BASE_URL}/review-replies`;

    constructor(private readonly _http: HttpClient) {}

    getRestaurantReviewReplyAutomations(restaurantId: string): Observable<ReviewReplyAutomation[]> {
        return this._http
            .get<ApiResultV2<ReviewReplyAutomationDto[]>>(`${this.REPLY_REVIEW_BASE_URL}/restaurants/${restaurantId}`)
            .pipe(map((res) => res.data.map((dto) => buildReviewReplyAutomationFromDto(dto))));
    }

    updateRestaurantReviewReplyAutomations(
        restaurantId: string,
        automations: ReviewReplyAutomation[]
    ): Observable<ReviewReplyAutomation[]> {
        return this._http
            .put<ApiResultV2<ReviewReplyAutomationDto[]>>(`${this.REPLY_REVIEW_BASE_URL}/restaurants/${restaurantId}`, { automations })
            .pipe(map((res) => res.data.map((dto) => buildReviewReplyAutomationFromDto(dto))));
    }
}
