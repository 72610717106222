import { ArchiveReviewsFilters, CommentReviewsFilters, MalouPeriod, StatusReviewsFilter } from ':shared/models';

export const DEFAULT_PERIOD = MalouPeriod.ALL;
export const DEFAULT_RATINGS = [1, 2, 3, 4, 5, 0] as const;
export const DEFAULT_STATUSES = [
    StatusReviewsFilter.ANSWERED,
    StatusReviewsFilter.NOT_ANSWERED,
    StatusReviewsFilter.PENDING,
    StatusReviewsFilter.NOT_ANSWERABLE,
];
export const DEFAULT_COMMENTS = [CommentReviewsFilters.WITH_TEXT, CommentReviewsFilters.WITHOUT_TEXT];
export const DEFAULT_ARCHIVES = [ArchiveReviewsFilters.UNARCHIVED];
