<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div>{{ 'admin.users.organizations_list' | translate }}</div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <div class="flex flex-col gap-y-6">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onValueChange($event)"></app-search>
            <div class="gap-col-2 flex flex-col">
                @for (organization of filteredOrganizations; track trackByIdFn($index, organization)) {
                    <div>
                        {{ organization.name }}
                    </div>
                }
            </div>
        </div>
    </div>
</div>
