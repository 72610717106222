import { z } from 'zod';

import { ApplicationLanguage, flattenObject } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { userSettingsValidator } from './users.dto';

export const updateUserProfileBodyValidator = z
    .object({
        name: z.string().optional(),
        lastname: z.string().optional(),
        email: z.string().trim().toLowerCase().email().optional(),
        settings: userSettingsValidator.optional(),
        defaultLanguage: z.string().optional(),
        profilePicture: z.string().optional(),
    })
    .transform((data) => ({
        name: data.name,
        lastname: data.lastname,
        email: data.email,
        defaultLanguage: data.defaultLanguage,
        profilePicture: data.profilePicture,
        ...flattenObject(data ?? {}),
    }));
export type UpdateUserProfileInputBodyDto = z.input<typeof updateUserProfileBodyValidator>;
export type UpdateUserProfileOutputBodyDto = z.output<typeof updateUserProfileBodyValidator>;

//---------------------------------------------------------------------------------

export const getFrontChatUserEmailHashValidator = z.object({
    email: z.string(),
    language: z.nativeEnum(ApplicationLanguage),
});

export type GetFrontChatUserEmailHashInputBodyDto = z.input<typeof getFrontChatUserEmailHashValidator>;

//---------------------------------------------------------------------------------

export const getUsersByOrganizationIdParamsValidator = z
    .object({
        organization_id: objectIdValidator,
    })
    .transform((data) => ({
        organizationId: data.organization_id,
    }));

export type GetUsersByOrganizationIdParamsDto = z.infer<typeof getUsersByOrganizationIdParamsValidator>;

//---------------------------------------------------------------------------------

export const getUserByIdParamsValidator = z
    .object({
        user_id: objectIdValidator,
    })
    .transform((data) => ({
        userId: data.user_id,
    }));

export type GetUserByIdParamsDto = z.input<typeof getUserByIdParamsValidator>;

//---------------------------------------------------------------------------------

export const updateUserRestaurantParamsValidator = z.object({
    user_restaurant_id: objectIdValidator,
});

export type UpdateUserRestaurantParamsDto = z.input<typeof updateUserRestaurantParamsValidator>;

//-----------------------------------------------------------------------------------

export const updateUserLastVisitedRestaurantParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type UpdateUserLastVisitedRestaurantParamsDto = z.input<typeof updateUserLastVisitedRestaurantParamsValidator>;

//-----------------------------------------------------------------------------------

export const deleteUsersByIdsBodyValidator = z.object({
    usersIds: z.array(objectIdValidator),
});

export type DeleteUsersByIdsBodyDto = z.input<typeof deleteUsersByIdsBodyValidator>;
