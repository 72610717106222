import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appScrollableXAxis]',
    standalone: true,
})
export class HorizontallyScrollableDirective implements AfterViewInit {
    @Output() scrollableChange: EventEmitter<{ left: boolean; right: boolean }> = new EventEmitter();

    constructor(private readonly _elementRef: ElementRef) {}

    @HostListener('scroll') onScroll(): void {
        this.checkScrollable();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.checkScrollable(), 100);
    }

    checkScrollable(): void {
        const el = this._elementRef.nativeElement;
        const hasOverflowLeft = el.scrollLeft > 0;
        const hasOverflowRight = el.scrollWidth > el.clientWidth && el.scrollLeft < el.scrollWidth - el.clientWidth - 20;

        this.scrollableChange.emit({ left: hasOverflowLeft, right: hasOverflowRight });
    }
}
