import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StarIcon, StarIconsPipe } from ':shared/pipes/star-icon.pipe';

@Component({
    selector: 'app-star-gauge',
    templateUrl: './star-gauge.component.html',
    styleUrls: ['./star-gauge.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, IncludesPipe, StarIconsPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarGaugeComponent {
    readonly size = input<'small' | 'medium' | 'large'>('small');
    readonly stars = input.required<number>();
    readonly maxStars = input<number>(5);

    readonly StarIcon = StarIcon;
}
