import { z } from 'zod';

import { ConversationStatus, MessageStatus, PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const updateConversationsQueryValidator = z
    .object({
        conversation_ids: z.array(objectIdValidator),
    })
    .transform((data) => ({
        conversationIds: data.conversation_ids,
    }));
export type UpdateConversationsQueryDto = z.infer<typeof updateConversationsQueryValidator>;

export const updateConversationsBodyValidator = z
    .object({
        status: z.nativeEnum(ConversationStatus),
        favorite: z.boolean(),
        archived: z.boolean(),
    })
    .partial()
    .refine(({ status, favorite, archived }) => status !== undefined || favorite !== undefined || archived !== undefined, {
        message: 'Must send at least one of the following field: status, favorite, archived',
    });
export type UpdateConversationsBodyDto = z.infer<typeof updateConversationsBodyValidator>;

export const getConversationsPaginatedParamsValidator = z.object({
    restaurant_id: objectIdValidator,
});
export type GetConversationsPaginatedParamsDto = z.infer<typeof getConversationsPaginatedParamsValidator>;

export const getConversationsPaginatedQueryValidator = z.object({
    page_number: z.string().optional(),
    page_size: z.string().optional(),
    total: z.string().optional(),
    platforms: z.nativeEnum(PlatformKey).or(z.nativeEnum(PlatformKey).array()).optional(),
    sort_order: z.string().optional(),
    sort_by: z.string(),
    conversation_type: z.string(),
    conversation_status: z.string().array().optional(),
});
export type GetConversationsPaginatedQueryDto = z.infer<typeof getConversationsPaginatedQueryValidator>;

export const getUnreadConversationsCountParamsValidator = z.object({
    restaurant_id: objectIdValidator,
});
export type GetUnreadConversationsCountParamsDto = z.infer<typeof getUnreadConversationsCountParamsValidator>;

export const getUnreadConversationsCountQueryValidator = z.object({
    connected_platforms: z.nativeEnum(PlatformKey).array().optional(),
});
export type GetUnreadConversationsCountQueryDto = z.infer<typeof getUnreadConversationsCountQueryValidator>;

export const synchronizeConversationsParamsValidator = z.object({
    restaurant_id: objectIdValidator,
});
export type SynchronizeConversationsParamsDto = z.infer<typeof synchronizeConversationsParamsValidator>;

export const synchronizeConversationsBodyValidator = z.object({
    platformsKeys: z.nativeEnum(PlatformKey).array(),
});
export type SynchronizeConversationsBodyDto = z.infer<typeof synchronizeConversationsBodyValidator>;

export const updateMessageReactionParamsValidator = z.object({
    message_id: objectIdValidator,
    restaurant_id: objectIdValidator,
});
export type UpdateMessageReactionParamsDto = z.infer<typeof updateMessageReactionParamsValidator>;

export const updateMessageReactionBodyValidator = z.object({
    reactionType: z.string(),
});
export type UpdateMessageReactionBodyDto = z.infer<typeof updateMessageReactionBodyValidator>;

export const sendConversationMessageParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({ platformKey: data.platform_key }));
export type SendConversationMessageParamsDto = z.infer<typeof sendConversationMessageParamsValidator>;

const userInfoValidator = z.object({
    displayName: z.string().nullish(),
    userSocialId: z.string().nullish(),
    profilePictureUrl: z.string().nullish(),
    userDeviceLocale: z.string().nullish(),
});

const messageAttachmentUploadingValidator = z.object({ url: z.string().nullish(), type: z.string(), name: z.string().nullish() });

export const sendConversationMessageBodyValidator = z.object({
    restaurantId: objectIdValidator,
    message: z.object({
        isFromRestaurant: z.boolean(),
        key: z.nativeEnum(PlatformKey),
        conversationId: z.string().nullish(),
        socialCreatedAt: z.string(),
        socialConversationId: z.string().nullish(),
        status: z.nativeEnum(MessageStatus),
        malouAuthorId: objectIdValidator,
        userInfo: userInfoValidator.nullish(),
        templateIdUsed: objectIdValidator.nullish(),
        _id: z.string().nullish(),
        text: z.string().nullish(),
        attachments: z.array(messageAttachmentUploadingValidator).nullish(),
    }),
});
export type SendConversationMessageBodyDto = z.infer<typeof sendConversationMessageBodyValidator>;

export const updateConversationsParamsValidator = z.object({
    conversation_id: objectIdValidator,
});
export type UpdateConversationsParamsDto = z.infer<typeof updateConversationsParamsValidator>;
