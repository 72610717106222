import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PostsState } from './posts.reducer';

export const selectPostsState = createFeatureSelector<PostsState>('posts');

export const selectPostsBindingId = createSelector(selectPostsState, (state: PostsState) => state.postsBindingId);

export const selectPostsBindingIdKey = createSelector(selectPostsState, (state: PostsState) => state.bindingIdKey);

export const selectCurrentlyPublishingPosts = createSelector(selectPostsState, (state: PostsState) => state.currentlyPublishingPosts);

export const selectRefreshDates = createSelector(selectPostsState, (state: PostsState) => state.refreshDates);
