import { z } from 'zod';

export const handleTiktokOauthCallbackQueryValidator = z.object({
    code: z.string().optional(), // Not sent if user cancelled ongoing process
    scopes: z.string(),
    state: z.string(),
    error: z.string().optional(),
    error_description: z.string().optional(),
});
export type HandleTiktokOauthCallbackQueryDto = z.infer<typeof handleTiktokOauthCallbackQueryValidator>;
