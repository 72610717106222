export enum InformationsUpdateStatus {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
    NOT_STARTED = 'NOT_STARTED',
}
export interface InformationState {
    restaurantId?: string;
    isDirty?: boolean;
    isFooterVisible?: boolean;
    shouldStartUpdate?: boolean;
    platformPropertiesToUpdate: string[];
    updateLoadingState?: InformationsUpdateStatus;
}
