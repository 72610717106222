<div>
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>{{ getNotificationEmoji() }}</div>
        <div>
            <div
                class="malou-text-11 flex max-w-[90%] items-center gap-x-2 text-malou-color-text-1"
                [innerHTML]="
                    hasMultipleRestaurantsInNotification()
                        ? ('notification-center.special-hour-notification-item.are_your_restaurants_open'
                          | translate
                              : {
                                    formattedDate: notification().getFormattedEventDate(),
                                    eventName: getNotificationEventName(),
                                })
                        : ('notification-center.special-hour-notification-item.are_you_open'
                          | translate
                              : {
                                    formattedDate: notification().getFormattedEventDate(),
                                    eventName: getNotificationEventName(),
                                })
                "></div>
        </div>
    </div>
    <div class="ml-6.5 mt-2 flex flex-row gap-1">
        @if (!isUserActionCompleted()) {
            <button
                class="malou-btn-raised--secondary btn-xs !bg-transparent !px-1"
                mat-raised-button
                (click)="onActionClick(SpecialHourNotificationAction.OPEN_AS_USUAL)">
                {{ 'notification-center.special-hour-notification-item.button.open' | translate }}
            </button>
            <button
                class="malou-btn-raised--secondary btn-xs !bg-transparent !px-0"
                mat-raised-button
                (click)="onActionClick(SpecialHourNotificationAction.CLOSED)">
                {{ 'notification-center.special-hour-notification-item.button.closed' | translate }}
            </button>
            @if (!hasMultipleRestaurantsInNotification()) {
                <button
                    class="malou-btn-raised--secondary btn-xs !bg-transparent !px-2"
                    mat-raised-button
                    (click)="redirectToInformationPage()">
                    {{ 'notification-center.special-hour-notification-item.button.change_my_hours' | translate }}
                </button>
            }
        } @else {
            <span class="malou-text-10 text-malou-color-text-2">
                {{ 'notification-center.special-hour-notification-item.action_description' | translate }} "{{
                    specialHourNotificationActionText() | translate
                }}"</span
            >
            <span
                class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5"
                (click)="$event.stopPropagation(); redirectToInformationPage()">
                {{ 'common.edit' | translate }}
            </span>
        }
    </div>
</div>
