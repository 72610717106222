import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, EMPTY, Observable, switchMap, take } from 'rxjs';

import { InsightsChart, InsightsTab, PlatformKey } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { FiltersComponent } from '../filters/filters.component';
import { PlatformFilterPage } from '../store/statistics.interface';
import * as StatisticsSelectors from '../store/statistics.selectors';
import { CommunityComponent } from './community/community.component';
import { EngagementComponent } from './engagement/engagement.component';
import { PostsInsightsTableComponent } from './posts-insights-table/posts-insights-table.component';

@Component({
    selector: 'app-statistics-social-networks',
    templateUrl: './social-networks.component.html',
    styleUrls: ['./social-networks.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        CommunityComponent,
        EngagementComponent,
        PostsInsightsTableComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        LazyLoadImageModule,
    ],
})
export class SocialNetworksComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;

    isCommunityLoading = signal(true);
    isEngagementLoading = signal(true);
    isPostInsightsTableLoading = signal(true);

    isLoading = computed(() => this.isCommunityLoading() || this.isEngagementLoading() || this.isPostInsightsTableLoading());

    readonly PlatformFilterPage = PlatformFilterPage;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );

    chartOptions: ChartOptions = {
        [InsightsChart.COMMUNITY]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.ENGAGEMENT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.POST_INSIGHTS]: {
            tableSortOptions: undefined,
        },
        [InsightsChart.REEL_INSIGHTS]: {
            tableSortOptions: undefined,
        },
        [InsightsChart.STORY_INSIGHTS]: {
            tableSortOptions: undefined,
        },
    };

    readonly InsightsChart = InsightsChart;

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.topOfComponent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }));
    }

    openStatisticsDownload(): void {
        combineLatest([this._store.select(StatisticsSelectors.selectDatesFilter), this.platformKeys$])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.SOCIAL_NETWORKS,
                                filters: {
                                    dates: { startDate, endDate },
                                    platforms,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(event: { chart: InsightsChart; value: Sort }): void {
        this.chartOptions = {
            ...this.chartOptions,
            [event.chart]: {
                ...this.chartOptions[event.chart],
                tableSortOptions: event.value,
            },
        };
    }
}
