<ng-container class="min-h-52" [ngTemplateOutlet]="isParentLoading() ? loadingTemplate : estimatedCustomersBannerTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg px-4 py-6 flex flex-col gap-2 h-52">
        <div class="flex sm:hidden">
            <app-skeleton class="w-[250px]"></app-skeleton>
        </div>
        <div class="flex h-full items-center gap-4 sm:hidden">
            <app-skeleton appearance="circle" skeletonClass="h-[98px] w-[98px] rounded-full"></app-skeleton>
            <div class="flex flex-col gap-2">
                <app-skeleton class="h-[30px] w-[200px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[200px]"></app-skeleton>
            </div>
            <app-skeleton class="!h-full w-[5px]" skeletonClass="h-full"></app-skeleton>
            <app-skeleton appearance="circle" skeletonClass="h-[98px] w-[98px] rounded-full"></app-skeleton>
            <div class="flex flex-col gap-2">
                <app-skeleton class="h-[30px] w-[200px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[200px]"></app-skeleton>
            </div>
        </div>
    </app-skeleton>
</ng-template>

<ng-template #estimatedCustomersBannerTemplate>
    <div class="malou-simple-card roi-container flex h-full flex-col gap-3 px-4 py-6">
        <div class="flex items-center">
            <span class="malou-text-14--bold malou-color-text-1 mr-2">{{
                'roi.estimated_customers_and_performance_chart.estimated_customers_thanks_to_marketing' | translate
            }}</span>
        </div>
        <div class="flex flex-wrap gap-2 sm:justify-center">
            <div class="my-3 mr-3 flex items-center gap-3 sm:m-0">
                <div class="h-24 w-24 rounded-full bg-malou-color-background-dark p-5 sm:hidden">
                    <img class="h-14 w-14 sm:mb-5" [src]="'cutlery' | emojiPathResolver" />
                </div>
                <div class="estimated-customers-container flex flex-col">
                    <span class="malou-text-14--bold malou-color-text-1">{{
                        (isInTheLastFourDays()
                            ? 'roi.estimated_customers.will_be_estimated_this_month'
                            : 'roi.estimated_customers.will_be_estimated_next_month'
                        ) | translate
                    }}</span>
                    <span class="malou-text-13 malou-color-text-2 italic">{{
                        'roi.estimated_customers.come_back_next_month' | translate
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
