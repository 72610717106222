import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ReviewTemplatesFilters } from ':shared/models/template-filters';

import { ReviewTemplateState } from './review-templates.reducer';

export const selectReviewTemplatesState = createFeatureSelector<ReviewTemplateState>('reviewTemplates');

export const selectReviewTemplatesFilters = createSelector(selectReviewTemplatesState, (state: ReviewTemplateState) => state.filters);

export const selectReviewTemplatesFiltersRatings = createSelector(
    selectReviewTemplatesFilters,
    (filters: ReviewTemplatesFilters) => filters.ratings ?? []
);

export const selectReviewTemplatesSort = createSelector(selectReviewTemplatesState, (state: ReviewTemplateState) => state.sort);

export const selectReviewTemplatesSortBy = createSelector(
    selectReviewTemplatesSort,
    (sort: { sortBy: string; sortOrder: string }) => sort.sortBy
);

export const selectReviewTemplatesSortOrder = createSelector(
    selectReviewTemplatesSort,
    (sort: { sortBy: string; sortOrder: string }) => sort.sortOrder
);

export const selectReviewTemplatesCount = createSelector(selectReviewTemplatesState, (state: ReviewTemplateState) => state.templateCount);
