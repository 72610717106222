import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { RestaurantsWithActiveWheels, UserWheelOfFortuneStatus, WheelOfFortuneDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { Restaurant } from ':shared/models/restaurant';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';

@Injectable({
    providedIn: 'root',
})
export class WheelsOfFortuneService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/wheels-of-fortune`;
    readonly AGGREGATED_WHEELS_OF_FORTUNE_PATH = 'aggregated-wheels-of-fortune';
    readonly RESTAURANT_WHEELS_OF_FORTUNE_PATH = 'restaurant-wheels-of-fortune';

    constructor(private readonly _http: HttpClient) {}

    getRestaurantIdsWithoutActiveWheel(): Observable<ApiResultV2<string[]>> {
        return this._http.get<ApiResultV2<string[]>>(`${this.API_BASE_URL}/restaurants-without-active-wheel`);
    }

    getRestaurantIdsWithActiveWheel(): Observable<RestaurantsWithActiveWheels> {
        return this._http
            .get<ApiResultV2<RestaurantsWithActiveWheels>>(`${this.API_BASE_URL}/restaurants-with-active-wheel`)
            .pipe(map((res) => res.data));
    }

    updateWheelOfFortuneById(
        wofId: string,
        wheelOfFortune: WheelOfFortune,
        isAggregated: boolean
    ): Observable<ApiResultV2<WheelOfFortuneDto>> {
        const ROUTER_URL = isAggregated ? this.AGGREGATED_WHEELS_OF_FORTUNE_PATH : this.RESTAURANT_WHEELS_OF_FORTUNE_PATH;
        return this._http.put<ApiResultV2<WheelOfFortuneDto>>(`${this.API_BASE_URL}/${ROUTER_URL}/${wofId}`, wheelOfFortune);
    }

    createWheelOfFortune(wheelOfFortune: WheelOfFortune, isAggregated: boolean): Observable<ApiResultV2<WheelOfFortuneDto>> {
        const ROUTER_URL = isAggregated ? this.AGGREGATED_WHEELS_OF_FORTUNE_PATH : this.RESTAURANT_WHEELS_OF_FORTUNE_PATH;
        return this._http.post<ApiResultV2<WheelOfFortuneDto>>(`${this.API_BASE_URL}/${ROUTER_URL}`, wheelOfFortune);
    }

    getWheelOfFortuneById(wofId: string): Observable<ApiResultV2<WheelOfFortuneDto>> {
        return this._http.get<ApiResultV2<WheelOfFortuneDto>>(`${this.API_BASE_URL}/${wofId}`);
    }

    deleteWheelOfFortuneById(wofId: string): Observable<ApiResultV2<WheelOfFortuneDto>> {
        return this._http.delete<ApiResultV2<WheelOfFortuneDto>>(`${this.API_BASE_URL}/${wofId}`);
    }

    getUserActiveAggregatedWheels(): Observable<ApiResultV2<WheelOfFortuneDto[]>> {
        return this._http.get<ApiResultV2<WheelOfFortuneDto[]>>(`${this.API_BASE_URL}/${this.AGGREGATED_WHEELS_OF_FORTUNE_PATH}/active`);
    }

    getActiveRestaurantWheelOfFortune(restaurantId: string): Observable<ApiResultV2<WheelOfFortuneDto | null>> {
        return this._http.get<ApiResultV2<WheelOfFortuneDto | null>>(
            `${this.API_BASE_URL}/${this.RESTAURANT_WHEELS_OF_FORTUNE_PATH}/restaurants/${restaurantId}/active`
        );
    }

    getWheelExistingPlayerEmails(wheelOfFortuneId: string, restaurantId: string): Observable<ApiResultV2<string[]>> {
        return this._http.get<ApiResultV2<string[]>>(`${this.API_BASE_URL}/${wheelOfFortuneId}/restaurants/${restaurantId}/players-emails`);
    }

    getActiveWheel(restaurantId: string): Observable<ApiResultV2<WheelOfFortuneDto | null>> {
        return this._http.get<ApiResultV2<WheelOfFortuneDto | null>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`);
    }

    updateTotemIds(wheelOfFortuneId: string, totemIds: string[]): Observable<ApiResultV2<void>> {
        return this._http.post<ApiResultV2<void>>(`${this.API_BASE_URL}/${wheelOfFortuneId}/nfcs`, { totemIds });
    }

    haveAtLeastOneWheelOfFortune(restaurantIds: string[]): Observable<ApiResultV2<boolean>> {
        return this._http.post<ApiResultV2<boolean>>(`${this.API_BASE_URL}/have-at-least-one`, { restaurantIds });
    }

    getUserWheelOfFortuneInformations(): Observable<ApiResultV2<UserWheelOfFortuneStatus>> {
        return this._http.get<ApiResultV2<UserWheelOfFortuneStatus>>(`${this.API_BASE_URL}/users/status`);
    }

    sendSubscriptionRequest(restaurants: Restaurant[]): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/booster-pack/subscription-request`, { restaurants });
    }

    getPreviousWheelOfFortune(restaurantId: string): Observable<ApiResultV2<WheelOfFortuneDto | null>> {
        return this._http.get<ApiResultV2<WheelOfFortuneDto | null>>(
            `${this.API_BASE_URL}/${this.RESTAURANT_WHEELS_OF_FORTUNE_PATH}/restaurants/${restaurantId}/previous`
        );
    }
}
