import tlds from 'tlds';

import { urlRegex } from '../../constants';

export const cleanUtmParamsFromUrl = (url: string): string => {
    const splitUrl = url.split('?');
    let cleanedUrl = splitUrl[0];

    if (splitUrl.length > 1) {
        const urlParamsString = splitUrl[1];
        const urlParams = new URLSearchParams(urlParamsString);
        const keys = urlParams.keys();
        const keysToDelete: string[] = [];
        let key = keys.next();
        while (!key.done) {
            if (key.value.includes('utm_')) {
                keysToDelete.push(key.value);
            }
            key = keys.next();
        }
        keysToDelete.forEach((keyToDelete) => urlParams.delete(keyToDelete));

        const cleanUrlParams = urlParams.toString();
        if (cleanUrlParams.length > 0) {
            cleanedUrl += '?' + urlParams.toString();
        }
    }

    return cleanedUrl;
};

export function cleanUrl(url) {
    if (url === null || url === undefined) {
        return null;
    }
    return cleanUtmParamsFromUrl(url);
}

/**
 * Checks if a given string is a valid URL and has a valid TLD.
 */
export function isValidUrl(url: string, options?: { allowEmpty: boolean }): boolean {
    if (options?.allowEmpty && url === '') {
        return true;
    }

    // Check URL format
    if (!urlRegex.test(url)) {
        return false;
    }

    // Validate URL format
    let urlObj: URL;
    try {
        urlObj = new URL(url);
    } catch (err) {
        return false;
    }

    // Extract the hostname and validate TLD
    const hostname = urlObj.hostname;
    const domainParts = hostname.split('.');

    // Check if there is a TLD, and get it
    if (domainParts.length < 2) {
        return false;
    }
    const tld = domainParts.pop();

    if (!tlds.includes(tld!)) {
        return false;
    }

    return true;
}

export const changeUrlDomain = (url: string, newHostname: string) => {
    try {
        // Add http to url if it doesn't have it to prevent URL constructor
        // to throw an error
        const urlObj = url.match(/^https?:\/\//) ? new URL(url) : new URL(`http://${url}`);

        // Parse the URL to get different parts
        const { hostname } = urlObj;

        if (!hostname) {
            console.error(`Error changing domain, invalid format: ${url}`);
            return url;
        }

        // Construct the new hostname
        urlObj.hostname = newHostname;

        // Remove the protocol if it was initially absent
        const resultUrl = url.match(/^https?:\/\//) ? urlObj.toString() : urlObj.toString().replace('http://', '');

        return resultUrl;
    } catch (error: any) {
        console.error(`Error changing domain: ${error.message}`);
        return url;
    }
};

const getUrlDomain = (url: string): string | undefined => {
    try {
        const urlObj = new URL(url);
        const hostname = urlObj.hostname;
        const domainParts = hostname.split('.');

        // Check if we have at least a domain and a TLD
        if (domainParts.length < 2) {
            return;
        }

        return domainParts[domainParts.length - 2];
    } catch (error) {
        return;
    }
};

export const isFromDomain = ({ url, domain }: { url: string; domain: string }): boolean => {
    try {
        const domainToCheck = getUrlDomain(url);

        if (!domainToCheck) {
            return false;
        }

        return domainToCheck === domain;
    } catch (error) {
        return false;
    }
};

export const hasSameDomain = (url1, url2): boolean => {
    try {
        // Get both domains
        const domainToCheck = getUrlDomain(url1);
        const domainToCheck2 = getUrlDomain(url2);

        if (!domainToCheck || !domainToCheck2) {
            return false;
        }

        return domainToCheck === domainToCheck2;
    } catch (error) {
        return false;
    }
};

export const trimSlashes = (pathname: string): string => {
    let trimmedPathname = pathname;

    if (pathname.startsWith('/')) {
        trimmedPathname = trimmedPathname.slice(1);
    }

    if (pathname.endsWith('/')) {
        trimmedPathname = trimmedPathname.slice(0, -1);
    }

    return trimmedPathname;
};
