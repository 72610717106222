@let base = ['/restaurants', selectedRestaurant()?._id ?? ''];
<app-sidenav-content-route-group
    [options]="{
        text: 'sidenav_content.boosters' | translate,
        routerLink: base | concat: ['boosters'],
        svgIcon: SvgIcon.ROCKET,
        secondarySvgIcon: !selectedRestaurant()?.boosterPack?.activated ? SvgIcon.CROWN : undefined,
    }"
    [childrenOptions]="[
        {
            text: 'sidenav_content.wheel_of_fortune' | translate,
            routerLink: base | concat: ['boosters', 'wheels-of-fortune'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.totems' | translate,
            routerLink: base | concat: ['boosters', 'totems'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
    ]" />
