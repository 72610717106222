import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { SimpleAccountManagedConnectionStep0Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-simple-account-managed-modal/simple-account-managed-connection-step-0/simple-account-managed-connection-step-0.component';
import { SimpleAccountManagedConnectionStep1Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-simple-account-managed-modal/simple-account-managed-connection-step-1/simple-account-managed-connection-step-1.component';
import {
    PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
    PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
    ParentStepperComponentDialogData,
    PlatformsConnectionParentStepperComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-parent-stepper.component';
import { GetStepIndexFromPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/get-step-index-from-platform.service';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export type AuthorizedSimpleAccountManagedPlatformKeys = PlatformKey.OPENTABLE | PlatformKey.UBEREATS;

export interface SimpleAccountManagedConnectionModalResult {
    hasDataChanged?: boolean;
}

interface AccountSimpleManagedConnectionModalTranslations {
    title: string;
    subtitle: string;
    step0: string;
    step1: string;
}

const NOT_CONNECTED_STEP_INDEX = 0;
const CONNECTED_STEP_INDEX = 1;

@Injectable({ providedIn: 'root' })
export class SimpleAccountManagedConnectionModalService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _getStepIndexFromPlatformService = inject(GetStepIndexFromPlatformService);

    open(platformKey: AuthorizedSimpleAccountManagedPlatformKeys): Observable<SimpleAccountManagedConnectionModalResult | undefined> {
        const stepIndex$ = this._getStepIndexFromPlatformService.execute(platformKey, NOT_CONNECTED_STEP_INDEX, CONNECTED_STEP_INDEX);
        return stepIndex$.pipe(
            switchMap((stepIndex) => {
                const translations = this._getTranslations(platformKey);
                return this._customDialogService
                    .open<
                        PlatformsConnectionParentStepperComponent,
                        ParentStepperComponentDialogData<
                            { platformKey: AuthorizedSimpleAccountManagedPlatformKeys },
                            SimpleAccountManagedConnectionModalResult
                        >,
                        SimpleAccountManagedConnectionModalResult
                    >(PlatformsConnectionParentStepperComponent, {
                        height: PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
                        width: PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
                        data: {
                            sharedData: { platformKey },
                            steps: [
                                {
                                    stepName: this._translateService.instant(translations.step0),
                                    component: SimpleAccountManagedConnectionStep0Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(translations.step1),
                                    component: SimpleAccountManagedConnectionStep1Component,
                                    componentInputs: {},
                                },
                            ],
                            stepperTitle: this._translateService.instant(translations.title),
                            stepperSubtitle: this._translateService.instant(translations.subtitle),
                            stepperPlatformKeyIcon: platformKey,
                            stepIndex: stepIndex,
                        },
                    })
                    .afterClosed();
            })
        );
    }

    private _getTranslations(platformKey: AuthorizedSimpleAccountManagedPlatformKeys): AccountSimpleManagedConnectionModalTranslations {
        const base = 'platforms.connection_new.simple_account_managed';
        return {
            title: `${base}.${platformKey}.stepper.title`,
            subtitle: `${base}.${platformKey}.stepper.subtitle`,
            step0: `${base}.${platformKey}.step_0.step_name`,
            step1: `${base}.${platformKey}.step_1.step_name`,
        };
    }
}
