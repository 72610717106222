import { Routes } from '@angular/router';

import { NotificationsSettingsComponent } from ':modules/user/notifications-settings/notifications-settings.component';
import { ProfileComponent } from ':modules/user/profile/profile.component';
import { ReportsSettingsComponent } from ':modules/user/reports-settings/reports-settings.component';
import { UserResolver } from ':modules/user/user.resolver';

export const USERS_ROUTES: Routes = [
    {
        path: ':user_id',
        component: ProfileComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
    {
        path: ':user_id/notifications-settings',
        component: NotificationsSettingsComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
    {
        path: ':user_id/reports-settings',
        component: ReportsSettingsComponent,
        resolve: { user: UserResolver },
        providers: [UserResolver],
    },
];
