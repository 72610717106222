import { Component, computed, inject, input } from '@angular/core';
import { DateTime } from 'luxon';

import { LocalStorage } from ':core/storage/local-storage';
import { HoursModalService } from ':modules/informations/hours-modal/hours-modal.service';
import { DayOrDateHoursComponent } from ':shared/components/restaurant-informations/day-or-date-hours/day-or-date-hours.component';
import { Restaurant, SpecialDatePeriod, SpecialTimePeriod } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';

@Component({
    selector: 'app-restaurant-special-hours',
    templateUrl: './restaurant-special-hours.component.html',
    styleUrls: ['./restaurant-special-hours.component.scss'],
    standalone: true,
    imports: [DayOrDateHoursComponent, ApplyPurePipe],
    providers: [FormatDatePipe],
})
export class RestaurantSpecialHoursComponent {
    readonly restaurant = input.required<Restaurant>();

    private readonly _hoursModalService = inject(HoursModalService);
    private readonly _formatDatePipe = inject(FormatDatePipe);

    readonly displayedHours = computed((): SpecialTimePeriod[] => this._filterAndSortHours(this.restaurant().specialHours));
    readonly restaurantSpecialDatePeriods = computed((): SpecialDatePeriod[] =>
        this._hoursModalService
            .mapSpecialHoursToSpecialHoursState(this.displayedHours())
            .specialPeriods.sort((a, b) => (a.startDate?.getDate().getTime() ?? 0) - (b.startDate?.getDate().getTime() ?? 0))
    );

    readonly LANG = LocalStorage.getLang();

    startDateToDisplay = (datePeriod: SpecialDatePeriod): string =>
        datePeriod.isFromCalendarEvent && datePeriod.startDate
            ? DateTime.fromJSDate(datePeriod.startDate.getDate()).setLocale(this.LANG).toLocaleString(DateTime.DATE_FULL)
            : '';

    getTitle = (datePeriod: SpecialDatePeriod): string => {
        const endDate =
            datePeriod.endDate && !datePeriod.endDate.equals(datePeriod.startDate)
                ? this._formatDatePipe.transform(datePeriod.endDate.getDate(), 'shortDate')
                : '';
        const startDate = datePeriod.startDate
            ? endDate
                ? this._formatDatePipe.transform(datePeriod.startDate.getDate(), 'shortDate')
                : DateTime.fromJSDate(datePeriod.startDate.getDate()).setLocale(this.LANG).toLocaleString(DateTime.DATE_FULL)
            : '';
        const intervalDates = startDate + (endDate ? ' - ' + endDate : '');
        let title = datePeriod.name ?? intervalDates;

        if (!datePeriod.isFromCalendarEvent) {
            title += ' - ' + intervalDates;
        }
        return title;
    };

    private _filterAndSortHours(hours: SpecialTimePeriod[]): SpecialTimePeriod[] {
        if (!hours || hours.length === 0) {
            return [];
        }
        return [...hours]
            .filter((specialTimePeriod) => specialTimePeriod.isNotPastYet())
            .sort((h1, h2) => {
                if (h1.startDate.getDate() < h2.startDate.getDate()) {
                    return -1;
                }
                if (h1.startDate.getDate() > h2.startDate.getDate()) {
                    return 1;
                }
                if (!h1.openTime || !h2.openTime) {
                    return 0;
                }
                if (h1.openTime < h2.openTime) {
                    return -1;
                }
                if (h1.openTime > h2.openTime) {
                    return 1;
                }
                return 0;
            });
    }
}
