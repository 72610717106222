import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-right-arrow-with-content',
    templateUrl: './right-arrow-with-content.component.html',
    styleUrls: ['./right-arrow-with-content.component.scss'],
    standalone: true,
    imports: [MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightArrowWithContentComponent {
    readonly SvgIcon = SvgIcon;
}
