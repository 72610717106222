import { Pipe, PipeTransform } from '@angular/core';

import { formatStringDate } from ':shared/helpers';

@Pipe({
    name: 'DateToStringPipe',
    standalone: true,
})
export class DateToStringPipe implements PipeTransform {
    transform(date: Date | string): string {
        return formatStringDate(date);
    }
}
