import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, map, of, switchMap, take } from 'rxjs';

import { isNotNil, MalouErrorCode } from '@malou-io/package-utils';

import { DownloadService } from ':core/services/download.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { DownloadCsvEReputationService } from ':modules/reviews/reviews/download-select-menu/download-csv-e-reputation.service';
import { selectReviewsFilters } from ':modules/reviews/store/reviews.selectors';
import { DownloadInsightsFooterPopinComponent } from ':shared/components/download-insights-modal/download-insights-footer-popin/download-insights-footer-popin.component';
import { DownloadFormat, FileExtension } from ':shared/components/download-insights-modal/download-insights.interface';
import { FooterPopinService } from ':shared/components/footer-popin/footer-popin.service';
import { MenuButtonComponent } from ':shared/components/menu-button/menu-button.component';
import { downloadFilesAsZip } from ':shared/helpers/download-files-as-zip';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import {
    EReputationDownloadType,
    MAX_DOWNLOAD_REVIEWS_DURATION_IN_DAYS,
} from ':shared/services/csv-services/e-reputation/csv-e-reputation.interface';
import { createDateIntervalWithInDaysDurationCondition } from ':shared/services/csv-services/e-reputation/helper-functions';

@Component({
    selector: 'app-download-reviews-select-menu',
    templateUrl: './download-reviews-select-menu.component.html',
    styleUrls: ['./download-reviews-select-menu.component.scss'],
    standalone: true,
    imports: [MatMenuModule, TranslateModule, MenuButtonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadReviewsSelectMenuComponent {
    readonly isAggregatedView = input<boolean>(false);
    readonly DownloadFormat = DownloadFormat;

    readonly type = computed(() =>
        this.isAggregatedView() ? EReputationDownloadType.AGGREGATED_REVIEWS : EReputationDownloadType.REVIEWS
    );
    readonly isDownloading = signal<boolean>(false);
    private _startDate: Date;
    private _endDate: Date;

    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _footerPopinService = inject(FooterPopinService);
    private readonly _downloadCsvEReputationService = inject(DownloadCsvEReputationService);
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _downloadService = inject(DownloadService);

    showFooter(): string {
        this.isDownloading.set(true);
        return this._footerPopinService.open(DownloadInsightsFooterPopinComponent, {});
    }

    hideFooter(footerId: string): void {
        this.isDownloading.set(false);
        this._footerPopinService.close(footerId);
    }

    onDownloadFormatSelect(format: DownloadFormat): void {
        if (format === DownloadFormat.CSV) {
            this._downloadCsv();
        } else {
            this._downloadPdf();
        }
    }

    private _downloadCsv(): void {
        const footerId = this.showFooter();

        this._downloadCsvEReputationService
            .getCsvData$(this.type())
            .pipe(
                map((csvStringData) => {
                    if (!csvStringData || !csvStringData.length) {
                        return null;
                    }
                    return new Blob([csvStringData], { type: 'text/csv;charset=UTF-8' });
                }),
                switchMap((blob) =>
                    forkJoin({
                        blob: of(blob),
                        reviewsFilters: this._store.select(selectReviewsFilters).pipe(take(1)),
                    })
                )
            )
            .subscribe({
                next: ({ blob, reviewsFilters }) => {
                    if (!isNotNil(blob)) {
                        this._toastService.openErrorToast(this._translateService.instant('common.error'));
                        return;
                    }
                    const { startDate, endDate } = createDateIntervalWithInDaysDurationCondition(
                        reviewsFilters?.startDate,
                        reviewsFilters?.endDate,
                        MAX_DOWNLOAD_REVIEWS_DURATION_IN_DAYS
                    );
                    const filename = this._getFilename(FileExtension.CSV, startDate, endDate);
                    const zipFilename = this._getZipFilename(FileExtension.ZIP);
                    void downloadFilesAsZip([blob], [filename], zipFilename);
                    this.hideFooter(footerId);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.hideFooter(footerId);
                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                },
            });
    }

    private _downloadPdf(): void {
        const footerId = this.showFooter();
        forkJoin({
            reviewsFilters: this._store.select(selectReviewsFilters).pipe(take(1)),
        })
            .pipe(
                switchMap(({ reviewsFilters }) => {
                    const { startDate, endDate } = createDateIntervalWithInDaysDurationCondition(
                        reviewsFilters?.startDate,
                        reviewsFilters?.endDate,
                        MAX_DOWNLOAD_REVIEWS_DURATION_IN_DAYS
                    );
                    this._startDate = startDate;
                    this._endDate = endDate;
                    const restaurantIds: string[] = [];
                    if (this.isAggregatedView() && reviewsFilters?.aggregatedViewRestaurantIds) {
                        restaurantIds.push(...reviewsFilters.aggregatedViewRestaurantIds);
                    } else {
                        restaurantIds.push(this._restaurantsService.currentRestaurant._id);
                    }
                    return this._reviewsService.getFilteredReviewsPdfUrl(restaurantIds, {
                        ...omit(reviewsFilters, ['restaurantIds', 'period', 'aggregatedViewRestaurantIds']),
                        startDate,
                        endDate,
                    });
                }),
                switchMap((pdfUrl) => this._downloadService.downloadFile(pdfUrl))
            )
            .subscribe({
                next: (blob) => {
                    this.hideFooter(footerId);
                    const filename = this._getFilename(FileExtension.PDF, this._startDate, this._endDate);
                    saveAs(blob, filename);
                },
                error: (e) => {
                    if (
                        e.error?.message.includes('stack size exceeded') ||
                        e.error?.malouErrorCode === MalouErrorCode.LAMBDA_MAXIMUM_CALL_STACK_SIZE_EXCEEDED
                    ) {
                        this._toastService.openErrorToast(this._translateService.instant('reviews.download_info.size_limit'));
                    } else {
                        this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                    }
                    this.hideFooter(footerId);
                },
            });
    }

    private _getZipFilename(extension: string): string {
        const base = this._enumTranslatePipe.transform('tab_name', 'e_reputation');
        return `${base}.${extension}`;
    }

    private _getFilename(extension: string, startDate: Date, endDate: Date): string {
        const startDateFormatted = startDate ? DateTime.fromJSDate(startDate).toISODate() : '-';
        const endDateFormatted = endDate ? DateTime.fromJSDate(endDate).toISODate() : '-';
        const suffix = `_${startDateFormatted}_${endDateFormatted}`;
        const base = this._enumTranslatePipe.transform(this.type(), 'e_reputation');
        return `${base}${suffix}.${extension}`;
    }
}
