import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Post, PostWithJob, SocialPost, Story } from ':shared/models';

type GenericPost = Post | SocialPost | PostWithJob | Story;
@Injectable({
    providedIn: 'root',
})
export class SocialPostsService {
    readonly deletedPost$: Subject<GenericPost[]> = new Subject<GenericPost[]>();
    readonly editedPosts$: Subject<GenericPost[]> = new Subject<GenericPost[]>();
    readonly duplicatePosts$: Subject<{ posts: GenericPost[]; destination?: DuplicationDestination }> = new Subject<{
        posts: GenericPost[];
        destination?: DuplicationDestination;
    }>();
    readonly reload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    readonly highlightedPostIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    deletePost(posts: GenericPost[]): void {
        this.deletedPost$.next(posts);
    }

    duplicatePosts(posts: GenericPost[], destination?: DuplicationDestination): void {
        this.duplicatePosts$.next({ posts, destination });
    }

    editPosts(updatedPosts: GenericPost[]): void {
        this.editedPosts$.next(updatedPosts);
        const postIds = updatedPosts.map((post) => post.id);
        this.highlightedPostIds$.next(postIds);
        setTimeout(() => {
            this._removeHighlightedPostIds(postIds);
        }, 5000);
    }

    reloadFeed(): void {
        this.reload$.next(true);
    }

    private _removeHighlightedPostIds(postIds: string[]): void {
        const currentHighlightedPostIds = [...this.highlightedPostIds$.value];
        for (const postId of postIds) {
            const index = currentHighlightedPostIds.indexOf(postId);
            if (index > -1) {
                currentHighlightedPostIds.splice(index, 1);
            }
        }
        this.highlightedPostIds$.next(currentHighlightedPostIds);
    }
}
