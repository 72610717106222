@if (isLoading()) {
    <app-skeleton skeletonClass="!h-36 secondary-bg"></app-skeleton>
} @else {
    @if (!wantsRatingBeforeRestaurantCreationDate) {
        @if (!httpError) {
            @if (areSomeRatingsNotNull | applyPure: ratings) {
                <div
                    class="malou-simple-card flex items-center px-6 py-4 md:px-2"
                    [ngClass]="{
                        'h-40 flex-col items-baseline': showTitle,
                        'h-36 items-center !bg-malou-color-background-light': !showTitle,
                    }">
                    @if (showTitle) {
                        <div class="h-12.5">
                            <div class="malou-text-section-title malou-color-text-1 mb-2.5">
                                {{ 'platforms_rating.ratings_by_platform' | translate }}
                            </div>
                        </div>
                    }
                    <div class="flex w-full flex-wrap items-start justify-around gap-6">
                        @for (rating of ratings; track rating) {
                            <div class="flex flex-col items-center gap-5">
                                <div class="relative">
                                    <app-platform-logo
                                        imgClasses="h-12.5 w-12.5"
                                        [logo]="rating.platform"
                                        [withLazyLoading]="true"></app-platform-logo>
                                    @if (!rating.current) {
                                        <mat-icon
                                            class="malou-status--waiting absolute bottom-0 right-0"
                                            [svgIcon]="SvgIcon.EXCLAMATION_MARK"
                                            [matTooltip]="'statistics.e_reputation.platforms_ratings.no_ratings' | translate"></mat-icon>
                                    }
                                </div>
                                <div class="flex items-center">
                                    <span class="malou-text-section-title malou-color-text-1 mr-2">
                                        {{
                                            rating.current
                                                ? (rating.current | number: '1.0-2' : localeForNumberTransformation()) +
                                                  ((platformHasRatingOutOfTen | applyPure: rating.platform) ? '/10' : '')
                                                : '--'
                                        }}
                                    </span>
                                    @if (rating.current && rating.current !== rating.diff) {
                                        <app-number-evolution
                                            size="small"
                                            [value]="rating.diff"
                                            [displayedValue]="
                                                rating.diff | shortNumber: { fixNumber: 1, shouldDisplayMinusSign: false }
                                            "></app-number-evolution>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            } @else {
                <div class="flex h-36 flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'statistics.errors.no_data_explained' | translate }}</span>
                </div>
            }
        } @else {
            <div class="flex h-36 flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
            </div>
        }
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.e_reputation.platforms_ratings.no_data_yet' | translate }}</span>
            <span class="malou-text-10--regular">{{
                'statistics.e_reputation.platforms_ratings.want_ratings_before_restaurant_creation' | translate
            }}</span>
        </div>
    }
}
