import { Action, createReducer, on } from '@ngrx/store';
import { environment } from 'environments/environment';

import { LocalStorage } from ':core/storage/local-storage';

import * as JimoActions from './jimo.actions';

export interface JimoUserAttributesState {
    language: string;
    userCreationDate?: Date;
    restaurantCount: number;
    organizationCount: number;
    restaurantCountInOrganizations: number;
    userCountInOrganizations: number;
    aiInteractionCount: number;
    storyCreatedCount: number;
    hasCreatedAWheel: boolean;
    firstWheelCreatedAt: Date | null;
    hasAtLeastOneActiveOrProgrammedWheel: boolean;
    latestEndDateForActiveWheels: Date | null;
    email?: string;
    environment: string;
}

export const initialState: JimoUserAttributesState = {
    language: 'fr',
    restaurantCount: 0,
    organizationCount: 0,
    restaurantCountInOrganizations: 0,
    userCountInOrganizations: 0,
    aiInteractionCount: 0,
    storyCreatedCount: 0,
    hasCreatedAWheel: false,
    firstWheelCreatedAt: null,
    hasAtLeastOneActiveOrProgrammedWheel: false,
    latestEndDateForActiveWheels: null,
    environment: environment.environment,
};

const jimoUserAttributesReducer = createReducer(
    initialState,
    on(JimoActions.incrementJimoUserAiInteractionsCount, (state) => ({
        ...state,
        aiInteractionCount: state.aiInteractionCount + 1,
    })),
    on(
        JimoActions.changeWheelOfFortuneStatus,
        (state, { hasCreatedAWheel, firstWheelCreatedAt, hasAtLeastOneActiveOrProgrammedWheel, latestEndDateForActiveWheels }) => ({
            ...state,
            hasCreatedAWheel,
            firstWheelCreatedAt,
            hasAtLeastOneActiveOrProgrammedWheel,
            latestEndDateForActiveWheels,
        })
    ),
    on(JimoActions.incrementJimoUserStoriesCreatedCount, (state) => ({
        ...state,
        storyCreatedCount: state.storyCreatedCount + 1,
    })),
    on(JimoActions.updateLanguage, (state, { language }) => ({
        ...state,
        language,
    })),
    on(
        JimoActions.initJimoUserAttributes,
        (
            state,
            {
                user,
                aiInteractionCount,
                storyCreatedCount,
                restaurantCountInOrganizations,
                userCountInOrganizations,
                hasCreatedAWheel,
                firstWheelCreatedAt,
                hasAtLeastOneActiveOrProgrammedWheel,
                latestEndDateForActiveWheels,
            }
        ) => ({
            ...state,
            language: LocalStorage.getLang(),
            userCreationDate: user.createdAt,
            restaurantCount: (user.restaurants ?? []).length,
            organizationCount: (user.organizationIds ?? []).length,
            restaurantCountInOrganizations,
            userCountInOrganizations,
            aiInteractionCount,
            storyCreatedCount,
            hasCreatedAWheel,
            firstWheelCreatedAt,
            hasAtLeastOneActiveOrProgrammedWheel,
            latestEndDateForActiveWheels,
            email: user.email,
        })
    )
);

export function reducer(state: JimoUserAttributesState | undefined, action: Action): JimoUserAttributesState {
    return jimoUserAttributesReducer(state, action);
}
