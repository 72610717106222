<div class="h-full border-r border-malou-color-border-primary bg-malou-color-background-light">
    <div class="flex flex-col gap-4 border-b border-malou-color-border-primary px-6 py-5">
        <div class="malou-text-14--semibold text-malou-color-text-1">
            {{ 'social_posts.upsert_social_post_modal.duplicate_and_select_platforms.platforms' | translate }}
        </div>

        <div class="flex flex-col gap-2">
            @for (platformKey of socialPlatformKeys(); track platformKey) {
                <app-select-platform
                    [platformKey]="platformKey"
                    [checked]="isSelected | applyPure: platformKey : selectedSocialPlatforms()"
                    [tooltip]="
                        !(isConnected | applyPure: platformKey : connectedSocialPlatformKeys())
                            ? ('social_posts.upsert_social_post_modal.duplicate_and_select_platforms.not_connected' | translate)
                            : ''
                    "
                    [disabled]="!(isConnected | applyPure: platformKey : connectedSocialPlatformKeys())"
                    (selectChange)="onSelectChange(platformKey, $event)"></app-select-platform>
            }
        </div>
    </div>

    <div class="flex flex-col gap-4 border-b border-malou-color-border-primary px-6 py-5">
        <div class="malou-text-14--semibold text-malou-color-text-1">
            {{ 'social_posts.upsert_social_post_modal.duplicate_and_select_platforms.duplicate' | translate }}
        </div>

        <div class="flex flex-col gap-2">
            @for (platformKey of seoPlatformKeys(); track platformKey) {
                <app-select-platform [platformKey]="platformKey" [checked]="true"></app-select-platform>
            }
        </div>

        <div class="malou-text-12--regular italic text-malou-color-text-2">
            {{ 'social_posts.upsert_social_post_modal.duplicate_and_select_platforms.duplicate_hint' | translate }}
        </div>
    </div>
</div>
