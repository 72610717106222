<div class="malou-dialog">
    <div class="malou-dialog__header">
        <p>{{ 'user.profile.edit' | translate }}</p>
        <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-4" [formGroup]="editForm">
            <div class="flex gap-4 md:flex-col">
                <div class="relative self-stretch">
                    <img
                        class="malou-border-primary h-44 w-44 rounded-xl object-cover object-center md:w-full"
                        [src]="editForm.controls['profilePicture'].value?.urls?.small || ('Karl' | illustrationPathResolver)" />
                    <div class="absolute bottom-0 right-0 -translate-x-1/3 -translate-y-1/3">
                        <app-file-upload formControlName="profilePictureFile" (onError)="profilePictureErrorMessage = $event ?? ''">
                        </app-file-upload>
                    </div>
                </div>
                <div class="flex flex-1 flex-col justify-around">
                    <app-input-text
                        formControlName="name"
                        [required]="true"
                        [title]="'profile.firstname' | translate"
                        [placeholder]="'profile.firstname' | translate">
                    </app-input-text>

                    <app-input-text
                        formControlName="lastname"
                        [required]="true"
                        [title]="'profile.lastname' | translate"
                        [placeholder]="'profile.lastname' | translate">
                    </app-input-text>
                </div>
            </div>
            <p class="italic text-malou-color-state-warn">{{ profilePictureErrorMessage }}</p>
            <app-input-text
                formControlName="email"
                [required]="true"
                [title]="'profile.email' | translate"
                [placeholder]="'profile.email' | translate"
                [errorMessage]="editForm.get('email')?.dirty && editForm.get('email')?.errors?.error">
            </app-input-text>

            <app-input-text
                formControlName="role"
                [disabled]="true"
                [required]="true"
                [title]="'profile.role' | translate"
                [placeholder]="'profile.role' | translate">
            </app-input-text>
        </form>
    </div>

    <div class="malou-dialog__footer flex-wrap md:flex-nowrap">
        <button class="malou-btn-raised--secondary !h-11 md:!w-full" mat-raised-button (click)="cancel()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:!w-full" mat-raised-button [disabled]="editForm.invalid" (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
