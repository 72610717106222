import { Directive, ElementRef, Renderer2 } from '@angular/core';

const CLASSES_TO_ADD = ['absolute', 'top-1/2', 'left-1/2', '-translate-x-1/2', '-translate-y-1/2'];

@Directive({
    selector: '[appAbsoluteCentering]',
    standalone: true,
})
export class AbsoluteCenteringDirective {
    constructor(
        private readonly _renderer: Renderer2,
        private readonly _el: ElementRef<HTMLElement>
    ) {
        CLASSES_TO_ADD.forEach((classToAdd) => this._renderer.addClass(this._el.nativeElement, classToAdd));
    }
}
