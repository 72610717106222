import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { snakeCase } from 'lodash';

import { InformationUpdateErrorReason, InformationUpdateStateError } from '@malou-io/package-utils';

import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Injectable({
    providedIn: 'root',
})
export class InformationUpdatesStateService {
    private readonly _translateService = inject(TranslateService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);

    displayInformationUpdatesStateError(error: InformationUpdateStateError): string {
        const firstField =
            error.fields && error.fields.length > 0
                ? this._translateService.instant(`platforms.connection.update_information.${snakeCase(error.fields[0])}`)
                : '';
        const allFields = error.fields
            ? error.fields
                  .map((field) => this._translateService.instant(`platforms.connection.update_information.${snakeCase(field)}`))
                  .join(', ')
            : '';

        switch (error.reason) {
            case InformationUpdateErrorReason.ADDRESS_EDIT_CHANGES_COUNTRY:
                return this._translateService.instant('informations.updates_state.errors.address_edit_changes_country');
            case InformationUpdateErrorReason.ADDRESS_MISSING_REGION_CODE:
                return this._translateService.instant('informations.updates_state.errors.address_missing_region_code');
            case InformationUpdateErrorReason.ADDRESS_REMOVAL_NOT_ALLOWED:
                return this._translateService.instant('informations.updates_state.errors.address_removal_not_allowed');
            case InformationUpdateErrorReason.AMBIGUOUS_TITLE:
                return this._translateService.instant('informations.updates_state.errors.ambiguous_title');
            case InformationUpdateErrorReason.ATTRIBUTE_CANNOT_BE_REPEATED:
                return this._translateService.instant('informations.updates_state.errors.attribute_cannot_be_repeated', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.ATTRIBUTE_INVALID_ENUM_VALUE:
                return this._translateService.instant('informations.updates_state.errors.attribute_invalid_enum_value', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.ATTRIBUTE_NOT_AVAILABLE:
                return this._translateService.instant('informations.updates_state.errors.attribute_not_available', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED:
                return this._translateService.instant('informations.updates_state.errors.attribute_provider_url_not_allowed');
            case InformationUpdateErrorReason.ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.attribute_type_not_compatible_for_category', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.BLOCKED_REGION:
                return this._translateService.instant('informations.updates_state.errors.blocked_region');
            case InformationUpdateErrorReason.CANNOT_REOPEN:
                return this._translateService.instant('informations.updates_state.errors.cannot_reopen');
            case InformationUpdateErrorReason.ERROR_CODE_UNSPECIFIED:
                return this._translateService.instant('informations.updates_state.errors.error_code_unspecified');
            case InformationUpdateErrorReason.FIELDS_REQUIRED_FOR_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.fields_required_for_category', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.FORBIDDEN_WORDS:
                return this._translateService.instant('informations.updates_state.errors.forbidden_words', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.incompatible_more_hours_type_for_category');
            case InformationUpdateErrorReason.INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.incompatible_service_area_and_category');
            case InformationUpdateErrorReason.INVALID_ADDRESS:
                return this._translateService.instant('informations.updates_state.errors.invalid_address');
            case InformationUpdateErrorReason.INVALID_AREA_TYPE_FOR_SERVICE_AREA:
                return this._translateService.instant('informations.updates_state.errors.invalid_area_type_for_service_area');
            case InformationUpdateErrorReason.INVALID_ATTRIBUTE_NAME:
                return this._pluralTranslatePipe.transform(
                    'informations.updates_state.errors.invalid_attribute_name',
                    error.fields?.length ?? 0,
                    { allFields }
                );
            case InformationUpdateErrorReason.INVALID_BUSINESS_OPENING_DATE:
                return this._translateService.instant('informations.updates_state.errors.invalid_business_opening_date');
            case InformationUpdateErrorReason.INVALID_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.invalid_category');
            case InformationUpdateErrorReason.INVALID_CHARACTERS:
                return this._translateService.instant('informations.updates_state.errors.invalid_characters', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.INVALID_HOURS_VALUE:
                return this._translateService.instant('informations.updates_state.errors.invalid_hours_value');
            case InformationUpdateErrorReason.INVALID_INTERCHANGE_CHARACTERS:
                return this._translateService.instant('informations.updates_state.errors.invalid_interchange_characters', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.INVALID_LANGUAGE:
                return this._translateService.instant('informations.updates_state.errors.invalid_language');
            case InformationUpdateErrorReason.INVALID_LATLNG:
                return this._translateService.instant('informations.updates_state.errors.invalid_latlng');
            case InformationUpdateErrorReason.INVALID_PHONE_NUMBER:
                return this._translateService.instant('informations.updates_state.errors.invalid_phone_number');
            case InformationUpdateErrorReason.INVALID_PHONE_NUMBER_FOR_REGION:
                return this._translateService.instant('informations.updates_state.errors.invalid_phone_number_for_region');
            case InformationUpdateErrorReason.INVALID_SERVICE_AREA_PLACE_ID:
                return this._translateService.instant('informations.updates_state.errors.invalid_service_area_place_id');
            case InformationUpdateErrorReason.INVALID_SOCIAL_MEDIA_PROFILE_URL:
                return this._translateService.instant('informations.updates_state.errors.invalid_social_media_profile_url');
            case InformationUpdateErrorReason.INVALID_TIME_SCHEDULE:
                return this._translateService.instant('informations.updates_state.errors.invalid_time_schedule');
            case InformationUpdateErrorReason.INVALID_URL:
                return this._translateService.instant('informations.updates_state.errors.invalid_url', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.LAT_LNG_TOO_FAR_FROM_ADDRESS:
                return this._translateService.instant('informations.updates_state.errors.lat_lng_too_far_from_address');
            case InformationUpdateErrorReason.LAT_LNG_UPDATES_NOT_PERMITTED:
                return this._translateService.instant('informations.updates_state.errors.lat_lng_updates_not_permitted');
            case InformationUpdateErrorReason.LINK_ALREADY_EXISTS:
                return this._translateService.instant('informations.updates_state.errors.link_already_exists', { field: firstField });
            case InformationUpdateErrorReason.LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION:
                return this._translateService.instant('informations.updates_state.errors.lodging_cannot_edit_profile_description');
            case InformationUpdateErrorReason.MISSING_ADDRESS_COMPONENTS:
                return this._translateService.instant('informations.updates_state.errors.missing_address_components');
            case InformationUpdateErrorReason.MISSING_BOTH_PHONE_AND_WEBSITE:
                return this._translateService.instant('informations.updates_state.errors.missing_both_phone_and_website');
            case InformationUpdateErrorReason.MISSING_PRIMARY_PHONE_NUMBER:
                return this._translateService.instant('informations.updates_state.errors.missing_primary_phone_number');
            case InformationUpdateErrorReason.MISSING_STOREFRONT_ADDRESS_OR_SAB:
                return this._translateService.instant('informations.updates_state.errors.missing_storefront_address_or_sab');
            case InformationUpdateErrorReason.OPENING_DATE_BEFORE_1AD:
                return this._translateService.instant('informations.updates_state.errors.opening_date_before_1ad');
            case InformationUpdateErrorReason.OPENING_DATE_MISSING_YEAR_OR_MONTH:
                return this._translateService.instant('informations.updates_state.errors.opening_date_missing_year_or_month');
            case InformationUpdateErrorReason.OPENING_DATE_TOO_FAR_IN_THE_FUTURE:
                return this._translateService.instant('informations.updates_state.errors.opening_date_too_far_in_the_future');
            case InformationUpdateErrorReason.OVERLAPPED_SPECIAL_HOURS:
                return this._translateService.instant('informations.updates_state.errors.overlapped_special_hours');
            case InformationUpdateErrorReason.PHONE_NUMBER_EDITS_NOT_ALLOWED:
                return this._translateService.instant('informations.updates_state.errors.phone_number_edits_not_allowed');
            case InformationUpdateErrorReason.PIN_DROP_REQUIRED:
                return this._translateService.instant('informations.updates_state.errors.pin_drop_required');
            case InformationUpdateErrorReason.PO_BOX_IN_ADDRESS_NOT_ALLOWED:
                return this._translateService.instant('informations.updates_state.errors.po_box_in_address_not_allowed');
            case InformationUpdateErrorReason.PRICE_CURRENCY_INVALID:
                return this._translateService.instant('informations.updates_state.errors.price_currency_invalid');
            case InformationUpdateErrorReason.PRICE_CURRENCY_MISSING:
                return this._translateService.instant('informations.updates_state.errors.price_currency_missing');
            case InformationUpdateErrorReason.PROFILE_DESCRIPTION_CONTAINS_URL:
                return this._translateService.instant('informations.updates_state.errors.profile_description_contains_url');
            case InformationUpdateErrorReason.READ_ONLY_ADDRESS_COMPONENTS:
                return this._translateService.instant('informations.updates_state.errors.read_only_address_components');
            case InformationUpdateErrorReason.REQUIRED_FIELD_MISSING_VALUE:
                return this._translateService.instant('informations.updates_state.errors.required_field_missing_value', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY:
                return this._translateService.instant('informations.updates_state.errors.scalable_deep_link_invalid_multiplicity', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.SERVICE_TYPE_ID_DUPLICATE:
                return this._translateService.instant('informations.updates_state.errors.service_type_id_duplicate');
            case InformationUpdateErrorReason.SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS:
                return this._translateService.instant('informations.updates_state.errors.special_hours_set_without_regular_hours');
            case InformationUpdateErrorReason.STALE_DATA:
                return this._translateService.instant('informations.updates_state.errors.stale_data', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.STOREFRONT_REQUIRED_FOR_CATEGORY:
                return this._translateService.instant('informations.updates_state.errors.storefront_required_for_category');
            case InformationUpdateErrorReason.STRING_TOO_LONG:
                return this._translateService.instant('informations.updates_state.errors.string_too_long', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.STRING_TOO_SHORT:
                return this._translateService.instant('informations.updates_state.errors.string_too_short', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.THROTTLED:
                return this._translateService.instant('informations.updates_state.errors.throttled', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.TOO_MANY_ENTRIES:
                return this._translateService.instant('informations.updates_state.errors.too_many_entries', {
                    field: firstField,
                });
            case InformationUpdateErrorReason.TOO_MANY_UPDATES:
                return this._translateService.instant('informations.updates_state.errors.too_many_updates');
            case InformationUpdateErrorReason.UNVERIFIED_LOCATION:
                return this._translateService.instant('informations.updates_state.errors.unverified_location');
            case InformationUpdateErrorReason.GMB_PLATFORM_NOT_VERIFIED:
                return this._translateService.instant('informations.updates_state.errors.unverified_location');
            case InformationUpdateErrorReason.GMB_PLATFORM_DISCONNECTED:
                return this._translateService.instant('informations.updates_state.errors.gmb_platform_disconnected');
            case InformationUpdateErrorReason.GMB_PLATFORM_DISABLED:
                return this._translateService.instant('informations.updates_state.errors.gmb_platform_disabled');
            case InformationUpdateErrorReason.GMB_PLATFORM_SUSPENDED:
                return this._translateService.instant('informations.updates_state.errors.gmb_platform_suspended');
            case InformationUpdateErrorReason.GMB_PLATFORM_PENDING_VERIFICATION:
                return this._translateService.instant('informations.updates_state.errors.gmb_platform_pending_verification');
            // Unhandled errors, default to generic message
            case InformationUpdateErrorReason.ASSOCIATE_LOCATION_INVALID_PLACE_ID:
            case InformationUpdateErrorReason.ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION:
            case InformationUpdateErrorReason.DUPLICATE_CHILDREN_LOCATIONS:
            case InformationUpdateErrorReason.FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID:
            case InformationUpdateErrorReason.FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL:
            case InformationUpdateErrorReason.INVALID_CATEGORY_FOR_SAB:
            case InformationUpdateErrorReason.INVALID_SERVICE_ITEM:
            case InformationUpdateErrorReason.LAT_LNG_REQUIRED:
            case InformationUpdateErrorReason.MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION:
            case InformationUpdateErrorReason.PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF:
            case InformationUpdateErrorReason.RELATION_CANNOT_BE_THE_LOCATION_ITSELF:
            case InformationUpdateErrorReason.RELATION_ENDPOINTS_TOO_FAR:
            case InformationUpdateErrorReason.SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME:
            case InformationUpdateErrorReason.SERVICE_ITEM_LABEL_INVALID_UTF8:
            case InformationUpdateErrorReason.SERVICE_ITEM_LABEL_NO_DISPLAY_NAME:
            case InformationUpdateErrorReason.SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID:
            case InformationUpdateErrorReason.UNSUPPORTED_POINT_RADIUS_SERVICE_AREA:
            case InformationUpdateErrorReason.UNKNOWN:
            default:
                return this._translateService.instant('informations.update_error');
        }
    }
}
