import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';

import { PlatformKey } from '@malou-io/package-utils';

import * as PermissionsActions from './permissions.actions';
import { PermissionsState } from './permissions.interface';

export const initialState: PermissionsState = {
    data: [],
};

const permissionsReducer = createReducer(
    initialState,
    on(PermissionsActions.editPermissionsData, (state, { permissions }) => ({
        data: state.data
            .filter((perms) => perms.key !== permissions?.key)
            .concat(permissions)
            .filter((v) => !!v),
    })),
    on(PermissionsActions.removeFacebookPermissions, (state) => ({
        ...state,
        data: state.data.filter((pl) => pl.key !== PlatformKey.FACEBOOK),
    })),
    on(PermissionsActions.removeInstagramPermissions, (state) => ({
        ...state,
        data: state.data.filter((pl) => pl.key !== PlatformKey.INSTAGRAM),
    })),
    on(PermissionsActions.resetPermissionsData, () => initialState)
);

export const selectPermissionsState = createFeatureSelector<PermissionsState>('permissions');

export function reducer(state: PermissionsState | undefined, action: Action): PermissionsState {
    return permissionsReducer(state, action);
}
