import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { Observable } from 'rxjs';

import { PrivateReviewStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import {
    PrivateReviewCountChartComponent,
    PrivateReviewCountData,
} from ':modules/statistics/boosters/private-review-count/private-review-count-chart/private-review-count-chart.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-statistics-totems-private-review-count',
    templateUrl: './private-review-count.component.html',
    styleUrls: ['./private-review-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        PrivateReviewCountChartComponent,
        MatIconModule,
    ],
    providers: [EnumTranslatePipe],
})
export class PrivateReviewCountComponent implements OnInit {
    @Input() data$: Observable<PrivateReviewStatisticsData>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    hasData = true;

    privateReviewCountData: PrivateReviewCountData;
    privateReviewCountOnPeriod: number | null = null;
    privateReviewCountDifferenceWithPreviousPeriod: number | null = null;

    readonly RATING_BY_TRANSLATION = {
        [this._translateService.instant('statistics.totems.one_star')]: 1,
        [this._translateService.instant('statistics.totems.two_stars')]: 2,
        [this._translateService.instant('statistics.totems.three_stars')]: 3,
        [this._translateService.instant('statistics.totems.four_stars')]: 4,
        [this._translateService.instant('statistics.totems.five_stars')]: 5,
    };

    constructor(
        private readonly _store: Store,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.data$.subscribe(({ nfcs, scans, privateReviewsDto, previousPrivateReviewsDto }) => {
            this._store.dispatch(StatisticsActions.editPrivateReviewData({ data: privateReviewsDto }));
            this.privateReviewCountData = Object.entries(this.RATING_BY_TRANSLATION).map(([translation, rating]) => {
                const privateReviewsFiltered = privateReviewsDto.filter((privateReview) => privateReview.rating === rating);
                const privateReviewsByNfcId = groupBy(privateReviewsFiltered, (value) => {
                    const scan = scans.find((s) => s.id === value.scanId);
                    return scan?.nfcId;
                });
                const privateReviewsCountByNfcName = Object.entries(privateReviewsByNfcId).reduce((acc, [nfcId, privateReviews]) => {
                    const nfc = nfcs.find((n) => n.id === nfcId);
                    const key = nfc?.name ?? nfc?.chipName;
                    return key
                        ? {
                              ...acc,
                              [key]: privateReviews.length,
                          }
                        : acc;
                }, {});
                return {
                    translation,
                    rating,
                    value: privateReviewsFiltered.length,
                    details: privateReviewsCountByNfcName,
                };
            });
            this.privateReviewCountOnPeriod = privateReviewsDto.length;
            this.privateReviewCountDifferenceWithPreviousPeriod = this.privateReviewCountOnPeriod - previousPrivateReviewsDto.length;
            this.hasData = this.privateReviewCountOnPeriod > 0;
            this.hasDataChange.emit(this.privateReviewCountOnPeriod > 0);
        });
    }
}
