import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'threeDigitNumber',
    standalone: true,
})
export class ThreeDigitNumberPipe implements PipeTransform {
    transform(number: number): string {
        if (number < 0) {
            console.warn('Tried to use negative numbers with ThreeDigitNumber Pipe. Use positive integers only.');
            return '-';
        }
        if (number >= 1000) {
            return '999+';
        }
        return `${number}`;
    }
}
