import { Action, createReducer, on } from '@ngrx/store';

import { Keyword } from ':shared/models';

import * as KeywordsActions from './keywords.actions';

export interface KeywordsState {
    keywordsByRestaurantId: { [restaurantId: string]: Keyword[] };
    selectedRestaurantId: string | null;
}

export const initialState: KeywordsState = {
    keywordsByRestaurantId: {},
    selectedRestaurantId: null,
};

const keywordsReducer = createReducer(
    initialState,
    on(KeywordsActions.editSelectedRestaurantId, (state, { restaurantId }) => ({
        ...state,
        selectedRestaurantId: restaurantId,
    })),
    on(KeywordsActions.editKeywordsByRestaurantId, (state, { restaurantId, keywords }: { restaurantId: string; keywords: Keyword[] }) => {
        const keywordsByRestaurantId = Object.assign({}, state.keywordsByRestaurantId, { [restaurantId]: keywords });
        return { ...state, keywordsByRestaurantId };
    })
);

export function reducer(state: KeywordsState | undefined, action: Action): KeywordsState {
    return keywordsReducer(state, action);
}
