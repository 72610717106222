import { AnimationEvent } from '@angular/animations';
import { NgComponentOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    signal,
    Type,
    ViewChild,
    ViewContainerRef,
    WritableSignal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { appearDisappearFromBottomAnimation } from ':shared/animations/appear-disappear-from-bottom.animation';
import { FooterPopinService } from ':shared/components/footer-popin/footer-popin.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-footer-popin',
    templateUrl: './footer-popin.component.html',
    styleUrls: ['./footer-popin.component.scss'],
    standalone: true,
    imports: [NgComponentOutlet, MatIconModule],
    animations: [appearDisappearFromBottomAnimation.animation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterPopinComponent implements AfterViewInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild('dynamicTemplate', { read: ViewContainerRef }) dynamicTemplate: ViewContainerRef;

    readonly appearDisappearFromBottomAnimation = appearDisappearFromBottomAnimation;
    currentData: {
        componentRef?: ComponentRef<any>;
        id?: string;
    } = {};
    isOpen: WritableSignal<boolean> = signal(false);
    isHidden: WritableSignal<boolean> = signal(true);

    constructor(
        private readonly _footerPopinService: FooterPopinService,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this._startListeningToStore();
    }

    onCrossClick(): void {
        this.isOpen.set(false);
    }

    onAnimationDone(event: AnimationEvent): void {
        if (event.toState === appearDisappearFromBottomAnimation.closedState) {
            this._unloadComponent();
            this.isHidden.set(true);
        }
    }

    private _startListeningToStore(): void {
        this._footerPopinService.getOpenEvent$().subscribe((event) => this._onOpen(event.componentType, event.inputs, event.id));
        this._footerPopinService.getCloseEvent$().subscribe((event) => this._onClose(event));
        this._footerPopinService.getUpdateInputEvent$().subscribe((inputs) => this._onUpdateInputs(inputs));
    }

    private _onOpen(componentType: Type<any>, inputs: Record<string, unknown>, id: string): void {
        this._unloadComponent();
        this.isHidden.set(false);
        this.currentData.id = id;
        this.currentData.componentRef = this._loadComponent(componentType);
        this._setInputs(this.currentData.componentRef, inputs);
        this.isOpen.set(true);
        this._changeDetectorRef.detectChanges();
    }

    private _onClose(id: string): void {
        if (this.currentData.id === id) {
            this.isOpen.set(false);
        }
    }

    private _onUpdateInputs(inputs: Record<string, unknown>): void {
        this._setInputs(this.currentData.componentRef, inputs);
    }

    private _setInputs(componentRef: ComponentRef<any> | undefined, inputs: Record<string, unknown>): void {
        Object.entries(inputs).forEach(([key, value]) => {
            componentRef?.setInput(key, value);
        });
    }

    private _loadComponent(component: Type<any>): ComponentRef<any> {
        return this.dynamicTemplate.createComponent(component);
    }
    private _unloadComponent(): void {
        this.dynamicTemplate.clear();
        this.currentData = {};
    }
}
