import { PartialRecord, PlatformDataFetchedStatus, PlatformKey } from '@malou-io/package-utils';

import { PendingReviewReply } from ':modules/reviews/answer-review-container/answer-review.interface';
import { ReviewsDisplayMode } from ':modules/reviews/store/reviews.reducer';
import { FetchedState, Pagination, PrivateReview, Review, ReviewsFilters } from ':shared/models';

export enum SynchronizationStatus {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
    NOT_STARTED = 'NOT_STARTED',
}

export interface ReviewsState {
    filters: ReviewsFilters;
    selectablePlatforms: PlatformKey[];
    reviews: (Review | PrivateReview)[];

    /**
     * An estimation of the number of reviews matching the filters across all
     * the pages. This value can be quite expensive to compute so it makes sense
     * to “cache” it and it should not be considered exact.
     *
     * This field is `undefined` while the count is being computed.
     */
    estimatedReviewCount?: number;

    /** true when the last page has been loaded */
    hasLoadedAllReviews: boolean;

    replies: Record<string, PendingReviewReply>;
    currentReplyReviewId?: string;
    fetchStates: PartialRecord<PlatformKey, FetchedState<PlatformDataFetchedStatus>>;
    pagination: Pagination;
    isFooterVisible?: boolean;
    synchronizationStatus?: SynchronizationStatus;
    unansweredReviewCount: number;
    currentView?: ReviewsDisplayMode;
    couldNotGetPageNumberFromReviewId?: {
        error: string | null;
        timestamp: number;
    };
    semanticAnalysisToggle: boolean;
    isAggregatedReviewsFiltersLoaded: boolean;
    isRestaurantReviewsFiltersLoaded: boolean;
}
