@if (sticker()) {
    <div class="h-52 w-36" #stickerContainer>
        <ng-container [ngTemplateOutlet]="stickerTemplate"> </ng-container>
    </div>
}

<ng-template #stickerTemplate>
    @let isFrench = lang === ApplicationLanguage.FR;
    <div class="z-50">
        <img
            class="w-full rounded-xl bg-malou-color-background-white"
            [src]="(isFrench ? 'sticker_fr' : 'sticker_en') | imagePathResolver: { folder: 'stickers', extensionFormat: 'svg' }" />
        <div class="relative top-[-150px] flex justify-center">
            <img class="h-[110px] w-[110px] rounded-[10px]" alt="qr-code" [ngClass]="{ 'mt-2': isFrench }" [src]="qrCodeImage()" />
        </div>
    </div>
</ng-template>
