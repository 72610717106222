import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

// ------------------------------------------------------------------------------------------

export const handleRefreshMentionParamsValidator = z
    .object({
        mention_id: objectIdValidator,
    })
    .transform((data) => {
        return {
            mentionId: data.mention_id,
        };
    });

export type HandleRefreshMentionParamsDto = z.infer<typeof handleRefreshMentionParamsValidator>;

// ------------------------------------------------------------------------------------------

export const handleReplyMentionBodyValidator = z.object({
    text: z.string(),
    author: z.object({
        name: z.string(),
        picture: z.string().optional(),
    }),
    socialCreatedAt: z.coerce.date(),
});

export type HandleReplyMentionBodyDto = z.infer<typeof handleReplyMentionBodyValidator>;
