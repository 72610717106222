import { BricksCategory } from '@malou-io/package-utils';

import { GenerationFormWithRestaurantId } from ':modules/generator/store/generation-forms.interface';

import { Restaurant } from '../models';

export const buildGenerationForm = (restaurant: Restaurant, restaurantBricks): GenerationFormWithRestaurantId => ({
    restaurantId: restaurant._id,
    categoryList: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_CATEGORY),
    touristics: restaurantBricks.filter((b) => [BricksCategory.TOURISTIC_AREA, BricksCategory.STATION].includes(b.category)),
    offers: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_OFFER),
    equipment: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_EQUIPMENT),
    specials: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_SPECIAL),
    attributes: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_ATTRIBUTE),
    audience: restaurantBricks.filter((b) => b.category === BricksCategory.VENUE_AUDIENCE),
    postalCode: restaurant.address?.postalCode,
    apiLocationId: restaurant.keywordToolApiLocationId,
});
