@if (!isParentError) {
    @if (!isParentLoading) {
        @if (hasData()) {
            <ng-container [ngTemplateOutlet]="kpisTemplate"> </ng-container>
        }
    } @else {
        <div class="flex gap-4 md:flex-col">
            <div class="min-w-0 flex-1">
                <app-skeleton skeletonClass="secondary-bg h-[80px] w-full"></app-skeleton>
            </div>
            <div class="min-w-0 flex-1">
                <app-skeleton skeletonClass="secondary-bg h-[80px] w-full"></app-skeleton>
            </div>
        </div>
    }
} @else {
    <div class="flex h-[80] w-full flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #kpisTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="min-w-0 flex-1">
            <ng-container
                [ngTemplateOutlet]="cardTemplate"
                [ngTemplateOutletContext]="{
                    currentValue: totalGiftsData().totalWinningGifts | shortNumber,
                    currentValueRaw: totalGiftsData().totalWinningGifts,
                    diffValue: totalGiftsData().totalWinningGiftsDifferenceWithPreviousPeriod,
                    diffDisplayedValue:
                        totalGiftsData().totalWinningGiftsDifferenceWithPreviousPeriod | shortNumber: { shouldDisplayMinusSign: false },
                    title: 'aggregated_statistics.boosters.wheel_of_fortune.gifts_kpis.winning' | translate,
                    icon: 'medal',
                }">
            </ng-container>
        </div>
        <div class="min-w-0 flex-1">
            <ng-container
                [ngTemplateOutlet]="cardTemplate"
                [ngTemplateOutletContext]="{
                    currentValue: totalGiftsData().totalRetrievedGifts | shortNumber,
                    currentValueRaw: totalGiftsData().totalRetrievedGifts,
                    diffValue: totalGiftsData().totalRetrievedGiftsDifferenceWithPreviousPeriod,
                    diffDisplayedValue:
                        totalGiftsData().totalRetrievedGiftsDifferenceWithPreviousPeriod | shortNumber: { shouldDisplayMinusSign: false },
                    title: 'aggregated_statistics.boosters.wheel_of_fortune.gifts_kpis.retrieved' | translate,
                    icon: 'gift',
                }">
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template
    let-currentValue="currentValue"
    let-currentValueRaw="currentValueRaw"
    let-diffValue="diffValue"
    let-diffDisplayedValue="diffDisplayedValue"
    let-title="title"
    let-icon="icon"
    #cardTemplate>
    <div
        class="malou-simple-card flex h-20 flex-1 items-center justify-between gap-4 px-8 py-2 xl:h-fit xl:flex-row xl:items-center xl:justify-start">
        <div class="flex items-end xl:items-center">
            <ng-container [ngTemplateOutlet]="gradientCircleTemplate" [ngTemplateOutletContext]="{ icon }"></ng-container>
            <div class="ml-4 flex items-baseline xl:grow xl:flex-col">
                <div class="flex items-center xl:justify-between">
                    <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none">{{ currentValue }}</span>
                </div>
                <span class="malou-text-12--medium malou-color-text-2 mb-1 whitespace-nowrap">{{ title }}</span>
            </div>
        </div>
        @if (currentValueRaw) {
            <app-number-evolution [value]="diffValue" [displayedValue]="diffDisplayedValue"></app-number-evolution>
        }
    </div>
</ng-template>

<ng-template let-icon="icon" #gradientCircleTemplate>
    <div class="malou-background-gradient-5 flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full">
        <mat-icon class="malou-color-white !h-4 !w-4" [svgIcon]="icon"></mat-icon>
    </div>
</ng-template>
