import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformSearch } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SearchPlatformRestaurantsService {
    private readonly _platformsService = inject(PlatformsService);
    private readonly _restaurantsService = inject(RestaurantsService);

    execute(platformKey: PlatformKey, credentialId?: string, socialId?: string): Observable<PlatformSearch[]> {
        return this._platformsService
            .getSocialIds({
                restaurantId: this._restaurantsService.currentRestaurant._id,
                platformKey,
                credentialId,
                socialId,
            })
            .pipe(map((res) => res.list));
    }
}
