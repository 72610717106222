import { MediaService } from ':modules/media/media.service';

export interface MediaMetadata {
    height: number;
    width: number;
    duration?: number;
}

interface MediaAnalyzer {
    getMetadata(url: string): Promise<MediaMetadata>;
}

export class VideoMediaAnalyzer implements MediaAnalyzer {
    constructor(private readonly _mediaService?: MediaService) {}

    getMetadata(url: string): Promise<MediaMetadata> {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');

            video.addEventListener(
                'loadedmetadata',
                function () {
                    const height = this.videoHeight;
                    const width = this.videoWidth;
                    const duration = this.duration;

                    resolve({ height, width, duration });
                },
                false
            );
            video.addEventListener(
                'error',
                () => {
                    if (!this._mediaService) {
                        reject('Media service is not available');
                        return;
                    }
                    this._mediaService.getVideoInformation({ url: url }).subscribe((videoInformation) => {
                        resolve({
                            height: videoInformation.data.height,
                            width: videoInformation.data.width,
                            duration: videoInformation.data.durationInSeconds,
                        });
                    });
                },
                false
            );
            video.src = url;
        });
    }
}

export class ImageMediaAnalyzer implements MediaAnalyzer {
    getMetadata(url: string): Promise<MediaMetadata> {
        return new Promise((resolve) => {
            const image = document.createElement('img');

            image.addEventListener(
                'load',
                function () {
                    const height = this.height;
                    const width = this.width;

                    resolve({ height, width });
                },
                false
            );
            image.src = url;
        });
    }
}
