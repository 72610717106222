import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, ReactiveFormsModule, TranslateModule, InputTextComponent],
})
export class ChangePasswordComponent {
    readonly SvgIcon = SvgIcon;
    passwordFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);

    constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>) {}

    close(): void {
        this.dialogRef.close();
    }

    submit(): void {
        this.dialogRef.close(this.passwordFormControl.value);
    }
}
