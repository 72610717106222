import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'concat',
    pure: true,
    standalone: true,
})
export class ConcatPipe implements PipeTransform {
    transform<T>(arr1: T[], arr2: T[]): T[] {
        return arr1.concat(arr2);
    }
}
