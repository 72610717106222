import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDnd]',
    standalone: true,
})
export class DndDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() fileDropped: EventEmitter<Event> = new EventEmitter();

    // Dragover listener
    // Removed because was firing change detection and causing performance issue
    // Added ondragover="event.stopPropagation(); event.preventDefault()" to the html element
    // @HostListener('dragover', ['$event']) onDragOver(evt) {
    //     // evt.preventDefault();
    //     // evt.stopPropagation();
    //     // this.fileOver = true;
    // }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
