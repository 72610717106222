import { Routes } from '@angular/router';

import { ReviewTemplatesComponent } from './review-templates.component';

export const REVIEW_TEMPLATES_ROUTES: Routes = [
    {
        path: '',
        component: ReviewTemplatesComponent,
    },
];
