@if (!isLoading()) {
    @if (!httpError) {
        <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
            <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
        </div>
    } @else {
        <div class="malou-simple-card flex h-[400px] flex-col items-center justify-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
            <span class="malou-text-10--regular">
                {{ 'aggregated_statistics.errors.error_occurred_try_again' | translate }}
            </span>
            <span class="malou-text-10--regular">{{ httpError | aggregatedStatisticsHttpError }}</span>
        </div>
    }
} @else {
    <app-skeleton skeletonClass="!h-[539px] secondary-bg"></app-skeleton>
}

<ng-template #titleTemplate>
    <div class="flex grow flex-wrap items-center justify-between gap-x-2">
        <div class="flex items-center gap-x-2 pt-2">
            <div class="malou-text-section-title malou-color-text-1">
                {{ 'aggregated_statistics.e_reputation.review_ratings_average.average_rating_of_reviews_received' | translate }}
            </div>
            @if (warningTooltip) {
                <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
            @if (errorTooltip) {
                <div class="malou-status--error pdf-hidden" [matTooltip]="errorTooltip">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
        </div>

        <div class="flex gap-x-2">
            @if (!showSortByTextInsteadOfSelector && viewModeSelected === StatisticsDataViewMode.CHART) {
                <div class="flex items-center gap-2">
                    <span class="malou-text-14--medium md:hidden">{{ 'common.sort' | translate }}</span>
                    <app-select
                        [values]="SORT_BY_VALUES"
                        [displayWith]="sortByDisplayWith"
                        [formControl]="chartSortByControl"
                        (selectChange)="onChartSortByChange($event)"></app-select>
                </div>
            }

            @if (showSortByTextInsteadOfSelector && viewModeSelected === StatisticsDataViewMode.CHART) {
                <div class="flex items-center gap-2">
                    <span class="malou-text-14--regular italic">
                        {{ 'common.sorted_by_order' | translate }}:
                        {{ sortByDisplayWith | applyPure: chartSortByControl.value | lowercase }}
                    </span>
                </div>
            }

            @if (showViewModeToggle) {
                <div>
                    <mat-button-toggle-group
                        class="malou-group-toggle-btn"
                        [value]="viewModeSelected"
                        (change)="onToggleChange($event.value)">
                        <mat-button-toggle [value]="StatisticsDataViewMode.CHART">
                            <mat-icon [svgIcon]="SvgIcon.AGGREGATED_STATS"></mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle [value]="StatisticsDataViewMode.TABLE">
                            <mat-icon [svgIcon]="SvgIcon.LIST"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    @if (viewModeSelected === StatisticsDataViewMode.CHART) {
        <div class="h-[350px]">
            <app-reviews-ratings-average-chart
                [reviewsRatingsAverageData]="reviewsRatingsAverageData"
                [previousReviewsRatingsAverageData]="previousReviewsRatingsAverageData"
                [sortBy]="chartSortByControl.value">
            </app-reviews-ratings-average-chart>
        </div>
    }
    @if (viewModeSelected === StatisticsDataViewMode.TABLE) {
        <div [ngClass]="{ 'h-[350px]': !expandTable }">
            <app-reviews-ratings-average-table
                [sortBy]="tableSort"
                [data]="reviewsRatingsAverageData"
                [previousData]="previousReviewsRatingsAverageData"
                (sortChange)="onTableSortChange($event)">
            </app-reviews-ratings-average-table>
        </div>
    }
</ng-template>

<ng-template #infoTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">
                {{ totalAverageRating | shortNumber }}
            </div>
            @if (totalAverageRating) {
                <app-number-evolution
                    [value]="totalAverageRatingEvolution"
                    [displayedValue]="totalAverageRatingEvolution | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
            {{ 'aggregated_statistics.e_reputation.review_ratings_average.average_rating_of_reviews_received' | translate }}
        </div>
    </div>
</ng-template>
