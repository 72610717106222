import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { orderBy, round } from 'lodash';
import { Observable } from 'rxjs';

import { GIFT_PERCENTAGE_DECIMALS } from ':core/constants';
import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum WheelOfFortuneGiftsDistributionTableFieldName {
    GIFT = 'gift',
    WINNING = 'winning',
    RETRIEVED = 'retrieved',
    PERCENTAGE = 'percentage',
}

interface WheelOfFortuneGiftsDistributionTableData {
    giftName: string;
    giftDrawCount: number;
    giftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedGiftDrawCount: number;
    retrievedGiftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedPercentage: number;
    isFirst: boolean;
    isSecond: boolean;
    isThird: boolean;
}

@Component({
    selector: 'app-wheel-of-fortune-gifts-distribution',
    templateUrl: './wheel-of-fortune-gifts-distribution.component.html',
    styleUrls: ['./wheel-of-fortune-gifts-distribution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTableModule,
        MatSortModule,
        TranslateModule,
        NumberEvolutionComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
        NumberEvolutionComponent,
        ApplyPurePipe,
        SkeletonComponent,
        ImagePathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGiftsDistributionComponent implements OnInit {
    @Input() tableSortBy?: Sort;
    @Input() data$: Observable<WheelOfFortuneGiftsStatisticsData>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly TableFieldName = WheelOfFortuneGiftsDistributionTableFieldName;

    readonly displayedColumns = Object.values(WheelOfFortuneGiftsDistributionTableFieldName);
    dataSource = new MatTableDataSource<WheelOfFortuneGiftsDistributionTableData>([]);
    defaultSort: Sort = {
        active: WheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED,
        direction: ChartSortBy.DESC,
    };

    hasData: WritableSignal<boolean> = signal(true);

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case WheelOfFortuneGiftsDistributionTableFieldName.GIFT:
                        return item.giftName;
                    case WheelOfFortuneGiftsDistributionTableFieldName.WINNING:
                        return item.giftDrawCount ?? -1;
                    case WheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED:
                        return item.retrievedGiftDrawCount ?? -1;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        if (this.tableSortBy) {
            this.defaultSort = this.tableSortBy;
        }
        this.data$.subscribe(({ giftsInsightsPerGift }) => {
            this.hasData.set(giftsInsightsPerGift.length > 0);
            this.hasDataChange.emit(giftsInsightsPerGift.length > 0);
            const sortedGiftsInsights = orderBy(giftsInsightsPerGift, 'retrievedGiftDrawCount', ChartSortBy.DESC);
            this.dataSource.data = sortedGiftsInsights.map((giftInsight, index) => ({
                giftName: giftInsight.gift,
                giftDrawCount: giftInsight.giftDrawCount ?? 0,
                giftDrawCountDifferenceWithPreviousPeriod: (giftInsight.giftDrawCount ?? 0) - (giftInsight.previousGiftDrawCount ?? 0),
                retrievedGiftDrawCount: giftInsight.retrievedGiftDrawCount ?? 0,
                retrievedGiftDrawCountDifferenceWithPreviousPeriod:
                    (giftInsight.retrievedGiftDrawCount ?? 0) - (giftInsight.previousRetrievedGiftDrawCount ?? 0),
                retrievedPercentage: giftInsight.giftDrawCount
                    ? round((giftInsight.retrievedGiftDrawCount * 100) / giftInsight.giftDrawCount, GIFT_PERCENTAGE_DECIMALS)
                    : 0,
                isFirst: index === 0 && giftInsight.retrievedGiftDrawCount !== 0,
                isSecond: index === 1 && giftInsight.retrievedGiftDrawCount !== 0,
                isThird: index === 2 && giftInsight.retrievedGiftDrawCount !== 0,
            }));
        });
    }
}
