<div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
    <div>
        <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
    </div>
    @if (isParentLoading() || isChartLoading()) {
        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
    } @else {
        @if (chartError().noDataError) {
            <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        }
    }
</div>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="!h-[350px] secondary-bg"></app-skeleton>
</ng-template>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-baseline gap-1">
            <span class="malou-text-section-title malou-color-text-1">{{ 'roi.aggregated_performance.title' | translate }}</span>
            @if (selectedViewMode === StatisticsDataViewMode.CHART) {
                @if (
                    performanceChartData().length > MAX_RESTAURANTS_TO_SHOW_CUSTOM_LABEL && chartError().restaurantsWithoutData.length > 0
                ) {
                    <mat-icon
                        class="malou-bg-state-warn malou-color-white malou-border-color-warn relative top-0.5 !h-3.5 !w-3.5 rounded-full border-2"
                        [svgIcon]="SvgIcon.EXCLAMATION_MARK"
                        [matTooltipPosition]="'below'"
                        [matTooltip]="
                            'roi.aggregated_performance.errors.multiple.no_data'
                                | translate: { restaurants: chartError().restaurantsWithoutData.join(' , ') }
                        "></mat-icon>
                }
            }
        </div>

        <div class="flex items-center gap-2">
            @if (selectedViewMode === StatisticsDataViewMode.CHART) {
                <span class="malou-text-14--medium md:hidden">{{ 'common.sort_by' | translate }}</span>
                <app-select
                    [values]="SORT_BY_VALUES"
                    [displayWith]="sortByDisplayWith"
                    [formControl]="sortByControl"
                    (selectChange)="onSortByChange($event)"></app-select>
            }

            <div>
                <mat-button-toggle-group
                    class="malou-group-toggle-btn"
                    [value]="selectedViewMode"
                    (change)="onViewModeChange($event.value)">
                    <mat-button-toggle [value]="StatisticsDataViewMode.CHART">
                        <mat-icon [svgIcon]="SvgIcon.AGGREGATED_STATS"></mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="StatisticsDataViewMode.TABLE">
                        <mat-icon [svgIcon]="SvgIcon.LIST"></mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #chartTemplate>
    @if (selectedViewMode === StatisticsDataViewMode.CHART) {
        <app-aggregated-performance-chart
            [performanceChartData]="performanceChartData()"
            [showCustomChartLabel]="
                !screenSizeService.isPhoneScreen && performanceChartData().length <= MAX_RESTAURANTS_TO_SHOW_CUSTOM_LABEL
            "></app-aggregated-performance-chart>
    } @else {
        <div class="h-[420px]">
            <app-aggregated-performance-table [performanceKpisTableData]="performanceKpisTableData()"></app-aggregated-performance-table>
        </div>
    }
</ng-template>

<ng-template #noDataTemplate>
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <div class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</div>
    </div>
</ng-template>
