import { Injectable } from '@angular/core';
import { map, Observable, Subscriber } from 'rxjs';

import { PictureSize, PostType } from '@malou-io/package-utils';

import { MediaService } from ':modules/media/media.service';
import { Media } from ':shared/models';
import { VideoMediaAnalyzer } from ':shared/models/media-analyzer';
import { InstagramMediaErrors, MediaProperties } from ':shared/models/media-errors';

export interface MediaErrorData {
    dimensionsAreValid: boolean;
    properties: MediaProperties;
}

@Injectable({
    providedIn: 'root',
})
export class MediaDimensionsService {
    constructor(private readonly _mediaService: MediaService) {}

    areMediaDimensionsValid$({ medium, isReel }: { medium: Media; isReel: boolean; mediaIndex?: number }): Observable<MediaErrorData> {
        return this.getMediaProperties$({ medium, isReel, fit: PictureSize.ORIGINAL }).pipe(
            map((properties) => {
                const instagramReelErrors = new InstagramMediaErrors(properties);
                if (isReel) {
                    return { dimensionsAreValid: instagramReelErrors.areReelDimensionsValid(), properties: properties };
                }
                if (medium.isVideo()) {
                    return { dimensionsAreValid: instagramReelErrors.getVideoErrors().length === 0, properties: properties };
                }
                return {
                    dimensionsAreValid: instagramReelErrors.getImageErrors().length === 0,
                    properties: properties,
                };
            })
        );
    }

    getMediaProperties$({
        medium,
        isReel,
        fit = PictureSize.IG_FIT,
    }: {
        medium: Media;
        isReel: boolean;
        fit?: PictureSize;
    }): Observable<MediaProperties> {
        return new Observable((observer) => {
            if (medium.isVideo()) {
                this._emitVideoProperties$({ medium, observer, isReel });
            } else {
                this._emitImageProperties$({ medium, observer, fit });
            }
        });
    }

    private _emitImageProperties$({
        medium,
        observer,
        fit,
    }: {
        medium: Media;
        observer: Subscriber<MediaProperties>;
        fit: PictureSize;
    }): void {
        const image = new Image();
        image.onload = (): void => {
            const width = image.width,
                height = image.height;

            observer.next({
                width,
                height,
                bytes: medium.getBytesForSize(fit),
                type: PostType.IMAGE,
            });
            observer.complete();
        };
        image.onerror = (err): void => {
            observer.error(err);
        };
        image.src = medium.getMediaUrl(fit);
    }

    private async _emitVideoProperties$({
        medium,
        observer,
        isReel,
    }: {
        medium: Media;
        observer: Subscriber<MediaProperties>;
        isReel: boolean;
    }): Promise<void> {
        const properties: MediaProperties = {
            width: medium.dimensions?.original?.width ?? 0,
            height: medium.dimensions?.original?.height ?? 0,
            duration: medium.duration ?? 0,
            bytes: medium.getBytesForSize(PictureSize.IG_FIT),
            type: isReel ? PostType.REEL : PostType.VIDEO,
        };
        if (properties.width && properties.height && properties.duration) {
            observer.next(properties);
            observer.complete();
            return;
        }
        const url = medium.getMediaUrl(PictureSize.IG_FIT);
        const videoMediaAnalyzer = new VideoMediaAnalyzer(this._mediaService);
        const metadata = await videoMediaAnalyzer.getMetadata(url);
        observer.next({
            ...properties,
            ...metadata,
        });
        observer.complete();
    }
}
