import { EntityConstructor } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface RoiActivatedNotificationData {
    restaurantIds: string[];
}

type RoiActivatedNotificationProps = EntityConstructor<RoiActivatedNotification>;

export class RoiActivatedNotification extends Notification {
    data: RoiActivatedNotificationData;

    constructor(props: RoiActivatedNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<RoiActivatedNotificationProps>): RoiActivatedNotification {
        return new RoiActivatedNotification({ ...this, ...props });
    }
}
