import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-gift-out-of-stock-modal',
    templateUrl: './gift-out-of-stock-modal.component.html',
    styleUrls: ['./gift-out-of-stock-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatCheckboxModule, MatIconModule, TranslateModule, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftOutOfStockModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;

    constructor(
        private readonly _dialogRef: MatDialogRef<GiftOutOfStockModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            giftName: string;
        }
    ) {}

    close(shouldRedirect: boolean = false): void {
        this._dialogRef.close({ shouldRedirect });
    }
}
