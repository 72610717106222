import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GiftDrawDto, GiftDrawInsightPerGiftDto, GiftDrawInsightPerRestaurantDto, GiftDrawPopulatedDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { objectToQueryParams } from ':shared/helpers/query-params';

@Injectable({
    providedIn: 'root',
})
export class GiftDrawsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/gift-draws`;

    constructor(private readonly _http: HttpClient) {}

    getInsightsByRestaurants(
        restaurantIds: string[],
        startDate: Date,
        endDate: Date
    ): Observable<ApiResultV2<GiftDrawInsightPerRestaurantDto[]>> {
        const params = objectToQueryParams({ restaurantIds, startDate, endDate });
        return this._http.get<ApiResultV2<GiftDrawInsightPerRestaurantDto[]>>(`${this.API_BASE_URL}/insights-per-restaurant`, { params });
    }

    getInsightsByGifts(restaurantId: string, startDate: Date, endDate: Date): Observable<ApiResultV2<GiftDrawInsightPerGiftDto[]>> {
        const params = objectToQueryParams({ startDate, endDate });
        return this._http.get<ApiResultV2<GiftDrawInsightPerGiftDto[]>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/insights-per-gift`,
            {
                params,
            }
        );
    }

    drawGift(restaurantId: string, wheelOfFortuneId: string, lang: string, clientId: string): Observable<ApiResultV2<GiftDrawDto>> {
        return this._http.post<ApiResultV2<GiftDrawDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/wheels-of-fortune/${wheelOfFortuneId}`,
            { lang, clientId },
            {
                withCredentials: true,
            }
        );
    }

    getGiftDrawById(giftDrawId: string): Observable<ApiResultV2<GiftDrawPopulatedDto>> {
        return this._http.get<ApiResultV2<GiftDrawPopulatedDto>>(`${this.API_BASE_URL}/${giftDrawId}`);
    }

    setGiftRetrieved(giftDrawId: string): Observable<ApiResultV2<GiftDrawPopulatedDto>> {
        return this._http.get<ApiResultV2<GiftDrawPopulatedDto>>(`${this.API_BASE_URL}/${giftDrawId}/gift-retrieved`);
    }

    assignClient(giftDrawId: string, clientId: string): Observable<ApiResultV2<GiftDrawDto>> {
        return this._http.put<ApiResultV2<GiftDrawDto>>(`${this.API_BASE_URL}/${giftDrawId}/clients/${clientId}`, {});
    }

    sendRetrievalEmail(giftDrawId: string): Observable<ApiResultV2<GiftDrawDto>> {
        return this._http.get<ApiResultV2<GiftDrawDto>>(`${this.API_BASE_URL}/${giftDrawId}/send-retrieval-email`);
    }

    cancelDraw(giftDrawId: string): Observable<ApiResultV2<GiftDrawDto>> {
        return this._http.get<ApiResultV2<GiftDrawDto>>(`${this.API_BASE_URL}/${giftDrawId}/cancel-draw`);
    }
}
