export type Rgb = [number, number, number];

export const hexToRgb = function (hex: string): Rgb {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        throw new Error(`Invalid hex color: ${hex}`);
    }
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
};

export const rgbToHex = function (rgb: Rgb): string {
    // eslint-disable-next-line no-bitwise
    return '#' + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
};

export const singleColorRgbToHex = function (value: number): string {
    const hex = Math.round(value).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

export const rgbaToHex = function (color: string): string {
    if (color.match(/#/)) {
        return color;
    }

    const isRGBA = color.indexOf('rgba') !== -1;

    const [r, g, b, a] = isRGBA
        ? color
              .replace(/[rgba()]/g, '')
              .split(',')
              .map((value) => parseFloat(value))
        : color
              .replace(/[rgb()]/g, '')
              .split(',')
              .map((value) => parseFloat(value));

    const hexColor = '#' + singleColorRgbToHex(r) + singleColorRgbToHex(g) + singleColorRgbToHex(b);

    return isRGBA ? hexColor + singleColorRgbToHex(a * 255) : hexColor;
};

export const interpolateColor = function (color1: Rgb, color2: Rgb, factor: number = 0.5): Rgb {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result as Rgb;
};

export const addOpacityToColor = function (color: string, opacity = 0.95): string {
    if (color.includes('#')) {
        const red = parseInt(color.slice(1, 3), 16);
        const green = parseInt(color.slice(3, 5), 16);
        const blue = parseInt(color.slice(5, 7), 16);
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }
    return color;
};
