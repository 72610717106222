<ng-container
    class="min-h-52"
    [ngTemplateOutlet]="isSavedTimeLoading() || isParentLoading() ? loadingTemplate : savedTimeTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg px-4 py-6 flex flex-col gap-2 h-52">
        <app-skeleton class="w-[250px] sm:hidden"></app-skeleton>
        <div class="flex h-full items-center gap-4 sm:hidden">
            <app-skeleton appearance="circle" skeletonClass="h-[98px] w-[98px] rounded-full"></app-skeleton>
            <div class="flex flex-col gap-2">
                <app-skeleton class="h-[30px] w-[170px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[170px]"></app-skeleton>
            </div>
        </div>
    </app-skeleton>
</ng-template>

<ng-template #savedTimeTemplate>
    <div class="malou-simple-card roi-container flex h-full flex-col gap-3 px-4 py-6">
        @if (!isBeforeLimitDateToShowRoi()) {
            <div class="flex items-center">
                <span class="malou-text-14--bold malou-color-text-1 mr-2">{{ 'roi.saved_time.time_saved' | translate }}</span>
            </div>
        }
        <div class="flex h-full items-center gap-2 sm:justify-center">
            <div class="my-3 mr-3 flex gap-2 sm:justify-center">
                <div
                    class="h-24 w-24 rounded-full bg-malou-color-background-dark p-5 sm:hidden"
                    [ngClass]="{
                        'mr-4': isBeforeLimitDateToShowRoi(),
                    }">
                    <img class="h-14 w-fit sm:mb-5" [src]="'hourglass' | emojiPathResolver" />
                </div>
                <div class="flex flex-col items-start">
                    @if (isBeforeLimitDateToShowRoi()) {
                        <div class="flex items-center">
                            <span class="malou-text-14--bold malou-color-text-1 mr-2">{{ 'roi.saved_time.time_saved' | translate }}</span>
                        </div>
                    }
                    <div class="flex items-center gap-3">
                        <span class="malou-text-32--bold malou-color-text-1">
                            @if (roiSavedTime()?.totalTime) {
                                ~ {{ roiSavedTime()!.totalTime | millisecondsToHourMinutePipe: { round: true } }}
                            } @else {
                                0h
                            }
                        </span>
                    </div>
                    <span class="malou-text-10 malou-color-text-2">{{ 'roi.saved_time.saved_hours_thanks_to_malou' | translate }}</span>
                    <button class="malou-btn-flat !malou-text-10--semibold sm:mt-2 sm:self-center" (click)="openDetails()">
                        {{ 'roi.details' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
