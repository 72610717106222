import { Pipe, PipeTransform } from '@angular/core';

export enum StarIcon {
    STAR = 'star',
    STAR_HALF = 'star-half',
    STAR_BORDER = 'star-border',
}

export enum RoundedValue {
    HALF_UNIT = 'HALF_UNIT',
    DOWN = 'DOWN',
    UP = 'UP',
}

@Pipe({
    name: 'starIcons',
    standalone: true,
})
export class StarIconsPipe implements PipeTransform {
    transform(stars: number, maxStars: number): StarIcon[] {
        return this._computeStarsIcons(stars, maxStars);
    }

    private _computeStarsIcons(stars: number, maxStars: number): StarIcon[] {
        return Array(maxStars)
            .fill(0)
            .map((_value, index) => {
                const roundedValue: RoundedValue = this._roundValueByUnit(stars, index);
                if (roundedValue === RoundedValue.UP) {
                    return StarIcon.STAR;
                }
                if (roundedValue === RoundedValue.HALF_UNIT) {
                    return StarIcon.STAR_HALF;
                }
                if (roundedValue === RoundedValue.DOWN) {
                    return StarIcon.STAR_BORDER;
                }
                return StarIcon.STAR_BORDER;
            });
    }

    private _roundValueByUnit(value: number, unit: number): RoundedValue {
        if (value >= unit + 0.75) {
            return RoundedValue.UP;
        }
        if (value >= unit + 0.25) {
            return RoundedValue.HALF_UNIT;
        }
        return RoundedValue.DOWN;
    }
}
