<div class="flex w-full flex-col gap-4">
    <div>
        <div class="malou-text-14--bold text-malou-color-text-1">
            {{ 'informations.infos_updates_state' | translate }}
        </div>
        @if (!informationsContext.restaurantHasNeverSentInformationUpdates()) {
            @if (!informationsContext.isAboutToSendUpdates()) {
                <span class="malou-text-10--regular italic text-malou-color-text-2">{{
                    'informations.infos_updates_state_last_time'
                        | translate
                            : { date: informationsContext.restaurantInformationUpdateStates().latestValidatedAt ?? '' | formatDate: 'long' }
                }}</span>
            }
        } @else {
            <span class="malou-text-10--regular italic text-malou-color-text-2">
                {{ 'informations.infos_updates_state_never_sent' | translate }}
            </span>
        }
    </div>

    @if (!informationsContext.restaurantHasNeverSentInformationUpdates()) {
        <div class="flex gap-6">
            @if (informationsContext.isAboutToSendUpdates()) {
                <span class="malou-text-12--regular flex items-center">
                    <mat-icon
                        class="mr-2 !h-6 !w-6 rounded bg-malou-color-state-warn bg-opacity-30 p-1 text-malou-color-state-warn"
                        [svgIcon]="SvgIcon.CLOCK"></mat-icon>
                    {{ 'informations.pending_updates' | translate }}
                </span>
            } @else {
                @if (informationsContext.restaurantInformationUpdateStates().others.length; as nbSucceedUpdates) {
                    <span class="malou-text-12--regular flex items-center">
                        <mat-icon
                            class="mr-2 !h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                            [svgIcon]="SvgIcon.CHECK"></mat-icon>

                        {{ 'informations.nb_succeed_updates' | pluralTranslate: nbSucceedUpdates : { nbUpdates: nbSucceedUpdates } }}
                    </span>
                }
            }
            @if (informationsContext.restaurantInformationUpdateStates().errors.length; as nbFailedUpdates) {
                <span class="malou-text-12--regular flex items-center">
                    <mat-icon
                        class="mr-2 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
                        [svgIcon]="SvgIcon.CROSS"></mat-icon>

                    {{ 'informations.nb_failed_updates' | pluralTranslate: nbFailedUpdates : { nbUpdates: nbFailedUpdates } }}
                </span>
            }

            <button
                class="malou-btn-flat !malou-text-10--semibold sm:mt-2 sm:self-center"
                id="tracking_informations_updates_state_see_details"
                [disabled]="informationsContext.restaurantInformationUpdateStatesIsLoading()"
                (click)="openUpdateStateModal()">
                {{ 'informations.see_details' | translate }}
            </button>
        </div>
    }
</div>
