import { createAction, props } from '@ngrx/store';

import { SingleRestaurantPermissionDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { User } from '../user';

export const editUserInfos = createAction('[User] Edit_User_Infos', props<{ infos: User }>());

export const loadUser = createAction('[User] Load_User');

export const loadUserPlatformPermissions = createAction('[User] Load_User_Platform_Permissions', props<{ key: PlatformKey }>());

export const editUserPlatformPermissions = createAction(
    '[User] Edit_User_Platform_Permissions',
    props<{ key: PlatformKey; permissions: SingleRestaurantPermissionDto[] }>()
);

export const removeSinglePlatformPermissions = createAction(
    '[User] Remove_Single_Platform_Permissions',
    props<{ restaurantId: string; key: PlatformKey }>()
);
