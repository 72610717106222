<app-modal-structure
    [title]="'platforms.connection_new.facebook_instagram.step_4.title' | translate"
    [primaryButtonText]="'platforms.connection_new.facebook_instagram.step_4.primary_button' | translate"
    [primaryButtonSubText]="connectionButtonSubText"
    [secondaryButtonText]="'platforms.connection_new.facebook_instagram.step_4.secondary_button' | translate"
    [buttonStyle]="ButtonStyle.LONG_TEXT"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: 1 })">
    <div class="flex flex-col gap-y-20 pt-12">
        <app-right-arrow-with-content>
            <div class="malou-text-13--medium">
                <span class="malou-color-text-1">{{ 'platforms.connection_new.facebook_instagram.step_4.description' | translate }}</span>
                &nbsp;<a class="malou-color-text-primary whitespace-nowrap" target="_blank" [href]="FACEBOOK_LINK">{{ FACEBOOK_LINK }}</a>
            </div>
        </app-right-arrow-with-content>
        <div class="flex w-full gap-x-3">
            <div class="flex-1">
                <div class="flex flex-col items-center gap-y-3">
                    <img
                        class="malou-box-shadow h-[145px] w-full"
                        [src]="'facebook-linked-with-instagram' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
                    <div class="malou-text-12--semibold malou-color-text-1">
                        {{ 'platforms.connection_new.facebook_instagram.step_4.linked_page' | translate }}
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div class="flex flex-col items-center gap-y-3">
                    <img
                        class="malou-box-shadow h-[145px] w-full"
                        [src]="
                            'facebook-not-linked-with-instagram' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }
                        " />
                    <div class="malou-text-12--semibold malou-color-text-1">
                        {{ 'platforms.connection_new.facebook_instagram.step_4.not_linked_page' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal-structure>
