import { computed, inject, Injectable, Signal, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { AiCompletionDto, AiPostGenerationSettingsDto } from '@malou-io/package-dto';
import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiTextToOptimizeType,
    ApplicationLanguage,
    MediaType,
} from '@malou-io/package-utils';

import { AiInteractionsService } from ':core/services/ai-interactions.service';
import { AiService } from ':core/services/ai.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { PostCaptionDisplayState } from ':shared/components/post-caption-ai-generation/post-caption-ai-generation.component';
import { Media } from ':shared/models';
import { Interaction } from ':shared/models/interaction';
import { OpenaiErrorService } from ':shared/openai-prompt/openai-errors.service';
import { AiOperation, DefaultPrompt } from ':shared/openai-prompt/openai-prompt.component';

import { AiButton } from '../new-post-modal/types';
import { NewPostModalContext } from './new-post-modal.context';

@Injectable({
    providedIn: 'any',
})
export class NewPostModalAiContext {
    private readonly _aiService = inject(AiService);
    private readonly _aiInteractionsService = inject(AiInteractionsService);
    private readonly _translate = inject(TranslateService);
    private readonly _newPostModalContext = inject(NewPostModalContext);
    private readonly _openaiErrorService = inject(OpenaiErrorService);
    private readonly _toastService = inject(ToastService);
    private readonly _experimentationService = inject(ExperimentationService);

    /*
     * TODO: Delete all the properties below on feature toggle clean
     */

    @ViewChild('aiActionMenuTrigger') aiActionMenuTrigger?: MatMenuTrigger;
    readonly shouldDisplayAiPromptOverlay = signal(false);
    readonly isAiOptimizeButtonDisplayed = signal(false);
    readonly shouldDisplayAiOptionTemplate = signal(true);
    readonly aiPromptButtonTooltip = signal('');
    readonly selectedText = signal('');
    readonly aiRateLimitReached = signal(false);
    readonly lastClickedAiButton = signal(AiButton.GENERATE);

    // -------------------------------------------------------------

    readonly isTextAreaLoadingAnimationEnabled = signal(false);
    readonly isAiPromptButtonEnabled = signal(true);
    readonly loadingAnimationDefaultText = signal(this._translate.instant('posts.new_post.textarea_loading_text.ai_response_loading'));
    readonly lastPrompt = signal('');
    readonly isGeneratingPostTextFromAI = signal(false);
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    readonly interactions = signal<Interaction[]>([]);
    readonly currentInteraction = signal<Interaction | null>(null);
    readonly resetBrowser = signal(false);
    readonly aiTextGenerationFormControl = new FormControl<string>('', [Validators.required]);
    readonly PostCaptionAiGenerationDisplayState = PostCaptionDisplayState;
    readonly postCaptionAiGenerationDisplayState = signal(PostCaptionDisplayState.CLOSED);
    readonly defaultPrompt = signal(DefaultPrompt.GENERATE_POST);
    readonly captionProposals = signal<string[]>([]);
    readonly selectedCaption = signal<string>('');
    readonly isPostWithAttachments = computed<boolean>(() => this._newPostModalContext.postMedias().length > 0);
    readonly usablePhotoForImageAnalysis = computed<Media | undefined>(() =>
        this._newPostModalContext.postMedias().find((attachment) => attachment.type === MediaType.PHOTO)
    );
    readonly hasUsedAiToGeneratePostText = signal(false);
    readonly hasUsedMediaAnalysisToGeneratePostText = signal(false);

    readonly isImageAnalysisEnabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-ai-media-description'),
        { initialValue: false }
    );

    closePostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.CLOSED);
    }

    openPostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.OPEN);
    }

    // TODO delete this method on feature toggle clean
    initProperties(hasKeywords: boolean, aiActivated: boolean): void {
        this.isAiPromptButtonEnabled.set(hasKeywords && aiActivated);
        this.aiPromptButtonTooltip.set(this._getAiPromptButtonTooltip(hasKeywords, aiActivated));
    }

    initInteractions(postId: string): void {
        this._aiInteractionsService.getAiInteractions(postId, AiInteractionRelatedEntityCollection.POSTS).subscribe({
            next: (aiInteractions) => {
                const interactions = aiInteractions
                    .filter(
                        (interaction) =>
                            interaction.isSuccessful() &&
                            interaction.type !== AiInteractionType.TRANSLATE &&
                            interaction.type !== AiInteractionType.POST_TRANSLATION
                    )
                    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                    .map((interaction) => ({
                        id: uuidv4(),
                        text: interaction.completionText || '',
                        isAiInteraction: true,
                    }));
                this.interactions.set(interactions);
            },
        });
    }

    onGeneratePromptChange({ shouldUseImageAnalysis }: { shouldUseImageAnalysis: boolean }, userPrompt: string): void {
        this.loadingAnimationDefaultText.set(this._translate.instant('posts.new_post.textarea_loading_text.ai_response_loading'));
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
        this.shouldDisplayAiPromptOverlay.set(false);
        this.isAiPromptButtonEnabled.set(false);
        this.isGeneratingPostTextFromAI.set(true);
        this._generateSeoPost({ userPrompt, shouldUseImageAnalysis });
        this.hasUsedAiToGeneratePostText.set(true);
        this.hasUsedMediaAnalysisToGeneratePostText.set(shouldUseImageAnalysis);
    }

    translateText(lang: string): void {
        this.aiActionMenuTrigger?.closeMenu();
        if (!lang.length) {
            return;
        }
        this.loadingAnimationDefaultText.set(this._translate.instant('posts.new_post.textarea_loading_text.ai_can_translate'));
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.TRANSLATION));
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this.isAiPromptButtonEnabled.set(false);
        this.isGeneratingPostTextFromAI.set(true);
        this._translateTextWithAi(lang);
    }

    onLangChange(lang: ApplicationLanguage): void {
        this.translateText(lang);
    }

    // TODO delete this method on feature toggle clean
    onGenerateCompletionClick(): void {
        this.shouldDisplayAiPromptOverlay.set(!this.shouldDisplayAiPromptOverlay());
        this.lastClickedAiButton.set(AiButton.GENERATE);
    }

    // TODO delete this method on feature toggle clean
    retryCompletion(): void {
        this.shouldDisplayAiOptionTemplate.set(false);
        this.shouldDisplayAiPromptOverlay.set(true);
        this.lastClickedAiButton.set(AiButton.RETRY);
    }

    onSelectionChange(selectedText: string): void {
        this.selectedText.set(selectedText);
    }

    onInteractionChanged(interaction: Interaction): void {
        const currentFormText = this._newPostModalContext.postForm.get('post.text')?.value || '';
        this._updateInteractionsList({
            id: uuidv4(),
            text: currentFormText,
            isAiInteraction: false,
            originalInteractionId: this.currentInteraction()?.isAiInteraction
                ? this.currentInteraction()?.id
                : this.currentInteraction()?.originalInteractionId || undefined,
        });
        this._newPostModalContext.postForm.get('post.text')?.setValue(interaction.text);
        this.currentInteraction.set(interaction);
    }

    onCaptionProposalSelection(caption: string): void {
        this.selectedCaption.set(caption);

        let currentLegend = this._newPostModalContext.postForm.get('post.text')?.value ?? '';
        if (!currentLegend) {
            this._newPostModalContext.postForm.get('post.text')?.setValue(caption);
            return;
        }

        const existingCaptionInText = this.captionProposals().find((capt) => currentLegend.includes(capt));
        if (!!existingCaptionInText) {
            currentLegend = currentLegend.replace(`\n${existingCaptionInText}`, '').replace(existingCaptionInText, '');
        }
        const updatedLegend = currentLegend ? currentLegend + '\n' + caption : caption;
        this._newPostModalContext.postForm.get('post.text')?.setValue(updatedLegend);

        const textarea = document.getElementById('postText') as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }

    onAiOptimizeClick(): void {
        this.loadingAnimationDefaultText.set(this._translate.instant('posts.new_post.textarea_loading_text.ai_response_loading'));
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
        const textToOptimize = this._newPostModalContext.postForm.get('post.text')?.value ?? '';
        this._newPostModalContext.postForm.get('post.text')?.setValue('');
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this.isAiPromptButtonEnabled.set(false);
        this._aiService
            .optimizeText({
                relatedEntityId: this._newPostModalContext.currentPost()!._id,
                textToOptimize: textToOptimize,
                textToOptimizeType: AiTextToOptimizeType.SEO_POST,
                restaurantId: this._newPostModalContext.currentRestaurant()!._id,
                lang: LocalStorage.getLang(),
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    const { data } = res;
                    this.isAiPromptButtonEnabled.set(true);
                    this.shouldDisplayAiOptionTemplate.set(true);
                    this.isAiOptimizeButtonDisplayed.set(false);
                    this._aiService.handleAiInteraction();
                    this._updateInteractionsList({ text: data.optimizedText, isAiInteraction: true, id: uuidv4() });
                    this._newPostModalContext.postForm.get('post.text')?.setValue(data.optimizedText);
                    this._newPostModalContext.onChangePostLang(data.lang);
                },
                error: (err) => {
                    console.warn('err >', err);
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this.isAiPromptButtonEnabled.set(true);
                    this._newPostModalContext.postForm.get('post.text')?.setValue(textToOptimize);
                    if (err.error?.status === 429) {
                        this._handleAiLimitReachedError();
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    onTextAreaInput(_event: InputEvent): void {
        this.resetBrowser.update((value) => !value);
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translate.instant('posts.new_post.textarea_loading_text.post_subject'),
                    this._translate.instant('posts.new_post.textarea_loading_text.your_keywords'),
                    this._translate.instant('posts.new_post.textarea_loading_text.optimized_text_length'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translate.instant('common.langs.en').toLowerCase(),
                    this._translate.instant('common.langs.es').toLowerCase(),
                    this._translate.instant('common.langs.it').toLowerCase(),
                    this._translate.instant('posts.new_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }

    private _generateSeoPost({ userPrompt, shouldUseImageAnalysis }: { userPrompt: string; shouldUseImageAnalysis: boolean }): void {
        this.captionProposals.set([]);
        const currentText = this._newPostModalContext.postForm.get('post.text')?.value || '';
        this.lastPrompt.set(userPrompt);
        const generatePost$ = this._aiService.generateSeoPostUsingSettings({
            postId: this._newPostModalContext.currentPost()!._id,
            description: userPrompt,
            restaurantId: this._newPostModalContext.currentRestaurant()!._id,
            lang: LocalStorage.getLang(),
            shouldUseImageAnalysis,
        });
        generatePost$.subscribe({
            next: (res: { data: AiCompletionDto | AiPostGenerationSettingsDto }) => {
                const { data: generatedPostText } = res;
                this.captionProposals.set((generatedPostText as AiPostGenerationSettingsDto) ?? ['']);
                const validGeneratedPostText = this.captionProposals()[0];
                this.isAiPromptButtonEnabled.set(true);
                this.isGeneratingPostTextFromAI.set(false);
                this.shouldDisplayAiOptionTemplate.set(true);
                this.isAiOptimizeButtonDisplayed.set(false);
                this._aiService.handleAiInteraction();
                this._updateInteractionsList({ text: validGeneratedPostText, isAiInteraction: true, id: uuidv4() });
                this._newPostModalContext.postForm.get('post.text')?.setValue(currentText);
            },
            error: (err) => {
                this.isAiPromptButtonEnabled.set(true);
                this.isGeneratingPostTextFromAI.set(false);
                this._newPostModalContext.postForm.get('post.text')?.setValue(currentText || '');
                console.warn('err >', err);
                if (err.error?.status === 429) {
                    this._handleAiLimitReachedError();
                    this._openaiErrorService.openRateLimitErrorDialog();
                } else {
                    this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                }
            },
        });
    }

    private _translateTextWithAi(lang: string): void {
        const currentText = this._newPostModalContext.postForm.get('post.text')?.value || '';
        const textToTranslate = this.selectedText().length ? this.selectedText() : currentText;
        this._newPostModalContext.postForm.get('post.text')?.setValue('');
        this._aiService
            .translateText({
                relatedEntityId: this._newPostModalContext.currentPost()!._id,
                relatedEntityCollection: AiInteractionRelatedEntityCollection.POSTS,
                type: AiInteractionType.POST_TRANSLATION,
                restaurantId: this._newPostModalContext.currentRestaurant()!._id,
                text: textToTranslate,
                lang,
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    const { data: translatedSelection } = res;
                    const translatedText = currentText.replace(textToTranslate, translatedSelection);
                    this.isAiPromptButtonEnabled.set(true);
                    this.isGeneratingPostTextFromAI.set(false);
                    this.shouldDisplayAiOptionTemplate.set(true);
                    this._aiService.handleAiInteraction();
                    this._newPostModalContext.postForm.get('post.text')?.setValue(translatedText);
                    this._newPostModalContext.onChangePostLang(lang);
                },
                error: (err) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this.isAiPromptButtonEnabled.set(true);
                    this.isGeneratingPostTextFromAI.set(false);
                    this._newPostModalContext.postForm.get('post.text')?.setValue(currentText);
                    console.warn('err >', err);
                    if (err.error?.status === 429) {
                        this._handleAiLimitReachedError();
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    private _updateInteractionsList(interaction: Interaction): void {
        const interactions = this.interactions();
        const update = Interaction.getUpdatedInteractions(interactions, interaction);
        if (!update) {
            return;
        }
        this.interactions.set(update);
    }

    // TODO delete this method on feature toggle clean
    private _handleAiLimitReachedError(): void {
        this.aiRateLimitReached.set(true);
        this.aiPromptButtonTooltip.set(this._translate.instant('posts.new_post.textarea_loading_text.ai_limit_reached'));
    }
    // TODO delete this method on feature toggle clean
    private _getAiPromptButtonTooltip(hasKeywords: boolean, aiActivated: boolean): string {
        if (!aiActivated) {
            return this._translate.instant('posts.new_post.ai_completion_not_available');
        }
        if (!hasKeywords) {
            return this._translate.instant('posts.new_post.choose_keywords');
        }
        return '';
    }
}
