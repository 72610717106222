import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { MapstrConnectionStep0Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/mapstr-connection-step-0/mapstr-connection-step-0.component';
import { MapstrConnectionStep1Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/mapstr-connection-step-1/mapstr-connection-step-1.component';
import {
    PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
    PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
    ParentStepperComponentDialogData,
    PlatformsConnectionParentStepperComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-parent-stepper.component';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { Platform } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const NOT_CONNECTED_STEP_INDEX = 0;
const CONNECTED_STEP_INDEX = 1;

export interface MapstrConnectionModalResult {
    hasDataChanged?: boolean;
}

@Injectable({ providedIn: 'root' })
export class MapstrConnectionModalService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _store = inject(Store);

    open(): Observable<MapstrConnectionModalResult | undefined> {
        const platform$ = this._store.select(selectCurrentPlatform({ platformKey: PlatformKey.MAPSTR })).pipe(take(1));
        return platform$.pipe(
            switchMap((platform) => {
                const stepIndex = this._getStepIndexFormStepTypeService(platform);
                return this._customDialogService
                    .open<
                        PlatformsConnectionParentStepperComponent,
                        ParentStepperComponentDialogData<Platform | undefined, MapstrConnectionModalResult>,
                        MapstrConnectionModalResult
                    >(PlatformsConnectionParentStepperComponent, {
                        height: PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
                        width: PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
                        data: {
                            sharedData: platform,
                            steps: [
                                {
                                    stepName: this._translateService.instant('platforms.connection_new.mapstr.step_0.step_name'),
                                    component: MapstrConnectionStep0Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant('platforms.connection_new.mapstr.step_1.step_name'),
                                    component: MapstrConnectionStep1Component,
                                    componentInputs: {},
                                },
                            ],
                            stepperTitle: this._translateService.instant('platforms.connection_new.mapstr.stepper.title'),
                            stepperSubtitle: this._translateService.instant('platforms.connection_new.mapstr.stepper.subtitle'),
                            stepperPlatformKeyIcon: PlatformKey.MAPSTR,
                            stepIndex: stepIndex,
                        },
                    })
                    .afterClosed();
            })
        );
    }

    private _getStepIndexFormStepTypeService(platform: Platform | undefined): number {
        if (platform?.credentials.length) {
            return CONNECTED_STEP_INDEX;
        }
        return NOT_CONNECTED_STEP_INDEX;
    }
}
