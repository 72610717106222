import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SearchComponent } from ':shared/components/search/search.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { LightRestaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-select-restaurant-wheel-of-fortune-modal',
    templateUrl: './select-restaurant-wheel-of-fortune-modal.component.html',
    styleUrls: ['./select-restaurant-wheel-of-fortune-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, TranslateModule, SearchComponent, ImagePathResolverPipe, ApplySelfPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRestaurantWheelOfFortuneModalComponent {
    readonly SvgIcon = SvgIcon;
    filteredRestaurants: LightRestaurant[];

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        private readonly _dialogRef: MatDialogRef<SelectRestaurantWheelOfFortuneModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurants: LightRestaurant[];
        }
    ) {
        this.filteredRestaurants = this.data.restaurants;
    }

    onValueChange(value: string): void {
        this.filteredRestaurants = this.data.restaurants.filter(
            (restaurant) =>
                restaurant.name.toLowerCase().includes(value.toLowerCase()) ||
                restaurant.address?.getDisplayedValue().toLowerCase().includes(value.toLowerCase())
        );
    }

    selectRestaurant(restaurant: LightRestaurant): void {
        this.close(restaurant.id);
    }

    close(restaurantId?: string): void {
        this._dialogRef.close({ restaurantId });
    }
}
