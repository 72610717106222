import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { GeneratedHashtagDto, HashtagDto, SuggestHashtagsPayload } from '@malou-io/package-dto';
import { ApiResultV2, generateDbId } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { Hashtag } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class HashtagsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/hashtags`;

    constructor(private readonly _http: HttpClient) {}

    getHashtagsForRestaurant(restaurantId: string): Observable<Hashtag[]> {
        return this._http
            .get<ApiResultV2<HashtagDto[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`)
            .pipe(map((res) => res.data.map((hashtag) => new Hashtag(hashtag))));
    }

    suggestHashtags(
        payload: SuggestHashtagsPayload,
        restaurantId: string
    ): Observable<{ hashtags: Hashtag[]; preSelectedHashtags: Hashtag[] }> {
        return this._http.post<ApiResultV2<GeneratedHashtagDto[]>>(`${this.API_BASE_URL}/suggest`, payload).pipe(
            map((res) => {
                const hashtags = res.data
                    .filter((hashtag) => !!hashtag?.text)
                    .map((hashtag) => ({
                        id: generateDbId().toHexString(),
                        restaurantId: restaurantId,
                        isCustomerInput: false,
                        isMain: false,
                        isBookmarked: false,
                        text: hashtag.text,
                        type: hashtag.type,
                        isPreSelected: hashtag.isPreSelected,
                    }));
                return {
                    hashtags: hashtags.map((hashtag) => new Hashtag(hashtag)),
                    preSelectedHashtags: hashtags.filter((hashtag) => hashtag.isPreSelected).map((hashtag) => new Hashtag(hashtag)),
                };
            })
        );
    }

    saveHashtag(hashtag: Hashtag & { restaurantId: string }): Observable<Hashtag> {
        return this._http.post<ApiResultV2<HashtagDto>>(`${this.API_BASE_URL}/`, { ...hashtag }).pipe(map((res) => new Hashtag(res.data)));
    }

    deleteHashtag(hashtagId: string): Observable<ApiResultV2> {
        return this._http.delete<ApiResultV2>(`${this.API_BASE_URL}/${hashtagId}`);
    }

    editMainHashtagText(hashtagId: string, text: string): Observable<ApiResultV2<string>> {
        return this._http.put<ApiResultV2<string>>(`${this.API_BASE_URL}/${hashtagId}`, { text });
    }
}
