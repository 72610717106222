<td
    class="malou-color-text-1 malou-text-13--semibold field-key !w-[190px] md:!w-full md:pl-1"
    innerHTML="{{ comparison.niceKey }}"
    [class.!border-t-0]="index === 0"
    [class.!border-b-0]="lastRow"></td>

<td
    class="min-w-[200px]"
    [class.!border-t-0]="index === 0"
    [class.!border-b-0]="lastRow"
    [ngClass]="{ 'h-1': comparison.key === ComparisonKey.DESCRIPTIONS }">
    <div class="malou-text-14--semibold my-3 hidden items-center md:flex">
        {{ 'information.platform_comparison.malou_app' | translate }}
    </div>
    <div>
        @switch (comparison.key) {
            @case (ComparisonKey.SPECIAL_HOURS) {
                <app-comparison-special-hours [hours]="comparison.reference"></app-comparison-special-hours>
            }
            @case (ComparisonKey.REGULAR_HOURS) {
                <app-comparison-regular-hours [hours]="comparison.reference"></app-comparison-regular-hours>
            }
            @case (ComparisonKey.ADDRESS) {
                <app-comparison-address [address]="comparison.reference"></app-comparison-address>
            }
            @case (ComparisonKey.CATEGORY) {
                <app-comparison-categories [categories]="[comparison.reference]"></app-comparison-categories>
            }
            @case (ComparisonKey.CATEGORY_LIST) {
                <app-comparison-categories [categories]="comparison.reference"></app-comparison-categories>
            }
            @case (ComparisonKey.DESCRIPTIONS) {
                <app-comparison-description [descriptions]="comparison.reference"></app-comparison-description>
            }
            @case (ComparisonKey.ATTRIBUTES) {
                <app-comparison-attributes [attributes]="comparison.reference"></app-comparison-attributes>
            }
            @default {
                <app-default-comparison [value]="comparison.reference"></app-default-comparison>
            }
        }
    </div>
</td>

<td class="min-w-[200px]" [class.!border-t-0]="index === 0" [class.!border-b-0]="lastRow">
    <div class="malou-text-14--semibold my-3 hidden items-center md:flex">
        {{ platformName }}
    </div>
    <div>
        @switch (comparison.key) {
            @case (ComparisonKey.SPECIAL_HOURS) {
                <app-comparison-special-hours [hours]="comparison.compared"></app-comparison-special-hours>
            }
            @case (ComparisonKey.REGULAR_HOURS) {
                <app-comparison-regular-hours
                    [hours]="comparison.compared"
                    [referenceHours]="comparison.reference"></app-comparison-regular-hours>
            }
            @case (ComparisonKey.ADDRESS) {
                <app-comparison-address [address]="comparison.compared" [referenceAddress]="comparison.reference"></app-comparison-address>
            }
            @case (ComparisonKey.CATEGORY) {
                <app-comparison-categories
                    [categories]="[comparison.compared]"
                    [referenceCategories]="[comparison.reference]"></app-comparison-categories>
            }
            @case (ComparisonKey.CATEGORY_LIST) {
                <app-comparison-categories
                    [categories]="comparison.compared"
                    [referenceCategories]="comparison.reference"></app-comparison-categories>
            }
            @case (ComparisonKey.DESCRIPTIONS) {
                <app-comparison-description [descriptions]="comparison.compared"></app-comparison-description>
            }
            @case (ComparisonKey.ATTRIBUTES) {
                <app-comparison-attributes [attributes]="comparison.compared"></app-comparison-attributes>
            }
            @default {
                <app-default-comparison
                    [value]="comparison.compared"
                    [referenceValue]="comparison.reference ?? DISPLAYED_NULLISH_VALUE"></app-default-comparison>
            }
        }
    </div>
</td>

@if (action === PlatformComparisonAction.LOCK) {
    <td class="flex h-full w-[100px] justify-between !border-b-0 !pl-0 md:w-full" [class.!border-t-0]="index === 0">
        <div class="malou-text-14--semibold hidden text-left md:!ml-4 md:flex md:items-center">
            {{ 'information.platform_comparison.send_information' | translate }}
        </div>
        <div class="flex h-full items-center">
            <app-slide-toggle
                class="custom-slide-toggle"
                [checked]="!lockedField"
                [matTooltip]="
                    !lockedField
                        ? ('information.platform_comparison.dont_update_information' | translate: { platformName })
                        : ('information.platform_comparison.update_information' | translate: { platformName })
                "
                (onToggle)="emitChangeLock($event, comparison.key)">
            </app-slide-toggle>
        </div>
    </td>
}

@if (action === PlatformComparisonAction.COPY) {
    <td class="mr-4 flex justify-end !border-b-0 !pl-0" [class.!border-t-0]="index === 0">
        @switch (comparison.key) {
            @case (ComparisonKey.DESCRIPTIONS) {
                <ng-container [ngTemplateOutlet]="descriptionsTemplate"></ng-container>
            }
            @default {
                <mat-icon
                    class="!h-4 !w-4 cursor-pointer"
                    color="primary"
                    [svgIcon]="SvgIcon.COPY"
                    (click)="emitCopy(comparison.reference)"></mat-icon>
            }
        }
    </td>
}

<ng-template #descriptionsTemplate>
    <ng-container
        [ngTemplateOutlet]="comparison.reference.length === 1 ? uniqueDescriptionsTemplate : multipleDescriptionsTemplate"></ng-container>
</ng-template>

<ng-template #uniqueDescriptionsTemplate>
    <mat-icon
        class="!h-4 !w-4 cursor-pointer"
        color="primary"
        [svgIcon]="SvgIcon.COPY"
        (click)="emitCopy(comparison.reference[0])"></mat-icon>
</ng-template>

<ng-template #multipleDescriptionsTemplate>
    <div class="flex h-full w-full justify-end" [matMenuTriggerFor]="copyMenu">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
    </div>
    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #copyMenu="matMenu">
        @for (description of comparison.reference; track description) {
            <button class="flex gap-x-3" mat-menu-item (click)="emitCopy(description)">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
                <span class="malou-text-14--regular">{{
                    'information.platform_comparison.copy_description'
                        | translate: { description: description.language + ' (' + (description.size | enumTranslate: 'description') + ')' }
                }}</span>
            </button>
        }
    </mat-menu>
</ng-template>
