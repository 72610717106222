import { NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    Input,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { delay, map, of, switchMap } from 'rxjs';

import { TimeInMilliseconds } from '@malou-io/package-utils';

import { addOpacityToColor } from ':shared/helpers/color';
import { HtmlDownloader } from ':shared/helpers/html-downloader';
import { QrCode } from ':shared/helpers/qr-code';
import { Gift } from ':shared/models/gift';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

const GIFT_SUBSECTOR_SIZE = 3;

export enum WheelOfFortunePosterFormat {
    A4_LANDSCAPE = 'A4_LANDSCAPE',
    A4_PORTRAIT = 'A4_PORTRAIT',
}

const POSTER_MIN_SIDE = 496;
const POSTER_MAX_SIDE = 701;

@Component({
    selector: 'app-wheel-of-fortune-poster',
    standalone: true,
    imports: [NgStyle, NgTemplateOutlet, TranslateModule, ApplyPurePipe, ApplySelfPurePipe, IncludesPipe, ImagePathResolverPipe],
    templateUrl: './wheel-of-fortune-poster.component.html',
    styleUrls: ['./wheel-of-fortune-poster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortunePosterComponent {
    @ViewChild('wheelOfFortunePosterContainer', { static: false }) set htmlContainer(htmlElementRef: ElementRef<HTMLElement>) {
        if (htmlElementRef) {
            this.containerHtmlElement.set(htmlElementRef.nativeElement);
        }
    }

    @Input() format: WritableSignal<WheelOfFortunePosterFormat | null>;
    @Input() set wheelOfFortune(value: WheelOfFortune) {
        this._wheelOfFortune.set(value);
    }

    get wheelOfFortune(): WritableSignal<WheelOfFortune | null> {
        return this._wheelOfFortune;
    }

    readonly WheelOfFortunePosterFormat = WheelOfFortunePosterFormat;

    readonly containerHtmlElement = signal<HTMLElement | null>(null);
    readonly restaurantId: WritableSignal<string | null> = signal(null);

    readonly primaryColor = computed(() => this.wheelOfFortune()?.parameters.primaryColor);
    readonly secondaryColor = computed(() => this.wheelOfFortune()?.parameters.secondaryColor);
    readonly logo = computed(() => this.wheelOfFortune()?.parameters.media);

    readonly qrCode = new QrCode();
    readonly qrCodeUrl = computed(() => this.wheelOfFortune()?.getWheelOfFortuneUrl({ isFromTotem: false }) || '');
    readonly qrCodeImage = toSignal(
        toObservable(this.qrCodeUrl).pipe(
            switchMap((url) => {
                if (!url) {
                    return of('');
                }
                const image = this.qrCode.generateQrCode$(url);
                return image.pipe(map((res) => res.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')));
            })
        )
    );
    readonly wheelContainerWidth = computed(() =>
        this.format() === WheelOfFortunePosterFormat.A4_PORTRAIT ? POSTER_MIN_SIDE : POSTER_MAX_SIDE
    );
    readonly wheelContainerHeight = computed(() =>
        this.format() === WheelOfFortunePosterFormat.A4_PORTRAIT ? POSTER_MAX_SIDE : POSTER_MIN_SIDE
    );

    readonly sectors: Signal<Gift[]> = computed(() => {
        const allSectors = new Array(8).fill(0);
        return allSectors;
    });

    readonly allSubSectors: Signal<number[]> = computed(() => [...new Array(this.sectors().length * GIFT_SUBSECTOR_SIZE).keys()]);

    readonly getColor: Signal<(index: number) => string> = computed(() => {
        const primaryColor = this.primaryColor();
        const secondaryColor = this.secondaryColor();
        if (!primaryColor || !secondaryColor) {
            return () => '';
        }
        return (index: number): string =>
            (Math.floor(index / GIFT_SUBSECTOR_SIZE) % this.allSubSectors().length) % 2 === 0 ? primaryColor : secondaryColor;
    });

    readonly getRotateDegreeValue: Signal<(index: number) => number | null> = computed(() => (index: number): number | null => {
        const rotateSize = 360 / this.allSubSectors().length;
        return rotateSize * index;
    });

    readonly getRotateDegrees: Signal<(index: number) => string | null> = computed(
        () =>
            (index: number): string | null =>
                `rotate(${this.getRotateDegreeValue()(index)}deg)`
    );
    private readonly _wheelOfFortune: WritableSignal<WheelOfFortune | null> = signal(null);

    constructor(private readonly _htmlDownloader: HtmlDownloader) {
        effect(() => {
            const htmlToDownload = this.containerHtmlElement();
            if (htmlToDownload && this.qrCodeImage()) {
                this._htmlDownloader
                    .downloadPng(htmlToDownload, 'poster')
                    .pipe(delay(1 * TimeInMilliseconds.SECOND))
                    .subscribe();
            }
        });
    }

    isMiddleSector(index: number): boolean {
        return index % GIFT_SUBSECTOR_SIZE === 1;
    }

    addOpacityToColor(color: string, opacity = 0.95): string {
        return addOpacityToColor(color, opacity);
    }
}
