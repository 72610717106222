import { Injectable } from '@angular/core';

import { CsvAsStringArrays } from '../csv-service.abstract';

const ROW_SEPARATOR = '\n';
const VALUE_SEPARATOR = ';';
const STRING_DELIMITER = '"';

@Injectable({ providedIn: 'root' })
export class CsvStringCreator {
    create(csvData: CsvAsStringArrays): string {
        const formattedCsvData = csvData.map((row) => row.map(this._escapeStringDelimiter).map(this._addStringDelimiter));
        return formattedCsvData.map((row) => row.join(VALUE_SEPARATOR)).join(ROW_SEPARATOR);
    }

    private _escapeStringDelimiter(str: string): string {
        const stringDelimiterRegex = new RegExp(STRING_DELIMITER, 'g');
        return str.replace(stringDelimiterRegex, `${STRING_DELIMITER}${STRING_DELIMITER}`);
    }

    private _addStringDelimiter(str: string): string {
        if (str.includes(STRING_DELIMITER) || str.includes(VALUE_SEPARATOR) || str.includes(ROW_SEPARATOR)) {
            return `${STRING_DELIMITER}${str}${STRING_DELIMITER}`;
        }
        return str;
    }
}
