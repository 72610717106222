import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { selectTextReviewsFilters } from ':modules/reviews/store/reviews.selectors';
import { SearchComponent } from ':shared/components/search/search.component';

@Component({
    selector: 'app-reviews-header-search',
    templateUrl: './reviews-header-search.component.html',
    styleUrls: ['./reviews-header-search.component.scss'],
    standalone: true,
    imports: [TranslateModule, SearchComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderSearchComponent {
    readonly id = input<string>();

    private readonly _store = inject(Store);

    readonly storedValue$ = this._store.select(selectTextReviewsFilters);
    readonly storedValue = toSignal(this.storedValue$, { initialValue: '' });

    onSearchChange(search: string): void {
        this._store.dispatch(ReviewsActions.editReviewsFiltersSearch({ search }));
    }
}
