import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map, Subject, takeUntil } from 'rxjs';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { FilterOption } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { RatingsFiltersComponent } from ':shared/components/ratings-filters/ratings-filters.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { CommentReviewsFilters } from ':shared/models';
import { AutomationTemplateFilters, ReviewTemplatesFilters } from ':shared/models/template-filters';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import * as ReviewsTemplatesActions from '../store/review-templates.actions';
import {
    selectReviewTemplatesFilters,
    selectReviewTemplatesFiltersRatings,
    selectReviewTemplatesSortBy,
    selectReviewTemplatesSortOrder,
} from '../store/review-templates.selectors';

const DEFAULT_RATINGS = [0, 1, 2, 3, 4, 5];
const DEFAULT_COMMENT: CommentReviewsFilters[] = [CommentReviewsFilters.WITH_TEXT, CommentReviewsFilters.WITHOUT_TEXT];
const DEFAULT_AUTOMATION: AutomationTemplateFilters[] = [AutomationTemplateFilters.AUTOMATED, AutomationTemplateFilters.MANUAL];

@Component({
    selector: 'app-review-template-actions-header',
    templateUrl: './review-template-actions-header.component.html',
    styleUrls: ['./review-template-actions-header.component.scss'],
    standalone: true,
    imports: [
        SearchComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        SortByFiltersComponent,
        MatTooltipModule,
        MatMenuModule,
        RatingsFiltersComponent,
        MatButtonToggleModule,
        MatRippleModule,
        AsyncPipe,
        TranslateModule,
        LazyLoadImageModule,
        ApplyPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewTemplateActionsHeaderComponent implements OnInit, KillSubscriptions {
    @Input() hasSelectedTemplates: boolean;
    @Output() searchChange: EventEmitter<string> = new EventEmitter();
    @Output() duplicateSelected: EventEmitter<string> = new EventEmitter();
    @Output() deleteSelected: EventEmitter<void> = new EventEmitter();
    @Output() createTemplate: EventEmitter<void> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    readonly DuplicationDestination = DuplicationDestination;

    readonly AVAILABLE_COMMENTS_FILTERS = DEFAULT_COMMENT;
    readonly AVAILABLE_AUTOMATION_FILTERS = DEFAULT_AUTOMATION;
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'name', label: this._translate.instant('templates.review.filters.name') },
        { key: 'withComment', label: this._translate.instant('templates.review.filters.comments') },
        { key: 'rating', label: this._translate.instant('templates.review.filters.ratings') },
    ];

    readonly sortBy$ = this._store.select(selectReviewTemplatesSortBy);
    readonly sortOrder$ = this._store.select(selectReviewTemplatesSortOrder).pipe(map((order) => (order === 'asc' ? 1 : -1)));
    readonly ratings$ = this._store.select(selectReviewTemplatesFiltersRatings);
    readonly ratings = toSignal(this.ratings$, { initialValue: [] });

    shouldShowAdvancedFilters = false;
    filters: ReviewTemplatesFilters;

    filterCount = 0;

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectReviewTemplatesFilters)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((filters) => {
                this.filters = filters;
                this.filterCount = this._computeFilterCount();
            });
    }

    toggleAdvancedFilters(): void {
        this.shouldShowAdvancedFilters = !this.shouldShowAdvancedFilters;
    }

    onSearch(search: string): void {
        this.searchChange.emit(search);
    }

    duplicate(duplicationDestination: string): void {
        this.duplicateSelected.emit(duplicationDestination);
    }

    delete(): void {
        this.deleteSelected.emit();
    }

    onCreateTemplate(): void {
        this.createTemplate.emit();
    }

    clearFilters(): void {
        this._store.dispatch(ReviewsTemplatesActions.resetReviewTemplatesFilters());
    }

    toggleRating(rating: number): void {
        this._store.dispatch(ReviewsTemplatesActions.editReviewTemplatesFiltersRatings({ ratings: [rating] }));
    }

    toggleReviewFilter(commentFilter: CommentReviewsFilters | AutomationTemplateFilters): void {
        this._store.dispatch(ReviewsTemplatesActions.toggleReviewTemplatesFilters({ filter: commentFilter }));
    }

    onSortByChange(sortBy: string): void {
        this._store.dispatch(ReviewsTemplatesActions.editReviewTemplatesSort({ sortBy }));
    }

    onSortOrderChange(order: number): void {
        const sortOrder = order === 1 ? 'asc' : 'desc';

        this._store.dispatch(ReviewsTemplatesActions.editReviewTemplatesSort({ sortOrder }));
    }

    private _computeFilterCount(): number {
        const isDefaultRatings = this.filters.ratings?.length === DEFAULT_RATINGS.length;
        const isDefaultAutomation = [this.filters.automated, this.filters.manual].filter(Boolean).length === DEFAULT_AUTOMATION.length;
        const isDefaultComments = [this.filters.withText, this.filters.withText].filter(Boolean).length === DEFAULT_COMMENT.length;

        return [!isDefaultRatings, !isDefaultAutomation, !isDefaultComments].filter(Boolean).length;
    }
}
