export enum MalouTimeScalePeriod {
    DEFAULT = 'default',
    LAST_SEVEN_DAYS = 'last_seven_days',
    LAST_THIRTY_DAYS = 'last_thirty_days',
    LAST_AND_COMING_THIRTY_DAYS = 'last_and_coming_thirty_days',
    LAST_AND_COMING_SIX_MONTH = 'last_and_coming_six_month',
    CUSTOM = 'custom',
    ALL = 'all',
    LAST_MONTH = 'last_month',
    LAST_TWO_MONTHS = 'last_two_months',
    LAST_THREE_MONTHS = 'last_three_months',
    LAST_FOUR_MONTHS = 'last_four_months',
    LAST_FIVE_MONTHS = 'last_five_months',
    LAST_SIX_MONTHS = 'last_six_months',
    LAST_SEVEN_MONTHS = 'last_seven_months',
    LAST_EIGHT_MONTHS = 'last_eight_months',
    LAST_NINE_MONTHS = 'last_nine_months',
    LAST_TEN_MONTHS = 'last_ten_months',
    LAST_ELEVEN_MONTHS = 'last_eleven_months',
    LAST_TWELVE_MONTHS = 'last_twelve_months',
}

export const getSelectedMonthsNumberFromTimeScaleFilter = (selectedTimeScaleFilter: MalouTimeScalePeriod | undefined): number => {
    switch (selectedTimeScaleFilter) {
        case MalouTimeScalePeriod.LAST_MONTH:
            return 1;
        case MalouTimeScalePeriod.LAST_TWO_MONTHS:
            return 2;
        case MalouTimeScalePeriod.LAST_THREE_MONTHS:
            return 3;
        case MalouTimeScalePeriod.LAST_FOUR_MONTHS:
            return 4;
        case MalouTimeScalePeriod.LAST_FIVE_MONTHS:
            return 5;
        case MalouTimeScalePeriod.LAST_SIX_MONTHS:
            return 6;
        case MalouTimeScalePeriod.LAST_SEVEN_MONTHS:
            return 7;
        case MalouTimeScalePeriod.LAST_EIGHT_MONTHS:
            return 8;
        case MalouTimeScalePeriod.LAST_NINE_MONTHS:
            return 9;
        case MalouTimeScalePeriod.LAST_TEN_MONTHS:
            return 10;
        case MalouTimeScalePeriod.LAST_ELEVEN_MONTHS:
            return 11;
        case MalouTimeScalePeriod.LAST_TWELVE_MONTHS:
            return 12;
        default:
            return 6;
    }
};

export const getMalouPeriodFromNumberOfMonths = (value: number): MalouTimeScalePeriod => {
    switch (value) {
        case 1:
            return MalouTimeScalePeriod.LAST_MONTH;
        case 2:
            return MalouTimeScalePeriod.LAST_TWO_MONTHS;
        case 3:
            return MalouTimeScalePeriod.LAST_THREE_MONTHS;
        case 4:
            return MalouTimeScalePeriod.LAST_FOUR_MONTHS;
        case 5:
            return MalouTimeScalePeriod.LAST_FIVE_MONTHS;
        case 6:
            return MalouTimeScalePeriod.LAST_SIX_MONTHS;
        case 7:
            return MalouTimeScalePeriod.LAST_SEVEN_MONTHS;
        case 8:
            return MalouTimeScalePeriod.LAST_EIGHT_MONTHS;
        case 9:
            return MalouTimeScalePeriod.LAST_NINE_MONTHS;
        case 10:
            return MalouTimeScalePeriod.LAST_TEN_MONTHS;
        case 11:
            return MalouTimeScalePeriod.LAST_ELEVEN_MONTHS;
        case 12:
            return MalouTimeScalePeriod.LAST_TWELVE_MONTHS;
        default:
            return MalouTimeScalePeriod.LAST_SIX_MONTHS;
    }
};
