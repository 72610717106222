import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-how-wheel-of-fortune-works',
    templateUrl: './how-wheel-of-fortune-works.component.html',
    styleUrls: ['./how-wheel-of-fortune-works.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, IllustrationPathResolverPipe],
})
export class HowWheelOfFortuneWorksComponent {
    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    readonly Illustration = Illustration;

    constructor(private readonly _screenSizeService: ScreenSizeService) {}
}
