<div [matTooltip]="syncTooltip()">
    <button
        class="malou-btn-icon--secondary btn-xl"
        data-testid="reviews-sync-btn"
        mat-icon-button
        [id]="id()"
        [disabled]="isSynchronizationLoading()"
        (click)="onClick()">
        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SYNC" [ngClass]="{ 'malou-spin': isSynchronizationLoading() }"></mat-icon>
    </button>
</div>
