import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserService {
    private _isChrome: boolean;

    constructor() {
        this._isChrome = !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
    }

    public isChromeBrowser(): boolean {
        return this._isChrome;
    }
}
