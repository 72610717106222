import { Routes } from '@angular/router';

import { ADMIN_ROUTES } from ':modules/admin/admin.routing';
import { HeaderRouterComponent } from ':modules/header-router/header-router.component';
import { USERS_ROUTES } from ':modules/user/users.routing';

export const HEADER_ONLY_ROUTER_ROUTES: Routes = [
    {
        path: '',
        component: HeaderRouterComponent,
        children: [
            {
                path: 'users',
                loadChildren: () => USERS_ROUTES,
            },
            {
                path: 'admin',
                runGuardsAndResolvers: 'always',
                loadChildren: () => ADMIN_ROUTES,
            },
        ],
    },
];
