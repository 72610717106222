@if (restaurant()) {
    <div class="flex items-center justify-between gap-x-3">
        <div>
            <div class="malou-text-10--regular text-malou-color-text-1">
                {{ restaurant() | applySelfPure: 'getDisplayName' }}
            </div>
            <div class="malou-text-8--regular italic text-malou-color-text-2">
                {{ restaurant() | applySelfPure: 'getFormattedAddressWithPostalCodeAndLocality' }}
            </div>
        </div>
        <div class="w-[100px]">
            <app-loader-progress
                [progress]="generationProgress"
                [progressCompletedAction]="generationCompletedAction()"
                [progressCompletedText]="generationCompletedText()"></app-loader-progress>
        </div>
    </div>
}
