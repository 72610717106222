<div class="flex gap-4 md:flex-col">
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">{{ totalFollowers().current | shortNumber }}</div>
            @if (totalFollowers().diff) {
            <app-number-evolution
                [value]="totalFollowers().diff ?? null"
                [displayedValue]="totalFollowers().diff | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">
            {{ 'statistics.social_networks.community.followers' | translate }}
        </div>
    </div>

    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">{{ totalNewFollowers().current | shortNumber }}</div>
            @if (totalNewFollowers().diff) {
            <app-number-evolution
                [value]="totalNewFollowers().diff ?? null"
                [displayedValue]="totalNewFollowers().diff | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">
            {{ 'statistics.social_networks.community.new_followers' | translate }}
        </div>
    </div>
</div>
