import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';

export interface PlatformPresentationStepModalResult {
    openHelpModal?: boolean;
}
export interface PlatformPresentationStepTranslations {
    title: string;
    primaryButton: string;
    secondaryButton: string;
    description: string;
}

type AuthorizedPlatformKeys =
    | PlatformKey.TRIPADVISOR
    | PlatformKey.FOURSQUARE
    | PlatformKey.LAFOURCHETTE
    | PlatformKey.PAGESJAUNES
    | PlatformKey.YELP;

interface PlatformPresentationSharedDataType {
    platformKey: AuthorizedPlatformKeys;
    socialId?: string;
}

@Component({
    selector: 'app-platform-presentation-step',
    templateUrl: './platform-presentation-step.component.html',
    styleUrls: ['./platform-presentation-step.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformPresentationStepComponent extends BaseStepComponent<
    PlatformPresentationSharedDataType,
    PlatformPresentationStepModalResult
> {
    translations = input.required<PlatformPresentationStepTranslations>();
    readonly ButtonStyle = ButtonStyle;
}
