<app-skeleton skeletonClass="secondary-bg h-[200px] w-full">
    <div class="flex w-full items-center justify-between px-6.5 pt-3">
        <div class="flex w-full items-center gap-x-3">
            <app-skeleton skeletonClass="!h-[50px] !w-[50px] !rounded-full" [useContainer]="false"></app-skeleton>
            <div class="flex flex-col gap-y-1">
                <app-skeleton skeletonClass="!h-[25px] !w-[200px] md:!w-[100px]" [useContainer]="false"></app-skeleton>
                <app-skeleton skeletonClass="!h-[15px] !w-[300px] md:!w-[150px]" [useContainer]="false"></app-skeleton>
            </div>
        </div>

        @if (!isPhoneScreen()) {
            <app-skeleton
                gapClass="gap-x-2"
                flexDirection="flex-row"
                skeletonClass="!h-[50px] !w-[50px] rounded-xl"
                [count]="4"
                [useContainer]="false"></app-skeleton>
        }
        @if (isPhoneScreen()) {
            <app-skeleton skeletonClass="!h-[50px] !w-[50px] rounded-xl" [useContainer]="false"></app-skeleton>
        }
    </div>
    <div class="w-full px-6.5 py-3">
        <div class="flex w-full items-center gap-x-3">
            <app-skeleton skeletonClass="!h-[110px] !w-[110px]" [useContainer]="false"></app-skeleton>
            <app-skeleton gapClass="gap-y-2" skeletonClass="!h-[20px] !w-[200px]" [count]="4" [useContainer]="false"></app-skeleton>
        </div>
    </div>
</app-skeleton>
