import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { InformationUpdatesStatus, informationUpdateSupportedKeyMap, InformationUpdateSupportedPlatformKey } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Component({
    selector: 'app-information-update-state-error',
    standalone: true,
    imports: [TranslateModule, CommonModule, MatIconModule, IncludesPipe, PlatformNamePipe],
    templateUrl: './information-update-state-error.component.html',
    styleUrl: './information-update-state-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateStateErrorComponent {
    readonly InformationUpdatesStatus = InformationUpdatesStatus;
    readonly SvgIcon = SvgIcon;
    readonly informationUpdateSupportedKeyMap = informationUpdateSupportedKeyMap;
    readonly platformAccessStatus = input.required<InformationUpdatesStatus>();
    readonly platformKey = input.required<InformationUpdateSupportedPlatformKey>();

    private readonly _translateService = inject(TranslateService);

    constructor() {
        this._translateService.instant('informations.updates_state.errors.address_edit_changes_country');
    }
}
