<div
    class="edit-modal-container md:absolute md:bottom-0 md:h-[80vh]"
    [class.close-modal-container]="displayCloseModal"
    [class.close]="isClosed">
    <ng-container *ngTemplateOutlet="editModal"></ng-container>
</div>
<ng-template #editModal>
    <div class="malou-dialog h-full">
        <div class="malou-dialog__header">
            <div class="malou-text-18--bold">
                {{ 'information.suggestions.title' | translate }}
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="cancel(false)">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body h-full w-full overflow-auto">
            <table class="mat-expansion-panel-body w-full md:!hidden">
                <thead class="bg-malou-color-background-dark px-[15px]">
                    <tr>
                        <th class="malou-color-text-2 malou-text-12--medium">
                            {{ 'information.suggestions.information_source' | translate }}
                        </th>
                        <th class="malou-color-text-2 malou-text-12--medium">
                            <span>{{ 'information.suggestions.malou_card' | translate }}</span>
                        </th>
                        <th class="malou-color-text-2 malou-text-12--medium">
                            <span>{{ 'information.suggestions.suggested_changes' | translate }}</span>
                        </th>
                    </tr>
                </thead>
                @for (diff of comparisons; track diff) {
                    <tr class="px-[15px]" app-comparison-row [comparison]="diff" [platformName]="platformName"></tr>
                }
            </table>
            <div class="hidden flex-col md:flex">
                @for (diff of comparisons; track diff; let i = $index; let isLast = $last) {
                    <div
                        class="flex flex-col"
                        app-comparison-row
                        [platformName]="platformName"
                        [comparison]="diff"
                        [index]="i"
                        [lastRow]="isLast"></div>
                }
            </div>
        </div>

        @if (malouErrorCodeMessageTranslated()) {
            <div class="malou-text-12--medium mt-6 pl-[26px]" [innerHtml]="malouErrorCodeMessageTranslated()"></div>

            <div class="malou-dialog__footer sm:justify-between">
                <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="goGmbBusinessProfile()">
                    <div class="md:hidden">
                        {{ 'information.suggestions.pin_drop_required_cta' | translate }}
                    </div>
                    <div class="hidden md:block">
                        {{ 'information.suggestions.pin_drop_required_cta_responsive' | translate }}
                    </div>
                </button>
            </div>
        } @else {
            <div class="malou-dialog__footer sm:justify-between">
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="cancel(true)">
                    {{ 'information.suggestions.reject_changes' | translate }}
                </button>
                <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="save()">
                    <div class="md:hidden">
                        {{ 'information.suggestions.accept_all_changes' | translate }}
                    </div>
                    <div class="hidden md:block">
                        {{ 'information.suggestions.accept_all' | translate }}
                    </div>
                </button>
            </div>
        }
    </div>
</ng-template>
