import { z } from 'zod';

export const getDiagnosticByIdParamsValidator = z
    .object({
        diagnostic_id: z.string(),
    })
    .transform((data) => ({ diagnosticId: data.diagnostic_id }));

export type GetDiagnosticByIdParamsDto = z.infer<typeof getDiagnosticByIdParamsValidator>;

// --------------------------------------------------

export const getDiagnosticByEncryptedIdBodyValidator = z.object({
    diagnosticId: z.string(),
});

export type GetDiagnosticByEncryptedIdBodyDto = z.infer<typeof getDiagnosticByEncryptedIdBodyValidator>;
