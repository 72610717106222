import { Routes } from '@angular/router';

import { AuthGuard } from ':core/auth/auth.guard';
import { MaintenanceGuard, RedirectToProductionGuard } from ':core/maintenance/maintenance.guard';
import { LOGGED_IN_ROUTES } from ':shared/routes/logged-in.routes';
import { LOGGED_OUT_ROUTES } from ':shared/routes/logged-out.routes';

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => LOGGED_IN_ROUTES,
        canActivate: [AuthGuard, RedirectToProductionGuard, MaintenanceGuard],
        runGuardsAndResolvers: 'always',
        providers: [MaintenanceGuard, RedirectToProductionGuard],
    },
    {
        path: '',
        loadChildren: () => LOGGED_OUT_ROUTES,
    },
];
