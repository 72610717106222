import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { differenceBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, defer, forkJoin, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delayWhen, filter, map, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { PlatformsService } from ':core/services/platforms.service';
import { PostsService } from ':core/services/posts.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { SocialAccountsService } from ':core/services/social-accounts.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Platform, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

export interface IGAccount {
    username: string;
    profile_picture_url: string;
    name: string;
    followers_count: number;
    media_count: number;
    biography: string;
}

interface MappedIGAccount {
    userName: string;
    profilePicUrl: string;
    name: string;
    followersCount: number;
    mediaCount: number;
    biography: string;
}

interface SocialAccount {
    platformKey: string;
    socialId: string;
    score?: number;
}

const NB_RECOMMENDED_ACCOUNT_DISPLAYED = 3;

enum SearchResultStatus {
    FOUND = 'found',
    NOT_FOUND = 'not_found',
}

@Component({
    selector: 'app-edit-inspiring-accounts-dialog',
    templateUrl: './edit-inspiring-accounts-dialog.component.html',
    styleUrls: ['./edit-inspiring-accounts-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        ButtonComponent,
        SearchComponent,
        MatProgressSpinnerModule,
        SkeletonComponent,
        LazyLoadImageModule,
        MatTooltipModule,
        AsyncPipe,
        ShortNumberPipe,
        ShortTextPipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        TranslateModule,
    ],
})
export class EditInspiringAccountsDialogComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly SearchResultStatus = SearchResultStatus;
    readonly MAX_WATCHED_ACCOUNTS = 10;

    readonly searchText$ = new BehaviorSubject('');

    restaurant: Restaurant;
    accounts = [];
    searching = false;
    watchedAccounts: MappedIGAccount[] = [];
    accountsList: SocialAccount[] = [];
    recommendedAccounts: Partial<IGAccount>[] = [];
    currentIndex = NB_RECOMMENDED_ACCOUNT_DISPLAYED;
    searchResult: {
        account: IGAccount | null;
        status: string | null;
    } = { account: null, status: null };
    canFetch = true;
    dirtyModal = false;
    displayCloseModal = false;
    originalWatchedAccounts: MappedIGAccount[] = [];

    readonly isSaving = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<EditInspiringAccountsDialogComponent>,
        private readonly _socialAccountsService: SocialAccountsService,
        private readonly _platformsService: PlatformsService,
        private readonly _postsService: PostsService,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            watchedAccounts: MappedIGAccount[];
            igPlatform: Platform;
            restaurant: Restaurant;
        }
    ) {}

    ngOnInit(): void {
        this.watchedAccounts = [...(this.data?.watchedAccounts || [])];
        this.originalWatchedAccounts = [...(this.data?.watchedAccounts || [])];
        this.restaurant = this.data.restaurant;
        this.searchText$
            .pipe(
                filter((text) => {
                    this.setSearchResult(null, null);
                    return !!text;
                }),
                debounceTime(400),
                switchMap((text) => {
                    this.searching = true;
                    return this._postsService.igSearch(text, this.data.igPlatform._id).pipe(
                        map((res) => ({ ...res, msg: 'found' })),
                        catchError((error) => {
                            if (error.error?.message?.match(/Invalid user id/)) {
                                return of({ data: null, error: true, msg: 'not_found' });
                            }
                            return of({ data: null, error: true, msg: 'unknown' });
                        })
                    );
                })
            )
            .subscribe({
                next: (result) => {
                    const account = result.data?.business_discovery;
                    const status = result.msg;
                    this.searching = false;
                    this.setSearchResult(account, status);
                },
                error: (err) => {
                    this.searching = false;
                    console.warn(err);
                },
            });

        const initialSetup$ = new Subject();
        this._socialAccountsService
            .recommended(this.data.igPlatform?.restaurantId)
            .pipe(
                map((res) => res.data),
                switchMap((accounts: SocialAccount[]) => {
                    const ownAccountsUsernames = this.watchedAccounts.map((wa) => wa.userName);
                    this.accountsList = accounts
                        ?.filter((acc) => !ownAccountsUsernames.includes(acc.socialId))
                        .sort((a, b) => {
                            if (!a.score) {
                                return !b.score ? 0 : -1;
                            }
                            if (!b.score) {
                                return 1;
                            }
                            return a.score > b.score ? -1 : 1;
                        });
                    return defer(() => of(this.currentIndex)).pipe(
                        switchMap((idx) =>
                            forkJoin(
                                this.accountsList.slice(idx - NB_RECOMMENDED_ACCOUNT_DISPLAYED, idx).map((acc) =>
                                    this._postsService.igSearch(acc.socialId, this.data.igPlatform._id).pipe(
                                        map((res) => res.data),
                                        map((account) => account.business_discovery)
                                    )
                                )
                            )
                        ),
                        retryWhen((errors) =>
                            errors.pipe(
                                tap((error) => {
                                    if (error?.error?.message?.match(/Invalid user id/)) {
                                        this.currentIndex += 1;
                                    } else {
                                        throw error;
                                    }
                                }),
                                delayWhen(() => timer(1))
                            )
                        )
                    );
                }),
                takeUntil(initialSetup$)
            )
            .subscribe({
                next: (accounts: IGAccount[]) => {
                    initialSetup$.next(true);
                    this.recommendedAccounts = accounts;
                },
                error: (err) => {
                    initialSetup$.next(true);
                    this._dialogService.open({
                        title: 'Oups..',
                        message: this.clarifyError(err),
                        variant: DialogVariant.ERROR,
                        primaryButton: {
                            label: this._translate.instant('common.return'),
                        },
                    });
                    console.warn('err :>> ', err);
                },
            });
    }

    cancel(): void {
        if (this._haveWatchedAccountsChanged()) {
            this.displayCloseModal = true;
        } else {
            this._dialogRef.close({ dirtyModal: false });
        }
    }

    isChosen(account: IGAccount): boolean {
        return !!this.watchedAccounts.find((acc) => acc.userName === account?.username);
    }

    loadMoreAccounts(): void {
        this.fetchNewAccounts(NB_RECOMMENDED_ACCOUNT_DISPLAYED);
    }

    fetchNewAccounts(nbAccount: number, indexToReplaceAccount: number | null = null): void {
        if (this.canFetch) {
            defer(() => of(this.currentIndex))
                .pipe(
                    switchMap((idx) => {
                        this.canFetch = false;
                        if (idx >= this.accountsList.length) {
                            return throwError(new Error('no_more_accounts'));
                        }
                        const requests: Observable<IGAccount>[] = new Array(nbAccount).fill(
                            defer(() =>
                                this._postsService.igSearch(this.accountsList[this.currentIndex++].socialId, this.data.igPlatform._id)
                            ).pipe(
                                map((res) => res.data),
                                map((account) => account.business_discovery),
                                retryWhen((errors) =>
                                    errors.pipe(
                                        tap((error) => {
                                            if (!error?.error?.message?.match(/Invalid user id/)) {
                                                throw error;
                                            }
                                        }),
                                        delayWhen(() => timer(1))
                                    )
                                )
                            )
                        );
                        return forkJoin(requests);
                    })
                )
                .subscribe({
                    next: (accounts) => {
                        if (indexToReplaceAccount !== null) {
                            this.recommendedAccounts[indexToReplaceAccount] = accounts[0];
                        } else {
                            this.recommendedAccounts = [...this.recommendedAccounts, ...accounts];
                        }

                        this.canFetch = true;
                        if (indexToReplaceAccount === null) {
                            setTimeout(() => {
                                this._scrollToSuggestionContainerBottom();
                            }, 0);
                        }
                    },
                    error: (err) => {
                        this.recommendedAccounts = [];
                        this.canFetch = true;
                        if (err?.message?.match(/no_more_accounts/)) {
                            if (this.recommendedAccounts.filter((ra) => !!ra.username).length === 0) {
                                // No more recommended accounts
                                this._dialogService.open({
                                    title: this._translate.instant('inspirations.edit.no_more_accounts'),
                                    message: this._translate.instant('inspirations.edit.no_more_accounts_txt'),
                                    variant: DialogVariant.ERROR,
                                    primaryButton: {
                                        label: this._translate.instant('common.return'),
                                    },
                                });
                            }
                        } else {
                            this._dialogService.open({
                                title: this._translate.instant('inspirations.edit.problem_occurred'),
                                message: this.clarifyError(err),
                                variant: DialogVariant.ERROR,
                                primaryButton: {
                                    label: this._translate.instant('common.return'),
                                },
                            });
                        }
                    },
                });
        }
    }

    clarifyError(err: any): string {
        if (err?.error?.message?.match(/unexpected error has occurred/)) {
            return this._translate.instant('inspirations.edit.no_ig_results');
        }
        if (err?.error?.message?.match(/Application request limit/)) {
            return this._translate.instant('inspirations.edit.request_limit');
        }
        if (err?.error?.message?.match(/Need credentialId/)) {
            return this._translate.instant('inspirations.edit.no_available_access');
        }
        return this._translate.instant('inspirations.edit.try_to_fix');
    }

    setSearchResult(account: IGAccount | null, status: string | null): void {
        this.searchResult.account = account;
        this.searchResult.status = status;
    }

    choose(account: IGAccount): void {
        if (!this.watchedAccounts.find((wa) => wa.userName === account.username)) {
            if (this.watchedAccounts.length < this.MAX_WATCHED_ACCOUNTS) {
                this.dirtyModal = true;
                const newAccount = {
                    userName: account?.username,
                    profilePicUrl: account?.profile_picture_url,
                    name: account?.name,
                    followersCount: account?.followers_count,
                    mediaCount: account?.media_count,
                    biography: account?.biography,
                };
                this.watchedAccounts.push(newAccount);
                this.searchText$.next('');
            }
        }
    }

    remove(username: string): void {
        this.dirtyModal = true;
        this.watchedAccounts = this.watchedAccounts.filter((acc) => acc.userName !== username);
    }

    typed($event: string): void {
        this.searchText$.next($event);
    }

    save(): void {
        if (this._haveWatchedAccountsChanged()) {
            this.isSaving.set(true);
            this._platformsService
                .upsert(this.restaurant._id, { key: PlatformKey.INSTAGRAM, watchedAccounts: this.watchedAccounts })
                .subscribe({
                    next: () => {
                        this.isSaving.set(false);
                        this._dialogRef.close({ dirtyModal: true });
                    },
                    error: (err) => {
                        this.watchedAccounts.pop();
                        this.isSaving.set(false);
                        if (err.status === 403) {
                            return;
                        }
                        this._dialogService.open({
                            title: this._translate.instant('inspirations.edit.problem_occurred'),
                            message: this.clarifyError(err),
                            variant: DialogVariant.ERROR,
                            primaryButton: {
                                label: this._translate.instant('common.return'),
                            },
                        });
                    },
                });
        } else {
            this._dialogRef.close({ dirtyModal: false });
        }
    }

    confirmClose(): void {
        this._dialogRef.close({ dirtyModal: this._haveWatchedAccountsChanged() });
    }

    private _scrollToSuggestionContainerBottom(): void {
        const suggestionsContainer = this.screenSizeService.isPhoneScreen
            ? (document.querySelector('.suggestions-mobile-container') as HTMLElement)
            : (document.querySelector('.suggestions-container') as HTMLElement);
        suggestionsContainer.scrollTo({
            top: suggestionsContainer.scrollHeight,
            behavior: 'smooth',
        });
    }

    private _haveWatchedAccountsChanged(): boolean {
        const difference1 = differenceBy(this.watchedAccounts, this.originalWatchedAccounts, 'userName');
        const difference2 = differenceBy(this.originalWatchedAccounts, this.watchedAccounts, 'userName');
        return difference1.length > 0 || difference2.length > 0;
    }
}
