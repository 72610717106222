import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-ratings-filters',
    templateUrl: './ratings-filters.component.html',
    styleUrls: ['./ratings-filters.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule, NgClass, MatIconModule, PluralTranslatePipe, IncludesPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingsFiltersComponent {
    readonly ratings = input.required<number[]>();
    readonly availableRatings = input<number[]>([0, 1, 2, 3, 4, 5]);
    readonly disabled = input<boolean>(false);
    readonly onToggleRating = output<number>();

    readonly SvgIcon = SvgIcon;

    toggleRating(rating: number): void {
        this.onToggleRating.emit(rating);
    }
}
