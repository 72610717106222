@let base = ['/restaurants', selectedRestaurant()?._id ?? ''];
@if (isBrandBusiness()) {
    <app-sidenav-content-route
        [options]="{
            text: 'sidenav_content.information' | translate,
            routerLink: base | concat: ['seo', 'informations'],
            svgIcon: SvgIcon.EDIT,
        }" />
} @else {
    <app-sidenav-content-route-group
        [options]="{
            text: 'sidenav_content.local_seo' | translate,
            routerLink: base | concat: ['seo'],
            svgIcon: SvgIcon.SEARCH,
        }"
        [childrenOptions]="[
            {
                text: 'sidenav_content.information' | translate,
                routerLink: base | concat: ['seo', 'informations'],
                svgIcon: SvgIcon.DOT,
                isSmallSvgIcon: true,
                shouldHideSvgIconOnOpenedSidenav: true,
            },
            {
                text: 'sidenav_content.posts' | translate,
                routerLink: base | concat: ['seo', 'posts'],
                svgIcon: SvgIcon.DOT,
                isSmallSvgIcon: true,
                shouldHideSvgIconOnOpenedSidenav: true,
            },
        ]" />
}
