import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';
import { ROUTER_LINK_ACTIVE_OPTIONS } from '../../sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';

@Component({
    selector: 'app-sidenav-content-e-reputation-routes',
    templateUrl: './sidenav-content-e-reputation-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule, ConcatPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentEReputationRoutesComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _router = inject(Router);

    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly isBrandBusiness = computed(() => !!this.selectedRestaurant()?.isBrandBusiness());
    readonly SvgIcon = SvgIcon;

    private readonly _onNavigationEnd$ = this._router.events.pipe(filter((event) => event.type === EventType.NavigationEnd));
    private readonly _onNavigationEnd = toSignal(this._onNavigationEnd$);

    constructor() {
        this._initEffectNavigateIfBrandBusinessIsSelected();
    }

    _initEffectNavigateIfBrandBusinessIsSelected(): void {
        effect(() => {
            const selectedRestaurant = this.selectedRestaurant();
            if (!selectedRestaurant || !selectedRestaurant.isBrandBusiness()) {
                return;
            }
            this._onNavigationEnd(); // Used to trigger this effect
            const reputationUrl = `/restaurants/${selectedRestaurant._id}/reputation`;
            const isSeoPostsRouteActive = this._router.isActive(reputationUrl, ROUTER_LINK_ACTIVE_OPTIONS);
            if (isSeoPostsRouteActive) {
                const seoInformationUrl = `/restaurants/${selectedRestaurant._id}/dashboard`;
                this._router.navigate([seoInformationUrl]);
                return;
            }
        });
    }
}
