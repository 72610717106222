import { NgClass } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    input,
    InputSignal,
    OnInit,
    output,
    OutputEmitterRef,
    Signal,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, forkJoin, Observable, of, Subject } from 'rxjs';

import { ApiResult } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { formKeysOrder, GenerationForm, GenerationFormWithCountry } from ':modules/generator/store/generation-forms.interface';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    PersonalizeGeneratorLocationsComponent,
    PersonalizeGeneratorLocationsData,
} from ':shared/components/personalize-generator-locations/personalize-generator-locations.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Step } from ':shared/interfaces/step.interface';
import { IRestaurant, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { ApiLocationOption } from '../../../generator/generator.component';

@Component({
    selector: 'app-keywords-generation-form-answers',
    templateUrl: './keywords-generation-form-answers.component.html',
    styleUrls: ['./keywords-generation-form-answers.component.scss'],
    standalone: true,
    imports: [NgClass, MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, ApplySelfPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsGenerationFormAnswersComponent implements OnInit {
    readonly hasSelectedKeywords: InputSignal<boolean> = input.required();
    readonly hasAnsweredToForm: InputSignal<boolean> = input.required();
    readonly generatorFormAnswers: InputSignal<GenerationForm> = input.required();

    readonly SvgIcon = SvgIcon;
    readonly openGeneratorFormModal: OutputEmitterRef<void> = output<void>();

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly formToggled = signal(false);

    readonly generatorKeys = computed(() =>
        (Object.keys(this.generatorFormAnswers()) ?? []).sort(
            (a: keyof GenerationForm, b: keyof GenerationForm) => formKeysOrder.indexOf(a) - formKeysOrder.indexOf(b)
        )
    );

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly postalCode = computed(() => {
        const restaurant = this._restaurant();
        if (!restaurant) {
            return '';
        }
        return restaurant.bricksPostalCode ?? restaurant.address?.postalCode ?? '';
    });

    readonly apiLocationId = computed(() => {
        const restaurant = this._restaurant();
        if (!restaurant) {
            return '';
        }
        return restaurant.keywordToolApiLocationId ?? '';
    });

    readonly apiLocationOptions = signal([] as ApiLocationOption[]);

    readonly city = computed(() => {
        const apiLocationId = this.apiLocationId();
        const apiLocation = this.apiLocationOptions().find((apiLocationOption) => apiLocationOption.apiLocationId === apiLocationId);
        return apiLocation?.apiCanonicalName;
    });

    private readonly _restaurant$ = this._restaurantsService.restaurantSelected$.pipe(
        filter(Boolean),
        filter((restaurant) => !restaurant.isBrandBusiness()),
        takeUntilDestroyed(this._destroyRef)
    );
    private readonly _restaurant: Signal<Restaurant> = toSignal(this._restaurant$, {
        initialValue: this._restaurantsService.currentRestaurant,
    });

    constructor(
        private readonly _keywordsService: KeywordsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _translateService: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _formBuilder: FormBuilder,
        private readonly _httpClient: HttpClient,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this._initApiLocationOptionsAndInitialValue();
    }

    toggleForm(): void {
        this.formToggled.update((currentValue) => !currentValue);
    }

    onOpenGeneratorFormModal(): void {
        this.openGeneratorFormModal.emit();
    }

    openDuplicateGeneratorModal(): void {
        const steps: Step[] = [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                nextFunction$: (restaurantSelectionData: RestaurantsSelectionData) =>
                    of(this._populateSelectedRestaurantsWithLocationData(restaurantSelectionData)),
            },
            {
                component: PersonalizeGeneratorLocationsComponent,
                subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.personalize_generator_locations.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (personalizeGeneratorLocationsData: PersonalizeGeneratorLocationsData[]) =>
                    this._displayConfirmModalIfNeededAndDuplicateGenerators$(personalizeGeneratorLocationsData),
            },
        ];
        this._customDialogService.open(StepperModalComponent, {
            width: '1000px',
            data: {
                steps,
                title: this._translateService.instant('duplicate_to_restaurants_dialog.title'),
                initialData: {
                    skipOwnRestaurant: true,
                    withoutBrandBusiness: true,
                    selectedRestaurants: [],
                },
                sharedData: this.generatorFormAnswers,
                onSuccess: () => {
                    this._toastService.openSuccessToast(
                        this._translateService.instant('duplicate_to_restaurants_dialog.personalize_generator_locations.success')
                    );
                    this._customDialogService.closeAll();
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error);
                },
            },
        });
    }

    private _populateSelectedRestaurantsWithLocationData(
        restaurantSelectionData: RestaurantsSelectionData
    ): PersonalizeGeneratorLocationsData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const apiLocation = restaurant.keywordToolApiLocationId
                    ? this.apiLocationOptions().find(
                          (apiLocationOption) => apiLocationOption.apiLocationId === restaurant.keywordToolApiLocationId
                      )
                    : null;
                const locationForm = this._formBuilder.group({
                    apiLocation: new FormControl(apiLocation, [Validators.required]),
                    touristics: [[] as string[]],
                    postalCode: new FormControl(restaurant.bricksPostalCode ?? restaurant.address?.postalCode ?? '', [Validators.required]),
                });
                return {
                    restaurant,
                    locationForm,
                };
            }) || []
        );
    }

    private _displayConfirmModalIfNeededAndDuplicateGenerators$(
        restaurantWithLocationDatas: PersonalizeGeneratorLocationsData[]
    ): Observable<any> {
        const doesSomeRestaurantHaveGeneratorsData = restaurantWithLocationDatas.some((restaurantWithLocationData) => {
            const restaurant = restaurantWithLocationData.restaurant;
            return restaurant.bricksPostalCode || restaurant.bricks?.length > 0;
        });
        if (!doesSomeRestaurantHaveGeneratorsData) {
            return this._duplicateGenerators$(restaurantWithLocationDatas);
        }
        const subject = new Subject<boolean>();
        this._dialogService.open({
            title: this._translateService.instant('duplicate_to_restaurants_dialog.personalize_generator_locations.confirm_modal.title'),
            message: this._translateService.instant(
                'duplicate_to_restaurants_dialog.personalize_generator_locations.confirm_modal.message'
            ),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translateService.instant(
                    'duplicate_to_restaurants_dialog.personalize_generator_locations.confirm_modal.continue_and_replace'
                ),
                action: () => {
                    this._duplicateGenerators$(restaurantWithLocationDatas).subscribe({
                        next: () => subject.next(true),
                        error: (err) => {
                            subject.error(err);
                        },
                    });
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.return'),
            },
        });
        return subject.asObservable();
    }

    private _duplicateGenerators$(restaurantWithLocationDatas: PersonalizeGeneratorLocationsData[]): Observable<ApiResult<IRestaurant>[]> {
        const restaurant = this._restaurant();
        if (!restaurant) {
            return of([]);
        }
        return forkJoin(
            restaurantWithLocationDatas.map((restaurantWithLocationData) => {
                const generationFormWithCountry: GenerationFormWithCountry = Object.assign(
                    { ...this.generatorFormAnswers() },
                    {
                        country: restaurantWithLocationData.restaurant.address?.regionCode ?? '',
                        postalCode: restaurantWithLocationData.locationForm.value.postalCode,
                        touristics: restaurantWithLocationData.locationForm.value.touristics,
                        apiLocationId:
                            restaurantWithLocationData.locationForm.value.apiLocation?.apiLocationId ??
                            restaurantWithLocationData.restaurant.keywordToolApiLocationId,
                    }
                );
                return this._keywordsService.sendBricksForm(restaurantWithLocationData.restaurant._id, generationFormWithCountry);
            })
        );
    }

    private _onDuplicationError(error: unknown): void {
        if (error instanceof HttpErrorResponse) {
            this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
            return;
        }
        this._toastService.openErrorToast(this._translateService.instant('hashtags.duplication_failed'));
    }

    private _initApiLocationOptionsAndInitialValue(): void {
        this._httpClient.get('../../../assets/jsons/api_locations.json').subscribe((res: ApiLocationOption[]) => {
            this.apiLocationOptions.set(res);
        });
    }
}
