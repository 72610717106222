import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ValueWithDifference } from ':shared/interfaces/value-with-difference.interface';
import { Address } from ':shared/models';

@Component({
    selector: 'app-comparison-address',
    templateUrl: './comparison-address.component.html',
    styleUrls: ['./comparison-address.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ComparisonAddressComponent implements OnInit {
    @Input() address: Partial<Address>;
    @Input() referenceAddress: Partial<Address>;

    addressFieldsWithDifference: ValueWithDifference<string>[] = [];

    ngOnInit(): void {
        this.addressFieldsWithDifference = this.getAddressFieldsToDisplay().map(
            (key: keyof Address): ValueWithDifference<string> => ({
                value: (this.address[key] ?? '') as string,
                hasDifference: !!this.referenceAddress?.[key] && this.address[key] !== this.referenceAddress?.[key],
            })
        );
    }

    getAddressFieldsToDisplay(): (keyof Address)[] {
        return ['formattedAddress', 'postalCode', 'locality', 'country'];
    }
}
