import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';

import { ToastItemData, ToastService } from ':core/services/toast.service';

import { slideInAnimation } from './animations/slide-in.animation';
import { slideOutAnimation } from './animations/slide-out.animation';
import { triggerChildAnimation } from './animations/trigger-child.animation';
import { verticalCollapseAnimation } from './animations/vertical-collapse.animation';
import { ToastItemComponent } from './toast-item/toast-item.component';

@Component({
    selector: 'app-toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['toast.component.scss'],
    animations: [slideInAnimation, slideOutAnimation, verticalCollapseAnimation, triggerChildAnimation],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ToastItemComponent],
})
export class ToastComponent implements OnInit {
    items: WritableSignal<ToastItemData[]> = signal([]);

    constructor(public toastService: ToastService) {}

    ngOnInit(): void {
        this.toastService.items$.subscribe((item) => {
            if (item) {
                this.items.update((items) => [...items, item]);
            }
        });
    }

    dismissItem(index: number): void {
        this.items.update((items) => {
            items.splice(index, 1);
            return [...items];
        });
    }
}
