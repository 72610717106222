import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces/kill-subscriptions.interface';
import { Comment, CommentsFilters, Pagination, Restaurant } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { CommentPreviewComponent } from '../comment-preview/comment-preview.component';
import { CommentsAndMentionsCounts, CommentsService } from '../comments.service';
import { NoCommentViewComponent } from '../no-comment-view/no-comment-view.component';
import { editCommentCount, editMentionCount, editPagination } from '../store/comments.actions';
import { selectCommentsFilters } from '../store/comments.reducer';

const DEFAULT_PAGINATION = { pageSize: 24, pageNumber: 0, total: 0 };
@Component({
    selector: 'app-comments-list-view',
    templateUrl: './comments-list-view.component.html',
    styleUrls: ['./comments-list-view.component.scss'],
    standalone: true,
    imports: [
        SkeletonComponent,
        NoCommentViewComponent,
        InfiniteScrollModule,
        CommentPreviewComponent,
        ScrollToTopComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        LazyLoadImageModule,
    ],
})
@AutoUnsubscribeOnDestroy()
export class CommentsListViewComponent implements OnInit, KillSubscriptions {
    @Input() isUpdating = false;

    readonly killSubscriptions$: Subject<void> = new Subject();

    restaurant$ = this._restaurantsService.restaurantSelected$;
    restaurant: Restaurant;

    comments: Comment[];
    pagination: Pagination = DEFAULT_PAGINATION;
    filters$: Observable<CommentsFilters> = this._store
        .select(selectCommentsFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    filters: CommentsFilters;

    isLoading = true;
    loadingMore$ = new BehaviorSubject(false);
    fetchCommentsError: any = null;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _commentsService: CommentsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this.restaurant$.pipe(filter(Boolean), takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (restaurant: Restaurant) => (this.restaurant = restaurant),
        });

        this.filters$.subscribe({
            next: (newFilters) => {
                this.filters = newFilters;
            },
        });

        combineLatest([this.restaurant$, this.filters$, this._commentsService.shouldReload$])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(([restaurant, filters]: [Restaurant, CommentsFilters, boolean]) => {
                    this.isLoading = true;
                    this.restaurant = restaurant;
                    this.filters = filters;
                    return this._commentsService.getRestaurantCommentsPaginated(restaurant._id, DEFAULT_PAGINATION, filters);
                }),
                map((res) => res.data),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (data) => {
                    this.pagination = data.pagination;
                    this.updatePaginationInStore(this.pagination);
                    this.comments = data.comments;
                    this._editCounts(data.counts);
                    this.fetchCommentsError = null;
                    this.isLoading = false;
                },
                error: (err) => {
                    console.warn(err);
                    this.fetchCommentsError = err;
                    this.isLoading = false;
                },
            });

        this._commentsService.archivedComment$.pipe(takeUntil(this.killSubscriptions$)).subscribe((comment) => {
            try {
                this.comments[this.comments.findIndex((c) => c._id === comment._id)].archived = comment.archived;
                this.onCommentToggleArchive(comment);
            } catch (e) {
                console.warn(e);
            }
        });
    }

    onScroll(): void {
        if (!this.loadingMore$.value) {
            if (this._reachedMaximumMessages()) {
                return;
            }
            this.loadMoreComments();
        }
    }

    loadMoreComments(): void {
        this.loadingMore$.next(true);
        this._commentsService
            .getRestaurantCommentsPaginated(
                this.restaurant._id,
                {
                    ...this.pagination,
                    pageNumber: this.pagination.pageNumber + 1,
                },
                this.filters
            )
            .subscribe({
                next: (res) => {
                    this.loadingMore$.next(false);
                    this.updatePaginationInStore(this.pagination);
                    this.pagination = res.data.pagination;
                    this.comments.push(...res.data.comments);
                    this._editCounts(res.data.counts);
                    this.fetchCommentsError = null;
                },
                error: (err) => {
                    this.loadingMore$.next(false);
                    console.warn(err);
                    this.fetchCommentsError = null;
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
    }

    updatePaginationInStore(pagination: Pagination): void {
        this._store.dispatch(editPagination({ pagination: { pageSize: (pagination.pageNumber + 1) * pagination.pageSize } }));
    }

    onCommentToggleArchive(comment: Comment): void {
        if (comment.archived && this.filters.archived) {
            return;
        }
        if (comment.archived && !this.filters.archived) {
            this.comments.splice(
                this.comments.findIndex((c) => c._id === comment._id),
                1
            );
        }
    }

    private _editCounts(counts: CommentsAndMentionsCounts): void {
        this._store.dispatch(editCommentCount({ commentCount: counts.commentCount }));
        this._store.dispatch(editMentionCount({ mentionCount: counts.mentionCount }));
    }

    private _reachedMaximumMessages(): boolean {
        return this.pagination.pageNumber * this.pagination.pageSize >= this.pagination.total;
    }
}
