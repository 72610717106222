import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    ApplicationLanguage,
    FrenchTutoiementVouvoiement,
} from '@malou-io/package-utils';

import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-ai-post-settings-modal-tab',
    templateUrl: './ai-post-settings-modal-tab.component.html',
    styleUrls: ['./ai-post-settings-modal-tab.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatRadioModule,
        MatFormFieldModule,
        TranslateModule,
        InputTextComponent,
        SimpleSelectComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
        TextAreaComponent,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        SelectChipListComponent,
        TextAreaComponent,
        CdkTextareaAutosize,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPostSettingsModalTabComponent {
    readonly aiSettingsContext = inject(AiSettingsContext);
    readonly postSettingsForm = input.required<
        FormGroup<{
            denomination: FormControl<FrenchTutoiementVouvoiement>;
            tone: FormControl<AiPostSettingsTone[]>;
            length: FormControl<AiPostSettingsLength>;
            emoji: FormControl<AiPostGenerationEmojiStatus>;
            prompt: FormControl<string | null>;
        }>
    >();
    readonly tones = Object.values(AiPostSettingsTone);
    readonly lengths = Object.values(AiPostSettingsLength);
    readonly emojis = Object.values(AiPostGenerationEmojiStatus);
    readonly tutoiementVouvoiement = Object.values(FrenchTutoiementVouvoiement);

    private readonly _translateService = inject(TranslateService);

    readonly isFrench = computed(() => this.aiSettingsContext.restaurantAiSettings()?.defaultLanguageResponse === ApplicationLanguage.FR);

    emojiDisplayWithEnum = (value: AiPostGenerationEmojiStatus): string =>
        this._translateService.instant(`enums.ai_post_settings_emoji.${value}`);
    lengthDisplayWithEnum = (value: AiPostSettingsLength): string =>
        this._translateService.instant(`enums.ai_post_settings_length.${value}`);
    toneDisplayWithEnum = (value: AiPostSettingsTone): string => this._translateService.instant(`enums.ai_post_settings_tone.${value}`);
}
