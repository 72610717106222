import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReviewResponseDto, SearchPrivateReviewBodyDto } from '@malou-io/package-dto';
import { ApiResultMetadataPagination, ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PrivateReviewsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/private-reviews`;

    constructor(private readonly _http: HttpClient) {}

    search(body: SearchPrivateReviewBodyDto): Observable<ApiResultV2<ReviewResponseDto[]>> {
        return this._http.post<ApiResultV2<ReviewResponseDto[], ApiResultMetadataPagination>>(this.API_BASE_URL, body);
    }
}
