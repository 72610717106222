<div class="flex h-full flex-col items-center gap-10 md:px-6 md:py-8">
    <div class="flex h-44 items-center">
        <img class="max-h-44" [src]="illustration() | illustrationPathResolver" />
    </div>

    <ng-container [ngTemplateOutlet]="loader"></ng-container>
</div>

<ng-template #loader>
    <div class="mb-20 flex items-center">
        @for (step of steps(); let index = $index; track $index; let isLast = $last) {
            @let stepStatus = getStepStatus | applyPure: index : currentStepIndex();

            @if (index === 0) {
                <div
                    class="step-link hidden"
                    [ngClass]="{
                        'md:block': index === currentStepIndex(),
                    }"></div>
            }

            <div
                class="step relative flex h-7 items-center justify-center rounded-full px-3 py-1"
                [ngClass]="{
                    'malou-bg-state-success': index < currentStepIndex(),
                    'malou-color-bg-success-lighter': index === currentStepIndex(),
                    'malou-color-bg-text-2--lighter': index > currentStepIndex(),
                    'md:hidden': index !== currentStepIndex(),
                }">
                @switch (stepStatus) {
                    @case (LoaderStepStatus.DONE) {
                        <mat-icon class="!h-4 !w-4 text-malou-color-text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    }
                    @case (LoaderStepStatus.CURRENT) {
                        <app-malou-spinner [size]="'small'" [color]="'#34B467'"></app-malou-spinner>
                    }
                    @case (LoaderStepStatus.TO_BE_DONE) {
                        <mat-icon class="!h-4 !w-4 text-malou-color-text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    }
                }

                <div
                    class="absolute top-[35px] text-center text-xs font-semibold"
                    [ngClass]="{
                        'step-label': index !== currentStepIndex(),
                        'current-step-label !text-malou-color-success': index === currentStepIndex(),
                    }">
                    {{ step }}
                </div>
            </div>

            @if (!isLast) {
                <div
                    class="step-link h-[1px] border"
                    [ngClass]="{
                        'border-dashed': index - currentStepIndex() >= -1,
                        'malou-border-color-success': index - currentStepIndex() < -1,
                        'malou-border-color-gradient-success': index - currentStepIndex() === -1,
                        'border-malou-text/20': index - currentStepIndex() >= 0,
                        'md:hidden': index !== currentStepIndex() && index !== currentStepIndex() - 1,
                    }"></div>
            } @else {
                <div
                    class="step-link hidden"
                    [ngClass]="{
                        'md:block': index === currentStepIndex(),
                    }"></div>
            }
        }
    </div>
</ng-template>
