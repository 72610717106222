import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReviewsEvolutionWithRange } from ':modules/reviews/reviews.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = ReviewsEvolutionWithRange | undefined;

@Injectable({ providedIn: 'root' })
export class ReviewsRatingsEvolutionCsvInsightsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectReviewsRatingsEvolutionData).pipe(take(1));
    }

    protected override _getCsvDataRows(data: Exclude<Data, undefined>): CsvAsStringArrays {
        return data.results.dataPerDay.map((d) => {
            const date = DateTime.fromObject({ ordinal: d.day, year: d.year }).toJSDate();
            const dateString = date.toISOString();
            const value = d.total.toString();
            return [dateString, value];
        });
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Review Count'];
    }
}
