import { Routes } from '@angular/router';

import { RestaurantListComponent } from './restaurant-list.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';

export const RESTAURANTS_LIST_ROUTES: Routes = [
    {
        path: '',
        component: RestaurantListComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: RestaurantsComponent,
            },
        ],
    },
];
