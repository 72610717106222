import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slice',
    standalone: true,
})
export class SlicePipe implements PipeTransform {
    transform(input: any[], { start = 0, end = 0 } = { start: 0, end: 0 }): any[] {
        return input.slice(start, end);
    }
}
