import { SimpleUserInterface } from ':modules/user/user';

import { KeywordAnalysis, KeywordAnalysisFactory } from './keyword-analysis';

export enum DescriptionSize {
    SHORT = 'short',
    LONG = 'long',
}

export class Description {
    _id: string;
    language: string;
    author?: SimpleUserInterface;
    size: DescriptionSize;
    text: string;
    keywordAnalysis: KeywordAnalysis;
    updatedAt: Date;
    createdAt: Date;

    public constructor(init?: Partial<Description>) {
        Object.assign(this, init);
    }

    toString(): string {
        return `${this.text}`;
    }
}

export class DescriptionFactory {
    static createTestDescription(): Description {
        return new Description({
            _id: 'some description id',
            language: 'fr',
            author: undefined,
            text: 'some text',
            updatedAt: new Date(),
            createdAt: new Date(),
            keywordAnalysis: KeywordAnalysisFactory.createTestKeywordAnalysis(),
        });
    }
}
