<div class="relative flex h-full max-h-[80vh] max-w-[80vw] flex-col justify-center overflow-hidden md:w-[100vw] md:max-w-none">
    <div class="absolute left-0 top-0 z-10 flex w-full items-center justify-between p-2">
        @if (data.actions.length > 0) {
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [matMenuTriggerFor]="actionsMenu"
                (click)="$event.stopPropagation()">
                <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
            </button>
        }

        <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
            @if (data.actions | includes: MediaAction.RENAME) {
                <button class="flex gap-x-3" mat-menu-item (click)="renameMedia()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.rename' | translate }}</span>
                </button>
            }
            @if (data.actions | includes: MediaAction.DUPLICATE) {
                <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateMenu">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.duplicate' | translate }}</span>
                </button>
            }
            @if ((data.actions | includes: MediaAction.EDIT_MEDIA) && data.medias[currentIndex].type !== 'video') {
                <button class="flex gap-x-3" mat-menu-item (click)="showEditModal()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.edit_image' | translate }}</span>
                </button>
            }
            @if (data.actions | includes: MediaAction.DOWNLOAD) {
                <button class="flex gap-x-3" mat-menu-item (click)="downloadSingleMedia()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.download' | translate }}</span>
                </button>
            }
            @if (data.actions | includes: MediaAction.DELETE) {
                <button class="flex gap-x-3" mat-menu-item (click)="deleteMedia()">
                    <mat-icon color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.delete' | translate }}</span>
                </button>
            }
        </mat-menu>

        <mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
            <button mat-menu-item (click)="duplicateMedia(DuplicationDestination.HERE)">
                <span class="malou-text-14--regular">
                    {{ 'gallery.duplicate_here' | translate }}
                </span>
            </button>
            <button mat-menu-item (click)="duplicateMedia(DuplicationDestination.OUT)">
                <span class="malou-text-14--regular">
                    {{ 'common.to_other_venues' | translate }}
                </span>
            </button>
        </mat-menu>
        <button class="malou-btn-icon--secondary btn-xl !ml-auto" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    @if (data.medias[currentIndex].type === 'photo') {
        <div class="flex max-h-[80vh] max-w-[80vw] flex-col items-center justify-center bg-white md:max-w-none">
            <img class="object-contain" [lazyLoad]="data.medias[currentIndex] | applySelfPure: 'getMediaUrl' : PictureSize.IG_FIT" />
        </div>
    }
    @if (data.medias[currentIndex].type === 'video') {
        <div class="flex h-[80vh] min-w-full max-w-[80vw] justify-center bg-white">
            <video
                class="object-contain object-center"
                type="video/mp4"
                controls
                muted
                loop
                autoplay
                disablepictureinpicture
                controlslist="nodownload noplaybackrate"
                [src]="data.medias[currentIndex] | applySelfPure: 'getMediaUrl'"></video>
        </div>
    }

    <div class="fixed left-0 top-[50%] flex w-[100vw] translate-y-[50%] transform px-7 md:px-4">
        @if (currentIndex > 0) {
            <mat-icon
                class="!h-10 !w-10 !text-malou-color-text-white shadow-sm"
                [svgIcon]="SvgIcon.CHEVRON_LEFT"
                (click)="previousMedia()"></mat-icon>
        }
        @if (currentIndex < data.medias.length - 1) {
            <mat-icon
                class="!ml-auto !h-10 !w-10 !text-malou-color-text-white shadow-sm"
                [svgIcon]="SvgIcon.CHEVRON_RIGHT"
                (click)="nextMedia()"></mat-icon>
        }
    </div>
</div>
