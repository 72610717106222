<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex gap-x-2">
            <span>{{ (data.upsertKind === UpsertKind.INSERT ? 'common.edit' : 'common.add') | translate }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-y-6" [formGroup]="formGroup">
            <app-input-text
                formControlName="name"
                [title]="'admin.organizations.name' | translate"
                [placeholder]="'admin.organizations.name' | translate">
            </app-input-text>
            <app-input-text
                formControlName="limit"
                [inputType]="'number'"
                [title]="'admin.organizations.limit' | translate"
                [placeholder]="'admin.organizations.limit' | translate">
            </app-input-text>
            <app-select-chip-list
                formControlName="users"
                [title]="'admin.organizations.users' | translate"
                [placeholder]="'admin.organizations.add_users' | translate"
                [values]="allUsers"
                [displayedOptionCount]="30"
                [displayWith]="userDisplayWith"
                [checkboxOption]="true">
            </app-select-chip-list>
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-10 md:grow" mat-raised-button [disabled]="formGroup.invalid" (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
