<div class="malou-dialog">
    <div class="malou-dialog__header">
        <span class="malou-text-18--bold">{{ 'roi.performance_score.reach_hundred' | translate }}</span>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body mb-8 mt-5 flex max-h-[65vh] flex-col gap-3">
        @for (row of performanceScoreRows$ | async; track $index) {
            <app-performance-score-item [item]="row"> </app-performance-score-item>
        }
    </div>
</div>
