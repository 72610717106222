<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <span>
                {{ 'information.information.info' | translate }}
            </span>
            <button class="malou-btn-icon" id="tracking_informations_general_infos_cancel_cross" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <form class="flex flex-col gap-y-6" [formGroup]="infoForm">
                <div class="relative">
                    <div class="relative self-stretch">
                        <img
                            class="malou-border-primary h-44 w-full rounded-xl object-cover object-center md:h-32"
                            alt="restaurant cover"
                            [src]="infoForm.controls['cover'].value?.urls?.smallCover ?? ('default_cover' | imagePathResolver)" />
                        <div class="absolute bottom-0 right-0 -translate-x-1/3 -translate-y-1/3">
                            <app-file-upload formControlName="coverFile" (onError)="coverErrorMessage = $event ?? ''"></app-file-upload>
                        </div>
                    </div>

                    <div class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
                        <img
                            class="malou-box-shadow h-28 w-28 rounded-full bg-white object-cover"
                            alt="restaurant logo"
                            [src]="infoForm.controls['logo'].value?.urls?.original ?? ('default_logo' | imagePathResolver)" />
                        <div class="absolute bottom-0 right-0">
                            <app-file-upload formControlName="logoFile" (onError)="logoErrorMessage = $event ?? ''"></app-file-upload>
                        </div>
                    </div>
                </div>

                <div class="malou-text-10 malou-color-state-error mt-14 italic">{{ coverErrorMessage ?? logoErrorMessage }}</div>

                <app-input-text
                    formControlName="name"
                    [title]="'information.information.business_name' | translate"
                    [placeholder]="'information.information.business_name' | translate"
                    [required]="true"></app-input-text>

                <ng-container [ngTemplateOutlet]="isLoading ? shimmerCategoriesTemplate : categoriesTemplate"></ng-container>
                @if (!restaurant.isBrandBusiness()) {
                    <ng-container [ngTemplateOutlet]="locationTemplate"></ng-container>
                }

                <!-- CONTACT -->
                <app-input-text
                    formControlName="website"
                    [title]="'information.information.website' | translate"
                    [placeholder]="'information.information.website' | translate"
                    [errorMessage]="infoForm.get('website')?.invalid ? infoForm.get('website')?.errors?.error : null"></app-input-text>

                @if (!restaurant.isBrandBusiness()) {
                    <app-input-text
                        formControlName="menuUrl"
                        [title]="'information.information.menu_url' | translate"
                        [placeholder]="'information.information.menu_url' | translate"
                        [errorMessage]="infoForm.get('menuUrl')?.invalid ? infoForm.get('menuUrl')?.errors?.error : null"></app-input-text>

                    <app-input-text
                        formControlName="reservationUrl"
                        [title]="'information.information.reservation_link' | translate"
                        [placeholder]="'information.information.reservation_link' | translate"
                        [errorMessage]="
                            infoForm.get('reservationUrl')?.invalid ? infoForm.get('reservationUrl')?.errors?.error : null
                        "></app-input-text>

                    <app-input-text
                        formControlName="orderUrl"
                        [title]="'information.information.order_link' | translate"
                        [placeholder]="'information.information.order_link' | translate"
                        [errorMessage]="
                            infoForm.get('orderUrl')?.invalid ? infoForm.get('orderUrl')?.errors?.error : null
                        "></app-input-text>
                }

                <div class="flex gap-x-3 md:flex-col md:gap-y-6" formGroupName="socialNetworkUrls">
                    <ng-container [ngTemplateOutlet]="socialNetworkUrlsTemplate"></ng-container>
                </div>

                <div class="flex gap-x-3 md:flex-col md:gap-y-6" formGroupName="phone">
                    <div class="w-1/3 md:w-full">
                        <app-select
                            formControlName="prefix"
                            [title]="'information.information.phone_prefix' | translate"
                            [placeholder]="'information.information.phone_prefix' | translate"
                            [values]="PHONE_CODES"
                            [displayWith]="phoneCodesDisplayWith"></app-select>
                    </div>

                    <div class="grow">
                        <app-input-text
                            formControlName="digits"
                            [title]="'information.information.phone' | translate"
                            [placeholder]="'information.information.phone' | translate"
                            [required]="!!initialPhone?.digits"
                            [errorMessage]="
                                infoForm.get(['phone', 'digits'])?.invalid ? infoForm.get(['phone', 'digits'])?.errors?.error : null
                            "></app-input-text>
                    </div>
                </div>

                <app-input-date-picker
                    formControlName="openingDate"
                    [title]="'information.information.business_opening_date' | translate"
                    [placeholder]="'information.information.business_opening_date' | translate"
                    [errorMessage]="
                        infoForm.get('openingDate')?.invalid ? infoForm.get('openingDate')?.errors?.error : null
                    "></app-input-date-picker>
            </form>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button
                class="malou-btn-raised--secondary !h-10 md:grow"
                id="tracking_informations_general_infos_cancel_button"
                mat-raised-button
                (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>

            <app-button
                class="md:grow"
                id="tracking_informations_general_infos_update"
                buttonClasses="!h-10 md:w-full"
                [text]="'common.update' | translate"
                [disabled]="isLoading || !infoForm.valid || !addressForm.valid || infoForm.pristine"
                (buttonClick)="save()"></app-button>
        </div>
    </div>
</ng-template>

<ng-template #shimmerCategoriesTemplate>
    <div class="flex flex-col gap-y-6">
        <app-skeleton heightClass="h-auto" skeletonClass="!h-16" [count]="2"></app-skeleton>
    </div>
</ng-template>

<ng-template #categoriesTemplate>
    <div class="flex flex-col gap-y-6" [formGroup]="infoForm">
        <app-select
            formControlName="category"
            [title]="'information.information.main_category' | translate"
            [placeholder]="'information.information.main_category' | translate"
            [required]="true"
            [errorMessage]="infoForm.get(['category'])?.errors?.error"
            [values]="categories"
            [displayWith]="categoryDisplayWith"></app-select>

        <div class="flex flex-col">
            <app-select-chip-list
                formControlName="categoryList"
                [maxSelectableValues]="10"
                [multiSelectionElementWrap]="true"
                [title]="'information.information.secondary_categories' | translate"
                [displayWith]="categoryDisplayWith"
                [values]="categories"
                [selectedValues]="categoryList"
                [hideSelectedValues]="true">
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                        <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span> {{ categoryDisplayWith | applyPure: value }} </span>
                    </div>
                </ng-template>
            </app-select-chip-list>
        </div>
    </div>
</ng-template>

<ng-template #locationTemplate>
    <div>
        <app-input-google-maps-autocomplete
            [title]="'information.information.address' | translate"
            [placeholder]="'information.information.address' | translate"
            [formControl]="addressForm"
            [errorMessage]="
                (infoForm.get('address')?.dirty && infoForm.get('address')?.errors?.error) ||
                (addressForm.dirty && addressForm.errors?.error)
            "
            (locationSelected)="selectedAddress($event)"></app-input-google-maps-autocomplete>
    </div>
</ng-template>

<ng-template #socialNetworkUrlsTemplate>
    <div class="w-full">
        <div class="malou-text-10--regular text-malou-color-text-2">{{ 'information.information.social_network_urls' | translate }}</div>

        <div class="flex w-full flex-col gap-2">
            @for (
                socialNetworkUrl of infoForm.controls.socialNetworkUrls.controls;
                track socialNetworkUrl;
                let index = $index;
                let last = $last
            ) {
                <div class="flex w-full items-start gap-x-3" [formGroup]="socialNetworkUrl">
                    <app-select-social-network
                        class="w-[200px]"
                        formControlName="key"
                        [title]="''"
                        [values]="socialPlatformOptions()[index]"></app-select-social-network>

                    <app-input-text
                        class="grow"
                        formControlName="url"
                        [title]="''"
                        [placeholder]="getPlaceholderUrlForPlatform | applyPure: socialNetworkUrl.controls.key.value"
                        [required]="true"
                        [hideRequiredStar]="true"
                        [errorMessage]="
                            socialNetworkUrl.controls.url.invalid ? socialNetworkUrl.controls.url.errors?.error : null
                        "></app-input-text>

                    <div class="margin-top-custom flex gap-x-2">
                        <mat-icon
                            class="malou-color-chart-pink--accent !h-4 !w-4 cursor-pointer"
                            [svgIcon]="SvgIcon.INTERDICTION"
                            (click)="removeSocialNetworkAt(index)"></mat-icon>
                        @if (infoForm.controls.socialNetworkUrls.length < MAX_SOCIAL_NETWORKS) {
                            <mat-icon
                                class="invisible !h-4 !w-4 cursor-pointer text-malou-color-primary"
                                [ngClass]="{ '!visible': last }"
                                [svgIcon]="SvgIcon.ADD"
                                (click)="addSocialNetworkUrl()"></mat-icon>
                        }
                    </div>
                </div>
            }
        </div>

        @if (infoForm.controls.socialNetworkUrls.length === 0) {
            <div class="mt-3 flex cursor-pointer items-center gap-x-3" (click)="addSocialNetworkUrl()">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
                <div class="malou-text-12--semibold malou-color-primary">
                    {{ 'information.information.add_social_network_url' | translate }}
                </div>
            </div>
        }
    </div>
</ng-template>
