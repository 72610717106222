import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidLinkedinUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.LINKEDIN })) {
            return false;
        }

        // Try to extract linkedin username
        const userName = getLinkedinUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getLinkedinUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = decodeURIComponent(trimSlashes(parsedUrl.pathname)); // Remove beginning and trailing slashes if they exist, and decode encoded characters like é, è, that were transformed to %C3%A9, %C3%A8

        // Only alphanumeric characters, latin characters, apostrophes, hyphens, dots and underscores are allowed for linkedin usernames

        // if is not enough, we can also use \p{Script=Latin} with /u regex operator, it covers more characters (Romanian, Vietnamese, ...)
        // starting with /in/username
        const profileRegex = /^in\/[-a-zA-ZÀ-ž0-9'_\.]+$/;
        if (profileRegex.test(pathname)) {
            const encodedUsername = encodeURIComponent(pathname.replace('in/', ''));
            return `in/${encodedUsername}`;
        }

        // starting with /company/username
        const companyRegex = /^company\/[-a-zA-ZÀ-ž0-9'_\.]+$/;
        if (companyRegex.test(pathname)) {
            const encodedUsername = encodeURIComponent(pathname.replace('company/', ''));
            return `company/${encodedUsername}`;
        }

        // starting with /showcase/username
        const showcaseRegex = /^showcase\/[-a-zA-ZÀ-ž0-9'_\.]+$/;
        if (showcaseRegex.test(pathname)) {
            const encodedUsername = encodeURIComponent(pathname.replace('showcase/', ''));
            return `company/${encodedUsername}`;
        }

        return null;
    } catch {
        return null;
    }
}

export function transformLinkedinUrl(url: string): string | null {
    if (!isValidLinkedinUrl(url)) return null;
    const userName = getLinkedinUserName(url);
    return `https://www.linkedin.com/${userName}`;
}
