<div class="malou-card malou-form malou-form-dialog h-full w-full !rounded-lg md:h-full md:justify-center">
    <div class="flex flex-col items-center md:h-full md:justify-center">
        <img alt="Cook illustration" width="100" height="100" [src]="'Cook' | illustrationPathResolver" />
        <div class="malou-card__title mt-5 md:text-center">
            {{ 'close_without_saving_modal.title' | translate }}
        </div>
        <div class="malou-text-13--regular mt-5 md:mb-10">
            {{ 'close_without_saving_modal.subtitle' | translate }}
        </div>

        <div
            class="mt-5 flex justify-center gap-4 md:absolute md:bottom-0 md:mb-4 md:w-full md:flex-col md:items-center md:justify-between">
            <button class="malou-btn-raised--secondary !h-11 md:w-10/12" mat-raised-button (click)="onConfirmFn()">
                {{ 'close_without_saving_modal.confirm' | translate }}
            </button>
            <button class="malou-btn-raised--primary !h-11 md:w-10/12" mat-raised-button (click)="onCancelFn()">
                {{ 'close_without_saving_modal.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
