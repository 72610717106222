import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { LocalStorage } from ':core/storage/local-storage';
import { CalendarEvent } from ':shared/models/calendar-event';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-special-hours-calendar-event',
    templateUrl: './special-hours-calendar-event.component.html',
    styleUrls: ['./special-hours-calendar-event.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, ApplySelfPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialHoursCalendarEventComponent {
    readonly calendarEvent = input.required<CalendarEvent>();
    readonly disabled = input<boolean>(false);

    readonly validateHours = output<void>();

    readonly LANG = LocalStorage.getLang();

    validateHoursForCalendarEvent(): void {
        this.validateHours.emit();
    }
}
