import { MalouErrorCode } from '@malou-io/package-utils';

export const getStatisticsError = (error: any): string => {
    const errorMessage: string = error?.error?.message;
    if (!errorMessage) {
        return 'statistics.unknown_error';
    }
    if (errorMessage.match(/Time range too short/)) {
        return 'statistics.time_range_errors.too_short';
    }
    if (errorMessage.match(/Time range too long/)) {
        return 'statistics.time_range_errors.too_long';
    }
    if (errorMessage.match(/Start date must occur before end date/)) {
        return 'statistics.time_range_errors.start_date_before_end_date';
    }
    if (errorMessage.match(/Application request limit reached/g)) {
        return 'statistics.social_network.errors.request_limit_reached';
    }
    if (errorMessage.match(/Requires/g) || errorMessage.match(/permission/g)) {
        return 'statistics.social_network.errors.requires_permissions';
    }
    if (
        error?.error?.malouErrorCode === MalouErrorCode.QUERY_VALIDATION_ERROR ||
        errorMessage.match(/invalid_platform/g) ||
        errorMessage.match(/platform_required/g)
    ) {
        return 'statistics.social_network.errors.change_filters';
    }
    if (errorMessage.match(/change_filters/g)) {
        return 'statistics.social_network.errors.change_filters';
    }
    return 'statistics.unknown_error';
};
