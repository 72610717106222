<div class="malou-text-13 m-4 flex max-w-[85%] items-center">
    @if (!isPhoneScreen()) {
        <img class="mr-4 !h-[167px] !w-[75px]" alt="wheel of fortune" [src]="Illustration.WheelOfFortune | illustrationPathResolver" />
    }
    <div class="text-justify leading-6">
        <span [innerHtml]="'wheel_of_fortune.create_wheel_card.rules_details.step1' | translate"></span>
        <br />
        <span [innerHtml]="'wheel_of_fortune.create_wheel_card.rules_details.step2' | translate"></span>
        <br />
        <span [innerHtml]="'wheel_of_fortune.create_wheel_card.rules_details.step3' | translate"></span>
        <br />
        <span [innerHtml]="'wheel_of_fortune.create_wheel_card.rules_details.step4' | translate"></span>
        <br />
        <span [innerHtml]="'wheel_of_fortune.create_wheel_card.rules_details.step5' | translate"></span>
    </div>
</div>
