<mat-paginator
    class="malou-paginator"
    appPagesRangePaginator
    showFirstLastButtons
    [selectConfig]="matPaginatorSelectConfig"
    [ngClass]="{ '!hidden': shouldBeHidden() }"
    [pageSize]="pageSize()"
    [pageSizeOptions]="pageSizeOptions()"
    [pageIndex]="pageIndex()"
    [length]="length()"
    (page)="onPageEvent($event)">
</mat-paginator>
