import { DateTime } from 'luxon';

export enum MalouPeriod {
    DEFAULT = 'default',
    LAST_SEVEN_DAYS = 'last_seven_days',
    LAST_THIRTY_DAYS = 'last_thirty_days',
    LAST_AND_COMING_THIRTY_DAYS = 'last_and_coming_thirty_days',
    LAST_THREE_MONTHS = 'last_three_months',
    LAST_SIX_MONTHS = 'last_six_months',
    LAST_AND_COMING_SIX_MONTH = 'last_and_coming_six_month',
    LAST_TWELVE_MONTHS = 'last_twelve_months',
    CUSTOM = 'custom',
    ALL = 'all',
}

export interface DatesAndPeriod {
    startDate: Date | null;
    endDate: Date | null;
    period: MalouPeriod;
}

function isCustom(input: GetFilterInput): input is GetCustomPeriodFilterInput {
    return (input as GetCustomPeriodFilterInput).period === MalouPeriod.CUSTOM;
}

type MalouDefinedPeriod = Exclude<MalouPeriod, MalouPeriod.CUSTOM>;
type MalouCustomPeriod = MalouPeriod.CUSTOM;

export type GetFilterInput = GetDefinedPeriodFilterInput | GetCustomPeriodFilterInput;

interface GetDefinedPeriodFilterInput {
    period: MalouDefinedPeriod;
}

interface GetCustomPeriodFilterInput {
    period: MalouCustomPeriod;
    startDate: Date;
    endDate: Date;
}

export class MalouDateFilters {
    getFilter = (params: GetFilterInput): DatesAndPeriod => {
        const todayDateTime = DateTime.now();
        const endOfToday = todayDateTime.endOf('day').toJSDate();
        if (isCustom(params)) {
            return {
                // Dates should not change when selecting custom
                period: MalouPeriod.CUSTOM,
                startDate: DateTime.fromJSDate(params.startDate).startOf('day').toJSDate(),
                endDate: DateTime.fromJSDate(params.endDate).endOf('day').toJSDate(),
            };
        }
        const filterByPeriod: Record<MalouDefinedPeriod, DatesAndPeriod> = {
            [MalouPeriod.DEFAULT]: {
                period: MalouPeriod.LAST_THIRTY_DAYS,
                startDate: todayDateTime.minus({ days: 30 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_SEVEN_DAYS]: {
                period: MalouPeriod.LAST_SEVEN_DAYS,
                startDate: todayDateTime.minus({ days: 7 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_THIRTY_DAYS]: {
                period: MalouPeriod.LAST_THIRTY_DAYS,
                startDate: todayDateTime.minus({ days: 30 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_AND_COMING_THIRTY_DAYS]: {
                period: MalouPeriod.LAST_AND_COMING_THIRTY_DAYS,
                startDate: todayDateTime.minus({ days: 30 }).startOf('day').toJSDate(),
                endDate: todayDateTime.plus({ days: 30 }).endOf('day').toJSDate(),
            },
            [MalouPeriod.LAST_THREE_MONTHS]: {
                period: MalouPeriod.LAST_THREE_MONTHS,
                startDate: todayDateTime.minus({ months: 3 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_SIX_MONTHS]: {
                period: MalouPeriod.LAST_SIX_MONTHS,
                startDate: todayDateTime.minus({ months: 6 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_TWELVE_MONTHS]: {
                period: MalouPeriod.LAST_TWELVE_MONTHS,
                startDate: todayDateTime.minus({ months: 12 }).startOf('day').toJSDate(),
                endDate: endOfToday,
            },
            [MalouPeriod.LAST_AND_COMING_SIX_MONTH]: {
                period: MalouPeriod.LAST_AND_COMING_SIX_MONTH,
                startDate: todayDateTime.minus({ months: 6 }).startOf('day').toJSDate(),
                endDate: todayDateTime.plus({ months: 6 }).endOf('day').toJSDate(),
            },
            [MalouPeriod.ALL]: {
                // Dates should be set to null when selecting all
                period: MalouPeriod.ALL,
                startDate: null,
                endDate: null,
            },
        };
        return filterByPeriod[params.period];
    };
}
