// !!! WARNING !!!
// if you make change to these validators, you must also make change to the interfaces
// Because on strict false, zod make all properties optional
// So when strict true, remove interfaces and infer type with zod
import { z } from 'zod';

export interface LatlngDto {
    lat: number;
    lng: number;
}

export const latlngDtoValidator = z.object({
    lat: z.number(),
    lng: z.number(),
});
