export interface GalleryFilters {
    sortOrder?: number;
    mediaType?: string;
    isNeverUsed: boolean;
    title?: string;
}

export enum galleryMediaType {
    ALL = 'all',
    PHOTO = 'photo',
    VIDEO = 'video',
}
