import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import {
    DownloadReviewsAsPdfResponseDto,
    GetRestaurantsReviewsAverageRatingDto,
    GetRestaurantsReviewsV2QueryDto,
    GetRestaurantsReviewsV2ResponseDto,
    GetRestaurantsUnansweredCountResponseDto,
    GetReviewCountResponseDto,
    GetReviewPageResponseDto,
    GetReviewsEvolutionTotalBodyDto,
    GetReviewsEvolutionTotalResponseBodyDto,
    GetTotalReviewCountResponseDto,
    ReviewResponseDto,
    ReviewWithTranslationsResponseDto,
    SynchronizeReviewsQueryDto,
    SynchronizeReviewsResponseDto,
} from '@malou-io/package-dto';
import { ApiResultV2, ApplicationLanguage, PlatformKey, PrivatePlatforms, TranslationSource } from '@malou-io/package-utils';

import { ReviewsMapper } from ':core/services/mappers/reviews.mapper';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';
import {
    ApiResult,
    ChartReviewsStats,
    Pagination,
    Review,
    ReviewReply,
    ReviewsFilters,
    ReviewWithAnalysis,
    SemanticAnalysis,
} from ':shared/models';
import { PrivateReview, PrivateReviewReply } from ':shared/models/private-review';

import {
    ReviewsAverageAnswerTime,
    ReviewsByAnswerStatus,
    ReviewsEvolutionTotalWithRange,
    ReviewsEvolutionWithRange,
    ReviewsRatingsWithRange,
} from './reviews.interface';

@Injectable({
    providedIn: 'root',
})
export class ReviewsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/reviews`;

    constructor(private readonly _http: HttpClient) {}

    getRestaurantsReviewsAverageChartData(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<GetRestaurantsReviewsAverageRatingDto> {
        const filters = {
            endDate,
            startDate,
            platforms,
            previousPeriod,
            restaurantId,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResultV2<GetRestaurantsReviewsAverageRatingDto>>(`${this.API_BASE_URL}/restaurants/chart/rating/average`, {
                params: cleanFilters,
            })
            .pipe(map((res) => res.data));
    }

    getChartRestaurantsReviewsEvolutions(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ReviewsEvolutionWithRange> {
        const filters = {
            endDate,
            startDate,
            platforms,
            previousPeriod,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult<ReviewsEvolutionWithRange>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/chart/evolution`, {
                params: cleanFilters,
            })
            .pipe(map((res) => res.data));
    }

    getChartRestaurantsReviewsTotal(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ReviewsEvolutionTotalWithRange> {
        const filters = {
            endDate,
            startDate,
            platforms,
            previousPeriod,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult<ReviewsEvolutionTotalWithRange>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/chart/evolution/total`, {
                params: cleanFilters,
            })
            .pipe(map((res) => res.data));
    }

    getChartRestaurantsReviewsReplied(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ReviewsByAnswerStatus> {
        const filters = {
            endDate,
            startDate,
            platforms,
            previousPeriod,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult<ReviewsByAnswerStatus>>(
                `
            ${this.API_BASE_URL}/restaurants/${restaurantId}/chart/replied`,
                { params: cleanFilters }
            )
            .pipe(map((res) => res.data));
    }

    getChartRestaurantsReviewsRatings(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null
    ): Observable<ReviewsRatingsWithRange> {
        const filters = {
            endDate,
            startDate,
            platforms,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/chart/rating`, { params: cleanFilters })
            .pipe(map((res) => res.data));
    }

    getChartAverageAnswerTime(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null,
        previousPeriod = false
    ): Observable<ReviewsAverageAnswerTime> {
        const filters = {
            endDate,
            startDate,
            platforms,
            previousPeriod,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult<ReviewsAverageAnswerTime>>(
                `
            ${this.API_BASE_URL}/restaurants/${restaurantId}/chart/average-answer-time`,
                { params: cleanFilters }
            )
            .pipe(map((res) => res.data));
    }

    // TODO synchro with the mobile app to use the body instead of query params to send restaurantIds and filters
    getSelectedRestaurantsReviewsPaginated(
        restaurantIds: string[],
        pagination: Pagination,
        filters: ReviewsFilters
    ): Observable<{
        reviews: (Review | PrivateReview)[];
        pagination: { pageSize: number; skip: number };
    }> {
        if (!restaurantIds.length) {
            return of({
                reviews: [],
                pagination: { pageSize: pagination.pageSize, skip: pagination.skip ?? 0 },
            });
        }

        // replace "notAnswerable" field by "answerable" field to match the mobile app query params
        // and if "notAnswerable" reviews are not shown, then don't show private reviews from totem
        const filtersWithAnswerable = omit(
            {
                ...this._cleanReviewFilters(filters),
                answerable: !filters.notAnswerable,
                privatePlatforms: !filters.notAnswerable ? [PrivatePlatforms.CAMPAIGN] : undefined,
            },
            'notAnswerable'
        );

        const params: GetRestaurantsReviewsV2QueryDto = {
            // ignore sortBy and sortOrder if the state has been restored from the localStorage
            ...omit(filtersWithAnswerable, 'sortBy', 'sortOrder'),
            ...pagination,
            restaurant_ids: restaurantIds,
            timeZone: undefined,
            private_platforms: undefined,
        };
        return this._http
            .get<ApiResultV2<GetRestaurantsReviewsV2ResponseDto>>(`${this.API_BASE_URL}/v2`, { params: objectToQueryParams(params) })
            .pipe(
                map((res: ApiResultV2<GetRestaurantsReviewsV2ResponseDto>) => {
                    const reviews = res.data.reviews.map((review) =>
                        this._isPrivateReview(review)
                            ? PrivateReview.fromReviewWithTranslationsResponseDto(review)
                            : Review.fromReviewWithTranslationsResponseDto(review)
                    );
                    return { reviews, pagination: res.data.pagination };
                })
            );
    }

    getReviewCount(restaurantIds: string[], filters: ReviewsFilters): Observable<number> {
        // replace "notAnswerable" field by "answerable" field to match the mobile app query params
        // and if "notAnswerable" reviews are not shown, then don't show private reviews from totem
        const filtersWithAnswerable = omit(
            {
                ...this._cleanReviewFilters(filters),
                answerable: !filters.notAnswerable,
                privatePlatforms: !filters.notAnswerable ? [PrivatePlatforms.CAMPAIGN] : undefined,
            },
            'notAnswerable'
        );

        const body = { ...filtersWithAnswerable, restaurantIds };
        return this._http
            .post<ApiResultV2<GetReviewCountResponseDto>>(`${this.API_BASE_URL}/count`, body)
            .pipe(map((res: ApiResult<GetReviewCountResponseDto>) => res.data.count));
    }

    getRestaurantsUnansweredReviewCount(
        restaurantIds: string[],
        filters: ReviewsFilters
    ): Observable<ApiResult<GetRestaurantsUnansweredCountResponseDto>> {
        const body = { ...this._cleanReviewFilters(filters), restaurantIds };
        return this._http.post<ApiResult<GetRestaurantsUnansweredCountResponseDto>>(`${this.API_BASE_URL}/unanswered/v2`, body, {
            withCredentials: true,
        });
    }

    /**
     * Post a new comment on a review
     */
    postReviewComment(reviewSocialId: string, comment: ReviewReply, restaurantId: string): Observable<ApiResult<Review>> {
        return this._http
            .post<
                ApiResult<Review>
            >(`${this.API_BASE_URL}/${reviewSocialId}/comments/restaurants/${restaurantId}`, { comment }, { withCredentials: true })
            .pipe(
                map((res: ApiResult) => {
                    res.data = new Review(res.data);
                    return res;
                })
            );
    }

    /**
     * Update comment on a review
     */
    updateReviewComment(reviewId: string, commentId: string, comment: ReviewReply, restaurantId: string): Observable<ApiResult<Review>> {
        return this._http
            .put<ApiResult>(
                `${this.API_BASE_URL}/${reviewId}/comments/${commentId}/restaurants/${restaurantId}`,
                { comment },
                { withCredentials: true }
            )
            .pipe(
                map((res: ApiResult) => {
                    res.data = new Review(res.data);
                    return res;
                })
            );
    }

    updateReviewArchivedValue(reviewId: string, archived: boolean, isPrivate: boolean): Observable<ApiResult<Review | PrivateReview>> {
        return this._http
            .put<
                ApiResult<ReviewResponseDto>
            >(`${this.API_BASE_URL}/${reviewId}/archived`, { archived, isPrivate }, { withCredentials: true })
            .pipe(
                map((res: ApiResult<ReviewResponseDto>) => ({
                    ...res,
                    data: ReviewsMapper.mapToReview(res.data),
                }))
            );
    }

    // TODO synchro with the mobile app to use the body instead of query params to send restaurantIds
    synchronizeRestaurantReviews(restaurantIds: string[]): Observable<ApiResult<SynchronizeReviewsResponseDto>> {
        const params: SynchronizeReviewsQueryDto = objectToQueryParams({ restaurantIds }) as SynchronizeReviewsQueryDto;
        return this._http.get<ApiResult<SynchronizeReviewsResponseDto>>(`${this.API_BASE_URL}/restaurants/synchronize`, { params });
    }

    deleteRestaurantReviewsForPlatform(platformKey: string, restaurantId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/platforms/${platformKey}/restaurants/${restaurantId}`);
    }

    deleteReviewById(reviewId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${reviewId}`);
    }

    getPageNumberFromReviewId(
        reviewId: string,
        restaurantIds: string[],
        filters: ReviewsFilters
    ): Observable<ApiResult<GetReviewPageResponseDto>> {
        const body = { ...this._cleanReviewFilters(filters), restaurantIds };
        return this._http.post<ApiResult<GetReviewPageResponseDto>>(`${this.API_BASE_URL}/${reviewId}/page/v2`, body, {
            withCredentials: true,
        });
    }

    canAnswer(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/can_answer`, { withCredentials: true });
    }

    getChartReviewsForUserRestaurants(body: GetReviewsEvolutionTotalBodyDto): Observable<ChartReviewsStats[]> {
        return this._http
            .post<ApiResultV2<GetReviewsEvolutionTotalResponseBodyDto>>(`${this.API_BASE_URL}/restaurants/chart/evolution/total`, body)
            .pipe(map(({ data }) => data.map((elem) => new ChartReviewsStats(elem))));
    }

    getReviewsWithAnalysis(
        startDate: Date | null,
        endDate: Date | null,
        platforms: string[],
        restaurantId: string
    ): Observable<ReviewWithAnalysis[]> {
        const filters = {
            endDate,
            startDate,
            platforms,
        };
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase(filters));
        return this._http
            .get<ApiResult<ReviewWithAnalysis[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/chart/review_analyses`, {
                params: {
                    ...cleanFilters,
                    platforms,
                },
            })
            .pipe(
                map((res) => res.data),
                map((apiResult) =>
                    apiResult.map((e) => ({
                        ...e,
                        socialCreatedAt: new Date(e.socialCreatedAt),
                        semanticAnalysis: new SemanticAnalysis(e.semanticAnalysis, e.text),
                    }))
                )
            );
    }

    updateReviewKeywordsLang(reviewId: string, keywordsLang: string): Observable<Review> {
        return this._http
            .put<ApiResultV2<ReviewWithTranslationsResponseDto>>(`${this.API_BASE_URL}/${reviewId}/keywords_lang`, { keywordsLang })
            .pipe(map((res) => Review.fromReviewWithTranslationsResponseDto(res.data)));
    }

    getFilteredReviewsPdfUrl(restaurantIds: string[], filters: ReviewsFilters): Observable<DownloadReviewsAsPdfResponseDto> {
        // replace "notAnswerable" field by "answerable" field to match the mobile app query params
        // and if "notAnswerable" reviews are not shown, then don't show private reviews from totem
        const filtersWithAnswerable = omit(
            {
                ...this._cleanReviewFilters(filters),
                answerable: !filters.notAnswerable,
                privatePlatforms: !filters.notAnswerable ? [PrivatePlatforms.CAMPAIGN] : undefined,
            },
            'notAnswerable'
        );

        const body = { ...filtersWithAnswerable, restaurantIds };
        const params = objectToQueryParams({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

        return this._http
            .post<ApiResultV2<DownloadReviewsAsPdfResponseDto>>(`${this.API_BASE_URL}/download-reviews-as-pdf/v2`, body, { params })
            .pipe(map((res) => res.data));
    }

    addTranslationToReview(
        reviewId: string,
        translation: string,
        language: ApplicationLanguage,
        source: TranslationSource,
        isPrivateReview: boolean
    ): Observable<ApiResultV2<Review | PrivateReview>> {
        return this._http
            .post<ApiResultV2<ReviewWithTranslationsResponseDto>>(
                `${this.API_BASE_URL}/${reviewId}/translations`,
                {
                    translation,
                    language,
                    source,
                    isPrivateReview,
                },
                { withCredentials: true }
            )
            .pipe(
                map((res) => ({
                    ...res,
                    data: this._isPrivateReview(res.data)
                        ? PrivateReview.fromReviewWithTranslationsResponseDto(res.data)
                        : Review.fromReviewWithTranslationsResponseDto(res.data),
                }))
            );
    }

    // -------------------------------------------------------------------------------------------------
    //  Private Reviews
    // -------------------------------------------------------------------------------------------------

    createPrivateReview(privateReview: Partial<PrivateReview>): Observable<ApiResult<PrivateReview>> {
        return this._http.post<ApiResult<PrivateReview>>(`${this.API_BASE_URL}/private`, { privateReview });
    }

    postPrivateReviewComment(reviewId: string, comment: PrivateReviewReply, restaurantId: string): Observable<ApiResult<PrivateReview>> {
        return this._http
            .post<ApiResult>(
                `${this.API_BASE_URL}/${reviewId}/comments/restaurants/${restaurantId}/private`,
                { comment },
                { withCredentials: true }
            )
            .pipe(
                map((res: ApiResult) => {
                    res.data = new PrivateReview(res.data);
                    return res;
                })
            );
    }

    getTotalReviewCountForPlatform(restaurantId: string, platformKey: string): Observable<GetTotalReviewCountResponseDto> {
        return this._http
            .get<
                ApiResultV2<GetTotalReviewCountResponseDto>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/platforms/${platformKey}/count`)
            .pipe(map((res) => res.data));
    }

    private _isPrivateReview(review: ReviewWithTranslationsResponseDto): boolean {
        return review.key === PlatformKey.PRIVATE;
    }

    private _cleanReviewFilters(filters: ReviewsFilters): ReviewsFilters {
        return omit(filters, 'aggregatedViewRestaurantIds');
    }
}
