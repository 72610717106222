import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { v4 } from 'uuid';

import { SearchComponent } from ':shared/components/search/search.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AsTypePipe } from ':shared/pipes/as.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

export interface ComputedValue {
    title: string;
    subtitle?: string;
    link?: string;
}

interface AggregatedValue<T> {
    id: string;
    original: T;
    computed: ComputedValue;
    compareValue: any;
}

export interface PlatformsConnectionBusinessSelectorTranslations {
    title?: string;
    noResultsDescription: string;
}

@Component({
    selector: 'app-platforms-connection-business-selector',
    templateUrl: './platforms-connection-business-selector.component.html',
    styleUrls: ['./platforms-connection-business-selector.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        SearchComponent,
        MatRadioModule,
        ApplyPurePipe,
        MatIconModule,
        NgTemplateOutlet,
        AsTypePipe,
        MatExpansionModule,
        IllustrationPathResolverPipe,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsConnectionBusinessSelectorComponent<T> {
    readonly translations = input.required<PlatformsConnectionBusinessSelectorTranslations>();
    readonly selectedValue = input.required<T | null>();
    readonly compareValue = computed(() => (this.selectedValue() ? this.compareWith()(this.selectedValue()!) : null));
    readonly selectableValues = input.required<T[]>();
    readonly displayWith = input.required<(value: T) => ComputedValue>();
    readonly showSearchInput = input(true);
    readonly isDisabled = input(false);

    readonly SvgIcon = SvgIcon;
    readonly compareWith = input<(t: T) => any>((t) => t);
    readonly selectionChange = output<T>();

    readonly searchText = signal('');
    readonly aggregatedValues = computed<AggregatedValue<T>[]>(() =>
        this.selectableValues()
            .map((selectableValue) => ({
                id: v4(),
                original: selectableValue,
                compareValue: this.compareWith()(selectableValue),
                computed: this.displayWith()(selectableValue),
            }))
            .filter((aggregatedValue) => aggregatedValue.computed.title.toLowerCase().includes(this.searchText().toLowerCase()))
    );

    readonly duplicatedAggregatedValues = computed<AggregatedValue<T>[]>(() => {
        const aggregatedValues = this.aggregatedValues();
        const groupedBySocialId = groupBy(aggregatedValues, 'original.socialId');
        const duplicates = Object.entries(groupedBySocialId)
            .filter(([key, _value]) => key !== undefined)
            .map(([_key, value]) => value)
            .filter((value) => value.length > 1)
            .flat();
        return duplicates;
    });

    onSearchChange(value: string): void {
        this.searchText.set(value);
    }
}
