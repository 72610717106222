import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonComponent } from ':shared/components/button/button.component';

@Component({
    selector: 'app-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
    standalone: true,
    imports: [NgClass, MatMenuModule, ButtonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
    readonly id = input<string>('');
    readonly text = input<string>('');
    readonly loading = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly buttonClasses = input<string>('');
    readonly tooltip = input<string>('');
    readonly testId = input<string>('');
}
