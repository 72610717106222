import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, NotificationType, PostSource } from '@malou-io/package-utils';

import { PostSuggestionNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-post-suggestion-popin',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, ApplyPurePipe, TranslateModule],
    templateUrl: './post-suggestion-popin.component.html',
    styleUrl: './post-suggestion-popin.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSuggestionPopinComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly PostSource = PostSource;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { restaurant: Restaurant; notification: PostSuggestionNotification },
        private readonly _dialogRef: MatDialogRef<PostSuggestionPopinComponent>,
        private readonly _router: Router,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this._notificationService
            .trackNotificationAction$({
                heapEventName: HeapEventName.NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_CLOSE_POPIN,
                notificationId: this.data.notification.id,
                properties: {
                    restaurantId: this.data.restaurant._id,
                    notificationType: NotificationType.POST_SUGGESTION,
                },
            })
            .subscribe();
    }

    close(): void {
        this._notificationService
            .trackNotificationAction$({
                heapEventName: HeapEventName.NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_CLOSE_POPIN,
                notificationId: this.data.notification.id,
                properties: {
                    restaurantId: this.data.restaurant._id,
                    notificationType: NotificationType.POST_SUGGESTION,
                },
            })
            .subscribe();
        this._dialogRef.close();
    }

    navigateToPosts(source: PostSource): void {
        const heapEventName =
            source === PostSource.SOCIAL
                ? HeapEventName.NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_WEB_REDIRECT
                : HeapEventName.NOTIFICATION_POST_SUGGESTION_TRACKING_SEO_WEB_REDIRECT;
        this._notificationService
            .trackNotificationAction$({
                heapEventName: heapEventName,
                notificationId: this.data.notification.id,
                properties: {
                    restaurantId: this.data.restaurant._id,
                    notificationType: NotificationType.POST_SUGGESTION,
                },
            })
            .subscribe();
        switch (source) {
            case PostSource.SOCIAL:
                this._router.navigate(['restaurants', this.data.restaurant._id, 'social', 'socialposts']);
                break;
            case PostSource.SEO:
                this._router.navigate(['restaurants', this.data.restaurant._id, 'seo', 'posts']);
                break;
        }
        this.close();
    }
}
