import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';
import { brickValidator } from './shared.dto';

export const addRestaurantBricksParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type AddRestaurantBricksParamsDto = z.infer<typeof addRestaurantBricksParamsValidator>;

export const addRestaurantBricksBodyValidator = z.object({
    formData: z.object({
        categoryList: z.array(brickValidator),
        touristics: z.array(brickValidator),
        specials: z.array(brickValidator),
        offers: z.array(brickValidator),
        equipment: z.array(brickValidator),
        attributes: z.array(brickValidator),
        audience: z.array(brickValidator),
    }),
});
export type AddRestaurantBricksBodyDto = z.infer<typeof addRestaurantBricksBodyValidator>;
