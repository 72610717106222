import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    interval,
    map,
    Observable,
    Subject,
    Subscription,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';

import { ConversationStatus, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { notionLinks } from ':core/constants';
import { FacebookErrorSubCode } from ':core/constants/facebook-error-codes';
import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ConversationPanelComponent } from ':modules/messages/conversation-panel/conversation-panel.component';
import { ConversationsComponent } from ':modules/messages/conversations/conversations.component';
import { MessagesFiltersComponent } from ':modules/messages/messages-filters/messages-filters.component';
import * as MessagesActions from ':modules/messages/messages.actions';
import { MessagesState } from ':modules/messages/messages.reducer';
import { MessagesService } from ':modules/messages/messages.service';
import { selectCurrentPlatforms, selectRestaurantId } from ':modules/platforms/store/platforms.reducer';
import { NoConnectedPlatformsComponent } from ':shared/components-v3/no-connected-platforms/no-connected-platforms.component';
import { ToastDuration } from ':shared/components-v3/toast/toast-item/toast-item.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { AvailablePlatform, FetchedState, Pagination, Platform, Restaurant } from ':shared/models';
import { ConversationType, ConversationWithMessages } from ':shared/models/conversation';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

interface AppState {
    platforms: any;
    messages: MessagesState;
}

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MessagesFiltersComponent,
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        ConversationsComponent,
        ConversationPanelComponent,
        NoConnectedPlatformsComponent,
        AsyncPipe,
        TranslateModule,
        ApplySelfPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class MessagesComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly shouldLoadMoreConversations$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    readonly conversationStatusFilters$: BehaviorSubject<ConversationStatus[]> = new BehaviorSubject([
        ConversationStatus.READ,
        ConversationStatus.UNREAD,
    ]);
    readonly sortOrder$ = new BehaviorSubject(-1);
    readonly sortBy$ = new BehaviorSubject('latestMessageAt');
    readonly availablePlatforms$: BehaviorSubject<AvailablePlatform[]> = new BehaviorSubject([]);
    readonly checkedPlatforms$ = new BehaviorSubject<string[]>([]);
    readonly conversationChanged$ = new BehaviorSubject<ConversationWithMessages | null>(null);
    readonly platformsStatus$: BehaviorSubject<{
        instagram?: FetchedState<string>;
        facebook?: FetchedState<string>;
    }> = new BehaviorSubject({});
    readonly hasUpdateFinished$ = new BehaviorSubject(true);

    readonly conversationsWithMessages$: Observable<ConversationWithMessages[]> = this._store.select(
        (state) => state.messages.conversationsWithMessages
    );
    readonly platformsKeys$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms);

    readonly paginations = this._initPaginations();

    readonly scrollDown = new EventEmitter();

    readonly checkedConversationsWithMessages: WritableSignal<ConversationWithMessages[]> = signal([]);

    conversationsWithMessages: ConversationWithMessages[];
    filteredConversations: ConversationWithMessages[];
    loadingConversations = {
        availablePlatforms: true,
        conversations: true,
    };
    isLoading = true;
    errorLoadingConversations = false;
    selectedConversation: ConversationWithMessages | null;
    conversationType = ConversationType.GENERAL;

    restaurant: Restaurant;

    displayActivateMessagingBtn = false;

    showPlatformsStatus = true;
    areAllPlatformsDisconnected = false;
    areAllSocialPlatformsDisconnected = false;

    hasLoadedConversation: Partial<Record<ConversationType, boolean>> = {};
    statusSubscription: Subscription;
    selectedTabIndex = 0;

    conversationsTextSearch = '';

    private readonly _DEFAULT_PAGINATION: Pagination = { pageSize: 20, pageNumber: 0, total: 0 };
    private readonly _MESSAGING_PLATFORMS_KEYS: PlatformKey[] = PlatformDefinitions.getPlatformKeysWithMessages();
    private readonly _PERMISSIONS_TRANSLATE = this._translate.instant('permissions');

    constructor(
        private readonly _messagesService: MessagesService,
        private readonly _store: Store<AppState>,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _restaurantService: RestaurantsService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translate: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _toastService: ToastService,
        private readonly _dialogService: DialogService,
        public screenSizeService: ScreenSizeService,
        private readonly _heapEmailEventsTrackerService: HeapEmailEventsTrackerService
    ) {
        this.conversationsWithMessages$.subscribe((conversations) => {
            this.loadingConversations.conversations = false;
            this._updateLoadingStatus();
            this.conversationsWithMessages = conversations;
            this.filteredConversations = this.conversationsWithMessages;
            this._updateCheckedConversations();
        });
    }

    ngOnInit(): void {
        this._heapEmailEventsTrackerService.trackEmailEvent();
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(Boolean),
                tap((rest) => {
                    this._resetTabToGeneral();
                    this.restaurant = rest;
                    this.selectedConversation = null;
                    this.hasUpdateFinished$.next(true);
                    this.loadingConversations = {
                        availablePlatforms: true,
                        conversations: true,
                    };
                    this._updateLoadingStatus();
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe();
        this._listenForNewMessages();
        this._getAvailablePlatforms$()
            .pipe(
                filter((availablePlatforms) => !!availablePlatforms && !this.loadingConversations.availablePlatforms),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (availablePlatforms) => {
                    this.availablePlatforms$.next(availablePlatforms);
                    this.checkedPlatforms$.next(availablePlatforms.filter((p) => p.checked).map((p) => p.key));
                    this.areAllPlatformsDisconnected = !availablePlatforms.filter((p) => p.connected).length;
                    this.areAllSocialPlatformsDisconnected = !availablePlatforms.filter(
                        (plat) => PlatformDefinitions.getSocialPlatformKeysWithMessages().includes(plat.key) && plat.connected
                    ).length;
                    this.loadingConversations.availablePlatforms = false;
                    this._updateLoadingStatus();
                },
            });
        this._loadConversations();

        this.shouldLoadMoreConversations$
            .pipe(
                filter((val) => !!val),
                tap(() => this.paginations[this.conversationType].pageNumber++),
                switchMap(() =>
                    this._messagesService.getConversationsPaginated(
                        this.restaurant._id,
                        this.checkedPlatforms$.value,
                        this.conversationType,
                        this.conversationStatusFilters$.value,
                        {
                            ...this.paginations[this.conversationType],
                            sortBy: this.sortBy$.value,
                            sortOrder: this.sortOrder$.value,
                        }
                    )
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ conversations, count }) => {
                    this._store.dispatch({ type: MessagesActions.loadMoreConversations.type, conversationsWithMessages: conversations });
                    this.filteredConversations = this.conversationsWithMessages;
                    this.errorLoadingConversations = false;
                    this.paginations[this.conversationType].total = count;
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.paginations[this.conversationType].pageNumber--;
                    this.errorLoadingConversations = true;
                },
            });
    }

    searchChanged(value: string): void {
        this.conversationsTextSearch = value;
        if (value === '') {
            this.filteredConversations = this.conversationsWithMessages;
        } else {
            this.filteredConversations = this.conversationsWithMessages?.filter((c) =>
                c.conversation.userInfo?.displayName?.toLowerCase()?.includes(value.toLowerCase())
            );
        }
    }

    sortedBy(value: string): void {
        this.sortBy$.next(value);
    }

    changedSortOrder(value: number): void {
        this.sortOrder$.next(value);
    }

    changeSelectedPlatforms({ checked, platform }: { checked: boolean; platform: AvailablePlatform }): void {
        this.availablePlatforms$.next(
            this.availablePlatforms$.value.map((p) => {
                if (p.key === platform.key) {
                    return { ...p, checked };
                }
                return p;
            })
        );

        if (checked) {
            this.checkedPlatforms$.next([...this.checkedPlatforms$.value, platform.key]);
            return;
        }

        if (this.selectedConversation?.conversation?.key === platform.key) {
            this._store.dispatch({ type: MessagesActions.changeCurrentConversation.type, payload: null });
        }
        this.checkedPlatforms$.next(this.checkedPlatforms$.value.filter((key) => key !== platform.key));
    }

    onSelectConversation(event: ConversationWithMessages): void {
        this.selectedConversation = event;
        this.scrollDown.emit({ isNewConversation: true });
        this._store.dispatch({ type: MessagesActions.changeCurrentConversation.type, conversationWithMessages: event });
        this.conversationChanged$.next(event);
    }

    onCheckConversation(event: ConversationWithMessages): void {
        this.checkedConversationsWithMessages.update((value) => {
            const index = value.findIndex(
                (conversationWithMessages) => conversationWithMessages.conversation._id === event.conversation._id
            );
            if (index >= 0) {
                value.splice(index, 1);
            } else {
                value.push(event);
            }
            return [...value];
        });
    }

    loadMoreConversations(): void {
        this.shouldLoadMoreConversations$.next(true);
    }

    redirectToPlatforms(): void {
        this._store
            .select(selectRestaurantId)
            .pipe(take(1))
            .subscribe({
                next: (restaurantId) =>
                    this._router.navigate(['/restaurants', restaurantId, 'settings', 'platforms', 'connection'], {
                        relativeTo: this._activatedRoute,
                    }),
            });
    }

    synchronizeMessages(): void {
        this.showPlatformsStatus = true;

        this.checkedPlatforms$
            .pipe(
                take(1),
                map((checkedPlatforms) => checkedPlatforms.filter((p) => p !== PlatformKey.GMB)),
                switchMap((platforms) => {
                    const syncStatus = {};
                    platforms.forEach((p) => (syncStatus[p] = { status: 'async', lastTried: null, error: null }));
                    this.platformsStatus$.next(syncStatus);
                    return this._messagesService.synchronizeMessages(this.restaurant._id, Object.keys(syncStatus));
                })
            )
            .subscribe({
                next: () => this.startStatusWatcher(),
                error: (err) => {
                    console.error(err);
                    this.updateFinished();
                },
            });
    }

    startStatusWatcher(): void {
        this.hasUpdateFinished$.next(false);
        this.statusSubscription = interval(1000)
            .pipe(
                switchMap(() => this._restaurantsService.show(this.restaurant._id).pipe(map((res) => res.data))),
                map((restaurant: Restaurant) => restaurant?.currentState?.messages?.fetched),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (status) => {
                    this.platformsStatus$.next(status);
                    const hasFinished = this._hasFinished(status);
                    this.hasUpdateFinished$.next(hasFinished);
                    if (hasFinished) {
                        this.updateFinished();
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    updateFinished(): void {
        this.statusSubscription?.unsubscribe();
        this.hasLoadedConversation = {};
        this._loadConversations();
        const errors = Object.values(this.platformsStatus$?.value ?? {})
            ?.map((status) => status.error)
            ?.filter((e) => !!e);
        if (!errors?.length) {
            return;
        }
        const err = errors[0];
        console.warn('sync err :>>', err);
        if (this._isAuthorizationError(err)) {
            this._showAuthorizationModal();
        } else {
            this._toastService.openErrorToast(this._clarifyError(err), ToastDuration.LONG);
        }
    }

    goToPlatforms(): void {
        this._router.navigate(['../../settings/platforms/connection'], { relativeTo: this._activatedRoute });
    }

    getPlatformTooltip(platform: AvailablePlatform): string | undefined {
        if (platform.key === PlatformKey.INSTAGRAM) {
            return this._translate.instant('messages.conversations.instagram_only_for_business');
        }
    }

    onSelectedTabChange(event: MatTabChangeEvent): void {
        this.selectedTabIndex = event.index;
        const previousConversationType = this.conversationType;
        this.conversationType = Object.values(ConversationType)[this.selectedTabIndex];
        if (!this.hasLoadedConversation[this.conversationType]) {
            this._loadConversations(previousConversationType);
        }
        this.checkedConversationsWithMessages.set([]);
    }

    onChangeArchivedConversation(id: string): void {
        if (this.selectedConversation?.conversation._id === id) {
            this.selectedConversation = null;
        }
    }

    onRemoveConversationFromFavorite(id: string): void {
        if (this.selectedTabIndex === 1 && this.selectedConversation?.conversation._id === id) {
            this.selectedConversation = null;
        }
    }

    conversationStatusFiltersChange(status: ConversationStatus[]): void {
        this.conversationStatusFilters$.next(status);
    }

    markSelectedConversationsAsUnread(): void {
        this._changeConversationReadStatus(ConversationStatus.UNREAD);
    }

    markSelectedConversationsAsRead(): void {
        this._changeConversationReadStatus(ConversationStatus.READ);
    }

    addSelectedConversationsToFavorites(): void {
        this._changeConversationFavoriteStatus(true);
    }

    removeSelectedConversationsFromFavorites(): void {
        this._changeConversationFavoriteStatus(false);
    }

    unarchiveSelectedConversations(): void {
        this._changeConversationArchiveStatus(false);
    }

    archiveSelectedConversations(): void {
        this._changeConversationArchiveStatus(true);
    }

    private _changeConversationReadStatus(status: ConversationStatus): void {
        const conversationsToUpdate = this.checkedConversationsWithMessages()
            .filter((conversationWithMessages) => conversationWithMessages.conversation.status !== status)
            .map((conversationWithMessages) => conversationWithMessages.conversation);

        this._messagesService.updateConversations(conversationsToUpdate, { status }).subscribe({
            next: (result) => {
                if (!result.metadata?.error && result.data.modifiedCount > 0) {
                    conversationsToUpdate.forEach((conversation) => {
                        this._store.dispatch({
                            type: MessagesActions.editConversationStatus.type,
                            conversation,
                            status,
                        });
                    });
                }
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
            },
        });
    }

    private _changeConversationFavoriteStatus(favorite: boolean): void {
        const conversationsWithMessagesToUpdate = this.checkedConversationsWithMessages().filter(
            (conversationWithMessages) => conversationWithMessages.conversation.favorite !== favorite
        );

        const conversationsToUpdate = conversationsWithMessagesToUpdate.map(
            (conversationWithMessages) => conversationWithMessages.conversation
        );

        this._messagesService.updateConversations(conversationsToUpdate, { favorite }).subscribe({
            next: (result) => {
                if (!result.metadata?.error && result.data.modifiedCount > 0) {
                    conversationsToUpdate.forEach((conversation) => {
                        this._store.dispatch({
                            type: MessagesActions.editConversationFavorite.type,
                            conversation,
                            favorite,
                        });
                    });
                }
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
            },
        });

        // when remove conversations from favorite, in favorite tab, unselect them because they disappear from the view
        if (!favorite && this.selectedTabIndex === 1) {
            conversationsWithMessagesToUpdate.forEach((conversationWithMessages) => {
                this.checkedConversationsWithMessages.update((value) => {
                    const index = value.findIndex(
                        (convWithMessages) => convWithMessages.conversation._id === conversationWithMessages.conversation._id
                    );
                    if (index >= 0) {
                        value.splice(index, 1);
                    }
                    return [...value];
                });
            });

            if (this._isSelectedConversationUpdated(conversationsWithMessagesToUpdate)) {
                this.selectedConversation = null;
            }
        }
    }

    private _changeConversationArchiveStatus(archived: boolean): void {
        const conversationsWithMessagesToUpdate = this.checkedConversationsWithMessages().filter(
            (conversationWithMessages) => conversationWithMessages.conversation.archived !== archived
        );

        const conversationsToUpdate = conversationsWithMessagesToUpdate.map(
            (conversationWithMessages) => conversationWithMessages.conversation
        );

        this._messagesService.updateConversations(conversationsToUpdate, { archived }).subscribe({
            next: (result) => {
                if (!result.metadata?.error && result.data.modifiedCount > 0) {
                    conversationsToUpdate.forEach((conversation) => {
                        this._store.dispatch({
                            type: MessagesActions.editConversationArchived.type,
                            conversation,
                            archived,
                        });
                    });
                }
            },
            error: (err) => {
                console.error('An error occured while saving this conversation', err);
                this._openErrorToast(err.message);
            },
        });

        // when unarchive or unarchive conversations, unselect them because they disappear from the view
        conversationsWithMessagesToUpdate.forEach((conversationWithMessages) => {
            this.checkedConversationsWithMessages.update((value) => {
                const index = value.findIndex(
                    (convWithMessages) => convWithMessages.conversation._id === conversationWithMessages.conversation._id
                );
                if (index >= 0) {
                    value.splice(index, 1);
                }
                return [...value];
            });
        });

        if (this._isSelectedConversationUpdated(conversationsWithMessagesToUpdate)) {
            this.selectedConversation = null;
        }
    }

    private _isSelectedConversationUpdated = (conversationsWithMessagesUpdated: ConversationWithMessages[]): boolean =>
        conversationsWithMessagesUpdated.some(
            (conversationWithMessages) => conversationWithMessages.conversation._id === this.selectedConversation?.conversation._id
        );

    private _isAuthorizationError(err: any): boolean {
        return (
            !!String(err)?.match(/does not have permission/) ||
            !!String(err)?.match(/No access to this page/) ||
            !!String(err)?.match(/Need credentialId/) ||
            !!String(err)?.match(/not authorized application/) ||
            !!String(err)?.match(/hasn't authorized/) ||
            !!String(err)?.match(/Requires instagram_manage_messages permission/)
        );
    }

    private _showAuthorizationModal(): void {
        this._dialogService.open({
            title: this._PERMISSIONS_TRANSLATE.authorization_problem,
            message: this._PERMISSIONS_TRANSLATE.not_the_right_permissions,
            variant: DialogVariant.ALERT,
            primaryButton: {
                label: this._PERMISSIONS_TRANSLATE.edit_params,
                action: () => {
                    this._router.navigate(['/restaurants', this.restaurant._id, 'platforms', 'connection'], {
                        relativeTo: this._activatedRoute,
                    });
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
        });
    }

    private _hasFinished(status: { [key: string]: FetchedState<string> }): boolean {
        if (!status) {
            return true;
        }
        if (
            Object.values(status)
                .map((sts) => sts.status)
                .includes('async')
        ) {
            return false;
        }
        return true;
    }

    private _updateLoadingStatus(): void {
        this.isLoading = Object.values(this.loadingConversations).filter(Boolean).length !== 0;
    }

    private _loadConversations(previousConversationType?: ConversationType): void {
        combineLatest([
            this._restaurantService.restaurantSelected$,
            this.sortBy$,
            this.sortOrder$,
            this.checkedPlatforms$,
            this.conversationStatusFilters$,
        ])
            .pipe(
                filter(([restaurant]) => !!restaurant),
                tap(() => {
                    this.loadingConversations.conversations = true;
                    this._updateLoadingStatus();
                    this.paginations[this.conversationType] = { ...this._DEFAULT_PAGINATION };
                }),
                switchMap(
                    ([restaurant, sortBy, sortOrder, platforms, conversationStatus]: [
                        Restaurant,
                        string,
                        number,
                        string[],
                        ConversationStatus[],
                    ]) =>
                        this._messagesService.getConversationsPaginated(
                            restaurant._id,
                            platforms,
                            this.conversationType,
                            conversationStatus,
                            {
                                ...this.paginations[this.conversationType],
                                sortBy,
                                sortOrder,
                            }
                        )
                )
            )
            .subscribe({
                next: ({ conversations, count }) => {
                    this.selectedConversation = null;
                    if (previousConversationType && this.hasLoadedConversation[previousConversationType]) {
                        this._store.dispatch({
                            type: MessagesActions.loadMoreConversations.type,
                            conversationsWithMessages: conversations,
                        });
                    } else {
                        this._store.dispatch({ type: MessagesActions.editConversations.type, conversationsWithMessages: conversations });
                    }
                    this.filteredConversations = this.conversationsWithMessages;
                    this.errorLoadingConversations = false;
                    this.paginations[this.conversationType].total = count;
                    this.hasLoadedConversation[this.conversationType] = true;
                },
                error: (error) => {
                    console.error(error);
                    this.selectedConversation = null;
                    this.loadingConversations.conversations = false;
                    this._updateLoadingStatus();
                    this.errorLoadingConversations = true;
                },
            });
    }

    private _getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.platformsKeys$, this._store.select(selectPermissionsState)]).pipe(
            filter(([platforms, _permissions]) => !!platforms && platforms.length >= 0),
            map(([platforms, permissions]) => {
                if (platforms.length && !permissions.data) {
                    return [];
                }
                const connectedPlatforms = platforms.map((plat) => plat.key);
                this.loadingConversations.availablePlatforms = false;
                this._updateLoadingStatus();
                return this._MESSAGING_PLATFORMS_KEYS.map((platformKey) => {
                    const isConnected =
                        (connectedPlatforms.includes(platformKey) && permissions.data?.find((perm) => perm.key === platformKey)?.isValid) ??
                        false;
                    return {
                        key: platformKey,
                        connected: isConnected,
                        checked: isConnected,
                    };
                });
            }),
            takeUntil(this.killSubscriptions$)
        );
    }

    private _listenForNewMessages(): void {
        this._messagesService.getMessage$().subscribe(() => {
            this.scrollDown.emit();
        });
    }

    private _initPaginations(): Record<ConversationType, Pagination> {
        return Object.values(ConversationType).reduce(
            (acc: { [key in ConversationType]?: Pagination }, type: ConversationType) => ({
                ...acc,
                [type]: { ...this._DEFAULT_PAGINATION },
            }),
            {}
        ) as Record<ConversationType, Pagination>;
    }

    private _resetTabToGeneral(): void {
        this.conversationType = ConversationType.GENERAL;
        this.selectedTabIndex = 0;
        this.hasLoadedConversation = {};
    }

    private _clarifyError(err: string): string {
        const error = JSON.parse(err);
        const errorMessage = error?.error?.message || error?.message || String(err);
        if (errorMessage?.match(/cannot create Agent without organization/)) {
            return this._translate.instant('messages.conversations.missing_essential_info_error');
        } else if (error?.error_subcode === FacebookErrorSubCode.DISABLED_ACCESS_TO_DIRECT_MESSAGE) {
            const html = `<span>${error?.message}</span><br>
            <a mat-flat-button class="malou-button--tertiary" href="${notionLinks.AUTHORIZE_MESSAGES}" target="_blank">
                ${this._translate.instant('messages.should_activate_messages')}
            </a>`;
            return html;
        }
        return this._translate.instant('messages.errors.unknown_error') + '<br> <br>' + errorMessage;
    }

    private _openErrorToast(errMessage: string): void {
        this._toastService.openErrorToast(this._clarifyMessageServiceError(errMessage));
    }

    private _clarifyMessageServiceError(message: string): string {
        return this._translate.instant('messages.errors.unknown_error') + ' : ' + message;
    }

    private _updateCheckedConversations(): void {
        this.checkedConversationsWithMessages.update((oldValues) => {
            const newValues: ConversationWithMessages[] = [];
            oldValues.forEach((conversationWithMessages) => {
                const conversationFound = this.conversationsWithMessages.find(
                    (convWithMess) => convWithMess.conversation._id === conversationWithMessages.conversation._id
                );
                if (conversationFound) {
                    newValues.push(conversationFound);
                }
            });
            return newValues;
        });
    }
}
