import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { UpsertAggregatedRoiSettingsComponent } from '../upsert-aggregated-roi-settings/upsert-aggregated-roi-settings.component';

@Component({
    selector: 'app-update-aggregated-roi-settings-modal',
    standalone: true,
    imports: [MatIconModule, TranslateModule, UpsertAggregatedRoiSettingsComponent],
    templateUrl: './update-aggregated-roi-settings-modal.component.html',
    styleUrl: './update-aggregated-roi-settings-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateAggregatedRoiSettingsModalComponent {
    readonly SvgIcon = SvgIcon;
    constructor(
        private readonly _dialogRef: MatDialogRef<UpdateAggregatedRoiSettingsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isFromNotification?: boolean;
        }
    ) {}

    close(needsToBeUpdated: boolean = false): void {
        this._dialogRef.close({ needsToBeUpdated });
    }
}
