<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal() || (displayCloseModal() && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal() && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header md:!flex-wrap">
            <span class="order-1 whitespace-nowrap">
                {{ 'information.hours.edit_hours' | translate }}
            </span>
            <div class="order-2 flex w-full justify-end md:order-3 md:mt-2 md:justify-start">
                <mat-checkbox
                    class="malou-text-14--bold malou-color-text-2 mr-5"
                    color="primary"
                    [checked]="isClosedTemporarily()"
                    (change)="changeOpenStatus($event)">
                    {{ 'information.hours.close_temporarily' | translate }}
                </mat-checkbox>
            </div>
            <div class="order-3 flex md:order-2">
                <button class="malou-btn-icon" id="tracking_informations_hours_cancel_cross" mat-icon-button (click)="close()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        </div>

        <div class="malou-dialog__body" id="hoursModal">
            <mat-tab-group
                animationDuration="5ms"
                mat-align-tabs="start"
                [selectedIndex]="selectedTab()"
                (selectedIndexChange)="handleTabChange($event)">
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label> {{ 'information.hours.open' | translate }} </ng-template>
                    <app-business-hours-form [disabled]="isClosedTemporarily()"></app-business-hours-form>
                </mat-tab>
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span [innerHTML]="'information.hours.exceptional' | translate"></span>
                    </ng-template>
                    <app-special-hours-form [disabled]="isClosedTemporarily()"></app-special-hours-form>
                </mat-tab>
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span [innerHTML]="'information.hours.other' | translate"></span>
                    </ng-template>
                    <app-other-hours-form [disabled]="isClosedTemporarily()"></app-other-hours-form>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="malou-dialog__footer !justify-between gap-3 md:flex-col">
            <div class="!leading-4">
                @if (errorText(); as errorText) {
                    <span class="malou-color-state-error malou-text-12--semibold">
                        {{ errorText }}
                    </span>
                } @else if (shouldDisplayFacebookWarning()) {
                    <div
                        class="flex items-center gap-x-2 rounded-[5px] border border-malou-color-border-primary bg-malou-color-background-light p-3">
                        <div class="malou-status--waiting">
                            <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                        </div>
                        <div>
                            <div class="malou-text-11--semibold text-malou-color-text-1">
                                {{ 'information.hours.facebook_warning.title' | translate }}
                            </div>
                            <div class="malou-text-11--regular text-malou-color-text-2">
                                {{ 'information.hours.facebook_warning.subtitle' | translate }}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="flex min-w-fit gap-x-3 md:justify-between">
                <button
                    class="malou-btn-raised--secondary !h-11 md:grow"
                    id="tracking_informations_hours_cancel_button"
                    mat-raised-button
                    (click)="close()">
                    {{ 'common.cancel' | translate }}
                </button>
                <app-button
                    class="md:grow"
                    id="tracking_informations_hours_update"
                    buttonClasses="!h-11 md:w-full"
                    [text]="'common.update' | translate"
                    [disabled]="(doesFormHaveErrors() && !isClosedTemporarily()) || !hasBeenTouched()"
                    (buttonClick)="save()"></app-button>
            </div>
        </div>
    </div>
</ng-template>
