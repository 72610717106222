import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { CredentialsService } from ':core/services/credentials.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { ConnectMapstrPremiumService } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/connect-mapstr-premium.service';
import { MapstrConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/mapstr-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { Credential, Platform } from ':shared/models';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-mapstr-connection-step-1',
    templateUrl: './mapstr-connection-step-1.component.html',
    styleUrls: ['./mapstr-connection-step-1.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        InputTextComponent,
        ReactiveFormsModule,
        MatRadioModule,
        NgTemplateOutlet,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapstrConnectionStep1Component extends BaseStepComponent<Platform | undefined, MapstrConnectionModalResult> implements OnInit {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _credentialsService = inject(CredentialsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _store = inject(Store);
    private readonly _connectMapstrPremiumService = inject(ConnectMapstrPremiumService);

    readonly ButtonStyle = ButtonStyle;
    readonly restaurant = this._restaurantsService.currentRestaurant;

    readonly apiTokenControl = new FormControl<string>('', Validators.required);
    controlValue = toSignal(this.apiTokenControl.valueChanges);
    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));
    isError = signal(false);
    isLoading = signal(false);
    isMalouTokenValid = signal(false);
    inputErrorMessage = computed(() => {
        if (this.isLoading()) {
            return null;
        }
        if (this.isError()) {
            return this._translateService.instant('platforms.connection_new.mapstr.step_1.http_error');
        }
        if (!this.controlValue()) {
            return null;
        }
        if (!this.isMalouTokenValid()) {
            return this._translateService.instant('platforms.connection_new.mapstr.step_1.malou_token_invalid');
        }
        return null;
    });

    readonly isConnectingMapstrPremium = signal<boolean>(false);

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.apiTokenControl.valueChanges
            .pipe(
                filter(Boolean),
                filter(() => !!this.restaurant.placeId),
                debounceTime(500),
                tap(() => {
                    this.isError.set(false);
                    this.isLoading.set(true);
                    this.isMalouTokenValid.set(false);
                }),
                switchMap((value) =>
                    this._credentialsService
                        .isMapstrMalouTokenValid({
                            malouToken: value,
                            googleId: this.restaurant.placeId!,
                        })
                        .pipe(
                            catchError((err) => {
                                console.error(err);
                                this.isError.set(true);
                                this.isLoading.set(false);
                                return of(null);
                            })
                        )
                )
            )
            .subscribe((apiResult) => {
                this.isLoading.set(false);
                this.isMalouTokenValid.set(apiResult?.data ?? false);
            });

        this._store
            .select(selectCurrentPlatform({ platformKey: PlatformKey.MAPSTR }))
            .pipe(filter(isNotNil))
            .subscribe((platform) => {
                const apiToken = (platform.credentials[0] as Credential | undefined)?.accessToken;
                this.apiTokenControl.setValue(apiToken ?? null);
            });
    }

    onPrimaryClick(): void {
        const apiToken = this.apiTokenControl.value;
        if (!apiToken) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }
        this.isConnectingMapstrPremium.set(true);
        this.apiTokenControl.disable();
        this._connectMapstrPremiumService.execute(this.sharedData(), apiToken).subscribe({
            next: () => {
                this.close.emit({ hasDataChanged: true });
                this.isConnectingMapstrPremium.set(false);
                this.apiTokenControl.enable();
            },
            error: (err) => {
                console.error(err);
                this.isConnectingMapstrPremium.set(false);
                this.apiTokenControl.enable();
                this._toastService.openErrorToast(this._translateService.instant('common.error'));
            },
        });
    }
}
