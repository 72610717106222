import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-previous-period-legend',
    templateUrl: './previous-period-legend.component.html',
    styleUrls: ['./previous-period-legend.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatTooltipModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviousPeriodLegendComponent {
    readonly showMaxRangeError = input<boolean>(false);

    readonly SvgIcon = SvgIcon;
}
