import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from ':core/services/dialog.service';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';

@Injectable({ providedIn: 'root' })
export class OpenaiErrorService {
    constructor(
        private readonly _dialogService: DialogService,
        private readonly _translate: TranslateService
    ) {}

    openRateLimitErrorDialog(): void {
        this._dialogService.open({
            title: this._translate.instant('openai.api_rate_limit'),
            message: this._translate.instant('openai.rate_limit_reached'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translate.instant('common.ok'),
            },
        });
    }

    clarifyError(error: any): string {
        if (error?.error?.message?.match(/Error while creating/)) {
            return this._translate.instant('openai.create_error');
        }
        return error?.error?.message || this._translate.instant('openai.api_error');
    }
}
