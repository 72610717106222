import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-roi-missing-information-badge',
    standalone: true,
    imports: [MatIconModule, MatTooltipModule, TranslateModule],
    templateUrl: './roi-missing-information-badge.component.html',
    styleUrl: './roi-missing-information-badge.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiMissingInformationBadgeComponent {
    readonly SvgIcon = SvgIcon;
    tooltip = input<string>();
}
