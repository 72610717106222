<div class="flex h-full flex-col gap-y-4 py-4">
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }

    <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if (((restaurants$ | async)?.length ?? 0) > 1) {
            @if (((platformKeys$ | async)?.length ?? 0) !== 0 || !hasPlatformsLoadedOnce) {
                <div class="flex flex-col gap-y-6">
                    <app-aggregated-top-3-posts (isLoadingEvent)="isTop3PostsLoading.set($event)"></app-aggregated-top-3-posts>
                    <app-aggregated-posts-insights-table
                        (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_PUBLICATIONS_TABLE, $event)"
                        (isLoadingEvent)="isInsightsLoading.set($event)">
                    </app-aggregated-posts-insights-table>
                </div>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.platforms_not_selected' | translate }}</span>
                </div>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.select_at_least_2_businesses' | translate }}</span>
            </div>
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 md:flex-col md:items-center md:px-0">
        <div class="flex-1">
            <app-statistics-filters [page]="PlatformFilterPage.SOCIAL_NETWORKS"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="((restaurants$ | async)?.length ?? 0) < 2 || isLoading()"
            (click)="openDownloadStatisticsModal()">
            {{ 'aggregated_statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
