import { RoiTip, RoiTipAction, RoiTipCategory } from '@malou-io/package-utils';

const TipRedirectionUrl = {
    [RoiTipCategory.REVIEWS]: 'reputation/reviews',
    [RoiTipCategory.BOOSTER]: 'boosters/wheels-of-fortune',
    [RoiTipCategory.GOOGLE]: 'seo/posts/list',
    [RoiTipCategory.INFORMATION]: 'seo/informations',
    [RoiTipCategory.INTERACTION]: 'interactions/comments',
    [RoiTipCategory.KEYWORDS]: 'resources/keywords/list',
    [RoiTipCategory.SOCIAL_MEDIA]: 'social/socialposts',
    [RoiTipCategory.MOBILE_APP]: 'dashboard',
};

const TipEmojiPath: Record<RoiTip, string> = {
    [RoiTip.RS_NO_ACCOUNT_IN_INSPIRATION]: 'tips/bulb',
    [RoiTip.RS_POST_COUNT_PER_PLATFORM_TOO_LOW]: 'saved-time/social_post',
    [RoiTip.RS_POSTS_OF_THE_SAME_TYPE]: 'saved-time/social_post',
    [RoiTip.RS_STORY_COUNT_TOO_LOW]: 'performance-score/instagram_post_count',
    [RoiTip.RS_HASHTAG_COUNT_TOO_LOW_OR_TOO_HIGH]: 'performance-score/instagram_post_count',
    [RoiTip.GOOGLE_SAME_SEO_POST_TYPE]: 'saved-time/seo_post',
    [RoiTip.GOOGLE_NO_ACTION_BUTTON_IN_POSTS]: 'tips/action',
    [RoiTip.GOOGLE_AVERAGE_KEYWORDS_SCORE_TOO_LOW]: 'tips/bulb',
    [RoiTip.GOOGLE_POST_COUNT_TOO_LOW]: 'saved-time/seo_post',
    [RoiTip.BOOSTER_NO_TOTEMS]: 'tips/moai',
    [RoiTip.BOOSTER_NO_WHEEL_OF_FORTUNE]: 'tips/wheel_of_fortune',
    [RoiTip.BOOSTER_NO_CAMPAIGNS]: 'performance-score/keywords_in_top_ten',
    [RoiTip.KEYWORDS_NOT_UPDATED_FOR_3_MONTHS]: 'tips/sun',
    [RoiTip.KEYWORDS_SAME_POPULARITY]: 'tips/cool',
    [RoiTip.INFORMATION_MISSING_DATA]: 'tips/nerd',
    [RoiTip.INFORMATION_EMPTY_DESCRIPTION]: 'tips/monocle',
    [RoiTip.INFORMATION_DATA_NOT_UPDATED_FOR_3_MONTHS]: 'tips/nerd',
    [RoiTip.REVIEWS_NOT_ENOUGH]: 'performance-score/keywords_in_top_ten',
    [RoiTip.REVIEWS_PRICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/money_bag',
    [RoiTip.REVIEWS_QUALITY_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/star',
    [RoiTip.REVIEWS_SERVICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/running',
    [RoiTip.REVIEWS_HYGIENE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/inspector',
    [RoiTip.REVIEWS_KITCHEN_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/chef',
    [RoiTip.REVIEWS_ATMOSPHERE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS]: 'tips/pop_star',
    [RoiTip.REVIEWS_AVERAGE_RESPONSE_TIME_TOO_HIGH]: 'tips/bulb',
    [RoiTip.REVIEWS_AUTOMATION_NOT_ACTIVATED]: 'tips/robot',
    [RoiTip.INTERACTION_UNANSWERED_COMMENT_AND_MENTION_COUNT_TOO_HIGH]: 'tips/mail_box',
    [RoiTip.INTERACTION_UNANSWERED_MESSAGE_COUNT_TOO_HIGH]: 'saved-time/interaction',
    [RoiTip.MOBILE_APP_NOT_BEING_USED]: 'estimated-customers/phone_call_clicks',
};

export class RoiTipItem {
    id: RoiTip;
    restaurantId: string;

    category: RoiTipCategory;
    action: RoiTipAction;

    actionRedirectionLink: string;

    actionTranslationKey: string;
    titleTranslationKey: string;
    descriptionTranslationKey: string;

    emojiPath: string;

    value = 0;

    public constructor(init?: Partial<RoiTipItem>) {
        Object.assign(this, init);
        const { title, description } = this._getTranslationKey();
        this.actionTranslationKey = `roi.tips.buttons.${this.action.toLowerCase()}`;
        this.titleTranslationKey = title;
        this.descriptionTranslationKey = description;
        this.emojiPath = this._getEmojiPath();
        this.actionRedirectionLink = this._getActionRedirectionLink();
    }

    private _getTranslationKey(): { title: string; description: string } {
        if (this.category === RoiTipCategory.REVIEWS && this.action === RoiTipAction.CHECK) {
            return {
                title: `roi.tips.items.${this.category.toLowerCase()}.${this.id.toLowerCase()}_title`,
                description: `roi.tips.items.${this.category.toLowerCase()}.reviews_tag_description`,
            };
        }
        return {
            title: `roi.tips.items.${this.category.toLowerCase()}.${this.id.toLowerCase()}_title`,
            description: `roi.tips.items.${this.category.toLowerCase()}.${this.id.toLowerCase()}_description`,
        };
    }

    private _getEmojiPath(): string {
        return `/assets/emojis/roi/${TipEmojiPath[this.id]}.png`;
    }

    private _getActionRedirectionLink(): string {
        switch (this.id) {
            case RoiTip.RS_NO_ACCOUNT_IN_INSPIRATION:
                return `/restaurants/${this.restaurantId}/social/inspirations`;
            case RoiTip.RS_STORY_COUNT_TOO_LOW:
                return `/restaurants/${this.restaurantId}/social/stories`;
            case RoiTip.REVIEWS_NOT_ENOUGH:
                return `/restaurants/${this.restaurantId}/boosters/wheels-of-fortune`;
            case RoiTip.REVIEWS_SERVICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_PRICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_QUALITY_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_SERVICE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_HYGIENE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_KITCHEN_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
            case RoiTip.REVIEWS_ATMOSPHERE_REGULARLY_MENTIONED_IN_NEGATIVE_ANALYSIS:
                return `/restaurants/${this.restaurantId}/statistics/e-reputation`;
            default:
                return `/restaurants/${this.restaurantId}/${TipRedirectionUrl[this.category]}`;
        }
    }
}
