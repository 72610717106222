<div class="flex flex-col gap-12">
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'aggregated_statistics_pdf.boosters.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantsTitle$ | async | statisticsPdfRestaurantsFormatter: true }}
        </span>
    </div>

    @if (boostersAggregatedScanCountHasData) {
        <div class="flex flex-col gap-4 px-8.5 py-4">
            @if (displayedCharts | includes: InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT) {
                <app-aggregated-boosters-scan-count
                    [chartSortBy]="chartOptions[InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT]?.chartSortBy"
                    [data$]="boostersData$"
                    [restaurants$]="restaurants$"
                    [isParentLoading]="isLoadingBoosters()"
                    [isParentError]="isErrorBoosters()"
                    (hasDataChange)="boostersAggregatedScanCountHasData = $event"></app-aggregated-boosters-scan-count>
            }
            @if (
                atLeastOneBoosterPackActivated() &&
                (displayedCharts | includes: InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT)
            ) {
                <ng-container [ngTemplateOutlet]="wheelOfFortuneStatisticsTemplate"></ng-container>
            }
        </div>
    }
</div>

<ng-template #wheelOfFortuneStatisticsTemplate>
    <div class="malou-text-18--bold text-malou-color-text-1">{{ 'aggregated_statistics.boosters.scans.wheel_of_fortune' | translate }}</div>
    @if (wheelOfFortuneAggregatedGiftsKpisHasData) {
        <app-aggregated-wheel-of-fortune-gifts-kpis
            [data$]="giftsData$"
            [isParentLoading]="isLoadingGifts()"
            [isParentError]="isErrorGifts()"
            (hasDataChange)="wheelOfFortuneAggregatedGiftsKpisHasData = $event"></app-aggregated-wheel-of-fortune-gifts-kpis>
    }

    <div class="flex gap-4 md:flex-col">
        @if (wheelOfFortuneAggregatedGiftsDistributionHasData) {
            <div class="min-w-0 flex-1">
                <app-aggregated-wheel-of-fortune-gifts-distribution
                    class="flex h-[500px]"
                    [data$]="giftsData$"
                    [restaurants$]="restaurantsWithBoosterPackActivated$"
                    [isParentLoading]="isLoadingGifts()"
                    [isParentError]="isErrorGifts()"
                    [tableSort]="chartOptions[InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION]?.tableSortOptions"
                    (hasDataChange)="
                        wheelOfFortuneAggregatedGiftsDistributionHasData = $event
                    "></app-aggregated-wheel-of-fortune-gifts-distribution>
            </div>
        }
        @if (wheelOfFortuneAggregatedEstimatedReviewCountHasData) {
            <div class="min-w-0 flex-1">
                <app-aggregated-wheel-of-fortune-estimated-review-count
                    class="flex h-[500px]"
                    [data$]="wheelOfFortuneData$"
                    [restaurants$]="restaurantsWithBoosterPackActivated$"
                    [isParentLoading]="isLoadingBoosters()"
                    [isParentError]="isErrorBoosters()"
                    [tableSort]="chartOptions[InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT]?.tableSortOptions"
                    (hasDataChange)="wheelOfFortuneAggregatedEstimatedReviewCountHasData = $event">
                </app-aggregated-wheel-of-fortune-estimated-review-count>
            </div>
        }
    </div>
</ng-template>
