// !!! WARNING !!!
// if you make change to these validators, you must also make change to the interfaces
// Because on strict false, zod make all properties optional
// So when strict true, remove interfaces and infer type with zod
import { z } from 'zod';

export interface AddressDto {
    locality?: string;
    regionCode: string;
    country?: string;
    postalCode?: string;
    formattedAddress?: string;
    streetNumber?: string;
    route?: string;
    administrativeArea?: string;
}

export const addressDtoValidator = z.object({
    locality: z.string().optional(),
    regionCode: z.string(),
    country: z.string().optional(),
    postalCode: z.string().optional(),
    formattedAddress: z.string().optional(),
    streetNumber: z.string().optional(),
    route: z.string().optional(),
    administrativeArea: z.string().optional(),
});
