<div
    class="modal-container"
    [ngClass]="{
        'h-[90vh]': !displayCloseModal && !screenSizeService.isPhoneScreen && type === TemplateType.REVIEW,
        'h-[80vh]':
            !displayCloseModal && (screenSizeService.isPhoneScreen || (!screenSizeService.isPhoneScreen && type === TemplateType.MESSAGE)),
        'max-h-[520px]': !displayCloseModal && !screenSizeService.isPhoneScreen && type === TemplateType.MESSAGE,
        'h-[50vh]': displayCloseModal && screenSizeService.isPhoneScreen,
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal class="w-full" id="closeModal" (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false">
    </app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile">
        <div class="malou-dialog__header">
            <span class="order-1 whitespace-nowrap">
                {{
                    type === TemplateType.REVIEW
                        ? ('templates.review.create_template' | translate)
                        : ('templates.message.create_template' | translate)
                }}
            </span>
            <div class="order-3 flex md:order-2">
                <button class="malou-btn-icon" mat-icon-button (click)="close()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        </div>
        <div class="malou-dialog__body">
            <form [formGroup]="templateForm">
                <div class="mb-2 flex flex-col gap-y-4">
                    <div class="malou-text-10--regular malou-color-text-2 flex">
                        <app-input-text
                            class="w-full"
                            formControlName="name"
                            [required]="true"
                            [title]="'templates.review.upsert_modal.template_name' | translate"
                            [placeholder]="
                                type === TemplateType.REVIEW
                                    ? ('templates.review.upsert_modal.template_name_placeholder' | translate)
                                    : ('templates.message.upsert_modal.template_name_placeholder' | translate)
                            "
                            [autocapitalize]="'none'">
                        </app-input-text>
                    </div>
                    @if (type === TemplateType.REVIEW) {
                        <div>
                            <app-select
                                class="w-full"
                                formControlName="withComment"
                                [title]="'reviews.reply_modal.templates.review_type' | translate"
                                [placeholder]="'common.search' | translate"
                                [values]="availableCommentOptions"
                                [displayWith]="commentOptionDisplayWith"></app-select>
                            <app-template-rating-filter
                                class="w-full"
                                formControlName="rating"
                                [title]="('reviews.reply_modal.templates.rating' | translate) + ' *'"
                                [tooltipOnRating]="'reviews.reply_modal.templates.adjacent_ratings' | translate"
                                [errorMessage]="
                                    templateForm.errors?.hasVariableToReplaceError ||
                                    (templateForm.controls.rating.dirty && templateForm.controls.rating.invalid
                                        ? ('common.required_field' | translate)
                                        : '')
                                "
                                (selectRatings)="onSelectRatings($event)"></app-template-rating-filter>
                        </div>
                    }
                    <ng-container *ngTemplateOutlet="templateChips"></ng-container>
                    <div class="w-full">
                        <app-text-area
                            [textAreaId]="TEXT_AREA_ID"
                            [title]="'reviews.reply_modal.message' | translate"
                            [placeholder]="TEMPLATE_REVIEW_TRANSLATE.placeholder"
                            [formControl]="textFormControl"
                            [debounceTime]="200"
                            [required]="true"
                            [errorMessage]="
                                templateForm.errors?.hasVariableToReplaceError ||
                                (templateForm.controls.text.dirty && templateForm.controls.text.invalid
                                    ? ('common.required_field' | translate)
                                    : '')
                            "
                            (textAreaChange)="onTextChange($event)"></app-text-area>
                    </div>
                    <ng-container>
                        @if (!(restaurant | applySelfPure: 'isBrandBusiness') && type === TemplateType.REVIEW) {
                            <app-keywords-score-gauge
                                [text$]="text$"
                                [restaurant$]="restaurant$"
                                [shouldCacheScore]="false"
                                [textType$]="textType$"
                                [keywords$]="restaurantKeywords$"
                                [shouldDisplayTips]="true"
                                [shouldDisplayKeywords]="true"
                                (addKeyword)="addText($event)"
                                #keywordsScoreGauge>
                            </app-keywords-score-gauge>
                        }
                    </ng-container>
                </div>
            </form>
        </div>
        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <app-button
                class="md:grow"
                buttonClasses="!h-11 md:full"
                [disabled]="!templateForm.valid"
                [text]="'common.save' | translate"
                [loading]="isUpsertingTemplate()"
                (buttonClick)="submit()"></app-button>
        </div>
    </div>
</ng-template>

<ng-template #templateChips>
    <div class="flex flex-wrap gap-1">
        @for (chip of chipList; track chip) {
            <div class="malou-chip malou-chip--primary" (click)="addChipToText(chip)">
                <span>{{ chip }}</span>
            </div>
        }
    </div>
</ng-template>
