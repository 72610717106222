import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { InvalidPlatformReason, isNotNil, PlatformKey } from '@malou-io/package-utils';

import { PermissionsState } from ':core/credentials/store/permissions.interface';
import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { ToastService } from ':core/services/toast.service';
import { selectRestaurantId } from ':modules/platforms/store/platforms.reducer';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserRestaurants } from ':modules/user/store/user.selectors';
import { UsersService } from ':modules/user/users.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

const GOOGLE_LOCATION_MANAGER_LINK = 'https://business.google.com/locations';

enum PermissionStatus {
    WARNING = 'warning',
    INFO = 'info',
}
@Component({
    selector: 'app-permissions-headband',
    templateUrl: './permissions-headband.component.html',
    styleUrls: ['./permissions-headband.component.scss'],
    standalone: true,
    imports: [MatIconModule, AsyncPipe, TranslateModule],
})
export class PermissionsHeadbandComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    isHeadbandOpen$: Observable<boolean>;
    warningText$: BehaviorSubject<string> = new BehaviorSubject('');
    status: PermissionStatus = PermissionStatus.WARNING;
    missingPlatformPermissions: string;
    shouldRedirectToGoogleLocationManger = false;
    ctaButtonLabel: string = this._translate.instant('permissions.reconnect');

    constructor(
        private readonly _store: Store,
        private readonly _userService: UsersService,
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.isHeadbandOpen$ = combineLatest([
            this._store.select(selectPermissionsState),
            this._store.select(selectUserRestaurants),
            this._store.select(selectRestaurantId).pipe(distinctUntilChanged()),
            this._router.events,
        ]).pipe(
            map(([permissions, userRestaurants, restaurantId, routerEv]) => {
                if (
                    (routerEv instanceof NavigationEnd && routerEv.urlAfterRedirects === '/restaurants/list') ||
                    !permissions.data?.length
                ) {
                    return false;
                }
                this.setWarningText(permissions);
                const restaurant = userRestaurants.find((r) => r.restaurantId === restaurantId);
                const displayHeadband = restaurant && 'displayPermissionsModal' in restaurant ? restaurant.displayPermissionsModal : true;
                const validPermissions = permissions.data.reduce((sum, next) => sum && next.isValid, true);
                return displayHeadband && !validPermissions;
            })
        );
    }

    setWarningText(permissions: PermissionsState): void {
        const invalidPerms = permissions.data.filter((perm) => !perm.isValid);
        const platforms = permissions.data.reduce((pl: string[], next) => (!next.isValid ? pl.concat(next.key) : pl), []);
        if (invalidPerms?.[0]?.missing?.[0]?.match(/businesscommunications/)) {
            this.status = PermissionStatus.INFO;
            this.missingPlatformPermissions = PlatformKey.GMB;
            this.warningText$.next(this._translate.instant('permissions.business_communications_missing'));
        } else if (
            invalidPerms?.[0]?.missing?.[0]?.match(/pages_messaging/) ||
            invalidPerms?.[0]?.missing?.[0]?.match(/instagram_manage_messages/)
        ) {
            this.status = PermissionStatus.INFO;
            this.missingPlatformPermissions = invalidPerms?.[0]?.missing[0]?.match(/pages_messaging/)
                ? PlatformKey.FACEBOOK
                : PlatformKey.INSTAGRAM;
            this.warningText$.next(this._translate.instant('permissions.facebook_messages_missing'));
        } else if (invalidPerms?.[0]?.miscellaneous?.match(InvalidPlatformReason.GMB_SUSPENDED)) {
            this.status = PermissionStatus.INFO;
            this.warningText$.next(
                platforms.length ? this._translate.instant('permissions.gmb_suspended', { platforms: platforms.join(' & ') }) : ''
            );
        } else if (invalidPerms?.[0]?.miscellaneous?.match(InvalidPlatformReason.GMB_DISABLED)) {
            this.status = PermissionStatus.INFO;
            this.ctaButtonLabel = this._translate.instant('permissions.open_google_location_manager');
            this.shouldRedirectToGoogleLocationManger = true;
            this.warningText$.next(this._translate.instant('permissions.gmb_disabled'));
        } else if (invalidPerms?.[0]?.miscellaneous?.match(InvalidPlatformReason.GMB_NOT_VERIFIED)) {
            this.status = PermissionStatus.INFO;
            this.ctaButtonLabel = this._translate.instant('permissions.open_google_location_manager');
            this.shouldRedirectToGoogleLocationManger = true;
            this.warningText$.next(this._translate.instant('permissions.gmb_not_verified'));
        } else if (invalidPerms?.[0]?.miscellaneous?.match(InvalidPlatformReason.GMB_PENDING_VERIFICATION)) {
            this.ctaButtonLabel = '';
            this.status = PermissionStatus.INFO;
            this.warningText$.next(this._translate.instant('permissions.gmb_pending_verification'));
        } else {
            this.status = PermissionStatus.INFO;
            this.warningText$.next(
                platforms.length ? this._translate.instant('permissions.connection_expired', { platforms: platforms.join(' & ') }) : ''
            );
        }
    }

    closeHeadBand(): void {
        this.isHeadbandOpen$ = of(false);
        this.updateDisplayPermissionsHeadband();
    }

    redirectToConnection(): void {
        const queryParams: any = {};
        if (this.missingPlatformPermissions) {
            queryParams.missingPlatformPermissions = this.missingPlatformPermissions;
        }
        this._store
            .select(selectRestaurantId)
            .pipe(take(1))
            .subscribe({
                next: (restaurantId) =>
                    this._router.navigate(['/restaurants', restaurantId, 'settings', 'platforms', 'connection'], {
                        relativeTo: this._activatedRoute,
                        queryParams,
                    }),
            });
    }

    updateDisplayPermissionsHeadband(): void {
        combineLatest([this._store.select(selectUserRestaurants), this._store.select(selectRestaurantId)])
            .pipe(
                take(1),
                filter(([userRestaurants, restaurantId]) => !!userRestaurants.length && !!restaurantId),
                map(([userRestaurants, restaurantId]) => userRestaurants.find((r) => r.restaurantId === restaurantId)),
                filter(isNotNil),
                switchMap((update) => this._userService.updateUserRestaurantById(update._id, { displayPermissionsModal: false }))
            )
            .subscribe({
                next: () => {
                    this._store.dispatch({ type: UserActions.loadUser.type });
                },
                error: (err) => {
                    if (err?.status !== 403) {
                        this._toastService.openErrorToast(this._translate.instant('permissions.unknown_error'));
                    }
                },
            });
    }

    redirectToGoogleLocationManager(): void {
        window.open(GOOGLE_LOCATION_MANAGER_LINK, '_blank');
    }

    getCtaButtonLabel(): string {
        return this.shouldRedirectToGoogleLocationManger
            ? this._translate.instant('permissions.open_google_location_manager')
            : this._translate.instant('permissions.reconnect');
    }

    onCtaButtonClick(): void {
        if (this.shouldRedirectToGoogleLocationManger) {
            this.redirectToGoogleLocationManager();
        } else {
            this.redirectToConnection();
        }
    }
}
