import { createAction, props } from '@ngrx/store';

import { Conversation, ConversationWithMessage, ConversationWithMessages } from ':shared/models/conversation';
import { Message } from ':shared/models/message';

export const receiveNewMessage = createAction(
    '[Messages] Receive_new_message',
    props<{ conversationWithMessage: ConversationWithMessage }>()
);

export const deleteMessage = createAction('[Messages] Delete_message', props<{ conversationWithMessage: ConversationWithMessage }>());

export const receiveNewReaction = createAction(
    '[Messages] Receive_new_reaction',
    props<{ conversationWithMessage: ConversationWithMessage }>()
);

export const sendNewMessage = createAction('[Messages] Send_new_message', props<{ message: Partial<Message> }>());

export const editConversationStatus = createAction(
    '[Messages] Edit_Conversation_Status',
    props<{ conversation: Conversation; status: string }>()
);

export const editConversationFavorite = createAction(
    '[Messages] Edit_Conversation_Favorite',
    props<{ conversation: Conversation; favorite: boolean }>()
);

export const editConversations = createAction(
    '[Messages] Edit_Conversations',
    props<{ conversationsWithMessages: ConversationWithMessages[] }>()
);

export const editConversationArchived = createAction(
    '[Messages] Edit_Conversation_Archived',
    props<{ conversation: Conversation; archived: boolean }>()
);

export const loadMoreConversations = createAction(
    '[Messages] Load_More_Conversations',
    props<{ conversationsWithMessages: ConversationWithMessages[] }>()
);

export const editCount = createAction('[Messages] Edit_Count', props<{ unreadConversationCount: number }>());

export const loadMoreMessages = createAction(
    '[Messages] Load_More_Messages',
    props<{ conversationWithMessages: ConversationWithMessages }>()
);

export const changeCurrentConversation = createAction(
    '[Messages] Change_Current_Conversation',
    props<{ conversationWithMessages: ConversationWithMessages }>()
);

export const editMessageAfterSend = createAction(
    '[Messages] Edit_Message_After_Send',
    props<{ message: Partial<Message>; correctDbId: string }>()
);

export const editConversationTypedText = createAction('[Messages] Edit_Conversation_Typed_Text', props<{ text: string }>());
