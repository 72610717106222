@if (isLoading()) {
    <div class="flex flex-col gap-4">
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
    </div>
} @else {
    @if (isReviewAnalysisEnabled) {
        @if (reviews.length) {
            <div class="flex flex-col gap-4">
                @if (shouldDisplayAnalysesTagCharts) {
                    <div class="malou-simple-card flex flex-col gap-1.5 px-6 py-3 md:px-2">
                        <div class="flex items-center">
                            <div class="malou-text-section-title malou-color-text-1">
                                {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis' | translate }}
                            </div>
                        </div>
                        <div class="malou-text-12--regular malou-color-text-2">
                            {{ 'statistics.e_reputation.reviews_analysis.on_analyzed_reviews' | translate }}
                        </div>
                        <div class="flex lg:flex-col-reverse">
                            <div class="min-w-0 flex-1">
                                <app-tags-bar-chart
                                    [reviews]="reviews"
                                    [shouldDisplayAnalysesTagClickableLabels]="shouldDisplayAnalysesTagClickableLabels"
                                    [isFromAggregatedStatistics]="false">
                                </app-tags-bar-chart>
                            </div>
                            <div class="min-w-0 flex-1">
                                <app-tags-doughnut-chart [reviews]="reviews"></app-tags-doughnut-chart>
                            </div>
                        </div>
                    </div>
                }
                @if (shouldDisplayAnalysesTagEvolution) {
                    <div>
                        <app-tags-evolution
                            [shouldDetailTagsEvolutionCharts]="shouldDetailTagsEvolutionCharts"
                            [reviews]="reviews"
                            [dates]="(dates$ | async)!"
                            [viewBy]="tagEvolutionViewBy"
                            (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"
                            (viewByChange)="viewByChange.emit($event)"></app-tags-evolution>
                    </div>
                }
            </div>
        } @else {
            <div class="my-4 flex flex-col items-center py-4">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">
                    {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis_not_available' | translate }}
                </span>
                <span class="malou-text-10--regular">
                    {{ 'statistics.e_reputation.reviews_analysis.edit_filters' | translate }}
                </span>
            </div>
        }
    } @else {
        <div class="my-4 flex flex-col items-center py-4">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">
                {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis_disabled' | translate }}
            </span>
            <span class="malou-text-10--regular">
                {{ 'statistics.e_reputation.reviews_analysis.contact_us' | translate }}
            </span>
        </div>
    }
}
