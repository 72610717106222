<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="translations().primaryButton | translate"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [showTertiaryButton]="hasTutorialLink()"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col gap-y-6">
        <div class="malou-color-text-1 malou-text-12--bold">{{ translations().description1 | translate }}</div>
        <div
            class="malou-color-text-2 malou-text-12--medium"
            [innerHtml]="translations().description2 | translate: { class: 'malou-text-12--bold' }"></div>
        <div
            class="malou-color-background-light flex h-[45px] items-center self-center rounded-lg border border-malou-color-background-dark">
            <div class="malou-color-text-2 malou-text-12--semibold px-2">{{ MALOU_MANAGER_EMAIL_ADDRESS }}</div>
            <div class="flex cursor-pointer border-l border-malou-color-background-dark px-3" (click)="onCopyButtonClick()">
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.COPY"></mat-icon>
            </div>
        </div>
        <img
            class="{{ imagesClasses[platformKey()] }} self-center mt-4"
            [src]="imageName() | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
    </div>
</app-modal-structure>
