import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PlatformDefinitions } from '@malou-io/package-utils';

/*
 * Return platform name given a platform key
 */
@Pipe({
    name: 'platformName',
    standalone: true,
})
export class PlatformNamePipe implements PipeTransform {
    constructor(private readonly _translateService: TranslateService) {}

    transform(platKey: string): string {
        return PlatformDefinitions.getPlatformDefinition(platKey)?.fullName || this._translateService.instant(platKey);
    }
}
