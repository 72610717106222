@if (useContainer()) {
    <div class="flex {{ heightClass() }} w-full {{ gapClass() }} {{ flexDirection() }} {{ flexWrap() }} {{ justifyContent() }}">
        @for (c of countArray(); track $index) {
            <ng-container *ngTemplateOutlet="singleskeleton"></ng-container>
        }
    </div>
} @else {
    <div
        class="skeleton-bg {{ skeletonClass() }} malou-animate-pulse"
        [ngClass]="{
            'rounded-full': appearance() === 'circle',
            'w-6': appearance() === 'circle',
            'rounded-lg': appearance() === 'line',
            'w-full': appearance() === 'line',
            'malou-animate-pulse': animate(),
        }">
        @if (nestedSkeletonTemplateRef(); as nestedSkeletonTemplateRef) {
            <ng-container [ngTemplateOutlet]="nestedSkeletonTemplateRef"></ng-container>
        } @else {
            <ng-content></ng-content>
        }
    </div>
}

<ng-template #singleskeleton>
    <div
        class="skeleton-bg {{ skeletonClass() }} malou-animate-pulse"
        [ngClass]="{
            'rounded-full': appearance() === 'circle',
            'w-6': appearance() === 'circle',
            'rounded-lg': appearance() === 'line',
            'w-full': appearance() === 'line',
            'malou-animate-pulse': animate(),
        }">
        @if (nestedSkeletonTemplateRef(); as nestedSkeletonTemplateRef) {
            <ng-container [ngTemplateOutlet]="nestedSkeletonTemplateRef"></ng-container>
        } @else {
            <ng-content></ng-content>
        }
    </div>
</ng-template>
