import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { isObservable, Subscription } from 'rxjs';

interface InterpolatedParams {
    restaurants: string;
}

@Pipe({
    name: 'statisticsPdfRestaurantsFormatter',
    standalone: true,
    pure: false,
})
export class StatisticsPdfRestaurantsFormatterPipe implements PipeTransform, OnDestroy {
    value = '';
    key = '';
    interpolateParams: InterpolatedParams | undefined = undefined;

    private readonly _simpleTranslateKey = 'statistics_pdf.common.restaurant';
    private readonly _aggregatedTranslateKey = 'aggregated_statistics_pdf.common.restaurants';

    onLangChange: Subscription | undefined;

    constructor(
        private readonly _translateService: TranslateService,
        private _ref: ChangeDetectorRef
    ) {}

    ngOnDestroy() {
        this._dispose();
    }

    updateValue(interpolateParams?: Object, translations?: any): void {
        const onTranslation = (res: string): void => {
            this.value = res !== undefined ? res : this.key;
            // for rerendering the pipe
            this._ref.markForCheck();
        };

        if (translations) {
            const res = this._translateService.getParsedResult(translations, this.key, interpolateParams);

            // Because parseResult can return an Observable
            if (isObservable(res.subscribe)) {
                res.subscribe(onTranslation);
            } else {
                onTranslation(res);
            }
        }

        // get the translations
        this._translateService.get(this.key, interpolateParams).subscribe(onTranslation);
    }

    transform(restaurants: string | null, isAggregated: boolean = false): string {
        // store params
        this.key = isAggregated ? this._aggregatedTranslateKey : this._simpleTranslateKey;
        this.interpolateParams = { restaurants: restaurants ?? '' };

        // update the value
        this.updateValue(this.interpolateParams);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateValue(this.interpolateParams, event.translations);
            });
        }

        return this.value;
    }

    private _dispose(): void {
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
    }
}
