@if (isFooterVisible$ | async) {
    <div class="campaign-footer__container malou-box-shadow flex items-center justify-end px-6" id="campaign-footer">
        <ng-container [ngTemplateOutlet]="campaignStatusTemplate"> </ng-container>
        <button class="ml-3" color="primary" mat-icon-button (click)="close()">
            <mat-icon class="!h-[16px] !w-[16px]" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
}

<ng-template #campaignStatusTemplate>
    <div class="flex items-center">
        <ng-container [ngTemplateOutlet]="campaignIcon"></ng-container>
        <span class="malou-text-12--bold w-fit">{{ campaignStatus | enumTranslate: 'campaign_status' }}</span>
    </div>
</ng-template>

<ng-template #campaignIcon>
    <div class="flex">
        @switch (campaignStatus) {
            @case (CampaignStatus.IN_PROGRESS) {
                <mat-icon class="malou-spin mr-4 !h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.SPINNER"> </mat-icon>
            }
            @case (CampaignStatus.SENT) {
                <div class="malou-bg-state-success mr-2 grid h-4 w-4 place-items-center rounded-full">
                    <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.CHECK"> </mat-icon>
                </div>
            }
            @case (CampaignStatus.ERROR) {
                <div class="malou-bg-state-error mr-2 grid h-4 w-4 place-items-center rounded-full" [matTooltip]="error">
                    <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.CROSS"> </mat-icon>
                </div>
            }
        }
    </div>
</ng-template>
