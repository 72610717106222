import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';

@Component({
    selector: 'app-facebook-credentials',
    templateUrl: './facebook-credentials.component.html',
    styleUrls: ['./facebook-credentials.component.scss'],
    standalone: true,
    imports: [MatButtonModule],
})
export class FacebookCredentialsComponent implements OnInit {
    @Input() public credential: any;
    public url: string;

    constructor(private readonly _credentialsService: CredentialsService) {}

    ngOnInit(): void {
        this._credentialsService.getOauthLoginUrl(PlatformKey.FACEBOOK).subscribe((res) => {
            this.url = res.data;
        });
    }
}
