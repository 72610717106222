<div class="flex h-full flex-col">
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>

    <div class="flex min-h-0 grow">
        <div class="flex min-w-0 grow flex-col">
            <div class="flex min-h-0 grow">
                <app-duplicate-and-select-platforms class="w-[270px] shrink-0"></app-duplicate-and-select-platforms>

                <app-social-post-content-form class="min-w-0 grow"></app-social-post-content-form>
            </div>

            <app-upsert-social-post-modal-footer (cancel)="onCancel()"></app-upsert-social-post-modal-footer>
        </div>

        <app-previews-feed-notes class="w-[400px] shrink-0"></app-previews-feed-notes>
    </div>
</div>

<ng-template #headerTemplate>
    <div class="flex items-center justify-between border-b border-malou-color-border-primary px-6 py-5">
        <div class="malou-text-18--semibold text-malou-color-text-1">
            {{ 'social_posts.upsert_social_post_modal.title.create' | translate }}
        </div>

        <button class="malou-btn-icon !rounded-full !bg-malou-color-background-dark" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
</ng-template>
