import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import { AccountManagedConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import { UpdatePlatformAccessService } from ':modules/platforms/platforms-connection-modals/platforms-connection-password-managed-modal/account-managed-connection-step-2/update-platform-access.service';
import { PASSWORD_MANAGED_SHARED_DATA_TYPE } from ':modules/platforms/platforms-connection-modals/platforms-connection-password-managed-modal/password-managed-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { isSafari } from ':shared/helpers/is-safari';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-account-managed-connection-step-2',
    templateUrl: './password-managed-connection-step-2.component.html',
    styleUrls: ['./password-managed-connection-step-2.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent, ImagePathResolverPipe, MatIconModule, InputTextComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordManagedConnectionStep2Component extends BaseStepComponent<
    PASSWORD_MANAGED_SHARED_DATA_TYPE,
    AccountManagedConnectionModalResult
> {
    private readonly _toastService = inject(ToastService);
    private readonly _updatePlatformAccessService = inject(UpdatePlatformAccessService);
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;
    readonly isSafari = isSafari;

    platformKey = computed(() => this.sharedData().platformKey);

    translations = computed(() => {
        const base = 'platforms.connection_new.password_managed';
        const platformKey = this.platformKey();
        return {
            title: `${base}.${platformKey}.step_2.step_name`,
            description: `${base}.${platformKey}.step_2.description`,
            primaryButton: `${base}.${platformKey}.step_2.primary_button`,
            login: `${base}.${platformKey}.step_2.login`,
            password: `${base}.${platformKey}.step_2.password`,
            invalidEmail: `${base}.${platformKey}.step_2.invalid_email`,
            connected: `${base}.${platformKey}.step_2.connected`,
            updateFailed: `${base}.${platformKey}.step_2.update_failed`,
        };
    });

    readonly passwordControl = new FormControl<string>('', Validators.required);
    readonly loginControl = new FormControl<string>('', [Validators.required, Validators.email]);

    readonly isEmailInError$ = this.loginControl.statusChanges.pipe(map(() => this.loginControl.errors?.email ?? false));
    readonly isEmailInError = toSignal<boolean, boolean>(this.isEmailInError$, {
        initialValue: false,
    });

    readonly isUpdatingPlatform = signal<boolean>(false);

    onPrimaryClick(): void {
        const login = this.loginControl.value;
        const password = this.passwordControl.value;
        if (!login || !password) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }
        this.isUpdatingPlatform.set(true);
        const platformKey = this.platformKey();
        this._updatePlatformAccessService.execute(platformKey, { login, password }).subscribe({
            next: () => {
                this.isUpdatingPlatform.set(false);
                this._toastService.openSuccessToast(this._translateService.instant(this.translations().connected));
                this.close.emit({ hasDataChanged: true });
            },
            error: (err) => {
                console.error(err);
                this.isUpdatingPlatform.set(false);
                this._toastService.openErrorToast(this._translateService.instant(this.translations().updateFailed));
            },
        });
    }
}
