import { z } from 'zod';

import { HeapEventName, MaloupeLocale } from '@malou-io/package-utils';

export const createDiagnosticParamsValidator = z
    .object({
        place_id: z.string(),
    })
    .transform((data) => ({
        placeId: data.place_id,
    }));
export type CreateDiagnosticParamsDto = z.infer<typeof createDiagnosticParamsValidator>;

export const createDiagnosticBodyValidator = z.object({
    lang: z.nativeEnum(MaloupeLocale),
});
export type CreateDiagnosticBodyDto = z.infer<typeof createDiagnosticBodyValidator>;

// --------------------------------------------
export const updateDiagnosticParamsValidator = z
    .object({
        diagnostic_id: z.string(),
    })
    .transform((data) => ({
        diagnosticId: data.diagnostic_id,
    }));

export type UpdateDiagnosticParamsDto = z.infer<typeof updateDiagnosticParamsValidator>;

// --------------------------------------------

export const updateDiagnosticWithInstagramBodyValidator = z.object({
    instagramAccountName: z.string(),
});

export type UpdateDiagnosticWithInstagramBodyDto = z.infer<typeof updateDiagnosticWithInstagramBodyValidator>;

// --------------------------------------------

export const getInstagramPageParamsValidator = z.object({
    text: z.string(),
});

export type GetInstagramPageParamsDto = z.infer<typeof getInstagramPageParamsValidator>;

// --------------------------------------------

export const sendDiagnosticEmailBodyValidator = z.object({
    email: z.string(),
    name: z.string(),
    restaurantName: z.string(),
    language: z.nativeEnum(MaloupeLocale),
});

export type SendDiagnosticEmailBodyDto = z.infer<typeof sendDiagnosticEmailBodyValidator>;

// --------------------------------------------

export const diagnosticEmailOpenedQueryValidator = z.object({
    receiverEmail: z.string(),
    malouDiagnosticId: z.string(),
});

export type DiagnosticEmailOpenedQueryDto = z.infer<typeof diagnosticEmailOpenedQueryValidator>;

// --------------------------------------------

export const trackDiagnosticActionBodyValidator = z.object({
    identity: z.string().optional(),
    id: z.string().optional(),
    eventName: z.nativeEnum(HeapEventName),
    receiverEmail: z.string().optional(),
});

export type TrackDiagnosticActionBodyDto = z.infer<typeof trackDiagnosticActionBodyValidator>;

// --------------------------------------------

export const updateLocationCategoryBodyValidator = z.object({
    categoryId: z.string(),
});

export type UpdateLocationCategoryBodyDto = z.infer<typeof updateLocationCategoryBodyValidator>;
