import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-ai-generate-reply',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule],
    templateUrl: './ai-generate-reply.component.html',
    styleUrls: ['./ai-generate-reply.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiGenerateReplyComponent {
    readonly aiPromptButtonTooltip = input<string>('');
    readonly disabled = input<boolean>(true);
    readonly answerReviewWithAi = output<void>();

    readonly SvgIcon = SvgIcon;

    onAnswerReviewWithAiClick(): void {
        this.answerReviewWithAi.emit();
    }
}
