<div class="flex items-center gap-3.5" [matTooltip]="'roi.not_enough_data' | translate" [matTooltipDisabled]="!!timeScaleList().length">
    <span class="malou-text-14 malou-color-text-2">{{ 'roi.filters.scale' | translate }}</span>
    <app-select
        class="w-[305px] sm:w-full"
        [formControl]="control"
        [values]="timeScaleList()"
        [inputReadOnly]="true"
        (selectChange)="selectTimesScaleChange($event)">
        <ng-template let-value="value" #simpleSelectedValueTemplate>
            <ng-container [ngTemplateOutlet]="customOptionTemplate" [ngTemplateOutletContext]="{ value, isTitle: true }"></ng-container>
        </ng-template>
        <ng-template let-value="value" #optionTemplate>
            <ng-container [ngTemplateOutlet]="customOptionTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
        </ng-template>
    </app-select>
</div>

<ng-template let-value="value" let-isTitle="isTitle" #customOptionTemplate>
    <div class="flex items-center gap-[5px]" [ngClass]="isTitle ? 'ml-2.5' : ''">
        <span class="font-medium" [ngClass]="isTitle ? 'malou-text-12' : 'malou-text-14'">
            {{ displayPeriod(value) }}
        </span>
        <span class="malou-color-text-2 font-normal italic" [ngClass]="isTitle ? 'malou-text-10' : 'malou-text-12'">{{
            displayPeriodDetail(value)
        }}</span>
    </div>
</ng-template>
