import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ToastVariant } from ':core/services/toast.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum ToastDuration {
    SHORT = 'short',
    MEDIUM = 'medium',
    LONG = 'long',
}

@Component({
    selector: 'app-toast-item',
    templateUrl: './toast-item.component.html',
    styleUrls: ['./toast-item.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, NgTemplateOutlet],
})
export class ToastItemComponent implements OnInit {
    @Input() type: ToastVariant = ToastVariant.SUCCESS;
    @Input() message = '';
    @Input() duration: ToastDuration = ToastDuration.SHORT;
    @Output() dismiss = new EventEmitter<boolean>();

    readonly SvgIcon = SvgIcon;

    bgClass = '';
    svgIcon = '';

    onClick(): void {
        this.dismiss.emit(true);
    }

    ngOnInit(): void {
        const durationMap = {
            [ToastDuration.SHORT]: 3000,
            [ToastDuration.MEDIUM]: 6000,
            [ToastDuration.LONG]: 9000,
        };
        setTimeout(() => {
            this.dismiss.emit(true);
        }, durationMap[this.duration]);

        this.bgClass = this._getBgClass();
        this.svgIcon = this._getSvgIcon();
    }

    private _getBgClass(): string {
        switch (this.type) {
            case ToastVariant.SUCCESS:
                return 'bg-malou-color-state-success';
            case ToastVariant.WARN:
                return 'bg-malou-color-state-warn';
            case ToastVariant.ERROR:
                return 'bg-malou-color-state-error';
        }
    }

    private _getSvgIcon(): string {
        switch (this.type) {
            case ToastVariant.SUCCESS:
                return 'check';
            case ToastVariant.WARN:
                return 'minus';
            case ToastVariant.ERROR:
                return 'cross';
        }
    }
}
