import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { isNotNil, TimeInMilliseconds } from '@malou-io/package-utils';

import { selectOpenedFooterCount } from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TemplatesService } from ':core/services/templates.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { EditCreateTemplateModalComponent } from ':shared/components/edit-create-template-modal/edit-create-template-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { RestaurantsSelectionModalComponent } from ':shared/components/restaurants-selection-modal/restaurants-selection-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { TemplateType } from ':shared/enums/template-type.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { MediumTemplate } from ':shared/models';
import { MessageTemplatesFilters } from ':shared/models/template-filters';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { TemplateReplacer, TemplateReplacerType, TemplateVariable } from '../template-replacer/template-replacer';
import { MessageTemplateActionsHeaderComponent } from './message-template-actions-header/message-template-actions-header.component';
import * as MessageTemplatesActions from './store/message-templates.actions';
import { selectMessageTemplatesSort } from './store/message-templates.selectors';

enum MessageTemplateColumns {
    SELECT = 'select',
    NAME = 'name',
    TEXT = 'text',
    ACTIONS = 'actions',
}

const AVAILABLE_MESSAGE_TEMPLATE_VARIABLES = [
    TemplateVariable.CLIENT_NAME,
    TemplateVariable.ADDRESS,
    TemplateVariable.MENU_LINK,
    TemplateVariable.PHONE,
    TemplateVariable.REGULAR_HOURS,
    TemplateVariable.WEBSITE,
];

const sortData =
    () =>
    (items: MediumTemplate[], sort: MatSort): MediumTemplate[] => {
        if (!sort.active || sort.direction === '') {
            return items;
        }
        return sortTemplates(items, sort);
    };

const sortTemplates = (templates: MediumTemplate[], sort: Sort): MediumTemplate[] =>
    templates.sort((a: MediumTemplate, b: MediumTemplate) => {
        const comparatorResult = sortByKey(sort.active)(a, b);
        return comparatorResult * (sort.direction === 'asc' ? 1 : -1);
    });

const sortByKey =
    (key: string) =>
    (a: any, b: any): number =>
        a[key]?.localeCompare(b[key]);

@Component({
    selector: 'app-message-templates',
    templateUrl: './message-templates.component.html',
    styleUrls: ['./message-templates.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        MessageTemplateActionsHeaderComponent,
        NoopMatCheckboxComponent,
        ScrollToTopComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        IncludesPipe,
        LazyLoadImageModule,
        ApplySelfPurePipe,
        ApplyPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [PluralTranslatePipe],
})
@AutoUnsubscribeOnDestroy()
export class MessageTemplatesComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly filters$: BehaviorSubject<MessageTemplatesFilters> = new BehaviorSubject({});

    readonly DuplicationDestination = DuplicationDestination;
    readonly MessageTemplateColumns = MessageTemplateColumns;
    readonly Illustration = Illustration;

    readonly displayedColumns: MessageTemplateColumns[] = Object.values(MessageTemplateColumns);
    readonly SCROLL_CONTAINER_SELECTOR = '.scrollable';
    restaurantId: string;

    selection = new SelectionModel<MediumTemplate>(true, []);
    dataSource: MatTableDataSource<MediumTemplate> = new MatTableDataSource<MediumTemplate>();

    lastSelectedSegmentRow = 0;
    sort: Sort;
    templates: MediumTemplate[];

    hasFetchedTemplates = false;
    hasSearch = false;
    footersVisibilityCount = 0;
    duplicatedTemplateIds: string[] = [];

    private readonly _TEMPLATE_REPLACER = new TemplateReplacer(this._translate, AVAILABLE_MESSAGE_TEMPLATE_VARIABLES);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinner: SpinnerService,
        private readonly _templatesService: TemplatesService,
        private readonly _translate: TranslateService,
        private readonly _dialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _store: Store,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
        this.dataSource.sortData = sortData();
    }

    ngOnInit(): void {
        this.dataSource.filterPredicate = this.customFilterPredicate;

        this._loadTemplates();

        this._store
            .select(selectOpenedFooterCount)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((count) => {
                this.footersVisibilityCount = count;
            });

        this.filters$.pipe(takeUntil(this.killSubscriptions$)).subscribe((filters) => {
            this.hasSearch = !!filters.search && filters.search.length > 0;
            this.dataSource.filter = JSON.stringify({ ...filters });
            this._setTemplateCount(this.dataSource.filteredData.length);
        });
    }

    customFilterPredicate = (inputData: MediumTemplate, inputFilter: string): boolean => {
        const filters: MessageTemplatesFilters = JSON.parse(inputFilter);
        const isSearchMatch = this.isSearchMatch(inputData, filters.search ?? '');
        return isSearchMatch;
    };

    isSearchMatch(inputData: MediumTemplate, search: string): boolean {
        const name = inputData.name?.toLowerCase();
        const text = this.replaceVariableTextToChipText(inputData.text)?.toLowerCase();
        return [name, text].some((str) => str?.includes(search?.toLowerCase()));
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    isAllFilteredSelected(): boolean {
        return this.dataSource.filteredData.every((template) => this.selection.isSelected(template));
    }

    toggleAllFiltered(): void {
        if (this.isAllFilteredSelected()) {
            this.selection.deselect(...this.dataSource.filteredData);
            return;
        }
        this.selection.select(...this.dataSource.filteredData);
        this.lastSelectedSegmentRow = 0;
    }

    deleteTemplatesByIds(templateIds: string[]): void {
        if (!templateIds.length) {
            return;
        }
        this._dialogService.open({
            variant: DialogVariant.ALERT,
            title: this._pluralTranslatePipe.transform('templates.delete_prompt', templateIds.length),
            primaryButton: {
                label: this._translate.instant('common.delete'),
                action: () => {
                    this._spinner.show();
                    this._deleteTemplatesById(templateIds);
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
                action: () => {
                    this._spinner.hide();
                },
            },
        });
    }

    duplicateTemplates(to: string, singleTemplate: MediumTemplate | null = null): void {
        const templatesToDuplicate = singleTemplate ? [singleTemplate] : this.selection.selected;
        if (to === DuplicationDestination.HERE) {
            return this._duplicateTemplates(templatesToDuplicate, [this.restaurantId]);
        }
        this._customDialogService
            .open(RestaurantsSelectionModalComponent, {
                width: '600px',
                data: {
                    itemsLength: templatesToDuplicate.length,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.ids) {
                        this._duplicateTemplates(templatesToDuplicate, result.ids);
                    } else {
                        this.selection.clear();
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.selection.clear();
                },
            });
    }

    openEditTemplateModal(template: MediumTemplate | null = null): void {
        this._customDialogService
            .open(EditCreateTemplateModalComponent, {
                panelClass: 'malou-dialog-panel--animated',
                width: '750px',
                height: 'unset',
                data: {
                    template,
                    type: TemplateType.MESSAGE,
                },
            })
            .afterClosed()
            .subscribe({
                next: (done: boolean) => {
                    if (done) {
                        this._loadTemplates();
                    }
                },
            });
    }

    selectMultiple(event: MouseEvent, lastRow: number, mediumTemplate: MediumTemplate): void {
        if (event.shiftKey) {
            const sortedTemplates = sortTemplates(this.dataSource.data, this.sort);
            const templatesToSelect = sortedTemplates.filter(
                (template, i) => template.type === TemplateType.MESSAGE && i >= this.lastSelectedSegmentRow && i <= lastRow
            );

            this.selection.select(...templatesToSelect);
        } else {
            this.selection.toggle(mediumTemplate);
        }
        this.lastSelectedSegmentRow = lastRow;
    }

    replaceVariableTextToChipText = (text: string): string =>
        this._TEMPLATE_REPLACER.replaceText(text, {
            from: TemplateReplacerType.VARIABLE_TEXT,
            to: TemplateReplacerType.CHIP_TEXT,
        });

    onSearchChange(search: string): void {
        this.filters$.next({ ...this.filters$.value, search });
    }

    onDuplicateSelected(duplicationDestination: string): void {
        this.duplicateTemplates(duplicationDestination);
    }

    onDeleteSelected(): void {
        const templateIds = this.selection.selected.map((t) => t._id);
        this.deleteTemplatesByIds(templateIds);
    }

    onClickOnInputFile(): void {
        this.openEditTemplateModal();
    }

    private _duplicateTemplates(selectedTemplates: MediumTemplate[], restaurantIds: string[]): void {
        const mappedSelectedTemplates = selectedTemplates.map((template) => ({
            category: template.category,
            name: this._translate.instant('templates.copy_prefix', { name: template.name }),
            language: template.language,
            withComment: template.withComment,
            text: template.text,
            type: template.type,
        }));
        this._spinner.show();
        this._templatesService.duplicateTemplatesForRestaurants(this.restaurantId, mappedSelectedTemplates, restaurantIds).subscribe({
            next: (templates) => {
                this._spinner.hide();
                this._openDuplicateSuccessToast(mappedSelectedTemplates.length);

                if (restaurantIds.includes(this.restaurantId) && templates?.length) {
                    this._addTemplatesToDataSource(templates, { shouldSort: this.screenSizeService.isPhoneScreen });
                    this.duplicatedTemplateIds = templates.map((t) => t._id);
                    setTimeout(() => this._scrollToTemplate(this.duplicatedTemplateIds[0]));
                    setTimeout(() => {
                        this.duplicatedTemplateIds = [];
                    }, 10 * TimeInMilliseconds.SECOND);
                }
                this.selection.clear();
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._spinner.hide();
                if (err.status === 403) {
                    return;
                }
                this._openErrorModal(this._httpErrorPipe.transform(err));
                this.selection.clear();
            },
        });
    }

    private _scrollToTemplate(templateId: string): void {
        const duplicatedTemplatesElement = document.getElementById(templateId);
        if (duplicatedTemplatesElement) {
            duplicatedTemplatesElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    private _loadTemplates(): void {
        combineLatest([
            this._store.select(selectMessageTemplatesSort),
            this._restaurantsService.restaurantSelected$.pipe(
                filter(isNotNil),
                switchMap((rest) => {
                    this.restaurantId = rest._id;
                    return this._templatesService.getTemplatesByRestaurantId(rest._id, TemplateType.MESSAGE);
                })
            ),
        ])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([{ sortBy, sortOrder }, templates]) => {
                this.sort = {
                    active: sortBy,
                    direction: sortOrder === 'asc' ? 'asc' : 'desc',
                };

                this.templates = templates;
                this.hasFetchedTemplates = true;
                this.dataSource.data = [];
                this._addTemplatesToDataSource(templates, { shouldSort: this.screenSizeService.isPhoneScreen });
            });
    }

    private _setTemplateCount(count: number): void {
        this._store.dispatch(MessageTemplatesActions.setTemplateCount({ count }));
    }

    private _deleteTemplatesById(templateIds: string[]): void {
        this._templatesService
            .deleteManyTemplates(templateIds)
            .pipe(switchMap(() => this._templatesService.getTemplatesByRestaurantId(this.restaurantId, TemplateType.MESSAGE)))
            .subscribe({
                next: () => {
                    this.selection.clear();
                    this._spinner.hide();
                    this._removeTemplatesFromDataSource(templateIds);
                    this._openDeleteSuccessToast(templateIds.length);
                },
                error: (err) => {
                    this.selection.clear();
                    this._spinner.hide();
                    if (err.status === 403) {
                        return;
                    }
                    this._openErrorModal(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _addTemplatesToDataSource(templates: MediumTemplate[] = [], options: { shouldSort?: boolean } = {}): void {
        this.dataSource.data.push(...templates);
        if (options.shouldSort && this.sort) {
            const sortedTemplates = sortTemplates(this.dataSource.data, this.sort);
            this.dataSource.data = sortedTemplates;
        }
        this.dataSource._updateChangeSubscription();
        this._setTemplateCount(this.dataSource.filteredData.length);
    }

    private _removeTemplatesFromDataSource(templateIds: string[]): void {
        templateIds.forEach((templateId) => {
            const index = this.dataSource.data.findIndex((template) => template._id === templateId);
            this.dataSource.data.splice(index, 1);
        });
        this.dataSource._updateChangeSubscription();
        this._setTemplateCount(this.dataSource.filteredData.length);
    }

    private _openErrorModal(message: string): void {
        this._dialogService.open({
            variant: DialogVariant.ERROR,
            title: this._translate.instant('common.error'),
            message,
            primaryButton: {
                label: this._translate.instant('common.close'),
            },
        });
    }

    private _openDuplicateSuccessToast(duplicatedTemplateCount: number): void {
        if (duplicatedTemplateCount === 0) {
            return;
        }
        this._toastService.openSuccessToast(this._pluralTranslatePipe.transform('templates.duplicate_success', duplicatedTemplateCount));
    }

    private _openDeleteSuccessToast(deletedTemplateCount: number): void {
        if (deletedTemplateCount === 0) {
            return;
        }
        this._toastService.openSuccessToast(this._pluralTranslatePipe.transform('templates.delete_success', deletedTemplateCount));
    }
}
