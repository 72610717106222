import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { capitalize } from 'lodash';

import { AggregatedTipsModalComponent } from ':modules/aggregated-statistics/roi/aggregated-tips-modal/aggregated-tips-modal.component';
import { AggregatedDefaultRoiData } from ':modules/aggregated-statistics/roi/roi.interface';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-custom-roi-chart-label',
    standalone: true,
    imports: [TranslateModule, MatIconModule, MatTooltipModule],
    templateUrl: './custom-roi-chart-label.component.html',
    styleUrl: './custom-roi-chart-label.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRoiChartLabelComponent {
    readonly aggregatedData = input.required<AggregatedDefaultRoiData>();
    readonly shouldShowButtonInLegend = input<boolean>(false);

    readonly restaurantsNames: Signal<string[]> = computed(() =>
        this.aggregatedData().map((data) => capitalize(data.restaurant.internalName ?? data.restaurant.name ?? ''))
    );

    private readonly _customDialogService = inject(CustomDialogService);

    readonly SvgIcon = SvgIcon;
    openRestaurantTips(restaurant: Restaurant): void {
        this._customDialogService
            .open<AggregatedTipsModalComponent>(AggregatedTipsModalComponent, {
                width: '700px',
                height: '440px',
                data: {
                    restaurant,
                },
            })
            .afterClosed()
            .subscribe();
    }
}
