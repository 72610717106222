<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>⛔️</div>
        <app-platform-logo [imgClasses]="'w-10'" [logo]="notification().data.platform.key"></app-platform-logo>
        <div class="flex flex-col">
            <div
                class="malou-text-11--semibold max-w-[80%] text-malou-color-text-1"
                [innerHTML]="
                    'notification-center.platform-disconnected.title'
                        | translate
                            : {
                                  platformName: notification().data.platform.key | platformName,
                                  restaurantName: restaurant()?.name ?? notification().data.mainRestaurantName,
                              }
                "></div>
            <div
                class="malou-text-11 text-malou-color-text-2"
                [innerHTML]="'notification-center.platform-disconnected.description' | translate"></div>
        </div>

        <div class="ml-auto">
            <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.reconnect' | translate }}</button>
        </div>
    </div>
</div>
