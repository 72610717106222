<app-select-restaurants
    [testId]="'reviews-restaurants-input'"
    [maxSelectableValues]="MAX_SELECTABLE_RESTAURANTS"
    [values]="(selectableRestaurants$ | async) ?? []"
    [selectedValues]="(storedRestaurants$ | async) ?? []"
    [multiSelectionElementWrap]="false"
    [shouldSwitchToWrapModeOnClick]="false"
    [defaultEmptyValueMessage]="'app_select_restaurants.no_business_found' | translate"
    [shouldUpdateValuesToDisplayAfterSelection]="false"
    [computeObjectHash]="compareByRestaurantId"
    (selectRestaurantsChange)="onRestaurantsChange($event)">
</app-select-restaurants>
