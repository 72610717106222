import { AggregatedUserFiltersResponseDto, UserRestaurantFiltersResponseDto } from '@malou-io/package-dto';
import {
    AggregatedReviewsFilters,
    AggregatedStatisticsFilters,
    MalouPeriod,
    ReviewsPerRestaurantFilters,
    StatisticsPerRestaurantFilters,
} from '@malou-io/package-utils';

export class AggregatedUserFilters {
    id: string;
    aggregatedStatisticsFilters: AggregatedStatisticsFilters;
    aggregatedReviewsFilters: AggregatedReviewsFilters;

    constructor(init: AggregatedUserFiltersResponseDto) {
        this.id = init.id;
        this.aggregatedStatisticsFilters = {
            dates: {
                startDate: dateStringToDate(init.aggregatedStatisticsFilters.dates.startDate),
                endDate: dateStringToDate(init.aggregatedStatisticsFilters.dates.endDate),
                period: init.aggregatedStatisticsFilters.dates.period,
            },
            platforms: init.aggregatedStatisticsFilters.platforms,
            restaurantIds: init.aggregatedStatisticsFilters.restaurantIds,
            roiRestaurantIds: init.aggregatedStatisticsFilters.roiRestaurantIds,
            totemIds: init.aggregatedStatisticsFilters.totemIds,
            timeScale: init.aggregatedStatisticsFilters.timeScale,
        };
        const aggregatedReviewsFiltersPeriod = init.aggregatedReviewsFilters.filters.period;
        this.aggregatedReviewsFilters = {
            ...init.aggregatedReviewsFilters,
            filters: {
                ...init.aggregatedReviewsFilters.filters,
                period:
                    aggregatedReviewsFiltersPeriod === MalouPeriod.ALL
                        ? MalouPeriod.LAST_AND_COMING_THIRTY_DAYS
                        : aggregatedReviewsFiltersPeriod,
            },
        };
    }
}

export class UserRestaurantFilters {
    id: string;
    restaurantId: string;
    statisticsFilters: StatisticsPerRestaurantFilters['filters'];
    reviewsFilters: ReviewsPerRestaurantFilters['filters'];

    constructor(init: UserRestaurantFiltersResponseDto) {
        this.id = init.id;
        this.restaurantId = init.restaurantId;
        this.statisticsFilters = {
            ...init.statisticsFilters,
            dates: {
                startDate: dateStringToDate(init.statisticsFilters.dates.startDate),
                endDate: dateStringToDate(init.statisticsFilters.dates.endDate),
                period: init.statisticsFilters.dates.period,
            },
        };
        this.reviewsFilters = init.reviewsFilters;
    }
}

function dateStringToDate(date: string | null): Date | null {
    return date ? new Date(date) : null;
}
