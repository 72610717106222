import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ValueWithDifference } from ':shared/interfaces/value-with-difference.interface';
import { DISPLAYED_NULLISH_VALUE } from ':shared/models';

@Component({
    selector: 'app-default-comparison',
    templateUrl: './default-comparison.component.html',
    styleUrls: ['./default-comparison.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class DefaultComparisonComponent implements OnInit {
    @Input() value: Partial<any>;
    @Input() referenceValue: any;

    valueWithDifference: ValueWithDifference<string>;

    ngOnInit(): void {
        this.valueWithDifference = {
            value: (this.value ?? DISPLAYED_NULLISH_VALUE) as unknown as string,
            hasDifference: !!this.referenceValue && this.value !== this.referenceValue,
        };
    }
}
