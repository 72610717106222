import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-foursquare-credentials',
    templateUrl: './foursquare-credentials.component.html',
    styleUrls: ['./foursquare-credentials.component.scss'],
    standalone: true,
    imports: [MatButtonModule],
})
export class FoursquareCredentialsComponent implements OnInit {
    @Input() credential: any;
    url: string;

    constructor() {} //   private readonly _foursquareServe: FoursquareService

    ngOnInit(): void {
        // this._foursquareServe.oauth2().subscribe(res => {
        //   this.url = res.data;
        // });
    }
}
