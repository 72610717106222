import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Uppy from '@uppy/core';
import { filter, interval, map, Subject, switchMap, takeUntil } from 'rxjs';

import { getTypeFromExtension, isNotNil, MediaConvertedStatus, MediaType } from '@malou-io/package-utils';

import { MediaService } from ':modules/media/media.service';
import { Media, UppyTemporaryMedia } from ':shared/models/media';

import { ErrorFileCategory } from '../../enums/error-file-category.enum';
import { bytesConverter, BytesUnit } from '../../helpers/bytes-converter';
import { FileError, UppyComponent, UppyFileWithError } from '../uppy/uppy.component';

@Component({
    selector: 'app-media-file-uploader',
    standalone: true,
    imports: [TranslateModule, MatIconModule, MatMenuModule, MatButtonModule, UppyComponent],
    templateUrl: './media-file-uploader.component.html',
    styleUrls: ['./media-file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFileUploaderComponent implements OnDestroy {
    @Input() accept = 'image/png, image/jpeg, image/heif, image/heic, video/quicktime, video/mp4';
    @Input() multiple = true;
    @Input() maxVideoSize = 0;
    @Input() maxImageSize: number;
    @Input() disabled = false;
    @Input() maxNumberOfFiles = 50;

    @Output() fileProcessed: EventEmitter<Media[]> = new EventEmitter<Media[]>();
    @Output() finishUpload: EventEmitter<Media[]> = new EventEmitter<Media[]>();
    @Output() startReadingFile: EventEmitter<number> = new EventEmitter<number>();
    @Output() processError: EventEmitter<Error | string> = new EventEmitter<Error>();
    @Output() dragEnter: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragLeave: EventEmitter<void> = new EventEmitter<void>();
    @Output() progressChanged: EventEmitter<number> = new EventEmitter<number>();

    currentUppyInstance: Uppy;
    isDragging = false;
    createdMedia: Media[] | null = null;
    killStatus$: Subject<boolean> = new Subject<boolean>();

    private _fileInput = viewChild.required<ElementRef>('fileInput');

    constructor(
        private readonly _mediaService: MediaService,
        private readonly _translate: TranslateService
    ) {}

    public openFilePicker(): void {
        this._fileInput().nativeElement.click();
    }

    ngOnDestroy(): void {
        this.killStatus$.next(true);
    }

    onFinishUpload(results: { successful: UppyTemporaryMedia[]; unsuccessful: UppyFileWithError[] }): void {
        this._mediaService
            .createManyMedias(results.successful)
            .pipe(
                map((res) => {
                    const createdMedia = res.data;
                    this.createdMedia = createdMedia;
                    this.finishUpload.emit(createdMedia);
                    return createdMedia;
                }),
                switchMap((createdMedia: Media[]) => this._mediaService.fetchMediaDescription(createdMedia?.map((media) => media.id)))
            )
            .subscribe({
                error: (err) => {
                    this.killStatus$.next(true);
                    this.processError.emit(err);
                },
            });
    }

    startWatcher({ uppyInstance }: { uppyInstance: Uppy }): void {
        this.currentUppyInstance = uppyInstance;
        interval(1000)
            .pipe(
                map(() => this.createdMedia),
                filter(isNotNil),
                switchMap((createdMedia) => this._mediaService.getManyMedia(createdMedia.map((m) => m.id))),
                takeUntil(this.killStatus$)
            )
            .subscribe({
                next: (res) => {
                    if (!this._isStillProcessingMediaConversion(res.data)) {
                        this.createdMedia = null;
                        this.killStatus$.next(true);
                        this.fileProcessed.emit(res.data);
                    }
                },
                error: (err) => {
                    this.killStatus$.next(true);
                    console.warn('err :>>', err);
                    this.processError.emit(err);
                },
                complete: () => {
                    this.currentUppyInstance.cancelAll();
                },
            });
    }

    updateDraggingState(isDragging: boolean): void {
        this.isDragging = isDragging;
        if (isDragging) {
            this.dragEnter.emit();
            return;
        }
        this.dragLeave.emit();
    }

    startUploadProcess(numberOfFiles: number): void {
        this.startReadingFile.emit(numberOfFiles);
    }

    buildErrorsArray(errors: FileError[]): void {
        this._processError(errors[0]);
    }

    updateProgress(event: number): void {
        this.progressChanged.emit(event);
    }

    private _isStillProcessingMediaConversion(media: Media[]): boolean {
        if (!media.length) {
            return false;
        }
        return media.some((m) => m.convertedStatus === MediaConvertedStatus.PROGRESSING);
    }

    private _processError(error: Error | FileError): void {
        console.error('Error during uploading image', error);
        if (error instanceof Error) {
            this.processError.emit(error);
            return;
        }
        const errorMessage = this._getErrorMessage(error);
        this.processError.emit(errorMessage);
    }

    private _getErrorMessage(error: FileError): string {
        const { file, errorCategory } = error;
        const fileName = file.name;
        switch (errorCategory) {
            case ErrorFileCategory.HEVC:
                return this._translate.instant('gallery.error_file_hevc', { fileName });

            case ErrorFileCategory.TOO_BIG:
                const extension = fileName.split('.').pop();
                const fileType = getTypeFromExtension(extension);
                const maxFileSize = fileType === MediaType.VIDEO ? this.maxVideoSize : this.maxImageSize;
                return this._translate.instant('gallery.file_size_too_big', {
                    fileName,
                    fileSize: bytesConverter(file.size, BytesUnit.MO),
                    maxSize: bytesConverter(maxFileSize, BytesUnit.MO),
                });
            default:
                return this._translate.instant('gallery.unknown_error_upload', { fileName });
        }
    }
}
