<div class="flex h-full">
    <div class="md:hidden">
        <app-platforms-connection-stepper
            [steps]="stepNames"
            [currentStep]="currentStepIndex()"
            [platformKeyIcon]="initialModalData.stepperPlatformKeyIcon"
            [title]="initialModalData.stepperTitle"
            [subtitle]="initialModalData.stepperSubtitle"
            (goToStep)="onGoToStep($event)"></app-platforms-connection-stepper>
    </div>
    <div class="min-w-0 grow">
        <ng-template #dynamicTemplate></ng-template>
    </div>
</div>
