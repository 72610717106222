import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash';
import { Observable, take } from 'rxjs';

import { KeywordStatsV3Dto } from '@malou-io/package-dto';

import { selectKeywords } from ':modules/statistics/store/statistics.selectors';
import { Keyword, KeywordVolumeHistory } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    stats: KeywordStatsV3Dto[];
    keywords: Keyword[];
}

@Injectable({ providedIn: 'root' })
export class KeywordsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(selectKeywords).pipe(take(1));
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        const competitorHeaders = Array(20)
            .fill(0)
            .map((_, i) => [`Competitor ${i + 1}`, `Competitor ${i + 1} Address`])
            .flat();
        return ['Date', 'Keyword', 'Volume (average local searches)', 'Google Ranking', ...competitorHeaders];
    }

    protected override _getCsvDataRows({ keywords, stats }: Data): CsvAsStringArrays {
        return keywords
            .filter((keyword) => keyword.selected)
            .flatMap((keyword) => {
                const keywordStats = stats.find((ks) => ks.keywordId === keyword.keywordId);
                if (!keywordStats) {
                    return [];
                }

                // FIXME: not great (we should display the competitor list evolution) but let’s improve this later
                const competitors: string[] = [...Array(20)].flatMap((_, i) => {
                    const competitor = keywordStats.localCompetitorsPodium[i];
                    if (!competitor) {
                        return ['', ''];
                    }
                    return [competitor.name, competitor.address];
                });

                return sortBy(keywordStats.rankHistory, (p) => p.fetchDate).map((point): string[] => {
                    const date = new Date(point.fetchDate).toLocaleDateString();
                    const volume =
                        this._getClosestVolumeHistoryFromDate(keyword.volumeHistory, new Date(point.fetchDate))?.toString() ?? '';
                    const rank = (point.rank ?? point.outOf).toString();
                    return [date, keyword.text, volume, rank, ...competitors];
                });
            });
    }

    private _getClosestVolumeHistoryFromDate(volumeHistory: KeywordVolumeHistory[], date: Date): number {
        const volumeHistoryWithDateDifference = volumeHistory.map((el) => ({
            value: el.volume,
            dateDifference: Math.abs(date.getTime() - new Date(el.fetchDate).getTime()),
        }));
        return sortBy(volumeHistoryWithDateDifference, (e) => e.dateDifference)[0]?.value;
    }
}
