<div class="flex w-full flex-col">
    @if (title() || (showMaxLength() && showCounterInTitle())) {
        <div class="flex justify-between">
            <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1" id="textAreaTitle">
                <span>{{ title() }}</span>
                @if (required()) {
                    <span>*</span>
                }
                @if (showMaxLength() && showCounterInTitle()) {
                    <span>{{ control.value?.length || 0 }}/{{ maxLength() }}</span>
                }
            </div>
            <ng-content select="[afterTitleJustifyEndContent]"></ng-content>
            @if (showAsPanel()) {
                <mat-icon
                    class="chevron-icon mx-[21px] !h-[16px] !w-[16px] transform cursor-pointer justify-end transition-all"
                    color="primary"
                    [class.rotate-180]="isTextAreaOpened()"
                    [svgIcon]="SvgIcon.CHEVRON_DOWN"
                    (click)="openTextArea()"></mat-icon>
            }
        </div>
    }
    <div
        class="flex flex-col rounded-lg bg-white"
        [class.hidden]="showAsPanel() && !isTextAreaOpened()"
        [class.malou-border-primary]="!errorMessage() && !isFocused()"
        [class.malou-border-secondary]="!errorMessage() && isFocused()"
        [class.malou-border-error]="!!errorMessage()">
        <div class="relative flex w-full gap-x-1">
            @if (isLoadingAnimationEnabled()) {
                <div class="absolute left-3 top-5 z-10">
                    @if (loadingAnimationTemplate(); as loadingAnimationTemplate) {
                        <ng-container [ngTemplateOutlet]="loadingAnimationTemplate"></ng-container>
                    }
                </div>
            }

            @if (shouldDisplayAiInteractionHeadBand()) {
                <div class="x-4 absolute z-10 flex w-full justify-between rounded-t-lg bg-malou-color-background-dark py-1">
                    <div class="ml-4 flex items-center">
                        <mat-icon class="mr-2 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                        <span class="malou-text-10--semibold text-malou-color-chart-purple--accent">
                            {{ 'reviews.reply_modal.ai_suggestion' | translate }}
                        </span>
                    </div>
                    <div class="mr-4 flex items-center">
                        <mat-icon
                            class="h-4 !w-4 cursor-pointer text-malou-color-chart-purple--accent"
                            id="tracking_remove_ai_suggestions"
                            [svgIcon]="SvgIcon.TRASH"
                            (click)="emitHideAiInteractionReply()"></mat-icon>
                    </div>
                </div>
            }

            <div class="w-full">
                <div class="flex h-full items-center">
                    <ng-container [ngTemplateOutlet]="textArea"></ng-container>
                    @if (shouldDisplayAiButton() && control.value.length === 0) {
                        <ng-content select="[afterTextAreaAiButtonTemplate]"></ng-content>
                    }
                </div>
            </div>

            @if (!shouldDisplayAiInteractionHeadBand()) {
                <div class="my-3 flex flex-col items-end justify-between rounded px-1.5">
                    @if (isEmojiPickerEnabled()) {
                        <div class="sm:hidden">
                            <app-emoji-picker (emitEmoji)="emojiSelected($event)"> </app-emoji-picker>
                        </div>
                    }
                    @if (showMaxLength()) {
                        <div class="malou-color-text-2 malou-text-12--regular w-13 flex justify-end whitespace-nowrap italic">
                            <div [class.malou-color-state-error]="!!errorMessage()?.includes(POST_CAPTION_TEXT_LIMIT.toString())">
                                {{ control.value?.length || 0 }}/{{ maxLength() }}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>

        <ng-content select="[footerTemplate]"></ng-content>
    </div>
</div>
@if (errorMessage(); as errorMessage) {
    <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
}

<ng-template #textArea>
    <textarea
        class="malou-text-12--medium m-0 box-border h-auto w-full resize-y rounded-lg border-0 bg-white p-3 leading-6 outline-none placeholder:truncate"
        type="text"
        appTextareaAutosize
        [class.!w-[165px]]="shouldDisplayAiButton() && control.value?.length === 0"
        [class.!pr-0]="shouldDisplayAiButton() && control.value?.length === 0"
        [activateResize]="autoResizeOnContentOverflow()"
        [id]="textAreaId()"
        [class.opacity-50]="control.disabled"
        [class.italic]="isEmptyValue()"
        [class.malou-text-12]="isEmptyValue()"
        [class.pt-[40px]]="shouldDisplayAiInteractionHeadBand()"
        [mentionConfig]="mentionConfiguration()"
        [placeholder]="computedPlaceholder()"
        [formControl]="control"
        [maxlength]="maxLength()"
        [rows]="textAreaRowsCount()"
        [class.!resize-none]="!resizable()"
        [attr.data-testid]="testId()"
        (keyup.enter)="pressEnterKey()"
        (input)="onInput($any($event))"
        (mouseup)="onMouseUp()"
        (focus)="isFocused.set(true); onFocus.emit($event)"
        (blur)="isFocused.set(false)"></textarea>
</ng-template>
