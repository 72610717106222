import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { RoiPerformanceScoreRow } from ':shared/models/roi-performance-score.model';
import { RoiPerformanceScorePathResolver } from ':shared/pipes/roi-performance-score-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-performance-score-item',
    standalone: true,
    imports: [RoiPerformanceScorePathResolver, ShortNumberPipe],
    templateUrl: './performance-score-item.component.html',
    styleUrl: './performance-score-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceScoreItemComponent {
    readonly item = input.required<RoiPerformanceScoreRow>();
}
