<div
    class="malou-text-12--medium rounded-md p-2"
    [ngClass]="{
        'bg-malou-color-background-purple-soft text-malou-color-chart-purple-deep': popularity() === KeywordPopularity.HIGH,
        'bg-malou-color-background-pending text-malou-color-text-purple--light': popularity() === KeywordPopularity.MEDIUM,
        'bg-malou-color-background-lavender-light text-malou-color-chart-purple--accent': popularity() === KeywordPopularity.LOW,
        'bg-malou-color-background-warning text-malou-color-state-warn': popularity() === KeywordPopularity.PENDING,
    }"
    [matTooltip]="'keywords.keyword_popularity.tooltip' | translate">
    {{ popularity() | enumTranslate: 'keywords_popularity' }}
</div>
