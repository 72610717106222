<div class="malou-dialog">
    <div class="malou-dialog__header">
        <p>
            {{ 'restaurants_list.edit_internal_name.edit_name' | translate }}
        </p>

        <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <div class="malou-text-11--regular my-4 flex">
            {{ 'restaurants_list.edit_internal_name.edit_name_description' | translate }}
        </div>

        <form class="flex flex-col gap-4" [formGroup]="internalNameForm">
            <app-input-text
                formControlName="internalNameControl"
                [placeholder]="'Choisissez un nom interne pour votre établissement'"
                [customActionBtn]="'Réinitialiser'"
                [errorMessage]="
                    internalNameForm.get('internalNameControl')?.errors?.duplicate
                        ? ('restaurants_list.edit_internal_name.duplicate_name' | translate)
                        : ''
                "
                (customAction)="resetInternalName()">
            </app-input-text>
        </form>
    </div>

    <div class="malou-dialog__footer flex-wrap">
        <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="cancel()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11" mat-raised-button [disabled]="!internalNameForm.valid" (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
