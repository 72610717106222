import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { NotificationType } from '@malou-io/package-utils';

import { CommentNotificationItemComponent } from ':core/components/notification-center/components/notification-item/comment-notification-item/comment-notification-item.component';
import { NegativeReviewReminderNotificationItemComponent } from ':core/components/notification-center/components/notification-item/negative-review-reminder-notification-item/negative-review-reminder-notification-item.component';
import { PostErrorNotificationItemComponent } from ':core/components/notification-center/components/notification-item/post-error-notification-item/post-error-notification-item.component';
import { PostSuggestionNotificationItemComponent } from ':core/components/notification-center/components/notification-item/post-suggestion-notification-item/post-suggestion-notification-item.component';
import { ReviewNotificationItemComponent } from ':core/components/notification-center/components/notification-item/review-notification-item/review-notification-item.component';
import { SpecialHourNotificationComponent } from ':core/components/notification-center/components/notification-item/special-hour-notification-item/special-hour-notification-item.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { Notification } from ':core/components/notification-center/models/notification.model';

import { MentionNotificationItemComponent } from '../mention-notification-item/mention-notification-item.component';
import { MessageNotificationItemComponent } from '../message-notification-item/message-notification-item.component';
import { PlatformDisconnectedNotificationItemComponent } from '../platform-disconnected-notification-item/platform-disconnected-notification-item.component';
import { RoiActivatedNotificationItemComponent } from '../roi-activated-notification-item/roi-activated-notification-item.component';

@Component({
    selector: 'app-notification-toast',
    templateUrl: './notification-toast.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NegativeReviewReminderNotificationItemComponent,
        ReviewNotificationItemComponent,
        SpecialHourNotificationComponent,
        MatIconModule,
        PostSuggestionNotificationItemComponent,
        PostErrorNotificationItemComponent,
        CommentNotificationItemComponent,
        MentionNotificationItemComponent,
        MessageNotificationItemComponent,
        RoiActivatedNotificationItemComponent,
        PlatformDisconnectedNotificationItemComponent,
    ],
})
export class NotificationToastComponent {
    readonly notificationCenterContext = inject(NotificationCenterContext);
    readonly notification: Notification = inject(MAT_SNACK_BAR_DATA);
    readonly NotificationType = NotificationType;

    close(): void {
        this.notificationCenterContext.closeNotificationToast();
    }
}
