import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ExperimentationService } from ':core/services/experimentation.service';
import { NoSocialPostComponent } from ':modules/posts-v2/social-posts/components/no-social-post/no-social-post.component';
import { SocialPostsListV2Component } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list.component';
import { UpsertSocialPostModalComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.component';
import {
    UpsertSocialPostModalProps,
    UpsertSocialPostModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { SocialPostsListComponent } from './social-posts-list/social-posts-list.component';

@Component({
    selector: 'app-social-posts-root',
    templateUrl: './social-posts-root.component.html',
    styleUrls: ['./social-posts-root.component.scss'],
    standalone: true,
    imports: [NoSocialPostComponent, SocialPostsListComponent, SocialPostsListV2Component],
})
export class SocialPostsRootComponent implements OnInit {
    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _experimentationService = inject(ExperimentationService);
    readonly isNewSocialPostsAndMediasEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-social-posts-and-medias'),
        { initialValue: false }
    );

    readonly restaurantHasNoPost = this._socialPostsContext.restaurantHasNoPost;

    ngOnInit(): void {
        this._socialPostsContext.init();
    }

    onCreatePost(): void {
        this._customDialogService.open<UpsertSocialPostModalComponent, UpsertSocialPostModalProps, UpsertSocialPostModalResult>(
            UpsertSocialPostModalComponent,
            {
                width: '100vw',
                disableClose: false,
                height: '100vh',
                data: {},
            }
        );
    }

    onCreateReelOrTikTok(): void {
        // TODO
        console.log('Create reel or tiktok not implemented yet');
    }
}
