// MUST MATCH THE FOLDER /assets/svg-icons

export enum SvgIcon {
    ADD = 'add',
    AGGREGATED_STATS = 'aggregated-stats',
    ALARM = 'alarm',
    ARROW_DOWN = 'arrow-down',
    ARROW_NEXT = 'arrow-next',
    ARROW_PREVIOUS = 'arrow-previous',
    ARROW_RIGHT = 'arrow-right',
    ARROW_UP = 'arrow-up',
    ATTACHMENT = 'attachment',
    AUTOMATION = 'automation',
    BAR = 'bar',
    BLOCK = 'block',
    BOOKMARK = 'bookmark',
    BOOKMARK_FILLED = 'bookmark-filled',
    BOX = 'box',
    BURGER = 'burger',
    CALENDAR = 'calendar',
    CALENDAR_2 = 'calendar-2',
    CAMERA = 'camera',
    CARD = 'card',
    CHECK = 'check',
    CHEVRON_DOWN = 'chevron-down',
    CHEVRON_LEFT = 'chevron-left',
    CHEVRON_RIGHT = 'chevron-right',
    CHEVRON_UP = 'chevron-up',
    CHEVRON_UP_AND_DOWN = 'chevron-up-and-down',
    CLOCK = 'clock',
    CONVERSATION = 'conversation',
    COPY = 'copy',
    CROP = 'crop',
    CROSS = 'cross',
    CROWN = 'crown',
    CURSOR = 'cursor',
    DIMENSION = 'dimension',
    DOT = 'dot',
    DOWNLOAD = 'download',
    DRAFT = 'draft',
    DRAGGABLE = 'draggable',
    DUPLICATE = 'duplicate',
    EDIT = 'edit',
    EDIT_FONT = 'edit-font',
    ELLIPSIS = 'ellipsis',
    EXCHANGE = 'exchange',
    EXCLAMATION_MARK = 'exclamation-mark',
    EYE = 'eye',
    EYE_CLOSED = 'eye-closed',
    FLAG = 'flag',
    FLASH = 'flash',
    FOLDER = 'folder',
    FOLDER_CHECK = 'folder-check',
    FRONT_CHAT = 'front-chat',
    GIFT = 'gift',
    HANDSHAKE = 'handshake',
    HEART = 'heart',
    HEART_FILLED = 'heart-filled',
    HOUSE = 'house',
    IMAGE = 'image',
    IMAGES = 'images',
    IMPORT = 'import',
    INFO = 'info',
    INTERDICTION = 'interdiction',
    LABEL = 'label',
    LAPTOP = 'laptop',
    LETTER = 'letter',
    LINK = 'link',
    LIST = 'list',
    LEFT_PANEL_CLOSE = 'left-panel-close',
    LOCALISATION = 'localisation',
    MAGIC_WAND = 'magic-wand',
    MEDAL = 'medal',
    MENU_BOOK = 'menu-book',
    MINUS = 'minus',
    MOTOR = 'motor',
    MOVE = 'move',
    NOTIFICATIONS = 'notifications',
    OPENAI = 'openai',
    PADLOCK = 'padlock',
    PADLOCK_OPEN = 'padlock-open',
    PAINT = 'paint',
    PAPER_PLANE = 'paper-plane',
    PARAMETER = 'parameter',
    PASTE = 'paste',
    PAUSE = 'pause',
    PERCENT = 'percent',
    PHONE = 'phone',
    PLAY = 'play',
    PLUS = 'plus',
    PROFILE = 'profile',
    QR_CODE = 'qr-code',
    QUESTION_MARK = 'question-mark',
    REMOVE = 'remove',
    ROCKET = 'rocket',
    ROTATION = 'rotation',
    ROUNDED_MINUS = 'rounded-minus',
    SAVE = 'save',
    SEARCH = 'search',
    SETTINGS = 'settings',
    SHARE = 'share',
    SIMPLE_PERCENT = 'simple-percent',
    SMILEY = 'smiley',
    SOCIAL_NETWORKS = 'social-networks',
    SOUND_OFF = 'sound-off',
    SOUND_ON = 'sound-on',
    SPINNER = 'spinner',
    STAR = 'star',
    STAR_BORDER = 'star-border',
    STAR_HALF = 'star-half',
    STAT_ARROW_DOWN = 'stat-arrow-down',
    STAT_ARROW_UP = 'stat-arrow-up',
    STICKER = 'sticker',
    SYNC = 'sync',
    SYNC_ALT = 'sync-alt',
    TAG = 'tag',
    TEMPLATE = 'template',
    THUMB_DOWN = 'thumb-down',
    THUMB_UP = 'thumb-up',
    TIME = 'time',
    TIMER = 'timer',
    TRASH = 'trash',
    USER_ADD = 'user-add',
    VIDEO = 'video',
    WARNING = 'warning',
    ZOOM_ADD = 'zoom-add',
    ZOOM_MINUS = 'zoom-minus',
}
