function _camelCaseToSnakeCase(key: string): string {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function objectToSnakeCase(obj: Record<string, any>): Record<string, any> {
    const newObj = {};
    // eslint-disable-next-line guard-for-in
    for (const camel in obj) {
        newObj[_camelCaseToSnakeCase(camel)] = obj[camel];
    }
    return newObj;
}
