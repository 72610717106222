import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

import { MediaType, PostType, waitFor } from '@malou-io/package-utils';

import { PostStatus } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-social-post-media',
    templateUrl: './social-post-media.component.html',
    styleUrls: ['./social-post-media.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        ImagePathResolverPipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediaComponent {
    readonly postType = input<PostType>(PostType.IMAGE);
    readonly firstAttachmentType = input<MediaType>();
    readonly customMediaStyle = input<object>({});
    readonly customMediaClass = input<string>('');
    readonly customIconStyle = input<object>({});
    readonly customIconClass = input<string>('');
    readonly tooltipText = input<string>('');
    readonly workingMedia = input.required<string>();
    readonly showVideoControls = input<boolean>(true);
    readonly postStatus = input<PostStatus>();
    readonly socialLink = input<string>();
    readonly backupMediaUrl = input<string | undefined>();
    readonly shouldLazyLoadMedia = input<boolean>(true);
    readonly thumbnailUrl = input<string | null>();

    readonly refreshMedia = output<ErrorEvent>();
    readonly loadedVideoMetadata = output<Event>();

    readonly PostType = PostType;

    readonly shouldLoadVideo = signal(false);

    readonly iconKey = computed(() => {
        switch (this.postType()) {
            case PostType.IMAGE:
                return 'image';
            case PostType.VIDEO:
            case PostType.REEL:
                return 'video';
            case PostType.CAROUSEL:
                return 'images';
            default:
                return 'image';
        }
    });

    onLoadedVideoMetadata(event: Event): void {
        this.loadedVideoMetadata.emit(event);
    }

    async playMedia(event: MouseEvent): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        const playIcon = event.target as HTMLElement;

        this.shouldLoadVideo.set(true);
        await waitFor(0); // Wait for Angular to refresh component and add the video element

        // get closest video tag
        const video = playIcon?.parentNode?.parentNode?.children[0] as HTMLVideoElement;

        // is video playing?
        if (!video.paused) {
            playIcon.innerHTML = 'play_arrow';
            video.pause();
            return;
        }

        video.play();
        playIcon.innerHTML = 'pause';
    }

    refresh(event: ErrorEvent): void {
        this.refreshMedia.emit(event);
    }

    refreshIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refresh(new ErrorEvent('Could not load some media'));
        }
    }

    openSocialLink(): void {
        if (this.socialLink()) {
            window.open(this.socialLink(), '_blank');
        }
    }
}
