import { ReviewAnalysisDto, SegmentAnalysisDto } from '@malou-io/package-dto';
import { ReviewAnalysisSentiment, ReviewAnalysisStatus, ReviewAnalysisTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

export class SemanticAnalysis {
    id: string;
    providerKey: string;
    platformKey: string;
    status: ReviewAnalysisStatus;
    reviewSocialId: string;
    externalId: string;
    socialCreatedAt: Date;
    providerId: string;
    segmentAnalyses: SegmentAnalyses[];
    rawProviderResponse: string;
    failCount: number;
    failedJobIds: string[];

    public constructor(init: Partial<SemanticAnalysis> | null, reviewText: string | null) {
        Object.assign(this, init);
        this.socialCreatedAt = new Date(this.socialCreatedAt);
        this.segmentAnalyses = this._sortSegmentsByPositionInText(this.segmentAnalyses, reviewText);
        this.segmentAnalyses = this._filterSegmentAnalysesByTag(this.segmentAnalyses, ReviewAnalysisTag.OVERALL_EXPERIENCE);
        this.segmentAnalyses = this._filterSegmentAnalysesBySentiment(this.segmentAnalyses, SemanticAnalysisSentiment.NEUTRAL);
    }

    static fromReviewAnalysisDto(semanticAnalysis: ReviewAnalysisDto | undefined, reviewText: string): SemanticAnalysis | null {
        if (!semanticAnalysis) {
            return null;
        }

        return new SemanticAnalysis(
            {
                ...semanticAnalysis,
                id: semanticAnalysis._id,
                status: SemanticAnalysis.mapToReviewAnalysisStatus(semanticAnalysis.status),
                socialCreatedAt: semanticAnalysis.socialCreatedAt ? new Date(semanticAnalysis.socialCreatedAt) : undefined,
                segmentAnalyses: semanticAnalysis.segmentAnalyses?.map((segmentAnalysis) =>
                    SemanticAnalysis.mapToSegmentAnalysis(segmentAnalysis)
                ),
            },
            reviewText
        );
    }

    static mapToReviewAnalysisStatus(status: string): ReviewAnalysisStatus | undefined {
        switch (status) {
            case ReviewAnalysisStatus.DONE:
                return ReviewAnalysisStatus.DONE;
            case ReviewAnalysisStatus.PENDING:
                return ReviewAnalysisStatus.PENDING;
            case ReviewAnalysisStatus.FAILED:
                return ReviewAnalysisStatus.FAILED;
            case ReviewAnalysisStatus.REVIEW_TOO_OLD:
                return ReviewAnalysisStatus.REVIEW_TOO_OLD;
            case ReviewAnalysisStatus.UNSUPPORTED_PLATFORM:
                return ReviewAnalysisStatus.UNSUPPORTED_PLATFORM;
            case ReviewAnalysisStatus.NO_RESULTS:
                return ReviewAnalysisStatus.NO_RESULTS;
            default:
                return undefined;
        }
    }

    static mapToSegmentAnalysis(segmentAnalysis: SegmentAnalysisDto): SegmentAnalyses {
        return {
            tag: segmentAnalysis.tag,
            segment: segmentAnalysis.segment,
            sentiment: SemanticAnalysis.mapToSemanticAnalysisSentiment(segmentAnalysis.sentiment),
        };
    }

    static mapToSemanticAnalysisSentiment(sentiment: ReviewAnalysisSentiment): SemanticAnalysisSentiment {
        switch (sentiment) {
            case ReviewAnalysisSentiment.POSITIVE:
                return SemanticAnalysisSentiment.POSITIVE;
            case ReviewAnalysisSentiment.NEGATIVE:
                return SemanticAnalysisSentiment.NEGATIVE;
            case ReviewAnalysisSentiment.NEUTRAL:
                return SemanticAnalysisSentiment.NEUTRAL;
        }
    }

    private _sortSegmentsByPositionInText(segmentAnalyses: SegmentAnalyses[], reviewText: string | null): SegmentAnalyses[] {
        const cleanReviewText = reviewText ? reviewText.replace(/\n/g, '') : '';
        return segmentAnalyses
            ?.map((segmentAnalysis) => ({
                ...segmentAnalysis,
                position: cleanReviewText.indexOf(segmentAnalysis.segment.replace(/\n/g, '').trim()),
            }))
            .sort((a, b) => a.position - b.position)
            .map((segmentAnalysis: SegmentAnalyses) => {
                delete segmentAnalysis.position;
                return {
                    ...segmentAnalysis,
                    segment: segmentAnalysis.segment.trim(),
                };
            });
    }

    private _filterSegmentAnalysesByTag(segmentAnalyses: SegmentAnalyses[], tag: ReviewAnalysisTag): SegmentAnalyses[] {
        return segmentAnalyses?.filter((segmentAnalysis) => segmentAnalysis.tag !== tag);
    }

    private _filterSegmentAnalysesBySentiment(segmentAnalyses: SegmentAnalyses[], sentiment: SemanticAnalysisSentiment): SegmentAnalyses[] {
        return segmentAnalyses?.filter((segmentAnalysis) => segmentAnalysis.sentiment?.toLowerCase() !== sentiment?.toLowerCase());
    }
}
export interface SegmentAnalyses {
    tag: ReviewAnalysisTag;
    segment: string;
    sentiment: SemanticAnalysisSentiment;
    position?: number;
}

export enum SemanticAnalysisProviderKey {
    OPENAI = 'openai',
}
