import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import {
    PlatformKey,
    ReviewReplyAutomationComment,
    ReviewReplyAutomationMethod,
    ReviewReplyAutomationRatingCategory,
} from '@malou-io/package-utils';

import { AutomationsService } from ':core/services/automations.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import {
    AVAILABLE_REVIEW_TEMPLATE_VARIABLES,
    TemplateReplacer,
    TemplateReplacerType,
} from ':modules/templates/template-replacer/template-replacer';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { Restaurant, Template } from ':shared/models';
import { ReviewReplyAutomation, ReviewReplyAutomationWithTemplates } from ':shared/models/automations';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

export interface EditReviewReplyAutomationsModalInputData {
    title: string;
    restaurant: Restaurant;
    automations: ReviewReplyAutomation[];
    templates: Template[];
    withComment: ReviewReplyAutomationComment;
    availableAutoResponsePlatforms: PlatformKey[];
}

@Component({
    selector: 'app-edit-review-reply-automations-modal',
    templateUrl: './edit-review-reply-automations-modal.component.html',
    styleUrls: ['./edit-review-reply-automations-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatRadioModule,
        TranslateModule,
        MatFormFieldModule,
        CloseWithoutSavingModalComponent,
        PlatformLogoComponent,
        SlideToggleComponent,
        SelectPlatformsComponent,
        ApplyPurePipe,
        ShortTextPipe,
        MatDividerModule,
        LazyLoadImageModule,
        MatTooltipModule,
    ],
})
export class EditReviewReplyAutomationsModalComponent {
    readonly SvgIcon = SvgIcon;
    automations: ReviewReplyAutomationWithTemplates[];
    displayCloseModal = false;
    isLoading = false;
    areAutomationsValid: boolean;
    reRenderSelectedPlatformsCount = 0;

    readonly ReviewReplyAutomationMethod = ReviewReplyAutomationMethod;

    readonly _TEMPLATE_REPLACER = new TemplateReplacer(this._translateService, AVAILABLE_REVIEW_TEMPLATE_VARIABLES);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: EditReviewReplyAutomationsModalInputData,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _dialogRef: MatDialogRef<EditReviewReplyAutomationsModalComponent>,
        private readonly _translateService: TranslateService,
        private readonly _automationsService: AutomationsService,
        private readonly _router: Router
    ) {
        this.automations = this.data.automations
            .filter((automation) => automation.withComment === this.data.withComment)
            .map((automation) => {
                const templates = automation.getTemplatesForAutomation(this.data.templates);
                const automationWithTemplates = new ReviewReplyAutomationWithTemplates(automation, templates);
                return automationWithTemplates;
            });

        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    confirmClose(automations: ReviewReplyAutomation[] | null = null): void {
        this._dialogRef.close(automations);
    }

    close(): void {
        if (!this.isLoading) {
            this.displayCloseModal = true;
        }
    }

    submit(): void {
        if (this._checkIfAutomationsAreValid()) {
            const notUpdatedWithComment =
                this.data.withComment === ReviewReplyAutomationComment.WITH_COMMENT
                    ? ReviewReplyAutomationComment.WITHOUT_COMMENT
                    : ReviewReplyAutomationComment.WITH_COMMENT;

            const notUpdatedAutomations = this.data.automations.filter((automation) => automation.withComment === notUpdatedWithComment);

            const updatedAndNotUpdatedAutomations = notUpdatedAutomations.concat(
                this.automations.map((automation) => omit(automation, ['templates']))
            );

            this.isLoading = true;

            this._automationsService
                .updateRestaurantReviewReplyAutomations(this.data.restaurant._id, updatedAndNotUpdatedAutomations)
                .subscribe((automations) => {
                    this.confirmClose(automations);
                    this.isLoading = false;
                });
        }
    }

    getRatingCategoryTitle = (ratingCategory: ReviewReplyAutomationRatingCategory): string =>
        this._translateService.instant(`automations.reviewReplies.ratingCategories.${ratingCategory}`);

    toggleActive(automation: ReviewReplyAutomationWithTemplates): void {
        automation.setActive(!automation.active);
        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    onReplyMethodChange(automation: ReviewReplyAutomationWithTemplates, event: MatRadioChange): void {
        automation.setReplyMethod(event.value as ReviewReplyAutomationMethod);
        if (event.value === ReviewReplyAutomationMethod.TEMPLATES) {
            const templateConfigsUpdate = automation.templateConfigs.length
                ? automation.templateConfigs
                : this._buildDefaultTemplateConfigs(automation);
            automation.setTemplateConfigs(templateConfigsUpdate);
        }
        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    onAiConfigPlatformsChange(automation: ReviewReplyAutomationWithTemplates, sendAutomaticallyToThesePlatformKeys: PlatformKey[]): void {
        automation.setAiConfig({ sendAutomaticallyToThesePlatformKeys });
        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    onTemplateConfigsPlatformChange(automation: ReviewReplyAutomationWithTemplates, template: Template, platformKey: PlatformKey): void {
        const templateConfigIndex = automation.templateConfigs.findIndex((config) => config.template === template._id);
        if (templateConfigIndex === -1) {
            automation.pushTemplateConfig({ template: template._id, platformKeys: [platformKey] });
        } else {
            const templateConfig = { ...automation.templateConfigs[templateConfigIndex] };

            const updatedTemplateConfig = {
                ...templateConfig,
                platformKeys: this._toggleArrayValue(templateConfig.platformKeys, platformKey),
            };
            if (updatedTemplateConfig.platformKeys.length) {
                const templateConfigsUpdate = automation.templateConfigs;
                templateConfigsUpdate[templateConfigIndex] = updatedTemplateConfig;
                automation.setTemplateConfigs(templateConfigsUpdate);
            } else {
                const templateConfigsUpdate = automation.templateConfigs.filter((config) => config.template !== template._id);
                automation.setTemplateConfigs(templateConfigsUpdate);
            }
            this.reRenderSelectedPlatformsCount++;
        }

        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    onTogglePlatformConfigsActive(automation: ReviewReplyAutomationWithTemplates, template: Template): void {
        if (this.isTemplateSelected(automation, template)) {
            const templateConfigsUpdate = automation.templateConfigs.filter((templateConfig) => templateConfig.template !== template._id);
            automation.setTemplateConfigs(templateConfigsUpdate);
        } else {
            automation.pushTemplateConfig({
                template: template._id,
                platformKeys: this.data.availableAutoResponsePlatforms,
            });
        }
        this.areAutomationsValid = this._checkIfAutomationsAreValid();
    }

    isPlatformChecked = (automation: ReviewReplyAutomation, template: Template, platformKey: PlatformKey): boolean =>
        automation.templateConfigs.some(
            (templateConfig) => templateConfig.template === template._id && templateConfig.platformKeys.includes(platformKey)
        );

    isTemplateSelected = (automation: ReviewReplyAutomation, template: Template): boolean =>
        automation.templateConfigs.some((templateConfig) => templateConfig.template === template._id);

    goToTemplates(): void {
        this._dialogRef.close();
        this._router.navigate([`/restaurants/${this.data.restaurant._id}/resources/review-templates`]);
    }

    goToPlatforms(): void {
        this._dialogRef.close();
        this._router.navigate([`/restaurants/${this.data.restaurant._id}/settings/platforms`]);
    }

    getAvailableTemplateTooltip = (automation: ReviewReplyAutomationWithTemplates): string => {
        if (!automation.templates.length) {
            return '';
        }
        const ratingCategory = this._translateService.instant(`automations.reviewReplies.ratingCategories.${automation.ratingCategory}`);
        const withComment = this._translateService.instant(`automations.reviewReplies.withComment.${automation.withComment}`);

        return this._translateService.instant('automations.reviewReplies.available_templates_tooltip', {
            templatesLength: automation.templates.length,
            ratingCategory,
            withComment,
        });
    };

    getTemplateConfigTitle = (automation: ReviewReplyAutomationWithTemplates): string =>
        this._translateService.instant('automations.reviewReplies.edit.templates.title', {
            templatesLength: automation.templates.length,
            templatesSelected: automation.templateConfigs.length,
        });

    replaceVariableTextToChipText = (text: string): string =>
        this._TEMPLATE_REPLACER.replaceText(text, {
            from: TemplateReplacerType.VARIABLE_TEXT,
            to: TemplateReplacerType.CHIP_TEXT,
        });

    private _checkIfAutomationsAreValid(): boolean {
        // automations are valid if they are not active,
        // OR if (they are active and the reply method is AI),
        // OR if (they are active, the reply method is TEMPLATES,
        // they have at least one enabled template AND
        // every enabled template have at least one enabled platform)
        return this.automations.every(
            (automation) =>
                !automation.active ||
                automation.replyMethod === ReviewReplyAutomationMethod.AI ||
                (automation.replyMethod === ReviewReplyAutomationMethod.TEMPLATES &&
                    automation.templateConfigs?.length &&
                    automation.templateConfigs.every((templateConfig) => templateConfig.platformKeys?.length))
        );
    }

    private _buildDefaultTemplateConfigs(automation: ReviewReplyAutomationWithTemplates): ReviewReplyAutomation['templateConfigs'] {
        return automation.templates.map((template) => ({
            template: template._id,
            platformKeys: this.data.availableAutoResponsePlatforms,
        }));
    }

    private _toggleArrayValue<T>(array: T[], value: T): T[] {
        return array.includes(value) ? array.filter((item) => item !== value) : [...array, value];
    }
}
