<!-- Loader -->
@if (isLoading) {
    <div class="flex flex-col">
        <app-skeleton skeletonClass="!w-[100%] !h-[80px] secondary-bg" [count]="7"></app-skeleton>
    </div>
}
<!-- End Loader -->

<ng-container>
    <!-- No conversations -->
    @if (!isLoading && !errorLoadingConversations && !conversationsWithMessages.length && !areAllPlatformsDisconnected) {
        <div class="h-full">
            <ng-container *ngTemplateOutlet="noconversations"></ng-container>
        </div>
    }
    <!-- End no conversations -->

    <!-- Rendering conversations -->
    @if (!isLoading && !errorLoadingConversations && conversationsWithMessages.length) {
        <mat-tab-group
            class="grow"
            mat-align-tabs="start"
            dynamicHeight
            [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="onTabChange($event)">
            <mat-tab label="{{ 'messages.tab_bar.general' | translate }}">
                @if ((filteredConversations | filterByConversationType: ConversationType.GENERAL).length !== 0) {
                    @for (
                        conversation of filteredConversations | filterByConversationType: ConversationType.GENERAL;
                        track trackByConversationId($index, conversation)
                    ) {
                        <app-conversation-card
                            class="cursor-pointer"
                            [selectedConversation]="selectedConversation"
                            [conversationWithMessages]="conversation"
                            [checkedConversations]="checkedConversations"
                            (onSelectConversation)="selectConversation($event)"
                            (onCheckConversation)="checkConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation.emit($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite.emit($event)">
                        </app-conversation-card>
                    }
                } @else {
                    <div class="grid h-full min-h-[60vh] w-full place-items-center">
                        <div class="flex flex-col items-center text-center">
                            <img class="mb-10" height="127" width="127" [src]="'Goggles' | illustrationPathResolver" />
                            @if (conversationsTextSearch.length === 0) {
                                <div class="malou-text-14--bold mb-2">{{ 'messages.conversations.no_conversations' | translate }}</div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_hint' | translate"></div>
                            } @else {
                                <div
                                    class="malou-text-14--bold mb-2"
                                    [innerHTML]="'messages.conversations.search_result_null' | translate"></div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_for_search' | translate"></div>
                            }
                        </div>
                    </div>
                }
            </mat-tab>
            <mat-tab label="{{ 'messages.tab_bar.favorite' | translate }}">
                @if ((filteredConversations | filterByConversationType: ConversationType.FAVORITE).length !== 0) {
                } @else {
                    <div class="grid h-full min-h-[60vh] w-full place-items-center">
                        <div class="flex flex-col items-center text-center">
                            <img class="mb-10" height="127" width="127" [src]="'Goggles' | illustrationPathResolver" />
                            @if (conversationsTextSearch.length === 0) {
                                <div class="malou-text-14--bold mb-2">{{ 'messages.conversations.no_conversations' | translate }}</div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_hint' | translate"></div>
                            } @else {
                                <div
                                    class="malou-text-14--bold mb-2"
                                    [innerHTML]="'messages.conversations.search_result_null' | translate"></div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_for_search' | translate"></div>
                            }
                        </div>
                    </div>
                }
                @for (
                    conversation of filteredConversations | filterByConversationType: ConversationType.FAVORITE;
                    track trackByConversationId($index, conversation)
                ) {
                    <app-conversation-card
                        class="cursor-pointer"
                        [selectedConversation]="selectedConversation"
                        [conversationWithMessages]="conversation"
                        [checkedConversations]="checkedConversations"
                        (onSelectConversation)="selectConversation($event)"
                        (onCheckConversation)="checkConversation($event)"
                        (onChangeArchivedConversation)="onChangeArchivedConversation.emit($event)"
                        (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite.emit($event)">
                    </app-conversation-card>
                }
            </mat-tab>
            <mat-tab label="{{ 'messages.tab_bar.archived' | translate }}">
                @if ((filteredConversations | filterByConversationType: ConversationType.ARCHIVED).length !== 0) {
                    @for (
                        conversation of filteredConversations | filterByConversationType: ConversationType.ARCHIVED;
                        track trackByConversationId($index, conversation)
                    ) {
                        <app-conversation-card
                            class="cursor-pointer"
                            [selectedConversation]="selectedConversation"
                            [conversationWithMessages]="conversation"
                            [checkedConversations]="checkedConversations"
                            (onSelectConversation)="selectConversation($event)"
                            (onCheckConversation)="checkConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation.emit($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite.emit($event)">
                        </app-conversation-card>
                    }
                } @else {
                    <div class="grid h-full min-h-[60vh] w-full place-items-center">
                        <div class="flex flex-col items-center text-center">
                            <img class="mb-10" height="127" width="127" [src]="'Goggles' | illustrationPathResolver" />
                            @if (conversationsTextSearch.length === 0) {
                                <div class="malou-text-14--bold mb-2">{{ 'messages.conversations.no_conversations' | translate }}</div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_hint' | translate"></div>
                            } @else {
                                <div
                                    class="malou-text-14--bold mb-2"
                                    [innerHTML]="'messages.conversations.search_result_null' | translate"></div>
                                <div
                                    class="malou-text-10--regular"
                                    [innerHTML]="'messages.conversations.no_conversations_for_search' | translate"></div>
                            }
                        </div>
                    </div>
                }
            </mat-tab>
        </mat-tab-group>
    }
    @if (
        (canLoadMoreConversations | applyPure: paginations : filteredConversations : selectedTabIndex) &&
        filteredConversations.length >= conversationsPageSize
    ) {
        <button
            class="malou-btn-flat malou-background-white left-0 z-[4] h-[60px] w-full !rounded-[10px] p-4"
            mat-button
            (click)="loadMoreConversations()">
            {{ 'messages.load_more_conversations' | translate }}
        </button>
    }
</ng-container>
<!-- End Conversations -->

<ng-template #noconversations>
    <div class="grid h-full min-h-[60vh] w-full place-items-center">
        <div class="flex flex-col items-center text-center">
            <img class="mb-10" height="127" width="127" [src]="'Goggles' | illustrationPathResolver" />
            @if (conversationsTextSearch.length === 0) {
                <div class="malou-text-14--bold mb-2">{{ 'messages.conversations.no_conversations' | translate }}</div>
                <div class="malou-text-10--regular" [innerHTML]="'messages.conversations.no_conversations_hint' | translate"></div>
            } @else {
                <div class="malou-text-14--bold mb-2" [innerHTML]="'messages.conversations.search_result_null' | translate"></div>
                <div class="malou-text-10--regular" [innerHTML]="'messages.conversations.no_conversations_for_search' | translate"></div>
            }
        </div>
    </div>
</ng-template>
