import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TemplateType } from ':shared/enums/template-type.enum';
import { Template } from ':shared/models';

@Pipe({
    name: 'duplicateTooltip',
    standalone: true,
})
export class DuplicateTooltipPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(template: Template): string {
        const restaurantName = template.duplicatedFromRestaurant?.name;
        const userName = [template.duplicatedByUser?.name, template.duplicatedByUser?.lastname].filter(Boolean).join(' ');
        let tooltipText;
        if (restaurantName) {
            tooltipText = userName
                ? this._translate.instant('templates.to_be_confirmed', { restaurant: restaurantName, user: userName })
                : this._translate.instant('templates.to_be_confirmed_restaurant_only', { restaurant: restaurantName });
        } else {
            tooltipText = userName
                ? this._translate.instant('templates.to_be_confirmed_user_only', { user: userName })
                : this._translate.instant('templates.to_be_confirmed_no_detail');
        }
        if (template.type === TemplateType.REVIEW) {
            tooltipText += this._translate.instant('templates.change_keywords');
        }
        return tooltipText;
    }
}
