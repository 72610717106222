<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            @if (totalDifferencesCount) {
                <span>
                    {{ 'information.platform_comparison.differences' | translate: { totalDifferencesCount } }}
                </span>
            }
            @if (!totalDifferencesCount && comparedPlatforms.length === 1) {
                <div class="flex items-center gap-x-2">
                    <span class="malou-text-18--bold malou-color-text-1">{{
                        'admin.update.compare_modal.update_information_on_platform'
                            | translate: { platform: comparedPlatforms[0].key | enumTranslate: 'platform_key' }
                    }}</span>
                    <app-platform-logo imgClasses="h-8 w-8" [logo]="comparedPlatforms[0].key"></app-platform-logo>
                    <button class="malou-btn-flat malou-text-13--semibold" mat-button (click)="accessPlatform(comparedPlatforms[0])">
                        {{ 'admin.update.compare_modal.access_platform' | translate }}
                    </button>
                </div>
            }

            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <ng-container *ngTemplateOutlet="comparedPlatforms.length > 1 ? multiplePlatforms : singlePlatform"></ng-container>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                {{ 'common.close' | translate }}
            </button>
            @if (comparedPlatforms.length > 1) {
                <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="updateNow()">
                    {{ 'common.validate' | translate }}
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #multiplePlatforms>
    <div class="malou-expansion-panel" [@.disabled]="disableAnimation">
        <mat-accordion>
            @for (platform of comparedPlatforms; track platform) {
                <mat-expansion-panel [disabled]="platform.platformStatus === PlatformStatus.EMPTY_PLATFORM">
                    <mat-expansion-panel-header
                        class="custom-expansion-panel-header"
                        [class.custom-expansion-panel-header--disabled]="platform.platformStatus === PlatformStatus.EMPTY_PLATFORM">
                        <app-platform-logo
                            imgClasses="h-8 w-8"
                            [logo]="platform.key"
                            [dynamicClasses]="{ 'opacity-50': !platform.isConnected() }"></app-platform-logo>
                        <span
                            class="malou-color-text-1 malou-text-13--semibold ml-4 flex items-center"
                            [class.opacity-50]="!platform.isConnected()">
                            {{ platform.fullName }}
                        </span>
                        @if (platform.platformStatus !== PlatformStatus.EMPTY_PLATFORM) {
                            <div class="diff-icon">
                                {{ platform?.data?.length }}
                            </div>
                        }
                    </mat-expansion-panel-header>
                    <app-platform-comparison
                        [platformComparison]="platform"
                        [action]="action"
                        (changeLock)="onChangeLock(platform, $event)"></app-platform-comparison>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template #singlePlatform>
    <app-platform-comparison
        [platformComparison]="comparedPlatforms[0]"
        [action]="action"
        (changeLock)="onChangeLock(comparedPlatforms[0], $event)"></app-platform-comparison>
</ng-template>
