import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SpecialTimePeriod } from ':shared/models/periods';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-comparison-special-hours',
    templateUrl: './comparison-special-hours.component.html',
    styleUrls: ['./comparison-special-hours.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, ApplySelfPurePipe],
})
export class ComparisonSpecialHoursComponent {
    @Input() public hours: SpecialTimePeriod[];
}
