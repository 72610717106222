import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-email-verification-failure',
    templateUrl: './email-verification-failure.component.html',
    styleUrls: ['./email-verification-failure.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class EmailVerificationFailureComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
