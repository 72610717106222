import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { WheelOfFortuneType } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

export interface CreateAggregatedOrRestaurantWheelOfFortuneOutput {
    doNotShowAgain: boolean;
    wheelOfFortuneType: WheelOfFortuneType;
}

@Component({
    selector: 'app-create-aggregated-or-restaurant-wheel-of-fortune-modal',
    standalone: true,
    imports: [MatButtonModule, MatCheckboxModule, MatIconModule, TranslateModule, IllustrationPathResolverPipe],
    templateUrl: './create-aggregated-or-restaurant-wheel-of-fortune-modal.component.html',
    styleUrls: ['./create-aggregated-or-restaurant-wheel-of-fortune-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAggregatedOrRestaurantWheelOfFortuneModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly WheelOfFortuneType = WheelOfFortuneType;
    readonly doNotShowAgain = signal(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<CreateAggregatedOrRestaurantWheelOfFortuneModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {}
    ) {}

    close(wheelOfFortuneType?: WheelOfFortuneType): void {
        if (!wheelOfFortuneType) {
            return this._dialogRef.close();
        }

        this._dialogRef.close({ doNotShowAgain: this.doNotShowAgain(), wheelOfFortuneType });
    }

    setDoNotShowAgain(value: MatCheckboxChange): void {
        this.doNotShowAgain.set(value.checked);
    }
}
