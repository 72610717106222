import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { GetTop3PostsInsightsResponseDto } from '@malou-io/package-dto';
import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { TopPostCardSkeletonComponent } from ':shared/components/top-post-card-skeleton/top-post-card-skeleton.component';
import { TopPostCardComponent, TopPostCardInputData } from ':shared/components/top-post-card/top-post-card.component';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { DatesAndPeriod, Restaurant } from ':shared/models';

import { PlatformFilterPage } from '../../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';

@Component({
    selector: 'app-aggregated-top-3-posts',
    templateUrl: './aggregated-top-3-posts.component.html',
    styleUrls: ['./aggregated-top-3-posts.component.scss'],
    standalone: true,
    imports: [AsyncPipe, TranslateModule, TopPostCardComponent, TopPostCardSkeletonComponent],
})
export class AggregatedTop3PostsComponent implements OnInit {
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);
    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    isLoading$ = new BehaviorSubject(true);
    top3Posts$: Observable<GetTop3PostsInsightsResponseDto[]>;
    topPostCardInputs$: Observable<TopPostCardInputData[]>;

    readonly trackByUrlFn = TrackByFunctionFactory.get('uuid');

    constructor(
        private readonly _store: Store,
        private readonly _postsService: PostsService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        this.isLoading$.subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));
    }

    ngOnInit(): void {
        this.top3Posts$ = this._getTop3Posts$();

        this.topPostCardInputs$ = this.top3Posts$.pipe(map((top3Posts) => top3Posts.map(this._mapTop3PostsToTopPostCardInputs)));
    }

    private _mapTop3PostsToTopPostCardInputs(top3Post: GetTop3PostsInsightsResponseDto): TopPostCardInputData {
        return {
            restaurantName: top3Post.restaurantName,
            restaurantAddress: top3Post.restaurantAddress,
            postType: top3Post.postType,
            platformKey: top3Post.platformKey,
            url: top3Post.url,
            thumbnailUrl: top3Post.thumbnailUrl,
            createdAt: top3Post.createdAt,
            likes: top3Post.likes ?? 0,
            comments: top3Post.comments ?? 0,
            shares: top3Post.shares ?? 0,
            saves: top3Post.saves ?? 0,
            impressions: top3Post.impressions ?? 0,
            engagementRate: top3Post.engagementRate ?? 0,
            uuid: uuidv4(),
        };
    }

    private _getTop3Posts$(): Observable<GetTop3PostsInsightsResponseDto[]> {
        return combineLatest([this.dates$, this.platformKeys$, this.selectedRestaurants$]).pipe(
            filter(([dates, platforms]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0),
            filter(([dates]) => {
                const { startDate, endDate } = dates;
                return !!startDate && !!endDate;
            }),
            tap(() => this.isLoading$.next(true)),
            switchMap(([dates, platformKeys, restaurants]) =>
                this._postsService
                    .getTop3PostsInsights({
                        restaurantIds: restaurants.map((r) => r._id),
                        platformKeys: PlatformDefinitions.getPlatformKeysWithRSStats().filter((platformKey) =>
                            platformKeys.includes(platformKey)
                        ) as (PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM)[],
                        startDate: (dates.startDate as Date).toISOString(),
                        endDate: (dates.endDate as Date).toISOString(),
                    })
                    .pipe(catchError(() => of({ data: [] })))
            ),
            map((res) => res.data),
            tap((data) => {
                if (!data.length) {
                    this.hasDataChange.emit(false);
                }
                this.isLoading$.next(false);
            })
        );
    }
}
