export enum CountryCode {
    AFGHANISTAN = 'AF',
    ALBANIA = 'AL',
    ALGERIA = 'DZ',
    AMERICAN_SAMOA = 'AS',
    ANDORRA = 'AD',
    ANGOLA = 'AO',
    ANGUILLA = 'AI',
    ANTARCTICA = 'AQ',
    ANTIGUA_AND_BARBUDA = 'AG',
    ARGENTINA = 'AR',
    ARMENIA = 'AM',
    ARUBA = 'AW',
    AUSTRALIA = 'AU',
    AUSTRIA = 'AT',
    AZERBAIJAN = 'AZ',
    BAHAMAS = 'BS',
    BAHRAIN = 'BH',
    BANGLADESH = 'BD',
    BARBADOS = 'BB',
    BELARUS = 'BY',
    BELGIUM = 'BE',
    BELIZE = 'BZ',
    BENIN = 'BJ',
    BERMUDA = 'BM',
    BHUTAN = 'BT',
    BOLIVIA = 'BO',
    BOSNIA_AND_HERZEGOVINA = 'BA',
    BOTSWANA = 'BW',
    BRAZIL = 'BR',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
    BRUNEI_DARUSSALAM = 'BN',
    BULGARIA = 'BG',
    BURKINA_FASO = 'BF',
    BURUNDI = 'BI',
    CAMBODIA = 'KH',
    CAMEROON = 'CM',
    CANADA = 'CA',
    CAPE_VERDE = 'CV',
    CAYMAN_ISLANDS = 'KY',
    CENTRAL_AFRICAN_REPUBLIC = 'CF',
    CHAD = 'TD',
    CHILE = 'CL',
    CHINA = 'CN',
    CHRISTMAS_ISLAND = 'CX',
    COCOS_ISLANDS = 'CC',
    COLOMBIA = 'CO',
    COMOROS = 'KM',
    CONGO = 'CG',
    COOK_ISLANDS = 'CK',
    COSTA_RICA = 'CR',
    COTE_D_IVOIRE = 'CI',
    CROATIA = 'HR',
    CUBA = 'CU',
    CYPRUS = 'CY',
    CZECH_REPUBLIC = 'CZ',
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'CD',
    DENMARK = 'DK',
    DJIBOUTI = 'DJ',
    DOMINICA = 'DM',
    DOMINICAN_REPUBLIC = 'DO',
    ECUADOR = 'EC',
    EGYPT = 'EG',
    EL_SALVADOR = 'SV',
    EQUATORIAL_GUINEA = 'GQ',
    ERITREA = 'ER',
    ESTONIA = 'EE',
    ETHIOPIA = 'ET',
    FALKLAND_ISLANDS = 'FK',
    FAROE_ISLANDS = 'FO',
    FIJI = 'FJ',
    FINLAND = 'FI',
    FRANCE = 'FR',
    FRENCH_GUIANA = 'GF',
    FRENCH_POLYNESIA = 'PF',
    GABON = 'GA',
    GAMBIA = 'GM',
    GEORGIA = 'GE',
    GERMANY = 'DE',
    GHANA = 'GH',
    GIBRALTAR = 'GI',
    GREECE = 'GR',
    GREENLAND = 'GL',
    GRENADA = 'GD',
    GUADELOUPE = 'GP',
    GUAM = 'GU',
    GUATEMALA = 'GT',
    GUERNSEY = 'GG',
    GUINEA = 'GN',
    GUINEA_BISSAU = 'GW',
    GUYANA = 'GY',
    HAITI = 'HT',
    HONDURAS = 'HN',
    HONG_KONG = 'HK',
    HUNGARY = 'HU',
    ICELAND = 'IS',
    INDIA = 'IN',
    INDONESIA = 'ID',
    IRAN = 'IR',
    IRAQ = 'IQ',
    IRELAND = 'IE',
    ISLE_OF_MAN = 'IM',
    ISRAEL = 'IL',
    ITALY = 'IT',
    JAMAICA = 'JM',
    JAPAN = 'JP',
    JERSEY = 'JE',
    JORDAN = 'JO',
    KAZAKHSTAN = 'KZ',
    KENYA = 'KE',
    KIRIBATI = 'KI',
    KUWAIT = 'KW',
    KYRGYZSTAN = 'KG',
    LAND_ISLANDS = 'AX',
    LAOS = 'LA',
    LATVIA = 'LV',
    LEBANON = 'LB',
    LESOTHO = 'LS',
    LIBERIA = 'LR',
    LIBYA = 'LY',
    LIECHTENSTEIN = 'LI',
    LITHUANIA = 'LT',
    LUXEMBOURG = 'LU',
    MACAO = 'MO',
    MACEDONIA = 'MK',
    MADAGASCAR = 'MG',
    MALAWI = 'MW',
    MALAYSIA = 'MY',
    MALDIVES = 'MV',
    MALI = 'ML',
    MALTA = 'MT',
    MARSHALL_ISLANDS = 'MH',
    MARTINIQUE = 'MQ',
    MAURITANIA = 'MR',
    MAURITIUS = 'MU',
    MAYOTTE = 'YT',
    MEXICO = 'MX',
    MICRONESIA = 'FM',
    MOLDOVA = 'MD',
    MONACO = 'MC',
    MONGOLIA = 'MN',
    MONTENEGRO = 'ME',
    MONTSERRAT = 'MS',
    MOROCCO = 'MA',
    MOZAMBIQUE = 'MZ',
    MYANMAR = 'MM',
    NAMIBIA = 'NA',
    NAURU = 'NR',
    NEPAL = 'NP',
    NETHERLANDS = 'NL',
    NETHERLANDS_ANTILLES = 'AN',
    NEW_CALEDONIA = 'NC',
    NEW_ZEALAND = 'NZ',
    NICARAGUA = 'NI',
    NIGER = 'NE',
    NIGERIA = 'NG',
    NIUE = 'NU',
    NORFOLK_ISLAND = 'NF',
    NORTH_KOREA = 'KP',
    NORTHERN_MARIANA_ISLANDS = 'MP',
    NORWAY = 'NO',
    OMAN = 'OM',
    PAKISTAN = 'PK',
    PALAU = 'PW',
    PALESTINIAN = 'PS',
    PANAMA = 'PA',
    PAPUA_NEW_GUINEA = 'PG',
    PARAGUAY = 'PY',
    PERU = 'PE',
    PHILIPPINES = 'PH',
    PITCAIRN = 'PN',
    POLAND = 'PL',
    PORTUGAL = 'PT',
    PUERTO_RICO = 'PR',
    QATAR = 'QA',
    RÉUNION = 'RE',
    ROMANIA = 'RO',
    RUSSIA = 'RU',
    RWANDA = 'RW',
    SAINT_BARTHÉLEMY = 'BL',
    SAINT_HELENA = 'SH',
    SAINT_KITTS_AND_NEVIS = 'KN',
    SAINT_LUCIA = 'LC',
    SAINT_MARTIN = 'MF',
    SAINT_PIERRE_AND_MIQUELON = 'PM',
    SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
    SAMOA = 'WS',
    SAN_MARINO = 'SM',
    SAO_TOME_AND_PRINCIPE = 'ST',
    SAUDI_ARABIA = 'SA',
    SENEGAL = 'SN',
    SERBIA = 'RS',
    SEYCHELLES = 'SC',
    SIERRA_LEONE = 'SL',
    SINGAPORE = 'SG',
    SLOVAKIA = 'SK',
    SLOVENIA = 'SI',
    SOLOMON_ISLANDS = 'SB',
    SOMALIA = 'SO',
    SOUTH_AFRICA = 'ZA',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'GS',
    SOUTH_KOREA = 'KR',
    SPAIN = 'ES',
    SRI_LANKA = 'LK',
    SUDAN = 'SD',
    SURINAME = 'SR',
    SVALBARD_AND_JAN_MAYEN = 'SJ',
    SWAZILAND = 'SZ',
    SWEDEN = 'SE',
    SWITZERLAND = 'CH',
    SYRIA = 'SY',
    TAIWAN = 'TW',
    TAJIKISTAN = 'TJ',
    TANZANIA = 'TZ',
    THAILAND = 'TH',
    TIMOR_LESTE = 'TL',
    TOGO = 'TG',
    TOKELAU = 'TK',
    TONGA = 'TO',
    TRINIDAD_AND_TOBAGO = 'TT',
    TUNISIA = 'TN',
    TURKEY = 'TR',
    TURKMENISTAN = 'TM',
    TURKS_AND_CAICOS_ISLANDS = 'TC',
    TUVALU = 'TV',
    UGANDA = 'UG',
    UKRAINE = 'UA',
    UNITED_ARAB_EMIRATES = 'AE',
    UNITED_KINGDOM = 'GB',
    UNITED_STATES = 'US',
    URUGUAY = 'UY',
    UZBEKISTAN = 'UZ',
    VANUATU = 'VU',
    VATICAN = 'VA',
    VENEZUELA = 'VE',
    VIETNAM = 'VN',
    VIRGIN_ISLANDS_BRITISH = 'VG',
    VIRGIN_ISLANDS_US = 'VI',
    WALLIS_AND_FUTUNA = 'WF',
    YEMEN = 'YE',
    ZAMBIA = 'ZM',
    ZIMBABWE = 'ZW',
}

export const allCountries = 'ALL';

export const COUNTRIES = [
    {
        name: 'Afghanistan',
        dial_code: '+93',
        code: CountryCode.AFGHANISTAN,
        name_fr: 'Afghanistan',
    },
    {
        name: 'Albania',
        dial_code: '+355',
        code: CountryCode.ALBANIA,
        name_fr: 'Albanie',
    },
    {
        name: 'Algeria',
        dial_code: '+213',
        code: CountryCode.ALGERIA,
        name_fr: 'Algérie',
    },
    {
        name: 'AmericanSamoa',
        dial_code: '+1 684',
        code: CountryCode.AMERICAN_SAMOA,
        name_fr: 'Samoa américaines',
    },
    {
        name: 'Andorra',
        dial_code: '+376',
        code: CountryCode.ANDORRA,
        name_fr: 'Andorre',
    },
    {
        name: 'Angola',
        dial_code: '+244',
        code: CountryCode.ANGOLA,
        name_fr: 'Angola',
    },
    {
        name: 'Anguilla',
        dial_code: '+1 264',
        code: CountryCode.ANGUILLA,
        name_fr: 'Anguilla',
    },
    {
        name: 'Antigua and Barbuda',
        dial_code: '+1268',
        code: CountryCode.ANTIGUA_AND_BARBUDA,
        name_fr: 'Antigue-et-Barbude',
    },
    {
        name: 'Argentina',
        dial_code: '+54',
        code: CountryCode.ARGENTINA,
        name_fr: 'Argentine',
    },
    {
        name: 'Armenia',
        dial_code: '+374',
        code: CountryCode.ARMENIA,
        name_fr: 'Arménie',
    },
    {
        name: 'Aruba',
        dial_code: '+297',
        code: CountryCode.ARUBA,
        name_fr: 'Aruba',
    },
    {
        name: 'Australia',
        dial_code: '+61',
        code: CountryCode.AUSTRALIA,
        name_fr: 'Australie',
    },
    {
        name: 'Austria',
        dial_code: '+43',
        code: CountryCode.AUSTRIA,
        name_fr: 'Autriche',
    },
    {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: CountryCode.AZERBAIJAN,
        name_fr: 'Azerbaïdjan',
    },
    {
        name: 'Bahamas',
        dial_code: '+1 242',
        code: CountryCode.BAHAMAS,
        name_fr: 'Bahamas',
    },
    {
        name: 'Bahrain',
        dial_code: '+973',
        code: CountryCode.BAHRAIN,
        name_fr: 'Bahreïn',
    },
    {
        name: 'Bangladesh',
        dial_code: '+880',
        code: CountryCode.BANGLADESH,
        name_fr: 'Bangladesh',
    },
    {
        name: 'Barbados',
        dial_code: '+1 246',
        code: CountryCode.BARBADOS,
        name_fr: 'Barbade',
    },
    {
        name: 'Belarus',
        dial_code: '+375',
        code: CountryCode.BELARUS,
        name_fr: 'Biélorussie',
    },
    {
        name: 'Belgium',
        dial_code: '+32',
        code: CountryCode.BELGIUM,
        name_fr: 'Belgique',
    },
    {
        name: 'Belize',
        dial_code: '+501',
        code: CountryCode.BELIZE,
        name_fr: 'Belize',
    },
    {
        name: 'Benin',
        dial_code: '+229',
        code: CountryCode.BENIN,
        name_fr: 'Benin',
    },
    {
        name: 'Bermuda',
        dial_code: '+1 441',
        code: CountryCode.BERMUDA,
        name_fr: 'Bermudes',
    },
    {
        name: 'Bhutan',
        dial_code: '+975',
        code: CountryCode.BHUTAN,
        name_fr: 'Bhoutan',
    },
    {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: CountryCode.BOSNIA_AND_HERZEGOVINA,
        name_fr: 'Bosnie-Herzégovine',
    },
    {
        name: 'Botswana',
        dial_code: '+267',
        code: CountryCode.BOTSWANA,
        name_fr: 'Botswana',
    },
    {
        name: 'Brazil',
        dial_code: '+55',
        code: CountryCode.BRAZIL,
        name_fr: 'Brésil',
    },
    {
        name: 'British Indian Ocean Territory',
        dial_code: '+246',
        code: CountryCode.BRITISH_INDIAN_OCEAN_TERRITORY,
        name_fr: "Territoire britannique de l'océan Indien",
    },
    {
        name: 'Bulgaria',
        dial_code: '+359',
        code: CountryCode.BULGARIA,
        name_fr: 'Bulgarie',
    },
    {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: CountryCode.BULGARIA,
        name_fr: 'Burkina Faso',
    },
    {
        name: 'Burundi',
        dial_code: '+257',
        code: CountryCode.BURUNDI,
        name_fr: 'Burundi',
    },
    {
        name: 'Cambodia',
        dial_code: '+855',
        code: CountryCode.CAMBODIA,
        name_fr: 'Cambodge',
    },
    {
        name: 'Cameroon',
        dial_code: '+237',
        code: CountryCode.CAMEROON,
        name_fr: 'Cameroun',
    },
    {
        name: 'Canada',
        dial_code: '+1',
        code: CountryCode.CANADA,
        name_fr: 'Canada',
    },
    {
        name: 'Cape Verde',
        dial_code: '+238',
        code: CountryCode.CAPE_VERDE,
        name_fr: 'Cap-Vert',
    },
    {
        name: 'Cayman Islands',
        dial_code: '+ 345',
        code: CountryCode.CAYMAN_ISLANDS,
        name_fr: 'Îles Caïmanes',
    },
    {
        name: 'Central African Republic',
        dial_code: '+236',
        code: CountryCode.CENTRAL_AFRICAN_REPUBLIC,
        name_fr: 'Centrafricaine, république',
    },
    {
        name: 'Chad',
        dial_code: '+235',
        code: CountryCode.CHAD,
        name_fr: 'Tchad',
    },
    {
        name: 'Chile',
        dial_code: '+56',
        code: CountryCode.CHILE,
        name_fr: 'Chili',
    },
    {
        name: 'China',
        dial_code: '+86',
        code: CountryCode.CHINA,
        name_fr: 'Chine',
    },
    {
        name: 'Christmas Island',
        dial_code: '+61',
        code: CountryCode.CHRISTMAS_ISLAND,
        name_fr: 'île Christmas',
    },
    {
        name: 'Colombia',
        dial_code: '+57',
        code: CountryCode.COLOMBIA,
        name_fr: 'Colombie',
    },
    {
        name: 'Comoros',
        dial_code: '+269',
        code: CountryCode.COMOROS,
        name_fr: 'Comores',
    },
    {
        name: 'Congo',
        dial_code: '+242',
        code: CountryCode.CONGO,
        name_fr: 'République du Congo',
    },
    {
        name: 'Cook Islands',
        dial_code: '+682',
        code: CountryCode.COOK_ISLANDS,
        name_fr: 'Îles Cook',
    },
    {
        name: 'Costa Rica',
        dial_code: '+506',
        code: CountryCode.COSTA_RICA,
        name_fr: 'Costa Rica',
    },
    {
        name: 'Croatia',
        dial_code: '+385',
        code: CountryCode.CROATIA,
        name_fr: 'Croatie',
    },
    {
        name: 'Cuba',
        dial_code: '+53',
        code: CountryCode.CUBA,
        name_fr: 'Cuba',
    },
    {
        name: 'Cyprus',
        dial_code: '+537',
        code: CountryCode.CYPRUS,
        name_fr: 'Chypre',
    },
    {
        name: 'Czech Republic',
        dial_code: '+420',
        code: CountryCode.CZECH_REPUBLIC,
        name_fr: 'République tchèque',
    },
    {
        name: 'Denmark',
        dial_code: '+45',
        code: CountryCode.DENMARK,
        name_fr: 'Danemark',
    },
    {
        name: 'Djibouti',
        dial_code: '+253',
        code: CountryCode.DJIBOUTI,
        name_fr: 'Djibouti',
    },
    {
        name: 'Dominica',
        dial_code: '+1 767',
        code: CountryCode.DOMINICA,
        name_fr: 'Dominique',
    },
    {
        name: 'Dominican Republic',
        dial_code: '+1 849',
        code: CountryCode.DOMINICAN_REPUBLIC,
        name_fr: 'République Dominicaine',
    },
    {
        name: 'Ecuador',
        dial_code: '+593',
        code: CountryCode.ECUADOR,
        name_fr: 'Équateur',
    },
    {
        name: 'Egypt',
        dial_code: '+20',
        code: CountryCode.EGYPT,
        name_fr: 'Égypte',
    },
    {
        name: 'El Salvador',
        dial_code: '+503',
        code: CountryCode.EL_SALVADOR,
        name_fr: 'El Salvador',
    },
    {
        name: 'Equatorial Guinea',
        dial_code: '+240',
        code: CountryCode.EQUATORIAL_GUINEA,
        name_fr: 'Guinée équatoriale',
    },
    {
        name: 'Eritrea',
        dial_code: '+291',
        code: CountryCode.ERITREA,
        name_fr: 'Érythrée',
    },
    {
        name: 'Estonia',
        dial_code: '+372',
        code: CountryCode.ESTONIA,
        name_fr: 'Estonie',
    },
    {
        name: 'Ethiopia',
        dial_code: '+251',
        code: CountryCode.ETHIOPIA,
        name_fr: 'Éthiopie',
    },
    {
        name: 'Faroe Islands',
        dial_code: '+298',
        code: CountryCode.FAROE_ISLANDS,
        name_fr: 'Îles Féroé',
    },
    {
        name: 'Fiji',
        dial_code: '+679',
        code: CountryCode.FIJI,
        name_fr: 'Fidji',
    },
    {
        name: 'Finland',
        dial_code: '+358',
        code: CountryCode.FINLAND,
        name_fr: 'Finlande',
    },
    {
        name: 'France',
        dial_code: '+33',
        code: CountryCode.FRANCE,
        name_fr: 'France',
    },
    {
        name: 'French Guiana',
        dial_code: '+594',
        code: CountryCode.FRENCH_GUIANA,
        name_fr: 'Guyane française',
    },
    {
        name: 'French Polynesia',
        dial_code: '+689',
        code: CountryCode.FRENCH_POLYNESIA,
        name_fr: 'Polynésie Française',
    },
    {
        name: 'Gabon',
        dial_code: '+241',
        code: CountryCode.GABON,
        name_fr: 'Gabon',
    },
    {
        name: 'Gambia',
        dial_code: '+220',
        code: CountryCode.GAMBIA,
        name_fr: 'Gambie',
    },
    {
        name: 'Georgia',
        dial_code: '+995',
        code: CountryCode.GEORGIA,
        name_fr: 'Géorgie',
    },
    {
        name: 'Germany',
        dial_code: '+49',
        code: CountryCode.GERMANY,
        name_fr: 'Allemagne',
    },
    {
        name: 'Ghana',
        dial_code: '+233',
        code: CountryCode.GHANA,
        name_fr: 'Ghana',
    },
    {
        name: 'Gibraltar',
        dial_code: '+350',
        code: CountryCode.GIBRALTAR,
        name_fr: 'Gibraltar',
    },
    {
        name: 'Greece',
        dial_code: '+30',
        code: CountryCode.GREECE,
        name_fr: 'Grèce',
    },
    {
        name: 'Greenland',
        dial_code: '+299',
        code: CountryCode.GREENLAND,
        name_fr: 'Groenland',
    },
    {
        name: 'Grenada',
        dial_code: '+1 473',
        code: CountryCode.GRENADA,
        name_fr: 'Grenade',
    },
    {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: CountryCode.GUADELOUPE,
        name_fr: 'Guadeloupe',
    },
    {
        name: 'Guam',
        dial_code: '+1 671',
        code: CountryCode.GUAM,
        name_fr: 'Guam',
    },
    {
        name: 'Guatemala',
        dial_code: '+502',
        code: CountryCode.GUATEMALA,
        name_fr: 'Guatemala',
    },
    {
        name: 'Guinea',
        dial_code: '+224',
        code: CountryCode.GUINEA,
        name_fr: 'Guinée',
    },
    {
        name: 'Guinea-Bissau',
        dial_code: '+245',
        code: CountryCode.GUINEA_BISSAU,
        name_fr: 'Guinée-Bissau',
    },
    {
        name: 'Guyana',
        dial_code: '+595',
        code: CountryCode.GUYANA,
        name_fr: 'Guyana',
    },
    {
        name: 'Haiti',
        dial_code: '+509',
        code: CountryCode.HAITI,
        name_fr: 'Haïti',
    },
    {
        name: 'Honduras',
        dial_code: '+504',
        code: CountryCode.HONDURAS,
        name_fr: 'Honduras',
    },
    {
        name: 'Hungary',
        dial_code: '+36',
        code: CountryCode.HUNGARY,
        name_fr: 'Hongrie',
    },
    {
        name: 'Iceland',
        dial_code: '+354',
        code: CountryCode.ICELAND,
        name_fr: 'Islande',
    },
    {
        name: 'India',
        dial_code: '+91',
        code: CountryCode.INDIA,
        name_fr: 'Inde',
    },
    {
        name: 'Indonesia',
        dial_code: '+62',
        code: CountryCode.INDONESIA,
        name_fr: 'Indonésie',
    },
    {
        name: 'Iraq',
        dial_code: '+964',
        code: CountryCode.IRAQ,
        name_fr: 'Irak',
    },
    {
        name: 'Ireland',
        dial_code: '+353',
        code: CountryCode.IRELAND,
        name_fr: 'Irlande',
    },
    {
        name: 'Israel',
        dial_code: '+972',
        code: CountryCode.ISRAEL,
        name_fr: 'Israël',
    },
    {
        name: 'Italy',
        dial_code: '+39',
        code: CountryCode.ITALY,
        name_fr: 'Italie',
    },
    {
        name: 'Jamaica',
        dial_code: '+1 876',
        code: CountryCode.JAMAICA,
        name_fr: 'Jamaïque',
    },
    {
        name: 'Japan',
        dial_code: '+81',
        code: CountryCode.JAPAN,
        name_fr: 'Japon',
    },
    {
        name: 'Jordan',
        dial_code: '+962',
        code: CountryCode.JORDAN,
        name_fr: 'Jordanie',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+7 7',
        code: CountryCode.KAZAKHSTAN,
        name_fr: 'Kazakhstan',
    },
    {
        name: 'Kenya',
        dial_code: '+254',
        code: CountryCode.KENYA,
        name_fr: 'Kenya',
    },
    {
        name: 'Kiribati',
        dial_code: '+686',
        code: CountryCode.KIRIBATI,
        name_fr: 'Kiribati',
    },
    {
        name: 'Kuwait',
        dial_code: '+965',
        code: CountryCode.KUWAIT,
        name_fr: 'Koweït',
    },
    {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: CountryCode.KYRGYZSTAN,
        name_fr: 'Kirghizistan',
    },
    {
        name: 'Latvia',
        dial_code: '+371',
        code: CountryCode.LATVIA,
        name_fr: 'Lettonie',
    },
    {
        name: 'Lebanon',
        dial_code: '+961',
        code: CountryCode.LEBANON,
        name_fr: 'Liban',
    },
    {
        name: 'Lesotho',
        dial_code: '+266',
        code: CountryCode.LESOTHO,
        name_fr: 'Lesotho',
    },
    {
        name: 'Liberia',
        dial_code: '+231',
        code: CountryCode.LIBERIA,
        name_fr: 'Liberia',
    },
    {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: CountryCode.LIECHTENSTEIN,
        name_fr: 'Liechtenstein',
    },
    {
        name: 'Lithuania',
        dial_code: '+370',
        code: CountryCode.LITHUANIA,
        name_fr: 'Lituanie',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: CountryCode.LUXEMBOURG,
        name_fr: 'Luxembourg',
    },
    {
        name: 'Madagascar',
        dial_code: '+261',
        code: CountryCode.MADAGASCAR,
        name_fr: 'Madagascar',
    },
    {
        name: 'Malawi',
        dial_code: '+265',
        code: CountryCode.MALAWI,
        name_fr: 'Malawi',
    },
    {
        name: 'Malaysia',
        dial_code: '+60',
        code: CountryCode.MALAYSIA,
        name_fr: 'Malaisie',
    },
    {
        name: 'Maldives',
        dial_code: '+960',
        code: CountryCode.MALDIVES,
        name_fr: 'Maldives',
    },
    {
        name: 'Mali',
        dial_code: '+223',
        code: CountryCode.MALI,
        name_fr: 'Mali',
    },
    {
        name: 'Malta',
        dial_code: '+356',
        code: CountryCode.MALTA,
        name_fr: 'Malte',
    },
    {
        name: 'Marshall Islands',
        dial_code: '+692',
        code: CountryCode.MARSHALL_ISLANDS,
        name_fr: 'Îles Marshall',
    },
    {
        name: 'Martinique',
        dial_code: '+596',
        code: CountryCode.MARTINIQUE,
        name_fr: 'Martinique',
    },
    {
        name: 'Mauritania',
        dial_code: '+222',
        code: CountryCode.MAURITANIA,
        name_fr: 'Mauritanie',
    },
    {
        name: 'Mauritius',
        dial_code: '+230',
        code: CountryCode.MAURITIUS,
        name_fr: 'Île Maurice',
    },
    {
        name: 'Mayotte',
        dial_code: '+262',
        code: CountryCode.MAYOTTE,
        name_fr: 'Mayotte',
    },
    {
        name: 'Mexico',
        dial_code: '+52',
        code: CountryCode.MEXICO,
        name_fr: 'Mexique',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: CountryCode.MONACO,
        name_fr: 'Monaco',
    },
    {
        name: 'Mongolia',
        dial_code: '+976',
        code: CountryCode.MONGOLIA,
        name_fr: 'Mongolie',
    },
    {
        name: 'Montenegro',
        dial_code: '+382',
        code: CountryCode.MONTENEGRO,
        name_fr: 'Monténégro',
    },
    {
        name: 'Montserrat',
        dial_code: '+1664',
        code: CountryCode.MONTSERRAT,
        name_fr: 'Montserrat',
    },
    {
        name: 'Morocco',
        dial_code: '+212',
        code: CountryCode.MOROCCO,
        name_fr: 'Maroc',
    },
    {
        name: 'Myanmar',
        dial_code: '+95',
        code: CountryCode.MYANMAR,
        name_fr: 'Myanmar',
    },
    {
        name: 'Namibia',
        dial_code: '+264',
        code: CountryCode.NAMIBIA,
        name_fr: 'Namibie',
    },
    {
        name: 'Nauru',
        dial_code: '+674',
        code: CountryCode.NAURU,
        name_fr: 'Nauru',
    },
    {
        name: 'Nepal',
        dial_code: '+977',
        code: CountryCode.NEPAL,
        name_fr: 'Népal',
    },
    {
        name: 'Netherlands',
        dial_code: '+31',
        code: CountryCode.NETHERLANDS,
        name_fr: 'Pays-Bas',
    },
    {
        name: 'Netherlands Antilles',
        dial_code: '+599',
        code: CountryCode.NETHERLANDS_ANTILLES,
        name_fr: 'Antilles néerlandaises',
    },
    {
        name: 'New Caledonia',
        dial_code: '+687',
        code: CountryCode.NEW_CALEDONIA,
        name_fr: 'Nouvelle-Calédonie',
    },
    {
        name: 'New Zealand',
        dial_code: '+64',
        code: CountryCode.NEW_ZEALAND,
        name_fr: 'Nouvelle-Zélande',
    },
    {
        name: 'Nicaragua',
        dial_code: '+505',
        code: CountryCode.NICARAGUA,
        name_fr: 'Nicaragua',
    },
    {
        name: 'Niger',
        dial_code: '+227',
        code: CountryCode.NIGER,
        name_fr: 'Niger',
    },
    {
        name: 'Nigeria',
        dial_code: '+234',
        code: CountryCode.NIGERIA,
        name_fr: 'Nigéria',
    },
    {
        name: 'Niue',
        dial_code: '+683',
        code: CountryCode.NIUE,
        name_fr: 'Nioué',
    },
    {
        name: 'Norfolk Island',
        dial_code: '+672',
        code: CountryCode.NORFOLK_ISLAND,
        name_fr: 'Iles Norfolk',
    },
    {
        name: 'Northern Mariana Islands',
        dial_code: '+1 670',
        code: CountryCode.NORTHERN_MARIANA_ISLANDS,
        name_fr: 'Îles Mariannes du Nord',
    },
    {
        name: 'Norway',
        dial_code: '+47',
        code: CountryCode.NORWAY,
        name_fr: 'Norvège',
    },
    {
        name: 'Oman',
        dial_code: '+968',
        code: CountryCode.OMAN,
        name_fr: 'Oman',
    },
    {
        name: 'Pakistan',
        dial_code: '+92',
        code: CountryCode.PAKISTAN,
        name_fr: 'Pakistan',
    },
    {
        name: 'Palau',
        dial_code: '+680',
        code: CountryCode.PALAU,
        name_fr: 'Palaos',
    },
    {
        name: 'Panama',
        dial_code: '+507',
        code: CountryCode.PANAMA,
        name_fr: 'Panama',
    },
    {
        name: 'Papua New Guinea',
        dial_code: '+675',
        code: CountryCode.PAPUA_NEW_GUINEA,
        name_fr: 'Papouasie-Nouvelle-Guinée',
    },
    {
        name: 'Paraguay',
        dial_code: '+595',
        code: CountryCode.PARAGUAY,
        name_fr: 'Paraguay',
    },
    {
        name: 'Peru',
        dial_code: '+51',
        code: CountryCode.PERU,
        name_fr: 'Pérou',
    },
    {
        name: 'Philippines',
        dial_code: '+63',
        code: CountryCode.PHILIPPINES,
        name_fr: 'Philippines',
    },
    {
        name: 'Poland',
        dial_code: '+48',
        code: CountryCode.POLAND,
        name_fr: 'Pologne',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: CountryCode.PORTUGAL,
        name_fr: 'Portugal',
    },
    {
        name: 'Puerto Rico',
        dial_code: '+1 939',
        code: CountryCode.PUERTO_RICO,
        name_fr: 'Porto Rico',
    },
    {
        name: 'Qatar',
        dial_code: '+974',
        code: CountryCode.QATAR,
        name_fr: 'Qatar',
    },
    {
        name: 'Romania',
        dial_code: '+40',
        code: CountryCode.ROMANIA,
        name_fr: 'Roumanie',
    },
    {
        name: 'Rwanda',
        dial_code: '+250',
        code: CountryCode.RWANDA,
        name_fr: 'Rwanda',
    },
    {
        name: 'Samoa',
        dial_code: '+685',
        code: CountryCode.SAMOA,
        name_fr: 'Samoa',
    },
    {
        name: 'San Marino',
        dial_code: '+378',
        code: CountryCode.SAN_MARINO,
        name_fr: 'Saint-Marin',
    },
    {
        name: 'Saudi Arabia',
        dial_code: '+966',
        code: CountryCode.SAUDI_ARABIA,
        name_fr: 'Arabie saoudite',
    },
    {
        name: 'Senegal',
        dial_code: '+221',
        code: CountryCode.SENEGAL,
        name_fr: 'Sénégal',
    },
    {
        name: 'Serbia',
        dial_code: '+381',
        code: CountryCode.SERBIA,
        name_fr: 'Serbie',
    },
    {
        name: 'Seychelles',
        dial_code: '+248',
        code: CountryCode.SEYCHELLES,
        name_fr: 'Seychelles',
    },
    {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: CountryCode.SIERRA_LEONE,
        name_fr: 'Sierra Leone',
    },
    {
        name: 'Singapore',
        dial_code: '+65',
        code: CountryCode.SINGAPORE,
        name_fr: 'Singapour',
    },
    {
        name: 'Slovakia',
        dial_code: '+421',
        code: CountryCode.SLOVAKIA,
        name_fr: 'Slovaquie',
    },
    {
        name: 'Slovenia',
        dial_code: '+386',
        code: CountryCode.SLOVENIA,
        name_fr: 'Slovénie',
    },
    {
        name: 'Solomon Islands',
        dial_code: '+677',
        code: CountryCode.SOLOMON_ISLANDS,
        name_fr: 'Îles Salomon',
    },
    {
        name: 'South Africa',
        dial_code: '+27',
        code: CountryCode.SOUTH_AFRICA,
        name_fr: 'Afrique du Sud',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        dial_code: '+500',
        code: CountryCode.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
        name_fr: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    },
    {
        name: 'Spain',
        dial_code: '+34',
        code: CountryCode.SPAIN,
        name_fr: 'Espagne',
    },
    {
        name: 'Sri Lanka',
        dial_code: '+94',
        code: CountryCode.SRI_LANKA,
        name_fr: 'Sri Lanka',
    },
    {
        name: 'Sudan',
        dial_code: '+249',
        code: CountryCode.SUDAN,
        name_fr: 'Soudan',
    },
    {
        name: 'Suriname',
        dial_code: '+597',
        code: CountryCode.SURINAME,
        name_fr: 'Suriname',
    },
    {
        name: 'Swaziland',
        dial_code: '+268',
        code: CountryCode.SWAZILAND,
        name_fr: 'Swaziland',
    },
    {
        name: 'Sweden',
        dial_code: '+46',
        code: CountryCode.SWEDEN,
        name_fr: 'Suède',
    },
    {
        name: 'Switzerland',
        dial_code: '+41',
        code: CountryCode.SWITZERLAND,
        name_fr: 'Suisse',
    },
    {
        name: 'Tajikistan',
        dial_code: '+992',
        code: CountryCode.TAJIKISTAN,
        name_fr: 'Tadjikistan',
    },
    {
        name: 'Thailand',
        dial_code: '+66',
        code: CountryCode.THAILAND,
        name_fr: 'Thaïlande',
    },
    {
        name: 'Togo',
        dial_code: '+228',
        code: CountryCode.TOGO,
        name_fr: 'Togo',
    },
    {
        name: 'Tokelau',
        dial_code: '+690',
        code: CountryCode.TOKELAU,
        name_fr: 'Tokelau',
    },
    {
        name: 'Tonga',
        dial_code: '+676',
        code: CountryCode.TONGA,
        name_fr: 'Tonga',
    },
    {
        name: 'Trinidad and Tobago',
        dial_code: '+1 868',
        code: CountryCode.TRINIDAD_AND_TOBAGO,
        name_fr: 'Trinité-et-Tobago',
    },
    {
        name: 'Tunisia',
        dial_code: '+216',
        code: CountryCode.TUNISIA,
        name_fr: 'Tunisie',
    },
    {
        name: 'Turkey',
        dial_code: '+90',
        code: CountryCode.TURKEY,
        name_fr: 'Turquie',
    },
    {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: CountryCode.TURKMENISTAN,
        name_fr: 'Turkmenistan',
    },
    {
        name: 'Turks and Caicos Islands',
        dial_code: '+1 649',
        code: CountryCode.TURKS_AND_CAICOS_ISLANDS,
        name_fr: 'Îles Turks et Caïques',
    },
    {
        name: 'Tuvalu',
        dial_code: '+688',
        code: CountryCode.TUVALU,
        name_fr: 'Tuvalu',
    },
    {
        name: 'Uganda',
        dial_code: '+256',
        code: CountryCode.UGANDA,
        name_fr: 'Ouganda',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: CountryCode.UKRAINE,
        name_fr: 'Ukraine',
    },
    {
        name: 'United Arab Emirates',
        dial_code: '+971',
        code: CountryCode.UNITED_ARAB_EMIRATES,
        name_fr: 'Émirats Arabes Unis',
    },
    {
        name: 'United Kingdom',
        dial_code: '+44',
        code: CountryCode.UNITED_KINGDOM,
        name_fr: 'Grande-Bretagne',
    },
    {
        name: 'United States',
        dial_code: '+1',
        code: CountryCode.UNITED_STATES,
        name_fr: 'États-Unis',
    },
    {
        name: 'Uruguay',
        dial_code: '+598',
        code: CountryCode.URUGUAY,
        name_fr: 'Uruguay',
    },
    {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: CountryCode.UZBEKISTAN,
        name_fr: 'Ouzbékistan',
    },
    {
        name: 'Vanuatu',
        dial_code: '+678',
        code: CountryCode.VANUATU,
        name_fr: 'Vanuatu',
    },
    {
        name: 'Wallis and Futuna',
        dial_code: '+681',
        code: CountryCode.WALLIS_AND_FUTUNA,
        name_fr: 'Wallis et Futuna',
    },
    {
        name: 'Yemen',
        dial_code: '+967',
        code: CountryCode.YEMEN,
        name_fr: 'Yémen',
    },
    {
        name: 'Zambia',
        dial_code: '+260',
        code: CountryCode.ZAMBIA,
        name_fr: 'Zambie',
    },
    {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: CountryCode.ZIMBABWE,
        name_fr: 'Zimbabwe',
    },
    {
        name: 'land Islands',
        dial_code: '',
        code: CountryCode.LAND_ISLANDS,
        name_fr: 'ïles land',
    },
    {
        name: 'Antarctica',
        dial_code: null,
        code: CountryCode.ANTARCTICA,
        name_fr: 'Antarctique',
    },
    {
        name: 'Bolivia',
        dial_code: '+591',
        code: CountryCode.BOLIVIA,
        name_fr: 'Bolivie',
    },
    {
        name: 'Brunei Darussalam',
        dial_code: '+673',
        code: CountryCode.BRUNEI_DARUSSALAM,
        name_fr: 'Brunéi Darussalam',
    },
    {
        name: 'Cocos (Keeling) Islands',
        dial_code: '+61',
        code: CountryCode.COCOS_ISLANDS,
        name_fr: 'îles cocos',
    },
    {
        name: 'Democratic Republic of the Congo',
        dial_code: '+243',
        code: CountryCode.DEMOCRATIC_REPUBLIC_OF_THE_CONGO,
        name_fr: 'République démocratique du congo',
    },
    {
        name: "Cote d'Ivoire",
        dial_code: '+225',
        code: CountryCode.COTE_D_IVOIRE,
        name_fr: "Côte d'Ivoire",
    },
    {
        name: 'Falkland Islands (Malvinas)',
        dial_code: '+500',
        code: CountryCode.FALKLAND_ISLANDS,
        name_fr: 'îles falkland',
    },
    {
        name: 'Guernsey',
        dial_code: '+44',
        code: CountryCode.GUERNSEY,
        name_fr: 'Guernesey',
    },
    {
        name: 'Vatican',
        dial_code: '+379',
        code: CountryCode.VATICAN,
        name_fr: 'Vatican',
    },
    {
        name: 'Hong Kong',
        dial_code: '+852',
        code: CountryCode.HONG_KONG,
        name_fr: 'Hong Kong',
    },
    {
        name: 'Iran',
        dial_code: '+98',
        code: CountryCode.IRAN,
        name_fr: 'Iran',
    },
    {
        name: 'Isle of Man',
        dial_code: '+44',
        code: CountryCode.ISLE_OF_MAN,
        name_fr: 'Île de Man',
    },
    {
        name: 'Jersey',
        dial_code: '+44',
        code: CountryCode.JERSEY,
        name_fr: 'Jersey',
    },
    {
        name: 'North Korea',
        dial_code: '+850',
        code: CountryCode.NORTH_KOREA,
        name_fr: 'Corée du nord',
    },
    {
        name: 'South Korea',
        dial_code: '+82',
        code: CountryCode.SOUTH_AFRICA,
        name_fr: 'Corée du sud',
    },
    {
        name: 'Laos',
        dial_code: '+856',
        code: CountryCode.LAOS,
        name_fr: 'Laos',
    },
    {
        name: 'Libya',
        dial_code: '+218',
        code: CountryCode.LIBYA,
        name_fr: 'Libye',
    },
    {
        name: 'Macao',
        dial_code: '+853',
        code: CountryCode.MACAO,
        name_fr: 'Macao',
    },
    {
        name: 'Macedonia',
        dial_code: '+389',
        code: CountryCode.MACEDONIA,
        name_fr: 'Macédoine',
    },
    {
        name: 'Micronesia',
        dial_code: '+691',
        code: CountryCode.MICRONESIA,
        name_fr: 'Micronésie',
    },
    {
        name: 'Moldova',
        dial_code: '+373',
        code: CountryCode.MOLDOVA,
        name_fr: 'Moldavie',
    },
    {
        name: 'Mozambique',
        dial_code: '+258',
        code: CountryCode.MOZAMBIQUE,
        name_fr: 'Mozambique',
    },
    {
        name: 'Palestinian',
        dial_code: '+970',
        code: CountryCode.PALESTINIAN,
        name_fr: 'Palestine',
    },
    {
        name: 'Pitcairn',
        dial_code: '+872',
        code: CountryCode.PITCAIRN,
        name_fr: 'Îles Pitcairn',
    },
    {
        name: 'Réunion',
        dial_code: '+262',
        code: CountryCode.RÉUNION,
        name_fr: 'Réunion',
    },
    {
        name: 'Russia',
        dial_code: '+7',
        code: CountryCode.RUSSIA,
        name_fr: 'Russie',
    },
    {
        name: 'Saint Barthélemy',
        dial_code: '+590',
        code: CountryCode.SAINT_BARTHÉLEMY,
        name_fr: 'Saint Barthélemy',
    },
    {
        name: 'Saint Helena',
        dial_code: '+290',
        code: CountryCode.SAINT_HELENA,
        name_fr: 'Sainte-Hélène',
    },
    {
        name: 'Saint Kitts and Nevis',
        dial_code: '+1 869',
        code: CountryCode.SAINT_KITTS_AND_NEVIS,
        name_fr: 'Saint-Christophe-et-Niévès',
    },
    {
        name: 'Saint Lucia',
        dial_code: '+1 758',
        code: CountryCode.SAINT_LUCIA,
        name_fr: 'Sainte-Lucie',
    },
    {
        name: 'Saint Martin',
        dial_code: '+590',
        code: CountryCode.SAINT_MARTIN,
        name_fr: 'Saint-Martin',
    },
    {
        name: 'Saint Pierre and Miquelon',
        dial_code: '+508',
        code: CountryCode.SAINT_PIERRE_AND_MIQUELON,
        name_fr: 'Saint-Pierre et Miquelon',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dial_code: '+1 784',
        code: CountryCode.SAINT_VINCENT_AND_THE_GRENADINES,
        name_fr: 'Saint-Vincent-et-les Grenadines',
    },
    {
        name: 'Sao Tome and Principe',
        dial_code: '+239',
        code: CountryCode.SAO_TOME_AND_PRINCIPE,
        name_fr: 'Sao Tomé-et-Principe',
    },
    {
        name: 'Somalia',
        dial_code: '+252',
        code: CountryCode.SOMALIA,
        name_fr: 'Somalie',
    },
    {
        name: 'Svalbard and Jan Mayen',
        dial_code: '+47',
        code: CountryCode.SVALBARD_AND_JAN_MAYEN,
        name_fr: 'Svalbard et Jan Mayen',
    },
    {
        name: 'Syria',
        dial_code: '+963',
        code: CountryCode.SYRIA,
        name_fr: 'Syrie',
    },
    {
        name: 'Taiwan',
        dial_code: '+886',
        code: CountryCode.TAIWAN,
        name_fr: 'Taiwan',
    },
    {
        name: 'Tanzania',
        dial_code: '+255',
        code: CountryCode.TANZANIA,
        name_fr: 'Tanzanie',
    },
    {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: CountryCode.TIMOR_LESTE,
        name_fr: 'Timor-Leste',
    },
    {
        name: 'Venezuela',
        dial_code: '+58',
        code: CountryCode.VENEZUELA,
        name_fr: 'Venezuela',
    },
    {
        name: 'Vietnam',
        dial_code: '+84',
        code: CountryCode.VIETNAM,
        name_fr: 'Vietnam',
    },
    {
        name: 'Virgin Islands, British',
        dial_code: '+1 284',
        code: CountryCode.VIRGIN_ISLANDS_BRITISH,
        name_fr: 'îles vierges (british)',
    },
    {
        name: 'Virgin Islands, U.S.',
        dial_code: '+1 340',
        code: CountryCode.VIRGIN_ISLANDS_US,
        name_fr: 'îles vierges (us)',
    },
];

export const COUNTRY_CODES = Object.values(CountryCode);

export type LowerCaseCountryCode = Lowercase<CountryCode>;

export const countryCodeWithAllOption: string[] = (COUNTRY_CODES as string[]).concat([allCountries]);

export const usRegionsWithAbbreviation = [
    { regionName: 'Alabama', abbreviation: 'AL' },
    { regionName: 'Alaska', abbreviation: 'AK' },
    { regionName: 'Alberta', abbreviation: 'AB' },
    { regionName: 'American Samoa', abbreviation: 'AS' },
    { regionName: 'Arizona', abbreviation: 'AZ' },
    { regionName: 'Arkansas', abbreviation: 'AR' },
    { regionName: 'Armed Forces (AE)', abbreviation: 'AE' },
    { regionName: 'Armed Forces Americas', abbreviation: 'AA' },
    { regionName: 'Armed Forces Pacific', abbreviation: 'AP' },
    { regionName: 'British Columbia', abbreviation: 'BC' },
    { regionName: 'California', abbreviation: 'CA' },
    { regionName: 'Colorado', abbreviation: 'CO' },
    { regionName: 'Connecticut', abbreviation: 'CT' },
    { regionName: 'Delaware', abbreviation: 'DE' },
    { regionName: 'District Of Columbia', abbreviation: 'DC' },
    { regionName: 'Florida', abbreviation: 'FL' },
    { regionName: 'Georgia', abbreviation: 'GA' },
    { regionName: 'Guam', abbreviation: 'GU' },
    { regionName: 'Hawaii', abbreviation: 'HI' },
    { regionName: 'Idaho', abbreviation: 'ID' },
    { regionName: 'Illinois', abbreviation: 'IL' },
    { regionName: 'Indiana', abbreviation: 'IN' },
    { regionName: 'Iowa', abbreviation: 'IA' },
    { regionName: 'Kansas', abbreviation: 'KS' },
    { regionName: 'Kentucky', abbreviation: 'KY' },
    { regionName: 'Louisiana', abbreviation: 'LA' },
    { regionName: 'Maine', abbreviation: 'ME' },
    { regionName: 'Manitoba', abbreviation: 'MB' },
    { regionName: 'Maryland', abbreviation: 'MD' },
    { regionName: 'Massachusetts', abbreviation: 'MA' },
    { regionName: 'Michigan', abbreviation: 'MI' },
    { regionName: 'Minnesota', abbreviation: 'MN' },
    { regionName: 'Mississippi', abbreviation: 'MS' },
    { regionName: 'Missouri', abbreviation: 'MO' },
    { regionName: 'Montana', abbreviation: 'MT' },
    { regionName: 'Nebraska', abbreviation: 'NE' },
    { regionName: 'Nevada', abbreviation: 'NV' },
    { regionName: 'New Brunswick', abbreviation: 'NB' },
    { regionName: 'New Hampshire', abbreviation: 'NH' },
    { regionName: 'New Jersey', abbreviation: 'NJ' },
    { regionName: 'New Mexico', abbreviation: 'NM' },
    { regionName: 'New York', abbreviation: 'NY' },
    { regionName: 'Newfoundland', abbreviation: 'NF' },
    { regionName: 'North Carolina', abbreviation: 'NC' },
    { regionName: 'North Dakota', abbreviation: 'ND' },
    { regionName: 'Northwest Territories', abbreviation: 'NT' },
    { regionName: 'Nova Scotia', abbreviation: 'NS' },
    { regionName: 'Nunavut', abbreviation: 'NU' },
    { regionName: 'Ohio', abbreviation: 'OH' },
    { regionName: 'Oklahoma', abbreviation: 'OK' },
    { regionName: 'Ontario', abbreviation: 'ON' },
    { regionName: 'Oregon', abbreviation: 'OR' },
    { regionName: 'Pennsylvania', abbreviation: 'PA' },
    { regionName: 'Prince Edward Island', abbreviation: 'PE' },
    { regionName: 'Puerto Rico', abbreviation: 'PR' },
    { regionName: 'Quebec', abbreviation: 'PQ' },
    { regionName: 'Rhode Island', abbreviation: 'RI' },
    { regionName: 'Saskatchewan', abbreviation: 'SK' },
    { regionName: 'South Carolina', abbreviation: 'SC' },
    { regionName: 'South Dakota', abbreviation: 'SD' },
    { regionName: 'Tennessee', abbreviation: 'TN' },
    { regionName: 'Texas', abbreviation: 'TX' },
    { regionName: 'Utah', abbreviation: 'UT' },
    { regionName: 'Vermont', abbreviation: 'VT' },
    { regionName: 'Virgin Islands', abbreviation: 'VI' },
    { regionName: 'Virginia', abbreviation: 'VA' },
    { regionName: 'Washington', abbreviation: 'WA' },
    { regionName: 'West Virginia', abbreviation: 'WV' },
    { regionName: 'Wisconsin', abbreviation: 'WI' },
    { regionName: 'Wyoming', abbreviation: 'WY' },
    { regionName: 'Yukon Territory', abbreviation: 'YT' },
];
