import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-email-verification-success',
    templateUrl: './email-verification-success.component.html',
    styleUrls: ['./email-verification-success.component.scss'],
    standalone: true,
    imports: [IllustrationPathResolverPipe, TranslateModule],
})
export class EmailVerificationSuccessComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
