@if (isFooterVisible$ | async) {
    <div>
        @if (status !== SynchronizationStatus.NOT_STARTED) {
            <div
                class="review-synchronization-footer__container malou-box-shadow close-animation flex items-center justify-end px-6"
                id="footer-review-synchronization">
                <ng-container [ngTemplateOutlet]="synchronizationStatusTemplate" [ngTemplateOutletContext]="{ status }"></ng-container>
                <ng-container [ngTemplateOutlet]="logosLoading" [ngTemplateOutletContext]="{ status }"></ng-container>
                <button class="malou-btn-icon ml-3" mat-icon-button (click)="close()">
                    <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        }
    </div>
}

<ng-template let-status="status" #synchronizationStatusTemplate>
    <div class="flex items-center">
        <div class="mr-2 flex items-center">
            @switch (status) {
                @case (SynchronizationStatus.LOADING) {
                    <div class="flex items-center">
                        <mat-icon class="malou-spin mr-4" color="primary" [svgIcon]="SvgIcon.SPINNER"></mat-icon>
                        <span class="malou-text-12--bold">
                            {{
                                isAggregatedView
                                    ? ('reviews.synchronization_footer.synchronization_in_progress_of_your_restaurants' | translate)
                                    : ('reviews.synchronization_footer.synchronization_in_progress' | translate)
                            }}
                        </span>
                    </div>
                }
                @case (SynchronizationStatus.LOADED) {
                    <div class="malou-bg-state-success mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                        <mat-icon
                            class="!h-[9px] !w-[9px] text-white"
                            matTooltip="{{ 'reviews.synchronization_footer.synchronization_finished' | translate }}"
                            [svgIcon]="SvgIcon.CHECK">
                        </mat-icon>
                    </div>
                    <span class="malou-text-12--bold">
                        {{ 'reviews.synchronization_footer.synchronization_finished' | translate }}
                    </span>
                }
                @case (SynchronizationStatus.ERROR) {
                    <div class="malou-bg-state-error mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                        <mat-icon
                            class="!h-[9px] !w-[9px] text-white"
                            matTooltip="{{ 'reviews.synchronization_footer.synchronization_failed' | translate }}"
                            [svgIcon]="SvgIcon.CROSS">
                        </mat-icon>
                    </div>
                    <span class="malou-text-12--bold">
                        {{ 'reviews.synchronization_footer.synchronization_failed' | translate }}
                    </span>
                }
            }
        </div>
    </div>
</ng-template>

<ng-template let-status="status" #logosLoading>
    <div class="flex justify-evenly" data-testid="review-sync-footer-container">
        @for (platform of platformsWithStatus; track platform) {
            <div class="relative mx-1">
                <div class="icon-img">
                    <app-platform-logo imgClasses="h-8 w-8" [logo]="platform.key"></app-platform-logo>
                    <div class="svg-container" [matTooltip]="fetchStates?.[platform.key]! | reviewStatusTooltip">
                        <svg class="circle">
                            <g>
                                <ellipse
                                    class="foreground"
                                    ry="17"
                                    rx="17"
                                    stroke-width="35"
                                    [ngClass]="{
                                        loading:
                                            !(['success', 'error'] | includes: fetchStates?.[platform.key]?.status) ||
                                            (status === SynchronizationStatus.LOADING && !fetchStates?.[platform.key]),
                                        loaded: ['success', 'error'] | includes: fetchStates?.[platform.key]?.status,
                                    }" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div
                    class="absolute bottom-0 right-0"
                    [ngStyle]="{ visibility: (platform | applySelfPure: 'isConnected') ? 'visible' : 'invisible' }">
                    @if (platform | applySelfPure: 'isConnected') {
                        <div>
                            @if ([SynchronizationStatus.LOADING, SynchronizationStatus.LOADED] | includes: status) {
                                @switch (fetchStates?.[platform.key]?.status) {
                                    @case (PlatformDataFetchedStatus.SUCCESS) {
                                        <div class="malou-bg-state-success grid h-[15px] w-[15px] place-items-center rounded-full">
                                            <mat-icon
                                                class="!h-[9px] !w-[9px] text-white"
                                                data-testid="review-sync-footer-platform-checked"
                                                [svgIcon]="SvgIcon.CHECK"></mat-icon>
                                        </div>
                                    }
                                    @case (PlatformDataFetchedStatus.ERROR) {
                                        <div class="malou-bg-state-error grid h-[15px] w-[15px] place-items-center rounded-full">
                                            <mat-icon
                                                class="!h-[9px] !w-[9px] text-white"
                                                matTooltip="{{ clarifyError | applyPure: (fetchStates?.[platform.key])!.error }}"
                                                [svgIcon]="SvgIcon.CROSS">
                                            </mat-icon>
                                        </div>
                                    }
                                    @case (PlatformDataFetchedStatus.ASYNC) {
                                        <ng-container [ngTemplateOutlet]="loadingIconTemplate"></ng-container>
                                    }
                                    @case (PlatformDataFetchedStatus.PENDING) {
                                        <ng-container [ngTemplateOutlet]="loadingIconTemplate"></ng-container>
                                    }
                                    @default {
                                        <ng-container [ngTemplateOutlet]="loadingIconTemplate"></ng-container>
                                    }
                                }
                            }
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #loadingIconTemplate>
    <div class="malou-bg-state-warn grid h-[15px] w-[15px] place-items-center rounded-full">
        <mat-icon class="!h-[9px] !w-[9px] text-white" [svgIcon]="SvgIcon.ALARM"></mat-icon>
    </div>
</ng-template>
