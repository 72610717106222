// See https://developers.tiktok.com/doc/content-posting-api-reference-query-creator-info for more info
export enum TiktokPrivacyStatus {
    // For public accounts
    PUBLIC_TO_EVERYONE = 'PUBLIC_TO_EVERYONE',

    // For private accounts
    FOLLOWER_OF_CREATOR = 'FOLLOWER_OF_CREATOR',

    // For both public and private accounts
    MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
    SELF_ONLY = 'SELF_ONLY',
}

export enum TiktokPostPublishFailedReason {
    // Unsupported media format
    FILE_FORMAT_CHECK_FAILED = 'file_format_check_failed',
    // Video does not meet our duration restrictions
    DURATION_CHECK_FAILED = 'duration_check_failed',
    // Unsupported frame rate
    FRAME_RATE_CHECK_FAILED = 'frame_rate_check_failed',
    // Upsupported picture size
    PICTURE_SIZE_CHECK_FAILED = 'picture_size_check_failed',
    // Some parts of the TikTok server may currently be unavailable. This is a retryable error
    INTERNAL = 'internal',
    // The TikTok server encountered a connection error while downloading the specified video resource, or the download is terminated since it can not be completed within the one-hour timeout.
    // Check if the supplied URL is publicly accessible or bandwidth-limited. If developers are certain that the URL is valid, a retry is recommended.
    VIDEO_PULL_FAILED = 'video_pull_failed',
    // The TikTok server encountered a connection error while downloading the specified photo resource, or the download is terminated since it can not be completed within the one-hour timeout.
    // Check if the supplied URL is publicly accessible or bandwidth-limited. If developers are certain that the URL is valid, a retry is recommended.
    PHOTO_PULL_FAILED = 'photo_pull_failed',
    // Developers can cancel an ongoing download. After a successful cancellation, developers will receive a webhook containing this error reason.
    PUBLISH_CANCELLED = 'publish_cancelled',
    // This TikTok creator has removed the developer's access while the download/uploading is being processed, so the publishing effort must be terminated. Retry should not be done.
    AUTH_REMOVED = 'auth_removed',
    // This TikTok creator has created too many posts within the last 24 hours via OpenAPI.
    SPAM_RISK_TOO_MANY_POSTS = 'spam_risk_too_many_posts',
    // TikTok TnS team has banned the creator from making new posts. Retry should not be done.
    SPAM_RISK_USER_BANNED_FROM_POSTING = 'spam_risk_user_banned_from_posting',
    // TikTok TnS team determines that the description text has risky or spammy contents, so the publishing attempt is terminated. Retry should not be done.
    SPAM_RISK_TEXT = 'spam_risk_text',
    // TikTok TnS team determines the publishing request is risky, so the publishing attempt is terminated. Retry should not be done.
    SPAM_RISK = 'spam_risk',
}
