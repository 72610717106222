import { Location, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, forkJoin, map, switchMap, tap } from 'rxjs';

import { RoiSavedTimeDto } from '@malou-io/package-dto';
import { HeapEventName, isNotNil, PlatformKey, RoiPageVisibilityStatus } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { RoiContext } from ':modules/roi/roi.context';
import { RoiService } from ':modules/roi/roi.service';
import { SavedTimeDetailsModalComponent } from ':modules/roi/saved-time-details-modal/saved-time-details-modal.component';
import * as statisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { MillisecondsToHourMinutePipe } from ':shared/pipes/milliseconds-to-hour-minute.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-saved-time',
    templateUrl: './saved-time.component.html',
    styleUrls: ['./saved-time.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EmojiPathResolverPipe,
        MillisecondsToHourMinutePipe,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        NgTemplateOutlet,
        SkeletonComponent,
        NgClass,
    ],
})
export class SavedTimeComponent implements OnInit {
    readonly isParentLoading = input.required<boolean>();
    readonly isBeforeLimitDateToShowRoi = input.required<boolean>();

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _roiService = inject(RoiService);
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _platformService = inject(PlatformsService);
    private readonly _location = inject(Location);
    private readonly _heapService = inject(HeapService);
    readonly roiContext = inject(RoiContext);
    readonly isSavedTimeLoading = signal(false);

    readonly isGmbConnected$ = this._platformService.isPlatformConnected$(PlatformKey.GMB);

    readonly PlatformKey = PlatformKey;

    readonly isLoading: WritableSignal<boolean> = signal(false);

    readonly roiSavedTime: WritableSignal<RoiSavedTimeDto | null> = signal(null);
    readonly previousPeriodRoiSavedTime: WritableSignal<RoiSavedTimeDto | null> = signal(null);

    readonly selectedTimeScaleFilter$ = this._store.select(statisticsSelectors.selectTimeScaleFilter);
    readonly selectedMonths$ = this.selectedTimeScaleFilter$.pipe(
        map((timeScaleFilter) => getSelectedMonthsNumberFromTimeScaleFilter(timeScaleFilter))
    );
    readonly selectedMonths: Signal<number> = toSignal(this.selectedMonths$, {
        initialValue: getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS),
    });

    ngOnInit(): void {
        this._initRoiSavedTime();
    }

    openDetails(): void {
        const currentUrl = this._location.path();
        if (currentUrl.includes('groups')) {
            this._heapService.track(HeapEventName.TRACKING_CHECK_GAINED_TIME_DETAILS_MULTI_RESTAURANTS);
        } else {
            this._heapService.track(HeapEventName.TRACKING_CHECK_GAINED_TIME_DETAILS_SINGLE_RESTAURANT);
        }
        this._customDialogService.open(
            SavedTimeDetailsModalComponent,
            {
                height: 'unset',
                maxHeight: '90vh',
                data: {
                    currentData: this.roiSavedTime(),
                    previousData: this.previousPeriodRoiSavedTime(),
                    isAggregatedView: false,
                },
            },
            { closeOnOutsideClick: true }
        );
    }

    private _initRoiSavedTime(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.selectedTimeScaleFilter$])
            .pipe(
                tap(() => this.isSavedTimeLoading.set(true)),
                filter(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined]) => isNotNil(restaurant)),
                switchMap(([restaurant, _]: [Restaurant, MalouTimeScalePeriod | undefined]) =>
                    forkJoin([
                        this._roiService.getSavedTimeForNLastMonths(restaurant?._id, this.selectedMonths()),
                        this._roiService.getSavedTimeForNLastMonths(restaurant?._id, this.selectedMonths(), {
                            previousPeriod: true,
                            visibility: this.isBeforeLimitDateToShowRoi() ? RoiPageVisibilityStatus.PARTIAL : RoiPageVisibilityStatus.FULL,
                        }),
                    ])
                )
            )
            .subscribe({
                next: ([currentData, previousData]) => {
                    if (currentData.data) {
                        this.roiSavedTime.set(currentData.data);
                    }
                    if (previousData.data) {
                        this.previousPeriodRoiSavedTime.set(previousData.data);
                    }
                    this.isSavedTimeLoading.set(false);
                },
                error: (err) => {
                    this.isSavedTimeLoading.set(false);
                    console.error(err);
                },
            });
    }
}
