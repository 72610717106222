import { z } from 'zod';

import { lightRestaurantValidator } from '../restaurant';
import { objectIdValidator } from '../utils/validators';

export const giftWithoutIdDtoValidator = z.object({
    name: z.string(),
    weight: z.number(),
    stocks: z.array(
        z.object({
            quantity: z.number().nullable(),
            restaurant: lightRestaurantValidator,
        })
    ),
    conditions: z
        .object({
            fr: z.string().nullish(),
            en: z.string().nullish(),
            es: z.string().nullish(),
            it: z.string().nullish(),
        })
        .nullish(),
});

export type GiftWithoutIdDto = z.infer<typeof giftWithoutIdDtoValidator>;

export const giftWithIdDtoValidator = z.object({
    id: objectIdValidator.optional(),
    ...giftWithoutIdDtoValidator.shape,
});

export type GiftWithIdDto = z.infer<typeof giftWithIdDtoValidator>;
