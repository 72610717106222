<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <div class="flex flex-col">
            <span>
                {{ 'informations.state_of_updates' | translate }}
            </span>
            @if (isAboutToSendUpdates()) {
                <span class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ 'informations.pending_updates' | translate }}
                </span>
            } @else {
                <span class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ 'informations.infos_updates_state_last_time' | translate: { date: latestValidatedAt() | formatDate: 'long' } }}
                </span>
            }
        </div>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body !m-0 flex h-auto flex-col gap-8 overflow-x-hidden !px-8 pb-8">
        @if (errorUpdateCount(); as nbFailedUpdates) {
            <app-information-update-errors-list
                [nbFailedUpdates]="nbFailedUpdates"
                [informationUpdateErrors]="data.informationUpdateErrors"
                (close)="close()"></app-information-update-errors-list>
        }

        @if (otherUpdateCount(); as nbSucceedUpdates) {
            <app-information-update-others-list
                [nbSucceedUpdates]="nbSucceedUpdates"
                [informationUpdateOthers]="data.informationUpdateOthers"
                [isAboutToSendUpdates]="isAboutToSendUpdates()"></app-information-update-others-list>
        }
    </div>
</div>
