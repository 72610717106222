<div class="flex flex-col gap-3 rounded border border-malou-color-border-primary p-5">
    <div class="flex gap-3">
        <div class="flex flex-1 flex-col">
            <span class="malou-text-12--bold text-malou-color-text-1">{{ panelTitle() }}</span>
            <span class="malou-text-10 italic text-malou-color-text-2">{{ explanation() }} </span>
        </div>
        <div class="flex gap-2">
            <span class="malou-text-12--bold text-malou-color-text-1"
                >{{ currentCustomerRange()[0] }} - {{ currentCustomerRange()[1] }}</span
            >
            @if (previousCustomersEvolution()) {
                <app-number-evolution
                    size="xs"
                    [value]="previousCustomersEvolution()"
                    [displayedValue]="
                        previousCustomersEvolution() | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                    "></app-number-evolution>
            }
        </div>
    </div>

    @for (item of customerItems(); track item) {
        <div class="flex w-full gap-2 rounded border border-malou-color-border-primary bg-white p-1 sm:h-20">
            <div class="flex h-9 w-9 items-center justify-center rounded bg-malou-color-background-darker">
                <img class="m-3 h-4" [src]="item.emoji | roiCustomersPathResolver" />
            </div>
            <div class="flex flex-1 items-center gap-2">
                <span class="malou-text-12--bold text-malou-color-text-1">{{ item.title }}</span>
                @if (item.subtitle) {
                    <span class="malou-text-12 italic text-malou-color-text-1">{{ item.subtitle }}</span>
                }
                <span class="malou-text-10 italic text-malou-color-text-2">{{ item.details }}</span>
            </div>
            <div class="mr-2 flex items-center">
                @if (!item.clickValue) {
                    <span class="malou-text-12--bold text-malou-color-text-1">0 {{ 'roi.client' | translate }}</span>
                } @else {
                    <span class="malou-text-12--bold text-malou-color-text-1"
                        >{{ item.minCustomers }} - {{ item.maxCustomers }} {{ 'roi.clients' | translate }}</span
                    >
                }
            </div>
        </div>
    }
</div>
