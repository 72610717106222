<div class="flex justify-between gap-1 rounded-b-lg bg-[#152228] px-4 py-2 xl:flex-col">
    <div class="flex min-w-0 grow items-center gap-x-2">
        <div class="flex min-w-0 grow flex-col">
            <div class="malou-text-11--semibold truncate text-white">{{ 'send_ubereats_offer.send_offer' | translate }}</div>
            @if (showOrderInfo()) {
                @let ordersCountValue = ordersCount();
                @let ordinalDescription =
                    ordersCountValue
                        ? ('send_ubereats_offer.ordinal_description' | translate: { ordinal: ordersCountValue | toOrdinal: 'feminine' })
                        : '';
                @let description = total() + ordinalDescription;
                <div class="malou-text-12--regular truncate italic text-[#b9bdbf]" [matTooltip]="description">
                    {{ description }}
                </div>
            }
        </div>
    </div>
    <div class="flex gap-x-2">
        <app-button
            buttonClasses="!min-w-0 !px-2"
            [theme]="selectedValue() === UbereatsPromotionValue.NONE ? 'primary' : 'secondary'"
            [text]="'send_ubereats_offer.none' | translate"
            (buttonClick)="onSelect(UbereatsPromotionValue.NONE)"></app-button>
        <app-button
            buttonClasses="!min-w-0 !px-2"
            [theme]="selectedValue() === UbereatsPromotionValue.TIER_1 ? 'primary' : 'secondary'"
            [text]="offers()[UbereatsPromotionValue.TIER_1]"
            (buttonClick)="onSelect(UbereatsPromotionValue.TIER_1)"></app-button>
        <app-button
            buttonClasses="!min-w-0 !px-2"
            [theme]="selectedValue() === UbereatsPromotionValue.TIER_2 ? 'primary' : 'secondary'"
            [text]="offers()[UbereatsPromotionValue.TIER_2]"
            (buttonClick)="onSelect(UbereatsPromotionValue.TIER_2)"></app-button>
        <app-button
            buttonClasses="!min-w-0 !px-2"
            [theme]="selectedValue() === UbereatsPromotionValue.TIER_3 ? 'primary' : 'secondary'"
            [text]="offers()[UbereatsPromotionValue.TIER_3]"
            (buttonClick)="onSelect(UbereatsPromotionValue.TIER_3)"></app-button>
    </div>
</div>
