<div class="malou-auth flex h-full w-full">
    <div class="malou-color-bg-white auth-container flex items-center justify-center">
        <div class="only-mobile flex w-full justify-end">
            <img class="malou-logo" alt="MalouApp" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="auth-form">
            <mat-divider class="mb-2 mt-2"></mat-divider>
            <h1>Nouveau !</h1>
            <p>La MalouApp change d'adresse : <a class="malou-text--large" href="https://app.malou.io">app.malou.io</a></p>
            <mat-divider class="mb-2 mt-2"></mat-divider>
        </div>
        <div class="only-mobile"></div>
    </div>
    <div class="only-web items-center justify-center" style="width: 40%">
        <div class="flex h-full flex-col items-center justify-between">
            <div class="flex w-full justify-end">
                <img class="malou-logo" alt="MalouApp" [src]="'malou_logo' | imagePathResolver" />
            </div>
            <img class="auth-image" alt="login image" [src]="'login' | imagePathResolver" />
            <div class="malou-logo"></div>
        </div>
    </div>
</div>
