import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { GalleryFilters, galleryMediaType } from ':shared/models';

import * as GalleryActions from './gallery.actions';

export interface GalleryState {
    filters: GalleryFilters;
}

export const initialState: GalleryState = {
    filters: {
        sortOrder: -1,
        mediaType: galleryMediaType.ALL,
        isNeverUsed: false,
        title: '',
    },
};

const galleryReducer = createReducer(
    initialState,
    on(GalleryActions.editGalleryFilters, (state, { filters }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...filters,
        },
    })),
    on(GalleryActions.editGallerySortOrder, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            sortOrder: state.filters.sortOrder ? state.filters.sortOrder * -1 : undefined,
        },
    })),
    on(GalleryActions.resetGalleryFilters, () => initialState)
);

export const selectGalleryState = createFeatureSelector<GalleryState>('gallery');

export const selectGalleryFilters = createSelector(selectGalleryState, (state: GalleryState) => state.filters);

export function reducer(state: GalleryState | undefined, action: Action): GalleryState {
    return galleryReducer(state, action);
}
