import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'xor',
    standalone: true,
})
export class XorPipe implements PipeTransform {
    transform(bool1: boolean, bool2: boolean): boolean {
        return (bool1 && !bool2) || (!bool1 && bool2);
    }
}
