import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewArchiveTooltip',
    standalone: true,
})
export class ReviewArchiveTooltipPipe implements PipeTransform {
    constructor(private _translate: TranslateService) {}

    transform(review: Review | PrivateReview): string {
        return this._getArchiveTooltipText(review);
    }

    private _getArchiveTooltipText(review: Review | PrivateReview): string {
        return review.archived ? this._translate.instant('reviews.unarchive') : this._translate.instant('reviews.archive');
    }
}
