import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { LocalStorage } from ':core/storage/local-storage';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { InputTextTheme } from ':shared/components/input-text/input-text.interface';
import { SelectOpenHoursComponent } from ':shared/components/select-open-hours/select-open-hours.component';
import { Period, SpecialDatePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-special-period-form',
    templateUrl: './special-period-form.component.html',
    styleUrls: ['./special-period-form.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        InputDatePickerComponent,
        InputTextComponent,
        SelectOpenHoursComponent,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialPeriodFormComponent {
    readonly specialDatePeriod = input.required<SpecialDatePeriod>();
    readonly disabled = input<boolean>(false);
    readonly canDuplicate = input.required<boolean>();
    readonly startDateChange = output<Date | null>();
    readonly endDateChange = output<Date | null>();
    readonly delete = output<void>();
    readonly duplicate = output<void>();
    readonly timePeriodsChange = output<Period[]>();
    readonly isClosedChange = output<boolean>();
    readonly nameChange = output<string>();

    readonly SvgIcon = SvgIcon;
    readonly InputTextTheme = InputTextTheme;

    readonly TODAY = DateTime.now().startOf('day').toJSDate();
    readonly maxStartDate = computed(() => {
        const endDate = this.specialDatePeriod().endDate;
        return endDate ? endDate.getDate() : undefined;
    });
    readonly minEndDate = computed(() => {
        const startDate = this.specialDatePeriod().startDate;
        return startDate ? startDate.getDate() : this.TODAY;
    });

    readonly newSpecialPeriodName = signal('');
    readonly isEditingName = signal(false);

    readonly LANG = LocalStorage.getLang();

    updateSpecialPeriodStartDate(date: Date | null): void {
        this.startDateChange.emit(date);
    }

    updateSpecialPeriodEndDate(date: Date | null): void {
        this.endDateChange.emit(date);
    }

    deleteSpecialPeriod(): void {
        this.delete.emit();
    }

    duplicateSpecialPeriod(): void {
        this.duplicate.emit();
    }

    updatePeriod(periods: Period[]): void {
        if (periods.length === 0 || this.disabled()) {
            return;
        }
        this.timePeriodsChange.emit(periods);
    }

    updateIsClosed(isClosed: boolean): void {
        if (this.disabled()) {
            return;
        }
        this.isClosedChange.emit(isClosed);
    }

    updateSpecialPeriodName(): void {
        this.nameChange.emit(this.newSpecialPeriodName());
        this.isEditingName.set(false);
    }

    editSpecialPeriodName(): void {
        this.newSpecialPeriodName.set(this.specialDatePeriod().name ?? '');
        this.isEditingName.set(true);
    }

    cancelEditSpecialPeriodName(): void {
        this.isEditingName.set(false);
    }

    updateNewSpecialPeriodName(name: string): void {
        this.newSpecialPeriodName.set(name);
    }
}
