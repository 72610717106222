import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('slideIn', [
    state(
        '*',
        style({
            transform: 'translateX(0px)',
            opacity: 1,
        })
    ),
    state(
        'void',
        style({
            transform: 'translateX(100px)',
            opacity: 0,
        })
    ),
    transition('void => *', animate('.5s ease-in-out')),
]);
