import { Routes } from '@angular/router';

import { AuthComponent } from ':core/auth/auth/auth.component';
import { ConfirmComponent } from ':core/auth/confirm/confirm.component';
import { ForgotPasswordConfirmComponent } from ':core/auth/forgot-password-confirm/forgot-password-confirm.component';
import { ForgotPasswordComponent } from ':core/auth/forgot-password/forgot-password.component';
import { LoginComponent } from ':core/auth/login/login.component';
import { NeedsConfirmComponent } from ':core/auth/needs-confirm/needs-confirm.component';
import { NewPasswordComponent } from ':core/auth/new-password/new-password.component';
import { EditUserMiscellaneousComponent } from ':modules/user/edit-user-miscellaneous/edit-user-miscellaneous.component';
import { UserResolver } from ':modules/user/user.resolver';

export const AUTH_ROUTES: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'password/forgot',
                component: ForgotPasswordComponent,
            },
            {
                path: 'password/confirm',
                component: ForgotPasswordConfirmComponent,
            },
            {
                path: 'password/new/:user_id/:token',
                component: NewPasswordComponent,
            },
            {
                path: 'needs-confirm',
                component: NeedsConfirmComponent,
            },
            {
                path: 'confirm/:uid/:token',
                component: ConfirmComponent,
            },
            {
                path: ':user_id/edit-misc',
                component: EditUserMiscellaneousComponent,
                resolve: { user: UserResolver },
                providers: [UserResolver],
            },
        ],
    },
];
