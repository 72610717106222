import { Action, createFeatureSelector, createReducer, createSelector, DefaultProjectorFn, MemoizedSelector, on } from '@ngrx/store';

import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { Platform } from ':shared/models';

import * as PlatformsActions from './platforms.actions';

export interface PlatformState {
    platformsData: { [restaurantId: string]: Platform[] };
    selectedRestaurantId: string;
}

export const initialState: PlatformState = {
    platformsData: {},
    selectedRestaurantId: '',
};

const platformsReducer = createReducer(
    initialState,
    on(PlatformsActions.editSelectedRestaurantId, (state, { restaurantId }) => ({
        ...state,
        selectedRestaurantId: restaurantId,
    })),
    on(PlatformsActions.editPlatformsData, (state, { platformsData }) => {
        const newPlatformsDataState = Object.assign({}, state.platformsData, platformsData);
        return { ...state, platformsData: newPlatformsDataState };
    }),
    on(PlatformsActions.editConnectedPlatforms, (state, { connectedPlatforms }) => ({
        ...state,
        connectedPlatforms,
    }))
);

export const selectPlatformsState = createFeatureSelector<PlatformState>('platforms');

export const selectRestaurantId = createSelector(selectPlatformsState, (state: PlatformState) => state.selectedRestaurantId);

export const selectPlatformsData = createSelector(selectPlatformsState, (state: PlatformState) => state.platformsData);

export const selectCurrentPlatforms = createSelector(
    selectRestaurantId,
    selectPlatformsData,
    (restaurantId, platformsData) => platformsData[restaurantId] ?? []
);

export const selectCurrentPlatformKeys = createSelector(selectCurrentPlatforms, (platforms: Platform[]): PlatformKey[] =>
    platforms.map((e) => e.key)
);

export const selectCurrentPlatform = (props: {
    platformKey: string;
}): MemoizedSelector<object, Platform | undefined, DefaultProjectorFn<Platform | undefined>> =>
    createSelector(selectCurrentPlatforms, (platforms: Platform[]) => {
        if (platforms?.length && props.platformKey) {
            return platforms.find((p) => p.key === props.platformKey);
        }
    });

export const selectOauthPlatforms = createSelector(selectPlatformsState, (_state: PlatformState) =>
    PlatformDefinitions.getPlatformsWithOauth()
);

export const selectFetchPermissionsPlatforms = createSelector(selectPlatformsState, (_state: PlatformState) =>
    PlatformDefinitions.getPlatformsWithShouldFetchPermissions()
);

export function reducer(state: PlatformState | undefined, action: Action): PlatformState {
    return platformsReducer(state, action);
}
