<div class="flex flex-col rounded-lg border border-malou-color-border-primary">
    <div
        class="flex items-center justify-between border-b border-malou-color-border-primary bg-malou-color-background-light py-2 pl-4 pr-2">
        @if (isEditingName()) {
            <div class="flex items-center gap-x-2">
                <app-input-text
                    class="w-96 sm:!w-32"
                    [defaultValue]="newSpecialPeriodName()"
                    [placeholder]="'informations.special_hours.name' | translate"
                    [disabled]="disabled()"
                    [theme]="InputTextTheme.UNDERLINE"
                    (inputTextChange)="updateNewSpecialPeriodName($event)">
                </app-input-text>
                <button
                    class="malou-btn-icon btn-sm !rounded-full !bg-malou-color-background-text-1"
                    mat-icon-button
                    (click)="cancelEditSpecialPeriodName()">
                    <mat-icon class="text-malou-color-text-white" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
                <button
                    class="malou-btn-icon btn-sm !rounded-full !bg-malou-color-background-success-dark"
                    mat-icon-button
                    (click)="updateSpecialPeriodName()">
                    <mat-icon class="text-malou-color-text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                </button>
            </div>
        } @else {
            <div class="rounded-t-lg">
                <span class="malou-text-14--semibold text-malou-color-text-1">
                    {{ specialDatePeriod().name }}
                </span>
                @if (specialDatePeriod().isFromCalendarEvent) {
                    -
                    <span class="malou-text-14--regular italic text-malou-color-text-2">
                        {{ specialDatePeriod() | applySelfPure: 'getDisplayStartDate' : LANG }}
                    </span>
                }
            </div>
        }

        <div>
            <button class="malou-btn-icon" mat-icon-button [matMenuTriggerFor]="actionsMenu" [ngClass]="{ invisible: disabled() }">
                <mat-icon color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
            </button>
            <mat-menu class="malou-mat-menu malou-box-shadow !rounded-md" #actionsMenu="matMenu">
                @if (!specialDatePeriod().isFromCalendarEvent) {
                    <button class="flex gap-x-2" mat-menu-item (click)="editSpecialPeriodName()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span class="malou-text-12--regular">{{ 'informations.special_hours.edit_name' | translate }}</span>
                    </button>
                }
                @if (canDuplicate()) {
                    <button class="flex gap-x-2" mat-menu-item (click)="duplicateSpecialPeriod()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                        <span class="malou-text-12--regular">{{ 'common.duplicate_to' | translate }}</span>
                    </button>
                }
                <button class="flex gap-x-2" mat-menu-item (click)="deleteSpecialPeriod()">
                    <mat-icon color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-text-12--regular">{{ 'common.delete' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="flex flex-col gap-4 p-4">
        @if (!specialDatePeriod().isFromCalendarEvent) {
            <div class="flex items-center justify-between md:flex-col">
                <div class="flex md:w-full">
                    <div class="malou-border-primary flex justify-center rounded-lg md:w-full">
                        <app-input-date-picker
                            [defaultValue]="specialDatePeriod().startDate"
                            [placeholder]="'common.start_date' | translate"
                            [shouldDisplayBorder]="false"
                            [disabled]="disabled()"
                            [min]="TODAY"
                            [max]="maxStartDate()"
                            (inputDatePickerChange)="updateSpecialPeriodStartDate($event)">
                        </app-input-date-picker>
                    </div>
                    <span class="malou-text-12--medium malou-color-text-2 mx-3 flex items-center">
                        {{ 'common.to' | translate }}
                    </span>
                    <div class="malou-border-primary flex justify-center rounded-lg md:w-full">
                        <app-input-date-picker
                            [defaultValue]="specialDatePeriod().endDate"
                            [placeholder]="'common.end_date' | translate"
                            [shouldDisplayBorder]="false"
                            [disabled]="disabled()"
                            [min]="minEndDate()"
                            (inputDatePickerChange)="updateSpecialPeriodEndDate($event)">
                        </app-input-date-picker>
                    </div>
                </div>
            </div>
        }

        <app-select-open-hours
            [periods]="specialDatePeriod().periods"
            [isRestaurantClosedDuringPeriods]="specialDatePeriod().isClosed"
            [disabled]="disabled()"
            (periodsChange)="updatePeriod($event)"
            (isRestaurantClosedDuringPeriodsChange)="updateIsClosed($event)"></app-select-open-hours>
    </div>
</div>
