import { z } from 'zod';

import { HashtagType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const hashtagBodyValidator = z.object({
    id: objectIdValidator,
    text: z.string(),
    isMain: z.boolean().optional(),
    restaurantId: objectIdValidator,
    isCustomerInput: z.boolean().optional(),
    type: z.nativeEnum(HashtagType),
});

export type SaveHashtagBodyDto = z.infer<typeof hashtagBodyValidator>;

export const updateMainHashtagTextBodyValidator = z.object({
    text: z.string(),
});

export type UpdateMainHashtagTextBodyDto = z.infer<typeof updateMainHashtagTextBodyValidator>;

export const suggestHashtagsPayloadValidator = z.object({
    restaurantName: z.string(),
    postDescription: z.string(),
    favoriteHashtags: z.array(z.string()),
    restaurantCategory: z.array(z.string()),
    restaurantLocality: z.string(),
    restaurantPostalCode: z.string(),
    keywords: z.array(z.string()),
});

export type SuggestHashtagsPayload = z.infer<typeof suggestHashtagsPayloadValidator>;
