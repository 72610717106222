<div class="malou-dialog malou-dialog-mobile flex h-full flex-col !overflow-hidden">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex w-full items-center justify-between">
            <span class="malou-text-18--bold text-malou-color-text-1">
                {{ 'gallery.folders.shared_folder_modal.title' | translate: { folderName } }}
            </span>
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>
    </div>

    <div
        class="malou-dialog__body !m-0 flex grow gap-x-5 !overflow-hidden !pr-0"
        [ngClass]="{ 'items-end': screenSizeService.isPhoneScreen }">
        <div class="mb-7 w-full justify-between pr-6.5 text-center" [ngClass]="{ flex: !screenSizeService.isPhoneScreen }">
            <div class="malou-card !mt-2 cursor-pointer hover:bg-malou-color-background-light" (click)="close(data.folder)">
                <div class="malou-text-12--bold mb-2 text-malou-color-text-1">
                    {{ 'gallery.folders.shared_folder_modal.download_media' | translate }}
                </div>
                <div class="malou-text-10 italic text-malou-color-text-2">
                    {{ 'gallery.folders.shared_folder_modal.then_access_to_folder' | translate }}
                </div>
            </div>
            <div class="malou-card !mt-2 cursor-pointer hover:bg-malou-color-background-light" (click)="close()">
                <div class="malou-text-12--bold mb-2 text-malou-color-text-1">
                    {{ 'gallery.folders.shared_folder_modal.access_to_folder_now' | translate }}
                </div>
                <div class="malou-text-10 italic text-malou-color-text-2">
                    {{ 'gallery.folders.shared_folder_modal.media_can_be_downloaded_later' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
