import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';

@Component({
    selector: 'app-download-insights-footer-popin',
    templateUrl: './download-insights-footer-popin.component.html',
    styleUrls: ['./download-insights-footer-popin.component.scss'],
    standalone: true,
    imports: [TranslateModule, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadInsightsFooterPopinComponent {}
