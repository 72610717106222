<div class="flex h-screen w-screen items-center justify-center">
    <div class="flex flex-col items-center gap-4">
        <div>
            <img class="mb-8 h-8" alt="logo" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="malou-border-primary flex w-[600px] flex-col items-center rounded-lg border bg-white p-8 sm:w-full">
            <div class="mb-12">
                <img class="h-32" alt="logo" [src]="Illustration.Cook | illustrationPathResolver" />
            </div>
            @if (!submitSuccess()) {
                <div class="flex flex-col gap-12">
                    <div class="malou-text-23--bold malou-color-text-1 text-center">{{ reportTypeTranslationTitle() }}</div>
                    <div class="malou-text-14--regular malou-color-text-2 text-center">
                        {{ reportTypeTranslationSubtitle() }}
                    </div>
                    <form class="flex w-full gap-4 sm:flex-col" [formGroup]="unsubscribeForm" (ngSubmit)="submitForm()">
                        <app-input-text
                            class="flex-3"
                            formControlName="email"
                            autofocus
                            [placeholder]="'email@address.com'"
                            [autocapitalize]="'none'"
                            [errorMessage]="!email?.valid && email?.dirty ? ('login.invalid_email' | translate) : ''">
                        </app-input-text>
                        <button
                            class="malou-btn-raised--primary !h-11 flex-2"
                            type="submit"
                            mat-raised-button
                            [disabled]="!unsubscribeForm.valid">
                            {{ 'common.confirm' | translate }}
                        </button>
                    </form>
                </div>
            } @else {
                <div class="flex flex-col gap-12">
                    <div class="malou-text-23--bold malou-color-text-1 text-center">
                        {{ 'reports.unsubscribe.unsubscribe_success_title' | translate }}
                    </div>
                    <div class="malou-text-14--regular malou-color-text-2 text-center">
                        {{ 'reports.unsubscribe.unsubscribe_success_subtitle' | translate }}
                    </div>
                </div>
            }
        </div>
    </div>
</div>
