import { Component } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { AuthService } from '../auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InputTextComponent,
        MatButtonModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
    ],
})
export class ForgotPasswordComponent {
    public emailForm: UntypedFormGroup = this._fb.group({
        email: ['', [Validators.required, Validators.email]],
    });
    formError = '';

    constructor(
        private readonly _auth: AuthService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _spinnerService: SpinnerService,
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute
    ) {
        const email = this._route.snapshot.queryParams.email;
        if (email) {
            this.emailForm.setValue({ email });
        }
    }

    get email(): AbstractControl | null {
        return this.emailForm.get('email');
    }

    send(): void {
        this._spinnerService.show();
        const email = this.emailForm.value.email;
        this._auth.sendResetEmail(email).subscribe({
            next: () => {
                this._spinnerService.hide();
                this.goToForgotPasswordConfirmationScreen(email);
            },
            error: (err) => {
                this._spinnerService.hide();
                if (err.status === 404) {
                    this.goToForgotPasswordConfirmationScreen(email);
                } else {
                    this.formError = this._translate.instant('login.forgot_password.server_error');
                }
            },
        });
    }

    goHome(): void {
        this._router.navigate(['/']);
    }

    goToForgotPasswordConfirmationScreen(email: string): void {
        this._router.navigate(['../confirm'], {
            relativeTo: this._route,
            queryParams: { email },
        });
    }
}
