import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Observable } from 'rxjs';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { NotificationsModalComponent } from './notifications-modal/notifications-modal.component';
import { RoiNotificationsContext } from './notifications.context';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, TranslateModule, MatBadgeModule, AsyncPipe],
})
export class NotificationsComponent implements OnInit {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);

    readonly SvgIcon = SvgIcon;
    readonly notificationCount$: Observable<number> = this._roiNotificationsContext.notificationCount$;

    ngOnInit(): void {
        this._roiNotificationsContext.initRoiNotifications();
        this._openNotificationsModalOnStartup();
        this._openNotificationsModalOnRestaurantCreation();
    }

    openNotificationsModal(): void {
        const modalRef = this._customDialogService.open<NotificationsModalComponent>(NotificationsModalComponent, {
            width: '380px',
            hasBackdrop: true,
            backdropClass: 'notifications-modal-backdrop',
            panelClass: 'notifications-modal-panel',
            height: 'auto',
            maxHeight: '650px',
            closeOnNavigation: true,
            autoFocus: false,
            position: {
                top: '65px',
                right: '20px',
            },
            data: {
                notifications: this._roiNotificationsContext.notifications,
            },
        });
        modalRef.backdropClick().subscribe(() => {
            modalRef.close();
        });
    }

    private _openNotificationsModalOnStartup(): void {
        this.notificationCount$.pipe(filter((count) => !!count)).subscribe(() => {
            if (this._roiNotificationsContext.shouldOpenNotificationsModalOnStartup()) {
                this.openNotificationsModal();
            }
        });
    }

    private _openNotificationsModalOnRestaurantCreation(): void {
        this._roiNotificationsContext.openNotificationCenterOnRestaurantCreation$.subscribe((open) => {
            if (open) {
                this.openNotificationsModal();
            }
        });
    }
}
