import { createAction, props } from '@ngrx/store';

import { AvailableFooterType } from './footer-manager.interface';

export const setFooterVisibility = createAction(
    '[FooterManager] Toggle_Footer_Visibility',
    props<{
        footerType: AvailableFooterType;
        isFooterVisible: boolean;
    }>()
);
