import { Component } from '@angular/core';

import { ReviewReplyAutomationsComponent } from './review-reply-automations/review-reply-automations.component';

@Component({
    selector: 'app-automations',
    templateUrl: './automations.component.html',
    standalone: true,
    imports: [ReviewReplyAutomationsComponent],
})
export class AutomationsComponent {}
