import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { PostDateStatus } from ':modules/posts/new-post-modal/types';

export function onlyFutureDate(): ValidatorFn {
    return (
        formGroup: FormGroup<{
            status: FormControl<PostDateStatus | null>;
            plannedPublicationDate: FormControl<Date | null>;
            postTime: FormControl<string | null>;
        }>
    ) => {
        const plannedPublicationDate = formGroup.get('plannedPublicationDate')?.value;
        const postTime = formGroup.get('postTime')?.value;

        if (plannedPublicationDate && postTime) {
            const plannedPublicationDateWithTime = new Date(plannedPublicationDate);
            plannedPublicationDateWithTime.setHours(Number(postTime.split(':')[0]));
            plannedPublicationDateWithTime.setMinutes(Number(postTime.split(':')[1]));

            if (plannedPublicationDateWithTime < new Date()) {
                return { publicationDateInTheFuture: true };
            }
        }
        return null;
    };
}
