import { z } from 'zod';

import {
    averageRatingPropsValidator,
    BaseEmailProps,
    BaseReportEmailProps,
    ctaValidator,
    notAnsweredReviewsPropsValidator,
    ratingPropsValidator,
    restaurantPropsValidator,
} from '../common';

/** */

export const dailyReviewsStatsValidator = z.object({
    stats: z.object({
        rating: ratingPropsValidator,
        averageRating: averageRatingPropsValidator.optional(),
        notAnsweredReviews: notAnsweredReviewsPropsValidator.optional(),
        cta: ctaValidator,
        noReviewsCta: ctaValidator,
        aiGlobalAnalysisCta: ctaValidator,
    }),
    restaurantCount: z.number(),
});

export type DailyReviewsStatsProps = z.infer<typeof dailyReviewsStatsValidator>;

/** */

export const dailyReportValidator = z.object({
    reviewsStats: dailyReviewsStatsValidator,
    restaurantsReviews: z.array(restaurantPropsValidator),
});

export type DailyReportProps = z.infer<typeof dailyReportValidator> & BaseEmailProps & BaseReportEmailProps;

/** */

export const noDailyReviewsReportValidator = z.object({
    link: z.string(),
});

export type NoDailyReviewsReportProps = z.infer<typeof noDailyReviewsReportValidator> & BaseEmailProps & BaseReportEmailProps;
