import { DatesAndPeriod } from '../models/';

export function isDateSetOrGenericPeriod(dates: DatesAndPeriod): boolean {
    const { startDate, endDate, period } = dates;
    if (startDate && endDate) {
        return true;
    }
    if (!startDate && !endDate && period !== 'custom') {
        return true;
    }
    return false;
}
