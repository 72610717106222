<ng-container
    class="min-h-52"
    [ngTemplateOutlet]="isLoading() || isParentLoading() ? loadingTemplate : estimatedCustomersTemplate"></ng-container>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg px-4 py-6 flex flex-col gap-2 h-52">
        <div class="flex sm:hidden">
            <app-skeleton class="w-[250px]"></app-skeleton>
        </div>
        <div class="flex h-full items-center gap-4 sm:hidden">
            <app-skeleton appearance="circle" skeletonClass="h-[98px] w-[98px] rounded-full"></app-skeleton>
            <div class="flex flex-col gap-2">
                <app-skeleton class="h-[30px] w-[200px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[200px]"></app-skeleton>
            </div>
            <app-skeleton class="!h-full w-[5px]" skeletonClass="h-full"></app-skeleton>
            <app-skeleton appearance="circle" skeletonClass="h-[98px] w-[98px] rounded-full"></app-skeleton>
            <div class="flex flex-col gap-2">
                <app-skeleton class="h-[30px] w-[200px]"></app-skeleton>
                <app-skeleton class="h-[15px] w-[200px]"></app-skeleton>
            </div>
        </div>
    </app-skeleton>
</ng-template>

<ng-template #estimatedCustomersTemplate>
    <div class="malou-simple-card roi-container flex h-full flex-col gap-3 px-4 py-6">
        <div class="flex items-center">
            <span class="malou-text-14--bold malou-color-text-1 mr-2">{{
                'roi.estimated_customers_and_performance_chart.estimated_customers_thanks_to_marketing' | translate
            }}</span>
            @if (!hasValidData()) {
                <app-roi-missing-information-badge
                    [tooltip]="'roi.estimated_customers.invalid_data' | translate"></app-roi-missing-information-badge>
            } @else if (isMissingData()) {
                <app-roi-missing-information-badge
                    [tooltip]="'roi.estimated_customers.missing_data' | translate"></app-roi-missing-information-badge>
            }
        </div>
        <div class="flex flex-wrap gap-2 sm:justify-center">
            <div class="my-3 mr-3 flex items-center gap-3 sm:m-0">
                <div class="h-24 w-24 rounded-full bg-malou-color-background-dark p-5 sm:hidden">
                    <img class="h-14 w-14 sm:mb-5" [src]="'cutlery' | emojiPathResolver" />
                </div>
                <div class="estimated-customers-container flex">
                    <div class="estimated-customers-value flex flex-col items-start sm:items-center">
                        <div class="flex items-center gap-3">
                            <span class="malou-text-32--bold malou-color-text-1">
                                @if (hasValidData() && (minCustomers() || maxCustomers())) {
                                    <span>{{ minCustomers() | shortNumber: { fixNumber: 1 } }}</span>
                                    -
                                    <span>{{ maxCustomers() | shortNumber: { fixNumber: 1 } }}</span>
                                } @else {
                                    ---
                                }
                            </span>
                        </div>
                        @if (hasValidData() && hasSimilarLocationsData()) {
                            <div class="flex items-center gap-1">
                                <span class="malou-text-10 malou-color-text-2"
                                    ><span class="malou-text-10 malou-color-text-2">{{
                                        'roi.estimated_customers.vs_other_similar_businesses'
                                            | translate
                                                : {
                                                      min: minSimilarCustomers() | shortNumber: { fixNumber: 1 },
                                                      max: maxSimilarCustomers() | shortNumber: { fixNumber: 1 },
                                                  }
                                    }}</span></span
                                >

                                <mat-icon
                                    class="!h-3 !w-3"
                                    [svgIcon]="SvgIcon.INFO"
                                    [matTooltip]="'roi.estimated_customers.tooltip_sales' | translate"></mat-icon>
                            </div>
                        }
                        @if (hasValidData()) {
                            <button class="malou-btn-flat !malou-text-10--semibold sm:mt-2 sm:self-center" (click)="openDetails()">
                                {{ 'roi.details' | translate }}
                            </button>
                        }
                    </div>
                    <div class="divider ml-3 h-auto w-0.5 bg-malou-color-background-dark sm:h-0.5 sm:w-full"></div>
                    <div class="estimated-earnings-value mb-3 ml-3 sm:m-0">
                        <div class="flex flex-col items-start sm:items-center">
                            <div class="flex items-center gap-3">
                                <span class="malou-text-32--bold malou-color-text-1">
                                    @if (hasValidData() && minEarnings()) {
                                        <span>{{ minEarnings() | shortNumber: { fixNumber: 1 } }}</span>
                                    } @else {
                                        -
                                    }
                                    -
                                    @if (hasValidData() && maxEarnings()) {
                                        <span>{{ maxEarnings() | shortNumber: { fixNumber: 1 } }}</span>
                                    } @else {
                                        -
                                    }
                                    {{ roiContext.roiSettings()?.displayCurrency() }}
                                </span>
                                @if (hasValidData() && !isTotalEarningsEvolutionTooHigh()) {
                                    <app-number-evolution
                                        [value]="totalEarningsEvolution()"
                                        [displayedValue]="
                                            totalEarningsEvolution()
                                                | shortNumber: { fixNumber: 1, content: '%', shouldDisplayMinusSign: false }
                                        "></app-number-evolution>
                                }
                            </div>
                            <div class="flex items-center gap-1">
                                @if (hasValidData() && (minSimilarEarnings() || maxSimilarEarnings())) {
                                    <span class="malou-text-10 malou-color-text-2"
                                        >{{ 'roi.estimated_customers.vs_other_similar_businesses_sales' | translate }}
                                        {{ minSimilarEarnings() | shortNumber: { fixNumber: 1 } }} -
                                        {{ maxSimilarEarnings() | shortNumber: { fixNumber: 1 } }}
                                        {{ roiContext.roiSettings()?.displayCurrency() }}</span
                                    >
                                    <mat-icon
                                        class="!h-3 !w-3"
                                        [svgIcon]="SvgIcon.INFO"
                                        [matTooltip]="'roi.estimated_customers.tooltip_sales' | translate"></mat-icon>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
