import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { combineLatest, Observable, tap } from 'rxjs';

import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedBoostersStatisticsData } from '../booster.interface';

enum AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName {
    BUSINESS = 'business',
    REVIEWS = 'reviews',
    EVOLUTION = 'evolution',
}

interface AggregatedWheelOfFortuneEstimatedReviewCountTableData {
    restaurantId: string;
    restaurantName: string;
    estimatedReviewCount: number;
    estimatedReviewCountDifferenceWithPreviousPeriod: number;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-estimated-review-count',
    templateUrl: './aggregated-wheel-of-fortune-estimated-review-count.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-estimated-review-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        ReactiveFormsModule,
        TranslateModule,
        NumberEvolutionComponent,
        SelectComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneEstimatedReviewCountComponent implements OnInit {
    @Input() tableSort?: Sort;
    @Input() data$: Observable<AggregatedBoostersStatisticsData>;
    @Input() restaurants$: Observable<Restaurant[]>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly TableFieldName = AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName;

    readonly displayedColumns = Object.values(AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName);
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantId');
    readonly dataSource: MatTableDataSource<AggregatedWheelOfFortuneEstimatedReviewCountTableData> =
        new MatTableDataSource<AggregatedWheelOfFortuneEstimatedReviewCountTableData>([]);

    isLoading: WritableSignal<boolean> = signal(false);
    isError: WritableSignal<boolean> = signal(false);

    defaultSort: Sort = {
        active: AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.REVIEWS,
        direction: ChartSortBy.DESC,
    };

    hasData: WritableSignal<boolean> = signal(true);

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.BUSINESS:
                        return item.restaurantName;
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.REVIEWS:
                        return item.estimatedReviewCount;
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.EVOLUTION:
                        return item.estimatedReviewCountDifferenceWithPreviousPeriod;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        if (this.tableSort) {
            this.defaultSort = this.tableSort;
        }
        combineLatest([this.data$, this.restaurants$])
            .pipe(
                tap(() => {
                    this.isLoading.set(true);
                    this.isError.set(false);
                })
            )
            .subscribe({
                next: ([{ scans, previousScans }, restaurants]) => {
                    this.hasData.set(scans.length > 0);
                    this.hasDataChange.emit(scans.length > 0);
                    const scansAndPreviousScansByRestaurantId = this._groupScansAndPreviousScansByRestaurant(
                        scans,
                        previousScans,
                        restaurants
                    );

                    this.dataSource.data = Object.entries(scansAndPreviousScansByRestaurantId).map(
                        ([restaurantId, { scans: scansByRestaurant, previousScans: previousScansByRestaurant }]) => {
                            const restaurant = restaurants.find((r) => r.id === restaurantId);
                            const estimatedReviewCount = this._getEstimatedReviewCount(scansByRestaurant);
                            const previousEstimatedReviewCount = this._getEstimatedReviewCount(previousScansByRestaurant);
                            const estimatedReviewCountDifferenceWithPreviousPeriod = estimatedReviewCount - previousEstimatedReviewCount;
                            return {
                                restaurantId: restaurant?.id || scans[0].nfcSnapshot?.restaurantId || '',
                                restaurantName: restaurant?.getDisplayedValue() || '',
                                estimatedReviewCount,
                                estimatedReviewCountDifferenceWithPreviousPeriod,
                            };
                        }
                    );

                    this.isLoading.set(false);
                    this.isError.set(false);
                },
                error: (error) => {
                    console.warn(error);
                    this.isError.set(true);
                    this.isLoading.set(false);
                },
            });
    }

    private _groupScansAndPreviousScansByRestaurant(
        scans: ScanForStats[],
        previousScans: ScanForStats[],
        restaurants: Restaurant[]
    ): Record<string, { scans: ScanForStats[]; previousScans: ScanForStats[] }> {
        const scansByRestaurant: Record<string, ScanForStats[]> = groupBy(scans, 'nfcSnapshot.restaurantId');
        const previousScansByRestaurant: Record<string, ScanForStats[]> = groupBy(previousScans, 'nfcSnapshot.restaurantId');
        return Object.values(restaurants).reduce(
            (acc, restaurant) => ({
                ...acc,
                [restaurant._id]: {
                    scans: scansByRestaurant[restaurant._id] || [],
                    previousScans: previousScansByRestaurant[restaurant._id] || [],
                },
            }),
            {}
        );
    }

    private _getEstimatedReviewCount(scans: ScanForStats[]): number {
        return scans.filter((scan) => {
            const hasMatchedReview = scan.matchedReviewId;
            return hasMatchedReview;
        }).length;
    }
}
