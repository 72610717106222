import { ChartEvent, Defaults } from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';

const scale: DeepPartial<Defaults['scale']> = {
    offset: true,
    border: {
        display: false,
    },
    grid: {
        display: false,
    },
};

const elements: DeepPartial<Defaults['elements']> = {
    line: {
        tension: 0.3,
        borderCapStyle: 'round',
    },
    bar: {
        borderRadius: 5,
    },
    point: {
        pointStyle: 'circle',
        radius: 0,
        hoverRadius: 5,
        hitRadius: 20,
    },
};

const plugins: DeepPartial<Defaults['plugins']> = {
    tooltip: {
        usePointStyle: true,
        animation: false,
    },
    legend: {
        labels: {
            usePointStyle: true,
            pointStyle: 'circle',
        },
        onHover: (e: ChartEvent) => {
            if (e.native && (e.native.target as HTMLElement)?.style) {
                (e.native.target as HTMLElement).style.cursor = 'pointer';
            }
        },
        onLeave: (e: ChartEvent) => {
            if (e.native && (e.native.target as HTMLElement)?.style) {
                (e.native.target as HTMLElement).style.cursor = 'default';
            }
        },
    },
};

export const defaultChartJsConfiguration: DeepPartial<Defaults> = {
    clip: false,
    responsive: true,
    maintainAspectRatio: false,
    scale: scale,
    elements: elements,
    plugins: plugins,
};

export const SMALL_TOOLTIP_TAB = '  ';
export const LARGE_TOOLTIP_TAB = '     ';
