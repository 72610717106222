import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedWheelOfFortuneGiftsStatisticsData } from '../booster.interface';

interface AggregatedTotalGiftsData {
    totalWinningGifts: number | null;
    totalRetrievedGifts: number | null;
    totalWinningGiftsDifferenceWithPreviousPeriod: number | null;
    totalRetrievedGiftsDifferenceWithPreviousPeriod: number | null;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-gifts-kpis',
    templateUrl: './aggregated-wheel-of-fortune-gifts-kpis.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-gifts-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        SkeletonComponent,
        ShortNumberPipe,
        NumberEvolutionComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneGiftsKpisComponent implements OnInit {
    @Input() data$: Observable<AggregatedWheelOfFortuneGiftsStatisticsData>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly hasData: WritableSignal<boolean> = signal(true);
    readonly totalGiftsData: WritableSignal<AggregatedTotalGiftsData> = signal({
        totalWinningGifts: null,
        totalRetrievedGifts: null,
        totalWinningGiftsDifferenceWithPreviousPeriod: null,
        totalRetrievedGiftsDifferenceWithPreviousPeriod: null,
    });

    ngOnInit(): void {
        this.data$.subscribe(({ giftsInsightsPerRestaurant }) => {
            this.hasData.set(giftsInsightsPerRestaurant.length > 0);
            this.hasDataChange.emit(giftsInsightsPerRestaurant.length > 0);
            const totalWinningGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.giftDrawCount ?? 0), 0);
            const totalRetrievedGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.retrievedGiftDrawCount ?? 0), 0);
            const previousTotalWinningGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.previousGiftDrawCount ?? 0), 0);
            const previousTotalRetrievedGifts = giftsInsightsPerRestaurant.reduce(
                (acc, curr) => acc + (curr.previousRetrievedGiftDrawCount ?? 0),
                0
            );

            this.totalGiftsData.set({
                totalWinningGifts,
                totalRetrievedGifts,
                totalWinningGiftsDifferenceWithPreviousPeriod: totalWinningGifts - previousTotalWinningGifts,
                totalRetrievedGiftsDifferenceWithPreviousPeriod: totalRetrievedGifts - previousTotalRetrievedGifts,
            });
        });
    }
}
