import { z } from 'zod';

import {
    AutomationFeature,
    PlatformKey,
    ReviewReplyAutomationComment,
    ReviewReplyAutomationMethod,
    ReviewReplyAutomationRatingCategory,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

const restaurantIdParamsValidator = z.object({
    restaurantId: objectIdValidator,
});
// ------------------------------------------------------------------------------------------
export const getRestaurantReviewReplyAutomationsParamsValidator = restaurantIdParamsValidator;

export type GetRestaurantReviewReplyAutomationsParamsDto = z.infer<typeof getRestaurantReviewReplyAutomationsParamsValidator>;
// ------------------------------------------------------------------------------------------
export const updateRestaurantReviewReplyAutomationsParamsValidator = restaurantIdParamsValidator;

export type UpdateRestaurantReviewReplyAutomationsParamsDto = z.infer<typeof updateRestaurantReviewReplyAutomationsParamsValidator>;

export const updateRestaurantReviewReplyAutomationsBodyValidator = z.object({
    automations: z.array(
        z.object({
            restaurantId: objectIdValidator,
            active: z.boolean(),
            feature: z.string().refine((value) => value === AutomationFeature.REPLY_TO_REVIEW),
            ratingCategory: z.nativeEnum(ReviewReplyAutomationRatingCategory),
            withComment: z.nativeEnum(ReviewReplyAutomationComment),
            replyMethod: z.nativeEnum(ReviewReplyAutomationMethod).nullable(),
            aiConfig: z.object({
                sendAutomaticallyToThesePlatformKeys: z.array(z.nativeEnum(PlatformKey)),
            }),
            templateConfigs: z.array(
                z.object({
                    template: objectIdValidator,
                    platformKeys: z.array(z.nativeEnum(PlatformKey)),
                })
            ),
        })
    ),
});
