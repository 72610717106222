import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { TemplateDto } from '@malou-io/package-dto';
import { ApiResultV2, TemplateTranslationLang, TemplateType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult, CoreTemplate, CoreTemplateSpot, Template } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class TemplatesService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/templates`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    create(template: Partial<Template>, restaurantId: string): Observable<Template> {
        return this._http
            .post<ApiResultV2<TemplateDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, template, {
                withCredentials: true,
            })
            .pipe(map((res) => new Template(res.data)));
    }

    getTemplate(id: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${id}`).pipe(
            map((res: ApiResult) => {
                res.data = new Template(res.data);
                return res;
            })
        );
    }

    getTemplates(): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}`).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((t) => new Template(t));
                return res;
            })
        );
    }

    getCoreTemplates(): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/core`).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((t) => new CoreTemplate(t));
                return res;
            })
        );
    }

    getCoreTemplatesSpots(): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/spots`).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((t) => new CoreTemplateSpot(t));
                return res;
            })
        );
    }

    getTemplatesByRestaurantId(
        restaurantId: string,
        type: TemplateType | null = null,
        fields: string | null = null
    ): Observable<Template[]> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId, type, fields }));
        return this._http
            .get<ApiResult<Template[]>>(`${this.API_BASE_URL}`, { params: cleanFilters })
            .pipe(map((res) => res.data.map((t) => new Template(t))));
    }

    updateAndActivateTemplate$(
        id: string,
        data: Partial<Template>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<Template> {
        return this._http
            .put<ApiResultV2<TemplateDto>>(`${this.API_BASE_URL}/${id}`, data, { withCredentials: true, params: { restaurantId } })
            .pipe(map((res) => new Template(res.data)));
    }

    updateTemplateToPendingStatus$(templateId: string): Observable<ApiResultV2<null>> {
        return this._http.put<ApiResultV2<null>>(`${this.API_BASE_URL}/${templateId}/to_pending`, { withCredentials: true });
    }

    deleteManyTemplates(templateIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http.post<ApiResult>(
            `${this.API_BASE_URL}/delete`,
            { templateIds },
            { withCredentials: true, params: { restaurantId } }
        );
    }

    deleteTemplate(id: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${id}`, { withCredentials: true, params: { restaurantId } });
    }

    duplicateTemplatesForRestaurants(
        restaurantId: string,
        originalTemplates: Partial<Template>[],
        restaurantIds: string[]
    ): Observable<Template[]> {
        return this._http
            .post<ApiResult>(
                `${this.API_BASE_URL}/${restaurantId}/duplicate`,
                { originalTemplates, restaurantIds },
                { withCredentials: true }
            )
            .pipe(map((res) => res.data.map((t) => new Template(t))));
    }

    generateTemplates(langs: TemplateTranslationLang[], restaurantId: string): Observable<Template[]> {
        return this._http
            .post<ApiResultV2<TemplateDto[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/generate`, { langs })
            .pipe(map((res) => res.data.map((t) => new Template(t))));
    }
}
