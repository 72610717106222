import { MediaType } from './constants';

export const getTypeFromExtension = (extension: string | undefined): MediaType => {
    switch (extension?.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
        case 'png':
            return MediaType.PHOTO;
        case 'mp4':
        case 'mov':
        case 'quicktime':
            return MediaType.VIDEO;
        default:
            return MediaType.FILE;
    }
};

export const getTypeFromMimetype = (mimetype: string): MediaType => {
    switch (mimetype) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'application/octet-stream':
            return MediaType.PHOTO;
        case 'video/mp4':
        case 'video/quicktime':
        case 'video/mov':
            return MediaType.VIDEO;
        default:
            return MediaType.FILE;
    }
};
