<app-social-posts-list-header
    [(selectedFilter)]="selectedFilter"
    (createPost)="onCreatePost()"
    (createReelOrTikTok)="onCreateReelOrTikTok()"></app-social-posts-list-header>

<div
    class="hide-scrollbar flex h-full flex-col gap-3 overflow-y-auto px-6 py-3 pb-10"
    infinite-scroll
    [infiniteScrollContainer]="scrollContainer"
    (scrolled)="onScrollDown()"
    #scrollContainer>
    @if (isFetchingPosts()) {
        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
    } @else {
        @for (post of posts(); track post.id) {
            <app-social-post-item [post]="post"></app-social-post-item>
        }
        @if (isFetchingMorePosts()) {
            <ng-container [ngTemplateOutlet]="postSkeletonTemplate"></ng-container>
        }
    }
</div>

<ng-template #loadingTemplate>
    @for (count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track count) {
        <ng-container [ngTemplateOutlet]="postSkeletonTemplate"></ng-container>
    }
</ng-template>

<ng-template #postSkeletonTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[106px] w-full"></app-skeleton>
</ng-template>
