import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';

@Component({
    selector: 'app-top-post-card-skeleton',
    templateUrl: './top-post-card-skeleton.component.html',
    styleUrls: ['./top-post-card-skeleton.component.scss'],
    standalone: true,
    imports: [SkeletonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopPostCardSkeletonComponent implements OnInit {
    @Input() withRestaurantInfo: boolean;

    skeletonHeightClass: string;

    ngOnInit(): void {
        this.skeletonHeightClass = this.withRestaurantInfo ? 'h-[410px]' : 'h-[365px]';
    }
}
