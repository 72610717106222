<div class="flex flex-col gap-12">
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'aggregated_statistics_pdf.social_networks.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantsTitle$ | async | statisticsPdfRestaurantsFormatter: true }}
        </span>
    </div>

    <div class="flex flex-col gap-4 px-8.5 py-4">
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_TOP_POSTS_CARDS) && aggregatedTop3PostsHasData) {
            <div class="overflow-y-none break-inside-avoid">
                <app-aggregated-top-3-posts (hasDataChange)="aggregatedTop3PostsHasData = $event"></app-aggregated-top-3-posts>
            </div>
        }
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_PUBLICATIONS_TABLE) && aggregatedPostsInsightsHasData) {
            <div class="overflow-y-none">
                <app-aggregated-posts-insights-table
                    [tableSortOptions]="chartOptions[InsightsChart.AGGREGATED_PUBLICATIONS_TABLE]?.tableSortOptions"
                    [hideRestaurantWithErrors]="true"
                    (hasDataChange)="aggregatedPostsInsightsHasData = $event">
                </app-aggregated-posts-insights-table>
            </div>
        }
    </div>
</div>
