import { z } from 'zod';

import { lightMediaValidator } from '../media/media.dto';
import { objectIdValidator } from '../utils/validators';
import { addressDtoValidator } from './address/address.dto';

export const lightRestaurantValidator = z.object({
    id: objectIdValidator,
    name: z.string(),
    internalName: z.string().optional(),
    address: addressDtoValidator.optional(),
    logo: lightMediaValidator.nullable(),
});
