<div class="flex flex-col gap-y-1">
    @if (title()) {
        <div class="malou-text-10--regular malou-color-text-2">
            {{ title() }}
        </div>
    }
    <div class="flex gap-x-4">
        <div class="grow">
            <app-select
                [testId]="'platforms-connection-account-input'"
                [formControl]="formControl"
                [values]="selectableValues()"
                [displayWith]="displayWith()"
                (selectChange)="selectionChange.emit($event)"></app-select>
        </div>
        @if (showButton()) {
            <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="buttonClick.emit()">
                <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
            </button>
        }
    </div>
</div>
