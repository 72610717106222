import { ChangeDetectionStrategy, Component, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-recipients',
    templateUrl: 'select-recipients.component.html',
    styleUrls: ['./select-recipients.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectRecipientsComponent),
        },
    ],
    standalone: true,
    imports: [SelectBaseComponent, FormsModule, ReactiveFormsModule, MatIconModule, TranslateModule, MatTooltipModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRecipientsComponent extends ControlValueAccessorConnectorComponent {
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<string[]>([]);

    readonly selectedValues = input<string[]>([]);

    readonly multiSelection = input<boolean>(true);

    readonly multiSelectionElementWrap = input<boolean>(true);

    readonly shouldSwitchToWrapModeOnClick = input<boolean>(true);

    readonly shouldUpdateValuesToDisplayAfterSelection = input<boolean>(true);

    readonly defaultEmptyValueMessage = input<string | null>(null);

    readonly showValuesSelectedCount = input<boolean>(true);

    readonly disabled = input<boolean>(false);

    readonly maxSelectableValues = input<number>(100);

    readonly computeObjectHash = input<((a?: any) => any) | undefined>();

    readonly buildValueFromText = input<((text: string) => string) | undefined>();

    readonly onAddValue = input<((value: string) => void) | undefined>();

    // ------------ Event ------------//

    /** On change event */
    readonly selectRecipientsChange = output<unknown>();

    readonly SvgIcon = SvgIcon;

    readonly inputAutoCompleteControl = new UntypedFormControl('');

    displayWith: (option: string) => string = (option: string) => option;

    sortBy = (a: string, b: string): number => (a > b ? 1 : -1);

    doesItemExist = (controlValue: any, values: any[]): boolean => {
        if (!controlValue) {
            return false;
        }

        return values?.find((item) => this.displayWith(item)?.toLowerCase()?.trim() === controlValue?.toLowerCase()?.trim());
    };
}
