import { Routes } from '@angular/router';

import { AiSettingsComponent } from ':modules/ai-settings/ai-settings.component';

export const AI_ROUTES: Routes = [
    {
        path: '',
        component: AiSettingsComponent,
    },
];
