import { Directive, HostListener, signal } from '@angular/core';

@Directive({
    selector: '[appIsHovered]',
    standalone: true,
    exportAs: 'appIsHovered',
})
export class IsHoveredDirective {
    isHovered = signal(false);

    @HostListener('mouseenter') onMouseEnter(): void {
        this.isHovered.set(true);
    }

    @HostListener('mouseleave') onMouseLeave(): void {
        this.isHovered.set(false);
    }
}
