import { AsyncPipe, LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { ReviewsCountTableComponent } from ':modules/aggregated-statistics/e-reputation/reviews-count/reviews-count-table/reviews-count-table.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { getStatisticsError, isDateSetOrGenericPeriod } from ':shared/helpers';
import { ChartReviewsStats, DatesAndPeriod, Restaurant } from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import * as AggregatedStatisticsActions from '../../store/aggregated-statistics.actions';
import { PlatformFilterPage } from '../../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';
import { ReviewsCountChartComponent } from './reviews-count-chart/reviews-count-chart.component';
import { RestaurantReviewsCountChartData } from './reviews-count.interface';

@Component({
    selector: 'app-reviews-count',
    templateUrl: './reviews-count.component.html',
    styleUrls: ['./reviews-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        NumberEvolutionComponent,
        ReviewsCountChartComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        TranslateModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        LowerCasePipe,
        MatButtonToggleModule,
        ReviewsCountTableComponent,
        EnumTranslatePipe,
        NgClass,
    ],
})
export class ReviewsCountComponent implements OnInit {
    @Input() chartSortBy?: ChartSortBy;
    @Input() tableSort?: Sort;
    @Input() viewMode?: StatisticsDataViewMode;
    @Input() showViewModeToggle = true;
    @Output() chartSortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() viewModeChange: EventEmitter<StatisticsDataViewMode> = new EventEmitter<StatisticsDataViewMode>();
    @Input() showSortByTextInsteadOfSelector = false;
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);
    @Input() expandTable = false;

    readonly SvgIcon = SvgIcon;
    readonly StatisticsDataViewMode = StatisticsDataViewMode;
    viewModeSelected = StatisticsDataViewMode.CHART;

    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    reviewsStats$: Observable<ValueError<ChartReviewsStats[], string>>;
    restaurantReviewsChartData: RestaurantReviewsCountChartData[];
    totalRestaurantsReviews: number;
    totalRestaurantsReviewsEvolution: number;
    warningTooltip: string | null;
    errorTooltip: string | null;
    isLoading = signal(true);

    readonly chartSortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(
        ChartSortBy.ALPHABETICAL
    ) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _reviewsService: ReviewsService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.chartSortBy) {
            this.chartSortByControl.setValue(this.chartSortBy);
        }
        if (this.viewMode) {
            this.viewModeSelected = this.viewMode;
        }
        this.reviewsStats$ = this.loadAggregatedReviewsStats$();
    }

    loadAggregatedReviewsStats$(): Observable<ValueError<ChartReviewsStats[], string>> {
        return combineLatest([this.dates$, this.platformKeys$, this.selectedRestaurants$]).pipe(
            filter(([dates, _platforms, _selectedRestaurants]) => isDateSetOrGenericPeriod(dates)),
            tap(() => this.isLoading.set(true)),
            switchMap(([dates, platformKeys, restaurants]) => {
                const restaurantIds = restaurants.filter((r) => !r.isBrandBusiness()).map((r) => r._id);
                if (!dates.startDate || !dates.endDate) {
                    throw new Error('No dates in ReviewsRatingsAverageComponent filter');
                }
                const body = {
                    startDate: dates.startDate.toISOString(),
                    endDate: dates.endDate.toISOString(),
                    platformKeys,
                    restaurantIds,
                    previousPeriod: false,
                };
                const bodyWithPreviousPeriod = {
                    ...body,
                    previousPeriod: true,
                };
                return forkJoin([
                    this._reviewsService.getChartReviewsForUserRestaurants(body),
                    this._reviewsService.getChartReviewsForUserRestaurants(bodyWithPreviousPeriod),
                    of(restaurants),
                ]);
            }),
            tap(([currentStats, _previousStats, restaurants]) => {
                this.errorTooltip = this._getErrorTooltip(currentStats, restaurants);
                this.warningTooltip = this._getWarningTooltip(restaurants);
            }),
            map(([currentReviewsStats, previousReviewsStats, _restaurants]) => {
                this._store.dispatch(AggregatedStatisticsActions.editReviewCountsData({ data: currentReviewsStats }));
                const restaurantsInCurrentStats = currentReviewsStats.map((r) => r.restaurant);
                const restaurantsInPreviousStats = previousReviewsStats.map((r) => r.restaurant);
                const restaurantToCheck = [...restaurantsInCurrentStats, ...restaurantsInPreviousStats];
                const uniqRestaurantToCheck = uniqBy(restaurantToCheck, '_id');
                this.restaurantReviewsChartData = uniqRestaurantToCheck
                    .map((restaurant) => {
                        const currentStats = currentReviewsStats.find(
                            (currentReviewStats) => currentReviewStats.restaurant._id === restaurant._id
                        );
                        const previousStats = previousReviewsStats.find(
                            (previousReviewStats) => previousReviewStats.restaurant._id === restaurant._id
                        );
                        return {
                            restaurantName: restaurant.internalName ?? restaurant.name,
                            restaurantAddress: restaurant.address?.getAddressWithDistrict(),
                            reviewsCountPerPlatform: this._getReviewsCountPerPlatform(currentStats),
                            total: currentStats?.total ?? 0,
                            evolution: this._getReviewsCountEvolution(currentStats, previousStats),
                        };
                    })
                    .sort((a, b) => (b.restaurantName > a.restaurantName ? -1 : 1));
                this.totalRestaurantsReviews = currentReviewsStats.reduce((acc, curr) => acc + (curr.total ?? 0), 0);
                const previousTotalRestaurantsReviews = previousReviewsStats
                    ? previousReviewsStats.reduce((acc, curr) => acc + (curr.total ?? 0), 0)
                    : 0;
                this.totalRestaurantsReviewsEvolution = this.totalRestaurantsReviews - previousTotalRestaurantsReviews;
                if (!currentReviewsStats.length) {
                    this.hasDataChange.emit(false);
                }
                this.isLoading.set(false);
                return currentReviewsStats;
            }),
            map((values) => ({ value: values })),
            catchError((error) => {
                this.hasDataChange.emit(false);
                this.isLoading.set(false);
                return of({
                    error: error instanceof HttpResponseBase ? getStatisticsError(error) : error.message,
                });
            })
        );
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onChartSortByChange(sortBy: ChartSortBy): void {
        this.chartSortByControl.setValue(sortBy);
        this.chartSortByChange.emit(sortBy);
    }

    onTableSortChange(sort: Sort): void {
        this.tableSortByChange.emit(sort);
    }

    onViewModeChange(value: StatisticsDataViewMode): void {
        this.viewModeSelected = value;
        this.viewModeChange.emit(value);
    }

    private _getReviewsCountPerPlatform(reviewStats: ChartReviewsStats | undefined): { [key: string]: number } {
        const reviewsCountPerPlatform = {};
        (reviewStats?.reviews ?? []).forEach((review) => {
            if (reviewsCountPerPlatform[review.key]) {
                reviewsCountPerPlatform[review.key]++;
            } else {
                reviewsCountPerPlatform[review.key] = 1;
            }
        });
        return reviewsCountPerPlatform;
    }

    private _getReviewsCountEvolution(currentStats: ChartReviewsStats | undefined, previousStats: ChartReviewsStats | undefined): number {
        const currentReviewsCount = currentStats?.total ?? 0;
        const previousReviewsCount = previousStats?.total ?? 0;
        return currentReviewsCount - previousReviewsCount;
    }

    private _getWarningTooltip(restaurants: Restaurant[]): string | null {
        const selectedBrandBusiness = restaurants.filter((r) => r.isBrandBusiness());
        if (!selectedBrandBusiness.length) {
            return null;
        }
        const restaurantsLabel = selectedBrandBusiness.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _getErrorTooltip(currentRestaurantReviewsStats: ChartReviewsStats[], restaurants: Restaurant[]): string | null {
        const restaurantsLabel = restaurants
            .filter((r) => !r.isBrandBusiness())
            .reduce((acc, curr) => {
                const currentRestaurantReviewsStatsForRestaurant = currentRestaurantReviewsStats.find(
                    (currentStats) => currentStats.restaurant._id === curr._id
                );
                if (!currentRestaurantReviewsStatsForRestaurant) {
                    return acc + curr.internalName + ', ';
                }
                return acc;
            }, '');
        if (restaurantsLabel === '') {
            return null;
        }
        return this._translate.instant('aggregated_statistics.errors.gmb_fetching_error', {
            restaurants: restaurantsLabel,
        });
    }
}
