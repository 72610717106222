import { createAction, props } from '@ngrx/store';

import { User } from ':modules/user/user';

export const initJimoUserAttributes = createAction(
    '[Jimo] Init_User_Attributes',
    props<{
        user: User;
        aiInteractionCount: number;
        storyCreatedCount: number;
        restaurantCountInOrganizations: number;
        userCountInOrganizations: number;
        hasCreatedAWheel: boolean;
        firstWheelCreatedAt: Date | null;
        hasAtLeastOneActiveOrProgrammedWheel: boolean;
        latestEndDateForActiveWheels: Date | null;
    }>()
);

export const incrementJimoUserAiInteractionsCount = createAction('[Jimo] Increment_User_Ai_Interactions_Count');

export const incrementJimoUserStoriesCreatedCount = createAction('[Jimo] Increment_User_Stories_Created_Count');

export const updateLanguage = createAction('[Jimo] Update_App_Language', props<{ language: string }>());

export const changeWheelOfFortuneStatus = createAction(
    '[Jimo] Change_User_Wheel_Of_Fortune_Status',
    props<{
        hasCreatedAWheel: boolean;
        firstWheelCreatedAt: Date | null;
        hasAtLeastOneActiveOrProgrammedWheel: boolean;
        latestEndDateForActiveWheels: Date | null;
    }>()
);
