import { Pipe, PipeTransform } from '@angular/core';

interface DisplayMenuItemsArgs {
    isFold: boolean;
    maxMenuItemShown: number;
}

@Pipe({
    name: 'displayMenuItems',
    standalone: true,
})
export class DisplayMenuItemsPipe implements PipeTransform {
    transform(menuItems: any[], args: DisplayMenuItemsArgs): any[] {
        const { isFold, maxMenuItemShown } = args;
        const maxMenuItemToDisplay = this.getMaxItemToDisplay(menuItems.length, maxMenuItemShown);
        return menuItems.slice(0, isFold ? maxMenuItemToDisplay : undefined);
    }

    getMaxItemToDisplay(menuItemsLength: number, maxMenuItemShown: number): number {
        if (menuItemsLength <= maxMenuItemShown) {
            return menuItemsLength;
        }
        return maxMenuItemShown;
    }
}
