import { DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PostType } from '@malou-io/package-utils';

import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

export interface TopPostCardInputData {
    restaurantName?: string;
    restaurantAddress?: string;
    postType: PostType;
    platformKey: string;
    url: string;
    thumbnailUrl?: string;
    createdAt: Date;
    likes: number;
    comments: number;
    shares: number;
    saves: number;
    impressions: number;
    engagementRate: number;
    uuid?: string; // only for tracking in @for loop (template)
}

@Component({
    selector: 'app-top-post-card',
    templateUrl: './top-post-card.component.html',
    styleUrls: ['./top-post-card.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        SocialPostMediaComponent,
        ApplySelfPurePipe,
        DecimalPipe,
        FormatDatePipe,
        PlatformLogoComponent,
        ShortNumberPipe,
        ShortNumberPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopPostCardComponent {
    @Input() topPostCardInputData: TopPostCardInputData;

    readonly SvgIcon = SvgIcon;
    constructor(public readonly translateService: TranslateService) {}
}
