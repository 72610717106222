import { Day } from '@malou-io/package-utils';

import { CalendarEvent, HoursType, Period, SpecialDatePeriod } from ':shared/models';

export interface Schedule {
    selectedDays: Day[];
    periods: Period[];
    availableDays: Day[];
}

export interface ScheduleWithIsClosed extends Schedule {
    isClosed: boolean;
}

export interface OtherServiceSchedules {
    type: HoursType;
    schedules: Schedule[];
}

export interface BusinessHoursState {
    schedules: ScheduleWithIsClosed[];
    hasBeenTouched: boolean;
}

export interface OtherHoursState {
    services: OtherServiceSchedules[];
    availableHoursTypes: HoursType[];
    hasBeenTouched: boolean;
}

export interface SpecialHoursState {
    specialPeriods: SpecialDatePeriod[];
    calendarEvents: CalendarEvent[];
    prefilledStartDate?: Date;
    hasBeenTouched: boolean;
}

export interface HoursModalState {
    businessHours: BusinessHoursState;
    otherHours: OtherHoursState;
    specialHours: SpecialHoursState;
    isClosedTemporarily: boolean;
    hasBeenTouched: boolean;
}

export const DEFAULT_HOURS_PERIOD = new Period({ isClosed: false, openTime: null, closeTime: null });
export const DEFAULT_SCHEDULE_WITH_IS_CLOSED: ScheduleWithIsClosed = {
    selectedDays: [],
    periods: [new Period(DEFAULT_HOURS_PERIOD)],
    isClosed: false,
    availableDays: Object.values(Day),
};
export const DEFAULT_SCHEDULE: Schedule = {
    selectedDays: [],
    periods: [new Period(DEFAULT_HOURS_PERIOD)],
    availableDays: Object.values(Day),
};
