import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'htmlTag',
    standalone: true,
})
export class HtmlTagPipe implements PipeTransform {
    public constructor(private readonly _sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml | null {
        if (!value) {
            return null;
        }
        return this._sanitizer.bypassSecurityTrustHtml(value.replace(/\[/g, '<').replace(/]/g, '>'));
    }
}
