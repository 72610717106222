import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, forwardRef, inject, signal, untracked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { UploadAndEditMediasComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/upload-and-edit-medias/upload-and-edit-medias.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { linkedSignal } from ':shared/helpers/linked-signal';

import { ImageEditorComponent, TransformData } from './components/image-editor/image-editor.component';
import { ImageTransformButtonsComponent, RotationDirection } from './components/image-transform-buttons/image-transform-buttons.component';

interface Media {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
    transformData?: TransformData;
}

export interface EditMediaModalDialogData {
    medias: Media[];
    selectedMediaId?: string;
}

export interface EditMediaModalDialogResult {
    medias: Media[];
}
const ROTATION_INCREMENT_IN_DEGREES = 90;

export enum AspectRatio {
    SQUARE = 1,
    PORTRAIT = 4 / 5,
    LANDSCAPE = 16 / 9,
}

@Component({
    selector: 'app-edit-media-modal',
    templateUrl: './edit-media-modal.component.html',
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        forwardRef(() => UploadAndEditMediasComponent),
        NgTemplateOutlet,
        ImageEditorComponent,
        ImageTransformButtonsComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMediaModalComponent {
    private readonly _matDialogRef = inject<MatDialogRef<EditMediaModalComponent, EditMediaModalDialogResult>>(MatDialogRef);
    private readonly _matDialogData = inject<EditMediaModalDialogData>(MAT_DIALOG_DATA);

    readonly medias = signal(cloneDeep(this._matDialogData.medias));
    readonly selectedMediaId = signal<string | undefined>(this._matDialogData.selectedMediaId ?? this._matDialogData.medias[0].id);
    readonly selectedMedia = linkedSignal(() => {
        const medias = untracked(() => this.medias());
        const selectedMediaId = this.selectedMediaId();
        return medias.find((m) => m.id === selectedMediaId);
    });

    readonly computedCropBoxAspectRation = signal<AspectRatio | undefined>(undefined);
    readonly aspectRatioButtonValue = signal<AspectRatio | undefined>(undefined);
    readonly rotationButtonValue = signal<number | undefined>(undefined);

    readonly ButtonStyle = ButtonStyle;

    constructor() {
        this._checkSelectedMediaIdExistenceOnMediasChange();
    }

    onClose(): void {
        this._matDialogRef.close();
    }

    onPrimaryClick(): void {
        this._saveSelectedMedia();
        this._matDialogRef.close({ medias: this.medias() });
    }

    onSecondaryClick(): void {
        this._matDialogRef.close();
    }

    onMediaClicked(id: string): void {
        this._saveSelectedMedia();
        const index = this.medias().findIndex((media) => media.id === id);
        if (index !== -1) {
            this.selectedMediaId.set(id);
        }
    }

    onTransformDataChange(transformData: TransformData): void {
        this.selectedMedia.update((m) => {
            if (!m) {
                return m;
            }
            return { ...m, transformData };
        });
    }

    onCropBoxAspectRatioComputed(cropBoxAspectRatio: AspectRatio): void {
        this.computedCropBoxAspectRation.set(cropBoxAspectRatio);
    }

    onAspectRatioChange(aspectRatio: AspectRatio): void {
        this.aspectRatioButtonValue.set(aspectRatio);
    }

    onRotationDirectionChange(rotationDirection: RotationDirection): void {
        const increment =
            rotationDirection === RotationDirection.CLOCKWISE ? ROTATION_INCREMENT_IN_DEGREES : -ROTATION_INCREMENT_IN_DEGREES;
        const currentRotation = this.selectedMedia()?.transformData?.rotate ?? 0;
        this.rotationButtonValue.set(currentRotation + increment);
    }

    private _checkSelectedMediaIdExistenceOnMediasChange(): void {
        effect(
            () => {
                const selectedMediaId = untracked(() => this.selectedMediaId());
                const index = this.medias().findIndex((media) => media.id === selectedMediaId);
                if (index === -1) {
                    this.selectedMediaId.set(this.medias()[0]?.id);
                }
            },
            { allowSignalWrites: true }
        );
    }

    private _saveSelectedMedia(): void {
        const selectedMedia = this.selectedMedia();
        if (!selectedMedia) {
            return;
        }
        this.medias.update((medias) => medias.map((m) => (m.id === selectedMedia.id ? selectedMedia : m)));
    }
}
