import { Action, createReducer, on } from '@ngrx/store';

import { CampaignsActions } from './campaigns.actions';
import { CampaignState, CampaignStatus } from './campaigns.interface';

const initialState: CampaignState = {
    restaurantId: undefined,
    isFooterVisible: false,
    campaignStatus: CampaignStatus.NOT_STARTED,
    error: undefined,
};

const campaignsReducer = createReducer(
    initialState,
    on(CampaignsActions.selectRestaurant, (state, { restaurantId }) => ({ ...state, restaurantId })),
    on(CampaignsActions.editCampaignStatus, (state, { campaignStatus, error }) => ({
        ...state,
        campaignStatus,
        error: error ?? undefined,
    })),
    on(CampaignsActions.setFooterVisibility, (state, { isFooterVisible }) => ({ ...state, isFooterVisible }))
);

export function reducer(state: CampaignState, action: Action): CampaignState {
    return campaignsReducer(state, action);
}
