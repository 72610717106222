<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[90vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"> </app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header !p-4">
            <span>
                {{ 'information.description.edit' | translate }}
            </span>
            <button class="malou-btn-icon" id="tracking_informations_descriptions_cancel_cross" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
        <div class="malou-dialog__body flex flex-col">
            <form class="flex flex-col" [formGroup]="infoForm">
                <mat-tab-group
                    class="mb-4"
                    animationDuration="0"
                    [selectedIndex]="selectedTab"
                    (selectedIndexChange)="handleTabChange($event)">
                    @if (showLong) {
                        <mat-tab class="malou-text-14--medium" label="{{ 'information.description.long' | translate }}"> </mat-tab>
                    }
                    <mat-tab class="malou-text-14--medium" label="{{ 'information.description.short' | translate }}"> </mat-tab>
                </mat-tab-group>
                @if (selectedTab === DescriptionModalTabs.LONG_DESCRIPTION) {
                    <app-text-area
                        placeholder="{{ 'information.description.description' | translate }}"
                        [textAreaId]="TEXT_AREA_ID"
                        [required]="false"
                        [formControl]="getCurrentFormControl()"
                        [maxLength]="descriptionTypes.long.maxLength"
                        [rows]="4"
                        [errorMessage]="
                            isTextLimitReached()
                                ? ('information.description.limit_reached' | translate: { limit: descriptionTypes.long.maxLength })
                                : null
                        ">
                    </app-text-area>
                } @else {
                    <app-text-area
                        placeholder="{{ 'information.description.description' | translate }}"
                        [textAreaId]="TEXT_AREA_ID"
                        [required]="false"
                        [formControl]="getCurrentFormControl()"
                        [maxLength]="descriptionTypes.short.maxLength"
                        [rows]="4"
                        [errorMessage]="
                            isTextLimitReached()
                                ? ('information.description.limit_reached' | translate: { limit: descriptionTypes.short.maxLength })
                                : null
                        ">
                    </app-text-area>
                }

                <p class="malou-full-width malou-text__card-subtitle mb-5 mt-3 flex">
                    {{ getCurrentLength() }} {{ 'forms.text_area.max_length' | translate }}
                </p>
                <div class="grow">
                    @if (!data.restaurant.isBrandBusiness()) {
                        <app-keywords-score-gauge
                            [text$]="descriptionText$.asObservable()"
                            [restaurant$]="restaurant$"
                            [textType$]="textType$"
                            [shouldCacheScore]="false"
                            [keywords$]="restaurantKeywords$"
                            [shouldDisplayTips]="true"
                            [shouldDisplayKeywords]="true"
                            [title]="'information.description.keyword_gauge_title' | translate"
                            (addKeyword)="addKeyword($event)"
                            #keywordsScoreGauge>
                        </app-keywords-score-gauge>
                    }
                </div>
            </form>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button
                class="malou-btn-raised--secondary !h-11 md:grow"
                id="tracking_informations_descriptions_cancel_button"
                mat-raised-button
                (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>

            <app-button
                class="md:grow"
                id="tracking_informations_descriptions_update"
                buttonClasses="!h-11 md:w-full"
                [text]="'common.update' | translate"
                [disabled]="!infoForm.valid || infoForm.pristine"
                (buttonClick)="save()"></app-button>
        </div>
    </div>
</ng-template>
