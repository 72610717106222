import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { ChartDataArray, malouChartColorBluePurple, malouChartColorLighterBlue } from ':shared/helpers';
import { RoiPerformanceScorePathResolver } from ':shared/pipes/roi-performance-score-path-resolver.pipe';

type DoughnutChartType = Extract<ChartType, 'doughnut'>;

const DOUGHNUT_START_ANGLE = -30;
const DOUGHNUT_END_ANGLE = 230;

@Component({
    selector: 'app-performance-gauge-chart',
    standalone: true,
    imports: [NgStyle, NgChartsModule, RoiPerformanceScorePathResolver],
    templateUrl: './performance-gauge-chart.component.html',
    styleUrl: './performance-gauge-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceGaugeChartComponent {
    readonly performanceScore = input.required<number>();

    readonly CHART_TYPE: DoughnutChartType = 'doughnut';

    readonly chartDataSets: Signal<ChartDataset<DoughnutChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.performanceScore())
    );
    readonly chartOptions: Signal<ChartOptions<DoughnutChartType>> = computed(() => this._computeChartOptions());

    readonly gaugeArrowRotateAngle: Signal<{ [key: string]: string }> = computed(() => {
        let angle = DOUGHNUT_START_ANGLE + (this.performanceScore() * (DOUGHNUT_END_ANGLE - DOUGHNUT_START_ANGLE)) / 100;
        if (this.performanceScore() > 50) {
            angle -= 10;
        }

        const defaultCss = {
            transform: `rotate(${angle}deg`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'transform-origin': 'right center',
        };

        if (angle <= 10) {
            return {
                ...defaultCss,
                bottom: '55px',
                left: '30px',
            };
        } else if (angle <= 70) {
            return {
                ...defaultCss,
                bottom: '45px',
                left: '25px',
            };
        } else if (angle <= 100) {
            return {
                ...defaultCss,
                bottom: '45px',
                left: '20px',
            };
        } else if (angle <= 170) {
            return {
                ...defaultCss,
                bottom: '45px',
                left: '15px',
            };
        }
        return {
            ...defaultCss,
            bottom: '60px',
            left: '10px',
        };
    });

    private _computeChartData(performanceScore: number): ChartDataset<DoughnutChartType, ChartDataArray>[] {
        return [
            {
                backgroundColor: [malouChartColorBluePurple, malouChartColorLighterBlue],
                hoverBackgroundColor: [malouChartColorBluePurple, malouChartColorLighterBlue],
                data: [performanceScore, 100 - performanceScore],
                borderWidth: 0,
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<DoughnutChartType> {
        return {
            plugins: {
                tooltip: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
            },
            cutout: '75%',
            circumference: 270,
            rotation: -135,
        };
    }
}
