import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from ':environments/environment';
import { ApiResult, Mention, Reply } from ':shared/models';

@Injectable({ providedIn: 'root' })
export class MentionsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/mentions`;

    constructor(private readonly _http: HttpClient) {}

    refresh(mentionId: string): Observable<ApiResult<Mention>> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${mentionId}/refresh`);
    }

    reply(mentionId: string, reply: Partial<Reply>): Observable<ApiResult<Mention>> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/${mentionId}/reply`, { ...reply });
    }
}
