import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortText',
    standalone: true,
})
export class ShortTextPipe implements PipeTransform {
    transform(text?: string, maxLength = 20): string {
        if (!text) {
            return '';
        }
        if (text.length > maxLength) {
            return `${text?.substring(0, maxLength)}...`;
        }
        return text;
    }
}
