<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>{{ notification().data.event.emoji }}</div>
        <div class="flex flex-col">
            <div class="malou-text-11--semibold text-malou-color-text-1">
                {{
                    'notification-center.post-suggestion-notification.title'
                        | translate
                            : {
                                  notificationName: notification().getNotificationName(),
                                  notificationDate: notification().getFormattedEventDate(),
                              }
                }}
            </div>
            @if (!!notification().getNotificationDescription()) {
                <div class="flex md:!mt-2 md:!flex-col">
                    <div class="malou-card__body-text">
                        <div>
                            <span
                                class="font-normal"
                                [innerHTML]="
                                    isFolded()
                                        ? (notification().getNotificationDescription() | shortText: SHORT_TEXT_LENGTH)
                                        : notification().getNotificationDescription()
                                "></span>
                            @if (notification().getNotificationDescription().length > SHORT_TEXT_LENGTH) {
                                <span
                                    class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5"
                                    (click)="$event.stopPropagation(); toggleFolded()">
                                    {{ (isFolded() ? 'common.see_more' : 'common.see_less') | translate }}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
