<div class="flex gap-3 md:flex-col">
    @if (withIsClosedSelection()) {
        <app-simple-select
            class="w-48 md:w-full"
            [displayWith]="displayIsRestaurantClosedDuringPeriodsWith"
            [defaultValue]="isRestaurantClosedDuringPeriods()"
            [values]="IS_RESTAURANT_CLOSED_OPTIONS"
            [disabled]="disabled()"
            (simpleSelectChange)="updateIsRestaurantClosedDuringPeriods($event)">
            <ng-template let-value="value" #selectedValueTemplate>
                <div class="malou-text-12--medium mt-[1px] text-malou-color-text-1">
                    {{ displayIsRestaurantClosedDuringPeriodsWith | applyPure: value }}
                </div>
            </ng-template>
        </app-simple-select>
    }

    @if (!isRestaurantClosedDuringPeriods()) {
        <div class="flex flex-col gap-3">
            @for (period of periods(); let index = $index; track period; let last = $last) {
                <ng-container [ngTemplateOutlet]="selectPeriodTemplate" [ngTemplateOutletContext]="{ period, index, last }"></ng-container>
            }
        </div>
    }
</div>

<ng-template let-period="period" let-index="index" let-last="last" #selectPeriodTemplate>
    <div class="flex gap-x-3 md:justify-between">
        <div class="flex w-32 flex-col md:w-5/12">
            <app-simple-select
                class="w-full"
                placeholder="----"
                [defaultValue]="period.openTime"
                [values]="TIMES_WITH_24"
                [disabled]="disabled()"
                (simpleSelectChange)="updateOpenTime(index, $event)">
                <ng-template let-value="value" #selectedValueTemplate>
                    <div class="malou-text-12--medium mt-[1px] text-malou-color-text-1">
                        @if (value) {
                            @if (value === TIME_24) {
                                {{ 'common.open_24h' | translate }}
                            } @else {
                                {{ value | formatTime }}
                            }
                        }
                    </div>
                </ng-template>

                <ng-template let-option="option" #optionTemplate>
                    <div class="malou-text-12--regular text-malou-color-text-2">
                        @if (option === TIME_24) {
                            {{ 'common.open_24h' | translate }}
                        } @else {
                            {{ option | formatTime }}
                        }
                    </div>
                </ng-template>
            </app-simple-select>

            @if (getPeriodOpenTimeError | applyPure: period; as openTimeError) {
                <div class="malou-text-10 italic text-malou-color-state-error">
                    {{ openTimeError }}
                </div>
            }
        </div>
        <div class="flex w-32 flex-col md:w-5/12">
            <app-simple-select
                class="w-full"
                placeholder="----"
                [defaultValue]="period.closeTime"
                [values]="TIMES"
                [disabled]="period.openTime === TIME_24 || disabled()"
                (simpleSelectChange)="updateCloseTime(index, $event)">
                <ng-template let-value="value" #selectedValueTemplate>
                    <div class="malou-text-12--medium mt-[1px] text-malou-color-text-1">
                        @if (value) {
                            {{ value | formatTime }}
                        }
                    </div>
                </ng-template>

                <ng-template let-option="option" #optionTemplate>
                    <div class="malou-text-12--regular text-malou-color-text-2">
                        {{ option | formatTime }}
                    </div>
                </ng-template>
            </app-simple-select>

            @if (getPeriodCloseTimeError | applyPure: period; as closeTimeError) {
                <div class="malou-text-10 italic text-malou-color-state-error">
                    {{ closeTimeError }}
                </div>
            }
        </div>

        @if (!disabled()) {
            <div class="margin-top-custom flex gap-x-2">
                @if (periods().length > 1) {
                    <mat-icon
                        class="malou-color-chart-pink--accent !h-4 !w-4 cursor-pointer"
                        [svgIcon]="SvgIcon.INTERDICTION"
                        (click)="deletePeriod(index)"></mat-icon>
                }
                @if (maxPeriodCount() > periods().length) {
                    <mat-icon
                        class="invisible !h-4 !w-4 cursor-pointer text-malou-color-primary"
                        [ngClass]="{ '!visible': last }"
                        [svgIcon]="SvgIcon.ADD"
                        (click)="addPeriod()"></mat-icon>
                }
            </div>
        }
    </div>
</ng-template>
