import { TranslateService } from '@ngx-translate/core';

import { RoiAdditionalCustomersDto } from '@malou-io/package-dto';
import {
    AdditionalCustomerKey,
    AdditionalCustomersRange,
    additionalCustomersSeoKeys,
    additionalCustomersSocialKeys,
    getNumberWithSpaces,
    PlatformCategory,
    PlatformKey,
} from '@malou-io/package-utils';

export interface RoiAdditionalCustomersRow {
    type: PlatformCategory;
    emoji: string;
    title: string;
    subtitle?: string;
    details: string;
    clickValue: number;
    minCustomers: number;
    maxCustomers: number;
}

export class RoiAdditionalCustomersDetails {
    minCustomers: number;
    maxCustomers: number;
    totalCustomers: number;
    previousPeriodTotalCustomers?: number;
    gmbDirectionRequests: AdditionalCustomersRange;
    gmbBooking: AdditionalCustomersRange;
    gmbPhoneCallClicks: AdditionalCustomersRange;
    gmbFoodMenuClicks: AdditionalCustomersRange;
    gmbWebsiteClicks: AdditionalCustomersRange;
    gmbFoodOrders: AdditionalCustomersRange;
    socialDirectionRequests: AdditionalCustomersRange;
    socialPhoneCallClicks: AdditionalCustomersRange;
    socialWebsiteClicks: AdditionalCustomersRange;
    socialEmailContacts: AdditionalCustomersRange;
    socialMessageSent: AdditionalCustomersRange;
    socialImpressions: AdditionalCustomersRange;
    socialShares: AdditionalCustomersRange;
    socialSaves: AdditionalCustomersRange;

    connectedPlatforms: PlatformKey[];

    constructor(init: RoiAdditionalCustomersDto, connectedPlatforms?: PlatformKey[]) {
        this.minCustomers = init.minCustomers;
        this.maxCustomers = init.maxCustomers;
        this.totalCustomers = init.totalCustomers;
        this.previousPeriodTotalCustomers = init.previousPeriodTotalCustomers;

        this.gmbDirectionRequests = init.gmbDirectionRequests;
        this.gmbBooking = init.gmbBooking;
        this.gmbPhoneCallClicks = init.gmbPhoneCallClicks;
        this.gmbFoodMenuClicks = init.gmbFoodMenuClicks;
        this.gmbWebsiteClicks = init.gmbWebsiteClicks;
        this.gmbFoodOrders = init.gmbFoodOrders;
        this.socialDirectionRequests = init.socialDirectionRequests;
        this.socialPhoneCallClicks = init.socialPhoneCallClicks;
        this.socialWebsiteClicks = init.socialWebsiteClicks;
        this.socialEmailContacts = init.socialEmailContacts;
        this.socialMessageSent = init.socialMessageSent;
        this.socialImpressions = init.socialImpressions;
        this.socialShares = init.socialShares;
        this.socialSaves = init.socialSaves;

        this.connectedPlatforms = connectedPlatforms ?? [];
    }

    buildRoiAdditionalCustomersDetails(translate: TranslateService): RoiAdditionalCustomersRow[] {
        const rows: RoiAdditionalCustomersRow[] = [];
        for (const metric of this._getAvailableMetrics()) {
            const title = this._getTitleForMetric(metric, translate);
            const subtitle = this._getSubtitleForMetric(metric, translate);
            const emoji = this._getEmojiForMetric(metric);
            const details = this._getDetailForMetric(metric, translate);
            rows.push({
                type: additionalCustomersSeoKeys.includes(metric) ? PlatformCategory.SEO : PlatformCategory.SOCIAL,
                emoji,
                title,
                subtitle,
                details,
                clickValue: this[metric].clicks,
                minCustomers: this[metric].min,
                maxCustomers: this[metric].max,
            });
        }
        return rows;
    }

    private _getTitleForMetric(metric: AdditionalCustomerKey, translate: TranslateService): string {
        switch (metric) {
            case AdditionalCustomerKey.gmbBooking:
                return translate.instant('roi.estimated_customers.booking');
            case AdditionalCustomerKey.gmbDirectionRequests:
            case AdditionalCustomerKey.socialDirectionRequests:
                return translate.instant('roi.estimated_customers.direction_request');
            case AdditionalCustomerKey.gmbFoodMenuClicks:
                return translate.instant('roi.estimated_customers.menu');
            case AdditionalCustomerKey.gmbFoodOrders:
                return translate.instant('roi.estimated_customers.order');
            case AdditionalCustomerKey.gmbPhoneCallClicks:
            case AdditionalCustomerKey.socialPhoneCallClicks:
                return translate.instant('roi.estimated_customers.call');
            case AdditionalCustomerKey.gmbWebsiteClicks:
            case AdditionalCustomerKey.socialWebsiteClicks:
                return translate.instant('roi.estimated_customers.website');
            case AdditionalCustomerKey.socialEmailContacts:
                return translate.instant('roi.estimated_customers.email');
            case AdditionalCustomerKey.socialMessageSent:
                return translate.instant('roi.estimated_customers.message_sent');
            case AdditionalCustomerKey.socialImpressions:
                return translate.instant('roi.estimated_customers.impressions');
            case AdditionalCustomerKey.socialShares:
                return translate.instant('roi.estimated_customers.shares');
            case AdditionalCustomerKey.socialSaves:
                return translate.instant('roi.estimated_customers.saves');
            default:
                return '';
        }
    }

    private _getSubtitleForMetric(metric: AdditionalCustomerKey, translate: TranslateService): string {
        const instagramOnlyMetric = [
            AdditionalCustomerKey.socialShares,
            AdditionalCustomerKey.socialSaves,
            AdditionalCustomerKey.socialImpressions,
        ];
        if (instagramOnlyMetric.includes(metric)) {
            return translate.instant('roi.estimated_customers.instagram');
        }
        return '';
    }

    private _getEmojiForMetric(metric: AdditionalCustomerKey): string {
        switch (metric) {
            case AdditionalCustomerKey.gmbBooking:
                return 'business_bookings';
            case AdditionalCustomerKey.gmbDirectionRequests:
            case AdditionalCustomerKey.socialDirectionRequests:
                return 'direction_requests';
            case AdditionalCustomerKey.gmbFoodMenuClicks:
                return 'business_food_menu_clicks';
            case AdditionalCustomerKey.gmbFoodOrders:
                return 'business_food_order';
            case AdditionalCustomerKey.gmbPhoneCallClicks:
            case AdditionalCustomerKey.socialPhoneCallClicks:
                return 'phone_call_clicks';
            case AdditionalCustomerKey.gmbWebsiteClicks:
            case AdditionalCustomerKey.socialWebsiteClicks:
                return 'website_clicks';
            case AdditionalCustomerKey.socialEmailContacts:
                return 'contact_email';
            case AdditionalCustomerKey.socialMessageSent:
                return 'message_sent';
            case AdditionalCustomerKey.socialImpressions:
                return 'impressions';
            case AdditionalCustomerKey.socialShares:
                return 'shares';
            case AdditionalCustomerKey.socialSaves:
                return 'saves';
            default:
                return '';
        }
    }

    private _getDetailForMetric(metric: AdditionalCustomerKey, translate: TranslateService): string {
        const notClicks = [AdditionalCustomerKey.socialImpressions, AdditionalCustomerKey.socialShares, AdditionalCustomerKey.socialSaves];
        const prettyClickNb = getNumberWithSpaces(this[metric].clicks);
        if (notClicks.includes(metric)) {
            return `(${prettyClickNb})`;
        }
        return translate.instant('roi.estimated_customers.clicks', { value: prettyClickNb });
    }

    private _getAvailableMetrics(): AdditionalCustomerKey[] {
        let roiAdditionalCustomersMetrics: AdditionalCustomerKey[] = [];
        if (this.connectedPlatforms.includes(PlatformKey.GMB)) {
            roiAdditionalCustomersMetrics = roiAdditionalCustomersMetrics.concat(additionalCustomersSeoKeys);
        }

        if (this.connectedPlatforms.includes(PlatformKey.FACEBOOK) || this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiAdditionalCustomersMetrics = roiAdditionalCustomersMetrics.concat(additionalCustomersSocialKeys);
        }
        return roiAdditionalCustomersMetrics;
    }
}
