<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="translations().primaryButton | translate"
    [secondaryButtonText]="translations().secondaryButton | translate"
    [buttonStyle]="ButtonStyle.LONG_TEXT"
    (close)="close.emit({})"
    (primaryClick)="goToStep.emit({ type: 'relative', value: 1 })"
    (secondaryClick)="close.emit({ openHelpModal: true })">
    <div
        class="malou-color-text-2 malou-text-13--medium"
        [innerHtml]="translations().description | translate: { class: 'malou-text-13--bold' }"></div>
</app-modal-structure>
