import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MaintenanceMode, MaintenanceService } from ':core/maintenance/maintenance.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

import * as AdminActions from '../store/admin.actions';
import { selectMaintenanceMode } from '../store/admin.reducer';

interface AppState {
    admin: {
        maintenance: MaintenanceMode;
    };
}

@Component({
    selector: 'app-developer-settings',
    templateUrl: './developer-settings.component.html',
    styleUrls: ['./developer-settings.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonToggleModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSlideToggleModule,
    ],
})
export class DeveloperSettingsComponent implements OnInit {
    maintenance$: Observable<MaintenanceMode | null> = this._store.select(selectMaintenanceMode);
    maintenance: MaintenanceMode | null = null;
    webhookUriControl = new UntypedFormControl('', [Validators.required]);
    constructor(
        private readonly _store: Store<AppState>,
        private readonly _maintenanceService: MaintenanceService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {
        this.maintenance$.subscribe((maintenance) => {
            this.maintenance = maintenance;
            this.webhookUriControl.setValue(maintenance?.localWebhookUri);
        });
    }

    ngOnInit(): void {}

    updateWebhookStatus(event: MatButtonToggleChange): void {
        const active = event.source.checked;
        this._maintenanceService.updateMaintenance({ webhookRedirectActive: active }).subscribe({
            next: () => {
                if (!this.maintenance) {
                    return;
                }
                this._store.dispatch(
                    AdminActions.editMaintenanceMode({ maintenance: { ...this.maintenance, webhookRedirectActive: active } })
                );
            },
            error: (err) => {
                console.warn('Error updating maintenance: ', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err?.error?.message || err?.message || err));
            },
        });
    }

    updateWebhookUri(): void {
        this._spinnerService.show();
        const uri = this.webhookUriControl.value.replace(/\/+$/, '');
        this._maintenanceService.updateMaintenance({ localWebhookUri: uri }).subscribe({
            next: () => {
                if (!this.maintenance) {
                    return;
                }
                this._store.dispatch(AdminActions.editMaintenanceMode({ maintenance: { ...this.maintenance, localWebhookUri: uri } }));
                this._spinnerService.hide();
                this._toastService.openSuccessToast('Bien ouej webhook setup avec succés');
            },
            error: (e) => {
                console.warn('Error updating maintenance: ', e);
                this._spinnerService.hide();
            },
        });
    }
}
