import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { isNotNil, PlatformDefinitions } from '@malou-io/package-utils';

import { ReviewsRatingsAverageData } from ':modules/aggregated-statistics/e-reputation/reviews-ratings-average/reviews-ratings-average.component';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = ReviewsRatingsAverageData[];

@Injectable({ providedIn: 'root' })
export class AggregatedAverageReviewsRatingsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return data.length !== 0;
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(AggregatedStatisticsSelectors.selectAverageReviewsRatingsData).pipe(take(1));
    }

    protected override _getCsvDataRows(data: Data): CsvAsStringArrays {
        const platformsWithData = this._getPlatformsWithData(data);
        return data.map((d) => {
            const restaurantName = d.restaurant.name;
            const restaurantInternalName = d.restaurant.internalName ?? '';
            const restaurantAddress = d.restaurant.address?.getFullFormattedAddress() ?? '';
            const averageReviewsRatings = typeof d.averageRating === 'number' ? this._shortNumberPipe.transform(d.averageRating) : '';
            const platformsAverageReviewsRatings = this._getPlatformAverageReviewsRatings(d, platformsWithData);
            return [restaurantName, restaurantInternalName, restaurantAddress, averageReviewsRatings, ...platformsAverageReviewsRatings];
        });
    }

    protected override _getCsvHeaderRow(data: Data): CsvAsStringArrays[0] {
        const platformsWithData = this._getPlatformsWithData(data);
        const platformsFullNames = platformsWithData.map((p) => PlatformDefinitions.getPlatformDefinition(p)?.fullName).filter(isNotNil);
        const platformsHeaders = platformsFullNames.map((p) => `Reviews Average Rating (${p})`);
        return ['Location', 'Location Internal Name', 'Location Address', 'Reviews Average Rating (All platforms)', ...platformsHeaders];
    }

    private _getPlatformsWithData(data: Data): string[] {
        const platformsWithData = data.map((d) => d.byPlatforms.map((p) => p.platformKey)).flat();
        return [...new Set(platformsWithData)];
    }

    private _getPlatformAverageReviewsRatings(data: ReviewsRatingsAverageData, platformKeys: string[]): string[] {
        return platformKeys.map((platformKey) => {
            const platformData = data.byPlatforms.find((p) => p.platformKey === platformKey);
            if (!platformData) {
                return '-';
            }
            return typeof platformData.average === 'number' ? this._shortNumberPipe.transform(platformData.average) : '-';
        });
    }
}
