import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PostInsightsDto } from '@malou-io/package-dto';
import { PlatformKey, PostPublicationStatus, PostSource } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ApiResult, Pagination, Story } from ':shared/models';

import { PostsService } from './posts.service';

@Injectable({
    providedIn: 'root',
})
export class PostsInsightsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/post-insights`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _postsService: PostsService
    ) {}

    fetchStoriesInsights({
        restaurantId,
        platformKey,
        storiesSocialIds,
    }: {
        restaurantId: string;
        platformKey: PlatformKey;
        storiesSocialIds: string[];
    }): Observable<PostInsightsDto[]> {
        return this._http
            .post<ApiResult<PostInsightsDto[]>>(
                `${this.API_BASE_URL}/restaurants/${restaurantId}/platforms/${platformKey}/active_stories_insights`,
                {
                    socialIds: storiesSocialIds,
                }
            )
            .pipe(map((res) => res.data));
    }

    getStoriesInsights({
        restaurantId,
        platformKey,
        storiesSocialIds,
    }: {
        restaurantId: string;
        platformKey: PlatformKey;
        storiesSocialIds: string[];
    }): Observable<PostInsightsDto[]> {
        return this._http
            .post<
                ApiResult<PostInsightsDto[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/platforms/${platformKey}/stories_insights`, { socialIds: storiesSocialIds })
            .pipe(map((res) => res.data));
    }

    getStoriesAndInsights$(
        restaurantId: string,
        pagination: Pagination,
        startDate: Date | null,
        endDate: Date | null
    ): Observable<{ stories: Story[]; insights: PostInsightsDto[] }> {
        return this._postsService
            .getRestaurantPostsPaginated(restaurantId, pagination, {
                startDate,
                endDate,
                category: PostSource.SOCIAL,
                source: PostSource.SOCIAL,
                isStory: true,
                publicationStatus: [PostPublicationStatus.PUBLISHED],
                platforms: [PlatformKey.INSTAGRAM], // stories insights only exist for Instagram and not Facebook for now
                sortOrder: -1,
            })
            .pipe(
                map((res) => res.data.posts.map((story) => new Story(story))),
                switchMap((stories) => {
                    const storiesSocialIds = stories.map((story) => story.socialId);
                    return forkJoin({
                        stories: of(stories),
                        insights: storiesSocialIds.length
                            ? this.getStoriesInsights({
                                  restaurantId,
                                  platformKey: PlatformKey.INSTAGRAM,
                                  storiesSocialIds,
                              })
                            : of([]),
                    });
                })
            );
    }

    synchronizeStoriesAndFetchInsights$(restaurantId: string): Observable<{ stories: Story[]; insights: PostInsightsDto[] }> {
        return this._postsService.synchronizeStories(restaurantId).pipe(
            map((res) => res.data.map((story) => new Story(story))),
            switchMap((stories) => {
                // stories insights only exist for Instagram and not Facebook for now
                const igStoriesSocialIds = stories.filter((story) => story.key === PlatformKey.INSTAGRAM).map((story) => story.socialId);
                return forkJoin({
                    stories: of(stories),
                    insights: igStoriesSocialIds.length
                        ? this.fetchStoriesInsights({
                              restaurantId,
                              platformKey: PlatformKey.INSTAGRAM,
                              storiesSocialIds: igStoriesSocialIds,
                          })
                        : of([]),
                });
            }),
            catchError((error) => of(error))
        );
    }
}
