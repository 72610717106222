<div class="flex h-full w-full gap-x-2" (mouseleave)="starHoveredIndex.set(null)">
    @for (_ of 5 | createArray; track $index; let index = $index) {
        <div class="flex-1" (mouseenter)="starHoveredIndex.set(index)">
            <mat-icon
                class="malou-color-primary !h-full !w-full cursor-pointer"
                [svgIcon]="starHoveredIndex() !== null && index <= starHoveredIndex()! ? SvgIcon.STAR : SvgIcon.STAR_BORDER"
                (click)="onStarClicked(index)"></mat-icon>
        </div>
    }
</div>
