<div class="flex flex-col">
    <div class="h-12.5">
        <div class="malou-text-section-title malou-color-text-1">{{ 'statistics.e_reputation.reviews_kpis.title' | translate }}</div>
    </div>
    @if (isLoading()) {
        <app-skeleton skeletonClass="!h-50 secondary-bg"></app-skeleton>
    } @else {
        @if (!httpError) {
            @if (currentTotalReviews || currentAnsweredRepliesRate || currentAverageAnswerTime) {
                <div class="flex gap-6 lg:flex-col">
                    <ng-container
                        [ngTemplateOutlet]="cardTemplate"
                        [ngTemplateOutletContext]="{
                            tooltip: gmbTotalReviewCountTooltip$ | async,
                            currentValue: currentTotalReviews | shortNumber,
                            currentValueRaw: currentTotalReviews,
                            diffValue: diffTotalReviews,
                            diffDisplayedValue: diffTotalReviews | shortNumber: { shouldDisplayMinusSign: false },
                            title: 'statistics.e_reputation.reviews_kpis.reviews' | translate,
                            icon: 'thumb-up',
                        }"></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="cardTemplate"
                        [ngTemplateOutletContext]="{
                            tooltip: 'statistics.e_reputation.reviews_kpis.answer_rate_tooltip' | translate,
                            currentValue: currentAnsweredRepliesRate | shortNumber: { content: '%', fixNumber: 1 },
                            currentValueRaw: currentAnsweredRepliesRate,
                            diffValue: diffAnsweredRepliesRate,
                            diffDisplayedValue:
                                diffAnsweredRepliesRate | shortNumber: { content: 'pts', fixNumber: 1, shouldDisplayMinusSign: false },
                            title: 'statistics.e_reputation.reviews_kpis.answer_rate' | translate,
                            icon: 'conversation',
                        }"></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="cardTemplate"
                        [ngTemplateOutletContext]="{
                            currentValue: currentAverageAnswerTime | formatMillisecondsDuration: { onlyValue: true },
                            currentValueUnit: currentAverageAnswerTime | formatMillisecondsDuration: { onlyUnit: true },
                            currentValueRaw: currentAverageAnswerTime,
                            diffValue: diffAverageAnswerTime,
                            diffDisplayedValue: diffAverageAnswerTime | formatMillisecondsDuration: { onlyValue: true },
                            title: 'statistics.e_reputation.reviews_kpis.average_answer_time' | translate,
                            icon: 'clock',
                            numberEvolutionTooltip: diffAverageAnswerTime | formatMillisecondsDuration: { onlyValue: false },
                            reverseArrow: true,
                        }"></ng-container>
                </div>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'statistics.e_reputation.reviews_kpis.no_data' | translate }}</span>
                </div>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
            </div>
        }
    }
</div>

<ng-template let-icon="icon" #gradientCircleTemplate>
    <div class="malou-background-gradient-5 flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full">
        <mat-icon class="malou-color-white !h-4 !w-4" [svgIcon]="icon"></mat-icon>
    </div>
</ng-template>

<ng-template
    let-tooltip="tooltip"
    let-currentValue="currentValue"
    let-currentValueSuffix="currentValueSuffix"
    let-currentValueRaw="currentValueRaw"
    let-diffValue="diffValue"
    let-diffDisplayedValue="diffDisplayedValue"
    let-title="title"
    let-icon="icon"
    let-reverseArrow="reverseArrow"
    let-numberEvolutionTooltip="numberEvolutionTooltip"
    let-currentValueUnit="currentValueUnit"
    #cardTemplate>
    <div class="malou-simple-card flex flex-1 p-4">
        <div class="flex w-full justify-between">
            <div class="flex gap-4" [matTooltip]="tooltip">
                <ng-container [ngTemplateOutlet]="gradientCircleTemplate" [ngTemplateOutletContext]="{ icon }"></ng-container>
                <div class="flex gap-4 xl:flex-col">
                    <div class="flex items-center">
                        <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none">
                            {{ currentValue }}
                            <span class="malou-text-20--bold malou-color-text-1 mr-2 leading-none">{{ currentValueUnit }}</span>
                        </span>
                    </div>
                    <div class="mb-2 flex items-end gap-2">
                        @if (currentValueSuffix) {
                            <span class="malou-text-12--medium malou-color-text-2"> {{ currentValueSuffix }}. </span>
                        }
                        <span class="malou-text-12--medium malou-color-text-2 whitespace-nowrap">{{ title }}</span>
                    </div>
                </div>
            </div>
            <div class="flex items-center" [matTooltip]="numberEvolutionTooltip">
                @if (currentValueRaw) {
                    <app-number-evolution
                        [value]="diffValue"
                        [size]="'small'"
                        [displayedValue]="diffDisplayedValue"
                        [reverseArrow]="reverseArrow">
                    </app-number-evolution>
                }
            </div>
        </div>
    </div>
</ng-template>
