import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ZenchefConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-zenchef-connection-step-0',
    templateUrl: './zenchef-connection-step-0.component.html',
    styleUrls: ['./zenchef-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZenchefConnectionStep0Component extends BaseStepComponent<undefined, ZenchefConnectionModalResult> {
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;

    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));

    onPrimaryClick(): void {
        window.open('https://app.zenchef.com/zw#/parameters/partners', '_blank');
        this.goToStep.emit({ type: 'relative', value: 1 });
    }

    onTertiaryClick(): void {
        window.open(
            'https://welcomehomemalou.notion.site/Comment-connecter-Zenchef-la-MalouApp-b547bf0932c845168991144a9775170f',
            '_blank'
        );
    }
}
