import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidPinterestUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.PINTEREST })) {
            return false;
        }

        // Try to extract pinterest username
        const userName = getPinterestUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getPinterestUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = decodeURIComponent(trimSlashes(parsedUrl.pathname)); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters and underscores are allowed for pinterest usernames
        const userNameRegex = /^[a-zA-ZÀ-ž0-9_]+$/;

        // /username
        return pathname && userNameRegex.test(pathname) ? encodeURIComponent(pathname) : null;
    } catch {
        return null;
    }
}

export function transformPinterestUrl(url: string): string | null {
    if (!isValidPinterestUrl(url)) return null;
    const userName = getPinterestUserName(url);
    return `https://www.pinterest.com/${userName}`;
}
