import { inject, Injectable, signal } from '@angular/core';
import { filter, switchMap } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';

@Injectable({
    providedIn: 'root',
})
export class AiSettingsContext {
    readonly _keywordsService = inject(KeywordsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);

    readonly restaurantAiSettings = signal<RestaurantAiSettings | null>(null);

    constructor() {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) => this._restaurantAiSettingsService.getRestaurantAiSettings(restaurant.id))
            )
            .subscribe({
                next: (restaurantAiSettings) => {
                    this.restaurantAiSettings.set(restaurantAiSettings);
                },
            });
    }

    async updateAiSettings({
        restaurantAiSettings,
        dispatcher,
    }: {
        restaurantAiSettings: RestaurantAiSettings;
        dispatcher: RestaurantAiSettingsDispatch;
    }): Promise<void> {
        this.restaurantAiSettings.set(restaurantAiSettings);

        await this._restaurantAiSettingsService.updateRestaurantAiSettings({
            restaurantAiSettings,
            restaurantId: restaurantAiSettings.restaurantId,
            restaurantAiSettingsDispatch: dispatcher,
        });
    }
}
