import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { GalleryFilters, IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import * as GalleryActions from '../gallery.actions';
import { GalleryState, selectGalleryFilters } from '../gallery.reducer';
import { FolderCreationModalComponent } from '../modals/folder-creation-modal/folder-creation-modal.component';

@Component({
    selector: 'app-gallery-actions-header',
    templateUrl: './gallery-actions-header.component.html',
    styleUrls: ['./gallery-actions-header.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        TranslateModule,
        NgTemplateOutlet,
        InputTextComponent,
        SearchComponent,
        SelectChipListComponent,
        ApplyPurePipe,
        AsyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryActionsHeaderComponent {
    @Input()
    set hasSelectedMedias(value: boolean) {
        this._hasSelectedMedias = value;
        this._buildMoveActionTooltip();
    }
    get hasSelectedMedias(): boolean {
        return this._hasSelectedMedias;
    }
    @Input()
    set hasSelectedFolders(value: boolean) {
        this._hasSelectedFolders = value;
        this._buildMoveActionTooltip();
    }
    get hasSelectedFolders(): boolean {
        return this._hasSelectedFolders;
    }
    @Input() currentFolder: IFolder | null;
    @Input() restaurantId: string;
    @Output() downloadSelected: EventEmitter<void> = new EventEmitter();
    @Output() moveSelected: EventEmitter<void> = new EventEmitter();
    @Output() deleteSelected: EventEmitter<void> = new EventEmitter();
    @Output() uploadFile: EventEmitter<void> = new EventEmitter();
    @Output() duplicateSelected: EventEmitter<DuplicationDestination> = new EventEmitter();
    @Output() createFolder: EventEmitter<IFolder> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly DuplicationDestination = DuplicationDestination;
    readonly filters$: Observable<GalleryFilters> = this._store.select(selectGalleryFilters);

    filters: GalleryFilters;
    showAdvancedFilters = false;

    readonly moveActionTooltip = signal('');
    private _hasSelectedMedias: boolean;
    private _hasSelectedFolders: boolean;

    readonly isPhoneScreen = toSignal(this.screenSizeService.isPhoneScreen$, { initialValue: this.screenSizeService.isPhoneScreen });

    constructor(
        private readonly _store: Store<GalleryState>,
        private readonly _customDialogService: CustomDialogService,
        private readonly _translateService: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.filters$.subscribe((filters) => (this.filters = filters));
    }

    toggleAdvancedFilters(): void {
        this.showAdvancedFilters = !this.showAdvancedFilters;
    }

    onSearch(search: string): void {
        this._store.dispatch(GalleryActions.editGalleryFilters({ filters: { ...this.filters, title: search } }));
    }

    download(): void {
        this.downloadSelected.emit();
    }

    move(): void {
        this.moveSelected.emit();
    }

    delete(): void {
        this.deleteSelected.emit();
    }

    duplicate(duplicationDestination: DuplicationDestination): void {
        this.duplicateSelected.emit(duplicationDestination);
    }

    onUploadFile(): void {
        this.uploadFile.emit();
    }

    onEditNeverPosterOnlyFilter(event: MatButtonToggleChange): void {
        const filters = { isNeverUsed: event.value as boolean };
        this._store.dispatch({ type: GalleryActions.editGalleryFilters.type, filters });
    }

    onCreateFolder(): void {
        this._customDialogService
            .open(FolderCreationModalComponent, {
                width: this.screenSizeService.isPhoneScreen ? '100%' : '600px',
                height: 'unset',
                data: { currentFolderId: this.currentFolder?.id ?? null, restaurantId: this.restaurantId },
            })
            .afterClosed()
            .subscribe({
                next: (value?: IFolder) => {
                    if (value) {
                        this.createFolder.emit(value);
                    }
                },
            });
    }

    private _buildMoveActionTooltip(): void {
        if (this.hasSelectedFolders) {
            this.moveActionTooltip.set(this._translateService.instant('gallery.cant_move_folders'));
        } else {
            const tooltip = this.hasSelectedMedias
                ? this._translateService.instant('common.move')
                : this._translateService.instant('gallery.must_select_items_before_moving');
            this.moveActionTooltip.set(tooltip);
        }
    }
}
