import { Routes } from '@angular/router';

import { InspirationsRootComponent } from './inspirations-root.component';
import { InspirationsComponent } from './inspirations/inspirations.component';

export const INSPIRATIONS_ROUTES: Routes = [
    {
        path: '',
        component: InspirationsRootComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'new-inspirations',
            },
            {
                path: 'new-inspirations',
                component: InspirationsComponent,
            },
        ],
    },
];
