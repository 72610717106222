import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { times } from ':core/constants';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { formatHours, isBeforeToday, isPastHour, isToday } from ':shared/helpers';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

export interface PersonalizePostDuplicationData {
    restaurant: Restaurant;
    post: INullableFormGroup<{
        status: PostDateStatus;
        date: Date;
        text: string;
        time: string;
    }>;
}

interface PersonalizePostDuplicationSharedData {
    isStory?: boolean;
}

enum PostDuplicationDisplayedColumns {
    RESTAURANT = 'restaurant',
    STATUS = 'status',
    DATE = 'date',
    TIME = 'time',
    TEXT = 'text',
}

const BaseStepPersonalizePostComponent = BaseStepComponent<PersonalizePostDuplicationData[], PersonalizePostDuplicationSharedData>;

@Component({
    selector: 'app-personalize-post-duplication',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        InputDatePickerComponent,
        InputTextComponent,
        PaginatorComponent,
        SearchComponent,
        SelectComponent,
        TextAreaComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        FormatTimePipe,
        FormsModule,
        ImagePathResolverPipe,
        IncludesPipe,
    ],
    templateUrl: './personalize-post-duplication.component.html',
    styleUrls: ['./personalize-post-duplication.component.scss'],
})
export class PersonalizePostDuplicationComponent extends BaseStepPersonalizePostComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly isPastHour = isPastHour;
    readonly isBeforeToday = isBeforeToday;

    readonly DEFAULT_PAGINATION_SIZE = 50;
    readonly PostDateStatus = PostDateStatus;
    readonly POST_DATE_STATUSES = Object.values(PostDateStatus);
    readonly MIN_DATE = new Date();
    readonly DESCRIPTION_MAX_LENGTH = 1500;
    readonly IS_LOADING_ANIMATION_ENABLED = false;
    readonly TIMES: string[] = times;

    displayedColumns: PostDuplicationDisplayedColumns[] = Object.values(PostDuplicationDisplayedColumns);
    restaurantAndPostDataSource: MatTableDataSource<PersonalizePostDuplicationData> =
        new MatTableDataSource<PersonalizePostDuplicationData>([]);

    isEditingText = false;
    selectedRestaurantAndPost?: PersonalizePostDuplicationData;
    initialText?: string;

    constructor(private readonly _translateService: TranslateService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.sharedData?.isStory) {
            this.displayedColumns = this.displayedColumns.filter((col) => col !== PostDuplicationDisplayedColumns.TEXT);
        }

        this.restaurantAndPostDataSource.data = this.inputData;
        this.restaurantAndPostDataSource.filterPredicate = this._getFilterPredicate;

        this.valid.emit(this._isValid());
    }

    onSearchChange(search: string): void {
        this.restaurantAndPostDataSource.filter = search.trim().toLowerCase();
    }

    onStatusChange(status: PostDateStatus, index: number): void {
        this.restaurantAndPostDataSource.filteredData[index]?.post.patchValue({ status });
        this.valid.emit(this._isValid());
    }

    onTimeChange(event: MatSelectChange, index: number): void {
        this.restaurantAndPostDataSource.filteredData[index]?.post.patchValue({ time: event.value });
        this.valid.emit(this._isValid());
    }

    onTimeInputChange(event: Event, index: number): void {
        const value: string = (event.target as HTMLInputElement).value;
        this.onTimeChange({ value } as MatSelectChange, index);
    }

    onDateChange(): void {
        this.valid.emit(this._isValid());
    }

    displayStatus = (status: PostDateStatus): string => this._translateService.instant(`posts.new_post.${status}`);

    buildStatus = (item: PostDateStatus): PostDateStatus => item;

    selectPostAndEditText(restaurantAndPost: PersonalizePostDuplicationData): void {
        this.selectedRestaurantAndPost = restaurantAndPost;
        this.initialText = restaurantAndPost.post.controls.text.value ?? undefined;
        this.isEditingText = true;
        this.showButtons.emit(false);
        this.valid.emit(false);
    }

    closeEditText(cancel: boolean): void {
        this.isEditingText = false;
        if (cancel) {
            this.selectedRestaurantAndPost?.post.patchValue({ text: this.initialText });
        }
        this.selectedRestaurantAndPost = undefined;
        this.initialText = undefined;
        this.showButtons.emit(true);
        this.valid.emit(this._isValid());
    }

    getErrorMessage = (text?: string | null): string => (text ? '' : this._translateService.instant('common.field_required'));

    getSelectableTimes = (index: number): string[] => {
        const date = this.restaurantAndPostDataSource.data[index].post.value.date;

        if (date && isToday(date)) {
            const now = new Date();
            const currentTime = formatHours(now);
            return this.TIMES.filter((time) => time > currentTime);
        }

        return this.TIMES;
    };

    protected _submitData(): PersonalizePostDuplicationData[] {
        return this.restaurantAndPostDataSource.data;
    }

    protected _isValid(): boolean {
        return this.restaurantAndPostDataSource.data.every((restaurantAndPost) => {
            const date = this._getDateWithTime(restaurantAndPost.post.value.date ?? null, restaurantAndPost.post.value.time ?? null);
            const now = new Date();
            const isFutureDate = date && date.getTime() > now.getTime();
            return restaurantAndPost.post.valid && (restaurantAndPost.post.value.status === PostDateStatus.NOW || isFutureDate);
        });
    }

    private _getFilterPredicate = (restaurantAndPost: PersonalizePostDuplicationData, filter: string): boolean =>
        restaurantAndPost.restaurant.name.trim().toLowerCase().includes(filter) ||
        (!restaurantAndPost.restaurant.isBrandBusiness() &&
            restaurantAndPost.restaurant.fullFormattedAddress?.trim().toLowerCase().includes(filter));

    private _getDateWithTime(date: Date | null, time: string | null): Date | undefined {
        if (!date) {
            return undefined;
        }
        if (!time) {
            return date;
        }
        const dateWithTime = DateTime.fromJSDate(date);
        const [hours, minutes] = time.split(':');
        return dateWithTime.set({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10) }).toJSDate();
    }
}
