@if (shouldDisplayMessagesNewStartDate) {
    <div class="malou-text-12--regular mb-8 grid w-full place-items-center text-malou-color-text-2">
        {{ getMessageDateOrToday | applyPure: message.socialCreatedAt }}
    </div>
}
<div class="mb-3 flex w-full justify-end" [class.flex-row-reverse]="!message.isFromRestaurant">
    <div class="flex flex-col items-start">
        <!-- Story -->
        @if (message.story) {
            <ng-container *ngTemplateOutlet="story"></ng-container>
        }

        <!-- story end -->

        <div
            class="flex w-full flex-col"
            [class.ml-7]="!message.isFromRestaurant && !shouldDisplayProfilePicture"
            (dblclick)="updateReaction($event)">
            <div
                class="flex items-center"
                [ngClass]="{ 'flex-row-reverse': message.isFromRestaurant, 'flex-row': !message.isFromRestaurant }">
                <div
                    class="malou-card !m-0 flex !max-w-[85%] !flex-[none] flex-col justify-between !px-6 !py-4"
                    [class.!bg-malou-color-background-dark]="message.isFromRestaurant"
                    [class.!border-malou-color-state-error]="message.status === 'ERROR'"
                    [class.opacity-50]="message.status === 'PENDING'">
                    @if (message.isUnsupportedAttachment) {
                        <div
                            class="malou-text-12--regular text-malou-color-text-2"
                            innerHTML="{{ 'messages.attachment_not_supported' | translate }}"></div>
                    }
                    <div class="malou-text-10--regular mb-4 text-malou-color-text-2">
                        {{ getMessageDate | applyPure: message.socialCreatedAt }}
                    </div>
                    @if (message.isDeleted) {
                        <div>
                            <span class="malou-text-11--regular text-nowrap italic text-malou-color-text-2">
                                {{ 'messages.message_has_been_deleted' | translate }}
                            </span>
                        </div>
                    }
                    @if (message.text) {
                        <div
                            class="malou-text-12--regular text-malou-color-text-1"
                            [innerHTML]="message | applySelfPure: 'getMessageTextHtml'"></div>
                    }
                    @if (message.attachments.length > 0) {
                        <div>
                            @for (attachment of message.attachments; track attachment) {
                                @switch (attachment.type) {
                                    @case ('image') {
                                        <img
                                            class="message-media"
                                            width="200"
                                            height="200"
                                            [lazyLoad]="attachment.url"
                                            [alt]="attachment.name"
                                            (click)="openCarousel()" />
                                    }
                                    @case ('video') {
                                        <video
                                            class="message-media"
                                            width="200"
                                            height="200"
                                            autoplay
                                            loop
                                            controls
                                            [src]="attachment.url"
                                            [muted]="'muted'"
                                            (click)="openCarousel()"></video>
                                    }
                                    @case ('audio') {
                                        <video
                                            class="message-media"
                                            width="300"
                                            height="60"
                                            controls
                                            [src]="attachment.url"
                                            [muted]="'muted'"
                                            (click)="openCarousel()"></video>
                                    }
                                    @case ('file') {
                                        <div class="flex items-center">
                                            <mat-icon class="malou-color-grey-light small-icon mr-2">download</mat-icon>
                                            <a target="_blank" [href]="attachment.url">
                                                {{ attachment.name || ('messages.file' | translate) }}
                                            </a>
                                        </div>
                                    }
                                }
                            }
                        </div>
                    }
                </div>
                @if (message | applySelfPure: 'canSendReaction') {
                    <div
                        class="mx-2 flex items-center rounded-[30px] bg-malou-color-background-dark p-1"
                        [class.flex-row-reverse]="!message.isFromRestaurant">
                        @for (reaction of message | applySelfPure: 'getReactionsFromUsers'; track reaction) {
                            <div class="relative">
                                <img
                                    class="mx-2 h-[11px] rounded-full"
                                    height="11"
                                    width="11"
                                    [lazyLoad]="reaction?.userInfo?.displayName | avatar" />
                            </div>
                        }
                        <div class="icons-small relative grid cursor-pointer place-items-center" (click)="updateReaction($event)">
                            @if (
                                !message.reactions.length ||
                                !(message | applySelfPure: 'hasReactionsFromRestaurant' : message.reactions.length)
                            ) {
                                <mat-icon color="primary" [svgIcon]="SvgIcon.HEART"> </mat-icon>
                            }
                            @if (message | applySelfPure: 'hasReactionsFromRestaurant' : message.reactions.length) {
                                <mat-icon color="primary" [svgIcon]="SvgIcon.HEART_FILLED"> </mat-icon>
                            }
                        </div>
                    </div>
                }

                @if (message.status === 'PENDING') {
                    <app-malou-spinner size="small"></app-malou-spinner>
                }
            </div>
            @if (message.status === 'ERROR') {
                <span class="malou-color-state-error malou-text-10--regular mt-2 self-end">
                    <i>{{ 'messages.message_error' | translate }}</i>
                    <button
                        class="malou-text-10--semibold"
                        matRipple
                        [matTooltip]="'messages.error_sending_message_tooltip' | translate"
                        (click)="retrySendMessage()">
                        {{ 'messages.error_sending_message' | translate }}
                    </button>
                </span>
            }
        </div>
    </div>
    @if (!message.isFromRestaurant && shouldDisplayProfilePicture) {
        <img class="mr-2 h-[20px] rounded-full" height="20" width="20" [lazyLoad]="getAvatarUrl | applyPure: message" />
    }
</div>

<ng-template #story>
    <div [class.ml-7]="!message.isFromRestaurant && !shouldDisplayProfilePicture">
        @switch (message.story.storyType) {
            @case ('mention') {
                <div>
                    <p class="malou-text-12--regular text-malou-color-text-2" [innerHTML]="getMentionStoryText | applyPure: message"></p>
                </div>
            }
            @case ('reply_to') {
                <div>
                    <p class="malou-text-12--regular text-malou-color-text-2" [innerHTML]="getReplyToStoryText | applyPure: message"></p>
                </div>
            }
        }
        <div class="cursor-pointer border-l-2 border-solid border-malou-color-background-dark p-3">
            @switch (messageStoryMediaType$ | async) {
                @case ('video') {
                    <video
                        class="rounded-2xl object-contain"
                        width="90"
                        height="125"
                        [muted]="'muted'"
                        (click)="openStory(MediaType.VIDEO)">
                        <source type="video/mp4" [src]="message.story.storySocialUrl" />
                    </video>
                }
                @default {
                    <img
                        class="rounded-2xl object-contain"
                        width="90"
                        height="125"
                        [lazyLoad]="message.story.storySocialUrl"
                        (click)="openStory(MediaType.PHOTO)" />
                }
            }
        </div>
    </div>
</ng-template>
