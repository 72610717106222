import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'canBeReplied',
    standalone: true,
})
export class CanBeRepliedPipe implements PipeTransform {
    transform(review: Review | PrivateReview): boolean {
        return review.canBeReplied();
    }
}
