<div class="malou-dialog h-full">
    <div class="malou-dialog__header !pb-2">
        <div class="flex flex-col pr-1">
            <span class="malou-text-18--bold sm:malou-text-14--bold">
                {{ 'roi.aggregated_settings.title' | translate }}
            </span>

            <span class="malou-text-12--regular malou-color-text-2 sm:malou-text-10--regular italic">
                @if (data.isFromNotification) {
                    {{ 'roi.settings.confirm_settings' | translate }}{{ ' ' }}{{ 'roi.settings.data_is_confidential' | translate }}
                } @else {
                    {{ 'roi.settings.new_settings_effect' | translate }}
                }
            </span>
        </div>

        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !overflow-hidden pb-4 sm:!overflow-y-scroll">
        <app-upsert-aggregated-roi-settings
            [isFromModal]="true"
            [isFromNotification]="data.isFromNotification ?? false"
            (onCancel)="close()"
            (onSave)="close(true)"></app-upsert-aggregated-roi-settings>
    </div>
</div>
