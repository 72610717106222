import { Routes } from '@angular/router';

import { WheelsOfFortuneRootComponent } from './wheels-of-fortune-root.component';

export const WHEELS_OF_FORTUNE_ROUTES: Routes = [
    {
        path: '',
        component: WheelsOfFortuneRootComponent,
    },
];
