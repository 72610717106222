import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, output, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { combineLatest, debounceTime } from 'rxjs';

import { SocialPostsListFilter } from '@malou-io/package-utils';

import { SocialPostItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-item.component';
import { SocialPostsListHeaderComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/social-posts-list-header.component';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Pagination } from ':shared/models';

@Component({
    selector: 'app-social-posts-list-v2',
    templateUrl: './social-posts-list.component.html',
    styleUrls: ['./social-posts-list.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, SocialPostsListHeaderComponent, SocialPostItemComponent, InfiniteScrollDirective, SkeletonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostsListV2Component implements OnInit {
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();

    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _destroyRef = inject(DestroyRef);

    readonly posts: Signal<SocialPostItem[]> = this._socialPostsContext.posts;
    readonly isFetchingPosts: Signal<boolean> = this._socialPostsContext.isFetchingPosts;
    readonly isFetchingMorePosts: Signal<boolean> = this._socialPostsContext.isFetchingMorePosts;

    readonly selectedFilter: WritableSignal<SocialPostsListFilter> = this._socialPostsContext.selectedFilter;
    readonly selectedFilter$ = toObservable(this.selectedFilter);
    readonly pagination: Signal<Pagination> = this._socialPostsContext.pagination;
    readonly pagination$ = toObservable(this.pagination);

    readonly restaurant$ = this._socialPostsContext.restaurant$;

    ngOnInit(): void {
        this.selectedFilter$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.resetPagination();
        });

        this.restaurant$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.resetFilter();
            this._socialPostsContext.resetPagination();
        });

        // debounceTime(0) is used to make sure the observable is triggered after the previous observables
        combineLatest([this.restaurant$, this.selectedFilter$, this.pagination$])
            .pipe(debounceTime(0), takeUntilDestroyed(this._destroyRef))
            .subscribe(([restaurant, selectedFilter, pagination]) => {
                this._socialPostsContext.fetchPosts(selectedFilter, pagination, restaurant.id);
            });
    }

    onScrollDown(): void {
        const currentPagination = this.pagination();
        if (this.posts().length === (currentPagination.pageNumber + 1) * currentPagination.pageSize) {
            this._socialPostsContext.goNextPage();
        }
    }

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }
}
