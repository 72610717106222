// TODO EPHEMERA : delete when no longer need custom settings (BUT do not delete before 15 april 2025)
export enum EphemeraGiftKey {
    epOrPd = 'epOrPd',
    tenPercent = 'tenPercent',
    twentyPercent = 'twentyPercent',
    thirtyPercent = 'thirtyPercent',
    hotDrink = 'hotDrink',
    cocktail = 'cocktail',
    dessert = 'dessert',
}

const EPHEMERA_CUSTOM_IDS = {
    local: {
        restaurantId: '6268039d8de2974d89ebd445', // Churras dev
        wheelOfFortuneId: '65cde3e21c8ff08a2484b042',
        [EphemeraGiftKey.epOrPd]: '66fab23a1fba2c3561444ea9',
        [EphemeraGiftKey.tenPercent]: '662fc2334730edc0cbd5d73a',
        [EphemeraGiftKey.twentyPercent]: '662fc2334730edc0cbd5d736',
        [EphemeraGiftKey.thirtyPercent]: '662fc2334730edc0cbd5d732',
        [EphemeraGiftKey.hotDrink]: '66fbef4158c72b0532c05e25',
        [EphemeraGiftKey.cocktail]: '66fbef4158c72b0532c05e29',
        [EphemeraGiftKey.dessert]: '66fbef4158c72b0532c05e21',
    },
    development: {
        restaurantId: '6268039d8de2974d89ebd445', // Churras
        wheelOfFortuneId: '65cde3e21c8ff08a2484b042',
        [EphemeraGiftKey.epOrPd]: '66fab23a1fba2c3561444ea9',
        [EphemeraGiftKey.tenPercent]: '662fc2334730edc0cbd5d73a',
        [EphemeraGiftKey.twentyPercent]: '662fc2334730edc0cbd5d736',
        [EphemeraGiftKey.thirtyPercent]: '662fc2334730edc0cbd5d732',
        [EphemeraGiftKey.hotDrink]: '66fbef4158c72b0532c05e25',
        [EphemeraGiftKey.cocktail]: '66fbef4158c72b0532c05e29',
        [EphemeraGiftKey.dessert]: '66fbef4158c72b0532c05e21',
    },
    staging: {
        restaurantId: '650429e3b1060bba3d3402ab', // Churras
        wheelOfFortuneId: '661f8c33ff8ca1d9e6efbae9',
        [EphemeraGiftKey.epOrPd]: '66fbf0c9e2861c4e553dcd59',
        [EphemeraGiftKey.tenPercent]: '66fab296e2861c4e553dc8df',
        [EphemeraGiftKey.twentyPercent]: '66fbf0c9e2861c4e553dcd5d',
        [EphemeraGiftKey.thirtyPercent]: '66fab296e2861c4e553dc8e7',
        [EphemeraGiftKey.hotDrink]: '66fbf0c9e2861c4e553dcd55',
        [EphemeraGiftKey.cocktail]: '66fab296e2861c4e553dc8db',
        [EphemeraGiftKey.dessert]: '66fab296e2861c4e553dc8e3',
    },
    production: {
        restaurantId: '651e6fac2020185069f37266', // Jungle Palace
        wheelOfFortuneId: '66faa5ddb0395adec6d991c0',
        [EphemeraGiftKey.epOrPd]: '66faa5ddb0395adec6d991ab',
        [EphemeraGiftKey.tenPercent]: '66faa5ddb0395adec6d991bb',
        [EphemeraGiftKey.twentyPercent]: '66faa5ddb0395adec6d991a2',
        [EphemeraGiftKey.thirtyPercent]: '66faa5ddb0395adec6d991af',
        [EphemeraGiftKey.hotDrink]: '66faa5ddb0395adec6d991b3',
        [EphemeraGiftKey.cocktail]: '66faa5ddb0395adec6d991a7',
        [EphemeraGiftKey.dessert]: '66faa5ddb0395adec6d991b7',
    },
};

export const EPHEMERA_ASSOCIATED_RESTAURANTS = [
    {
        id: '6232fece3950f08703ec1f4f',
        name: 'Under The Sea Restaurant - Ephemera',
        address: {
            formattedAddress: '160 Av. de France',
            locality: 'Paris',
            country: 'France',
            regionCode: 'FR',
        },
        logo: null,
    },
    {
        id: '64527a2a737668fe4c2bbe9b',
        name: 'Stellar Restaurant - Ephemera',
        address: {
            formattedAddress: '45 rue saint sébastien',
            locality: 'Paris',
            country: 'France',
            regionCode: 'FR',
        },
        logo: null,
    },
];

export const getEphemeraRestaurantIdAndSpecialGiftIdForEnv = (
    env: string
): {
    restaurantId: string;
    wheelOfFortuneId: string;
    [EphemeraGiftKey.epOrPd]: string;
    [EphemeraGiftKey.tenPercent]: string;
    [EphemeraGiftKey.twentyPercent]: string;
    [EphemeraGiftKey.thirtyPercent]: string;
    [EphemeraGiftKey.hotDrink]: string;
    [EphemeraGiftKey.cocktail]: string;
    [EphemeraGiftKey.dessert]: string;
} => {
    return (
        EPHEMERA_CUSTOM_IDS[env] ?? {
            restaurantId: '',
            wheelOfFortuneId: '',
            epOrPd: '',
            tenPercent: '',
            twentyPercent: '',
            thirtyPercent: '',
            hotDrink: '',
            cocktail: '',
            dessert: '',
        }
    );
};
