<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [buildValueFromText]="buildValueFromText()"
    [control]="inputAutoCompleteControl"
    [onAddValue]="onAddValue()"
    [showSelectAllCheckbox]="false"
    [showValuesSelectedCount]="showValuesSelectedCount()"
    [maxSelectableValues]="maxSelectableValues()"
    [valuesSelectedCountMessage]="'common.recipients' | translate"
    [groupSelectedValuesAtTop]="true"
    [multiSelectionElementWrap]="multiSelectionElementWrap()"
    [shouldSwitchToWrapModeOnClick]="shouldSwitchToWrapModeOnClick()"
    [defaultEmptyValueMessage]="defaultEmptyValueMessage()"
    [shouldUpdateValuesToDisplayAfterSelection]="shouldUpdateValuesToDisplayAfterSelection()"
    [sortBy]="sortBy"
    [selectedValues]="selectedValues()"
    [disabled]="disabled()"
    (selectBaseChange)="selectRecipientsChange.emit($event)">
    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
        <div class="malou-chip malou-chip--primary">
            <span>{{ displayWith | applyPure: value }}</span>
            <mat-icon
                class="malou-chip-icon--right malou-color-primary"
                [svgIcon]="SvgIcon.REMOVE"
                (click)="deleteValueAt(index)"></mat-icon>
        </div>
    </ng-template>
    <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
        <div class="flex items-center gap-x-2">
            <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">{{ value }}</div>
        </div>
    </ng-template>

    @if (inputAutoCompleteControl.value.length > 0) {
        <ng-template #valueBuilderTemplate>
            @if (inputAutoCompleteControl.value?.length > 0 && !(doesItemExist | applyPure: inputAutoCompleteControl.value : values())) {
                <div class="malou-chip malou-chip--primary mx-4 flex items-center">
                    <span>
                        {{ inputAutoCompleteControl.value }}
                    </span>
                    <mat-icon class="ml-2 !h-3.5 !w-3.5 text-malou-color-primary" [svgIcon]="SvgIcon.ADD"> </mat-icon>
                </div>
            }
        </ng-template>
    }
</app-select-base>
