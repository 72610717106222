import { Routes } from '@angular/router';

import { GeneratorComponent } from ':modules/generator/generator.component';

export const GENERATOR_ROUTES: Routes = [
    {
        path: '',
        component: GeneratorComponent,
    },
];
