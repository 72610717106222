import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import {
    GiftClaimDurationInDaysOption,
    GiftClaimStartDateOption,
    NextDrawEnabledDelay,
    PlatformDefinitions,
    PlatformKey,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import {
    AppRedirection,
    CloseModalSettings,
} from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/upsert-wheel-of-fortune-modal.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { IFormGroup } from ':shared/interfaces/form-control-record.interface';
import { getNfcWithRestaurantDisplayName, RestaurantWithTotemsAndWheels } from ':shared/models';
import { WheelOfFortuneGlobalSettings } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-wheel-of-fortune-global-settings',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        FormsModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        InputDatePickerComponent,
        SimpleSelectComponent,
        SelectComponent,
        SelectBaseComponent,
        EnumTranslatePipe,
        PluralTranslatePipe,
        PlatformLogoComponent,
        SelectBaseComponent,
        ApplyPurePipe,
    ],
    templateUrl: './wheel-of-fortune-global-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGlobalSettingsComponent implements OnInit {
    @Input() globalSettings: WritableSignal<WheelOfFortuneGlobalSettings>;
    @Input() selectedRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]>;
    @Input() selectedTotems: WritableSignal<NfcWithRestaurantDto[]>;
    @Input() isAggregatedView: boolean;
    @Input() isDisabled: boolean;
    readonly onClose = output<CloseModalSettings>();

    readonly SvgIcon = SvgIcon;
    readonly TODAY = DateTime.now().startOf('day').toJSDate();
    readonly GIFT_CLAIM_DURATION_IN_DAYS_OPTIONS = GiftClaimDurationInDaysOption;
    readonly redirectPlatformOptions: WritableSignal<WheelOfFortuneRedirectionPlatformKey[]> = signal([PlatformKey.GMB]);
    readonly GiftClaimStartDateOption = GiftClaimStartDateOption;
    readonly WheelOfFortuneRedirectionPlatformKey = WheelOfFortuneRedirectionPlatformKey;
    readonly nextDrawEnabledDelayOptions = Object.values(NextDrawEnabledDelay);
    readonly NextDrawEnabledDelay = NextDrawEnabledDelay;

    readonly filteredRedirectPlatformOptions = computed(() => (index: number): WheelOfFortuneRedirectionPlatformKey[] => {
        const currentSelectedPlatform = this.globalSettings().redirectionSettings.platforms[index].platformKey;
        const otherSelectedPlatforms = this.globalSettings()
            .redirectionSettings.platforms.filter((_platform, i) => i !== index)
            .map((platform) => platform.platformKey);
        return this.redirectPlatformOptions().filter(
            (platform) => !otherSelectedPlatforms.includes(platform) || platform === currentSelectedPlatform
        );
    });

    readonly maxRedirectionPlatformsCount = computed(() => this.redirectPlatformOptions().length);
    readonly shouldShowRedirectionAfterNthTimes = computed(() => {
        const globalSettings = this.globalSettings();
        return (
            globalSettings.redirectionSettings.nextDrawEnabledDelay !== NextDrawEnabledDelay.NEVER &&
            globalSettings.redirectionSettings.shouldRedirect
        );
    });

    totemsControl: FormControl<NfcWithRestaurantDto[]> = new FormControl([]) as FormControl<NfcWithRestaurantDto[]>;
    totems: NfcWithRestaurantDto[];
    giftClaimDurationInDaysControl: FormControl;
    wheelOfFortuneDatesForm: IFormGroup<{
        startDate: Date | null;
        endDate: Date | null;
    }>;
    redirectionSettingsForm: IFormGroup<{
        nextDrawEnabledDelay: NextDrawEnabledDelay | null;
        shouldRedirect: boolean | null;
    }>;

    readonly sortedRedirectionPlatforms = computed<{ order: number; platformKey: WheelOfFortuneRedirectionPlatformKey }[]>(() =>
        this.globalSettings().redirectionSettings.platforms.sort((a, b) => a.order - b.order)
    );
    readonly firstTimeWithoutRedirection = computed<number>(() => this.globalSettings().redirectionSettings.platforms.length + 1);

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    ngOnInit(): void {
        if (!this.isAggregatedView) {
            this._initTotemsControl();
        }
        this._initWofDatesForm();
        this._initRedirectionSettingsForm();
        this._initGiftClaimDurationInDaysControl();
        const selectedRestaurantIds = this.selectedRestaurants()
            .map((restaurant) => restaurant.id)
            .filter(Boolean);

        if (!selectedRestaurantIds) {
            return;
        }
        if (!this.isAggregatedView && this._restaurantsService.currentRestaurant?.isBrandBusiness()) {
            this.redirectPlatformOptions.set([PlatformKey.INSTAGRAM]);
            this.globalSettings.update((settings) => ({
                ...settings,
                redirectionSettings: {
                    ...settings.redirectionSettings,
                    platforms: [{ order: 0, platformKey: PlatformKey.INSTAGRAM }],
                },
            }));
        } else if (selectedRestaurantIds.length) {
            this._platformsService
                .getPlatformsForMultipleRestaurants({
                    restaurantIds: selectedRestaurantIds,
                    keys: PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection(),
                })
                .subscribe({
                    next: (res) => {
                        this.redirectPlatformOptions.set([]);
                        const platforms = this._sortGmbFirst(PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection());
                        platforms.forEach((platformKey) => {
                            const existingPlatforms = res.data?.filter((platform) => platform.key === platformKey);
                            if (this.isAggregatedView && existingPlatforms.length > 0) {
                                this.redirectPlatformOptions.update((redirectPlatformOptions) => [
                                    ...redirectPlatformOptions,
                                    platformKey as WheelOfFortuneRedirectionPlatformKey,
                                ]);
                            }
                            if (!this.isAggregatedView && existingPlatforms.length) {
                                this.redirectPlatformOptions.update((redirectPlatformOptions) => [
                                    ...redirectPlatformOptions,
                                    platformKey as WheelOfFortuneRedirectionPlatformKey,
                                ]);
                            }
                        });

                        this.redirectPlatformOptions.update((redirectPlatformOptions) => [...redirectPlatformOptions]);

                        const invalidPlatformOrders: number[] = [];
                        this.globalSettings().redirectionSettings.platforms.forEach((platform) => {
                            if (!this.redirectPlatformOptions().includes(platform.platformKey)) {
                                invalidPlatformOrders.push(platform.order);
                            }
                        });
                        invalidPlatformOrders.forEach((order) => this.removeRedirectionPlatform(order));
                    },
                });
        } else {
            const platformsOptions = this._sortGmbFirst(PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection()).map(
                (platformKey) => platformKey as WheelOfFortuneRedirectionPlatformKey
            );
            this.redirectPlatformOptions.set([...platformsOptions]);
        }
    }

    totemsSortBy = (a: NfcWithRestaurantDto, b: NfcWithRestaurantDto): number =>
        getNfcWithRestaurantDisplayName(a) > getNfcWithRestaurantDisplayName(b) ? 1 : -1;

    totemDisplayWith = (option: NfcWithRestaurantDto): string =>
        option.chipName
            ? [option.name || '', option.chipName].filter(Boolean).join(' - ')
            : this._translateService.instant('admin.nfcs.type.sticker');

    toggleIsEndDateMandatory(): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            isEndDateMandatory: !settings.isEndDateMandatory,
        }));
    }

    onGiftClaimStartDateOptionChange(event: MatRadioChange): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            giftClaimStartDateOption: event.value,
        }));
    }

    onGiftClaimDurationInDaysChange(giftClaimDurationInDays: number): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            giftClaimDurationInDays,
        }));
    }

    onRedirectionPlatformChange(platformKey: WheelOfFortuneRedirectionPlatformKey, index: number): void {
        this.globalSettings.update((settings) => {
            const platforms = settings.redirectionSettings.platforms;
            platforms[index] = { ...platforms[index], platformKey };
            return {
                ...settings,
                redirectionSettings: {
                    ...settings.redirectionSettings,
                    platforms,
                },
            };
        });
    }

    onNextDrawEnabledDelayChange(nextDrawEnabledDelay: NextDrawEnabledDelay | null): void {
        if (!nextDrawEnabledDelay) {
            return;
        }
        this.globalSettings.update((settings) => ({
            ...settings,
            redirectionSettings: {
                ...settings.redirectionSettings,
                nextDrawEnabledDelay,
            },
        }));
    }

    onShouldRedirectOptionChange(event: MatRadioChange): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            redirectionSettings: {
                ...settings.redirectionSettings,
                shouldRedirect: event.value,
                platforms:
                    event.value && settings.redirectionSettings.platforms.length === 0
                        ? [{ order: 0, platformKey: this.redirectPlatformOptions()[0] }]
                        : settings.redirectionSettings.platforms,
            },
        }));
    }

    displayGiftClaimDurationInDaysOptionsWith = (giftClaimDurationInDays: number): string => {
        switch (giftClaimDurationInDays) {
            // values of GiftClaimDurationInDaysOption
            case 2:
            case 7:
            case 15:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.gift_claim_duration_in_days_option',
                    { count: giftClaimDurationInDays }
                );
                break;
            case 30:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.one_month'
                );
            case 60:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.two_month'
                );
            case 182:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.six_month'
                );
                break;
        }
        return '';
    };

    getMinEndDate = (): Date | undefined => {
        const startDate = this.wheelOfFortuneDatesForm.get('startDate')?.value;
        return startDate ? DateTime.fromJSDate(startDate).startOf('day').toJSDate() : undefined;
    };

    onTotemSelectionChange(event: NfcWithRestaurantDto[]): void {
        this.totemsControl.setValue(event);
        this.selectedTotems.set(this.totemsControl.value);
    }

    addRedirectionPlatform(): void {
        const redirectionPlatformsCount = this.globalSettings().redirectionSettings.platforms.length;
        const availablePlatforms = this.redirectPlatformOptions().filter(
            (platform) => !this.globalSettings().redirectionSettings.platforms.some((p) => p.platformKey === platform)
        );
        const newRedirectionPlatform = {
            order: redirectionPlatformsCount,
            platformKey: availablePlatforms[0],
        };
        this.globalSettings.update((settings) => ({
            ...settings,
            redirectionSettings: {
                ...settings.redirectionSettings,
                platforms: [...settings.redirectionSettings.platforms, newRedirectionPlatform],
            },
        }));
    }

    removeRedirectionPlatform(order: number): void {
        this.globalSettings.update((settings) => {
            const platforms = settings.redirectionSettings.platforms
                .filter((platformWithOrder) => platformWithOrder.order !== order)
                .map((platform) => (platform.order > order ? { ...platform, order: platform.order - 1 } : platform));
            return {
                ...settings,
                redirectionSettings: {
                    ...settings.redirectionSettings,
                    platforms,
                },
            };
        });
    }

    close({
        shouldCheckBeforeClose = false,
        restaurantId,
        restaurantIdInAggregatedWheelOfFortune,
        redirection = AppRedirection.SETTINGS,
    }: CloseModalSettings): void {
        this.onClose.emit({ shouldCheckBeforeClose, restaurantId, restaurantIdInAggregatedWheelOfFortune, redirection });
    }

    private _initWofDatesForm(): void {
        this.wheelOfFortuneDatesForm = new FormGroup({
            startDate: new FormControl({ value: this.globalSettings().startDate, disabled: this.isDisabled }),
            endDate: new FormControl({ value: this.globalSettings().endDate, disabled: this.isDisabled }),
        });
        this.wheelOfFortuneDatesForm.valueChanges.subscribe((value) => this._onWheelOfFortuneDatesChange(value));
    }

    private _initGiftClaimDurationInDaysControl(): void {
        this.giftClaimDurationInDaysControl = new FormControl<number>({
            value: this.globalSettings().giftClaimDurationInDays,
            disabled: this.isDisabled,
        });
        this.giftClaimDurationInDaysControl.valueChanges.subscribe((value) => this.onGiftClaimDurationInDaysChange(value));
    }

    private _initRedirectionSettingsForm(): void {
        this.redirectionSettingsForm = new FormGroup({
            nextDrawEnabledDelay: new FormControl<NextDrawEnabledDelay>({
                value: this.globalSettings().redirectionSettings.nextDrawEnabledDelay,
                disabled: this.isDisabled,
            }),
            shouldRedirect: new FormControl<boolean>({
                value: this.globalSettings().redirectionSettings.shouldRedirect,
                disabled: this.isDisabled,
            }),
        });

        this.redirectionSettingsForm
            .get('nextDrawEnabledDelay')!
            .valueChanges.subscribe((value) => this.onNextDrawEnabledDelayChange(value));
    }

    private _initTotemsControl(): void {
        this.totems = this.selectedRestaurants()?.[0].totems || [];
        this.totemsControl.setValue(this.selectedTotems() ?? []);
    }

    private _sortGmbFirst(platformKeys: PlatformKey[]): PlatformKey[] {
        return platformKeys.sort((a, b) => {
            if (a === PlatformKey.GMB) {
                return -1;
            } else if (b === PlatformKey.GMB) {
                return 1;
            }
            return 0;
        });
    }

    private _onWheelOfFortuneDatesChange(value: { startDate?: Date | null; endDate?: Date | null }): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            startDate: value.startDate || null,
            endDate: value.endDate || null,
        }));
    }
}
