import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, computed, DestroyRef, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ApplicationLanguage, BricksCategory, isNotNil, mapLanguageStringToApplicationLanguage } from '@malou-io/package-utils';

import { HashtagsService } from ':core/services/hashtags.service';
import { KeywordsService } from ':core/services/keywords.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { InputAutocompleteComponent } from ':shared/components/input-autocomplete/input-autocomplete.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectLanguagesComponent } from ':shared/components/select-languages/select-languages.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { createCustomBrick } from ':shared/helpers/create-custom-brick';
import { buildGenerationForm } from ':shared/helpers/generation-form';
import { IDisplayable } from ':shared/interfaces';
import { ApiResult, Brick, Pagination, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import * as GenerationFormsActions from './store/generation-forms.actions';
import { GenerationForm } from './store/generation-forms.interface';
import * as fromStore from './store/generation-forms.selector';

interface AppState {
    generator: {
        generationForm;
    };
}

const DEFAULT_PAGE_SIZE = 5000;

export enum ApiLocationType {
    CITY = 'City',
    COUNTRY = 'Country',
    DEPARTMENT = 'Department',
    REGION = 'Region',
    STATE = 'State',
}

export interface ApiLocationOption {
    apiLocationId: string;
    apiLocationName: string;
    apiCanonicalName: string;
    apiCountryCode: string;
    apiLocationType: ApiLocationType;
}

@Component({
    selector: 'app-generator',
    templateUrl: './generator.component.html',
    styleUrls: ['./generator.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatIconModule,
        MatButtonModule,
        SkeletonComponent,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        SelectChipListComponent,
        SelectLanguagesComponent,
        MatRippleModule,
        TranslateModule,
        InputAutocompleteComponent,
        InputTextComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
    ],
})
export class GeneratorComponent implements OnInit, AfterViewInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild('stepper') stepper: MatStepper;

    readonly loadingRestaurant = toSignal(this._restaurantsService.restaurantLoading$, {
        initialValue: this._restaurantsService.restaurantLoading$.value,
    });
    readonly loadingBricks = signal(true);
    readonly loadingApiLocationJsonData = signal(true);
    readonly loading = computed(() => this.loadingRestaurant() || this.loadingBricks() || this.loadingApiLocationJsonData());

    readonly reload$ = new BehaviorSubject(false);
    readonly pagination$: Subject<Pagination> = new Subject();

    readonly apiLocationErrorMessage = signal('');
    readonly apiLocationOptions: WritableSignal<ApiLocationOption[]> = signal([]);
    readonly initialApiLocation: WritableSignal<ApiLocationOption | null> = signal(null);

    categoryFormGroup: UntypedFormGroup;
    geoFormGroup: UntypedFormGroup;
    furtherFormGroup: UntypedFormGroup;
    restaurant: Restaurant;

    recommendedTouristics: Brick[] = [];

    categoriesBricks: Brick[] = [];
    specialBricks: Brick[] = [];
    audienceBricks: Brick[] = [];
    attributeBricks: Brick[] = [];
    equipmentBricks: Brick[] = [];
    labelBricks: Brick[] = [];
    offerBricks: Brick[] = [];

    isSecondKeywordGeneratorAttempt = false;
    readonly BricksCategory = BricksCategory;
    currentLang = LocalStorage.getLang();

    readonly generatorFormAnswers$ = this._store.select(fromStore.selectCurrentForm);
    generatorFormAnswers: GenerationForm;

    currentStep = 1;
    displayCloseModal = false;

    totalBricksCount = 0;

    readonly buildNewItemFns: Record<BricksCategory, (text: BricksCategory) => Brick> = [
        BricksCategory.VENUE_SPECIAL,
        BricksCategory.TOURISTIC_AREA,
        BricksCategory.VENUE_OFFER,
        BricksCategory.VENUE_ATTRIBUTE,
        BricksCategory.VENUE_AUDIENCE,
        BricksCategory.VENUE_EQUIPMENT,
        BricksCategory.VENUE_LABEL,
    ].reduce((acc, cur) => ({ ...acc, [cur]: this.buildNewItem(cur) }), {} as Record<BricksCategory, (text: BricksCategory) => Brick>);

    readonly languageOptions = Object.values(ApplicationLanguage);
    readonly languagesControl = new FormControl<ApplicationLanguage[]>([]);
    readonly shouldDisplayLanguageStep = signal(false);
    private readonly _GENERATOR_TRANSLATE = this._translate.instant('keywords.generator');

    constructor(
        private readonly _dialogRef: MatDialogRef<GeneratorComponent>,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _router: Router,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _keywordsService: KeywordsService,
        private readonly _hashtagsService: HashtagsService,
        private readonly _store: Store<AppState>,
        private readonly _spinnerService: SpinnerService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _httpClient: HttpClient,
        private readonly _destroyRef: DestroyRef,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.shouldDisplayLanguageStep.set(this._router.url.includes('keywords'));
        this._initGeneratorForm();
        this._initGeneratorFormAnswers();
    }

    get postalCode(): string {
        return this.geoFormGroup.get('postalCode')?.value;
    }

    apiLocationFilterFn = (value: string, option: ApiLocationOption): boolean => {
        const words = value.split(' ');
        return words.every(
            (word) =>
                [ApiLocationType.CITY, ApiLocationType.COUNTRY, ApiLocationType.REGION].includes(option.apiLocationType) &&
                option.apiCanonicalName.toLowerCase().includes(word.toLowerCase())
        );
    };
    apiLocationWriteValueFn = (option: ApiLocationOption): string => option.apiCanonicalName;
    apiLocationDisplayWithFn = (option: ApiLocationOption): string => option.apiCanonicalName;

    ngOnInit(): void {
        this._translate.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            this.currentLang = mapLanguageStringToApplicationLanguage(res.lang);
            this.reload$.next(true);
            this._restaurantsService.reloadSelectedRestaurant();
        });

        this._initFormGroups();
        this._initGetBricksPaginated();
        this._initBricksCount();
        this._initApiLocationOptionsAndInitialValue();
    }

    ngAfterViewInit(): void {
        if (this.stepper) {
            this.stepper._getIndicatorType = (): string => 'number';
        }
    }

    buildNewItem(category: BricksCategory): (text: string) => Brick {
        const currentLang = this.currentLang;
        return function (text: string): Brick {
            return createCustomBrick(text, category, currentLang);
        };
    }

    listChanged(key: string, value: IDisplayable[], formGroup: UntypedFormGroup): void {
        if (value.length >= 0) {
            formGroup.get(key)?.setValue(value);
            formGroup.get(key)?.markAsDirty();
        }
    }

    saveBricks(): void {
        this._spinnerService.show();
        this.updateBricks();
    }

    updateBricks(): void {
        const apiLocationId = this.geoFormGroup.get('apiLocation')?.value?.apiLocationId;
        const isInKeywords = this._router.url.includes('keywords');
        if (isInKeywords && !apiLocationId) {
            console.error('apiLocationId is required');
            return;
        }

        const generationForm: GenerationForm = {
            categoryList: this.categoryFormGroup.get('categoryList')?.value,
            touristics: this.geoFormGroup.get('touristics')?.value,
            specials: this.categoryFormGroup.get('specials')?.value,
            audience: this.furtherFormGroup.get('audience')?.value,
            attributes: this.furtherFormGroup.get('attributes')?.value,
            equipment: this.furtherFormGroup.get('equipment')?.value,
            offers: this.furtherFormGroup.get('offers')?.value,
            postalCode: this.geoFormGroup.get('postalCode')?.value,
            apiLocationId: isInKeywords ? apiLocationId : undefined,
        };
        this._store.dispatch({
            type: GenerationFormsActions.editGenerationForm.type,
            generationForm: {
                restaurantId: this.restaurant._id,
                ...generationForm,
            },
        });

        this._keywordsService
            .sendBricksForm(this.restaurant._id, {
                ...generationForm,
                country: this.restaurant.address?.regionCode ?? '',
            })
            .pipe(switchMap(() => this.generate()))
            .subscribe({
                next: () => {
                    this._restaurantsService.reloadSelectedRestaurant();
                    this._spinnerService.hide();
                    this._dialogRef.close(true);
                },
                error: (err) => {
                    this._spinnerService.hide();
                    console.warn('err :>>', err);
                    if (err.status === 403) {
                        return;
                    }
                    if (err?.error?.message?.match(/Task timed out/) && !this.isSecondKeywordGeneratorAttempt) {
                        this.isSecondKeywordGeneratorAttempt = true;
                        this._toastService.openErrorToast(this._GENERATOR_TRANSLATE.restart_generator);
                    } else {
                        this._toastService.openErrorToast(this._GENERATOR_TRANSLATE.unknown_error);
                    }
                },
            });
    }

    generate(): Observable<ApiResult> {
        const langs = this.languagesControl.value?.length ? this.languagesControl.value : [ApplicationLanguage.FR, ApplicationLanguage.EN];
        return this.generateKeywords$(langs);
    }

    generateKeywords$(langs: ApplicationLanguage[]): Observable<ApiResult> {
        this._keywordsService.generateKeywordsForRestaurant(this.restaurant._id, langs);
        return of({ data: [], msg: 'Generation in progress' });
    }

    close(shouldCheckChangesBeforeClose = false): void {
        if (shouldCheckChangesBeforeClose && this.isAnyFormDirty()) {
            this.displayCloseModal = true;
            return;
        }
        this._dialogRef.close();
    }

    isAnyFormDirty(): boolean {
        return this.categoryFormGroup.dirty || this.geoFormGroup.dirty || this.furtherFormGroup.dirty;
    }

    getInkBarWidth(): string {
        const stepperHeader = document.querySelector('.stepper-header');
        const currentStepChild = stepperHeader?.childNodes[this.currentStep - 1] as HTMLElement;
        const width = (currentStepChild?.offsetWidth ?? 0) + (currentStepChild?.offsetLeft ?? 0);
        return `${width}px`;
    }

    displayBrickWith(brick: Brick): string {
        return brick.getDisplayedValue();
    }

    hasValidatedStep = (step: number): boolean => {
        switch (step) {
            case 2:
                return this.geoFormGroup.valid;
            case 3:
                return this.furtherFormGroup.valid;
            case 4:
                return this.languagesControl.valid;
            default:
                return this.categoryFormGroup.valid;
        }
    };

    onApiLocationSelected(option: ApiLocationOption | null): void {
        const apiLocationControl = this.geoFormGroup.get(['apiLocation']);
        if (apiLocationControl) {
            apiLocationControl.setValue(option);
            this.onApiLocationDirty();
        }
    }

    onApiLocationDirty(): void {
        const apiLocationControl = this.geoFormGroup.get(['apiLocation']);
        if (apiLocationControl) {
            apiLocationControl.markAsDirty();
            this.apiLocationErrorMessage.set(apiLocationControl.value ? '' : this._translate.instant('common.required_field'));
        }
    }

    onLanguagesChange(languages: ApplicationLanguage[]): void {
        this.languagesControl.setValue(languages);
    }

    getStepperValidateBtnLabel = (currentStep: number): string => {
        const isInKeywords = this._router.url.includes('keywords');
        const lastStep = isInKeywords ? 4 : 3;
        return currentStep === lastStep ? this._translate.instant('common.confirm') : this._translate.instant('common.next');
    };

    getConfirmBtnTrackerId = (currentStep: number): string => {
        const isInKeywords = this._router.url.includes('keywords');
        const lastStep = isInKeywords ? 4 : 3;
        return currentStep === lastStep ? 'tracking_keywords_form_confirmation' : 'tracking_keywords_form_next_step';
    };

    onConfirmBtnClick = (currentStep: number): void => {
        const isInKeywords = this._router.url.includes('keywords');
        const lastStep = isInKeywords ? 4 : 3;
        if (currentStep === lastStep) {
            this.saveBricks();
        } else {
            this.currentStep = currentStep + 1;
        }
    };

    getLocationAutocompleteId = (restaurantId: string): string => `apiLocation_${restaurantId}_autocomplete`;

    private _initApiLocationOptionsAndInitialValue(): void {
        this._httpClient.get('../../../assets/jsons/api_locations.json').subscribe((res: ApiLocationOption[]) => {
            this.apiLocationOptions.set(res);
            this.loadingApiLocationJsonData.set(false);
            const initialApiLocation = res.find((option) => option.apiLocationId === this.restaurant.keywordToolApiLocationId) ?? null;
            this.initialApiLocation.set(initialApiLocation);
        });
    }

    private _initGetBricksPaginated(): void {
        this.pagination$
            .pipe(
                tap(() => {
                    this.loadingBricks.set(true);
                }),
                switchMap((pagination) => forkJoin([this._keywordsService.getBricksPaginated(pagination), of(pagination)])),
                map(([bricks, pagination]) => ({
                    bricks: bricks
                        .filter((b) => !b.needReview)
                        .map((b) => new Brick(b, this.currentLang))
                        .sort((a, b) => ((a.meanSearchVol || 0) > (b.meanSearchVol || 0) ? -1 : 1)),
                    pagination,
                })),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ({ bricks, pagination }) => {
                    this.loadingBricks.set(false);
                    this.categoriesBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_CATEGORY));
                    this.specialBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_SPECIAL));
                    this.offerBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_OFFER));
                    this.equipmentBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_EQUIPMENT));
                    this.labelBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_LABEL));
                    this.attributeBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_ATTRIBUTE));
                    this.audienceBricks.push(...bricks.filter((b) => b.category === BricksCategory.VENUE_AUDIENCE));
                    this.totalBricksCount -= bricks.length;
                    if (this.totalBricksCount > 0 && bricks.length > 0) {
                        this.pagination$.next({
                            pageNumber: pagination.pageNumber + 1,
                            pageSize: DEFAULT_PAGE_SIZE,
                            total: this.totalBricksCount,
                        });
                    }
                },
                error: (err) => {
                    this.loadingBricks.set(false);
                    console.warn(err);
                },
            });
    }

    private _initBricksCount(): void {
        this._keywordsService.getBricksCount().subscribe({
            next: ({ data }) => {
                this.totalBricksCount = data;
                this.pagination$.next({ pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE, total: data });
            },
        });
    }

    private _initGeneratorFormAnswers(): void {
        this.generatorFormAnswers$.pipe(filter(isNotNil), takeUntilDestroyed(this._destroyRef)).subscribe((res) => {
            const answers = omit(res, 'restaurantId');
            this.generatorFormAnswers = answers;
        });
    }

    private _initGeneratorForm(): void {
        this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil), takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (restaurant) => {
                this._spinnerService.hide();
                this.restaurant = restaurant;
                const restaurantBricks = this.restaurant.bricks.map((brick) => new Brick(brick, this.currentLang));
                this._store.dispatch({
                    type: GenerationFormsActions.editGenerationForm.type,
                    generationForm: buildGenerationForm(this.restaurant, restaurantBricks),
                });
                this._store.dispatch({ type: GenerationFormsActions.selectForm.type, formId: this.restaurant._id });
            },
            error: () => this._spinnerService.hide(),
        });
    }

    private _initFormGroups(): void {
        const { address, bricksPostalCode } = this.restaurant;

        const categoryListFiltered = this.generatorFormAnswers.categoryList?.filter((b) => !!this.displayBrickWith(b)) ?? [];
        const specialsFiltered = this.generatorFormAnswers.specials?.filter((b) => !!this.displayBrickWith(b)) ?? [];

        this.categoryFormGroup = this._formBuilder.group({
            categoryList: [categoryListFiltered, Validators.required],
            specials: [specialsFiltered, Validators.required],
        });

        const offersFiltered = this.generatorFormAnswers.offers?.filter((b) => !!this.displayBrickWith(b)) ?? [];
        const attributesFiltered = this.generatorFormAnswers.attributes?.filter((b) => !!this.displayBrickWith(b)) ?? [];
        const audienceFiltered = this.generatorFormAnswers.audience?.filter((b) => !!this.displayBrickWith(b)) ?? [];
        const equipmentFiltered = this.generatorFormAnswers.equipment?.filter((b) => !!this.displayBrickWith(b)) ?? [];

        this.furtherFormGroup = this._formBuilder.group({
            offers: [offersFiltered],
            attributes: [attributesFiltered],
            audience: [audienceFiltered],
            equipment: [equipmentFiltered],
        });
        const touristicsFiltered = this.generatorFormAnswers.touristics?.filter((b) => !!this.displayBrickWith(b)) ?? [];

        this.geoFormGroup = this._formBuilder.group({
            postalCode: [bricksPostalCode || address?.postalCode, this.restaurant.isBrandBusiness() ? null : Validators.required],
            touristics: [touristicsFiltered],
            apiLocation: [null, Validators.required],
        });

        this._initLanguagesControl();

        this._restaurantsService.restaurantSelected$
            .pipe(filter(isNotNil), takeUntilDestroyed(this._destroyRef))
            .subscribe((restaurant) => {
                this.geoFormGroup.get(['postalCode'])?.setValue(restaurant?.bricksPostalCode || restaurant?.address?.postalCode);
            });
    }

    private _initLanguagesControl(): void {
        this.languagesControl.setValidators(Validators.required);
        this.languagesControl.setValue([]);
    }
}
