import { intersection, pick } from 'lodash';

import { ReviewReplyAutomationDto } from '@malou-io/package-dto';
import {
    AutomationFeature,
    fromAutomationWithCommentToCommentOptionValue,
    fromRatingCategoryToRatings,
    PlatformKey,
    RemoveMethodsFromClass,
    ReviewReplyAutomationComment,
    ReviewReplyAutomationMethod,
    ReviewReplyAutomationRatingCategory,
    TemplateStatus,
} from '@malou-io/package-utils';

import { CommentOptionValue } from ':shared/enums/with-comment.enum';

import { Template } from './template';

export class Automation {
    restaurantId: string;
    active: boolean;
    feature: AutomationFeature;

    constructor(init: RemoveMethodsFromClass<Automation>) {
        this.restaurantId = init.restaurantId;
        this.active = init.active;
        this.feature = init.feature;
    }

    setActive(active: boolean): void {
        this.active = active;
    }
}

// ---------------------------------------------------------------------------------

export class ReviewReplyAutomation extends Automation {
    ratingCategory: ReviewReplyAutomationRatingCategory;
    withComment: ReviewReplyAutomationComment;
    replyMethod: ReviewReplyAutomationMethod;
    aiConfig: {
        sendAutomaticallyToThesePlatformKeys: PlatformKey[];
    };
    templateConfigs: {
        template: string;
        platformKeys: PlatformKey[];
    }[];

    constructor(automation: ReviewReplyAutomationDto) {
        super(pick(automation, ['restaurantId', 'active', 'feature']));
        Object.assign(this, automation);
    }

    setReplyMethod(replyMethod: ReviewReplyAutomationMethod): void {
        this.replyMethod = replyMethod;
    }

    setAiConfig(aiConfig: { sendAutomaticallyToThesePlatformKeys: PlatformKey[] }): void {
        this.aiConfig = aiConfig;
    }

    setTemplateConfigs(
        templateConfigs: {
            template: string;
            platformKeys: PlatformKey[];
        }[]
    ): void {
        this.templateConfigs = templateConfigs;
    }

    pushTemplateConfig(templateConfig: { template: string; platformKeys: PlatformKey[] }): void {
        this.templateConfigs.push(templateConfig);
    }

    isPlatformChecked(template: Template, platformKey: PlatformKey): boolean {
        return this.templateConfigs.some(
            (templateConfig) => templateConfig.template === template._id && templateConfig.platformKeys.includes(platformKey)
        );
    }

    getTemplatesForAutomation(templates: Template[]): Template[] {
        return templates.filter(
            (template) =>
                template.status === TemplateStatus.ACTIVE &&
                intersection(fromRatingCategoryToRatings(this.ratingCategory), template.rating)?.length > 0 &&
                (template.withComment === CommentOptionValue.WITH_OR_WITHOUT ||
                    fromAutomationWithCommentToCommentOptionValue(this.withComment) === template.withComment)
        );
    }
}

// ---------------------------------------------------------------------------------

export class ReviewReplyAutomationWithTemplates extends ReviewReplyAutomation {
    templates: Template[];

    constructor(automation: ReviewReplyAutomation, templates?: Template[]) {
        super(automation);
        this.templates = templates ?? [];
    }
}

export function buildReviewReplyAutomationFromDto(dto: ReviewReplyAutomationDto): ReviewReplyAutomation {
    return new ReviewReplyAutomation(dto);
}
