import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RestaurantRankingFormatWithScore } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-ranking-competitors-list',
    templateUrl: './rankings-competitors-list.component.html',
    styleUrls: ['./rankings-competitors-list.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, TranslateModule, ApplyPurePipe, IllustrationPathResolverPipe],
})
export class RankingsCompetitorsListComponent {
    readonly SvgIcon = SvgIcon;
    public keyword: string;
    public restaurant: Restaurant;
    public restaurantIndex: number;
    public ranking: RestaurantRankingFormatWithScore[] = [];

    readonly trackByPlaceIdFn = TrackByFunctionFactory.get('place_id');

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { keyword: string; ranking: RestaurantRankingFormatWithScore[]; indexPosition: number },
        private readonly _dialogRef: MatDialogRef<RankingsCompetitorsListComponent>,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translate: TranslateService
    ) {
        this.keyword = data.keyword;
        this.ranking = data.ranking;
        this.restaurantIndex = data.indexPosition - 1;
    }

    getRestaurantName = (index: number, restaurantName: string): string =>
        // The api returns the restaurant name translated sometimes, so we check if our restaurant is included and return the name from the malouapp
        index === this.restaurantIndex ? this._restaurantsService.currentRestaurant.name : restaurantName;

    cancel(done: boolean = false): void {
        this._dialogRef.close(done);
    }

    getEmojiAndTextFromPosition = (position: number): { emoji: string; title: string; caption: string } => {
        switch (true) {
            case position === 1:
                return {
                    emoji: '🏆',
                    title: this._translate.instant('keywords.positions.first'),
                    caption: this._translate.instant('keywords.positions.first_subtitle'),
                };
            case position === 2:
                return {
                    emoji: '🥈',
                    title: this._translate.instant('keywords.positions.second', { position }),
                    caption: this._translate.instant('keywords.positions.second_subtitle'),
                };
            case position === 3:
                return {
                    emoji: '🥉',
                    title: this._translate.instant('keywords.positions.third', { position }),
                    caption: this._translate.instant('keywords.positions.third_subtitle'),
                };
            case position <= 10:
                return {
                    emoji: '💪',
                    title: this._translate.instant('keywords.positions.top20', { position }),
                    caption: this._translate.instant('keywords.positions.top10_subtitle'),
                };
            case position <= 15:
                return {
                    emoji: '✌️',
                    title: this._translate.instant('keywords.positions.top20', { position }),
                    caption: this._translate.instant('keywords.positions.top15_subtitle'),
                };
            case position <= 20:
                return {
                    emoji: '🙌 ',
                    title: this._translate.instant('keywords.positions.top20', { position }),
                    caption: this._translate.instant('keywords.positions.top20_subtitle'),
                };

            default:
                return {
                    emoji: '😶‍🌫️',
                    title: `+${position}`,
                    caption: this._translate.instant('keywords.positions.other_subtitle'),
                };
        }
    };
}
