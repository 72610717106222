import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CampaignState } from './campaigns.interface';

export namespace CampaignsSelectors {
    export const selectCampaignsState = createFeatureSelector<CampaignState>('campaigns');

    export const selectCurrentRestaurantId = createSelector(selectCampaignsState, (state) => state.restaurantId);
    export const selectCurrentCampaignStatus = createSelector(selectCampaignsState, (state) => state.campaignStatus);
}
