@if (isGmbConnected$ | async) {
    @if (isLoading()) {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    } @else {
        @if (!httpError) {
            @if (!insightsError) {
                <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
                    <div>
                        <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
                    </div>
                    <div>
                        <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                    </div>
                    <div>
                        <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
                    </div>
                </div>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                    <span class="malou-text-10--regular">{{ insightsError }}</span>
                </div>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
            </div>
        }
    }
} @else {
    <div class="flex flex-col items-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
        <span class="malou-text-10--regular">{{ 'statistics.errors.gmb_not_connected' | translate }}</span>
    </div>
}

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.gmb_actions.actions' | translate }}</span>
        @if (!showViewByTextInsteadOfSelector) {
            <div class="flex items-center gap-2">
                <div class="malou-text-14--medium md:hidden">{{ 'statistics.seo.gmb_actions.view_by' | translate }}</div>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-gmb-actions-chart
        [gmbActionsData]="gmbActionsData"
        [labels]="dateLabels"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        [hiddenDatasetIndexes]="hiddenDatasetIndexes"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
    </app-gmb-actions-chart>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div
                    class="malou-text-30--bold malou-color-text-1"
                    [matTooltip]="'statistics.seo.gmb_actions.user_actions_number' | translate">
                    {{ currentGmbInsights?.totalActions | shortNumber }}
                </div>
                @if (currentGmbInsights?.totalActions) {
                    <app-number-evolution
                        [value]="actionsEvolutionPercentage"
                        [displayedValue]="
                            actionsEvolutionPercentage | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.gmb_actions.actions' | translate }}
            </div>
        </div>

        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div
                    class="malou-text-30--bold malou-color-text-1"
                    [matTooltip]="'statistics.seo.gmb_actions.conversion_rate_tooltip' | translate">
                    {{ currentGmbInsights?.ratioActionsOverImpressions | shortNumber: { content: '%' } }}
                </div>
                @if (currentGmbInsights?.ratioActionsOverImpressions) {
                    <app-number-evolution
                        [value]="conversionRateEvolution"
                        [displayedValue]="
                            conversionRateEvolution | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.gmb_actions.conversion_rate' | translate }}
            </div>
        </div>
    </div>
</ng-template>
