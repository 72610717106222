import { ChangeDetectionStrategy, Component, effect, inject, input, output, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { ApiResultV2 } from '@malou-io/package-utils';

import { OrganizationsService, PingedOrganization } from ':core/services/organizations.service';
import { ToastService } from ':core/services/toast.service';
import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { DEFAULT_REVIEW_PRIVATE_FORM, ReviewPrivateForm } from ':modules/reviews/answer-review-container/answer-review.interface';
import { User } from ':modules/user/user';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord, INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { PrivateReview, Restaurant, Review } from ':shared/models';

@Component({
    selector: 'app-answer-private-review',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TranslateModule, InputTextComponent, SelectComponent],
    templateUrl: './answer-private-review.component.html',
    styleUrls: ['./answer-private-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerPrivateReviewComponent {
    readonly currentUser = input.required<User | undefined>();
    readonly restaurant = input.required<Restaurant | null>();
    readonly review = input.required<Review | PrivateReview | null>();

    readonly onValidChange = output<boolean>();

    private readonly _formBuilder = inject(FormBuilder);
    private readonly _campaignsService = inject(CampaignsService);
    private readonly _organizationsService = inject(OrganizationsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly privateForm = this._initPrivateForm();
    readonly availableEmails = signal<string[]>([]);

    constructor() {
        effect(() => {
            this._updateAvailableEmails(this.restaurant());
        });

        toObservable(this.review).subscribe(() => {
            this._resetPrivateForm();
        });

        this.privateForm.valueChanges.subscribe(() => {
            this.onValidChange.emit(this.privateForm.valid);
        });
    }

    private _initPrivateForm(): INullableFormGroup<ReviewPrivateForm> {
        const privateForm: INullableFormControlRecord<ReviewPrivateForm> = getFormControlRecordFromDefaultValue(
            DEFAULT_REVIEW_PRIVATE_FORM,
            {
                mail: { validators: Validators.required },
            }
        );
        return this._formBuilder.group(privateForm);
    }

    private _resetPrivateForm(): void {
        this.privateForm?.reset(DEFAULT_REVIEW_PRIVATE_FORM);
    }

    private _updateAvailableEmails(restaurant: Restaurant | null): void {
        if (!restaurant) {
            return;
        }
        const organisations$: Observable<ApiResultV2<PingedOrganization | null>> = restaurant.organizationId
            ? this._organizationsService.ping(restaurant.organizationId)
            : of({ data: null, msg: '' });

        organisations$
            .pipe(
                map((res) => [this.currentUser()?.email].concat(res.data?.organization?.verifiedEmailsForCampaigns ?? [])),
                map((possibleVerifiedEmails) => possibleVerifiedEmails.filter(Boolean)),
                switchMap((possibleVerifiedEmails: string[]) =>
                    forkJoin([
                        this._campaignsService.getAWSVerifiedExpeditorEmails({ emails: possibleVerifiedEmails }),
                        of(possibleVerifiedEmails),
                    ])
                ),
                map(([res, possibleVerifiedEmails]) => possibleVerifiedEmails.filter((email) => res.data[email]))
            )
            .subscribe({
                next: (verifiedEmails) => {
                    const verifiedEmailsWithoutDuplicates = Array.from(new Set(verifiedEmails));
                    this.availableEmails.set(verifiedEmailsWithoutDuplicates);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this._toastService.openErrorToast(
                        this._translateService.instant('reviews.reply_modal.no_emails') + JSON.stringify(err)
                    );
                },
            });
    }
}
