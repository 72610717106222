import { z } from 'zod';

import { AiInteractionRelatedEntityCollection } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getAiInteractionsParamsValidator = z
    .object({
        related_entity_id: objectIdValidator,
    })
    .transform((data) => ({
        relatedEntityId: data.related_entity_id,
    }));
export type GetAiInteractionsParamsDto = z.infer<typeof getAiInteractionsParamsValidator>;

export const getAiInteractionsQueryValidator = z
    .object({
        related_entity_collection: z.nativeEnum(AiInteractionRelatedEntityCollection),
    })
    .transform((data) => ({
        relatedEntityCollection: data.related_entity_collection,
    }));
export type GetAiInteractionsQueryDto = z.infer<typeof getAiInteractionsQueryValidator>;
