import { z } from 'zod';

export const getCommentAndMentionUnansweredCountParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export const getCommentAndMentionUnansweredCountQueryValidator = z
    .object({
        start_date: z.string().datetime({ offset: true }).optional(),
    })
    .transform((data) => ({
        startDate: data.start_date ? new Date(data.start_date) : undefined,
    }));

export type GetCommentAndMentionUnansweredCountParamsDto = z.infer<typeof getCommentAndMentionUnansweredCountParamsValidator>;
export type GetCommentAndMentionUnansweredCountQueryDto = z.infer<typeof getCommentAndMentionUnansweredCountQueryValidator>;
