import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

export interface FilterOption {
    label: string;
    key: string;
}

@Component({
    selector: 'app-sort-by-filters',
    templateUrl: './sort-by-filters.component.html',
    styleUrls: ['./sort-by-filters.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        TranslateModule,
        ApplyPurePipe,
    ],
})
export class SortByFiltersComponent implements OnInit {
    @ViewChild('select') select: MatSelect;

    @Input() sortOptions: FilterOption[];
    @Input() sortOrder: number;
    @Input() class = 'min-lg:w-[210px]';
    @Input() disabled = false;
    @Output() changeSortBy = new EventEmitter<string>();
    @Output() toggleSortOrder = new EventEmitter<number>();
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');

    currentOption: FilterOption | undefined;
    isUp: boolean;

    @Input() set sortBy(value: string) {
        const filterOption = this._getFilterOption(value);
        this.currentOption = filterOption;
    }
    @Input() displaySelectedOption: (currentSelectedOption: FilterOption | undefined) => string | undefined = (option) => option?.label;
    @Input() compareOptions: (o1: any, o2: any) => boolean = (o1: any, o2: any) => o1 === o2;

    ngOnInit(): void {
        if (this.sortOrder) {
            this.isUp = this.sortOrder === 1 ? true : false;
        } else {
            this.isUp = true;
        }
    }

    onSortByChange(event: { value: string }): void {
        this.currentOption = this._getFilterOption(event.value);
        this.changeSortBy.emit(event.value);
    }

    onSortOrderChange(): void {
        this.isUp = !this.isUp;
        this.toggleSortOrder.emit(this.isUp ? 1 : -1);
    }

    onClick(): void {
        this.openPanel();
    }

    openPanel(): void {
        this.select.open();
    }

    private _getFilterOption(key: string): FilterOption {
        return this.sortOptions.find((sortOption) => sortOption.key === key) as FilterOption;
    }
}
