import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, throwError } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { KeywordsService } from ':core/services/keywords.service';

import * as KeywordsActions from './keywords.actions';

@Injectable()
export class KeywordsEffect {
    readonly loadKeywords$ = createEffect(() =>
        this._actions$.pipe(
            ofType(KeywordsActions.loadKeywords),
            exhaustMap((action) =>
                this._keywordsService.getKeywordsByRestaurantId(action.restaurantId).pipe(
                    map((res) => {
                        if (!res.data) {
                            throwError(() => new Error('no_data'));
                        }
                        return KeywordsActions.editKeywordsByRestaurantId({ restaurantId: action.restaurantId, keywords: res.data });
                    }),
                    catchError((err) => {
                        console.warn('err :', err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _keywordsService: KeywordsService
    ) {}
}
