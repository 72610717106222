<div class="flex h-full flex-col gap-3 px-9 py-6">
    <ng-container [ngTemplateOutlet]="myProfileTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="aboutYourMalouTemplate"></ng-container>
</div>

<ng-template #myProfileTemplate>
    <div class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white p-6.5">
        <div class="flex w-full items-start justify-between">
            <div class="malou-text-14--bold text-malou-color-text-1">{{ 'admin.profile.my_profile' | translate }}</div>

            <div class="flex gap-5 md:hidden">
                <a
                    class="malou-text-13--semibold mt-4 block text-malou-color-primary hover:underline"
                    target="_blank"
                    [href]="CGU_LINK_DATA">
                    {{ 'admin.profile.conditions' | translate }}
                </a>
                <button class="malou-btn-icon--secondary btn-xl !m-0" mat-icon-button (click)="openEditUserModal()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
            </div>

            <div class="hidden gap-5 md:flex">
                <a
                    class="malou-text-13--semibold mt-4 block text-malou-color-primary hover:underline"
                    target="_blank"
                    [href]="CGU_LINK_DATA">
                    {{ 'profile.cgu' | translate }}
                </a>
                <button class="malou-btn-icon--secondary btn-xl !m-0" mat-icon-button (click)="openEditUserModal()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
            </div>
        </div>

        <div class="flex w-full gap-10 md:mt-4 md:flex-wrap md:gap-5">
            <div class="md:flex md:w-full md:justify-between">
                @if (user()?.profilePicture?.urls?.small; as profilePicture) {
                    <img
                        class="malou-border-primary h-40 w-36 rounded-xl object-cover object-center md:h-14 md:w-14"
                        [src]="profilePicture" />
                } @else {
                    <div
                        class="flex h-40 w-36 items-center justify-center rounded-xl border border-malou-color-border-primary bg-malou-color-background-light md:h-14 md:w-14">
                        <img class="h-20 opacity-50 md:h-6" [src]="Illustration.Karl | illustrationPathResolver" />
                    </div>
                }
            </div>

            <div class="flex w-full justify-between">
                <div class="flex flex-col justify-around md:gap-4">
                    <div class="flex items-center">
                        <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                        <div class="malou-text-14--bold ml-4 truncate text-malou-color-text-1 md:hidden">
                            {{ user()?.fullname }}
                        </div>
                        <div class="malou-text-14--bold ml-4 hidden truncate text-malou-color-text-1 md:block">
                            {{ user()?.name + '.' + user()?.lastname?.charAt(0) | shortText: 15 }}
                        </div>
                    </div>
                    <div class="flex items-center">
                        <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.LETTER"></mat-icon>
                        <div class="malou-text-14--regular ml-4 text-malou-color-text-1">
                            {{ user()?.email }}
                        </div>
                    </div>
                    <div class="flex items-center">
                        <mat-icon class="icon-btn !h-4 text-malou-color-primary" color="primary" [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                        <div class="malou-text-14--regular ml-4 text-malou-color-text-1">
                            {{ user()?.role | titlecase }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #aboutYourMalouTemplate>
    <div class="flex flex-col gap-4 rounded-[10px] border border-malou-color-border-primary bg-white p-6.5">
        <div class="flex w-full items-start justify-between">
            <div class="malou-text-14--bold text-malou-color-text-1">{{ 'admin.profile.app_language' | translate }}</div>
        </div>

        <app-select-languages
            class="w-1/2 md:!w-full"
            [selectedValues]="[currentLang]"
            [values]="APP_LANGUAGES"
            [disabled]="isUpdatingLang()"
            [multiSelection]="false"
            (selectLanguagesChange)="changeLang($event)">
            <ng-template let-value="value" #simpleSelectedLangTemplate>
                <span class="flex items-center gap-x-2">
                    <img class="h-6 w-6" [src]="value | flagPathResolver" [alt]="value" />
                    <span class="malou-text-14--regular">{{ value | enumTranslate: 'application_language_raw' }}</span>
                </span>
            </ng-template>
            <ng-template let-value="value" #langOptionTemplate>
                <span class="flex items-center gap-x-2">
                    <img class="h-4 w-4" [src]="value | flagPathResolver" [alt]="value" />
                    <span class="malou-text-13--regular">
                        {{ value | enumTranslate: 'application_language_raw' }}
                    </span>
                </span>
            </ng-template>
        </app-select-languages>
    </div>
</ng-template>
