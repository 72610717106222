import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class SocialComponent {}
