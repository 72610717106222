<table class="mat-expansion-panel-body w-full md:!hidden">
    <thead class="malou-color-background-light h-12.5 px-[15px]">
        <tr>
            <th class="malou-color-text-2 malou-text-12--medium pl-[15px] text-start">
                <span>
                    {{ 'information.platform_comparison.information' | translate }}
                </span>
            </th>
            <th class="malou-color-text-2 malou-text-12--medium pl-[15px] text-start">
                <span>MalouApp</span>
            </th>
            <th class="malou-color-text-2 malou-text-12--medium pl-[15px] text-start">
                <span>{{ platformComparison.fullName }}</span>
            </th>
            <th class="malou-color-text-2 malou-text-12--medium w-[88px] text-start">
                {{ action === PlatformComparisonAction.COPY ? '' : ('information.platform_comparison.send_information' | translate) }}
            </th>
        </tr>
    </thead>
    @for (diff of platformComparison.data; track diff; let i = $index; let isLast = $last) {
        <tr
            class="px-[15px]"
            app-comparison-row
            [comparison]="diff"
            [action]="action"
            [platformName]="platformComparison.fullName ?? ''"
            [index]="i"
            [isLocked]="
                action === PlatformComparisonAction.LOCK && (platformsLockedFields[diff.key] ? platformsLockedFields[diff.key].lock : false)
            "
            [lastRow]="isLast"
            (changeLock)="handleChangeLock($event)"
            (copyClick)="onCopy(diff, $event)"></tr>
    }
</table>

<div class="hidden flex-col md:flex">
    @for (diff of platformComparison.data; track diff; let i = $index; let isLast = $last) {
        <div
            class="flex flex-col"
            app-comparison-row
            [comparison]="diff"
            [action]="action"
            [platformName]="platformComparison.fullName ?? ''"
            [index]="i"
            [isLocked]="
                action === PlatformComparisonAction.LOCK && (platformsLockedFields[diff.key] ? platformsLockedFields[diff.key].lock : false)
            "
            [lastRow]="isLast"
            (changeLock)="handleChangeLock($event)"
            (copyClick)="onCopy(diff, $event)"></div>
    }
</div>
