<div class="flex flex-col overflow-auto pr-3">
    <p class="text-malou-color-text-2">{{ 'platforms.connection.choose_restaurant.select_account' | translate }}</p>
    <div class="mt-1 flex">
        <div class="flex w-full gap-4 sm:flex-wrap">
            <app-select
                class="w-full"
                [testId]="'create-restaurant-account-input'"
                [values]="platformCredentials()"
                [displayWith]="displayCredentialSelect"
                [formControl]="platformCredentialForm"></app-select>
            <div class="flex w-full justify-between gap-4 sm:!h-12.5">
                <a class="sm:w-full" (click)="onEditRestaurantConnection()">
                    <button class="malou-btn-raised--secondary--alt !h-full sm:mr-1 sm:w-full" mat-raised-button>
                        {{ 'platforms.connection.choose_restaurant.edit_params' | translate }}
                    </button>
                </a>
                <a (click)="onEditRestaurantConnection()">
                    <button class="malou-btn-icon--secondary btn-size-custom" mat-icon-button>
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    </button>
                </a>
            </div>
        </div>
    </div>
    @if (!(isLoading$ | async)) {
        <div class="my-1 flex justify-between">
            @if (selectedAccountStatus()) {
                <div>
                    @if (selectedAccountStatus().isValid) {
                        <div>
                            <div class="malou-text-10--regular malou-color-state-success flex items-center">
                                <mat-icon class="malou-color-state-success mr-[-4px] !flex items-center justify-center sm:m-0">
                                    fiber_manual_record
                                </mat-icon>
                                <span class="leading-none">{{
                                    currentPlatformKey() === PlatformKey.GMB
                                        ? ('platforms.connection.choose_restaurant.valid_permissions' | translate)
                                        : ('platforms.connection.choose_restaurant.permissions_expires_in'
                                          | translate: { days: (getExpirationTime | applyPure: selectedAccountStatus()) })
                                }}</span>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        @if (restaurantsFound(); as restaurantsFound) {
            @if ((restaurantsFound | asType: ObjectWithError).error; as restaurantError) {
                <div class="m-8 flex flex-1 flex-col items-center justify-center">
                    <img class="mb-5 h-16" [src]="'Cutlery' | illustrationPathResolver" />
                    @switch (restaurantError) {
                        @case ('no_credential') {
                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                {{ 'platforms.connection.choose_restaurant.update_identifiers' | translate }}
                            </p>
                            <span class="malou-text-10--regular my-1 text-center text-malou-color-text-1">{{
                                'platforms.connection.choose_restaurant.check_connection' | translate
                            }}</span>
                        }
                        @case ('no_access') {
                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                {{ 'platforms.connection.choose_restaurant.invalid_credentials' | translate }}
                            </p>
                            <p
                                class="malou-text-12--bold my-1 cursor-pointer text-center text-malou-color-primary"
                                (click)="onEditRestaurantConnection()">
                                {{ 'platforms.connection.choose_restaurant.connection' | translate }}
                            </p>
                        }
                        @case ('spotted_forbidden') {
                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                {{ 'platforms.connection.choose_restaurant.could_not_recover_info' | translate }}
                            </p>
                        }
                    }
                </div>
            } @else {
                @if (((restaurantsFound | asType: PlatformSearchArray)?.length ?? 0) > 0) {
                    <ng-container class="flex-1">
                        @if (currentPlatformKey() === PlatformKey.GMB) {
                            <p class="my-2 text-malou-color-text-2">
                                {{ 'platforms.connection.choose_restaurant.business_groups' | translate }}
                            </p>
                            <app-select
                                [values]="groupedRestaurantsFoundByAccount()"
                                [required]="false"
                                [displayWith]="displayGroupedRestaurantSelect"
                                [formControl]="restaurantsGroupedControl"></app-select>
                            <p class="my-2 text-malou-color-text-2">
                                {{ 'platforms.connection.choose_restaurant.select_business' | translate }}
                            </p>
                            <app-search
                                placeholder="{{ 'restaurants_list.business.search' | translate }}"
                                [debounceTime]="0"
                                (searchChange)="applyFilter($event)">
                            </app-search>
                            <div class="overflow-auto sm:flex-auto">
                                @if (currentPageData) {
                                    @if (currentPageData.length) {
                                        <mat-radio-group (change)="changeLocation($event)">
                                            <mat-list>
                                                @for (restaurant of currentPageData; track trackBySocialIdFn($index, restaurant)) {
                                                    <mat-list-item
                                                        class="group mb-2 !h-14 rounded-xl !px-4 hover:bg-malou-color-background-dark"
                                                        [ngClass]="{
                                                            'bg-malou-color-background-dark':
                                                                selectedLocation()?.socialId === restaurant.socialId,
                                                        }">
                                                        <mat-radio-button class="!flex h-full w-full" [value]="restaurant">
                                                            <div class="flex w-full flex-col">
                                                                <div mat-line>
                                                                    <div class="flex items-center">
                                                                        <p
                                                                            class="malou-text-12--bold w-96 truncate text-malou-color-text-1 sm:w-56 sm:group-hover:w-44"
                                                                            target="_blank">
                                                                            {{ restaurant.name }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <p
                                                                    class="!malou-text-10--regular w-96 truncate italic leading-4 text-malou-color-text-2 sm:w-56 sm:group-hover:w-44"
                                                                    mat-line>
                                                                    {{ restaurant?.formattedAddress }}
                                                                </p>
                                                            </div>
                                                            <div class="flex flex-1 items-center justify-end">
                                                                @if (!!restaurant.locationId) {
                                                                    <a
                                                                        class="malou-text-10--bold sm:w- absolute right-0 top-[50%] mr-5 hidden translate-x-[0%] translate-y-[-50%] text-malou-color-primary group-hover:block"
                                                                        target="_blank"
                                                                        [ngClass]="{
                                                                            '!block': selectedLocation()?.socialId === restaurant.socialId,
                                                                        }"
                                                                        [href]="
                                                                            restaurant.locationId | profileLinkPlatform: PlatformKey.GMB
                                                                        ">
                                                                        {{ 'new_restaurant.see_page' | translate }}
                                                                    </a>
                                                                }
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-list-item>
                                                }
                                            </mat-list>
                                        </mat-radio-group>
                                    } @else {
                                        <div class="m-8 flex flex-1 flex-col items-center justify-center">
                                            <img class="mb-5 h-16" [src]="'Cook' | illustrationPathResolver" />
                                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                                {{ 'platforms.connection.choose_restaurant.no_results_filter' | translate }}
                                            </p>
                                        </div>
                                    }
                                }
                                <app-paginator
                                    class="mb-5"
                                    [shouldBeHidden]="(restaurantsFound | asType: PlatformSearchArray).length < 50"
                                    [pageSize]="50"
                                    [pageSizeOptions]="[50]">
                                </app-paginator>
                            </div>
                        } @else {
                            <app-search
                                placeholder="{{ 'restaurants_list.business.search' | translate }}"
                                [debounceTime]="0"
                                (searchChange)="applyFilter($event)">
                            </app-search>
                            <div class="sm:flex-auto">
                                @if (currentPageData) {
                                    @if (currentPageData.length) {
                                        <mat-radio-group (change)="changeLocation($event)">
                                            <mat-list>
                                                @for (restaurant of currentPageData; track trackBySocialIdFn($index, restaurant)) {
                                                    <mat-list-item
                                                        class="group mb-2 !h-14 rounded-xl !px-4 hover:bg-malou-color-background-dark"
                                                        [ngClass]="{
                                                            'bg-malou-color-background-dark':
                                                                selectedLocation()?.socialId === restaurant.socialId,
                                                        }">
                                                        <mat-radio-button class="!flex h-full w-full" [value]="restaurant">
                                                            <div class="flex w-full flex-col">
                                                                <div mat-line>
                                                                    <div class="flex items-center">
                                                                        <p
                                                                            class="malou-text-12--bold w-96 truncate text-malou-color-text-1 sm:w-56 sm:group-hover:w-44"
                                                                            target="_blank">
                                                                            {{ restaurant.name }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <p
                                                                    class="!malou-text-10--regular w-96 truncate italic text-malou-color-text-2 sm:w-56 sm:group-hover:w-44"
                                                                    mat-line>
                                                                    {{ restaurant?.formattedAddress }}
                                                                </p>
                                                            </div>
                                                            <div class="flex flex-1 items-center justify-end">
                                                                @if (!!restaurant.socialUrl) {
                                                                    <a
                                                                        class="malou-text-10--bold absolute right-0 top-[50%] mr-5 hidden translate-x-[0%] translate-y-[-50%] text-malou-color-primary group-hover:block"
                                                                        target="_blank"
                                                                        [ngClass]="{
                                                                            '!block': selectedLocation()?.socialId === restaurant.socialId,
                                                                        }"
                                                                        [href]="restaurant.socialUrl">
                                                                        {{ 'new_restaurant.see_page' | translate }}
                                                                    </a>
                                                                }
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-list-item>
                                                }
                                            </mat-list>
                                        </mat-radio-group>
                                    } @else {
                                        <div class="m-8 flex flex-1 flex-col items-center justify-center">
                                            <img class="mb-5 h-16" [src]="'Cook' | illustrationPathResolver" />
                                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                                {{ 'platforms.connection.choose_restaurant.no_results_filter' | translate }}
                                            </p>
                                        </div>
                                    }
                                }
                                <app-paginator
                                    class="mb-5"
                                    [shouldBeHidden]="(restaurantsFound | asType: PlatformSearchArray).length < 50"
                                    [pageSize]="50"
                                    [pageSizeOptions]="[50]">
                                </app-paginator>
                            </div>
                        }
                    </ng-container>
                } @else {
                    @if (currentPlatformKey() !== PlatformKey.FACEBOOK) {
                        <div class="m-8 flex flex-1 flex-col items-center justify-center">
                            <img class="mb-5 h-16" [src]="'Cook' | illustrationPathResolver" />
                            <p class="malou-text-12--regular my-1 text-center text-malou-color-text-1">
                                {{ 'platforms.connection.choose_restaurant.no_results' | translate }}
                            </p>
                        </div>
                    } @else {
                        <div class="m-8 flex flex-1 flex-col items-center justify-center">
                            <img class="mb-5 h-16" [src]="'Cook' | illustrationPathResolver" />
                            <p
                                class="malou-text-12--regular my-1 text-center text-malou-color-text-1"
                                innerHTML="{{
                                    'platforms.connection.choose_restaurant.could_not_recover_info' | translate: { url: FB_HELP_LINK }
                                }}"></p>
                        </div>
                    }
                }
            }
        }
    } @else {
        <div class="mt-10 flex justify-center">
            <app-malou-spinner size="medium"></app-malou-spinner>
        </div>
    }
</div>
