import { Action, createReducer, on } from '@ngrx/store';

import { IRestaurant } from ':shared/models';

import * as SideBarActions from './sidenav.actions';

export interface SidenavState {
    isOpened: boolean;
    sidenavWidth: number;
    isSelectBusinessesPanelOpen: boolean;
    // ownRestaurants with faster http request only for this module
    ownRestaurants: Pick<IRestaurant, '_id' | 'logo' | 'name' | 'internalName' | 'address' | 'type'>[];
}

const initialState: SidenavState = {
    isOpened: true,
    sidenavWidth: 0,
    isSelectBusinessesPanelOpen: false,
    ownRestaurants: [],
};

const _sidenavReducer = createReducer(
    initialState,
    on(SideBarActions.open, (state) => ({ ...state, isOpened: true })),
    on(SideBarActions.close, (state) => ({ ...state, isOpened: false })),
    on(SideBarActions.toggle, (state) => ({ ...state, isOpened: !state.isOpened })),
    on(SideBarActions.setSidenavWidth, (state, { sidenavWidth }) => ({ ...state, sidenavWidth })),
    on(SideBarActions.toggleSelectBusinessesPanel, (state) => ({
        ...state,
        isSelectBusinessesPanelOpen: !state.isSelectBusinessesPanelOpen,
    })),
    on(SideBarActions.setOwnRestaurants, (state, { ownRestaurants }) => ({
        ...state,
        ownRestaurants: ownRestaurants,
    }))
);

export function reducer(state: SidenavState | undefined, action: Action): SidenavState {
    return _sidenavReducer(state, action);
}
