<div class="ml-[3%] flex w-[97%] flex-col">
    <div class="flex justify-around">
        @for (restaurantData of aggregatedData(); track $index) {
            <div class="w-28 text-center">
                @if (restaurantData.isMissingData) {
                    <mat-icon
                        class="malou-bg-state-error malou-color-white malou-border-color-error relative top-0.5 !h-3.5 !w-3.5 rounded-full border-2"
                        [svgIcon]="SvgIcon.CROSS"
                        [matTooltipPosition]="'below'"
                        [matTooltip]="'roi.aggregated_performance.errors.one.no_data' | translate"></mat-icon>
                }
                <span class="malou-text-10--regular text-malou-color-text-1">
                    {{ restaurantsNames()[$index] }}
                </span>
            </div>
        }
    </div>
    @if (shouldShowButtonInLegend()) {
        <div class="mt-1 flex justify-around">
            @for (restaurantData of aggregatedData(); track $index) {
                <span
                    class="malou-text-9--semibold malou-color-text-primary w-28 cursor-pointer text-center"
                    (click)="openRestaurantTips(restaurantData.restaurant)"
                    >{{ 'roi.tips.buttons.check_tips' | translate }}</span
                >
            }
        </div>
    }
</div>
