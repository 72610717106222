import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CustomerNaming, FrenchTutoiementVouvoiement } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { Keyword } from ':shared/models/keyword';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-ai-advanced-review-settings-modal-tab',
    templateUrl: './ai-advanced-review-settings-modal-tab.component.html',
    styleUrls: ['./ai-advanced-review-settings-modal-tab.component.scss'],
    standalone: true,
    imports: [
        MatCheckboxModule,
        MatIconModule,
        TranslateModule,
        SelectChipListComponent,
        ApplyPurePipe,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiAdvancedReviewSettingsModalTabComponent {
    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly aiReviewSettingsForm = input.required<
        FormGroup<{
            replyTone: FormControl<FrenchTutoiementVouvoiement>;
            catchphrase: FormControl<string>;
            customerNaming: FormControl<CustomerNaming>;
            shouldTranslateCatchphrase: FormControl<boolean>;
            signatures: FormArray<FormControl<string>>;
            restaurantKeywordIds: FormControl<string[]>;
            forbiddenWords: FormControl<string[]>;
            shouldTranslateSignature: FormControl<boolean>;
        }>
    >();

    get restaurantKeywordIds(): FormControl<string[]> {
        return this.aiReviewSettingsForm().controls['restaurantKeywordIds'] as FormControl<string[]>;
    }

    readonly selectedKeywords = input<Keyword[]>([]);
    readonly close = output();

    readonly SvgIcon = SvgIcon;

    readonly MAX_FORBIDDEN_WORDS = 10;

    readonly isFirstTimeUpdate = signal<boolean>(true);

    displayForbiddenWordWith = (forbiddenWord: string): string => forbiddenWord;

    buildForbiddenWordFromText = (text: string): string => text.trim();

    onKeywordsCtaClick(): void {
        this.close.emit();
        void this._router.navigate([`/restaurants/${this._restaurantsService.currentRestaurant._id}/resources/keywords`]);
    }

    onCheckKeywordChange(keyword: Keyword, checked: boolean): void {
        const ids = this.restaurantKeywordIds.value;
        if (checked) {
            ids.push(keyword.restaurantKeywordId);
        } else {
            const index = ids.indexOf(keyword.restaurantKeywordId);
            ids.splice(index, 1);
        }
        this.restaurantKeywordIds.setValue(ids);
    }

    isKeywordChecked(keyword: Keyword): boolean {
        return this.restaurantKeywordIds.value.includes(keyword.restaurantKeywordId);
    }
}
