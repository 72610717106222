import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Injector, input, OnInit, output, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs';

import { StarWithTextChipComponent } from ':shared/components/star-with-text-chip/star-with-text-chip.component';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { ControlValueAccessorConnectorComponent } from '../control-value-accessor-connector/control-value-accessor-connector';
import { SelectBaseComponent } from '../select-abstract/select-base.component';

interface Rating {
    value: number;
    disabled: boolean;
}

@Component({
    selector: 'app-template-rating-filter',
    templateUrl: './template-rating-filter.component.html',
    styleUrls: ['./template-rating-filter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TemplateRatingFilterComponent),
        },
        PluralTranslatePipe,
    ],
    standalone: true,
    imports: [
        SelectBaseComponent,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgClass,
        MatIconModule,
        TranslateModule,
        PluralTranslatePipe,
        StarWithTextChipComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateRatingFilterComponent extends ControlValueAccessorConnectorComponent implements OnInit {
    readonly title = input<string>('');
    readonly maxRating = input<number>(5);
    readonly minRating = input<number>(0);
    readonly tooltipOnRating = input<string>('');
    readonly required = input<boolean>(false);
    readonly errorMessage = input<string>('');
    readonly selectRatings = output<number[]>();

    readonly availableRatings = signal<Rating[]>([]);

    constructor(
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        readonly injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.availableRatings.set(this._initAvailableRatings(this.minRating(), this.maxRating()));
        const initialValue = this.control.value;
        if (initialValue) {
            this.changeSelection(this.availableRatings().filter((rating) => initialValue.includes(rating.value)));
        }
        this.control.valueChanges
            .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
            .subscribe((ratings: Rating[]) => {
                const ratingsValue = ratings.map(this.compareByRatingValue);
                const selectedRatings = this.availableRatings().filter((availableRating) => ratingsValue.includes(availableRating.value));
                this.changeSelection(selectedRatings);
            });
    }

    changeSelection(selectedRatings: Rating[]): void {
        this.selectRatings.emit(selectedRatings.map((r) => r.value));
        if (!selectedRatings?.length) {
            this.availableRatings.set(this._initAvailableRatings(this.minRating(), this.maxRating()));
            return;
        }

        if (selectedRatings.length === 1) {
            const rat = selectedRatings[0].value;
            const relatedRating = this._getRelatedRatings(rat);
            this.availableRatings.update((availableRatings) =>
                availableRatings.map((rating) => ({
                    disabled: !relatedRating.includes(rating.value),
                    value: rating.value,
                }))
            );
            return;
        }

        if (selectedRatings.length === 2) {
            this.availableRatings.update((availableRatings) =>
                availableRatings.map((rating) => ({
                    disabled: !selectedRatings.map((r) => r.value).includes(rating.value),
                    value: rating.value,
                }))
            );
            return;
        }
    }

    displayWithRating = (rating: Rating): string => this._pluralTranslatePipe.transform('templates.ratings', rating.value);

    compareByRatingValue(rating?: Rating | number): number {
        if (typeof rating === 'object') {
            return rating?.value;
        }
        return rating ?? 0;
    }

    private _getRelatedRatings(rating: number, minRating = this.minRating(), maxRating = this.maxRating()): number[] {
        if (rating === 0) {
            return [rating];
        } else if (rating === (minRating === 0 ? minRating + 1 : minRating)) {
            return [rating, rating + 1];
        } else if (rating === maxRating) {
            return [rating - 1, rating];
        }
        return [rating - 1, rating, rating + 1];
    }

    private _initAvailableRatings(minRating: number, maxRating: number): Rating[] {
        return Array.from({ length: maxRating - minRating + 1 }, (_, i) => i + minRating).map((rating) => ({
            value: rating,
            disabled: false,
        }));
    }
}
