import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

import { PlatformKey } from '@malou-io/package-utils';

import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { CredentialsService } from '../credentials.service';
import { FacebookCredentialsComponent } from '../facebook-credentials/facebook-credentials.component';
import { FoursquareCredentialsComponent } from '../foursquare-credentials/foursquare-credentials.component';
import { GmbCredentialsComponent } from '../gmb-credentials/gmb-credentials.component';
import { YelpCredentialsComponent } from '../yelp-credentials/yelp-credentials.component';

@Component({
    selector: 'app-credentials-root',
    templateUrl: './credentials-root.component.html',
    styleUrls: ['./credentials-root.component.scss'],
    standalone: true,
    imports: [
        GmbCredentialsComponent,
        YelpCredentialsComponent,
        FacebookCredentialsComponent,
        FoursquareCredentialsComponent,
        RouterLink,
        RouterOutlet,
        ApplyPurePipe,
    ],
})
export class CredentialsRootComponent implements OnInit {
    public credentials: any = [];

    readonly PlatformKey = PlatformKey;
    constructor(private readonly _credentialService: CredentialsService) {}

    ngOnInit(): void {
        this._credentialService.getUserCredentials().subscribe((res) => {
            this.credentials = res.data;
        });
    }

    getCredential(key: string): any {
        return this.credentials.find((el) => el.key === key);
    }
}
