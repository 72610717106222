import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { PlatformDefinitions } from '@malou-io/package-utils';

import { SuggestionsService } from ':core/services/suggestions.service';
import { DEFAULT_COMPARISON_KEYS, Platform, PlatformComparison, Restaurant } from ':shared/models';

import * as SuggestionActions from './suggestions.actions';

@Injectable()
export class SuggestionEffect {
    readonly loadInformationSuggestions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(SuggestionActions.loadInformationSuggestions),
            switchMap((props) => {
                if (!this.canHaveSuggestions(props.restaurant)) {
                    return EMPTY;
                }
                return forkJoin([of(props.restaurant), this._suggestionsService.getSuggestions(props.restaurant._id)]);
            }),
            filter(Boolean),
            map(([restaurant, suggestion]) => {
                const restaurantPlatform = suggestion?.data?.suggestionData as Platform;
                const allComparisons = new PlatformComparison(restaurant, restaurantPlatform).getDifferentComparisonsByKeys(
                    DEFAULT_COMPARISON_KEYS
                );
                const comparisons = allComparisons?.filter((data) => suggestion?.data?.suggestionKeys?.includes(data.key));
                return SuggestionActions.editInformationSuggestions({
                    suggestion: {
                        comparisons,
                        restaurant,
                        malouErrorCode: suggestion?.data?.malouErrorCode,
                    },
                });
            }),
            catchError((err) => {
                console.warn('err :', err);
                return EMPTY;
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _suggestionsService: SuggestionsService
    ) {}

    canHaveSuggestions = (restaurant: Restaurant): boolean => {
        const platformsWhichCanHandleSuggestions = PlatformDefinitions.getPlatformKeysWithSuggestions();
        return !!restaurant.access.find((access) => platformsWhichCanHandleSuggestions.includes(access.platformKey));
    };
}
