@if (selectableValues().length === 0) {
    <ng-container *ngTemplateOutlet="noResultsTemplate"></ng-container>
} @else {
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
}

<ng-template #mainTemplate>
    <div class="flex h-full flex-col gap-y-1">
        @if (translations().title) {
            <div class="malou-text-10--regular malou-color-text-2">
                {{ translations().title! | translate }}
            </div>
        }
        <div class="flex min-h-0 grow flex-col gap-y-4">
            <ng-container *ngTemplateOutlet="resultsTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #resultsTemplate>
    @if (showSearchInput()) {
        <app-search
            [debounceTime]="0"
            [placeholder]="'common.search' | translate"
            [disabled]="isDisabled()"
            (searchChange)="onSearchChange($event)"></app-search>
    }
    <div class="flex min-h-[60px] flex-col gap-y-4 overflow-y-auto pr-2">
        @if (duplicatedAggregatedValues().length > 0) {
            <ng-container *ngTemplateOutlet="duplicatesTemplate"></ng-container>
        }
        <mat-radio-group class="flex flex-col gap-y-2" [disabled]="isDisabled()" (change)="selectionChange.emit($event.value)">
            @for (aggregatedValue of aggregatedValues(); track aggregatedValue.id) {
                <mat-radio-button
                    class="group rounded-md px-4 py-2 hover:bg-malou-color-background-purple"
                    data-testid="business-selector-item"
                    [value]="aggregatedValue.original"
                    [checked]="aggregatedValue.compareValue === compareValue()">
                    <div class="ml-4 flex items-center justify-between">
                        <div class="flex min-h-[22px] min-w-0 grow flex-col">
                            <div class="malou-text-12--bold malou-color-text-1 truncate">
                                {{ aggregatedValue.computed.title }}
                            </div>
                            @if (aggregatedValue.computed.subtitle) {
                                <div class="malou-text-12--regular malou-color-text-2 truncate italic">
                                    <span>{{ aggregatedValue.computed.subtitle }}</span>
                                </div>
                            }
                        </div>
                        <div>
                            @if (aggregatedValue.computed.link) {
                                <a
                                    class="malou-color-primary malou-text-10--bold hidden group-hover:block"
                                    target="_blank"
                                    [href]="aggregatedValue.computed.link">
                                    {{ 'platforms.connection_new.shared.business_selector.see_page' | translate }}
                                </a>
                            }
                        </div>
                    </div>
                </mat-radio-button>
            }
        </mat-radio-group>
    </div>
</ng-template>

<ng-template #duplicatesTemplate>
    <mat-accordion>
        <mat-expansion-panel class="!rounded-xl !bg-malou-color-state-warn--light !shadow-none">
            <mat-expansion-panel-header class="!bg-malou-color-state-warn--light hover:!bg-malou-color-state-warn--light">
                <div class="flex items-center gap-x-2">
                    <mat-icon class="malou-color-state-warn !h-4 !w-4" [svgIcon]="SvgIcon.INFO"></mat-icon>
                    <span class="malou-text-10--semibold malou-color-state-warn">{{
                        'platforms.connection_new.shared.business_selector.duplicates_found' | translate
                    }}</span>
                </div>
            </mat-expansion-panel-header>
            <div>
                @for (duplicate of duplicatedAggregatedValues(); track duplicate.id) {
                    <div class="leading-none">
                        <span class="malou-text-10--medium malou-color-state-warn">• {{ duplicate.computed.title }} - </span>
                        <span class="malou-text-10--regular malou-color-state-warn">{{ duplicate.computed.subtitle }}</span>
                    </div>
                }
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-template #noResultsTemplate>
    <div class="flex h-full flex-col items-center justify-center gap-y-4">
        <img class="h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <div class="flex flex-col items-center gap-y-2">
            <div class="malou-color-text-1 malou-text-14--bold">
                {{ 'platforms.connection_new.shared.business_selector.no_results_title' | translate }}
            </div>
            <div class="malou-text-10--regular malou-color-text-2">
                {{ translations().noResultsDescription | translate }}
            </div>
        </div>
    </div>
</ng-template>
