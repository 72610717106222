<div class="my-3 flex flex-col gap-4">
    @for (schedule of businessHoursSchedules(); track schedule; let index = $index; let last = $last) {
        <app-select-days-and-hours
            [selectedDays]="schedule.selectedDays"
            [periods]="schedule.periods"
            [availableDays]="schedule.availableDays"
            [isRestaurantClosedDuringPeriods]="schedule.isClosed"
            [disabled]="disabled()"
            [canDeleteSelect]="businessHoursSchedules().length > 1"
            (selectedDaysChange)="updateScheduleSelectedDays(index, $event)"
            (periodsChange)="updateSchedulePeriods(index, $event)"
            (isRestaurantClosedDuringPeriodsChange)="updateScheduleIsClosed(index, $event)"
            (delete)="deleteSchedule(index)"></app-select-days-and-hours>
        @if (!last || (missingDays().length > 0 && !disabled())) {
            <mat-divider class="!border-malou-color-border-primary"></mat-divider>
        }
    }
</div>

<div>
    @if (missingDays().length > 0 && !disabled()) {
        <button
            class="malou-btn-flat !p-0"
            mat-button
            matTooltip="{{ 'information.business_hours.add_schedule' | translate }}"
            (click)="addSchedule()">
            <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
            {{ 'information.business_hours.add_schedule' | translate }}
        </button>
    }
</div>
