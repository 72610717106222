import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

export interface CursorPosition {
    startPosition: number;
    endPosition: number;
}

export function isEmojiEvent(event: EmojiEvent | string): event is EmojiEvent {
    return !!(event as EmojiEvent)?.emoji;
}

export const createTextWithEmoji = (cursorPosition: CursorPosition, text: string, event: EmojiEvent | string): string => {
    const value = isEmojiEvent(event) ? event.emoji.native : event;
    text = text ?? '';
    return cursorPosition
        ? text.substring(0, cursorPosition.startPosition) + value + text.substring(cursorPosition.endPosition)
        : text + value;
};

export const focusAfterEmoji = (
    textarea: HTMLTextAreaElement | HTMLInputElement,
    cursorPosition: CursorPosition,
    event: EmojiEvent | string
): void => {
    const emojiLength = isEmojiEvent(event) ? (event.emoji?.native?.length ?? 0) : event.length;
    textarea.selectionStart = cursorPosition.startPosition + emojiLength;
    textarea.selectionEnd = cursorPosition.startPosition + emojiLength;
    textarea.focus();
};
