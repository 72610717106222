import { z } from 'zod';

import { ApplicationLanguage, TranslationSource } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const addTranslationToReviewParamsValidator = z
    .object({
        review_id: objectIdValidator,
    })
    .transform((data) => ({
        reviewId: data.review_id,
    }));
export type AddTranslationToReviewParamsDto = z.infer<typeof addTranslationToReviewParamsValidator>;

export const addTranslationToReviewBodyValidator = z.object({
    translation: z.string(),
    language: z.nativeEnum(ApplicationLanguage),
    source: z.nativeEnum(TranslationSource),
    isPrivateReview: z.boolean(),
});
export type AddTranslationToReviewBodyDto = z.infer<typeof addTranslationToReviewBodyValidator>;
