import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AttributesModalComponent, AttributesUpdateData } from ':modules/informations/attributes-modal/attributes-modal.component';
import { InformationsContext } from ':modules/informations/informations.context';
import { RestaurantAttributesComponent } from ':shared/components/restaurant-informations/restaurant-attributes/restaurant-attributes.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, RestaurantAttributesComponent, TranslateModule, MatTooltipModule],
})
export class AttributesComponent {
    @Input() restaurant: Restaurant;
    @Output() attributesChange: EventEmitter<AttributesUpdateData> = new EventEmitter();
    @Output() prepareAttributesDuplication: EventEmitter<void> = new EventEmitter<void>();

    readonly SvgIcon = SvgIcon;

    readonly informationsContext = inject(InformationsContext);

    readonly categoryAttributesError = this.informationsContext.categoryAttributesError;

    constructor(private readonly _customDialogService: CustomDialogService) {
        this.informationsContext.openRestaurantAttributesModal$.pipe(takeUntilDestroyed()).subscribe(() => this.openAttributesDialog());
    }

    openAttributesDialog(): void {
        this._customDialogService
            .open<AttributesModalComponent, any, AttributesUpdateData>(AttributesModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                data: {
                    restaurant: this.restaurant,
                    allAttributes: this.informationsContext.allCategoryAttributes(),
                    restaurantAttributes: this.informationsContext.restaurantAttributes(),
                },
            })
            .afterClosed()
            .subscribe({
                next: (data) => {
                    if (data && Object.keys(data).length > 0) {
                        this.attributesChange.emit(data);
                    }
                },
            });
    }

    onPrepareAttributesDuplication(): void {
        this.prepareAttributesDuplication.emit();
    }
}
