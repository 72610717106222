import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { interval, startWith } from 'rxjs';

import { isNotNil, TimeInMilliseconds } from '@malou-io/package-utils';

import { LoaderProgressComponent, LoaderProgressSize } from ':shared/components/loader-progress/loader-progress.component';

export const MAX_PROGRESS = 99;

@Component({
    selector: 'app-loader-page',
    templateUrl: './loader-page.component.html',
    styleUrls: ['./loader-page.component.scss'],
    imports: [TranslateModule, LoaderProgressComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderPageComponent {
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly generationStartDate = input.required<Date | null>();
    readonly generationEstimatedTime = input.required<number>();
    readonly title = input<string>(this._translateService.instant('keywords.keywords_generating_loader.generating_keywords'));
    readonly footerText = input<string>(this._translateService.instant('keywords.keywords_generating_loader.you_can_leave_this_page'));

    readonly LoaderProgressSize = LoaderProgressSize;

    readonly generationProgress = computed(() => {
        const generationStartDate = this.generationStartDate();
        if (!generationStartDate) {
            return 100;
        }
        if (isNotNil(this._interval())) {
            const now = new Date();
            const progressTime = now.getTime() - generationStartDate.getTime();
            const progressPercentage = Math.round((progressTime / this.generationEstimatedTime()) * 100);
            return Math.min(progressPercentage, MAX_PROGRESS);
        }
        return 0;
    });

    private readonly _interval$ = interval(1 * TimeInMilliseconds.SECOND).pipe(startWith(0), takeUntilDestroyed(this._destroyRef));
    private readonly _interval = toSignal(this._interval$);
}
