import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ValueWithDifference } from ':shared/interfaces/value-with-difference.interface';
import { Category } from ':shared/models';

@Component({
    selector: 'app-comparison-categories',
    templateUrl: './comparison-categories.component.html',
    styleUrls: ['./comparison-categories.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ComparisonCategoriesComponent implements OnInit {
    @Input() categories: Category[] = [];
    @Input() referenceCategories: Category[] = [];
    categoriesWithDifference: ValueWithDifference<string>[] = [];

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        this.categoriesWithDifference = this.computeCategoriesWithDifference(this.categories, this.referenceCategories);
    }

    computeCategoriesWithDifference(categories: Category[], referenceCategories: Category[]): ValueWithDifference<string>[] {
        return categories.map((category, i) => {
            const lang = this.getLang();
            const referenceCategory = referenceCategories?.[i];
            return {
                value: this.getCategoryName(category, lang),
                hasDifference: this.checkDifference(category, referenceCategory, lang),
            };
        });
    }

    checkDifference(category: Category, referenceCategory: Category, lang: string = this.getLang()): boolean {
        const categoryName = this.getCategoryName(category, lang);
        const referenceCategoryName = this.getCategoryName(referenceCategory, lang);
        return !!referenceCategoryName && categoryName !== referenceCategoryName;
    }

    getLang(): string {
        return this._translate.currentLang || 'backup';
    }

    getCategoryName(category: Category, lang: string): string {
        return category?.categoryName?.[lang];
    }
}
