import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChecklistDto, ChecklistTaskDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ChecklistService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/checklists`;
    readonly _http = inject(HttpClient);

    constructor() {}

    getChecklistForRestaurant(restaurantId: string): Observable<ApiResultV2<ChecklistDto>> {
        return this._http.get<ApiResultV2<ChecklistDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`);
    }

    updateChecklistById({
        checklistId,
        tasks,
        isDisplayed,
    }: {
        checklistId: string;
        tasks?: ChecklistTaskDto[];
        isDisplayed?: boolean;
    }): Observable<ApiResultV2<void>> {
        return this._http.post<ApiResultV2<void>>(`${this.API_BASE_URL}/${checklistId}`, { tasks, isDisplayed });
    }
}
