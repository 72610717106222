<div class="flex items-center gap-x-2">
    @if (showTitle()) {
        <div class="malou-color-text-1 malou-text-12--medium">
            {{ 'reviews.auto_reply' | translate }}
        </div>
    }
    <div class="malou-color-background-dark h-7.5 w-7.5 cursor-pointer rounded-full p-[3px]" (click)="goToAutoReplyModels()">
        <mat-icon class="text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.FLASH"></mat-icon>
    </div>
</div>
