import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'absolute',
    standalone: true,
})
export class AbsolutePipe implements PipeTransform {
    transform(value: number | null): number {
        if (!value) {
            return 0;
        }
        return Math.abs(value);
    }
}
