import { Comment, CommentsFilters, Post } from ':shared/models';

export type IPost = {
    // Omitting all functions from Post
    [K in keyof Post as Post[K] extends Function ? never : K]: Post[K];
};

// Omitting all non booleans from CommentsFilters
export type ToogleableFiltersKey = keyof { [K in keyof CommentsFilters as CommentsFilters[K] extends boolean | undefined ? K : never] };

export interface IPostWithCommentsAndMentions extends IPost {
    areAllCommentsAndMentionsArchived: string;
    unansweredComments: Comment[];
    unansweredMentions: Comment[];
}

export class PostWithComments extends Post {
    comments: Comment[];
    lastCommentDate: Date;

    public constructor(init: Partial<PostWithComments> = {}) {
        super();
        Object.assign(this, init);
        this.comments = init.comments ?? [];
    }
}

export class PostWithCommentsAndMentions extends Post {
    areAllCommentsAndMentionsArchived: boolean;
    unansweredComments: Comment[] = [];
    unansweredMentions: Comment[] = [];
    lastCommentDate: Date;

    public constructor(init: Partial<IPostWithCommentsAndMentions> = {}) {
        super();
        Object.assign(this, init);
        this.unansweredComments = init?.unansweredComments?.filter((c) => !!c).map((c) => new Comment(c as any)) || [];
        this.unansweredMentions = init?.unansweredMentions?.filter((c) => !!c).map((c) => new Comment(c as any)) || [];
    }

    getFirstUnAnsweredCommentOrMention(): Comment {
        return this.unansweredComments?.[0] || this.unansweredMentions?.[0];
    }
}
