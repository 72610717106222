import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { GetVerifiedEmailsInSESBodyDto, GetVerifiedEmailsInSESResponseDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult } from ':shared/models';
import { Campaign, Email } from ':shared/models/campaign';

@Injectable({
    providedIn: 'root',
})
export class CampaignsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/campaigns`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    createCampaign(data: Partial<Campaign>): Observable<ApiResult<Campaign>> {
        return this._http.post<ApiResult<Campaign>>(`${this.API_BASE_URL}/`, { ...data }, { withCredentials: true }).pipe(
            map((res) => {
                res.data = new Campaign(res.data);
                return res;
            })
        );
    }

    sendCampaignMails(campaignId: string, restaurantId: string): Observable<ApiResult> {
        return this._http.post<ApiResult>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/send_mails/${campaignId}`,
            {},
            { withCredentials: true }
        );
    }

    sendTestEmail(restaurantId: string, platformKey: string, emailData: Email): Observable<ApiResult> {
        return this._http.post<ApiResult>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/send_test_mail/${platformKey}`,
            { data: emailData },
            { withCredentials: true }
        );
    }

    getAWSVerifiedExpeditorEmails(body: GetVerifiedEmailsInSESBodyDto): Observable<ApiResultV2<GetVerifiedEmailsInSESResponseDto>> {
        return this._http.post<ApiResultV2<GetVerifiedEmailsInSESResponseDto>>(`${this.API_BASE_URL}/aws_verified_emails`, body);
    }

    sendVerificationEmail(email: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/send_verification_mail`, { email }, { withCredentials: true, params });
    }

    getCampaignsByRestaurantId(restaurantId: string): Observable<ApiResult<Campaign[]>> {
        return this._http.get<ApiResult<Campaign[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { withCredentials: true }).pipe(
            map((res) => {
                res.data = res.data.map((c) => new Campaign(c));
                return res;
            })
        );
    }

    deleteManyCampaigns(campaignIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/delete`, { campaignIds }, { withCredentials: true, params });
    }

    deleteCampaign(campaignId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${campaignId}`, { withCredentials: true, params });
    }

    endCampaign(campaignId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult<Campaign>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.put<ApiResult<Campaign>>(`${this.API_BASE_URL}/${campaignId}/end`, {}, { withCredentials: true, params });
    }

    getClientsSources(campaignId: string): Observable<ApiResult<{ [key: string]: number }>> {
        return this._http.get<ApiResult<{ [key: string]: number }>>(`${this.API_BASE_URL}/${campaignId}/clients_sources`, {
            withCredentials: true,
        });
    }
}
