<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith"
    [sortBy]="sortBy"
    [testId]="testId()"
    (selectBaseChange)="selectTemplatesChange.emit($event)">
    <ng-template let-value="value" #optionTemplate>
        <div
            class="flex flex-row items-center gap-x-2"
            [matTooltip]="value.text.length > 50 ? (templateReplacer() | applySelfPure: 'replaceToChipText' : value.text) : ''"
            [matTooltipTouchGestures]="'off'">
            <span class="malou-text-12--medium malou-color-text-1">{{ value.name }}</span>
            <span class="malou-text-12 italic">{{
                templateReplacer() | applySelfPure: 'replaceToChipText' : value.text | shortText: 50
            }}</span>
            <div class="ml-auto flex gap-x-1">
                @for (rating of value.rating; track $index) {
                    <div>
                        <app-star-with-text-chip [value]="rating === 0 ? null : rating"></app-star-with-text-chip>
                    </div>
                }
            </div>
        </div>
    </ng-template>
</app-select-base>
