import { z } from 'zod';

import { NfcsPlatformKey, NfcStar } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const createStickerBodyValidator = z.object({
    chipName: z.string().nullish(),
    restaurantId: objectIdValidator,
    notes: z.string().nullish(),
    active: z.boolean(),
    platformKey: z.nativeEnum(NfcsPlatformKey),
    redirectionLink: z.string().url(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)),
});
export type CreateStickerBodyDto = z.infer<typeof createStickerBodyValidator>;
