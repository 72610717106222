<div class="flex flex-col gap-5">
    <app-simple-select
        [defaultValue]="actionType()"
        [testId]="'social-post-mapster-input'"
        [placeholder]="'social_posts.upsert_social_post_modal.content_form.cta.title' | translate"
        [values]="MapstrCtaButtonTypeValues"
        [displayWith]="mapstrCtaButtonTypeDisplayWith"
        (simpleSelectChange)="onActionTypeChange($event)">
    </app-simple-select>

    @if (actionType()) {
        <app-input-text
            placeholder="https://"
            [defaultValue]="url()"
            [testId]="'social-post-mapster-cta-input'"
            [title]="'social_posts.new_social_post.add_mapstr_cta_text' | translate"
            [errorMessage]="urlErrorMessage()"
            (inputTextChange)="onUrlChange($event)">
        </app-input-text>
    }
</div>
