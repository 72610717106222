<form class="flex flex-col gap-y-4 pr-1" [formGroup]="postSettingsForm()">
    <div [class.hidden]="!isFrench()">
        <div class="malou-text-10--regular malou-color-text-2 mb-3">
            {{ 'restaurant_ai_settings.modals.upsert.tabs.general.reply_tone.title' | translate }} *
        </div>

        <mat-radio-group class="flex gap-x-5" formControlName="denomination" color="primary">
            @for (item of tutoiementVouvoiement; track $index) {
                <mat-radio-button class="malou-small-radio-button" data-testid="general-ai-settings-formal-input" [value]="item">
                    <span class="malou-text-10--semibold">
                        {{ item | enumTranslate: 'reply_tone' }}
                    </span>
                </mat-radio-button>
            }
        </mat-radio-group>
    </div>

    <app-select-chip-list
        formControlName="tone"
        [displayWith]="toneDisplayWithEnum"
        [checkboxOption]="true"
        [errorMessage]="
            postSettingsForm().get('tone')?.dirty && postSettingsForm().get('tone')?.invalid
                ? ('ai.posts.tone_length_error' | translate)
                : null
        "
        [maxSelectableValues]="2"
        [title]="'ai.posts.tone' | translate"
        [values]="tones">
    </app-select-chip-list>
    <app-select
        formControlName="length"
        [displayWith]="lengthDisplayWithEnum"
        [title]="'ai.posts.length' | translate"
        [values]="lengths"
        [required]="true">
    </app-select>
    <app-select
        formControlName="emoji"
        [displayWith]="emojiDisplayWithEnum"
        [title]="'ai.posts.emoji' | translate"
        [values]="emojis"
        [required]="true">
    </app-select>
    <app-text-area
        formControlName="prompt"
        [showAsPanel]="true"
        [title]="'ai.posts.prompt' | translate"
        [placeholder]="'ai.posts.prompt_placeholder' | translate"
        [isEmojiPickerEnabled]="false"
        [rows]="6"></app-text-area>
</form>
