import { NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { isNumber } from 'lodash';
import { ImageCroppedEvent, ImageCropperModule, OutputFormat } from 'ngx-image-cropper';

import { PlatformKey } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SkeletonComponent } from '../skeleton/skeleton.component';

@Component({
    selector: 'app-edit-image-dialog',
    templateUrl: './edit-image-dialog.component.html',
    styleUrls: ['./edit-image-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        ImageCropperModule,
        MatButtonModule,
        MatIconModule,
        MatSliderModule,
        MatTooltipModule,
        TranslateModule,
        SkeletonComponent,
    ],
})
export class EditImageDialogComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    croppedImage: string | null = '';
    savedImage = '';
    ratio = 1 / 1;
    rotation = 0;
    state = 'crop';
    showDefault = true;
    format: OutputFormat = 'jpeg';
    showError = false;
    shouldDisableCropButtons = false;
    shouldShowTextInfo = false;

    dimensionsButtonToggled = false;
    rotationButtonToggled = false;

    constructor(
        public dialogRef: MatDialogRef<EditImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { imgUrl: string; selectedPlatforms: string[]; index?: number; ratio?: number; name?: string }
    ) {}

    ngOnInit(): void {
        if (this.data?.imgUrl?.includes('.png')) {
            this.format = 'png';
        }
        if (this.data.ratio) {
            this.ratio = this.data.ratio;
        }
        if (isNumber(this.data.index)) {
            this.shouldDisableCropButtons = this.data.index !== 0;
            this.shouldShowTextInfo = this.data.index === 0;
        }
        fetch(this.data.imgUrl + '?x-request=bar')
            .then((res) => {
                if (res.status >= 400) {
                    this.showDefault = false;
                    this.showError = true;
                } else {
                    this.showDefault = false;
                    this.showError = false;
                }
            })
            .catch((_e) => {
                this.showDefault = false;
                this.showError = true;
            });
    }

    setCroppedImage(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64 ?? null;
    }

    close(emit = false): void {
        if (emit) {
            this.dialogRef.close({
                croppedImage: this.croppedImage,
                ratio: this.ratio,
            });
        } else {
            this.dialogRef.close(null);
        }
    }

    changeAspectRatio(event: MouseEvent): void {
        const target = event.target as HTMLSpanElement;
        document.getElementsByClassName('selected-crop-option')[0].classList.remove('selected-crop-option');
        target.classList.add('selected-crop-option');
        const cropOptions = {
            square: 1 / 1,
            landscape: 16 / 9,
            portrait: 9 / 16,
        };
        this.ratio = cropOptions[target.id];
    }

    isFacebookOnlySelected(): boolean {
        return this.data.selectedPlatforms?.includes(PlatformKey.FACEBOOK) && this.data.selectedPlatforms?.length === 1;
    }

    closeCropDimensions(): void {
        this.dimensionsButtonToggled = false;
    }

    toggleRotation(): void {
        this.rotationButtonToggled = !this.rotationButtonToggled;
        this.dimensionsButtonToggled = false;
    }

    toggleDimension(): void {
        this.dimensionsButtonToggled = !this.dimensionsButtonToggled;
        this.rotationButtonToggled = false;
    }

    onSliderInput(event: any): void {
        this.rotation = event.target.value ?? 0;
    }
}
