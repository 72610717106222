<div class="mx-4 flex items-center justify-between gap-x-2">
    <div class="min-w-0 grow cursor-pointer" (click)="togglePanel()" #container>
        <app-sidenav-content-restaurant-info
            [isEmptyState]="isEmptyState()"
            [logo]="restaurantLogo() ?? ('default_logo' | imagePathResolver)"
            [countLogoOverlay]="isAllRestaurantsSelected() ? ownRestaurants().length : undefined"
            [line1]="
                isAllRestaurantsSelected()
                    ? ('sidenav_content.select_restaurant.all_businesses' | translate)
                    : selectedRestaurant()?.internalName
            "
            [svgIconOnLine1]="SvgIcon.CHEVRON_UP_AND_DOWN"
            [line2]="
                isAllRestaurantsSelected()
                    ? undefined
                    : (selectedRestaurant() | applySelfPure: 'isBrandBusiness')
                      ? ('common.brand_account' | translate)
                      : (selectedRestaurant() | applySelfPure: 'getFullFormattedAddress')
            "
            [hideLines]="!isSidenavOpened()"></app-sidenav-content-restaurant-info>
    </div>
    @if (isSidenavOpened()) {
        <div class="mb-[10px]">
            <app-sidenav-toggle-button
                [svgIconWhenClosed]="SvgIcon.LEFT_PANEL_CLOSE"
                [svgIconWhenOpen]="SvgIcon.LEFT_PANEL_CLOSE"></app-sidenav-toggle-button>
        </div>
    }
</div>

@if (isSelectBusinessesPanelOpen()) {
    <div [additionalElement]="container" (appClickOutside)="togglePanel()">
        <app-sidenav-content-select-restaurant-panel></app-sidenav-content-select-restaurant-panel>
    </div>
}
