<mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary gap-4" multiple>
    <mat-button-toggle
        class="!h-12.5 rounded-[10px]"
        [checked]="isChecked.published"
        [name]="options.published"
        (click)="$event.stopPropagation()"
        (change)="changeOptions($event, 'published')">
        <div class="malou-text-12--medium pt-0.5">
            {{ options.published }}
        </div>
    </mat-button-toggle>
    <mat-button-toggle
        class="!h-12.5 rounded-[10px]"
        [checked]="isChecked.pending"
        [name]="options.pending"
        (click)="$event.stopPropagation()"
        (change)="changeOptions($event, 'pending')">
        <div class="malou-text-12--medium pt-0.5">
            {{ options.pending }}
        </div>
    </mat-button-toggle>
    <mat-button-toggle
        class="!h-12.5 rounded-[10px]"
        [checked]="isChecked.draft"
        [name]="options.draft"
        (click)="$event.stopPropagation()"
        (change)="changeOptions($event, 'draft')">
        <div class="malou-text-12--medium pt-0.5">
            {{ options.draft }}
        </div>
    </mat-button-toggle>
</mat-button-toggle-group>
