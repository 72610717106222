<ng-container *ngTemplateOutlet="(isLoading$ | async) ? loading : content"></ng-container>

<ng-template #loading>
    <div class="h-full overflow-y-auto px-7.5 py-4">
        <div class="w-full">
            <app-skeleton
                flexDirection="flex-row"
                skeletonClass="secondary-bg h-[50px] basis-52"
                justifyContent="justify-end"
                [count]="3"></app-skeleton>
        </div>

        <div class="inspirations-grid mt-5 gap-4">
            @for (item of [0, 1, 2, 3, 4, 5, 6, 7]; track item) {
                <app-skeleton skeletonClass="h-[460px] secondary-bg p-6" [animate]="false">
                    <ng-template #nestedSkeletonTemplate>
                        <div class="flex flex-col gap-4">
                            <div class="flex items-center justify-between">
                                <div class="mr-2">
                                    <app-skeleton skeletonClass="!h-12.5 !w-[50px]" [appearance]="'circle'"></app-skeleton>
                                </div>
                                <div class="title-subtitle grow">
                                    <app-skeleton skeletonClass="!w-full !h-[18px] mb-2"></app-skeleton>
                                    <app-skeleton skeletonClass="!w-full !h-[18px]"></app-skeleton>
                                </div>
                                <div class="ml-2">
                                    <app-skeleton skeletonClass="!h-12.5 !w-[50px]"></app-skeleton>
                                </div>
                            </div>
                            <app-skeleton heightClass="h-[150px]" skeletonClass="!w-full !h-full"></app-skeleton>
                            <app-skeleton
                                heightClass="h-[30px]"
                                skeletonClass="!w-10/12 !h-full"
                                justifyContent="justify-center"
                                flexDirection="flex-row"></app-skeleton>
                            <app-skeleton
                                heightClass="h-[60px]"
                                skeletonClass="!w-2/3 !h-full"
                                justifyContent="justify-around"
                                flexDirection="flex-row"
                                [count]="2"></app-skeleton>
                            <app-skeleton heightClass="h-[60px]" skeletonClass="!w-full !h-full"></app-skeleton>
                        </div>
                    </ng-template>
                </app-skeleton>
            }
        </div>
    </div>
</ng-template>

<ng-template #header>
    <div class="flex justify-end gap-4 px-7.5 pb-3.5">
        <div class="flex lg:hidden">
            <mat-button-toggle-group class="malou-group-toggle-btn" (change)="changePostsView()">
                <mat-button-toggle [checked]="!isSavedPostsView">{{ 'inspirations.news' | translate }}</mat-button-toggle>
                <mat-button-toggle [checked]="isSavedPostsView">{{ 'inspirations.saved' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="flex md:w-full">
            <app-sort-by-filters
                class="w-full"
                [sortOptions]="filterOptions"
                [sortBy]="sortKey"
                [sortOrder]="sortDirection"
                [class]="'min-w-[220px]'"
                (changeSortBy)="applyFilter($event)"
                (toggleSortOrder)="onSortOrderChange()">
            </app-sort-by-filters>
        </div>

        <div class="flex md:hidden">
            <button
                class="malou-btn-raised--primary malou-background-gradient-5 btn-xl !h-12.5"
                mat-raised-button
                appCheckPermissions
                [context]="{ concernedPlatforms: [PlatformKey.FACEBOOK], action: 'search_accounts' }"
                (myClick)="openEditInspiringAccounts()">
                {{ 'inspirations.edit_inspiring_accounts' | translate }}
            </button>
        </div>

        <div class="hidden md:flex">
            <button
                class="malou-btn-icon--primary malou-background-gradient-5 btn-xl"
                mat-icon-button
                appCheckPermissions
                [context]="{ concernedPlatforms: [PlatformKey.FACEBOOK], action: 'search_accounts' }"
                (myClick)="openEditInspiringAccounts()">
                <mat-icon [svgIcon]="SvgIcon.EDIT"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #content>
    <ng-container *ngTemplateOutlet="isIgPlatformConnected ? igConnected : igNotConnected"></ng-container>
</ng-template>

<ng-template #igConnected>
    <ng-container *ngTemplateOutlet="allPosts?.length ? posts : noPostsFound"></ng-container>
</ng-template>

<ng-template #posts>
    <div class="flex h-full flex-col py-4">
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <div class="!relative min-h-0 grow overflow-y-scroll px-7.5 pb-3.5" id="inspirations-scroll-container">
            <ng-container *ngTemplateOutlet="isSavedPostsView ? bookmarkedPostsTemplate : allPostsTemplate"></ng-container>

            <app-scroll-to-top
                class="fixed right-7.5 md:!bottom-[60px] lg:bottom-[85px]"
                container="#inspirations-scroll-container"
                [ngClass]="{ 'bottom-8': footersVisibilityCount === 0, 'bottom-24': footersVisibilityCount >= 1 }">
            </app-scroll-to-top>

            <div class="!sticky inset-6 top-auto hidden lg:inline">
                <mat-button-toggle-group class="malou-group-toggle-mobile-btn" (change)="changePostsView()">
                    <mat-button-toggle [checked]="!isSavedPostsView">{{ 'inspirations.news' | translate }}</mat-button-toggle>
                    <mat-button-toggle [checked]="isSavedPostsView">{{ 'inspirations.saved' | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #allPostsTemplate>
    <div class="inspirations-grid gap-4">
        @for (post of allPosts; track post.socialId) {
            <app-post
                [post]="post"
                [hoveredPosts]="getHoveredPosts | applyPure: post.username"
                [labelInsight]="getSelectedOptionFilterField | applyPure: 'label'"
                [metricKey]="getSelectedOptionFilterField | applyPure: 'key'"
                [displaySaveIcon]="true"
                [isSaved]="isSaved | applyPure: post : restaurant.bookmarkedPosts.length"
                (save)="save($event)">
            </app-post>
        }
    </div>
</ng-template>

<ng-template #bookmarkedPostsTemplate>
    @if (bookmarkedPosts.length) {
        <div class="inspirations-grid gap-4">
            @for (bookmarkedPost of bookmarkedPosts; track bookmarkedPost) {
                <app-post
                    [post]="bookmarkedPost.post"
                    [hoveredPosts]="bookmarkedPost.hoveredPosts"
                    [labelInsight]="getSelectedOptionFilterField | applyPure: 'label'"
                    [metricKey]="getSelectedOptionFilterField | applyPure: 'key'"
                    [displaySaveIcon]="true"
                    [isSaved]="true"
                    (save)="save($event)">
                </app-post>
            }
        </div>
    } @else {
        <div class="flex h-[75vh] flex-col items-center justify-center">
            <img class="h-32" loading="lazy" [src]="'Cook' | illustrationPathResolver" />
            <p class="malou-text-14--bold mt-9 text-center">
                {{ 'inspirations.no_bookmarked_posts_title' | translate }}
            </p>
            <p class="malou-text-10--regular my-4.5 text-center">
                {{ 'inspirations.no_bookmarked_posts' | translate }}
            </p>
        </div>
    }
</ng-template>

<ng-template #igNotConnected>
    <div class="flex h-full flex-col items-center justify-center py-4">
        <img class="h-32" loading="lazy" [src]="'Goggles' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">{{ 'inspirations.connect_to_ig' | translate }}</p>
        <p class="malou-text-10--regular my-4.5 text-center">
            {{ 'inspirations.add_accounts_not_allowed' | translate }}
        </p>
        <button class="malou-btn-raised--primary malou-background-gradient-5 btn-xl !h-12.5" mat-raised-button (click)="goToPlatforms()">
            {{ 'inspirations.login_ig' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #noPostsFound>
    <div class="flex h-full flex-col items-center justify-center py-4">
        <img class="h-32" loading="lazy" [src]="'Goggles' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'inspirations.inspire_expire' | translate }}
        </p>
        <p class="malou-text-10--regular my-4.5 text-center">
            {{ 'inspirations.add_inspirational_account' | translate }}
        </p>
        <button
            class="malou-btn-raised--primary malou-background-gradient-5 btn-xl !h-12.5"
            mat-raised-button
            (click)="openEditInspiringAccounts()">
            {{ 'inspirations.add_account' | translate }}
        </button>
    </div>
</ng-template>
