@if (!isParentError) {
    @if (!isParentLoading) {
        <div class="malou-simple-card flex w-full flex-col gap-3 px-6 py-3 md:px-2">
            <div>
                <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            </div>
            <ng-container [ngTemplateOutlet]="hasData() ? tableTemplate : noDataTemplate"> </ng-container>
        </div>
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #titleTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.wheel_of_fortune.gifts_distribution.distribution_by_gift' | translate }}
        </span>
    </div>
</ng-template>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table overflow-auto pr-3"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="defaultSort.active"
        [matSortDirection]="defaultSort.direction"
        (matSortChange)="tableSortByChange.emit($event)"
        #table="matTable">
        <ng-container [matColumnDef]="TableFieldName.GIFT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'statistics.wheel_of_fortune.gifts_distribution.gifts' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-text-13--semibold text-malou-color-text-1">
                @if (rowData.isFirst) {
                    <span class="mr-1.5">
                        <img class="h-7 w-7" [src]="'first' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                    </span>
                }
                @if (rowData.isSecond) {
                    <span class="mr-1.5">
                        <img class="h-7 w-7" [src]="'second' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                    </span>
                }
                @if (rowData.isThird) {
                    <span class="mr-1.5 h-7">
                        <img class="h-7 w-7" [src]="'third' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                    </span>
                }
                <span>{{ rowData.giftName }}</span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableFieldName.WINNING">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'statistics.wheel_of_fortune.gifts_distribution.winning' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-mat-table-cell-xsmall">
                <span class="mr-1.5">{{ rowData.giftDrawCount }}</span>
                @if (rowData.giftDrawCountDifferenceWithPreviousPeriod) {
                    <app-number-evolution
                        size="small"
                        [value]="rowData.giftDrawCountDifferenceWithPreviousPeriod"
                        [displayedValue]="rowData.giftDrawCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableFieldName.RETRIEVED">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'statistics.wheel_of_fortune.gifts_distribution.retrieved' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-mat-table-cell-xsmall">
                <span class="mr-1.5">{{ rowData.retrievedGiftDrawCount }}</span>
                @if (rowData.retrievedGiftDrawCountDifferenceWithPreviousPeriod) {
                    <app-number-evolution
                        size="small"
                        [value]="rowData.retrievedGiftDrawCountDifferenceWithPreviousPeriod"
                        [displayedValue]="rowData.retrievedGiftDrawCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableFieldName.PERCENTAGE">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall">
                {{ 'statistics.wheel_of_fortune.gifts_distribution.retrieved_rate' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-mat-table-cell-xsmall">
                <span>{{ rowData.retrievedPercentage }}%</span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; table: table; let i = index"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noDataTemplate>
    <div class="flex h-[442px] flex-col items-center justify-center gap-3 py-6">
        <img class="mb-6 h-40" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
        <div class="malou-text-14--bold malou-color-text-1 mb-2">{{ 'statistics.errors.no_result' | translate }}</div>
        <div class="malou-text-10--regular malou-color-text-2">{{ 'statistics.errors.change_period' | translate }}</div>
    </div>
</ng-template>
