<div class="flex flex-wrap gap-4">
    @for (rating of availableRatings(); track rating) {
        <mat-button-toggle-group
            class="malou-single-toggle-icon-btn--tertiary"
            multiple
            [disabled]="disabled()"
            [ngClass]="{ 'col-span-2': rating === 0 }">
            <mat-button-toggle
                class="!h-12.5 min-w-[63px] rounded-[10px]"
                value="{{ rating }}"
                [checked]="ratings() | includes: rating"
                [disabled]="disabled()"
                (change)="toggleRating(rating)">
                <div class="flex h-12 items-center">
                    <span
                        class="mr-0.5 mt-[3px]"
                        [ngClass]="{
                            'malou-text-17--medium': rating > 0,
                            'malou-text-12--medium': rating === 0,
                        }">
                        {{ 'rating_filters' | pluralTranslate: rating }}
                    </span>
                    @if (rating > 0) {
                        <mat-icon class="small-icon" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                    }
                </div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    }
</div>
