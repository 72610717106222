import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { RoiPerformanceScoreDto } from '@malou-io/package-dto';

import { selectCurrentPlatformKeys } from ':modules/platforms/store/platforms.reducer';
import { RoiPerformanceScoreDetails, RoiPerformanceScoreRow } from ':shared/models/roi-performance-score.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { PerformanceScoreItemComponent } from '../performance-score-item/performance-score-item.component';

@Component({
    selector: 'app-performance-score-details-modal',
    standalone: true,
    imports: [MatIconModule, TranslateModule, PerformanceScoreItemComponent, AsyncPipe],
    templateUrl: './performance-score-details-modal.component.html',
    styleUrl: './performance-score-details-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceScoreDetailsModalComponent {
    readonly performanceScoreRows$: Observable<RoiPerformanceScoreRow[]>;

    private readonly _dialogRef = inject(MatDialogRef<PerformanceScoreDetailsModalComponent>);
    private readonly _translateService = inject(TranslateService);
    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            performanceData: RoiPerformanceScoreDto;
        }
    ) {
        this.performanceScoreRows$ = this._store
            .select(selectCurrentPlatformKeys)
            .pipe(
                map((platformKeys) =>
                    new RoiPerformanceScoreDetails(this.data.performanceData ?? {}, platformKeys).buildRoiPerformanceScoreDetails(
                        this._translateService
                    )
                )
            );
    }

    close(): void {
        this._dialogRef.close();
    }
}
