import { Component, Input, OnInit } from '@angular/core';

enum ScoreColor {
    RED = 'red',
    ORANGE = 'orange',
    GREEN = 'green',
    WHITE = 'white',
}

@Component({
    selector: 'app-score-gauge',
    templateUrl: './score-gauge.component.html',
    styleUrls: ['./score-gauge.component.scss'],
    standalone: true,
    imports: [],
})
export class ScoreGaugeComponent implements OnInit {
    possibleScores: number[] = [1, 2, 3, 4, 5];
    scoreColors: Record<number, ScoreColor> = {};

    private _score = 0;
    @Input() set score(value: number) {
        this._score = value;

        this._computeScoreClasses(this._score);
    }

    ngOnInit(): void {
        this._computeScoreClasses(this._score);
    }

    private _computeScoreClasses(score: number): void {
        for (const index of this.possibleScores) {
            this.scoreColors[index] = this._getColorFromScore(index, score);
        }
    }

    private _getColorFromScore(index: number, actualScore: number): ScoreColor {
        if (index <= actualScore) {
            if (actualScore < 2) {
                return ScoreColor.RED;
            } else if (actualScore < 4) {
                return ScoreColor.ORANGE;
            } else if (actualScore < 6) {
                return ScoreColor.GREEN;
            }
        }
        return ScoreColor.WHITE;
    }
}
