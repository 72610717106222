import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, forkJoin, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { ClientVariable, isNotNil } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CampaignReportModalComponent } from ':modules/campaigns/campaign-report-modal/campaign-report-modal.component';
import { CampaignsContext } from ':modules/campaigns/campaigns.context';
import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { CreateCampaignComponent } from ':modules/campaigns/create-campaign/create-campaign.component';
import { CampaignStatus } from ':modules/campaigns/store/campaigns.interface';
import { CampaignsSelectors } from ':modules/campaigns/store/campaigns.selector';
import { ClientsService } from ':modules/clients/clients.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { FilterOption } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { NoResultsComponent } from ':shared/components/no-results/no-results.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { ApiResult, Restaurant } from ':shared/models';
import { Campaign, CampaignType } from ':shared/models/campaign';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-campaigns-list',
    templateUrl: './campaigns-list.component.html',
    styleUrls: ['./campaigns-list.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        TranslateModule,
        NoopMatCheckboxComponent,
        NoResultsComponent,
        PlatformLogoComponent,
        SearchComponent,
        SkeletonComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ApplyPurePipe,
        FormatDatePipe,
        IllustrationPathResolverPipe,
        ShortTextPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class CampaignsListComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly killSubscriptions$: Subject<void> = new Subject();

    currentUser$ = this._store.select(selectUserInfos).pipe(filter(isNotNil));
    user: User;
    dataSource = new MatTableDataSource<Campaign>();
    selection = new SelectionModel<Campaign>(true, []);
    displayedColumns: string[] = [
        'select',
        'name',
        'createdAt',
        'platform',
        'type',
        'mailsNumber',
        'responseRate',
        'unsubscribedRate',
        'edit',
    ];
    isLoading = true;
    restaurantSelected$: Observable<ApiResult<Restaurant>>;
    restaurant: Restaurant;

    hasFetchedCampaigns = false;
    campaignsType = CampaignType;
    hasCampaignInProgress = false;

    sortOptions: FilterOption[] = [
        { key: 'name', label: this.translate.instant('campaigns.name') },
        { key: 'createdAt', label: this.translate.instant('campaigns.createdAt') },
        { key: 'platform', label: this.translate.instant('campaigns.platform_column') },
        { key: 'type', label: this.translate.instant('campaigns.type') },
        { key: 'mailsNumber', label: this.translate.instant('campaigns.mails_number') },
        { key: 'responseRate', label: this.translate.instant('campaigns.response_rate') },
        { key: 'unsubscribedRate', label: this.translate.instant('campaigns.unsubscribed_rate') },
    ];

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _campaignsService: CampaignsService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _customDialogService: CustomDialogService,
        public readonly translate: TranslateService,
        private readonly _clientsService: ClientsService,
        private readonly _store: Store,
        private readonly _malouDialogService: DialogService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _campaignsContext: CampaignsContext
    ) {}

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    case 'platform':
                        return item.platformKey;
                    case 'mailsNumber':
                        return item.contactInteractions?.length;
                    case 'responseRate':
                        return this.getResponseRate(item);
                    case 'unsubscribedRate':
                        return this.getUnsubscribedRate(item);
                    default:
                        return item[property];
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        this.loadCampaigns();
        this.currentUser$.subscribe((user) => (this.user = user));
        this._store
            .select(CampaignsSelectors.selectCurrentCampaignStatus)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((status) => {
                this.hasCampaignInProgress = status === CampaignStatus.IN_PROGRESS;
            });
    }

    loadCampaigns(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter((restaurant) => !restaurant?.isBrandBusiness()),
                switchMap((rest: Restaurant) => {
                    this.hasFetchedCampaigns = false;
                    this.restaurant = rest;
                    return forkJoin([
                        this._campaignsService.getCampaignsByRestaurantId(rest._id),
                        this._clientsService.getClientsByRestaurantId(rest._id),
                    ]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([campaigns, clients]) => {
                    this.hasFetchedCampaigns = true;
                    if (!clients.data?.length) {
                        return this._openNoClientsModal();
                    }
                    if (campaigns?.data) {
                        const sortedData = campaigns.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                        this.dataSource.data = sortedData;
                        this._campaignsContext.campaignsCount.set(this.dataSource.filteredData.length);
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.hasFetchedCampaigns = false;
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    applyFilter(textFilter: string): void {
        this.dataSource.filter = textFilter?.trim()?.toLowerCase() || '';
        this._campaignsContext.campaignsCount.set(this.dataSource.filteredData.length);
    }

    isAllSelected = (): boolean => {
        if (this.selection.hasValue()) {
            const numSelected = this.selection.selected.length;
            const numRows = this.dataSource.data.length;
            return numSelected === numRows;
        }
        return false;
    };

    isAllSelectedIndeterminate = (): boolean => this.selection.hasValue() && !this.isAllSelected();

    toggleAllFiltered(): void {
        if (this._isAllFilteredSelected()) {
            this.selection.deselect(...this.dataSource.filteredData);
            return;
        }
        this.selection.select(...this.dataSource.filteredData);
    }

    deleteSelectedCampaigns(): void {
        const campaignsIds = this.selection.selected.map((t) => t._id);
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this.translate.instant('common.are_you_sure'),
            message:
                campaignsIds.length > 1
                    ? this.translate.instant('campaigns.campaigns_will_be_deleted')
                    : this.translate.instant('campaigns.campaigns_will_be_deleted_single'),
            secondaryButton: {
                label: this.translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this.translate.instant('common.delete'),
                action: () => {
                    this._deleteManyCampaigns(campaignsIds);
                },
            },
        });
    }

    deleteOneCampaign(campaignId: string): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this.translate.instant('common.are_you_sure'),
            message: this.translate.instant('campaigns.campaigns_will_be_deleted_single'),
            secondaryButton: {
                label: this.translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this.translate.instant('common.delete'),
                action: () => {
                    this._deleteOneCampaign(campaignId);
                },
            },
        });
    }

    getCampaignType = (campaign: Campaign): string =>
        this.translate.instant(campaign.type === CampaignType.REVIEW_BOOSTER ? 'campaigns.review_booster' : 'campaigns.promotion');

    openCreateCampaignModal(): void {
        this._customDialogService
            .open(CreateCampaignComponent, {
                height: 'auto',
                width: 'auto',
                data: {
                    restaurant: this.restaurant,
                    user: this.user,
                    upsertCampaign: this._upsertOneInDataSource.bind(this),
                    removeCampaign: this._removeOneFromDataSource.bind(this),
                },
            })
            .afterClosed();
    }

    getResponseRate(campaign: Campaign): number {
        const responsesCount = campaign.contactInteractions.filter((client) => !!client.lastStarRating)?.length;
        const deliveredCount = campaign.contactInteractions.filter((client) => !!client.deliveredDate)?.length;
        if (!deliveredCount) {
            return 0;
        }
        const rate = (responsesCount / deliveredCount) * 100;
        return Math.round(rate);
    }

    getUnsubscribedRate(campaign: Campaign): number {
        const unsubscribedNumber = campaign.contactInteractions.filter((c) => !!c.unsubscribedDate).length;
        if (!campaign.contactInteractions?.length) {
            return 0;
        }
        const rate = (unsubscribedNumber / campaign.contactInteractions.length) * 100;
        return Math.round(rate);
    }

    openReport(campaign: Campaign): void {
        this._customDialogService
            .open(CampaignReportModalComponent, {
                width: '730px',
                disableClose: false,
                data: {
                    campaign,
                    user: this.user,
                },
            })
            .afterClosed();
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const start = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active || 'createdAt', start, disableClear: true });
    }

    private _isAllFilteredSelected(): boolean {
        return this.dataSource.filteredData.every((campaign) => this.selection.isSelected(campaign));
    }

    private _transformHtmlToText(textHTML: string): string {
        return textHTML
            .replace(`<a style="text-decoration: none; color: #323C47;" href="${this.restaurant.website}"><strong>`, '')
            .replace('</strong></a>', '')
            .replace('<span><strong>', '')
            .replace('<br>', '\n')
            .replace(ClientVariable.FIRSTNAME, this.user?.name)
            .replace(ClientVariable.LASTNAME, this.user?.lastname);
    }

    private _openNoClientsModal(): void {
        this._malouDialogService.open({
            variant: DialogVariant.ALERT,
            title: this.translate.instant('campaigns.no_client_modal_title'),
            message: this.translate.instant('campaigns.no_client_modal_message'),
            secondaryButton: {
                label: this.translate.instant('common.add_later'),
            },
            primaryButton: {
                label: this.translate.instant('common.add_now'),
                action: () => {
                    this._router.navigate([':modules/campaigns/:modules/campaigns/:modules/campaigns/resources/clients/list'], {
                        relativeTo: this._activatedRoute,
                    });
                },
            },
        });
    }

    private _deleteManyInDataSource(campaignsIds: string[]): void {
        this.dataSource.data = this.dataSource.data.filter((c) => !campaignsIds.includes(c._id));
    }

    private _deleteByIdInDataSource(campaignId: string): void {
        const idx = this.dataSource.data.findIndex((c) => c._id === campaignId);
        if (idx > -1) {
            this.dataSource.data.splice(idx, 1);
            this.dataSource._updateChangeSubscription();
        }
    }

    private _deleteManyCampaigns(campaignsIds: string[]): void {
        this._campaignsService.deleteManyCampaigns(campaignsIds).subscribe({
            next: () => {
                this._deleteManyInDataSource(campaignsIds);
                this.selection.clear();
                this._toastService.openSuccessToast(
                    campaignsIds.length > 1
                        ? this.translate.instant('campaigns.deleted')
                        : this.translate.instant('campaigns.single_deleted')
                );
            },
            error: (err) => {
                this.selection.clear();
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _deleteOneCampaign(campaignId: string): void {
        this._campaignsService.deleteCampaign(campaignId).subscribe({
            next: () => {
                this._deleteByIdInDataSource(campaignId);
                this._toastService.openSuccessToast(this.translate.instant('campaigns.single_deleted'));
            },
            error: (err) => {
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _upsertOneInDataSource(campaign: Campaign): void {
        const idx = this.dataSource.data.findIndex((c) => c._id === campaign._id);
        if (idx > -1) {
            this.dataSource.data[idx] = campaign;
        } else {
            this.dataSource.data.unshift(campaign);
        }
        this.dataSource._updateChangeSubscription();
    }

    private _removeOneFromDataSource(campaignId: string): void {
        const idx = this.dataSource.data.findIndex((c) => c._id === campaignId);
        if (idx === -1) {
            return;
        }
        this.dataSource.data.splice(idx, 1);
        this.dataSource._updateChangeSubscription();
    }
}
