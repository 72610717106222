import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { ApplyPurePipe } from '../pipes/apply-fn.pipe';
import { PlatformLogoPathResolverPipe } from '../pipes/platform-logo-path-resolver.pipe';

export enum DefaultPrompt {
    INTRODUCE_PLACE,
    INTRODUCE_DISH,
    ANNOUNCE_EVENT,
    TRANSLATE,
    OPTIMIZE,
    GENERATE_POST,
    WRITE_CONTEST,
    GIVE_ADVICE,
}

export enum AiOperation {
    COMPLETION,
    TRANSLATION,
}

@Component({
    selector: 'app-openai-prompt',
    templateUrl: './openai-prompt.component.html',
    styleUrls: ['./openai-prompt.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TextFieldModule,
        MatIconModule,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        MatMenuModule,
        TranslateModule,
        PlatformLogoPathResolverPipe,
        ApplyPurePipe,
    ],
})
export class OpenaiPromptComponent implements OnInit {
    @Input() placeholder: string;
    @Input() defaultPrompt: DefaultPrompt;
    @Input() defaultPromptList: DefaultPrompt[];
    @Input() lastPrompt: string;
    @Input() validateButtonId?: string | undefined;
    @Output() promptChange = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;

    promptForm: FormGroup = this._fb.group({
        prompt: new FormControl<string>(''),
    });
    shouldDisplayDefaultPromptList = false;
    promptLength = 0;
    defaultPromptText: string;
    positionPairs: ConnectionPositionPair[] = [
        { offsetX: 20, offsetY: 80, originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' },
        { offsetX: 20, offsetY: -50, originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
    ];

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.lastPrompt?.length) {
            this.promptForm.get('prompt')?.setValue(this.lastPrompt);
            this.promptLength = this.lastPrompt.length;
        }
        this.defaultPromptText = this._getFullDefaultPromptText(this.defaultPrompt);
    }

    setFirstDefaultPrompt(): void {
        const prompt = this.promptForm.get('prompt')?.value;
        if (!prompt?.length) {
            this.promptForm.get('prompt')?.setValue(this.defaultPromptText);
            this.promptLength = this.defaultPromptText.length;
        }
    }

    onValueChange(prompt: string): void {
        this.promptLength = prompt.length;
        if (!prompt.startsWith(this.defaultPromptText)) {
            this.promptForm.get('prompt')?.setValue(this.defaultPromptText);
        }
    }

    choosePromptOption(promptOption: DefaultPrompt): void {
        this.shouldDisplayDefaultPromptList = false;
        const prompt = this._getFullDefaultPromptText(promptOption);
        this.promptLength = prompt.length;
        this.promptForm.get('prompt')?.setValue(prompt);
        document.getElementById('openaiPromptTextarea')?.focus();
    }

    emitPrompt(): void {
        const prompt = this.promptForm.get('prompt')?.value;
        if (!prompt.length || prompt.trim() === this.defaultPromptText.trim()) {
            return;
        }
        this.promptChange.emit(prompt);
    }

    getDefaultPromptOptionText = (promptOption: DefaultPrompt): string =>
        ({
            [DefaultPrompt.INTRODUCE_PLACE]: this._translate.instant('openai.introduce_place'),
            [DefaultPrompt.INTRODUCE_DISH]: this._translate.instant('openai.introduce_dish'),
            [DefaultPrompt.ANNOUNCE_EVENT]: this._translate.instant('openai.announce_event'),
            [DefaultPrompt.GENERATE_POST]: this._translate.instant('openai.generate_post_key'),
            [DefaultPrompt.WRITE_CONTEST]: this._translate.instant('openai.write_contest_key'),
            [DefaultPrompt.GIVE_ADVICE]: this._translate.instant('openai.give_advice_key'),
            [DefaultPrompt.TRANSLATE]: this._translate.instant('openai.translate'),
            [DefaultPrompt.OPTIMIZE]: this._translate.instant('openai.optimize'),
        })[promptOption];

    private _getFullDefaultPromptText(promptOption: DefaultPrompt): string {
        return {
            [DefaultPrompt.INTRODUCE_PLACE]: this._translate.instant('openai.introduce_place_post'),
            [DefaultPrompt.INTRODUCE_DISH]: this._translate.instant('openai.introduce_dish_post'),
            [DefaultPrompt.ANNOUNCE_EVENT]: this._translate.instant('openai.announce_event_post'),
            [DefaultPrompt.GENERATE_POST]: this._translate.instant('openai.generate_post'),
            [DefaultPrompt.WRITE_CONTEST]: this._translate.instant('openai.write_contest'),
            [DefaultPrompt.GIVE_ADVICE]: this._translate.instant('openai.give_advice'),
            [DefaultPrompt.TRANSLATE]: this._translate.instant('openai.translate'),
            [DefaultPrompt.OPTIMIZE]: this._translate.instant('openai.optimize'),
        }[promptOption];
    }
}
