<div class="flex flex-col">
    <label class="malou-text-10--regular" id="example-radio-group-label">{{
        'validate_credentials.choose_organization_title' | translate
    }}</label>
    <mat-radio-group
        class="align-items: !ml-4 mt-5 flex h-full max-h-[54vh] flex-col items-start overflow-y-auto"
        aria-labelledby="example-radio-group-label"
        [(ngModel)]="selectedOrganization"
        (change)="selectOrganization($event)">
        @for (organization of organizationsWithRestaurantsCount(); track trackByIdFn($index, organization)) {
            <div
                [matTooltip]="'validate_credentials.organization_limit_reached' | translate"
                [matTooltipDisabled]="organization.restaurantsCount < organization.limit">
                <mat-radio-button
                    class="malou-text-12--bold mb-5 text-malou-color-text-1"
                    [value]="organization"
                    [class.opacity-50]="organization.restaurantsCount >= organization.limit"
                    [disabled]="organization.restaurantsCount >= organization.limit">
                    {{ organization.name }}
                </mat-radio-button>
            </div>
        }
    </mat-radio-group>
    @if (organizations().length === 0) {
        <p class="mb-2.5">
            <b>
                {{ 'validate_credentials.no_organization_found' | translate }}
            </b>
        </p>
    }
</div>
