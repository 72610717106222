import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { hasEmoji } from ':shared/helpers/emoji';

@Pipe({
    name: 'replyTextToError',
    standalone: true,
})
export class ReplyTextToErrorPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(replyText: string | null): string {
        if (!replyText) {
            return '';
        }
        const errorKey = this._getErrorKey(replyText);
        if (!errorKey) {
            return '';
        }
        return this._translate.instant(`reviews.errors.${errorKey}`);
    }

    private _getErrorKey(replyText: string): string | undefined {
        if (replyText.includes('(À CHANGER)')) {
            return 'change_error';
        }
        if (new RegExp(/\<\<|\>\>/).test(replyText)) {
            return 'chevron_error';
        }
        if (hasEmoji(replyText)) {
            return 'emoji_error';
        }
    }
}
