@for (attribute of attributes; track attribute) {
    <p class="flex items-center">
        @if (attribute.value === 'yes') {
            <mat-icon class="malou-color-success mr-3 !w-4" [svgIcon]="SvgIcon.CHECK"></mat-icon>
        }
        @if (attribute.value === 'no') {
            <mat-icon class="malou-color-fail mr-3 !w-4" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        }
        @if (attribute.value === 'notConcerned') {
            <mat-icon class="malou-color-leslie-light mr-3 !w-4 align-text-top"> block </mat-icon>
        }
        <span
            [ngStyle]="{
                'text-decoration': attribute.attributeValue === RestaurantAttributeValue.NOT_CONCERNED ? 'line-through' : 'none',
            }">
            {{ getAttributeText | applyPure: attribute }}
        </span>
    </p>
}
@if (!attributes?.length) {
    <p>-</p>
}
