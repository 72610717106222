import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, of, take } from 'rxjs';

import { isNotNil, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostService {
    private readonly _store = inject(Store);

    getConnectedSocialPlatforms(): Observable<Platform[]> {
        return this._store.select(selectCurrentPlatforms).pipe(
            filter(isNotNil),
            map((platforms) =>
                platforms.filter(
                    (platform) =>
                        PlatformDefinitions.getSocialPlatformKeys().includes(platform.key) &&
                        (platform.key !== PlatformKey.MAPSTR || !!platform.credentials?.length)
                )
            ),
            take(1)
        );
    }

    getPost(_postId: string): Observable<UpsertSocialPost | null> {
        // TODO implement
        return of(null);
    }

    updatePost(post: IUpsertSocialPost): Observable<UpsertSocialPost> {
        // TODO implement
        return of(new UpsertSocialPost(post));
    }

    deletePost(_postId: string): Observable<null> {
        // TODO implement
        return of(null);
    }
}
