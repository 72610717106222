import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { SocialPostItemDto } from '@malou-io/package-dto';
import {
    FileFormat,
    HashtagType,
    MediaCategory,
    MediaType,
    PictureSize,
    PlatformKey,
    PostPublicationStatus,
    PostType,
    SocialPostsListFilter,
} from '@malou-io/package-utils';

import { Pagination } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SocialPostsV2Service {
    private readonly _http = inject(HttpClient);

    getSocialPostsCountByFilterOptions$(): Observable<{ filterOption: SocialPostsListFilter; count: number | null }[]> {
        return of([
            { filterOption: SocialPostsListFilter.ALL, count: null },
            { filterOption: SocialPostsListFilter.DRAFT, count: 5 },
            { filterOption: SocialPostsListFilter.FEEDBACK, count: 0 },
            { filterOption: SocialPostsListFilter.ERROR, count: 1 },
        ]);
    }

    getSocialPosts$(filter: SocialPostsListFilter, pagination: Pagination, _restaurantId: string): Observable<SocialPostItemDto[]> {
        return of(
            [
                {
                    id: '1',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '2',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK],
                    published: PostPublicationStatus.PUBLISHED,
                    postType: PostType.IMAGE,
                    socialLink: 'https://www.facebook.com/112820618244491/posts/419431404250076',
                    socialCreatedAt: DateTime.now().minus({ days: 1 }).toJSDate(),
                    feedbackMessagesCount: 0,
                    plannedPublicationDate: DateTime.now().minus({ days: 2 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/447559986_419431334250083_915566955833491930_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=127cfc&_nc_ohc=KDT3939iq4gQ7kNvgGGFjH8&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&_nc_gid=AdFoi0xrQt7yofjW3lyMM4i&oh=00_AYBGU1GgfSgvmRPruLGZ7da6L0wpt9EEsKcgKokZm9Xk7g&oe=6740DB9B',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [
                            { id: '1', text: '#published', isMain: true, type: HashtagType.DESCRIPTION },
                            { id: '2', text: '#wow', isMain: true, type: HashtagType.DESCRIPTION },
                        ],
                        suggested: [],
                    },
                },
                {
                    id: '3',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '4',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '5',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '6',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '7',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '8',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '9',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '10',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '11',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://malou-dev.s3.eu-west-3.amazonaws.com/restaurants/651570c96d6bef28631ffbfd/media/d98c2763-f749-437d-90fd-794224420355/small.jpg',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '12',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '13',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '14',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
                {
                    id: '15',
                    text: 'Nam quis enim. Quisque ornare dui a tortor. Fusce consequat lacus pellentesque metus. Duis euismod. Duis non quam. Maecenas vitae dolor in ipsum auctor vehicula. Vivamus nec nibh eget wisi varius pulvinar. Cras a lacus. Etiam et massa. Donec in nisl sit amet dui imperdiet vestibulum. Duis porttitor nibh id eros. ',
                    platformKeys: [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM, PlatformKey.MAPSTR],
                    published: PostPublicationStatus.DRAFT,
                    postType: PostType.IMAGE,
                    feedbackMessagesCount: 2,
                    plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    thumbnail: {
                        id: '1',
                        category: MediaCategory.ADDITIONAL,
                        format: FileFormat.PNG,
                        type: MediaType.PHOTO,
                        urls: {
                            [PictureSize.ORIGINAL]:
                                'https://scontent-cdg4-1.xx.fbcdn.net/v/t39.30808-6/465727385_1005693354905450_238238058326300943_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=127cfc&_nc_ohc=QF1m49FK3qYQ7kNvgH6Q8jr&_nc_zt=23&_nc_ht=scontent-cdg4-1.xx&edm=AJfPMC4EAAAA&_nc_gid=ARyS_gjBz12xmqzoYb_yn36&oh=00_AYDysJy1H244geFD-mbHOsGDwBN4_2Rk_q8-5pwSTTzEEA&oe=673C1008',
                        },
                        sizes: { [PictureSize.ORIGINAL]: 150 },
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    hashtags: {
                        selected: [{ id: '1', text: '#hashtag1', isMain: true, type: HashtagType.DESCRIPTION }],
                        suggested: [],
                    },
                },
            ]
                .filter(
                    (post) =>
                        filter === SocialPostsListFilter.ALL ||
                        (filter === SocialPostsListFilter.DRAFT && post.published === PostPublicationStatus.DRAFT) ||
                        (filter === SocialPostsListFilter.ERROR && post.published === PostPublicationStatus.ERROR) ||
                        (filter === SocialPostsListFilter.FEEDBACK && post.feedbackMessagesCount > 0)
                )
                .slice(pagination.pageNumber * pagination.pageSize, (pagination.pageNumber + 1) * pagination.pageSize)
        ).pipe(delay(2000));
    }
}
