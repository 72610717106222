import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';
import { brickValidator } from './shared.dto';

export const updateRestaurantBricksParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type UpdateRestaurantBricksParamsDto = z.infer<typeof updateRestaurantBricksParamsValidator>;

export const updateRestaurantBricksBodyValidator = z.object({
    formData: z.object({
        categoryList: z.array(brickValidator),
        touristics: z.array(brickValidator),
        specials: z.array(brickValidator),
        offers: z.array(brickValidator),
        equipment: z.array(brickValidator),
        attributes: z.array(brickValidator),
        audience: z.array(brickValidator),
        postalCode: z.string().optional(),
        country: z.string().optional(),
        apiLocationId: z.string().optional(),
    }),
});
export type UpdateRestaurantBricksBodyDto = z.infer<typeof updateRestaurantBricksBodyValidator>;
