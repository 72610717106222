<app-button
    [id]="id()"
    [text]="text()"
    [tooltip]="tooltip()"
    [disabled]="disabled()"
    [loading]="loading()"
    [buttonClasses]="buttonClasses()"
    [testId]="testId()"
    [matMenuTriggerFor]="matMenu"
    [ngClass]="{ 'pointer-events-none': disabled() || loading() }"></app-button>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #matMenu="matMenu">
    <ng-content></ng-content>
</mat-menu>
