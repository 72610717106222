import { createAction, props } from '@ngrx/store';

import { BindingIdKey } from ':core/constants';
import { Post, PostsFilters } from ':shared/models';

export const editPostsFilters = createAction('[Posts] Edit_Posts_Filters', props<{ filters: PostsFilters }>());

export const editPostsFiltersPlatforms = createAction('[Posts] Edit_Posts_Filters_Platforms', props<{ platforms: string[] }>());

export const setPostsFiltersPlatforms = createAction('[Posts] Set_Posts_Filters_Platforms', props<{ platforms: string[] }>());

export const changeSortOrder = createAction('[Posts] Change_Sort_Order');

export const resetPostsFiltersDates = createAction('[Posts] Reset_Posts_Filters_Dates');

export const resetPostsFiltersStatus = createAction('[Posts] Reset_Posts_Filters_Status');

export const updatePostsBindingId = createAction('[Posts] Update_Binding_Id', props<{ bindingId: string; bindingIdKey: BindingIdKey }>());

export const resetPostsBindingId = createAction('[Posts] Reset_Binding_Id');

export const setCurrentlyPublishingPosts = createAction('[Posts] Set_Currently_Publishing_Posts', props<{ posts: Post[] }>());

export const updateRefreshDates = createAction('[Posts] Update_Refresh_Date', props<{ postId: string }>());
