<div class="flex flex-col gap-y-4 py-4">
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }

    <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if (((restaurants$ | async)?.length ?? 0) > 1) {
            <div class="mb-4 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded$ | async">
                <div class="h-[520px] flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded$ | async">
                    <app-keywords-v3
                        (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_RANKINGS, $event)"
                        (isLoadingEvent)="isKeywordsLoading.set($event)">
                    </app-keywords-v3>
                </div>
                <div class="h-full min-w-0 flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded$ | async">
                    <app-gmb-impressions
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
                        (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
                        (isLoadingEvent)="isImpressionsLoading.set($event)">
                    </app-gmb-impressions>
                </div>
            </div>
            <div class="h-full">
                <app-gmb-actions
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
                    (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
                    (isLoadingEvent)="isActionsLoading.set($event)">
                </app-gmb-actions>
            </div>
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.select_at_least_2_businesses' | translate }}</span>
            </div>
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 sm:flex-col sm:items-center md:px-0">
        <div class="flex-1">
            <app-statistics-filters [showPlatformsFilter]="false" [page]="PlatformFilterPage.SEO"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="((restaurants$ | async)?.length ?? 0) < 2 || isLoading()"
            (click)="openDownloadStatisticsModal()">
            {{ 'aggregated_statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
