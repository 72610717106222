import { z } from 'zod';

import { KeywordVolumeProvider, Locale } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const keywordDtoValidator = z.object({
    keywordId: objectIdValidator,
    apiLocationId: z.string(),
    text: z.string(),
    bricks: z.array(z.object({ category: z.string(), text: z.string() })),
    language: z.nativeEnum(Locale),
    isCustomerInput: z.boolean(),
    volume: z.number(),
    volumeHistory: z.array(
        z.object({
            volume: z.number(),
            fetchDate: z.string(),
            source: z.nativeEnum(KeywordVolumeProvider).optional(),
        })
    ),
    volumeFromAdmin: z.number().optional(),
});

export const duplicateKeywordsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type DuplicateKeywordsParamsDto = z.infer<typeof duplicateKeywordsParamsValidator>;

export const duplicateKeywordsBodyValidator = z.object({
    originalKeywordIds: z.array(objectIdValidator),
    restaurantIds: z.array(objectIdValidator),
});
export type DuplicateKeywordsBodyDto = z.infer<typeof duplicateKeywordsBodyValidator>;
