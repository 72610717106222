import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { CommentReviewsFilters, Template } from ':shared/models';
import { CommentOption } from ':shared/models/comment-option';

export interface TemplateGroup {
    category: string;
    templates: Template[];
}

export interface PendingReviewReply {
    reviewId: string;
    replyText: string;
    templateName?: string;
    saveTemplateName?: string;
    withComment?: CommentOption;
    rating?: number[];
    lang?: string;
}

export type SaveTemplateForm = Omit<PendingReviewReply, 'reviewId'> & { activated: boolean };

export interface ReviewFiltersForm {
    ratingFilter: number[];
    withCommentFilter: CommentReviewsFilters[];
}

export interface ReviewPrivateForm {
    from: string;
    mail: string;
    object: string;
}

export interface ReplyForm {
    saveTemplate: INullableFormGroup<SaveTemplateForm>;
}

export interface TemplateFilters {
    withCommentFilter: CommentReviewsFilters[];
    ratingFilter: number[];
}

export const DEFAULT_REVIEW_FILTERS: ReviewFiltersForm = {
    ratingFilter: [],
    withCommentFilter: [],
};

export const DEFAULT_REVIEW_PRIVATE_FORM: ReviewPrivateForm = {
    from: '',
    mail: '',
    object: '',
};

export const INITIAL_REVIEW_DISPLAY_PAGE_NUMBER = 3;
export const DEFAULT_REVIEW_PAGE_SIZE = 20;
export const INITIAL_REVIEW_DISPLAY_SIZE = INITIAL_REVIEW_DISPLAY_PAGE_NUMBER * DEFAULT_REVIEW_PAGE_SIZE;
export const DEFAULT_STORE_PAGINATION = { pageSize: DEFAULT_REVIEW_PAGE_SIZE, pageNumber: 0, total: 0 };
export const DEFAULT_PAGINATION = { lower: 0, upper: 0 };

export const DEFAULT_AVAILABLE_RATINGS = [1, 2, 3, 4, 5, 0];
export const DEFAULT_AVAILABLE_COMMENTS: CommentReviewsFilters[] = [CommentReviewsFilters.WITH_TEXT, CommentReviewsFilters.WITHOUT_TEXT];
