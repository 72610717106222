import { DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, OnInit, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey, PostType } from '@malou-io/package-utils';

import { IStoryVideoCheck } from ':core/constants';
import { MediaDurationComponent } from ':modules/stories/media-duration/media-duration.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { StoryVideoErrorComponent } from ':shared/components/story-video-error/story-video-error.component';
import { getStoryVideoValidity } from ':shared/helpers/story-video-checker';
import { Post } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

const defaultVideoValidity = {
    duration: 0,
    maxDuration: 0,
    minDuration: 0,
    isDurationValid: true,
    width: 0,
    maxWidth: 0,
    isWidthValid: true,
    height: 0,
    ratio: 0,
    maxRatio: 0,
    minRatio: 0,
    isRatioValid: true,
    extension: '',
    isHeightValid: true,
    isExtensionValid: true,
    error: false,
};

const DEFAULT_PHOTO_DURATION = 5;
@Component({
    selector: 'app-post-summary',
    standalone: true,
    imports: [
        JsonPipe,
        MatIconModule,
        DatePipe,
        SocialPostMediaComponent,
        PluralTranslatePipe,
        EnumTranslatePipe,
        MatButtonModule,
        MediaDurationComponent,
        TranslateModule,
        StoryVideoErrorComponent,
    ],
    templateUrl: './post-summary.component.html',
    styleUrls: ['./post-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSummaryComponent implements OnInit {
    readonly selectedKeys = input.required<PlatformKey[]>();
    readonly post = input.required<Post>();
    readonly warningText = input('');
    readonly deletedPost = output<string>();

    readonly SvgIcon = SvgIcon;
    readonly mediaDuration = signal<number>(0);
    readonly videoValidity = signal<IStoryVideoCheck>(defaultVideoValidity);
    readonly videoError = signal<boolean>(false);

    lastLoadedVideo: HTMLVideoElement | null = null;

    constructor() {
        effect(
            () => {
                const keys = this.selectedKeys();
                this._updateVideoValidity(this.lastLoadedVideo, keys);
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        if (this.post()?.postType === PostType.IMAGE) {
            this.mediaDuration.set(DEFAULT_PHOTO_DURATION);
        }
    }

    deletePost(): void {
        this.deletedPost.emit(this.post()._id);
    }

    onLoadedVideoMetadata(event: Event, shouldSaveVideo = true): void {
        const videoElt = event.target as HTMLVideoElement;
        if (shouldSaveVideo) {
            this.lastLoadedVideo = videoElt;
        }
        this._updateVideoValidity(videoElt, this.selectedKeys());
    }

    private _updateVideoValidity(videoElt: HTMLVideoElement | null, keys: PlatformKey[]): void {
        if (!videoElt) {
            return;
        }
        const videoValidity = getStoryVideoValidity(videoElt, keys);
        this.videoValidity.set(videoValidity);
        this.videoError.set(videoValidity.error);
        this.mediaDuration.set(videoValidity.duration);
    }
}
