import { Action, createReducer, on } from '@ngrx/store';

import { PlatformDefinitions, PostPublicationStatus } from '@malou-io/package-utils';

import { MalouDateFilters, MalouPeriod, PostsFilters } from ':shared/models';

import * as SocialPostsActions from '../social-posts/social-posts.actions';

export interface State {
    socialFilters: PostsFilters;
    postsSync: {
        loading: boolean;
        completed: boolean;
    };
}

const dateFilters = new MalouDateFilters();

export const initialState: State = {
    socialFilters: {
        platforms: PlatformDefinitions.getSocialPlatformKeys(),
        ...dateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_SIX_MONTH }),
        sortBy: 'date',
        sortOrder: -1,
        publicationStatus: [PostPublicationStatus.PUBLISHED, PostPublicationStatus.PENDING, PostPublicationStatus.DRAFT],
    },
    postsSync: {
        loading: false,
        completed: true,
    },
};

const socialPostsReducer = createReducer(
    initialState,
    on(
        SocialPostsActions.editSocialPostsFilters,
        (state, { filters }) => ({
            ...state,
            socialFilters: {
                ...state.socialFilters,
                ...filters,
            },
        }) // better way ? https://github.com/paularmstrong/normalizr https://github.com/reduxjs/redux/issues/994
    ),
    on(SocialPostsActions.editSocialPostsFiltersPlatforms, (state, { platforms }) => ({
        ...state,
        socialFilters: {
            ...state.socialFilters,
            platforms,
        },
    })),
    on(SocialPostsActions.setSocialPostsFiltersPlatforms, (state, { platforms }) => ({
        ...state,
        socialFilters: {
            ...state.socialFilters,
            platforms,
        },
    })),
    on(SocialPostsActions.changeSocialSortOrder, (state) => ({
        ...state,
        socialFilters: {
            ...state.socialFilters,
            sortOrder: (state.socialFilters.sortOrder || -1) * -1,
        },
    })),
    on(SocialPostsActions.editHasSyncState, (state, { postsSync }) => ({
        ...state,
        postsSync: {
            ...state.postsSync,
            ...postsSync,
        },
    })),
    on(SocialPostsActions.resetSocialPostsFilters, () => ({
        ...initialState,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return socialPostsReducer(state, action);
}
