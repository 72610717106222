import saveAs from 'file-saver';
import JSZip from 'jszip';

export async function downloadFilesAsZip(blobs: Blob[], filenames: string[], zipFilename: string): Promise<void> {
    const zip = new JSZip();
    blobs.forEach((blob, index) => {
        zip.file(filenames[index], blob);
    });
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, zipFilename);
}
