<form [formGroup]="privateForm">
    <div class="flex items-center gap-x-2">
        <app-input-text
            class="w-1/2"
            formControlName="from"
            [required]="true"
            [title]="'reviews.reply_modal.private.from' | translate"
            [placeholder]="'reviews.reply_modal.private.from' | translate">
        </app-input-text>
        @if (availableEmails().length) {
            <app-select
                class="w-1/2"
                formControlName="mail"
                [title]="'&nbsp;'"
                [placeholder]="'common.email' | translate"
                [values]="availableEmails()">
            </app-select>
        } @else {
            <p class="p-2 italic text-malou-color-state-error">{{ 'reviews.reply_modal.private.no_available_emails' | translate }}</p>
        }
    </div>

    <app-input-text
        formControlName="object"
        [required]="true"
        [title]="'reviews.reply_modal.private.object' | translate"
        [placeholder]="'reviews.reply_modal.private.object' | translate">
    </app-input-text>
</form>
