import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-move-media-modal',
    templateUrl: './move-media-modal.component.html',
    styleUrls: ['./move-media-modal.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatIconModule, MatButtonModule, TranslateModule, SelectComponent, SelectBaseComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveMediaModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_FOLDER: IFolder = {
        id: '',
        name: this._translateService.instant('gallery.my_gallery'),
        parentFolderId: null,
        mediaCount: 0,
    };
    readonly folders: IFolder[];
    readonly selectedFolderControl: FormControl<IFolder> = new FormControl<IFolder>(this.DEFAULT_FOLDER) as FormControl<IFolder>;

    constructor(
        private readonly _dialogRef: MatDialogRef<MoveMediaModalComponent>,
        private readonly _translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: {
            folders: IFolder[];
            initSelectedFolder: IFolder | null;
        }
    ) {
        this.folders = [this.DEFAULT_FOLDER, ...this._data.folders];
        this.selectedFolderControl.setValue(this._data.initSelectedFolder ?? this.DEFAULT_FOLDER);
    }

    close(): void {
        this._dialogRef.close();
    }

    move(): void {
        const selectedFolder = this.selectedFolderControl.value;
        this._dialogRef.close(selectedFolder.id || null);
    }

    displayFolderWith(folder: IFolder): string {
        return folder.name;
    }
}
