import { Component, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute, Router } from '@angular/router';

import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: true,
    imports: [MatDividerModule, ImagePathResolverPipe],
})
export class MaintenanceComponent implements OnInit {
    until: Date;
    constructor(
        public _route: ActivatedRoute,
        public _router: Router
    ) {
        this.until = this._route.snapshot.queryParams.until;
    }

    ngOnInit(): void {
        this._router.navigate(['restaurants/list']);
    }
}
