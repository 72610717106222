import { Pipe, PipeTransform } from '@angular/core';

import { ConversationType, ConversationWithMessages } from ':shared/models/conversation';

/*
 * Filter conversations by conversation type
 */
@Pipe({
    name: 'filterByConversationType',
    standalone: true,
})
export class FilterByConversationTypePipe implements PipeTransform {
    transform(conversations: ConversationWithMessages[], conversationType: ConversationType): ConversationWithMessages[] {
        if (!conversationType) {
            return conversations;
        }
        switch (conversationType) {
            case ConversationType.GENERAL:
                return conversations.filter((c) => !c.conversation.archived);
            case ConversationType.ARCHIVED:
                return conversations.filter((c) => c.conversation.archived);
            case ConversationType.FAVORITE:
                return conversations.filter((c) => c.conversation.favorite);
            default:
                return conversations;
        }
    }
}
