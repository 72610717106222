<div class="flex h-screen flex-col items-center justify-center gap-4 bg-malou-color-backdrop">
    <div class="malou-box-shadow flex flex-col items-center justify-center rounded-xl bg-white p-16">
        <img class="h-40" alt="Cape" [src]="'Karl' | illustrationPathResolver" />
        <div class="mt-6">
            <div class="malou-text-14--bold text-center text-malou-color-text-1">
                {{ 'campaigns.confirm_email.confirmed' | translate }}
            </div>
            <div class="malou-text-10 mt-6 text-center text-malou-color-text-2">
                {{ 'campaigns.email_verification_pages.validated_text_next' | translate }}
            </div>
        </div>
    </div>
</div>
