export enum EmailPadding {
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low',
}

export enum GrowthVariation {
    UP = 'up',
    DOWN = 'down',
    SAME = 'same',
    NONE = 'none',
}
