import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { intersectionWith } from 'lodash';
import { catchError, combineLatest, filter, map, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { InformationUpdatesStateResponseDto } from '@malou-io/package-dto';
import { BusinessCategory, isNotNil, MalouErrorCode, RestaurantAttributeValue } from '@malou-io/package-utils';

import { InformationUpdatesService } from ':core/services/information-update.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { AttributeService } from ':modules/informations/attributes/attributes-service';
import { InformationUpdateState } from ':modules/informations/informations-updates-state/information-update-state.interface';
import { Attribute, RestaurantAttribute } from ':shared/models';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Injectable({
    providedIn: 'root',
})
export class InformationsContext {
    readonly allCategoryAttributes = signal<Attribute[]>([]);
    readonly restaurantAttributes = signal<RestaurantAttribute[]>([]);
    readonly categoryAttributesError = signal<string>('');

    readonly openRestaurantDescriptionsModal$ = new Subject<void>();
    readonly openRestaurantAttributesModal$ = new Subject<void>();
    readonly openRestaurantInformationsModal$ = new Subject<void>();
    readonly openRestaurantHoursModal$ = new Subject<void>();
    readonly restaurantInformationUpdateStatesIsLoading = signal<boolean>(false);
    private readonly _refreshRestaurantInformationUpdateStates$ = new Subject<void>();

    private readonly _attributesService = inject(AttributeService);
    private readonly _informationUpdatesService = inject(InformationUpdatesService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _platformNamePipe = inject(PlatformNamePipe);

    readonly restaurantInformationUpdateStates = toSignal(
        combineLatest([this._restaurantsService.restaurantSelected$, this._refreshRestaurantInformationUpdateStates$]).pipe(
            map(([restaurant]) => restaurant),
            filter(isNotNil),
            tap(() => {
                this.restaurantInformationUpdateStatesIsLoading.set(true);
            }),
            switchMap((restaurant) =>
                combineLatest([
                    this._getDetailedUpdateStatus(restaurant._id),
                    restaurant.type === BusinessCategory.LOCAL_BUSINESS ? this._getCategoryAttributes(restaurant._id) : of([]),
                    this._getRestaurantAttributes(restaurant._id),
                ])
            ),
            map(([detailedUpdateStatusResult, allAttributes, restaurantAttributes]) => {
                this._updateAttributes(allAttributes, restaurantAttributes);
                const others: InformationUpdateState[] = [];
                const errors: InformationUpdateState[] = [];

                for (const state of detailedUpdateStatusResult.states) {
                    const informationUpdateState = new InformationUpdateState({
                        platformKey: state.platformKey,
                        status: state.status,
                        translationService: this._translateService,
                        provider: state.provider,
                        platformNamePipe: this._platformNamePipe,
                        socialLink: state.socialLink,
                        supportedFields: state.supportedFields,
                        errors: state.errors,
                    });

                    if (informationUpdateState.isOk()) {
                        others.push(informationUpdateState);
                    } else {
                        errors.push(informationUpdateState);
                    }
                }

                // Sort others and errors by status and platform name
                const orderedOthers: InformationUpdateState[] = [];
                InformationUpdateState.orderedStatuses().forEach((status) => {
                    const informationUpdateStatePerStatusPerName = others
                        .filter((el) => el.status === status)
                        .sort((a, b) => a.translatedPlatformName.localeCompare(b.translatedPlatformName));
                    orderedOthers.push(...informationUpdateStatePerStatusPerName);
                });

                const orderedErrors: InformationUpdateState[] = errors.sort((a, b) =>
                    a.translatedPlatformName.localeCompare(b.translatedPlatformName)
                );

                return {
                    others: orderedOthers,
                    errors: orderedErrors,
                    latestValidatedAt: detailedUpdateStatusResult.latestValidatedAt
                        ? new Date(detailedUpdateStatusResult.latestValidatedAt)
                        : null,
                };
            }),
            tap(() => {
                this.restaurantInformationUpdateStatesIsLoading.set(false);
            })
        ),
        { initialValue: { others: [], errors: [], latestValidatedAt: null } }
    );

    readonly restaurantHasNeverSentInformationUpdates = computed(() => this.restaurantInformationUpdateStates().latestValidatedAt === null);

    readonly isAboutToSendUpdates = computed(() =>
        this.restaurantInformationUpdateStates().others.some((informationUpdateState) => informationUpdateState.isAboutToBeSent())
    );

    refreshRestaurantInformationUpdateStates(): void {
        this._refreshRestaurantInformationUpdateStates$.next();
    }

    private _getCategoryAttributes(restaurantId: string): Observable<Attribute[]> {
        return this._attributesService.getCategoryAttributes(restaurantId).pipe(
            catchError((err) => {
                console.warn('err :>>', err);
                this.categoryAttributesError.set(this._clarifyCategoryAttributesError(err));
                return of({ data: [], err: true });
            }),
            map((res) => res.data)
        );
    }

    private _getRestaurantAttributes(restaurantId: string): Observable<RestaurantAttribute[]> {
        return this._attributesService.getRestaurantAttributes(restaurantId).pipe(
            map((res) => res.data),
            catchError(() => of([]))
        );
    }

    private _getDetailedUpdateStatus(restaurantId: string): Observable<InformationUpdatesStateResponseDto> {
        return this._informationUpdatesService.getDetailedUpdateStatus(restaurantId).pipe(
            catchError(async () => ({
                latestValidatedAt: null,
                states: [],
            }))
        );
    }

    private _updateAttributes(allAttributes: Attribute[], restaurantAttributes: RestaurantAttribute[]): void {
        this.allCategoryAttributes.set(allAttributes);
        const filteredRestaurantAttributes = allAttributes.length
            ? intersectionWith(
                  restaurantAttributes.filter((el) => el.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED),
                  allAttributes,
                  (restaurantAttribute, attribute) => restaurantAttribute.attribute?.attributeId === attribute.attributeId
              )
            : restaurantAttributes.filter((el) => el.attributeValue !== RestaurantAttributeValue.NOT_CONCERNED);
        this.restaurantAttributes.set(filteredRestaurantAttributes);
    }

    private _clarifyCategoryAttributesError(err: HttpErrorResponse): string {
        if ([MalouErrorCode.GMB_NOT_CONNECTED, MalouErrorCode.ATTRIBUTES_GMB_NOT_CONNECTED].includes(err?.error?.malouErrorCode)) {
            return this._translateService.instant('information.attributes.error.gmb_not_connected_text');
        }
        if (err?.error?.malouErrorCode === MalouErrorCode.CREDENTIALS_GMB_API_ERROR) {
            return this._translateService.instant('information.attributes.error.gmb_credentials_error_text');
        }
        return err?.error?.message ?? err?.message ?? String(err);
    }
}
