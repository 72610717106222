@if (!isParentError && !isError()) {
    @if (!isParentLoading && !isLoading()) {
        <div class="malou-simple-card flex w-full flex-col gap-3 px-6 py-3 md:px-2">
            <div>
                <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            </div>
            <ng-container [ngTemplateOutlet]="hasData() ? tableTemplate : noDataTemplate"> </ng-container>
        </div>
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #titleTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">
            {{ 'aggregated_statistics.boosters.wheel_of_fortune.estimated_review_count.title' | translate }}
        </span>
    </div>
</ng-template>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table overflow-auto pr-3"
        matSort
        [trackBy]="trackByIdFn"
        [matSortDisableClear]="true"
        [dataSource]="dataSource"
        [matSortActive]="defaultSort.active"
        [matSortDirection]="defaultSort.direction"
        (matSortChange)="tableSortByChange.emit($event)"
        #table="matTable">
        <ng-container [matColumnDef]="TableFieldName.BUSINESS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.boosters.wheel_of_fortune.estimated_review_count.business' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-text-13--semibold text-malou-color-text-1">
                {{ rowData.restaurantName }}
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableFieldName.REVIEWS">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall max-w-[90px]" mat-sort-header>
                {{ 'aggregated_statistics.boosters.wheel_of_fortune.estimated_review_count.reviews' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-mat-table-cell-xsmall max-w-[90px]">
                {{ rowData.estimatedReviewCount }}
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableFieldName.EVOLUTION">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall max-w-[90px]" mat-sort-header>
                {{ 'aggregated_statistics.boosters.wheel_of_fortune.estimated_review_count.evolution' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData; table: table" class="malou-mat-table-cell-xsmall max-w-[90px]">
                @if (rowData.estimatedReviewCount) {
                    <app-number-evolution
                        size="small"
                        [value]="rowData.estimatedReviewCountDifferenceWithPreviousPeriod"
                        [displayedValue]="rowData.estimatedReviewCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
                @if (!rowData.estimatedReviewCount) {
                    <div class="malou-text-14--bold">-</div>
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; table: table; let i = index"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noDataTemplate>
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <div class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</div>
    </div>
</ng-template>
