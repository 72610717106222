import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_EXTENSION_FORMAT = 'png';

@Pipe({
    name: 'imagePathResolver',
    standalone: true,
})
export class ImagePathResolverPipe implements PipeTransform {
    transform(key: string, options?: { folder?: string; extensionFormat?: 'png' | 'svg' | 'gif' | 'jpeg' }): string {
        const extensionFormat = options?.extensionFormat ?? DEFAULT_EXTENSION_FORMAT;
        if (options?.folder) {
            return `/assets/images/${options.folder}/${key}.${extensionFormat}`;
        }
        return `/assets/images/${key}.${extensionFormat}`;
    }
}
