import { z } from 'zod';

import { SimilarRestaurantCategory } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getSimilarRestaurantsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetSimilarRestaurantsParamsDto = z.infer<typeof getSimilarRestaurantsParamsValidator>;

// -------------------------------------------------------------------------------

export const getManySimilarRestaurantsBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
});

export type GetManySimilarRestaurantsBodyDto = z.infer<typeof getManySimilarRestaurantsBodyValidator>;

// -------------------------------------------------------------------------------

export const updateSimilarRestaurantsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type UpdateSimilarRestaurantsParamsDto = z.infer<typeof updateSimilarRestaurantsParamsValidator>;

export const updateSimilarRestaurantsBodyValidator = z.object({
    category: z.nativeEnum(SimilarRestaurantCategory),
});

export type UpdateSimilarRestaurantsBodyDto = z.infer<typeof updateSimilarRestaurantsBodyValidator>;

// -------------------------------------------------------------------------------

export const getUpdatedSimilarRestaurantsBodyValidator = z.object({
    restaurantId: objectIdValidator,
});

export type GetUpdatedSimilarRestaurantsBodyDto = z.infer<typeof getUpdatedSimilarRestaurantsBodyValidator>;
