import { Restaurant } from ':shared/models';

export namespace Sort {
    export const sortByBrandBusinessThenName = (a: Restaurant, b: Restaurant): number => {
        if (a.isBrandBusiness() && !b.isBrandBusiness()) {
            return -1;
        }
        if (!a.isBrandBusiness() && b.isBrandBusiness()) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    };

    export const sortByDate =
        (key: string) =>
        (a: any, b: any): number => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            return dateA.getTime() - dateB.getTime();
        };
}
