import { NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MalouErrorCode, ReportType } from '@malou-io/package-utils';

import { ReportsService } from ':core/services/report.service';
import { ToastService } from ':core/services/toast.service';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-unsubscribe-report',
    templateUrl: './unsubscribe-report.component.html',
    styleUrls: ['./unsubscribe-report.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ImagePathResolverPipe,
        FormsModule,
        ReactiveFormsModule,
        InputTextComponent,
        TranslateModule,
        MatButtonModule,
        IllustrationPathResolverPipe,
        NgTemplateOutlet,
    ],
})
export class UnsubscribeReportComponent implements OnInit {
    readonly Illustration = Illustration;
    public unsubscribeForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });
    public reportTypeTranslationTitle = signal('');
    public reportTypeTranslationSubtitle = signal('');
    public submitSuccess = signal(false);
    private _reportId: string | null = null;
    private _hash: string | null = null;
    private _index: string | null = null;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _reportService: ReportsService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService
    ) {}

    get email(): AbstractControl<string | null, string | null> | null {
        return this.unsubscribeForm.get('email');
    }

    ngOnInit(): void {
        this._route.queryParams.subscribe((params) => {
            if (params.reportId) {
                this._reportId = params.reportId;
            }
            if (params.hash) {
                this._hash = params.hash;
            }
            if (params.index) {
                this._index = params.index;
            }
            if (params.reportType as ReportType) {
                switch (params.reportType) {
                    case ReportType.WEEKLY_PERFORMANCE:
                        this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.weekly_performance_title'));
                        this.reportTypeTranslationSubtitle.set(
                            this._translate.instant('reports.unsubscribe.confirm_email_weekly_performance')
                        );
                        break;
                    case ReportType.MONTHLY_PERFORMANCE:
                        this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.monthly_performance_title'));
                        this.reportTypeTranslationSubtitle.set(
                            this._translate.instant('reports.unsubscribe.confirm_email_monthly_performance')
                        );
                        break;
                    case ReportType.WEEKLY_REVIEWS:
                        this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.weekly_reviews_title'));
                        this.reportTypeTranslationSubtitle.set(this._translate.instant('reports.unsubscribe.confirm_email_weekly_reviews'));
                        break;
                    case ReportType.DAILY_REVIEWS:
                        this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.daily_reviews_title'));
                        this.reportTypeTranslationSubtitle.set(this._translate.instant('reports.unsubscribe.confirm_email_daily_reviews'));
                        break;
                    default:
                        this.reportTypeTranslationTitle.set('');
                        this.reportTypeTranslationSubtitle.set('');
                        break;
                }
            }
        });
    }

    public submitForm(): void {
        if (this.unsubscribeForm.invalid) {
            return;
        }
        if (!this._reportId || !this._hash || !this._index || !this.email?.value) {
            return;
        }
        this._reportService
            .unsubscribeReport({
                email: this.email.value,
                hash: this._hash,
                index: parseInt(this._index, 10),
                reportId: this._reportId,
            })
            .subscribe({
                next: () => {
                    this.submitSuccess.set(true);
                },
                error: (httpError: HttpErrorResponse) => {
                    this._toastService.openErrorToast(this._clarifyError(httpError.error.message));
                },
            });
    }

    private _clarifyError(message: string): string {
        if (message.match(MalouErrorCode.INVALID_UNSUBSCRIBE_HASH)) {
            return this._translate.instant('reports.unsubscribe.wrong_email');
        }
        return this._translate.instant('common.unknown_error');
    }
}
