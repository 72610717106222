@if (totalInteractions() > 0) {
    <div class="ml-2 flex h-full items-end gap-1">
        <mat-icon
            class="mb-px !h-4 !w-4 cursor-pointer text-malou-color-chart-purple--accent"
            data-testid="post-modal-previous-interaction-btn"
            [svgIcon]="SvgIcon.ARROW_PREVIOUS"
            [id]="previousIconId()"
            [class.opacity-50]="currentInteractionIndex() === 0"
            [class.!cursor-default]="currentInteractionIndex() === 0"
            (click)="toPreviousInteraction()">
        </mat-icon>
        <mat-icon
            class="mb-px !h-4 !w-4 cursor-pointer text-malou-color-chart-purple--accent"
            data-testid="post-modal-next-interaction-btn"
            [svgIcon]="SvgIcon.ARROW_NEXT"
            [id]="nextIconId()"
            [class.opacity-50]="(currentInteractionIndex() ?? 0) >= totalInteractions() - 1"
            [class.!cursor-default]="(currentInteractionIndex() ?? 0) >= totalInteractions() - 1"
            (click)="toNextInteraction()">
        </mat-icon>
    </div>
}
