import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, throwError } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { PlatformsService } from ':core/services/platforms.service';

import * as PlatformsActions from './platforms.actions';

@Injectable()
export class PlatformsEffect {
    readonly loadPlatformsData$ = createEffect(() =>
        this._actions$.pipe(
            ofType(PlatformsActions.loadPlatformsData),
            exhaustMap((action) =>
                this._platformsService.getPlatformsForRestaurant(action.restaurantId).pipe(
                    map((res) => {
                        if (!res.data) {
                            throwError('no_data');
                        }
                        const platformsData = {};
                        platformsData[action.restaurantId] = res.data;
                        return PlatformsActions.editPlatformsData({ platformsData });
                    }),
                    catchError((err) => {
                        console.warn('err :', err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _platformsService: PlatformsService
    ) {}
}
