@if (currentCWM) {
    <div class="flex h-full w-full flex-col px-8 pt-2 md:max-h-[80vh] md:px-0">
        <div class="mb-4 hidden w-full items-center md:flex">
            <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="leaveConversation()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
            </button>
            <div class="platform-user-logo relative ml-5">
                <app-platform-logo
                    class="z-10"
                    imgClasses="h-8 w-8 absolute left-0 top-0 !rounded-full"
                    [logo]="currentCWM.conversation.key"></app-platform-logo>
                <img
                    class="translate-x-[50%] transform rounded-full"
                    height="34"
                    width="34"
                    [src]="currentCWM.getConversationSenderName() | avatar" />
            </div>
            <div class="malou-text-12--bold malou-color-text-1 ml-6">{{ '@' + currentCWM.getConversationSenderName() }}</div>
        </div>
        <hr class="mb-4 hidden md:block" />
        <!-- Conversation messages list -->
        <div class="relative grow overflow-auto">
            <div class="messages-list h-full overflow-y-auto overflow-x-hidden" id="messages-list" (scroll)="onScroll()">
                <div>
                    @if (loadingMore$ | async) {
                        <div class="flex w-full justify-center">
                            <mat-spinner diameter="20"></mat-spinner>
                        </div>
                    }
                    @for (message of currentCWM.messages; track trackByIdFn(index, message); let index = $index) {
                        <app-message-card
                            [restaurant]="restaurant"
                            [shouldDisplayMessagesNewStartDate]="
                                index === 0 ||
                                (currentCWM.messages[index - 1].socialCreatedAt | date: 'dd/MM/yyyy') !==
                                    (message.socialCreatedAt | date: 'dd/MM/yyyy')
                            "
                            [shouldDisplayProfilePicture]="
                                index === 0
                                    ? !message.isFromRestaurant
                                    : !!currentCWM.messages[index - 1].isFromRestaurant && !message.isFromRestaurant
                            "
                            [message]="message | asType: Message"
                            (onRetrySendMessage)="retrySendMessage($event)">
                        </app-message-card>
                    }
                    <div class="flex items-center" [class.hidden]="!isUserTyping">
                        <img class="client-picture" height="20" width="20" [src]="getDisplayName() | avatar" />
                        <span class="spinner-left">
                            <div class="spinner">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </span>
                    </div>
                    <div class="typing-spinner-container flex items-center justify-end" [class.hidden]="!teamTyping.typing">
                        <span class="spinner-left">
                            <div class="spinner">
                                <div class="bounce3"></div>
                                <div class="bounce2"></div>
                                <div class="bounce1"></div>
                            </div>
                        </span>
                        <img class="client-picture" height="20" width="20" [src]="getTeamTypingDisplayName() | avatar" />
                    </div>
                </div>
            </div>
            <div class="new-message-container flex w-full justify-center" [class.hidden]="!receivedNewMessage">
                <mat-chip class="malou-box-shadow malou-text-12--regular" (click)="scrollBottom()">
                    {{ 'messages.received_new_message' | translate }}
                    <button matChipRemove (click)="onRemovedChip()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    </button>
                </mat-chip>
            </div>
        </div>
        <!-- Writing message container -->
        <div class="mt-5">
            <!-- Chips quick texts -->
            <mat-chip-listbox>
                <div class="flex flex-nowrap gap-1">
                    @for (quickText of quickTexts; track quickText) {
                        <div
                            class="malou-chip malou-chip--primary min-w-fit max-w-[100px] truncate"
                            matRipple
                            (click)="insertQuickText(quickText)">
                            <span>{{ '@' + quickText.displayKey }}</span>
                        </div>
                    }
                </div>
            </mat-chip-listbox>
            <!-- END Chips quick texts -->
            <div class="mt-3 flex items-center justify-between gap-x-2">
                <div class="action-buttons flex max-w-[1rem] gap-x-4" [ngClass]="{ 'max-w-[6rem]': moreButtonsToggled }">
                    <button
                        class="malou-btn-icon !h-12.5 !w-fit !p-0"
                        mat-icon-button
                        disableRipple
                        [class.rotate-180]="moreButtonsToggled"
                        (click)="toggleMoreButtons()">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="moreButtonsToggled ? 'interdiction' : 'add'"></mat-icon>
                    </button>
                    <button
                        class="malou-btn-icon !h-12.5 !w-fit !p-0 opacity-0"
                        mat-icon-button
                        disableRipple
                        [matMenuTriggerFor]="menu"
                        [class.opacity-100]="moreButtonsToggled"
                        [matTooltip]="'messages.message_area.add_file' | translate">
                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ATTACHMENT"></mat-icon>
                    </button>
                    <div class="!w-fit" [matTooltip]="templates.length === 0 ? ('messages.message_area.no_templates' | translate) : ''">
                        <button
                            class="malou-btn-icon !h-12.5 !w-fit !p-0 opacity-0"
                            mat-icon-button
                            disableRipple
                            [class.opacity-100]="moreButtonsToggled"
                            [matTooltip]="templates.length === 0 ? '' : ('messages.message_area.templates.templates_tooltip' | translate)"
                            [disabled]="templates.length === 0"
                            (click)="openTemplatesPickerModal()">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.LIST"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="grow">
                    <app-text-area
                        [formControl]="messageText"
                        [placeholder]="'messages.message_area.placeholder' | translate"
                        [maxLength]="MAX_MESSAGE_LENGTH"
                        [rows]="1"
                        [errorMessage]="getTextErrorMessages()"
                        [maxRows]="4"
                        [resizable]="false"
                        (onPressEnterKey)="sendMessage()">
                    </app-text-area>
                </div>
                @if (!saveTemplate) {
                    <button
                        class="malou-btn-icon--primary btn-xl"
                        id="tracking_conversation_panel_send_message_button"
                        mat-icon-button
                        [disabled]="!messageText.valid && (getMediaAttachments | applyPure: attachments).length === 0"
                        (click)="sendMessage()">
                        <mat-icon class="!text-white" [svgIcon]="SvgIcon.PAPER_PLANE"></mat-icon>
                    </button>
                }
            </div>
            <div class="mt-3 w-full rounded-lg bg-malou-color-background-dark p-4 md:mb-3">
                <mat-checkbox class="malou-text-12--regular" color="primary" [checked]="saveTemplate" (change)="toggleSaveTemplate()">
                    {{ 'messages.message_area.save_as_model' | translate }}
                </mat-checkbox>
                @if (saveTemplate) {
                    <div class="mt-3 w-full">
                        <app-input-text
                            title="{{ 'messages.message_area.templates.input_title' | translate }}"
                            placeholder="{{ 'messages.message_area.templates.input_placeholder' | translate }}"
                            [formControl]="templateNameControl"
                            [errorMessage]="getTemplateErrorMessages(templateNameControl)"
                            [showClearIcon]="true"></app-input-text>
                    </div>
                    <div class="mt-4 flex w-full justify-end gap-x-3">
                        <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="toggleSaveTemplate()">
                            {{ 'common.cancel' | translate }}
                        </button>
                        <button
                            class="malou-btn-raised--primary !h-11"
                            mat-raised-button
                            [disabled]="hasTemplateErrors()"
                            (click)="sendMessage()">
                            {{ 'messages.message_area.templates.save_send' | translate }}
                        </button>
                    </div>
                }
            </div>
        </div>
    </div>
}

<mat-menu class="malou-mat-menu malou-box-shadow custom-option-panel !mx-0 !mt-3 !max-w-none" #menu="matMenu">
    <button class="flex gap-x-3" mat-menu-item (click)="fileInput.click()">
        <input
            type="file"
            accept="image/png, image/jpeg, video/quicktime, video/mp4"
            hidden
            format
            (change)="onFileChange($event)"
            #fileInput />
        @if (!screenSizeService.isPhoneScreen) {
            <span class="malou-text-14--regular">{{ 'messages.from_computer' | translate }}</span>
        }
        @if (screenSizeService.isPhoneScreen) {
            <span class="malou-text-14--regular">{{ 'messages.from_phone' | translate }}</span>
        }
    </button>
    <button mat-menu-item (click)="openMediaPicker()">
        <span class="malou-text-14--regular">
            {{ 'messages.from_gallery' | translate }}
        </span>
    </button>
</mat-menu>
