<div class="malou-dialog malou-dialog-mobile !max-w-[100vw]">
    <div class="malou-dialog__header">
        <span>
            {{ 'gallery.move_modal_title' | translate }}
        </span>
        <mat-icon class="close" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body">
        <div class="grow">
            <app-select
                [title]="'gallery.move_modal_select_title' | translate"
                [values]="folders"
                [formControl]="selectedFolderControl"
                [displayWith]="displayFolderWith">
                <ng-template let-value="value" #simpleSelectedValueTemplate>
                    <div class="flex h-full items-center">
                        <span class="malou-text-12--regular text-malou-color-text-1">{{ value.name }}</span>
                    </div>
                </ng-template>

                <ng-template let-value="value" #optionTemplate>
                    @if (value.id) {
                        <div class="flex items-center">
                            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                            <span class="malou-text-12--semibold ml-3 text-malou-color-text-2">{{ value.name }}</span>
                        </div>
                    } @else {
                        <span class="malou-text-12--semibold text-malou-color-text-1">{{ value.name }}</span>
                    }
                </ng-template>
            </app-select>
        </div>
    </div>
    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="move()">
            {{ 'common.move' | translate }}
        </button>
    </div>
</div>
