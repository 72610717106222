<div>
    <ng-container [ngTemplateOutlet]="isLoading() ? loadingTemplate : mainTemplate"></ng-container>
</div>

<ng-template #mainTemplate>
    <div class="malou-text-18--bold malou-color-text-1 mb-4">{{ 'automations.reviewReplies.title' | translate }}</div>

    <div class="grid grid-cols-2 gap-4 md:grid-cols-1">
        <app-automation-card
            [title]="'automations.reviewReplies.reviewsWithComment' | translate"
            [automations]="reviewAutomationsWithComment()"
            (onClick)="openReviewsWithCommentAutomationsModal()" />

        <app-automation-card
            [title]="'automations.reviewReplies.reviewsWithoutComment' | translate"
            [automations]="reviewAutomationsWithoutComment()"
            (onClick)="openReviewsWithoutCommentAutomationsModal()" />
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
</ng-template>
