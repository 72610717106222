import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-ai-post-settings-proposal',
    standalone: true,
    imports: [TranslateModule, MatIconModule],
    templateUrl: './ai-post-settings-proposal.component.html',
    styleUrl: './ai-post-settings-proposal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPostSettingsProposalComponent {
    readonly text = input.required<string>();
    readonly SvgIcon = SvgIcon;
}
