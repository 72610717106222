<div class="h-full bg-malou-color-background-light">
    <div class="malou-dialog__header !p-4 !pl-5">
        <div class="title">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'restaurant_ai_settings.modals.upsert.preview' | translate }}
            </div>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close.emit()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body h-full max-h-[70vh] overflow-y-auto !pl-5 pb-3">
        <div class="flex rounded-lg border border-malou-color-border-primary bg-white p-4">
            <textarea
                class="malou-text-12--regular w-full resize-none border-none placeholder:italic focus:outline-none"
                [placeholder]="'ai.posts.preview_placeholder' | translate"
                [formControl]="generateLegendControl"
                [maxLength]="500"
                [cdkTextareaAutosize]="true"
                [cdkAutosizeMaxRows]="4"
                [cdkAutosizeMinRows]="3"
                [rows]="6"></textarea>
        </div>

        <div class="mt-2 flex justify-end">
            <app-button theme="secondary--alt" [loading]="isLoading()" (buttonClick)="generatePreview()">
                <ng-template class="flex" #textTemplate>
                    <div class="flex">
                        <mat-icon
                            class="mb-1 mr-1 h-4 !w-4 text-malou-color-chart-purple--accent"
                            icon
                            [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                        <span class="text-malou-color-chart-purple--accent">
                            {{ 'restaurant_ai_settings.modals.upsert.generate' | translate }}
                        </span>
                    </div>
                </ng-template>
            </app-button>
        </div>

        @if (!isLoading() && postTextPreview()) {
            <app-ai-post-settings-proposal [text]="postTextPreview()"></app-ai-post-settings-proposal>
        }
    </div>
</div>
