import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnInit,
    output,
    Signal,
    signal,
    viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Subject } from 'rxjs';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Keyword } from ':shared/models';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

import { DISPLAYED_COLUMNS, MAX_KEYWORDS_SELECTED } from '../keywords-validation-modal.component';
import { UpdateKeywordLangComponent } from '../update-keyword-lang/update-keyword-lang.component';

@Component({
    selector: 'app-selected-keywords-validation-modal',
    templateUrl: './selected-keywords-validation-modal.component.html',
    styleUrls: ['./selected-keywords-validation-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        LazyLoadImageModule,
        ButtonComponent,
        KeywordsPopularityComponent,
        MalouSpinnerComponent,
        NoopMatCheckboxComponent,
        SkeletonComponent,
        UpdateKeywordLangComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        CreateArrayPipe,
        FlagPathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedKeywordsValidationModalComponent implements OnInit {
    readonly dataSource: ModelSignal<MatTableDataSource<Keyword>> = model.required();
    readonly selection: InputSignal<SelectionModel<Keyword>> = input.required();
    readonly allKeywords: InputSignal<Keyword[]> = input.required();
    readonly loading: InputSignal<boolean> = input.required();
    readonly isSaving: InputSignal<boolean> = input.required();

    readonly updateKeywordLanguage = output<{ keyword: Keyword; lang: ApplicationLanguage }>();
    readonly chooseKeywords = output<void>();
    readonly unselectKeywords = output<Keyword[]>();
    readonly close = output<void>();
    readonly save = output<void>();

    private readonly _translateService = inject(TranslateService);

    readonly matSort = viewChild(MatSort);

    readonly selectionCount$: Subject<number> = new Subject<number>();
    readonly selectionCount: Signal<number> = toSignal(this.selectionCount$, { initialValue: 0 });
    readonly isAllSelected = computed(() => this.dataSource().data.length === this.selectionCount() && this.selectionCount() > 0);
    readonly isAllFilteredSelected = computed(() => this.dataSource().filteredData.length === this.selectionCount());
    readonly lastSelectedSegmentRow = signal(0);

    readonly DISPLAYED_COLUMNS = DISPLAYED_COLUMNS;
    readonly MAX_KEYWORDS_SELECTED = MAX_KEYWORDS_SELECTED;

    constructor() {
        effect(() => {
            const sort = this.matSort();

            const sortingDataAccessor = (item: Keyword, property: string): string | number => {
                switch (property) {
                    case 'text':
                        return item.text
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                    default:
                        return item[property];
                }
            };
            this.dataSource.update((currentDataSource) => {
                currentDataSource.sort = sort || null;
                currentDataSource.sortingDataAccessor = sortingDataAccessor;
                return currentDataSource;
            });
        });
    }

    ngOnInit(): void {
        this.selection()
            .getCount$()
            .subscribe((count) => this.selectionCount$.next(count));
    }

    getPrettyLang = (lang: ApplicationLanguage | string): string => this._translateService.instant(`header.langs.${lang}`);

    updateKeywordLang = ({ keyword, lang }: { keyword: Keyword; lang: ApplicationLanguage }): void => {
        this.updateKeywordLanguage.emit({ keyword, lang });
    };

    chooseKeywordsForMe(): void {
        this.chooseKeywords.emit();
    }

    removeSelectedKeyword(keyword: Keyword): void {
        this.unselectKeywords.emit([keyword]);
    }

    removeSelectedKeywords(): void {
        this.unselectKeywords.emit(this.selection().getSelection());
    }

    selectMultiple(event: MouseEvent, lastRow: number): void {
        if (event.shiftKey) {
            const rowsToSelect = this.dataSource().filteredData.filter((_val, i) => i >= this.lastSelectedSegmentRow() && i < lastRow);

            this.selection().select(rowsToSelect);
        }
        this.lastSelectedSegmentRow.set(lastRow);
    }

    toggleAllFiltered(): void {
        if (this.isAllFilteredSelected()) {
            this.selection().unselect(this.dataSource().filteredData);
        } else {
            this.selection().select(this.dataSource().filteredData);
            this.lastSelectedSegmentRow.set(0);
        }
    }

    toggleSelected(keyword: Keyword): void {
        this.selection().toggle(keyword);
    }

    onClose(): void {
        this.close.emit();
    }

    onSave(): void {
        this.save.emit();
    }
}
