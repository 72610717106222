import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

const DEFAULT_SIZE = 'medium';

@Component({
    selector: 'app-malou-spinner',
    templateUrl: './malou-spinner.component.html',
    styleUrls: ['./malou-spinner.component.scss'],
    standalone: true,
    imports: [NgStyle, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MalouSpinnerComponent {
    readonly size = input<'xs' | 'small' | 'medium' | 'large'>(DEFAULT_SIZE);
    readonly color = input<string | null>(null);

    readonly SvgIcon = SvgIcon;
}
