import { z } from 'zod';

import { OrganizationDto } from './organization.response.dto';

export const createOrganizationRequestBodyValidator = z.object({
    name: z.string(),
    verifiedEmailsForCampaigns: z.array(z.string()),
    limit: z.coerce.number().nullish(),
});
export type CreateOrganizationRequestBodyDto = z.infer<typeof createOrganizationRequestBodyValidator>;

export type CreateOrganizationResponseBodyDto = OrganizationDto;
