import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MessageTemplateState } from './message-templates.reducer';

export const selectMessageTemplatesState = createFeatureSelector<MessageTemplateState>('messageTemplates');

export const selectMessageTemplatesCount = createSelector(
    selectMessageTemplatesState,
    (state: MessageTemplateState) => state.templateCount
);

export const selectMessageTemplatesSort = createSelector(selectMessageTemplatesState, (state: MessageTemplateState) => state.sort);

export const selectMessageTemplatesSortBy = createSelector(
    selectMessageTemplatesSort,
    (state: { sortBy: string; sortOrder: string }) => state.sortBy
);

export const selectMessageTemplatesSortOrder = createSelector(
    selectMessageTemplatesSort,
    (state: { sortBy: string; sortOrder: string }) => state.sortOrder
);
