<div class="relative flex pb-3 pl-6 pt-3" [class.selected]="isSelected()" (click)="selectConversation()">
    <div class="flex items-center pr-3">
        <mat-checkbox
            color="primary"
            [checked]="isConversationChecked()"
            (change)="toogleMessageChecked()"
            (click)="$event.stopPropagation()"></mat-checkbox>
    </div>
    <div class="w-[calc(100%-0.75rem-24px)] flex-col justify-between pr-6">
        <div class="flex items-center justify-between">
            <div class="platform-user-logo relative">
                <app-platform-logo
                    class="z-10"
                    imgClasses="h-8 w-8 absolute left-0 top-0 !rounded-full"
                    [logo]="conversationWithMessages.conversation.key"
                    [withLazyLoading]="true"></app-platform-logo>
                <img
                    class="translate-x-[50%] transform rounded-full"
                    height="34"
                    width="34"
                    [lazyLoad]="conversationWithMessages.conversation.userInfo.displayName | avatar" />
            </div>
            <div class="ml-8 grow">
                <span
                    class="malou-text-12--medium malou-color-text-1 flex items-center"
                    [class.malou-text-12--bold]="isConversationUnread | applyPure: conversationWithMessages.conversation.status">
                    <span>{{ '@' + conversationWithMessages.getConversationSenderName() }}</span>
                    @if (
                        (isConversationUnread | applyPure: conversationWithMessages.conversation.status) && screenSizeService.isPhoneScreen
                    ) {
                        <div class="ml-3 h-[9px] w-[9px] rounded-full bg-malou-color-primary"></div>
                    }
                </span>
                <div
                    class="malou-text-10 self-baseline italic"
                    [class.unread-conversation]="isConversationUnread | applyPure: conversationWithMessages.conversation.status">
                    {{ conversationWithMessages | applySelfPure: 'getTimeSinceNewMessage' }}
                </div>
            </div>
            <button
                [matTooltip]="
                    conversationWithMessages.conversation.favorite
                        ? ('messages.remove_from_favorites' | translate)
                        : ('messages.add_to_favorite' | translate)
                "
                (click)="saveConversationAsFavorite(); $event.stopPropagation()">
                <mat-icon
                    class="bookmark"
                    [svgIcon]="SvgIcon.BOOKMARK_FILLED"
                    [class.active]="conversationWithMessages.conversation.favorite"></mat-icon>
            </button>
        </div>
        <div class="mt-3 flex items-center justify-between">
            <div
                class="malou-text-10--medium max-w-[85%] truncate"
                [class.unread-conversation]="isConversationUnread | applyPure: conversationWithMessages.conversation.status"
                [innerHTML]="getConversationLastMessageHtml | applyPure: conversationWithMessages"
                [ngClass]="{ italic: (conversationWithMessages | applySelfPure: 'isLastMessageDirectText') }"></div>

            @if ((isConversationUnread | applyPure: conversationWithMessages.conversation.status) && !screenSizeService.isPhoneScreen) {
                <div class="mr-1 h-[9px] w-[9px] rounded-full bg-malou-color-primary"></div>
            }

            @if (screenSizeService.isPhoneScreen) {
                <div class="translate-x-1 transform">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="openMenu($event)">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                    </button>
                    <mat-menu
                        class="malou-box-shadow custom-option-panel malou-mat-menu !mx-0 !mt-3 !max-w-none"
                        (closed)="closeMenu()"
                        #menu="matMenu">
                        <button class="flex gap-x-3" mat-menu-item (click)="toggleConversationStatus()">
                            <mat-icon color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'messages.mark_as_unread' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="changeArchivedConversation()">
                            <mat-icon color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
                            <span class="malou-text-14--regular">
                                {{
                                    ((isConversationArchived | applyPure: conversationWithMessages.conversation.archived)
                                        ? 'messages.unarchive'
                                        : 'messages.archive'
                                    ) | translate
                                }}
                            </span>
                        </button>
                    </mat-menu>
                </div>
            }
        </div>

        @if (!screenSizeService.isPhoneScreen) {
            <div
                class="menu-button absolute right-6 top-1/2 -translate-x-1/2 -translate-y-1/2 transform opacity-0"
                [class.opacity-100]="menuOpened">
                <button
                    class="malou-btn-icon--secondary btn-xl malou-box-shadow"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    (click)="openMenu($event)">
                    <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                </button>
                <mat-menu
                    class="malou-mat-menu malou-box-shadow custom-option-panel !mx-0 !mt-3 !max-w-none"
                    (closed)="closeMenu()"
                    #menu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="toggleConversationStatus()">
                        <mat-icon
                            color="primary"
                            [svgIcon]="
                                (isConversationUnread | applyPure: conversationWithMessages.conversation.status) ? 'eye' : 'eye-closed'
                            "></mat-icon>
                        <span class="malou-text-14--regular">{{
                            (isConversationUnread | applyPure: conversationWithMessages.conversation.status)
                                ? ('messages.mark_as_read' | translate)
                                : ('messages.mark_as_unread' | translate)
                        }}</span>
                    </button>
                    <button mat-menu-item (click)="changeArchivedConversation()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.BOX"></mat-icon>
                        <span class="malou-text-14--regular">
                            {{
                                (isConversationArchived | applyPure: conversationWithMessages.conversation.archived)
                                    ? ('messages.unarchive' | translate)
                                    : ('messages.archive' | translate)
                            }}
                        </span>
                    </button>
                </mat-menu>
            </div>
        }
    </div>
</div>

<hr class="border-malou-color-background-dark" />
