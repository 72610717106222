/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Directive, ElementRef, HostListener, inject, input, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, Subject } from 'rxjs';

const DEFAULT_MARGIN_IN_PIXELS = 16;

/**
 * Keep element in window.
 *
 * Useful for absolute positioned div like emoji selector.
 */
@Directive({
    selector: '[appKeepElementInWindowDirective]',
    standalone: true,
})
export class KeepAbsoluteDivInWindowDirective implements AfterViewInit {
    private readonly _elementRef = inject<ElementRef<Element>>(ElementRef<Element>);
    private readonly _renderer = inject(Renderer2);

    private readonly _refresh = new Subject<void>();

    marginPx = input(DEFAULT_MARGIN_IN_PIXELS);

    constructor() {
        this._refresh.pipe(takeUntilDestroyed(), debounceTime(500)).subscribe(() => this._checkAndAdjustPosition());
    }

    ngAfterViewInit(): void {
        this._checkAndAdjustPosition();
    }

    @HostListener('window:resize')
    onWindowResizeEvent(): void {
        this._refresh.next();
    }

    private _checkAndAdjustPosition(): void {
        const element = this._elementRef.nativeElement;
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const styles = getComputedStyle(element);
        const currentTopProperty = parseInt(styles.top ?? '0', 10);
        const currentLeftProperty = parseInt(styles.left ?? '0', 10);

        let deltaTop = 0;
        let deltaLeft = 0;

        if (rect.top < 0) {
            deltaTop = rect.top + this.marginPx();
        } else if (rect.bottom > windowHeight) {
            deltaTop = -(rect.bottom - windowHeight + this.marginPx());
        }

        if (rect.left < 0) {
            deltaLeft = rect.left + this.marginPx();
        } else if (rect.right > windowWidth) {
            deltaLeft = -(rect.right - windowWidth + this.marginPx());
        }

        const computedTop = currentTopProperty + deltaTop;
        const computedLeft = currentLeftProperty + deltaLeft;
        const topValue = `${computedTop}px`;
        const leftValue = `${computedLeft}px`;

        this._renderer.setStyle(element, 'top', topValue);
        this._renderer.setStyle(element, 'left', leftValue);
    }
}
