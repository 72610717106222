import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { PlatformDefinitions, ReviewAnalysisStatus, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { Review, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

const REVIEWS_ANALYSIS_START_DATE = DateTime.local().minus({ days: 3 }).toJSDate();

@Component({
    selector: 'app-review-semantic-analysis',
    templateUrl: './review-semantic-analysis.component.html',
    styleUrls: ['./review-semantic-analysis.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewSemanticAnalysisComponent implements OnInit {
    readonly whiteBorders = input<boolean>(false);
    readonly showOriginalTextWarning = input<boolean>(false);
    readonly hoveredChip = output<SegmentAnalyses>();
    readonly highlightAllSegments = output<void>();
    readonly review = input.required<Review | PrivateReview | undefined>();

    readonly segmentAnalyses = computed((): SegmentAnalyses[] => {
        const review = this.review();
        return this.getUniqueSegmentAnalyses(review);
    });
    readonly analysisStatus = computed((): string => this.getReviewAnalysisStatus(this.review(), this.segmentAnalyses()));

    readonly ReviewAnalysisStatus = ReviewAnalysisStatus;
    readonly SemanticAnalysisSentiment = SemanticAnalysisSentiment;
    readonly isAnyChipHovered = signal(false);

    ngOnInit(): void {
        this.highlightAllSegments.emit();
    }

    getReviewAnalysisStatus(review: Review | PrivateReview | undefined, segmentAnalyses: SegmentAnalyses[]): ReviewAnalysisStatus {
        if (!review) {
            return ReviewAnalysisStatus.PENDING;
        }
        if (!this._doesPlatformHaveAnalysis()) {
            return ReviewAnalysisStatus.UNSUPPORTED_PLATFORM;
        }
        if (review.semanticAnalysis?.status === ReviewAnalysisStatus.DONE && !segmentAnalyses.length) {
            return ReviewAnalysisStatus.NO_RESULTS;
        }
        if (
            review.semanticAnalysis?.status === ReviewAnalysisStatus.FAILED ||
            review.semanticAnalysis?.status === ReviewAnalysisStatus.DONE
        ) {
            return review.semanticAnalysis?.status;
        }
        if (review.socialCreatedAt < REVIEWS_ANALYSIS_START_DATE) {
            return ReviewAnalysisStatus.REVIEW_TOO_OLD;
        }
        return ReviewAnalysisStatus.PENDING;
    }

    getUniqueSegmentAnalyses(review: Review | PrivateReview | undefined): SegmentAnalyses[] {
        return (
            (review?.semanticAnalysis?.segmentAnalyses?.filter((segmentAnalysis) => !!segmentAnalysis.sentiment) ?? []).filter(
                (segmentAnalysis, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.tag === segmentAnalysis.tag && t.sentiment.toLowerCase() === segmentAnalysis.sentiment.toLowerCase()
                    )
            ) ?? []
        );
    }

    onMouseEnter(segmentAnalyses: SegmentAnalyses): void {
        this.isAnyChipHovered.set(true);
        this.hoveredChip.emit(segmentAnalyses);
    }

    onMouseLeave(): void {
        this.isAnyChipHovered.set(false);
        this.highlightAllSegments.emit();
    }

    private _doesPlatformHaveAnalysis(): boolean {
        const review = this.review();
        return !!review && (PlatformDefinitions.getPlatformDefinition(review.key)?.hasReviewSemanticAnalysis ?? false);
    }
}
