import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, model, output, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';

interface Media {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
}

@Component({
    selector: 'app-media-thumbnail-list',
    templateUrl: './media-thumbnail-list.component.html',
    standalone: true,
    imports: [MatIconModule, NgClass, NgOptimizedImage, NgTemplateOutlet, CreateArrayPipe, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaThumbnailListComponent {
    readonly medias = model.required<Media[]>();
    readonly uploadingMediaCount = input.required<number>();
    readonly showEditMediaButton = input.required();
    readonly mediaClicked = output<string>();
    readonly editMedia = output<string>();

    readonly mediaAdded = output<{ id: string }>();

    readonly scrollableDiv = viewChild<ElementRef<HTMLElement>>('scrollableDiv');
    readonly fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');

    readonly SvgIcon = SvgIcon;

    readonly atLeftStop = signal<boolean>(true);
    readonly atRightStop = signal<boolean>(false);

    private readonly _scrollableDivObserver: ResizeObserver;

    constructor() {
        this._scrollableDivObserver = new ResizeObserver(() => {
            this.updateStopBoolean();
        });

        effect(() => {
            const scrollableDiv = this.scrollableDiv();
            if (scrollableDiv) {
                this._scrollableDivObserver.disconnect();
                this._scrollableDivObserver.observe(scrollableDiv.nativeElement);
            }
        });
    }

    onChevronLeftClick(): void {
        this._scroll(-200);
    }

    onChevronRightClick(): void {
        this._scroll(200);
    }

    onScroll(): void {
        this.updateStopBoolean();
    }

    updateStopBoolean(): void {
        const scrollable = this.scrollableDiv()?.nativeElement;
        if (!scrollable) {
            return;
        }
        this.atLeftStop.set(scrollable.scrollLeft === 0);
        const maxScrollLeft = scrollable.scrollWidth - scrollable.clientWidth;
        this.atRightStop.set(scrollable.scrollLeft >= maxScrollLeft);
    }

    removeMedia(mediaId: string): void {
        this.medias.update((medias) => medias.filter((m) => m.id !== mediaId));
    }

    onEditMedia(mediaId: string): void {
        this.editMedia.emit(mediaId);
    }
    private _scroll(increment: number): void {
        const scrollableDiv = this.scrollableDiv();
        if (!scrollableDiv) {
            return;
        }
        scrollableDiv.nativeElement.scrollTo({ left: scrollableDiv.nativeElement.scrollLeft + increment, behavior: 'smooth' });
    }
}
