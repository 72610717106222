import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

import { LocalStorage } from ':core/storage/local-storage';
import { RestaurantBusinessHoursComponent } from ':shared/components/restaurant-informations/restaurant-business-hours/restaurant-business-hours.component';
import { Restaurant } from ':shared/models/';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-restaurant-other-hours',
    templateUrl: './restaurant-other-hours.component.html',
    styleUrls: ['./restaurant-other-hours.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatExpansionModule,
        TranslateModule,
        RestaurantBusinessHoursComponent,
        ApplySelfPurePipe,
        IllustrationPathResolverPipe,
    ],
})
export class RestaurantOtherHoursComponent {
    @Input() restaurant: Restaurant;
    @Output() openModal = new EventEmitter<void>();

    lang = LocalStorage.getLang();

    openHoursModal(): void {
        this.openModal.emit();
    }
}
