import { Pipe, PipeTransform } from '@angular/core';

const powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1000 },
];

@Pipe({
    name: 'shortNumber',
    standalone: true,
})
export class ShortNumberPipe implements PipeTransform {
    transform(value?: number | null, { content = '', fixNumber = 2, shouldDisplayMinusSign = true } = {}): string {
        if (typeof value !== 'number' || isNaN(value)) {
            return '-';
        }

        const isNegative: boolean = value < 0;
        const abs: number = Math.abs(value);

        let resultValue: number = abs;
        let key = '';

        for (let i = 0; i < powers.length; i++) {
            const reduced = abs / powers[i].value;
            if (reduced >= 1) {
                resultValue = reduced;
                key = powers[i].key;
                break;
            }
        }

        const decimalsCount: number = resultValue.toString().split('.')[1]?.length ?? 0;
        const fixedNumber: string = Number(resultValue).toFixed(Math.min(decimalsCount, fixNumber));
        const fixedNumberWithoutTrialingZeros: number = +fixedNumber;

        return (isNegative && shouldDisplayMinusSign ? '-' : '') + fixedNumberWithoutTrialingZeros + key + content;
    }
}
