import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, PlatformKey } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { BusinessHoursFormComponent } from ':modules/informations/hours-modal/business-hours-form/business-hours-form.component';
import { HoursModalContext } from ':modules/informations/hours-modal/hours-modal.context';
import { OtherHoursFormComponent } from ':modules/informations/hours-modal/other-hours-form/other-hours-form.component';
import { SpecialHoursFormComponent } from ':modules/informations/hours-modal/special-hours-form/special-hours-form.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export type HoursUpdateData = Partial<
    Pick<Restaurant, 'isClosedTemporarily' | 'regularHours' | 'specialHours' | 'otherHours' | 'availableHoursTypeIds'>
>;
export enum HoursModalTabs {
    REGULAR_HOURS,
    SPECIAL_HOURS,
    OTHER_HOURS,
}

@Component({
    selector: 'app-hours-modal',
    templateUrl: './hours-modal.component.html',
    styleUrls: ['./hours-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        TranslateModule,
        BusinessHoursFormComponent,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        OtherHoursFormComponent,
        SpecialHoursFormComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoursModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly HoursModalTabs = HoursModalTabs;

    readonly displayCloseModal = signal<boolean>(false);
    private readonly _heapService = inject(HeapService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly selectedTab = signal<HoursModalTabs>(HoursModalTabs.REGULAR_HOURS);

    readonly hasBeenTouched = this._hoursModalContext.stateHasBeenTouched;
    readonly isClosedTemporarily = this._hoursModalContext.hoursModalState.isClosedTemporarily;

    readonly formErrors = computed<string[]>(() => [
        ...this._hoursModalContext.businessHoursErrors(),
        ...this._hoursModalContext.otherHoursErrors(),
        ...this._hoursModalContext.specialHoursErrors(),
    ]);

    readonly doesFormHaveErrors = computed((): boolean => this.formErrors().length > 0);
    readonly errorText = computed((): string => {
        const formErrors = this.formErrors();
        return formErrors.length > 0 ? formErrors[0] : '';
    });
    readonly shouldDisplayFacebookWarning = computed((): boolean => {
        const restaurant = this._restaurantsService.currentRestaurant;
        const hasFacebookAccess = restaurant?.access.some((access) => access.platformKey === PlatformKey.FACEBOOK && access.active);
        if (!hasFacebookAccess) {
            return false;
        }

        const MAX_PERIODS_PER_DAY_FOR_FACEBOOK = 2;
        const hasTooMuchPeriodsForFacebook = this._hoursModalContext.hoursModalState.businessHours
            .schedules()
            .some((schedule) => schedule.periods.length > MAX_PERIODS_PER_DAY_FOR_FACEBOOK && !schedule.isClosed);
        return hasTooMuchPeriodsForFacebook;
    });

    constructor(
        private readonly _dialogRef: MatDialogRef<HoursModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            selectedTab: HoursModalTabs;
            prefilledStartDate?: Date;
        },
        private readonly _hoursModalContext: HoursModalContext,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.selectedTab.set(this.data.selectedTab ?? HoursModalTabs.REGULAR_HOURS);
        this._hoursModalContext.initState(this.data.restaurant, this.data.prefilledStartDate);
    }

    handleTabChange(event: number): void {
        this.selectedTab.set(event);
        switch (event) {
            case HoursModalTabs.REGULAR_HOURS:
                this._heapService.track(HeapEventName.TRACKING_HOURS_MODAL_CLICK_REGULAR_HOURS_TAB);
                break;
            case HoursModalTabs.SPECIAL_HOURS:
                this._heapService.track(HeapEventName.TRACKING_HOURS_MODAL_CLICK_SPECIAL_HOURS_TAB);
                break;
            case HoursModalTabs.OTHER_HOURS:
                this._heapService.track(HeapEventName.TRACKING_HOURS_MODAL_CLICK_OTHER_HOURS_TAB);
                break;
        }
    }

    save(): void {
        const isClosedTemporarily = this.isClosedTemporarily();
        if (isClosedTemporarily) {
            this.confirmClose({ isClosedTemporarily });
        } else {
            const availableHoursTypeIds = this._hoursModalContext.hoursModalState.otherHours.availableHoursTypes().map((hour) => hour._id);

            const hoursUpdate: HoursUpdateData = {
                isClosedTemporarily,
                availableHoursTypeIds,
            };

            if (this._hoursModalContext.hoursModalState.businessHours.hasBeenTouched()) {
                const regularHours = this._hoursModalContext.getRegularHoursFromBusinessHoursState();
                hoursUpdate.regularHours = regularHours;
            }

            if (this._hoursModalContext.hoursModalState.specialHours.hasBeenTouched()) {
                const specialHours = this._hoursModalContext.getSpecialHoursFromSpecialHoursState();
                hoursUpdate.specialHours = specialHours;
            }

            if (this._hoursModalContext.hoursModalState.otherHours.hasBeenTouched()) {
                const otherHours = this._hoursModalContext.getOtherHoursFromOtherHoursState();
                hoursUpdate.otherHours = otherHours;
            }

            this.confirmClose(hoursUpdate);
        }
    }

    changeOpenStatus(event: MatCheckboxChange): void {
        this._hoursModalContext.updateIsCloseTemporarily(event.checked);
    }

    close(): void {
        if (this.hasBeenTouched()) {
            this.displayCloseModal.set(true);
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: HoursUpdateData): void {
        this._dialogRef.close(data);
    }
}
