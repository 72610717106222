import { createAction, props } from '@ngrx/store';

import { MaintenanceMode } from ':core/maintenance/maintenance.service';
import { InformationUpdateFilters, Restaurant } from ':shared/models';

export const loadUserRestaurants = createAction('[Admin] Load_User_Restaurants');

export const editUserRestaurants = createAction('[Admin] Edit_User_Restaurants', props<{ userRestaurants: Restaurant[] }>());

export const editMaintenanceMode = createAction('[Admin] Edit_Maintenance_Mode', props<{ maintenance: MaintenanceMode }>());

export const loadMaintenance = createAction('[Admin] Load_Maintenance');

export const editUpdatesFilters = createAction('[Admin] Edit_Updates_Filters', props<{ filters: InformationUpdateFilters }>());
