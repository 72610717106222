import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { NotificationCenterComponent } from ':core/components/notification-center/notification-center.component';
import { ChatbotService } from ':core/services/chatbot.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { HeaderComponent } from ':shared/components/header/header.component';

@Component({
    selector: 'app-header-router',
    templateUrl: './header-router.component.html',
    styleUrls: ['./header-router.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, NotificationCenterComponent],
})
export class HeaderRouterComponent {
    constructor(
        private _chatbotService: ChatbotService,
        public notificationCenterContext: NotificationCenterContext,
        public experimentationService: ExperimentationService
    ) {
        this._chatbotService.initChatbot();
    }
}
