import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-star-with-text-chip',
    templateUrl: './star-with-text-chip.component.html',
    styleUrls: ['./star-with-text-chip.component.scss'],
    standalone: true,
    imports: [MatIconModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarWithTextChipComponent {
    // Special case if null is sent, see html
    readonly value = input<number | string | null>(null);

    readonly widthMode = input<'fixed' | 'fit'>('fixed');

    readonly SvgIcon = SvgIcon;
}
