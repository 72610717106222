import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    TestReportConfigurationBodyDto,
    UnsubscribeFromReportBodyDto,
    UnsubscribeFromReportParamsDto,
    UnsubscribeFromReportV2QueryDto,
    UpdateReportDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ApiResult } from ':shared/models';
import { Report } from ':shared/models/report';

@Injectable({ providedIn: 'root' })
export class ReportsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/reports`;

    constructor(private readonly _http: HttpClient) {}

    getReportsForUser(userId: string): Observable<ApiResultV2<Report[]>> {
        return this._http.get<ApiResultV2<Report[]>>(`${this.API_BASE_URL}/${userId}`).pipe(
            map((res) => {
                res.data = res.data.map((report) => new Report(report));
                return res;
            })
        );
    }

    updateReport(report: UpdateReportDto): Observable<ApiResultV2<Report>> {
        return this._http.put<ApiResultV2<Report>>(this.API_BASE_URL, report).pipe(
            map((res) => {
                res.data = new Report(res.data);
                return res;
            })
        );
    }

    getReviewsReportUrl(start: string, end: string, lang: string): Observable<ApiResult<string>> {
        return this._http.get<ApiResult<string>>(`${this.API_BASE_URL}/reviews/pdf?start=${start}&end=${end}&lang=${lang}`);
    }

    sendTestConfiguration(data: TestReportConfigurationBodyDto): Observable<ApiResultV2<void>> {
        return this._http.post<ApiResultV2<void>>(`${this.API_BASE_URL}/test`, data);
    }

    unsubscribeReport({
        email,
        reportId,
        hash,
        index,
    }: UnsubscribeFromReportParamsDto & UnsubscribeFromReportBodyDto): Observable<ApiResultV2<void>> {
        return this._http.post<ApiResultV2<void>>(`${this.API_BASE_URL}/${reportId}/unsubscribe?report_id=${reportId}`, {
            email,
            hash,
            index,
        });
    }

    unsubscribeReportV2({ hash }: UnsubscribeFromReportV2QueryDto): Observable<ApiResultV2<void>> {
        return this._http.get<ApiResult<void>>(`${this.API_BASE_URL}/unsubscribe_v2?hash=${hash}`);
    }

    fillUserConfigurations(userId: string, restaurantsIds: string[]): Observable<ApiResultV2<void>> {
        return this._http.put<ApiResultV2<void>>(`${this.API_BASE_URL}/${userId}/fill_configurations`, { userId, restaurantsIds });
    }
}
