<div class="malou-dialog malou-dialog-mobile flex h-full flex-col">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex w-full items-center justify-between">
            <div class="malou-color-text-1 malou-text-18--semibold">
                {{ 'wheel_of_fortune.subscription_request_modal.title' | translate }}
            </div>
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>
    </div>

    <div class="malou-dialog__body malou-color-text-2 malou-text-12--regular !m-0 flex flex-col gap-y-3 !overflow-y-hidden">
        <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.feature_part_of_booster_pack' | translate"></div>

        <img class="w-full" [src]="'boosterPack' | imagePathResolver: { folder: 'wheels-of-fortune' }" />

        <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.gain_reviews_and_subscribers' | translate"></div>
        <ul class="flex flex-col gap-y-1">
            <li class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.nfc_items' | translate"></div>
            </li>
            <li class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.stickers' | translate"></div>
            </li>
            <li class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.wheel_of_fortune' | translate"></div>
            </li>
            <li class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.personalized_advices' | translate"></div>
            </li>
        </ul>

        <div class="flex items-center rounded-xl bg-malou-color-background-dark p-4">
            <div class="ml-4 mr-6 text-2xl">🚀</div>
            <div
                class="malou-color-text-1"
                [innerHTML]="'wheel_of_fortune.subscription_request_modal.get_3_to_6_times_more_reviews' | translate"></div>
        </div>
    </div>

    <div class="malou-dialog__footer !justify-end">
        @if (!requestSent()) {
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button [disabled]="sendingRequest()" (click)="close()">
                {{ 'common.later' | translate }}
            </button>
        }
        <div [matTooltip]="requestSent() ? ('wheel_of_fortune.subscription_request_modal.request_sent' | translate) : ''">
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                id="tracking_aggregated_wof_get_more_informations"
                mat-raised-button
                [disabled]="requestSent() || sendingRequest()"
                (click)="sendRequest()">
                {{ 'wheel_of_fortune.subscription_request_modal.contact_me' | translate }}
            </button>
        </div>
    </div>
</div>
