import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SuggestionsResponseDto } from '@malou-io/package-dto';

import { environment } from ':environments/environment';
import * as SuggestionsActions from ':modules/informations/information-suggestions-modal/store/suggestions.actions';
import { defaultSuggestionState } from ':modules/informations/information-suggestions-modal/store/suggestions.reducer';
import { ApiResult, Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SuggestionsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/suggestions`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store
    ) {}

    emitShouldFetchSuggestions(restaurant: Restaurant, resetToInitialState = false): void {
        const newSuggestion = {
            ...(resetToInitialState && { ...defaultSuggestionState }),
            restaurant,
        };
        this._store.dispatch({ type: SuggestionsActions.editInformationSuggestions.type, suggestion: newSuggestion });
    }

    getSuggestions(restaurantId: string): Observable<ApiResult<SuggestionsResponseDto>> {
        return this._http.get<ApiResult<SuggestionsResponseDto>>(`${this.API_BASE_URL}/${restaurantId}`);
    }
}
