<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[90vh]': !displayCloseModal() || (displayCloseModal() && isPhoneScreen()),
        'h-[350px]': displayCloseModal() && !isPhoneScreen(),
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)">
    </app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog h-full w-full !flex-row">
        <div class="flex w-[63%] flex-col justify-between">
            <ng-container *ngTemplateOutlet="settingsTemplate"></ng-container>
        </div>
        <div class="flex w-[37%] flex-col justify-between">
            <ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #settingsTemplate>
    <div class="malou-dialog__header !py-5">
        <div class="title">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'restaurant_ai_settings.modals.upsert.title' | translate }}
            </div>
        </div>
    </div>

    <div class="malou-dialog__body h-[75%] md:!overflow-y-scroll">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>

    <div class="malou-dialog__footer">
        <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button [disabled]="isSubmitting()" (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11 md:grow"
            id="tracking_restaurant_ai_settings_edit_validation_button"
            data-testid="general-ai-settings-save-btn"
            mat-raised-button
            [disabled]="isSubmitting() || !(isFormValid$ | async)"
            (click)="submit()">
            @if (isSubmitting()) {
                <app-malou-spinner class="flex w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
            } @else {
                {{ 'common.save' | translate }}
            }
        </button>
    </div>
</ng-template>

<ng-template #previewTemplate>
    <div class="h-full bg-malou-color-background-light">
        <div class="malou-dialog__header !p-4 !pl-5">
            <div class="title">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'restaurant_ai_settings.modals.upsert.preview' | translate }}
                </div>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body h-full max-h-[70vh] overflow-y-auto !pl-5 pb-3">
            <form [formGroup]="previewReviewReplyForm">
                <div class="mb-3">
                    <app-select
                        class="w-full"
                        formControlName="withComment"
                        [values]="AVAILABLE_COMMENT_OPTIONS"
                        [displayWith]="displayOption"
                        (selectChange)="onWithCommentChange($event)"></app-select>
                </div>
                <app-select-languages
                    class="min-w-fit flex-1"
                    formControlName="chosenLanguageForPreview"
                    [selectedValues]="[aiSettingsContext.restaurantAiSettings()!.defaultLanguageResponse]"
                    [title]="'ai.general.language' | translate"
                    [values]="APP_LANGUAGES"
                    [multiSelection]="false">
                </app-select-languages>
            </form>

            <div class="malou-card__header mb-2 mt-2 flex flex-col px-4 !pb-0">
                <div class="flex w-full items-center">
                    <div class="malou-card__image-container !mr-0 mt-2 flex">
                        <img class="malou-avatar--medium !rounded" alt="avatar" [src]="'default_avatar_ai_preview' | imagePathResolver" />
                    </div>
                    <div class="ml-2 flex w-[30%] grow flex-col justify-center">
                        <div class="malou-text-12--bold truncate">
                            {{ 'restaurant_ai_settings.modals.upsert.reviewer_name' | translate }}
                        </div>
                        <div class="malou-text__card-subtitle">{{ currentDate() }}</div>
                    </div>
                    <div class="malou-card__action-button">
                        <app-star-gauge class="md:!hidden" [stars]="5"></app-star-gauge>
                    </div>
                </div>
            </div>
            @if (previewReviewReplyForm.get('withComment')?.value?.value === CommentOptionValue.WITH) {
                <div class="malou-text-12--regular malou-color-text-2 px-4">
                    {{ 'restaurant_ai_settings.modals.upsert.example_preview' | translate }}
                </div>
            }
            <div class="mt-2 flex justify-end">
                <button
                    class="malou-btn-raised--secondary--alt !h-10 !text-malou-color-chart-purple--accent"
                    mat-raised-button
                    (click)="generateResponsePreview()">
                    <mat-icon class="mb-1 mr-1 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                    <span class="text-malou-color-chart-purple--accent">
                        @if (!previewReviewReply()) {
                            <span>{{ 'restaurant_ai_settings.modals.upsert.generate' | translate }}</span>
                        } @else {
                            <span>{{ 'restaurant_ai_settings.modals.upsert.retry' | translate }}</span>
                        }
                    </span>
                </button>
            </div>
            @if (isLoadingPreview()) {
                <div class="flex h-28 items-center justify-center">
                    <app-malou-spinner class="flex w-[93px] justify-center" size="small"></app-malou-spinner>
                </div>
            } @else if (previewReviewReply()) {
                <div class="mt-4 flex flex-col gap-[1px]">
                    <div class="malou-text-13--semibold malou-chip--purple flex w-full justify-between rounded-t-md p-3 !pb-2">
                        <div class="flex items-center">
                            <mat-icon class="mb-1 mr-1 h-4 !w-4" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                            <span>{{ 'restaurant_ai_settings.modals.upsert.proposal' | translate }}</span>
                        </div>
                        <div class="flex items-center gap-2">
                            <label class="malou-text-10--semibold malou-color-text-2">{{
                                'restaurant_ai_settings.modals.upsert.keyword_score' | translate
                            }}</label>
                            <app-keywords-score-gauge
                                [text$]="previewReviewReply$"
                                [restaurant$]="restaurant$"
                                [textType$]="textType$"
                                [lang$]="lang$"
                                [shouldCacheScore]="false"
                                [withLargeDetails]="false"
                                [keywords$]="keywords$"
                                [shouldDisplayKeywords]="false"
                                [shouldDisplayKeywordsCategory]="false"
                                [shouldDisplayTips]="false"
                                [reviewerName$]="reviewerName$"
                                [responseTime$]="oneHour$"
                                [restaurantAiSettings$]="aiReviewSettingsForm$"
                                [shouldOnlyDisplayScore]="true"
                                [shouldShowTipsInTooltip]="true"
                                #keywordsScoreGauge></app-keywords-score-gauge>
                        </div>
                    </div>
                    <div
                        class="malou-text-12--regular malou-chip--purple rounded-b-md p-3 !pt-2 !text-malou-color-text-2"
                        [innerHTML]="formattedPreviewReviewReply()"></div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #formTemplate>
    <mat-tab-group class="h-full" animationDuration="200" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)">
        <mat-tab [label]="'restaurant_ai_settings.modals.upsert.tabs.general.title' | translate">
            <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                <app-ai-general-review-settings-modal-tab
                    [aiReviewSettingsForm]="aiReviewSettingsForm"></app-ai-general-review-settings-modal-tab>
            </div>
        </mat-tab>
        <mat-tab [label]="'restaurant_ai_settings.modals.upsert.tabs.advanced.title' | translate">
            <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                @if (aiReviewSettingsForm) {
                    <app-ai-advanced-review-settings-modal-tab
                        [aiReviewSettingsForm]="aiReviewSettingsForm"
                        [selectedKeywords]="selectedKeywords()"
                        (close)="confirmClose()"></app-ai-advanced-review-settings-modal-tab>
                }
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>
