import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { CallToActionType, SeoPostTopic } from '@malou-io/package-utils';

import { UserState } from ':modules/user/store/user.reducer';
import { Media } from ':shared/models';

export enum DialogStep {
    ONE,
    TWO,
}

export interface AppState {
    user: UserState;
}

export interface DateFormGroup {
    postDate: Date;
    postTime: string;
    postDateStatus: PostDateStatus;
}

export enum PostDateStatus {
    NOW,
    LATER,
    DRAFT,
}

export enum AiButton {
    GENERATE = 'GENERATE',
    RETRY = 'RETRY',
}

export type PostForm = FormGroup<{
    post: FormGroup<{
        event: FormGroup<{
            title: FormControl<string | null>;
            startDate: FormControl<Date | null>;
            endDate: FormControl<Date | null>;
        }>;
        language: FormControl<string>;
        attachments: FormControl<Media[]>;
        attachmentsName: FormControl<string>;
        plannedPublicationDate: FormControl<Date>;
        postTopic: FormControl<{ type: SeoPostTopic; text: string }>;
        offer: FormGroup<{
            couponCode: FormControl<string>;
            onlineUrl: FormControl<string>;
            termsConditions: FormControl<string>;
        }>;
        callToAction: FormGroup<{
            actionType: FormControl<{ type: CallToActionType; text: string }>;
            url: FormControl<string | null>;
        }>;
        text: FormControl<string>;
    }>;
    date: FormGroup<{
        postDateStatus: FormControl<PostDateStatus>;
        postDate: FormControl<Date>;
        postTime: FormControl<string>;
    }>;
    keys: FormArray<FormControl<string>>;
}>;
