import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, uniq } from 'lodash';

import {
    ReviewReplyAutomationComment,
    ReviewReplyAutomationMethod,
    ReviewReplyAutomationRatingCategory,
    TemplateStatus,
    TemplateTag,
    TemplateType,
} from '@malou-io/package-utils';

import { User } from ':modules/user/user';

import { Restaurant } from '.';
import { CommentOptionValue } from '../enums/with-comment.enum';
import { IDisplayable } from '../interfaces';
import { ReviewReplyAutomation } from './automations';

export const getTemplateVariablesAndTranslations = (_translate: TranslateService): { dbVariable: string; translate: string }[] => [
    { dbVariable: TemplateTag.BUSINESS_NAME, translate: _translate.instant('templates.create.business_name') },
    { dbVariable: TemplateTag.MY_FIRSTNAME, translate: _translate.instant('templates.create.user_name') },
    { dbVariable: TemplateTag.CLIENT_NAME, translate: _translate.instant('templates.create.client_name') },
    { dbVariable: TemplateTag.PHONE, translate: _translate.instant('templates.create.phone') },
    { dbVariable: TemplateTag.ADDRESS, translate: _translate.instant('templates.create.address') },
    { dbVariable: TemplateTag.MENU_LINK, translate: _translate.instant('templates.create.menu_link') },
    { dbVariable: TemplateTag.WEBSITE, translate: _translate.instant('templates.create.website') },
    { dbVariable: TemplateTag.REGULAR_HOURS, translate: _translate.instant('templates.create.regular_hours') },
];

const defaultAutomationConfig = { automated: false, ratings: [], withComment: CommentOptionValue.WITH_OR_WITHOUT };

export class MediumTemplate {
    _id: string;
    category: string;
    name?: string | null;
    text: string;
    rating: number[];
    language: string;
    withComment: CommentOptionValue;
    type: TemplateType;
    status: TemplateStatus;
    duplicatedFromRestaurant: Restaurant;
    duplicatedByUser: User;

    automationConfig: {
        automated: boolean;
        ratings: number[];
        withComment: CommentOptionValue;
    } = cloneDeep(defaultAutomationConfig);

    constructor(init: Partial<MediumTemplate>) {
        Object.assign(this, init);
    }

    setAutomationConfig(automations: ReviewReplyAutomation[]): void {
        const templateAutomations = automations.filter(
            (automation) =>
                automation.active &&
                automation.replyMethod === ReviewReplyAutomationMethod.TEMPLATES &&
                automation.templateConfigs?.some((config) => config.template === this._id)
        );
        if (templateAutomations.length) {
            this.automationConfig.automated = true;

            this.automationConfig.ratings = uniq(
                templateAutomations.map((automation) => this._mapAutomationRatingCategoryToRating(automation.ratingCategory)).flat()
            );

            this.automationConfig.withComment = this._mergeCommentOptionValues(
                templateAutomations.map((automation) => this._mapAutomationWithCommentToCommentOptionValue(automation.withComment)).flat()
            );
        }
    }

    setStatus(status: TemplateStatus): void {
        this.status = status;
    }

    resetAutomationConfig(): void {
        this.automationConfig = cloneDeep(defaultAutomationConfig);
    }

    private _mapAutomationRatingCategoryToRating(automationRatingCategory: ReviewReplyAutomationRatingCategory): number[] {
        switch (automationRatingCategory) {
            case ReviewReplyAutomationRatingCategory.REVIEW_1_2:
                return [1, 2];
            case ReviewReplyAutomationRatingCategory.REVIEW_3:
                return [3];
            case ReviewReplyAutomationRatingCategory.REVIEW_4:
                return [4];
            case ReviewReplyAutomationRatingCategory.REVIEW_5:
                return [5];
            default:
                return [];
        }
    }

    private _mapAutomationWithCommentToCommentOptionValue(automationComment: ReviewReplyAutomationComment): CommentOptionValue[] {
        switch (automationComment) {
            case ReviewReplyAutomationComment.WITH_COMMENT:
                return [CommentOptionValue.WITH];
            case ReviewReplyAutomationComment.WITHOUT_COMMENT:
                return [CommentOptionValue.WITHOUT];
            default:
                return [CommentOptionValue.WITH_OR_WITHOUT];
        }
    }

    private _mergeCommentOptionValues(commentOptionValues: CommentOptionValue[]): CommentOptionValue {
        if (commentOptionValues.includes(CommentOptionValue.WITH) && commentOptionValues.includes(CommentOptionValue.WITHOUT)) {
            return CommentOptionValue.WITH_OR_WITHOUT;
        } else if (commentOptionValues.includes(CommentOptionValue.WITHOUT)) {
            return CommentOptionValue.WITHOUT;
        } else if (commentOptionValues.includes(CommentOptionValue.WITH)) {
            return CommentOptionValue.WITH;
        }
        return CommentOptionValue.WITH_OR_WITHOUT;
    }
}

export class Template extends MediumTemplate {
    restaurantId?: string;
    createdAt?: Date;
    updatedAt?: Date;
    user?: User;

    public constructor(init: Partial<Template>) {
        super(init);
    }
}

export class CoreTemplate implements IDisplayable {
    _id: string;
    displayedTagFr: string;
    templateId: string;
    tone: string;
    angle: string;
    ratings: string;
    language: string;
    text: string;
    spot1: string;
    spot2: string;
    spot3: string;
    spot4: string;

    public constructor(init?: Partial<CoreTemplate>) {
        Object.assign(this, init);
    }

    getDisplayedValue(): string {
        return this.displayedTagFr;
    }
}
export class CoreTemplateSpot {
    _id: string;
    templateId: string;
    spot: string;
    spotType: string;
    gender: string;
    number: string;
    nature: string;
    startsWithVowel: boolean;
    preSpot: string;

    public constructor(init?: Partial<CoreTemplateSpot>) {
        Object.assign(this, init);
    }
}
