import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { AggregatedInformationUpdateProviderKey, YextPublisherId } from '@malou-io/package-utils';

import { InformationUpdateState } from ':modules/informations/informations-updates-state/information-update-state.interface';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { YextPlatformLogoComponent } from ':shared/components/yext-platform-logo/yext-platform-logo.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-information-update-others-list',
    templateUrl: './information-update-others-list.component.html',
    styleUrls: ['./information-update-others-list.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        TranslateModule,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        PlatformLogoComponent,
        YextPlatformLogoComponent,
        ApplyPurePipe,
        PluralTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateOthersListComponent {
    readonly nbSucceedUpdates = input.required<number>();
    readonly informationUpdateOthers = input.required<InformationUpdateState[]>();
    readonly isAboutToSendUpdates = input.required<boolean>();

    readonly sort = viewChild<MatSort>('otherUpdatesSort');

    readonly otherUpdatesSource = new MatTableDataSource<InformationUpdateState>([]);
    readonly otherDisplayedColumns: string[] = ['platformKey', 'status', 'details'];

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    constructor() {
        effect(() => {
            const sort = this.sort();
            if (sort) {
                this.otherUpdatesSource.sort = sort;
            }
        });

        effect(
            () => {
                this.otherUpdatesSource.data = this.informationUpdateOthers();
            },
            { allowSignalWrites: true }
        );
    }

    isYextKey(key: AggregatedInformationUpdateProviderKey): key is YextPublisherId {
        return InformationUpdateState.isYextKey(key);
    }
}
