import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-no-connected-platforms',
    templateUrl: './no-connected-platforms.component.html',
    styleUrls: ['./no-connected-platforms.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoConnectedPlatformsComponent {
    readonly illustration = input<Illustration>(Illustration.Goggles);
    readonly message = input.required<string>();

    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);

    goToPlatforms(): void {
        this._router.navigate([`/restaurants/${this._restaurantsService.restaurantSelected$.value?._id}/settings/platforms/connection`]);
    }
}
