@if (post.postStatus; as status) {
    <div
        class="malou-card !mx-0 h-full"
        [ngClass]="{
            'selected-post': isChecked,
            'malou-border-error': status.type === ExtendedPostPublicationStatus.ERROR,
            'cursor-pointer': true,
        }">
        <ng-container [ngTemplateOutlet]="singlepost" [ngTemplateOutletContext]="{ status: status }"></ng-container>
    </div>
}

<ng-template let-status="status" #singlepost>
    <div class="malou-card__header !pb-3">
        <app-noop-mat-checkbox [checked]="isChecked" (click)="toggleCheckPost()"></app-noop-mat-checkbox>

        <div class="ml-3 max-w-[50%]">
            <div class="malou-text-12--semibold align-center flex max-w-fit truncate">
                <span class="malou-text--semi-bold">
                    {{ 'social_posts.' + status.type | translate }}
                </span>
                <mat-icon
                    class="ml-1 !h-4 !w-4"
                    [ngStyle]="{ color: status.iconColor }"
                    [matTooltip]="errorMessage ?? ''"
                    [svgIcon]="status.icon"></mat-icon>
            </div>
            @if (status.subtext) {
                <div class="malou-text-10 italic text-malou-color-text-2">
                    {{ status.subtext }}
                </div>
            }
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl !ml-auto"
            mat-icon-button
            [matMenuTriggerFor]="actionsMenu"
            (click)="emitEmptySelectedPosts(); $event.stopPropagation()">
            <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
        </button>
    </div>
    <div class="malou-card__body flex grow-2 flex-col items-center">
        <div class="flex w-full">
            @if (isGmbConnected$ | async) {
                <div class="flex w-full" (dblclick)="openSocialLink(post)">
                    <img
                        class="max-h-[150px] w-full rounded-lg object-cover"
                        alt="Image du post"
                        [defaultImage]="'default-picture-grey' | imagePathResolver"
                        [lazyLoad]="(post | applySelfPure: 'getSocialMediaUrl') || (post | applySelfPure: 'getMalouMediaUrl')"
                        (onStateChange)="refreshPostIfFailed($event)" />
                </div>
            } @else {
                <div class="flex w-full">
                    <img
                        class="max-h-[150px] w-full rounded-lg object-cover"
                        alt="Image du post"
                        [defaultImage]="'default-picture-grey' | imagePathResolver"
                        [lazyLoad]="(post | applySelfPure: 'getSocialMediaUrl') || (post | applySelfPure: 'getMalouMediaUrl')"
                        (onStateChange)="refreshPostIfFailed($event)" />
                </div>
            }
        </div>
        <div class="flex w-full grow-2 flex-col items-start">
            <app-single-post-legend [text]="post.text" (toggledTextSize)="emitEmptySelectedPosts()"></app-single-post-legend>
            @if (showFeedbackBadge) {
                <p class="malou-text-10--semibold malou-color-state-warn w-full cursor-pointer" (click)="openPost()">
                    {{ 'feedbacks.feedback_not_completed' | translate }}
                </p>
            }
        </div>
        <mat-divider class="malou-border-color-dark !mb-3 w-full"></mat-divider>
        <div class="flex w-full items-center justify-start">
            <app-keywords-score-gauge
                [text$]="postText$"
                [restaurant$]="restaurant$"
                [textType$]="gaugeTextType$"
                [keywords$]="restaurantKeywords$"
                [shouldOnlyDisplayScore]="true"
                [lang$]="postLang$">
            </app-keywords-score-gauge>
        </div>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
    @if (post.postStatus.type !== ExtendedPostPublicationStatus.REJECTED) {
        <button class="flex gap-x-3" mat-menu-item (click)="openPost()">
            <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
            <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
        </button>
    }
    <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateMenu">
        <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        <span class="malou-text-14--regular">{{ 'common.duplicate' | translate }}</span>
    </button>
    <button class="flex gap-x-3" mat-menu-item (click)="deletePost()">
        <mat-icon class="!h-4 w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
    </button>
    @if (post.socialLink) {
        <a class="flex gap-x-3" mat-menu-item target="_blank" [href]="post.socialLink">
            <mat-icon class="!h-4 w-4" color="primary" [svgIcon]="SvgIcon.LAPTOP"></mat-icon>
            <span class="malou-text-14--regular">{{ 'posts.open_platform' | translate }}</span>
        </a>
    }
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
    <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, platformsKeysWithSeoPost)">
        <span class="malou-text-14--regular">
            {{ 'common.here' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, platformsKeysWithSocialPost)">
        <span class="malou-text-14--regular">
            {{ 'posts.duplicate_in_social' | translate }}
        </span>
    </button>
    <div [matTooltip]="duplicationDisabledTooltipText()" [matTooltipDisabled]="!isOutDuplicationDisabled()">
        <button
            mat-menu-item
            [disabled]="isOutDuplicationDisabled()"
            (click)="duplicatePost(DuplicationDestination.OUT, platformsKeysWithSeoPost)">
            <span class="malou-text-14--regular">
                {{ 'common.to_other_venues' | translate }}
            </span>
        </button>
    </div>
</mat-menu>
