import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PostDatePickerSize } from ':modules/posts-v2/post-date-picker/post-date-picker.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';

@Component({
    selector: 'app-post-date-picker',
    templateUrl: './post-date-picker.component.html',
    styleUrls: ['./post-date-picker.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, FormatDatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostDatePickerComponent {
    readonly selectedDate = input<Date | null>(null);
    readonly disabled = input<boolean>(false);
    readonly size = input<PostDatePickerSize>(PostDatePickerSize.SMALL);
    readonly withBorder = input<boolean>(false);

    readonly SvgIcon = SvgIcon;
    readonly PostDatePickerSize = PostDatePickerSize;
}
