<app-media-file-uploader
    [accept]="accept"
    [multiple]="true"
    [maxVideoSize]="maxVideoSize"
    [maxImageSize]="maxImageSize"
    (finishUpload)="onFinishUpload($event)"
    (startReadingFile)="onStartReadingFile($event)"
    (processError)="onProcessError($event)"
    #mediaUploader>
    <div (click)="$event.stopPropagation()">
        <button class="malou-btn-flat flex items-center gap-x-1" [matMenuTriggerFor]="uploadFromMenu">
            <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
            <span>
                {{ 'common.add_media' | translate }}
            </span>
        </button>
        <mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #uploadFromMenu="matMenu">
            <button mat-menu-item (click)="mediaUploader.openFilePicker()">
                <span class="malou-text-12--regular text-malou-color-text-2 md:hidden">{{
                    'common.upload_from_computer' | translate
                }}</span>
                <span class="malou-text-12--regular hidden text-malou-color-text-2 md:block">{{
                    'common.upload_from_phone' | translate
                }}</span>
            </button>
            <button mat-menu-item (click)="openMediaPickerModal()">
                <span class="malou-text-12--regular text-malou-color-text-2">{{ 'common.upload_from_gallery' | translate }}</span>
            </button>
        </mat-menu>
    </div>
</app-media-file-uploader>
