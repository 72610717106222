<div
    class="mb-3 flex flex-col border-malou-color-background-dark px-7 py-3"
    [class.bg-malou-color-background-light]="notification() !== MalouNotification.ROI_SETTINGS_UPDATED"
    [class.border-b]="notification() === MalouNotification.ROI_SETTINGS_UPDATED">
    <span class="malou-text-9 italic text-malou-color-text-2--light">
        {{ 'notifications.at' | translate: { date: receptionDate() | date: 'dd.MM.yyyy', time: receptionDate() | date: 'HH:mm' } }}
    </span>
    <span class="malou-text-13--bold mb-4 text-malou-color-text-1">
        {{ notificationTranslationsKey().title | translate }}
    </span>
    <span class="malou-text-10--regular mb-4 text-malou-color-text-2">
        {{ notificationTranslationsKey().subTitle | translate }}
    </span>
    @switch (notification()) {
        @case (MalouNotification.FINISH_RESTAURANT_SETUP) {
            <button class="malou-btn-raised--primary !h-6 !max-w-fit !rounded-md" mat-raised-button (click)="onActionClick()">
                {{ 'common.fill' | translate }}
            </button>
        }
        @case (MalouNotification.ROI_SETTINGS_UPDATED) {
            <button class="malou-btn-raised--secondary !h-6 !max-w-fit !rounded-md" mat-raised-button (click)="onActionClick()">
                {{ 'common.see' | translate }}
            </button>
        }
    }
</div>
