export class ImageProperties {
    width: number;
    height: number;
    bytes: number;

    constructor({ width, height, bytes }: { width: number; height: number; bytes: number }) {
        this.width = width;
        this.height = height;
        this.bytes = bytes;
    }

    isTooSmallSizeImage(minimumBytes = 10_000): boolean {
        return !!this.bytes && this.bytes < minimumBytes;
    }

    isTooSmallDimensionsImage(minimumWidthInPx = 250, minimumHeightInPx = 250): boolean {
        const isTooSmallWidth = !!this.width && this.width < minimumWidthInPx;
        const isTooSmallHeight = !!this.height && this.height < minimumHeightInPx;
        return isTooSmallWidth || isTooSmallHeight;
    }
}
