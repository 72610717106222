import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, of, switchMap } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';

import { CreateWheelOfFortuneCardComponent } from '../wheels-of-fortune/create-wheel-of-fortune-card/create-wheel-of-fortune-card.component';
import { WheelOfFortuneDataCardComponent } from '../wheels-of-fortune/wheel-of-fortune-data-card/wheel-of-fortune-data-card.component';
import { WheelsOfFortuneService } from '../wheels-of-fortune/wheels-of-fortune.service';

@Component({
    selector: 'app-wheels-of-fortune-root',
    templateUrl: './wheels-of-fortune-root.component.html',
    styleUrls: ['./wheels-of-fortune-root.component.scss'],
    imports: [NgTemplateOutlet, CreateWheelOfFortuneCardComponent, WheelOfFortuneDataCardComponent, AsyncPipe],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelsOfFortuneRootComponent {
    private readonly _currentRestaurant$ = this._restaurantsService.restaurantSelected$;
    readonly hasBoosterPackActivated = toSignal(
        this._currentRestaurant$.pipe(
            filter(Boolean),
            map((restaurant) => restaurant.boosterPack?.activated)
        ),
        { initialValue: false }
    );

    readonly shouldReload$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly restaurantsIdsWithoutWheels$ = combineLatest([this._currentRestaurant$, this.shouldReload$]).pipe(
        map(([restaurant]) => restaurant),
        filter(Boolean),
        switchMap((restaurant) => forkJoin([of(restaurant), this._wheelsOfFortuneService.getRestaurantIdsWithActiveWheel()])),
        map(([restaurant, result]) => {
            const isRestaurantInActiveAggregatedWheel = result.restaurantsWithActiveAggregatedWheel.includes(restaurant.id);
            return isRestaurantInActiveAggregatedWheel ? [] : [restaurant.id];
        })
    );
    readonly restaurantsIdsWithoutWheels = toSignal(this.restaurantsIdsWithoutWheels$, { initialValue: [] });

    readonly activeRestaurantWheelOfFortune$ = combineLatest([this._currentRestaurant$, this.shouldReload$]).pipe(
        map(([restaurant]) => restaurant),
        filter(Boolean),
        switchMap((restaurant) => this._wheelsOfFortuneService.getActiveRestaurantWheelOfFortune(restaurant.id)),
        map((result) => (result.data ? new WheelOfFortune(result.data) : null))
    );
    readonly activeRestaurantWheelOfFortune = toSignal(this.activeRestaurantWheelOfFortune$, { initialValue: null });

    readonly activeAggregatedWheelOfFortune$ = combineLatest([this._currentRestaurant$, this.shouldReload$]).pipe(
        map(([restaurant]) => restaurant),
        filter(Boolean),
        switchMap((restaurant) => this._wheelsOfFortuneService.getActiveWheel(restaurant.id)),
        map((result) => (result.data ? new WheelOfFortune(result.data) : null))
    );
    readonly activeAggregatedWheelOfFortune = toSignal(this.activeAggregatedWheelOfFortune$, { initialValue: null });

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.shouldReload$.next(true);
    }

    onReload(): void {
        this.shouldReload$.next(true);
    }
}
