import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, Observable, of, switchMap, take } from 'rxjs';

import { SaveInformationUpdateDataBodyDto } from '@malou-io/package-dto';

import { getPreviousDataBodyDtoFromRestaurant } from ':shared/helpers/information-updates.helper';
import { Restaurant } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

import { InformationUpdatesService } from './information-update.service';
import { RestaurantsService } from './restaurants.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class InformationService {
    readonly informationTranslate = this._translateService.instant('information');

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _translateService: TranslateService
    ) {}

    updateRestaurant$(restaurant: Restaurant, update: Partial<Restaurant>): Observable<Restaurant | null> {
        return forkJoin([
            this._restaurantsService.restaurantSelected$.pipe(take(1)),
            this._saveInformationUpdatesData$(restaurant, update),
        ]).pipe(
            switchMap(([selectedRestaurant, _]) =>
                forkJoin([this._restaurantsService.update(restaurant._id, update), of(selectedRestaurant)])
            ),
            switchMap(([updateRes, selectedRestaurant]) => {
                if (restaurant._id === selectedRestaurant?._id) {
                    this._restaurantsService.setSelectedRestaurant(updateRes.data);
                }
                return of(updateRes.data);
            }),
            catchError((error) => {
                console.warn(error);
                if (error?.error?.status === 400) {
                    this._toastService.openErrorToast(this.informationTranslate.detected_error + error?.error?.message);
                } else if (error?.error?.message?.match(/not_found/)) {
                    this._toastService.openErrorToast(this.informationTranslate.user_not_authorized);
                } else if (
                    error?.error?.message?.includes('not valid for this location') ||
                    error?.error?.message?.includes('Invalid attribute_id provided')
                ) {
                    this._toastService.openErrorToast(this.informationTranslate.user_not_authorized);
                } else {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
                }
                return of(null);
            })
        );
    }

    private _saveInformationUpdatesData$(restaurant: Restaurant, update: Partial<Restaurant>): Observable<void> {
        const body: SaveInformationUpdateDataBodyDto = {
            restaurantId: restaurant._id,
            data: getPreviousDataBodyDtoFromRestaurant(update),
            previousData: getPreviousDataBodyDtoFromRestaurant(restaurant),
        };

        return this._informationUpdatesService.saveInformationUpdateData(body);
    }
}
