import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { AggregationTimeScale, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { InsightsService } from ':modules/statistics/insights.service';
import { PostsComponent } from ':modules/statistics/social-networks/posts-insights-table/posts/posts.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { getStatisticsError, isDateSetOrGenericPeriod } from ':shared/helpers';
import {
    DatesAndPeriod,
    DiffCombinedSocialPageInsights,
    InsightsByPlatformByRestaurant,
    Restaurant,
    SocialPlatformsInsights,
} from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { PlatformFilterPage } from '../../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';

enum InsightsTableColumn {
    RESTAURANT_NAME = 'restaurantName',
    FOLLOWERS = 'followers',
    IMPRESSIONS = 'impressions',
    ENGAGEMENTS = 'engagements',
    POSTS = 'posts',
}

const SOCIAL_METRICS = [MalouMetric.FOLLOWERS, MalouMetric.ENGAGEMENTS, MalouMetric.IMPRESSIONS, MalouMetric.POSTS];

@Component({
    selector: 'app-aggregated-posts-insights-table',
    templateUrl: './aggregated-posts-insights-table.component.html',
    styleUrls: ['./aggregated-posts-insights-table.component.scss'],
    standalone: true,
    imports: [
        SortByFiltersComponent,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        NgClass,
        RouterLink,
        NgTemplateOutlet,
        SkeletonComponent,
        AsyncPipe,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplySelfPurePipe,
        PostsComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class AggregatedPostsInsightsTableComponent implements OnInit {
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Input() hideRestaurantWithErrors = false;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    isLoading$ = new BehaviorSubject(true);
    socialNetworksPlatforms: PlatformKey[];
    dataSource = new MatTableDataSource<DiffCombinedSocialPageInsights>();
    insights$: Observable<ValueError<DiffCombinedSocialPageInsights[], string>>;
    readonly DISPLAYED_COLUMNS: InsightsTableColumn[] = Object.values(InsightsTableColumn);

    readonly SORT_OPTIONS: FilterOption[] = [
        {
            key: InsightsTableColumn.RESTAURANT_NAME,
            label: this._translateService.instant('aggregated_statistics.social_networks.business'),
        },
        {
            key: InsightsTableColumn.FOLLOWERS,
            label: this._translateService.instant('aggregated_statistics.social_networks.followers'),
        },
        {
            key: InsightsTableColumn.IMPRESSIONS,
            label: this._translateService.instant('aggregated_statistics.social_networks.impressions'),
        },
        {
            key: InsightsTableColumn.ENGAGEMENTS,
            label: this._translateService.instant('aggregated_statistics.social_networks.engagement_rate'),
        },
        {
            key: InsightsTableColumn.POSTS,
            label: this._translateService.instant('aggregated_statistics.social_networks.posts'),
        },
    ];
    sortOrder: SortDirection = 'asc';
    sortKey: string;

    constructor(
        private readonly _store: Store,
        private readonly _translateService: TranslateService,
        private readonly _insightsService: InsightsService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        this.isLoading$.subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                const { active, direction } = sort;
                this.tableSortOptionsChange.emit({ active, direction });
                switch (property) {
                    case InsightsTableColumn.FOLLOWERS:
                        return item.current?.followers ?? 0;
                    case InsightsTableColumn.IMPRESSIONS:
                        return item.current?.impressions ?? 0;
                    case InsightsTableColumn.ENGAGEMENTS:
                        return item.current?.engagements ?? 0;
                    case InsightsTableColumn.POSTS:
                        return item.current?.posts ?? 0;
                    default:
                        return item[property];
                }
            };
            this.dataSource.sort = sort;

            if (this.tableSortOptions) {
                this.dataSource.sort?.sort({
                    id: this.tableSortOptions.active,
                    start: this.tableSortOptions.direction,
                    disableClear: false,
                });
            } else {
                this.dataSource.sort?.sort({
                    id: this.dataSource.sort.active || InsightsTableColumn.RESTAURANT_NAME,
                    start: this.sortOrder,
                    disableClear: false,
                });
            }
        }
    }

    ngOnInit(): void {
        this.insights$ = this._getInsights$();
    }

    onSortOrderChange(): void {
        this.sortOrder = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({
            id: this.dataSource.sort.active,
            start: this.sortOrder,
            disableClear: false,
        });
    }

    onSortByChange(sortBy: string): void {
        this.sortKey = sortBy;
        this.dataSource.sort?.sort({ id: sortBy, start: this.sortOrder, disableClear: false });
    }

    private _getInsights$(): Observable<ValueError<DiffCombinedSocialPageInsights[], string>> {
        return combineLatest([this.dates$, this.platformKeys$, this.selectedRestaurants$]).pipe(
            filter(([dates, platforms, restaurants]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0 && restaurants.length > 0),
            filter(([dates]) => {
                const { startDate, endDate } = dates;
                return !!startDate && !!endDate;
            }),
            tap(() => {
                this.isLoading$.next(true);
            }),
            map((values) => {
                const [_dates, socialNetworksPlatforms] = values;
                this.socialNetworksPlatforms = socialNetworksPlatforms;
                return values;
            }),
            switchMap(([dates, socialNetworksPlatforms, restaurants]) => {
                const startDate = dates.startDate as Date;
                const endDate = dates.endDate as Date;
                const restaurantIds = restaurants.map((r) => r._id);
                return forkJoin([
                    this._insightsService
                        .getInsights({
                            platformsKeys: socialNetworksPlatforms,
                            metrics: SOCIAL_METRICS,
                            aggregators: [AggregationTimeScale.TOTAL],
                            startDate,
                            endDate,
                            previousPeriod: false,
                            restaurantIds,
                        })
                        .pipe(map((res) => res.data)),
                    this._insightsService
                        .getInsights({
                            platformsKeys: this.socialNetworksPlatforms,
                            metrics: SOCIAL_METRICS,
                            aggregators: [AggregationTimeScale.TOTAL],
                            startDate,
                            endDate,
                            previousPeriod: true,
                            restaurantIds,
                        })
                        .pipe(map((res) => res.data))
                        .pipe(catchError(() => of(null))),
                    of(restaurants),
                ]);
            }),
            map(
                ([currentInsights, previousInsights, restaurants]: [
                    InsightsByPlatformByRestaurant,
                    InsightsByPlatformByRestaurant,
                    Restaurant[],
                ]) => {
                    const mappedCurrent = this._mapDataToSocialPageInsights(currentInsights, restaurants);
                    const mappedPrevious = this._mapDataToSocialPageInsights(previousInsights, restaurants);
                    const diffCombinedSocialPageInsights = mappedCurrent
                        .map((combinedInsights) => {
                            const previousAssociatedRestaurantInsights = mappedPrevious.find(
                                (c) => c.restaurant?._id === combinedInsights.restaurant?._id
                            );
                            return new DiffCombinedSocialPageInsights(
                                combinedInsights,
                                previousAssociatedRestaurantInsights,
                                this._translateService
                            );
                        })
                        .filter((insight) => !(this.hideRestaurantWithErrors && insight.hasErrors()));
                    const insights = { value: diffCombinedSocialPageInsights };

                    if (insights.value) {
                        this.dataSource = new MatTableDataSource<DiffCombinedSocialPageInsights>(insights.value);
                    }
                    if (!insights.value?.length) {
                        this.hasDataChange.emit(false);
                    }
                    return insights;
                }
            ),
            catchError((error) => {
                console.error('error', error);
                return of({
                    error: error instanceof HttpResponseBase ? getStatisticsError(error) : (error.message as string),
                });
            }),
            tap(() => {
                this.isLoading$.next(false);
            })
        );
    }

    private _mapDataToSocialPageInsights(insights: InsightsByPlatformByRestaurant, restaurants: Restaurant[]): SocialPlatformsInsights[] {
        const mappedInsights: SocialPlatformsInsights[] = [];
        Object.entries(insights)?.forEach(([restaurantId, insightsForRestaurant]) => {
            const mappedInsightsForRestaurant = {};
            for (const platformKey of this.socialNetworksPlatforms) {
                const total = insightsForRestaurant?.[platformKey]?.total;
                if (!total) {
                    mappedInsightsForRestaurant[platformKey] = insightsForRestaurant?.[platformKey] || {
                        error: true,
                        message: 'unknown_error',
                    };
                } else {
                    const metricsKeys = Object.keys(total);
                    const mappedInsightsForPlatform = {};
                    metricsKeys.forEach((metric) => {
                        mappedInsightsForPlatform[metric] = total[metric]?.value;
                    });
                    mappedInsightsForRestaurant[platformKey] = mappedInsightsForPlatform;
                }
            }
            const restaurant = restaurants.find((r) => r._id === restaurantId);
            if (restaurant) {
                mappedInsights.push({
                    ...mappedInsightsForRestaurant,
                    restaurant: {
                        _id: restaurantId,
                        name: restaurant.internalName,
                        type: restaurant.type,
                    },
                });
            }
        });
        return mappedInsights;
    }
}
