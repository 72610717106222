import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { CsvAsStringArrays, CsvService } from ':shared/services/csv-services/csv-service.abstract';
import { AggregatedReviewsCsvService } from ':shared/services/csv-services/e-reputation/aggregated-reviews-csv.service';
import { EReputationDownloadType } from ':shared/services/csv-services/e-reputation/csv-e-reputation.interface';
import { ReviewsCsvService } from ':shared/services/csv-services/e-reputation/reviews-csv.service';
import { CsvStringCreator } from ':shared/services/csv-services/helpers/create-csv-string';

@Injectable({ providedIn: 'root' })
export class DownloadCsvEReputationService {
    constructor(
        @Inject(ReviewsCsvService)
        private readonly _reviewsCsvService: CsvService,
        @Inject(AggregatedReviewsCsvService)
        private readonly _aggregatedReviewsCsvService: CsvService,
        private readonly _csvStringCreator: CsvStringCreator
    ) {}

    getCsvData$(csv: EReputationDownloadType): Observable<string | null> {
        let csvData$: Observable<CsvAsStringArrays | null>;
        switch (csv) {
            case EReputationDownloadType.REVIEWS:
                csvData$ = this._reviewsCsvService.getCsvData$();
                break;
            case EReputationDownloadType.AGGREGATED_REVIEWS:
                csvData$ = this._aggregatedReviewsCsvService.getCsvData$();
                break;
            default:
                return of(null);
        }

        return csvData$.pipe(
            map((csvData) => {
                if (!csvData) {
                    return null;
                }
                return this._csvStringCreator.create(csvData);
            })
        );
    }
}
