import { z } from 'zod';

import { BricksCategory } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const brickValidator = z.object({
    _id: objectIdValidator.optional(),
    id: objectIdValidator.optional(),
    brickId: z.string(),
    key: z.object({
        fr: z.string().optional(),
        en: z.string().optional(),
        es: z.string().optional(),
        it: z.string().optional(),
    }),
    category: z.nativeEnum(BricksCategory),
    subCategory: z.string(),
    nature: z.string().optional(),
    gender: z.string().optional(),
    number: z.string().optional(),
    text: z.object({
        fr: z.string().optional(),
        en: z.string().optional(),
        es: z.string().optional(),
        it: z.string().optional(),
    }),
    meanSearchVol: z.number().optional(),
    needReview: z.boolean().optional(),
    currentLang: z.string().optional(),
});
export type BrickDto = z.infer<typeof brickValidator>;
