import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidTiktokUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.TIKTOK })) {
            return false;
        }

        // Try to extract tiktok username
        const userName = getTiktokUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getTiktokUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = trimSlashes(parsedUrl.pathname); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters, dots and underscores are allowed for tiktok usernames
        const userNameRegex = /^@[a-zA-Z0-9_\.]+$/;

        // /username
        return pathname && userNameRegex.test(pathname) ? pathname : null;
    } catch {
        return null;
    }
}

export function transformTiktokUrl(url: string): string | null {
    if (!isValidTiktokUrl(url)) return null;
    const userName = getTiktokUserName(url);
    return `https://www.tiktok.com/${userName}`;
}
