import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult } from ':shared/models';
import { Feedback, FeedbackMessage, FeedbackUser } from ':shared/models/feedback';

@Injectable({
    providedIn: 'root',
})
export class FeedbacksService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/feedbacks`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    getFeedback(feedbackId: string): Observable<ApiResult<Feedback>> {
        return this._http.get<ApiResult<Feedback>>(`${this.API_BASE_URL}/${feedbackId}`, { withCredentials: true }).pipe(
            map((res) => {
                res.data = new Feedback(res.data);
                return res;
            })
        );
    }

    createFeedback(postId: string): Observable<ApiResult<Feedback>> {
        return this._http.post<ApiResult<Feedback>>(this.API_BASE_URL, { postId }, { withCredentials: true }).pipe(
            map((res) => {
                res.data = new Feedback(res.data);
                return res;
            })
        );
    }

    updateFeedback(
        feedbackId: string,
        data: Partial<Feedback>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .put<ApiResult<Feedback>>(`${this.API_BASE_URL}/${feedbackId}`, { ...data }, { withCredentials: true, params })
            .pipe(
                map((res) => {
                    res.data = new Feedback(res.data);
                    return res;
                })
            );
    }

    deleteFeedback(feedbackId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http.delete<ApiResult<Feedback>>(`${this.API_BASE_URL}/${feedbackId}`, { withCredentials: true, params });
    }

    addFeedbackMessage(
        feedbackId: string,
        message: Partial<FeedbackMessage>,
        participants: FeedbackUser[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .post<
                ApiResult<Feedback>
            >(`${this.API_BASE_URL}/${feedbackId}/messages`, { message, participants }, { withCredentials: true, params })
            .pipe(
                map((res) => {
                    res.data = new Feedback(res.data);
                    return res;
                })
            );
    }

    updateFeedbackMessage(
        feedbackId: string,
        messageId: string,
        message: Partial<FeedbackMessage>,
        participants: FeedbackUser[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .put<
                ApiResult<Feedback>
            >(`${this.API_BASE_URL}/${feedbackId}/messages/${messageId}`, { message, participants }, { withCredentials: true, params })
            .pipe(
                map((res) => {
                    res.data = new Feedback(res.data);
                    return res;
                })
            );
    }

    deleteFeedbackMessage(
        feedbackId: string,
        messageId: string,
        sendFakeOpenedNotificationEmail = false,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .delete<ApiResult>(
                `${this.API_BASE_URL}/${feedbackId}/messages/${messageId}?send_fake_opened_email=${sendFakeOpenedNotificationEmail}`,
                { withCredentials: true, params }
            )
            .pipe(
                map((res) => {
                    res.data = new Feedback(res.data);
                    return res;
                })
            );
    }
}
