export class Phone {
    prefix?: number | null;
    digits?: number | null;

    public constructor(init?: Partial<Phone>) {
        this.prefix = init?.prefix;
        this.digits = init?.digits;
    }

    toString(): string | null {
        return this.prefix && this.digits ? `+${this.prefix} ${this.digits}` : null;
    }
}
