import { createAction, props } from '@ngrx/store';

import { GenerationFormWithRestaurantId } from './generation-forms.interface';

export const editGenerationForm = createAction(
    '[Keywords] Edit_GenerationForm',
    props<{ generationForm: GenerationFormWithRestaurantId }>()
);

export const selectForm = createAction('[Keywords] Select Form', props<{ formId: string }>());
