import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from ':environments/environment';
import { ApiResult, Attribute, RestaurantAttribute } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class AttributeService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/attributes`;

    constructor(private readonly _http: HttpClient) {}

    getRestaurantAttributes(restaurantId: string): Observable<ApiResult<RestaurantAttribute[]>> {
        return this._http.get<ApiResult<RestaurantAttribute[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`).pipe(
            map((res) => {
                res.data = res.data?.map((att) => new RestaurantAttribute(att));
                return res;
            })
        );
    }

    getCategoryAttributes(restaurantId: string): Observable<ApiResult<Attribute[]>> {
        return this._http.get<ApiResult<Attribute[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/categories`).pipe(
            map((res) => {
                res.data = res.data.map((att) => new Attribute(att));
                return res;
            })
        );
    }
}
