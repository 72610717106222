import { NgFor, NgIf, NgSwitch, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    signal,
    SimpleChanges,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil, uniq } from 'lodash';

import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { ReviewsRatingsAverageData } from '../reviews-ratings-average.component';

enum TableField {
    RESTAURANT_NAME = 'restaurantName',
    AVERAGE_RATING = 'averageRating',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.AVERAGE_RATING,
    direction: 'asc',
};

interface RestaurantAverageRatingsData {
    restaurantId: string;
    averageRating: number | null;
    restaurantName: string;
    evolution: number | null;
    platformsData: {
        [key: string]: { currentAverageRating?: number; previousAverageRating?: number; evolution?: number; hasData: boolean };
    };
}

@Component({
    selector: 'app-reviews-ratings-average-table',
    templateUrl: './reviews-ratings-average-table.component.html',
    styleUrls: ['./reviews-ratings-average-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatTableModule,
        MatSortModule,
        TranslateModule,
        NumberEvolutionComponent,
        ShortNumberPipe,
        ApplyPurePipe,
        NgFor,
        NgSwitch,
        NgTemplateOutlet,
        NgIf,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class ReviewsRatingsAverageTableComponent implements OnInit, OnChanges {
    @Input() data: ReviewsRatingsAverageData[];
    @Input() previousData: ReviewsRatingsAverageData[];
    @Input() sortBy: Sort | undefined;
    @Output() sortChange: EventEmitter<Sort> = new EventEmitter();

    displayedColumns: WritableSignal<(TableField | PlatformKey)[]> = signal([TableField.RESTAURANT_NAME, TableField.AVERAGE_RATING]);
    defaultSort: WritableSignal<Sort> = signal(DEFAULT_SORT);

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                const { active, direction } = sort;
                this.sortChange.emit({ active, direction });
                switch (property) {
                    case TableField.RESTAURANT_NAME:
                        return item[property];
                    case TableField.AVERAGE_RATING:
                        return item[property] ?? 0;
                    default:
                        return item.platformsData[property]?.currentAverageRating ?? 0;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;

    readonly dataSource: MatTableDataSource<RestaurantAverageRatingsData> = new MatTableDataSource<RestaurantAverageRatingsData>([]);

    constructor(private readonly _translateService: TranslateService) {}

    ngOnInit(): void {
        this.updateMatDataSource(this.data, this.previousData);
        this._initDataSourceSortOptions(this.sortBy);
    }

    updateMatDataSource(data: ReviewsRatingsAverageData[], previousData: ReviewsRatingsAverageData[]): void {
        this.displayedColumns.set([TableField.RESTAURANT_NAME, TableField.AVERAGE_RATING, ...this._getPlatformsWithData(data)]);
        this.dataSource.data = this.computeDataSource(data, previousData);
    }
    computeDataSource(data: ReviewsRatingsAverageData[], previousData: ReviewsRatingsAverageData[]): RestaurantAverageRatingsData[] {
        const platformsWithData = this._getPlatformsWithData(data);

        return data.map((d) => {
            const currentAverage = d.averageRating;
            const previousAverage = previousData.find((previous) => previous.restaurant._id === d.restaurant._id)?.averageRating;
            const evolution = isNil(currentAverage) || isNil(previousAverage) ? null : currentAverage - previousAverage;

            const platformsData = this._getRestaurantPlatformData(platformsWithData, d, previousData);

            return {
                restaurantId: d.restaurant._id,
                [TableField.RESTAURANT_NAME]: d.restaurant.name,
                [TableField.AVERAGE_RATING]: currentAverage,
                evolution: evolution,
                platformsData,
            };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'data', 'previousData')) {
            this.updateMatDataSource(this.data, this.previousData);
        }
    }

    getTableColumnDisplayName = (column: TableField | PlatformKey): string => {
        switch (column) {
            case TableField.RESTAURANT_NAME:
                return this._translateService.instant('reviews_ratings_average_table.restaurant_name');
            case TableField.AVERAGE_RATING:
                return this._translateService.instant('reviews_ratings_average_table.average_rating');
            default:
                return PlatformDefinitions.getPlatformDefinition(column)?.fullName ?? '';
        }
    };

    private _initDataSourceSortOptions(sortOptions: Sort | undefined): void {
        if (sortOptions) {
            this.defaultSort.set(sortOptions);
        }
    }

    private _getPlatformsWithData(data: ReviewsRatingsAverageData[]): PlatformKey[] {
        const platformKeys = data.map((d) => d.byPlatforms.map((p) => p.platformKey)).flat();
        return uniq(platformKeys);
    }

    private _getRestaurantPlatformData(
        platformKeys: PlatformKey[],
        currentRestaurantData: ReviewsRatingsAverageData,
        previousData: ReviewsRatingsAverageData[]
    ): RestaurantAverageRatingsData['platformsData'] {
        return platformKeys.reduce((acc, platformKey) => {
            const currentPlatformData = currentRestaurantData.byPlatforms.find((p) => p.platformKey === platformKey);

            if (!currentPlatformData) {
                acc[platformKey] = {
                    hasData: false,
                };
                return acc;
            }
            const previousPlatformData = previousData
                .find((previous) => previous.restaurant._id === currentRestaurantData.restaurant._id)
                ?.byPlatforms.find((p) => p.platformKey === platformKey);

            acc[platformKey] = {
                currentAverageRating: currentPlatformData?.average,
                previousAverageRating: previousPlatformData?.average ?? null,
                hasData: true,
                evolution:
                    currentPlatformData?.average && previousPlatformData?.average
                        ? currentPlatformData?.average - previousPlatformData?.average
                        : null,
            };
            return acc;
        }, {});
    }
}
