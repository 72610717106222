<div class="relative h-full overflow-hidden border-l border-malou-color-border-primary bg-malou-color-background-light px-6 py-5">
    <div class="flex h-full w-full flex-col items-center justify-center gap-5">
        <img
            class="w-full max-w-[250px]"
            alt="{{ Illustration.Stove }} illustration"
            [src]="Illustration.Stove | illustrationPathResolver" />
        <div class="malou-text-14--regular italic text-malou-color-text-2">WORK IN PROGRESS, WOW EFFECT COMING SOON!</div>
    </div>

    <app-social-post-caption-ai-generation
        [(lastPrompt)]="upsertSocialPostAiContext.lastPrompt"
        [(isGenerating)]="upsertSocialPostAiContext.isGeneratingPostTextFromAI"
        [isSmallScreen]="false"
        [defaultPrompt]="defaultPrompt()"
        [promptFormControl]="upsertSocialPostAiContext.aiTextGenerationFormControl"
        [displayState]="upsertSocialPostAiContext.postCaptionAiGenerationDisplayState()"
        (onClose)="upsertSocialPostAiContext.closePostCaptionAiGeneration()"
        (onGeneratePromptClick)="upsertSocialPostAiContext.generatePost($event)">
    </app-social-post-caption-ai-generation>
    <app-social-post-choose-hashtags-panel></app-social-post-choose-hashtags-panel>
</div>
