import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomerIOService {
    customerIOInAppPlugin = 'Customer.io In-App Plugin';
    willTrack = environment.environment?.includes('production');
    customerIOAnalytics;

    initialize(): void {
        if (!this.willTrack) {
            return;
        }
        try {
            this.customerIOAnalytics = AnalyticsBrowser.load(
                { cdnURL: 'https://cdp-eu.customer.io', writeKey: environment.CUSTOMER_IO_WRITE_KEY },
                {
                    integrations: {
                        [this.customerIOInAppPlugin]: {
                            siteId: environment.CUSTOMER_IO_SITE_ID,
                        },
                    },
                }
            );
        } catch (error) {
            console.warn('[Error][Customer.io Service] Customer.io could not be initialized', error);
        }
    }

    identify(email: string, data: { last_name?: string; first_name?: string; role?: string }): void {
        if (!this.willTrack) {
            return;
        }
        if (!email) {
            console.warn('[Error][Customer.io Service] Invalid parameters provided to identify method, no email found');
            return;
        }
        if (!this.customerIOAnalytics) {
            console.warn('[Error][Customer.io Service] Customer.io has not been initialized before calling identify');
            return;
        }
        this.customerIOAnalytics.identify(email, data);
    }
}
