import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

import { ImgFormat } from ':core/constants';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

interface ImageDimensions {
    width: number;
    height: number;
}
@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
})

// https://netbasal.com/how-to-implement-file-uploading-in-angular-reactive-forms-89a3fffa1a03
export class FileUploadComponent implements ControlValueAccessor {
    @Output()
    onError: EventEmitter<string | null> = new EventEmitter<string>();

    readonly SvgIcon = SvgIcon;

    onChange: Function;

    constructor(
        private readonly _host: ElementRef<HTMLInputElement>,
        private readonly _translate: TranslateService
    ) {}

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
        this.onError.emit(null);

        const file: any = event && event.item(0);

        if (!this._isValidImage(file)) {
            return;
        }
        const isValidSize = file.size > 10240;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
            const img = new Image();
            img.onload = (): void => {
                if (img.height < 180 || img.width < 180 || !isValidSize) {
                    const errorMessage = this._getErrorText({ width: img.width, height: img.height }, file.size);
                    this.onError.emit(errorMessage);
                    return;
                } else {
                    this.onChange(file);
                }
            };
            img.onerror = (): void => {
                this.onError.emit(this._translate.instant('upload_image.could_not_load_file'));
                return;
            };
            img.src = String(reader.result) as string;
        };
    }

    writeValue(_value: null): void {
        // clear file input
        this._host.nativeElement.value = '';
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(_fn: Function): void {}

    private _getErrorText(imageDimensions: ImageDimensions, size: number): string {
        return imageDimensions.height < 180 || imageDimensions.width < 180
            ? this._translate.instant('upload_image.invalid_dimensions', { width: imageDimensions.width, height: imageDimensions.height })
            : this._translate.instant('upload_image.invalid_size', { size });
    }

    private _isValidImage(file): boolean {
        if (file === null) {
            this.onError.emit(this._translate.instant('upload_image.no_file'));
            return false;
        }
        const extension = file.name.split('.').pop().toLowerCase();
        const isImage = Object.values(ImgFormat).includes(extension);
        if (!isImage) {
            this.onError.emit(this._translate.instant('upload_image.invalid_format'));
            return false;
        }
        return true;
    }
}
