<div class="flex flex-col sm:gap-4">
    <div class="mt-2 flex items-center justify-between gap-16">
        <div class="hidden grow sm:block">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="'createdAt'"
                [sortOrder]="-1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange()">
            </app-sort-by-filters>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="!isLoading() ? dataTemplate : loadingTemplate"></ng-container>

    @if (shouldDisplayElementsPaginated) {
        <app-paginator
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [pageSize]="10"
            [length]="totalStories"
            [shouldBeHidden]="totalStories < 5"
            (pageEvent)="onPageChange($event)">
        </app-paginator>
    }
</div>

<ng-template #dataTemplate>
    @if (!httpError) {
        @if (dataSource.data && dataSource.data.length > 0) {
            <mat-table
                class="malou-mat-table"
                matSort
                matSortDisableClear
                [dataSource]="dataSource"
                [matSortActive]="defaultSort.active"
                [matSortDirection]="defaultSort.direction"
                #table="matTable">
                <ng-container matColumnDef="media">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights">
                        @if (shouldLazyLoadMedia) {
                            <app-social-post-media
                                class="!h-10 !w-10 rounded-md"
                                customIconClass="!hidden"
                                [postType]="storyInsights.postType"
                                [workingMedia]="storyInsights.mediaUrl"
                                [thumbnailUrl]="storyInsights.thumbnailUrl"
                                (click)="openSocialLink(storyInsights)"></app-social-post-media>
                        } @else {
                            <img
                                class="h-10 w-10 rounded-md"
                                [src]="storyInsights.mediaUrl || ('default-picture-grey' | imagePathResolver)"
                                (click)="openSocialLink(storyInsights)" />
                        }
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="platform">
                    <mat-header-cell *matHeaderCellDef class="max-w-[100px]" mat-sort-header>
                        {{ 'statistics.social_networks.stories.platform' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table" class="max-w-[100px]">
                        <app-platform-logo imgClasses="h-4 w-4" [logo]="storyInsights.key" [withLazyLoading]="true"></app-platform-logo>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'statistics.social_networks.stories.created_at' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <span class="malou-color-text-1">
                            @if (!storyInsights.createdAt) {
                                -
                            }
                            @if (storyInsights.createdAt) {
                                {{ storyInsights.createdAt | formatDate: 'mediumDate' }}
                                {{ storyInsights.createdAt | formatDate: 'shortTime' }}
                            }
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="impressions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'statistics.social_networks.stories.impressions' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                            @if (storyInsights.hasData) {
                                <div class="pl-3" [matTooltip]="getTooltipForValue | applyPure: 'impressions' : storyInsights.impressions">
                                    {{ getValueDisplay | applyPure: storyInsights.impressions }}
                                </div>
                            } @else {
                                <span class="malou-color-text-2 malou-text-12--regular absolute left-[30%] top-[13px] md:relative">
                                    {{ 'statistics.social_networks.stories.no_stats_facebook_stories' | translate }}
                                </span>
                            }
                            <div class="malou-text-13--regular hidden sm:block">
                                {{ 'statistics.social_networks.stories.impressions' | translate }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="reach">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'statistics.social_networks.stories.reach' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                            @if (storyInsights.hasData) {
                                <div class="pl-3" [matTooltip]="getTooltipForValue | applyPure: 'reach' : storyInsights.reach">
                                    {{ getValueDisplay | applyPure: storyInsights.reach }}
                                </div>
                            }
                            <div class="malou-text-13--regular hidden sm:block">
                                {{ 'statistics.social_networks.stories.reach' | translate }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tapsExits">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-icon
                            class="pdf-hidden mr-1 !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.INFO"
                            [matTooltip]="'statistics.social_networks.stories.exits_details' | translate">
                        </mat-icon>
                        <span>{{ 'statistics.social_networks.stories.exits' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                            @if (storyInsights.hasData) {
                                <div class="pl-3" [matTooltip]="getTooltipForValue | applyPure: 'tapsExits' : storyInsights.tapsExits">
                                    {{ getValueDisplay | applyPure: storyInsights.tapsExits }}
                                </div>
                            }
                            <div class="malou-text-13--regular hidden sm:block">
                                {{ 'statistics.social_networks.stories.exits' | translate }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tapsForward">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-icon
                            class="pdf-hidden mr-1 !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.INFO"
                            [matTooltip]="'statistics.social_networks.stories.taps_forward_details' | translate">
                        </mat-icon>
                        <span>{{ 'statistics.social_networks.stories.taps_forward' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                            @if (storyInsights.hasData) {
                                <div class="pl-3" [matTooltip]="getTooltipForValue | applyPure: 'tapsForward' : storyInsights.tapsForward">
                                    {{ getValueDisplay | applyPure: storyInsights.tapsForward }}
                                </div>
                            }
                            <div class="malou-text-13--regular hidden sm:block">
                                {{ 'statistics.social_networks.stories.taps_forward' | translate }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tapsBack">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-icon
                            class="pdf-hidden mr-1 !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.INFO"
                            [matTooltip]="'statistics.social_networks.stories.taps_back_details' | translate">
                        </mat-icon>
                        <span>{{ 'statistics.social_networks.stories.taps_back' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let storyInsights; table: table">
                        <div class="sm:grid sm:grid-cols-[3.75rem_1fr]">
                            @if (storyInsights.hasData) {
                                <div class="pl-3" [matTooltip]="getTooltipForValue | applyPure: 'tapsBack' : storyInsights.tapsBack">
                                    {{ getValueDisplay | applyPure: storyInsights.tapsBack }}
                                </div>
                            }
                            <div class="malou-text-13--regular hidden sm:block">
                                {{ 'statistics.social_networks.stories.taps_back' | translate }}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                <mat-row *matRowDef="let storyInsights; columns: DISPLAYED_COLUMNS; let i = index" class="!relative"></mat-row>
            </mat-table>
        } @else {
            @if (!shouldHideIfNoData) {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'statistics.errors.change_filters' | translate }}</span>
                </div>
            }
        }
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
            <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
        </div>
    }
</ng-template>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="!h-12 secondary-bg" [count]="5"></app-skeleton>
</ng-template>
