<div class="malou-border-color-dark flex h-fit w-full flex-col rounded-[5px] border">
    <span class="malou-text-14--bold my-[22px] flex items-center pl-6 text-malou-color-text-1">
        <mat-icon
            class="mr-4 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
            [svgIcon]="SvgIcon.CROSS"></mat-icon>
        {{ 'informations.nb_failed_updates' | pluralTranslate: nbFailedUpdates() : { nbUpdates: nbFailedUpdates() } }}
    </span>
    <mat-table class="malou-mat-table" matSort [dataSource]="errorUpdatesSource" [trackBy]="trackByIdFn" #errorUpdatesSort="matSort">
        <ng-container matColumnDef="platformKey">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                <span> {{ 'informations.platforms' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-small font-semibold !text-malou-color-text-1">
                <div class="flex items-center gap-2">
                    @if (update.socialLink) {
                        <a target="_blank" [href]="update.socialLink">
                            @if (isYextKey | applyPure: update.providerKey) {
                                <app-yext-platform-logo
                                    imgClasses="max-h-8 w-8 mr-10 object-contain"
                                    [yextPublisherId]="update.providerKey"></app-yext-platform-logo>
                            } @else {
                                <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                            }
                        </a>
                    } @else {
                        @if (isYextKey | applyPure: update.providerKey) {
                            <app-yext-platform-logo
                                imgClasses="max-h-8 w-8 mr-10 object-contain"
                                [yextPublisherId]="update.providerKey"></app-yext-platform-logo>
                        } @else {
                            <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                        }
                    }
                    <span class="malou-text-10--semibold text-malou-color-text-1">
                        {{ update.translatedPlatformName }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-large" mat-sort-header>
                <span> {{ 'informations.state' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-large font-semibold !text-malou-color-text-1 md:ml-4 md:!flex">
                <app-information-update-state-error
                    [platformKey]="update.platformKey"
                    [platformAccessStatus]="update.status"></app-information-update-state-error>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge md:!hidden">
                <span> {{ 'informations.details' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-xxlarge md:!hidden">
                <app-information-update-detail-error
                    [platformKey]="update.platformKey"
                    [platformAccessStatus]="update.status"
                    [errors]="update.errors"
                    (close)="close.emit()"></app-information-update-detail-error>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="errorUpdatesDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let update; columns: errorUpdatesDisplayedColumns; let i = index"></mat-row>
    </mat-table>
</div>
