<div class="m-auto my-2 flex h-[70vh] flex-col sm:m-0 sm:w-full sm:justify-between" [ngClass]="isFromModal() ? 'sm:h-full' : 'sm:h-[80vh]'">
    @if (!isFromModal()) {
        <div class="mb-4 flex sm:flex-col">
            <img class="mr-4 h-9 w-9 sm:mb-5" [src]="'rocket' | emojiPathResolver" />
            <div class="flex flex-col">
                <span class="malou-text-18--bold malou-color-text-1">
                    {{ 'roi.aggregated_settings.congrats_several_months_with_malou' | translate }}
                </span>
                <span class="malou-text-12--regular malou-text-color-2 !font-medium">
                    {{ 'roi.settings.enter_parameters' | translate }}
                </span>
                <span class="malou-text-12--regular malou-color-text-2 italic">{{ 'roi.settings.data_is_confidential' | translate }}</span>
            </div>
        </div>
    }
    @if (!isPhoneScreen()) {
        <app-select
            class="sm:!w-full"
            [ngClass]="isFromModal() ? 'w-full' : 'w-[465px]'"
            [formControl]="currencyForm()"
            [title]="'roi.aggregated_settings.currency' | translate"
            [values]="currencyOptions"
            [displayWith]="currencyDisplayWith">
        </app-select>
    }

    <div class="relative mt-3 flex flex-col overflow-y-auto overflow-x-hidden" [ngClass]="{ 'max-h-[53vh]': isFromModal() }">
        @if (isPhoneScreen()) {
            <app-select
                class="mb-3 sm:!w-full"
                [ngClass]="isFromModal() ? 'w-full' : 'w-[465px]'"
                [formControl]="currencyForm()"
                [title]="'roi.aggregated_settings.currency' | translate"
                [values]="currencyOptions"
                [displayWith]="currencyDisplayWith">
            </app-select>
        }
        @if (restaurantsWithoutRoiSettings().length) {
            <ng-container
                *ngTemplateOutlet="
                    fillRestaurantSettingsTemplate;
                    context: { restaurants: restaurantsWithoutRoiSettings() }
                "></ng-container>
            @if (!!initialRestaurantsWithValidRoiSettings().length) {
                <div class="expansion-header malou-expansion-panel my-3">
                    <mat-accordion>
                        <mat-expansion-panel
                            class="hover:bg-transparent"
                            hideToggle
                            [expanded]="false"
                            (opened)="isExpansionPanelOpened = true"
                            (closed)="isExpansionPanelOpened = false">
                            <mat-expansion-panel-header
                                class="!malou-text-12--semibold flex justify-center !text-center text-malou-color-primary">
                                {{
                                    (isExpansionPanelOpened
                                        ? 'roi.aggregated_settings.close_already_configured_restaurants'
                                        : 'roi.aggregated_settings.open_already_configured_restaurants'
                                    ) | translate
                                }}
                            </mat-expansion-panel-header>
                            <div class="flex flex-col">
                                <ng-container
                                    *ngTemplateOutlet="
                                        fillRestaurantSettingsTemplate;
                                        context: { restaurants: initialRestaurantsWithValidRoiSettings() }
                                    "></ng-container>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            }
        } @else {
            <ng-container
                *ngTemplateOutlet="
                    fillRestaurantSettingsTemplate;
                    context: { restaurants: initialRestaurantsWithValidRoiSettings() }
                "></ng-container>
        }
        <div
            class="custom-box-tooltip absolute right-[10px] top-[85px] flex w-[280px] items-center rounded-xl bg-white p-4 leading-5"
            id="duplicateTooltipModal"
            [ngStyle]="{
                visibility: shouldShowDuplicateButtonTooltip() ? 'visible' : 'hidden',
                opacity: shouldShowDuplicateButtonTooltip() ? '1' : '0',
            }">
            <div class="triangle"></div>
            <img class="h-7 w-auto pr-4" [src]="'tips-idea' | roiTipsPathResolver" />
            <div class="flex flex-col">
                <span class="malou-text-11--bold text-malou-text-color-1">{{
                    'roi.settings.same_data_for_multiple_locations' | translate
                }}</span>
                <span class="malou-text-11--regular text-malou-text-color-1">{{ 'roi.settings.duplicate_with_button' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="!mt-5 flex items-start justify-between sm:flex-col">
        <div
            class="malou-text-10 py-1 italic sm:mb-1 sm:text-left"
            [class.malou-color-state-error]="!hasAtLeastTwoRestaurants() && !isFromModal()">
            @if (!hasAtLeastTwoRestaurants() && !isFromModal()) {
                {{ 'roi.aggregated_settings.need_at_least_two_restaurants' | translate }}
            } @else if (restaurantsWithoutRoiSettings().length) {
                {{
                    'roi.aggregated_settings.remaining_restaurants'
                        | translate
                            : {
                                  restaurantWithoutRoiSettingsCount: restaurantsWithValidRoiSettings().length,
                                  restaurantCount: restaurants().length,
                              }
                }}
            }
        </div>
        <div class="flex justify-end sm:w-full sm:justify-center sm:gap-2">
            @if (isFromModal()) {
                <button
                    class="malou-btn-raised--secondary sm:!text-malou-10 mr-4 !h-11 items-center justify-center sm:mr-0 sm:!h-9 sm:w-full"
                    mat-raised-button
                    (click)="cancel()">
                    @if (isFromNotification()) {
                        {{ 'common.later' | translate }}
                    } @else {
                        {{ 'common.cancel' | translate }}
                    }
                </button>
            }

            <button
                class="malou-btn-raised--primary sm:!text-malou-10 flex !h-11 items-center justify-center sm:!h-9 sm:w-full"
                mat-raised-button
                [disabled]="!isGlobalFormValid()"
                [ngClass]="{ 'cursor-not-allowed': !isGlobalFormValid() }"
                (click)="saveManyRoiSettings()">
                @if (isSavingRoiSettings()) {
                    <app-malou-spinner class="flex !w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
                } @else {
                    <span>{{ 'common.save' | translate }}</span>
                }
            </button>
        </div>
    </div>
</div>

<ng-template #duplicateModalTemplate>
    <div class="malou-card__body flex max-h-[250px] flex-col gap-y-1 overflow-y-auto !p-0">
        <div class="flex items-center px-3 py-2">
            <mat-checkbox color="primary" [checked]="areAllRestaurantsChecked()" (change)="toggleAllDuplicate($event)"> </mat-checkbox>
            <div class="malou-text-13--semibold ml-3 text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.your_establishments' | translate }}
            </div>
        </div>
        @for (restaurant of restaurants(); track trackByIdFn($index, restaurant)) {
            <div
                class="flex cursor-pointer items-center px-3 py-2 hover:bg-malou-color-background-dark"
                [ngClass]="{
                    'opacity-50': restaurant.id === currentDuplicatedRoiSettings()?.restaurantId,
                    'bg-malou-color-background-dark': isChecked()(restaurant._id),
                }"
                (click)="toggleDuplicate(!isChecked()(restaurant._id), restaurant._id)">
                <mat-checkbox
                    class="pointer-events-none"
                    color="primary"
                    [checked]="isChecked()(restaurant._id)"
                    [disabled]="restaurant.id === currentDuplicatedRoiSettings()?.restaurantId">
                </mat-checkbox>
                <div class="ml-3">
                    <div class="malou-text-12--semibold text-malou-color-text-1">
                        {{ restaurant.name }}
                    </div>
                    <div class="malou-text-10--regular italic text-malou-color-text-2">
                        {{ restaurant.address | applySelfPure: 'getFullFormattedAddress' }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-restaurants="restaurants" #fillRestaurantSettingsTemplate>
    @for (restaurant of restaurants; track trackByIdFn($index, restaurant); let index = $index; let isLast = $last) {
        <div
            class="malou-color-bg-white malou-border-primary my-2 flex flex-col rounded-[10px] p-5 sm:w-full"
            [ngClass]="{ '!mt-0': index === 0, '!mb-0': isLast, 'w-full': isFromModal(), 'w-[465px]': !isFromModal() }"
            [formGroup]="roiSettingsControls()[restaurant._id]">
            <div class="relative mb-5 flex w-full items-center justify-between">
                <div class="flex max-w-[85%] items-center">
                    <img
                        class="malou-avatar--small"
                        [lazyLoad]="restaurant?.logo?.getMediaUrl('small') ?? ''"
                        [defaultImage]="'default-picture-grey' | imagePathResolver" />
                    <div class="ml-3 flex min-w-0 grow flex-col leading-4">
                        <span class="malou-text-14--semibold text-malou-color-text-1">{{ restaurant?.internalName }}</span>
                        <span class="malou-text-10--regular truncate font-normal italic text-malou-color-text-2">{{
                            getRestaurantSubtext | applyPure: restaurant
                        }}</span>
                    </div>
                </div>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    id="duplicateModalButton"
                    mat-icon-button
                    [disabled]="!isFormControlFilledForRestaurant()(restaurant._id)"
                    [matTooltip]="'roi.aggregated_settings.duplicate_settings' | translate"
                    (click)="openDuplicateDropdown($event, restaurant._id)">
                    <mat-icon class="icon-btn h-3 w-4" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>
                @if (showDuplicateModal() && currentDuplicatedRoiSettings()?.restaurantId === restaurant._id) {
                    <div
                        class="malou-card duplicate-settings-modal-box-shadow !absolute right-[-9px] z-10 w-[270px] !px-0 !py-2"
                        id="duplicateModalTemplate">
                        <ng-container *ngTemplateOutlet="duplicateModalTemplate"></ng-container>
                    </div>
                }
            </div>
            <app-select
                class="w-full"
                formControlName="category"
                [title]="'roi.aggregated_settings.restaurant_category' | translate"
                [values]="categoriesOptions"
                [inputReadOnly]="true"
                [displayWith]="categoryDisplayWith"
                [errorMessage]="requiredFieldMessage()(restaurant._id, RoiFormFieldName.CATEGORY)"></app-select>
            <app-input-number
                class="mb-5 w-full"
                [value]="roiSettingsControls()[restaurant._id].get('averageTicket')?.value ?? null"
                [title]="'roi.aggregated_settings.average_ticket' | translate"
                [placeholder]="'roi.settings.average_ticket_placeholder' | translate"
                [min]="1"
                [max]="1000"
                [errorMessage]="requiredFieldMessage()(restaurant._id, RoiFormFieldName.AVERAGE_TICKET)"
                (inputNumberChange)="onAverageTicketChange(restaurant._id, $event)">
            </app-input-number>
            <app-select
                class="w-full"
                formControlName="revenue"
                [title]="'roi.aggregated_settings.average_revenue_per_month' | translate"
                [placeholder]="'roi.settings.average_revenue_per_month_placeholder' | translate"
                [values]="revenueOptions"
                [inputReadOnly]="true"
                [errorMessage]="requiredFieldMessage()(restaurant._id, RoiFormFieldName.REVENUE)"
                [displayWith]="revenueDisplayWith"></app-select>
        </div>
    }
</ng-template>
