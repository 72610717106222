import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

/**
 * Parameter that define how to move between steps.
 *
 * - `absolute`: Move to the step with the given index.
 * - `relative`: Move to the step with the given offset from the current step.
 * - `history`: Move to the previous step in the history (value must be >= 1).
 *              if value equal 1, it will move to the previous step
 *              if value equal 2, it will move to the previous previous step
 *              if value equal 3, ...
 */
export type GoToStepParam = { type: 'absolute'; value: number } | { type: 'relative'; value: number } | { type: 'history'; value: number };

@Component({ template: '', standalone: true, changeDetection: ChangeDetectionStrategy.OnPush })
export class BaseStepComponent<SharedData = undefined, ModalReturnType = void> {
    sharedData = input.required<SharedData>();
    updateSharedData = output<SharedData>();
    goToStep = output<GoToStepParam>();
    close = output<ModalReturnType>();
}
