import { Routes } from '@angular/router';

import { RolesManagerComponent } from './roles-manager/roles-manager.component';
import { RolesRootComponent } from './roles.root.component';

export const ROLES_ROUTES: Routes = [
    {
        path: '',
        component: RolesRootComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: RolesManagerComponent,
            },
        ],
    },
];
