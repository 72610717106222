import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { CAMPAIGNS_ROUTES } from ':modules/campaigns/campaigns.routing';
import { REVIEWS_ROUTES } from ':modules/reviews/reviews.routing';

import { ReputationComponent } from './reputation.component';

export const REPUTATION_ROUTES: Routes = [
    {
        path: '',
        component: ReputationComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'reviews',
            },
            {
                path: 'reviews',
                loadChildren: () => REVIEWS_ROUTES,
                data: { path: RoutePath.REVIEWS },
            },
            {
                path: 'campaigns',
                loadChildren: () => CAMPAIGNS_ROUTES,
                data: { path: RoutePath.CAMPAIGNS },
            },
        ],
    },
];
