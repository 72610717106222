import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { exhaustMap, pairwise, withLatestFrom } from 'rxjs/operators';

import { HeapEventName } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import {
    selectAggregatedStatisticsSelectedPage,
    selectRestaurantIdsFilter,
    selectRoiRestaurantIdsFilter,
} from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';

interface AggregatedStatisticsEffectRestaurantIds {
    previousRestaurantIds: string[];
    currentRestaurantIds: string[];
    previousRoiRestaurantIds: string[];
    currentRoiRestaurantIds: string[];
}

@Injectable()
export class AggregatedStatisticsEffect {
    editRestaurants$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    AggregatedStatisticsActions.editSelectedPage,
                    AggregatedStatisticsActions.editRestaurants,
                    AggregatedStatisticsActions.editRoiRestaurants
                ),
                withLatestFrom(
                    this._store.select(selectUserInfos),
                    this._store.select(selectAggregatedStatisticsSelectedPage),
                    this._store.pipe(select(selectRoiRestaurantIdsFilter), pairwise()),
                    this._store.pipe(select(selectRestaurantIdsFilter), pairwise())
                ),
                exhaustMap(
                    ([
                        action,
                        user,
                        page,
                        [previousRoiRestaurantIds, currentRoiRestaurantIds],
                        [previousRestaurantIds, currentRestaurantIds],
                    ]) => {
                        const userEmail = user?.email;
                        const venuesIds = this._getVenuesIdsByAction(action, page, {
                            previousRestaurantIds,
                            currentRestaurantIds,
                            previousRoiRestaurantIds,
                            currentRoiRestaurantIds,
                        });
                        return of(
                            this._heapService.track(HeapEventName.EDIT_AGGREGATED_STATISTICS_RESTAURANTS, {
                                userEmail,
                                page,
                                venuesIds: venuesIds.join(','),
                            })
                        );
                    }
                )
            ),
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _heapService: HeapService
    ) {}

    private _getRestaurantsDiff(previousRestaurants: string[], currentRestaurants: string[]): string[] {
        return currentRestaurants.filter((restaurant) => !previousRestaurants.includes(restaurant));
    }

    private _getVenuesIdsByAction(
        action: ReturnType<
            | typeof AggregatedStatisticsActions.editSelectedPage
            | typeof AggregatedStatisticsActions.editRestaurants
            | typeof AggregatedStatisticsActions.editRoiRestaurants
        >,
        page: PlatformFilterPage,
        restaurantIds: AggregatedStatisticsEffectRestaurantIds
    ): string[] {
        const isRoiPage = page === PlatformFilterPage.ROI;
        if (action.type === AggregatedStatisticsActions.editSelectedPage.type) {
            return isRoiPage ? restaurantIds.currentRoiRestaurantIds : restaurantIds.currentRestaurantIds;
        }
        return isRoiPage
            ? this._getRestaurantsDiff(restaurantIds.previousRoiRestaurantIds, restaurantIds.currentRoiRestaurantIds)
            : this._getRestaurantsDiff(restaurantIds.previousRestaurantIds, restaurantIds.currentRestaurantIds);
    }
}
