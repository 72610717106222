import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PlatformDataFetchedStatus } from '@malou-io/package-utils';

import { FetchedState } from '../models';

@Pipe({
    name: 'reviewStatusTooltip',
    standalone: true,
})
export class ReviewStatusTooltipPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(fetchedState: FetchedState<PlatformDataFetchedStatus>): string {
        switch (fetchedState?.status) {
            case PlatformDataFetchedStatus.SUCCESS:
            case PlatformDataFetchedStatus.ERROR:
                return '';
            case PlatformDataFetchedStatus.ASYNC:
                return this._translate.instant('reviews.synchronization_footer.retrieving_reviews_async');
            default:
                return this._translate.instant('reviews.synchronization_footer.retrieving_reviews');
        }
    }
}
