import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { concat, of, switchMap, toArray } from 'rxjs';

import { HeapEventName, Locale, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { ConfirmActionModalComponent } from ':core/components/notification-center/components/confirm-action-modal/confirm-action-modal.component';
import { NotificationItemComponent } from ':core/components/notification-center/components/notification-item/notification-item.component';
import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { SpecialHoursNotificationService } from ':core/components/notification-center/services/special-hours-notification.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { Restaurant } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-special-hour-notification-item',
    templateUrl: './special-hour-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, MatButtonModule],
})
export class SpecialHourNotificationComponent extends NotificationItemComponent implements OnInit {
    private readonly _router = inject(Router);
    private readonly _notificationsService = inject(NotificationService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _specialHoursNotificationService = inject(SpecialHoursNotificationService);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _datePipe = inject(DatePipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _spinnerService = inject(SpinnerService);

    readonly notification = computed(() => this.initialNotification() as SpecialHourNotification);
    readonly specialHourNotificationActionText = computed(() => {
        switch (this.notification().data.event.action) {
            case SpecialHourNotificationAction.CLOSED:
                return 'notification-center.special-hour-notification-item.button.closed';
            case SpecialHourNotificationAction.OPEN_AS_USUAL:
                return 'notification-center.special-hour-notification-item.button.open';
            default:
                return '';
        }
    });
    readonly getNotificationEmoji = computed(() => this.notification().data.event.emoji);
    readonly getNotificationEventName = computed(
        () => this.notification().data.event.name[LocalStorage.getLang()] ?? this.notification().data.event.name[Locale.EN]
    );
    readonly SpecialHourNotificationAction = SpecialHourNotificationAction;

    readonly notificationRestaurants = computed(() =>
        this._restaurantService
            .restaurants()
            .filter(
                (restaurant: Restaurant) =>
                    this.notification().data?.restaurantIds?.includes(restaurant._id) &&
                    !restaurant.isBrandBusiness() &&
                    !restaurant.isClosedTemporarily &&
                    !this._specialHoursNotificationService.isRestaurantSpecialHoursDefinedForEventDate(restaurant, this.notification())
            )
    );

    readonly isUserActionCompleted = computed(() => this.notification().data.event.action !== SpecialHourNotificationAction.NOTHING);

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notificationRestaurants().length > 1);

    ngOnInit(): void {
        if (!this.notificationRestaurants().length) {
            this.markAsRead();
        }
    }

    onNotificationClick(): void {
        this.markAsRead();
        this.notificationCenterContext.close();
    }

    redirectToInformationPage(): void {
        this.markAsRead();
        const restaurantId = this.notificationRestaurants()[0]?._id ?? this.notification().data.restaurantIds[0];
        const eventDate = new Date(this.notification().data.event.startDate);
        this._router.navigate([`/restaurants/${restaurantId}/seo/informations`], {
            queryParams: { openSpecialHours: true, date: eventDate },
        });
    }

    onActionClick(action: SpecialHourNotificationAction): void {
        this.markAsRead();
        const restaurants = this.notificationRestaurants().map((restaurant) => restaurant.name);
        if (!restaurants.length) {
            return;
        }
        this._customDialogService
            .open(ConfirmActionModalComponent, {
                width: '550px',
                disableClose: false,
                height: 'auto',
                data: {
                    notificationType: this.notification().type,
                    restaurants,
                    updateDescription: this._getUpdateDescription(action),
                },
            })
            .afterClosed()
            .subscribe(({ isConfirmed }) => {
                if (isConfirmed === true) {
                    this.onActionConfirmed(action);
                }
            });
    }

    onActionConfirmed(action: SpecialHourNotificationAction): void {
        const updates$ = this.notificationRestaurants().map((restaurant) =>
            this._specialHoursNotificationService.updateSpecialHours$(restaurant, this.notification(), action)
        );

        this._spinnerService.show();
        concat(...updates$)
            .pipe(
                toArray(),
                switchMap((results) =>
                    results.every((r) => isNil(r))
                        ? of(null)
                        : this._notificationsService.updateSpecialHourNotification(this.notification().id, { action })
                )
            )
            .subscribe({
                next: (result) => {
                    this._spinnerService.hide();
                    if (result) {
                        this.notificationCenterContext.updateNotification(result);
                    }
                },
                error: (err) => {
                    this._spinnerService.hide();
                    console.warn(err);
                },
            });

        this.notificationCenterContext.trackNotification({
            heapEventName:
                action === SpecialHourNotificationAction.CLOSED
                    ? HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSED_ACTION
                    : HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_UPDATE_HOURS_ACTION,
            notificationId: this.notification().id,
            properties: {
                eventName: this.notification().data.event.name.fr ?? '',
            },
        });
    }

    private _getUpdateDescription(action: SpecialHourNotificationAction): string {
        const formattedDate = this._datePipe.transform(this.notification().data.event.startDate, 'dd/MM/yyyy');
        switch (action) {
            case SpecialHourNotificationAction.CLOSED:
                return this._translateService.instant('notification-center.special-hour-notification-item.closed_on', {
                    date: formattedDate,
                });
            case SpecialHourNotificationAction.OPEN_AS_USUAL:
                return this._translateService.instant('notification-center.special-hour-notification-item.open_as_usual', {
                    date: formattedDate,
                });
            default:
                return '';
        }
    }
}
