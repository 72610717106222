import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';

@Component({ template: '' })
@AutoUnsubscribeOnDestroy()
export class BaseStepComponent<T = unknown, U = unknown> implements OnInit, KillSubscriptions {
    @Input() inputData: T;
    @Input() sharedData: U;
    @Input() askEmitSubmit$: Observable<void>;
    @Output() submit = new EventEmitter<unknown | null>();
    @Output() valid = new EventEmitter<boolean>();
    @Output() showButtons = new EventEmitter<boolean>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this.askEmitSubmit$.pipe(takeUntil(this.killSubscriptions$)).subscribe(() => this.submit.emit(this._submitData()));
        this.valid.emit(this._isValid()); // init validation of the data to submit
    }

    /**
     * Override this function for data validation
     */
    protected _isValid(): boolean {
        return true;
    }

    /**
     * Override this function to submit data to stepper
     */
    protected _submitData(): unknown | null {
        return null;
    }
}
