import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, tap } from 'rxjs';

import * as FooterManagerActions from ':core/components/restaurant/footer-manager/store/footer-manager.actions';
import { AvailableFooterType } from ':core/components/restaurant/footer-manager/store/footer-manager.interface';
import { CampaignsActions } from ':modules/campaigns/store/campaigns.actions';
import { CampaignStatus } from ':modules/campaigns/store/campaigns.interface';
import { CampaignsSelectors } from ':modules/campaigns/store/campaigns.selector';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces/kill-subscriptions.interface';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

const FOOTER_ID = 'campaign-footer';
const ANIMATION_CLASS = 'close-animation';

@Component({
    selector: 'app-campaign-footer',
    templateUrl: './campaign-footer.component.html',
    styleUrls: ['./campaign-footer.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatIconModule, MatButtonModule, MatTooltipModule, AsyncPipe, EnumTranslatePipe],
})
@AutoUnsubscribeOnDestroy()
export class CampaignFooterComponent implements KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly CampaignStatus = CampaignStatus;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    restaurant: Restaurant;
    campaignStatus: CampaignStatus = CampaignStatus.NOT_STARTED;
    error: any = null;

    readonly isFooterVisible$: Observable<boolean> = this._store.select(CampaignsSelectors.selectCampaignsState).pipe(
        tap((state) => {
            this.campaignStatus = state.campaignStatus ?? CampaignStatus.NOT_STARTED;
            this.error = state.error;
        }),
        map((state) => (state.isFooterVisible || [CampaignStatus.SENT, CampaignStatus.ERROR].includes(this.campaignStatus)) ?? false),
        tap((isFooterVisible) => {
            if (isFooterVisible) {
                this._showFooter();
            }
            this._store.dispatch(
                FooterManagerActions.setFooterVisibility({
                    footerType: AvailableFooterType.CAMPAIGN,
                    isFooterVisible,
                })
            );
        })
    );

    constructor(private readonly _store: Store) {}

    close(): void {
        this._hideFooter();
        setTimeout(() => {
            this._dispatchEditIsVisible(false);
            if ([CampaignStatus.SENT, CampaignStatus.ERROR].includes(this.campaignStatus)) {
                this._dispatchCampaignStatus(CampaignStatus.NOT_STARTED);
            }
        }, 500);
    }

    private _dispatchEditIsVisible(isFooterVisible: boolean): void {
        this._store.dispatch(
            CampaignsActions.setFooterVisibility({
                isFooterVisible,
            })
        );
    }

    private _dispatchCampaignStatus(campaignStatus: CampaignStatus): void {
        this._store.dispatch(
            CampaignsActions.editCampaignStatus({
                campaignStatus,
            })
        );
    }

    private _showFooter(): void {
        document.getElementById(FOOTER_ID)?.classList.remove(ANIMATION_CLASS);
    }

    private _hideFooter(): void {
        document.getElementById(FOOTER_ID)?.classList.add(ANIMATION_CLASS);
    }
}
