<div
    class="section-size malou-color-background-light relative flex-3 overflow-hidden"
    [hidden]="!newSocialPostContext.shouldShowFeedbacks()">
    <div class="flex h-full flex-col">
        <div class="mb-2 flex w-full items-end p-2">
            @if (!isSmallScreen()) {
                <div
                    class="nav-button flex w-1/2 justify-center py-2"
                    [ngClass]="{
                        active: newSocialPostContext.displayedRightPanel() === newSocialPostContext.RightPanel.Feed,
                    }">
                    <button
                        class="malou-btn-flat btn-sm malou-color-text-2 !malou-text-14--medium w-full !bg-malou-color-background-light"
                        data-testid="social-post-modal-feed-nav"
                        mat-flat-button
                        (click)="changeDisplayedRightPanel(newSocialPostContext.RightPanel.Feed)">
                        <span>{{ 'social_posts.right_panel.feed_nav_title' | translate }} </span>
                    </button>
                </div>
            }
            <div
                class="nav-button flex w-full justify-center py-2"
                [ngClass]="{
                    active: newSocialPostContext.displayedRightPanel() === newSocialPostContext.RightPanel.Feedback,
                    '!w-1/2': !isSmallScreen(),
                }">
                <button
                    class="malou-btn-flat btn-sm malou-color-text-2 !malou-text-14--medium nav-button w-full !bg-malou-color-background-light"
                    data-testid="social-post-modal-feedback-nav"
                    mat-flat-button
                    (click)="changeDisplayedRightPanel(newSocialPostContext.RightPanel.Feedback)">
                    <span
                        >{{ 'social_posts.right_panel.feedback_nav_title' | translate }} ({{
                            newSocialPostContext.displayedFeedbackMessages().length
                        }})</span
                    >
                </button>
            </div>
        </div>
        @if (newSocialPostContext.displayedRightPanel() === newSocialPostContext.RightPanel.Feedback || isSmallScreen()) {
            <div class="flex-1 overflow-hidden px-4">
                <app-feedbacks-panel
                    [post]="newSocialPostContext.postInitialData().post"
                    [shouldHideTitle]="true"
                    [restaurantManagers]="newSocialPostContext.postInitialData().restaurantManagers"
                    (updateDisplayedMessages)="onUpdateDisplayedMessages($event)"></app-feedbacks-panel>
            </div>
        }
        @if (newSocialPostContext.displayedRightPanel() === newSocialPostContext.RightPanel.Feed && !isSmallScreen()) {
            <div class="overflow-hidden">
                <app-static-feed
                    [restaurantId]="newSocialPostContext.currentRestaurant()._id"
                    [currentPost]="newSocialPostContext.partialPost()"></app-static-feed>
            </div>
        }
    </div>
    @if (!isSmallScreen()) {
        <app-post-caption-ai-generation
            [(lastPrompt)]="newSocialPostAiContext.lastPrompt"
            [(isGenerating)]="newSocialPostAiContext.isGeneratingPostTextFromAI"
            [isSmallScreen]="false"
            [defaultPrompt]="defaultPrompt()"
            [promptFormControl]="newSocialPostAiContext.aiTextGenerationFormControl"
            [displayState]="newSocialPostAiContext.postCaptionAiGenerationDisplayState()"
            [isSeo]="false"
            (onClose)="newSocialPostAiContext.closePostCaptionAiGeneration()"
            (onGeneratePromptClick)="newSocialPostAiContext.generatePost($event)">
        </app-post-caption-ai-generation>
        <app-post-choose-hashtags-panel></app-post-choose-hashtags-panel>
    }
</div>

@if (isSmallScreen()) {
    <app-post-caption-ai-generation
        class="absolute right-0 top-0"
        [(lastPrompt)]="newSocialPostAiContext.lastPrompt"
        [(isGenerating)]="newSocialPostAiContext.isGeneratingPostTextFromAI"
        [isSmallScreen]="true"
        [defaultPrompt]="defaultPrompt()"
        [promptFormControl]="newSocialPostAiContext.aiTextGenerationFormControl"
        [displayState]="newSocialPostAiContext.postCaptionAiGenerationDisplayState()"
        [isSeo]="false"
        (onClose)="newSocialPostAiContext.closePostCaptionAiGeneration()"
        (onGeneratePromptClick)="newSocialPostAiContext.generatePost($event)">
    </app-post-caption-ai-generation>
    <app-post-choose-hashtags-panel class="absolute right-0 top-0"></app-post-choose-hashtags-panel>
}
