<div class="flex h-full flex-col gap-6 overflow-y-auto px-6 py-5">
    <div class="malou-text-14--semibold text-malou-color-text-1">
        {{ 'social_posts.upsert_social_post_modal.content_form.section_title' | translate }}
    </div>

    <div class="flex flex-col gap-2">
        <div class="malou-text-10--regular text-malou-color-text-2">
            {{ 'social_posts.upsert_social_post_modal.content_form.media.title' | translate }}
        </div>

        <app-upload-and-edit-medias
            [medias]="[]"
            [maxMediaSizeInMo]="maxMediaSizeInMo()"
            [mediaFormatAccepted]="mediaFormatAccepted()"></app-upload-and-edit-medias>
    </div>

    @if (isMapstrSelected()) {
        <div class="flex flex-col gap-2">
            <div class="malou-text-10--regular text-malou-color-text-2">
                {{ 'social_posts.upsert_social_post_modal.content_form.title.title' | translate }}
            </div>

            <app-input-text [defaultValue]="postTitle()" (inputTextChange)="onTitleChange($event)"></app-input-text>
        </div>
    }

    <div class="flex flex-col gap-2">
        <div class="malou-text-10--regular text-malou-color-text-2">
            {{ 'social_posts.upsert_social_post_modal.content_form.caption.title' | translate }}
        </div>

        <app-social-post-caption
            [value]="postCaption()"
            [isMapstrPlatformChecked]="isMapstrSelected()"
            [isReel]="false"
            (valueChange)="onCaptionChange($event)"></app-social-post-caption>
    </div>

    @if (areFacebookOrInstagramSelected()) {
        <div class="flex flex-col gap-2">
            <div class="malou-text-10--regular malou-color-text-2 flex items-center gap-x-1">
                <div>
                    {{ 'social_posts.upsert_social_post_modal.content_form.place.title' | translate }}
                </div>
                @if (isMapstrSelected()) {
                    <mat-icon
                        class="malou-color-text-2 !h-4 !w-4"
                        [svgIcon]="SvgIcon.INFO"
                        [matTooltip]="'social_posts.new_social_post.location_only_for_facebook_and_instagram' | translate"></mat-icon>
                }
            </div>
            <app-social-post-place
                [location]="postLocation()"
                [platformSocialId]="facebookPlatformSocialId()"
                [isDisabled]="false"
                [isLoadingLocation]="isLoadingLocation()"
                (locationChange)="onLocationChange($event)"></app-social-post-place>
        </div>
    }

    @if (isMapstrSelected()) {
        <div class="flex flex-col gap-2">
            <div class="malou-text-10--regular flex items-center gap-x-1 text-malou-color-text-2">
                <div>
                    {{ 'social_posts.upsert_social_post_modal.content_form.cta.title' | translate }}
                </div>
                <mat-icon
                    class="malou-color-text-2 !h-4 !w-4"
                    [svgIcon]="SvgIcon.INFO"
                    [matTooltip]="'social_posts.new_social_post.button_cta_only_for_mapstr' | translate"></mat-icon>
            </div>
            <app-social-post-cta></app-social-post-cta>
        </div>
    }

    <!-- WILL DO IN ANOTHER PR -->
    <!-- <div class="flex flex-col gap-2">
        <div class="malou-text-10--regular text-malou-color-text-2">
            {{ 'social_posts.upsert_social_post_modal.content_form.collaborators.title' | translate }}
        </div>
    </div> -->
</div>
