<div
    class="malou-color-background-purple flex h-10.5 items-center justify-center rounded"
    [class.w-12.5]="widthMode() === 'fixed'"
    [class.px-1]="widthMode() === 'fit'">
    @if (value() === null) {
        <span class="malou-color-text-primary malou-text-12--medium">
            {{ 'star_with_number.without_review' | translate }}
        </span>
    } @else {
        <span class="malou-color-text-primary malou-text-17--medium">{{ value() }}</span>
        <mat-icon class="small-icon malou-color-text-primary !h-5 !w-5" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
    }
</div>
