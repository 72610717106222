import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { InformationUpdateOptions } from ':shared/models';

import * as AdminActions from '../../store/admin.actions';
import { AccessComponent } from '../access/access.component';
import { UpdatesComponent } from '../updates/updates.component';
import { PlatformsManagementViewType } from './platforms-management-action-header.interface';

interface PlatformsManagementActionsHeaderForm {
    selection: InformationUpdateOptions;
    viewType: PlatformsManagementViewType;
    showProcessed: boolean;
}

@Component({
    selector: 'app-platforms-management-actions-header',
    templateUrl: './platforms-management-actions-header.component.html',
    styleUrls: ['./platforms-management-actions-header.component.scss'],
    standalone: true,
    imports: [
        SearchComponent,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        SortByFiltersComponent,
        NgClass,
        MatIconModule,
        MatExpansionModule,
        MatButtonModule,
        NgStyle,
        MatDividerModule,
        AccessComponent,
        UpdatesComponent,
        AsyncPipe,
        TranslateModule,
        SelectComponent,
        SlideToggleComponent,
        MatButtonToggleModule,
    ],
})
export class PlatformsManagementActionsHeaderComponent implements OnInit {
    @Output() searchChange = new EventEmitter<string>();
    @Output() delete = new EventEmitter<void>();
    @Output() createNfc = new EventEmitter<void>();

    readonly PlatformsManagementViewType = PlatformsManagementViewType;

    readonly SELECTION_OPTIONS = [InformationUpdateOptions.ACCESS, InformationUpdateOptions.UPDATE, InformationUpdateOptions.BOTH];
    form: INullableFormGroup<PlatformsManagementActionsHeaderForm>;

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _fb: FormBuilder
    ) {}

    get showProcessed(): boolean {
        return !!this.form?.get('showProcessed')?.value;
    }

    ngOnInit(): void {
        this.form = this._initForm();
        this.form.get('viewType')?.valueChanges.subscribe((viewType) => {
            const filters = {
                viewType,
            };
            this._store.dispatch({ type: AdminActions.editUpdatesFilters.type, filters });
        });
    }

    onSearchChange(search: string): void {
        this.searchChange.emit(search);
    }

    selectionDisplayWith = (selection: InformationUpdateOptions): string => {
        if (!selection || typeof selection !== 'string') {
            return '';
        }
        return this._translate.instant(`admin.update.${selection}`);
    };

    selectionChange(event: InformationUpdateOptions): void {
        this.form.get('selection')?.setValue(event);
        const filters = {
            selectedOption: event,
        };
        this._store.dispatch({ type: AdminActions.editUpdatesFilters.type, filters });
    }

    toggleShowTreated(showProcessed: boolean): void {
        this.form.get('showProcessed')?.setValue(showProcessed);
        const filters = {
            showProcessed: this.showProcessed,
        };
        this._store.dispatch({ type: AdminActions.editUpdatesFilters.type, filters });
    }

    private _initForm(): INullableFormGroup<PlatformsManagementActionsHeaderForm> {
        return this._fb.group({
            selection: [InformationUpdateOptions.BOTH],
            viewType: [PlatformsManagementViewType.BUSINESS],
            showProcessed: [false],
        });
    }
}
