import { z } from 'zod';

import { PlatformDataFetchedStatus } from '@malou-io/package-utils';

export interface CurrentStateDto {
    reviews?: ReviewsStateDto;
    comments?: CommentsStateDto;
    posts?: PostsStateDto;
    messages?: MessagesStateDto;
}

export interface ReviewsStateDto {
    fetched?: ReviewsFetchedDto;
}

export interface CommentsStateDto {
    fetched?: CommentsFetchedDto;
}

export interface PostsStateDto {
    lastSync?: string;
}

export interface MessagesStateDto {
    lastSync?: string;
    fetched?: MessagesFetchedDto;
}

export interface ReviewsFetchedDto {
    gmb?: PlatformStateDto;
    facebook?: PlatformStateDto;
    foursquare?: PlatformStateDto;
    tripadvisor?: PlatformStateDto;
    yelp?: PlatformStateDto;
    ubereats?: PlatformStateDto;
    resy?: PlatformStateDto;
}

export interface CommentsFetchedDto {
    facebook?: PlatformStateDto;
    instagram?: PlatformStateDto;
}

export interface MessagesFetchedDto {
    facebook?: PlatformStateDto;
    instagram?: PlatformStateDto;
}

export interface PlatformStateDto {
    status: PlatformDataFetchedStatus;
    lastTried?: string;
    error: null | string;
}

export const platformStateDtoValidator = z
    .object({
        status: z.string(),
        lastTried: z.string().datetime().nullish(),
        error: z.string().nullable(),
    })
    .transform((data) => ({
        ...data,
        lastTried: data.lastTried ? new Date(data.lastTried) : undefined,
    }));

const reviewsFetchedDtoValidator = z.object({
    gmb: platformStateDtoValidator.optional(),
    facebook: platformStateDtoValidator.optional(),
    foursquare: platformStateDtoValidator.optional(),
    tripadvisor: platformStateDtoValidator.optional(),
    yelp: platformStateDtoValidator.optional(),
    ubereats: platformStateDtoValidator.optional(),
});

const commentsFetchedDtoValidator = z.object({
    facebook: platformStateDtoValidator.optional(),
    instagram: platformStateDtoValidator.optional(),
});

const messagesFetchedDtoValidator = z.object({
    facebook: platformStateDtoValidator.optional(),
    instagram: platformStateDtoValidator.optional(),
});

const reviewsStateDtoValidator = z.object({
    fetched: reviewsFetchedDtoValidator.optional(),
});

const commentsStateDtoValidator = z.object({
    fetched: commentsFetchedDtoValidator.optional(),
});

const postsStateDtoValidator = z
    .object({
        lastSync: z.string().datetime().nullish(),
    })
    .transform((data) => ({
        ...data,
        lastSync: data.lastSync ? new Date(data.lastSync) : undefined,
    }));

const messagesStateDtoValidator = z
    .object({
        lastSync: z.string().datetime().optional(),
        fetched: messagesFetchedDtoValidator.optional(),
    })
    .transform((data) => ({
        ...data,
        lastSync: data.lastSync ? new Date(data.lastSync) : undefined,
    }));

export const currentStateDtoValidator = z.object({
    reviews: reviewsStateDtoValidator.optional(),
    comments: commentsStateDtoValidator.optional(),
    posts: postsStateDtoValidator.optional(),
    messages: messagesStateDtoValidator.optional(),
});
