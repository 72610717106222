// https://uppy.io/docs/writing-plugins/#Creating-A-Plugin
import Uppy, { BasePlugin, UppyFile } from '@uppy/core';
import { firstValueFrom } from 'rxjs';

import { MediaService } from ':modules/media/media.service';

interface CheckCodecsUppyPluginOptions {
    id?: string;
    mediaService: MediaService;
}
export class CheckCodecsUppyPlugin extends BasePlugin<CheckCodecsUppyPluginOptions> {
    mediaService: MediaService;

    constructor(uppy: Uppy, opts: CheckCodecsUppyPluginOptions) {
        super(uppy, opts);
        this.id = 'CheckCodecsUppyPlugin';
        this.type = 'check-codec';
        this.mediaService = opts.mediaService;
    }

    override install(): void {
        this.uppy.addPreProcessor(this.preProcess);
    }

    override uninstall(): void {
        this.uppy.removePreProcessor(this.preProcess);
    }

    preProcess = async (fileIDs: string[]): Promise<void> => {
        const promises = fileIDs.map(async (fileID) => {
            const file = this.uppy.getFile(fileID);
            await this.preProcessFile(file);
        });

        return Promise.all(promises).then((_e) => {});
    };

    async preProcessFile(file: UppyFile): Promise<void> {
        const mimeType = file.type;
        const shouldConvertCodec = mimeType === 'image/heic' || mimeType === 'image/heif';
        if (!shouldConvertCodec) {
            return;
        }
        const blob: Blob = file.data;
        const resBlob = await firstValueFrom(this.mediaService.transcodeHeifToJpg(blob));
        this.uppy.removeFile(file.id);
        this.uppy.addFile({ data: resBlob, type: 'image/jpg' });
    }
}
