import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { SelectComponent } from ':shared/components/select/select.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-platforms-connection-access-information',
    templateUrl: './platforms-connection-access-information.component.html',
    styleUrls: ['./platforms-connection-access-information.component.scss'],
    standalone: true,
    imports: [TranslateModule, SelectComponent, MatButtonModule, MatIconModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsConnectionAccessInformationComponent {
    readonly SvgIcon = SvgIcon;
    readonly isValid = input.required<boolean>();
    readonly expirationTimestamp = input.required<number | undefined>();

    readonly expirationInDays = computed<number | undefined>(() => {
        const expirationTimestamp = this.expirationTimestamp();
        if (!expirationTimestamp) {
            return undefined;
        }
        const diffInDaysFloat = DateTime.fromSeconds(expirationTimestamp).diffNow('days').days;
        return Math.floor(diffInDaysFloat);
    });
}
