import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { RestaurantsService } from ':core/services/restaurants.service';
import { Media, Post, Restaurant } from ':shared/models';

import { PostForm } from '../new-post-modal/types';

@Injectable({
    providedIn: 'any',
})
export class NewPostModalContext {
    private readonly _restaurantsService = inject(RestaurantsService);

    postForm: PostForm;
    readonly postLang = signal<string | null>(null);
    readonly postMedias = signal<Media[]>([]);
    readonly currentPost = signal<Post | null>(null);
    readonly currentRestaurant = toSignal<Restaurant | null>(this._restaurantsService.restaurantSelected$);
    readonly isSmallScreen = signal(false);

    onChangePostLang(lang: string): void {
        this.postForm.get('post.language')?.setValue(lang);
        this.postLang.set(lang);
    }
}
