import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { Observable, switchMap, take } from 'rxjs';

import { GIFT_PERCENTAGE_DECIMALS } from ':core/constants';
import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersDataFetchingService } from ':modules/statistics/boosters/services/get-boosters-data.service';
import { selectDatesFilter } from ':modules/statistics/store/statistics.selectors';

import { AbstractCsvService, CsvAsStringArrays } from '../csv-service.abstract';

type Data = WheelOfFortuneGiftsStatisticsData;

@Injectable({ providedIn: 'root' })
export class BoostersGiftsCsvInsightService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _boostersDataFetchingService: BoostersDataFetchingService
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return this._store.select(selectDatesFilter).pipe(
            take(1),
            switchMap((dates) => this._boostersDataFetchingService.getGiftsData(dates))
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return data.giftsInsightsPerGift.length > 0;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Gift', 'Winners Count', 'Retrieved Gift Count', 'Retrieved Gift Ratio'];
    }

    protected override _getCsvDataRows({ giftsInsightsPerGift }: Data): CsvAsStringArrays {
        return giftsInsightsPerGift.map(({ gift, giftDrawCount, retrievedGiftDrawCount }) => [
            gift,
            giftDrawCount ? giftDrawCount.toString() : '0',
            retrievedGiftDrawCount ? retrievedGiftDrawCount.toString() : '0',
            retrievedGiftDrawCount ? round((retrievedGiftDrawCount / giftDrawCount) * 100, GIFT_PERCENTAGE_DECIMALS) + '%' : '0%',
        ]);
    }
}
