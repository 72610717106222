import { DateTime } from 'luxon';

import { GiftDrawDto, GiftDrawPopulatedDto } from '@malou-io/package-dto';
import { isAfterToday, isBeforeToday } from '@malou-io/package-utils';

import { LightClient } from './client';
import { LightGift } from './gift';
import { LightRestaurant } from './restaurant';
import { LightWheelOfFortune } from './wheel-of-fortune';

export enum GiftDrawState {
    ALREADY_CLAIMED = 'ALREADY_CLAIMED',
    CLAIMABLE = 'CLAIMABLE',
    DRAW_EXPIRED = 'DRAW_EXPIRED',
    NOT_CLAIMABLE_YET = 'NOT_CLAIMABLE_YET',
    NOT_SET = 'NOT_SET',
    WHEEL_OF_FORTUNE_EXPIRED = 'WHEEL_OF_FORTUNE_EXPIRED',
}

export class GiftDraw {
    id?: string;
    giftId: string;
    restaurantId: string;
    clientId: string | null;
    wheelOfFortuneId: string;
    retrievedAt: Date | null;
    retrievalStartDate: Date;
    retrievalEndDate: Date;
    lang: string;

    constructor(init: GiftDrawDto) {
        this.id = init.id;
        this.restaurantId = init.restaurantId;
        this.giftId = init.giftId;
        this.clientId = init.clientId;
        this.wheelOfFortuneId = init.wheelOfFortuneId;
        this.retrievedAt = init.retrievedAt ? new Date(init.retrievedAt) : null;
        this.retrievalStartDate = new Date(init.retrievalStartDate);
        this.retrievalEndDate = new Date(init.retrievalEndDate);
        this.lang = init.lang;
    }

    hasAlreadyBeenClaimed(): boolean {
        return !!this.retrievedAt;
    }

    isExpired(): boolean {
        return isBeforeToday(this.retrievalEndDate);
    }

    isNotClaimableYet(): boolean {
        return isAfterToday(this.retrievalStartDate);
    }

    getFormattedRetrievalStartDate(locale: string): string {
        return DateTime.fromJSDate(this.retrievalStartDate).setLocale(locale).toLocaleString(DateTime.DATE_HUGE);
    }
}

export class GiftDrawPopulated extends GiftDraw {
    restaurant: LightRestaurant;
    wheelOfFortune: LightWheelOfFortune;
    client: LightClient | null;
    gift: LightGift;

    constructor(init: GiftDrawPopulatedDto) {
        super(init);
        this.restaurant = new LightRestaurant(init.restaurant);
        this.wheelOfFortune = new LightWheelOfFortune(init.wheelOfFortune);
        this.client = init.client ? new LightClient(init.client) : null;
        this.gift = new LightGift(init.gift);
    }

    getRestaurantAddressDisplayedValue(): string {
        return this.restaurant.getAddressDisplayedValue();
    }

    getRestaurantFullFormattedAddress(): string {
        return this.restaurant.getFullFormattedAddress();
    }

    getRestaurantLogoUrl(): string | null {
        return this.restaurant.getLogoUrl();
    }
}
