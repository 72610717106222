import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationType } from '@malou-io/package-utils';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';

@Component({
    selector: 'app-notification-reminder-item',
    standalone: true,
    templateUrl: './notification-reminder-item.component.html',
    styleUrls: ['./notification-reminder-item.component.scss'],
    imports: [SlideToggleComponent, TranslateModule, NgTemplateOutlet, MatButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationReminderItemComponent {
    notificationType = input.required<NotificationType>();
    checked = input.required<boolean>();
    onToggle = output();

    notificationReminderTile = computed(() => `admin.profile.alerts.${this.notificationType()}.title`);
    notificationReminderDescription = computed(() => `admin.profile.alerts.${this.notificationType()}.description`);

    toggleReminder(): void {
        this.onToggle.emit();
    }
}
