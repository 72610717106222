import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { HeaderComponent } from ':shared/components/header/header.component';
import { NavigationHeaderComponent, NavigationTab } from ':shared/components/navigation-header/navigation-header.component';

import * as AdminActions from './store/admin.actions';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NavigationHeaderComponent, RouterOutlet, ScrollToTopComponent],
})
export class AdminComponent implements OnInit {
    tabs: NavigationTab[] = [
        { label: this._translate.instant('admin.navbar.access_and_updates'), link: './platforms-management' },
        { label: this._translate.instant('admin.navbar.restaurants'), link: './restaurants' },
        { label: this._translate.instant('admin.navbar.users'), link: './users' },
        { label: this._translate.instant('admin.navbar.organizations'), link: './organizations' },
        { label: this._translate.instant('admin.navbar.nfc'), link: './nfc' },
    ];

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService
    ) {}

    ngOnInit(): void {
        this._store.dispatch({ type: AdminActions.loadUserRestaurants.type });
    }
}
