import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ValuesPipe } from ':shared/pipes/values.pipe';

import { SlideToggleComponent } from '../../components-v3/slide-toggle/slide-toggle.component';
import { PlatformComparisonAction } from '../../interfaces/value-with-difference.interface';
import { Comparison, ComparisonKey, Description, DISPLAYED_NULLISH_VALUE } from '../../models';
import { ComparisonAddressComponent } from './comparison-address/comparison-address.component';
import { ComparisonAttributesComponent } from './comparison-attributes/comparison-attributes.component';
import { ComparisonCategoriesComponent } from './comparison-categories/comparison-categories.component';
import { ComparisonDescriptionComponent } from './comparison-description/comparison-description.component';
import { ComparisonRegularHoursComponent } from './comparison-regular-hours/comparison-regular-hours.component';
import { ComparisonSpecialHoursComponent } from './comparison-special-hours/comparison-special-hours.component';
import { DefaultComparisonComponent } from './default-comparison/default-comparison.component';

export interface ChangeLockData {
    lock: boolean;
    fieldKey: string;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-comparison-row]',
    templateUrl: './comparison-row.component.html',
    styleUrls: ['./comparison-row.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatMenuModule,
        TranslateModule,
        MatIconModule,
        NgClass,
        NgTemplateOutlet,
        ComparisonSpecialHoursComponent,
        ComparisonRegularHoursComponent,
        ComparisonAddressComponent,
        ComparisonCategoriesComponent,
        ComparisonDescriptionComponent,
        ComparisonAttributesComponent,
        DefaultComparisonComponent,
        SlideToggleComponent,
        ValuesPipe,
        EnumTranslatePipe,
    ],
})
export class ComparisonRowComponent {
    @Input() comparison: Comparison;
    @Input() action: PlatformComparisonAction;
    @Input() platformName: string;
    @Input() index: number;
    @Input() lastRow: boolean;
    @Output() changeLock = new EventEmitter<ChangeLockData>();
    @Output() copyClick = new EventEmitter<{ valueToCopy: any }>();

    ComparisonKey = ComparisonKey;
    PlatformComparisonAction = PlatformComparisonAction;

    lockedField: boolean;
    readonly DISPLAYED_NULLISH_VALUE = DISPLAYED_NULLISH_VALUE;

    @Input() set isLocked(value: boolean) {
        this.lockedField = value ?? false;
    }

    readonly SvgIcon = SvgIcon;

    emitChangeLock(event: boolean, fieldKey: string): void {
        this.changeLock.emit({ lock: event, fieldKey });
    }

    emitCopy(options?: Record<string, any>): void {
        if (!options) {
            this.copyClick.emit();
            return;
        }
        const valueToCopy = this._getValueToCopy(this.comparison.key, options);
        this.copyClick.emit({ valueToCopy });
    }

    private _getValueToCopy(key: string, options: any): any {
        if (key === 'descriptions') {
            return (options as Description).text;
        }
        if (key === 'attributes') {
            return (options as { text: string; value: string }[])
                ?.filter((attr) => attr.value === 'yes' && !!attr.text)
                ?.map((attr) => attr.text)
                ?.join(', ');
        }
        return;
    }
}
