import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { UpdateAggregatedRoiSettingsModalComponent } from ':modules/aggregated-statistics/roi/update-aggregated-roi-settings-modal/update-aggregated-roi-settings-modal.component';
import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { RestaurantSetupModalComponent } from ':modules/roi/restaurant-setup-modal/restaurant-setup-modal.component';
import { RoiContext } from ':modules/roi/roi.context';
import { MalouNotification } from ':shared/enums/notification';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-roi-notification-item',
    templateUrl: './roi-notification-item.component.html',
    styleUrls: ['./roi-notification-item.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, TranslateModule, DatePipe, MatButtonModule, ApplyPurePipe, MalouSpinnerComponent, NgTemplateOutlet],
})
export class RoiNotificationItemComponent {
    readonly notification = input.required<MalouNotification>();
    readonly receptionDate = input.required<Date>();

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _screenService = inject(ScreenSizeService);
    private readonly _roiContext = inject(RoiContext);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _notificationCenterContext = inject(NotificationCenterContext);

    readonly notificationTranslationsKey = computed(() => ({
        title: `notifications.${this.notification()}.title`,
        subTitle: `notifications.${this.notification()}.subtitle`,
    }));

    readonly MalouNotification = MalouNotification;

    onNotificationClick(): void {
        switch (this.notification()) {
            case MalouNotification.FINISH_RESTAURANT_SETUP:
            case MalouNotification.ROI_SETTINGS_UPDATED:
                this._openRestaurantSetupDialog();
                break;
            default:
                break;
        }

        this._notificationCenterContext.close();
    }

    private _openRestaurantSetupDialog(): void {
        const restaurantsSettingCount = this._roiContext.restaurantsRoiSettings().length;
        const restaurantWithoutRoiSettingsCount = this._roiContext.restaurantsIdsWithoutRoiSettings().length;
        if (restaurantWithoutRoiSettingsCount > 1 || (restaurantWithoutRoiSettingsCount === 0 && restaurantsSettingCount > 1)) {
            this._customDialogService
                .open(UpdateAggregatedRoiSettingsModalComponent, {
                    panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                    maxWidth: '600px',
                    autoFocus: false,
                    data: {
                        isFromNotification: true,
                    },
                })
                .afterClosed()
                .subscribe((res) => {
                    if (res?.needsToBeUpdated) {
                        this._roiNotificationsContext.emitReload();
                    }
                });
        } else {
            this._customDialogService
                .open(RestaurantSetupModalComponent, {
                    panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                    maxWidth: '600px',
                    autoFocus: false,
                    data: {
                        restaurantId:
                            this._roiContext.restaurantsIdsWithoutRoiSettings()[0] ??
                            this._roiContext.restaurantsRoiSettings()[0]?.restaurantId,
                    },
                })
                .afterClosed()
                .subscribe((res) => {
                    if (res?.needsToBeUpdated) {
                        this._roiNotificationsContext.emitReload();
                    }
                });
        }
    }
}
