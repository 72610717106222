import { Component, Input, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ScreenSizeService } from ':core/services/screen-size.service';

export interface NavigationTab {
    label: string;
    link: string;
    withDot?: boolean;
    isNew?: boolean;
}

@Component({
    selector: 'app-navigation-header',
    templateUrl: './navigation-header.component.html',
    styleUrls: ['./navigation-header.component.scss'],
    standalone: true,
    imports: [MatTabsModule, TranslateModule, RouterLinkActive, RouterLink],
})
export class NavigationHeaderComponent implements OnInit {
    @Input() tabs: NavigationTab[];
    @Input() containerClass = '';

    readonly routerActiveMatchOptions: IsActiveMatchOptions = {
        queryParams: 'ignored',
        matrixParams: 'exact',
        paths: 'subset',
        fragment: 'ignored',
    };

    constructor(public screenSizeService: ScreenSizeService) {}

    ngOnInit(): void {
        setTimeout(() => {
            const target = document.querySelector('.tab-active');
            this.onNavigate({ target } as any as MouseEvent);
        }, 500);
    }

    onNavigate(event: MouseEvent): void {
        const element = event.target as HTMLElement;
        const inkbar = document.querySelector('.ink-bar') as HTMLElement;
        if (!inkbar) {
            return;
        }

        const navContainer = document.querySelector('.mobile-navbar') as HTMLElement;
        navContainer?.scrollTo(element.offsetLeft, 0);

        // move inkbar to the clicked element
        inkbar.style.left = `${element.offsetLeft}px`;
        inkbar.style.width = `${element.offsetWidth}px`;
    }
}
