@if (!screenSizeService.isPhoneScreen) {
    <div class="flex h-full gap-x-4 py-4">
        <div class="flex-2 pl-7.5 md:px-7.5">
            <ng-container *ngTemplateOutlet="postsList"></ng-container>
        </div>
        <div class="max-w-1/3 flex-1 pb-3.5 pr-7">
            <ng-container *ngTemplateOutlet="currentStory"></ng-container>
        </div>
    </div>
} @else {
    <div class="px-3">
        <ng-container *ngTemplateOutlet="loading() ? loader : stories()?.length === 0 ? noPosts : postsList"></ng-container>
    </div>
    <div class="px-7">
        <ng-container *ngTemplateOutlet="actionsBar"></ng-container>
    </div>
}

<ng-template #actionsBar>
    <div class="flex items-center justify-end gap-x-4 pb-3 md:w-full">
        <div [matTooltip]="selectedStories.length === 0 ? ('social-posts-list.select_posts_to_delete' | translate) : ''">
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [matTooltip]="'common.delete' | translate"
                [disabled]="selectedStories.length === 0"
                (click)="deleteSelectedStories()">
                <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
            </button>
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matMenuTriggerFor]="duplicateActions"
            [disabled]="selectedStories.length === 0"
            [matTooltip]="'common.duplicate' | translate">
            <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        </button>
        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [matMenuTriggerFor]="filtersMenu">
            @if (activeFiltersCount > 0) {
                <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                    {{ activeFiltersCount }}
                </span>
            }
            <mat-icon [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
        </button>
        <button
            class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-max md:!hidden"
            mat-raised-button
            [disabled]="!canCreateStory()"
            (click)="createNewStory()">
            {{ 'stories.create_story' | translate }}
        </button>
        <button class="malou-btn-icon--primary btn-xl !hidden md:!flex" mat-icon-button (click)="createNewStory()">
            <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
        </button>
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matTooltip]="'common.synchronize' | translate"
            [disabled]="!(isAnyPlatformConnected$ | async)"
            (click)="synchronize()">
            <mat-icon [svgIcon]="SvgIcon.SYNC"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #postsList>
    <div class="flex h-full flex-col">
        <ng-container *ngTemplateOutlet="actionsBar"></ng-container>
        <div class="min-h-0 grow">
            <ng-container *ngTemplateOutlet="loading() ? loader : stories()?.length === 0 ? noPosts : list"></ng-container>
        </div>
    </div>
    <ng-template #list>
        <div
            class="hide-scrollbar h-full w-full overflow-y-auto pb-[100px] md:pb-[280px]"
            infinite-scroll
            [infiniteScrollContainer]="scrollContainer"
            (scrolled)="onScrollDown()"
            #scrollContainer>
            <div class="flex flex-col items-center gap-y-3">
                @for (story of stories(); track trackByIdFn($index, story)) {
                    <div class="w-full overflow-hidden">
                        <app-story
                            [story]="story"
                            (storyChecked)="storyChecked($event, story)"
                            (editStoryEvent)="onEditStory($event)"
                            (deleteStoryEvent)="onDeleteStory($event)"
                            (duplicate)="duplicateSingleStory($event)"></app-story>
                    </div>
                }

                @if (shouldDisplayNoMoreResultsText) {
                    <div class="no-more-results malou-text-12--regular mt-4 flex items-end gap-x-3 italic text-malou-color-text-2">
                        <span>
                            {{ 'social_posts.no_more_results' | translate }}
                        </span>
                        <span> <img height="30" width="30" [src]="Illustration.Goggles | illustrationPathResolver" /></span>
                    </div>
                }
            </div>
        </div>
    </ng-template>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #filtersMenu="matMenu">
    <div class="w-[440px] p-4 md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="activeFiltersCount === 0" (click)="resetFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>

        <div class="mt-4 flex flex-col gap-y-5 md:h-d-70">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.period' | translate }}
        </div>
        <app-grouped-date-filters
            [startDate]="(start$ | async)!"
            [endDate]="(end$ | async)!"
            [periodOptions]="periodOptions"
            [defaultPeriod]="(period$ | async)!"
            [withLabel]="false"
            (chooseBoundaryDate)="editPostsFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.status' | translate }}
        </div>
        <app-is-published-filters [initialValue$]="postFilterStatus$" (changeOption)="editPostsFilters({ publicationStatus: $event })">
        </app-is-published-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.platforms' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (platform of availablePlatforms(); track trackByKeyFn($index, platform)) {
                <div [matTooltip]="!platform.connected ? ('common.platform_disconnected' | translate) : ''">
                    <button
                        matRipple
                        [disabled]="!platform.connected"
                        (click)="toggleSelectedPlatforms(platform.key, availablePlatforms())">
                        <app-platform-logo
                            imgClasses="h-[29px] w-[29px]"
                            [dynamicClasses]="{ 'opacity-50': !platform.checked || !platform.connected }"
                            [logo]="platform.key"></app-platform-logo>
                    </button>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #currentStory>
    <app-story-carousel
        [stories]="(currentStories$ | async) ?? []"
        [igAccountName]="(igUserName$ | async) ?? ''"
        [profilePictureUrl]="(igPictureUrl$ | async) ?? null"></app-story-carousel>
</ng-template>

<ng-template #loader>
    <div class="hide-scrollbar h-full overflow-y-auto pt-5">
        @for (item of [1, 2, 3, 4, 5]; track item) {
            <div>
                <div class="mb-4 h-[200px] w-full">
                    <app-post-skeleton></app-post-skeleton>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #noPosts>
    <div class="grid h-full min-h-[70vh] w-full place-items-center">
        <div class="flex w-full flex-col items-center gap-y-6">
            <img height="100" width="100" [src]="Illustration.Icecream | illustrationPathResolver" />
            <div class="malou-text-14--bold text-center">
                {{ 'stories.no_stories_headline' | translate }}
                <div class="malou-text-10--regular mt-3 text-center">
                    {{ 'stories.no_stories_caption' | translate }}
                </div>
            </div>
            <button class="malou-btn-raised--primary" mat-raised-button [disabled]="!canCreateStory()" (click)="createNewStory()">
                {{ 'stories.create_story' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #duplicateActions="matMenu">
    <button mat-menu-item (click)="duplicateSelectedStories(DuplicationDestination.HERE)">
        <span>{{ 'common.here' | translate }}</span>
    </button>
    <button mat-menu-item (click)="duplicateSelectedStories(DuplicationDestination.OUT)">
        <span>{{ 'common.to_other_venues' | translate }}</span>
    </button>
</mat-menu>
