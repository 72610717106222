import { ChangeDetectionStrategy, Component, effect, input, output, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { AggregatedInformationUpdateProviderKey, YextPublisherId } from '@malou-io/package-utils';

import { InformationUpdateState } from ':modules/informations/informations-updates-state/information-update-state.interface';
import { InformationUpdateDetailErrorComponent } from ':modules/informations/informations-updates-state/information-updates-state-modal/information-update-errors-list/information-update-detail-error/information-update-detail-error.component';
import { InformationUpdateStateErrorComponent } from ':modules/informations/informations-updates-state/information-updates-state-modal/information-update-errors-list/information-update-state-error/information-update-state-error.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { YextPlatformLogoComponent } from ':shared/components/yext-platform-logo/yext-platform-logo.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-information-update-errors-list',
    templateUrl: './information-update-errors-list.component.html',
    styleUrls: ['./information-update-errors-list.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatTableModule,
        MatSortModule,
        TranslateModule,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        InformationUpdateDetailErrorComponent,
        InformationUpdateStateErrorComponent,
        PlatformLogoComponent,
        YextPlatformLogoComponent,
        ApplyPurePipe,
        PluralTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateErrorsListComponent {
    readonly nbFailedUpdates = input.required<number>();
    readonly informationUpdateErrors = input.required<InformationUpdateState[]>();
    readonly close = output<void>();

    readonly sort = viewChild<MatSort>('errorUpdatesSort');

    readonly errorUpdatesSource = new MatTableDataSource<InformationUpdateState>([]);
    readonly errorUpdatesDisplayedColumns: string[] = ['platformKey', 'status', 'details'];

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    constructor() {
        effect(() => {
            const sort = this.sort();
            if (sort) {
                this.errorUpdatesSource.sort = sort;
            }
        });

        effect(
            () => {
                this.errorUpdatesSource.data = this.informationUpdateErrors();
            },
            { allowSignalWrites: true }
        );
    }

    isYextKey(key: AggregatedInformationUpdateProviderKey): key is YextPublisherId {
        return InformationUpdateState.isYextKey(key);
    }
}
