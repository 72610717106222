import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';

import { isNotNilOrEmpty, PlatformKey } from '@malou-io/package-utils';

import { TikTokConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-tiktok-modal/tiktok-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { GetOauthUrlService } from ':modules/platforms/platforms-connection-modals/shared/services/get-oauth-url.service';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { QrCode } from ':shared/helpers/qr-code';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-tiktok-connection-step-0',
    templateUrl: './tiktok-connection-step-0.component.html',
    styleUrls: ['./tiktok-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, ImagePathResolverPipe, ReactiveFormsModule, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TikTokConnectionStep0Component extends BaseStepComponent<undefined, TikTokConnectionModalResult> {
    private readonly _getOauthUrlService = inject(GetOauthUrlService);

    readonly ButtonStyle = ButtonStyle;

    readonly qrCode = new QrCode();
    readonly tikTokOauthUrl = signal<string>('');
    readonly tikTokOauthUrl$ = toObservable(this.tikTokOauthUrl);
    readonly qrCodeImage$ = this.tikTokOauthUrl$.pipe(
        filter(isNotNilOrEmpty),
        switchMap((url) => this.qrCode.generateQrCode$(url))
    );
    readonly qrCodeImage = toSignal(this.qrCodeImage$);

    constructor() {
        super();
        this._getOauthUrlService.execute(PlatformKey.TIKTOK).subscribe((url) => {
            this.tikTokOauthUrl.set(url);
        });
    }

    onConnectWithTikTok(): void {
        const tikTokOauthUrl = this.tikTokOauthUrl();
        if (tikTokOauthUrl) {
            window.location.href = tikTokOauthUrl;
        }
    }

    onPrimaryClick(): void {
        // TODO tutoriel ?
    }
}
