import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { AiService } from ':core/services/ai.service';
import { OrganizationsService } from ':core/services/organizations.service';
import { PostsService } from ':core/services/posts.service';
import * as jimoActions from ':modules/jimo/jimo.actions';
import * as userActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';

@Injectable()
export class JimoEffect {
    loadUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(userActions.editUserInfos),
            mergeMap(() =>
                forkJoin([
                    this._store.select(selectUserInfos).pipe(filter(isNotNil), take(1)),
                    this._aiService.getUserAiInteractionCount(),
                    this._postsService.getUserStoryCreatedCount(),
                    this._organizationsService.getRestaurantCountInOrganizationsForUser(),
                    this._organizationsService.getUserCountInOrganizationsForUser(),
                    this._wheelsOfFortuneService.getUserWheelOfFortuneInformations(),
                ]).pipe(
                    tap(([user]) => (window['JIMO_SESSION_TOKEN'] = user._id)),
                    map(
                        ([
                            user,
                            aiInteractionCount,
                            storyCreatedCount,
                            restaurantCountInOrganizations,
                            userCountInOrganizations,
                            userWheelOfFortune,
                        ]) =>
                            jimoActions.initJimoUserAttributes({
                                user,
                                aiInteractionCount: aiInteractionCount.data,
                                storyCreatedCount: storyCreatedCount.data,
                                restaurantCountInOrganizations: restaurantCountInOrganizations.data,
                                userCountInOrganizations: userCountInOrganizations.data,
                                hasCreatedAWheel: userWheelOfFortune.data.hasCreatedAWheel,
                                firstWheelCreatedAt: userWheelOfFortune.data.firstWheelCreatedAt,
                                hasAtLeastOneActiveOrProgrammedWheel: userWheelOfFortune.data.hasAtLeastOneActiveOrProgrammedWheel,
                                latestEndDateForActiveWheels: userWheelOfFortune.data.latestEndDateForActiveWheels,
                            })
                    ),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _aiService: AiService,
        private readonly _postsService: PostsService,
        private readonly _organizationsService: OrganizationsService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _store: Store
    ) {}
}
