import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { SelectPlatformComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/duplicate-and-select-platforms/select-platform/select-platform.component';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-duplicate-and-select-platforms',
    templateUrl: './duplicate-and-select-platforms.component.html',
    styleUrls: ['./duplicate-and-select-platforms.component.scss'],
    standalone: true,
    imports: [TranslateModule, SelectPlatformComponent, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateAndSelectPlatformsComponent {
    readonly isReel = input<boolean>(false);

    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);

    readonly socialPlatformKeys = computed(() => this._getSocialPlatformKeys(this.isReel()));
    readonly seoPlatformKeys = signal([PlatformKey.GMB]);

    readonly connectedSocialPlatformKeys = computed(() =>
        this._upsertSocialPostContext.upsertSocialPostState.connectedSocialPlatforms().map((p) => p.key)
    );
    readonly selectedSocialPlatforms = this._upsertSocialPostContext.upsertSocialPostState.post.platformKeys;

    isSelected = (platformKey: PlatformKey, selectedSocialPlatforms: PlatformKey[]): boolean =>
        selectedSocialPlatforms.includes(platformKey);
    isConnected = (platformKey: PlatformKey, connectedSocialPlatformKeys: PlatformKey[]): boolean =>
        connectedSocialPlatformKeys.includes(platformKey);

    onSelectChange(platformKey: PlatformKey, selected: boolean): void {
        const selectedSocialPlatforms = this.selectedSocialPlatforms();
        const updatedSocialPlatforms = selected
            ? [...selectedSocialPlatforms, platformKey]
            : selectedSocialPlatforms.filter((key) => key !== platformKey);

        this._upsertSocialPostContext.selectPlatforms(updatedSocialPlatforms);
    }

    private _getSocialPlatformKeys(isReel: boolean): PlatformKey[] {
        const platformKeys = isReel ? PlatformDefinitions.getPlatformKeysWithReel() : PlatformDefinitions.getSocialPlatformKeys();

        const featureFlaggedPlatforms = PlatformDefinitions.getFeatureFlaggedPlatforms();
        const featureFlaggedPlatformKeysToHide = featureFlaggedPlatforms
            .filter((p) => !this._experimentationService.isFeatureEnabled(p.featureFlagKey!))
            .map((p) => p.key);

        return platformKeys.filter((platformKey) => !featureFlaggedPlatformKeysToHide.includes(platformKey));
    }
}
