import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from ':environments/environment';
import { ApiResult } from ':shared/models';

import { User } from '../user/user';

@Injectable({ providedIn: 'root' })
export class AdminService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/`;

    constructor(private readonly _http: HttpClient) {}

    createAccount(user: Partial<User>): Observable<any> {
        return this._http.post(this.API_BASE_URL + 'users/new_account', { user });
    }

    updateAccount(userId: string, params: any): Observable<any> {
        return this._http.put(this.API_BASE_URL + 'users/admin/' + userId, params);
    }

    deleteAccount(userId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/users/${userId}`);
    }
}
