import { Component, Input } from '@angular/core';

import { Description, DescriptionSize } from ':shared/models/description';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-comparison-description',
    templateUrl: './comparison-description.component.html',
    styleUrls: ['./comparison-description.component.scss'],
    standalone: true,
    imports: [EnumTranslatePipe],
})
export class ComparisonDescriptionComponent {
    @Input() public descriptions: Description[];

    DescriptionSize = DescriptionSize;
}
