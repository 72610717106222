import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy, uniqBy } from 'lodash';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import { PlatformKey, WHEEL_OF_FORTUNE_NEEDED_ROLES, WheelOfFortuneRedirectionPlatformKey } from '@malou-io/package-utils';

import { UserRestaurant } from ':modules/user/user';
import { SubscriptionRequestModalComponent } from ':modules/wheels-of-fortune/subscription-request-modal/subscription-request-modal.component';
import {
    AppRedirection,
    CloseModalSettings,
} from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/upsert-wheel-of-fortune-modal.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { getNfcWithRestaurantDisplayName, LightRestaurant, Restaurant, RestaurantWithTotemsAndWheels } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-restaurant-selection-wheel-of-fortune',
    templateUrl: './restaurant-selection-wheel-of-fortune.component.html',
    styleUrls: ['./restaurant-selection-wheel-of-fortune.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        MatCheckboxModule,
        TranslateModule,
        SelectBaseComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantSelectionWheelOfFortuneComponent implements OnInit {
    @Input() selectedRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]>;
    @Input() selectedTotems: WritableSignal<NfcWithRestaurantDto[]>;
    @Input() allRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]>;
    @Input() nonOwnedRestaurants: WritableSignal<LightRestaurant[]>;
    @Input() nonOwnedSelectedTotems: WritableSignal<NfcWithRestaurantDto[]>;
    @Input() selectedRedirectionPlatforms: Signal<WheelOfFortuneRedirectionPlatformKey[]>;
    @Input() userRestaurants: WritableSignal<UserRestaurant[]>;
    @Input() isUpdate: boolean;
    @Output() onClose = new EventEmitter<CloseModalSettings>();

    readonly enumTranslatePipe = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    readonly AppRedirection = AppRedirection;
    readonly WHEEL_OF_FORTUNE_NEEDED_ROLES = WHEEL_OF_FORTUNE_NEEDED_ROLES;

    readonly sortedRestaurants = computed(() =>
        this.allRestaurants().sort((restaurantA, restaurantB) => {
            if (this.isSelectable()(restaurantA)) {
                return this.isSelectable()(restaurantB)
                    ? restaurantA.getDisplayedValue().localeCompare(restaurantB.getDisplayedValue())
                    : -1;
            }
            if (this.isSelectable()(restaurantB)) {
                return 1;
            }

            if (restaurantA.hasNoWheel()) {
                return restaurantB.hasNoWheel() ? restaurantA.getDisplayedValue().localeCompare(restaurantB.getDisplayedValue()) : -1;
            }

            return restaurantB.hasNoWheel() ? 1 : restaurantA.getDisplayedValue().localeCompare(restaurantB.getDisplayedValue());
        })
    );
    readonly sortedNonOwnedRestaurants = computed(() =>
        this.nonOwnedRestaurants().sort((restaurantA, restaurantB) =>
            restaurantA.getDisplayName().localeCompare(restaurantB.getDisplayName())
        )
    );

    readonly isSelectable = computed(
        () =>
            (restaurant: RestaurantWithTotemsAndWheels): boolean =>
                restaurant.hasNoOtherWheel(this._initialSelectedRestaurantIds().includes(restaurant._id)) &&
                this.isSelectableBasedOnRedirectionPlatforms()(restaurant) &&
                this.isSelectableBasedOnCaslRoles()(restaurant) &&
                restaurant.boosterPack?.activated
    );

    readonly isSelectableBasedOnCaslRoles = computed(() => (restaurant: RestaurantWithTotemsAndWheels): boolean => {
        const userRestaurant = this.userRestaurants().find((ur) => ur.restaurantId === restaurant.id);
        return !!userRestaurant?.caslRole && WHEEL_OF_FORTUNE_NEEDED_ROLES.includes(userRestaurant.caslRole);
    });

    readonly invalidRedirectionPlatformsForRestaurant = computed(
        () =>
            (restaurant: RestaurantWithTotemsAndWheels): WheelOfFortuneRedirectionPlatformKey[] =>
                this.selectedRedirectionPlatforms().filter(
                    (platform) =>
                        ![PlatformKey.INSTAGRAM, WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION].includes(platform) &&
                        restaurant.missingPermissionKeys.includes(platform)
                )
    );
    readonly invalidRedirectionPlatformsForRestaurantAsString = computed(
        () =>
            (restaurant: RestaurantWithTotemsAndWheels): string =>
                this.invalidRedirectionPlatformsForRestaurant()(restaurant)
                    .map((platform) => this.enumTranslatePipe.transform(platform, 'platform_key'))
                    .join(', ')
    );

    readonly isSelectableBasedOnRedirectionPlatforms = computed(
        () =>
            (restaurant: RestaurantWithTotemsAndWheels): boolean =>
                this.invalidRedirectionPlatformsForRestaurant()(restaurant).length === 0
    );

    private readonly _initialSelectedRestaurantIds: WritableSignal<string[]> = signal([]);

    private readonly _selectableRestaurants: Signal<RestaurantWithTotemsAndWheels[]> = computed(() =>
        this.allRestaurants().filter((restaurant) => this.isSelectable()(restaurant))
    );

    readonly areAllSelected: Signal<boolean> = computed(() => this._selectableRestaurants().length === this.selectedRestaurants().length);

    readonly selectedRestaurantIds: Signal<string[]> = computed(() => this.selectedRestaurants().map(({ _id }) => _id));

    readonly totemsControls: { [key: string]: FormControl<NfcWithRestaurantDto[]> } = {};
    readonly getDisplayedTotems: Signal<(restaurantId: string) => NfcWithRestaurantDto[]> = computed(() => {
        const selectedTotems = this.selectedTotems();
        return (restaurantId: string): NfcWithRestaurantDto[] =>
            this.totemsControls[restaurantId]?.value?.filter((totem) => selectedTotems.includes(totem)) || [];
    });

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _customDialogService: CustomDialogService
    ) {}

    ngOnInit(): void {
        this._initializeSelectedTotems();
        if (this.isUpdate) {
            this._initialSelectedRestaurantIds.set(this.selectedRestaurants().map((restaurant) => restaurant._id));
        }
    }

    readonly totemsSortBy = (a: NfcWithRestaurantDto, b: NfcWithRestaurantDto): number =>
        getNfcWithRestaurantDisplayName(a) > getNfcWithRestaurantDisplayName(b) ? 1 : -1;
    readonly totemDisplayWith = (option: NfcWithRestaurantDto): string =>
        option.chipName
            ? [option.name || '', option.chipName].filter(Boolean).join(' - ')
            : this._translateService.instant('admin.nfcs.type.sticker');

    isSelected = (restaurant: RestaurantWithTotemsAndWheels): boolean =>
        !!this.selectedRestaurants().find((rest) => rest._id === restaurant._id);

    toggleAllRows(): void {
        if (this.areAllSelected()) {
            this.selectedRestaurants.set([]);
            return;
        }
        this.selectedRestaurants.set(this._selectableRestaurants());
    }

    onRestaurantSelectionChange(event: MatCheckboxChange, restaurant: RestaurantWithTotemsAndWheels): void {
        if (event.checked) {
            this.selectedRestaurants.update((selectedRestaurants) => [...selectedRestaurants, restaurant]);
        } else {
            this.selectedRestaurants.update((selectedRestaurants) => selectedRestaurants.filter(({ _id }) => _id !== restaurant._id));
        }
        this._updateSelectedTotems();
    }

    onTotemSelectionChange(event: NfcWithRestaurantDto[], restaurantId: string): void {
        this.totemsControls[restaurantId].setValue(event);
        this._updateSelectedTotems();
    }

    close({
        shouldCheckBeforeClose = false,
        restaurantId,
        restaurantIdInAggregatedWheelOfFortune,
        redirection = AppRedirection.TOTEMS,
    }: CloseModalSettings): void {
        this.onClose.emit({ shouldCheckBeforeClose, restaurantId, restaurantIdInAggregatedWheelOfFortune, redirection });
    }

    sendSubscriptionRequest(restaurant: Restaurant): void {
        this._customDialogService.open(SubscriptionRequestModalComponent, {
            height: '80vh',
            disableClose: false,
            data: { restaurants: [restaurant] },
        });
    }

    private _updateSelectedTotems(): void {
        this.selectedTotems.set(
            uniqBy(
                [
                    ...Object.entries(this.totemsControls)
                        .filter(([key, _]) =>
                            this.selectedRestaurants()
                                .map(({ id }) => id)
                                .includes(key)
                        )
                        .map(([_, control]) => control.value)
                        .flat(),
                    ...this.nonOwnedSelectedTotems(),
                ],
                'id'
            )
        );
    }

    private _initializeSelectedTotems(): void {
        const totemsByRestaurant = groupBy(this.selectedTotems(), 'restaurantId');
        this.allRestaurants().forEach(
            (restaurant) =>
                (this.totemsControls[restaurant.id] = new FormControl(totemsByRestaurant[restaurant._id] || []) as FormControl<
                    NfcWithRestaurantDto[]
                >)
        );
    }
}
