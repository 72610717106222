import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, forwardRef, Injector, input, output, Signal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { formatDate, isTodayInFirstThreeDaysOfMonth } from ':shared/helpers';
import { getMalouPeriodFromNumberOfMonths, getSelectedMonthsNumberFromTimeScaleFilter, MalouTimeScalePeriod } from ':shared/models';

import { ControlValueAccessorConnectorComponent } from '../control-value-accessor-connector/control-value-accessor-connector';
import { SelectComponent } from '../select/select.component';

@Component({
    selector: 'app-select-time-scale-filter',
    templateUrl: './select-time-scale-filter.component.html',
    styleUrls: ['./select-time-scale-filter.component.scss'],
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectTimeScaleFilterComponent),
        },
    ],
    imports: [NgClass, NgTemplateOutlet, SelectComponent, TranslateModule, MatTooltipModule],
})
export class SelectTimeScaleFilterComponent extends ControlValueAccessorConnectorComponent {
    readonly minAcceptedDate = input<Date | null>();
    readonly selectTimeScaleChange = output<MalouTimeScalePeriod>();

    readonly timeScaleList: Signal<MalouTimeScalePeriod[]> = computed(() => {
        const defaultPeriod = [
            MalouTimeScalePeriod.LAST_THREE_MONTHS,
            MalouTimeScalePeriod.LAST_SIX_MONTHS,
            MalouTimeScalePeriod.LAST_TWELVE_MONTHS,
        ];
        const minDate = this.minAcceptedDate();
        if (!minDate) {
            return defaultPeriod;
        }
        const minDatetime = DateTime.fromJSDate(new Date(minDate));
        const referenceDate = !isTodayInFirstThreeDaysOfMonth() ? DateTime.now().minus({ days: 4 }) : DateTime.now();
        const endDate = referenceDate.minus({ month: 1 }).endOf('month').endOf('day');
        const monthsDifference = Math.trunc(endDate.diff(minDatetime, 'months').months ?? 0);
        if (monthsDifference <= 0) {
            this.selectTimesScaleChange(MalouTimeScalePeriod.LAST_MONTH);
            return [MalouTimeScalePeriod.LAST_MONTH];
        }
        if (monthsDifference >= 12) {
            this.selectTimesScaleChange(MalouTimeScalePeriod.LAST_SIX_MONTHS);
            return defaultPeriod;
        }
        const period = getMalouPeriodFromNumberOfMonths(monthsDifference);
        if (monthsDifference >= 6) {
            this.selectTimesScaleChange(MalouTimeScalePeriod.LAST_SIX_MONTHS);
            return period === MalouTimeScalePeriod.LAST_SIX_MONTHS
                ? [MalouTimeScalePeriod.LAST_THREE_MONTHS, MalouTimeScalePeriod.LAST_SIX_MONTHS]
                : [MalouTimeScalePeriod.LAST_THREE_MONTHS, MalouTimeScalePeriod.LAST_SIX_MONTHS, period];
        }

        if (monthsDifference >= 3) {
            this.selectTimesScaleChange(MalouTimeScalePeriod.LAST_THREE_MONTHS);
            return period === MalouTimeScalePeriod.LAST_THREE_MONTHS
                ? [MalouTimeScalePeriod.LAST_THREE_MONTHS]
                : [MalouTimeScalePeriod.LAST_THREE_MONTHS, period];
        }
        this.selectTimesScaleChange(period);
        return [period];
    });

    constructor(
        protected readonly injector: Injector,
        private readonly _translateService: TranslateService
    ) {
        super(injector);
    }

    selectTimesScaleChange(value: MalouTimeScalePeriod): void {
        this.selectTimeScaleChange.emit(value);
    }

    displayPeriod = (value: MalouTimeScalePeriod | string): string => this._translateService.instant('date_overview.' + value);

    displayPeriodDetail = (value: MalouTimeScalePeriod | string): string => {
        const monthsNumber = getSelectedMonthsNumberFromTimeScaleFilter(value as MalouTimeScalePeriod);
        if (!monthsNumber) {
            return '';
        }
        const endDate = DateTime.now().minus({ month: 1 }).endOf('month').endOf('day');
        const startDate = endDate.minus({ month: monthsNumber }).plus({ second: 1 });
        const subtitle = `(${this.customDisplayDate(startDate)} - ${this.customDisplayDate(endDate)})`;
        return subtitle;
    };

    customDisplayDate(date: DateTime): string {
        return formatDate(date.toJSDate(), false).replace(/\//g, '.');
    }
}
