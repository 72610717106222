import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, effect, inject, input, OnDestroy, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss'],
    standalone: true,
    imports: [MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopComponent implements AfterViewInit, OnDestroy {
    readonly container = input.required<string>();
    readonly trigger$ = input<Observable<void>>();

    readonly SvgIcon = SvgIcon;

    readonly windowScrolled = signal(false);
    readonly viewInitialized = signal(false);

    private _containerElement: HTMLElement;

    private readonly _destroyRef = inject(DestroyRef);

    constructor() {
        effect(() => {
            const trigger$ = this.trigger$();
            if (this.viewInitialized() && trigger$) {
                trigger$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => this.scrollToTop());
            }
        });
    }

    ngAfterViewInit(): void {
        this._containerElement = document.querySelector(this.container()) as HTMLElement;
        window.addEventListener('scroll', this._onScroll.bind(this), true);
        this.viewInitialized.set(true);
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this._onScroll.bind(this), true);
    }

    scrollToTop(): void {
        this._containerElement.scrollTo({
            top: this._containerElement.clientTop,
            behavior: 'smooth',
        });
    }

    private _onScroll(): void {
        if (this._containerElement?.scrollTop > 100) {
            this.windowScrolled.set(true);
        } else {
            this.windowScrolled.set(false);
        }
    }
}
