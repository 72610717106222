import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { CsvInsightChart, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import {
    AggregatedAverageReviewsRatingsCsvInsightsService,
    AggregatedFollowersCsvInsightsService,
    AggregatedGmbVisibilityCsvInsightsService,
    AggregatedPublicationsCsvInsightsService,
    AggregatedReviewCountCsvInsightsService,
    AggregatedStoriesCsvInsightsService,
    PlatformsRatingsAggregatedCsvInsightsService,
} from ':shared/services/csv-services/aggregated-insights';
import { AggregatedBoostersGiftsCsvInsightService } from ':shared/services/csv-services/aggregated-insights/aggregated-boosters-gifts.service';
import { AggregatedBoostersReviewsCountCsvInsightService } from ':shared/services/csv-services/aggregated-insights/aggregated-boosters-reviews-count.service';
import { AggregatedBoostersScanCountCsvInsightService } from ':shared/services/csv-services/aggregated-insights/aggregated-boosters-scan-count.service';
import { CsvAsStringArrays, CsvService } from ':shared/services/csv-services/csv-service.abstract';
import { CsvStringCreator } from ':shared/services/csv-services/helpers/create-csv-string';
import {
    BoostersGiftsCsvInsightService,
    BoostersReviewsCountCsvInsightService,
    BoostersScanCountCsvInsightService,
    FollowersCsvInsightsService,
    GmbVisibilityCsvInsightsService,
    KeywordsCsvInsightsService,
    PlatformsRatingsCsvInsightsService,
    PublicationsCsvInsightsService,
    ReviewsRatingsEvolutionCsvInsightsService,
    ReviewsRatingsTotalCsvInsightsService,
    StoryCsvInsightsService,
} from ':shared/services/csv-services/insights';

@Injectable({ providedIn: 'root' })
export class DownloadCsvInsightsService {
    constructor(
        @Inject(KeywordsCsvInsightsService)
        private readonly _keywordsCsvInsightsService: CsvService,
        @Inject(GmbVisibilityCsvInsightsService)
        private readonly _gmbVisibilityCsvInsightsService: CsvService,
        @Inject(AggregatedGmbVisibilityCsvInsightsService)
        private readonly _aggregatedGmbVisibilityCsvInsightsService: CsvService,
        @Inject(PublicationsCsvInsightsService)
        private readonly _publicationsCsvInsightsService: CsvService,
        @Inject(StoryCsvInsightsService)
        private readonly _storiesCsvInsightsService: CsvService,
        @Inject(PlatformsRatingsCsvInsightsService)
        private readonly _platformsRatingsCsvInsightsService: CsvService,
        @Inject(FollowersCsvInsightsService)
        private readonly _followersCsvInsightsService: CsvService,
        @Inject(PlatformsRatingsAggregatedCsvInsightsService)
        private readonly _platformsRatingsAggregatedCsvInsightsService: CsvService,
        @Inject(AggregatedStoriesCsvInsightsService)
        private readonly _aggregatedStoriesCsvInsightsService: CsvService,
        @Inject(AggregatedFollowersCsvInsightsService)
        private readonly _aggregatedFollowersCsvInsightsService: CsvService,
        @Inject(AggregatedPublicationsCsvInsightsService)
        private readonly _aggregatedPublicationsCsvInsightsService: CsvService,
        @Inject(AggregatedReviewCountCsvInsightsService)
        private readonly _aggregatedReviewCountCsvInsightsService: CsvService,
        @Inject(AggregatedAverageReviewsRatingsCsvInsightsService)
        private readonly _aggregatedAverageReviewsRatingsCsvInsightsService: CsvService,
        @Inject(ReviewsRatingsEvolutionCsvInsightsService)
        private readonly _reviewsRatingsEvolutionCsvInsightsService: CsvService,
        @Inject(ReviewsRatingsTotalCsvInsightsService)
        private readonly _reviewsRatingsTotalCsvInsightsService: CsvService,
        @Inject(BoostersScanCountCsvInsightService)
        private readonly _boostersScanCountInsightsService: CsvService,
        @Inject(BoostersReviewsCountCsvInsightService)
        private readonly _boostersReviewsCountInsightsService: CsvService,
        @Inject(BoostersGiftsCsvInsightService)
        private readonly _boostersGiftsInsightsService: CsvService,
        @Inject(AggregatedBoostersScanCountCsvInsightService)
        private readonly _aggregatedBoostersScanCountCsvInsightService: CsvService,
        @Inject(AggregatedBoostersGiftsCsvInsightService)
        private readonly _aggregatedBoostersGiftsCsvInsightsService: CsvService,
        @Inject(AggregatedBoostersReviewsCountCsvInsightService)
        private readonly _aggregatedBoostersReviewsCountCsvInsightService: CsvService,
        private readonly _csvStringCreator: CsvStringCreator
    ) {}

    getCsvInsightsData$(csvChart: CsvInsightChart): Observable<string | null> {
        let csvData$: Observable<CsvAsStringArrays | null>;
        switch (csvChart) {
            case CsvInsightChart.KEYWORDS:
                csvData$ = this._keywordsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.GMB_VISIBILITY:
                csvData$ = this._gmbVisibilityCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_GMB_VISIBILITY:
                csvData$ = this._aggregatedGmbVisibilityCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.PUBLICATIONS:
                csvData$ = this._publicationsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.STORIES:
                csvData$ = this._storiesCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.ALL_FOLLOWERS:
                csvData$ = this._followersCsvInsightsService.getCsvData$({
                    platformKeys: PlatformDefinitions.getPlatformKeysWithFollowers(),
                });
                break;
            case CsvInsightChart.FB_FOLLOWERS:
                csvData$ = this._followersCsvInsightsService.getCsvData$({ platformKeys: [PlatformKey.FACEBOOK] });
                break;
            case CsvInsightChart.IG_FOLLOWERS:
                csvData$ = this._followersCsvInsightsService.getCsvData$({ platformKeys: [PlatformKey.INSTAGRAM] });
                break;
            case CsvInsightChart.PLATFORMS_RATINGS:
                csvData$ = this._platformsRatingsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.BOOSTERS_SCAN_COUNT:
                csvData$ = this._boostersScanCountInsightsService.getCsvData$();
                break;
            case CsvInsightChart.BOOSTERS_REVIEWS_COUNT:
                csvData$ = this._boostersReviewsCountInsightsService.getCsvData$();
                break;
            case CsvInsightChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION:
                csvData$ = this._boostersGiftsInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_PLATFORMS_RATINGS:
                csvData$ = this._platformsRatingsAggregatedCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_STORIES:
                csvData$ = this._aggregatedStoriesCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_ALL_FOLLOWERS:
                csvData$ = this._aggregatedFollowersCsvInsightsService.getCsvData$({
                    platformKeys: PlatformDefinitions.getPlatformKeysWithFollowers(),
                });
                break;
            case CsvInsightChart.AGGREGATED_FB_FOLLOWERS:
                csvData$ = this._aggregatedFollowersCsvInsightsService.getCsvData$({ platformKeys: [PlatformKey.FACEBOOK] });
                break;
            case CsvInsightChart.AGGREGATED_IG_FOLLOWERS:
                csvData$ = this._aggregatedFollowersCsvInsightsService.getCsvData$({ platformKeys: [PlatformKey.INSTAGRAM] });
                break;
            case CsvInsightChart.AGGREGATED_PUBLICATIONS:
                csvData$ = this._aggregatedPublicationsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_REVIEW_COUNT:
                csvData$ = this._aggregatedReviewCountCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_AVERAGE_REVIEWS_RATINGS:
                csvData$ = this._aggregatedAverageReviewsRatingsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_BOOSTERS_SCAN_COUNT:
                csvData$ = this._aggregatedBoostersScanCountCsvInsightService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION:
                csvData$ = this._aggregatedBoostersGiftsCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.AGGREGATED_BOOSTERS_REVIEWS_COUNT:
                csvData$ = this._aggregatedBoostersReviewsCountCsvInsightService.getCsvData$();
                break;
            case CsvInsightChart.REVIEWS_RATINGS_EVOLUTION:
                csvData$ = this._reviewsRatingsEvolutionCsvInsightsService.getCsvData$();
                break;
            case CsvInsightChart.REVIEWS_RATINGS_TOTAL:
                csvData$ = this._reviewsRatingsTotalCsvInsightsService.getCsvData$();
                break;
            default:
                return of(null);
        }

        return csvData$.pipe(
            map((csvData) => {
                if (!csvData) {
                    return null;
                }
                return this._csvStringCreator.create(csvData);
            })
        );
    }
}
