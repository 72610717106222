import { DatePipe, JsonPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { PostType } from '@malou-io/package-utils';

import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { Post } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

const DEFAULT_MEDIA_DURATION = 5;

@Component({
    selector: 'app-media-duration',
    standalone: true,
    imports: [JsonPipe, MatIconModule, DatePipe, SocialPostMediaComponent, PluralTranslatePipe, EnumTranslatePipe, MatButtonModule],
    templateUrl: './media-duration.component.html',
    styleUrls: ['./media-duration.component.scss'],
})
export class MediaDurationComponent {
    @Input() post: Post;
    @Input() duration = DEFAULT_MEDIA_DURATION;
    mediaTypeToTranslateKey = {
        [PostType.IMAGE]: 'single_image',
        [PostType.VIDEO]: 'single_video',
    };
}
