<div class="mt-3 flex h-full flex-col justify-between">
    <div class="flex flex-col gap-3">
        @for (datePeriod of restaurantSpecialDatePeriods(); track datePeriod) {
            @let title = getTitle | applyPure: datePeriod;
            <app-day-or-date-hours
                [dayOrDateTitle]="title"
                [showStartDate]="!!datePeriod.isFromCalendarEvent"
                [startDate]="startDateToDisplay | applyPure: datePeriod"
                [timePeriods]="datePeriod.periods"
                [largeTitle]="true"
                [isClosed]="datePeriod.isClosed"></app-day-or-date-hours>
        }
    </div>

    @if (!displayedHours().length) {
        <div class="min-h-[200px]">
            <i> -- </i>
        </div>
    }
</div>
