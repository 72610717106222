<div
    class="malou-card !m-0 cursor-pointer !py-4 md:!p-4"
    [class.selected-folder]="isSelected"
    (click)="$event.stopPropagation(); onFolderClick()"
    (pointerdown)="$event.stopPropagation()">
    <div class="malou-card__body flex w-full grow flex-col p-0">
        <div class="flex items-center justify-between">
            <mat-checkbox
                class="!flex"
                color="primary"
                [checked]="isSelected"
                (click)="$event.stopPropagation()"
                (pointerdown)="$event.stopPropagation()"
                (change)="toggleSelection()"></mat-checkbox>
            <div class="malou-text-12--semibold ml-3 flex max-w-[50%] items-center">
                <div class="mr-1 max-w-fit truncate" [matTooltip]="folder.name + ' (' + folder.mediaCount + ')'">
                    {{ folder.name }}
                </div>
                <div>({{ folder.mediaCount }})</div>
            </div>

            @if (!screenSizeService.isPhoneScreen) {
                <button
                    class="malou-btn-icon--secondary btn-xl !ml-auto"
                    mat-icon-button
                    [matMenuTriggerFor]="actionsMenu"
                    (click)="$event.stopPropagation()"
                    (pointerdown)="$event.stopPropagation()">
                    <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                </button>
            }
            @if (screenSizeService.isPhoneScreen) {
                <mat-icon
                    matRipple
                    color="primary"
                    [svgIcon]="SvgIcon.ELLIPSIS"
                    [matMenuTriggerFor]="actionsMenu"
                    (click)="$event.stopPropagation()"
                    (pointerdown)="$event.stopPropagation()"></mat-icon>
            }

            <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                <button class="flex gap-x-3" mat-menu-item (click)="renameFolder()">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.rename' | translate }}</span>
                </button>
                <button class="flex gap-x-3" mat-menu-item [matMenuTriggerFor]="duplicateMenu">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.duplicate' | translate }}</span>
                </button>
                <button class="flex gap-x-3" mat-menu-item (click)="onDownloadFolderClick()">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.download' | translate }}</span>
                </button>
                <button
                    class="flex gap-x-3"
                    mat-menu-item
                    [cdkCopyToClipboard]="getFolderLink | applyPure: folder"
                    (cdkCopyToClipboardCopied)="onCopyToClipboard($event)">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.LINK"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.folder.copy_link' | translate }}</span>
                </button>
                <button class="flex gap-x-3" mat-menu-item (click)="onDeleteFolder()">
                    <mat-icon class="!h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-text-14--regular">{{ 'gallery.delete' | translate }}</span>
                </button>
            </mat-menu>

            <mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
                <button mat-menu-item (click)="onDuplicateFolder(DuplicationDestination.HERE, folder)">
                    <span class="malou-text-14--regular">
                        {{ 'gallery.duplicate_here' | translate }}
                    </span>
                </button>
                <button mat-menu-item (click)="onDuplicateFolder(DuplicationDestination.OUT, folder)">
                    <span class="malou-text-14--regular">
                        {{ 'common.to_other_venues' | translate }}
                    </span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>
