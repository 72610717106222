<div class="malou-dialog malou-dialog-mobile !max-w-[100vw]">
    <div class="malou-dialog__header">
        <span>
            {{ 'gallery.rename_modal_title' | translate }}
        </span>
        <mat-icon class="close" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body">
        <div class="grow">
            <app-input-text
                [required]="true"
                [errorMessage]="mediaName.length === 0 ? ('gallery.rename_input_title_error_message' | translate) : ''"
                [title]="'gallery.rename_input_title' | translate"
                [defaultValue]="mediaName"
                (inputTextChange)="onTextChange($event)"></app-input-text>
        </div>
    </div>
    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button [disabled]="mediaName.length === 0" (click)="rename()">
            {{ 'common.rename' | translate }}
        </button>
    </div>
</div>
