import { Component } from '@angular/core';

import { NfcDisplayMode, SharedNfcComponent } from ':shared/components/shared-nfc/shared-nfc.component';

@Component({
    selector: 'app-nfc',
    templateUrl: './nfc.component.html',
    styleUrls: ['./nfc.component.scss'],
    standalone: true,
    imports: [SharedNfcComponent],
})
export class NfcComponent {
    NfcDisplayMode = NfcDisplayMode;
}
