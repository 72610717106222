import { Component, Injector, Input, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, UntypedFormControl } from '@angular/forms';

@Component({ template: '' })
export abstract class ControlValueAccessorConnectorComponent implements ControlValueAccessor {
    @ViewChild(FormControlDirective, { static: true })
    formControlDirective: FormControlDirective;

    @Input()
    formControl: UntypedFormControl = new UntypedFormControl();

    @Input()
    formControlName: string;

    defaultControl = new UntypedFormControl();

    constructor(protected readonly _injector: Injector) {}

    get control(): FormControl {
        if (this.formControlName) {
            return this.controlContainer?.control?.get(this.formControlName) as FormControl;
        }
        return this.formControl || (this.controlContainer?.control?.get(this.formControlName) as FormControl);
    }

    get controlContainer(): ControlContainer {
        return this._injector.get(ControlContainer);
    }

    registerOnTouched(fn: any): void {
        this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void {
        this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    }

    writeValue(obj: any): void {
        this.formControlDirective?.valueAccessor?.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        // @ts-ignore
        this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
    }
}
