import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { InformationUpdateState } from ':modules/informations/informations-updates-state/information-update-state.interface';
import { InformationUpdateErrorsListComponent } from ':modules/informations/informations-updates-state/information-updates-state-modal/information-update-errors-list/information-update-errors-list.component';
import { InformationUpdateOthersListComponent } from ':modules/informations/informations-updates-state/information-updates-state-modal/information-update-others-list/information-update-others-list.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';

@Component({
    selector: 'app-information-updates-state-modal',
    standalone: true,
    imports: [MatIconModule, TranslateModule, InformationUpdateErrorsListComponent, InformationUpdateOthersListComponent, FormatDatePipe],
    templateUrl: './information-updates-state-modal.component.html',
    styleUrl: './information-updates-state-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdatesStateModalComponent implements OnInit {
    readonly latestValidatedAt = signal(new Date());
    readonly isAboutToSendUpdates = signal(false);
    readonly errorUpdateCount = signal(0);
    readonly otherUpdateCount = signal(0);

    readonly SvgIcon = SvgIcon;

    constructor(
        private readonly _dialogRef: MatDialogRef<InformationUpdatesStateModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            informationUpdateOthers: InformationUpdateState[];
            informationUpdateErrors: InformationUpdateState[];
            latestValidatedAt: Date;
            isAboutToSendUpdates: boolean;
        }
    ) {}

    ngOnInit(): void {
        this.errorUpdateCount.set(this.data.informationUpdateErrors.length);
        this.otherUpdateCount.set(this.data.informationUpdateOthers.length);
        this.latestValidatedAt.set(this.data.latestValidatedAt);
        this.isAboutToSendUpdates.set(this.data.isAboutToSendUpdates);
    }

    close(): void {
        this._dialogRef.close();
    }
}
