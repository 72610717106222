import { RestaurantAttributeValue } from '@malou-io/package-utils';

export class Attribute {
    _id: string;
    platformKey: string;
    attributeName: {
        fr: string;
        en: string;
        es: string;
        it: string;
    };
    attributeId: string;
    displayed: boolean;
    platformAttributes: Attribute[] = [];

    public constructor(init: Partial<Attribute> = {}) {
        Object.assign(this, init);
        this.platformAttributes = this.platformAttributes?.map((e) => new Attribute(e));
    }

    getAttributeNameForLang(lang: string): string {
        return this.attributeName[lang] || this.attributeName.fr;
    }
}

export class RestaurantAttribute {
    _id: string;
    restaurantId: string;
    attributeId: string;
    attributeValue: RestaurantAttributeValue;
    attribute?: Attribute;

    public constructor(init?: Partial<RestaurantAttribute>) {
        Object.assign(this, init);
        if (init?.attribute) {
            this.attribute = new Attribute(init.attribute);
        }
    }
}
