import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PlatformKey } from '@malou-io/package-utils';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-select-platform',
    templateUrl: './select-platform.component.html',
    styleUrls: ['./select-platform.component.scss'],
    standalone: true,
    imports: [NgClass, MatTooltipModule, PlatformLogoComponent, SlideToggleComponent, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlatformComponent {
    readonly platformKey = input.required<PlatformKey>();
    readonly checked = input.required<boolean>();
    readonly disabled = input<boolean>(false);
    readonly tooltip = input<string>('');

    readonly selectChange = output<boolean>();

    onToggle(value: boolean): void {
        this.selectChange.emit(!value);
    }
}
