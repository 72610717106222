import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from ':environments/environment';
import { selectMaintenanceMode } from ':modules/admin/store/admin.reducer';
import { ApiResult } from ':shared/models';

type MaintenanceStatus = 'up' | 'maintenance';
export interface MaintenanceMode {
    status: MaintenanceStatus;
    until: Date;
    trespass: string[];
    redirect: boolean;
    webhookRedirectActive: boolean;
    localWebhookUri: string;
}

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store
    ) {}

    public getMaintenanceStatusFromServer(): Observable<ApiResult<MaintenanceMode>> {
        return this._http.get<ApiResult<MaintenanceMode>>(`${this.API_BASE_URL}maintenance/`);
    }

    public getMaintenanceStatus(): Observable<MaintenanceMode> {
        return this._store.select(selectMaintenanceMode).pipe(
            switchMap((mode) => {
                if (!mode) {
                    return this.getMaintenanceStatusFromServer().pipe(map((res) => res.data));
                }
                return of(mode);
            })
        );
    }

    public setMaintenanceStatus(status: MaintenanceStatus): Observable<ApiResult<MaintenanceMode>> {
        return this._http.put<ApiResult<any>>(`${this.API_BASE_URL}maintenance/`, { status });
    }

    public updateMaintenance(data: Partial<MaintenanceMode>): Observable<ApiResult> {
        return this._http.put<ApiResult<any>>(`${this.API_BASE_URL}maintenance/`, { ...data });
    }
}
