import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { filter, from, map, Observable, switchMap } from 'rxjs';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { EditImageDialogComponent } from ':shared/components/edit-image-dialog/edit-image-dialog.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Media, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaService } from '../../media/media.service';
import { MediaAction, MediaPreviewModalComponent } from '../modals/media-preview-modal/media-preview-modal.component';
import { RenameMediaModalComponent } from '../modals/rename-media-modal/rename-media-modal.component';

@Component({
    selector: 'app-gallery-media',
    templateUrl: './gallery-media.component.html',
    styleUrls: ['./gallery-media.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        DragToSelectModule,
        LazyLoadImageModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        FormatDatePipe,
        ImagePathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryMediaComponent {
    @Input() medias: WritableSignal<Media[]>;
    @Input() media: Media;
    @Input() restaurant: Restaurant;
    @Input() isSelected: boolean;
    @Output() mediaContainerClick: EventEmitter<Media> = new EventEmitter();
    @Output() mediaSelected: EventEmitter<{ event: MatCheckboxChange; media: Media }> = new EventEmitter();
    @Output() deleteSingleMedia: EventEmitter<Media> = new EventEmitter();
    @Output() duplicateSingleMedia: EventEmitter<{ to: DuplicationDestination; media: Media }> = new EventEmitter();
    @Output() editedMedia: EventEmitter<Media> = new EventEmitter();
    @Output() moveMedia: EventEmitter<Media> = new EventEmitter();
    @Output() mediaRenamed: EventEmitter<Media> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly DuplicationDestination = DuplicationDestination;

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService,
        public readonly translateService: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    onMediaSelected(event: MatCheckboxChange): void {
        this.mediaSelected.emit({ event, media: this.media });
    }

    onMediaContainerClick(): void {
        this.mediaContainerClick.emit(this.media);
    }

    renameMedia(media?: Media, callback?: () => void): void {
        this._customDialogService
            .open(RenameMediaModalComponent, {
                height: 'unset',
                data: {
                    media: this.media,
                },
            })
            .afterClosed()
            .subscribe({
                next: (newName?: string) => {
                    if (callback) {
                        callback();
                    }
                    if (newName) {
                        this._mediaService.updateMediaById(media ? media.id : this.media.id, { name: newName }).subscribe({
                            next: (result) => {
                                this.mediaRenamed.emit(result.data);
                            },
                            error: (err) => {
                                this._toastService.openErrorToast(this.translateService.instant('gallery.there_is_error') + String(err));
                            },
                        });
                    }
                },
            });
    }

    showEditModal(): void {
        this._customDialogService
            .open(EditImageDialogComponent, {
                height: 'unset',
                data: {
                    imgUrl: this.media?.urls?.igFit || this.media?.urls?.original,
                },
            })
            .afterClosed()
            .pipe(
                map((res) => res?.croppedImage),
                filter(Boolean),
                switchMap((mediaBase64: string) => this._transformMedia(mediaBase64)),
                switchMap((file) => this._mediaService.replaceMediaUrls(this.media.id, file))
            )
            .subscribe({
                next: (res) => {
                    const newMedia = new Media(res.data);
                    this.editedMedia.emit(newMedia);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    downloadSingleMedia(): void {
        this._mediaService.downloadSingleMedia(this.media).subscribe({
            next: (blob) => {
                saveAs(blob, `${this.media.getFullname()}`);
            },
            error: (err) => {
                console.warn('err :>> ', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    deleteMedia(): void {
        this.deleteSingleMedia.emit(this.media);
    }

    duplicateMedia(to: DuplicationDestination): void {
        this.duplicateSingleMedia.emit({ to, media: this.media });
    }

    openCarousel(event: MouseEvent): void {
        event.stopPropagation();
        const mediaIndex = this.medias().findIndex((media) => media.id === this.media.id);
        this._customDialogService
            .open(MediaPreviewModalComponent, {
                height: 'unset',
                disableClose: false,
                data: {
                    mediaIndex,
                    medias: this.medias(),
                    restaurant: this.restaurant,
                    actions: [MediaAction.RENAME, MediaAction.DUPLICATE, MediaAction.EDIT_MEDIA, MediaAction.DOWNLOAD, MediaAction.DELETE],
                    deleteMedia: this.deleteMedia.bind(this),
                    duplicate: this.duplicateMedia.bind(this),
                    renameMedia: this.renameMedia.bind(this),
                },
            })
            .afterClosed()
            .subscribe();
    }

    onMoveMedia(): void {
        this.moveMedia.emit(this.media);
    }

    private _transformMedia(mediaBase64: string): Observable<File> {
        return from(
            fetch(mediaBase64)
                .then((res) => res.blob())
                .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
        );
    }
}
