import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-no-comment-view',
    templateUrl: './no-comment-view.component.html',
    styleUrls: ['./no-comment-view.component.scss'],
    standalone: true,
    imports: [HttpErrorPipe, IllustrationPathResolverPipe, TranslateModule],
})
export class NoCommentViewComponent {
    @Input() error: any = null;
    @Input() hasSearch = false;
}
