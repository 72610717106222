import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roiTipsPathResolver',
    standalone: true,
})
export class RoiTipsPathResolver implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/roi/tips/${key}.png`;
    }
}
