<div class="malou-box-shadow relative rounded-[10px] bg-malou-color-background-white">
    <div class="absolute left-0 top-0 h-[4px] w-full overflow-hidden">
        <div class="progress-bar--{{ duration }} h-[200px] w-full rounded-[10px] {{ bgClass }}"></div>
    </div>
    <div class="malou-toast__close" (click)="onClick()">
        <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
    </div>
    <div class="malou-toast__content items-center">
        <div class="malou-toast__content-prefix grid place-items-center">
            <ng-container [ngTemplateOutlet]="circleTemplate"></ng-container>
        </div>
        <div class="malou-toast__message break-word pl-4" [innerHTML]="message"></div>
    </div>
</div>

<ng-template #circleTemplate>
    <div class="grid h-[45px] w-[45px] place-items-center rounded-full {{ bgClass }} bg-opacity-40">
        <div class="grid h-[35px] w-[35px] place-items-center rounded-full {{ bgClass }}">
            <mat-icon class="!h-[22px] !w-[22px] text-white" [svgIcon]="svgIcon"></mat-icon>
        </div>
    </div>
</ng-template>
