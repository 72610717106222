import { ApplicationLanguage, BricksCategory } from '@malou-io/package-utils';

import { Brick } from '../models';

export const createCustomBrick = (brickText: string, brickCategory: BricksCategory, currentLang: string = ApplicationLanguage.FR): Brick =>
    new Brick(
        {
            key: {
                [currentLang]: brickText?.trim()?.toLowerCase(),
            },
            brickId: `${brickCategory}:userInput:${brickText && brickText.replace(/ /g, '_').replace(/\'/g, '_')?.toLowerCase()}`,
            subCategory: 'userInput',
            category: brickCategory,
            text: {
                [currentLang]: brickText,
            },
            needReview: true,
        },
        currentLang
    );
