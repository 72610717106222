import { z } from 'zod';

import { WatcherStatus } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const watchKeywordsGenerationParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type WatchKeywordsGenerationParamsDto = z.infer<typeof watchKeywordsGenerationParamsValidator>;

export interface WatchKeywordsGenerationResponseDto {
    status: WatcherStatus;
    error?: string;
}
