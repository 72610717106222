<div class="mb-3 flex w-full items-center justify-between sm:flex-wrap">
    <app-search
        class="grow pr-[15px] sm:basis-full sm:pb-2 sm:pr-0"
        [placeholder]="'common.search' | translate"
        [debounceTime]="500"
        (searchChange)="onSearchChange($event)"></app-search>
    @if (showActions) {
        <div class="flex items-center justify-between gap-x-4 sm:w-full">
            <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [disabled]="!hasSelectedNfcs" (click)="onDeleteClick()">
                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
            </button>
            <button class="malou-btn-raised--primary btn-xl !h-12 sm:!hidden" mat-raised-button [matMenuTriggerFor]="actionsMenu">
                {{ 'admin.nfcs.add_totem' | translate }}
            </button>
            <button class="malou-btn-icon--primary btn-xl min-sm:!hidden" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
            </button>
        </div>
    }
</div>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
    <button class="flex gap-x-3" mat-menu-item (click)="onCreateNfcClick({ isTotem: true })">
        <span class="malou-text-14--regular">{{ 'admin.nfcs.type.totem' | translate }}</span>
    </button>
    <button class="flex gap-x-3" mat-menu-item (click)="onCreateNfcClick({ isTotem: false })">
        <span class="malou-text-14--regular">{{ 'admin.nfcs.type.sticker' | translate }}</span>
    </button>
    <button class="flex gap-x-3" mat-menu-item (click)="onLoadNfcsFromSheet()">
        <span class="malou-text-14--regular">{{ 'admin.nfcs.type.load_nfcs_from_sheet' | translate }}</span>
    </button>
</mat-menu>
