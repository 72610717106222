import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MediumTemplate } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { CommentOptionValue } from '../../enums/with-comment.enum';

@Component({
    selector: 'app-template-info-modal',
    templateUrl: './template-info-modal.component.html',
    styleUrls: ['./template-info-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, IllustrationPathResolverPipe],
})
export class TemplateInfoModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;

    template: MediumTemplate;
    ratingText: string;

    constructor(
        private readonly _dialogRef: MatDialogRef<TemplateInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            template: MediumTemplate;
        },
        private readonly _translate: TranslateService
    ) {
        this.template = this.data.template;
    }

    ngOnInit(): void {
        this.ratingText = `${this.getRatingText(this.template.rating)} ${this.getWithCommentText(this.template.withComment)}`;
    }

    getWithCommentText(withComment: CommentOptionValue): string {
        switch (withComment) {
            case CommentOptionValue.WITH:
                return this._translate.instant('template_info_modal.with_comment');
            case CommentOptionValue.WITHOUT:
                return this._translate.instant('template_info_modal.without_comment');
            case CommentOptionValue.WITH_OR_WITHOUT:
                return this._translate.instant('template_info_modal.with_or_without');
            default:
                return '';
        }
    }

    getRatingText(rating: number[]): string {
        if (!rating.length) {
            return '';
        }
        const maxRating = Math.max(...rating);
        if (maxRating === 0) {
            return this._translate.instant('template_info_modal.without_rating');
        }
        if (maxRating === 3 && rating.length === 1) {
            return this._translate.instant('template_info_modal.neutral');
        }
        if (maxRating <= 3) {
            return this._translate.instant('template_info_modal.unhappy');
        }
        return this._translate.instant('template_info_modal.satisfied');
    }

    close(): void {
        this._dialogRef.close({ createOtherTemplate: false, firstTemplate: this.template });
    }

    createOtherTemplate(): void {
        this._dialogRef.close({ createOtherTemplate: true, firstTemplate: this.template });
    }
}
