<div
    class="w-full min-w-[260px] cursor-pointer rounded-[10px] border-[1px] border-solid border-malou-color-background-dark px-3 py-3.5"
    [matMenuTriggerFor]="menu"
    (click)="selectContainerOpened = !selectContainerOpened; onMenuOpened()"
    #selectContainer>
    <div class="flex w-full items-center justify-between">
        <div class="ml-2.5 flex items-center gap-x-3">
            @if (prefixText) {
                <span class="malou-text-12--medium text-malou-color-text-1">{{ prefixText }}</span>
            }
            @for (lang of selectedLangs; track lang) {
                <img class="h-5 w-5" [src]="lang | flagPathResolver" />
            }
        </div>
        <mat-icon
            class="ml-3 !h-4 !w-4 transition-all"
            color="primary"
            [svgIcon]="SvgIcon.CHEVRON_DOWN"
            [class.rotate-180]="selectContainerOpened"></mat-icon>
    </div>
    <mat-menu class="malou-mat-menu malou-box-shadow !min-w-[260px] !max-w-none !rounded-[10px]" #menu="matMenu">
        <div mat-menu-item (click)="$event.stopPropagation(); selectAllLanguages()">
            <mat-checkbox color="primary" [checked]="areAllSelected()" (click)="$event.stopPropagation()" (change)="selectAllLanguages()">
                <span class="malou-text-12--medium ml-2.5 text-malou-color-text-1">{{ 'common.select_all' | translate }}</span>
            </mat-checkbox>
        </div>
        @for (lang of selectableLangs; track lang) {
            <div mat-menu-item (click)="$event.stopPropagation(); selectLanguage(lang)">
                <mat-checkbox
                    color="primary"
                    [checked]="isLanguageSelected(lang)"
                    (click)="$event.stopPropagation()"
                    (change)="selectLanguage(lang)">
                    <div class="flex items-center gap-x-3">
                        <img class="h-5 w-5" [src]="lang | flagPathResolver" />
                        <span class="malou-text-12--medium ml-2.5 text-malou-color-text-1">{{ langDisplayWith | applyPure: lang }}</span>
                    </div>
                </mat-checkbox>
            </div>
        }
    </mat-menu>
</div>
