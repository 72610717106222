<div class="malou-text-12--regular flex" [ngClass]="isInsideTextarea() ? 'h-[25px]' : 'h-[30px] flex-col'">
    <p>{{ defaultText() }}</p>
    @if (displayAnimation()) {
        <div class="text-slider flex" [@textAnimation]="slideTextList().length" (@textAnimation.done)="onAnimationDone()">
            @for (text of slideTextList(); track text) {
                <p [class.!pl-0]="!isInsideTextarea()">{{ text }}...</p>
            }
        </div>
    }
</div>
