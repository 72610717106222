import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-reviews-header-automatic-reply',
    templateUrl: './reviews-header-automatic-reply.component.html',
    styleUrls: ['./reviews-header-automatic-reply.component.scss'],
    standalone: true,
    imports: [MatIconModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderAutomaticReplyComponent {
    readonly showTitle = input.required<boolean>();

    readonly SvgIcon = SvgIcon;

    private readonly _router = inject(Router);
    private readonly _route = inject(ActivatedRoute);

    goToAutoReplyModels(): void {
        void this._router.navigate(['../../resources/review-templates'], { relativeTo: this._route.parent });
    }
}
