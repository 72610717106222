import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
    constructor(
        private readonly _authService: AuthService,
        private readonly _router: Router
    ) {}
    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._authService.isAuthenticated()) {
            this._authService.setRedirectUrl(state.url);
            this._router.navigate(['auth/login']);
            return false;
        }
        this._authService.setRedirectUrl(null);
        return true;
    }
}
