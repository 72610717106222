<header
    class="malou-header sticky top-0 z-10 flex items-center justify-between gap-x-2 bg-malou-color-background-white px-9 sm:!border-b-0 md:px-4">
    @if (shouldShowLogo()) {
        <div>
            <img id="brand-logo" routerLink="/" [src]="'malou_logo_black' | imagePathResolver" />
        </div>
    }
    <div>
        @for (route of displayedSubRoutes(); track route.key; let isLast = $last) {
            <span class="text-malou-color-text-1" [ngClass]="isLast ? 'malou-text-15--semibold' : 'malou-text-15--regular'">
                {{ route.text }}
            </span>
            @if (!isLast) {
                <span class="malou-text-15--regular"> / </span>
            } @else if (route.detail) {
                <span class="malou-text-15--regular italic">({{ route.detail }})</span>
            }
        }
    </div>
    <div class="flex items-center gap-x-2 sm:gap-3">
        @if (!experimentationService.isFeatureEnabled('release-notification-center')) {
            <app-notifications></app-notifications>
        } @else {
            <button
                class="malou-btn-icon mb-0 mr-2 flex h-[35px] w-[35px] items-center justify-center rounded-[50%] bg-malou-color-background-light"
                data-testid="header-notifications-btn"
                mat-icon-button
                (click)="notificationCenterContext.open(); $event.stopPropagation()">
                <mat-icon
                    class="!h-5 !w-5"
                    color="primary"
                    matBadgeSize="small"
                    aria-hidden="false"
                    [matBadgeHidden]="notificationCenterContext.unreadNotificationsCount() === 0"
                    [matBadge]="notificationCenterContext.unreadNotificationsCount()"
                    [svgIcon]="SvgIcon.NOTIFICATIONS"></mat-icon>
            </button>
        }

        @if (showProfile) {
            @if (userInfos$ | async; as user) {
                <div class="flex shrink-0 items-center">
                    <button
                        class="malou-text-12--bold text-malou-color-text-1 md:hidden"
                        data-testid="header-profile-btn"
                        [matMenuTriggerFor]="actionsMenu">
                        <img
                            class="malou-avatar--small mr-0"
                            [src]="user.profilePicture ? user.profilePicture.urls.small : ('Karl' | illustrationPathResolver)"
                            [matMenuTriggerFor]="actionsMenu" />
                    </button>
                    <mat-menu class="malou-mat-menu malou-box-shadow mt-6 !rounded-xl" #actionsMenu="matMenu">
                        <button class="flex gap-x-3" mat-menu-item [routerLink]="'/users/' + user._id">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.my_profile' | translate
                            }}</span>
                        </button>
                        <button class="flex gap-x-3" mat-menu-item [routerLink]="'/users/' + user._id + '/notifications-settings'">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.WARNING"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.my_alerts' | translate
                            }}</span>
                        </button>
                        <button
                            class="flex gap-x-3"
                            data-testid="header-my-reports-btn"
                            mat-menu-item
                            [routerLink]="'/users/' + user._id + '/reports-settings'">
                            <mat-icon class="!h-5 !w-5 shrink-0" color="primary" [svgIcon]="SvgIcon.FOLDER"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2"
                                >{{ 'header.profile_menu.my_reports' | translate }}
                            </span>
                        </button>
                        <button class="flex gap-x-3" data-testid="header-logout-btn" data-cy="deleteBtn" mat-menu-item (click)="logout()">
                            <mat-icon class="malou-color-state-error !h-5 !w-5 shrink-0" [svgIcon]="SvgIcon.IMPORT"></mat-icon>
                            <span class="malou-text-14--regular text-malou-color-text-2">{{
                                'header.profile_menu.logout' | translate
                            }}</span>
                        </button>
                    </mat-menu>
                </div>
            }
        }
        @if (showSidenavToggle) {
            <app-sidenav-toggle-button></app-sidenav-toggle-button>
        }
    </div>
</header>
