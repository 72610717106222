<app-modal-structure
    [title]="'platforms.connection_new.google.step_0.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="passwordControl.invalid || loginControl.invalid"
    [secondaryButtonText]="'common.cancel' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isCreatingCredentials()"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="close.emit({})">
    <div class="flex flex-col gap-y-4">
        <app-input-text
            [testId]="'deliveroo-connection-email-input'"
            [formControl]="loginControl"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.deliveroo.step_0.email_address' | translate"
            [placeholder]="'address@email.com'"
            [errorMessage]="isEmailInError() ? ('platforms.connection_new.deliveroo.step_0.invalid_email' | translate) : undefined"
            [autocapitalize]="'none'"
            [disabled]="isCreatingCredentials()">
        </app-input-text>
        <app-input-text
            [testId]="'deliveroo-connection-password-input'"
            [autocomplete]="isSafari ? 'current-password' : 'new-password'"
            [formControl]="passwordControl"
            [inputType]="'password'"
            [password]="true"
            [showEyeIcon]="true"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.deliveroo.step_0.password' | translate"
            [placeholder]="'xxxx'"
            [autocapitalize]="'none'"
            [disabled]="isCreatingCredentials()">
        </app-input-text>
    </div>
</app-modal-structure>
