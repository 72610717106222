// https://en.wikipedia.org/wiki/List_of_postal_codes
export const countries = [
    {
        name: 'Afghanistan',
        dialCode: '+93',
        code: 'AF',
        nameFr: 'Afghanistan',
        hasPostalCode: true,
    },
    {
        name: 'land Islands',
        dialCode: '',
        code: 'AX',
        nameFr: 'ïles land',
        hasPostalCode: true,
    },
    {
        name: 'Albania',
        dialCode: '+355',
        code: 'AL',
        nameFr: 'Albanie',
        hasPostalCode: true,
    },
    {
        name: 'Algeria',
        dialCode: '+213',
        code: 'DZ',
        nameFr: 'Algérie',
        hasPostalCode: true,
    },
    {
        name: 'AmericanSamoa',
        dialCode: '+1 684',
        code: 'AS',
        nameFr: 'Samoa américaines',
        hasPostalCode: true,
    },
    {
        name: 'Andorra',
        dialCode: '+376',
        code: 'AD',
        nameFr: 'Andorre',
        hasPostalCode: true,
    },
    {
        name: 'Angola',
        dialCode: '+244',
        code: 'AO',
        nameFr: 'Angola',
        hasPostalCode: false,
    },
    {
        name: 'Anguilla',
        dialCode: '+1 264',
        code: 'AI',
        nameFr: 'Anguilla',
        hasPostalCode: true,
    },
    {
        name: 'Antarctica',
        dialCode: null,
        code: 'AQ',
        nameFr: 'Antarctique',
        hasPostalCode: true,
    },
    {
        name: 'Antigua and Barbuda',
        dialCode: '+1268',
        code: 'AG',
        nameFr: 'Antigue-et-Barbude',
        hasPostalCode: false,
    },
    {
        name: 'Argentina',
        dialCode: '+54',
        code: 'AR',
        nameFr: 'Argentine',
        hasPostalCode: true,
    },
    {
        name: 'Armenia',
        dialCode: '+374',
        code: 'AM',
        nameFr: 'Arménie',
        hasPostalCode: true,
    },
    {
        name: 'Aruba',
        dialCode: '+297',
        code: 'AW',
        nameFr: 'Aruba',
        hasPostalCode: false,
    },
    {
        name: 'Australia',
        dialCode: '+61',
        code: 'AU',
        nameFr: 'Australie',
        hasPostalCode: true,
    },
    {
        name: 'Austria',
        dialCode: '+43',
        code: 'AT',
        nameFr: 'Autriche',
        hasPostalCode: true,
    },
    {
        name: 'Azerbaijan',
        dialCode: '+994',
        code: 'AZ',
        nameFr: 'Azerbaïdjan',
        hasPostalCode: true,
    },
    {
        name: 'Bahamas',
        dialCode: '+1 242',
        code: 'BS',
        nameFr: 'Bahamas',
        hasPostalCode: false,
    },
    {
        name: 'Bahrain',
        dialCode: '+973',
        code: 'BH',
        nameFr: 'Bahreïn',
        hasPostalCode: true,
    },
    {
        name: 'Bangladesh',
        dialCode: '+880',
        code: 'BD',
        nameFr: 'Bangladesh',
        hasPostalCode: true,
    },
    {
        name: 'Barbados',
        dialCode: '+1 246',
        code: 'BB',
        nameFr: 'Barbade',
        hasPostalCode: true,
    },
    {
        name: 'Belarus',
        dialCode: '+375',
        code: 'BY',
        nameFr: 'Biélorussie',
        hasPostalCode: true,
    },
    {
        name: 'Belgium',
        dialCode: '+32',
        code: 'BE',
        nameFr: 'Belgique',
        hasPostalCode: true,
    },
    {
        name: 'Belize',
        dialCode: '+501',
        code: 'BZ',
        nameFr: 'Belize',
        hasPostalCode: false,
    },
    {
        name: 'Benin',
        dialCode: '+229',
        code: 'BJ',
        nameFr: 'Benin',
        hasPostalCode: false,
    },
    {
        name: 'Bermuda',
        dialCode: '+1 441',
        code: 'BM',
        nameFr: 'Bermudes',
        hasPostalCode: false,
    },
    {
        name: 'Bhutan',
        dialCode: '+975',
        code: 'BT',
        nameFr: 'Bhoutan',
        hasPostalCode: true,
    },
    {
        name: 'Bolivia',
        dialCode: '+591',
        code: 'BO',
        nameFr: 'Bolivie',
        hasPostalCode: false,
    },
    {
        name: 'Bosnia and Herzegovina',
        dialCode: '+387',
        code: 'BA',
        nameFr: 'Bosnie-Herzégovine',
        hasPostalCode: true,
    },
    {
        name: 'Botswana',
        dialCode: '+267',
        code: 'BW',
        nameFr: 'Botswana',
        hasPostalCode: false,
    },
    {
        name: 'Brazil',
        dialCode: '+55',
        code: 'BR',
        nameFr: 'Brésil',
        hasPostalCode: true,
    },
    {
        name: 'British Indian Ocean Territory',
        dialCode: '+246',
        code: 'IO',
        // eslint-disable-next-line @typescript-eslint/quotes
        nameFr: "Territoire britannique de l'océan Indien",
        hasPostalCode: true,
    },
    {
        name: 'Brunei Darussalam',
        dialCode: '+673',
        code: 'BN',
        nameFr: 'Brunéi Darussalam',
        hasPostalCode: true,
    },
    {
        name: 'Bulgaria',
        dialCode: '+359',
        code: 'BG',
        nameFr: 'Bulgarie',
        hasPostalCode: true,
    },
    {
        name: 'Burkina Faso',
        dialCode: '+226',
        code: 'BF',
        nameFr: 'Burkina Faso',
        hasPostalCode: false,
    },
    {
        name: 'Burundi',
        dialCode: '+257',
        code: 'BI',
        nameFr: 'Burundi',
        hasPostalCode: false,
    },
    {
        name: 'Cambodia',
        dialCode: '+855',
        code: 'KH',
        nameFr: 'Cambodge',
        hasPostalCode: true,
    },
    {
        name: 'Cameroon',
        dialCode: '+237',
        code: 'CM',
        nameFr: 'Cameroun',
        hasPostalCode: false,
    },
    {
        name: 'Canada',
        dialCode: '+1',
        code: 'CA',
        nameFr: 'Canada',
        hasPostalCode: true,
    },
    {
        name: 'Cape Verde',
        dialCode: '+238',
        code: 'CV',
        nameFr: 'Cap-Vert',
        hasPostalCode: true,
    },
    {
        name: 'Cayman Islands',
        dialCode: '+ 345',
        code: 'KY',
        nameFr: 'Îles Caïmanes',
        hasPostalCode: true,
    },
    {
        name: 'Central African Republic',
        dialCode: '+236',
        code: 'CF',
        nameFr: 'Centrafricaine, république',
        hasPostalCode: false,
    },
    {
        name: 'Chad',
        dialCode: '+235',
        code: 'TD',
        nameFr: 'Tchad',
        hasPostalCode: false,
    },
    {
        name: 'Chile',
        dialCode: '+56',
        code: 'CL',
        nameFr: 'Chili',
        hasPostalCode: true,
    },
    {
        name: 'China',
        dialCode: '+86',
        code: 'CN',
        nameFr: 'Chine',
        hasPostalCode: true,
    },
    {
        name: 'Christmas Island',
        dialCode: '+61',
        code: 'CX',
        nameFr: 'île Christmas',
        hasPostalCode: true,
    },
    {
        name: 'Cocos (Keeling) Islands',
        dialCode: '+61',
        code: 'CC',
        nameFr: 'îles cocos',
        hasPostalCode: true,
    },
    {
        name: 'Colombia',
        dialCode: '+57',
        code: 'CO',
        nameFr: 'Colombie',
        hasPostalCode: true,
    },
    {
        name: 'Comoros',
        dialCode: '+269',
        code: 'KM',
        nameFr: 'Comores',
        hasPostalCode: false,
    },
    {
        name: 'Congo',
        dialCode: '+242',
        code: 'CG',
        nameFr: 'République du Congo',
        hasPostalCode: false,
    },
    {
        // eslint-disable-next-line @typescript-eslint/quotes
        name: "Cote d'Ivoire",
        dialCode: '+225',
        code: 'CI',
        // eslint-disable-next-line @typescript-eslint/quotes
        nameFr: "Côte d'Ivoire",
        hasPostalCode: false,
    },
    {
        name: 'Democratic Republic of the Congo',
        dialCode: '+243',
        code: 'CD',
        nameFr: 'République démocratique du congo',
        hasPostalCode: false,
    },
    {
        name: 'Cook Islands',
        dialCode: '+682',
        code: 'CK',
        nameFr: 'Îles Cook',
        hasPostalCode: false,
    },
    {
        name: 'Costa Rica',
        dialCode: '+506',
        code: 'CR',
        nameFr: 'Costa Rica',
        hasPostalCode: true,
    },
    {
        name: 'Croatia',
        dialCode: '+385',
        code: 'HR',
        nameFr: 'Croatie',
        hasPostalCode: true,
    },
    {
        name: 'Cuba',
        dialCode: '+53',
        code: 'CU',
        nameFr: 'Cuba',
        hasPostalCode: true,
    },
    {
        name: 'Cyprus',
        dialCode: '+537',
        code: 'CY',
        nameFr: 'Chypre',
        hasPostalCode: true,
    },
    {
        name: 'Czech Republic',
        dialCode: '+420',
        code: 'CZ',
        nameFr: 'République tchèque',
        hasPostalCode: true,
    },
    {
        name: 'Denmark',
        dialCode: '+45',
        code: 'DK',
        nameFr: 'Danemark',
        hasPostalCode: true,
    },
    {
        name: 'Djibouti',
        dialCode: '+253',
        code: 'DJ',
        nameFr: 'Djibouti',
        hasPostalCode: false,
    },
    {
        name: 'Dominica',
        dialCode: '+1 767',
        code: 'DM',
        nameFr: 'Dominique',
        hasPostalCode: false,
    },
    {
        name: 'Dominican Republic',
        dialCode: '+1 849',
        code: 'DO',
        nameFr: 'République Dominicaine',
        hasPostalCode: true,
    },
    {
        name: 'Ecuador',
        dialCode: '+593',
        code: 'EC',
        nameFr: 'Équateur',
        hasPostalCode: true,
    },
    {
        name: 'Egypt',
        dialCode: '+20',
        code: 'EG',
        nameFr: 'Égypte',
        hasPostalCode: true,
    },
    {
        name: 'El Salvador',
        dialCode: '+503',
        code: 'SV',
        nameFr: 'El Salvador',
        hasPostalCode: true,
    },
    {
        name: 'Equatorial Guinea',
        dialCode: '+240',
        code: 'GQ',
        nameFr: 'Guinée équatoriale',
        hasPostalCode: false,
    },
    {
        name: 'Eritrea',
        dialCode: '+291',
        code: 'ER',
        nameFr: 'Érythrée',
        hasPostalCode: false,
    },
    {
        name: 'Estonia',
        dialCode: '+372',
        code: 'EE',
        nameFr: 'Estonie',
        hasPostalCode: true,
    },
    {
        name: 'Ethiopia',
        dialCode: '+251',
        code: 'ET',
        nameFr: 'Éthiopie',
        hasPostalCode: true,
    },
    {
        name: 'Falkland Islands (Malvinas)',
        dialCode: '+500',
        code: 'FK',
        nameFr: 'îles falkland',
        hasPostalCode: true,
    },
    {
        name: 'Faroe Islands',
        dialCode: '+298',
        code: 'FO',
        nameFr: 'Îles Féroé',
        hasPostalCode: true,
    },
    {
        name: 'Fiji',
        dialCode: '+679',
        code: 'FJ',
        nameFr: 'Fidji',
        hasPostalCode: false,
    },
    {
        name: 'Finland',
        dialCode: '+358',
        code: 'FI',
        nameFr: 'Finlande',
        hasPostalCode: true,
    },
    {
        name: 'France',
        dialCode: '+33',
        code: 'FR',
        nameFr: 'France',
        hasPostalCode: true,
    },
    {
        name: 'French Guiana',
        dialCode: '+594',
        code: 'GF',
        nameFr: 'Guyane française',
        hasPostalCode: true,
    },
    {
        name: 'French Polynesia',
        dialCode: '+689',
        code: 'PF',
        nameFr: 'Polynésie Française',
        hasPostalCode: true,
    },
    {
        name: 'Gabon',
        dialCode: '+241',
        code: 'GA',
        nameFr: 'Gabon',
        hasPostalCode: false,
    },
    {
        name: 'Gambia',
        dialCode: '+220',
        code: 'GM',
        nameFr: 'Gambie',
        hasPostalCode: false,
    },
    {
        name: 'Georgia',
        dialCode: '+995',
        code: 'GE',
        nameFr: 'Géorgie',
        hasPostalCode: true,
    },
    {
        name: 'Germany',
        dialCode: '+49',
        code: 'DE',
        nameFr: 'Allemagne',
        hasPostalCode: true,
    },
    {
        name: 'Ghana',
        dialCode: '+233',
        code: 'GH',
        nameFr: 'Ghana',
        hasPostalCode: true,
    },
    {
        name: 'Gibraltar',
        dialCode: '+350',
        code: 'GI',
        nameFr: 'Gibraltar',
        hasPostalCode: true,
    },
    {
        name: 'Greece',
        dialCode: '+30',
        code: 'GR',
        nameFr: 'Grèce',
        hasPostalCode: true,
    },
    {
        name: 'Greenland',
        dialCode: '+299',
        code: 'GL',
        nameFr: 'Groenland',
        hasPostalCode: true,
    },
    {
        name: 'Grenada',
        dialCode: '+1 473',
        code: 'GD',
        nameFr: 'Grenade',
        hasPostalCode: false,
    },
    {
        name: 'Guadeloupe',
        dialCode: '+590',
        code: 'GP',
        nameFr: 'Guadeloupe',
        hasPostalCode: true,
    },
    {
        name: 'Guam',
        dialCode: '+1 671',
        code: 'GU',
        nameFr: 'Guam',
        hasPostalCode: true,
    },
    {
        name: 'Guatemala',
        dialCode: '+502',
        code: 'GT',
        nameFr: 'Guatemala',
        hasPostalCode: true,
    },
    {
        name: 'Guernsey',
        dialCode: '+44',
        code: 'GG',
        nameFr: 'Guernesey',
        hasPostalCode: true,
    },
    {
        name: 'Guinea',
        dialCode: '+224',
        code: 'GN',
        nameFr: 'Guinée',
        hasPostalCode: true,
    },
    {
        name: 'Guinea-Bissau',
        dialCode: '+245',
        code: 'GW',
        nameFr: 'Guinée-Bissau',
        hasPostalCode: true,
    },
    {
        name: 'Guyana',
        dialCode: '+595',
        code: 'GY',
        nameFr: 'Guyana',
        hasPostalCode: false,
    },
    {
        name: 'Haiti',
        dialCode: '+509',
        code: 'HT',
        nameFr: 'Haïti',
        hasPostalCode: true,
    },
    {
        name: 'Honduras',
        dialCode: '+504',
        code: 'HN',
        nameFr: 'Honduras',
        hasPostalCode: true,
    },
    {
        name: 'Hong Kong',
        dialCode: '+852',
        code: 'HK',
        nameFr: 'Hong Kong',
        hasPostalCode: false,
    },
    {
        name: 'Hungary',
        dialCode: '+36',
        code: 'HU',
        nameFr: 'Hongrie',
        hasPostalCode: true,
    },
    {
        name: 'Iceland',
        dialCode: '+354',
        code: 'IS',
        nameFr: 'Islande',
        hasPostalCode: true,
    },
    {
        name: 'India',
        dialCode: '+91',
        code: 'IN',
        nameFr: 'Inde',
        hasPostalCode: true,
    },
    {
        name: 'Indonesia',
        dialCode: '+62',
        code: 'ID',
        nameFr: 'Indonésie',
        hasPostalCode: true,
    },
    {
        name: 'Iran',
        dialCode: '+98',
        code: 'IR',
        nameFr: 'Iran',
        hasPostalCode: true,
    },
    {
        name: 'Iraq',
        dialCode: '+964',
        code: 'IQ',
        nameFr: 'Irak',
        hasPostalCode: true,
    },
    {
        name: 'Ireland',
        dialCode: '+353',
        code: 'IE',
        nameFr: 'Irlande',
        hasPostalCode: true,
    },
    {
        name: 'Isle of Man',
        dialCode: '+44',
        code: 'IM',
        nameFr: 'Île de Man',
        hasPostalCode: true,
    },
    {
        name: 'Israel',
        dialCode: '+972',
        code: 'IL',
        nameFr: 'Israël',
        hasPostalCode: true,
    },
    {
        name: 'Italy',
        dialCode: '+39',
        code: 'IT',
        nameFr: 'Italie',
        hasPostalCode: true,
    },
    {
        name: 'Jamaica',
        dialCode: '+1 876',
        code: 'JM',
        nameFr: 'Jamaïque',
        hasPostalCode: true,
    },
    {
        name: 'Japan',
        dialCode: '+81',
        code: 'JP',
        nameFr: 'Japon',
        hasPostalCode: true,
    },
    {
        name: 'Jersey',
        dialCode: '+44',
        code: 'JE',
        nameFr: 'Jersey',
        hasPostalCode: true,
    },
    {
        name: 'Jordan',
        dialCode: '+962',
        code: 'JO',
        nameFr: 'Jordanie',
        hasPostalCode: true,
    },
    {
        name: 'Kazakhstan',
        dialCode: '+7 7',
        code: 'KZ',
        nameFr: 'Kazakhstan',
        hasPostalCode: true,
    },
    {
        name: 'Kenya',
        dialCode: '+254',
        code: 'KE',
        nameFr: 'Kenya',
        hasPostalCode: true,
    },
    {
        name: 'Kiribati',
        dialCode: '+686',
        code: 'KI',
        nameFr: 'Kiribati',
        hasPostalCode: false,
    },
    {
        name: 'Kuwait',
        dialCode: '+965',
        code: 'KW',
        nameFr: 'Koweït',
        hasPostalCode: true,
    },
    {
        name: 'Kyrgyzstan',
        dialCode: '+996',
        code: 'KG',
        nameFr: 'Kirghizistan',
        hasPostalCode: true,
    },
    {
        name: 'Laos',
        dialCode: '+856',
        code: 'LA',
        nameFr: 'Laos',
        hasPostalCode: true,
    },
    {
        name: 'Latvia',
        dialCode: '+371',
        code: 'LV',
        nameFr: 'Lettonie',
        hasPostalCode: true,
    },
    {
        name: 'Lebanon',
        dialCode: '+961',
        code: 'LB',
        nameFr: 'Liban',
        hasPostalCode: true,
    },
    {
        name: 'Lesotho',
        dialCode: '+266',
        code: 'LS',
        nameFr: 'Lesotho',
        hasPostalCode: true,
    },
    {
        name: 'Liberia',
        dialCode: '+231',
        code: 'LR',
        nameFr: 'Liberia',
        hasPostalCode: true,
    },
    {
        name: 'Libya',
        dialCode: '+218',
        code: 'LY',
        nameFr: 'Libye',
        hasPostalCode: false,
    },
    {
        name: 'Liechtenstein',
        dialCode: '+423',
        code: 'LI',
        nameFr: 'Liechtenstein',
        hasPostalCode: true,
    },
    {
        name: 'Lithuania',
        dialCode: '+370',
        code: 'LT',
        nameFr: 'Lituanie',
        hasPostalCode: true,
    },
    {
        name: 'Luxembourg',
        dialCode: '+352',
        code: 'LU',
        nameFr: 'Luxembourg',
        hasPostalCode: true,
    },
    {
        name: 'Macao',
        dialCode: '+853',
        code: 'MO',
        nameFr: 'Macao',
        hasPostalCode: false,
    },
    {
        name: 'Macedonia',
        dialCode: '+389',
        code: 'MK',
        nameFr: 'Macédoine',
        hasPostalCode: true,
    },
    {
        name: 'Madagascar',
        dialCode: '+261',
        code: 'MG',
        nameFr: 'Madagascar',
        hasPostalCode: true,
    },
    {
        name: 'Malawi',
        dialCode: '+265',
        code: 'MW',
        nameFr: 'Malawi',
        hasPostalCode: true,
    },
    {
        name: 'Malaysia',
        dialCode: '+60',
        code: 'MY',
        nameFr: 'Malaisie',
        hasPostalCode: true,
    },
    {
        name: 'Maldives',
        dialCode: '+960',
        code: 'MV',
        nameFr: 'Maldives',
        hasPostalCode: true,
    },
    {
        name: 'Mali',
        dialCode: '+223',
        code: 'ML',
        nameFr: 'Mali',
        hasPostalCode: false,
    },
    {
        name: 'Malta',
        dialCode: '+356',
        code: 'MT',
        nameFr: 'Malte',
        hasPostalCode: true,
    },
    {
        name: 'Marshall Islands',
        dialCode: '+692',
        code: 'MH',
        nameFr: 'Îles Marshall',
        hasPostalCode: true,
    },
    {
        name: 'Martinique',
        dialCode: '+596',
        code: 'MQ',
        nameFr: 'Martinique',
        hasPostalCode: true,
    },
    {
        name: 'Mauritania',
        dialCode: '+222',
        code: 'MR',
        nameFr: 'Mauritanie',
        hasPostalCode: false,
    },
    {
        name: 'Mauritius',
        dialCode: '+230',
        code: 'MU',
        nameFr: 'Île Maurice',
        hasPostalCode: true,
    },
    {
        name: 'Mayotte',
        dialCode: '+262',
        code: 'YT',
        nameFr: 'Mayotte',
        hasPostalCode: true,
    },
    {
        name: 'Mexico',
        dialCode: '+52',
        code: 'MX',
        nameFr: 'Mexique',
        hasPostalCode: true,
    },
    {
        name: 'Micronesia',
        dialCode: '+691',
        code: 'FM',
        nameFr: 'Micronésie',
        hasPostalCode: true,
    },
    {
        name: 'Moldova',
        dialCode: '+373',
        code: 'MD',
        nameFr: 'Moldavie',
        hasPostalCode: true,
    },
    {
        name: 'Monaco',
        dialCode: '+377',
        code: 'MC',
        nameFr: 'Monaco',
        hasPostalCode: true,
    },
    {
        name: 'Mongolia',
        dialCode: '+976',
        code: 'MN',
        nameFr: 'Mongolie',
        hasPostalCode: true,
    },
    {
        name: 'Montenegro',
        dialCode: '+382',
        code: 'ME',
        nameFr: 'Monténégro',
        hasPostalCode: true,
    },
    {
        name: 'Montserrat',
        dialCode: '+1664',
        code: 'MS',
        nameFr: 'Montserrat',
        hasPostalCode: true,
    },
    {
        name: 'Morocco',
        dialCode: '+212',
        code: 'MA',
        nameFr: 'Maroc',
        hasPostalCode: true,
    },
    {
        name: 'Mozambique',
        dialCode: '+258',
        code: 'MZ',
        nameFr: 'Mozambique',
        hasPostalCode: true,
    },
    {
        name: 'Myanmar',
        dialCode: '+95',
        code: 'MM',
        nameFr: 'Myanmar',
        hasPostalCode: true,
    },
    {
        name: 'Namibia',
        dialCode: '+264',
        code: 'NA',
        nameFr: 'Namibie',
        hasPostalCode: true,
    },
    {
        name: 'Nauru',
        dialCode: '+674',
        code: 'NR',
        nameFr: 'Nauru',
        hasPostalCode: false,
    },
    {
        name: 'Nepal',
        dialCode: '+977',
        code: 'NP',
        nameFr: 'Népal',
        hasPostalCode: true,
    },
    {
        name: 'Netherlands',
        dialCode: '+31',
        code: 'NL',
        nameFr: 'Pays-Bas',
        hasPostalCode: false,
    },
    {
        name: 'Netherlands Antilles',
        dialCode: '+599',
        code: 'AN',
        nameFr: 'Antilles néerlandaises',
        hasPostalCode: true,
    },
    {
        name: 'New Caledonia',
        dialCode: '+687',
        code: 'NC',
        nameFr: 'Nouvelle-Calédonie',
        hasPostalCode: true,
    },
    {
        name: 'New Zealand',
        dialCode: '+64',
        code: 'NZ',
        nameFr: 'Nouvelle-Zélande',
        hasPostalCode: true,
    },
    {
        name: 'Nicaragua',
        dialCode: '+505',
        code: 'NI',
        nameFr: 'Nicaragua',
        hasPostalCode: true,
    },
    {
        name: 'Niger',
        dialCode: '+227',
        code: 'NE',
        nameFr: 'Niger',
        hasPostalCode: true,
    },
    {
        name: 'Nigeria',
        dialCode: '+234',
        code: 'NG',
        nameFr: 'Nigéria',
        hasPostalCode: true,
    },
    {
        name: 'Niue',
        dialCode: '+683',
        code: 'NU',
        nameFr: 'Nioué',
        hasPostalCode: false,
    },
    {
        name: 'Norfolk Island',
        dialCode: '+672',
        code: 'NF',
        nameFr: 'Iles Norfolk',
        hasPostalCode: true,
    },
    {
        name: 'North Korea',
        dialCode: '+850',
        code: 'KP',
        nameFr: 'Corée du nord',
        hasPostalCode: false,
    },
    {
        name: 'Northern Mariana Islands',
        dialCode: '+1 670',
        code: 'MP',
        nameFr: 'Îles Mariannes du Nord',
        hasPostalCode: true,
    },
    {
        name: 'Norway',
        dialCode: '+47',
        code: 'NO',
        nameFr: 'Norvège',
        hasPostalCode: true,
    },
    {
        name: 'Oman',
        dialCode: '+968',
        code: 'OM',
        nameFr: 'Oman',
        hasPostalCode: true,
    },
    {
        name: 'Pakistan',
        dialCode: '+92',
        code: 'PK',
        nameFr: 'Pakistan',
        hasPostalCode: true,
    },
    {
        name: 'Palau',
        dialCode: '+680',
        code: 'PW',
        nameFr: 'Palaos',
        hasPostalCode: true,
    },
    {
        name: 'Palestinian',
        dialCode: '+970',
        code: 'PS',
        nameFr: 'Palestine',
        hasPostalCode: true,
    },
    {
        name: 'Panama',
        dialCode: '+507',
        code: 'PA',
        nameFr: 'Panama',
        hasPostalCode: true,
    },
    {
        name: 'Papua New Guinea',
        dialCode: '+675',
        code: 'PG',
        nameFr: 'Papouasie-Nouvelle-Guinée',
        hasPostalCode: true,
    },
    {
        name: 'Paraguay',
        dialCode: '+595',
        code: 'PY',
        nameFr: 'Paraguay',
        hasPostalCode: true,
    },
    {
        name: 'Peru',
        dialCode: '+51',
        code: 'PE',
        nameFr: 'Pérou',
        hasPostalCode: true,
    },
    {
        name: 'Philippines',
        dialCode: '+63',
        code: 'PH',
        nameFr: 'Philippines',
        hasPostalCode: true,
    },
    {
        name: 'Pitcairn',
        dialCode: '+872',
        code: 'PN',
        nameFr: 'Îles Pitcairn',
        hasPostalCode: true,
    },
    {
        name: 'Poland',
        dialCode: '+48',
        code: 'PL',
        nameFr: 'Pologne',
        hasPostalCode: true,
    },
    {
        name: 'Portugal',
        dialCode: '+351',
        code: 'PT',
        nameFr: 'Portugal',
        hasPostalCode: true,
    },
    {
        name: 'Puerto Rico',
        dialCode: '+1 939',
        code: 'PR',
        nameFr: 'Porto Rico',
        hasPostalCode: true,
    },
    {
        name: 'Qatar',
        dialCode: '+974',
        code: 'QA',
        nameFr: 'Qatar',
        hasPostalCode: false,
    },
    {
        name: 'Réunion',
        dialCode: '+262',
        code: 'RE',
        nameFr: 'Réunion',
        hasPostalCode: true,
    },
    {
        name: 'Romania',
        dialCode: '+40',
        code: 'RO',
        nameFr: 'Roumanie',
        hasPostalCode: true,
    },
    {
        name: 'Rwanda',
        dialCode: '+250',
        code: 'RW',
        nameFr: 'Rwanda',
        hasPostalCode: false,
    },
    {
        name: 'Russia',
        dialCode: '+7',
        code: 'RU',
        nameFr: 'Russie',
        hasPostalCode: true,
    },
    {
        name: 'Saint Barthélemy',
        dialCode: '+590',
        code: 'BL',
        nameFr: 'Saint Barthélemy',
        hasPostalCode: true,
    },
    {
        name: 'Saint Helena',
        dialCode: '+290',
        code: 'SH',
        nameFr: 'Sainte-Hélène',
        hasPostalCode: true,
    },
    {
        name: 'Saint Kitts and Nevis',
        dialCode: '+1 869',
        code: 'KN',
        nameFr: 'Saint-Christophe-et-Niévès',
        hasPostalCode: true,
    },
    {
        name: 'Saint Lucia',
        dialCode: '+1 758',
        code: 'LC',
        nameFr: 'Sainte-Lucie',
        hasPostalCode: true,
    },
    {
        name: 'Saint Martin',
        dialCode: '+590',
        code: 'MF',
        nameFr: 'Saint-Martin',
        hasPostalCode: true,
    },
    {
        name: 'Saint Pierre and Miquelon',
        dialCode: '+508',
        code: 'PM',
        nameFr: 'Saint-Pierre et Miquelon',
        hasPostalCode: true,
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dialCode: '+1 784',
        code: 'VC',
        nameFr: 'Saint-Vincent-et-les Grenadines',
        hasPostalCode: true,
    },
    {
        name: 'Samoa',
        dialCode: '+685',
        code: 'WS',
        nameFr: 'Samoa',
        hasPostalCode: true,
    },
    {
        name: 'San Marino',
        dialCode: '+378',
        code: 'SM',
        nameFr: 'Saint-Marin',
        hasPostalCode: true,
    },
    {
        name: 'Sao Tome and Principe',
        dialCode: '+239',
        code: 'ST',
        nameFr: 'Sao Tomé-et-Principe',
        hasPostalCode: false,
    },
    {
        name: 'Saudi Arabia',
        dialCode: '+966',
        code: 'SA',
        nameFr: 'Arabie saoudite',
        hasPostalCode: true,
    },
    {
        name: 'Senegal',
        dialCode: '+221',
        code: 'SN',
        nameFr: 'Sénégal',
        hasPostalCode: true,
    },
    {
        name: 'Serbia',
        dialCode: '+381',
        code: 'RS',
        nameFr: 'Serbie',
        hasPostalCode: true,
    },
    {
        name: 'Seychelles',
        dialCode: '+248',
        code: 'SC',
        nameFr: 'Seychelles',
        hasPostalCode: false,
    },
    {
        name: 'Sierra Leone',
        dialCode: '+232',
        code: 'SL',
        nameFr: 'Sierra Leone',
        hasPostalCode: false,
    },
    {
        name: 'Singapore',
        dialCode: '+65',
        code: 'SG',
        nameFr: 'Singapour',
        hasPostalCode: true,
    },
    {
        name: 'Slovakia',
        dialCode: '+421',
        code: 'SK',
        nameFr: 'Slovaquie',
        hasPostalCode: true,
    },
    {
        name: 'Slovenia',
        dialCode: '+386',
        code: 'SI',
        nameFr: 'Slovénie',
        hasPostalCode: true,
    },
    {
        name: 'Solomon Islands',
        dialCode: '+677',
        code: 'SB',
        nameFr: 'Îles Salomon',
        hasPostalCode: false,
    },
    {
        name: 'Somalia',
        dialCode: '+252',
        code: 'SO',
        nameFr: 'Somalie',
        hasPostalCode: true,
    },
    {
        name: 'South Africa',
        dialCode: '+27',
        code: 'ZA',
        nameFr: 'Afrique du Sud',
        hasPostalCode: true,
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        dialCode: '+500',
        code: 'GS',
        nameFr: 'Géorgie du Sud-et-les îles Sandwich du Sud',
        hasPostalCode: true,
    },
    {
        name: 'South Korea',
        dialCode: '+82',
        code: 'KR',
        nameFr: 'Corée du sud',
        hasPostalCode: true,
    },
    {
        name: 'Spain',
        dialCode: '+34',
        code: 'ES',
        nameFr: 'Espagne',
        hasPostalCode: true,
    },
    {
        name: 'Sri Lanka',
        dialCode: '+94',
        code: 'LK',
        nameFr: 'Sri Lanka',
        hasPostalCode: true,
    },
    {
        name: 'Sudan',
        dialCode: '+249',
        code: 'SD',
        nameFr: 'Soudan',
        hasPostalCode: true,
    },
    {
        name: 'Suriname',
        dialCode: '+597',
        code: 'SR',
        nameFr: 'Suriname',
        hasPostalCode: false,
    },
    {
        name: 'Swaziland',
        dialCode: '+268',
        code: 'SZ',
        nameFr: 'Swaziland',
        hasPostalCode: true,
    },
    {
        name: 'Sweden',
        dialCode: '+46',
        code: 'SE',
        nameFr: 'Suède',
        hasPostalCode: true,
    },
    {
        name: 'Switzerland',
        dialCode: '+41',
        code: 'CH',
        nameFr: 'Suisse',
        hasPostalCode: true,
    },
    {
        name: 'Svalbard and Jan Mayen',
        dialCode: '+47',
        code: 'SJ',
        nameFr: 'Svalbard et Jan Mayen',
        hasPostalCode: true,
    },
    {
        name: 'Syria',
        dialCode: '+963',
        code: 'SY',
        nameFr: 'Syrie',
        hasPostalCode: false,
    },
    {
        name: 'Taiwan',
        dialCode: '+886',
        code: 'TW',
        nameFr: 'Taiwan',
        hasPostalCode: true,
    },
    {
        name: 'Tajikistan',
        dialCode: '+992',
        code: 'TJ',
        nameFr: 'Tadjikistan',
        hasPostalCode: true,
    },
    {
        name: 'Tanzania',
        dialCode: '+255',
        code: 'TZ',
        nameFr: 'Tanzanie',
        hasPostalCode: true,
    },
    {
        name: 'Thailand',
        dialCode: '+66',
        code: 'TH',
        nameFr: 'Thaïlande',
        hasPostalCode: true,
    },
    {
        name: 'Timor-Leste',
        dialCode: '+670',
        code: 'TL',
        nameFr: 'Timor-Leste',
        hasPostalCode: false,
    },
    {
        name: 'Togo',
        dialCode: '+228',
        code: 'TG',
        nameFr: 'Togo',
        hasPostalCode: false,
    },
    {
        name: 'Tokelau',
        dialCode: '+690',
        code: 'TK',
        nameFr: 'Tokelau',
        hasPostalCode: false,
    },
    {
        name: 'Tonga',
        dialCode: '+676',
        code: 'TO',
        nameFr: 'Tonga',
        hasPostalCode: false,
    },
    {
        name: 'Trinidad and Tobago',
        dialCode: '+1 868',
        code: 'TT',
        nameFr: 'Trinité-et-Tobago',
        hasPostalCode: true,
    },
    {
        name: 'Tunisia',
        dialCode: '+216',
        code: 'TN',
        nameFr: 'Tunisie',
        hasPostalCode: true,
    },
    {
        name: 'Turkey',
        dialCode: '+90',
        code: 'TR',
        nameFr: 'Turquie',
        hasPostalCode: true,
    },
    {
        name: 'Turkmenistan',
        dialCode: '+993',
        code: 'TM',
        nameFr: 'Turkmenistan',
        hasPostalCode: true,
    },
    {
        name: 'Turks and Caicos Islands',
        dialCode: '+1 649',
        code: 'TC',
        nameFr: 'Îles Turks et Caïques',
        hasPostalCode: true,
    },
    {
        name: 'Tuvalu',
        dialCode: '+688',
        code: 'TV',
        nameFr: 'Tuvalu',
        hasPostalCode: false,
    },
    {
        name: 'Uganda',
        dialCode: '+256',
        code: 'UG',
        nameFr: 'Ouganda',
        hasPostalCode: false,
    },
    {
        name: 'Ukraine',
        dialCode: '+380',
        code: 'UA',
        nameFr: 'Ukraine',
        hasPostalCode: true,
    },
    {
        name: 'United Arab Emirates',
        dialCode: '+971',
        code: 'AE',
        nameFr: 'Émirats Arabes Unis',
        hasPostalCode: false,
    },
    {
        name: 'United Kingdom',
        dialCode: '+44',
        code: 'GB',
        nameFr: 'Grande-Bretagne',
        hasPostalCode: true,
    },
    {
        name: 'United States',
        dialCode: '+1',
        code: 'US',
        nameFr: 'États-Unis',
        hasPostalCode: true,
    },
    {
        name: 'Uruguay',
        dialCode: '+598',
        code: 'UY',
        nameFr: 'Uruguay',
        hasPostalCode: true,
    },
    {
        name: 'Uzbekistan',
        dialCode: '+998',
        code: 'UZ',
        nameFr: 'Ouzbékistan',
        hasPostalCode: true,
    },
    {
        name: 'Vanuatu',
        dialCode: '+678',
        code: 'VU',
        nameFr: 'Vanuatu',
        hasPostalCode: false,
    },
    {
        name: 'Vatican',
        dialCode: '+379',
        code: 'VA',
        nameFr: 'Vatican',
        hasPostalCode: true,
    },
    {
        name: 'Venezuela',
        dialCode: '+58',
        code: 'VE',
        nameFr: 'Venezuela',
        hasPostalCode: true,
    },
    {
        name: 'Vietnam',
        dialCode: '+84',
        code: 'VN',
        nameFr: 'Vietnam',
        hasPostalCode: true,
    },
    {
        name: 'Virgin Islands, British',
        dialCode: '+1 284',
        code: 'VG',
        nameFr: 'îles vierges (british)',
        hasPostalCode: true,
    },
    {
        name: 'Virgin Islands, U.S.',
        dialCode: '+1 340',
        code: 'VI',
        nameFr: 'îles vierges (us)',
        hasPostalCode: true,
    },
    {
        name: 'Wallis and Futuna',
        dialCode: '+681',
        code: 'WF',
        nameFr: 'Wallis et Futuna',
        hasPostalCode: true,
    },
    {
        name: 'Yemen',
        dialCode: '+967',
        code: 'YE',
        nameFr: 'Yémen',
        hasPostalCode: false,
    },
    {
        name: 'Zambia',
        dialCode: '+260',
        code: 'ZM',
        nameFr: 'Zambie',
        hasPostalCode: true,
    },
    {
        name: 'Zimbabwe',
        dialCode: '+263',
        code: 'ZW',
        nameFr: 'Zimbabwe',
        hasPostalCode: false,
    },
];
