import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-new-version-modal',
    templateUrl: './new-version-modal.component.html',
    styleUrls: ['./new-version-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
})
export class NewVersionModalComponent {
    constructor(private readonly _dialogRef: MatDialogRef<NewVersionModalComponent>) {}

    reload(): void {
        window.location.reload();
    }

    close(): void {
        this._dialogRef.close();
    }
}
