import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { Role } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { UserRestaurant } from ':modules/user/user';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

interface AppState {
    user: {
        infos: {
            role: string;
            restaurants: UserRestaurant[];
        };
    };
}

interface DialogData {
    dialogTitle: string;
    dialogSubtitle: string;
    dialogPrimaryButtonText: string;
    dialogSecondaryButtonText: string;
    skipOwnRestaurant: boolean;
    withoutBrandBusiness: boolean;
}

const DEFAULT_RESTAURANT_PAGINATION = 5;
const DEFAULT_ORGANIZATION_PAGINATION = 10;

@Component({
    selector: 'app-restaurants-selection-modal',
    templateUrl: './restaurants-selection-modal.component.html',
    styleUrls: ['./restaurants-selection-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        PaginatorComponent,
        SearchComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        LazyLoadImageModule,
        MatButtonModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        ImagePathResolverPipe,
    ],
})
export class RestaurantsSelectionModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_RESTAURANT_PAGINATION = DEFAULT_RESTAURANT_PAGINATION;
    readonly DEFAULT_ORGANIZATION_PAGINATION = DEFAULT_ORGANIZATION_PAGINATION;

    readonly isAdmin$ = new BehaviorSubject(false);
    readonly hasOrganizations$ = new BehaviorSubject(false);

    readonly restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);

    readonly DISPLAYED_COLUMNS: string[] = ['restaurant'];

    restaurantDataSource: MatTableDataSource<Restaurant> = new MatTableDataSource<Restaurant>([]);
    restaurantSelection = new SelectionModel<Restaurant>(true, []);
    organizationDataSource: MatTableDataSource<string> = new MatTableDataSource<string>([]);
    paginatedOrganizations: string[] = [];
    organizationSelection = new SelectionModel<string>(true, []);

    restaurantsGroupedByOrganization: Record<string, Restaurant[]> = {};
    displayOrganizations = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<RestaurantsSelectionModalComponent>,
        private readonly _translate: TranslateService,
        private readonly _store: Store<AppState>,
        private readonly _restaurantsService: RestaurantsService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.restaurants$.subscribe((restaurants) => {
            const grouped = restaurants.reduce((acc, restaurant) => {
                const organizationName = restaurant.organization?.name ?? 'Other';
                if (!acc[organizationName]) {
                    acc[organizationName] = [];
                }
                acc[organizationName].push(restaurant);
                return acc;
            }, {});
            this.restaurantsGroupedByOrganization = grouped;
            this.organizationDataSource.data = Object.keys(grouped).sort((a, b) => a.localeCompare(b));
            this.restaurantDataSource.data = restaurants.sort((a, b) => a.name.localeCompare(b.name));

            if (this.data?.skipOwnRestaurant) {
                this._restaurantsService.restaurantSelected$.subscribe((ownRestaurant) => {
                    this.restaurantDataSource.data = this.restaurantDataSource.data.filter(
                        (restaurant) => restaurant._id !== ownRestaurant?._id
                    );
                });
            }

            if (this.data?.withoutBrandBusiness) {
                this.restaurantDataSource.data = this.restaurantDataSource.data.filter((restaurant) => !restaurant.isBrandBusiness());
            }
        });

        this._store.select(selectUserInfos).subscribe((user) => {
            this.isAdmin$.next(user?.role === Role.ADMIN);
        });

        combineLatest([this.isAdmin$, this.hasOrganizations$]).subscribe(([isAdmin, hasOrganizations]) => {
            this.displayOrganizations = isAdmin && hasOrganizations;
        });
    }

    @ViewChild('RestaurantPaginator') set restaurantPaginator(paginatorComponent: PaginatorComponent) {
        this.restaurantDataSource.paginator = paginatorComponent.matPaginator;
    }

    @ViewChild('OrganizationPaginator') set organizationPaginator(paginatorComponent: PaginatorComponent) {
        this.organizationDataSource.paginator = paginatorComponent.matPaginator;
    }

    ngOnInit(): void {
        this.data = {
            dialogTitle: this.data?.dialogTitle ?? this._translate.instant('duplicate_to_restaurants_dialog.title'),
            dialogSubtitle: this.data?.dialogSubtitle ?? this._translate.instant('duplicate_to_restaurants_dialog.subtitle'),
            dialogPrimaryButtonText: this.data?.dialogPrimaryButtonText ?? this._translate.instant('common.duplicate'),
            dialogSecondaryButtonText: this.data?.dialogSecondaryButtonText ?? this._translate.instant('common.cancel'),
            withoutBrandBusiness: this.data?.withoutBrandBusiness ?? false,
            skipOwnRestaurant: this.data?.skipOwnRestaurant ?? false,
        };

        this.organizationDataSource.filterPredicate = (organizationName: string, filter: string): boolean =>
            organizationName.toLowerCase().includes(filter) || organizationName.includes(filter);
        this.organizationDataSource.connect().subscribe((data) => {
            this.paginatedOrganizations = data.slice(0, DEFAULT_ORGANIZATION_PAGINATION);
            this.displayOrganizations = this.paginatedOrganizations.length !== 0;
            this.hasOrganizations$.next(data.length > 0);
        });
    }

    cancel(): void {
        this._dialogRef.close();
    }

    save(): void {
        this._dialogRef.close({
            ids: this.restaurantSelection.selected.map((restaurant) => restaurant._id),
        });
    }

    onSearchChange(search: string): void {
        this.restaurantDataSource.filter = search.trim().toLowerCase();
        this.organizationDataSource.filter = search.trim();
    }

    toDataSource(data: Restaurant[]): MatTableDataSource<Restaurant> {
        return new MatTableDataSource<Restaurant>(data);
    }

    toggleAllRows(): void {
        if (this.isAllRestaurantsSelected()) {
            this.restaurantSelection.clear();
            this.organizationSelection.clear();
            return;
        }

        this.restaurantSelection.select(...this.restaurantDataSource.data);
        this.organizationSelection.select(...Object.keys(this.restaurantsGroupedByOrganization));
    }

    isRestaurantSelected = (restaurant: Restaurant): boolean => this.restaurantSelection.isSelected(restaurant);

    selectRestaurant(restaurant: Restaurant): void {
        if (this.isRestaurantSelected(restaurant)) {
            this.restaurantSelection.deselect(restaurant);
        } else {
            this.restaurantSelection.select(restaurant);
        }
    }

    isAllRestaurantsSelected(): boolean {
        return this.restaurantSelection.selected.length === this.restaurantDataSource.data.length;
    }

    isOneOrMoreRestaurantSelected(): boolean {
        return this.restaurantSelection.selected.length > 0;
    }

    isOrganisationSelected = (organizationName: string): boolean => this.organizationSelection.isSelected(organizationName);

    selectOrganisation(organizationName: string): void {
        if (this.isOrganisationSelected(organizationName)) {
            this.organizationSelection.deselect(organizationName);
            this.restaurantSelection.deselect(...this.restaurantsGroupedByOrganization[organizationName]);
        } else {
            this.organizationSelection.select(organizationName);
            this.restaurantSelection.select(...this.restaurantsGroupedByOrganization[organizationName]);
        }
    }

    isOneOrMoreRestaurantSelectedInOrganization = (organizationName: string): boolean =>
        this.restaurantsGroupedByOrganization[organizationName].some((restaurant) => this.restaurantSelection.isSelected(restaurant));

    isAllRestaurantsSelectedInOrganization = (organizationName: string): boolean =>
        this.restaurantsGroupedByOrganization[organizationName].every((restaurant) => this.restaurantSelection.isSelected(restaurant));
}
