import { Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import { Role } from '@malou-io/package-utils';

import { selectUserInfos } from ':modules/user/store/user.selectors';

@Directive({
    selector: '[appShowIfAdmin]',
    standalone: true,
})
export class ShowIfAdminDirective {
    constructor(
        private readonly _store: Store,
        private readonly _el: ElementRef
    ) {
        this._store
            .select(selectUserInfos)
            .pipe(
                take(1),
                map((infos) => infos?.role)
            )
            .subscribe({
                next: (role) => {
                    if (role !== Role.ADMIN) {
                        this._el.nativeElement.remove();
                    }
                },
            });
    }
}
