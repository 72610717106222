import { z } from 'zod';

import { Currency } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getRoiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetRoiSettingsParamsDto = z.infer<typeof getRoiSettingsParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getRoiSettingsForRestaurantsBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
});

export type GetRoiSettingsForRestaurantsBodyDto = z.infer<typeof getRoiSettingsForRestaurantsBodyValidator>;

// ------------------------------------------------------------------------------------------

export const updateRoiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type UpdateRoiSettingsParamsDto = z.infer<typeof updateRoiSettingsParamsValidator>;

export const upsertRoiSettingsBodyValidator = z.object({
    currency: z.nativeEnum(Currency).nullish(),
    averageTicket: z.number().nullish(),
    minRevenue: z.number().nullish(),
    maxRevenue: z.number().nullish(),
    duplicatedFromRestaurantId: objectIdValidator.nullish(),
});

export type UpsertRoiSettingsBodyDto = z.infer<typeof upsertRoiSettingsBodyValidator>;
