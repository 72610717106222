import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformDefinitions, PlatformGroup } from '@malou-io/package-utils';

import { ButtonComponent } from ':shared/components/button/button.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { Platform } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

export interface DisconnectedPlatformsModalProps {
    restaurantId: string;
    platforms: Platform[];
}

export interface DisconnectedPlatformsModalResult {
    ignoreUpdateLocalStorage: boolean;
}

@Component({
    selector: 'app-disconnected-platforms-modal',
    templateUrl: './disconnected-platforms-modal.component.html',
    styleUrls: ['./disconnected-platforms-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, ButtonComponent, PlatformLogoComponent, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisconnectedPlatformsModalComponent {
    private readonly _router = inject(Router);

    readonly SvgIcon = SvgIcon;

    readonly sortedPlatforms: Platform[];

    constructor(
        private readonly _dialogRef: MatDialogRef<DisconnectedPlatformsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: DisconnectedPlatformsModalProps
    ) {
        this.sortedPlatforms = this.data.platforms.sort((platformA, platformB) => {
            const platformAGroup = PlatformDefinitions.getPlatformDefinition(platformA.key)?.group;
            const platformBGroup = PlatformDefinitions.getPlatformDefinition(platformB.key)?.group;
            switch (platformAGroup) {
                case PlatformGroup.MUST_HAVE:
                    return -1;
                case PlatformGroup.SEO:
                    if (platformBGroup === PlatformGroup.MUST_HAVE) {
                        return 1;
                    }
                    if (platformBGroup === PlatformGroup.SEO) {
                        return 0;
                    }
                    return -1;
                default:
                    if (platformBGroup && [PlatformGroup.MUST_HAVE, PlatformGroup.SEO].includes(platformBGroup)) {
                        return 1;
                    }
                    return 0;
            }
        });
    }

    close(res?: DisconnectedPlatformsModalResult | undefined): void {
        this._dialogRef.close(res);
    }

    goToPlatformsConnection(): void {
        const platformToOpen = this.sortedPlatforms[0];
        const queryParams = platformToOpen ? { open_platform: platformToOpen.key } : {};
        this._router.navigate(['restaurants', this.data.restaurantId, 'settings', 'platforms', 'connection'], {
            queryParams,
        });
        this.close({ ignoreUpdateLocalStorage: true });
    }
}
