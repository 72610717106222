import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { MediaCategory, MediaFileType, MediaType, PlatformKey } from '@malou-io/package-utils';

interface FbMediaItem {
    source: string;
    url: string;
    category: string;
}

interface GoogleMediaItem {
    name: string;
    googleUrl: string;
    locationAssociation: {
        category: string;
    };
    description: string;
    mediaFormat: MediaType;
}

export type PlatformMedia = FbMediaItem | GoogleMediaItem;

export interface DownloadedMedia {
    file: File;
    name: string;
    category: MediaCategory;
    description: string;
    fileType: MediaFileType;
}

const checkIsFbMedia = (media: any): media is FbMediaItem => !!media.source;

@Injectable({
    providedIn: 'root',
})
export class PlatformMediaDownloaderService {
    constructor(private _httpClient: HttpClient) {}

    downloadMedia(mediaItems: PlatformMedia[]): Observable<DownloadedMedia[]> {
        const mediasToDownload = mediaItems
            .map((media) => {
                let mappedMedia: any = {};
                if (checkIsFbMedia(media) && media.source === PlatformKey.FACEBOOK) {
                    const name = media.url?.split('/')[5].split('.')[0];
                    mappedMedia = {
                        url: media.url,
                        category: media.category,
                        description: '',
                        fileType: MediaFileType.IMAGE,
                        name,
                    };
                } else if (!checkIsFbMedia(media)) {
                    const {
                        name,
                        googleUrl,
                        locationAssociation: { category },
                        description,
                        mediaFormat,
                    } = media;
                    mappedMedia = {
                        url: googleUrl,
                        category,
                        description,
                        fileType: mapGoogleFormatToMalouFormat(mediaFormat),
                        name,
                    };
                }
                return mappedMedia;
            })
            .filter((media) => !!media.url);
        return forkJoin(
            mediasToDownload.map((media) =>
                this._httpClient
                    .get(media.url, {
                        responseType: 'blob',
                    })
                    .pipe(
                        map((blob: Blob) => {
                            const file = new File([blob], media.name, { type: blob.type });
                            return {
                                file,
                                name: media.name,
                                category: media.category,
                                description: media.description,
                                fileType: media.fileType,
                            };
                        })
                    )
            )
        );
    }
}

const mapGoogleFormatToMalouFormat = (fileType: MediaType): MediaFileType => {
    switch (fileType.toLowerCase()) {
        case MediaType.PHOTO:
            return MediaFileType.IMAGE;
        case MediaType.VIDEO:
            return MediaFileType.VIDEO;
        default:
            throw new Error('file type unsupported');
    }
};
