import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { keyBy } from 'lodash';
import { Observable, switchMap, take } from 'rxjs';

import { MalouMetric } from '@malou-io/package-utils';

import { PostsInsightsService } from ':core/services/post-insights.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { StoriesAndInsights } from ':modules/statistics/social-networks/posts-insights-table/stories/stories.component';
import { selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { AbstractCsvService, CsvAsStringArrays, DEFAULT_CSV_PAGINATION } from ':shared/services/csv-services/csv-service.abstract';

@Injectable({ providedIn: 'root' })
export class StoryCsvInsightsService extends AbstractCsvService<StoriesAndInsights> {
    constructor(
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsInsightsService: PostsInsightsService,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {
        super();
    }

    protected _isDataValid(data: StoriesAndInsights): boolean {
        return data.stories.length > 0;
    }

    protected override _getData$(): Observable<StoriesAndInsights> {
        return this._postsInsightsService.synchronizeStoriesAndFetchInsights$(this._restaurantsService.currentRestaurant?._id).pipe(
            take(1),
            switchMap(() => this._store.select(selectDatesFilter).pipe(take(1))),
            switchMap((dates) =>
                this._postsInsightsService.getStoriesAndInsights$(
                    this._restaurantsService.currentRestaurant?._id,
                    DEFAULT_CSV_PAGINATION,
                    dates.startDate,
                    dates.endDate
                )
            )
        );
    }
    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Hour', 'Platform', 'Impressions', 'Reach', 'Exits', 'Next story', 'Previous story'];
    }
    protected override _getCsvDataRows({ stories, insights }: StoriesAndInsights): CsvAsStringArrays {
        const insightsMap = keyBy(insights, 'socialId');

        return stories.map((story) => {
            const storyInsights = insightsMap[story.socialId];
            const date = new Date(story.socialCreatedAt).toLocaleDateString();
            const hour = `${new Date(story.socialCreatedAt).getHours()}:${new Date(story.socialCreatedAt).getMinutes()}`;
            const platform = this._enumTranslatePipe.transform(story.key, 'platform_key');
            const impressions = storyInsights?.data?.find((el) => el.metric === MalouMetric.IMPRESSIONS)?.value?.toString() ?? '0';
            const reach = storyInsights?.data?.find((el) => el.metric === MalouMetric.REACH)?.value?.toString() ?? '0';
            const tapsExits = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_EXITS)?.value?.toString() ?? '0';
            const tapsForward = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_FORWARD)?.value?.toString() ?? '0';
            const tapsBack = storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_BACK)?.value?.toString() ?? '0';

            return [date, hour, platform, impressions, reach, tapsExits, tapsForward, tapsBack];
        });
    }
}
