@if (isLoading || isUpdating) {
    @if (!screenSizeService.isPhoneScreen) {
        <div class="post-grid grid h-full gap-x-4.5 gap-y-6 overflow-y-scroll px-7.5 md:px-6">
            @for (item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]; track item) {
                <app-skeleton skeletonClass="malou-card !m-0 !h-[17.625rem] !p-5 !flex-none secondary-bg"></app-skeleton>
            }
        </div>
    } @else {
        <div class="hide-scrollbar h-full overflow-y-scroll">
            <app-skeleton gapClass="gap-y-2" skeletonClass="secondary-bg !h-[4.375rem]" [count]="12"></app-skeleton>
        </div>
    }
} @else {
    @if (!!fetchCommentsError || !posts || posts.length === 0) {
        <app-no-comment-view [error]="fetchCommentsError" [hasSearch]="!(areFiltersDefault$ | async)"></app-no-comment-view>
    } @else {
        <div
            class="h-full overflow-y-scroll px-7.5 md:px-6"
            id="comments-posts-infinite-scroll-container"
            infinite-scroll
            infiniteScrollContainer="#comments-posts-infinite-scroll-container"
            [infiniteScrollDistance]="2"
            [fromRoot]="true"
            (scrolled)="onScroll()">
            @if (!screenSizeService.isPhoneScreen) {
                <div class="post-grid grid gap-x-4.5 gap-y-6">
                    @for (post of posts; track trackByIdFn($index, post)) {
                        <app-post-comment-preview
                            [post]="post"
                            [filters]="(filters$ | async) ?? {}"
                            [platforms]="platforms"
                            (archived)="onCommentToggleArchive($event)">
                        </app-post-comment-preview>
                    }
                    @if (loadingMore$ | async) {
                        @for (item of [0, 1]; track item) {
                            <div class="!m-0">
                                <app-skeleton
                                    skeletonClass="malou-card !m-0 !h-[17.625rem] !p-5 !flex-none secondary-bg"
                                    [useContainer]="false">
                                </app-skeleton>
                            </div>
                        }
                    }
                </div>
            } @else {
                <div class="flex flex-col gap-y-2">
                    @for (post of posts; track trackByIdFn($index, post)) {
                        <app-post-comment-preview [post]="post" [filters]="(filters$ | async) ?? {}" [platforms]="platforms">
                        </app-post-comment-preview>
                    }
                    @if (loadingMore$ | async) {
                        @for (item of [0, 1]; track item) {
                            <div class="!m-0">
                                <app-skeleton skeletonClass="secondary-bg !h-[4.375rem]" [useContainer]="false"> </app-skeleton>
                            </div>
                        }
                    }
                </div>
            }
            @if (pagination.pageNumber * pagination.pageSize >= pagination.total) {
                <div class="my-9 flex w-full justify-center md:mb-36">
                    <div class="mr-1 h-4 w-4">
                        <img alt="Goggles illustration" [lazyLoad]="'Goggles' | illustrationPathResolver" />
                    </div>
                    <div class="malou-text-13--semibold malou-color-text-2">
                        {{ 'moderation.end_of_comments' | translate }}
                    </div>
                </div>
            }
            <app-scroll-to-top class="fixed bottom-7.5 right-7.5 md:bottom-20" container="#comments-posts-infinite-scroll-container">
            </app-scroll-to-top>
        </div>
    }
}
