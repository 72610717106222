import { PlatformAccessStatus } from '@malou-io/package-utils';

import { PlatformsManagementViewType } from ':modules/admin/platforms-management/platforms-management-actions-header/platforms-management-action-header.interface';

import { Restaurant } from './restaurant';

export interface InformationUpdate {
    _id?: string;
    restaurantId: string;
    platforms: {
        key: string;
        lastVerifiedByAdmin: Date;
        status: PlatformAccessStatus;
        createdAt: Date;
    }[];
    completed: boolean;
    newInformationFields: string[];
    restaurant: Partial<Restaurant>;
    updatedAt: Date;
    createdAt: Date;
    lastClientUpdate: Date;
}

export enum InformationUpdateOptions {
    ACCESS = 'access',
    UPDATE = 'update',
    BOTH = 'both',
}
export interface InformationUpdateFilters {
    selectedOption: InformationUpdateOptions;
    viewType: PlatformsManagementViewType;
    showProcessed: boolean;
}
