<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="'common.validate' | translate"
    [primaryButtonDisabled]="urlControl.invalid || isLoading() || !selectedPlatformSearch()"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isUpdatingPlatform()"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex h-full flex-col gap-y-4">
        <div class="malou-color-text-1 malou-text-12--bold">{{ translations().description | translate }}</div>
        <app-input-text
            [testId]="'simple-account-managed-connection-select-business-url-input'"
            [formControl]="urlControl"
            [placeholder]="urlPlaceholder()"
            [errorMessage]="isUrlInError() ? urlPlaceholder() : undefined"
            [autocapitalize]="'none'"
            [disabled]="isUpdatingPlatform()">
        </app-input-text>
        @if (isLoading()) {
            <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        } @else if (!isFirstSearchDone()) {
            <!--empty-->
        } @else {
            <div class="min-h-0 grow">
                <app-platforms-connection-business-selector
                    [translations]="{
                        noResultsDescription: translations().noResultsDescription,
                    }"
                    [selectedValue]="selectedPlatformSearch()"
                    [compareWith]="platformSearchCompareWith"
                    [selectableValues]="platformSearchResults()"
                    [showSearchInput]="false"
                    [displayWith]="platformSearchDisplayWith"
                    [isDisabled]="isUpdatingPlatform()"
                    (selectionChange)="onBusinessChange($event)"></app-platforms-connection-business-selector>
            </div>
        }
    </div>
</app-modal-structure>

<ng-template #loadingTemplate>
    <div class="flex h-full items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
</ng-template>
