import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

export const getPostInsightsParamsValidator = z
    .object({
        restaurant_id: z.string(),
        platform_key: z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK]),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platformKey: data.platform_key,
    }));

export const getPostInsightsBodyValidator = z.object({
    socialIds: z.array(z.string()),
});

export type GetPostInsightsParamsDto = z.infer<typeof getPostInsightsParamsValidator>;
export type GetPostInsightsBodyDto = z.infer<typeof getPostInsightsBodyValidator>;
