import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { HeapEventName, NotificationType } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';

@Injectable({ providedIn: 'root' })
export class HeapEmailEventsTrackerService {
    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _heapService: HeapService
    ) {}

    trackEmailEvent(): void {
        this._activatedRoute.queryParamMap.subscribe((queryParams) => {
            this._trackSummaryNotificationEvents(queryParams);
        });
    }

    private _trackSummaryNotificationEvents(queryParams: ParamMap): void {
        const utmSource = queryParams.get('utm_source');
        const type = queryParams.get('type');
        const innerType = queryParams.get('inner_type');

        const isInnerTypeValid = [
            NotificationType.SPECIAL_HOUR,
            NotificationType.POST_SUGGESTION,
            NotificationType.COMMENT,
            NotificationType.MENTION,
            NotificationType.NEW_MESSAGE,
            NotificationType.REVIEW,
            NotificationType.POST_ERROR,
            NotificationType.SUMMARY,
        ].includes(innerType as NotificationType);

        if (type === NotificationType.SUMMARY && utmSource === 'email' && isInnerTypeValid) {
            this._heapService.track(HeapEventName.NOTIFICATION_SUMMARY_WEB_REDIRECT, {
                notificationId: queryParams.get('notification_id') ?? '',
                notificationType: innerType,
            });
        }
    }
}
