@if (isHeadbandOpen$ | async) {
    <div class="malou-color-background--warning relative p-4">
        <div class="flex w-full items-center justify-center md:justify-start">
            <div
                class="malou-border-color-warn malou-color-white malou-color-bg-warn mr-4 hidden !h-4 !min-w-[16px] items-center justify-center rounded-full pt-0.5 text-xs md:flex">
                !
            </div>
            <div class="flex md:flex-col">
                <div
                    class="malou-border-color-warn malou-color-white malou-color-bg-warn flex !h-4 !min-w-[16px] items-center justify-center rounded-full pt-0.5 text-xs md:hidden">
                    !
                </div>
                <span class="malou-text-12--medium malou-color-state-warn ml-4 mr-2 md:ml-0">
                    {{ warningText$ | async }}
                </span>
                <span class="malou-text-12--bold malou-color-state-warn cursor-pointer underline" (click)="onCtaButtonClick()">
                    {{ ctaButtonLabel }}
                </span>
            </div>
            <div class="absolute right-[20px]">
                <mat-icon
                    class="malou-color-state-warn !h-3.5 !w-3.5 cursor-pointer"
                    [svgIcon]="SvgIcon.CROSS"
                    (click)="closeHeadBand()"></mat-icon>
            </div>
        </div>
    </div>
}
