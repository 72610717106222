@if (wheelOfFortune()) {
    <div
        class="cover bg-center-top flex flex-col items-center justify-center bg-malou-color-background-dark bg-contain bg-no-repeat"
        [ngStyle]="{
            height: wheelContainerHeight() + 'px',
            width: wheelContainerWidth() + 'px',
        }"
        #wheelOfFortunePosterContainer>
        <ng-container
            [ngTemplateOutlet]="format() === WheelOfFortunePosterFormat.A4_PORTRAIT ? portraitPosterTemplate : landscapePosterTemplate">
        </ng-container>
    </div>
}

<ng-template #portraitPosterTemplate>
    <div
        class="z-50 bg-contain bg-no-repeat"
        [ngStyle]="{
            'background-image': 'url(' + ('stars_portrait' | imagePathResolver: { folder: 'wheels-of-fortune' }) + ')',
            height: wheelContainerHeight() + 'px',
            width: wheelContainerWidth() + 'px',
        }">
        <div class="mt-[30px] flex h-[60px] justify-center">
            @if (logo()) {
                <img alt="logo" [src]="logo() | applySelfPure: 'getMediaUrl'" />
            }
        </div>
        <div class="malou-text-40--semibold mt-[16px] px-[58px] text-center font-passion-one leading-[normal] text-malou-color-text-1">
            {{ 'wheel_of_fortune.wheel_of_fortune_poster.turn_the_wheel' | translate }}
            <span [ngStyle]="{ color: primaryColor() }">
                {{ 'wheel_of_fortune.wheel_of_fortune_poster.discover_gift' | translate }}
            </span>
        </div>
        <div class="mt-[100px] flex justify-center">
            <img class="w-[130px] rounded-[10px]" alt="qr-code" [src]="qrCodeImage()" />
        </div>
        <div class="absolute left-[6%] top-[35%] w-[105px]">
            <div class="malou-text-30--medium absolute mr-[9%] mt-[12%] rotate-[-9deg] text-center font-meow-script leading-none">
                {{ 'wheel_of_fortune.wheel_of_fortune_poster.winner' | translate }}
            </div>
            <div class="h-[100px] w-[100px] rounded-full bg-white"></div>
            <img class="absolute left-[32px] top-[110px] w-[26px]" [src]="'arrow-1' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>
        <div class="absolute right-[8%] top-[37%]">
            <img
                class="absolute bottom-[12%] left-[14%] w-[80px] rotate-[8deg]"
                [src]="'gift' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
            <div class="h-[105px] w-[105px] rounded-full bg-white"></div>
            <img
                class="absolute bottom-[-65%] left-[70%] w-[20px]"
                [src]="'arrow-2' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>
    </div>
    <div class="absolute top-[380px]">
        <ng-container [ngTemplateOutlet]="wheelOfFortuneTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #landscapePosterTemplate>
    <div
        class="z-50 bg-contain bg-no-repeat"
        [ngStyle]="{
            'background-image': 'url(' + ('stars_landscape' | imagePathResolver: { folder: 'wheels-of-fortune' }) + ')',
            height: wheelContainerHeight() + 'px',
            width: wheelContainerWidth() + 'px',
        }">
        <div class="mt-[30px] flex h-[28px] justify-center">
            @if (logo()) {
                <img alt="logo" [src]="logo() | applySelfPure: 'getMediaUrl'" />
            }
        </div>
        <div class="malou-text-40--semibold mt-[28px] px-[56px] text-center font-passion-one leading-[normal] text-malou-color-text-1">
            {{ 'wheel_of_fortune.wheel_of_fortune_poster.turn_the_wheel' | translate }}
            <span [ngStyle]="{ color: primaryColor() }">
                {{ 'wheel_of_fortune.wheel_of_fortune_poster.discover_gift' | translate }}
            </span>
        </div>
        <div class="mt-[40px] flex justify-center">
            <img class="w-[140px] rounded-[10px]" alt="qr-code" [src]="qrCodeImage()" />
        </div>
        <div class="absolute left-[6%] top-[46%] w-[105px]">
            <div class="malou-text-30--medium absolute mr-[9%] mt-[12%] rotate-[-9deg] text-center font-meow-script leading-none">
                {{ 'wheel_of_fortune.wheel_of_fortune_poster.winner' | translate }}
            </div>
            <div class="h-[100px] w-[100px] rounded-full bg-white"></div>
            <img
                class="absolute left-[60px] top-[110px] w-[30px] rotate-[-18deg]"
                alt="arrow-1"
                [src]="'arrow-1' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>
        <div class="absolute right-[8%] top-[42%]">
            <img
                class="absolute bottom-[12%] left-[14%] w-[80px] rotate-[8deg]"
                [src]="'gift' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
            <div class="h-[105px] w-[105px] rounded-full bg-white"></div>
            <img
                class="absolute top-[125px] w-[65px]"
                alt="arrow-3"
                [src]="'arrow-3' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>
    </div>
    <div class="absolute top-[280px]">
        <ng-container [ngTemplateOutlet]="wheelOfFortuneTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #wheelOfFortuneTemplate>
    <div class="flex justify-center">
        <div class="absolute top-[33%] z-10 border-x-[10px] border-b-[38px] border-malou-color-text-1 !border-x-transparent"></div>
        <div
            class="malou-color-bg-white relative h-[360px] w-[360px] rotate-[7.5deg] transform overflow-hidden rounded-full border-[8px] border-white"
            #wheelDisplay>
            <div
                class="absolute left-[42%] top-[42%] z-10 h-[16%] w-[16%] rounded-full border-[5px] border-malou-color-text-1"
                [ngStyle]="{ 'background-color': primaryColor() }"></div>
            @for (index of allSubSectors(); track index) {
                <div>
                    <div
                        class="absolute left-[43%] flex h-[50%] w-[14%] origin-bottom rotate-[-50%] transform items-center justify-center"
                        style="clip-path: polygon(100% 0, 50% 100%, 0 0)"
                        [ngStyle]="{
                            transform: getRotateDegrees() | applyPure: index,
                            'background-color':
                                (isMiddleSector | applyPure: index)
                                    ? (addOpacityToColor | applyPure: (getColor() | applyPure: index))
                                    : (getColor() | applyPure: index),
                        }"></div>
                </div>
            }
        </div>
    </div>
</ng-template>
