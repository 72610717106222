@if (isMalouTokenCreated()) {
    <ng-container *ngTemplateOutlet="malouTokenCreated"></ng-container>
} @else {
    <ng-container *ngTemplateOutlet="malouTokenNotCreated"></ng-container>
}

<ng-template #malouTokenCreated>
    <app-modal-structure
        [title]="'platforms.connection_new.mapstr.step_0.step_name' | translate"
        [primaryButtonText]="'platforms.connection_new.mapstr.step_0.malou_token_created.primary_button' | translate"
        [secondaryButtonText]="'common.cancel' | translate"
        [buttonStyle]="ButtonStyle.CLASSIC"
        (close)="close.emit({})"
        (primaryClick)="onPrimaryClick()"
        (secondaryClick)="close.emit({})">
        <div class="flex flex-col items-center gap-y-8 pt-20">
            <img
                class="h-[200px] w-[410px]"
                [src]="'mapstr-api-token' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
            <div
                class="malou-text-10--regular malou-text-color-2"
                [innerHtml]="
                    'platforms.connection_new.mapstr.step_0.malou_token_created.description'
                        | translate: { class: 'malou-text-10--semibold' }
                "></div>
        </div>
    </app-modal-structure>
</ng-template>

<ng-template #malouTokenNotCreated>
    <app-modal-structure
        [title]="'platforms.connection_new.mapstr.step_0.step_name' | translate"
        [primaryButtonText]="'platforms.connection_new.mapstr.step_0.malou_token_not_created.primary_button' | translate"
        [secondaryButtonText]="'common.cancel' | translate"
        [buttonStyle]="ButtonStyle.CLASSIC"
        (close)="close.emit({})"
        (primaryClick)="onPrimaryClickBis()"
        (secondaryClick)="close.emit({})">
        <div class="flex flex-col items-center gap-y-6 pt-20">
            <div class="flex h-[84px] w-[84px] items-center justify-center rounded-full bg-malou-color-background-dark">
                <img class="h-auto w-[50px]" [src]="'hourglass' | emojiPathResolver" />
            </div>

            <div class="flex flex-col items-center gap-y-2">
                <div class="malou-text-14--bold malou-color-text-1">
                    {{ 'platforms.connection_new.mapstr.step_0.malou_token_not_created.title' | translate }}
                </div>
                <div class="malou-text-12--medium malou-color-text-1">
                    {{ 'platforms.connection_new.mapstr.step_0.malou_token_not_created.description' | translate }}
                </div>
                <div class="malou-text-12--regular malou-color-text-2 italic">
                    {{ 'platforms.connection_new.mapstr.step_0.malou_token_not_created.estimated_date' | translate }}
                    {{ tomorrow() | date }}
                </div>
            </div>
        </div>
    </app-modal-structure>
</ng-template>
