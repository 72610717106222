import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { KeywordPopularity } from '@malou-io/package-utils';

import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-keywords-popularity',
    templateUrl: './keywords-popularity.component.html',
    styleUrls: ['./keywords-popularity.component.scss'],
    standalone: true,
    imports: [NgClass, MatTooltipModule, TranslateModule, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsPopularityComponent {
    readonly popularity: InputSignal<KeywordPopularity> = input.required();

    readonly KeywordPopularity = KeywordPopularity;
}
