import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { ClientsService } from ':modules/clients/clients.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { Client } from ':shared/models/client';
import { PrivateReview } from ':shared/models/private-review';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-campaign-negative-review',
    templateUrl: './campaign-negative-review.component.html',
    styleUrls: ['./campaign-negative-review.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        TextAreaComponent,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        IllustrationPathResolverPipe,
        TranslateModule,
    ],
})
export class CampaignNegativeReviewComponent implements OnInit {
    clientId: string;
    restaurantId: string;
    campaignId: string;
    client: Client;
    stars: number | undefined;
    errorOccured = false;
    reviewTextForm = new UntypedFormControl('', Validators.required);
    submittedReview = false;
    apiKey: string;
    hasAlreadyLeftReview = false;
    disableValidate = false;

    readonly Illustration = Illustration;
    reviewSentSuccessFully = false;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _translate: TranslateService,
        private readonly _reviewsService: ReviewsService,
        private readonly _clientsService: ClientsService,
        private readonly _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.errorOccured = this._route.snapshot.queryParams.error === 'true';
        if (this.errorOccured) {
            return;
        }

        this.clientId = this._route.snapshot.queryParams.client_id;
        this.restaurantId = this._route.snapshot.queryParams.restaurant_id;
        this.campaignId = this._route.snapshot.queryParams.campaign_id;
        this.stars = this._route.snapshot.queryParams.star ? parseInt(this._route.snapshot.queryParams.star, 10) : undefined;
        this.apiKey = this._route.snapshot.queryParams.api_key;

        if (this.campaignId !== 'test') {
            this._clientsService.getHasClientLeftNegativeReview(this.clientId, this.apiKey).subscribe({
                next: (res) => {
                    this.hasAlreadyLeftReview = res.data.hasLeftReview;
                    this.disableValidate = res.data.isTest ?? false;
                },
                error: (err) => {
                    console.warn(err);
                    this.errorOccured = true;
                },
            });
        }
    }

    submitReview(): void {
        this.submittedReview = true;
        const privateReview: Partial<PrivateReview> = {
            restaurantId: this.restaurantId,
            text: this.reviewTextForm.value,
            lang: ApplicationLanguage.FR,
            campaignId: this.campaignId,
            socialCreatedAt: new Date(),
            rating: this.stars, // must be undefined if no rating
            clientId: this.clientId,
            archived: false,
        };
        this._reviewsService.createPrivateReview(privateReview).subscribe({
            next: () => {
                this.reviewSentSuccessFully = true;
            },
            error: (err) => {
                console.warn('Err >> : ', err);
                this.errorOccured = true;
                this.submittedReview = false;
                this._toastService.openErrorToast(this._translate.instant('campaigns.negative_review.review_not_created'));
            },
        });
    }
}
