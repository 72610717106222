import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map, of } from 'rxjs';

import {
    ApplicationLanguage,
    CommentOptionValue,
    CustomerNaming,
    FrenchTutoiementVouvoiement,
    isObjectRequired,
    KeywordScoreTextType,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { AiService } from ':core/services/ai.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { AiAdvancedReviewSettingsModalTabComponent } from ':modules/ai-settings/ai-review-settings/edit-ai-review-settings-modal/ai-advanced-review-settings-modal-tab/ai-advanced-review-settings-modal-tab.component';
import { AiGeneralReviewSettingsModalTabComponent } from ':modules/ai-settings/ai-review-settings/edit-ai-review-settings-modal/ai-general-review-settings-modal-tab/ai-general-review-settings-modal-tab.component';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { Indication, KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { formatDate } from ':shared/helpers';
import { highlightKeywordsInText, Keyword } from ':shared/models';
import { CommentOption } from ':shared/models/comment-option';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { SelectLanguagesComponent } from '../../../../shared/components/select-languages/select-languages.component';

export interface LangOption {
    value: ApplicationLanguage;
    text: string;
}

export interface EditRestaurantAiSettingsModalInputData {
    restaurantAiSettings: RestaurantAiSettings;
    selectedKeywords: Keyword[];
}

interface RestaurantAiSettingsTab {
    label: string;
    key: TabKeys;
}

enum TabKeys {
    GENERAL = 'general',
    ADVANCED = 'advanced',
}

@Component({
    selector: 'app-edit-ai-review-settings-modal',
    templateUrl: './edit-ai-review-settings-modal.component.html',
    styleUrls: ['./edit-ai-review-settings-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        TranslateModule,
        AiAdvancedReviewSettingsModalTabComponent,
        CloseWithoutSavingModalComponent,
        AiGeneralReviewSettingsModalTabComponent,
        MalouSpinnerComponent,
        StarGaugeComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ImagePathResolverPipe,
        KeywordsScoreGaugeComponent,
        SelectLanguagesComponent,
        AsyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAiReviewSettingsModalComponent implements OnInit {
    @ViewChild('keywordsScoreGauge') keywordsScoreGauge: KeywordsScoreGaugeComponent;
    readonly aiSettingsContext = inject(AiSettingsContext);

    private readonly _data: EditRestaurantAiSettingsModalInputData = inject(MAT_DIALOG_DATA);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _aiService = inject(AiService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _dialogRef = inject(MatDialogRef<EditAiReviewSettingsModalComponent>);

    readonly ApplicationLanguage = ApplicationLanguage;
    readonly APP_LANGUAGES = Object.values(ApplicationLanguage);
    readonly SvgIcon = SvgIcon;
    readonly selectedKeywords = signal<Keyword[]>([]);

    readonly aiReviewSettingsForm = new FormGroup({
        replyTone: new FormControl<FrenchTutoiementVouvoiement>(FrenchTutoiementVouvoiement.DOES_NOT_MATTER, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        catchphrase: new FormControl<string>('', {
            nonNullable: true,
        }),
        customerNaming: new FormControl<CustomerNaming>(CustomerNaming.TITLE_AND_LASTNAME, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        shouldTranslateCatchphrase: new FormControl<boolean>(false, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        signatures: new FormArray([
            new FormControl<string>('', {
                nonNullable: true,
            }),
        ]),
        restaurantKeywordIds: new FormControl<string[]>([], { nonNullable: true }),
        forbiddenWords: new FormControl<string[]>([], {
            nonNullable: true,
        }),
        shouldTranslateSignature: new FormControl<boolean>(false, {
            validators: [Validators.required],
            nonNullable: true,
        }),
    });

    get signatures(): FormArray<FormControl<string>> {
        return this.aiReviewSettingsForm.controls.signatures;
    }

    readonly isFormValid$ = this.aiReviewSettingsForm.valueChanges.pipe(map(() => this.aiReviewSettingsForm.valid));

    readonly aiReviewSettingsForm$ = this.aiReviewSettingsForm.valueChanges.pipe(
        filter((form) => isObjectRequired(form)),
        map(
            (form) =>
                new RestaurantAiSettings({
                    ...this.aiSettingsContext.restaurantAiSettings()!,
                    reviewSettings: {
                        customerNaming: form.customerNaming!,
                        forbiddenWords: form.forbiddenWords!,
                        replyTone: form.replyTone!,
                        restaurantKeywordIds: form.restaurantKeywordIds!,
                        shouldTranslateCatchphrase: form.shouldTranslateCatchphrase!,
                        shouldTranslateSignature: form.shouldTranslateSignature!,
                        catchphrase: form.catchphrase!,
                        signatures: form.signatures!,
                        signatureTranslationIds: [],
                    },
                })
        )
    );

    readonly TabKeys = TabKeys;
    private readonly _GENERAL_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.GENERAL,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.general.title'),
    };
    private readonly _ADVANCED_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.ADVANCED,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.advanced.title'),
    };
    readonly TABS = signal<RestaurantAiSettingsTab[]>([this._GENERAL_TAB, this._ADVANCED_TAB]);
    readonly selectedTabIndex = signal<number>(0);
    readonly selectedTab = computed<RestaurantAiSettingsTab>(() => this.TABS()[this.selectedTabIndex()]);

    readonly displayCloseModal = signal(false);
    readonly isSubmitting = signal(false);
    readonly currentDate = signal(formatDate(new Date()));

    readonly CommentOptionValue = CommentOptionValue;
    readonly AVAILABLE_COMMENT_OPTIONS: CommentOption[] = [
        { value: CommentOptionValue.WITH, text: this._translateService.instant('restaurant_ai_settings.modals.upsert.with_comments') },
        {
            value: CommentOptionValue.WITHOUT,
            text: this._translateService.instant('restaurant_ai_settings.modals.upsert.without_comments'),
        },
    ];

    readonly previewReviewReplyForm = new FormGroup({
        withComment: new FormControl(this.AVAILABLE_COMMENT_OPTIONS[0]),
        chosenLanguageForPreview: new FormControl<ApplicationLanguage | null>(null),
    });

    get chosenLanguageForPreview(): FormControl<ApplicationLanguage> {
        return this.previewReviewReplyForm.controls.chosenLanguageForPreview as FormControl<ApplicationLanguage>;
    }

    readonly currentWithCommentOption = signal<CommentOptionValue>(this.AVAILABLE_COMMENT_OPTIONS[0].value);

    readonly isLoadingPreview = signal<boolean>(false);
    readonly previewReviewReply = signal<string>('');

    readonly formattedPreviewReviewReply = computed(() =>
        highlightKeywordsInText({
            text: this.previewReviewReply(),
            keywords: this._data.selectedKeywords,
            restaurantName: this.aiSettingsContext.restaurantAiSettings()?.restaurantName,
            currentLang: this.chosenLanguageForPreview.value,
        })
    );

    readonly previewReviewReply$ = toObservable(this.previewReviewReply);
    readonly oneHour$ = of(1);
    readonly reviewerName$ = of(this._translateService.instant('restaurant_ai_settings.modals.upsert.reviewer_name'));
    readonly keywords$ = of(this._data.selectedKeywords);
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly textType$ = of(KeywordScoreTextType.HIGH_RATE_REVIEW);
    readonly lang$ = this.chosenLanguageForPreview.valueChanges;
    readonly keywordsIndicationList: WritableSignal<Indication[]> = signal([]);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    isTouched = false;
    isFirstEffectCall = true;

    readonly selectedIndex = signal(0);

    constructor() {
        this.selectedKeywords.set(this._data.selectedKeywords);
        console.log(this.aiSettingsContext.restaurantAiSettings()!.defaultLanguageResponse);
        this.previewReviewReplyForm.patchValue({
            chosenLanguageForPreview: this.aiSettingsContext.restaurantAiSettings()!.defaultLanguageResponse,
        });
    }

    ngOnInit(): void {
        this._initializeReviewSettingsForm();
    }

    close(): void {
        if (this.isSubmitting()) {
            return;
        }
        if (this.isTouched) {
            this.displayCloseModal.set(true);
        } else {
            this.confirmClose();
        }
    }

    confirmClose(): void {
        this._dialogRef.close();
    }

    displayOption(option: { text: string }): string {
        return option.text;
    }

    generateResponsePreview(): void {
        const aiReviewSettingsForm = this.aiReviewSettingsForm.value;
        const restaurantAiSettings = this.aiSettingsContext.restaurantAiSettings()!;
        // only to narrow the type
        if (isObjectRequired(aiReviewSettingsForm)) {
            this.isLoadingPreview.set(true);

            if (!aiReviewSettingsForm) {
                this._toastService.openErrorToast(
                    this._translateService.instant('restaurant_ai_settings.modals.upsert.error_generating_preview')
                );
                return;
            }
            aiReviewSettingsForm.signatures = [];
            this._aiService
                .answerReviewPreview({
                    restaurantId: restaurantAiSettings.restaurantId,
                    restaurantAiSettings: new RestaurantAiSettings({
                        ...restaurantAiSettings!,
                        reviewSettings: aiReviewSettingsForm,
                    }),
                    sourceLanguage: this.chosenLanguageForPreview.value,
                    lang: this.chosenLanguageForPreview.value,
                    reviewerName: this._translateService.instant('restaurant_ai_settings.modals.upsert.reviewer_name'),
                    text:
                        this.currentWithCommentOption() === CommentOptionValue.WITH
                            ? this._translateService.instant('restaurant_ai_settings.modals.upsert.example_preview')
                            : '',
                })
                .subscribe({
                    next: (res) => {
                        this.isLoadingPreview.set(false);
                        this.previewReviewReply.set(res.data);
                    },
                    error: (err) => {
                        this.isLoadingPreview.set(false);
                        console.warn(err);
                        this._toastService.openErrorToast(
                            this._translateService.instant('restaurant_ai_settings.modals.upsert.error_generating_preview')
                        );
                    },
                });
        }
    }

    async submit(): Promise<void> {
        if (this.aiReviewSettingsForm.invalid) {
            return;
        }
        const aiReviewSettingsForm = this.aiReviewSettingsForm.value;

        // just to narrow the type
        if (isObjectRequired(aiReviewSettingsForm)) {
            this.isSubmitting.set(true);

            try {
                await this.aiSettingsContext.updateAiSettings({
                    restaurantAiSettings: new RestaurantAiSettings({
                        ...this.aiSettingsContext.restaurantAiSettings()!,
                        reviewSettings: {
                            ...aiReviewSettingsForm,
                            signatures: aiReviewSettingsForm.signatures.filter((signature) => signature.trim() !== ''),
                        },
                    }),
                    dispatcher: RestaurantAiSettingsDispatch.review,
                });
                this.confirmClose();
                this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.upsert.success'));
                this.isSubmitting.set(false);
            } catch (e) {
                this._toastService.openErrorToast(
                    this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.upsert_error')
                );
                this.isSubmitting.set(false);
            }
        }
    }

    onTabChanged(event: MatTabChangeEvent): void {
        this.selectedIndex.set(event.index);
    }

    onWithCommentChange(event: CommentOption): void {
        this.currentWithCommentOption.set(event.value);
    }

    private _initializeReviewSettingsForm(): void {
        const settings = this.aiSettingsContext.restaurantAiSettings();
        if (settings) {
            this.aiReviewSettingsForm.patchValue({
                catchphrase: settings.reviewSettings.catchphrase,
                customerNaming: settings.reviewSettings.customerNaming,
                replyTone: settings.reviewSettings.replyTone,
                shouldTranslateCatchphrase: settings.reviewSettings.shouldTranslateCatchphrase,
                shouldTranslateSignature: settings.reviewSettings.shouldTranslateSignature,
                forbiddenWords: settings.reviewSettings.forbiddenWords,
                restaurantKeywordIds: settings.reviewSettings.restaurantKeywordIds,
            });
            if (settings.reviewSettings.signatures.length > 0) {
                this.signatures.clear();
                settings.reviewSettings.signatures.forEach((signature) =>
                    this.signatures.push(
                        new FormControl<string>(signature, {
                            nonNullable: true,
                        })
                    )
                );
            }
        }
    }
}
