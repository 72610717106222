import { z } from 'zod';

import {
    BaseEmailProps,
    BaseReportEmailProps,
    gmbPerformanceReportValidator,
    incomingEventsPerformanceReportValidator,
    notAnsweredMessagesPerformanceReportValidator,
    reviewsStatsPerformanceReportValidator,
    socialMediaPerformanceReportValidator,
} from '../common';

export const weeklyPerformanceReportValidator = z.object({
    incomingEvents: incomingEventsPerformanceReportValidator.optional(),
    gmb: gmbPerformanceReportValidator.optional(),
    reviews: reviewsStatsPerformanceReportValidator.optional(),
    socialMedia: socialMediaPerformanceReportValidator.optional(),
    notAnsweredMessages: notAnsweredMessagesPerformanceReportValidator.optional(),
});

export type WeeklyPerformanceReport = z.infer<typeof weeklyPerformanceReportValidator>;

export type WeeklyPerformanceReportProps = WeeklyPerformanceReport & BaseEmailProps & BaseReportEmailProps;
