import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, EMPTY } from 'rxjs';

import { GeoSamplePlatform } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RankingPosition } from ':modules/keywords/keywords-list/keywords-list.component.interface';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { addDay, subtractDay } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { KeywordEvolutionChartComponent } from './keyword-evolution-chart/keyword-evolution-chart.component';

@Component({
    selector: 'app-keyword-evolution',
    templateUrl: './keyword-evolution.component.html',
    styleUrls: ['./keyword-evolution.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatButtonToggleModule,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
        KeywordEvolutionChartComponent,
        SkeletonComponent,
    ],
})
export class KeywordEvolutionComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    keywordText: string;
    positions: RankingPosition[] | undefined;
    periodPositions: RankingPosition[];
    allPositions: RankingPosition[] | undefined;
    showOnlyPeriod = true;

    restaurantId: string;
    restaurantPlaceId: string;
    httpError: any = null;
    isLoading = true;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            positions: RankingPosition[];
            keywordText: string;
            restaurantId: string;
            restaurantPlaceId: string;
        },
        private readonly _dialogRef: MatDialogRef<KeywordEvolutionComponent>,
        private readonly _keywordsService: KeywordsService
    ) {
        this.restaurantPlaceId = data.restaurantPlaceId;
        this.restaurantId = data.restaurantId;
        this.periodPositions = data.positions.filter((p) => p.position).sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
        this.positions = this.periodPositions;

        this.keywordText = data.keywordText;
    }

    ngOnInit(): void {
        this.loadChart();
        this._keywordsService
            .handleGetKeywordRankingsForManyRestaurantsV3({
                doNotFetchRecentSamples: true,
                endDate: new Date(),
                keywords: [this.keywordText],
                platformKey: GeoSamplePlatform.GMAPS,
                restaurantIds: [this.restaurantId],
                startDate: new Date(0),
            })
            .pipe(
                catchError((error) => {
                    this.httpError = error;
                    this.isLoading = false;
                    return EMPTY;
                })
            )
            .subscribe((res) => {
                if (res.restaurants.length === 1) {
                    const restaurantStats = res.restaurants[0];
                    if (restaurantStats.keywords.length === 1) {
                        this.allPositions = restaurantStats.keywords[0].rankHistory.map((p) => ({
                            createdAt: new Date(p.fetchDate),
                            position: { rank: p.rank ?? Infinity, outOf: p.outOf },
                        }));
                        this.isLoading = false;
                    }
                }
            });
    }

    loadChart(): void {
        // hack to show position in the middle of the chart
        if (this.positions?.length === 1) {
            const tempTime = this.positions[0].createdAt;
            this.positions.unshift({ position: undefined, createdAt: subtractDay(1, new Date(tempTime)) });
            this.positions.push({ position: undefined, createdAt: addDay(1, new Date(tempTime)) });
        }
    }

    changePeriod(showAll = false): void {
        this.showOnlyPeriod = !showAll;
        this.positions = this.showOnlyPeriod ? this.periodPositions : this.allPositions;
        this.loadChart();
    }

    close(): void {
        this._dialogRef.close();
    }
}
