<div class="flex flex-col gap-12">
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'aggregated_statistics_pdf.seo.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantsTitle$ | async | statisticsPdfRestaurantsFormatter: true }}
        </span>
    </div>

    <div class="flex flex-col gap-4 px-8.5 py-4">
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_RANKINGS) && keywordsHasData) {
            <div class="overflow-y-none">
                <app-keywords-v3
                    [shouldHideTableClickableElements]="true"
                    [tableSortOptions]="chartOptions[InsightsChart.AGGREGATED_RANKINGS]?.tableSortOptions"
                    (hasDataChange)="keywordsHasData = $event">
                </app-keywords-v3>
            </div>
        }

        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_APPARITIONS) && gmbImpressionsHasData) {
            <div class="h-[520px] break-inside-avoid">
                <app-gmb-impressions
                    [hiddenDatasetIndexes]="chartOptions[InsightsChart.AGGREGATED_APPARITIONS]?.hiddenDatasetIndexes ?? []"
                    [sortBy]="chartOptions[InsightsChart.AGGREGATED_APPARITIONS]?.chartSortBy ?? SortBy.ALPHABETICAL"
                    [showSortByTextInsteadOfSelector]="true"
                    (hasDataChange)="gmbImpressionsHasData = $event">
                </app-gmb-impressions>
            </div>
        }

        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_ACTIONS) && gmbActionsHasData) {
            <div class="mb-4 h-[520px] break-inside-avoid">
                <app-gmb-actions
                    [hiddenDatasetIndexes]="chartOptions[InsightsChart.AGGREGATED_ACTIONS]?.hiddenDatasetIndexes ?? []"
                    [sortBy]="chartOptions[InsightsChart.AGGREGATED_ACTIONS]?.chartSortBy ?? SortBy.ALPHABETICAL"
                    [showSortByTextInsteadOfSelector]="true"
                    (hasDataChange)="gmbActionsHasData = $event">
                </app-gmb-actions>
            </div>
        }
    </div>
</div>
