import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult, Comment, CommentsFilters, ModerationTarget, Pagination } from ':shared/models';

import * as CommentsActions from '../comments/store/comments.actions';
import { PostWithCommentsAndMentions } from './comments.interface';

export interface CommentsAndMentionsCounts {
    commentCount: number;
    mentionCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/comments`;

    readonly archivedComment$: Subject<Comment> = new Subject();
    readonly shouldReload$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store
    ) {}

    archiveComment(comment: Comment): void {
        this.archivedComment$.next(comment);
    }

    reload(): void {
        this.shouldReload$.next(true);
    }

    public synchronizeRestaurantComments(restaurantId: string): Observable<ApiResult> {
        return this._http
            .get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/synchronize`)
            .pipe(tap(() => this._updateUnansweredCommentsAndMentionsCount()));
    }

    public getRestaurantCommentsPaginated(
        restaurantId: string,
        pagination: Pagination,
        filters: CommentsFilters
    ): Observable<ApiResult<{ comments: ModerationTarget[]; pagination: Pagination; counts: CommentsAndMentionsCounts }>> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...pagination, ...filters }));
        return this._http
            .get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/comments_and_mentions`, {
                params: { ...cleanFilters },
            })
            .pipe(
                map((res: ApiResult) => {
                    res.data.comments = res.data.comments.map((comment) => {
                        try {
                            return new Comment(comment);
                        } catch (err) {
                            console.warn(`Error while formatting comment ${comment._id}`, err);
                            return null;
                        }
                    });
                    res.data.comments = res.data.comments.filter(Boolean);
                    return res;
                }),
                tap(() => this._updateUnansweredCommentsAndMentionsCount())
            );
    }

    public getRestaurantCommentsByPostPaginated(
        restaurantId: string,
        pagination: Pagination,
        filters: CommentsFilters
    ): Observable<ApiResult<{ posts: PostWithCommentsAndMentions[]; pagination: Pagination }>> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...pagination, ...filters }));
        return this._http
            .get<
                ApiResult<{ posts: PostWithCommentsAndMentions[]; pagination: Pagination }>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/by_post`, { params: cleanFilters })
            .pipe(
                map((res: ApiResult) => {
                    res.data.posts = res.data.posts.map((e) => new PostWithCommentsAndMentions(e));
                    return res;
                })
            );
    }

    public getFilteredCommentsForPost(postSocialId: string, platformId: string, filters: CommentsFilters): Observable<ApiResult> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...filters }));
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/posts/${postSocialId}/platforms/${platformId}`, {
            params: cleanFilters,
        });
    }

    public reply(message: string, commentId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http
            .post<
                ApiResult<Comment>
            >(`${this.API_BASE_URL}/${commentId}/reply`, { message }, { withCredentials: true, params: { restaurantId } })
            .pipe(tap(() => this._updateUnansweredCommentsAndMentionsCount()));
    }

    public updateCommentById(
        commentId: string,
        comment: Partial<Comment>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult> {
        return this._http
            .put<ApiResult>(`${this.API_BASE_URL}/${commentId}`, { comment }, { withCredentials: true, params: { restaurantId } })
            .pipe(
                map((res: ApiResult) => {
                    res.data = new Comment(res.data);
                    return res;
                })
            );
    }

    public deleteCommentById(commentId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http
            .delete<ApiResult>(`${this.API_BASE_URL}/${commentId}`, {
                withCredentials: true,
                params: { restaurantId },
            })
            .pipe(tap(() => this._updateUnansweredCommentsAndMentionsCount()));
    }

    public archiveCommentsForPost(
        postId: string,
        archived: boolean,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult> {
        return this._http
            .put<ApiResult>(
                `${this.API_BASE_URL}/restaurants/${restaurantId}/posts/${postId}/archive`,
                { archived },
                { withCredentials: true }
            )
            .pipe(tap(() => this._updateUnansweredCommentsAndMentionsCount()));
    }

    public getUnansweredCommentsAndMentionsCount(
        startDate?: Date
    ): Observable<ApiResult<{ unansweredCommentCount: number; unansweredMentionCount: number }>> {
        const params = startDate ? objectToSnakeCase({ startDate: DateTime.fromJSDate(startDate).toUTC().toISO() }) : undefined;

        return this._http.get<ApiResult<{ unansweredCommentCount: number; unansweredMentionCount: number }>>(
            `${this.API_BASE_URL}/restaurants/${this._restaurantsService.currentRestaurant._id}/comments_and_mentions/unanswered_count`,
            { withCredentials: true, params }
        );
    }

    getCommentById(commentId: string): Observable<Comment> {
        return this._http.get<ApiResult<Comment>>(`${this.API_BASE_URL}/${commentId}`).pipe(
            map((res: ApiResult) => {
                const comment = new Comment(res.data.comment);
                return comment;
            })
        );
    }

    getCommentsAndMentionsByPostSocialId(postSocialId: string, filters: CommentsFilters): Observable<ApiResult<Comment[]>> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...filters }));
        return this._http
            .get<ApiResult<Comment[]>>(
                `${this.API_BASE_URL}/restaurants/${this._restaurantsService.currentRestaurant._id}/by_post/${postSocialId}`,
                {
                    params: cleanFilters,
                }
            )
            .pipe(
                map((res) => {
                    res.data = res.data.map((comment) => new Comment(comment as any));
                    return res;
                })
            );
    }

    private _updateUnansweredCommentsAndMentionsCount(): void {
        this.getUnansweredCommentsAndMentionsCount(DateTime.now().minus({ months: 6 }).toJSDate()).subscribe((res) => {
            this._store.dispatch({
                type: CommentsActions.editUnansweredCommentCount.type,
                unansweredCommentCount: res.data.unansweredCommentCount,
            });
            this._store.dispatch({
                type: CommentsActions.editUnansweredMentionCount.type,
                unansweredMentionCount: res.data.unansweredMentionCount,
            });
        });
    }
}
