<div class="flex w-full flex-col">
    @if (title || subtitle || required) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if (required) {
                <span>*</span>
            }
        </div>
    }

    <div
        class="malou-border-primary flex items-center self-stretch rounded-lg bg-white"
        [class.opacity-50]="control.disabled"
        [class.malou-border-secondary]="!errorMessage && isFocused"
        [class.malou-border-error]="!!errorMessage || (control && control.invalid && (control.dirty || control.touched))">
        <div class="h-12 w-full">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 bg-white px-5 !text-malou-color-text-1 outline-none placeholder:italic"
                type="number"
                [class.bg-white]="control.disabled"
                [placeholder]="placeholder"
                [formControl]="control"
                [min]="min"
                [max]="max"
                (keypress)="preventNonNumericalValue($event)"
                (focus)="isFocused = true"
                (blur)="isFocused = false"
                #inputNumber />
        </div>

        @if (icon(); as icon) {
            <mat-icon class="mr-2 !h-8" color="primary" [class]="iconClass()" [svgIcon]="icon"></mat-icon>
        } @else {
            <div class="flex flex-col px-2">
                <button
                    class="h-4"
                    id="tracking_input_number_increment"
                    type="button"
                    [disabled]="stepUpDisabled | applyPure: disabled : max : control.value"
                    (click)="stepUp()">
                    <mat-icon
                        class="!h-4"
                        color="primary"
                        [svgIcon]="SvgIcon.CHEVRON_UP"
                        [ngClass]="{ 'opacity-25': stepUpDisabled | applyPure: disabled : max : control.value }"></mat-icon>
                </button>
                <button
                    class="h-4"
                    id="tracking_input_number_decrement"
                    type="button"
                    [disabled]="stepDownDisabled | applyPure: disabled : min : control.value"
                    (click)="stepDown()">
                    <mat-icon
                        class="!h-4"
                        color="primary"
                        [svgIcon]="SvgIcon.CHEVRON_DOWN"
                        [ngClass]="{ 'opacity-25': stepDownDisabled | applyPure: disabled : min : control.value }"></mat-icon>
                </button>
            </div>
        }
    </div>

    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
    @if (!errorMessage && control && control.invalid && (control.dirty || control.touched) && max && (control.value ?? 0) > max) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">
            {{ 'common.must_be_under' | translate: { max } }}
        </div>
    } @else {
        @if (!errorMessage && control && control.invalid && (control.dirty || control.touched) && min && (control.value ?? 0) < min) {
            <div class="malou-text-10 malou-color-state-error py-1 italic">
                {{ 'common.must_be_over' | translate: { min } }}
            </div>
        } @else {
            @if (!errorMessage && control && control.invalid && (control.dirty || control.touched)) {
                <div class="malou-text-10 malou-color-state-error py-1 italic">
                    {{ 'common.required_field' | translate }}
                </div>
            }
        }
    }
</div>
