<div class="flex h-screen w-screen items-center justify-center">
    <div class="flex flex-col items-center gap-4">
        <div>
            <img class="mb-8 h-8" alt="logo" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="malou-border-primary flex w-[600px] flex-col items-center rounded-lg border bg-white p-8 sm:w-full">
            @if (!requestSucceeded()) {
                <div class="flex flex-col items-center gap-12">
                    <img class="h-32" alt="logo" [src]="Illustration.Cook | illustrationPathResolver" />
                    <div class="malou-text-23--bold malou-color-text-1 text-center">{{ reportTypeTranslationTitle() }}</div>
                    <div class="malou-text-14--regular malou-color-text-2 text-center" [innerHtml]="reportTypeTranslationSubtitle()"></div>
                    <div class="flex w-full max-w-[128px] flex-col gap-4">
                        <button class="malou-btn-raised--primary !h-12.5" mat-raised-button (click)="unsubscribeFromConfiguration()">
                            {{ 'common.confirm' | translate }}
                        </button>
                    </div>
                </div>
            } @else {
                <div class="flex flex-col items-center gap-12">
                    <img class="h-32" alt="logo" [src]="Illustration.Cape | illustrationPathResolver" />
                    <div class="malou-text-23--bold malou-color-text-1 text-center">
                        {{ 'reports.unsubscribe.unsubscribe_success_title' | translate }}
                    </div>
                    <div class="malou-text-14--regular malou-color-text-2 text-center">
                        {{ 'reports.unsubscribe.unsubscribe_success_subtitle' | translate }}
                    </div>
                </div>
            }
        </div>
    </div>
</div>
