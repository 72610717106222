<app-permissions-headband class="fixed left-0 right-0 top-0 z-20"></app-permissions-headband>
<mat-sidenav-container class="h-screen w-screen !bg-malou-color-background-light" (click)="notificationCenterContext.close()">
    <mat-sidenav
        [disableClose]="!isPhoneScreen()"
        [opened]="!isPhoneScreen() || isSidenavOpened()"
        [position]="isPhoneScreen() ? 'end' : 'start'"
        [mode]="isPhoneScreen() ? 'over' : 'side'">
        <app-sidenav-content></app-sidenav-content>
    </mat-sidenav>
    <mat-sidenav-content [style]="sidenavContentStyle()">
        <div class="flex h-full flex-col">
            <app-header [showProfile]="!isPhoneScreen()" [showSidenavToggle]="isPhoneScreen()"></app-header>
            <div class="min-h-0 grow !bg-malou-color-background-light">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer-manager></app-footer-manager>
    </mat-sidenav-content>
</mat-sidenav-container>

@if (experimentationService.isFeatureEnabled('release-notification-center')) {
    <app-notification-center></app-notification-center>
    <app-notification-popins></app-notification-popins>
}
