import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roiCustomersPathResolver',
    standalone: true,
})
export class RoiCustomersPathResolver implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/roi/estimated-customers/${key}.png`;
    }
}
