import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export type CsvAsStringArrays = string[][];

export const DEFAULT_CSV_PAGINATION = { pageSize: 2000, pageNumber: 0, total: 0 };

export type DataWithNilExcluded<Data> = { [K in keyof Data]: Exclude<Data[K], undefined | null> };

export interface CsvService {
    getCsvData$(...args: any[]): Observable<CsvAsStringArrays | null>;
}

export abstract class AbstractCsvService<Data, Options = unknown> implements CsvService {
    getCsvData$(options: Options): Observable<CsvAsStringArrays | null> {
        return this._getData$(options).pipe(
            take(1),
            map((data: Data) => {
                if (!this._isDataValid(data, options)) {
                    return null;
                }
                const headerRow = this._getCsvHeaderRow(data, options);
                const dataRows = this._getCsvDataRows(data, options);
                return [headerRow, ...dataRows];
            })
        );
    }

    protected _isDataValid(_data: Data, _options: Options): boolean {
        return true;
    }
    protected abstract _getData$(options: Options): Observable<Data>;
    protected abstract _getCsvHeaderRow(data: Data, options: Options): CsvAsStringArrays[0];
    protected abstract _getCsvDataRows(data: Data, options: Options): CsvAsStringArrays;
}
