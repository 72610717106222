import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';

@Component({
    selector: 'app-post-skeleton',
    templateUrl: './post-skeleton.component.html',
    styleUrls: ['./post-skeleton.component.scss'],
    standalone: true,
    imports: [SkeletonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSkeletonComponent {
    private readonly _screenSizeService = inject(ScreenSizeService);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
}
