import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';

interface AppState {
    user: {
        infos: User;
    };
}

@Injectable()
export class RoleGuard {
    constructor(
        private readonly _router: Router,
        private readonly _store: Store<AppState>
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const expectedRoles = route.data.roles;
        return this._store.select(selectUserInfos).pipe(
            filter((storageUser) => !!storageUser),
            map((storageUser) => {
                if (expectedRoles.includes(storageUser?.role)) {
                    return true;
                } else {
                    this._router.navigate(['/restaurants']);
                    return false;
                }
            })
        );
    }
}
