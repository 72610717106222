<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex gap-x-2">
            <span>{{ 'common.edit' | translate }}</span>
            <span>{{ data.restaurant.name }}</span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-y-6" [formGroup]="formGroup">
            <app-select
                formControlName="organization"
                [title]="'admin.restaurants.organization' | translate"
                [placeholder]="'admin.restaurants.organization' | translate"
                [values]="allOrganizations"
                [displayWith]="organizationDisplayWith"
                [displayedOptionCount]="20"
                [required]="true">
            </app-select>

            <app-select-chip-list
                formControlName="users"
                [title]="'admin.restaurants.managers' | translate"
                [placeholder]="'admin.restaurants.managers' | translate"
                [values]="allUsers"
                [displayWith]="userDisplayWith"
                [displayedOptionCount]="20"
                [checkboxOption]="true">
            </app-select-chip-list>
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-10 md:grow" mat-raised-button [disabled]="formGroup.invalid" (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
