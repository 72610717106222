<ng-container *ngTemplateOutlet="loading ? shimmer : page"></ng-container>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile flex h-full !w-[100vw] flex-col !overflow-hidden">
        <div class="malou-dialog__header gap-x-5">
            <div class="w-[50%] md:w-full">
                <span class="malou-text-18--bold hidden text-malou-color-text-1 md:!block">{{ 'common.answer' | translate }}</span>
                <ng-container *ngTemplateOutlet="filters"></ng-container>
            </div>
            <div class="flex w-[70%] items-center justify-between md:justify-end">
                <span class="malou-text-18--bold ml-5 text-malou-color-text-1 md:hidden">{{ 'common.answer' | translate }}</span>
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
            </div>
        </div>
        <div class="malou-dialog__body !m-0 flex grow gap-x-5 !overflow-hidden !pr-0">
            <!-- LEFT SIDE -->
            <ng-container *ngTemplateOutlet="commentsListTemplate"></ng-container>
            <!-- RIGHT SIDE -->
            <form class="flex-7 flex h-full flex-col gap-y-3 overflow-auto pb-[100px] pr-6.5 md:w-full">
                <ng-container *ngTemplateOutlet="currentCommentHeader"></ng-container>
                <ng-container *ngTemplateOutlet="replies"></ng-container>
                @if (currentComment?.replies?.length) {
                    <div class="malou-text-14--bold mb-3 text-malou-color-text-2">
                        {{ 'comments_modal.reply_again' | translate }}
                    </div>
                }
                <ng-container *ngTemplateOutlet="quickReplies"></ng-container>
                <ng-container *ngTemplateOutlet="answerArea"></ng-container>

                <div
                    class="fixed bottom-0 right-2 flex w-[56%] flex-2 justify-end gap-x-3 bg-white pb-5 pr-6.5 pt-5 md:w-full md:justify-center md:gap-x-4 md:px-0 md:py-5">
                    <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="close()">
                        {{ 'common.cancel' | translate }}
                    </button>

                    <app-button
                        buttonClasses="!h-11"
                        [disabled]="!replyTextFormControl.valid"
                        [text]="
                            (isLastComment | applyPure: commentsList : currentComment)
                                ? ('common.answer' | translate)
                                : ('comments_modal.answer_and_next' | translate)
                        "
                        [loading]="isReplying()"
                        (buttonClick)="reply()"></app-button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- RIGHT SIDE TEMPLATES -->
<ng-template #shimmer>
    <div class="malou-dialog malou-dialog-mobile flex h-full !w-[100vw] flex-col">
        <div class="malou-dialog__header gap-x-5">
            <div class="flex-5"></div>
            <div class="flex-7 flex items-center justify-end">
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
            </div>
        </div>
        <div class="malou-dialog__body !m-0 flex grow gap-x-5 !overflow-hidden">
            <div class="h-full flex-5">
                <app-skeleton skeletonClass="secondary-bg w-full h-[50px]" [useContainer]="false"></app-skeleton>
                <div class="mt-5 h-full w-full">
                    <app-skeleton skeletonClass="secondary-bg w-full h-[80px]" [count]="6"></app-skeleton>
                </div>
            </div>
            <div class="flex-7 grid h-full">
                <app-skeleton skeletonClass="secondary-bg w-full h-[200px]" [useContainer]="false"></app-skeleton>
                <div class="w-full">
                    <app-skeleton
                        skeletonClass="secondary-bg w-[100px] h-[30px]"
                        flexDirection="flex-row"
                        gapClass="gap-x-3"
                        [count]="7"></app-skeleton>
                </div>

                <div class="w-full">
                    <app-skeleton skeletonClass="secondary-bg w-full h-[200px]" [useContainer]="false"></app-skeleton>
                </div>

                <div class="flex w-full items-center justify-end gap-x-4 justify-self-end">
                    <app-skeleton skeletonClass="secondary-bg w-[120px] h-[40px]" [useContainer]="false"></app-skeleton>
                    <app-skeleton skeletonClass="secondary-bg w-[200px] h-[40px]" [useContainer]="false"></app-skeleton>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #currentCommentHeader>
    <div class="flex max-h-[200px] w-full flex-2 justify-between gap-x-3 md:max-h-full md:flex-col md:gap-y-4">
        <div class="flex h-full flex-2 flex-col gap-y-4 md:flex-1">
            @switch (currentComment?.type) {
                @case (CommentType.MENTION_IN_POST) {
                    <span class="malou-text-14--bold text-malou-color-text-2">{{ 'comments_modal.mention_in_post' | translate }}</span>
                }
                @default {
                    <span class="malou-text-14--bold text-malou-color-text-2">{{ 'comments_modal.post' | translate }}</span>
                }
            }

            <app-social-post-media
                customMediaClass="!rounded-[10px] grow aspect-square md:max-h-[150px]"
                [postType]="currentComment?.post?.postType ?? PostType.IMAGE"
                [thumbnailUrl]="currentComment?.post | applySelfPure: 'getSocialMediaThumbnail'"
                [workingMedia]="
                    (currentComment?.post | applySelfPure: 'getSocialMediaUrl') ||
                    (currentComment?.post | applySelfPure: 'getMalouMediaUrl')
                "
                [firstAttachmentType]="currentComment?.post | applySelfPure: 'getFirstMediumType'"
                [tooltipText]="currentComment?.post?.text ?? ''"
                (refreshMedia)="refreshPost()">
            </app-social-post-media>
        </div>
        <div class="flex-10 flex h-full grow flex-col gap-y-4 md:flex-1">
            @if (currentComment?.type !== CommentType.MENTION_IN_POST) {
                <div>
                    <div>
                        @switch (currentComment?.type) {
                            @case (CommentType.MENTION_IN_COMMENT) {
                                <span class="malou-text-14--bold text-malou-color-text-2">{{
                                    'comments_modal.mention_in_comment' | translate
                                }}</span>
                            }
                            @default {
                                <span class="malou-text-14--bold text-malou-color-text-2">{{ 'comments_modal.comment' | translate }}</span>
                            }
                        }
                    </div>
                </div>
            }

            <div class="malou-card--secondary-alt !m-0 !flex-1">
                <div class="malou-card__header">
                    <div class="malou-card__image-container !w-[50px]">
                        <img
                            class="malou-avatar--large aspect-square !w-[50px]"
                            [lazyLoad]="currentComment?.reviewer?.displayName | avatar" />
                    </div>
                    <div class="mt-2">
                        <div class="malou-text-12--bold text-malou-color-text-1">{{ '@' + currentComment?.reviewer?.displayName }}</div>
                        <div class="malou-text__card-subtitle">
                            {{ formatSocialDate | applyPure: currentComment?.socialCreatedAt }}
                        </div>
                    </div>
                    <a class="malou-card__action-button" target="_blank" [href]="currentComment?.post?.socialLink">
                        @if (currentComment?.hasBeenAnswered) {
                            <div class="malou-chip malou-chip--green mr-5 !h-6">
                                <span>{{ 'moderation.answered' | translate }}</span>
                            </div>
                        }
                        <button class="malou-btn-icon--secondary btn-xl" type="button" mat-icon-button>
                            <mat-icon [svgIcon]="SvgIcon.EYE"></mat-icon>
                        </button>
                    </a>
                </div>
                <div class="malou-card__body">
                    <div class="malou-card__body-text">
                        <p>
                            {{ currentComment?.text | shortText: (seeMoreCurrentCommentText ? currentComment?.text?.length : 100) }}
                        </p>
                        @if ((currentComment?.text?.length ?? 0) > 100) {
                            <span
                                class="malou-text-10--semibold malou-color-primary cursor-pointer"
                                (click)="seeMoreCurrentCommentText = !seeMoreCurrentCommentText">
                                {{ (seeMoreCurrentCommentText ? 'common.see_less' : 'common.see_more') | translate }}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #quickReplies>
    <div class="flex w-full flex-2 flex-wrap items-center gap-2">
        @for (template of allReplyTemplates(); track trackByIdFn($index, template)) {
            <div
                class="malou-chip malou-chip--primary"
                [matTooltip]="template.text"
                (click)="$event.stopPropagation(); choseTemplate(template)">
                <span>{{ template?.text | shortText: 50 }}</span>
                <mat-icon
                    class="malou-chip-icon--right malou-color-primary shrink-0"
                    [svgIcon]="SvgIcon.REMOVE"
                    (click)="$event.stopPropagation(); removeLabel(template)"></mat-icon>
            </div>
        }
    </div>
</ng-template>

<ng-template #answerArea>
    <div class="flex-5">
        <app-text-area
            id="replyTextArea"
            [formControl]="replyTextFormControl"
            [placeholder]="'---'"
            [title]="'comments_modal.answer_to_comment' | translate"
            [required]="true"></app-text-area>
        <div class="flex gap-x-3">
            <mat-checkbox color="primary" [formControl]="saveModelFormControl"></mat-checkbox>
            <div>
                <div class="malou-text-10--regular text-malou-color-text-1">{{ 'comments_modal.save_answer' | translate }}</div>
                <div class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ 'comments_modal.save_answer_subtitle' | translate }}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #replies>
    <div class="my-4 flex flex-col gap-y-3">
        @for (reply of reverseArray | applyPure: currentComment?.replies; track trackByIdFn($index, reply)) {
            <div class="malou-card--secondary !m-0 !flex-1 !py-5">
                <div class="malou-card__header !justify-start">
                    <div class="malou-card__image-container !w-[50px]">
                        <img class="malou-avatar--large aspect-square !w-[50px]" [lazyLoad]="reply.reviewer.displayName | avatar" />
                    </div>
                    <div class="mt-2">
                        <div class="malou-text-12--bold text-malou-color-text-1">{{ '@' + reply.reviewer.displayName }}</div>
                        <div class="malou-text__card-subtitle">
                            {{ formatSocialDate | applyPure: reply.socialCreatedAt }}
                        </div>
                    </div>
                </div>
                <div class="malou-card__body">
                    <div class="malou-card__body-text">
                        <p>
                            {{ reply?.text | shortText: (seeMoreReplies ? reply?.text?.length : 120) }}
                            @if (reply.text.length > 120) {
                                <span
                                    class="malou-text-10--semibold malou-color-primary ml-2 cursor-pointer"
                                    (click)="seeMoreReplies = !seeMoreReplies">
                                    {{ (seeMoreReplies ? 'common.see_less' : 'common.see_more') | translate }}
                                </span>
                            }
                        </p>
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<!-- LEFT SIDE TEMPLATES -->

<ng-template #commentsListTemplate>
    <div
        class="flex h-full flex-5 flex-col gap-y-3 overflow-auto pb-5 pr-8 md:hidden"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollContainer]="scrollContainer"
        (scrolled)="onScrollDown()"
        #scrollContainer>
        @for (comment of commentsList; track trackByIdFn($index, comment)) {
            <div
                class="flex cursor-pointer items-center gap-x-4 rounded-[10px] border border-solid border-malou-color-background-dark p-2 px-6 transition-colors hover:bg-malou-color-background-dark"
                id="comment-{{ comment?._id }}"
                [ngClass]="{
                    'border-malou-color-border-secondary': comment?._id === currentComment?._id,
                    'bg-malou-color-background-dark': comment?.hasBeenAnswered,
                }"
                (click)="changeCurrentComment(comment)">
                @if (!data.fromPosts) {
                    <div class="w-[90px]">
                        <app-social-post-media
                            customMediaClass="!rounded-[10px] !w-[90px] aspect-square !max-w-none"
                            [postType]="comment?.post?.postType ?? PostType.IMAGE"
                            [thumbnailUrl]="comment?.post | applySelfPure: 'getSocialMediaThumbnail'"
                            [workingMedia]="
                                (comment?.post | applySelfPure: 'getSocialMediaUrl') || (comment?.post | applySelfPure: 'getMalouMediaUrl')
                            "
                            [firstAttachmentType]="comment?.post | applySelfPure: 'getFirstMediumType'"
                            [tooltipText]="comment?.post?.text ?? ''"
                            (refreshMedia)="refreshPost(comment)">
                        </app-social-post-media>
                    </div>
                }
                <div class="mt-2 flex grow flex-col">
                    <div class="relative ml-4 flex w-full">
                        <div class="flex">
                            <app-platform-logo
                                class="z-[2]"
                                imgClasses="w-8 h-8 !rounded-full"
                                [logo]="comment.platformKey"
                                [withLazyLoading]="true"></app-platform-logo>
                            <img class="malou-avatar--medium -ml-4.5" [lazyLoad]="comment?.reviewer?.displayName | avatar" />
                        </div>
                        <div class="ml-2">
                            <div class="malou-text-12--bold">{{ '@' + comment?.reviewer?.displayName }}</div>
                            <div class="malou-text__card-subtitle">{{ formatSocialDate | applyPure: comment?.socialCreatedAt }}</div>
                        </div>
                    </div>
                    <div class="malou-text-12--regular mt-4 text-malou-color-text-2">
                        {{ comment?.text | shortText: 80 }}
                    </div>
                </div>
                <div class="flex items-center gap-x-2">
                    @if (comment?.hasBeenAnswered) {
                        <div class="malou-chip malou-chip--green !h-6">
                            <span>{{ 'moderation.answered' | translate }}</span>
                        </div>
                    }
                    <div class="malou-chip mr-2 !cursor-default" [ngClass]="getChipColor(comment)" [matTooltip]="getChipText(comment)">
                        {{ getChipLabel(comment) }}
                    </div>
                    <button
                        class="malou-btn-icon--secondary btn-xl"
                        mat-icon-button
                        [ngClass]="{ 'btn-active': comment.archived }"
                        [matTooltip]="!comment.archived ? ('common.archive' | translate) : ('common.unarchive' | translate)"
                        (click)="$event.stopPropagation(); toggleArchived(comment)">
                        <mat-icon [svgIcon]="SvgIcon.BOX"></mat-icon>
                    </button>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #filters>
    <div class="flex w-full items-center gap-x-4 pr-6 md:hidden">
        <app-search
            class="mr-0 grow"
            [placeholder]="'common.search' | translate"
            [debounceTime]="300"
            (searchChange)="onSearchChange($event)"></app-search>

        <div class="justify-start lg:flex-1">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="(filters$ | async)?.sortBy ?? 'date'"
                [sortOrder]="(filters$ | async)?.sortOrder ?? -1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange()">
            </app-sort-by-filters>
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="'common.filters' | translate"
            [disabled]="!hasConnectedPlatform">
            @if (filterCount > 0) {
                <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                    {{ filterCount }}
                </span>
            }
            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
        </button>
        <button
            class="malou-btn-icon--secondary btn-normal btn-xl"
            mat-icon-button
            [disabled]="!hasConnectedPlatform"
            (click)="startUpdateComments()">
            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SYNC"></mat-icon>
        </button>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #menu="matMenu">
    <div class="w-[440px] md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between p-6">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="filterCount == 0" (click)="clearFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>
        <div class="hide-scrollbar mx-6 flex h-[50vh] grow flex-col gap-y-5 overflow-y-scroll pb-5 md:h-d-70">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="taggedInFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="optionsFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<!-- Start Filter Menu contents templates -->
<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.period' | translate }}
        </div>
        <app-grouped-date-filters
            [startDate]="(filters$ | async)?.startDate ?? null"
            [endDate]="(filters$ | async)?.endDate ?? null"
            [periodOptions]="periodOptions"
            [defaultPeriod]="(filters$ | async)?.period ?? MalouPeriod.LAST_SIX_MONTHS"
            [withLabel]="false"
            (chooseBoundaryDate)="editCommentsFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.platforms' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (platform of availablePlatforms; track platform) {
                <button matRipple [disabled]="!platform.connected" (click)="toggleSelectedPlatforms(platform)">
                    <app-platform-logo
                        imgClasses="h-[29px] w-[29px]"
                        [dynamicClasses]="{ 'opacity-50': !platform.checked }"
                        [logo]="platform.key"></app-platform-logo>
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.status' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ (filters$ | async)?.answered }}"
                    [checked]="(filters$ | async)?.answered"
                    (change)="toggleFilter('answered')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.answered' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ (filters$ | async)?.notAnswered }}"
                    [checked]="(filters$ | async)?.notAnswered"
                    (change)="toggleFilter('notAnswered')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.notAnswered' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>

<ng-template #taggedInFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.comment_or_mention' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ (filters$ | async)?.isComment }}"
                    [checked]="(filters$ | async)?.isComment"
                    (change)="toggleFilter('isComment')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.comment' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ (filters$ | async)?.isMention }}"
                    [checked]="(filters$ | async)?.isMention"
                    (change)="toggleFilter('isMention')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.mention' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>

<ng-template #optionsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'moderation.filter_category.options' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ (filters$ | async)?.archived }}"
                    [checked]="(filters$ | async)?.archived"
                    (change)="toggleFilter('archived')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.archived' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                <mat-button-toggle
                    class="!h-12.5 rounded-[10px]"
                    value="{{ !(filters$ | async)?.withoutOwnComment }}"
                    [checked]="!(filters$ | async)?.withoutOwnComment"
                    (change)="toggleFilter('withoutOwnComment')">
                    <div class="malou-text-12--medium">
                        {{ 'moderation.filter_label.ownComments' | translate }}
                    </div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>
