import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const userIdParamsValidator = z.object({
    user_id: objectIdValidator,
});

export type UserIdParamsDto = z.infer<typeof userIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const restaurantIdParamsValidator = z.object({
    restaurant_id: objectIdValidator,
});

export type RestaurantIdParamsDto = z.infer<typeof restaurantIdParamsValidator>;

export const restaurantIdParamsTransformValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type RestaurantIdParamsTransformDto = z.infer<typeof restaurantIdParamsTransformValidator>;

export const restaurantIdQueryValidator = z.object({
    restaurant_id: objectIdValidator,
});

export type RestaurantIdQueryDto = z.infer<typeof restaurantIdQueryValidator>;

// ------------------------------------------------------------------------------------------

export const idParamsValidator = z.object({
    id: z.string(),
});

export type IdParamsValidator = z.infer<typeof idParamsValidator>;

// ------------------------------------------------------------------------------------------

export const platformKeyQueryValidator = z.object({
    platform_key: z.nativeEnum(PlatformKey),
});

export type PlatformKeyQueryDto = z.infer<typeof platformKeyQueryValidator>;

export const platformKeysBodyValidator = z.object({
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
});

export type PlatformKeysBodyDto = z.infer<typeof platformKeysBodyValidator>;
