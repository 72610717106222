import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import AwsS3 from '@uppy/aws-s3';
import { UploadedUppyFile, Uppy, UppyFile } from '@uppy/core';
import { groupBy, partition } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, filter, lastValueFrom, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { getTypeFromMimetype, MediaType, TimeInMilliseconds } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { CheckCodecsUppyPlugin } from ':shared/components/uppy/plugins/check-codecs-uppy-plugin';
import { UppyTemporaryMedia } from ':shared/models';

import { ErrorFileCategory } from '../../enums/error-file-category.enum';
import { ImageMediaAnalyzer, VideoMediaAnalyzer } from '../../models/media-analyzer';
import { isFileVideo, MalouResizeUppyPlugin } from './plugins/MalouResizeUppyPlugin';
import { MalouVideoDurationPlugin } from './plugins/MalouVideoDurationPlugin';

const MAX_FILE_DURATION_IN_SECONDS = 15 * TimeInMilliseconds.MINUTE;

export interface FileError {
    file: File;
    errorCategory: ErrorFileCategory;
}

export interface TriggerWatcherData {
    uppyInstance: Uppy;
    pipeUppyId?: string;
}

export interface UppyFileWithError extends UppyFile {
    error?: string;
}

@Component({
    selector: 'app-uppy',
    templateUrl: './uppy.component.html',
    styleUrls: ['./uppy.component.scss'],
    standalone: true,
})
export class UppyComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() fileInput: HTMLInputElement;
    @Input() dragAndDropArea: HTMLElement;
    @Input() maxVideoSize: number;
    @Input() maxImageSize: number;
    @Input() reset$: Subject<void>;
    @Input() disableDragAndDrop = false;
    @Input() maxNumberOfFiles: number;

    @Output() startWatcher: EventEmitter<TriggerWatcherData> = new EventEmitter<TriggerWatcherData>();
    @Output() progressChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() filesUploadedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() draggingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() beforeStart: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onFinishUpload: EventEmitter<{
        successful: UppyTemporaryMedia[];
        unsuccessful: UppyFileWithError[];
    }> = new EventEmitter();
    @Output() totalFilesChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() startUploadProcess: EventEmitter<number> = new EventEmitter<number>();
    @Output() filesUploadError: EventEmitter<FileError[]> = new EventEmitter<FileError[]>();

    private readonly _triggerWatcher$ = new BehaviorSubject<TriggerWatcherData | null>(null);
    private _uppy: Uppy;
    private _uppySuccessfulResult: UppyTemporaryMedia[] = [];
    private _uppyUnSuccessfulResult: UppyFileWithError[] = [];

    // pipeUppyId is initialized in onBeforeUpload method
    // this variable allow to identify the current uploading process
    // very useful for triggering the watcher only once
    private _pipeUppyId: string | null;

    private _nbFileImported: number;

    private _listeners: { event: string; fn: any }[] = [];

    constructor(
        private readonly _translate: TranslateService,
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService
    ) {}

    ngAfterViewInit(): void {
        this._uppy = this._generateNewUppyInstance();
        this._setCustomFileInput();
    }

    ngOnInit(): void {
        this._triggerWatcher$
            .pipe(
                distinctUntilChanged((prev, curr) => prev?.pipeUppyId === curr?.pipeUppyId),
                filter((triggerWatcherData: TriggerWatcherData) => !!triggerWatcherData)
            )
            .subscribe(({ uppyInstance }) => {
                this.startWatcher.emit({ uppyInstance });
            });
    }

    ngOnDestroy(): void {
        this._listeners.forEach((listener) => this.dragAndDropArea.removeEventListener(listener.event, listener.fn));
        this._listeners = [];
    }

    private _generateNewUppyInstance(): Uppy {
        const uppyInstance = new Uppy({
            autoProceed: true,
            debug: false,
            onBeforeUpload: (files): boolean => {
                if (!this._pipeUppyId) {
                    this._pipeUppyId = uuid();
                    this._nbFileImported = Object.values(files).length;
                    this.totalFilesChange.emit(this._nbFileImported);
                    this._triggerWatcher$.next({
                        pipeUppyId: this._pipeUppyId as string,
                        uppyInstance,
                    });
                }
                return true;
            },
        })
            .use(CheckCodecsUppyPlugin, { mediaService: this._mediaService })
            .use(MalouVideoDurationPlugin, {
                maxDurationInSeconds: MAX_FILE_DURATION_IN_SECONDS,
                mediaService: this._mediaService,
            })
            .use(MalouResizeUppyPlugin)
            .use(AwsS3, {
                id: 'aws-s3-uploader',
                getUploadParameters: (file) => lastValueFrom(this._mediaService.getUploadParams(file)),
            })
            .on('files-added', () => {
                this.beforeStart.emit(true);
            })
            .on('file-added', (file) => {
                if (file?.meta?.uppyInstance === 'small') {
                    this.filesUploadedChange.emit();
                }
            })
            .on('upload', (instance) => {
                if (instance?.fileIDs?.length) {
                    // upload event is fired once or twice. This condition is to catch the first event
                    this.startUploadProcess.emit(instance?.fileIDs?.length);
                }
            })
            .on('upload-error', (file, error) => {
                console.error('upload error', error);
                // removing the file from the uppy instance will remove it from result.failed
                // so we keep a trace from the error by pushing it in uppyUnSuccessfulResult
                // also we remove it from uppy instance otherwise the progress bar will never hit 100%
                // e.g, if we dont use removeFile(...) :
                // if we have 2 files and the first one fails, the progress bar will be stuck at 50%
                if (file) {
                    this._uppy.removeFile(file.id);
                    this._uppyUnSuccessfulResult.push(file);
                }

                // we have to manually trigger the "complete" event because if all the files we import fail,
                // uppy will never fire 'complete' event
                if (this._nbFileImported === this._uppyUnSuccessfulResult.length) {
                    this._uppy.emit('complete', {
                        successful: [],
                    });
                }
            })
            .on('progress', (progress) => {
                this.progressChange.emit(progress);
            })
            .on('upload-success', (file) => {
                if (file?.meta.uppyInstance === 'original') {
                    this.filesUploadedChange.emit(true);
                }
            })
            .on('complete', async (result) => {
                if (!result.successful.length && !this._uppyUnSuccessfulResult.length) {
                    return;
                }

                this._pipeUppyId = null;

                if (this._uppyUnSuccessfulResult.length > 0) {
                    const filesInError = this._uppyUnSuccessfulResult.filter(
                        (file) => JSON.parse(file.error as string)?.type === ErrorFileCategory.TOO_LONG_DURATION
                    );
                    this._openTooLongErrorSnackbar(filesInError.map((file) => file.name).join(', '));
                }

                if (result.successful.length > 0) {
                    this._uppySuccessfulResult = await this._mapUppyResult(result.successful);
                }

                this.onFinishUpload.emit({
                    successful: this._uppySuccessfulResult || [],
                    unsuccessful: this._uppyUnSuccessfulResult || [],
                });

                this._uppySuccessfulResult = [];
                this._uppyUnSuccessfulResult = [];
            });
        return uppyInstance;
    }

    private _setCustomFileInput(): void {
        // Event listener on input
        this.fileInput.addEventListener('change', (event: any) => {
            let files: File[] = Array.from(event.target.files);
            if (this.maxNumberOfFiles && files.length > this.maxNumberOfFiles) {
                files = files.slice(0, this.maxNumberOfFiles);
            }
            this._processFiles(files);
        });

        // Event listeners on drag and drop area
        const onDrag = (event: DragEvent): void => {
            event.preventDefault();
            this.draggingChange.emit(true);
        };
        this.dragAndDropArea.addEventListener('dragenter', onDrag);
        this._listeners.push({ event: 'dragenter', fn: onDrag });

        this.dragAndDropArea.addEventListener('dragover', onDrag);
        this._listeners.push({ event: 'dragover', fn: onDrag });

        const onDragLeave = (event: DragEvent): void => {
            event.preventDefault();
            this.draggingChange.emit(true);
        };
        this.dragAndDropArea.addEventListener('dragleave', onDragLeave);
        this._listeners.push({ event: 'dragleave', fn: onDragLeave });

        const onDrop = (event: DragEvent): void => {
            event.preventDefault();
            this.draggingChange.emit(false);
            const droppedFiles: FileList = event.dataTransfer?.files as FileList;
            if ((droppedFiles?.length ?? 0) > 0 && !this.disableDragAndDrop) {
                const files: File[] = Array.from(droppedFiles);
                this._processFiles(files);
            }
        };
        this.dragAndDropArea.addEventListener('drop', onDrop);
        this._listeners.push({ event: 'drop', fn: onDrop });
    }

    private async _mapUppyResult(
        uppyResult: UploadedUppyFile<Record<string, unknown>, Record<string, unknown>>[]
    ): Promise<UppyTemporaryMedia[]> {
        const mappedResult: UppyTemporaryMedia[] = [];
        const groupedFilesUploadedByName = groupBy(uppyResult, 'name');
        for (const key of Object.keys(groupedFilesUploadedByName)) {
            const files = groupedFilesUploadedByName[key];
            const original = files.find((file) => file.meta.uppyInstance === 'original') as UppyTemporaryMedia;

            const mediaAnalyzer = isFileVideo(original) ? new VideoMediaAnalyzer(this._mediaService) : new ImageMediaAnalyzer();
            const metadata = await mediaAnalyzer.getMetadata(original.uploadURL);
            original.dimensions = {
                original: {
                    width: metadata.width,
                    height: metadata.height,
                },
            };
            original.urls = {
                original: original.uploadURL,
            };
            original.sizes = {
                original: original.size,
            };
            if (original.type) {
                original.type = getTypeFromMimetype(original.type) as any;
            }
            if (!isFileVideo(original)) {
                const igFit = files.find((file) => file.meta.uppyInstance === 'igFit');
                const small = files.find((file) => file.meta.uppyInstance === 'small');
                if (igFit) {
                    const igFitMetadata = await new ImageMediaAnalyzer().getMetadata(igFit.uploadURL);
                    original.dimensions.igFit = {
                        width: igFitMetadata.width,
                        height: igFitMetadata.height,
                    };
                }

                if (small) {
                    const smallMetadata = await new ImageMediaAnalyzer().getMetadata(small.uploadURL);
                    original.dimensions.small = {
                        width: smallMetadata.width,
                        height: smallMetadata.height,
                    };
                }
                original['urls'] = {
                    original: original.uploadURL,
                    igFit: igFit?.uploadURL,
                    small: small?.uploadURL,
                };
                original['sizes'] = {
                    original: original.size,
                    igFit: igFit?.size,
                    small: small?.size,
                };
            }

            if (isFileVideo(original)) {
                original.duration = metadata.duration;
            }
            mappedResult.push(original);
        }
        return mappedResult;
    }

    private _displayFilesErrorWithSnackbar(files: FileError[]): void {
        this.filesUploadError.emit(files);
    }

    private _openTooLongErrorSnackbar(filesNames: string): void {
        this._toastService.openErrorToast(
            this._translate.instant('gallery.file_size_too_long', { filesNames, maxSize: MAX_FILE_DURATION_IN_SECONDS })
        );
    }

    private _processFiles(files: File[]): void {
        const [filesWithoutError, filesWithError] = partition(files, (object) => {
            const maxFileSize = getTypeFromMimetype(object.type) === MediaType.VIDEO ? this.maxVideoSize : this.maxImageSize;
            return object.size < maxFileSize;
        });
        const fileErrors: FileError[] = filesWithError.map((file) => ({ file, errorCategory: ErrorFileCategory.TOO_BIG }));
        const filesToUpload = filesWithoutError.map((file) => ({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
        }));

        if (!filesToUpload.length) {
            this._displayFilesErrorWithSnackbar(fileErrors);
            return;
        }
        try {
            this._uppy.addFiles(filesToUpload);
        } catch (err) {
            if (err.isRestriction) {
                // handle restrictions
                console.warn('Restriction error:', err);
            } else {
                // handle other errors
                console.error('[UPPY_ERROR]', err);
            }
        }
    }
}
