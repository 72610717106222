import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, switchMap, take } from 'rxjs';

import { InsightsChart, InsightsTab, PlatformKey } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { StatisticsSeoRatingsComponent } from ':modules/statistics/e-reputation/ratings/ratings.component';
import { ReviewAnalysesComponent } from ':modules/statistics/e-reputation/review-analyses/review-analyses.component';
import { ReviewsKpisComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis.component';
import { ReviewsRatingsEvolutionComponent } from ':modules/statistics/e-reputation/reviews-ratings-evolution/reviews-ratings-evolution.component';
import { ReviewsRatingsTotalComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total.component';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-statistics-e-reputation',
    templateUrl: './e-reputation.component.html',
    styleUrls: ['./e-reputation.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ReviewsKpisComponent,
        ReviewsRatingsEvolutionComponent,
        ReviewsRatingsTotalComponent,
        ReviewAnalysesComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        StatisticsSeoRatingsComponent,
    ],
})
export class EReputationComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;

    isSeoRatingLoading = signal(true);
    isReviewsKpisLoading = signal(true);
    isReviewsRatingsEvolutionLoading = signal(true);
    isReviewsRatingsTotalLoading = signal(true);
    isReviewAnalysesLoading = signal(true);

    isLoading = computed(
        () =>
            this.isSeoRatingLoading() ||
            this.isReviewsKpisLoading() ||
            this.isReviewsRatingsEvolutionLoading() ||
            this.isReviewsRatingsTotalLoading() ||
            this.isReviewAnalysesLoading()
    );

    readonly PlatformFilterPage = PlatformFilterPage;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );

    readonly InsightsChart = InsightsChart;
    chartOptions: ChartOptions = {
        [InsightsChart.REVIEW_RATING_EVOLUTION]: {
            viewBy: ViewBy.WEEK,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION]: {
            viewBy: ViewBy.WEEK,
            hiddenDatasetIndexes: [],
        },
    };

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.topOfComponent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }));
    }

    openStatisticsDownload(): void {
        combineLatest([this._store.select(StatisticsSelectors.selectDatesFilter), this.platformKeys$])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.E_REPUTATION,
                                filters: {
                                    dates: { startDate, endDate },
                                    platforms,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }
}
