import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import {
    COUNTRIES,
    CountryCode,
    countryCodeToUbereatsOfferTiersMap,
    CurrencyCode,
    PlatformKey,
    UbereatsPromotionValue,
} from '@malou-io/package-utils';

import ':shared/pipes/apply-fn.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ToOrdinalPipe } from ':shared/pipes/to-ordinal.pipe';

import { ButtonComponent } from '../../../../../shared/components/button/button.component';

@Component({
    selector: 'app-send-ubereats-offer',
    standalone: true,
    imports: [TranslateModule, MatButtonModule, ButtonComponent, ToOrdinalPipe, PlatformLogoPathResolverPipe, MatTooltipModule],
    templateUrl: './send-ubereats-offer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendUbereatsOfferComponent {
    readonly reviewerName = input.required<string>();
    readonly orderTotal = input.required<number | undefined>();
    readonly orderCurrencyCode = input.required<CurrencyCode>();
    readonly ordersCount = input.required<number | undefined>();
    readonly restaurantCountry = input.required<string | undefined>();

    readonly selected = output<UbereatsPromotionValue>();
    readonly showOrderInfo = computed(() => !!this.orderTotal());

    readonly offers = computed(() => {
        const restaurantCountry = this.restaurantCountry();
        const countryCode = COUNTRIES.find((country) => country.name === restaurantCountry || country.name_fr === restaurantCountry)?.code;
        let offers;
        if (countryCode && this._isCountryCodeSupported(countryCode)) {
            offers = countryCodeToUbereatsOfferTiersMap[countryCode];
        } else {
            offers = countryCodeToUbereatsOfferTiersMap[CountryCode.UNITED_STATES];
        }
        return Object.entries(offers).reduce((acc, cur) => {
            acc[cur[0]] = `${cur[1]}\u00a0${this.orderCurrencyCode()}`;
            return acc;
        }, {});
    });

    readonly total = computed(() => `${this.orderTotal()} ${this.orderCurrencyCode()}`);
    readonly UbereatsPromotionValue = UbereatsPromotionValue;
    readonly PlatformKey = PlatformKey;
    readonly selectedValue = signal(UbereatsPromotionValue.NONE);

    constructor() {
        effect(() => {
            this.selected.emit(this.selectedValue());
        });
    }

    onSelect(ubereatsPromotionValue: UbereatsPromotionValue): void {
        this.selectedValue.set(ubereatsPromotionValue);
    }

    private _isCountryCodeSupported(countryCode: CountryCode): countryCode is keyof typeof countryCodeToUbereatsOfferTiersMap {
        return countryCode in countryCodeToUbereatsOfferTiersMap;
    }
}
