import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';

import { SocialPostCaptionAiGenerationComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/social-post-caption-ai-generation/social-post-caption-ai-generation.component';
import { SocialPostChooseHashtagsPanelComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/social-post-choose-hashtags-panel/social-post-choose-hashtags-panel.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { DefaultPrompt } from ':shared/openai-prompt/openai-prompt.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-previews-feed-notes',
    templateUrl: './previews-feed-notes.component.html',
    styleUrls: ['./previews-feed-notes.component.scss'],
    standalone: true,
    imports: [SocialPostCaptionAiGenerationComponent, SocialPostChooseHashtagsPanelComponent, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewsFeedNotesComponent {
    readonly upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);

    readonly defaultPrompt = signal(DefaultPrompt.GENERATE_POST);

    readonly Illustration = Illustration;
}
