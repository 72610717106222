<div class="h-[30px]" [matTooltip]="aiPromptButtonTooltip()">
    <button
        class="malou-btn-flat !pr-0"
        id="tracking_answer_review_modal_generate_answer_with_ai_button"
        data-testid="answer-review-ask-ai-btn"
        mat-button
        [disabled]="disabled()"
        (click)="onAnswerReviewWithAiClick()">
        <mat-icon class="mb-1 mr-1 h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
        <span class="text-malou-color-chart-purple--accent">{{ 'reviews.reply_modal.ask_ai' | translate }}</span>
    </button>
</div>
