import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    CreateStickerBodyDto,
    CreateTotemBodyDto,
    LoadNfcsFromSheetRequestDto,
    LoadNfcsFromSheetResponseDto,
    NfcDto,
    NfcWithRestaurantDto,
    SearchNfcsQueryDto,
    UpdateStickerBodyDto,
    UpdateTotemBodyDto,
} from '@malou-io/package-dto';
import { ApiResultMetadataPagination, ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { objectToQueryParams } from ':shared/helpers/query-params';

@Injectable({
    providedIn: 'root',
})
export class NfcService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/nfc`;

    constructor(private readonly _http: HttpClient) {}

    search(
        query: Partial<SearchNfcsQueryDto>,
        restaurantIds?: string[]
    ): Observable<ApiResultV2<NfcWithRestaurantDto[], ApiResultMetadataPagination>> {
        const params = objectToQueryParams(query);
        return this._http.post<ApiResultV2<NfcWithRestaurantDto[], ApiResultMetadataPagination>>(
            `${this.API_BASE_URL}/search`,
            restaurantIds?.length ? { restaurantIds } : {},
            {
                params,
            }
        );
    }

    createTotem(body: CreateTotemBodyDto): Observable<ApiResultV2<NfcDto>> {
        return this._http.post<ApiResultV2<NfcDto>>(`${this.API_BASE_URL}/totems`, body);
    }

    updateTotem(id: string, body: UpdateTotemBodyDto): Observable<ApiResultV2<NfcDto>> {
        return this._http.put<ApiResultV2<NfcDto>>(`${this.API_BASE_URL}/totems/${id}`, body);
    }

    createSticker(body: CreateStickerBodyDto): Observable<ApiResultV2<NfcDto>> {
        return this._http.post<ApiResultV2<NfcDto>>(`${this.API_BASE_URL}/stickers`, body);
    }

    updateSticker(id: string, body: UpdateStickerBodyDto): Observable<ApiResultV2<NfcDto>> {
        return this._http.put<ApiResultV2<NfcDto>>(`${this.API_BASE_URL}/stickers/${id}`, body);
    }

    delete(id: string): Observable<never> {
        return this._http.delete<never>(`${this.API_BASE_URL}/${id}`);
    }

    getNfcByChipname(chipName: string): Observable<ApiResultV2<NfcWithRestaurantDto>> {
        return this._http.get<ApiResultV2<NfcWithRestaurantDto>>(`${this.API_BASE_URL}/chipname/${chipName}`);
    }

    getStickerByRestaurantId(restaurantId: string): Observable<ApiResultV2<NfcWithRestaurantDto>> {
        return this._http.get<ApiResultV2<NfcWithRestaurantDto>>(`${this.API_BASE_URL}/stickers/restaurants/${restaurantId}`);
    }

    loadNfcsFromSheet(body: LoadNfcsFromSheetRequestDto): Observable<LoadNfcsFromSheetResponseDto> {
        return this._http
            .post<ApiResultV2<LoadNfcsFromSheetResponseDto>>(`${this.API_BASE_URL}/load-from-sheet`, body)
            .pipe(map((res) => res.data));
    }

    getNfcByIds(nfcIds: string[]): Observable<ApiResultV2<NfcWithRestaurantDto[]>> {
        return this._http.post<ApiResultV2<NfcWithRestaurantDto[]>>(`${this.API_BASE_URL}/get-by-ids`, {
            nfcIds,
        });
    }
}
