import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { AuthService } from '../auth.service';
import { ConfirmModalComponent } from './confirm-modal-component/confirm-modal.component';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    standalone: true,
})
export class ConfirmComponent implements OnInit {
    userId: string | null;
    token: string | null;
    cleanPassword: string;

    constructor(
        private readonly _auth: AuthService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _spinnerService: SpinnerService,
        private readonly _translate: TranslateService,
        private readonly _malouDialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _matDialog: MatDialog
    ) {
        this.userId = this._route.snapshot.paramMap.get('uid');
        this.token = this._route.snapshot.paramMap.get('token');
    }

    ngOnInit(): void {
        if (!this.userId || !this.token) {
            this._router.navigate(['./auth/login']);
            return;
        }
        this._auth.confirm(this.userId, this.token).subscribe({
            next: () => {
                this._customDialogService.open(ConfirmModalComponent, {
                    width: '550px',
                    disableClose: false,
                    height: 'auto',
                    data: {
                        userId: this.userId,
                        token: this.token,
                    },
                });
            },
            error: (err) => {
                console.warn(err);
                const formattedError = err?.error?.message || err?.message || String(err);
                if (formattedError?.includes('invalid signature')) {
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('login.confirm_account.error'),
                        message: this._translate.instant('login.confirm_account.invalid_token'),
                        primaryButton: {
                            label: this._translate.instant('common.ok'),
                            action: () => this.goToLogin(),
                        },
                    });
                } else {
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('login.confirm_account.error'),
                        message: this._translate.instant('login.confirm_account.unknown_error'),
                        primaryButton: {
                            label: this._translate.instant('common.ok'),
                            action: () => this.goToLogin(),
                        },
                    });
                }
                this._spinnerService.hide();
            },
        });
    }

    goToLogin(): void {
        this._router.navigate(['./auth/login']);
        this._matDialog.closeAll();
    }
}
