@if (atLeastOneGenerationState()) {
    <div>
        <div class="malou-text-12--bold text-malou-color-text-1">
            @if (allGenerationAreOver()) {
                <div class="flex items-center gap-x-3">
                    <mat-icon
                        class="malou-bg-state-success malou-color-white malou-border-color-success !h-4 !w-4 rounded-full border-2"
                        [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    <div>
                        {{ 'keywords.keywords_generating_footer_popin.generation_over' | translate }}
                    </div>
                </div>
            } @else {
                <div>
                    {{ 'keywords.keywords_generating_footer_popin.generation_in_progress' | translate }}
                </div>
            }
        </div>
        @if (atLeastOneGenerationState()) {
            <hr class="my-3 border-malou-color-background-dark" />
            <div class="flex flex-col gap-y-2">
                @for (
                    keywordsGenerationStateWithRestaurant of keywordsGenerationStateWithRestaurants();
                    track keywordsGenerationStateWithRestaurant
                ) {
                    <div>
                        <app-keywords-generating-footer-popin-item
                            [keywordsGenerationStateWithRestaurant]="
                                keywordsGenerationStateWithRestaurant
                            "></app-keywords-generating-footer-popin-item>
                    </div>
                }
            </div>
        }
    </div>
} @else {
    <div class="malou-text-12--bold text-malou-color-text-1">
        {{ 'keywords.keywords_generating_footer_popin.no_generation' | translate }}
    </div>
}
