import { z } from 'zod';

import { CommentOptionValue, TemplateTranslationLang, TemplateType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const createTemplateParamsValidator = z.object({ restaurant_id: objectIdValidator }).transform((data) => ({
    restaurantId: data.restaurant_id,
}));
export type CreateTemplateParamsDto = z.infer<typeof createTemplateParamsValidator>;

export const createTemplateBodyValidator = z.object({
    name: z.string(),
    text: z.string(),
    rating: z.array(z.number()).optional(),
    withComment: z.nativeEnum(CommentOptionValue).optional(),
    type: z.nativeEnum(TemplateType),
});
export type CreateTemplateBodyDto = z.infer<typeof createTemplateBodyValidator>;
// ------------------------------------------------------------------------------------------
export const updateTemplateParamsValidator = z.object({ template_id: objectIdValidator }).transform((data) => ({
    templateId: data.template_id,
}));
export type UpdateTemplateParamsDto = z.infer<typeof updateTemplateParamsValidator>;

export const updateTemplateBodyValidator = z.object({
    name: z.string(),
    text: z.string(),
    rating: z.array(z.number()).optional(),
    withComment: z.nativeEnum(CommentOptionValue).optional(),
    type: z.nativeEnum(TemplateType),
});
export type UpdateTemplateBodyDto = z.infer<typeof updateTemplateBodyValidator>;
// ------------------------------------------------------------------------------------------
export const generateTemplatesBodyValidator = z.object({
    langs: z.array(z.nativeEnum(TemplateTranslationLang)),
});
export type GenerateTemplatesBodyDto = z.infer<typeof generateTemplatesBodyValidator>;

export const generateTemplatesParamsValidator = z.object({ restaurant_id: objectIdValidator }).transform((data) => ({
    restaurantId: data.restaurant_id,
}));
export type GenerateTemplatesParamsDto = z.infer<typeof generateTemplatesParamsValidator>;

// ------------------------------------------------------------------------------------------

export const handleGetTemplatesQueryValidator = z
    .object({
        restaurant_id: objectIdValidator.optional(),
        type: z.nativeEnum(TemplateType).optional(),
        fields: z.string().optional(),
    })
    .transform((data) => ({
        ...data,
        restaurantId: data.restaurant_id,
    }));

export type HandleGetTemplatesQueryDto = z.infer<typeof handleGetTemplatesQueryValidator>;

// ------------------------------------------------------------------------------------------

export const handleDeleteTemplatesBodyValidator = z.object({
    templateIds: z.array(objectIdValidator),
});

export type HandleDeleteTemplatesBodyDto = z.infer<typeof handleDeleteTemplatesBodyValidator>;

// ------------------------------------------------------------------------------------------

export const handleDeleteTemplateByIdParamsValidator = z.object({ template_id: objectIdValidator }).transform((data) => ({
    templateId: data.template_id,
}));

export type HandleDeleteTemplateByIdParamsDto = z.infer<typeof handleDeleteTemplateByIdParamsValidator>;
