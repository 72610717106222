import { IDisplayable } from '../interfaces';
import { Client } from './client';

export class Campaign implements IDisplayable {
    _id: string;
    name: string;
    platformKey: string;
    restaurantId: string;
    startDate: Date;
    endDate: Date;
    contactMode: string;
    type: CampaignType;
    audience: {
        sources: string[];
    };
    content: Email;
    contactInteractions: ClientInteraction[] = [];
    createdAt: Date;
    redirectFrom: string;

    public constructor(init: Partial<Campaign> = {}) {
        Object.assign(this, init);
    }

    getDisplayedValue(): string {
        return this.name;
    }
}

export class ClientInteraction {
    clientId: string;
    client?: Client;
    awsMailId?: string;
    lastStarRating: number;
    unsubscribedDate?: Date;
    openedDate?: Date;
    lastStarRatingDate?: Date;
    deliveredDate?: Date;

    public constructor(init: Partial<ClientInteraction> = {}) {
        Object.assign(this, init);
    }
}

export class Email {
    from: {
        name: string;
        email: string;
    };
    to?: string;
    object: string;
    messageHTML: string;

    public constructor(init: Partial<Email> = {}) {
        Object.assign(this, init);
    }
}

export enum CampaignType {
    REVIEW_BOOSTER = 'review_booster',
    PROMOTION = 'promotion',
}

// watcher on mail opening was added on July 22, 2022
// we can't have data for the previous campaigns
export const campaignOpeningStatisticsDate = new Date('08/22/2022'); // 22 juillet 2022
