@for (indication of indicationsList(); track $index) {
    <div class="flex">
        <ng-container [ngTemplateOutlet]="indicationTemplate" [ngTemplateOutletContext]="{ indication }"></ng-container>
    </div>
}

<ng-template let-indication="indication" #indicationTemplate>
    @if (indication.fulfilled) {
        <mat-icon class="malou-color-state-success" [svgIcon]="SvgIcon.CHECK"></mat-icon>
    } @else {
        <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
    }

    <div class="malou-text-9--medium mb-2 max-w-[150px] !text-malou-color-text-2">
        {{ 'keywords_gauge.' + indication.textType + '.maintext' | translate }}
        @if (indication.shouldDisplayCurrentValue) {
            <span>
                <span> : {{ indication.currentValue }}</span>
                @if (indication.optimalValue) {
                    /{{ indication.optimalValue }}
                }
            </span>
        }
        @if (indication.shouldDisplaySubText || indication.minimumValue) {
            <div class="malou-text-8 italic">
                @if (indication.shouldDisplaySubText) {
                    {{ 'keywords_gauge.' + indication.textType + '.subtext' | translate }}
                }
                @if (indication.minimumValue && indication.maximumValue) {
                    ({{ indication.minimumValue }} {{ 'common.minimum' | translate }}, {{ indication.maximumValue }}
                    {{ 'common.maximum' | translate }})
                }
                @if (indication.minimumValue && !indication.maximumValue) {
                    ({{ indication.minimumValue + ' ' + ('common.minimum' | translate) }})
                }
                @if (indication.maximumValue && !indication.minimumValue) {
                    ({{ indication.maximumValue + ' ' + ('common.maximum' | translate) }})
                }
            </div>
        }
    </div>
</ng-template>
