import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { malouChartColorBluePurple } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { RestaurantReviewsCountChartData } from '../reviews-count.interface';

type BarChartType = Extract<ChartType, 'bar'>;
const RESTAURANT_NAME_MAX_LENGTH = 20;

@Component({
    selector: 'app-reviews-count-chart',
    templateUrl: './reviews-count-chart.component.html',
    styleUrls: ['./reviews-count-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
})
export class ReviewsCountChartComponent implements OnInit, OnChanges {
    @Input() data: RestaurantReviewsCountChartData[];
    @Input() sortBy: ChartSortBy;

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, RestaurantReviewsCountChartData[]>[];
    chartOption: ChartOptions<BarChartType>;

    readonly PLATFORM_KEYS_TRAD = this._translateService.instant('enums.platform_key');

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _shortTextPipe: ShortTextPipe
    ) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'data')) {
            this.chartDataSets = this._computeChartData(this.data);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'sortBy')) {
            this.data = this._computeSortedData(this.sortBy);
            this.chartDataSets = this._computeChartData(this.data);
        }
    }

    private _computeChartData(data: RestaurantReviewsCountChartData[]): ChartDataset<BarChartType, RestaurantReviewsCountChartData[]>[] {
        return [
            {
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data,
                parsing: {
                    yAxisKey: 'total',
                    xAxisKey: 'restaurantName',
                },
                minBarLength: 10,
            },
        ];
    }

    private _computeSortedData(sortBy: ChartSortBy): RestaurantReviewsCountChartData[] {
        switch (sortBy) {
            case ChartSortBy.ALPHABETICAL:
                return this.data.sort((a, b) => a.restaurantName.localeCompare(b.restaurantName));
            case ChartSortBy.ASC:
                return this.data.sort((a, b) => a.total - b.total);
            case ChartSortBy.DESC:
                return this.data.sort((a, b) => b.total - a.total);
            default:
                return this.data;
        }
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem: TooltipItem<BarChartType>): string => this._computeTooltipLabel(tooltipItem),
                        afterTitle: (tooltipItem: TooltipItem<BarChartType>[]): string => this._computeTooltipAfterTitle(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<BarChartType>): string[] => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_tickValue: string, index: number, _ticks: any[]): string => {
                            const label = this.data[index].restaurantName;
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number) => this._shortNumberPipe.transform(tickValue),
                    },
                },
            },
        };
    }

    private _computeTooltipAfterTitle(items: TooltipItem<BarChartType>[]): string {
        const raw = items[0].raw as RestaurantReviewsCountChartData;
        return raw.restaurantAddress ?? '';
    }

    private _computeTooltipAfterLabel(items: TooltipItem<BarChartType>): string[] {
        const raw = items.raw as RestaurantReviewsCountChartData;
        return Object.entries(raw.reviewsCountPerPlatform).map(([platform, count]) => ` ${this.PLATFORM_KEYS_TRAD[platform]}: ${count}`);
    }

    private _getEvolutionString(evolution: number): string {
        if (evolution > 0) {
            return ` (+${evolution})`;
        }
        if (evolution < 0) {
            return ` (${evolution})`;
        }
        return '';
    }

    private _computeTooltipLabel(items: TooltipItem<BarChartType>): string {
        const raw = items.raw as RestaurantReviewsCountChartData;
        return ` ${this._shortNumberPipe.transform(raw.total)} ${this._getEvolutionString(raw.evolution)}`;
    }
}
