<app-modal-structure
    [title]="'platforms.connection_new.facebook_instagram.step_3.title' | translate"
    [primaryButtonText]="'common.next' | translate"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.LONG_TEXT"
    (close)="close.emit({})"
    (primaryClick)="goToStep.emit({ type: 'relative', value: 2 })"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <div class="flex flex-col gap-y-8">
        <div class="malou-color-text-2 malou-text-10--regular italic">
            {{ 'platforms.connection_new.facebook_instagram.step_3.description_main' | translate }}
        </div>
        <div class="flex flex-col justify-center gap-y-6">
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_3.description_1' | translate,
                        showUrl: true,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_3.description_2' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_3.description_3' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_3.description_4' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
            <app-right-arrow-with-content>
                <ng-container
                    [ngTemplateOutlet]="descriptionTemplate"
                    [ngTemplateOutletContext]="{
                        text: 'platforms.connection_new.facebook_instagram.step_3.description_5' | translate,
                        showUrl: false,
                    }"></ng-container>
            </app-right-arrow-with-content>
        </div>
    </div>
</app-modal-structure>

<ng-template let-showUrl="showUrl" let-text="text" #descriptionTemplate>
    <div class="malou-text-13--medium">
        <ng-container [ngTemplateOutlet]="descriptionTextTemplate" [ngTemplateOutletContext]="{ text }"></ng-container>
        @if (showUrl) {
            &nbsp;<ng-container *ngTemplateOutlet="description1UrlTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-text="text" #descriptionTextTemplate>
    <span class="malou-color-text-1">{{ text }}</span>
</ng-template>

<ng-template #description1UrlTemplate>
    <a class="malou-color-text-primary whitespace-nowrap" href="https://facebook.com/pages/create" target="_blank">{{
        'platforms.connection_new.facebook_instagram.step_3.description_1_url' | translate
    }}</a>
</ng-template>
