export enum CampaignStatus {
    NOT_STARTED = 'not_started',
    IN_PROGRESS = 'in_progress',
    SENT = 'sent',
    ERROR = 'error',
}

export interface CampaignState {
    restaurantId?: string;
    isFooterVisible?: boolean;
    campaignStatus?: CampaignStatus;
    error?: string;
}
