import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SearchComponent } from ':shared/components/search/search.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-organizations-list-modal',
    templateUrl: './organizations-list-modal.component.html',
    styleUrls: ['./organizations-list-modal.component.scss'],
    standalone: true,
    imports: [SearchComponent, TranslateModule, MatButtonModule, MatIconModule],
})
export class OrganizationsListModalComponent {
    readonly SvgIcon = SvgIcon;
    filteredOrganizations: Organization[] = [];
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        public dialogRef: MatDialogRef<OrganizationsListModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            organizations: Organization[];
        }
    ) {
        this.filteredOrganizations = this.data.organizations;
    }

    onValueChange(value: string): void {
        this.filteredOrganizations = this.data.organizations.filter((organization) =>
            organization.name.toLowerCase().includes(value.toLowerCase())
        );
    }

    close(): void {
        this.dialogRef.close();
    }
}
