import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { PostPublicationStatus } from '@malou-io/package-utils';

import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';

import { PublicationStatus } from '../../models/';

@Component({
    selector: 'app-is-published-filters',
    templateUrl: './is-published-filters.component.html',
    styleUrls: ['./is-published-filters.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule],
})
@AutoUnsubscribeOnDestroy()
export class IsPublishedFiltersComponent implements OnInit, KillSubscriptions {
    @Input() initialValue$: Observable<string[]>;
    @Output() changeOption: EventEmitter<PublicationStatus[]> = new EventEmitter();
    initialValue: string[];
    options = {
        all: this._translate.instant('published_filters.all'),
        published: this._translate.instant('published_filters.published'),
        pending: this._translate.instant('published_filters.pending'),
        draft: this._translate.instant('published_filters.draft'),
    };
    mapOptions = {
        published: PostPublicationStatus.PUBLISHED,
        pending: PostPublicationStatus.PENDING,
        draft: PostPublicationStatus.DRAFT,
    };
    isCheckedAll = false;
    isChecked = {
        published: true,
        pending: true,
        draft: false,
    };
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        this.initialValue$.subscribe((val) => {
            this.initialValue = val;
            if (this.initialValue) {
                this.isChecked.published = this.initialValue.includes(PostPublicationStatus.PUBLISHED);
                this.isChecked.pending = this.initialValue.includes(PostPublicationStatus.PENDING);
                this.isChecked.draft = this.initialValue.includes(PostPublicationStatus.DRAFT);
            }
        });

        this._translate.onLangChange.pipe(takeUntil(this.killSubscriptions$)).subscribe(() => {
            this.options = {
                all: this._translate.instant('published_filters.all'),
                published: this._translate.instant('published_filters.published'),
                pending: this._translate.instant('published_filters.pending'),
                draft: this._translate.instant('published_filters.draft'),
            };
        });
    }

    changeOptions(event: MatButtonToggleChange, key: string): void {
        this.isChecked[key] = event.source.checked;
        this.applyFilters();
    }

    applyFilters(): void {
        const filterList: PublicationStatus[] = [];

        if (this.isChecked.published) {
            filterList.push(PostPublicationStatus.PUBLISHED);
        }
        if (this.isChecked.pending) {
            filterList.push(PostPublicationStatus.PENDING);
        }
        if (this.isChecked.draft) {
            filterList.push(PostPublicationStatus.DRAFT);
        }

        this.changeOption.emit(filterList);
    }
}
