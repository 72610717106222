<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [selectedValues]="[selectedValue()]"
    [displayWith]="displayWith()"
    [multiSelection]="false"
    [checkboxOption]="false"
    [groupSelectedValuesAtTop]="false"
    (selectBaseChange)="selectPlatformChange.emit($event)">
    <ng-template let-value="value" #simpleSelectedValueTemplate>
        <span class="flex items-center gap-x-2">
            <app-platform-logo imgClasses="h-7 w-7" [logo]="value"></app-platform-logo>
            <span class="malou-text-12--medium text-malou-color-text-1">{{ displayWith() | applyPure: value }}</span>
        </span>
    </ng-template>
    <ng-template let-value="value" #optionTemplate>
        <span class="flex items-center gap-x-2">
            <app-platform-logo imgClasses="h-6 w-6" [logo]="value" [withLazyLoading]="true"></app-platform-logo>
            <span class="malou-text-12 text-malou-color-text-2">{{ displayWith() | applyPure: value }}</span>
        </span>
    </ng-template>
</app-select-base>
