/// <reference types="@types/googlemaps" />
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { map, startWith, Subject, takeUntil, tap } from 'rxjs';

import { KillSubscriptions, MatGoogleMapsAutocompleteEvent } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-input-google-maps-autocomplete',
    templateUrl: './input-google-maps-autocomplete.component.html',
    styleUrls: ['./input-google-maps-autocomplete.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputGoogleMapsAutocompleteComponent),
        },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatIconModule],
})
export class InputGoogleMapsAutocompleteComponent implements OnInit, ControlValueAccessor, KillSubscriptions, AfterViewInit {
    /**
     * Title
     *
     * @required
     */
    @Input()
    title = '';

    /**
     * Placeholder
     *
     * @optional
     */
    @Input()
    placeholder = '';

    /**
     * Required option, will add an asterix after the title
     *
     * @optional
     */
    @Input()
    required = false;

    /**
     * Error message, will add a colored border and will display the error below the input
     *
     * @optional
     */
    @Input()
    errorMessage: string | undefined;

    /**
     * Subtitle
     *
     * @required
     */
    @Input()
    subtitle?: string;

    /**
     * On value change output
     */
    @Output()
    inputGoogleMapsAutocompleteChange: EventEmitter<string> = new EventEmitter();

    /**
     * On location selected output (see: googleMapsAutocomplete)
     */
    @Output()
    locationSelected: EventEmitter<MatGoogleMapsAutocompleteEvent> = new EventEmitter();

    readonly SvgIcon = SvgIcon;

    @ViewChild('addressInput') addressInput: ElementRef;
    /**
     * To easily retrieve changed value event form input
     */
    control = new UntypedFormControl();

    isFocused = false;
    isEmptyValue = true;

    readonly killSubscriptions$: Subject<void> = new Subject();

    /**
     * For implementing ControlValueAccessor
     */
    isTouched = false;
    onTouched: any = () => {};
    onChange: any = () => {};

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(
                startWith(''),
                map((value) => (typeof value === 'string' ? value : '')),
                tap((value) => {
                    this.setEmptyValue(value);
                    this.propagateValue(value);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        this._getPlaceAutocomplete();
    }

    setEmptyValue(value: string): void {
        this.isEmptyValue = value === '';
    }

    propagateValue(value: string): void {
        this.markAsTouched();

        this.onChange(value);
        this.inputGoogleMapsAutocompleteChange.emit(value);
    }

    locationSelectedFn($event): void {
        this.locationSelected.emit($event);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    writeValue(value: string): void {
        this.control.setValue(value);
    }

    private _getPlaceAutocomplete(): void {
        const autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
            types: ['address'],
        });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.locationSelectedFn(place);
        });
    }
}
