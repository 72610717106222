<div class="star-container--{{ size() }}">
    @for (starIcon of stars() | starIcons: maxStars(); track $index) {
        <mat-icon
            class="star--{{ size() }}"
            [svgIcon]="starIcon"
            [ngClass]="{
                star: [StarIcon.STAR, StarIcon.STAR_HALF] | includes: starIcon,
                'star--empty': starIcon === StarIcon.STAR_BORDER,
            }"></mat-icon>
    }
</div>
