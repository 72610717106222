<div class="flex flex-col gap-3">
    @if (!disabled()) {
        <ng-container [ngTemplateOutlet]="addHoursTemplate"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="specialPeriodsTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="calendarEventsTemplate"></ng-container>
</div>

<ng-template #addHoursTemplate>
    <div class="mt-1 flex items-center justify-end">
        <button
            class="malou-btn-flat !p-0"
            mat-button
            matTooltip="{{ 'information.special_hours.add_period' | translate }}"
            [disabled]="disabled()"
            (click)="addSpecialPeriod()">
            <mat-icon class="icon-btn mr-2" [svgIcon]="SvgIcon.ADD"></mat-icon>
            {{ 'information.special_hours.add_period' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #specialPeriodsTemplate>
    <div class="mb-3 flex flex-col gap-4">
        @for (specialDatePeriod of futureSpecialPeriods(); track specialDatePeriod.divId; let index = $index) {
            <app-special-period-form
                [id]="specialDatePeriod.divId"
                [disabled]="disabled()"
                [specialDatePeriod]="specialDatePeriod"
                [canDuplicate]="canDuplicateSpecialPeriod | applyPure: specialDatePeriod"
                (delete)="deleteSpecialPeriod(index)"
                (duplicate)="duplicateSpecialPeriod(specialDatePeriod)"
                (startDateChange)="updateSpecialPeriodStartDate(index, $event)"
                (endDateChange)="updateSpecialPeriodEndDate(index, $event)"
                (timePeriodsChange)="updateSpecialPeriodTimePeriods(index, $event)"
                (isClosedChange)="updateSpecialPeriodIsClosed(index, $event)"
                (nameChange)="updateSpecialPeriodName(index, $event)"></app-special-period-form>
        }
    </div>
</ng-template>

<ng-template #calendarEventsTemplate>
    <div class="flex flex-col gap-4">
        @for (calendarEvent of filteredCalendarEvents(); track calendarEvent.id; let index = $index) {
            <app-special-hours-calendar-event
                [calendarEvent]="calendarEvent"
                (validateHours)="addSpecialPeriodFromCalendarEvent(calendarEvent)"></app-special-hours-calendar-event>
        }
    </div>
</ng-template>
