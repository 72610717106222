import { KeywordPopularity } from './keywords.interface';

export function computePopularity(volume: number, volumes: number[]): KeywordPopularity {
    const FIRST_PERCENTILE_THRESHOLD = 0.3;
    const SECOND_PERCENTILE_THRESHOLD = 0.8;

    if (!volume) {
        return volume !== 0 ? KeywordPopularity.PENDING : KeywordPopularity.LOW;
    }
    const firstPercentile = volumes[getPercentileIndex(FIRST_PERCENTILE_THRESHOLD, volumes.length)];
    const secondPercentile = volumes[getPercentileIndex(SECOND_PERCENTILE_THRESHOLD, volumes.length)];

    if (volume < firstPercentile) {
        return KeywordPopularity.LOW;
    } else if (volume <= secondPercentile) {
        return KeywordPopularity.MEDIUM;
    }
    return KeywordPopularity.HIGH;
}

function getPercentileIndex(percentile: number, volumesLength: number): number {
    const percentileFloat = volumesLength * percentile;
    const percentileRound = Math.round(percentileFloat);
    if (Math.abs(percentileFloat - percentileRound) < 0.01) {
        return percentileRound;
    }
    return Math.floor(volumesLength * percentile);
}
