import { ChangeDetectionStrategy, Component, Input, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

import { QrCode } from ':shared/helpers/qr-code';

@Component({
    selector: 'app-redirect-wheel-of-fortune-mobile',
    templateUrl: './redirect-wheel-of-fortune-mobile.component.html',
    styleUrls: ['./redirect-wheel-of-fortune-mobile.component.scss'],
    imports: [TranslateModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectWheelOfFortuneMobileComponent {
    @Input() logoUrl: Signal<string | null> = signal(null);

    readonly qrCode = new QrCode();
    readonly qrCodeImage = toSignal(
        this.qrCode.generateQrCode$(`${window.location.href}${this._urlHasQueryParam(window.location.href) ? '&' : '?'}isFromTotem=false`)
    );

    private _urlHasQueryParam(url: string): boolean {
        return url.includes('?');
    }
}
