import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import { domToPng } from 'modern-screenshot';
import { from, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HtmlDownloader {
    downloadPng(htmlElement: HTMLElement, fileName: string): Observable<any> {
        const scale = 4;
        return from(
            domToPng(htmlElement, {
                fetch: {
                    requestInit: {
                        cache: 'no-cache',
                    },
                },
                scale,
            })
        ).pipe(
            tap((pngDataUrl) => {
                if (!pngDataUrl) {
                    return;
                }
                saveAs(pngDataUrl, `${fileName}.png`);
            })
        );
    }
}
