import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-unsubscribe-to-campaigns',
    templateUrl: './unsubscribe-to-campaigns.component.html',
    styleUrls: ['./unsubscribe-to-campaigns.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class UnsubscribeToCampaignsComponent implements OnInit {
    restaurantName: string;
    errorOccured = false;

    constructor(private readonly _route: ActivatedRoute) {}

    ngOnInit(): void {
        // url is ${process.env.BASE_URL}/unsubscribe_campaign?restaurant_name=...&error=...

        this.restaurantName = this._route.snapshot.queryParams.restaurant_name || '';
        this.errorOccured = this._route.snapshot.queryParams.error === 'true';
    }
}
