import { Post } from './post';

export enum FeedbackMessageType {
    TEXT = 'text',
    CLOSE = 'close',
    REOPEN = 'reopen',
}

export enum FeedbackParticipantType {
    AUTHOR = 'author',
    COMMENTATOR = 'commentator',
    TAGGED = 'tagged',
    OTHER = 'other',
}

export interface FeedbackUser {
    _id: string;
    name: string;
    lastname: string;
    profilePictureUrl?: string;
    email: string;
}

export class Feedback {
    _id: string;
    postId: string;
    post?: Partial<Post>;
    isOpen: boolean;
    participants: {
        participant: FeedbackUser;
        types: string[];
    }[];
    feedbackMessages: FeedbackMessage[];

    public constructor(init: Partial<Feedback> = {}) {
        Object.assign(this, init);
        this.feedbackMessages = init?.feedbackMessages?.length ? init.feedbackMessages.map((message) => new FeedbackMessage(message)) : [];
    }
}

export enum MessageVisibilityLevel {
    ADMIN = 'admin',
    BASIC = 'basic',
}
export class FeedbackMessage {
    _id: string;
    author: FeedbackUser;
    createdAt: Date;
    text?: string;
    type: string;
    visibility = MessageVisibilityLevel.BASIC;

    public constructor(init: Partial<Feedback> = {}) {
        Object.assign(this, init);
    }
}
