import { NgClass, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR, DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { AnimatedWheelOfFortuneComponent } from ':shared/components/animated-wheel-of-fortune/animated-wheel-of-fortune.component';
import { Gift } from ':shared/models/gift';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';

@Component({
    selector: 'app-play-wheel-of-fortune',
    templateUrl: './play-wheel-of-fortune.component.html',
    styleUrls: ['./play-wheel-of-fortune.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass, MatButtonModule, AnimatedWheelOfFortuneComponent, MalouSpinnerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayWheelOfFortuneComponent implements OnInit {
    @Input() wheelOfFortune: Signal<WheelOfFortune | null>;
    @Input() regularTitle?: string;
    @Input() highlightedTitle: string;
    @Input() ctaText?: string;
    @Input() ctaId?: string;
    @Input() isWheelTurning = false;
    @Input() shouldClickOnCta = false;
    @Input() isCtaLoading: WritableSignal<boolean> = signal(false);
    @Input() isPreview: WritableSignal<boolean> = signal(false);
    @Output() onCtaClick = new EventEmitter<void>();

    readonly gifts: Signal<Gift[]> = computed(() => this.wheelOfFortune()?.gifts ?? []);
    readonly primaryColor: Signal<string> = computed(
        () => this.wheelOfFortune()?.parameters.primaryColor ?? DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR
    );
    readonly secondaryColor: Signal<string> = computed(
        () => this.wheelOfFortune()?.parameters.secondaryColor ?? DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR
    );
    readonly shouldStartAnimation: WritableSignal<boolean> = signal(false);

    ngOnInit(): void {
        if (this.isWheelTurning) {
            this.shouldStartAnimation.set(true);
            this.click();
        } else if (this.shouldClickOnCta) {
            this._waitAndClickOnCta(10);
        }
    }

    getWheelContainerSize(): string {
        const previewContainerWidth = '310';
        const overflowingScreenWidth = this.isPreview() ? previewContainerWidth : window.screen.width + 50;
        return `${overflowingScreenWidth}px`;
    }

    click(): void {
        this.onCtaClick.emit();
    }

    private _waitAndClickOnCta(timeAmount: number): void {
        setTimeout(() => {
            this.click();
        }, timeAmount);
    }
}
