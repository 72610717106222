import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, Plugin, TooltipItem } from 'chart.js';
import { EmptyObject } from 'chart.js/dist/types/basic';
import { NgChartsModule } from 'ng2-charts';

import { getPositiveAndNegativeStatsForSemanticAnalysis, getReviewsForSemanticAnalysisStats } from '@malou-io/package-utils';

import { ChartDataArray, malouChartColorGreen, malouChartColorRed, malouChartColorText1, malouChartColorText2 } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ReviewWithAnalysis, SegmentAnalyses } from ':shared/models';

enum ChartDataIndex {
    POSITIVE,
    NEGATIVE,
}

type DoughnutChartType = Extract<ChartType, 'doughnut'>;

const colors = [malouChartColorGreen, malouChartColorRed];

enum Quarter {
    TOP_RIGHT,
    TOP_LEFT,
    BOTTOM_RIGHT,
    BOTTOM_LEFT,
}

interface CustomChartLabel {
    value: number;
    percentageValue: number;
    subText: string[];
}
@Component({
    selector: 'app-tags-doughnut-chart',
    templateUrl: './tags-doughnut-chart.component.html',
    styleUrls: ['./tags-doughnut-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
})
export class TagsDoughnutChartComponent implements OnInit, OnChanges {
    @Input() reviews: ReviewWithAnalysis[];

    readonly CHART_TYPE: DoughnutChartType = 'doughnut';
    readonly CENTER_TEXT_PLUGIN: Plugin = this._getCenterTextPlugin();
    readonly DOUGHNUT_LABEL_LINE: Plugin = this._getDoughnutLabelLinePlugin();

    chartDataSets: ChartDataset<DoughnutChartType, ChartDataArray>[];
    chartLabels: { value: number; subText: string[] }[];
    chartOption: ChartOptions<DoughnutChartType>;

    allSegmentAnalyses: SegmentAnalyses[];

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviews')) {
            this.allSegmentAnalyses = getReviewsForSemanticAnalysisStats(this.reviews);

            this.chartDataSets = this._computeChartData();
            this.chartLabels = this._computeChartLabels();
        }
    }

    private _computeChartData(): ChartDataset<DoughnutChartType, ChartDataArray>[] {
        const { positiveSentimentsPercentage, negativeSentimentsPercentage } = getPositiveAndNegativeStatsForSemanticAnalysis(
            this.allSegmentAnalyses
        );

        return [
            {
                backgroundColor: colors,
                borderColor: colors,
                data: [positiveSentimentsPercentage, negativeSentimentsPercentage],
                borderWidth: 0,
            },
        ];
    }

    private _computeChartLabels(): CustomChartLabel[] {
        const {
            positiveSentimentsCount,
            positiveSentimentsPercentage,

            negativeSentimentsCount,
            negativeSentimentsPercentage,
        } = getPositiveAndNegativeStatsForSemanticAnalysis(this.allSegmentAnalyses);

        return [
            {
                value: positiveSentimentsCount,
                percentageValue: positiveSentimentsPercentage,
                subText: [
                    this._translate.instant('statistics.e_reputation.reviews_analysis.feelings'),
                    this._translate.instant('statistics.e_reputation.reviews_analysis.positive'),
                ],
            },
            {
                value: negativeSentimentsCount,
                percentageValue: negativeSentimentsPercentage,
                subText: [
                    this._translate.instant('statistics.e_reputation.reviews_analysis.feelings'),
                    this._translate.instant('statistics.e_reputation.reviews_analysis.negative'),
                ],
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<DoughnutChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>): string => {
                            const { value } = tooltipItem.chart.data.labels?.[tooltipItem.dataIndex] as CustomChartLabel;
                            if (tooltipItem.dataIndex === ChartDataIndex.POSITIVE) {
                                return ` ${this._translate.instant(
                                    'statistics.e_reputation.reviews_analysis.positive_sentiments'
                                )}: ${value}`;
                            }
                            return ` ${this._translate.instant('statistics.e_reputation.reviews_analysis.negative_sentiments')}: ${value}`;
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            cutout: '80%',
            layout: {
                padding: {
                    top: 40,
                    bottom: 50,
                },
            },
            responsive: true,
        };
    }

    private _getCenterTextPlugin(): Plugin {
        return {
            id: 'centerText',
            afterDraw: (chart: Chart, _args: EmptyObject): void => {
                const { ctx } = chart;
                ctx.save();
                const x = chart.getDatasetMeta(0).data[0].x;
                const y = chart.getDatasetMeta(0).data[0].y;

                ctx.font = '600 16px Poppins';
                ctx.fillStyle = malouChartColorText2;
                const textWidth = ctx.measureText(
                    `${this.allSegmentAnalyses.length} ${this._translate.instant('statistics.e_reputation.reviews_analysis.feelings')}`
                );
                ctx.fillText(
                    `${this.allSegmentAnalyses.length} ${this._translate.instant('statistics.e_reputation.reviews_analysis.feelings')}`,
                    x - textWidth.width / 2,
                    y + 10
                );
                ctx.restore();
            },
        };
    }

    private _getDoughnutLabelLinePlugin(): Plugin {
        return {
            id: 'doughnutLabelLine',
            afterDraw: (chart: Chart, _args: EmptyObject): void => {
                const { ctx } = chart;
                const centerX = chart.getDatasetMeta(0).data[0].x;
                const centerY = chart.getDatasetMeta(0).data[0].y;
                chart.data.datasets.forEach((dataset, i) => {
                    chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                        ctx.save();
                        const { x, y } = datapoint.tooltipPosition(true);
                        ctx.strokeStyle = dataset.borderColor?.[index];

                        const quarter = this._getQuarter(centerX, centerY, x, y);
                        ctx.beginPath();
                        ctx.moveTo(x, y);

                        const gap = 30;
                        const gap_2x = 50;
                        let currentXPos;
                        let currentYPos;

                        switch (quarter) {
                            case Quarter.TOP_RIGHT:
                                currentXPos = x + gap_2x;
                                currentYPos = y + gap;
                                ctx.lineTo(x + gap, y + gap);
                                ctx.lineTo(x + gap_2x, y + gap);
                                break;
                            case Quarter.BOTTOM_RIGHT:
                                currentXPos = x + gap_2x;
                                currentYPos = y - gap;
                                ctx.lineTo(x + gap, y - gap);
                                ctx.lineTo(x + gap_2x, y - gap);
                                break;
                            case Quarter.BOTTOM_LEFT:
                                currentXPos = x - gap_2x;
                                currentYPos = y - gap;
                                ctx.lineTo(x - gap, y - gap);
                                ctx.lineTo(x - gap_2x, y - gap);
                                ctx.textAlign = 'end';
                                break;
                            case Quarter.TOP_LEFT:
                                currentXPos = x - gap_2x;
                                currentYPos = y + gap;
                                ctx.lineTo(x - gap, y + gap);
                                ctx.lineTo(x - gap_2x, y + gap);
                                ctx.textAlign = 'end';
                                break;
                            default:
                                break;
                        }
                        ctx.stroke();
                        const labels = chart.data.labels?.[index] as CustomChartLabel;

                        ctx.textBaseline = 'middle';

                        ctx.font = '600 14px Poppins';
                        ctx.fillStyle = malouChartColorText1;
                        ctx.fillText(`${Math.round(labels.percentageValue)} %`, currentXPos, currentYPos - 8);

                        ctx.font = 'italic 400 12px Poppins';
                        ctx.fillStyle = malouChartColorText2;
                        ctx.fillText(`${labels.subText[0]}`, currentXPos, currentYPos + 8);
                        ctx.fillText(`${labels.subText[1]}`, currentXPos, currentYPos + 20);

                        ctx.restore();
                    });
                });
            },
        };
    }

    private _getQuarter(centerX, centerY, x, y): Quarter {
        if (x > centerX) {
            if (y > centerY) {
                return Quarter.TOP_RIGHT;
            } else {
                return Quarter.BOTTOM_RIGHT;
            }
        } else {
            if (y > centerY) {
                return Quarter.TOP_LEFT;
            } else {
                return Quarter.BOTTOM_LEFT;
            }
        }
    }
}
