import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { Restaurant, Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-restaurant-header-for-review-preview',
    templateUrl: './restaurant-header-for-review-preview.component.html',
    standalone: true,
    imports: [NgClass, LazyLoadImageModule, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantHeaderForReviewPreviewComponent {
    readonly restaurant = input.required<Restaurant | null>();
    readonly review = input<Review | PrivateReview>();
    readonly isSelected = input<boolean>(false);

    readonly hasReply = computed(() => {
        const review = this.review();
        return !!review && review.hasReply();
    });

    private readonly _screenSizeService = inject(ScreenSizeService);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
}
