<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <span class="max-w-[80%]">
            {{ 'keywords.competitors_modal_title' | translate: { text: keyword } }}
        </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="cancel()"></mat-icon>
    </div>
    <div class="malou-dialog__body hide-scrollbar max-h-[50vh] overflow-auto md:max-h-[65vh]">
        @if (ranking.length) {
            @for (restaurant of ranking; track trackByPlaceIdFn($index, restaurant); let index = $index) {
                <a
                    class="mb-1 flex items-center gap-x-4 rounded-xl border border-malou-color-border-primary px-6.5 py-4.5"
                    href="https://search.google.com/local/reviews?placeid={{ restaurant.place_id }}"
                    target="_blank"
                    [ngClass]="{ 'bg-malou-color-background-dark': restaurantIndex === index }">
                    <div class="rank">{{ index + 1 }}</div>
                    <div>
                        <div class="malou-text-12--bold">
                            {{ getRestaurantName | applyPure: index : restaurant.name }}
                        </div>
                        <div class="malou-text-12 italic text-malou-color-text-2">
                            {{ restaurant.vicinity || restaurant.formatted_address }}
                        </div>
                    </div>
                    @if (restaurantIndex === index) {
                        <div class="malou-text-10--semibold grow text-end text-malou-color-text-1">
                            <span class="malou-text-14--regular">{{ getEmojiAndTextFromPosition(index + 1).emoji }}</span>
                            {{ getEmojiAndTextFromPosition(index + 1).caption }}
                        </div>
                    }
                </a>
            }
        } @else {
            <div class="grid grow place-items-center p-5">
                <div class="flex flex-col items-center gap-y-3">
                    <img height="90" width="90" [src]="'Goggles' | illustrationPathResolver" />
                    <div class="malou-text-14--bold">{{ 'keywords.no_competition' | translate }}</div>
                </div>
            </div>
        }
    </div>
</div>
