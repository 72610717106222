import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Pusher, { Channel } from 'pusher-js';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';

import { UpdateConversationsBodyDto, UpdateConversationsResponseDto } from '@malou-io/package-dto';
import { ApiResultV2, ConversationStatus } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { IRealtimeMessagingService } from ':core/services/realtime-messaging/realtime-messaging.interface';
import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import * as MessagesActions from ':modules/messages/messages.actions';
import { User } from ':modules/user/user';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';
import { KillSubscriptions } from ':shared/interfaces';
import { ApiResult, Pagination, SortedPagination } from ':shared/models';
import { Conversation, ConversationType, ConversationWithMessage, ConversationWithMessages } from ':shared/models/conversation';
import { Message } from ':shared/models/message';

interface UserTyping {
    typing: boolean;
    conversationId?: string;
    user?: User;
}

@Injectable({
    providedIn: 'root',
})
@AutoUnsubscribeOnDestroy()
export class MessagesService implements KillSubscriptions, IRealtimeMessagingService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/messages`;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    private readonly _message$ = new Subject<ConversationWithMessage>();

    private readonly _userTyping$ = new BehaviorSubject<UserTyping>({ typing: false });
    private readonly _teamTyping$ = new BehaviorSubject<UserTyping>({ typing: false });
    private _serverChannel?: Channel;
    private _clientChannel?: Channel;

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _experimentationService: ExperimentationService
    ) {}

    changeTeamTypingStatus(conversationId: string, user: User, typing: boolean): void {
        this._clientChannel?.trigger('client-team-typing', { typing, conversationId, user });
    }

    getMessage$(): Observable<ConversationWithMessage> {
        return this._message$.asObservable();
    }

    getIsUserTyping$(): Observable<UserTyping> {
        return this._userTyping$.asObservable();
    }

    getIsTeamTyping$(): Observable<UserTyping> {
        return this._teamTyping$.asObservable();
    }

    getConversationsPaginated(
        restaurantId: string,
        platforms: string[],
        conversationType: ConversationType,
        conversationStatus: ConversationStatus[],
        pagination: SortedPagination
    ): Observable<{ conversations: ConversationWithMessages[]; count: number }> {
        const filters = { platforms, conversationType, conversationStatus, ...pagination };
        const cleanFilters = objectToQueryParams(filters);
        return this._http
            .get<
                ApiResult<{ conversations: ConversationWithMessages[]; count: number }>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/conversations`, { withCredentials: true, params: { ...cleanFilters } })
            .pipe(
                map((res) => {
                    res.data.conversations = res.data?.conversations?.map(
                        (conv) =>
                            new ConversationWithMessages({
                                ...conv,
                                messages: conv.messages.map((m) => new Message(m)),
                            })
                    );
                    return res.data;
                })
            );
    }

    updateConversation(conversation: Conversation, data: Partial<Conversation>): Observable<ApiResult<Conversation>> {
        return this._http.put<ApiResult<Conversation>>(`${this.API_BASE_URL}/conversations/${conversation._id}`, { ...data });
    }

    updateConversations(
        conversations: Conversation[],
        data: UpdateConversationsBodyDto
    ): Observable<ApiResultV2<UpdateConversationsResponseDto, { error?: boolean; message: string }>> {
        const conversationIds = conversations.map((conversation) => conversation._id);
        const params = objectToQueryParams({ conversationIds });
        return this._http.put<ApiResultV2<UpdateConversationsResponseDto, { error?: boolean; message: string }>>(
            `${this.API_BASE_URL}/conversations`,
            { ...data },
            { params }
        );
    }

    synchronizeMessages(
        restaurantId: string,
        platformsKeys: string[]
    ): Observable<ApiResult<{ conversations: ConversationWithMessage[]; errors: any[] }>> {
        return this._http.post<ApiResult<{ conversations: ConversationWithMessage[]; errors: any[] }>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/synchronize`,
            { platformsKeys }
        );
    }

    sendMessage(message: Partial<Message>, restaurantId: string): Observable<Message> {
        return this._http
            .post<ApiResult<Message>>(`${this.API_BASE_URL}/platforms/${message.key}/conversations/${message.conversationId}/send`, {
                message,
                restaurantId,
            })
            .pipe(map((res) => new Message(res.data)));
    }

    sendUpdateReaction(restaurantId: string, messageId: string, reactionType: string): Observable<Message> {
        return this._http
            .put<ApiResult<Message>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/reaction/${messageId}`, { reactionType })
            .pipe(map((res) => new Message(res.data)));
    }

    loadMoreMessages(
        conversationId: string,
        pagination: Pagination,
        filters = {}
    ): Observable<ApiResult<{ messages: Message[]; count: number }>> {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...filters, ...pagination }));
        return this._http
            .get<ApiResult<{ messages: Message[]; count: number }>>(`${this.API_BASE_URL}/conversations/${conversationId}/messages`, {
                withCredentials: true,
                params: { ...cleanFilters },
            })
            .pipe(
                map((res) => {
                    res.data.messages = res.data.messages?.map((message) => new Message(message));
                    return res;
                })
            );
    }

    getUnreadConversationsCount(restaurantId: string, connectedPlatforms: string[]): Observable<ApiResult<number>> {
        const params = objectToQueryParams({ connectedPlatforms });
        return this._http.get<ApiResult<number>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/conversations/unread`, {
            withCredentials: true,
            params,
        });
    }

    initRealtimeNotifications(realtimeMessagingClient: Pusher): void {
        this._restaurantsService.restaurantSelected$.pipe(takeUntil(this.killSubscriptions$)).subscribe({
            next: (restaurant) => {
                if (!restaurant) {
                    this._serverChannel?.unbind_all();
                    this._clientChannel?.unbind_all();
                    this._serverChannel?.unsubscribe();
                    this._clientChannel?.unsubscribe();
                    this._serverChannel = undefined;
                    this._clientChannel = undefined;
                    return;
                }
                this._serverChannel = realtimeMessagingClient.subscribe(
                    'messaging-restaurant-' + this._restaurantsService.currentRestaurant._id
                );
                this._clientChannel = realtimeMessagingClient.subscribe(
                    'private-messaging-restaurant-' + this._restaurantsService.currentRestaurant._id
                );

                this._clientChannel.bind('pusher:subscription_succeeded', () => console.info('Successfully connected to channel'));
                this._clientChannel.bind('pusher:subscription_error', (PusherError) => {
                    console.error('Error connecting to private channel', PusherError);
                });

                this._serverChannel.bind('message-received', ({ conversation, message }) => {
                    const conversationWithMessage = {
                        conversation,
                        message,
                    };
                    if (!message.isFromRestaurant && !this._experimentationService.isFeatureEnabled('release-new-message-notification')) {
                        this._message$.next(conversationWithMessage);
                    }
                    this._store.dispatch({ type: MessagesActions.receiveNewMessage.type, conversationWithMessage });
                });

                this._serverChannel.bind('message-deleted', ({ conversation, message }) => {
                    const conversationWithMessage = {
                        conversation,
                        message,
                    };
                    this._store.dispatch({ type: MessagesActions.deleteMessage.type, conversationWithMessage });
                });

                this._serverChannel.bind('reaction-received', ({ conversation, message }) => {
                    const conversationWithMessage = {
                        conversation,
                        message,
                    };
                    this._store.dispatch({ type: MessagesActions.receiveNewReaction.type, conversationWithMessage });
                });

                this._serverChannel.bind('user-typing', ({ typing, conversationId }) => {
                    this._userTyping$.next({ typing, conversationId });
                });

                this._clientChannel.bind('client-team-typing', ({ typing, conversationId, user }) => {
                    this._teamTyping$.next({ typing, conversationId, user });
                });
            },
        });
    }
}
