import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { PostCategory } from '../constants/post-category';
import { DialogService } from './dialog.service';
import { RestaurantsService } from './restaurants.service';

enum PostNumber {
    ONE = 1,
    MANY = 2,
}

@Injectable({
    providedIn: 'root',
})
export class DuplicatePostModalService {
    constructor(
        private readonly _dialogService: DialogService,
        private readonly _restaurantService: RestaurantsService,
        private readonly _router: Router,
        private readonly _translate: TranslateService,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {}

    openDuplicateModal(postId: string | null, target: PostCategory, manyPosts = false): Observable<any> {
        const postNumber = manyPosts ? PostNumber.MANY : PostNumber.ONE;
        const translatedTarget = this._enumTranslatePipe.transform(target, 'post_category') ?? target;
        return this._dialogService
            .open({
                variant: DialogVariant.INFO,
                title: this._pluralTranslatePipe.transform('posts.duplicate_post_modal.post_duplicated', postNumber),
                message: this._pluralTranslatePipe.transform('posts.duplicate_post_modal.will_be_saved', postNumber, {
                    target: translatedTarget,
                }),
                primaryButton: {
                    label: this._translate.instant('posts.duplicate_post_modal.yes_modify'),
                    action: () => {
                        this._goToModify(postId, target, manyPosts);
                    },
                },
                secondaryButton: {
                    label: this._translate.instant('posts.duplicate_post_modal.no_continue'),
                },
            })
            .afterClosed();
    }

    private _goToModify(postId: string | null, target: PostCategory, manyPosts = false): void {
        const targetUrl = this._computeTargetUrl(target);
        if (manyPosts) {
            this._router.navigate([targetUrl]);
        } else {
            this._router.navigate([targetUrl], { queryParams: { fromDuplicate: true, postId } });
        }
    }

    private _computeTargetUrl(target: PostCategory): string {
        const restaurantId = this._restaurantService.currentRestaurant._id;
        switch (target) {
            case PostCategory.SEO:
                return `./restaurants/${restaurantId}/seo/posts/list`;
            case PostCategory.SOCIAL:
                return `./restaurants/${restaurantId}/social/socialposts`;
            default:
                return '/';
        }
    }
}
