import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-detected-inconsistencies',
    standalone: true,
    imports: [MatButtonModule, TranslateModule, PluralTranslatePipe],
    templateUrl: './detected-inconsistencies.component.html',
    styleUrl: './detected-inconsistencies.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectedInconsistenciesComponent {
    readonly totalDifferencesCount = input<number>(0);
    readonly openPlatformsComparisons = output<void>();
}
