import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { take } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MapstrConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/mapstr-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { Platform } from ':shared/models';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-mapstr-connection-step-0',
    templateUrl: './mapstr-connection-step-0.component.html',
    styleUrls: ['./mapstr-connection-step-0.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        NgTemplateOutlet,
        DatePipe,
        EmojiPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapstrConnectionStep0Component extends BaseStepComponent<Platform | undefined, MapstrConnectionModalResult> {
    private readonly _platformsService = inject(PlatformsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _spinnerService = inject(SpinnerService);
    private readonly _toastService = inject(ToastService);
    private readonly _store = inject(Store);

    currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    imageLang = computed(() => (this.currentLang() === 'fr' ? 'fr' : 'en'));
    isMalouTokenCreated: Signal<boolean>;
    tomorrow = signal(DateTime.now().plus({ days: 1 }).toJSDate());

    readonly ButtonStyle = ButtonStyle;

    constructor() {
        super();
        const platformCreatedAt$ = this._store.select(selectCurrentPlatform({ platformKey: PlatformKey.MAPSTR })).pipe(
            take(1),
            map((platform) => platform?.createdAt)
        );

        const platformCreatedAtSignal = toSignal(platformCreatedAt$);
        this.isMalouTokenCreated = computed(() => {
            const platformCreatedAt = platformCreatedAtSignal();
            if (!platformCreatedAt) {
                return false;
            }
            // malou tokens are approximately created every day at 2 am every day UTC
            const now = DateTime.utc();
            const isPast2AM = now.hour >= 2;
            const last2AM = isPast2AM
                ? now.set({ hour: 2, minute: 0, second: 0, millisecond: 0 })
                : now.minus({ days: 1 }).set({ hour: 2, minute: 0, second: 0, millisecond: 0 });
            return platformCreatedAt < last2AM.toJSDate();
        });
    }

    onPrimaryClick(): void {
        window.open('https://dashboard.mapstr.com/posts', '_blank');
        this.goToStep.emit({ type: 'relative', value: 1 });
    }

    onPrimaryClickBis(): void {
        this._spinnerService.show();
        this._platformsService.sendMapstrReminder({ restaurantId: this._restaurantsService.currentRestaurant._id }).subscribe({
            next: () => {
                this._toastService.openSuccessToast(
                    this._translateService.instant('platforms.connection_new.mapstr.step_0.malou_token_not_created.reminder_sent')
                );
            },
            error: (error) => {
                console.error(error);
                this._toastService.openErrorToast(this._translateService.instant('common.error'));
            },
            complete: () => {
                this._spinnerService.hide();
                this.close.emit({});
            },
        });
    }
}
