<div class="flex items-center justify-between border-t border-malou-color-border-primary px-6 py-4">
    <button class="malou-text-14--semibold !text-malou-color-text-2" mat-button (click)="onCancel()">
        {{ 'common.cancel' | translate }}
    </button>

    <div class="flex items-center gap-x-3">
        <app-post-date-picker [selectedDate]="selectedDate()" [size]="PostDatePickerSize.BIG" [withBorder]="true"></app-post-date-picker>

        <app-menu-button-v2 [text]="'social_posts.new_social_post.later' | translate" [size]="MenuButtonSize.LARGE">
            <button class="flex !px-5" mat-menu-item>
                <span class="malou-text-14--regular text-malou-color-text-2">{{ 'social_posts.new_social_post.now' | translate }}</span>
            </button>
            <button class="flex !px-5" mat-menu-item>
                <span class="malou-text-14--regular text-malou-color-text-2">{{ 'social_posts.new_social_post.later' | translate }}</span>
            </button>
            <button class="flex !px-5" mat-menu-item>
                <span class="malou-text-14--regular text-malou-color-text-2">{{ 'posts.new_post.draft' | translate }}</span>
            </button>
        </app-menu-button-v2>
    </div>
</div>
