import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-rename-media-modal',
    templateUrl: './rename-media-modal.component.html',
    styleUrls: ['./rename-media-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, InputTextComponent, MatButtonModule, TranslateModule],
})
export class RenameMediaModalComponent {
    readonly SvgIcon = SvgIcon;
    mediaName: string;

    constructor(
        private readonly _dialogRef: MatDialogRef<RenameMediaModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: {
            media: Media;
        }
    ) {
        this.mediaName = this._data.media?.name ?? '';
    }

    close(): void {
        this._dialogRef.close();
    }

    rename(): void {
        this._dialogRef.close(this.mediaName);
    }

    onTextChange(text: string): void {
        this.mediaName = text;
    }
}
