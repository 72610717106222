import { waitFor } from '@malou-io/package-utils';

export const retryPromise = (promise: Promise<any>, retries = 3, delayInMilliseconds = 50): Promise<any> =>
    new Promise((resolve, reject) =>
        promise.then(resolve).catch((reason: Error) => {
            if (retries > 0) {
                return waitFor(delayInMilliseconds)
                    .then(retryPromise.bind(null, promise, retries - 1, delayInMilliseconds))
                    .then(resolve)
                    .catch(reject);
            }

            return reject(reason);
        })
    );
