import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SidenavState } from ':modules/sidenav-router/store/sidenav.reducer';

export const selectSidenavState = createFeatureSelector<SidenavState>('sidenav');

export const selectIsOpened = createSelector(selectSidenavState, (state: SidenavState) => state.isOpened);

export const selectSidenavWidth = createSelector(selectSidenavState, (state: SidenavState) => state.sidenavWidth);

export const selectIsSelectBusinessesPanelOpen = createSelector(
    selectSidenavState,
    (state: SidenavState) => state.isSelectBusinessesPanelOpen
);

export const selectOwnRestaurants = createSelector(selectSidenavState, (state: SidenavState) => state.ownRestaurants);
