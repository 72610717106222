import { computed, inject, Injectable } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

import { Day, isSameDay, mapLuxonWeekdayToDay, PlatformKey } from '@malou-io/package-utils';

import { OtherHoursService } from ':core/services/other-hours.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    BusinessHoursState,
    DEFAULT_HOURS_PERIOD,
    DEFAULT_SCHEDULE,
    DEFAULT_SCHEDULE_WITH_IS_CLOSED,
    HoursModalState,
    OtherHoursState,
    SpecialHoursState,
} from ':modules/informations/hours-modal/hours-modal.interface';
import { HoursModalService } from ':modules/informations/hours-modal/hours-modal.service';
import {
    CalendarEvent,
    HoursType,
    MyDate,
    OtherPeriod,
    Period,
    Restaurant,
    SpecialDatePeriod,
    SpecialTimePeriod,
    TimePeriod,
} from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Injectable({
    providedIn: 'root',
})
export class HoursModalContext {
    private readonly _hoursModalService = inject(HoursModalService);
    private readonly _otherHoursService = inject(OtherHoursService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);

    readonly LANG = LocalStorage.getLang();

    private readonly _initialBusinessHoursState: BusinessHoursState = {
        schedules: [cloneDeep(DEFAULT_SCHEDULE_WITH_IS_CLOSED)],
        hasBeenTouched: false,
    };
    private readonly _initialOtherHoursState: OtherHoursState = { services: [], availableHoursTypes: [], hasBeenTouched: false };
    private readonly _initialSpecialHoursState: SpecialHoursState = { specialPeriods: [], calendarEvents: [], hasBeenTouched: false };

    private readonly _initialHoursModalState: HoursModalState = {
        businessHours: this._initialBusinessHoursState,
        otherHours: this._initialOtherHoursState,
        specialHours: this._initialSpecialHoursState,
        isClosedTemporarily: false,
        hasBeenTouched: false,
    };

    readonly hoursModalState = signalState<HoursModalState>(this._initialHoursModalState);

    readonly businessHoursErrors = computed((): string[] =>
        this._hoursModalService.getBusinessHoursErrors(this.hoursModalState.businessHours.schedules())
    );
    readonly isBusinessHoursValid = computed((): boolean => this.businessHoursErrors().length === 0);
    readonly otherHoursErrors = computed((): string[] =>
        this._hoursModalService.getOtherHoursErrors(this.hoursModalState.otherHours.services())
    );
    readonly isOtherHoursValid = computed((): boolean => this.otherHoursErrors().length === 0);
    readonly specialHoursErrors = computed((): string[] =>
        this._hoursModalService.getSpecialHoursErrors(
            this.hoursModalState.specialHours.specialPeriods(),
            this.hoursModalState.businessHours.schedules()
        )
    );
    readonly isSpecialHoursValid = computed((): boolean => this.specialHoursErrors().length === 0);

    readonly stateHasBeenTouched = computed(
        (): boolean =>
            this.hoursModalState.hasBeenTouched() ||
            this.hoursModalState.businessHours.hasBeenTouched() ||
            this.hoursModalState.otherHours.hasBeenTouched() ||
            this.hoursModalState.specialHours.hasBeenTouched()
    );

    initState(restaurant: Restaurant, prefilledStartDate?: Date): void {
        const regularHours = restaurant.regularHours;
        const otherHours = restaurant.otherHours;
        const specialHours = restaurant.specialHours;
        const availableHoursTypes = restaurant.availableHoursTypes ?? [];

        const businessHoursState =
            regularHours.length > 0
                ? this._hoursModalService.mapRegularHoursToBusinessHoursState(regularHours)
                : this._initialBusinessHoursState;

        const otherHoursState =
            otherHours.length > 0
                ? this._hoursModalService.mapOtherHoursToOtherHoursState(otherHours, availableHoursTypes)
                : { services: [], availableHoursTypes, hasBeenTouched: false };

        const specialHoursState = this._hoursModalService.mapSpecialHoursToSpecialHoursState(specialHours, prefilledStartDate);

        const isClosedTemporarily = restaurant.isClosedTemporarily;

        patchState(this.hoursModalState, (state) => ({
            ...state,
            businessHours: businessHoursState,
            otherHours: otherHoursState,
            specialHours: specialHoursState,
            isClosedTemporarily,
            hasBeenTouched: false,
        }));

        this._computeAndSetBusinessHoursAvailableDays();
        otherHoursState.services.forEach((_, i) => this._computeAndSetOtherHoursAvailableDays(i));

        this._initSpecialHoursCalendarEvents(restaurant._id, new Date());
        this._initAvailableHoursTypes(restaurant._id);
    }

    updateIsCloseTemporarily(value: boolean): void {
        patchState(this.hoursModalState, (state) => ({ ...state, isClosedTemporarily: value, hasBeenTouched: true }));
    }

    /**
     * Business Hours methods
     *  */

    getRegularHoursFromBusinessHoursState(): TimePeriod[] {
        const schedules = this.hoursModalState.businessHours.schedules();
        return this._hoursModalService.getRegularHoursFromBusinessHoursState(schedules);
    }

    addScheduleToBusinessHours(): void {
        const schedules = this.hoursModalState.businessHours.schedules();
        patchState(this.hoursModalState, (state) => ({
            ...state,
            businessHours: {
                ...state.businessHours,
                schedules: [
                    ...state.businessHours.schedules,
                    {
                        ...cloneDeep(DEFAULT_SCHEDULE_WITH_IS_CLOSED),
                        availableDays: this._hoursModalService.getAvailableDaysForBusinessHours(
                            DEFAULT_SCHEDULE_WITH_IS_CLOSED.selectedDays,
                            schedules
                        ),
                    },
                ],
                hasBeenTouched: true,
            },
        }));
    }

    updateBusinessHoursScheduleSelectedDays(index: number, days: Day[]): void {
        patchState(this.hoursModalState, (state) => {
            const schedules = state.businessHours.schedules.map((schedule, i) => {
                const updatedSelectedDays = i === index ? days : schedule.selectedDays;
                return {
                    ...schedule,
                    selectedDays: updatedSelectedDays,
                };
            });
            return { ...state, businessHours: { ...state.businessHours, schedules, hasBeenTouched: true } };
        });
        this._computeAndSetBusinessHoursAvailableDays();
    }

    updateBusinessHoursSchedulePeriods(index: number, periods: Period[]): void {
        patchState(this.hoursModalState, (state) => {
            const schedules = [...state.businessHours.schedules];
            schedules[index].periods = periods;
            return { ...state, businessHours: { ...state.businessHours, schedules, hasBeenTouched: true } };
        });
    }

    updateBusinessHoursScheduleIsClosed(index: number, isClosed: boolean): void {
        patchState(this.hoursModalState, (state) => {
            const schedules = [...state.businessHours.schedules];
            schedules[index].isClosed = isClosed;
            return { ...state, businessHours: { ...state.businessHours, schedules, hasBeenTouched: true } };
        });
    }

    removeScheduleFromBusinessHours(index: number): void {
        patchState(this.hoursModalState, (state) => {
            const schedules = [...state.businessHours.schedules];
            schedules.splice(index, 1);
            return { ...state, businessHours: { ...state.businessHours, schedules, hasBeenTouched: true } };
        });
        this._computeAndSetBusinessHoursAvailableDays();
    }

    private _computeAndSetBusinessHoursAvailableDays(): void {
        patchState(this.hoursModalState, (state) => {
            const schedules = state.businessHours.schedules.map((schedule) => ({
                ...schedule,
                availableDays: this._hoursModalService.getAvailableDaysForBusinessHours(
                    schedule.selectedDays,
                    state.businessHours.schedules
                ),
            }));
            return { ...state, businessHours: { ...state.businessHours, schedules } };
        });
    }

    /**
     * Other Hours methods
     */

    getOtherHoursFromOtherHoursState(): OtherPeriod[] {
        return this._hoursModalService.getOtherHoursFromOtherHoursState(this.hoursModalState.otherHours.services());
    }

    removeServiceFromOtherHours(index: number): void {
        patchState(this.hoursModalState, (state) => {
            const services = [...state.otherHours.services];
            services.splice(index, 1);
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
    }

    addServiceToOtherHours(type: HoursType): void {
        patchState(this.hoursModalState, (state) => {
            const services = [...state.otherHours.services, { type, schedules: [cloneDeep(DEFAULT_SCHEDULE)] }];
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
    }

    updateOtherHoursServiceScheduleSelectedDays(serviceIndex: number, scheduleIndex: number, days: Day[]): void {
        patchState(this.hoursModalState, (state) => {
            const services = state.otherHours.services.map((service, i) => {
                if (i === serviceIndex) {
                    const schedules = service.schedules.map((schedule, j) => {
                        if (j === scheduleIndex) {
                            return { ...schedule, selectedDays: days };
                        }
                        return schedule;
                    });
                    return { ...service, schedules };
                }
                return service;
            });
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
        this._computeAndSetOtherHoursAvailableDays(serviceIndex);
    }

    updateOtherHoursServiceSchedulePeriods(serviceIndex: number, scheduleIndex: number, periods: Period[]): void {
        patchState(this.hoursModalState, (state) => {
            const services = state.otherHours.services.map((service, i) => {
                if (i === serviceIndex) {
                    const schedules = service.schedules.map((schedule, j) => {
                        if (j === scheduleIndex) {
                            return { ...schedule, periods };
                        }
                        return schedule;
                    });
                    return { ...service, schedules };
                }
                return service;
            });
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
    }

    addScheduleToOtherHoursService(serviceIndex: number): void {
        patchState(this.hoursModalState, (state) => {
            const services = state.otherHours.services.map((service, i) => {
                if (i === serviceIndex) {
                    return {
                        ...service,
                        schedules: [
                            ...service.schedules,
                            {
                                ...cloneDeep(DEFAULT_SCHEDULE),
                                availableDays: this._hoursModalService.getAvailableDaysForOtherHoursService(
                                    serviceIndex,
                                    DEFAULT_SCHEDULE.selectedDays,
                                    this.hoursModalState.otherHours.services
                                )(),
                            },
                        ],
                    };
                }
                return service;
            });
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
    }

    removeScheduleFromOtherHoursService(serviceIndex: number, scheduleIndex: number): void {
        patchState(this.hoursModalState, (state) => {
            const services = state.otherHours.services.map((service, i) => {
                if (i === serviceIndex) {
                    const schedules = [...service.schedules];
                    schedules.splice(scheduleIndex, 1);
                    return { ...service, schedules };
                }
                return service;
            });
            return { ...state, otherHours: { ...state.otherHours, services, hasBeenTouched: true } };
        });
        this._computeAndSetOtherHoursAvailableDays(serviceIndex);
    }

    private _initAvailableHoursTypes(restaurantId: string): void {
        this._otherHoursService
            .getHoursTypes(restaurantId, PlatformKey.GMB)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (hourTypes) => {
                    this._setAvailableHoursTypes(hourTypes);
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _setAvailableHoursTypes(hourTypes: HoursType[]): void {
        patchState(this.hoursModalState, (state) => ({ ...state, otherHours: { ...state.otherHours, availableHoursTypes: hourTypes } }));
    }

    private _computeAndSetOtherHoursAvailableDays(serviceIndex: number): void {
        patchState(this.hoursModalState, (state) => {
            const services = state.otherHours.services.map((service, i) => {
                if (i === serviceIndex) {
                    const schedules = service.schedules.map((schedule) => ({
                        ...schedule,
                        availableDays: this._hoursModalService.getAvailableDaysForOtherHoursService(
                            serviceIndex,
                            schedule.selectedDays,
                            this.hoursModalState.otherHours.services
                        )(),
                    }));
                    return { ...service, schedules };
                }
                return service;
            });
            return { ...state, otherHours: { ...state.otherHours, services } };
        });
    }

    /**
     * Special Hours methods
     */

    getSpecialHoursFromSpecialHoursState(): SpecialTimePeriod[] {
        return this._hoursModalService.getSpecialHoursFromSpecialHoursState(this.hoursModalState.specialHours.specialPeriods());
    }

    addSpecialPeriodToSpecialHours(): string {
        const name = this._translateService.instant('informations.special_hours.default_name');
        const newSpecialPeriod = new SpecialDatePeriod({
            name,
            startDate: MyDate.fromDate(DateTime.now().toJSDate()),
            endDate: undefined,
            isClosed: true,
            periods: [cloneDeep(DEFAULT_HOURS_PERIOD)],
        });

        patchState(this.hoursModalState, (state) => ({
            ...state,
            specialHours: {
                ...state.specialHours,
                specialPeriods: [newSpecialPeriod, ...state.specialHours.specialPeriods],
                hasBeenTouched: true,
            },
        }));

        return newSpecialPeriod.divId;
    }

    addSpecialPeriodToSpecialHoursFromCalendarEvent(calendarEvent: CalendarEvent): string {
        const calendarEventStartDateTime = DateTime.fromISO(calendarEvent.startDate);
        const calendarEventStartDate = calendarEventStartDateTime.toJSDate();

        const startDate = new MyDate();
        startDate.setDate(calendarEventStartDate);

        const endDate = new MyDate();
        endDate.setDate(calendarEventStartDate);

        const calendarEventDay = mapLuxonWeekdayToDay(calendarEventStartDateTime.weekday);
        const daySchedule = this.hoursModalState.businessHours
            .schedules()
            .find((schedule) => schedule.selectedDays.includes(calendarEventDay));
        let periods = daySchedule?.periods ?? [];
        if (periods.length === 0) {
            periods = [cloneDeep(DEFAULT_HOURS_PERIOD)];
        }

        const isClosed = !!daySchedule?.isClosed;

        const name = calendarEvent.getNameForLang(this.LANG);

        const newSpecialPeriod = new SpecialDatePeriod({
            periods,
            startDate,
            endDate,
            isClosed,
            name,
            isFromCalendarEvent: true,
        });

        patchState(this.hoursModalState, (state) => ({
            ...state,
            specialHours: {
                ...state.specialHours,
                specialPeriods: [newSpecialPeriod, ...state.specialHours.specialPeriods].sort(
                    this._hoursModalService.sortSpecialDatePeriods
                ),
                hasBeenTouched: true,
            },
        }));

        return newSpecialPeriod.divId;
    }

    removeSpecialPeriodFromSpecialHours(index: number): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = [...state.specialHours.specialPeriods];
            specialPeriods.splice(index, 1);
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    updateSpecialPeriodStartDate(index: number, startDate: Date | null): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = state.specialHours.specialPeriods.map((specialPeriod, i) => {
                if (i === index) {
                    let newStartDate: MyDate | undefined;
                    if (startDate) {
                        newStartDate = new MyDate();
                        newStartDate.setDate(startDate);
                    }
                    return new SpecialDatePeriod({ ...specialPeriod, startDate: newStartDate });
                }
                return specialPeriod;
            });
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    updateSpecialPeriodEndDate(index: number, endDate: Date | null): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = state.specialHours.specialPeriods.map((specialPeriod, i) => {
                if (i === index) {
                    let newEndDate: MyDate | undefined;
                    if (endDate) {
                        newEndDate = new MyDate();
                        newEndDate.setDate(endDate);
                    }
                    return new SpecialDatePeriod({ ...specialPeriod, endDate: newEndDate });
                }
                return specialPeriod;
            });
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    updateSpecialPeriodTimePeriods(index: number, periods: Period[]): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = state.specialHours.specialPeriods.map((specialPeriod, i) => {
                if (i === index) {
                    return new SpecialDatePeriod({ ...specialPeriod, periods });
                }
                return specialPeriod;
            });
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    updateSpecialPeriodIsClosed(index: number, isClosed: boolean): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = state.specialHours.specialPeriods.map((specialPeriod, i) => {
                if (i === index) {
                    return new SpecialDatePeriod({ ...specialPeriod, isClosed });
                }
                return specialPeriod;
            });
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    updateSpecialPeriodName(index: number, name: string): void {
        patchState(this.hoursModalState, (state) => {
            const specialPeriods = state.specialHours.specialPeriods.map((specialPeriod, i) => {
                if (i === index) {
                    return new SpecialDatePeriod({ ...specialPeriod, name });
                }
                return specialPeriod;
            });
            return {
                ...state,
                specialHours: {
                    ...state.specialHours,
                    specialPeriods,
                    hasBeenTouched: true,
                },
            };
        });
    }

    private _initSpecialHoursCalendarEvents(restaurantId: string, afterDate: Date): void {
        const beforeDate = DateTime.fromJSDate(afterDate).plus({ months: 3 }).toJSDate();
        this._restaurantsService.getRestaurantCalendarEvents(restaurantId, { afterDate, beforeDate }).subscribe((calendarEvents) => {
            patchState(this.hoursModalState, (state) => ({
                ...state,
                specialHours: {
                    ...state.specialHours,
                    calendarEvents: calendarEvents.filter((event) => event.shouldSuggestSpecialHourUpdate),
                },
            }));

            const prefilledStartDate = this.hoursModalState.specialHours.prefilledStartDate?.();
            if (prefilledStartDate) {
                const calendarEvent = calendarEvents.find((event) => {
                    const eventDate = DateTime.fromISO(event.startDate).toJSDate();
                    return isSameDay(eventDate, prefilledStartDate);
                });
                if (
                    calendarEvent &&
                    this._hoursModalService.noSpecialHoursForThisDate(
                        prefilledStartDate,
                        this.hoursModalState.specialHours.specialPeriods()
                    )
                ) {
                    this.addSpecialPeriodToSpecialHoursFromCalendarEvent(calendarEvent);
                }
            }
        });
    }
}
