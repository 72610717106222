import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, output, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs';

import { mapsterPostCaptionTextLimit, postCaptionTextLimit } from ':core/constants';
import { SocialPostHashtagsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-caption/social-post-hashtags/social-post-hashtags.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { PostHashtagsComponent } from ':modules/social-posts/new-social-post-modal/components/post-form-body/post-hashtags/post-hashtags.component';
import { AiGenerationActionsComponent } from ':shared/components/ai-generation-actions/ai-generation-actions.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';

@Component({
    selector: 'app-social-post-caption',
    templateUrl: './social-post-caption.component.html',
    styleUrls: ['./social-post-caption.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        AiGenerationActionsComponent,
        InfiniteTextSlideComponent,
        SocialPostHashtagsComponent,
        TextAreaComponent,
        ClickOutsideDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostCaptionComponent {
    readonly isMapstrPlatformChecked = input.required<boolean>();
    readonly isReel = input.required<boolean>();
    readonly value = input<string | undefined>();

    readonly valueChange = output<string>();

    private readonly _translateService = inject(TranslateService);
    readonly upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);

    readonly postHashtagsComponent = viewChild(PostHashtagsComponent, { read: ElementRef });

    readonly SvgIcon = SvgIcon;

    readonly postDescriptionFormControl = new FormControl<string>('') as FormControl<string>;

    readonly loadingAnimationDefaultText = signal(
        this._translateService.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));

    readonly MAPSTR_POST_TEXT_LIMIT = mapsterPostCaptionTextLimit;
    readonly POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    readonly TEXT_AREA_ID = 'postCaptionTextArea';

    constructor() {
        effect(() => {
            const value = this.value();
            if (value !== null && value !== undefined) {
                this.postDescriptionFormControl.setValue(value);
            }
        });

        this.postDescriptionFormControl.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe((value) => {
            this.valueChange.emit(value);
        });
    }

    onWriteWithAiClick(): void {
        setTimeout(() => document.getElementById('openaiPromptTextarea')?.focus(), 300);
    }

    handleClickOutside(target: Element): void {
        if (!target?.closest('#aiBtnInsideTextarea')) {
            this.upsertSocialPostAiContext.closePostCaptionAiGeneration();
        }
    }

    focusTextArea(): void {
        document.getElementById(this.TEXT_AREA_ID)?.getElementsByTagName('textarea')?.item(0)?.focus();
    }

    openPostCaptionAiGeneration(): void {
        this.upsertSocialPostAiContext.openPostCaptionAiGeneration();
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.post_subject'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.usual_tone'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.emojis'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.results_criteria'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translateService.instant('common.langs.en').toLowerCase(),
                    this._translateService.instant('common.langs.es').toLowerCase(),
                    this._translateService.instant('common.langs.it').toLowerCase(),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }
}
