import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

export interface NavBarTab {
    title: string;
    subtitle?: string;
    data: any;
}

@Component({
    selector: 'app-tab-nav-bar',
    templateUrl: './tab-nav-bar.component.html',
    styleUrls: ['./tab-nav-bar.component.scss'],
    standalone: true,
    imports: [MatTabsModule],
})
export class TabNavBarComponent implements OnInit {
    @Input() tabs: NavBarTab[];

    @Output() tabChange: EventEmitter<NavBarTab> = new EventEmitter<NavBarTab>();

    activeTab: NavBarTab;

    ngOnInit(): void {
        this.activeTab = this.tabs[0];
    }

    onTabCLick(tab: NavBarTab): void {
        this.activeTab = tab;
        this.tabChange.emit(tab);
    }
}
