<span class="relative" #dragAndDropArea>
    <ng-content></ng-content>
    <input
        class="hidden-file-input"
        type="file"
        multiple="multiple"
        [accept]="accept"
        [attr.disabled]="disabled ? '' : null"
        (click)="fileInput.value = ''"
        #fileInput />
    <app-uppy
        [dragAndDropArea]="dragAndDropArea"
        [fileInput]="fileInput"
        [maxVideoSize]="maxVideoSize"
        [maxImageSize]="maxImageSize"
        [maxNumberOfFiles]="maxNumberOfFiles"
        (progressChange)="updateProgress($event)"
        (onFinishUpload)="onFinishUpload($event)"
        (startWatcher)="startWatcher($event)"
        (startUploadProcess)="startUploadProcess($event)"
        (filesUploadError)="buildErrorsArray($event)"
        (draggingChange)="updateDraggingState($event)"></app-uppy>
</span>
