@let base = ['/restaurants', selectedRestaurant()?._id ?? ''];
<app-sidenav-content-route-group
    [options]="{
        text: 'sidenav_content.social_network' | translate,
        routerLink: base | concat: ['social'],
        svgIcon: SvgIcon.THUMB_UP,
    }"
    [childrenOptions]="[
        {
            text: 'sidenav_content.posts' | translate,
            routerLink: base | concat: ['social', 'socialposts'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.stories' | translate,
            routerLink: base | concat: ['social', 'stories'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
        {
            text: 'sidenav_content.inspirations' | translate,
            routerLink: base | concat: ['social', 'inspirations'],
            svgIcon: SvgIcon.DOT,
            isSmallSvgIcon: true,
            shouldHideSvgIconOnOpenedSidenav: true,
        },
    ]" />
