<div class="malou-card container">
    <div class="malou-card__header malou-text-18--bold">
        {{ 'validate_credentials.page_title' | translate }}
    </div>

    @if (showOrganizationSelect()) {
        <div class="malou-card__body malou-text-12--regular">
            <label id="example-radio-group-label">{{ 'validate_credentials.choose_organization_title' | translate }}</label>
            <ul class="my-3 ml-4">
                @for (organization of userOrganizations(); track trackByIdFn($index, organization)) {
                    <li class="mb-3">
                        <app-noop-mat-checkbox
                            color="primary"
                            classInput="malou-text-12--semibold"
                            [checked]="isChecked | applyPure: organization : selectedOrganizationIds()"
                            (click)="onValueChange(organization)">
                            {{ organization.name }}
                        </app-noop-mat-checkbox>
                    </li>
                }
            </ul>
            @if (userOrganizations().length === 0) {
                <p class="mb-2">
                    <b>
                        {{ 'validate_credentials.no_organization_found' | translate }}
                    </b>
                </p>
            }
            <button
                class="malou-btn-raised--primary !mt-3"
                data-testid="validate-credentials-confirm-btn"
                mat-raised-button
                [disabled]="!isOrganizationSelected()"
                (click)="validateOrganization()">
                {{ 'validate_credentials.confirm' | translate }}
            </button>
        </div>
    } @else {
        @if (isLoading()) {
            <app-malou-spinner></app-malou-spinner>
        }
    }
</div>
