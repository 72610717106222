<app-modal-structure
    [title]="translations().title | translate"
    [primaryButtonText]="translations().primaryButton | translate"
    [secondaryButtonText]="'common.cancel' | translate"
    [showTertiaryButton]="shouldDisplayTertiaryButton()"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="close.emit({})"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col gap-y-6 pt-8">
        <div class="malou-color-text-1 malou-text-12--bold">{{ translations().description1 | translate }}</div>
        <div
            class="malou-color-text-2 malou-text-12--medium"
            [innerHtml]="translations().description2 | translate: { class: 'malou-text-12--bold' }"></div>
        <div class="malou-color-background-light flex items-center self-center rounded-xl border border-malou-color-background-dark">
            <div class="malou-color-text-2 malou-text-12--semibold px-2 py-2">{{ MALOU_MANAGER_EMAIL_ADDRESS }}</div>
            <div class="cursor-pointer border-l border-malou-color-background-dark px-3 py-2" (click)="onCopyButtonClick()">
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.COPY"></mat-icon>
            </div>
        </div>
        <img
            class="h-[225px] w-auto self-center"
            [src]="imageName() | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
    </div>
</app-modal-structure>
