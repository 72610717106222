import { EntityConstructor, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface NotificationReview {
    id: string;
    text: string;
    platformKey: PlatformKey;
    socialCreatedAt: Date;
    rating: number;
    reviewerName?: string;
    reviewerProfilePictureUrl?: string;
    socialAttachmentUrls: string[];
}
export interface NegativeReviewReminderData {
    mainRestaurantReview: NotificationReview;
    mainRestaurantName: string;
    mainRestaurantId: string;
    mainRestaurantReviewCount: number;
    otherRestaurantsReviewCount: number;
    otherRestaurantNames: string[];
    suggestedReply?: string;
}

type NegativeReviewReminderNotificationProps = EntityConstructor<NegativeReviewReminderNotification>;

export class NegativeReviewReminderNotification extends Notification {
    data: NegativeReviewReminderData;

    constructor(props: NegativeReviewReminderNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<NegativeReviewReminderNotificationProps>): NegativeReviewReminderNotification {
        return new NegativeReviewReminderNotification({ ...this, ...props });
    }

    getMainReviewToNotify(): NotificationReview {
        return this.data.mainRestaurantReview;
    }

    getMainRestaurantName(): string {
        return this.data.mainRestaurantName;
    }

    getMainRestaurantId(): string {
        return this.data.mainRestaurantId;
    }
}
