import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as GenerationFormsActions from './generation-forms.actions';
import { GenerationFormsState, GenerationFormWithRestaurantId } from './generation-forms.interface';

export const adapter: EntityAdapter<GenerationFormWithRestaurantId> = createEntityAdapter<GenerationFormWithRestaurantId>({
    selectId: (gForm: GenerationFormWithRestaurantId) => gForm.restaurantId,
});

export const initialGenerationFormsState: GenerationFormsState = adapter.getInitialState({
    selectedFormId: null,
});

const generationFormsReducer = createReducer(
    initialGenerationFormsState,
    on(GenerationFormsActions.selectForm, (state, { formId }) => ({ ...state, selectedFormId: formId })),
    on(GenerationFormsActions.editGenerationForm, (state, { generationForm }) => adapter.upsertOne(generationForm, state))
);

export function reducer(state: GenerationFormsState | undefined, action: Action): GenerationFormsState {
    return generationFormsReducer(state, action);
}
