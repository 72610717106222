import { Directive, Input, OnInit, Optional, Self } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatMenuItem } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';

import { CaslRole } from '@malou-io/package-utils';

import { selectCurrentUserRestaurant, selectUserRestaurants } from ':modules/user/store/user.selectors';
import { UserRestaurant } from ':modules/user/user';

import { AutoUnsubscribeOnDestroy } from '../decorators/auto-unsubscribe-on-destroy.decorator';

export enum DisableIfMissingCaslRoleCheckType {
    ALL = 'ALL',
    AT_LEAST_ONE = 'AT_LEAST_ONE',
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[disableIfMissingCaslRole]',
    exportAs: 'isDisabledByCasl',
    standalone: true,
})
@AutoUnsubscribeOnDestroy()
export class DisableIfMissingCaslRoleDirective implements OnInit {
    @Input() neededCaslRoles: CaslRole[] = []; // Cant use signals in Directive
    @Input() disableIfMissingCaslRoleCheckType = DisableIfMissingCaslRoleCheckType.ALL;
    @Input() set restaurantIdsToCheckOnRole(value: string[]) {
        this._restaurantIdsToCheckOnRole = value;
        this._triggerDisable$.next(true);
    }

    public isDisabled = false;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    private _userRestaurants$ = this._store.select(selectUserRestaurants);
    private _currentUserRestaurant$ = this._store.select(selectCurrentUserRestaurant);
    private _restaurantIdsToCheckOnRole: string[] = [];
    private readonly _triggerDisable$ = new BehaviorSubject<boolean>(true);

    constructor(
        private readonly _store: Store,
        @Self() @Optional() private readonly _button: MatIconButton,
        @Self() @Optional() private readonly _menuItem: MatMenuItem
    ) {}

    ngOnInit(): void {
        combineLatest([this._userRestaurants$, this._currentUserRestaurant$, this._triggerDisable$])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([userRestaurants, currentUserRestaurant]) => {
                if (!userRestaurants) {
                    return;
                }

                const userRestaurantsToCheck = this._getUserRestaurantsToCheck(
                    userRestaurants,
                    currentUserRestaurant,
                    this._restaurantIdsToCheckOnRole
                );
                const disable = this._shouldBeDisabled(userRestaurantsToCheck);
                this.isDisabled = disable;
                this._disableElement(disable);
                return;
            });
    }

    private _shouldBeDisabled(userRestaurants: UserRestaurant[]): boolean {
        const caslRoleByRestaurant = userRestaurants.map((restaurant) => restaurant.caslRole);
        if (this.disableIfMissingCaslRoleCheckType === DisableIfMissingCaslRoleCheckType.AT_LEAST_ONE) {
            const atLeastOneRestaurantHasNotNeededRole = caslRoleByRestaurant.some((role) => !this.neededCaslRoles.includes(role));
            return atLeastOneRestaurantHasNotNeededRole;
        }
        const notAllRestaurantsHaveNeededRole = caslRoleByRestaurant.every((role) => !this.neededCaslRoles.includes(role));
        return notAllRestaurantsHaveNeededRole;
    }

    private _getUserRestaurantsToCheck(
        userRestaurants: UserRestaurant[],
        currentUserRestaurant: UserRestaurant | null,
        restaurantIdsToCheckOnRole: string[]
    ): UserRestaurant[] {
        if (currentUserRestaurant) {
            return [currentUserRestaurant];
        }
        if (this._restaurantIdsToCheckOnRole.length === 0) {
            return userRestaurants;
        }
        return userRestaurants.filter((restaurant) => restaurantIdsToCheckOnRole.includes(restaurant.restaurantId));
    }

    private _disableElement(disable: boolean): void {
        if (this._button && disable) {
            this._button.disabled = disable;
        }
        if (this._menuItem && disable) {
            this._menuItem.disabled = disable;
        }
    }
}
