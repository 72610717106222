import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getRestaurantAiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetRestaurantAiSettingsParamsDto = z.infer<typeof getRestaurantAiSettingsParamsValidator>;
