<div class="malou-card group !m-0 h-full">
    <div class="malou-card__header !pb-[15px]">
        <div class="malou-text-18--bold">
            {{ 'information.description.description' | translate }}
        </div>

        <div class="invisible flex gap-2 group-hover:visible md:visible">
            <button
                class="malou-btn-icon--secondary btn-xl"
                id="tracking_informations_descriptions_edit"
                mat-icon-button
                (click)="openDescriptionDialog()">
                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.EDIT"></mat-icon>
            </button>
            <button
                class="malou-btn-icon--secondary btn-xl"
                id="tracking_informations_descriptions_duplicate"
                mat-icon-button
                (click)="onPrepareDescriptionsDuplication()">
                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
            </button>
        </div>
    </div>
    <div class="malou-card__body h-full">
        @if (!restaurant.isBrandBusiness()) {
            <mat-tab-group animationDuration="5ms" mat-align-tabs="start" (selectedIndexChange)="handleTabChange($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="malou-text-14--medium">
                            {{ 'information.description.long' | translate }}
                        </span>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="malou-text-14--medium">
                            {{ 'information.description.short' | translate }}
                        </span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        }
        <app-restaurant-description
            [restaurant]="restaurant"
            [size]="selectedSize$.value"
            (changeDescriptions)="editDescription($event)"></app-restaurant-description>
    </div>
</div>
