import { Routes } from '@angular/router';

import { CREDENTIALS_ROUTES } from ':core/credentials/credentials.routing';
import { GroupsResolver } from ':core/resolvers/groups.resolver';
import { RestaurantResolver } from ':core/resolvers/restaurant.resolver';
import { RESTAURANTS_LIST_ROUTES } from ':modules/restaurant-list/restaurant-list.routing';
import { SidenavRouterComponent } from ':modules/sidenav-router/sidenav-router.component';
import { GROUPS_ROUTES } from ':shared/routes/groups.routing';
import { RESTAURANTS_ROUTES } from ':shared/routes/restaurants.routing';

export const SIDENAV_ROUTER_ROUTES: Routes = [
    {
        path: '',
        component: SidenavRouterComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'restaurants',
            },
            {
                path: 'restaurants',
                loadChildren: () => RESTAURANTS_LIST_ROUTES,
            },
            {
                path: 'credentials',
                loadChildren: () => CREDENTIALS_ROUTES,
            },
            {
                path: 'restaurants/:restaurant_id',
                providers: [RestaurantResolver],
                resolve: { restaurant: RestaurantResolver },
                loadChildren: () => RESTAURANTS_ROUTES,
            },
            {
                path: 'groups',
                providers: [GroupsResolver],
                resolve: { groups: GroupsResolver },
                loadChildren: () => GROUPS_ROUTES,
            },
        ],
    },
];
