import { Routes } from '@angular/router';

import { GENERATOR_ROUTES } from ':modules/generator/generator.routing';

import { KeywordsListComponent } from './keywords-list/keywords-list.component';
import { KeywordsRootComponent } from './keywords-root.component';

export const KEYWORDS_ROUTES: Routes = [
    {
        path: '',
        component: KeywordsRootComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: KeywordsListComponent,
            },
            {
                path: 'generate',
                loadChildren: () => GENERATOR_ROUTES,
            },
        ],
    },
];
