import { computed, Injectable, signal } from '@angular/core';

import { isNotNil } from '@malou-io/package-utils';

@Injectable({
    providedIn: 'root',
})
export class PlatformsContext {
    readonly connectedPlatformCount = signal<number | undefined>(undefined);
    readonly allPlatformCount = signal<number | undefined>(undefined);

    readonly formattedConnectedPlatformsOnTotalRatio = computed<string>(() => {
        const connectedPlatforms = this.connectedPlatformCount();
        const totalPlatforms = this.allPlatformCount();
        return isNotNil(connectedPlatforms) && isNotNil(totalPlatforms) ? `${connectedPlatforms}/${totalPlatforms}` : '';
    });
}
