import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';

import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { ToastService } from ':core/services/toast.service';
import { AiGeneralSettingsComponent } from ':modules/ai-settings/ai-general-settings/ai-general-settings.component';
import { AiPostSettingsComponent } from ':modules/ai-settings/ai-post-settings/ai-post-settings.component';
import { AiReviewSettingsComponent } from ':modules/ai-settings/ai-review-settings/ai-review-settings.component';
import {
    PersonalizeRestaurantAiSettingsDuplicationComponent,
    PersonalizeRestaurantAiSettingsDuplicationData,
} from ':modules/ai-settings/ai-review-settings/personalize-restaurant-ai-settings-duplication/personalize-restaurant-ai-settings-duplication.component';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { Step } from ':shared/interfaces/step.interface';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-ai-settings',
    standalone: true,
    imports: [
        AiGeneralSettingsComponent,
        TranslateModule,
        AiPostSettingsComponent,
        AiReviewSettingsComponent,
        SkeletonComponent,
        MatIconModule,
        MatButtonModule,
    ],
    templateUrl: './ai-settings.component.html',
    styleUrl: './ai-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiSettingsComponent {
    readonly SvgIcon = SvgIcon;
    readonly aiSettingsContext = inject(AiSettingsContext);
    readonly _customDialogService = inject(CustomDialogService);
    readonly _translateService = inject(TranslateService);
    readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);
    readonly _toastService = inject(ToastService);

    openDuplicateModal(): void {
        const restaurantId = this.aiSettingsContext.restaurantAiSettings()!.restaurantId;

        const steps = this._getStepsForDuplication(restaurantId);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness: false,
        };

        this._customDialogService.open(StepperModalComponent, {
            width: '900px',
            height: 'unset',
            data: {
                stepperModalData: { validateButtonId: 'tracking_restaurant_ai_settings_duplicate_validation_button' },
                steps,
                initialData,
                title: this._translateService.instant('restaurant_ai_settings.modals.duplicate.title'),
                onSuccess: (restaurantAiSettingsList: RestaurantAiSettings[]) => {
                    this._onDuplicationSuccess(restaurantAiSettingsList);
                },
                onError: () => {
                    this._onDuplicationError();
                },
            },
        });
    }

    private _getStepsForDuplication(restaurantId: string): Step[] {
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('restaurant_ai_settings.modals.duplicate.restaurant_selection.subtitle'),
                primaryButtonText: this._translateService.instant('common.next'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizeRestaurantAiSettingsDuplicationData[]> =>
                    this._populateSelectedRestaurantsWithDuplicationForm(data),
            },
            {
                component: PersonalizeRestaurantAiSettingsDuplicationComponent,
                subtitle: this._translateService.instant('restaurant_ai_settings.modals.duplicate.personalize_duplication.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (data: PersonalizeRestaurantAiSettingsDuplicationData[]): Observable<RestaurantAiSettings[]> =>
                    this._duplicatePersonalizedRestaurantAiSettings(restaurantId, data),
            },
        ];
    }

    private _populateSelectedRestaurantsWithDuplicationForm(
        restaurantSelectionData: RestaurantsSelectionData
    ): Observable<PersonalizeRestaurantAiSettingsDuplicationData[]> {
        if (!restaurantSelectionData.selectedRestaurants) {
            return of([]);
        }
        const restaurantIds = restaurantSelectionData.selectedRestaurants.map((e) => e._id);
        return this._restaurantAiSettingsService.getRestaurantAiSettingsList(restaurantIds).pipe(
            map(
                (aiSettings) =>
                    restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                        const aiSetting = aiSettings.find((e) => e.restaurantId === restaurant._id);
                        const form = new FormGroup({
                            restaurantName: new FormControl(aiSetting?.restaurantName ?? restaurant.name, [Validators.required]),
                            signature: new FormControl(aiSetting?.reviewSettings?.signatures?.[0] ?? ''),
                        });
                        return {
                            restaurant,
                            form,
                        };
                    }) ?? []
            )
        );
    }

    private _duplicatePersonalizedRestaurantAiSettings(
        restaurantId: string,
        restaurantAiSettingsDuplicationDataPerRestaurant: PersonalizeRestaurantAiSettingsDuplicationData[]
    ): Observable<RestaurantAiSettings[]> {
        const duplicationData = restaurantAiSettingsDuplicationDataPerRestaurant.map((data) => ({
            restaurantId: data.restaurant.id,
            restaurantName: data.form.value.restaurantName ?? data.restaurant.name,
            signature: data.form.value.signature ?? '',
        }));
        return this._restaurantAiSettingsService.duplicateRestaurantAiSettings(restaurantId, duplicationData);
    }

    private _onDuplicationSuccess = (restaurantAiSettingsList: RestaurantAiSettings[]): void => {
        if (restaurantAiSettingsList.length) {
            this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.duplicate.success'));
        }
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (): void => {
        this._toastService.openErrorToast(this._translateService.instant('restaurant_ai_settings.modals.duplicate.error'));
    };
}
