import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { MaintenanceMode } from ':core/maintenance/maintenance.service';
import { InformationUpdateFilters, InformationUpdateOptions, Restaurant } from ':shared/models';

import { PlatformsManagementViewType } from '../platforms-management/platforms-management-actions-header/platforms-management-action-header.interface';
import * as AdminActions from './admin.actions';

export interface AdminState {
    userRestaurants: Restaurant[];
    maintenance: MaintenanceMode | null;
    updateFilters: InformationUpdateFilters;
}

export const initialState: AdminState = {
    userRestaurants: [],
    maintenance: null,
    updateFilters: {
        selectedOption: InformationUpdateOptions.BOTH,
        viewType: PlatformsManagementViewType.BUSINESS,
        showProcessed: false,
    },
};

const adminReducer = createReducer(
    initialState,
    on(AdminActions.editUserRestaurants, (state, { userRestaurants }) => ({ ...state, userRestaurants })),
    on(AdminActions.editMaintenanceMode, (state, { maintenance }) => ({ ...state, maintenance })),
    on(AdminActions.editUpdatesFilters, (state, { filters }) => ({ ...state, updateFilters: { ...state.updateFilters, ...filters } }))
);

export const selectAdminState = createFeatureSelector<AdminState>('admin');

export const selectUserRestaurants = createSelector(selectAdminState, (state: AdminState) => state.userRestaurants);

export const selectMaintenanceMode = createSelector(selectAdminState, (state: AdminState) => state.maintenance);

export const selectUpdatesFilters = createSelector(selectAdminState, (state: AdminState) => state.updateFilters);

export const selectSelectedOption = createSelector(selectUpdatesFilters, (filters: InformationUpdateFilters) => filters.selectedOption);

export const selectViewType = createSelector(selectUpdatesFilters, (filters: InformationUpdateFilters) => filters.viewType);

export const selectShowTreated = createSelector(selectUpdatesFilters, (filters: InformationUpdateFilters) => filters.showProcessed);

export function reducer(state: AdminState | undefined, action: Action): AdminState {
    return adminReducer(state, action);
}
