import { DateTime } from 'luxon';

import { CalendarEventDto } from '@malou-io/package-dto';
import { CountriesWithEvents } from '@malou-io/package-utils';

export class CalendarEvent {
    _id: string;
    id: string;
    startDate: string;
    endDate?: string;
    emoji: string;
    key: string;
    name: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    ideas?: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    example?: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    isBankHoliday?: boolean;
    restaurantsIds: string[];
    country: CountriesWithEvents;
    byDefault: boolean;
    shouldSuggestSpecialHourUpdate?: boolean;

    public constructor(init?: Partial<CalendarEvent>) {
        Object.assign(this, init);
    }

    getNameForLang(lang: string): string {
        return (this.emoji ? `${this.emoji} ` : '') + this.name[lang];
    }

    getIdeasForLang(lang: string): string | undefined {
        return this.ideas?.[lang];
    }

    getExampleForLang(lang: string): string {
        return this.example?.[lang];
    }

    getDisplayStartDate(lang: string): string {
        return DateTime.fromISO(this.startDate).setLocale(lang).toLocaleString(DateTime.DATE_HUGE);
    }

    static fromDto(dto: CalendarEventDto): CalendarEvent {
        return new CalendarEvent({
            _id: dto.id,
            id: dto.id,
            startDate: dto.startDate?.toString(),
            emoji: dto.emoji,
            key: dto.key,
            name: dto.name,
            ideas: dto.ideas,
            example: dto.example,
            isBankHoliday: dto.isBankHoliday,
            byDefault: dto.byDefault,
            shouldSuggestSpecialHourUpdate: dto.shouldSuggestSpecialHourUpdate,
        });
    }
}
