import { Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { RoiSavedTimeRow } from ':shared/models/roi-saved-time.model';
import { MillisecondsToHourMinutePipe } from ':shared/pipes/milliseconds-to-hour-minute.pipe';
import { RoiSavedTimePathResolver } from ':shared/pipes/roi-time-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-saved-time-item',
    templateUrl: './saved-time-item.component.html',
    styleUrls: ['./saved-time-item.component.scss'],
    standalone: true,
    imports: [NumberEvolutionComponent, ShortNumberPipe, TranslateModule, RoiSavedTimePathResolver, MillisecondsToHourMinutePipe],
})
export class SavedTimeItemComponent {
    readonly item = input.required<RoiSavedTimeRow>();

    readonly evolution = computed(() =>
        this.item().previousTime ? ((this.item().currentTime - this.item().previousTime) / this.item().previousTime) * 100 : 0
    );
}
