import { createAction, props } from '@ngrx/store';

import { CampaignStatus } from './campaigns.interface';

export namespace CampaignsActions {
    export const selectRestaurant = createAction('[Campaigns] Select Restaurant', props<{ restaurantId: string }>());
    export const editCampaignStatus = createAction(
        '[Campaigns] Edit Campaign Status',
        props<{ campaignStatus: CampaignStatus; error?: string }>()
    );
    export const setFooterVisibility = createAction('[Campaigns] Set Footer Visibility', props<{ isFooterVisible: boolean }>());
}
