import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

export interface MenuOption {
    name: string;
    actionData: object;
}

@Component({
    selector: 'app-bottom-actions-modal',
    templateUrl: './bottom-actions-modal.component.html',
    styleUrls: ['./bottom-actions-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule],
})
export class BottomActionsModalComponent {
    options: MenuOption[];

    constructor(
        private readonly _dialogRef: MatDialogRef<BottomActionsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            options: MenuOption[];
        }
    ) {
        this.options = this.data.options;
    }

    getBottomPosition(index: number): { [key: string]: string } {
        return { bottom: 35 + 60 * (index + 1) + 'px' };
    }

    emitAction(option: MenuOption): void {
        this.close(option?.actionData);
    }

    close(data: any = null): void {
        this._dialogRef.close(data);
    }
}
