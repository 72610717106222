import { DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { forkJoin, map } from 'rxjs';

import { Currency, getCurrencySymbol, RevenueOption, RoiRevenueOptions, SimilarRestaurantCategory } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { RoiSettingsService } from ':modules/roi/roi-settings.service';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { displayRevenue } from ':shared/services/csv-services/e-reputation/helper-functions';

interface RestaurantSetupForm {
    currency: FormControl<Currency | null>;
    averageTicket: FormControl<number | null>;
    revenue: FormControl<RevenueOption | null>;
    category: FormControl<SimilarRestaurantCategory | null>;
    openingDate: FormControl<Date | null>;
}

@Component({
    selector: 'app-restaurant-setup-modal',
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        SelectComponent,
        InputNumberComponent,
        InputDatePickerComponent,
        LazyLoadImageModule,
        ImagePathResolverPipe,
        ApplyPurePipe,
    ],
    templateUrl: './restaurant-setup-modal.component.html',
    styleUrl: './restaurant-setup-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantSetupModalComponent implements OnInit {
    private readonly _dialogRef = inject(DialogRef);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _fb = inject(FormBuilder);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);
    private readonly _roiSettingsService = inject(RoiSettingsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    readonly SvgIcon = SvgIcon;
    readonly data = inject<{
        restaurantId?: string;
    }>(MAT_DIALOG_DATA);

    readonly currencyOptions = Object.values(Currency);
    readonly revenueOptions: RevenueOption[] = RoiRevenueOptions;
    readonly categoriesOptions: SimilarRestaurantCategory[] = Object.values(SimilarRestaurantCategory).sort(
        (a: SimilarRestaurantCategory, b: SimilarRestaurantCategory) =>
            this._enumTranslatePipe
                .transform(a, 'similar_restaurants_categories')
                .localeCompare(this._enumTranslatePipe.transform(b, 'similar_restaurants_categories'))
    );

    readonly currentRestaurantId = this.data.restaurantId ?? this._restaurantsService.currentRestaurant.id;
    readonly restaurantSetupForm: FormGroup<RestaurantSetupForm> = this._fb.group({
        currency: [Currency.EUR],
        averageTicket: [null as number | null, Validators.required],
        revenue: [null as RevenueOption | null, Validators.required],
        category: [null as SimilarRestaurantCategory | null, Validators.required],
        openingDate: [null as Date | null, Validators.required],
    });

    selectedRestaurant: WritableSignal<Restaurant | null> = signal(null);

    get averageTicket(): number | null | undefined {
        return this.restaurantSetupForm.get('averageTicket')?.value;
    }

    ngOnInit(): void {
        this._initRestaurantSetupForm();
    }

    close(needsToBeUpdated: boolean = false): void {
        this._dialogRef.close({ needsToBeUpdated });
    }

    saveRoiSettings(): void {
        const restaurantSetup = this.restaurantSetupForm.value;
        if (!restaurantSetup || !restaurantSetup.category || !restaurantSetup.openingDate || !restaurantSetup.averageTicket) {
            return;
        }
        forkJoin([
            this._restaurantsService.update(this.currentRestaurantId, { openingDate: restaurantSetup.openingDate }),
            this._similarRestaurantsService.update(this.currentRestaurantId, restaurantSetup.category),
            this._roiSettingsService.upsert(this.currentRestaurantId, {
                ...restaurantSetup,
                minRevenue: restaurantSetup.revenue?.min,
                maxRevenue: restaurantSetup.revenue?.max,
            }),
        ]).subscribe({
            next: () => {
                this.close(true);
            },
            error: (err) => {
                console.warn(err);
                this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
            },
        });
    }

    onAverageTicketChange(averageTicket: number | null): void {
        this.restaurantSetupForm.get('averageTicket')?.setValue(averageTicket);
    }

    currencyDisplayWith(currency: Currency): string {
        return getCurrencySymbol(currency);
    }

    revenueDisplayWith(revenue: RevenueOption): string {
        return displayRevenue(revenue);
    }

    getRestaurantSubtext = (restaurant: Restaurant | null): string => restaurant?.getFullFormattedAddress() ?? '';

    categoryDisplayWith = (category: SimilarRestaurantCategory): string =>
        this._enumTranslatePipe.transform(category, 'similar_restaurants_categories');

    private _initRestaurantSetupForm(): void {
        const restaurantId = this.currentRestaurantId;
        if (!restaurantId) {
            return;
        }
        forkJoin([
            this._restaurantsService.show(restaurantId).pipe(map((res) => res.data)),
            this._similarRestaurantsService.get(restaurantId).pipe(map((res) => res.data)),
            this._roiSettingsService.getByRestaurantId(restaurantId).pipe(map((res) => res.data)),
        ]).subscribe({
            next: ([restaurant, similarRestaurants, roiSettings]) => {
                this.selectedRestaurant.set(restaurant);
                this.restaurantSetupForm.get('averageTicket')?.setValue(roiSettings?.averageTicket ?? null);
                this.restaurantSetupForm.get('openingDate')?.setValue(restaurant?.openingDate ?? null);
                this.restaurantSetupForm.get('category')?.setValue(similarRestaurants?.restaurantCategory ?? null);
                this.restaurantSetupForm.get('currency')?.setValue(roiSettings?.currency ?? Currency.EUR);
                this.restaurantSetupForm
                    .get('revenue')
                    ?.setValue(
                        roiSettings?.minRevenue && roiSettings?.maxRevenue
                            ? { min: roiSettings.minRevenue, max: roiSettings.maxRevenue }
                            : null
                    );
                this._changeDetectorRef.detectChanges();
            },
            error: (err) => console.warn(err),
        });
    }
}
