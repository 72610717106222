export enum KeywordScoreTextType {
    LOW_RATE_REVIEW = 'lowRateReview',
    HIGH_RATE_REVIEW = 'highRateReview',
    LOW_RATE_TEMPLATE = 'lowRateTemplate',
    HIGH_RATE_TEMPLATE = 'highRateTemplate',
    SHORT_DESCRIPTION = 'shortDescription',
    LONG_DESCRIPTION = 'longDescription',
    POST = 'post',
    PICTURE = 'picture',
}

export enum KeywordConditionCriteria {
    SORRY_WORDS = 'sorryWords',
    BRICKS_NUMBER = 'bricksNumber',
    RESTAURANT_NAME = 'restaurantName',
    BRICKS_VARIETY = 'bricksVariety',
    TEXT_LENGTH = 'textLength',
    RESPONSE_TIME = 'responseTime',
    REVIEWER_NAME = 'reviewerName',
}
