import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, Observable, switchMap } from 'rxjs';

import { AiInteractionDto } from '@malou-io/package-dto';
import { AiInteractionRelatedEntityCollection, ApiResultV2, isNotNil } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { objectToSnakeCase } from ':shared/helpers';
import { AiInteraction } from ':shared/models/ai-interaction';

@Injectable({
    providedIn: 'root',
})
export class AiInteractionsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/ai-interactions`;

    private _aiInteractionsChanged$ = new BehaviorSubject<boolean>(true);
    private _aiRelatedEntityChanged$ = new BehaviorSubject<{ relatedEntityId: string | null }>({ relatedEntityId: null });

    constructor(private readonly _http: HttpClient) {}

    getAiInteractions(relatedEntityId: string, relatedEntityCollection: AiInteractionRelatedEntityCollection): Observable<AiInteraction[]> {
        const params = objectToSnakeCase({ relatedEntityCollection });
        return this._http
            .get<ApiResultV2<AiInteractionDto[]>>(`${this.API_BASE_URL}/related-entity-id/${relatedEntityId}`, { params })
            .pipe(map((apiResult) => apiResult.data.map((aiInteractionDto) => new AiInteraction(aiInteractionDto))));
    }

    listenToAiInteractionsChanges$(
        relatedEntityId: string,
        relatedEntityCollection: AiInteractionRelatedEntityCollection,
        filterFunction?: (aiInteraction: AiInteraction) => boolean
    ): Observable<AiInteraction[]> {
        return this._aiInteractionsChanged$.pipe(
            switchMap(() => this.getAiInteractions(relatedEntityId, relatedEntityCollection)),
            map((aiInteractions) => {
                const sortedAiInteractions = aiInteractions.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
                return filterFunction ? sortedAiInteractions.filter(filterFunction) : sortedAiInteractions;
            }),
            catchError((error) => {
                console.warn('[AiInteractionsWrapperService] - Error while fetching AI interactions', error);
                return [];
            })
        );
    }

    listenToAiEntityChanges$(
        relatedEntityCollection: AiInteractionRelatedEntityCollection,
        filterFunction?: (aiInteraction: AiInteraction) => boolean
    ): Observable<AiInteraction[]> {
        return this._aiRelatedEntityChanged$.pipe(
            filter(({ relatedEntityId }) => isNotNil(relatedEntityId)),
            switchMap(({ relatedEntityId }) => this.getAiInteractions(relatedEntityId as string, relatedEntityCollection)),
            map((aiInteractions) => {
                const sortedAiInteractions = aiInteractions.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
                return filterFunction ? sortedAiInteractions.filter(filterFunction) : sortedAiInteractions;
            }),
            catchError((error) => {
                console.warn('[AiInteractionsWrapperService] - Error while fetching AI interactions', error);
                return [];
            })
        );
    }

    refreshAiInteractions(): void {
        this._aiInteractionsChanged$.next(true);
    }

    refreshAiRelatedEntity(relatedEntityId: string): void {
        this._aiRelatedEntityChanged$.next({ relatedEntityId });
    }
}
