import { Component, Input } from '@angular/core';

import { MalouSpinnerComponent } from '../spinner/malou-spinner.component';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss'],
    standalone: true,
    imports: [MalouSpinnerComponent],
})
export class SpinnerOverlayComponent {
    @Input() public message: string;
}
