import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Restaurant } from ':shared/models/restaurant';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export interface PersonalizeRestaurantAiSettingsDuplicationData {
    restaurant: Restaurant;
    form: INullableFormGroup<{
        restaurantName: string;
        signature: string;
    }>;
}

const BaseStepPersonalizeRestaurantAiSettings = BaseStepComponent<PersonalizeRestaurantAiSettingsDuplicationData[], {}>;

enum RestaurantAiSettingsDuplicationDisplayedColumns {
    RESTAURANT = 'restaurant',
    RESTAURANT_NAME = 'restaurantName',
    SIGNATURE = 'signature',
}

@Component({
    selector: 'app-personalize-restaurant-ai-settings-duplication',
    templateUrl: './personalize-restaurant-ai-settings-duplication.component.html',
    styleUrls: ['./personalize-restaurant-ai-settings-duplication.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextComponent,
        PaginatorComponent,
        SearchComponent,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalizeRestaurantAiSettingsDuplicationComponent extends BaseStepPersonalizeRestaurantAiSettings implements OnInit {
    private readonly _translateService = inject(TranslateService);

    readonly DEFAULT_PAGINATION_SIZE = 50;

    readonly displayedColumns: RestaurantAiSettingsDuplicationDisplayedColumns[] = Object.values(
        RestaurantAiSettingsDuplicationDisplayedColumns
    );
    readonly restaurantAndAiSettingsDataSource: MatTableDataSource<PersonalizeRestaurantAiSettingsDuplicationData> =
        new MatTableDataSource<PersonalizeRestaurantAiSettingsDuplicationData>([]);

    ngOnInit(): void {
        super.ngOnInit();

        this.restaurantAndAiSettingsDataSource.data = this.inputData;
        this.restaurantAndAiSettingsDataSource.filterPredicate = this._getFilterPredicate;

        this.valid.emit(this._isValid());
    }

    onSearchChange(search: string): void {
        this.restaurantAndAiSettingsDataSource.filter = search.trim().toLowerCase();
    }

    onRestaurantNameInputChange(event: Event, index: number): void {
        const value: string = (event.target as HTMLInputElement).value;
        this.restaurantAndAiSettingsDataSource.filteredData[index]?.form.patchValue({ restaurantName: value });
        this.valid.emit(this._isValid());
    }

    onSignatureInputChange(event: Event, index: number): void {
        const value: string = (event.target as HTMLInputElement).value;
        this.restaurantAndAiSettingsDataSource.filteredData[index]?.form.patchValue({ signature: value });
        this.valid.emit(this._isValid());
    }

    protected _submitData(): PersonalizeRestaurantAiSettingsDuplicationData[] {
        return this.restaurantAndAiSettingsDataSource.data;
    }

    protected _isValid(): boolean {
        return this.restaurantAndAiSettingsDataSource.data.every((restaurantAndPost) => restaurantAndPost.form.valid);
    }

    private _getFilterPredicate = (restaurantAndPost: PersonalizeRestaurantAiSettingsDuplicationData, filter: string): boolean =>
        restaurantAndPost.restaurant.name.trim().toLowerCase().includes(filter) ||
        (!restaurantAndPost.restaurant.isBrandBusiness() &&
            restaurantAndPost.restaurant.fullFormattedAddress?.trim().toLowerCase().includes(filter));
}
