/* eslint-disable @typescript-eslint/naming-convention */
import { EntityConstructor, generateDbId, HashtagType } from '@malou-io/package-utils';

type HashtagProps = EntityConstructor<Omit<Hashtag, 'isCustomerInput' | 'isMain'>> & { isMain?: boolean; isCustomerInput?: boolean };

export class Hashtag {
    id: string;
    text: string;
    isCustomerInput = false;
    isMain = false;
    type: HashtagType;
    createdAt: Date;
    updatedAt: Date;

    constructor(props: HashtagProps) {
        this.id = props.id ?? generateDbId().toHexString();
        this.text = props.text;
        this.isCustomerInput = props.isCustomerInput ?? this.isCustomerInput;
        this.type = props.type;
        this.isMain = props.isMain ?? this.isMain;
        this.createdAt = props.createdAt || new Date();
        this.updatedAt = props.updatedAt || new Date();
    }
}
