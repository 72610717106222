import { Routes } from '@angular/router';

import { RoleGuard } from ':core/auth/role.guard';

import { AdminComponent } from './admin.component';
import { DeveloperSettingsComponent } from './developer-settings/developer-settings.component';
import { NfcComponent } from './nfc/nfc.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PlatformsManagementComponent } from './platforms-management/platforms-management.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { UsersComponent } from './users/users.component';

// import { WatcherComponent } from './watcher/watcher.component';

export const ADMIN_ROUTES: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [RoleGuard],
        data: { roles: ['admin', 'accountManager'] },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'platforms-management',
            },
            {
                path: 'platforms-management',
                component: PlatformsManagementComponent,
                runGuardsAndResolvers: 'always',
            },
            {
                path: 'restaurants',
                component: RestaurantsComponent,
            },
            {
                path: 'users',
                component: UsersComponent,
            },
            {
                path: 'organizations',
                component: OrganizationsComponent,
            },
            {
                path: 'developer-settings',
                component: DeveloperSettingsComponent,
            },
            {
                path: 'nfc',
                component: NfcComponent,
            },
            /* { do not uncomment/delete before Maz comeback
                path: 'watchers',
                component: WatcherComponent,
            }, */
        ],
    },
];
