import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'booleanTranslate',
    standalone: true,
})
export class BooleanTranslatePipe implements PipeTransform {
    constructor(private readonly _translateService: TranslateService) {}

    transform(value: boolean): string {
        return this._translateService.instant(value ? 'common.true' : 'common.false');
    }
}
