import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { CredentialDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { Credential, Platform, Restaurant } from ':shared/models';

@Injectable({ providedIn: 'root' })
export class ConnectMapstrPremiumService {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _platformsService = inject(PlatformsService);
    private readonly _credentialsService = inject(CredentialsService);
    private readonly _store = inject(Store);

    execute(platform: Platform | undefined, apiToken: string): Observable<Platform> {
        const restaurant = this._restaurantsService.currentRestaurant;
        const credential = platform?.credentials?.[0] as Credential | undefined;
        const platformToUpsert = {
            key: PlatformKey.MAPSTR,
            restaurantId: restaurant.id,
            socialId: restaurant.placeId,
        };
        const user$ = this._store.select(selectUserInfos).pipe(take(1));
        return this._platformsService.upsert(restaurant._id, platformToUpsert).pipe(
            map((apiResult) => apiResult.data),
            switchMap((updatedPlatform) => forkJoin([of(updatedPlatform), user$])),
            switchMap(([updatedPlatform, user]: [Platform, User]) =>
                forkJoin([of(updatedPlatform), this._createOrUpdateCredential(credential?._id, user, apiToken, restaurant)])
            ),
            switchMap(([updatedPlatform, updatedCredential]: [Platform, CredentialDto]) =>
                forkJoin([of(updatedPlatform), this._platformsService.attachCredential(updatedPlatform._id, updatedCredential.id)])
            ),
            map(([updatedPlatform, _]) => updatedPlatform)
        );
    }

    private _createOrUpdateCredential(
        existingCredentialId: string | undefined,
        user: User,
        apiToken: string,
        restaurant: Restaurant
    ): Observable<CredentialDto | null> {
        if (!restaurant.placeId) {
            return of(null);
        }
        const credentialToUpsert = {
            key: PlatformKey.MAPSTR,
            authId: restaurant.placeId,
            userId: user._id,
            accessToken: apiToken,
        };
        if (existingCredentialId) {
            return this._credentialsService.patch(existingCredentialId, credentialToUpsert).pipe(map((apiResult) => apiResult.data));
        } else {
            return this._credentialsService.create(credentialToUpsert).pipe(map((apiResult) => apiResult.data));
        }
    }
}
