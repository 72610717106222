<div class="flex h-12 w-full items-center gap-3 rounded border border-malou-color-background-dark bg-white p-2 pr-4">
    <div class="flex h-9 w-11 items-center justify-center rounded bg-malou-color-background-darker">
        <img class="m-3 h-4" [src]="item().emoji | roiPerformanceScorePathResolver" />
    </div>
    <div class="malou-text-12 flex flex-1 text-malou-color-text-1">
        <span class="font-semibold">{{ item().title }}</span>
        @if (item().subtitle) {
            <span class="ml-1 italic">{{ item().subtitle }}</span>
        }
    </div>
    <div class="flex flex-col items-end gap-1">
        <span class="malou-text-12--bold text-malou-color-text-1">{{ item().value | shortNumber }}{{ item().unit }}</span>
        <div class="malou-text-8--regular flex text-malou-color-text-2">
            <img class="mr-1 h-3" [src]="'goal' | roiPerformanceScorePathResolver" />
            @if (item().goal) {
                <span class="mt-[2px]">{{ item().goal | shortNumber }}{{ item().goalUnit }}</span>
            } @else {
                <span class="mt-[2px]">-</span>
            }
        </div>
    </div>
</div>
