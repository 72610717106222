import { animate, state, style, transition, trigger } from '@angular/animations';

export namespace appearDisappearFromBottomAnimation {
    export const openState = 'open';
    export const closedState = 'closed';
    export const animation = trigger('appearDisappearFromBottomAnimation', [
        state(openState, style({ transform: 'translateY(0%)' })),
        state(closedState, style({ transform: 'translateY(100%)' })),
        transition(`${openState} => ${closedState}, ${closedState} => ${openState}`, animate('300ms ease-in')),
    ]);
}
