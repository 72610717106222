import { NgClass, NgStyle, NgTemplateOutlet, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClipboardService } from 'ngx-clipboard';

import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { PostWithInsights } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

interface PostAndHoveredPosts {
    post: PostWithInsights;
    hoveredPosts: { url: string }[];
}

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostMediaComponent,
        ImagePathResolverPipe,
        PercentPipe,
        PlatformLogoComponent,
        ShortNumberPipe,
        ShortTextPipe,
        ApplyPurePipe,
        ApplySelfPurePipe,
    ],
    providers: [ShortTextPipe],
})
export class PostComponent implements OnInit, OnDestroy {
    @Input() post: PostWithInsights;
    @Input() hoveredPosts: { url: string }[] = [];
    @Input() labelInsight: string;
    @Input() metricKey: string;
    @Input() displaySaveIcon = false;
    @Input() isSaved: boolean;
    @Input() width = '300px';
    @Output() save: EventEmitter<PostAndHoveredPosts> = new EventEmitter<PostAndHoveredPosts>();

    readonly SvgIcon = SvgIcon;
    readonly trackByUrlFn = TrackByFunctionFactory.get('url');

    showHover = false;
    seeMore = false;
    hoveredModalLeftOffsetInPx = '';
    readonly MAX_CAPTION_DISPLAY_LENGTH = 150;

    constructor(
        private readonly _clipBoardService: ClipboardService,
        private readonly _shortTextPipe: ShortTextPipe
    ) {}

    ngOnInit(): void {
        this._initCloseModalOnClick();
    }

    goToLink(url: string): void {
        window.open(url, '_blank');
    }

    copy(caption: string): void {
        this._clipBoardService.copyFromContent(caption);
    }

    captionNeedToBeShortened(txt: string): boolean {
        if (txt) {
            return this._shortTextPipe.transform(txt, 70)?.length !== txt?.length;
        }
        return false;
    }

    savePost(post: PostWithInsights, hoveredPosts: { url: string }[]): void {
        hoveredPosts = hoveredPosts.filter((hoveredPost) => hoveredPost.url?.length);
        this.save?.emit({ post, hoveredPosts });
    }

    hovered(event): void {
        this.hoveredModalLeftOffsetInPx = window.innerWidth - event.target.getBoundingClientRect().right > 270 ? '50px' : '-65px';

        this.showHover = true;
    }

    ngOnDestroy(): void {
        document?.removeEventListener('click', () => {});
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (evt) => {
            const target = <HTMLElement>evt.target;
            if (!target.closest(`#hoveredPostsDiv-${this.post.socialId}`) && !target.closest(`#hoverPostsDiv-${this.post.socialId}`)) {
                this.showHover = false;
            }
        });
    }
}
