import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { MalouSpinnerComponent } from '../spinner/spinner/malou-spinner.component';

const REDIRECTION_PAGE_TIME = 2000;

@Component({
    selector: 'app-reviews-copy-clipboard',
    standalone: true,
    imports: [IllustrationPathResolverPipe, TranslateModule, MalouSpinnerComponent],
    templateUrl: './reviews-copy-clipboard.component.html',
    styleUrl: './reviews-copy-clipboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsCopyClipboardComponent implements OnInit {
    readonly Illustration = Illustration;
    private readonly _router = inject(ActivatedRoute);

    ngOnInit(): void {
        const redirectUrl = this._router.snapshot.queryParams.redirectUrl;

        if (redirectUrl) {
            setTimeout(() => {
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
            }, REDIRECTION_PAGE_TIME);
        }
    }
}
