import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AiPostGenerationEmojiStatus, AiPostSettingsLength, AiPostSettingsTone } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import {
    EditAIPostSettingsModalComponent,
    EditRestaurantAiSettingsModalInputData,
} from ':modules/ai-settings/ai-post-settings/edit-ai-post-settings-modal/edit-ai-post-settings-modal.component';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Keyword } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-ai-post-settings',
    standalone: true,
    templateUrl: './ai-post-settings.component.html',
    styleUrl: './ai-post-settings.component.scss',
    imports: [NgTemplateOutlet, MatButtonModule, MatIconModule, TranslateModule, SkeletonComponent, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPostSettingsComponent {
    readonly aiSettingsContext = inject(AiSettingsContext);

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;

    readonly selectedRestaurantKeywords = signal<Keyword[]>([]);

    readonly showDetails = signal(false);
    readonly isLoading = signal(false);

    readonly DEFAULT_DISPLAY = '-';

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;

    readonly denominationDisplay = computed(() => {
        const settings = this.aiSettingsContext.restaurantAiSettings()!;

        if (settings.postSettings.seo.denomination === settings.postSettings.social.denomination) {
            return this._enumTranslatePipe.transform(settings.postSettings.seo.denomination, 'reply_tone');
        }
        return `${this._enumTranslatePipe.transform(settings.postSettings.social.denomination, 'reply_tone')}, ${this._enumTranslatePipe.transform(settings.postSettings.seo.denomination, 'reply_tone')}`;
    });

    readonly lengthDisplay = computed(() => {
        const settings = this.aiSettingsContext.restaurantAiSettings()!;

        if (settings.postSettings.seo.length === settings.postSettings.social.length) {
            return this.lengthDisplayWithEnum(settings.postSettings.seo.length);
        }
        // eslint-disable-next-line max-len
        return `${this.lengthDisplayWithEnum(settings.postSettings.social.length)}, ${this.lengthDisplayWithEnum(settings.postSettings.seo.length)}`;
    });

    readonly emojiDisplay = computed(() => {
        const settings = this.aiSettingsContext.restaurantAiSettings()!;

        if (settings.postSettings.seo.emoji === settings.postSettings.social.emoji) {
            return this.emojiDisplayWithEnum(settings.postSettings.seo.emoji);
        }
        // eslint-disable-next-line max-len
        return `${this.emojiDisplayWithEnum(settings.postSettings.social.emoji)}, ${this.emojiDisplayWithEnum(settings.postSettings.seo.emoji)}`;
    });

    readonly toneDisplay = computed(() => {
        const settings = this.aiSettingsContext.restaurantAiSettings()!;

        return settings.postSettings.seo.tone
            .concat(settings.postSettings.social.tone.filter((tone) => !settings.postSettings.seo.tone.includes(tone)))
            .map((tone) => this.toneDisplayWithEnum(tone))
            .join(', ');
    });

    toggleShowDetails(): void {
        this.showDetails.update((currentShowDetails) => !currentShowDetails);
    }

    openRestaurantAiSettingsModal(): void {
        const restaurantAiSettings = this.aiSettingsContext.restaurantAiSettings();
        if (!restaurantAiSettings) {
            return;
        }

        const data: EditRestaurantAiSettingsModalInputData = {
            restaurantAiSettings,
            selectedKeywords: this.selectedRestaurantKeywords(),
        };

        this._customDialogService
            .open<EditAIPostSettingsModalComponent, EditRestaurantAiSettingsModalInputData>(EditAIPostSettingsModalComponent, {
                height: 'unset',
                maxHeight: '90vh',
                width: '950px',
                maxWidth: '80vw',
                data,
            })
            .afterClosed();
    }

    emojiDisplayWithEnum = (value: AiPostGenerationEmojiStatus): string =>
        this._translateService.instant(`enums.ai_post_settings_emoji.${value}`);
    lengthDisplayWithEnum = (value: AiPostSettingsLength): string =>
        this._translateService.instant(`enums.ai_post_settings_length.${value}`);
    toneDisplayWithEnum = (value: AiPostSettingsTone): string => this._translateService.instant(`enums.ai_post_settings_tone.${value}`);
}
