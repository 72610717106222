import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from ':environments/environment';
import { ApiResult, Label } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class LabelsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/labels`;

    constructor(private readonly _http: HttpClient) {}

    create(label: Label): Observable<ApiResult<Label>> {
        return this._http.post<ApiResult<Label>>(`${this.API_BASE_URL}/`, label, { withCredentials: true });
    }

    index(): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}`).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((t) => new Label(t));
                return res;
            })
        );
    }

    getLabelsByRestaurantId(restaurantId: string): Observable<ApiResult<Label[]>> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}`);
    }

    show(id: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${id}`).pipe(
            map((res: ApiResult) => {
                res.data = new Label(res.data);
                return res;
            })
        );
    }

    update(id: string, params: any): Observable<ApiResult> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}/${id}`, params);
    }

    deleteLabel(id: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${id}`);
    }
}
