import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from ':environments/environment';
import { ApiResult } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class FacebookService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/facebook`;
    constructor(private readonly _http: HttpClient) {}

    igMedia(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/ig/restaurants/${restaurantId}/media`);
    }
}
