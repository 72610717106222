export enum SocialPostsListFilter {
    ALL = 'all',
    DRAFT = 'draft',
    ERROR = 'error',
    FEEDBACK = 'feedback',
}

export enum CallToActionType {
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    SHOP = 'SHOP',
    LEARN_MORE = 'LEARN_MORE',
    SIGN_UP = 'SIGN_UP',
    CALL = 'CALL',
    NONE = 'NONE',
}
