<div class="malou-dialog malou-dialog-mobile !max-w-[100vw]">
    <div class="malou-dialog__header">
        <span>
            {{ 'keywords.update_modal.title' | translate }}
        </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body">
        <div class="grow">
            <app-input-text
                [inputType]="'number'"
                [required]="true"
                [title]="'keywords.update_modal.input_title' | translate"
                [formControl]="newVolume"
                [defaultValue]="'' + keyword.volume"></app-input-text>
        </div>
    </div>
    <div class="malou-dialog__footer sm:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button [disabled]="!newVolume.valid" (click)="save()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
