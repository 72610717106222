import { ChangeDetectionStrategy, Component, effect, input, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SelectComponent } from ':shared/components/select/select.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-platforms-connection-account-selector',
    templateUrl: './platforms-connection-account-selector.component.html',
    styleUrls: ['./platforms-connection-account-selector.component.scss'],
    standalone: true,
    imports: [TranslateModule, SelectComponent, MatButtonModule, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsConnectionAccountSelectorComponent<T> {
    readonly SvgIcon = SvgIcon;
    readonly title = input<string>('');
    readonly selectedValue = input.required<T | null>();
    readonly selectableValues = input.required<T[]>();
    readonly displayWith = input.required<(value: T) => string>();
    readonly showButton = input.required<boolean>();
    readonly disabled = input<boolean>(false);

    readonly selectionChange = output<T>();
    readonly buttonClick = output<void>();

    readonly formControl = new FormControl<T | null>(null);

    constructor() {
        effect(() => {
            this.formControl.setValue(this.selectedValue());
        });

        effect(() => {
            if (this.disabled()) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }
        });
    }
}
