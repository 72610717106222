import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appTextareaAutosize]',
    standalone: true,
})
export class TextareaAutosizeDirective {
    @Input() minRows = 5;
    @Input() maxRows = 15;
    @Input() activateResize = true;

    private _input: HTMLTextAreaElement;
    private _offsetHeight = 0;
    private readonly _avgLineHeight = 20;

    constructor(private readonly _element: ElementRef) {
        this._input = this._element.nativeElement;
    }

    @HostListener('input')
    onInput(): void {
        if (!this.activateResize) {
            return;
        }
        if (this._offsetHeight <= 0) {
            this._offsetHeight = this._input.scrollHeight;
        }
        const scrollHeight = this._input.scrollHeight;
        const offsetHeight = this._offsetHeight;
        const rows = Math.floor((scrollHeight - offsetHeight) / this._avgLineHeight);
        const rowsCount = this.minRows + rows;
        this._input.rows = Math.min(rowsCount, this.maxRows);
    }
}
