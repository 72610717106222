import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { PlatformKey } from '@malou-io/package-utils';

import { Message } from './message';

export interface Conversation {
    _id: string;
    restaurantId: string;
    key: PlatformKey;
    socialConversationId: string;
    userInfo: {
        displayName: string;
        userDeviceLocale: string;
        userSocialId?: string;
        profilePictureUrl?: string;
    };
    status: 'READ' | 'UNREAD';
    archived: boolean;
    favorite: boolean;
    latestMessageAt: string;
    context?: {
        entryPoint: string;
        resolvedLocale: string;
    };
    agent?: string;
}

export class ConversationWithMessages {
    conversation: Conversation;
    messages: Partial<Message>[];
    typedText?: string;

    public constructor(init: Partial<ConversationWithMessages>) {
        Object.assign(this, init);
    }

    getLastMessage(): Message {
        return new Message(this.messages[this.messages?.length - 1]);
    }

    isLastMessageDirectText(): boolean {
        const lastMessage = this.getLastMessage();
        return !lastMessage?.attachments?.length && !lastMessage?.story && !lastMessage.isUnsupportedAttachment;
    }

    getLastMessageHtml(translate: TranslateService): string | SafeHtml {
        const lastMessage = this.getLastMessage();
        if (lastMessage?.attachments?.length) {
            switch (lastMessage?.attachments[0].type) {
                case 'video':
                    return lastMessage.isFromRestaurant
                        ? translate.instant('messages.conversation_card.last_message_is_video_restaurant')
                        : translate.instant('messages.conversation_card.last_message_is_video');
                case 'image':
                default:
                    return lastMessage.isFromRestaurant
                        ? translate.instant('messages.conversation_card.last_message_is_image_restaurant')
                        : translate.instant('messages.conversation_card.last_message_is_image');
            }
        }
        if (lastMessage?.story) {
            switch (lastMessage?.story?.storyType) {
                case 'mention':
                    return lastMessage.isFromRestaurant
                        ? translate.instant('messages.story_mention')
                        : translate.instant('messages.story_mention_external');
                case 'reply_to':
                default:
                    return lastMessage.isFromRestaurant
                        ? translate.instant('messages.story_reply_to')
                        : translate.instant('messages.story_reply_to_external');
            }
        } else if (lastMessage?.isUnsupportedAttachment) {
            return translate.instant('messages.attachment_not_supported');
        } else if (lastMessage?.isDeleted) {
            return lastMessage.isFromRestaurant
                ? translate.instant('messages.message_deleted_from_you')
                : translate.instant('messages.message_deleted');
        }
        return lastMessage.getMessageTextHtml();
    }

    getConversationSenderName(): string {
        return this.conversation?.userInfo?.displayName;
    }

    getConversationSenderAvatar(): string {
        return this.conversation?.userInfo?.profilePictureUrl ?? '';
    }

    getConversationStatus(): string {
        return this.conversation.status;
    }

    getTimeSinceNewMessage(): string | undefined {
        return DateTime.fromISO(this.conversation.latestMessageAt).toRelative({ style: 'short' })?.toString();
    }
}

export interface ConversationWithMessage {
    conversation: Conversation;
    message: Message;
}

export enum ConversationType {
    GENERAL = 'general',
    FAVORITE = 'favorite',
    ARCHIVED = 'archived',
}
