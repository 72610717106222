import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { phoneRegex } from '@malou-io/package-utils';

import { mapsterPostCaptionTextLimit, postCaptionTextLimit } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { NewPostModalAiContext } from ':modules/posts/context/new-post-modal-ai.context';
import { NewPostModalContext } from ':modules/posts/context/new-post-modal.context';
import { AiGenerationActionsComponent } from ':shared/components/ai-generation-actions/ai-generation-actions.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { InteractionsBrowserComponent } from ':shared/components/interactions-browser/interactions-browser.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { Interaction } from ':shared/models/interaction';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-post-caption',
    templateUrl: './post-caption.component.html',
    styleUrls: ['./post-caption.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TextAreaComponent,
        MatIconModule,
        TranslateModule,
        MatTooltipModule,
        InfiniteTextSlideComponent,
        InteractionsBrowserComponent,
        AiGenerationActionsComponent,
        MatButtonModule,
        NgClass,
        NgTemplateOutlet,
        ApplyPurePipe,
        ClickOutsideDirective,
    ],
})
export class PostCaptionComponent {
    readonly postDescriptionFormControl = input.required<FormControl>();
    readonly textAreaId = input.required<string>();

    readonly _screenSizeService = inject(ScreenSizeService);
    readonly _translate = inject(TranslateService);
    readonly newPostModalContext = inject(NewPostModalContext);
    readonly newPostModalAiContext = inject(NewPostModalAiContext);

    readonly SvgIcon = SvgIcon;
    readonly resetAiInteractionsHistory = signal(false);
    readonly selectedText = signal('');
    readonly interactions: WritableSignal<Interaction[]> = signal([]);
    readonly loadingAnimationDefaultText = signal(
        this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    readonly currentInteraction = signal<Interaction | undefined>(undefined);
    readonly aiPromptButtonTooltip = signal('');

    readonly MAPSTR_POST_TEXT_LIMIT = mapsterPostCaptionTextLimit;
    readonly POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    onDescriptionChange(_event: InputEvent): void {
        this.resetAiInteractionsHistory.update((value) => !value);
    }

    onSelectionChange(selectedText: string): void {
        this.selectedText.update(() => selectedText);
    }

    onInteractionChanged(interaction: Interaction): void {
        const currentFormText = this.postDescriptionFormControl().value.trim();
        this._updateInteractionsList({
            id: uuidv4(),
            text: currentFormText,
            isAiInteraction: false,
            originalInteractionId: this.currentInteraction()?.isAiInteraction
                ? this.currentInteraction()?.id
                : this.currentInteraction()?.originalInteractionId || undefined,
        });

        this.postDescriptionFormControl().setValue(interaction.text);
        this.currentInteraction.update(() => interaction);
    }

    onWriteWithAiClick(): void {
        setTimeout(() => document.getElementById('openaiPromptTextarea')?.focus(), 500);
    }

    handleClickOutside(target: Element): void {
        if (!target?.closest('#aiBtnInsideTextarea')) {
            this.newPostModalAiContext.closePostCaptionAiGeneration();
        }
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.post_subject'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.usual_tone'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.emojis'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.results_criteria'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translate.instant('common.langs.en').toLowerCase(),
                    this._translate.instant('common.langs.es').toLowerCase(),
                    this._translate.instant('common.langs.it').toLowerCase(),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }

    private _updateInteractionsList(interaction: Interaction): void {
        const interactions = this.interactions();
        const update = Interaction.getUpdatedInteractions(interactions, interaction);
        if (!update) {
            return;
        }
        this.interactions.set(update);
    }

    get containPhoneNumberError(): boolean {
        return this.postDescriptionFormControl()?.value?.search(phoneRegex) !== -1;
    }

    getPostCaptionErrorMessages(
        textLength: number | undefined,
        captionTextLimit: number,
        containPhoneNumberError: boolean,
        hasTextError: boolean
    ): string | undefined {
        if (textLength && textLength > captionTextLimit) {
            return 'posts.new_post.post_caption_text_limit';
        } else if (containPhoneNumberError) {
            return 'posts.new_post.post_caption_contains_phone_number';
        } else if (hasTextError) {
            return 'common.required_field';
        }
        return '';
    }
}
