import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';

import * as SidenavActions from './sidenav.actions';

@Injectable()
export class SidenavEffects {
    readonly initIsOpenedState$ = createEffect(() =>
        this._actions$.pipe(
            ofType(SidenavActions.initState),
            map(() => {
                const isPhoneScreen = this._screenSizeService.isPhoneScreen;
                if (isPhoneScreen) {
                    return SidenavActions.close();
                }
                return SidenavActions.open();
            })
        )
    );

    readonly initOwnRestaurantsState$ = createEffect(() =>
        this._actions$.pipe(
            ofType(SidenavActions.initState),
            switchMap(() => this._restaurantsService.getUserRestaurantsForSidenav()),
            map((res) => SidenavActions.setOwnRestaurants({ ownRestaurants: res }))
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService
    ) {
        this._onResizeSideEffect();
    }
    /**
     * Qol when switching from desktop to mobile resolution and vice versa
     */
    private _onResizeSideEffect(): void {
        this._screenSizeService.isPhoneScreen$.pipe(debounceTime(0), distinctUntilChanged()).subscribe((isPhoneScreen) => {
            if (isPhoneScreen) {
                this._store.dispatch(SidenavActions.close());
            } else {
                this._store.dispatch(SidenavActions.open());
            }
        });
    }
}
