import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

import { ChecklistService } from ':core/services/checklist.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import { UpdateAggregatedRoiSettingsModalComponent } from ':modules/aggregated-statistics/roi/update-aggregated-roi-settings-modal/update-aggregated-roi-settings-modal.component';
import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { RestaurantSetupModalComponent } from ':modules/roi/restaurant-setup-modal/restaurant-setup-modal.component';
import { RoiContext } from ':modules/roi/roi.context';
import { getShouldShowRoiSettingsModal } from ':modules/roi/utils/get-should-show-roi-settings-modal';
import { ChecklistComponent } from ':shared/components/checklist/checklist.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { Checklist } from ':shared/models/checklist';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-restaurant-container',
    standalone: true,
    imports: [RouterOutlet, ChecklistComponent, AsyncPipe],
    templateUrl: './restaurant-container.component.html',
    // Warning, putting changeDetection: ChangeDetectionStrategy.OnPush here has introduced a bug in displaying multiple views.
    // Please investigate and fix before re-enabling.
    styleUrl: './restaurant-container.component.scss',
})
export class RestaurantContainerComponent {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _screenService = inject(ScreenSizeService);
    private readonly _roiContext = inject(RoiContext);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _checklistService = inject(ChecklistService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _router = inject(Router);

    readonly checklist$ = this._restaurantsService.restaurantSelected$.pipe(
        filter((restaurant) => !!restaurant?._id),
        switchMap((restaurant) => this._checklistService.getChecklistForRestaurant(restaurant!._id)),
        map(({ data: checklistDto }) => (checklistDto ? new Checklist(checklistDto) : null))
    );
    readonly isChecklistFeatureEnabled$ = this._experimentationService.isFeatureEnabled$('experiment-checklist');

    constructor() {
        this._showRoiSettingsModalIfMissingInformation();
    }

    private _showRoiSettingsModalIfMissingInformation(): void {
        this._roiNotificationsContext.restaurantsWithFetchedRoiSettings$.subscribe(({ restaurants }) => {
            this._openRoiSettingsUpdateModal(restaurants);
        });
    }

    private _openRoiSettingsUpdateModal(restaurants: Restaurant[]): void {
        const restaurantWithoutRoiSettings = restaurants.filter((restaurant) =>
            this._roiContext.restaurantsIdsWithoutRoiSettings().includes(restaurant._id)
        );

        const shouldForceShowModal = this._activatedRoute.snapshot.queryParams.showRoiPopup === 'true';
        if (shouldForceShowModal) {
            this._router.navigate(['./']);
        }

        const shouldShowModal = getShouldShowRoiSettingsModal(this._roiContext.getLatestCreatedRestaurantsWithoutRoiSettings(restaurants));

        if (shouldShowModal || shouldForceShowModal) {
            LocalStorage.setItem(LocalStorageKey.IS_ROI_SETTINGS_POPUP_SHOWN, 'true');
            if (restaurantWithoutRoiSettings.length > 1) {
                this._openUpdateAggregatedRoiSettingsModal();
            } else {
                this._openRestaurantSetupModal();
            }
        }
    }

    private _openUpdateAggregatedRoiSettingsModal(): void {
        this._customDialogService
            .open(UpdateAggregatedRoiSettingsModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                maxWidth: '600px',
                closeOnNavigation: false,
                autoFocus: false,
                data: {
                    isFromNotification: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.needsToBeUpdated) {
                    this._roiNotificationsContext.emitReload();
                }
            });
    }

    private _openRestaurantSetupModal(): void {
        this._customDialogService
            .open(RestaurantSetupModalComponent, {
                panelClass: this._screenService.isPhoneScreen ? 'malou-dialog-panel--full' : 'malou-dialog-panel--fit-content',
                maxWidth: '600px',
                closeOnNavigation: false,
                autoFocus: false,
                data: {
                    restaurantId: this._roiContext.restaurantsIdsWithoutRoiSettings()[0],
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.needsToBeUpdated) {
                    this._roiNotificationsContext.emitReload();
                }
            });
    }
}
