import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { RoiAdditionalCustomersRow } from ':shared/models/roi-additional-customers.model';
import { RoiCustomersPathResolver } from ':shared/pipes/roi-customers-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-estimated-customer-panel',
    templateUrl: './estimated-customer-panel.component.html',
    styleUrls: ['./estimated-customer-panel.component.scss'],
    standalone: true,
    imports: [NumberEvolutionComponent, ShortNumberPipe, TranslateModule, RoiCustomersPathResolver],
})
export class EstimatedCustomerPanelComponent {
    panelTitle = input.required<string>();
    explanation = input.required<string>();
    currentCustomerRange = input.required<number[]>();
    customerItems = input.required<RoiAdditionalCustomersRow[]>();
    previousCustomersEvolution = input.required<number>();
}
