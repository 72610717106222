import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR, DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

const REDIRECTION_PAGE_TIME = 3000; // 3sec

@Component({
    selector: 'app-wheel-of-fortune-redirect',
    templateUrl: './wheel-of-fortune-redirect.component.html',
    styleUrls: ['./wheel-of-fortune-redirect.component.scss'],
    standalone: true,
    imports: [NgStyle, MatIconModule, MalouSpinnerComponent, TranslateModule, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneRedirectComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;

    primaryColor: WritableSignal<string> = signal(DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR);
    secondaryColor: WritableSignal<string> = signal(DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR);
    redirectionPlatform: WritableSignal<PlatformKey> = signal(PlatformKey.GMB);
    redirectionLink: WritableSignal<string> = signal('');

    constructor(private readonly _route: ActivatedRoute) {
        this.primaryColor.set(this._route.snapshot.queryParams.primaryColor);
        this.secondaryColor.set(this._route.snapshot.queryParams.secondaryColor);
        this.redirectionPlatform.set(this._route.snapshot.queryParams.redirectionPlatform as PlatformKey);
        this.redirectionLink.set(this._route.snapshot.queryParams.redirectionLink);
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.redirectionLink()) {
                window.location.href = this.redirectionLink();
            }
        }, REDIRECTION_PAGE_TIME);
    }
}
