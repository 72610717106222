import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ReviewReplyAutomationMethod, ReviewReplyAutomationRatingCategory } from '@malou-io/package-utils';

import { ReviewReplyAutomation } from ':shared/models/automations';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

enum AutomationState {
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    PARTIALLY_ENABLED = 'PARTIALLY_ENABLED',
}

@Component({
    selector: 'app-automation-card',
    templateUrl: './automation-card.component.html',
    standalone: true,
    imports: [NgClass, MatButtonModule, MatIconModule, ApplyPurePipe, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationCardComponent {
    readonly SvgIcon = SvgIcon;
    private readonly _translateService = inject(TranslateService);
    readonly title = input.required<string>();
    readonly automations = input.required<ReviewReplyAutomation[]>();
    readonly onClick = output<void>();

    readonly getAutomationStateClassColor = computed(() => {
        switch (this._automationState()) {
            case AutomationState.DISABLED:
                return 'malou-chip--red';
            case AutomationState.ENABLED:
                return 'malou-chip--success-light text-malou-color-text-green';
            case AutomationState.PARTIALLY_ENABLED:
                return 'malou-chip--success-light text-malou-color-text-green';
        }
    });

    readonly getAutomationStateTitle = computed(() => {
        switch (this._automationState()) {
            case AutomationState.DISABLED:
                return this._translateService.instant('automations.reviewReplies.automation_disabled');
            case AutomationState.ENABLED:
                return this._translateService.instant('automations.reviewReplies.automation_enabled');
            case AutomationState.PARTIALLY_ENABLED:
                return this._translateService.instant('automations.reviewReplies.automation_partially_enabled');
        }
    });

    private readonly _automationState = computed(() => {
        const enabledAutomations = this.automations().filter((automation) => automation.active);
        if (enabledAutomations.length === 0) {
            return AutomationState.DISABLED;
        }
        if (enabledAutomations.length === this.automations().length) {
            return AutomationState.ENABLED;
        }
        return AutomationState.PARTIALLY_ENABLED;
    });

    emitClick(): void {
        this.onClick.emit();
    }

    getRatingCategoryTitle = (ratingCategory: ReviewReplyAutomationRatingCategory): string =>
        this._translateService.instant(`automations.reviewReplies.ratingCategories.${ratingCategory}`);

    getFeatureTitle = (replyMethod: ReviewReplyAutomationMethod): string =>
        this._translateService.instant(`automations.reviewReplies.replyMethod.${replyMethod}`);
}
