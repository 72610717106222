import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { AggregationTimeScale } from '@malou-io/package-utils';

import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { InsightsByPlatform } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = InsightsByPlatform | undefined;

@Injectable({ providedIn: 'root' })
export class PlatformsRatingsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {
        super();
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectPlatformsRatingsData).pipe(take(1));
    }

    protected override _getCsvDataRows(data: Exclude<Data, undefined>): CsvAsStringArrays {
        return Object.entries(data)
            .map(
                ([platformKey, platformRatings]) =>
                    platformRatings[AggregationTimeScale.BY_DAY]?.platform_rating?.map((rating) => {
                        const date = new Date(rating.date).toISOString();
                        const ratingValue = this._shortNumberPipe.transform(rating.value);
                        const platformKeyTranslated = this._enumTranslatePipe.transform(platformKey, 'platform_key');
                        return [date, ratingValue, platformKeyTranslated];
                    }) ?? []
            )
            .flat();
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Rating', 'Platform'];
    }
}
