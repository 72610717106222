import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ScreenSizeService } from ':core/services/screen-size.service';
import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ThreeDigitNumberPipe } from ':shared/pipes/three-digit-number.pipe';

export interface SidenavContentRouteOptions {
    routerLink: string[];
    text: string;
    svgIcon: SvgIcon;
    isSmallSvgIcon?: boolean;
    shouldHideSvgIconOnOpenedSidenav?: boolean;
    secondarySvgIcon?: SvgIcon;
    notificationCount?: number;
    isActiveOverride?: boolean;
    rightIconOnHover?: SvgIcon;
}

export const ROUTER_LINK_ACTIVE_OPTIONS: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored',
};

@Component({
    selector: 'app-sidenav-content-route',
    templateUrl: './sidenav-content-route.component.html',
    standalone: true,
    imports: [MatIconModule, NgClass, ThreeDigitNumberPipe, RouterLink, RouterLinkActive, NgTemplateOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentRouteComponent {
    private readonly _store = inject(Store);
    private readonly _screenSizeService = inject(ScreenSizeService);

    readonly showNotificationCount = input<boolean>(true);
    readonly options = input.required<SidenavContentRouteOptions>();
    readonly isActive = output<boolean>();

    readonly routerLinkActive = viewChild(RouterLinkActive);
    readonly isRouterLinkActive = signal(false);
    readonly isSidenavOpened = toSignal(this._store.select(SidenavSelectors.selectIsOpened), { requireSync: true });
    readonly isActiveComputed = computed(
        () => (this.options().isActiveOverride !== undefined ? this.options().isActiveOverride : this.isRouterLinkActive()) || false
    );
    readonly svgIconClass = computed(() => (this.options().isSmallSvgIcon ? '!h-1.5 !w-1.5' : '!h-4 !w-4'));
    readonly shouldShowIcon = computed(() => !this.options().shouldHideSvgIconOnOpenedSidenav || !this.isSidenavOpened());

    readonly ROUTER_LINK_ACTIVE_OPTIONS = ROUTER_LINK_ACTIVE_OPTIONS;
    private _subscription: Subscription | undefined;
    readonly SvgIcon = SvgIcon;

    constructor() {
        effect(() => {
            this.isActive.emit(this.isActiveComputed());
        });
        effect(
            (onCleanup) => {
                onCleanup(() => {
                    this._subscription?.unsubscribe();
                });
                this._subscription = this.routerLinkActive()?.isActiveChange.subscribe((next) => {
                    this.isRouterLinkActive.set(next);
                });
            },
            { allowSignalWrites: true }
        );
    }

    onClick(): void {
        if (this._screenSizeService.isPhoneScreen) {
            this._store.dispatch(SidenavActions.close());
        }
    }
}
