import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, combineLatest, EMPTY, map, Observable, of, shareReplay, switchMap, take } from 'rxjs';

import { NfcDto, NfcWithRestaurantDto, ScanDto } from '@malou-io/package-dto';
import {
    ApiResultV2,
    changePlatformUrlDomain,
    errorReplacer,
    NfcsPlatformKey,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { NfcService } from ':core/services/nfc.service';
import { ScansService } from ':core/services/scans.service';
import { ScanPrivateReviewComponent } from ':modules/scan/scan-private-review/scan-private-review.component';
import { RateWithStarsComponent } from ':shared/components/rate-with-stars/rate-with-stars.component';
import { Nfc } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-scan',
    templateUrl: './scan.component.html',
    styleUrls: ['./scan.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        MalouSpinnerComponent,
        AsyncPipe,
        IncludesPipe,
        MatIconModule,
        RateWithStarsComponent,
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        ScanPrivateReviewComponent,
        ImagePathResolverPipe,
        ApplyPurePipe,
    ],
})
export class ScanComponent implements OnInit {
    readonly Illustration = Illustration;
    isError = false;
    nfc$: Observable<NfcWithRestaurantDto>;
    showPrivateReviewPage = false;
    starNumber: number;
    scan$: Observable<ScanDto>;
    data$: Observable<{ nfc: NfcWithRestaurantDto; scan: ScanDto }>;
    PlatformKey = PlatformKey;
    isRedirectionInProgress = false;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _scansService: ScansService,
        private readonly _nfcService: NfcService,
        private readonly _translate: TranslateService
    ) {}

    ngOnInit(): void {
        const chipName = this._route.snapshot.queryParams.id;
        const restaurantId = this._route.snapshot.queryParams.restaurantId;
        if (!chipName && !restaurantId) {
            console.error('No chip name or restaurantId provided');
            this.isError = true;
            return;
        }

        this.nfc$ = chipName ? this.getTotem$(chipName) : this.getSticker$(restaurantId);
        this.scan$ = this.createScan$(this.nfc$);
        this.data$ = combineLatest({ nfc: this.nfc$, scan: this.scan$ });

        this.nfc$.subscribe((data) => {
            const nfc = Nfc.fromNfcWithRestaurantDto(data);
            if (
                nfc.isRedirectingToWheelOfFortune() ||
                (nfc.platformKey && PlatformDefinitions.getPlatformKeysNotRatedForTotems().includes(nfc.platformKey))
            ) {
                this.isRedirectionInProgress = true;
                this._patchScan().subscribe(() => {
                    if (nfc.redirectionLink) {
                        window.location.href = this._getUpdatedRedirectionLink({
                            redirectionLink: nfc.redirectionLink,
                            platformKey: nfc.platformKey,
                        });
                    }
                });
            }
        });
    }

    getTotem$(chipName: string): Observable<NfcWithRestaurantDto> {
        return this._nfcService.getNfcByChipname(chipName).pipe(
            map((apiResult: ApiResultV2<NfcWithRestaurantDto>) => apiResult.data),
            switchMap((nfc: NfcWithRestaurantDto) => {
                if (!nfc.active) {
                    console.error('NFC is not active');
                    this.isError = true;
                    return EMPTY;
                }
                if (!nfc.restaurant?.active) {
                    console.error('Restaurant is not active');
                    this.isError = true;
                    return EMPTY;
                }
                return of(nfc);
            }),
            catchError((e) => {
                console.error('Error when getting NFC', JSON.stringify(e, errorReplacer));
                this.isError = true;
                return EMPTY;
            }),
            shareReplay(1)
        );
    }

    getSticker$(restaurantId: string): Observable<NfcWithRestaurantDto> {
        return this._nfcService.getStickerByRestaurantId(restaurantId).pipe(
            map((apiResult: ApiResultV2<NfcWithRestaurantDto>) => apiResult.data),
            switchMap((nfc: NfcWithRestaurantDto) => {
                if (!nfc.active) {
                    console.error('NFC is not active');
                    this.isError = true;
                    return EMPTY;
                }
                if (!nfc.restaurant?.active) {
                    console.error('Restaurant is not active');
                    this.isError = true;
                    return EMPTY;
                }
                return of(nfc);
            }),
            catchError((e) => {
                console.error('Error when getting NFC', JSON.stringify(e, errorReplacer));
                this.isError = true;
                return EMPTY;
            }),
            shareReplay(1)
        );
    }

    createScan$(nfc$: Observable<NfcDto>): Observable<ScanDto> {
        return nfc$.pipe(
            switchMap((nfc) =>
                this._scansService.create({
                    nfcId: nfc.id,
                    scannedAt: new Date().toISOString(),
                    nfcSnapshot: nfc,
                })
            ),
            map((apiResult) => apiResult.data),
            catchError((e) => {
                console.error('Error when creating Scan', JSON.stringify(e, errorReplacer));
                this.isError = true;
                return EMPTY;
            }),
            shareReplay(1)
        );
    }

    onStarClicked(starNumber: number): void {
        this.starNumber = starNumber;
        this._patchScan(starNumber).subscribe(() => {
            this.nfc$.pipe(take(1)).subscribe((nfc) => {
                if (nfc.starsRedirected?.includes(starNumber)) {
                    if (!nfc.redirectionLink) {
                        console.error('NFC has no redirection link');
                        this.isError = true;
                        return;
                    }

                    window.location.href = this._getUpdatedRedirectionLink({
                        redirectionLink: nfc.redirectionLink,
                        platformKey: nfc.platformKey,
                    });
                } else {
                    this.showPrivateReviewPage = true;
                }
            });
        });
    }

    getTemplateTitle = (restaurant: NfcWithRestaurantDto['restaurant']): string =>
        restaurant?.totemDisplayName?.title
            ? this._translate.instant('scan.default_title', { restaurantName: restaurant?.totemDisplayName?.title })
            : this._translate.instant('scan.title', { restaurantName: restaurant?.name });

    getTemplateDescription = (restaurant: NfcWithRestaurantDto['restaurant']): string =>
        restaurant?.totemDisplayName?.text
            ? this._translate.instant('scan.default_description', { restaurantName: restaurant?.totemDisplayName?.text })
            : this._translate.instant('scan.description', { restaurantName: restaurant?.name });

    private _getUpdatedRedirectionLink({
        redirectionLink,
        platformKey,
    }: {
        redirectionLink: string;
        platformKey: NfcsPlatformKey;
    }): string {
        if (!redirectionLink) {
            console.error('No redirection link provided');
            return '';
        }

        const userLanguage = (
            navigator as {
                userLanguage?: string;
            }
        ).userLanguage;
        const langs = [...(navigator.languages ? navigator.languages : []), ...(userLanguage ? [userLanguage] : [])];

        return changePlatformUrlDomain(redirectionLink, platformKey as PlatformKey, langs);
    }

    private _patchScan(starNumber?: number): Observable<ScanDto> {
        return this.scan$.pipe(take(1)).pipe(
            switchMap((scan) =>
                this._scansService
                    .patch(scan.id, {
                        redirectedAt: new Date().toISOString(),
                        starClicked: starNumber,
                    })
                    .pipe(
                        map((apiResult) => apiResult.data),
                        catchError((e) => {
                            console.error('Error when updating scan', JSON.stringify(e, errorReplacer));
                            this.isError = true;
                            return EMPTY;
                        })
                    )
            )
        );
    }
}
