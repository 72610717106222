import { CaslRole, WheelOfFortuneRedirectionPlatformKey } from '../../constants';
import { GiftClaimStartDateOption, NextDrawEnabledDelay } from './wheels-of-fortune.interface';

export const DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR = '#8247E4';
export const DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR = '#AC32B7';
export const DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_START_DATE_OPTION = GiftClaimStartDateOption.TOMORROW;
export const DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_DURATION_IN_DAYS = 60;

export const WHEEL_OF_FORTUNE_NEEDED_ROLES = [CaslRole.ADMIN, CaslRole.OWNER, CaslRole.EDITOR];

export const DEFAULT_WHEEL_OF_FORTUNE_REDIRECTION_SETTINGS = {
    nextDrawEnabledDelay: NextDrawEnabledDelay.NEVER,
    shouldRedirect: true,
    platforms: [{ order: 0, platformKey: WheelOfFortuneRedirectionPlatformKey.GMB }],
};

export const GIFT_NAME_MAX_LENGTH = 40;
