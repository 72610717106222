import { Pipe, PipeTransform } from '@angular/core';

type Comparator = (a: any, b: any) => boolean;

@Pipe({
    name: 'includes',
    standalone: true,
})
export class IncludesPipe implements PipeTransform {
    transform(array: any[], value: any, comparator?: Comparator): boolean {
        if (!comparator) {
            return array.includes(value);
        }
        return array.some((arrayValue: any) => comparator(value, arrayValue));
    }
}
