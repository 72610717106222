import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface Heap {
    track: (event: string, properties?: Object) => void;
    identify: (identity: string) => void;
    resetIdentity: () => void;
    addUserProperties: (properties: Object) => void;
    addEventProperties: (properties: Object) => void;
    removeEventProperty: (property: string) => void;
    clearEventProperties: () => void;
    appid: string;
    userId: string;
    identity: string | null;
}

declare const heap: Heap;

@Injectable({
    providedIn: 'root',
})
export class HeapService {
    willTrack = environment.environment?.includes('production');

    addEventProperties(properties: Object): void {
        if (this.willTrack) {
            heap.clearEventProperties();
            heap?.addEventProperties(properties);
        }
    }

    addUserProperties(properties: Object): void {
        if (this.willTrack) {
            heap?.addUserProperties(properties);
        }
    }

    identify(identity: string): void {
        if (this.willTrack) {
            heap?.identify(identity?.toLowerCase());
        }
    }

    track(event: string, properties?: Record<string, any>): void {
        if (this.willTrack) {
            heap?.track(event, properties);
        }
    }
}
