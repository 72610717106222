import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'canBeEdited',
    standalone: true,
})
export class CanBeEditedPipe implements PipeTransform {
    transform(review: Review | PrivateReview): boolean {
        if (!review) {
            return false;
        }
        return review.canBeEdited();
    }
}
