<div class="malou-border-color-dark flex h-fit w-full flex-col rounded-[5px] border">
    <span class="malou-text-14--bold my-[22px] flex items-center pl-6 text-malou-color-text-1">
        @if (isAboutToSendUpdates()) {
            <mat-icon
                class="mr-4 !h-6 !w-6 rounded bg-malou-color-state-warn bg-opacity-30 p-1 text-malou-color-state-warn"
                [svgIcon]="SvgIcon.CLOCK"></mat-icon>
            {{ 'informations.pending_updates_modal' | translate }}
        } @else {
            <mat-icon
                class="mr-4 !h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                [svgIcon]="SvgIcon.CHECK"></mat-icon>
            {{ 'informations.nb_succeed_updates' | pluralTranslate: nbSucceedUpdates() : { nbUpdates: nbSucceedUpdates() } }}
        }
    </span>
    <mat-table class="malou-mat-table" matSort [dataSource]="otherUpdatesSource" [trackBy]="trackByIdFn" #otherUpdatesSort="matSort">
        <ng-container matColumnDef="platformKey">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                <span> {{ 'informations.platforms' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-small font-semibold !text-malou-color-text-1">
                <div class="flex items-center gap-2">
                    @if (update.socialLink) {
                        <a target="_blank" [href]="update.socialLink">
                            @if (isYextKey | applyPure: update.providerKey) {
                                <app-yext-platform-logo
                                    imgClasses="max-h-8 w-8 mr-10 object-contain"
                                    [yextPublisherId]="update.providerKey"></app-yext-platform-logo>
                            } @else {
                                <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                            }
                        </a>
                    } @else {
                        @if (isYextKey | applyPure: update.providerKey) {
                            <app-yext-platform-logo
                                imgClasses="max-h-8 w-8 mr-10 object-contain"
                                [yextPublisherId]="update.providerKey"></app-yext-platform-logo>
                        } @else {
                            <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                        }
                    }
                    <span class="malou-text-10--semibold text-malou-color-text-1">
                        {{ update.translatedPlatformName }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-large" mat-sort-header>
                <span> {{ 'informations.state' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-large md:ml-4 md:!flex">
                @if (update.isDone()) {
                    <ng-container [ngTemplateOutlet]="done"></ng-container>
                } @else {
                    <ng-container *ngTemplateOutlet="pending; context: { update }"></ng-container>
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge md:!hidden">
                <span> {{ 'informations.details' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let update" class="malou-mat-table-cell-xxlarge !text-malou-color-text-1 md:!hidden">
                <span class="malou-text-10--regular text-malou-color-text-2">{{ update.details }}</span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="otherDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let update; columns: otherDisplayedColumns; let i = index"></mat-row>
    </mat-table>
</div>

<ng-template #done>
    <span
        class="malou-text-10--regular flex items-center rounded-[5px] bg-malou-color-chart-green bg-opacity-20 p-1 pr-2 text-malou-color-state-success">
        <mat-icon class="mr-1 !h-6 !w-6 rounded p-1" [svgIcon]="SvgIcon.CHECK"></mat-icon>
        {{ 'informations.update_done' | translate }}
    </span>
</ng-template>

<ng-template let-update="update" #pending>
    <span
        class="malou-text-10--regular mr-2 flex items-center rounded-[5px] bg-malou-color-state-warn bg-opacity-20 p-1 pr-2 text-malou-color-state-warn">
        <mat-icon class="mr-1 !h-6 !w-6 rounded p-1" [svgIcon]="SvgIcon.CLOCK"></mat-icon>
        {{ 'informations.pending' | translate }}
    </span>

    @if (!isAboutToSendUpdates()) {
        <span class="malou-text-10--regular italic">{{ update.translatedUpdateTime }}</span>
    }
</ng-template>
