<div class="flex w-full flex-col">
    @if (title || subtitle || required) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if (required) {
                <span>*</span>
            }
        </div>
    }

    <div
        class="malou-border-primary flex h-12 self-stretch rounded-lg bg-white"
        [class.malou-border-secondary]="!errorMessage && isFocused"
        [class.malou-border-error]="!!errorMessage">
        <div class="w-full px-5">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                type="text"
                [class.italic]="isEmptyValue"
                [class.malou-text-12]="isEmptyValue"
                [placeholder]="placeholder"
                [formControl]="control"
                (focus)="isFocused = true"
                (blur)="isFocused = false"
                (onAutocompleteSelected)="locationSelectedFn($event)"
                #addressInput />
        </div>

        <div class="flex items-center pr-5">
            <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
        </div>
    </div>

    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
</div>
