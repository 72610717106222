import { Injectable } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { breakpoints } from ':core/constants';

export enum ScreenSize {
    IsVeryLargeScreen = 'isVeryLargeScreen',
    IsLargeScreen = 'isLargeScreen',
    IsMediumScreen = 'isMediumScreen',
    IsSmallScreen = 'isSmallScreen',
}

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService {
    // inferior to medium
    isPhoneScreen: boolean;
    isPhoneScreen$: Subject<boolean> = new Subject();

    // superior to medium and inferior to large
    isLargeScreen: boolean;

    // superior to large and inferior to very large
    isDesktopScreen: boolean;

    // superior to very large
    isLargeDesktopScreen: boolean;

    resize$ = fromEvent(window, 'resize').pipe(
        startWith(null),
        debounceTime(100),
        map((_) => {
            if (window.innerWidth >= breakpoints.very_large) {
                this.isPhoneScreen = false;
                this.isPhoneScreen$.next(false);
                this.isLargeScreen = false;
                this.isDesktopScreen = false;
                this.isLargeDesktopScreen = true;
                return ScreenSize.IsVeryLargeScreen;
            }
            if (window.innerWidth < breakpoints.very_large && window.innerWidth >= breakpoints.large) {
                this.isPhoneScreen = false;
                this.isPhoneScreen$.next(false);
                this.isLargeScreen = false;
                this.isDesktopScreen = true;
                this.isLargeDesktopScreen = false;
                return ScreenSize.IsLargeScreen;
            } else if (window.innerWidth < breakpoints.large && window.innerWidth >= breakpoints.medium) {
                this.isPhoneScreen = false;
                this.isPhoneScreen$.next(false);
                this.isLargeScreen = true;
                this.isDesktopScreen = false;
                this.isLargeDesktopScreen = false;
                return ScreenSize.IsMediumScreen;
            }
            this.isPhoneScreen = true;
            this.isPhoneScreen$.next(true);
            this.isLargeScreen = false;
            this.isDesktopScreen = false;
            this.isLargeDesktopScreen = false;
            return ScreenSize.IsSmallScreen;
        }),
        map((x) => ({ size: x, width: window.innerWidth }))
    );

    keepAlive$ = this.resize$.subscribe();
}
