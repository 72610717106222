import { Component, OnInit } from '@angular/core';

import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

import { DashboardCalendarComponent } from './dashboard-calendar/dashboard-calendar.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [DashboardCalendarComponent],
})
export class DashboardComponent implements OnInit {
    constructor(private readonly _heapEmailEventsTrackerService: HeapEmailEventsTrackerService) {}

    ngOnInit(): void {
        this._heapEmailEventsTrackerService.trackEmailEvent();
    }
}
