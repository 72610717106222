import { z } from 'zod';

export interface KeywordAnalysisDto {
    keywords: string[];
    score?: number;
    count?: number;
}

export const keywordAnalysisDtoValidator = z.object({
    keywords: z.array(z.string()),
    score: z.number().optional(),
    count: z.number().optional(),
});
