import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, effect, EventEmitter, inject, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewEvolution, ReviewsEvolutionWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    createDate,
    getDateOfISOWeek,
    getDaysYearRange,
    getMonthsYearRange,
    getWeekAndYearNumber,
    isDateSetOrGenericPeriod,
} from ':shared/helpers';
import { DatesAndPeriod, DayYear, MonthYear, Restaurant, WeekDayMonthYear, WeekYear } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import * as StatisticsActions from '../../store/statistics.actions';
import { PlatformFilterPage } from '../../store/statistics.interface';
import * as StatisticsSelectors from '../../store/statistics.selectors';
import {
    ReviewsRatingsCount,
    ReviewsRatingsEvolutionChartComponent,
} from './reviews-ratings-evolution-chart/reviews-ratings-evolution-chart.component';

enum ViewByTemporality {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}
interface EvolutionsMap {
    [key: string]: {
        [year: number]: {
            [time in ViewByTemporality]?: number;
        };
    };
}

@Component({
    selector: 'app-reviews-ratings-evolution',
    templateUrl: './reviews-ratings-evolution.component.html',
    styleUrls: ['./reviews-ratings-evolution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        ReviewsRatingsEvolutionChartComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        LowerCasePipe,
        ApplyPurePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class ReviewsRatingsEvolutionComponent implements OnInit {
    @Input() showViewByTextInsteadOfSelector = false;
    @Input() hiddenDatasetIndexes: number[] = [];

    @Output() viewByChange: EventEmitter<ViewBy> = new EventEmitter<ViewBy>();
    @Output() hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.WEEK) as FormControl<ViewBy>;
    readonly chartViewBy: WritableSignal<ViewBy> = signal(ViewBy.WEEK);
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);

    readonly dateLabels: WritableSignal<Date[]> = signal([]);
    readonly platformsRatingsData: WritableSignal<ReviewsRatingsCount> = signal({});

    httpError: any;
    noResults = false;
    isLoading = signal(true);
    isSomePlatformGotNoData = false;
    somePlatformGotNoDataErrorMsg: string;
    reviewsEvolution: ReviewsEvolutionWithRange | undefined;

    readonly SvgIcon = SvgIcon;

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @Input() set viewBy(value: ViewBy | undefined) {
        if (value) {
            this.chartViewBy.set(value);
            this.viewByControl.setValue(value);
        }
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this._restaurantsService.restaurantSelected$])
            .pipe(
                filter(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]): Observable<
                        [ReviewsEvolutionWithRange, string[]]
                    > => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;

                        return forkJoin([
                            this._reviewsService.getChartRestaurantsReviewsEvolutions(restaurantId, platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            of(platforms),
                        ]);
                    }
                ),
                map(([reviewsEvolution, platforms]: [ReviewsEvolutionWithRange, string[]]) => {
                    if (!reviewsEvolution || reviewsEvolution?.results?.dataPerWeek?.length === 0) {
                        this.isLoading.set(false);
                        this.noResults = true;
                        this.hasDataChange.emit(false);
                        return null;
                    }
                    return [reviewsEvolution, platforms];
                }),
                filter((dataOrNull: [ReviewsEvolutionWithRange, string[]] | null) => isNotNil(dataOrNull)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([reviewsEvolution, platforms]: [ReviewsEvolutionWithRange, string[]]) => {
                this._store.dispatch(StatisticsActions.editReviewsRatingsEvolutionData({ data: reviewsEvolution }));
                this.reviewsEvolution = reviewsEvolution;
                const evolutionsPerWeek = reviewsEvolution?.results?.dataPerWeek?.filter((e) => !!e.year && !!e.week);
                this._checkIfPlatformsGoNoData(evolutionsPerWeek, platforms);
                const viewBy = this.viewByControl.value;

                try {
                    const { platformsRatingsData, dateLabels } = this._getChartDataByView(reviewsEvolution, viewBy);
                    this.platformsRatingsData.set(platformsRatingsData);
                    this.dateLabels.set(dateLabels);
                } catch (error) {
                    console.error(error);
                }

                this.isLoading.set(false);
            });

        this.viewByControl.valueChanges.pipe(startWith(ViewBy.WEEK), takeUntilDestroyed(this._destroyRef)).subscribe((viewBy: ViewBy) => {
            this.chartViewBy.set(viewBy);
            this.viewByChange.emit(viewBy);
            if (!this.reviewsEvolution) {
                return;
            }
            const { platformsRatingsData, dateLabels } = this._getChartDataByView(this.reviewsEvolution, viewBy);
            this.platformsRatingsData.set(platformsRatingsData);
            this.dateLabels.set(dateLabels);
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _getChartDataByView(
        reviewsEvolution: ReviewsEvolutionWithRange,
        viewBy: ViewBy
    ): { platformsRatingsData: ReviewsRatingsCount; dateLabels: Date[] } {
        const {
            results: { dataPerDay, dataPerWeek, dataPerMonth },
            startDate,
            endDate,
        } = reviewsEvolution;
        const start = createDate(startDate);
        const end = createDate(endDate);
        switch (viewBy) {
            case ViewBy.DAY:
                const days: DayYear[] = getDaysYearRange(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerDay, days, viewBy),
                    dateLabels: this._getDayYearDateLabels(days),
                };
            case ViewBy.WEEK:
                const weekYears: WeekYear[] = this._getWeekYears(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerWeek, weekYears, viewBy),
                    dateLabels: this._getWeekYearDateLabels(weekYears),
                };
            case ViewBy.MONTH:
                const months: MonthYear[] = getMonthsYearRange(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerMonth, months, viewBy),
                    dateLabels: this._getMonthYearDateLabels(months),
                };
            default:
                return {
                    platformsRatingsData: {},
                    dateLabels: [],
                };
        }
    }

    private _checkIfPlatformsGoNoData(evolutions: ReviewEvolution[], platforms: string[]): void {
        const platformsWithNoData: string[] = platforms
            .filter((platform) => !evolutions.some((evolution) => evolution.key === platform))
            .map((platformKey) => this._enumTranslate.transform(platformKey, 'platform_key'));
        if (platformsWithNoData.length > 0) {
            const baseMsg = this._translateService.instant('statistics.e_reputation.reviews_ratings.some_platforms_got_no_data');
            this.somePlatformGotNoDataErrorMsg = `${baseMsg} ${platformsWithNoData.join(', ')}`;
            this.isSomePlatformGotNoData = true;
        }
    }

    private _getWeekYears(start: Date, end: Date): WeekYear[] {
        const startDateWeekYear = getWeekAndYearNumber(start);
        const endDateWeekYear = getWeekAndYearNumber(end);
        const weekYears: WeekYear[] = this._getWeeksRange(startDateWeekYear, endDateWeekYear);
        return weekYears;
    }

    private _getWeekYearDateLabels(weekYears: WeekYear[]): Date[] {
        return weekYears.map((e) => getDateOfISOWeek(e.week, e.year));
    }

    private _getDayYearDateLabels(days: DayYear[]): Date[] {
        return days.map((e) => new Date(e.year, 0, e.day));
    }

    private _getMonthYearDateLabels(months: MonthYear[]): Date[] {
        return months.map((e) => new Date(e.year, e.month - 1, 1));
    }

    private _getPlatformsRatingsData<T extends ReviewEvolution, U extends WeekDayMonthYear>(
        reviewsEvolutions: T[],
        timeRange: U[],
        viewBy: ViewBy
    ): ReviewsRatingsCount {
        const temporality = this._getViewByTemporality(viewBy);
        const evolutionsMap = this._getEvolutionsMap<T>(reviewsEvolutions, temporality);
        const platformsKeys = Object.keys(evolutionsMap);
        const platformsRatingsData: ReviewsRatingsCount = {};
        for (const platformKey of platformsKeys) {
            const platformTotals: number[] = [];
            for (const time of timeRange) {
                platformTotals.push(evolutionsMap[platformKey]?.[time.year]?.[time[temporality]] || 0);
            }
            platformsRatingsData[platformKey] = platformTotals;
        }
        return platformsRatingsData;
    }

    private _getWeeksRange(start: WeekYear, end: WeekYear): WeekYear[] {
        const weeks: WeekYear[] = [];
        let currentWeek = start.week;
        let currentYear = start.year;
        while (currentYear < end.year || (currentYear === end.year && currentWeek <= end.week)) {
            weeks.push({ week: currentWeek, year: currentYear });
            const weeksInWeekYear = this._getYearWeekNumber(currentYear);
            currentWeek = currentWeek === weeksInWeekYear ? 1 : currentWeek + 1;
            currentYear = currentWeek === 1 ? currentYear + 1 : currentYear;
        }
        return weeks;
    }

    /**
     * Return the number of weeks in a year
     * @param year
     * @return number 52 ou 53
     */
    private _getYearWeekNumber(year: number): number {
        // We set the month to 2 (= february) to avoid the january edge case
        // when week number is equal to 52 (or 53) in early january
        // see https://en.wikipedia.org/wiki/ISO_week_date
        return DateTime.utc(year, 2).weeksInWeekYear;
    }

    private _getEvolutionsMap<T extends ReviewEvolution>(reviewsEvolutions: T[], temporality: ViewByTemporality): EvolutionsMap {
        const evolutionsMap: EvolutionsMap = {};
        reviewsEvolutions.forEach((e) => {
            if (!evolutionsMap[e.key]) {
                evolutionsMap[e.key] = {};
            }
            if (!evolutionsMap[e.key][e.year]) {
                evolutionsMap[e.key][e.year] = {};
            }
            if (!evolutionsMap[e.key][e.year][e[temporality]]) {
                evolutionsMap[e.key][e.year][e[temporality]] = e.total;
            }
        });
        return evolutionsMap;
    }

    private _getViewByTemporality(viewBy: ViewBy): ViewByTemporality {
        switch (viewBy) {
            case ViewBy.DAY:
                return ViewByTemporality.DAY;
            case ViewBy.WEEK:
                return ViewByTemporality.WEEK;
            case ViewBy.MONTH:
                return ViewByTemporality.MONTH;
            default:
                return ViewByTemporality.WEEK;
        }
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.noResults = false;
        this.isSomePlatformGotNoData = false;
        this.somePlatformGotNoDataErrorMsg = '';
    }
}
