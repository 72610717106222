type Restauranty = {
    internalName?: string;
    name: string;
};

export const sortRestaurantsByInternalNameThenName: (a: Restauranty, b: Restauranty) => number = (a, b) => {
    if (a.internalName && b.internalName) {
        return a.internalName.localeCompare(b.internalName);
    } else if (a.internalName) {
        return -1;
    } else if (b.internalName) {
        return 1;
    } else {
        return a.name.localeCompare(b.name);
    }
};
