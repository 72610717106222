import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-no-results',
    templateUrl: './no-results.component.html',
    styleUrls: ['./no-results.component.scss'],
    standalone: true,
    imports: [MatButtonModule],
})
export class NoResultsComponent {
    @Input() picture: string;
    @Input() title: string;
    @Input() details?: string[] = [];
    @Input() buttonLabel: string;
    @Input() buttonClass = 'malou-btn-raised--primary';
    @Output() onClickButton: EventEmitter<unknown> = new EventEmitter();
}
