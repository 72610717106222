// https://uppy.io/docs/writing-plugins/#Creating-A-Plugin
import { UIPlugin, UppyFile } from '@uppy/core';

import { IG_FIT_BIGGER_SIDE_MAX_SIZE, SMALL_MEDIA_BIGGER_SIDE_MAX_SIZE } from '@malou-io/package-utils';

export function isFileVideo(file: UppyFile<Record<string, unknown>, Record<string, unknown>>): boolean {
    return !!file.extension.match(/mp4|webm|ogg|ogv|mov|wmv|avi|flv|mkv|3gp|3g2|mpeg|mpg|m4v|m4a|f4v|f4a|f4b/i);
}
export class MalouResizeUppyPlugin extends UIPlugin {
    constructor(uppy, opts) {
        super(uppy, opts);
        this.id = 'MalouResizePlugin';
        this.type = 'malou-resize';
    }

    prepareUpload = (fileIDs): Promise<void> => {
        const sizes = {
            small: SMALL_MEDIA_BIGGER_SIDE_MAX_SIZE,
            igFit: IG_FIT_BIGGER_SIDE_MAX_SIZE,
        };

        const promisesOriginal: Promise<File>[] = fileIDs.map((fileID) => {
            const file = this.uppy.getFile(fileID);
            return new Promise((resolve) => {
                this.uppy.setFileState(file.id, { meta: { ...file.meta, uppyInstance: 'original' } });
                resolve(file);
            });
        });

        const otherSizesPromises = Object.keys(sizes).map((sizeKey) => {
            const promise: Promise<File>[] = fileIDs.map((fileID) => {
                const file = this.uppy.getFile(fileID);

                if (isFileVideo(file)) {
                    return Promise.resolve([]);
                }
                return this._resizeImage(file.data as File, sizes[sizeKey]).then((resizedFile) => {
                    const newId = this.uppy.addFile({
                        source: 'file input',
                        name: file.name,
                        type: file.type,
                        data: resizedFile,
                        meta: { uppyInstance: sizeKey },
                    });
                    fileIDs.push(newId);
                    return file;
                });
            });

            return promise;
        });

        const emitPreprocessCompleteForAll = (): void => {
            fileIDs.forEach((fileID) => {
                const file = this.uppy.getFile(fileID);
                this.uppy.emit('preprocess-complete', file);
            });
        };
        return Promise.all([...promisesOriginal, ...otherSizesPromises.flat()]).then(emitPreprocessCompleteForAll);
    };

    override install(): void {
        this.uppy.addPreProcessor(this.prepareUpload);
    }

    override uninstall(): void {
        this.uppy.removePreProcessor(this.prepareUpload);
    }

    private _resizeImage(file: any, maxBiggerSideSize: number): Promise<File> {
        return new Promise((resolve, _reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event): any => {
                if (!event.target) {
                    return;
                }
                const img = new Image();
                img.src = event.target.result as string;
                img.onload = (e): any => {
                    if (!e.target) {
                        return;
                    }
                    // create a canvas, and get the right resized dimensions
                    const canvas = document.createElement('canvas');
                    const width = e.target['width'];
                    const height = e.target['height'];
                    const biggerSide = width >= height ? 'width' : 'height';
                    const maxSize = Math.min(e.target[biggerSide] || 0, maxBiggerSideSize);

                    // calculate the size of the new image
                    const ratio = maxSize / e.target[biggerSide];
                    canvas.width = biggerSide === 'width' ? maxSize : width * ratio;
                    canvas.height = biggerSide === 'height' ? maxSize : height * ratio;

                    // draw image on canvas
                    const context = canvas.getContext('2d');
                    if (!context) {
                        return;
                    }
                    context.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // generate blob from canvas
                    const dataUrl = canvas.toDataURL('image/jpeg');
                    const blob = this._dataURItoBlob(dataUrl);

                    // blob to file
                    const newResizedFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                    resolve(newResizedFile);
                };
            };
        });
    }

    private _dataURItoBlob(dataURI): Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const bytes = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            bytes[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([arrayBuffer], { type: mimeString });
        return blob;
    }
}
