@for (hour of hours; track hour) {
    <div class="hours">
        @if (hour.isClosed) {
            <span *ngTemplateOutlet="startDate"></span>
            <span>fermé</span>
        } @else {
            @if (hour.startDate | applySelfPure: 'equals' : hour.endDate) {
                <span>
                    <ng-container *ngTemplateOutlet="startDate"></ng-container>
                </span>
                <ng-container *ngTemplateOutlet="openCloseTime"></ng-container>
            } @else {
                <span>
                    <ng-container *ngTemplateOutlet="startDate"></ng-container> - <ng-container *ngTemplateOutlet="endDate"></ng-container>
                </span>
                <ng-container *ngTemplateOutlet="openCloseTime"></ng-container>
            }
        }
        <ng-template #startDate> {{ hour.startDate.day }}/{{ hour.startDate.month + 1 }}/{{ hour.startDate.year }} </ng-template>
        <ng-template #endDate> {{ hour.endDate.day }}/{{ hour.endDate.month + 1 }}/{{ hour.endDate.year }} </ng-template>
        <ng-template #openCloseTime>
            <span> {{ hour.openTime }} - {{ hour.closeTime }} </span>
        </ng-template>
    </div>
}
@if (!hours.length) {
    <div>-</div>
}
