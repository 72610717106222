<div
    class="fixed z-[1000] rounded bg-malou-chip-text-primary px-3 py-2 text-[10px] font-medium leading-[14px] text-malou-color-text-white"
    [@tooltipState]="animationState()"
    [ngClass]="position"
    [ngStyle]="{
        pointerEvents: isVisible() ? 'auto' : 'none',
        top: top() + 'px',
        left: left() + 'px',
    }">
    @if (isTemplate(content)) {
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    } @else {
        {{ content }}
    }
</div>
