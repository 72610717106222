import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    standalone: true,
    imports: [NgClass, MatSlideToggleModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
    readonly checked = input.required<boolean>();
    readonly disabled = input<boolean>(false);
    readonly testId = input<string>();
    readonly id = input<string>();

    readonly onToggle = output<boolean>();

    readonly defaultId = Math.random().toString(36).substring(7);
    readonly computedId = computed(() => this.id() ?? this.defaultId);

    toggle(): void {
        this.onToggle.emit(this.checked());
    }
}
