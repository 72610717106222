import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IStoryVideoCheck } from ':core/constants';

enum VideoError {
    DURATION = 'duration',
    WIDTH = 'width',
    EXTENSION = 'extension',
    RATIO = 'ratio',
    HEIGHT = 'height',
}

@Component({
    selector: 'app-story-video-error',
    templateUrl: './story-video-error.component.html',
    styleUrls: ['./story-video-error.component.scss'],
    standalone: true,
    imports: [TranslateModule],
    providers: [TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryVideoErrorComponent {
    readonly videoValidity = input<IStoryVideoCheck>();

    private readonly _translate = inject(TranslateService);

    readonly errors: Signal<VideoError[]> = computed(() => {
        const videoValidity = this.videoValidity();
        const errorsList: VideoError[] = [];
        if (!videoValidity) {
            return [];
        }
        if (!videoValidity.isDurationValid) {
            errorsList.push(VideoError.DURATION);
        }
        if (!videoValidity.isHeightValid) {
            errorsList.push(VideoError.HEIGHT);
        }
        if (!videoValidity.isWidthValid) {
            errorsList.push(VideoError.WIDTH);
        }
        if (!videoValidity.isExtensionValid) {
            errorsList.push(VideoError.EXTENSION);
        }
        if (!videoValidity.isRatioValid) {
            errorsList.push(VideoError.RATIO);
        }
        return errorsList;
    });

    readonly readableErrorMapping: Record<VideoError, string> = {
        [VideoError.DURATION]: this._translate.instant('story.video.error.duration'),
        [VideoError.WIDTH]: this._translate.instant('story.video.error.width'),
        [VideoError.EXTENSION]: this._translate.instant('story.video.error.extension'),
        [VideoError.RATIO]: this._translate.instant('story.video.error.ratio'),
        [VideoError.HEIGHT]: this._translate.instant('story.video.error.height'),
    };
}
