import { GiftDto, LightGiftDto } from '@malou-io/package-dto';

import { GiftStock } from './gift-stock';
import { LightRestaurant } from './restaurant';

export class Gift {
    id?: string;
    name: string;
    weight: number;
    stocks: GiftStock[];
    conditions?: {
        fr?: string | null;
        en?: string | null;
        es?: string | null;
        it?: string | null;
    } | null;

    constructor(init: Gift | GiftDto) {
        this.id = init.id;
        this.name = init.name;
        this.weight = init.weight;
        this.stocks = init.stocks.map((giftStock) => ({
            id: giftStock.id,
            quantity: giftStock.quantity,
            restaurant: new LightRestaurant(giftStock.restaurant),
        }));
        this.conditions = init.conditions;
    }

    getWeightAccordingToStocks(): number {
        const areAllStocksZero = this.stocks.every((stock) => stock.quantity === 0);
        return areAllStocksZero ? 0 : this.weight;
    }
}

export class LightGift {
    id?: string;
    name: string;
    weight: number;
    conditions?: {
        fr?: string | null;
        en?: string | null;
        es?: string | null;
        it?: string | null;
    } | null;

    constructor(init: LightGiftDto) {
        this.id = init.id;
        this.name = init.name;
        this.weight = init.weight;
        this.conditions = init.conditions;
    }
}
