import { createAction, props } from '@ngrx/store';

import { Permissions } from './permissions.interface';

export const loadPermissions = createAction('[permissions] Load_Permissions', props<{ restaurantId: string }>());

export const editPermissionsData = createAction('[permissions] Edit_Permissions_Data', props<{ permissions: Permissions }>());

export const resetPermissionsData = createAction('[permissions] Reset_Permissions_Data');

export const removeFacebookPermissions = createAction('[permissions] Remove_Facebook_Permissions');

export const removeInstagramPermissions = createAction('[permissions] Remove_Instagram_Permissions');

export const loadPlatformPermissions = createAction(
    '[permissions] Load_Platform_Permissions',
    props<{ platformKey: string; restaurantId: string }>()
);
