<div class="flex h-screen flex-col justify-between">
    <div class="flex grow flex-col items-center justify-center gap-6">
        <img class="w-[80%]" [src]="'404' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        <img class="w-[70%]" [src]="'routourne' | imagePathResolver: { folder: 'wheels-of-fortune', extensionFormat: 'gif' }" />
    </div>

    <div class="malou-text-12--semibold left-0 flex w-full items-baseline justify-center pb-3 text-center">
        <span>{{ 'play_wheel_of_fortune.powered_by' | translate }}</span>
        <img class="ml-1 max-h-2" [src]="'malou_logo_black' | imagePathResolver" />
    </div>
</div>
