import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SearchComponent } from ':shared/components/search/search.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-restaurants-list-modal',
    templateUrl: './restaurants-list-modal.component.html',
    styleUrls: ['./restaurants-list-modal.component.scss'],
    standalone: true,
    imports: [SearchComponent, TranslateModule, MatButtonModule, MatIconModule],
})
export class RestaurantsListModalComponent {
    readonly SvgIcon = SvgIcon;
    restaurants: Restaurant[] = [];
    filteredRestaurants: Restaurant[] = [];

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        public dialogRef: MatDialogRef<RestaurantsListModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurants: Restaurant[];
            maxRestaurants?: number;
        }
    ) {
        this.restaurants = this.data.restaurants;
        this.filteredRestaurants = this.restaurants;
    }

    onValueChange(value: string): void {
        this.filteredRestaurants = this.data.restaurants.filter((restaurant) =>
            restaurant.name.toLowerCase().includes(value.toLowerCase())
        );
    }

    close(): void {
        this.dialogRef.close();
    }
}
