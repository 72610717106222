import { Routes } from '@angular/router';

import { StoriesComponent } from './stories.component';

export const STORIES_ROUTES: Routes = [
    {
        path: '',
        component: StoriesComponent,
    },
];
