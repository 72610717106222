@if (!notification().readAt) {
    <div class="notification-unread-bubble h-2 w-2 rounded-full bg-malou-color-gradient-3"></div>
}

<div class="notification-actions hidden items-center">
    <app-noop-mat-checkbox
        color="primary"
        [matTooltip]="isRead() ? ('messages.mark_as_unread' | translate) : ('messages.mark_as_read' | translate)"
        [checked]="isRead()"
        (click)="toggleRead(); $event.stopPropagation()">
    </app-noop-mat-checkbox>
</div>
