import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    AuthorizedPlatformKeys,
    GetCredentialsService,
} from ':modules/platforms/platforms-connection-modals/shared/services/get-credentials.service';

@Injectable({
    providedIn: 'root',
})
export class GetStepIndexFromCredentialService {
    private readonly _getCredentialsService = inject(GetCredentialsService);

    execute(platformKey: AuthorizedPlatformKeys, notConnectedStepIndex: number, connectedStepIndex: number): Observable<number> {
        return this._getCredentialsService.execute(platformKey).pipe(
            map((credentials) => {
                if (credentials.length > 0) {
                    return connectedStepIndex;
                } else {
                    return notConnectedStepIndex;
                }
            })
        );
    }
}
