<div class="flex flex-col gap-y-2">
    <app-sidenav-content-route [options]="optionsComputed()" [showNotificationCount]="!isOpen()"></app-sidenav-content-route>
    <!-- Do not use @if here so we keep the output 'isActive' alive -->
    <div class="flex flex-col gap-1" [class.hidden]="!isOpen()">
        @for (childOptions of childrenOptions(); track childOptions.routerLink; let index = $index) {
            <app-sidenav-content-route
                [options]="childOptions"
                (isActive)="onIsActive($event, childOptions.routerLink)"></app-sidenav-content-route>
        }
    </div>
</div>
