import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { concatMap, filter, map, Subject, tap } from 'rxjs';

import { FileFormat, MimeType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { TransformData } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/upload-and-edit-medias/components/edit-media-modal/components/image-editor/image-editor.component';
import {
    EditMediaModalComponent,
    EditMediaModalDialogData,
    EditMediaModalDialogResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/upload-and-edit-medias/components/edit-media-modal/edit-media-modal.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { BodyDragAndDropEventsService } from ':shared/services/body-drag-and-drop-events.service';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaThumbnailListComponent } from './components/media-thumbnail-list/media-thumbnail-list.component';

interface Media {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
    transformData?: TransformData;
}

@Component({
    selector: 'app-upload-and-edit-medias',
    templateUrl: './upload-and-edit-medias.component.html',
    standalone: true,
    imports: [NgTemplateOutlet, MatIconModule, TranslateModule, MediaThumbnailListComponent, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAndEditMediasComponent {
    private readonly _customDialogService = inject(CustomDialogService);

    readonly medias = model.required<Media[]>();
    readonly maxMediaSizeInMo = input<number | undefined>();
    readonly mediaFormatAccepted = input<FileFormat[]>([]);
    readonly showEditMediaButton = input(true);
    readonly mediaClicked = output<string>();

    private readonly _translateService = inject(TranslateService);
    private readonly _mediaService = inject(MediaService);
    private readonly _bodyDragAndDropEventsService = inject(BodyDragAndDropEventsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);

    private readonly _fileSubject = new Subject<File>();
    private readonly _isModalOpen = signal(false);
    readonly uploadingMediaCount = signal(0);

    readonly SvgIcon = SvgIcon;
    readonly MAX_MEDIA_COUNT = 10;
    // This list is given to the <input type='file'>, remember that it's only a suggestion, the user can bypass this.
    readonly ACCEPT = [MimeType.IMAGE_PNG, MimeType.IMAGE_JPEG, MimeType.IMAGE_HEIC, MimeType.IMAGE_HEIF];

    readonly mediaRequirementsText = computed((): string => {
        const requirements: string[] = [];

        const maxMediaSize = this.maxMediaSizeInMo();
        if (maxMediaSize) {
            const maxMediaSizeRequirement = this._translateService.instant('upload_and_edit_medias.requirements.max_media_size', {
                maxMediaSize,
            });
            requirements.push(maxMediaSizeRequirement);
        }

        const mediaFormatAccepted = this.mediaFormatAccepted();
        if (mediaFormatAccepted.length > 0) {
            const mediaFormatAcceptedRequirement = mediaFormatAccepted.join(', ');
            requirements.push(mediaFormatAcceptedRequirement);
        }

        return requirements.join(', ');
    });

    readonly isDragging = signal(false);

    constructor() {
        this._initFileSubjectPipeline();

        this._bodyDragAndDropEventsService.dragEnter
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragEnter);
        this._bodyDragAndDropEventsService.dragOver
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragOver);
        this._bodyDragAndDropEventsService.dragLeave
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragLeave);
        this._bodyDragAndDropEventsService.drop
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDrop);
    }

    onFileInputChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        for (const file of Array.from(files)) {
            this._fileSubject.next(file);
        }
    }

    onEditMedia(mediaId: string): void {
        this._isModalOpen.set(true);
        this._customDialogService
            .open<EditMediaModalComponent, EditMediaModalDialogData, EditMediaModalDialogResult>(EditMediaModalComponent, {
                width: '700px',
                data: {
                    medias: this.medias(),
                    selectedMediaId: mediaId,
                },
            })
            .afterClosed()
            .subscribe((data) => {
                this._isModalOpen.set(false);
                if (data) {
                    this.medias.set(data.medias);
                }
            });
    }

    private _isDragAndDropActive = (): boolean => !this._isModalOpen();

    private _hasFile(event: DragEvent): boolean {
        return (event.dataTransfer?.types ?? []).includes('Files');
    }

    private _onDragEnter = (): void => {
        this.isDragging.set(true);
    };

    private _onDragOver = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    };

    private _onDragLeave = (event: DragEvent): void => {
        event.preventDefault();
        this.isDragging.set(false);
    };

    private _onDrop = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        this.isDragging.set(false);
        for (const file of Array.from(event.dataTransfer?.files ?? [])) {
            this._fileSubject.next(file);
        }
    };

    private _initFileSubjectPipeline(): void {
        this._fileSubject
            .pipe(
                filter(() => {
                    const freeSlots = this.MAX_MEDIA_COUNT - this.medias().length - this.uploadingMediaCount();
                    return freeSlots > 0;
                }),
                tap(() => this.uploadingMediaCount.update((value) => ++value)),
                concatMap((file) =>
                    this._mediaService.uploadV2({
                        file,
                        restaurantId: this._restaurantsService.currentRestaurant._id,
                        onProgress: () => {},
                    })
                ),
                map((result) => {
                    this.uploadingMediaCount.update((value) => --value);
                    if (result.success === false) {
                        this._toastService.openErrorToast('Media not uploaded'); // todo for next pr
                        return;
                    }
                    this.medias.update((medias) => [
                        ...medias,
                        {
                            id: result.mediaId,
                            thumbnail1024OutsideUrl: result.thumbnail1024OutsideUrl,
                            thumbnail256OutsideUrl: result.thumbnail256OutsideUrl,
                        },
                    ]);
                })
            )
            .subscribe();
    }
}
