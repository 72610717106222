import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TemplateReplacer } from ':modules/templates/template-replacer/template-replacer';
import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { StarWithTextChipComponent } from ':shared/components/star-with-text-chip/star-with-text-chip.component';
import { Template } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-templates',
    templateUrl: 'select-templates.component.html',
    styleUrls: ['./select-templates.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectTemplatesComponent),
        },
    ],
    standalone: true,
    imports: [
        SelectBaseComponent,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgTemplateOutlet,
        NgClass,
        MatIconModule,
        ShortTextPipe,
        PluralTranslatePipe,
        ApplySelfPurePipe,
        StarWithTextChipComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTemplatesComponent extends ControlValueAccessorConnectorComponent {
    // ------------ CORE ------------//

    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<Template[]>([]);

    /** Template replacer */
    readonly templateReplacer = input.required<TemplateReplacer>();

    /** On change event */
    readonly selectTemplatesChange = output<Template>();

    readonly testId = input<string>();

    displayWith: (option: any) => string = (option: any) => option.name;

    sortBy = (a: Template, b: Template): number => {
        if (!a.name) {
            return -1;
        }
        if (!b.name) {
            return 1;
        }
        return a.name > b.name ? 1 : -1;
    };
}
