<app-modal-structure
    [title]="titleTranslationKey() | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="!selectedPlatformSearch() && (!urlControl.value || urlControl.invalid)"
    [secondaryButtonText]="'common.previous' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isUpdatingPlatform()"
    (close)="close.emit({})"
    (primaryClick)="onValidate()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })">
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
</app-modal-structure>

<ng-template #mainTemplate>
    <div class="flex h-full flex-col gap-y-4">
        <div class="min-h-0 grow">
            @if (platformSearchResults() === null) {
                <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
            } @else {
                <app-platforms-connection-business-selector
                    [translations]="{
                        title: 'platforms.connection_new.shared.steps.select_business.select_business',
                        noResultsDescription: 'platforms.connection_new.shared.steps.select_business.no_results_description',
                    }"
                    [selectedValue]="selectedPlatformSearch()"
                    [compareWith]="platformSearchCompareWith"
                    [selectableValues]="platformSearchResults()!"
                    [displayWith]="platformSearchDisplayWith"
                    [isDisabled]="isUpdatingPlatform()"
                    (selectionChange)="onBusinessChange($event)"></app-platforms-connection-business-selector>
            }
        </div>
        <div class="flex flex-col gap-y-1">
            <div class="malou-text-12--bold malou-color-text-1">
                {{ 'platforms.connection_new.shared.steps.select_business.paste_your_link' | translate }}
            </div>
            <app-input-text
                [testId]="'select-business-url-input'"
                [formControl]="urlControl"
                [placeholder]="UrlPlaceholder[platformKey()]"
                [disabled]="isUpdatingPlatform()"
                [errorMessage]="urlControl.errors?.invalidUrl ? UrlPlaceholder[platformKey()] : undefined">
            </app-input-text>
        </div>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="flex h-full items-center justify-center"><app-malou-spinner></app-malou-spinner></div>
</ng-template>
