<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="defaultSort().active"
        [matSortDirection]="defaultSort().direction"
        (matSortChange)="sortChange.emit($event)">
        @for (column of displayedColumns(); track column) {
            <ng-container [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ getTableColumnDisplayName | applyPure: column }}
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <div class="hidden lg:flex">
                        <span>
                            {{ getTableColumnDisplayName | applyPure: column }}
                        </span>
                    </div>
                    @switch (column) {
                        @case (TableField.RESTAURANT_NAME) {
                            <span class="malou-color-text-1 malou-text-13--semibold">
                                {{ data[TableField.RESTAURANT_NAME] }}
                            </span>
                        }
                        @case (TableField.AVERAGE_RATING) {
                            <ng-container [ngTemplateOutlet]="averageRatingCellTemplate" [ngTemplateOutletContext]="{ cellData: data }">
                            </ng-container>
                        }
                        @default {
                            <ng-container
                                [ngTemplateOutlet]="platformCellTemplate"
                                [ngTemplateOutletContext]="{ cellData: data.platformsData[column] }">
                            </ng-container>
                        }
                    }
                </mat-cell>
            </ng-container>
        }

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns()"></mat-row>
    </mat-table>
</div>

<ng-template let-cellData="cellData" #averageRatingCellTemplate>
    <div class="flex gap-x-2">
        <div>
            <span class="malou-text-14--bold malou-color-text-1 mr-2 text-center leading-none">
                {{ cellData[TableField.AVERAGE_RATING] | shortNumber }}
            </span>
        </div>
        <div>
            <app-number-evolution
                size="small"
                [value]="cellData.evolution"
                [displayedValue]="cellData.evolution | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
        </div>
    </div>
</ng-template>

<ng-template let-cellData="cellData" #platformCellTemplate>
    <div class="flex items-center justify-center">
        <span class="malou-text-14--bold malou-color-text-1 mr-2 text-center leading-none">
            {{ cellData.hasData ? (cellData.currentAverageRating | shortNumber) : '-' }}
        </span>
        <div>
            @if (cellData.hasData) {
                <app-number-evolution
                    [value]="cellData.evolution"
                    [displayedValue]="cellData.evolution | shortNumber: { shouldDisplayMinusSign: false }"
                    [size]="'small'"
                    [reverseArrow]="false">
                </app-number-evolution>
            }
        </div>
    </div>
</ng-template>
