import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LocalStorage } from ':core/storage/local-storage';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Category, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ExternalHrefPipe } from ':shared/pipes/external-href.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-restaurant-overview',
    templateUrl: './restaurant-overview.component.html',
    styleUrls: ['./restaurant-overview.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        PlatformLogoComponent,
        ApplySelfPurePipe,
        ExternalHrefPipe,
        FormatDatePipe,
        ShortTextPipe,
    ],
})
export class RestaurantOverviewComponent implements OnInit, OnDestroy {
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly currentLang = LocalStorage.getLang();

    allCategories: Array<Category>;
    showAllCategories = false;
    containerElement: Element;
    widthObserver: ResizeObserver;
    formattedAddress: string;
    filteredCategories: Category[];
    remainingCategories: Category[];
    hasTooManyCategories: boolean;
    maxCategoriesToShow = 3;

    readonly SvgIcon = SvgIcon;

    private _restaurant: Restaurant;

    constructor(public readonly translateService: TranslateService) {}

    get restaurant(): Restaurant {
        return this._restaurant;
    }

    @Input() set restaurant(value: Restaurant) {
        this._restaurant = value;
        this.allCategories = this._restaurant.categoryList;
        this.hasTooManyCategories = this._restaurant.categoryList.length > this.maxCategoriesToShow;
        this.filteredCategories = this.hasTooManyCategories
            ? this._restaurant.categoryList.slice(0, this.maxCategoriesToShow)
            : this._restaurant.categoryList;
        this.remainingCategories = this.allCategories.slice(this.maxCategoriesToShow, this.allCategories.length);
        this.formattedAddress = `${this._restaurant.address?.formattedAddress}, ${this._restaurant.address?.postalCode}, 
            ${this._restaurant.address?.locality}`;
    }

    ngOnInit(): void {
        this._initCloseModalOnClick();
        this.containerElement = document.querySelector('#restaurant-overview-container') as HTMLElement;
        this._initResizeObserver();
    }

    ngOnDestroy(): void {
        document.removeEventListener('click', this._onClick);
        this.widthObserver.unobserve(this.containerElement);
    }

    showAll(): void {
        this.showAllCategories = !this.showAllCategories;
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (event) => this._onClick(event));
    }

    private _initResizeObserver(): void {
        this.widthObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                this.maxCategoriesToShow = Math.max(Math.floor(entry.contentRect.width / 100) - 2, 1);
                this.hasTooManyCategories = this._restaurant.categoryList.length > this.maxCategoriesToShow;
                this.filteredCategories = this.hasTooManyCategories
                    ? this._restaurant.categoryList.slice(0, this.maxCategoriesToShow)
                    : this._restaurant.categoryList;
                this.remainingCategories = this.allCategories.slice(this.maxCategoriesToShow, this.allCategories.length);
            });
        });
        this.widthObserver.observe(this.containerElement);
    }

    private _onClick(event: Event): void {
        const target = <HTMLElement>event.target;
        if (!target.closest('#categoriesCard') && !target.closest('#moreCatBtn')) {
            this.showAllCategories = false;
        }
    }
}
