import { z } from 'zod';

import { AggregationTimeScale, InsightsTab, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const downloadInsightsAsPdfBodyValidator = z.object({
    callbackUrl: z.string(),
    params: z.string(),
    jwtToken: z.string(),
    restaurantId: objectIdValidator.optional(),
    insightTab: z.nativeEnum(InsightsTab),
});
export type DownloadInsightsAsPdfBodyDto = z.infer<typeof downloadInsightsAsPdfBodyValidator>;

// ---------------------------------------------------------------------------------------------

export const getInsightsAggregatedRequestQueryValidator = z
    .object({
        restaurant_ids: z.union([z.string().transform((id) => [id]), z.array(z.string())]).optional(),
        start_date: z.string().optional(),
        end_date: z.string().optional(),
        previous_period: z.enum(['true', 'false']).optional(),
        platforms_keys: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]).optional(),
        aggregators: z
            .union([z.nativeEnum(AggregationTimeScale).transform((timeScale) => [timeScale]), z.array(z.nativeEnum(AggregationTimeScale))])
            .optional(),
        metrics: z
            .union([z.nativeEnum(MalouMetric).transform((malouMetric) => [malouMetric]), z.array(z.nativeEnum(MalouMetric))])
            .optional(),
    })
    .transform((data) => ({
        ...data,
        restaurantIds: data.restaurant_ids,
        startDate: data.start_date,
        endDate: data.end_date,
        previousPeriod: data.previous_period,
        platformsKeys: data.platforms_keys,
    }));

export type GetInsightsAggregatedRequestQueryDto = z.infer<typeof getInsightsAggregatedRequestQueryValidator>;
