import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectRestaurantId } from ':modules/platforms/store/platforms.reducer';
import { UserState } from ':modules/user/store/user.reducer';
import { User, UserRestaurant } from ':modules/user/user';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserInfos = createSelector(selectUserState, (state: UserState) => state.infos);

export const selectUserPlatformsPermissions = createSelector(selectUserState, (state: UserState) => state.platformsPermissions);

export const selectUserRestaurants = createSelector(selectUserInfos, (state: User): any[] => state?.restaurants || []);

export const selectCurrentUserRestaurant = createSelector(
    selectUserRestaurants,
    selectRestaurantId,
    (userRestaurants: UserRestaurant[], restaurantId: string) =>
        userRestaurants.find((userRestaurant: UserRestaurant) => userRestaurant.restaurantId === restaurantId) ?? null
);
