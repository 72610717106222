import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';

import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { rgbaToHex } from ':shared/helpers/color';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-input-color',
    templateUrl: 'input-color.component.html',
    styleUrls: ['./input-color.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, ColorPickerModule, FormsModule, ReactiveFormsModule, TranslateModule, ApplyPurePipe, ClickOutsideDirective],
    providers: [ColorPickerService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputColorComponent implements OnInit {
    /**
     * Title
     *
     * @optional
     */
    @Input()
    title = '';

    /**
     * Placeholder
     *
     * @optional
     */
    @Input()
    placeholder = '';

    /**
     * Subtitle
     *
     * @required
     */
    @Input()
    subtitle?: string;

    @Input()
    debounceTime = 0;

    @Input()
    value: string;

    @Input()
    presetColors: string[] = [];

    /** Disable input */
    @Input()
    disabled = false;

    @Input()
    showAlphaCursor = true;

    @Input()
    position = 'auto';

    @Input()
    positionRelativeToArrow = false;

    @Input()
    positionOffset = '0%';

    /**
     * On value change output
     */
    @Output()
    inputColorChange: EventEmitter<string | null> = new EventEmitter();

    ngOnInit(): void {
        this.transformValue(this.value);
    }

    transformValue = (value: string | null): string => {
        const hexaValue = this._transformRgbaToHexa(value);
        this.value = hexaValue ? hexaValue.toUpperCase() : '';
        return this.value;
    };

    onColorChange(event: string): void {
        const formattedValue = this.transformValue(event);
        this.inputColorChange.emit(formattedValue);
    }

    private _transformRgbaToHexa(color: string | null): string | null {
        if (!color) {
            return null;
        }
        return rgbaToHex(color);
    }
}
