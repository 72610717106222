import { z } from 'zod';

import { RoiPageVisibilityStatus } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getRoiSavedTimeForNLastMonthsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        nb_months: z.string(),
    })
    .transform((data) => ({ restaurantId: data.restaurant_id, nbMonths: parseInt(data.nb_months, 10) }));

export type GetRoiSavedTimeForNLastMonthsParamsDto = z.infer<typeof getRoiSavedTimeForNLastMonthsParamsValidator>;

export const getRoiSavedTimeForNLastMonthsQueryValidator = z
    .object({
        previous_period: z.string(),
        visibility: z.nativeEnum(RoiPageVisibilityStatus),
    })
    .transform((data) => ({ previousPeriod: data.previous_period === 'true', visibility: data.visibility }));

export type GetRoiSavedTimeForNLastMonthsQueryDto = z.infer<typeof getRoiSavedTimeForNLastMonthsQueryValidator>;

// ----------------------------------------------------------

export const getAggregatedRoiSavedTimeForNLastMonthsParamsValidator = z
    .object({
        nb_months: z.string(),
    })
    .transform((data) => ({ nbMonths: parseInt(data.nb_months, 10) }));

export type GetAggregatedRoiSavedTimeForNLastMonthsParamsDto = z.infer<typeof getAggregatedRoiSavedTimeForNLastMonthsParamsValidator>;

export const getAggregatedRoiSavedTimeForNLastMonthsQueryValidator = z
    .object({
        restaurant_ids: z.array(objectIdValidator).nullish(),
        previous_period: z.string(),
    })
    .transform((data) => ({ restaurantIds: data.restaurant_ids ?? [], previousPeriod: data.previous_period === 'true' }));

export type GetAggregatedRoiSavedTimeForNLastMonthsQueryDto = z.infer<typeof getAggregatedRoiSavedTimeForNLastMonthsQueryValidator>;

// ----------------------------------------------------------

export const getRoiPerformanceScoreForNLastMonthsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        nb_months: z.string(),
    })
    .transform((data) => ({ restaurantId: data.restaurant_id, nbMonths: parseInt(data.nb_months, 10) }));

export type GetRoiPerformanceScoreForNLastMonthsParamsDto = z.infer<typeof getRoiPerformanceScoreForNLastMonthsParamsValidator>;

export const getRoiPerformanceScoreForNLastMonthsQueryValidator = z
    .object({
        similar_locations: z.string(),
        visibility: z.nativeEnum(RoiPageVisibilityStatus),
    })
    .transform((data) => ({ similarLocations: data.similar_locations === 'true', visibility: data.visibility }));

export type GetRoiPerformanceScoreForNLastMonthsQueryDto = z.infer<typeof getRoiPerformanceScoreForNLastMonthsQueryValidator>;

// ----------------------------------------------------------

export const getAggregatedRoiPerformanceScoreForNLastMonthsParamsValidator = z
    .object({
        nb_months: z.string(),
    })
    .transform((data) => ({ nbMonths: parseInt(data.nb_months, 10) }));

export type GetAggregatedRoiPerformanceScoreForNLastMonthsParamsDto = z.infer<
    typeof getAggregatedRoiPerformanceScoreForNLastMonthsParamsValidator
>;

export const getAggregatedRoiPerformanceScoreForNLastMonthsQueryValidator = z
    .object({
        restaurant_ids: z.array(objectIdValidator).nullish(),
    })
    .transform((data) => ({ restaurantIds: data.restaurant_ids ?? [] }));

export type GetAggregatedRoiPerformanceScoreForNLastMonthsQueryDto = z.infer<
    typeof getAggregatedRoiPerformanceScoreForNLastMonthsQueryValidator
>;

// ----------------------------------------------------------

export const getRoiTipsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetRoiTipsParamsDto = z.infer<typeof getRoiTipsParamsValidator>;

// ----------------------------------------------------------

export const getRoiAdditionalCustomersForNLastMonthsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        nb_months: z.string(),
    })
    .transform((data) => ({ restaurantId: data.restaurant_id, nbMonths: parseInt(data.nb_months, 10) }));

export type GetRoiAdditionalCustomersForNLastMonthsParamsDto = z.infer<typeof getRoiAdditionalCustomersForNLastMonthsParamsValidator>;

export const getRoiAdditionalCustomersForNLastMonthsQueryValidator = z
    .object({
        similar_locations: z.string(),
    })
    .transform((data) => ({ similarLocations: data.similar_locations === 'true' }));

export type GetRoiAdditionalCustomersForNLastMonthsQueryDto = z.infer<typeof getRoiAdditionalCustomersForNLastMonthsQueryValidator>;

// ----------------------------------------------------------

export const getRoiAdditionalCustomersForRestaurantsForNLastMonthsParamsValidator = z
    .object({
        nb_months: z.string(),
    })
    .transform((data) => ({ nbMonths: parseInt(data.nb_months, 10) }));

export type GetRoiAdditionalCustomersForRestaurantsForNLastMonthsParamsDto = z.infer<
    typeof getRoiAdditionalCustomersForRestaurantsForNLastMonthsParamsValidator
>;

export const getRoiAdditionalCustomersForRestaurantsForNLastMonthsQueryValidator = z
    .object({
        similar_locations: z.string(),
        restaurant_ids: z.array(objectIdValidator).nullish(),
    })
    .transform((data) => ({ restaurantIds: data.restaurant_ids ?? [], similarLocations: data.similar_locations === 'true' }));

export type GetRoiAdditionalCustomersForRestaurantsForNLastMonthsQueryDto = z.infer<
    typeof getRoiAdditionalCustomersForRestaurantsForNLastMonthsQueryValidator
>;

// ----------------------------------------------------------

export const getAggregatedRoiAdditionalCustomersForNLastMonthsParamsValidator = z
    .object({
        nb_months: z.string(),
    })
    .transform((data) => ({ nbMonths: parseInt(data.nb_months, 10) }));

export type GetAggregatedRoiAdditionalCustomersForNLastMonthsParamsDto = z.infer<
    typeof getAggregatedRoiAdditionalCustomersForNLastMonthsParamsValidator
>;

export const getAggregatedRoiAdditionalCustomersForNLastMonthsQueryValidator = z
    .object({
        restaurant_ids: z.array(objectIdValidator).nullish(),
    })
    .transform((data) => ({ restaurantIds: data.restaurant_ids ?? [] }));

export type GetAggregatedRoiAdditionalCustomersForNLastMonthsQueryDto = z.infer<
    typeof getAggregatedRoiAdditionalCustomersForNLastMonthsQueryValidator
>;

// ----------------------------------------------------------

export const watchRoiInsightsCreationParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type WatchRoiInsightsCreationParamsDto = z.infer<typeof watchRoiInsightsCreationParamsValidator>;

// ----------------------------------------------------------

export const fetchRoiInsightsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type FetchRoiInsightsParamsDto = z.infer<typeof fetchRoiInsightsParamsValidator>;

// ----------------------------------------------------------

export const checkHasInsightsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type CheckHasInsightsParamsDto = z.infer<typeof checkHasInsightsParamsValidator>;

// ----------------------------------------------------------

export const checkHasInsightsQueryValidator = z.object({
    visibility: z.nativeEnum(RoiPageVisibilityStatus),
});

export type CheckHasInsightsQueryDto = z.infer<typeof checkHasInsightsQueryValidator>;
