import { NgClass, NgStyle } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SocialAttachment } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-attachments-preview-modal',
    templateUrl: './attachments-preview-modal.component.html',
    styleUrls: ['./attachments-preview-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, NgStyle, ImagePathResolverPipe, LazyLoadImageModule],
})
export class AttachmentsPreviewModalComponent {
    readonly SvgIcon = SvgIcon;
    currentIndex = 0;

    constructor(
        public dialogRef: MatDialogRef<AttachmentsPreviewModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            attachments: SocialAttachment[];
            index: number;
        }
    ) {
        this.currentIndex = this.data.index || 0;
    }

    close(): void {
        this.dialogRef.close();
    }

    nextMedia(): void {
        if (this.currentIndex === this.data.attachments.length - 1) {
            return;
        }
        this.currentIndex += 1;
        this.updateMinWidth();
    }

    previousMedia(): void {
        if (this.currentIndex === 0) {
            return;
        }
        this.currentIndex -= 1;
        this.updateMinWidth();
    }

    updateMinWidth(): void {
        setTimeout(() => {
            const modal = document.getElementById('mediaModal');
            modal?.style?.setProperty('min-width', 'unset');
            const width = modal?.clientWidth;
            modal?.style?.setProperty('min-width', width + 'px');
        }, 1);
    }
}
