import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, filter, from, map, Observable, switchMap } from 'rxjs';

import { PictureSize } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { EditImageDialogComponent } from ':shared/components/edit-image-dialog/edit-image-dialog.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Media, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaService } from '../../../media/media.service';

export enum MediaAction {
    RENAME = 'rename',
    DUPLICATE = 'duplicate',
    EDIT_MEDIA = 'edit_media',
    DOWNLOAD = 'download',
    DELETE = 'delete',
}

@Component({
    selector: 'app-media-preview-modal',
    templateUrl: './media-preview-modal.component.html',
    styleUrls: ['./media-preview-modal.component.scss'],
    standalone: true,
    imports: [LazyLoadImageModule, MatIconModule, MatButtonModule, MatMenuModule, TranslateModule, ApplySelfPurePipe, IncludesPipe],
})
export class MediaPreviewModalComponent implements OnInit, OnDestroy {
    readonly SvgIcon = SvgIcon;
    readonly MediaAction = MediaAction;
    readonly DuplicationDestination = DuplicationDestination;
    readonly medias$ = new BehaviorSubject(null);
    readonly PictureSize = PictureSize;

    currentIndex: number;
    mediaTitleFormControl: UntypedFormControl;
    isDownloading = false;
    renameMediaModalOpened = false;

    constructor(
        private readonly _mediaService: MediaService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogRef: MatDialogRef<MediaPreviewModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            mediaIndex: number;
            medias: Media[];
            restaurant: Restaurant;
            actions: MediaAction[];
            deleteMedia: (media: Media) => void;
            duplicate: (to: string, media: Media) => void;
            renameMedia: (media: Media, callback: () => void) => void;
        }
    ) {}

    ngOnInit(): void {
        this._initPreviousAndNextAndEscapeButtonsOnKeyPressListener();
        this.currentIndex = this.data.mediaIndex;
        this.mediaTitleFormControl = new UntypedFormControl(this.data.medias[this.currentIndex]?.title);
    }

    nextMedia(): void {
        if (this.currentIndex === this.data.medias.length - 1) {
            return;
        }
        this.currentIndex += 1;
    }

    previousMedia(): void {
        if (this.currentIndex === 0) {
            return;
        }
        this.currentIndex -= 1;
    }

    deleteMedia(): void {
        const medium = this.data.medias[this.currentIndex];
        this.data.deleteMedia(medium);
    }

    duplicateMedia(to: string): void {
        const medium = this.data.medias[this.currentIndex];
        this.data.duplicate(to, medium);
    }

    close(): void {
        this._dialogRef.close({ medias: this.data.medias });
    }

    downloadSingleMedia(): void {
        this.isDownloading = true;
        const media = this.data.medias[this.currentIndex];
        this._mediaService.downloadSingleMedia(media).subscribe({
            next: (blob) => {
                saveAs(blob, `${media.getFullname()}`);
                this.isDownloading = false;
            },
            error: (err) => {
                this.isDownloading = false;
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    showEditModal(): void {
        const media = this.data.medias[this.currentIndex];
        this._customDialogService
            .open(EditImageDialogComponent, {
                data: {
                    imgUrl: media?.urls?.igFit || media?.urls?.original,
                },
            })
            .afterClosed()
            .pipe(
                map((res) => res?.croppedImage),
                filter(Boolean),
                switchMap((mediaBase64: string) => this._transformMedia(mediaBase64)),
                switchMap((file) => this._mediaService.replaceMediaUrls(media.id, file))
            )
            .subscribe({
                next: (res) => {
                    const newMedia = new Media(res.data);
                    const mediaIndex = this.data.medias.findIndex((m) => m.id === media.id);
                    this.data.medias.splice(mediaIndex, 1, newMedia);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    renameMedia(): void {
        this.renameMediaModalOpened = true;
        this.data.renameMedia(this.data.medias[this.currentIndex], () => (this.renameMediaModalOpened = false));
    }

    ngOnDestroy(): void {
        document.removeEventListener('keydown', () => {});
    }

    private _initPreviousAndNextAndEscapeButtonsOnKeyPressListener(): void {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowRight' && !this.renameMediaModalOpened) {
                this.nextMedia();
            }
            if (event.key === 'ArrowLeft' && !this.renameMediaModalOpened) {
                this.previousMedia();
            }
            if (event.key === 'Escape') {
                this.close();
            }
        });
    }

    private _transformMedia(mediaBase64: string): Observable<File> {
        return from(
            fetch(mediaBase64)
                .then((res) => res.blob())
                .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
        );
    }
}
