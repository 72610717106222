import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType, Scale, TooltipItem } from 'chart.js';
import { compact } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { RankingPosition } from ':modules/keywords/keywords-list/keywords-list.component.interface';
import { ChartDataArray, DEFAULT_POINT_RADIUS, malouChartColorBluePurple } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';

type LineChartType = Extract<ChartType, 'line'>;

@Component({
    selector: 'app-keyword-evolution-chart',
    templateUrl: './keyword-evolution-chart.component.html',
    styleUrls: ['./keyword-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
})
export class KeywordEvolutionChartComponent implements OnInit, OnChanges {
    @Input() positions: RankingPosition[];

    chartLabels: Date[] = [];
    chartDataSets: ChartDataset<LineChartType, ChartDataArray>[];
    chartOptions: ChartOptions<LineChartType>;

    readonly CHART_TYPE: LineChartType = 'line';

    ngOnInit(): void {
        this.chartOptions = this._computeChartOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'positions')) {
            this.positions = (this.positions || []).sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
            this.chartLabels = this._computeChartLabels(this.positions);
            this.chartDataSets = this._computeChartData(this.positions);
        }
    }

    private _computeChartData(positions: RankingPosition[]): ChartDataset<LineChartType, ChartDataArray>[] {
        return [
            {
                data: positions.map((elem) => {
                    if (elem.position === undefined) {
                        return null;
                    }
                    // if no data, we consider that the position is 20 (last positioned)
                    if (!elem.position) {
                        return 20;
                    }

                    // if rank is infinity, we consider that the position is outOf (last positioned)
                    if (elem.position.rank === Infinity) {
                        return elem.position.outOf;
                    }

                    return elem.position.rank;
                }),
                borderColor: malouChartColorBluePurple,
                pointBackgroundColor: malouChartColorBluePurple,
                pointBorderColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                pointRadius: this.positions.filter((elem) => elem.position !== undefined).length === 1 ? DEFAULT_POINT_RADIUS : 0,
            },
        ];
    }

    private _getUnknownPositions(): number[] {
        return compact(
            this.positions.map((positions, index) => {
                if (!positions.position || positions.position.rank === Infinity) {
                    return index;
                }
            })
        );
    }

    private _computeChartLabels(positions: RankingPosition[]): Date[] {
        return positions.map((elem) => elem.createdAt);
    }

    private _computeChartOptions(): ChartOptions<LineChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem: TooltipItem<any>): string => {
                            if (this._getUnknownPositions().includes(tooltipItem.dataIndex)) {
                                return '> ' + tooltipItem.formattedValue;
                            }
                            return tooltipItem.formattedValue;
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    grid: {
                        display: false,
                    },
                },
                yAxis: {
                    axis: 'y',
                    min: 0,
                    max: 21,
                    reverse: true,
                    // trick not to have the line flatted when the value is min or max
                    afterBuildTicks: function (axis: Scale): void {
                        axis.ticks = [];
                        axis.ticks.push({ value: 1 });
                        axis.ticks.push({ value: 5 });
                        axis.ticks.push({ value: 10 });
                        axis.ticks.push({ value: 15 });
                        axis.ticks.push({ value: 20 });
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        };
    }
}
