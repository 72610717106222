<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="close()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <div class="malou-dialog">
        <div class="flex h-full w-[630px] md:!w-auto" [ngClass]="{ '!w-[70vw] md:flex-col': currentStep === 3 }">
            <div class="flex flex-2 flex-col">
                <div class="malou-dialog__header">
                    <p>
                        {{ 'campaigns.create.title' | translate }}
                    </p>
                    <button
                        class="malou-btn-icon"
                        color="primary"
                        mat-icon-button
                        [ngClass]="{ '!hidden': currentStep === 3 && !screenSizeService.isPhoneScreen }"
                        (click)="close(true)">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    </button>
                </div>

                <div class="malou-dialog__body" [ngClass]="{ 'md:!overflow-hidden': currentStep === 3 }" #currentStepBody>
                    <div class="flex h-full flex-col">
                        <ng-container *ngTemplateOutlet="customStepper"></ng-container>
                        <div class="flex flex-1 flex-col" #bodySteps>
                            <ng-container *ngTemplateOutlet="dialogBody"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="malou-dialog__footer" [ngClass]="{ '!justify-center': currentStep === 4, 'md:!hidden': currentStep === 3 }">
                    <ng-container *ngTemplateOutlet="buttonsNextStep"></ng-container>
                </div>
            </div>

            <div class="flex-[1.4] md:px-[26px] md:pb-24 md:pt-5" [ngClass]="{ '!hidden': currentStep !== 3 }">
                <div class="malou-dialog !rounded-r-[10px] border !border-malou-color-border-primary !bg-malou-color-background-light">
                    <div class="malou-dialog__header">
                        <p>
                            {{ 'campaigns.create.overview' | translate }}
                        </p>
                        <button class="malou-btn-icon md:!hidden" mat-icon-button (click)="close(true)">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                        </button>
                    </div>
                    <div class="malou-dialog__body" [ngClass]="{ 'md:!overflow-hidden': currentStep === 3 }">
                        <ng-container *ngTemplateOutlet="overview"></ng-container>
                    </div>

                    <div class="malou-dialog__footer md:flex-col">
                        <div class="w-full" [matTooltip]="(!isEmailConfirmed ? 'campaigns.create.confirm_email_required' : '') | translate">
                            <button
                                class="malou-btn-raised--secondary--alt !h-11 !w-full md:mb-14"
                                mat-raised-button
                                [disabled]="campaignWriteMessageForm.invalid || !isEmailConfirmed"
                                (click)="sendTestEmail()">
                                {{ 'campaigns.new_campaign.send_test_email' | translate }}
                            </button>
                        </div>
                        <div class="hidden md:fixed md:bottom-0 md:flex md:w-full md:gap-4 md:bg-white md:p-[26px]">
                            <ng-container *ngTemplateOutlet="buttonsNextStep"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dialogBody>
    <div [hidden]="currentStep !== 1">
        <ng-container *ngTemplateOutlet="settings"></ng-container>
    </div>
    <div [hidden]="currentStep !== 2">
        <ng-container *ngTemplateOutlet="targetPeople"></ng-container>
    </div>
    <div [hidden]="currentStep !== 3">
        <ng-container *ngTemplateOutlet="writeMessage"></ng-container>
    </div>
    <div class="flex-1 justify-center" [hidden]="currentStep !== 4">
        <ng-container *ngTemplateOutlet="confirmEmail"></ng-container>
    </div>
</ng-template>

<ng-template #settings>
    <form class="mt-8 flex flex-col gap-8" [formGroup]="campaignSettingsForm">
        <app-input-text
            formControlName="name"
            [required]="true"
            [title]="'campaigns.create.form.name' | translate"
            [placeholder]="'campaigns.create.form.name_placeholder' | translate">
        </app-input-text>

        <app-select
            class="hidden"
            formControlName="contactMode"
            [required]="true"
            [values]="contactModesList"
            [displayWith]="displayWithContactMode"
            [title]="'campaigns.create.form.mode' | translate"
            [placeholder]="'common.search' | translate">
        </app-select>

        <app-select
            class="grow md:self-stretch"
            formControlName="platformKey"
            [required]="true"
            [title]="'campaigns.create.form.platform' | translate"
            [values]="platformsForReview">
            <ng-template let-value="value" #simpleSelectedValueTemplate>
                <div class="flex h-full items-center">
                    <app-platform-logo imgClasses="h-8" [logo]="value"></app-platform-logo>
                    <span class="malou-text-12 ml-2 text-malou-color-text-1">{{ value | platformName }}</span>
                </div>
            </ng-template>
            <ng-template let-value="value" #optionTemplate>
                <div class="flex items-center gap-x-2">
                    <app-platform-logo imgClasses="h-8" [logo]="value"></app-platform-logo>
                    <span class="malou-text-12 ml-2 text-malou-color-text-1">{{ value | platformName }}</span>
                </div>
            </ng-template>
        </app-select>

        <div class="flex flex-col gap-3">
            <span class="malou-text-10--regular text-malou-color-text-2">
                {{ 'campaigns.create.form.redirectFrom' | translate }}
            </span>
            <mat-button-toggle-group
                class="malou-single-toggle-icon-btn--tertiary"
                name="fontStyle"
                formControlName="redirectFrom"
                aria-label="Font Style">
                <mat-button-toggle class="mr-4 !h-12.5 min-w-[63px] rounded-[10px]" value="four_stars">
                    <span>4&5</span>
                    <mat-icon class="ml-2 mt-[-3px] !h-5" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="!h-12.5 min-w-[63px] rounded-[10px]" value="five_stars">
                    <span>5</span>
                    <mat-icon class="mt-[-3px] !h-5" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </form>
</ng-template>

<ng-template #targetPeople>
    <form class="mt-8 flex flex-col gap-8" [formGroup]="campaignTargetPeopleForm">
        <div formGroupName="audience">
            <app-select-platforms
                class="min-w-0 grow md:self-stretch"
                formControlName="sources"
                [required]="true"
                [title]="'campaigns.create.form.sources' | translate"
                [values]="CLIENTS_SOURCE"
                [displayWith]="displayWithSelectPlatforms"></app-select-platforms>
        </div>
        <div class="flex gap-4">
            <div class="flex-1">
                <app-input-date-picker
                    formControlName="startDate"
                    [max]="endDate"
                    [required]="true"
                    [title]="
                        (screenSizeService.isPhoneScreen ? 'campaigns.create.form.dates_mobile' : 'campaigns.create.form.dates') | translate
                    "></app-input-date-picker>
            </div>
            <div class="flex-1">
                <app-input-date-picker formControlName="endDate" [title]="'&nbsp;'" [min]="startDate" [max]="TODAY"></app-input-date-picker>
            </div>
        </div>

        <app-select
            formControlName="minDaysFromLastContactedAt"
            [required]="true"
            [title]="'campaigns.create.form.last_contact' | translate"
            [placeholder]="'campaigns.create.form.last_contact_placeholder' | translate"
            [values]="DAYS_RANGE_FOR_CONTACT"></app-select>

        <mat-checkbox
            class="malou-text-10--regular whitespace-normal !leading-[1.5px] text-malou-color-text-2"
            formControlName="consent"
            color="primary">
            {{ 'campaigns.create.form.confirm_agreement' | translate }}</mat-checkbox
        >
    </form>
    <div class="malou-expansion-panel mt-5">
        <mat-accordion>
            <mat-expansion-panel class="bg-malou-color-background-dark" [disabled]="!dataSource.data.length">
                <mat-expansion-panel-header [ngClass]="{ '!text-malou-color-text-1': !dataSource.data.length }">
                    <mat-panel-title class="malou-text-12 px-2 text-malou-color-text-1 sm:m-0 sm:px-0">
                        <div
                            innerHTML="{{
                                'campaigns.create.form.clients_text'
                                    | translate: { nbClients: dataSource.data.length, nbSelectedClients: contactInteractions?.length }
                            }}"></div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                @if (dataSource.filteredData.length) {
                    <mat-table class="malou-mat-table px-4 pb-4" matSort [dataSource]="dataSource" #table="matTable">
                        <ng-container matColumnDef="lastName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div class="flex flex-col">
                                    {{ 'clients.lastname' | translate }}
                                </div>
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let client; table: table"
                                class="font-semibold !text-malou-color-text-1"
                                [matTooltip]="client.lastName && client.lastName.length > 9 ? client.lastName : ''">
                                {{ (client.lastName | shortText: 9) || '-' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="firstName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'clients.firstname' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let client; table: table"
                                [matTooltip]="client.firstName && client.firstName.length > 9 ? client.firstName : ''">
                                {{ (client.firstName | shortText: 9) || '-' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'clients.email' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let client; table: table"
                                [matTooltip]="client.email.length > emailLimitCharactersDisplay ? client.email : ''">
                                {{ (client.email | shortText: emailLimitCharactersDisplay) || '-' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastVisitedAt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'clients.last_visit' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table">
                                <div class="flex gap-1">
                                    <span>
                                        {{ (client.lastVisitedAt | formatDate: 'shortDate') || '-' }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="enable">
                            <mat-header-cell *matHeaderCellDef>
                                {{ 'campaigns.send' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table; let i = index">
                                <app-slide-toggle [checked]="client.enable" (onToggle)="onToggleEnableClient(client)"></app-slide-toggle>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS" class="sm:hidden"></mat-header-row>
                        <mat-row *matRowDef="let client; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
                    </mat-table>
                }
                <app-paginator [shouldBeHidden]="dataSource.filteredData.length < 10" [pageSize]="20" [pageSizeOptions]="[10, 20, 30]">
                </app-paginator>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<ng-template #writeMessage>
    <form class="mt-8 flex flex-col gap-8" [formGroup]="campaignWriteMessageForm">
        <div class="flex flex-col gap-8" formGroupName="content">
            <div class="flex items-end gap-4 md:flex-col md:gap-0" formGroupName="from">
                <div class="flex-1">
                    <app-input-text formControlName="name" [required]="true" [title]="'campaigns.create.form.from' | translate">
                    </app-input-text>
                </div>
                <div class="flex-1" [matTooltip]="!isEmailConfirmed ? warningMessage : ''">
                    <app-input-text formControlName="email"></app-input-text>
                </div>
            </div>
            <app-input-text formControlName="object" [required]="true" [title]="'campaigns.create.form.object' | translate">
            </app-input-text>
            <div class="relative">
                <div class="malou-text-12 my-2 flex cursor-pointer flex-wrap gap-2 text-malou-color-text-2">
                    <div
                        class="cursor-pointer rounded-md bg-malou-chip-background-primary p-2"
                        matRipple
                        (click)="addChipToMessageHTML(NEW_CAMPAIGN_TRANSLATE.client_firstname)">
                        <span>{{ 'campaigns.new_campaign.client_firstname' | translate }}</span>
                    </div>
                    <div
                        class="cursor-pointer rounded-md bg-malou-chip-background-primary p-2"
                        matRipple
                        (click)="addChipToMessageHTML(NEW_CAMPAIGN_TRANSLATE.client_lastname)">
                        <span>{{ 'campaigns.new_campaign.client_lastname' | translate }}</span>
                    </div>
                    <div
                        class="cursor-pointer rounded-md bg-malou-chip-background-primary p-2"
                        matRipple
                        (click)="addChipToMessageHTML(NEW_CAMPAIGN_TRANSLATE.business_name)">
                        <span>{{ 'campaigns.new_campaign.business_name' | translate }}</span>
                    </div>
                </div>
                <app-text-area
                    id="messageHTML"
                    formControlName="messageHTML"
                    [maxLength]="MAX_LENGTH_MESSAGE"
                    [rows]="4"
                    [required]="true"
                    [title]="'campaigns.create.form.html' | translate">
                </app-text-area>
                <div class="malou-text-10 flex justify-end text-malou-color-text-2">
                    <div>({{ messageHTML?.length }}/{{ MAX_LENGTH_MESSAGE }})</div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #confirmEmail>
    <ng-container *ngTemplateOutlet="!isEmailConfirmed ? emailNotConfirmed : emailConfirmed"></ng-container>

    <ng-template #emailNotConfirmed>
        <div class="flex h-full flex-col items-center justify-center gap-4">
            <img class="h-32" alt="Cape" [src]="'Cape' | illustrationPathResolver" />
            <div class="mt-6">
                <div class="malou-text-14--bold text-center text-malou-color-text-1">
                    {{ 'campaigns.confirm_email.warning' | translate }}
                </div>
                <div class="malou-text-14--bold text-center text-malou-color-text-1">
                    {{ 'campaigns.confirm_email.warning_email' | translate }}
                </div>
            </div>
            <div class="malou-text-10 text-center text-malou-color-text-2">
                {{ 'campaigns.confirm_email.warning_email_details' | translate: { email: user.email } }}
            </div>
            <div class="malou-text-10 text-center italic text-malou-color-text-2">
                {{ 'campaigns.confirm_email.require_email_confirm' | translate }}
            </div>
            @if (!isEmailConfirmed) {
                <app-malou-spinner size="medium"></app-malou-spinner>
            }
        </div>
    </ng-template>

    <ng-template #emailConfirmed>
        <div class="flex h-full flex-col items-center justify-center gap-4">
            <img class="h-40" alt="Cape" [src]="'Karl' | illustrationPathResolver" />
            <div class="mt-6">
                <div class="malou-text-14--bold text-center text-malou-color-text-1">
                    {{ 'campaigns.confirm_email.confirmed' | translate }}
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #overview>
    <div class="flex h-full flex-col justify-between">
        <div class="malou-text-12--regular text-malou-color-text-2">
            <div>
                {{ 'campaigns.new_campaign.from' | translate }} <strong>{{ name }}</strong>
            </div>
            <div class="mt-1"><{{ email }}></div>
            <div class="mt-4 md:mt-0">{{ 'campaigns.new_campaign.object' | translate }} : {{ object }}</div>
        </div>
        <div class="flex flex-col">
            @if (displayMessageHTML) {
                <p
                    class="malou-text-12--medium mb-14 break-all text-malou-color-text-1 md:mt-8 md:leading-[2]"
                    [innerHTML]="displayMessageHTML"></p>
            }
            <div class="mb-2 flex justify-center gap-3 md:mb-14">
                @for (i of [1, 2, 3, 4, 5]; track i) {
                    <mat-icon class="small-icon text-malou-color-primary opacity-30" [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                }
            </div>
        </div>
        <div class="malou-text-10--regular text-center text-malou-color-text-2">
            <div>{{ 'campaigns.new_campaign.no_more_email' | translate: { restaurantName: restaurant.name } }}</div>
            <div class="mt-4 font-semibold text-malou-color-primary">{{ 'campaigns.new_campaign.unsubscribe' | translate }}</div>
        </div>
    </div>
</ng-template>

<ng-template #customStepper>
    <div
        class="w-full rounded-xl border border-malou-color-border-primary bg-malou-color-background-light p-6.5"
        [hidden]="currentStep === 4">
        <div class="stepper-header relative flex w-full items-center justify-between pb-2 md:justify-start md:gap-x-3">
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [class.ink-bar-mobile-before]="currentStep === 1"
                [class.!malou-text-12--bold]="currentStep === 1"
                (click)="goToStep(1)">
                <span class="mr-1">1.</span>
                <div class="transition-all md:w-0 lg:invisible lg:h-0" [ngClass]="{ '!visible lg:h-fit lg:w-fit': currentStep === 1 }">
                    {{ 'keywords.generator.establishment_type_step' | translate }}
                </div>
            </div>
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [matRippleDisabled]="cantGoToStep(1)"
                [class.ink-bar-mobile-before]="currentStep === 2"
                [class.!malou-text-12--bold]="currentStep === 2"
                (click)="goToStep(2)">
                <span class="mr-1">2.</span>
                <div class="transition-all lg:invisible lg:h-0 lg:w-0" [ngClass]="{ '!visible lg:h-fit lg:w-fit': currentStep === 2 }">
                    {{ 'keywords.generator.localisation_step' | translate }}
                </div>
            </div>
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [matRippleDisabled]="cantGoToStep(2)"
                [class.ink-bar-mobile-before]="currentStep === 3"
                [class.!malou-text-12--bold]="currentStep === 3"
                (click)="goToStep(3)">
                <span class="mr-1">3.</span>
                <div class="transition-all lg:invisible lg:h-0 lg:w-0" [ngClass]="{ '!visible lg:h-fit lg:w-fit': currentStep === 3 }">
                    {{ 'keywords.generator.further_information_step' | translate }}
                </div>
            </div>
            <div
                class="ink-bar transition-all"
                [ngClass]="{
                    '!w-1/3': currentStep === 1,
                    '!w-2/3': currentStep === 2,
                    '!w-full': currentStep === 3,
                }"></div>
        </div>
    </div>
</ng-template>

<ng-template #buttonsNextStep>
    <button class="malou-btn-raised--secondary !h-11 md:!w-full" mat-raised-button (click)="currentStep > 1 ? previous() : close(true)">
        {{ (currentStep > 1 ? 'common.previous' : 'common.cancel') | translate }}
    </button>

    <app-button
        class="md:w-full"
        buttonClasses="!h-11 md:w-full"
        [disabled]="cantGoToStep(currentStep)"
        [text]="(isLastStep() ? (isPhoneScreen() ? 'common.validate' : 'campaigns.confirm') : 'common.next') | translate"
        [loading]="isCreatingCampaign()"
        (buttonClick)="isLastStep() ? createCampaign() : next()"></app-button>
</ng-template>
