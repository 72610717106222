import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { ROI_HIDDEN_FIRST_DAYS_NUMBER } from '@malou-io/package-utils';

import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';

@Component({
    selector: 'app-estimated-customers-banner',
    templateUrl: './estimated-customers-banner.component.html',
    styleUrls: ['./estimated-customers-banner.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [EmojiPathResolverPipe, TranslateModule, MatIconModule, MatTooltipModule, NgTemplateOutlet, SkeletonComponent],
})
export class EstimatedCustomersBannerComponent {
    readonly isParentLoading = input.required<boolean>();
    readonly limitDate = input.required<Date | undefined>();

    readonly isInTheLastFourDays: Signal<boolean> = computed(() => {
        const limitDate = this.limitDate();
        if (limitDate) {
            const today = DateTime.now();
            const startMonthToShowBanner = DateTime.fromJSDate(limitDate).plus({ days: ROI_HIDDEN_FIRST_DAYS_NUMBER }).month;
            if (today.month === startMonthToShowBanner + 1) {
                return true;
            }
        }
        return false;
    });
}
