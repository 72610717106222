import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TimeInMilliseconds } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

enum LoaderStepStatus {
    DONE = 'done',
    CURRENT = 'current',
    TO_BE_DONE = 'to be done',
}

@Component({
    selector: 'app-multiple-steps-loader',
    templateUrl: './multiple-steps-loader.component.html',
    styleUrls: ['./multiple-steps-loader.component.scss'],
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, MatIconModule, MalouSpinnerComponent, ApplyPurePipe, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleStepsLoaderComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly LoaderStepStatus = LoaderStepStatus;

    readonly steps = input.required<string[]>();
    readonly illustration = input<Illustration>(Illustration.Google);
    readonly currentStepIndex = signal(0);

    private readonly _STEP_DURATION = 2 * TimeInMilliseconds.SECOND; // for design purpose

    ngOnInit(): void {
        this._fakeWaitUntilNextStep();
    }

    getStepStatus(stepIndex: number, currentStepIndex: number): LoaderStepStatus {
        if (stepIndex < currentStepIndex) {
            return LoaderStepStatus.DONE;
        }
        if (stepIndex === currentStepIndex) {
            return LoaderStepStatus.CURRENT;
        }
        return LoaderStepStatus.TO_BE_DONE;
    }

    private _fakeWaitUntilNextStep(): void {
        setTimeout(() => {
            this._goToNextStep();
            if (this.currentStepIndex() < this.steps().length - 1) {
                this._fakeWaitUntilNextStep();
            }
        }, this._STEP_DURATION);
    }

    private _goToNextStep(): void {
        this.currentStepIndex.update((currentStepIndex) => currentStepIndex + 1);
    }
}
