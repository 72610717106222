@if (isValid()) {
    <div class="flex items-center gap-x-2">
        <mat-icon class="malou-color-state-success !h-3 !w-3" [svgIcon]="SvgIcon.DOT"></mat-icon>
        <span class="malou-color-state-success malou-text-10--regular">
            @if (expirationInDays()) {
                {{ 'platforms.connection_new.shared.access_information.permissions_expire_in' | translate: { days: expirationInDays() } }}
            } @else {
                {{ 'platforms.connection_new.shared.access_information.valid_permissions' | translate }}
            }
        </span>
    </div>
} @else {
    <div class="flex items-center gap-x-2">
        <mat-icon class="malou-color-state-warn !h-3 !w-3" [svgIcon]="SvgIcon.DOT"></mat-icon>
        <span class="malou-text-10--regular malou-color-state-warn">
            {{ 'platforms.connection_new.shared.access_information.missing_permissions' | translate }}
        </span>
    </div>
}
