export interface ReviewTemplatesFilters {
    search?: string;
    ratings?: number[];
    automated?: boolean;
    manual?: boolean;
    withText?: boolean;
    withoutText?: boolean;
}

export enum AutomationTemplateFilters {
    AUTOMATED = 'automated',
    MANUAL = 'manual',
}

export interface MessageTemplatesFilters {
    search?: string;
}
