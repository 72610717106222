import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { LightRestaurant } from ':shared/models';
import { Gift } from ':shared/models/gift';
import { GiftStock } from ':shared/models/gift-stock';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { TranslateInPipe } from ':shared/pipes/translate-in.pipe';

type GiftStockEdition = GiftStock & {
    initialStock: number | null;
    unlimited: boolean;
};

interface EditGiftStocksTab {
    label: string;
    key: TabKeys;
}

enum TabKeys {
    STOCKS = 'stocks',
    CONDITIONS = 'conditions',
}

export interface EditGiftStocksModalProps {
    initialStocks: GiftStock[];
    isUpdate: boolean;
    giftName: string;
    conditions?: Gift['conditions'];
}

export type EditGiftStocksModalOutput = {
    giftStocks: GiftStock[];
    conditions?: Gift['conditions'];
} | null;

@Component({
    selector: 'app-edit-gift-stocks-modal',
    templateUrl: './edit-gift-stocks-modal.component.html',
    standalone: true,
    styleUrls: ['./edit-gift-stocks-modal.component.scss'],
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
        InputNumberComponent,
        InputTextComponent,
        SlideToggleComponent,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        TranslateInPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGiftStocksModalComponent implements OnInit, OnDestroy {
    private readonly _translateService = inject(TranslateService);

    readonly trackByIdFn = TrackByFunctionFactory.get('id');
    readonly SvgIcon = SvgIcon;
    readonly initialStocks: WritableSignal<Map<string, number | null>> = signal(new Map());
    readonly restaurantGiftStocks: WritableSignal<GiftStockEdition[]> = signal([]);
    readonly restaurants = computed(() => this.restaurantGiftStocks().map((giftStock) => giftStock.restaurant));
    readonly disableSubmit = computed(() => this._isFormInvalid(this.restaurantGiftStocks()));

    readonly showCurrentStock = computed(() => (restaurantId: string): boolean => {
        const isInitiallyUnlimited = this.initialStocks().get(restaurantId) === null;
        const isStockInEdition = this.editingStocks().includes(restaurantId);
        return this.isUpdate() && !isStockInEdition && !isInitiallyUnlimited;
    });

    readonly editingStocks: WritableSignal<string[]> = signal([]);

    readonly GIFT_STOCK_MIN_QUANTITY = 0;

    readonly isUpdate = signal(false);
    readonly showCopyModal = signal(false);
    readonly currentCopyModalIndex: WritableSignal<number | null> = signal(null);
    readonly currentModalGiftStock: WritableSignal<GiftStockEdition | null> = signal(null);
    readonly isChecked = computed(
        () =>
            (restaurant: LightRestaurant): boolean =>
                restaurant.id === this.currentModalGiftStock()?.restaurant.id || this._restaurantIdsToCopyTo().includes(restaurant.id)
    );
    readonly areAllRestaurantsChecked = computed(() => {
        const currentRestaurant = this.currentModalGiftStock()?.restaurant;
        if (!currentRestaurant) {
            return false;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurant.id);

        return checkableRestaurants.every((checkableRestaurant) => this._restaurantIdsToCopyTo().includes(checkableRestaurant.id));
    });

    private readonly _STOCKS_TAB: EditGiftStocksTab = {
        key: TabKeys.STOCKS,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.tabs.stocks.title'),
    };
    private readonly _CONDITIONS_TAB: EditGiftStocksTab = {
        key: TabKeys.CONDITIONS,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.edit_gift_stocks_modal.tabs.conditions.title'),
    };
    readonly TABS = signal<EditGiftStocksTab[]>([this._STOCKS_TAB, this._CONDITIONS_TAB]);
    readonly selectedTabIndex = signal<number>(0);
    readonly selectedTab = computed<EditGiftStocksTab>(() => this.TABS()[this.selectedTabIndex()]);

    readonly TabKeys = TabKeys;
    readonly ApplicationLanguage = ApplicationLanguage;
    readonly APPLICATION_LANGUAGES = Object.values(ApplicationLanguage);

    readonly giftConditions = signal<Gift['conditions']>({});

    private readonly _restaurantIdsToCopyTo: WritableSignal<string[]> = signal([]);
    private readonly _currentCopiedQuantityAndUnlimited: WritableSignal<{ unlimited: boolean; quantity: null | number } | null> =
        signal(null);
    private readonly _giftStocksBeforeCopy: WritableSignal<GiftStockEdition[]> = signal([]);

    private _flagImg: Record<string, string> = {};

    constructor(
        private readonly _dialogRef: MatDialogRef<EditGiftStocksModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: EditGiftStocksModalProps
    ) {
        this.restaurantGiftStocks.set(
            this.data.initialStocks.map((giftStock) => ({
                id: giftStock.id,
                restaurant: giftStock.restaurant,
                initialStock: giftStock.quantity,
                unlimited: giftStock.quantity === null,
                quantity: giftStock.quantity || this.GIFT_STOCK_MIN_QUANTITY,
            }))
        );

        this.initialStocks.update((currentMap) => {
            this.data.initialStocks.forEach((giftStock) => {
                currentMap.set(giftStock.restaurant.id, giftStock.quantity);
            });
            return cloneDeep(currentMap);
        });

        this.giftConditions.set(this.data.conditions ?? {});

        this.isUpdate.set(this.data.isUpdate);

        const flagPathResolverPipe = new FlagPathResolverPipe();
        this.APPLICATION_LANGUAGES.forEach((l) => {
            this._flagImg[l] = flagPathResolverPipe.transform(l);
        });
    }

    ngOnInit(): void {
        this._initCloseModalOnClick();
    }

    ngOnDestroy(): void {
        document?.removeEventListener('click', this._onClick);
    }

    close(data?: { giftStocks: GiftStock[]; conditions: Gift['conditions'] }): void {
        this._dialogRef.close(data ? { giftStocks: data.giftStocks, conditions: data.conditions } : null);
    }

    editStocks(): void {
        const editedGiftStocks: GiftStock[] = this.restaurantGiftStocks().map((restaurantGiftStock) => ({
            id: restaurantGiftStock.id,
            restaurant: restaurantGiftStock.restaurant,
            quantity: restaurantGiftStock.unlimited ? null : restaurantGiftStock.quantity,
        }));

        this.close({ giftStocks: editedGiftStocks, conditions: this.giftConditions() });
    }

    toggleUnlimited(index: number): void {
        this.restaurantGiftStocks.update((currentGiftStocks) => {
            if (index < currentGiftStocks.length) {
                const existingGiftStock = currentGiftStocks[index];
                existingGiftStock.unlimited = !existingGiftStock.unlimited;
            }
            return [...currentGiftStocks];
        });
    }

    onQuantityChange(index: number, newQuantity: number | null): void {
        this.restaurantGiftStocks.update((currentGiftStocks) => {
            if (index < currentGiftStocks.length) {
                const existingGiftStock = currentGiftStocks[index];
                existingGiftStock.quantity = newQuantity;
            }
            return [...currentGiftStocks];
        });
    }

    copy(event: Partial<MouseEvent>, giftStock: GiftStockEdition, index: number): void {
        this._giftStocksBeforeCopy.set(cloneDeep(this.restaurantGiftStocks()));
        this.showCopyModal.set(true);
        this.currentCopyModalIndex.set(index);
        this.currentModalGiftStock.set(giftStock);
        this._currentCopiedQuantityAndUnlimited.set({ unlimited: giftStock.unlimited, quantity: giftStock.quantity });
        this._setDuplicateModalPosition(event);
    }

    toggleCopy(event: MatCheckboxChange, restaurant: LightRestaurant): void {
        if (event.checked) {
            this._restaurantIdsToCopyTo.update((currentRestaurantIds) => {
                if (!currentRestaurantIds.includes(restaurant.id)) {
                    currentRestaurantIds.push(restaurant.id);
                }
                return [...currentRestaurantIds];
            });

            this._pasteGiftStock(restaurant);
        } else {
            this._restaurantIdsToCopyTo.update((currentRestaurantIds) => {
                const index = currentRestaurantIds.findIndex((currentRestaurantId) => currentRestaurantId === restaurant.id);
                if (index >= 0) {
                    currentRestaurantIds.splice(index, 1);
                }
                return [...currentRestaurantIds];
            });

            this._resetGiftStock(restaurant);
        }
    }

    toggleAllCopy(event: MatCheckboxChange): void {
        const currentRestaurant = this.currentModalGiftStock()?.restaurant;
        if (!currentRestaurant) {
            return;
        }
        const checkableRestaurants = this.restaurants().filter((restaurant) => restaurant.id !== currentRestaurant.id);

        checkableRestaurants.forEach((restaurant) => this.toggleCopy(event, restaurant));
    }

    onEditStock(restaurantId: string): void {
        this.editingStocks.update((currentEditingStocks) => [...currentEditingStocks, restaurantId]);
    }

    onEditCondition(text: string, lang: ApplicationLanguage): void {
        this.giftConditions.update((currentConditions) => {
            if (!currentConditions) {
                return { [lang]: text };
            }
            currentConditions[lang] = text;
            return { ...currentConditions };
        });
    }

    handleTabChange(index: number): void {
        this.selectedTabIndex.set(index);
    }

    getFlagImg = (lang: string): string => this._flagImg[lang];

    private _pasteGiftStock(restaurant: LightRestaurant): void {
        const copiedQuantityAndUnlimited = this._currentCopiedQuantityAndUnlimited();
        if (!copiedQuantityAndUnlimited) {
            return;
        }

        this.restaurantGiftStocks.update((currentGiftStocks) => {
            const restaurantGiftStock = currentGiftStocks.find((giftStock) => giftStock.restaurant.id === restaurant.id);
            if (restaurantGiftStock) {
                restaurantGiftStock.unlimited = copiedQuantityAndUnlimited.unlimited;
                restaurantGiftStock.quantity = copiedQuantityAndUnlimited.quantity;
            }
            return [...currentGiftStocks];
        });
    }

    private _resetGiftStock(restaurant: LightRestaurant): void {
        this.restaurantGiftStocks.update((currentGiftStocks) => {
            const restaurantGiftStock = currentGiftStocks.find((giftStock) => giftStock.restaurant.id === restaurant.id);
            const previousRestaurantGiftStock = this._giftStocksBeforeCopy().find((giftStock) => giftStock.restaurant.id === restaurant.id);

            if (restaurantGiftStock && previousRestaurantGiftStock) {
                restaurantGiftStock.unlimited = previousRestaurantGiftStock.unlimited;
                restaurantGiftStock.quantity = previousRestaurantGiftStock.quantity;
            }
            return [...currentGiftStocks];
        });
    }

    private _setDuplicateModalPosition(event: Partial<MouseEvent>): void {
        setTimeout(() => {
            const copyModal = <HTMLElement>document.querySelector('#copyModal');
            const onTop = (event.clientY || 0) > 500;
            const style = onTop ? 'top: -295px;' : 'top: 36px;';
            copyModal.style.cssText = style;
        }, 50);
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (event) => this._onClick(event));
    }

    private _onClick(event: Event): void {
        const target = <HTMLElement>event.target;
        if (!target.closest('#copyModal') && !target.closest('#copyGiftStock')) {
            this._closeCopyModal();
        }
    }

    private _closeCopyModal(): void {
        this.showCopyModal.set(false);
        this._giftStocksBeforeCopy.set([]);
        this._restaurantIdsToCopyTo.set([]);
        this.currentModalGiftStock.set(null);
        this._currentCopiedQuantityAndUnlimited.set(null);
        this.currentCopyModalIndex.set(null);
    }

    private _isFormInvalid(giftStocks: GiftStockEdition[]): boolean {
        return giftStocks.some((giftStock) => !giftStock.unlimited && (giftStock.quantity === null || giftStock.quantity < 0));
    }
}
