import { Injectable } from '@angular/core';

import { parseAspectRatio } from ':shared/helpers/dimensions';
import { ResizeMetadata } from ':shared/models/media';

@Injectable({
    providedIn: 'root',
})
export class ResizeMetadataComputationService {
    computeResizeMetadata({
        mediaAspectRatio,
        desiredAspectRatio,
        width,
        height,
    }: {
        mediaAspectRatio: number;
        desiredAspectRatio: number;
        width: number;
        height: number;
    }): ResizeMetadata {
        if (parseAspectRatio(mediaAspectRatio) === desiredAspectRatio) {
            return {
                width,
                height,
                cropPosition: {
                    top: 0,
                    left: 0,
                },
                aspectRatio: desiredAspectRatio,
            };
        }

        const newWidth = parseAspectRatio(mediaAspectRatio) < desiredAspectRatio ? width : height * desiredAspectRatio;
        const newHeight = parseAspectRatio(mediaAspectRatio) < desiredAspectRatio ? width / desiredAspectRatio : height;
        return {
            width: newWidth,
            height: newHeight,
            cropPosition: {
                top: newHeight < height ? (height - newHeight) / 2 : 0,
                left: newWidth < width ? (width - newWidth) / 2 : 0,
            },
            aspectRatio: desiredAspectRatio,
        };
    }
}
