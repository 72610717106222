import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { ReviewResponseDto } from '@malou-io/package-dto';

import { PrivateReviewsService } from ':core/services/private-reviews.service';
import { AggregatedBoostersStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { Restaurant } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';

import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from '../csv-service.abstract';

interface Data {
    restaurants: Restaurant[];
    boostersData: AggregatedBoostersStatisticsData | undefined;
    privateReviews: ReviewResponseDto[];
}

@Injectable({ providedIn: 'root' })
export class AggregatedBoostersReviewsCountCsvInsightService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        private readonly _privateReviewsService: PrivateReviewsService
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin({
            restaurants: this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
            boostersData: this._store.select(AggregatedStatisticsSelectors.selectBoosterStatsData).pipe(take(1)),
        }).pipe(
            switchMap(({ restaurants, boostersData }) => {
                if (!boostersData) {
                    return forkJoin({
                        restaurants: of(restaurants),
                        boostersData: of(boostersData),
                        privateReviews: of([]),
                    });
                }
                const { scans, previousScans, startDate } = boostersData;
                const scanIds = [...scans.map((scan) => scan.id), ...previousScans.map((scan) => scan.id)];
                const privateReviewsDtos$ = scanIds.length
                    ? this._privateReviewsService.search({ scanIds }).pipe(map((apiResult) => apiResult.data))
                    : of<ReviewResponseDto[]>([]);
                const currentPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) >= startDate)
                    )
                );
                return forkJoin({
                    restaurants: of(restaurants),
                    boostersData: of(boostersData),
                    privateReviews: currentPrivateReviews$,
                });
            })
        );
    }

    protected override _isDataValid({ boostersData, restaurants }: Data): boolean {
        return !!boostersData && restaurants.length > 0;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Location', 'Location Internal Name', 'Location Address', 'Private Review Count', 'Collected Reviews Estimate'];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { boostersData, restaurants, privateReviews } = data;
        const scansByRestaurantId: Record<string, ScanForStats[]> = groupBy(boostersData.scans, 'nfcSnapshot.restaurantId');
        const groupedData: CsvAsStringArrays = [];
        Object.entries(scansByRestaurantId).map(([restaurantId, scans]) => {
            const restaurant = restaurants.find((r) => r.id === restaurantId);
            const estimatedReviewCount = scans.filter((scan) => scan.matchedReviewId).length;
            const privateReviewCount = privateReviews.filter((privateReview) => scans.find((s) => s.id === privateReview.scanId)).length;
            groupedData.push([
                restaurant?.name ?? '-',
                restaurant?.internalName ?? '-',
                restaurant?.address?.getFullFormattedAddress() ?? '-',
                privateReviewCount.toString() ?? '0',
                estimatedReviewCount.toString() ?? '0',
            ]);
        });
        return groupedData;
    }
}
