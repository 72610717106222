import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asType',
    standalone: true,
})
export class AsTypePipe implements PipeTransform {
    transform<S, T extends S>(value: S, _type?: new () => T): T {
        return value as T;
    }
}
