<app-menu-button-v2
    testId="social-post-open-create-post-modal-btn"
    [text]="'social_posts.create_post' | translate"
    [size]="MenuButtonSize.LARGE">
    <button class="flex !px-5" data-testid="social-post-menu-open-create-post-modal-btn" mat-menu-item (click)="onCreatePost()">
        <mat-icon color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'social_posts.publication' | translate }}</span>
    </button>
    <button
        class="flex !px-5"
        data-testid="social-post-menu-reel-open-create-post-modal-btn"
        mat-menu-item
        (click)="onCreateReelOrTikTok()">
        <mat-icon color="primary" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'social_posts.Reel' | translate }}</span>
    </button>
</app-menu-button-v2>
