export enum TemplateTranslationLang {
    FR = 'fr',
    EN = 'en',
}

export enum CommentOptionValue {
    WITH_OR_WITHOUT = 'withOrWithout',
    WITH = 'with',
    WITHOUT = 'without',
}
