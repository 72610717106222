import { ChangeDetectionStrategy, Component, inject, input, InputSignal, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { Keyword } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-update-keyword-lang',
    templateUrl: './update-keyword-lang.component.html',
    styleUrls: ['./update-keyword-lang.component.scss'],
    standalone: true,
    imports: [LazyLoadImageModule, MatIconModule, MatMenuModule, ApplyPurePipe, FlagPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateKeywordLangComponent {
    readonly keyword: InputSignal<Keyword> = input.required();
    readonly disabled: InputSignal<boolean> = input(false);

    readonly updateKeywordLang = output<{ keyword: Keyword; lang: ApplicationLanguage }>();

    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly applicationLanguages: ApplicationLanguage[] = Object.values(ApplicationLanguage);

    getPrettyLang = (lang: ApplicationLanguage | string): string => this._translateService.instant(`header.langs.${lang}`);

    updateKeywordLanguage(keyword: Keyword, lang: ApplicationLanguage): void {
        if (this.disabled()) {
            return;
        }
        this.updateKeywordLang.emit({ keyword, lang });
    }
}
