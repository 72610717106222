import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewerDisplayName',
    standalone: true,
})
export class ReviewerDisplayNamePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(review: Review | PrivateReview): string {
        if ((review as PrivateReview).scanId) {
            const totemTranslated = this._translate.instant('reviews.totem');
            const totemChipName = (review as PrivateReview).scan?.nfc?.chipName ?? '';
            return `${totemTranslated} ${totemChipName}`;
        }
        const displayName = review.getReviewerDisplayName()?.trim();
        if (displayName?.length || review.isPrivate()) {
            return displayName;
        }
        const mail = (<Review>review).reviewer?.email;
        const anonymous = this._translate.instant('reviews.anonymous');
        return `${anonymous}${mail ? ' (' + mail + ')' : ''}`;
    }
}
