import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as PlatformsActions from ':modules/platforms/store/platforms.actions';

@Injectable()
export class GroupsResolver {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);

    resolve(): any {
        this._restaurantsService.setSelectedRestaurant(null);
        this._store.dispatch(PlatformsActions.editSelectedRestaurantId({ restaurantId: '' }));
    }
}
