// https://uppy.io/docs/writing-plugins/#Creating-A-Plugin
import { UIPlugin, UppyFile } from '@uppy/core';

import { MediaService } from ':modules/media/media.service';
import { ErrorFileCategory } from ':shared/enums/error-file-category.enum';

export function isFileVideo(file: UppyFile<Record<string, unknown>, Record<string, unknown>>): boolean {
    return !!file.extension.match(/mp4|webm|ogg|ogv|mov|wmv|avi|flv|mkv|3gp|3g2|mpeg|mpg|m4v|m4a|f4v|f4a|f4b/i);
}

export class MalouVideoDurationPlugin extends UIPlugin {
    maxDurationInSeconds: number;
    mediaService: MediaService;

    constructor(uppy, opts) {
        super(uppy, opts);
        this.id = 'MalouVideoDurationPlugin';
        this.type = 'malou-video-duration';
        this.maxDurationInSeconds = opts?.maxDurationInSeconds || 60;
        this.mediaService = opts?.mediaService;
    }

    prepareUpload = (fileIDs): Promise<void> => {
        const checkVideoDuration: Promise<File>[] = fileIDs.map(async (fileID) => {
            const file = this.uppy.getFile(fileID);
            if (isFileVideo(file)) {
                const duration = await this._getDurationFromVideo(file.data as File);
                if (duration > this.maxDurationInSeconds) {
                    console.error(`Video duration is too long: ${duration} seconds`);
                    // not mentioned in the documentation but if we set field 'error' in setFileState,
                    // uppy will trigger 'upload-error' event
                    this.uppy.setFileState(file.id, {
                        error: JSON.stringify({
                            message: `Video duration is too long: ${duration} seconds`,
                            type: ErrorFileCategory.TOO_LONG_DURATION,
                            duration: duration,
                            maxDuration: this.maxDurationInSeconds,
                        }),
                    });
                }
            }
            return file;
        });

        const emitPreprocessCompleteForAll = (): void => {
            fileIDs.forEach((fileID) => {
                const file = this.uppy.getFile(fileID);
                this.uppy.emit('preprocess-complete', file);
            });
        };
        return Promise.all(checkVideoDuration).then(emitPreprocessCompleteForAll);
    };

    override install(): void {
        this.uppy.addPreProcessor(this.prepareUpload);
    }

    override uninstall(): void {
        this.uppy.removePreProcessor(this.prepareUpload);
    }

    /**
     * @param file
     * @returns duration in seconds
     */
    private _getDurationFromVideo(file: File): Promise<number> {
        return new Promise((resolve) => {
            const url = URL.createObjectURL(file);
            const video = document.createElement('video');
            video.src = url;
            const event = new CustomEvent('loadedmetadata');
            video.dispatchEvent(event);
            video.addEventListener(
                'loadedmetadata',
                function () {
                    const duration = this.duration;
                    resolve(duration);
                },
                false
            );

            video.addEventListener(
                'error',
                () => {
                    file.arrayBuffer().then((arrayBuffer) => {
                        const fileBuffer = Buffer.from(arrayBuffer);
                        const header = Buffer.from('mvhd');
                        const start = fileBuffer.indexOf(header) + 16;
                        const timeScale = fileBuffer.readUInt32BE(start);
                        const duration = fileBuffer.readUInt32BE(start + 4);

                        const lengthSeconds = Math.floor(duration / timeScale);

                        resolve(lengthSeconds);
                    });
                },
                false
            );
        });
    }
}
