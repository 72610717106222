import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { LastStep } from '../../connect-restaurant-modal/connect-restaurant-modal.component';

@Component({
    selector: 'app-after-create-restaurant-modal',
    templateUrl: './after-create-restaurant-modal.component.html',
    styleUrls: ['./after-create-restaurant-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, IllustrationPathResolverPipe],
})
export class AfterCreateRestaurantModalComponent {
    private readonly _dialogRef = inject(MatDialogRef<AfterCreateRestaurantModalComponent>);
    readonly data = inject<LastStep>(MAT_DIALOG_DATA);

    close(): void {
        this._dialogRef.close({ shouldOpenWelcomeModal: this.data.isErrorMessage });
    }
}
