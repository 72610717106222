import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { isNil } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { malouChartColorPurple } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { ReviewsRatingsAverageData } from '../reviews-ratings-average.component';

type BarChartType = Extract<ChartType, 'bar'>;

const RESTAURANT_NAME_MAX_LENGTH = 20;

@Component({
    selector: 'app-reviews-ratings-average-chart',
    templateUrl: './reviews-ratings-average-chart.component.html',
    styleUrls: ['./reviews-ratings-average-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
})
export class ReviewsRatingsAverageChartComponent implements OnChanges {
    @Input() reviewsRatingsAverageData: ReviewsRatingsAverageData[];
    @Input() previousReviewsRatingsAverageData: ReviewsRatingsAverageData[];
    @Input() sortBy: ChartSortBy;

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ReviewsRatingsAverageData[]>[];
    chartOption: ChartOptions<BarChartType>;

    readonly PLATFORM_KEYS_TRAD: Record<PlatformKey, string> = this._translateService.instant('enums.platform_key');

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _shortTextPipe: ShortTextPipe,
        private readonly _translateService: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviewsRatingsAverageData')) {
            this.chartDataSets = this._computeChartDataAndLabels(this.reviewsRatingsAverageData);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'reviewsRatingsAverageData', 'previousReviewsRatingsAverageData')) {
            this.chartOption = this._computeChartOptions();
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'sortBy')) {
            const sortedData = this._computeSortedData([...this.reviewsRatingsAverageData], this.sortBy);
            this.reviewsRatingsAverageData = sortedData;
            this.chartDataSets = this._computeChartDataAndLabels(this.reviewsRatingsAverageData);
        }
    }

    private _computeChartDataAndLabels(data: ReviewsRatingsAverageData[]): ChartDataset<BarChartType, ReviewsRatingsAverageData[]>[] {
        return [
            {
                borderColor: malouChartColorPurple,
                backgroundColor: malouChartColorPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data,
                parsing: {
                    xAxisKey: 'restaurant.internalName',
                    yAxisKey: 'averageRating',
                },
                minBarLength: 10,
            },
        ];
    }

    private _computeSortedData(data: ReviewsRatingsAverageData[], sortBy: ChartSortBy): ReviewsRatingsAverageData[] {
        switch (sortBy) {
            case ChartSortBy.ALPHABETICAL:
                return data.sort((a, b) => {
                    if (!a.restaurant.internalName || !b.restaurant.internalName) {
                        return 0;
                    }
                    return a.restaurant.internalName.localeCompare(b.restaurant.internalName);
                });
            case ChartSortBy.ASC:
                return data.sort((a, b) => (a.averageRating || 0) - (b.averageRating || 0));
            case ChartSortBy.DESC:
                return data.sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
            default:
                return data;
        }
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        afterTitle: (tooltipItem: TooltipItem<BarChartType>[]) => this._computeTooltipAfterTitle(tooltipItem),
                        label: (tooltipItem: TooltipItem<BarChartType>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<BarChartType>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_tickValue: number, _index: number, _ticks: Tick[]): string => {
                            const label = this.reviewsRatingsAverageData[_index].restaurant.internalName;
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number, _index: number, _ticks: Tick[]) => this._shortNumberPipe.transform(tickValue),
                    },
                },
            },
        };
    }

    private _computeTooltipAfterTitle(items: TooltipItem<BarChartType>[]): string {
        const raw = items[0].raw as ReviewsRatingsAverageData;
        return raw.restaurant.address?.getAddressWithDistrict() ?? '';
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string {
        const currentData = this.reviewsRatingsAverageData[item.dataIndex];
        const previousData = this.previousReviewsRatingsAverageData.find(
            (previous) => previous.restaurant._id === currentData.restaurant._id
        );
        const currentAverage = currentData.averageRating;
        const previousAverage = previousData?.averageRating;

        let evolutionString = '';
        if (!isNil(currentAverage) && !isNil(previousAverage)) {
            const difference = currentAverage - previousAverage;
            const sign = difference > 0 ? '+' : '-';
            const differenceTransformed = this._shortNumberPipe.transform(Math.abs(difference));
            evolutionString = `(${sign}${differenceTransformed})`;
        }

        const currentAverageTransformed = this._shortNumberPipe.transform(currentAverage);
        return `${currentAverageTransformed} ${evolutionString}`;
    }

    private _computeTooltipAfterLabel(item: TooltipItem<BarChartType>): string[] {
        const currentData = this.reviewsRatingsAverageData[item.dataIndex];
        const previousData = this.previousReviewsRatingsAverageData.find(
            (previous) => previous.restaurant._id === currentData.restaurant._id
        );
        return currentData.byPlatforms.map((byPlatform) => {
            const currentAverage = byPlatform.average;
            const previousAverage = previousData?.byPlatforms.find((e) => e.platformKey === byPlatform.platformKey)?.average;
            let evolutionString = '';
            if (!isNil(currentAverage) && !isNil(previousAverage)) {
                const difference = currentAverage - previousAverage;
                const sign = difference > 0 ? '+' : '-';
                const differenceTransformed = this._shortNumberPipe.transform(Math.abs(difference));
                evolutionString = `(${sign}${differenceTransformed})`;
            }

            const platformTrad = this.PLATFORM_KEYS_TRAD[byPlatform.platformKey];
            const currentAverageTransformed = this._shortNumberPipe.transform(byPlatform.average);
            return `${platformTrad}: ${currentAverageTransformed} ${evolutionString}`;
        });
    }
}
