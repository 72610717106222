import { ReviewAnalysisStatus, ReviewAnalysisTag, SemanticAnalysisProviderKey, SemanticAnalysisSentiment } from '../../constants';

export function getReviewsForSemanticAnalysis(reviews) {
    return reviews.filter(
        (review) =>
            review.semanticAnalysis?.providerKey === SemanticAnalysisProviderKey.OPENAI &&
            review.semanticAnalysis?.status !== ReviewAnalysisStatus.FAILED
    );
}

export function getReviewsForSemanticAnalysisStats(reviews) {
    return reviews
        .map((review) => review.semanticAnalysis?.segmentAnalyses)
        .filter((review) => review !== null || review !== undefined)
        .flat()
        .filter((el) => el.tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE && el.sentiment !== SemanticAnalysisSentiment.NEUTRAL);
}

export function getPositiveAndNegativeStatsForSemanticAnalysis(reviews) {
    const positiveSentimentsCount = reviews.filter((s) => s.sentiment === SemanticAnalysisSentiment.POSITIVE).length;
    const negativeSentimentsCount = reviews.filter((s) => s.sentiment === SemanticAnalysisSentiment.NEGATIVE).length;
    const positiveSentimentsPercentage = Math.round((positiveSentimentsCount * 100) / reviews.length);
    const negativeSentimentsPercentage = Math.round((negativeSentimentsCount * 100) / reviews.length);

    return {
        positiveSentimentsCount,
        positiveSentimentsPercentage,

        negativeSentimentsCount,
        negativeSentimentsPercentage,
    };
}
