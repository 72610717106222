import { Role } from '@malou-io/package-utils';

import { Feedback, MessageVisibilityLevel } from ':shared/models/feedback';

export const showFeedbackNotification = (feedback: Feedback | null, userRole: Role | undefined | null): boolean => {
    if (!feedback || !feedback.isOpen || !feedback.feedbackMessages?.length) {
        return false;
    }
    if (userRole === Role.ADMIN) {
        return true;
    }
    if (feedback.feedbackMessages?.some((feedbackMessage) => feedbackMessage.visibility !== MessageVisibilityLevel.ADMIN)) {
        return true;
    }
    return false;
};
