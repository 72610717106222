@if (value) {
    <div class="flex items-center">
        <mat-icon
            class="{{ size }}"
            [svgIcon]="(isValuePositive | xor: reverseArrow) ? 'stat-arrow-up' : 'stat-arrow-down'"
            [class.malou-color-state-success]="isValuePositive"
            [class.malou-color-state-error]="!isValuePositive"></mat-icon>
        <span
            [class.malou-color-state-success]="isValuePositive"
            [class.malou-color-state-error]="!isValuePositive"
            [class.malou-text-10--medium]="size === 'xs'"
            [class.malou-text-14--medium]="size === 'small'"
            [class.malou-text-18--medium]="size === 'medium'"
            >{{ displayedValue ?? (value | absolute) }}</span
        >
    </div>
}
