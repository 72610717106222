import { inject, Injectable } from '@angular/core';
import Pusher from 'pusher-js';

import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { environment } from ':environments/environment';
import { MessagesService } from ':modules/messages/messages.service';

@Injectable({
    providedIn: 'root',
})
export class RealtimeMessagingService {
    private readonly _API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1`;
    private readonly _messagingService = inject(MessagesService);
    private readonly _notificationService = inject(NotificationService);

    private _pusherClient: Pusher;

    initialize(): void {
        if (this._pusherClient) {
            this._pusherClient.disconnect();
        }
        this._pusherClient = new Pusher(environment.PUSHER_KEY, {
            cluster: 'eu',
            authEndpoint: `${this._API_BASE_URL}/messages/pusher/auth`,
        });
        this._messagingService.initRealtimeNotifications(this._pusherClient);
        this._notificationService.initRealtimeNotifications(this._pusherClient);
    }
}
