<div
    class="mb-3 flex flex-col gap-y-6"
    [ngClass]="{ '!cursor-not-allowed': isDisabled }"
    [matTooltip]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.locked_restaurant' | translate"
    [matTooltipDisabled]="!isDisabled">
    <ng-container [ngTemplateOutlet]="wheelOfFortuneDatesTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="giftClaimStartDateOptionTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="giftClaimDurationTemplate"></ng-container>
    @if (!isAggregatedView && totems.length) {
        <ng-container [ngTemplateOutlet]="totemSelectionTemplate"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="redirectionSettingsTemplate"></ng-container>
</div>

<ng-template #wheelOfFortuneDatesTemplate>
    <form [formGroup]="wheelOfFortuneDatesForm">
        <div class="malou-text-12--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.title' | translate }}
        </div>
        <div class="mb-4 flex items-center gap-x-2" [ngClass]="{ 'cursor-not-allowed opacity-50': isDisabled }">
            <mat-checkbox
                color="primary"
                [disabled]="isDisabled"
                [checked]="!globalSettings().isEndDateMandatory"
                (change)="toggleIsEndDateMandatory()"></mat-checkbox>
            <span class="malou-text-10--regular malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.no_end_date' | translate }}
            </span>
        </div>
        <div class="flex">
            <div class="flex w-[47%]">
                <app-input-date-picker
                    class="w-full"
                    formControlName="startDate"
                    [disabled]="isDisabled"
                    [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.start_date' | translate"
                    [min]="TODAY"
                    [max]="wheelOfFortuneDatesForm.get('endDate')?.value ?? undefined"
                    [shouldDisplayBorder]="true">
                </app-input-date-picker>
            </div>
            @if (globalSettings().isEndDateMandatory) {
                <span class="malou-text-10--regular malou-color-text-2 flex w-[6%] items-center justify-center">
                    {{ 'common.to' | translate }}
                </span>
                <div class="flex w-[47%]">
                    <app-input-date-picker
                        class="w-full"
                        formControlName="endDate"
                        [disabled]="isDisabled"
                        [min]="getMinEndDate()"
                        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.end_date' | translate"
                        [shouldDisplayBorder]="true">
                    </app-input-date-picker>
                </div>
            }
        </div>
    </form>
</ng-template>

<ng-template #giftClaimStartDateOptionTemplate>
    <div>
        <div class="malou-text-12--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_start_date_option.title' | translate }}
        </div>

        <mat-radio-group
            class="flex gap-x-5"
            color="primary"
            [disabled]="isDisabled"
            [value]="globalSettings().giftClaimStartDateOption"
            (change)="onGiftClaimStartDateOptionChange($event)">
            <mat-radio-button class="malou-small-radio-button" [value]="GiftClaimStartDateOption.NOW">
                <span class="malou-text-10--regular">
                    {{ GiftClaimStartDateOption.NOW | enumTranslate: 'gift_claim_start_date_option' }}
                </span>
            </mat-radio-button>
            <mat-radio-button class="malou-small-radio-button" [value]="GiftClaimStartDateOption.TOMORROW">
                <span class="malou-text-10--regular">
                    {{ GiftClaimStartDateOption.TOMORROW | enumTranslate: 'gift_claim_start_date_option' }}
                </span>
                <span class="malou-text-10--regular italic">
                    {{
                        'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_start_date_option.make_your_clients_come_back'
                            | translate
                    }}
                </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</ng-template>

<ng-template #giftClaimDurationTemplate>
    <div>
        <div class="malou-text-12--semibold malou-color-text-1">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.title' | translate }}
        </div>
        <div class="malou-text-10--regular malou-color-text-2 mb-4 italic">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.subtitle' | translate }}
        </div>
        <app-simple-select
            [disabled]="isDisabled"
            [formControl]="giftClaimDurationInDaysControl"
            [values]="GIFT_CLAIM_DURATION_IN_DAYS_OPTIONS"
            [displayWith]="displayGiftClaimDurationInDaysOptionsWith"></app-simple-select>
    </div>
</ng-template>

<ng-template #redirectionSettingsTemplate>
    <form
        class="flex flex-col gap-4 rounded border border-malou-color-background-dark bg-malou-color-background-light p-4"
        [formGroup]="redirectionSettingsForm">
        <div class="malou-text-14--bold malou-color-text-1 mb-2">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.title' | translate }}
        </div>

        <div class="flex flex-col gap-3">
            <div class="malou-text-12--semibold text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.nextDrawEnabledDelay' | translate }}
            </div>

            <app-simple-select formControlName="nextDrawEnabledDelay" [values]="nextDrawEnabledDelayOptions" [disabled]="isDisabled">
                <ng-template let-value="value" #selectedValueTemplate>
                    @if (value) {
                        <span class="malou-text-12--semibold text-malou-color-text-1">{{
                            value | enumTranslate: 'next_draw_enabled_delay'
                        }}</span>
                    }
                </ng-template>

                <ng-template let-option="option" #optionTemplate>
                    <span class="malou-text-12--regular text-malou-color-text-2">{{
                        option | enumTranslate: 'next_draw_enabled_delay'
                    }}</span>
                </ng-template>
            </app-simple-select>
        </div>

        <div class="flex flex-col gap-3">
            <div class="malou-text-12--semibold text-malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.should_redirect.title' | translate }}
            </div>

            <mat-radio-group
                class="flex gap-x-5"
                color="primary"
                [disabled]="isDisabled"
                [value]="this.redirectionSettingsForm.get('shouldRedirect')?.value"
                (change)="onShouldRedirectOptionChange($event)">
                <mat-radio-button class="malou-small-radio-button" [value]="true">
                    <span class="malou-text-10--regular">
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.should_redirect.redirect_to'
                                | translate
                        }}
                    </span>
                </mat-radio-button>
                <mat-radio-button class="malou-small-radio-button" [value]="false">
                    <span class="malou-text-10--regular">
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.should_redirect.no_redirection'
                                | translate
                        }}
                    </span>
                </mat-radio-button>
            </mat-radio-group>

            @if (globalSettings().redirectionSettings.shouldRedirect) {
                @if (redirectPlatformOptions().length === 0) {
                    <ng-container [ngTemplateOutlet]="noPlatformConnectedTemplate"></ng-container>
                } @else if (globalSettings().redirectionSettings.nextDrawEnabledDelay === NextDrawEnabledDelay.NEVER) {
                    <ng-container [ngTemplateOutlet]="singleRedirection"></ng-container>
                } @else {
                    <ng-container [ngTemplateOutlet]="multipleRedirections"></ng-container>
                }
            }
        </div>

        @if (shouldShowRedirectionAfterNthTimes()) {
            <div class="malou-text-12--regular italic text-malou-color-text-2">
                @switch (globalSettings().redirectionSettings.nextDrawEnabledDelay) {
                    @case (NextDrawEnabledDelay.AFTER_24_HOURS) {
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.player_can_play_after_24_hours_and_wont_be_redirected_nth_time'
                                | translate: { nthTime: firstTimeWithoutRedirection() }
                        }}
                    }
                    @case (NextDrawEnabledDelay.AFTER_48_HOURS) {
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.player_can_play_after_28_hours_and_wont_be_redirected_nth_time'
                                | translate: { nthTime: firstTimeWithoutRedirection() }
                        }}
                    }
                    @case (NextDrawEnabledDelay.AFTER_ONE_WEEK) {
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.player_can_play_after_one week_and_wont_be_redirected_nth_time'
                                | translate: { nthTime: firstTimeWithoutRedirection() }
                        }}
                    }
                    @default {
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.player_wont_be_redirected_nth_time'
                                | translate: { nthTime: firstTimeWithoutRedirection() }
                        }}
                    }
                }
            </div>
        }
    </form>
</ng-template>

<ng-template #singleRedirection>
    @if (sortedRedirectionPlatforms(); as sortedRedirectionPlatforms) {
        <div class="flex items-center gap-x-16">
            <div class="malou-text-12--medium w-32 min-w-fit">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.redirection' | translate }}
            </div>
            <ng-container
                [ngTemplateOutlet]="redirectionPlatformTemplate"
                [ngTemplateOutletContext]="{
                    platformKey: sortedRedirectionPlatforms.length > 0 ? sortedRedirectionPlatforms[0].platformKey : undefined,
                    index: 0,
                }"></ng-container>
        </div>
    }
</ng-template>

<ng-template #multipleRedirections>
    <div class="flex flex-col gap-2">
        @for (
            platformWithOrder of sortedRedirectionPlatforms();
            track platformWithOrder.order + '_' + platformWithOrder.platformKey;
            let index = $index;
            let last = $last
        ) {
            <div class="flex items-center gap-x-6">
                <div class="flex items-center gap-x-16">
                    <div class="malou-text-12--medium w-32 min-w-fit">
                        {{
                            'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_settings.nth_redirection'
                                | pluralTranslate: index + 1 : { count: index + 1 }
                        }}
                    </div>
                    <ng-container
                        [ngTemplateOutlet]="redirectionPlatformTemplate"
                        [ngTemplateOutletContext]="{ index, platformKey: platformWithOrder.platformKey }"></ng-container>
                </div>
                <div class="flex items-center gap-x-2">
                    @if (sortedRedirectionPlatforms().length > 1) {
                        <mat-icon
                            class="!w-4 cursor-pointer text-malou-color-chart-pink--accent"
                            [svgIcon]="SvgIcon.INTERDICTION"
                            [ngClass]="{ '!cursor-not-allowed opacity-50': isDisabled }"
                            (click)="!isDisabled && removeRedirectionPlatform(platformWithOrder.order)"></mat-icon>
                    }
                    @if (last && sortedRedirectionPlatforms().length < maxRedirectionPlatformsCount()) {
                        <mat-icon
                            class="!w-4 cursor-pointer"
                            color="primary"
                            [svgIcon]="SvgIcon.ADD"
                            [ngClass]="{ '!cursor-not-allowed opacity-50': isDisabled }"
                            (click)="!isDisabled && addRedirectionPlatform()"></mat-icon>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-platformKey="platformKey" let-index="index" #redirectionPlatformTemplate>
    <div class="w-72 min-w-fit">
        @if (redirectPlatformOptions().length > 1) {
            <app-simple-select
                [defaultValue]="platformKey"
                [disabled]="isDisabled"
                [values]="filteredRedirectPlatformOptions()(index)"
                (simpleSelectChange)="onRedirectionPlatformChange($event, index)">
                <ng-template let-value="value" #selectedValueTemplate>
                    @if (value) {
                        <div class="malou-text-12--medium malou-color-text-1 flex items-center gap-x-2">
                            <app-platform-logo imgClasses="h-8 w-8" [logo]="value"></app-platform-logo>
                            <span>{{ value | enumTranslate: 'platform_key' }}</span>
                        </div>
                    }
                </ng-template>
                <ng-template let-option="option" #optionTemplate>
                    @if (option) {
                        <div class="malou-text-12--regular my-1 flex items-center gap-x-2">
                            <app-platform-logo imgClasses="h-8 w-8" [logo]="option"></app-platform-logo>
                            <span>{{ option | enumTranslate: 'platform_key' }}</span>
                        </div>
                    }
                </ng-template>
            </app-simple-select>
        } @else {
            <div class="malou-text-12--medium malou-color-text-1 flex h-full items-center gap-x-2 pl-2">
                <app-platform-logo imgClasses="h-8 w-8" [logo]="redirectPlatformOptions()[0]"></app-platform-logo>
                <span>{{ redirectPlatformOptions()[0] | enumTranslate: 'platform_key' }}</span>
            </div>
        }
    </div>
</ng-template>

<ng-template #noPlatformConnectedTemplate>
    <div class="malou-text-10--regular malou-color-text-2 italic">
        @if (isAggregatedView) {
            <span>
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms' | translate }}
            </span>
        } @else {
            <span>
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_1' | translate }}
                <span
                    class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                    (click)="close({ shouldCheckBeforeClose: true, restaurantId: selectedRestaurants()[0]._id })">
                    {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_cta' | translate }}
                </span>
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_2' | translate }}
            </span>
        }
    </div>
</ng-template>

<ng-template #totemSelectionTemplate>
    <div class="flex flex-col">
        <span class="malou-text-12--semibold malou-color-text-1">{{
            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.which_totems' | translate
        }}</span>
        <span class="malou-text-10--medium italic">{{
            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.will_redirect' | translate
        }}</span>
        <app-select-base
            class="mr-5 mt-4 max-h-[50px] max-w-[98%] overflow-x-hidden"
            [values]="totems"
            [selectedValues]="totemsControl.value || []"
            [displayWith]="totemDisplayWith"
            [sortBy]="totemsSortBy"
            [formControl]="totemsControl"
            [defaultEmptyValueMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem' | translate"
            [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem_selected' | translate"
            [selectAllCheckboxMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.all_totems' | translate"
            [multiSelection]="true"
            [checkboxOption]="true"
            [showSelectAllCheckbox]="true"
            [showValuesSelectedCount]="true"
            [groupSelectedValuesAtTop]="true"
            [multiSelectionElementWrap]="false"
            [shouldSwitchToWrapModeOnClick]="false"
            [disabled]="isDisabled"
            [shouldUpdateValuesToDisplayAfterSelection]="true"
            [valuesSelectedCountMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.totems' | translate"
            (selectBaseChange)="$event ? onTotemSelectionChange($event) : null">
            <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
                <div class="malou-chip malou-chip--primary">
                    <span>{{ totemDisplayWith | applyPure: value }}</span>
                    <mat-icon
                        class="malou-chip-icon--right malou-color-primary"
                        [svgIcon]="SvgIcon.REMOVE"
                        (click)="deleteValueAt(index)"></mat-icon>
                </div>
            </ng-template>
            <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
                <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
                    {{ totemDisplayWith | applyPure: value }}
                </div>
            </ng-template>
        </app-select-base>
    </div>
</ng-template>
