import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';

@Component({
    selector: 'app-facebook-instagram-connection-step-0',
    templateUrl: './facebook-instagram-connection-step-0.component.html',
    styleUrls: ['./facebook-instagram-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookInstagramConnectionStep0Component extends BaseStepComponent<
    FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
    FacebookInstagramConnectionModalResult
> {
    readonly ButtonStyle = ButtonStyle;
}
