import { GetRestaurantReviewsEvolutionDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { Review } from ':shared/models';

export interface ResultsWithRange<T> {
    results: T[];
    startDate: string;
    endDate: string;
}

export class WeekReviewEvolution {
    key: PlatformKey;
    total: number;
    week: number;
    year: number;
}

export class DayReviewEvolution {
    key: PlatformKey;
    total: number;
    day: number;
    year: number;
}

export class MonthReviewEvolution {
    key: PlatformKey;
    total: number;
    month: number;
    year: number;
}

export type ReviewEvolution = WeekReviewEvolution | DayReviewEvolution | MonthReviewEvolution;

export type ReviewsEvolutionWithRange = GetRestaurantReviewsEvolutionDto;

export interface ReviewsRating {
    platforms: [
        {
            key: string;
            nbReviews: number;
        },
    ];
    rating: number;
    total: number;
}

export type ReviewsRatingsWithRange = ResultsWithRange<ReviewsRating>;

export type ReviewsEvolutionTotalWithRange = ResultsWithRange<ReviewsEvolutionTotal>;

export interface ReviewsEvolutionTotal {
    restaurant: {
        _id: string;
        name: string;
    };
    total: number;
}

export type ReviewsAverageRatingWithRange = ResultsWithRange<ReviewsAverageRating>;

export interface ReviewsAverageRating {
    restaurant: {
        _id: string;
        name: string;
        internalName?: string;
    };
    averageRating: number;
    averageRatingPerPlatform: {
        platformKey: PlatformKey;
        averageRating: number;
    }[];
}

export interface ReviewsByAnswerStatus {
    results: {
        answered: Review[];
        notAnswered: Review[];
    };
}

export type ReviewsAverageAnswerTime = number | null;

export const DEFAULT_DISPLAY_SEMANTIC_ANALYSIS_VALUE = true;
