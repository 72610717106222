<div class="my-2">
    @if (isTranslating()) {
        <span class="malou-text-10 malou-color-text-2 italic">{{ 'reviews.translating' | translate }}</span>
    } @else if (!showTranslation()) {
        <span
            class="malou-text-10--semibold malou-color-primary cursor-pointer"
            id="tracking_review_translations"
            data-testid="review-translation-btn"
            (click)="translateReviewText()">
            {{ 'reviews.translate_into_app_lang' | translate }}
        </span>
    } @else {
        <span class="malou-text-10 malou-color-text-2 italic">{{ 'reviews.translated' | translate }} -</span>
        <span
            class="malou-text-10--semibold malou-color-primary cursor-pointer"
            id="tracking_review_translations_original"
            (click)="showOriginal()">
            {{ 'reviews.show_original' | translate: { lang: originalReviewLang() } }}
        </span>
    }
</div>
