<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="mr-6 w-full">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onValueChange($event)"></app-search>
        </div>
        <mat-icon class="cursor-pointer" color="primary" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>

    <div class="malou-dialog__body pb-5">
        <div class="flex flex-col gap-y-5">
            @for (restaurant of filteredRestaurants; track trackByIdFn($index, restaurant)) {
                <div (click)="selectRestaurant(restaurant)">
                    <div class="flex h-[55px] w-full cursor-pointer items-center">
                        <img
                            class="mr-4 h-8 w-8 rounded-full"
                            [src]="(restaurant | applySelfPure: 'getLogoUrl') || ('default_logo' | imagePathResolver)" />
                        <div class="flex flex-col">
                            <span class="malou-text-15--semibold malou-color-text-1 b-1">{{ restaurant.name }}</span>
                            <span class="malou-text-13--regular italic">{{ restaurant | applySelfPure: 'getAddressDisplayedValue' }}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
