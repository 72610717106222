<div class="flex justify-center">
    <div class="text-center">
        <p [ngClass]="isPreview() ? 'malou-text-18--bold' : 'malou-text-22--bold'">
            @if (regularTitle) {
                <span class="malou-color-text-1">{{ regularTitle }}</span>
            }
            @if (regularTitle) {
                <br />
            }
            <span [ngStyle]="{ color: primaryColor() }">{{ highlightedTitle }}</span>
        </p>
    </div>
    <div class="max-h-[750px]:bottom-[6%] max-h-[700px]:bottom-[4%] absolute bottom-[9%] w-full">
        @if (wheelOfFortune()) {
            <app-animated-wheel-of-fortune
                class="flex"
                [gifts]="gifts"
                [primaryColor]="primaryColor"
                [secondaryColor]="secondaryColor"
                [shouldStartAnimation]="shouldStartAnimation"
                [isPreview]="isPreview"
                [wheelContainerSize]="getWheelContainerSize()"></app-animated-wheel-of-fortune>
        }
        @if (ctaText) {
            <div class="absolute bottom-0 z-10 flex w-full justify-center">
                <button
                    class="malou-btn-raised--dark btn-xl !mt-3 !w-[90%]"
                    mat-raised-button
                    [id]="ctaId"
                    [ngClass]="isPreview() ? '!h-[50px] !cursor-default !text-base' : '!h-[69px]'"
                    [disabled]="isCtaLoading()"
                    (click)="isPreview() ? null : click()">
                    @if (isCtaLoading()) {
                        <app-malou-spinner class="flex justify-center" size="small" color="white"></app-malou-spinner>
                    } @else {
                        {{ ctaText }}
                    }
                </button>
            </div>
        }
    </div>
</div>
