<div class="flex items-center" [additionalElement]="buttonElement()" (appClickOutside)="handleClickOutside()">
    @if (!buttonElement()) {
        <mat-icon
            class="!h-4 !w-4"
            [svgIcon]="SvgIcon.SMILEY"
            [ngClass]="(disabled() ? 'cursor-not-allowed' : 'cursor-pointer') + buttonColorClass()"
            (click)="toggle()"
            #icon></mat-icon>
    }
    @if (isEmojiPickerDisplayed()) {
        <!-- fixed positioning is important to not run into problem when some parent is scrolling -->
        <div class="fixed z-20" appKeepElementInWindowDirective>
            <emoji-mart
                [isNative]="true"
                [color]="DEFAULT_COLOR"
                [darkMode]="false"
                [showPreview]="true"
                [i18n]="i18n()"
                (emojiClick)="addEmoji($event)"></emoji-mart>
        </div>
    }
</div>
