import { ComponentType } from '@angular/cdk/overlay';

import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { Props } from ':shared/helpers/props';

export interface Step<T extends BaseStepComponent<any, any>> {
    stepName: string;
    component: ComponentType<T>;
    componentInputs: Record<string, any>;
}

type StepInputs<T extends BaseStepComponent<any, any>> = keyof Omit<Props<T>, keyof Props<BaseStepComponent>> extends never
    ? Record<string, never>
    : Omit<Props<T>, keyof Props<BaseStepComponent>>;

/**
 * Create a step that infer the type of the component to get autocompletion on componentInputs
 */
export function createStep<T extends BaseStepComponent<any, any>>(
    stepName: string,
    component: ComponentType<T>,
    componentInputs: StepInputs<T>
): Step<T> {
    return {
        stepName,
        component,
        componentInputs,
    };
}
