<div class="mx-7.5 h-full py-4 md:mx-6">
    @if (!isLoading && areAllPlatformsDisconnected) {
        <div class="mb-3.5 h-full">
            <ng-container *ngTemplateOutlet="noplatformavailable"></ng-container>
        </div>
    } @else {
        @if (!screenSizeService.isPhoneScreen) {
            <div class="flex h-full">
                <!-- Conversation left Side -->
                <div class="flex w-[33vw] min-w-[33vw] flex-col overflow-hidden">
                    <app-messages-filters
                        [conversationStatusFilters]="(conversationStatusFilters$ | async) ?? []"
                        [availablePlatforms$]="availablePlatforms$"
                        [hasUpdateFinished$]="hasUpdateFinished$"
                        [areAllSocialPlatformsDisconnected]="areAllSocialPlatformsDisconnected"
                        [checkedConversationsWithMessages]="checkedConversationsWithMessages"
                        (onConversationStatusChange)="conversationStatusFiltersChange($event)"
                        (onChangeSelectedPlatforms)="changeSelectedPlatforms($event)"
                        (onSearchValueChange)="searchChanged($event)"
                        (onSynchronizeMessages)="synchronizeMessages()"
                        (onMarkConversationsAsUnread)="markSelectedConversationsAsUnread()"
                        (onMarkConversationsAsRead)="markSelectedConversationsAsRead()"
                        (onAddConversationsToFavorites)="addSelectedConversationsToFavorites()"
                        (onRemoveConversationsFromFavorites)="removeSelectedConversationsFromFavorites()"
                        (onUnarchiveConversations)="unarchiveSelectedConversations()"
                        (onArchiveConversations)="archiveSelectedConversations()">
                    </app-messages-filters>
                    <!-- END Warnings container -->
                    <div
                        class="hide-scrollbar !m-0 h-full overflow-y-auto !p-0"
                        [ngClass]="{
                            '!border-0': isLoading || errorLoadingConversations,
                            'bg-[#F9FAFF]': isLoading || errorLoadingConversations,
                            'malou-card': !isLoading && !errorLoadingConversations,
                        }">
                        <app-conversations
                            [isLoading]="isLoading"
                            [errorLoadingConversations]="errorLoadingConversations"
                            [conversationsWithMessages]="conversationsWithMessages"
                            [areAllPlatformsDisconnected]="areAllPlatformsDisconnected"
                            [selectedConversation]="selectedConversation"
                            [selectedTabIndex]="selectedTabIndex"
                            [filteredConversations]="filteredConversations"
                            [paginations]="paginations"
                            [conversationsTextSearch]="conversationsTextSearch"
                            [checkedConversations]="checkedConversationsWithMessages"
                            (onSelectedTabChange)="onSelectedTabChange($event)"
                            (onLoadMoreConversations)="loadMoreConversations()"
                            (onSelectConversation)="onSelectConversation($event)"
                            (onCheckConversation)="onCheckConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite($event)">
                        </app-conversations>
                    </div>
                </div>
                <!-- END Conversation left Side -->
                <!-- Conversation right panel -->
                @if (!isLoading && (checkedPlatforms$ | async)?.length && selectedConversation) {
                    <div class="h-full grow">
                        <app-conversation-panel
                            [scrollDownEvent]="scrollDown"
                            [conversationChanged$]="conversationChanged$"
                            (conversationClosed)="selectedConversation = null">
                        </app-conversation-panel>
                    </div>
                }
                <!-- END Conversation right panel -->
            </div>
        } @else {
            @if (!selectedConversation) {
                <div class="full-h flex min-h-[75vh] w-full flex-col">
                    <app-messages-filters
                        [conversationStatusFilters]="(conversationStatusFilters$ | async) ?? []"
                        [availablePlatforms$]="availablePlatforms$"
                        [hasUpdateFinished$]="hasUpdateFinished$"
                        [areAllSocialPlatformsDisconnected]="areAllSocialPlatformsDisconnected"
                        [checkedConversationsWithMessages]="checkedConversationsWithMessages"
                        (onConversationStatusChange)="conversationStatusFiltersChange($event)"
                        (onChangeSelectedPlatforms)="changeSelectedPlatforms($event)"
                        (onSearchValueChange)="searchChanged($event)"
                        (onSynchronizeMessages)="synchronizeMessages()"
                        (onMarkConversationsAsUnread)="markSelectedConversationsAsUnread()"
                        (onMarkConversationsAsRead)="markSelectedConversationsAsRead()"
                        (onAddConversationsToFavorites)="addSelectedConversationsToFavorites()"
                        (onRemoveConversationsFromFavorites)="removeSelectedConversationsFromFavorites()"
                        (onUnarchiveConversations)="unarchiveSelectedConversations()"
                        (onArchiveConversations)="archiveSelectedConversations()">
                    </app-messages-filters>
                    <!-- END Warnings container -->
                    <div class="hide-scrollbar malou-card !m-0 h-full overflow-y-auto !p-0">
                        <app-conversations
                            [isLoading]="isLoading"
                            [errorLoadingConversations]="errorLoadingConversations"
                            [conversationsWithMessages]="conversationsWithMessages"
                            [areAllPlatformsDisconnected]="areAllPlatformsDisconnected"
                            [selectedConversation]="selectedConversation"
                            [selectedTabIndex]="selectedTabIndex"
                            [filteredConversations]="filteredConversations"
                            [paginations]="paginations"
                            [conversationsTextSearch]="conversationsTextSearch"
                            [checkedConversations]="checkedConversationsWithMessages"
                            (onSelectedTabChange)="onSelectedTabChange($event)"
                            (onLoadMoreConversations)="loadMoreConversations()"
                            (onSelectConversation)="onSelectConversation($event)"
                            (onCheckConversation)="onCheckConversation($event)"
                            (onChangeArchivedConversation)="onChangeArchivedConversation($event)"
                            (onRemoveConversationFromFavorite)="onRemoveConversationFromFavorite($event)">
                        </app-conversations>
                    </div>
                </div>
            }
            @if (!isLoading && (checkedPlatforms$ | async)?.length && selectedConversation) {
                <div>
                    <app-conversation-panel
                        [scrollDownEvent]="scrollDown"
                        [conversationChanged$]="conversationChanged$"
                        (conversationClosed)="selectedConversation = null">
                    </app-conversation-panel>
                </div>
            }
        }
    }
</div>

<ng-template #noplatformavailable>
    <app-no-connected-platforms [message]="'messages.please_connect_platforms' | translate"></app-no-connected-platforms>>
</ng-template>
