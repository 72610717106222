import { z } from 'zod';

import { ChecklistTaskName } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getChecklistForRestaurantParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export const updateChecklistByIdParamsValidator = z
    .object({
        checklist_id: objectIdValidator,
    })
    .transform((data) => ({
        checklistId: data.checklist_id,
    }));

export const updateChecklistByIdBodyValidator = z.object({
    tasks: z
        .array(
            z.object({
                name: z.nativeEnum(ChecklistTaskName),
                completed: z.boolean(),
            })
        )
        .optional(),
    isDisplayed: z.boolean().optional(),
});

export type GetChecklistForRestaurantParamsDto = z.infer<typeof getChecklistForRestaurantParamsValidator>;
export type UpdateChecklistByIdParamsDto = z.infer<typeof updateChecklistByIdParamsValidator>;
export type UpdateChecklistByIdBodyDto = z.infer<typeof updateChecklistByIdBodyValidator>;
