import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SpecialTimePeriod, TimePeriod } from '../models';

@Pipe({
    name: 'displayHour',
    standalone: true,
})
export class DisplayHourPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(hour: SpecialTimePeriod | TimePeriod): string {
        if (!hour) {
            return '--:--';
        }
        return this.display(hour);
    }

    display(hour: SpecialTimePeriod | TimePeriod): string {
        if (!hour || hour.isClosed) {
            return this._translate.instant('information.business_hours.closed');
        } else {
            const closeTime = hour.closeTime === '24:00' ? '00:00' : hour.closeTime;
            return `${hour.openTime} - ${closeTime}`;
        }
    }
}
