<div class="flex cursor-pointer justify-between rounded-xl" (click)="navigateToMessages()">
    <div class="flex items-center gap-x-4">
        <div>
            <app-platform-logo imgClasses="h-12 w-12" [logo]="data.key"></app-platform-logo>
        </div>
        <div class="malou-text-12--semibold malou-color-text-1 w-[200px]">
            {{ 'messages.notification.text' | translate }}
        </div>
    </div>

    <div>
        <button class="malou-btn-icon btn-sm" mat-icon-button (click)="close(); $event.stopPropagation()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
</div>
