import { JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, filter } from 'rxjs';

import { ApplicationLanguage, isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { AutomationCardComponent } from ':modules/automations/automation-card/automation-card.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectLanguagesComponent } from ':shared/components/select-languages/select-languages.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';

@Component({
    selector: 'app-ai-general-settings',
    standalone: true,
    templateUrl: './ai-general-settings.component.html',
    styleUrl: './ai-general-settings.component.scss',
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        AutomationCardComponent,
        SkeletonComponent,
        SelectLanguagesComponent,
        InputTextComponent,
        ReactiveFormsModule,
        JsonPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiGeneralSettingsComponent implements OnInit {
    readonly ApplicationLanguage = ApplicationLanguage;
    readonly APP_LANGUAGES = Object.values(ApplicationLanguage);

    private readonly _aiSettingsContext = inject(AiSettingsContext);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly aiGeneralSettingsForm = new FormGroup({
        restaurantName: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.minLength(1)] }),
        language: new FormControl<ApplicationLanguage>(ApplicationLanguage.EN, { nonNullable: true }),
    });

    constructor() {
        effect(() => {
            const restaurantAiSettings = this._aiSettingsContext.restaurantAiSettings();
            if (this.aiGeneralSettingsForm.pristine && restaurantAiSettings) {
                this.aiGeneralSettingsForm.patchValue({
                    restaurantName: restaurantAiSettings.restaurantName,
                    language: restaurantAiSettings.defaultLanguageResponse,
                });
            }
        });
    }

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil), takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            // used to not trigger an update when the restaurant is changed
            this.aiGeneralSettingsForm.markAsPristine();
        });

        this.aiGeneralSettingsForm.valueChanges
            .pipe(
                debounceTime(200),
                filter(() => !this.aiGeneralSettingsForm.pristine && this.aiGeneralSettingsForm.valid),
                takeUntilDestroyed(this._destroyRef)
            )

            .subscribe((value) => {
                this._aiSettingsContext.updateAiSettings({
                    restaurantAiSettings: new RestaurantAiSettings({
                        ...this._aiSettingsContext.restaurantAiSettings()!,
                        restaurantName: value.restaurantName!,
                        defaultLanguageResponse: value.language!,
                    }),
                    dispatcher: RestaurantAiSettingsDispatch.general,
                });
            });
    }
}
