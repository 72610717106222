<app-modal-structure
    [title]="'platforms.connection_new.zenchef.step_0.step_name' | translate"
    [primaryButtonText]="'platforms.connection_new.zenchef.step_0.open_zenchef' | translate"
    [secondaryButtonText]="'common.cancel' | translate"
    [showTertiaryButton]="false"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="close.emit({})"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col items-center gap-y-4">
        <div
            class="malou-color-text-2 malou-text-13--regular"
            [innerHtml]="'platforms.connection_new.zenchef.step_0.description_1' | translate: { class: 'malou-text-13--semibold' }"></div>
        <img class="h-auto w-full" [src]="'zenchef-api-token' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
        <div
            class="malou-color-text-2 malou-text-13--regular"
            [innerHtml]="'platforms.connection_new.zenchef.step_0.description_2' | translate: { class: 'malou-text-13--semibold' }"></div>
        <img class="h-auto w-full" [src]="'zenchef-connect-malou' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
    </div>
</app-modal-structure>
