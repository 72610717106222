import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const createGiftDrawParamsValidator = z
    .object({ restaurant_id: objectIdValidator, wheel_of_fortune_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id, wheelOfFortuneId: data.wheel_of_fortune_id }));
export type CreateGiftDrawParamsDto = z.infer<typeof createGiftDrawParamsValidator>;

export const createGiftDrawBodyValidator = z.object({ lang: z.string(), clientId: objectIdValidator });
export type CreateGiftDrawBodyDto = z.infer<typeof createGiftDrawParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getGiftDrawByIdParamsValidator = z
    .object({ gift_draw_id: objectIdValidator })
    .transform((data) => ({ giftDrawId: data.gift_draw_id }));
export type GetGiftDrawByIdParamsDto = z.infer<typeof getGiftDrawByIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const assignClientToGiftDrawParamsValidator = z
    .object({ gift_draw_id: objectIdValidator, client_id: objectIdValidator })
    .transform((data) => ({ giftDrawId: data.gift_draw_id, clientId: data.client_id }));
export type UpdateGiftDrawParamsDto = z.infer<typeof assignClientToGiftDrawParamsValidator>;

// ------------------------------------------------------------------------------------------

export const sendRetrievalEmailParamsValidator = z
    .object({ gift_draw_id: objectIdValidator })
    .transform((data) => ({ giftDrawId: data.gift_draw_id }));
export type SendRetrievalEmailParamsDto = z.infer<typeof sendRetrievalEmailParamsValidator>;

// ------------------------------------------------------------------------------------------

export const setGiftDrawRetrievedParamsValidator = z
    .object({ gift_draw_id: objectIdValidator })
    .transform((data) => ({ giftDrawId: data.gift_draw_id }));
export type SetGiftDrawRetrievedParamsDto = z.infer<typeof setGiftDrawRetrievedParamsValidator>;

// ------------------------------------------------------------------------------------------

export const cancelGiftDrawParamsValidator = z
    .object({ gift_draw_id: objectIdValidator })
    .transform((data) => ({ giftDrawId: data.gift_draw_id }));
export type CancelGiftDrawParamsDto = z.infer<typeof cancelGiftDrawParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getGiftDrawInsightsPerGiftParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export type GetGiftDrawInsightsPerGiftParamsDto = z.infer<typeof getGiftDrawInsightsPerGiftParamsValidator>;

export const getGiftDrawInsightsPerGiftQueryValidator = z
    .object({ start_date: z.coerce.date(), end_date: z.coerce.date() })
    .transform((data) => ({ startDate: data.start_date, endDate: data.end_date }));
export type GetGiftDrawInsightsPerGiftQueryDto = z.infer<typeof getGiftDrawInsightsPerGiftQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getGiftDrawInsightsPerRestaurantQueryValidator = z
    .object({ start_date: z.coerce.date(), end_date: z.coerce.date(), restaurant_ids: z.array(objectIdValidator) })
    .transform((data) => ({ startDate: data.start_date, endDate: data.end_date, restaurantIds: data.restaurant_ids }));
export type GetGiftDrawInsightsPerRestaurantQueryDto = z.infer<typeof getGiftDrawInsightsPerRestaurantQueryValidator>;
