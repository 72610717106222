<div class="flex w-full items-center gap-x-2 md:hidden">
    <div class="grow">
        <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearch($event)"></app-search>
    </div>
    <ng-container *ngTemplateOutlet="duplicateButton"></ng-container>
    <ng-container *ngTemplateOutlet="deleteButton"></ng-container>
    <ng-container *ngTemplateOutlet="filterButton"></ng-container>

    <button class="malou-btn-raised--primary btn-xl !h-12.5" mat-raised-button (click)="onCreateTemplate()">
        {{ 'templates.review.actions.create_template' | translate }}
    </button>
</div>

@if (screenSizeService.isPhoneScreen) {
    <div class="w-full pb-1 md:block" [class.show-advanced-filters]="shouldShowAdvancedFilters">
        <div class="mb-2 w-full items-center gap-x-2 md:flex">
            <ng-container *ngTemplateOutlet="duplicateButton"></ng-container>
            <ng-container *ngTemplateOutlet="deleteButton"></ng-container>
            <ng-container *ngTemplateOutlet="filterButton"></ng-container>
            <button class="malou-btn-icon--primary btn-xl" mat-icon-button (click)="onCreateTemplate()">
                <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
            </button>
        </div>
        <button class="malou-btn-flat !px-0" mat-button (click)="toggleAdvancedFilters()">
            {{ 'common.advanced_options' | translate }}
            <mat-icon class="chevron-icon !h-[16px] !w-[16px]" iconPositionEnd [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </button>
        <div class="search mt-2 flex h-0 grow flex-col gap-y-1 opacity-0">
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="(sortBy$ | async) ?? 'name'"
                [sortOrder]="(sortOrder$ | async) ?? 1"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange($event)">
            </app-sort-by-filters>
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearch($event)"></app-search>
        </div>
    </div>
}

<ng-template #duplicateButton>
    <div
        [matTooltip]="
            !hasSelectedTemplates ? ('templates.review.actions.must_select_template' | translate) : ('common.duplicate' | translate)
        ">
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matMenuTriggerFor]="duplicateMenu"
            [disabled]="!hasSelectedTemplates">
            <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #deleteButton>
    <div
        [matTooltip]="
            !hasSelectedTemplates ? ('templates.review.actions.must_select_template' | translate) : ('common.delete' | translate)
        ">
        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [disabled]="!hasSelectedTemplates" (click)="delete()">
            <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #filterButton>
    <div [matTooltip]="'common.filter' | translate">
        <button
            class="malou-btn-icon--secondary btn-xl"
            mat-icon-button
            [matMenuTriggerFor]="filterMenu"
            [matTooltip]="'common.filters' | translate">
            @if (filterCount > 0) {
                <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                    {{ filterCount }}
                </span>
            }
            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
        </button>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow" #duplicateMenu="matMenu">
    <button mat-menu-item (click)="duplicate(DuplicationDestination.HERE)">
        <span class="malou-text-14--regular">
            {{ 'templates.review.actions.duplicate_here' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="duplicate(DuplicationDestination.OUT)">
        <span class="malou-text-14--regular">
            {{ 'common.to_other_venues' | translate }}
        </span>
    </button>
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #filterMenu="matMenu">
    <div class="w-[440px] md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between p-6">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="filterCount == 0" (click)="clearFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>

        <div class="filter__body mb-2 flex flex-col gap-y-5">
            <ng-container [ngTemplateOutlet]="ratingsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="commentsFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #ratingsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'templates.review.filters.ratings' | translate }}
        </div>
        <app-ratings-filters [availableRatings]="[1, 2, 3, 4, 5, 0]" [ratings]="ratings()" (onToggleRating)="toggleRating($event)">
        </app-ratings-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'templates.review.filters.automated' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (automationFilter of AVAILABLE_AUTOMATION_FILTERS; track automationFilter) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple>
                    <mat-button-toggle
                        class="!h-12.5 !w-12.5 rounded-[10px]"
                        value="{{ automationFilter }}"
                        [checked]="filters[automationFilter]"
                        (change)="toggleReviewFilter(automationFilter)">
                        <div class="malou-text-12--medium mt-0.5">
                            {{ (automationFilter === 'automated' ? 'common.yes' : 'common.no') | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>

<ng-template #commentsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'templates.review.filters.comments' | translate }}
        </div>
        <div class="flex gap-x-4">
            @for (commentFilter of AVAILABLE_COMMENTS_FILTERS; track commentFilter) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary w-full" multiple>
                    <mat-button-toggle
                        class="!h-12.5 w-full rounded-[10px]"
                        value="{{ commentFilter }}"
                        [checked]="filters[commentFilter]"
                        (change)="toggleReviewFilter(commentFilter)">
                        <div class="malou-text-12--medium mt-0.5">
                            {{ 'templates.review.filters.' + commentFilter | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>
