import { Component } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { AuthService } from ':core/auth/auth.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { loadUser, loadUserPlatformPermissions } from ':modules/user/store/user.actions';
import { ToastDuration } from ':shared/components-v3/toast/toast-item/toast-item.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { isSafari } from ':shared/helpers/is-safari';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InputTextComponent,
        MatButtonModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
    ],
})
export class LoginComponent {
    public loginForm: UntypedFormGroup = this._fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    });
    passwordError = '';

    constructor(
        private readonly _authService: AuthService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _router: Router,
        private readonly _translate: TranslateService,
        private readonly _store: Store
    ) {}

    get email(): AbstractControl | null {
        return this.loginForm.get('email');
    }

    login(): void {
        this._spinnerService.show();
        this._authService
            .login(this.loginForm.value)
            .pipe(switchMap(() => this._authService.initSession()))
            .subscribe({
                next: () => {
                    this._spinnerService.hide();
                    this._store.dispatch({ type: loadUser.type });
                    this._store.dispatch({ type: loadUserPlatformPermissions.type, key: PlatformKey.GMB });
                    const redirectUrl = this._authService.getRedirectUrl();
                    this._router.navigateByUrl(redirectUrl);
                },
                error: (err) => {
                    console.warn(err);
                    this._spinnerService.hide();
                    if (err.status === 404) {
                        this.passwordError = this._translate.instant('login.invalid_password');
                    } else if (err.status === 403) {
                        this.passwordError = this._translate.instant('login.not_approved');
                    } else if (err.status === 401) {
                        // Unauthorized comes from the initSession call if the user does not have a session
                        // ie the user is not logged in, it might be because he block cookies on Safari
                        this.passwordError = this._translate.instant('login.unknown_error');
                        if (isSafari) {
                            this._toastService.openErrorToast(this._translate.instant('login.no_cookies_safari'), ToastDuration.LONG);
                        }
                    } else {
                        this.passwordError = this._translate.instant('login.unknown_error');
                    }
                },
            });
    }

    goToForgotPassword(): void {
        this._router.navigate(['../auth/password/forgot']);
    }
}
