import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';

@Component({
    selector: 'app-sidenav-content-boosters-routes',
    templateUrl: './sidenav-content-boosters-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule, ConcatPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentBoostersRoutesComponent {
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly SvgIcon = SvgIcon;
}
