import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { PlatformKey } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';

@Component({
    selector: 'app-gmb-credentials',
    templateUrl: './gmb-credentials.component.html',
    styleUrls: ['./gmb-credentials.component.scss'],
    standalone: true,
    imports: [MatButtonModule],
})
export class GmbCredentialsComponent implements OnInit {
    @Input() credential: any;
    authorizeUrl: string;

    constructor(private readonly _credentialsService: CredentialsService) {}

    ngOnInit(): void {
        this._credentialsService.getOauthLoginUrl(PlatformKey.GMB).subscribe((res) => {
            this.authorizeUrl = res.data;
        });
    }
}
