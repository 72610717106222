<div class="malou-dialog !h-[40vh] md:!absolute md:bottom-0 md:!h-[80vh]">
    <div class="malou-dialog__header">
        <div class="malou-text__card-title">
            {{ 'messages.message_area.templates.title' | translate }}
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body !overflow-hidden">
        <app-select
            [title]="'messages.message_area.templates.subtitle' | translate"
            [placeholder]="'common.search' | translate"
            [values]="data.templates"
            [displayWith]="displayWith"
            (selectChange)="selectTemplate($event)">
            <ng-template let-value="value" #simpleSelectedValueTemplate>
                <ng-container [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
            </ng-template>
            <ng-template let-value="value" #optionTemplate>
                <ng-container [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
            </ng-template>
            <ng-template let-value="value" #displayTemplate>
                <div class="flex items-center">
                    <div class="malou-text-13--bold text-malou-color-text-2">{{ value.name }} -</div>
                    <div class="malou-text-13--regular truncate text-malou-color-text-2">
                        {{ replaceText | applyPure: value.text }}
                    </div>
                </div>
            </ng-template>
        </app-select>
    </div>
    <div class="malou-dialog__footer">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'forms.buttons.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button [disabled]="!selectedTemplate" (click)="useTemplate()">
            {{ 'messages.message_area.templates.use_template' | translate }}
        </button>
    </div>
</div>
