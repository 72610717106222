import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SimilarRestaurantsDto } from '@malou-io/package-dto';
import { ApiResultV2, SimilarRestaurantCategory } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SimilarRestaurantsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/similar-restaurants`;

    constructor(private readonly _http: HttpClient) {}

    get(restaurantId: string): Observable<ApiResultV2<SimilarRestaurantsDto>> {
        return this._http.get<ApiResultV2<SimilarRestaurantsDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`);
    }

    getMany(restaurantIds: string[]): Observable<ApiResultV2<SimilarRestaurantsDto[]>> {
        return this._http.post<ApiResultV2<SimilarRestaurantsDto[]>>(`${this.API_BASE_URL}/restaurants`, {
            restaurantIds,
        });
    }

    update(restaurantId: string, category: SimilarRestaurantCategory): Observable<ApiResultV2<void>> {
        return this._http.put<ApiResultV2<void>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { category });
    }
}
