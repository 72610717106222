import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { IFolder } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-folder-picker',
    templateUrl: './folder-picker.component.html',
    styleUrls: ['./folder-picker.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatTooltipModule, TranslateModule, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderPickerComponent {
    @Input() folders: IFolder[];
    @Input() currentFolder: IFolder | null;
    @Output() openFolder: EventEmitter<IFolder | null> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    onOpenFolder(folder: IFolder | null): void {
        this.openFolder.emit(folder);
    }
}
