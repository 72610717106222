<div class="malou-card container m-8">
    <div class="flex items-center justify-between">
        <div class="flex items-center justify-between" style="width: 80%">
            <mat-label>Webhook URL</mat-label>
            <mat-form-field appearance="outline" style="width: 90%">
                <input matInput [disabled]="!maintenance?.webhookRedirectActive" [formControl]="webhookUriControl" />
            </mat-form-field>
            <button
                class="malou-button--accent"
                mat-raised-button
                [disabled]="!maintenance?.webhookRedirectActive"
                (click)="updateWebhookUri()">
                Confirmer
            </button>
        </div>

        <mat-button-toggle
            class="malou-single-toggle-icon-btn--tertiary"
            [checked]="maintenance?.webhookRedirectActive"
            (change)="updateWebhookStatus($event)">
            Webhook active
        </mat-button-toggle>
    </div>
</div>
