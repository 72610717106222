import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sentenceCase',
    standalone: true,
})
export class SentenceCasePipe implements PipeTransform {
    transform(text?: string, { capitalizeOnly }: { capitalizeOnly: boolean } = { capitalizeOnly: false }): string {
        if (!text?.length) {
            return '';
        }
        if (text.length === 1) {
            return text.toUpperCase();
        }
        return capitalizeOnly ? `${text[0].toUpperCase()}${text.slice(1)}` : `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;
    }
}
