/**
 * Facebook (and Instagram) make multiple formatting before publishing a post.
 *
 * 1 - remove multiple blank lines and replace them by one blank line
 * 2 - remove spaces and tabs on each lines
 * 3 - trim the posts
 * 4 - remove unusual separators (line separator and paragraph separator)
 * 5 - remove replacement character �
 *
 */
import { isNil } from 'lodash';

export function formatSocialNetworkPostText(postText: string): string {
    if (isNil(postText)) {
        return '';
    }

    let result = postText;

    const twoBlankLinesRegex = /\n\s*\n\s*\n/g;
    result = result.replace(twoBlankLinesRegex, '\n\n');

    const spacesAndTabsAtStartAndEndOfLineRegex = /^[ \t]+|[ \t]+$/gm;
    result = result.replace(spacesAndTabsAtStartAndEndOfLineRegex, '');

    result = result.trim();

    const unusualSeparatorsRegex = /[\u2028\u2029]/g;
    result = result.replace(unusualSeparatorsRegex, '');

    const replacementCharacterRegex = /�/g;
    result = result.replace(replacementCharacterRegex, '');

    return result;
}
