<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>💰️</div>
        <div class="flex flex-col">
            <div class="malou-text-11--semibold flex items-center gap-x-2 text-malou-color-text-1">
                {{ 'notification-center.roi_activated_notification_item.title' | translate }}
            </div>
            <div class="malou-text-11 text-malou-color-text-2">
                {{
                    hasMultipleRestaurantsInNotification()
                        ? ('notification-center.roi_activated_notification_item.text' | translate)
                        : ('notification-center.roi_activated_notification_item.text_single_restaurant'
                          | translate: { restaurantName: restaurant()?.getDisplayName() })
                }}
            </div>
        </div>
        <div class="ml-auto">
            <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.see' | translate }}</button>
        </div>
    </div>
</div>
