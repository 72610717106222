<div class="flex h-14 w-full gap-3 rounded border border-malou-color-background-dark bg-white p-1 sm:h-20">
    <div class="flex w-11 items-center justify-center rounded bg-malou-color-background-darker sm:w-9">
        <img class="m-3 h-4" [src]="emoji()" />
    </div>
    <div class="flex flex-1 flex-col justify-center">
        <span class="malou-text-12--regular sm:malou-text-10--regular text-malou-color-text-2">{{ title() }}</span>
        <span class="malou-text-10 sm:malou-text-8 italic text-malou-color-text-2">{{ details() }}</span>
    </div>
    <div class="flex items-center gap-1">
        <button
            class="malou-btn-flat !malou-text-10--semibold sm:malou-text-8--semibold mr-2 sm:mt-2 sm:self-center"
            (click)="redirectToPage()">
            {{ buttonText() }}
        </button>
    </div>
</div>
