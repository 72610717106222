<app-select-base
    class="h-12.5 w-full"
    [formControl]="control"
    [required]="required()"
    [title]="title()"
    [placeholder]="'common.search' | translate"
    [values]="availableRatings()"
    [multiSelection]="true"
    [checkboxOption]="true"
    [displayWith]="displayWithRating"
    [computeObjectHash]="compareByRatingValue"
    [errorMessage]="errorMessage()">
    <ng-template let-value="value" #selectedValueTemplate>
        <app-star-with-text-chip [value]="value === 0 ? null : value" [matTooltip]="tooltipOnRating()"></app-star-with-text-chip>
    </ng-template>
</app-select-base>
