import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getOrganizationParamsValidator = z
    .object({
        organization_id: objectIdValidator,
    })
    .transform((data) => ({
        organizationId: data.organization_id,
    }));
export type GetOrganizationParamsDto = z.infer<typeof getOrganizationParamsValidator>;

export const linkVerifiedEmailToOrganizationParamsValidator = z.object({
    organizationId: objectIdValidator,
});
export type LinkVerifiedEmailToOrganizationParamsDto = z.infer<typeof linkVerifiedEmailToOrganizationParamsValidator>;

export const linkVerifiedEmailToOrganizationBodyValidator = z.object({
    verifiedEmail: z.string(),
});
export type LinkVerifiedEmailToOrganizationBodyDto = z.infer<typeof linkVerifiedEmailToOrganizationBodyValidator>;
