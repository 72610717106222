import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { CommentsFilters, Pagination, Platform, Restaurant } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { PostWithCommentsAndMentions } from '../comments.interface';
import { CommentsService } from '../comments.service';
import { NoCommentViewComponent } from '../no-comment-view/no-comment-view.component';
import { PostCommentPreviewComponent } from '../post-comment-preview/post-comment-preview.component';
import { selectAreFiltersDefault, selectCommentsFilters } from '../store/comments.reducer';

const DEFAULT_PAGINATION = { pageSize: 24, pageNumber: 0, total: 0 };
@Component({
    selector: 'app-comments-posts-view',
    templateUrl: './comments-posts-view.component.html',
    styleUrls: ['./comments-posts-view.component.scss'],
    standalone: true,
    imports: [
        SkeletonComponent,
        NoCommentViewComponent,
        InfiniteScrollModule,
        PostCommentPreviewComponent,
        ScrollToTopComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        LazyLoadImageModule,
    ],
})
export class CommentsPostsViewComponent implements OnInit {
    @Input() platforms: Platform[];
    @Input() isUpdating = false;

    readonly killSubscriptions$: Subject<void> = new Subject();

    restaurant$ = this._restaurantsService.restaurantSelected$;
    restaurant: Restaurant;

    posts: PostWithCommentsAndMentions[];
    pagination: Pagination = DEFAULT_PAGINATION;
    filters$: Observable<CommentsFilters> = this._store
        .select(selectCommentsFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    filters: CommentsFilters;
    areFiltersDefault$: Observable<boolean> = this._store.select(selectAreFiltersDefault);

    isLoading = true;
    loadingMore$ = new BehaviorSubject(false);
    fetchCommentsError: any = null;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        private readonly _commentsService: CommentsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this.restaurant$.pipe(filter(Boolean), takeUntil(this.killSubscriptions$)).subscribe({
            next: (restaurant: Restaurant) => (this.restaurant = restaurant),
        });

        combineLatest([this.restaurant$, this.filters$, this._commentsService.shouldReload$])
            .pipe(
                filter(Boolean),
                switchMap(([restaurant, filters]: [Restaurant, CommentsFilters, boolean]) => {
                    this.isLoading = true;
                    this.restaurant = restaurant;
                    this.filters = filters;
                    return this._commentsService.getRestaurantCommentsByPostPaginated(restaurant._id, DEFAULT_PAGINATION, filters);
                }),
                map((res) => res.data),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (data) => {
                    this.pagination = data.pagination;
                    this.posts = data.posts;
                    this.fetchCommentsError = null;
                    this.isLoading = false;
                },
                error: (err) => {
                    console.warn(err);
                    this.fetchCommentsError = err;
                    this.isLoading = false;
                },
            });
    }

    onScroll(): void {
        if (!this.loadingMore$.value) {
            if (this._reachedMaximumMessages()) {
                return;
            }
            this.loadMorePosts();
        }
    }

    loadMorePosts(): void {
        this.loadingMore$.next(true);
        this._commentsService
            .getRestaurantCommentsByPostPaginated(
                this.restaurant._id,
                { ...this.pagination, pageNumber: this.pagination.pageNumber + 1 },
                this.filters
            )
            .subscribe({
                next: (res) => {
                    this.loadingMore$.next(false);
                    this.pagination = res.data.pagination;
                    this.posts.push(...res.data.posts);
                    this.fetchCommentsError = null;
                },
                error: (err) => {
                    this.loadingMore$.next(false);
                    console.warn(err);
                    this.fetchCommentsError = null;
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
    }

    onCommentToggleArchive({ post, archived }: { post: PostWithCommentsAndMentions; archived: boolean }): void {
        if (archived && this.filters.archived) {
            return;
        }
        if (archived && !this.filters.archived) {
            this.posts.splice(this.posts.indexOf(post), 1);
        }
    }

    private _reachedMaximumMessages(): boolean {
        return this.pagination.pageNumber * this.pagination.pageSize >= this.pagination.total;
    }
}
