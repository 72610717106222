import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getRestaurantAttributesByCategoryParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetRestaurantAttributesByCategoryParamsDto = z.infer<typeof getRestaurantAttributesByCategoryParamsValidator>;
// ------------------------------------------------------------------------------------------
export const getRestaurantWithAttributesByIdParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetRestaurantWithAttributesByIdParamsDto = z.infer<typeof getRestaurantWithAttributesByIdParamsValidator>;
