@if (categoriesWithDifference.length) {
    <div>
        @for (categoryWithDifference of categoriesWithDifference; track categoryWithDifference) {
            <div>
                <span
                    class="malou-text-12--medium malou-color-text-2"
                    [ngClass]="{ 'has-difference': categoryWithDifference.hasDifference }"
                    >{{ categoryWithDifference.value }}
                </span>
            </div>
        }
    </div>
} @else {
    <span class="has-difference"> - </span>
}
