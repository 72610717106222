import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { GiftDrawsService } from ':core/services/gift-draws.service';
import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScansService } from ':core/services/scans.service';
import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { MAX_TOTEM_NUMBER_FOR_FILTER } from ':shared/constants/filters';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, Nfc } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';

import { BoostersStatisticsData } from '../boosters.component';

@Injectable({
    providedIn: 'root',
})
export class BoostersDataFetchingService {
    constructor(
        private readonly _translateService: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _scansService: ScansService,
        private readonly _nfcService: NfcService,
        private readonly _giftDrawsService: GiftDrawsService
    ) {}

    getTotems(): Observable<Nfc[]> {
        return this._nfcService
            .search({ page: 1, limit: MAX_TOTEM_NUMBER_FOR_FILTER, restaurantId: this._restaurantsService.currentRestaurant._id })
            .pipe(map((apiResult) => apiResult.data.map((nfc) => Nfc.fromNfcDto(nfc))));
    }

    getChartsData(nfcs: Nfc[], dates: DatesAndPeriod): Observable<BoostersStatisticsData> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const difference = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).toObject();
        const previousStartDate = DateTime.fromJSDate(startDate).minus(difference).toJSDate();
        const scanDtos$ = this._scansService
            .search({
                nfcIds: nfcs?.map((nfc) => nfc.id).concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]) ?? [],
                restaurantIds: [this._restaurantsService.currentRestaurant._id],
                startScannedAt: previousStartDate.toISOString(),
                endScannedAt: endDate.toISOString(),
            })
            .pipe(
                map((apiResult) => apiResult.data.map((scan) => new ScanForStats(scan))),
                shareReplay(1)
            );
        const wheelOfFortuneFakeNfc = Nfc.createFakeWheelOfFortuneNfc({
            restaurantId: this._restaurantsService.currentRestaurant._id,
            redirectionLink: '',
            platformKey: PlatformKey.GMB,
            name: this._translateService.instant('statistics.totems.scan_count.wheel_of_fortune'),
        });
        wheelOfFortuneFakeNfc.name = this._translateService.instant('statistics.totems.scan_count.wheel_of_fortune');
        const previousScans$ = scanDtos$.pipe(map((scanDtos) => scanDtos.filter((scan) => new Date(scan.scannedAt) < startDate)));
        const scans$ = scanDtos$.pipe(map((scanDtos) => scanDtos.filter((scan) => new Date(scan.scannedAt) >= startDate)));

        const wheelOfFortuneCount$ = scans$.pipe(map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length));
        const previousWheelOfFortuneCount$ = previousScans$.pipe(
            map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length)
        );

        return forkJoin({
            nfcs: of((nfcs ?? []).concat([wheelOfFortuneFakeNfc])),
            scans: scans$,
            previousScans: previousScans$,
            previousStartDate: of(previousStartDate),
            wheelOfFortuneCount: wheelOfFortuneCount$,
            previousWheelOfFortuneCount: previousWheelOfFortuneCount$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }

    getGiftsData(dates: DatesAndPeriod): Observable<WheelOfFortuneGiftsStatisticsData> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const giftsInsightsPerGift$ = this._giftDrawsService
            .getInsightsByGifts(this._restaurantsService.currentRestaurant._id, startDate, endDate)
            .pipe(map((res) => res?.data));

        return forkJoin({
            giftsInsightsPerGift: giftsInsightsPerGift$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }
}
