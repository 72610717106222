import { z } from 'zod';

import { isValidUrl } from '@malou-io/package-utils';

export const objectIdValidator = z.string().regex(/^[0-9a-fA-F]{24}$/);

export const booleanAsStringValidator = z.string().refine((value) => ['true', 'false'].includes(value));

// ------------------------------------------------------------------------------------------
export const urlValidator = (options?: { allowEmpty: boolean }) =>
    z.string().refine((url) => isValidUrl(url, { allowEmpty: options?.allowEmpty ?? false }));
