<nav mat-tab-nav-bar [tabPanel]="tabPanel">
    @for (tab of tabs; track tab) {
        <a mat-tab-link [active]="activeTab.title === tab.title" (click)="onTabCLick(tab)">
            <span
                class="malou-color-text-2 italic"
                [class.malou-text-14--bold]="activeTab.title === tab.title"
                [class.malou-text-14--regular]="activeTab.title !== tab.title"
                >{{ tab.title }}</span
            >
            <span class="malou-color-text-2 malou-text-14--regular italic">{{ tab.subtitle }}</span>
        </a>
    }
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
