import { Routes } from '@angular/router';

import { SocialPostsRootComponent } from './social-posts-root.component';

export const SOCIAL_POSTS_ROUTES: Routes = [
    {
        path: '',
        component: SocialPostsRootComponent,
        children: [],
    },
];
