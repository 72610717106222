<div class="flex items-center justify-between border-b border-malou-color-border-primary bg-malou-color-background-white px-6 py-2">
    <div class="flex items-center gap-x-1">
        <button
            class="malou-btn-icon--secondary !border-none"
            data-testid="social-post-sync-btn"
            mat-icon-button
            [matTooltip]="'common.synchronize' | translate"
            [disabled]="!isAnyPlatformConnected()"
            (click)="synchronize()">
            <mat-icon [svgIcon]="SvgIcon.SYNC"></mat-icon>
        </button>

        @for (filterOptionAndCount of filterOptionsAndCount(); track filterOptionAndCount.filterOption) {
            @let filterOption = filterOptionAndCount.filterOption;
            @let count = filterOptionAndCount.count;
            <div
                class="flex cursor-pointer gap-x-[2px] rounded p-2 text-malou-color-text-1"
                [ngClass]="{
                    'malou-text-12--regular': filterOption !== selectedFilter(),
                    'malou-text-12--semibold bg-malou-color-background-dark': filterOption === selectedFilter(),
                    '!text-malou-color-chart-pink--accent': filterOption === SocialPostsListFilter.ERROR,
                }"
                (click)="selectFilter(filterOption)">
                <div>{{ filterOption | enumTranslate: 'social_posts_list_filter' }}</div>
                @if (count) {
                    <div class="italic">({{ count }})</div>
                }
            </div>
        }
    </div>

    <div class="flex gap-x-1">
        <app-create-social-post-menu-button
            (createPost)="onCreatePost()"
            (createReelOrTikTok)="onCreateReelOrTikTok()"></app-create-social-post-menu-button>
    </div>
</div>
