import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'externalHref',
    standalone: true,
})
export class ExternalHrefPipe implements PipeTransform {
    transform(href: string): string {
        return /^https?/.test(href) ? href : `//${href}`;
    }
}
