<div class="flex h-12 w-full items-center justify-between pl-4 pr-2">
    <p class="malou-text-14--bold capitalize text-black">{{ periodLabel }}</p>
    <div class="flex">
        <button class="malou-btn-flat !h-10 !w-10 !p-2" mat-icon-button [disabled]="!previousEnabled()" (click)="previousClicked()">
            <mat-icon class="!w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
        </button>
        <button class="malou-btn-flat !h-10 !w-10 !p-2" mat-icon-button [disabled]="!nextEnabled()" (click)="nextClicked()">
            <mat-icon class="!w-4 pr-5" color="primary" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
        </button>
    </div>
</div>
