import { Routes } from '@angular/router';

import { ReviewsComponent } from '../reviews/reviews/reviews.component';
import { AggregatedReputationComponent } from './aggregated-reputation.component';

export const AGGREGATED_REPUTATION_ROUTES: Routes = [
    {
        path: '',
        component: AggregatedReputationComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'reviews',
            },
            {
                path: 'reviews',
                component: ReviewsComponent,
            },
        ],
    },
];
