/**
 * Warning: This only work for detecting if an emoji is present in a string
 * It does not work to extract emojis from a string
 * https://stackoverflow.com/a/64007175
 * */
export const EMOJI_REGEX = /\p{Extended_Pictographic}/u;

export function hasEmoji(text: string): boolean {
    return EMOJI_REGEX.test(text);
}
