import { createAction, props } from '@ngrx/store';

import { Restaurant } from ':shared/models';

export const loadRestaurants = createAction('[Restaurants] Load_Restaurants');

export const editRestaurants = createAction('[Restaurants] Edit_Restaurants', props<{ restaurants: Restaurant[] }>());

export const removeRestaurantsByIds = createAction('[Restaurants] Remove_Restaurants_By_Ids', props<{ restaurantIds: string[] }>());

export const editRestaurant = createAction('[Restaurants] Edit_Restaurant', props<{ restaurant: Restaurant }>());
