import { AddressDto } from '@malou-io/package-dto';

import { Address } from ':shared/models/address';

export class ChartReviewsStats {
    restaurant: {
        _id: string;
        name: string;
        address: Address | null;
        internalName?: string;
    };
    averageRating?: number;
    total?: number;
    reviews: {
        key: string;
        socialId: string;
        rating: number;
    }[];

    public constructor(
        init: Omit<ChartReviewsStats, 'restaurant'> & {
            restaurant: Omit<ChartReviewsStats['restaurant'], 'address'> & { address: AddressDto | null | undefined };
        }
    ) {
        Object.assign(this, init);
        this.restaurant.address = this.restaurant.address ? new Address(this.restaurant.address) : null;
        this.restaurant.internalName = init.restaurant.internalName ?? init.restaurant.name;
    }
}
