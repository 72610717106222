import { createAction, props } from '@ngrx/store';

import { Media } from ':shared/models';

export const setIsGalleryOpen = createAction('[Gallery Import Media] Set_Gallery_Is_Open', props<{ isGalleryOpen: boolean }>());

export const setCreatedMedia = createAction('[Gallery Import Media] Set_Created_Media', props<{ createdMedia: Media[] }>());

export const setCurrentFolderId = createAction('[Gallery Import Media] Set_Current_Folder_Id', props<{ currentFolderId: null | string }>());

export const setFilesErrors = createAction('[Gallery Import Media] Set_Files_Errors', props<{ filesErrors: string[] }>());
