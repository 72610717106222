import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject } from 'rxjs';

import { MediaType } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import { MediaPreviewModalComponent } from ':modules/gallery/modals/media-preview-modal/media-preview-modal.component';
import { AttachmentsPreviewModalComponent } from ':shared/components/attachments-preview-modal/attachments-preview-modal.component';
import { Media, Restaurant } from ':shared/models';
import { Message, Reaction } from ':shared/models/message';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MessagesService } from '../../messages.service';

enum ReactionType {
    LIKE = 'like',
    REACT = 'react',
    UNREACT = 'unreact',
}

@Component({
    selector: 'app-message-card',
    templateUrl: './message-card.component.html',
    styleUrls: ['./message-card.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        MalouSpinnerComponent,
        AsyncPipe,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AvatarPipe,
        LazyLoadImageModule,
    ],
})
export class MessageCardComponent implements OnInit {
    @Input() message: Message;
    @Input() shouldDisplayProfilePicture: boolean;
    @Input() shouldDisplayMessagesNewStartDate: boolean;
    @Input() restaurant: Restaurant;
    @Output() onRetrySendMessage = new EventEmitter<Message>();

    readonly SvgIcon = SvgIcon;
    readonly messageStoryMediaType$: BehaviorSubject<'image' | 'video'> = new BehaviorSubject('image');
    readonly MediaType = MediaType;
    deletedMessageText: string;

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _translate: TranslateService,
        private readonly _messagesService: MessagesService,
        private readonly _toastService: ToastService,
        private readonly _avatarPipe: AvatarPipe
    ) {}

    ngOnInit(): void {
        this.message = new Message(this.message);
        this.deletedMessageText = this.message.isFromRestaurant
            ? this._translate.instant('messages.message_deleted_from_you')
            : this._translate.instant('messages.message_deleted');
        if (this.message?.story) {
            fetch(this.message.story.storySocialUrl)
                .then((res) => {
                    this.messageStoryMediaType$.next(res.headers.get('content-type')?.includes('video') ? 'video' : 'image');
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }

    getMessageDateOrToday = (socialCreatedAt: string): string => {
        const messageDate = DateTime.fromISO(socialCreatedAt);
        const today = DateTime.local();
        const isToday = messageDate.hasSame(today, 'day');
        return isToday ? this._translate.instant('messages.today') : this.getMessageDate(socialCreatedAt).split(' ')[0];
    };

    getAvatarDisplayName(): string {
        return this.message.getAvatarDisplayName(this.restaurant.name);
    }

    getAvatarUrl = (): string => this.message.getMessageSenderAvatar() ?? this._avatarPipe.transform(this.getAvatarDisplayName());

    getMessageDate = (socialCreatedAt: string): string =>
        DateTime.fromISO(socialCreatedAt).toFormat(this._translate.instant('messages.date_format'));

    retrySendMessage(): void {
        this.onRetrySendMessage.emit(this.message);
    }

    getReplyToStoryText = (message: Message): string => message.getReplyToStoryText(this._translate);

    getMentionStoryText = (message: Message): string => message.getMentionStoryText(this._translate);

    openCarousel(): void {
        const attachments = [
            {
                type: this.message.getFirstAttachmentType(),
                urls: {
                    original: this.message.attachments?.[0]?.url,
                },
            },
        ];
        this._customDialogService
            .open(AttachmentsPreviewModalComponent, {
                data: {
                    attachments,
                    index: 0,
                },
            })
            .afterClosed()
            .subscribe();
    }

    updateReaction(event: MouseEvent): void {
        const reactionType =
            this.message?.reactions?.length > 0 && this.message?.hasReactionsFromRestaurant() ? ReactionType.UNREACT : ReactionType.REACT;

        if (reactionType === ReactionType.UNREACT && this.message.hasReactionsFromRestaurant()) {
            this._removeReaction(this.message, event);
        } else if (reactionType === ReactionType.REACT && this.message.canSendReaction()) {
            const newReaction = this._buildNewReaction(this.restaurant, this.message);
            this._addReaction(this.message, newReaction, event);
        } else {
            return;
        }

        this._messagesService.sendUpdateReaction(this.restaurant._id, this.message._id, reactionType).subscribe({
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._clarifyError(err, reactionType));
            },
        });
    }

    openStory(mediaType: MediaType.PHOTO | MediaType.VIDEO): void {
        const medias = [
            new Media({
                type: mediaType,
                urls: {
                    original: this.message?.story?.storySocialUrl,
                },
            }),
        ];
        this._customDialogService
            .open(MediaPreviewModalComponent, {
                data: {
                    medias,
                    mediaIndex: 0,
                    restaurant: this.restaurant,
                    actions: [],
                },
            })
            .afterClosed()
            .subscribe();
    }

    private _clarifyError(err: any, reactionType: string): string {
        if (reactionType === ReactionType.REACT && String(err?.error?.message).match(/An unexpected error has occurred/)) {
            return this._translate.instant('messages.cant_add_reaction_try_later');
        }
        return reactionType === ReactionType.UNREACT
            ? this._translate.instant('messages.cant_remove_reaction')
            : this._translate.instant('messages.cant_add_reaction');
    }

    private _removeReaction(message: Message, event: MouseEvent): void {
        message.removeReactionFromRestaurant();
        this._removeReactionAnimation(event);
    }

    private _removeReactionAnimation(event: MouseEvent): void {
        (event.target as HTMLDivElement).closest('div')?.classList.remove('reaction-animation');
    }

    private _addReaction(message: Message, reaction: Reaction, event: MouseEvent): void {
        message.addReaction(reaction);
        this._addReactionAnimation(event);
    }

    private _addReactionAnimation(event: MouseEvent): void {
        (event.target as HTMLDivElement).closest('div')?.classList.add('reaction-animation');
    }

    private _buildNewReaction(restaurant: Restaurant, message: Message): Reaction {
        return {
            reactionType: ReactionType.LIKE,
            userInfo: {
                displayName: restaurant.name,
                userSocialId: message.isFromRestaurant && message.userInfo.userSocialId ? message.userInfo.userSocialId : 'anything',
            },
        };
    }
}
