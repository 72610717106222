import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { intersection } from 'lodash';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

@Component({
    selector: 'app-select-language',
    templateUrl: './select-language.component.html',
    styleUrls: ['./select-language.component.scss'],
    standalone: true,
    imports: [MatMenuModule, MatIconModule, MatCheckboxModule, TranslateModule, FlagPathResolverPipe, ApplyPurePipe],
})
export class SelectLanguageComponent {
    @Input() prefixText?: string;

    @Input() set defaultSelectedLangs(value: ApplicationLanguage[]) {
        this.selectedLangs = value ?? [ApplicationLanguage.FR];
    }

    @Output() selectLanguageChange: EventEmitter<ApplicationLanguage[]> = new EventEmitter();

    selectedLangs: ApplicationLanguage[] = [];
    selectableLangs: ApplicationLanguage[] = Object.values(ApplicationLanguage).filter(
        (lang) => lang !== ApplicationLanguage.ES && lang !== ApplicationLanguage.IT
    );

    selectContainerOpened = false;
    readonly SvgIcon = SvgIcon;

    constructor(private readonly _translate: TranslateService) {}

    @Input() set langs(value: ApplicationLanguage[]) {
        this.selectableLangs = value;
        this.selectedLangs = intersection(this.selectedLangs, value);
    }

    selectLanguage(lang: ApplicationLanguage): void {
        this.selectedLangs = this.selectedLangs.find((l) => l === lang)
            ? this.selectedLangs.filter((l) => l !== lang)
            : [...this.selectedLangs, lang];
        this.selectLanguageChange.emit(this.selectedLangs);
    }

    isLanguageSelected = (lang: string): boolean => !!this.selectedLangs.find((l) => l === lang);

    selectAllLanguages(): void {
        this.selectedLangs = this.areAllSelected() ? [] : this.selectableLangs;
        this.selectLanguageChange.emit(this.selectedLangs);
    }

    areAllSelected(): boolean {
        return this.selectedLangs.length === this.selectableLangs.length;
    }

    onMenuOpened(): void {
        // get trigger button width and set it to the menu
        const triggerButton = document.querySelector('.mat-menu-trigger');
        const menu = document.querySelector('.mat-menu-panel') as HTMLElement;
        if (triggerButton && menu) {
            menu.style.width = `${triggerButton.clientWidth}px`;
        }
    }

    langDisplayWith = (lang: ApplicationLanguage): string => this._translate.instant(`header.langs.${lang}`);
}
