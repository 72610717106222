<app-select-base
    [formControl]="control"
    [required]="required()"
    [title]="title()"
    [placeholder]="'common.search' | translate"
    [values]="values()"
    [displayWith]="displayWith"
    [multiSelection]="true"
    [checkboxOption]="true"
    [errorMessage]="errorMessage()">
    <ng-template let-value="value" #selectedValueTemplate>
        <app-star-with-text-chip [value]="value"></app-star-with-text-chip>
    </ng-template>
    <ng-template let-value="value" #optionTemplate>
        <app-star-with-text-chip [value]="value"></app-star-with-text-chip>
    </ng-template>
</app-select-base>
