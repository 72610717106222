@if (topPostCardInputs$ | async; as topPostCardInputs) {
    @if (!(isLoading$ | async)) {
        @if (topPostCardInputs.length > 0) {
            <div class="flex flex-col gap-y-4">
                <div class="malou-text-14--semibold malou-color-text-1 flex">
                    {{ 'aggregated_posts_insights_table.top_3_publications_title' | translate }}
                </div>
                <div class="flex gap-x-6 md:flex-col">
                    @for (topPostCardInput of topPostCardInputs; track trackByUrlFn($index, topPostCardInput)) {
                        <div class="flex-1">
                            <app-top-post-card [topPostCardInputData]="topPostCardInput"></app-top-post-card>
                        </div>
                    }
                </div>
            </div>
        }
    } @else {
        <div class="flex gap-x-6">
            @for (post of [1, 2, 3]; track post) {
                <div class="flex-1">
                    <app-top-post-card-skeleton [withRestaurantInfo]="true"></app-top-post-card-skeleton>
                </div>
            }
        </div>
    }
} @else {
    <div class="flex gap-x-6">
        @for (post of [1, 2, 3]; track post) {
            <div class="flex-1">
                <app-top-post-card-skeleton [withRestaurantInfo]="true"></app-top-post-card-skeleton>
            </div>
        }
    </div>
}
