import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommentOptionValue } from ':shared/enums/with-comment.enum';

@Pipe({
    name: 'withComment',
    standalone: true,
})
export class WithCommentPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(withComment: CommentOptionValue, size: 'short' | 'long'): string {
        if (size === 'short') {
            return this._getShortText(withComment);
        }
        return this._getLongText(withComment);
    }

    private _getShortText(withComment: CommentOptionValue): string {
        switch (withComment) {
            case CommentOptionValue.WITH:
                return this._translate.instant('templates.review.with_comment_short');
            case CommentOptionValue.WITHOUT:
                return this._translate.instant('templates.review.without_comment_short');
            default:
                return this._translate.instant('templates.review.with_or_without_short');
        }
    }

    private _getLongText(withComment: CommentOptionValue): string {
        switch (withComment) {
            case CommentOptionValue.WITH:
                return this._translate.instant('templates.review.with_comment');
            case CommentOptionValue.WITHOUT:
                return this._translate.instant('templates.review.without_comment');
            default:
                return this._translate.instant('templates.review.with_or_without');
        }
    }
}
