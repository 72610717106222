<div class="malou-color-background-light flex h-full flex-col items-center justify-center">
    <div class="malou-box-shadow flex flex-col rounded-lg bg-white p-8 md:h-full md:justify-center">
        <ng-container *ngTemplateOutlet="!errorOccured && !hasAlreadyLeftReview ? leaveReviewTpl : hasAlreadyLeftReviewTpl"></ng-container>
    </div>
</div>

<ng-template #leaveReviewTpl>
    <div class="flex flex-1 flex-col md:gap-4" [ngClass]="{ hidden: reviewSentSuccessFully }">
        <img class="h-24 w-fit self-center text-center" [src]="Illustration.Cook | illustrationPathResolver" />
        <div
            class="malou-text-heading text-center text-malou-color-text-1"
            [innerHtml]="'campaigns.negative_review.title' | translate"></div>
        <br />
        <p class="malou-title__kevin" [innerHtml]="'campaigns.negative_review.text' | translate"></p>
        <br />
        <app-text-area
            class="flex flex-1 flex-col"
            id="reviewText"
            placeholder="---"
            [maxLength]="500"
            [formControl]="reviewTextForm"
            [rows]="4">
        </app-text-area>
        <button
            class="malou-btn-raised--primary !w-fit self-end md:!w-full"
            mat-raised-button
            [disabled]="disableValidate || reviewTextForm.invalid || submittedReview"
            (click)="submitReview()">
            {{ 'campaigns.negative_review.send' | translate }}
        </button>
    </div>

    <div class="flex flex-col" [ngClass]="{ hidden: !reviewSentSuccessFully }">
        <img class="h-36 w-fit self-center text-center" [src]="Illustration.Karl | illustrationPathResolver" />
        <div class="malou-text-heading mt-10 text-center text-malou-color-text-1">
            {{ 'campaigns.negative_review.thanks' | translate }}
        </div>
        <div
            class="malou-text-12--regular mt-6 text-center text-malou-color-text-2"
            [innerHtml]="'campaigns.negative_review.success' | translate"></div>
    </div>
</ng-template>

<ng-template #hasAlreadyLeftReviewTpl>
    <div class="flex gap-6 md:flex-col md:items-center">
        <img class="h-60 w-fit" [src]="Illustration.NotFound | illustrationPathResolver" />
        <div class="flex flex-col justify-center">
            <div class="malou-text-heading my-8 text-malou-color-text-1 md:text-center">
                {{ 'campaigns.error_already_left_review' | translate }}
            </div>
            @if (errorOccured) {
                <p class="malou-text-12--regular text-malou-color-text-2 md:text-center">
                    {{ 'campaigns.error_detail' | translate }}
                </p>
            } @else {
                <p class="malou-text-12--regular text-malou-color-text-2 md:text-center">
                    {{ 'campaigns.error_detail_left_review' | translate }}
                </p>
            }
        </div>
    </div>
</ng-template>
