import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { DialogService } from ':core/services/dialog.service';
import { selectRestaurantId } from ':modules/platforms/store/platforms.reducer';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';

interface MyClickContext {
    action: string;
    concernedPlatforms: string[];
    platformKey?: string;
}

@Directive({
    selector: '[appCheckPermissions]',
    standalone: true,
})
export class CheckPermissionsDirective {
    @Output() myClick = new EventEmitter();
    @Input() context: MyClickContext;

    readonly PERMISSIONS_TRANSLATE = this._translate.instant('permissions');

    constructor(
        private readonly _store: Store,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translate: TranslateService,
        private readonly _dialogService: DialogService
    ) {}

    @HostListener('click', ['$event'])
    onClick(e): void {
        const skip = this.context.platformKey && !this.context.concernedPlatforms?.find((k) => k === this.context.platformKey);
        if (skip) {
            this.myClick.next(e);
            return;
        }
        this._store
            .select(selectPermissionsState)
            .pipe(take(1))
            .subscribe({
                next: (permissions) => {
                    const validPermissions =
                        permissions.data?.length > 0
                            ? this.context.concernedPlatforms.reduce(
                                  (acc, key) =>
                                      acc ||
                                      this.isValidPermissions(
                                          this.getRequiredScopeByAction(this.context.action, key),
                                          permissions.data.find((p) => p.key === key)?.missing || []
                                      ),
                                  false
                              )
                            : true;

                    if (!validPermissions) {
                        this._dialogService.open({
                            title: this.PERMISSIONS_TRANSLATE.authorization_problem,
                            message: this.PERMISSIONS_TRANSLATE.not_the_right_permissions,
                            variant: DialogVariant.ALERT,
                            primaryButton: {
                                label: this.PERMISSIONS_TRANSLATE.edit_params,
                                action: () => {
                                    this.redirectToConnection();
                                },
                            },
                            secondaryButton: {
                                label: this._translate.instant('common.cancel'),
                            },
                        });
                    } else {
                        this.myClick.next(e);
                    }
                },
                error: (err) => console.warn(err),
            });
    }

    redirectToConnection(): void {
        this._store
            .select(selectRestaurantId)
            .pipe(take(1))
            .subscribe({
                next: (restaurantId) =>
                    this._router.navigate(['/restaurants', restaurantId, 'settings', 'platforms', 'connection'], {
                        relativeTo: this._activatedRoute,
                    }),
            });
    }

    isValidPermissions(requiredScope: string[][], missingScope: string[]): boolean {
        return requiredScope.length > 0
            ? !requiredScope.reduce((acc, rs) => acc && !!rs.filter((perm) => missingScope.includes(perm)).length, true)
            : true;
    }

    // return required permissions to perform action
    getRequiredScopeByAction(action: string, platformKey: string): string[][] {
        switch (`${action} | ${platformKey}`) {
            case 'reply_comment | gmb':
                return [[]];

            case 'reply_comment | facebook':
                return [['pages_manage_engagement']];

            case 'reply_comment | instagram':
                return [['instagram_basic', 'instagram_manage_comments', 'pages_show_list', 'page_read_engagement']];

            case 'publish_post | facebook':
                return [['pages_read_engagement', 'pages_show_list', 'pages_manage_posts']];

            case 'publish_post | instagram':
                return [
                    ['instagram_basic', 'instagram_content_publish', 'pages_read_engagement'],
                    ['instagram_basic', 'instagram_content_publish', 'pages_show_list'],
                ];

            case 'sync_posts | facebook':
                return [['pages_read_engagement']];

            case 'sync_posts | instagram':
                return [['pages_show_list', 'pages_read_engagement', 'instagram_basic']];

            case 'reply_review | facebook':
                return [['pages_manage_engagement']];

            case 'search_accounts | instagram':
                return [
                    ['instagram_basic', 'instagram_manage_insights', 'pages_read_engagement'],
                    ['instagram_basic', 'instagram_manage_insights', 'pages_show_list'],
                ];

            default:
                return [];
        }
    }
}
