import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roiPerformanceScorePathResolver',
    standalone: true,
})
export class RoiPerformanceScorePathResolver implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/roi/performance-score/${key}.png`;
    }
}
