import { EntityConstructor } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface MessageNotificationData {
    restaurantIds: string[];
    conversations: {
        restaurantId: string;
        conversationId: string;
        messages: {
            id: string;
            text: string;
            senderName: string;
            senderProfilePictureUrl: string;
        }[];
    }[];

    messageCount: number;
}

type MessageNotificationProps = EntityConstructor<MessageNotification>;

export class MessageNotification extends Notification {
    data: MessageNotificationData;
    constructor(props: MessageNotificationProps) {
        super(props);
    }

    getFirstMessage(): MessageNotificationData['conversations'][0]['messages'][0] {
        return this.data.conversations[0].messages[0];
    }

    hasOneMessage(): boolean {
        return this.data.messageCount === 1;
    }

    getMessagesCount(): number {
        return this.data.messageCount;
    }
}
