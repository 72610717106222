import { Routes } from '@angular/router';

import { ClientsComponent } from './clients.component';

export const CLIENTS_ROUTES: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: ClientsComponent,
            },
        ],
    },
];
