import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { HeapService } from ':core/services/heap.service';
import { NewSocialPostAiContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-ai.context';
import { NewSocialPostHashtagsContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post-hashtags.context';
import { NewSocialPostContext } from ':modules/social-posts/new-social-post-modal/context/new-social-post.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-post-hashtags',
    templateUrl: './post-hashtags.component.html',
    styleUrls: ['./post-hashtags.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatIconModule, TranslateModule, NgClass, SkeletonComponent, ClickOutsideDirective],
})
export class PostHashtagsComponent {
    readonly postText = input.required<string>();

    readonly newSocialPostContext = inject(NewSocialPostContext);
    readonly newSocialPostAiContext = inject(NewSocialPostAiContext);
    readonly newSocialPostHashtagsContext = inject(NewSocialPostHashtagsContext);
    readonly _trackingService = inject(HeapService);

    readonly SvgIcon = SvgIcon;

    chooseHashtagsWithAi(): void {
        this._trackingService.track('tracking_hashtags_generate');
        this.newSocialPostHashtagsContext.getSuggestedHashtags();
        this.newSocialPostHashtagsContext.openPostHashtagsPanel();
    }

    retryHashtagsGeneration(): void {
        this._trackingService.track('tracking_hashtags_retry');
        this.chooseHashtagsWithAi();
    }

    removeHashtag(hashtagIndex: number): void {
        this._trackingService.track('tracking_hashtags_remove');
        this.newSocialPostHashtagsContext.selectedHashtags.update((hashtagsList) =>
            hashtagsList.filter((_, index) => index !== hashtagIndex)
        );
    }
}
