@if (restaurantAttributes.length) {
    <div>
        <div class="attributes-grid flex flex-wrap justify-between">
            @for (attribute of filteredRestaurantAttributes; track trackByIdFn($index, attribute)) {
                <div class="flex items-center px-[10px] py-[5px]">
                    @if (attribute.attributeValue === RestaurantAttributeValue.YES) {
                        <mat-icon class="malou-color-success mr-3 !w-4" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    }
                    @if (attribute.attributeValue !== RestaurantAttributeValue.YES) {
                        <mat-icon class="malou-color-fail mr-3 !w-4" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    }
                    @if (attribute.attribute) {
                        <span class="malou-text-12--regular">
                            {{ attribute.attribute | applySelfPure: 'getAttributeNameForLang' : currentLang }}
                        </span>
                    }
                </div>
            }
            @if (hasTooManyAttr) {
                <div class="flex justify-center">
                    <button class="malou-btn-flat !whitespace-pre-wrap !leading-normal" mat-button (click)="openAttributesModal()">
                        {{ btnText }}
                    </button>
                </div>
            }
        </div>
    </div>
} @else {
    <div class="flex flex-col justify-start">
        <span>--</span>
    </div>
}
