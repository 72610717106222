import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-media-picker',
    templateUrl: './media-picker.component.html',
    styleUrls: ['./media-picker.component.scss'],
    standalone: true,
    imports: [
        ApplyPurePipe,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        NgTemplateOutlet,
        NoopMatCheckboxComponent,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPickerComponent {
    public readonly maxMedia = input(10);

    public readonly maxMediaReachedMessage = input.required<string>();

    // This list must reference the same Media objects that the ones in the `medias`
    // input property (we use the === operator to compare media objects).
    public readonly selectedMedias: Signal<Media[]> = input.required();

    public readonly medias: Signal<Media[]> = input.required();

    public readonly multi: Signal<boolean> = input(false);

    @Output() selected: EventEmitter<Media[]> = new EventEmitter<Media[]>();

    readonly SvgIcon = SvgIcon;

    readonly canAddMedia = computed(() => this.selectedMedias().length < this.maxMedia());

    toggleSelected(media: Media): void {
        if (this.selectedMedias().some((sm) => sm === media)) {
            if (this.multi()) {
                this.selected.emit(this.selectedMedias().filter((sm) => sm !== media));
            } else {
                this.selected.emit([]);
            }
        } else {
            if (this.multi()) {
                if (this.canAddMedia()) {
                    this.selected.emit([...this.selectedMedias(), media]);
                }
            } else {
                this.selected.emit([media]);
            }
        }
    }

    onSelectCheckboxClick(media: Media, event: Event): void {
        event.stopPropagation();
        this.toggleSelected(media);
    }

    isSelected(media: Media): boolean {
        return this.selectedMedias().includes(media);
    }
}
