<div class="malou-dialog h-full">
    <div class="malou-dialog__header">
        <span class="malou-text-18--bold">
            {{ 'events.create_event' | translate }}
        </span>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
    <div class="malou-dialog__body">
        <form [formGroup]="calendarEventForm">
            <div class="flex h-full flex-col justify-between">
                <div>
                    <p class="malou-text-10--regular malou-color-text-2 pb-2.5 italic">
                        {{ 'events.event_name' | translate: { lang: langText[currentLang] } }}
                    </p>
                    <div class="flex gap-x-4">
                        <div
                            class="flex h-12.5 cursor-pointer items-center rounded-[10px] border border-malou-color-border-primary p-2"
                            #emojiToggleElement>
                            <input
                                class="h-full w-8 cursor-pointer bg-white text-center outline-none"
                                type="text"
                                formControlName="emoji"
                                [readonly]="true" />
                            <app-emoji-picker
                                [buttonElement]="emojiToggleElement"
                                [closeOnSelect]="true"
                                (emitEmoji)="changeEmoji($event)"></app-emoji-picker>
                        </div>
                        <div class="grow" formGroupName="name">
                            <app-input-text
                                class="w-full"
                                data-cy="eventNameInput"
                                placeholder="--"
                                [formControlName]="currentLang.toLowerCase()"
                                [svgImg]="getFlagImg(currentLang)"
                                [showMaxLength]="true"
                                [maxLength]="EVENT_TITLE_TEXT_LIMIT">
                            </app-input-text>
                        </div>
                    </div>
                    @if (!translateOtherLanguages) {
                        <div>
                            <div
                                class="my-5 flex cursor-pointer items-center"
                                (click)="expandModalHeight(); translateOtherLanguages = true">
                                <mat-icon class="mr-2 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
                                <span class="malou-text-12--semibold malou-color-primary">{{
                                    'events.change_lang' | translate: { lang: getOtherLangsText() }
                                }}</span>
                            </div>
                        </div>
                    }

                    @if (translateOtherLanguages) {
                        <div class="mb-5 flex flex-col">
                            <div class="flex w-full flex-col" formGroupName="name">
                                @for (otherLang of otherLangs; track otherLang) {
                                    <div class="flex">
                                        <div class="w-[74px]"></div>
                                        <app-input-text
                                            class="mt-4 w-full"
                                            placeholder="--"
                                            [formControlName]="otherLang"
                                            [svgImg]="getFlagImg(otherLang)"
                                            [showMaxLength]="true"
                                            [maxLength]="EVENT_TITLE_TEXT_LIMIT">
                                        </app-input-text>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <app-input-date-picker
                        data-cy="eventDateInput"
                        formControlName="startDate"
                        [required]="true"
                        [title]="'events.event_date' | translate"></app-input-date-picker>
                </div>
            </div>
        </form>
    </div>
    <div class="malou-dialog__footer md:justify-between">
        <button
            class="malou-btn-raised--secondary !mr-4 !h-11"
            data-cy="createEventBtn"
            mat-raised-button
            [ngClass]="{ 'w-[42vw]': isSmallScreen }"
            (click)="close()">
            {{ 'events.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-11"
            id="tracking_create_calendar_event_save_button"
            data-cy="createEventBtn"
            mat-raised-button
            [disabled]="calendarEventForm.invalid"
            [ngClass]="{ 'w-[42vw]': isSmallScreen }"
            (click)="save()">
            {{ 'events.save' | translate }}
        </button>
    </div>
</div>
