import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Keyword } from ':shared/models';

import { KeywordsState } from './keywords.reducer';

export const selectKeywordsState = createFeatureSelector<KeywordsState>('keywords');

export const selectRestaurantId = createSelector(selectKeywordsState, (state: KeywordsState) => state.selectedRestaurantId);

export const selectKeywordsByRestaurantId = createSelector(selectKeywordsState, (state: KeywordsState) => state.keywordsByRestaurantId);

export const selectCurrentKeywords = createSelector(
    selectRestaurantId,
    selectKeywordsByRestaurantId,
    (restaurantId, keywordsByRestaurantId): Keyword[] => {
        if (!restaurantId) {
            return [];
        }
        return keywordsByRestaurantId[restaurantId] ?? [];
    }
);
