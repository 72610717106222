<div class="circle-wrap relative">
    <div class="circle">
        <div class="mask full rotation">
            <div class="fill rotation"></div>
        </div>
        <div class="mask half">
            <div class="fill rotation"></div>
        </div>
    </div>
    <mat-icon
        class="absolute left-[50%] top-[50%] !h-4 !w-4 translate-x-[-50%] translate-y-[-50%] transform text-white"
        [svgIcon]="SvgIcon.CHECK"></mat-icon>
</div>
