<div class="flex flex-col gap-4">
    <ng-container [ngTemplateOutlet]="selectDaysTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="selectPeriodsTemplate"></ng-container>
</div>

<ng-template #selectDaysTemplate>
    <div class="flex justify-between">
        <div class="flex gap-x-3">
            @for (weekDay of DAYS_OPTIONS; track weekDay) {
                <div
                    class="malou-chip malou-text-15--semibold flex min-w-9 select-none items-center justify-center"
                    [ngClass]="{
                        'malou-chip--purple-light !text-malou-color-text-1': !(selectedDays() | includes: weekDay),
                        'bg-malou-color-chart-purple !text-malou-color-text-white': selectedDays() | includes: weekDay,
                        '!cursor-default': !(availableDays() | includes: weekDay) || disabled(),
                        'opacity-30': disabled(),
                    }"
                    (click)="toggleDay(weekDay)">
                    <span class="mt-[1px]" [ngClass]="{ 'opacity-30': !(availableDays() | includes: weekDay) }">
                        {{ getWeekdayLabel | applyPure: weekDay }}
                    </span>
                </div>
            }
        </div>
        @if (canDeleteSelect()) {
            <button class="malou-btn-flat flex items-center gap-x-2 !p-0" mat-button (click)="deleteSelect()">
                <mat-icon class="icon-btn h-4 !w-4" [svgIcon]="SvgIcon.TRASH"></mat-icon>
            </button>
        }
    </div>
</ng-template>

<ng-template #selectPeriodsTemplate>
    <app-select-open-hours
        [(periods)]="periods"
        [(isRestaurantClosedDuringPeriods)]="isRestaurantClosedDuringPeriods"
        [withIsClosedSelection]="withIsClosedSelection()"
        [disabled]="disabled()"></app-select-open-hours>
</ng-template>
