import { DateTime } from 'luxon';

import {
    ApplicationLanguage,
    EntityConstructor,
    formatEnglishDateWithSuffix,
    SpecialHourNotificationAction,
} from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { CalendarEvent } from ':shared/models/calendar-event';

import { Notification } from './notification.model';

export type SpecialHourNotificationEventData = Pick<CalendarEvent, 'id' | 'emoji' | 'startDate' | 'name'> & {
    action: SpecialHourNotificationAction;
};

export interface SpecialHourNotificationData {
    restaurantIds: string[];
    event: SpecialHourNotificationEventData;
}

type SpecialHourNotificationProps = EntityConstructor<SpecialHourNotification>;

export class SpecialHourNotification extends Notification {
    data: SpecialHourNotificationData;

    constructor(props: SpecialHourNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<SpecialHourNotificationProps>): SpecialHourNotification {
        return new SpecialHourNotification({ ...this, ...props });
    }

    getFormattedEventDate = (): string => {
        const userLanguage = LocalStorage.getLang();
        if (userLanguage === ApplicationLanguage.EN) {
            return formatEnglishDateWithSuffix(new Date(this.data.event.startDate));
        }
        return DateTime.fromJSDate(new Date(this.data.event.startDate)).toFormat('dd MMMM');
    };

    getNotificationEmoji = (): string => this.data.event.emoji ?? '🎉';

    getNotificationName = (): string => this.data.event.name[LocalStorage.getLang()]!;
}
