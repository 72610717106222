import { countBy, max, values } from 'lodash';

import { Currency } from '../../constants';
import { ROI_MINIMUM_GMB_RATING_SCORE, ROI_MINIMUM_KEYWORD_SCORE } from './constants';

export const getCurrencySymbol = (currency: Currency): string => {
    switch (currency) {
        case Currency.USD:
            return '$';
        case Currency.GBP:
            return '£';
        case Currency.EUR:
        default:
            return '€';
    }
};

export const getMaxOccurrenceCurrency = (currencyList: Currency[]): Currency => {
    const counts = countBy(currencyList);
    const maxCount = max(values(counts));
    const maxCurrencies = Object.keys(counts).filter((key) => counts[key] === maxCount);

    const priorityList = [Currency.EUR, Currency.USD, Currency.GBP];
    return priorityList.find((currency) => maxCurrencies.includes(currency)) ?? Currency.EUR;
};

export const getNumberWithSpaces = (value: number): string => value.toLocaleString('fr-FR');

export const computeGmbRatingScore = (score: number, total: number): number => {
    const ratio = ((score - ROI_MINIMUM_GMB_RATING_SCORE) / 1.5) ** 2;
    return total * ratio;
};

export const computeAverageKeywordScore = (score: number, total: number): number => {
    const ratio = ((score - ROI_MINIMUM_KEYWORD_SCORE) / 3) ** 2;
    return total * ratio;
};
