import { z } from 'zod';

import { getReviewFiltersBodyValidator } from './review.request.dto';

export const getFilteredReviewsAsPdfBodyValidator = getReviewFiltersBodyValidator;

export const getFilteredReviewsAsPdfQueryValidator = z
    .object({
        time_zone: z.string().optional(),
    })
    .transform((data) => ({ timeZone: data.time_zone }));

export type GetFilteredReviewsAsPdfBodyDto = z.output<typeof getFilteredReviewsAsPdfBodyValidator>;
export type GetFilteredReviewsAsPdfQueryDto = z.output<typeof getFilteredReviewsAsPdfQueryValidator>;
