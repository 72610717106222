import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roiSavedTimePathResolver',
    standalone: true,
})
export class RoiSavedTimePathResolver implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/roi/saved-time/${key}.png`;
    }
}
