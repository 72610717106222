import { z } from 'zod';

import { HashtagType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export type DeleteHashtagResponseDto = {
    success: boolean;
};

export const hashtagDto = z.object({
    id: objectIdValidator,
    text: z.string(),
    isMain: z.boolean(),
    isCustomerInput: z.boolean(),
    restaurantId: objectIdValidator,
    type: z.nativeEnum(HashtagType),
    createdAt: z.date(),
    updatedAt: z.date(),
});

export type HashtagDto = z.infer<typeof hashtagDto>;

export interface GeneratedHashtagDto {
    text: string;
    type: HashtagType;
    isPreSelected: boolean;
}
