import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, take } from 'rxjs';

import { getDatesBetween, isNotNil, isSameDay } from '@malou-io/package-utils';

import { selectActionsData, selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import { DailyValue, TimeScaleToMetricToDataValues } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    actionsInsights: TimeScaleToMetricToDataValues | undefined;
    startDate: Date | null;
    endDate: Date | null;
}

@Injectable({ providedIn: 'root' })
export class GmbVisibilityCsvInsightsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _isDataValid(data: Data): boolean {
        return !!data.actionsInsights && !!data.startDate && !!data.endDate;
    }
    protected override _getData$(): Observable<Data> {
        return forkJoin({
            actionsInsights: this._store.select(selectActionsData).pipe(take(1)),
            dates: this._store.select(selectDatesFilter).pipe(take(1)),
        }).pipe(map(({ actionsInsights, dates }) => ({ actionsInsights, startDate: dates.startDate, endDate: dates.endDate })));
    }
    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return [
            'Date',
            'Google Maps Impressions Desktop',
            'Google Maps Impressions Mobile',
            'Google Search Impressions Desktop',
            'Google Search Impressions Mobile',
            'Calls',
            'Direction Requests',
            'Website Clicks',
            'Menu Clicks',
            'Booking Clicks',
            'Order Clicks',
        ];
    }

    protected override _getCsvDataRows({ actionsInsights, startDate, endDate }: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const insightsByDay = actionsInsights?.by_day;
        const dates = getDatesBetween(startDate, endDate).filter(isNotNil);
        return dates.map((date) => {
            const dateStr = date.toLocaleDateString();
            const businessImpressionsDesktopMaps = this._getStringInsightValueByDay(insightsByDay?.business_impressions_desktop_maps, date);
            const businessImpressionsMobileMaps = this._getStringInsightValueByDay(insightsByDay?.business_impressions_mobile_maps, date);
            const businessImpressionsDesktopSearch = this._getStringInsightValueByDay(
                insightsByDay?.business_impressions_desktop_search,
                date
            );
            const businessImpressionsMobileSearch = this._getStringInsightValueByDay(
                insightsByDay?.business_impressions_mobile_search,
                date
            );
            const actionsPhone = this._getStringInsightValueByDay(insightsByDay?.actions_phone, date);
            const actionsDrivingDirections = this._getStringInsightValueByDay(insightsByDay?.actions_driving_directions, date);
            const actionsWebsite = this._getStringInsightValueByDay(insightsByDay?.actions_website, date);
            const actionsMenu = this._getStringInsightValueByDay(insightsByDay?.actions_menu, date);
            const actionsBooking = this._getStringInsightValueByDay(insightsByDay?.actions_booking, date);
            const actionsOrder = this._getStringInsightValueByDay(insightsByDay?.business_food_orders, date);
            return [
                dateStr,
                businessImpressionsDesktopMaps,
                businessImpressionsMobileMaps,
                businessImpressionsDesktopSearch,
                businessImpressionsMobileSearch,
                actionsPhone,
                actionsDrivingDirections,
                actionsWebsite,
                actionsMenu,
                actionsBooking,
                actionsOrder,
            ];
        });
    }

    private _getStringInsightValueByDay(insights: DailyValue[] | undefined, date: Date): string {
        return insights?.find((insight) => isSameDay(date, new Date(insight.date)))?.value.toString() ?? '';
    }
}
