import { Routes } from '@angular/router';

import { CredentialsRootComponent } from './credentials-root/credentials-root.component';
import { MissingOrganizationComponent } from './missing-organization/missing-organization.component';
import { ValidateCredentialsComponent } from './validate-credentials/validate-credentials.component';

export const CREDENTIALS_ROUTES: Routes = [
    {
        path: '',
        component: CredentialsRootComponent,
        children: [],
    },
    { path: 'validate', component: ValidateCredentialsComponent },
    { path: 'missing_organization', component: MissingOrganizationComponent },
];
