import { CommentReviewDto, ReviewResponseDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { Review, ReviewReply, SemanticAnalysis } from ':shared/models';
import { PrivateReview, PrivateReviewReply } from ':shared/models/private-review';

export namespace ReviewsMapper {
    export function mapToReview(params: ReviewResponseDto): Review | PrivateReview {
        const socialCreatedAt = params.socialCreatedAt ? new Date(params.socialCreatedAt) : undefined;
        const socialUpdatedAt = params.socialUpdatedAt ? new Date(params.socialUpdatedAt) : undefined;

        const data = { ...params, socialCreatedAt, socialUpdatedAt };

        return params.key === PlatformKey.PRIVATE
            ? new PrivateReview({
                  ...data,
                  comments: params.comments?.map((comment) => _mapToPrivateReviewReply(comment)),
                  semanticAnalysis: SemanticAnalysis.fromReviewAnalysisDto(params.semanticAnalysis, params.text),
              })
            : new Review({
                  ...data,
                  comments: params.comments?.map((comment) => _mapToReviewReply(comment)),
                  semanticAnalysis: SemanticAnalysis.fromReviewAnalysisDto(params.semanticAnalysis, params.text),
              });
    }

    function _mapToReviewReply(commentReviewDto: CommentReviewDto): ReviewReply {
        const socialUpdatedAt = commentReviewDto.socialUpdatedAt ? new Date(commentReviewDto.socialUpdatedAt) : undefined;
        return { ...commentReviewDto, socialUpdatedAt };
    }

    function _mapToPrivateReviewReply(commentReviewDto: CommentReviewDto): PrivateReviewReply {
        const socialUpdatedAt = commentReviewDto.socialUpdatedAt ? new Date(commentReviewDto.socialUpdatedAt) : undefined;
        return { ...commentReviewDto, socialUpdatedAt };
    }
}
