import { Routes } from '@angular/router';

import { MessageTemplatesComponent } from './message-templates.component';

export const MESSAGE_TEMPLATES_ROUTES: Routes = [
    {
        path: '',
        component: MessageTemplatesComponent,
    },
];
