import { z } from 'zod';

import { Locale, LocationType, NumberOfLocations } from '@malou-io/package-utils';

export const handleSubmitRestaurantInformationToHubSpotBodyValidator = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    phoneNumber: z.string(),
    companyName: z.string(),
    locationCount: z.nativeEnum(NumberOfLocations),
    locationType: z.nativeEnum(LocationType),
    zipCode: z.string(),
    malouDiagnosticId: z.string(),
    utm_source: z.string().optional(),
    utm_medium: z.string().optional(),
    utm_campaign: z.string().optional(),
    utm_content: z.string().optional(),
    language: z.nativeEnum(Locale),
    identity: z.string(),
});

export type HandleSubmitRestaurantInformationToHubSpotBodyValidator = z.infer<
    typeof handleSubmitRestaurantInformationToHubSpotBodyValidator
>;
