<div class="flex flex-col gap-y-4 px-8 py-4">
    <app-create-wheel-of-fortune-card
        [restaurantsIdsWithoutWheels]="restaurantsIdsWithoutWheels"
        [isAggregatedView]="true"
        [hasBoosterPackActivated]="hasBoosterPackActivated"
        (reloadWheelsOfFortune)="reloadWheelsOfFortune()"></app-create-wheel-of-fortune-card>

    <ng-container [ngTemplateOutlet]="isLoadingWheelsOfFortune() ? loadingTemplate : wheelsOfFortuneContentTemplate"></ng-container>
</div>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[100px] w-full p-6.5" [count]="2">
        <ng-template #nestedSkeletonTemplate>
            <div class="flex justify-between">
                <div class="flex gap-x-2">
                    <app-skeleton skeletonClass="h-[40px] !w-[180px]"></app-skeleton>
                    <app-skeleton skeletonClass="h-[40px] !w-[80px] !rounded-[20px]"></app-skeleton>
                </div>
                <div class="flex gap-x-2">
                    <app-skeleton skeletonClass="h-[40px] !w-[100px]"></app-skeleton>
                    <app-skeleton skeletonClass="h-[40px] !w-[50px]"></app-skeleton>
                    <app-skeleton skeletonClass="h-[40px] !w-[50px]"></app-skeleton>
                </div>
            </div>
        </ng-template>
    </app-skeleton>
</ng-template>

<ng-template #wheelsOfFortuneContentTemplate>
    <div class="mb-8 flex flex-col gap-y-4">
        @for (wheelOfFortune of wheelsOfFortune(); track trackByIdFn($index, wheelOfFortune); let index = $index) {
            <app-wheel-of-fortune-data-card
                [wheelOfFortune]="wheelOfFortune"
                [index]="index"
                (reloadWheelsOfFortune)="reloadWheelsOfFortune()"></app-wheel-of-fortune-data-card>
        }
    </div>
</ng-template>
