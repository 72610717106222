<div>
    @if (!isLoading()) {
        @if (areAllPlatformsInError()) {
            <div class="flex !h-[542px] flex-col items-center justify-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{
                    'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() }
                }}</span>
            </div>
        } @else {
            @if (!httpError()) {
                @if (!insightsError()) {
                    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
                        <div>
                            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
                        </div>
                    </div>
                } @else {
                    <div class="flex !h-[542px] flex-col items-center justify-center py-6">
                        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                        <span class="malou-text-10--regular">{{ insightsError() }}</span>
                    </div>
                }
            } @else {
                <div class="flex !h-[542px] flex-col items-center justify-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                    <span class="malou-text-10--regular">{{ httpError() | statisticsHttpError }}</span>
                </div>
            }
        }
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
</div>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-center gap-2">
            <span class="malou-text-section-title malou-color-text-1">{{
                'statistics.social_networks.engagement.engagement' | translate
            }}</span>
            @if (platformsErrorTooltip()) {
                <span
                    class="malou-status--waiting pdf-hidden"
                    [matTooltip]="'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() }">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </span>
            }
        </div>
        @if (!showViewByTextInsteadOfSelector) {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--medium md:hidden">{{ 'statistics.social_networks.engagement.view_by' | translate }}</span>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-engagement-chart
        [hiddenDatasetIndexes]="hiddenDatasetIndexes"
        [engagementData]="engagementData()"
        [labels]="dateLabels()"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-engagement-chart>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ totalImpressions().current | shortNumber }}
                </div>
                @if (totalImpressions().diff) {
                    <app-number-evolution
                        [value]="totalImpressions().diff ?? null"
                        [displayedValue]="totalImpressions().diff | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">
                {{ 'statistics.social_networks.kpis.impressions' | translate }}
                <mat-icon
                    class="pdf-hidden ml-1 !h-4 !w-4"
                    color="primary"
                    [svgIcon]="SvgIcon.INFO"
                    [matTooltip]="'statistics.social_networks.engagement.impressions_details' | translate">
                </mat-icon>
            </div>
        </div>

        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ totalEngagementRate().current | shortNumber: { content: '%', fixNumber: 1 } }}
                </div>
                @if (totalEngagementRate().diff) {
                    <app-number-evolution
                        [value]="totalEngagementRate().diff ?? null"
                        [displayedValue]="
                            totalEngagementRate().diff | shortNumber: { content: 'pts', fixNumber: 1, shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">
                {{ 'statistics.social_networks.kpis.engagement_rate' | translate }}
                <mat-icon
                    class="pdf-hidden ml-1 !h-4 !w-4"
                    color="primary"
                    [svgIcon]="SvgIcon.INFO"
                    [matTooltip]="'statistics.social_networks.engagement.engagement_rate_details' | translate">
                </mat-icon>
            </div>
        </div>
    </div>
</ng-template>
