@if (!screenSizeService.isPhoneScreen) {
    <div [class]="containerClass">
        <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            @for (tab of tabs; track tab) {
                <a mat-tab-link routerLinkActive="tab-active" [routerLink]="tab.link" [active]="rla.isActive" #rla="routerLinkActive">
                    <span> {{ tab.label }} </span>
                    @if (tab.withDot) {
                        <div class="ml-2 h-[9px] w-[9px] rounded-full bg-malou-color-primary"></div>
                    }
                    @if (tab.isNew) {
                        <div class="malou-text-9 ml-2 flex h-4.5 items-center gap-x-1 rounded bg-malou-color-purple--light px-1 text-white">
                            {{ 'common.new' | translate }}
                        </div>
                    }
                </a>
            }
        </nav>
    </div>
} @else {
    <div [class]="containerClass">
        <nav class="mobile-navbar hide-scrollbar relative flex max-w-[100vw] gap-x-8 overflow-x-auto">
            @for (tab of tabs; track tab) {
                <a routerLinkActive="tab-active" [routerLink]="tab.link" (click)="onNavigate($event)" #rla="routerLinkActive">
                    <span> {{ tab.label }} </span>
                </a>
            }
            <div class="ink-bar absolute bottom-0 left-0 z-10"></div>
        </nav>
    </div>
}

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
