import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatRowDef, MatTable } from '@angular/material/table';

import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[matRowDef]',
    providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDefDirective }],
    standalone: true,
})
export class TypeSafeMatRowDefDirective<T> extends MatRowDef<T> {
    @Input() matRowDefTable?: MatTable<T>;

    static ngTemplateContextGuard<T>(dir: TypeSafeMatRowDefDirective<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
        return true;
    }
}
