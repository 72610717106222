@if (hasBoosterPackActivated()) {
    <section>
        <div class="relative flex justify-between" [ngClass]="{ 'flex-col': isPhoneScreen() }">
            <div class="grow" [ngClass]="{ 'max-w-[65%]': !isPhoneScreen() }">
                <div class="malou-text-18--bold malou-color-text-1 flex items-center">
                    {{ 'wheel_of_fortune.create_wheel_of_fortune' | translate }}
                </div>
                <div class="malou-text-13 mt-3 italic">
                    {{ 'wheel_of_fortune.create_wheel_card.description' | translate }}
                </div>
            </div>
            <div
                class="h-fit"
                disableIfMissingCaslRole
                [neededCaslRoles]="WHEEL_OF_FORTUNE_NEEDED_ROLES"
                [restaurantIdsToCheckOnRole]="localBusinessRestaurantIdsWithoutWheel()"
                [matTooltip]="createWheelOfFortuneTooltip()(disabledDirective.isDisabled)"
                #disabledDirective="isDisabledByCasl">
                @if (hasBoosterPackActivated()) {
                    <button
                        class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit"
                        id="tracking_wof_create_wheel_button"
                        data-testid="create-wheel-btn"
                        mat-raised-button
                        [ngClass]="{ 'mt-8': isPhoneScreen() }"
                        [disabled]="!canCreateWheelOfFortune()(disabledDirective.isDisabled)"
                        (click)="openWheelOfFortuneModal(false)">
                        {{
                            isAggregatedView
                                ? ('wheel_of_fortune.create_wheel_card.create_aggregated' | translate)
                                : ('wheel_of_fortune.create_wheel_card.create_single' | translate)
                        }}
                    </button>
                }
            </div>
        </div>
    </section>
    <section class="mt-4">
        <p class="malou-text-13--bold malou-color-text-1 flex items-center">
            {{ 'wheel_of_fortune.create_wheel_card.how_does_it_work' | translate }}
        </p>
        <app-how-wheel-of-fortune-works></app-how-wheel-of-fortune-works>
    </section>
} @else {
    <div class="flex h-full flex-col">
        <div class="malou-color-text-2 malou-text-12--regular !mx-0 mt-3 flex flex-col gap-y-3 !overflow-y-hidden">
            <div
                class="malou-text-16"
                [innerHTML]="'wheel_of_fortune.subscription_request_modal.feature_part_of_booster_pack' | translate"></div>

            <div class="flex">
                <div class="flex-2">
                    <img [src]="'boosterPack' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
                </div>
                <div class="flex flex-2 flex-col justify-between p-4">
                    <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.gain_reviews_and_subscribers' | translate"></div>
                    <ul class="flex flex-col gap-y-1">
                        <li class="ml-5 flex items-center gap-x-2">
                            <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                            <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.nfc_items' | translate"></div>
                        </li>
                        <li class="ml-5 flex items-center gap-x-2">
                            <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                            <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.stickers' | translate"></div>
                        </li>
                        <li class="ml-5 flex items-center gap-x-2">
                            <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                            <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.wheel_of_fortune' | translate"></div>
                        </li>
                        <li class="ml-5 flex items-center gap-x-2">
                            <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                            <div [innerHTML]="'wheel_of_fortune.subscription_request_modal.personalized_advices' | translate"></div>
                        </li>
                    </ul>

                    <div class="flex items-center rounded-xl bg-malou-color-background-dark p-4">
                        <div class="ml-4 mr-6 text-2xl">🚀</div>
                        <div
                            class="malou-color-text-1"
                            [innerHTML]="'wheel_of_fortune.subscription_request_modal.get_3_to_6_times_more_reviews' | translate"></div>
                    </div>
                    <div
                        class="w-full"
                        [matTooltip]="requestSent() ? ('wheel_of_fortune.subscription_request_modal.request_sent' | translate) : ''">
                        <button
                            class="malou-btn-raised--primary !h-11 w-full md:grow"
                            id="tracking_wof_get_more_informations"
                            mat-raised-button
                            [disabled]="requestSent() || sendingRequest()"
                            (click)="sendRequest()">
                            {{ 'wheel_of_fortune.subscription_request_modal.contact_me' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
