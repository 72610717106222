import { Routes } from '@angular/router';

import { AggregatedStatisticsPdfComponent } from './aggregated-statistics-pdf.component';
import { BoostersPdfComponent } from './boosters-pdf/boosters-pdf.component';
import { EReputationPdfComponent } from './e-reputation-pdf/e-reputation-pdf.component';
import { SeoPdfComponent } from './seo-pdf/seo-pdf.component';
import { SocialNetworksPdfComponent } from './social-networks-pdf/social-networks-pdf.component';

export const AGGREGATED_STATISTICS_PDF_ROUTES: Routes = [
    {
        path: '',
        component: AggregatedStatisticsPdfComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'seo',
            },
            {
                path: 'seo',
                component: SeoPdfComponent,
            },
            {
                path: 'e-reputation',
                component: EReputationPdfComponent,
            },
            {
                path: 'social-networks',
                component: SocialNetworksPdfComponent,
            },
            {
                path: 'boosters',
                component: BoostersPdfComponent,
            },
        ],
    },
];
