import { Routes } from '@angular/router';

import { DownloadMediasAndFoldersComponent } from './download-medias-and-folders.component';

export const DOWNLOAD_ROUTES: Routes = [
    {
        path: '',
        component: DownloadMediasAndFoldersComponent,
    },
];
