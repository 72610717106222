import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-yelp-credentials',
    templateUrl: './yelp-credentials.component.html',
    styleUrls: ['./yelp-credentials.component.scss'],
    standalone: true,
})
export class YelpCredentialsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
