import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, map, skip, Subject, switchMap, takeUntil } from 'rxjs';

import { generateDbId, HashtagType, isNotNil, mapLanguageStringToApplicationLanguage } from '@malou-io/package-utils';

import { HashtagsService } from ':core/services/hashtags.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { Hashtag, Keyword } from ':shared/models';

export enum ChooseHashtagsPanelDisplayState {
    OPEN = 'open',
    CLOSED = 'closed',
}

@Injectable({
    providedIn: 'any',
})
export class UpsertSocialPostHashtagsContext {
    readonly ChooseHashtagsPanelDisplayState = ChooseHashtagsPanelDisplayState;
    readonly chooseHashtagsPanelDisplayState = signal(ChooseHashtagsPanelDisplayState.CLOSED);

    readonly bookmarkedHashtags = signal<Hashtag[]>([]);
    private readonly _selectedHashtags = signal<Hashtag[]>([]);
    private readonly _generatedHashtags = signal<Hashtag[]>([]);
    readonly isGeneratingHashtags = signal(false);
    readonly mainHashtag = signal<Hashtag | null>(null);

    readonly currentLang = mapLanguageStringToApplicationLanguage(LocalStorage.getLang());

    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _hashtagsService = inject(HashtagsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _restaurantKeywords$ = inject(KeywordsService)
        .getKeywordsByRestaurantId(this._restaurantsService.currentRestaurant._id)
        .pipe(map((res) => res.data.map((k) => new Keyword(k))));

    private readonly _resetSubscriptions$ = new Subject<void>();

    init(): void {
        this._resetSubscriptions();
        this._onPostHashtagsChanges();
    }

    openPostHashtagsPanel(): void {
        this.chooseHashtagsPanelDisplayState.set(ChooseHashtagsPanelDisplayState.OPEN);
    }

    closePostHashtagsPanel(): void {
        this.chooseHashtagsPanelDisplayState.set(ChooseHashtagsPanelDisplayState.CLOSED);
    }

    getRestaurantHashtags(): void {
        this._hashtagsService.getHashtagsForRestaurant(this._restaurantsService.currentRestaurant._id).subscribe({
            next: (hashtags: Hashtag[]) => {
                let mainHashtag = hashtags.find((hashtag) => hashtag?.isMain);
                if (!mainHashtag) {
                    mainHashtag = new Hashtag({
                        text: this._restaurantsService.currentRestaurant.getNameAsHashtag(),
                        isMain: true,
                        id: generateDbId().toHexString(),
                        isCustomerInput: false,
                        type: HashtagType.RESTAURANT,
                    });

                    this._hashtagsService
                        .saveHashtag({
                            ...mainHashtag,
                            restaurantId: this._restaurantsService.currentRestaurant._id,
                        })
                        .subscribe({
                            error: (err) => console.error('err >', err),
                        });
                }
                this.mainHashtag.set(mainHashtag);

                this._generatedHashtags.update((prev) => [mainHashtag, ...prev].filter((hashtag) => hashtag.text.length));

                this.bookmarkedHashtags.set([mainHashtag, ...hashtags.filter((hashtag) => hashtag.isMain === false)]);
            },
            error: (err) => console.error('err >', err),
        });
    }

    getSuggestedHashtags(): void {
        this.isGeneratingHashtags.set(true);
        this._restaurantKeywords$
            .pipe(
                switchMap((keywords) =>
                    this._hashtagsService.suggestHashtags(
                        {
                            favoriteHashtags: this.bookmarkedHashtags().map((hashtag) => hashtag.text),
                            keywords: keywords.flatMap((keyword) => keyword.bricks.map((brick) => brick.text)),
                            postDescription: this._upsertSocialPostContext.upsertSocialPostState.post.text() ?? '',
                            restaurantCategory: [
                                this._restaurantsService.currentRestaurant.category?.categoryName?.[this.currentLang] ||
                                    this._restaurantsService.currentRestaurant.category?.categoryName?.backup ||
                                    '',
                            ],
                            restaurantLocality: this._restaurantsService.currentRestaurant.address?.locality ?? '',
                            restaurantName: this._restaurantsService.currentRestaurant.name,
                            restaurantPostalCode: this._restaurantsService.currentRestaurant.address?.postalCode ?? '',
                        },
                        this._restaurantsService.currentRestaurant._id
                    )
                )
            )
            .subscribe({
                next: (res: { hashtags: Hashtag[]; preSelectedHashtags: Hashtag[] }) => {
                    this._generatedHashtags.set([this.mainHashtag(), ...res.hashtags].filter(isNotNil));
                    this._selectedHashtags.set([this.mainHashtag(), ...res.preSelectedHashtags].filter(isNotNil));
                    this.isGeneratingHashtags.set(false);
                },
                error: (err) => {
                    this.isGeneratingHashtags.set(false);
                    console.error('err >', err);
                },
            });
    }

    updateBookmarkedHashtags(hashtags: Hashtag[]): void {
        this.bookmarkedHashtags.set(hashtags);
    }

    private _onPostHashtagsChanges(): void {
        toObservable(this._selectedHashtags)
            .pipe(skip(1), debounceTime(100), takeUntil(this._resetSubscriptions$))
            .subscribe({
                next: (hashtags) => {
                    this._upsertSocialPostContext.updateSelectedHashtags(hashtags);
                },
            });

        toObservable(this._generatedHashtags)
            .pipe(skip(1), debounceTime(100), takeUntil(this._resetSubscriptions$))
            .subscribe({
                next: (hashtags) => {
                    this._upsertSocialPostContext.updateSuggestedHashtags(hashtags);
                },
            });
    }

    private _resetSubscriptions(): void {
        this._resetSubscriptions$.next();
    }
}
