@if (hoursWithDifference.length) {
    <div>
        @for (hourWithDifference of hoursWithDifference; track hourWithDifference) {
            <div class="my-1 flex">
                <div
                    class="malou-text-12--medium malou-color-text-2 !min-w-[80px]"
                    [ngClass]="{ 'has-difference-title': hoursHaveDifference | applyPure: hourWithDifference.hours }">
                    {{ hourWithDifference.day | enumTranslate: 'days' | titlecase }}
                </div>
                <div class="flex sm:flex-wrap md:!flex-row xl:flex-col">
                    @for (hour of hourWithDifference.hours; track hour; let index = $index) {
                        <span
                            class="malou-text-12--regular malou-color-text-2 mr-1.5 !min-w-[80px]"
                            [ngClass]="{ 'has-difference': hour?.hasDifference }">
                            {{ hour.value | displayHour }}
                        </span>
                    }
                    @if (!hourWithDifference.hours.length) {
                        <span class="malou-color-state-error"> - </span>
                    }
                </div>
            </div>
        }
    </div>
}
