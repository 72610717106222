export class Menu {
    _id: string;
    socialId: string;
    sections: Section[] = [];
    url: string;
    updatedAt: Date;
    createdAt: Date;

    public constructor(init?: Partial<Menu>) {
        Object.assign(this, init);
        this.sections = this.sections.map((s) => new Section(s));
    }

    isValid(): boolean {
        return this.sections.map((s) => s.isValid()).filter((e) => e === false).length === 0;
    }
}

export class Section {
    _id: string;
    label: string;
    socialId: string;
    items: Item[] = [];

    public constructor(init?: Partial<Section>) {
        Object.assign(this, init);
        this.items = this.items.map((s) => new Item(s));
    }

    isValid(): boolean {
        return this.label != null && this.label !== '' && this.items.map((i) => i.isValid()).filter((e) => e === false).length === 0;
    }
}

export class Item {
    _id: string;
    label: string;
    description: string;
    socialId: string;
    price: Price = new Price();

    public constructor(init: Partial<Item> = {}) {
        Object.assign(this, init);
        this.price = new Price(init.price);
    }

    isValid(): boolean {
        return this.label != null && this.label !== '' && this.price.isValid();
    }
}

export class Price {
    currencyCode: string;
    units: number;

    public constructor(init?: Partial<Price>) {
        Object.assign(this, init);
    }

    toString(): string {
        return this.units + this.currencyCode;
    }

    isValid(): boolean {
        return this.currencyCode != null && this.currencyCode !== '' && this.units != null;
    }

    isEqual(o: any): boolean {
        return o instanceof Price && this.currencyCode === o.currencyCode && this.units === o.units;
    }
}
