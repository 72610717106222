import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

import { GmbActionsComponent } from '../../aggregated-statistics/seo/gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from '../../aggregated-statistics/seo/gmb-impressions/gmb-impressions.component';
import { KeywordsV3Component } from '../../aggregated-statistics/seo/keywords/keywords-v3.component';

@Component({
    selector: 'app-seo-pdf',
    standalone: true,
    imports: [
        KeywordsV3Component,
        GmbActionsComponent,
        GmbImpressionsComponent,
        AsyncPipe,
        TranslateModule,
        FromToDateFormatterPipe,
        IncludesPipe,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './seo-pdf.component.html',
    styleUrls: ['./seo-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoPdfComponent {
    readonly startDate: Date;
    readonly endDate: Date;
    readonly displayedCharts: InsightsChart[];
    readonly chartOptions: ChartOptions;
    readonly InsightsChart = InsightsChart;
    readonly selectedRestaurantsTitle$: Observable<string>;

    keywordsHasData = true;
    gmbImpressionsHasData = true;
    gmbActionsHasData = true;
    SortBy = ChartSortBy;

    constructor(
        private readonly _aggregateStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { startDate, endDate, displayedCharts, chartOptions } = parsedQueryParams;

        this.displayedCharts = displayedCharts;
        this.chartOptions = chartOptions ?? {};
        this.startDate = startDate;
        this.endDate = endDate;

        this.selectedRestaurantsTitle$ = this._aggregateStatisticsFiltersContext.selectedRestaurants$.pipe(
            map((restaurants) => restaurants.map((restaurant) => restaurant.internalName ?? restaurant.name).join(', '))
        );
    }
}
