<div class="mat-gradient flex h-full w-full">
    <div class="malou-color-bg-white flex w-3/5 flex-col rounded-r-[50px] md:w-full md:rounded-none">
        <div class="malou-sticky-logo">
            <img alt="malou logo" [src]="'malou_logo' | imagePathResolver" />
        </div>
        <div class="hide-scrollbar grid h-full place-items-center overflow-y-auto overflow-x-hidden sm:mt-3 md:px-5">
            <div class="w-8/12 md:w-full">
                <div class="icons mb-3">
                    <img alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
                </div>
                <h1 class="md:!malou-text-35--semibold malou-text-heading malou-color-text-1">
                    {{ 'login.forgot_password_confirm.mail_send' | translate }}
                </h1>
                <span class="mb-9 flex items-center">
                    <h2 class="md:!malou-text-13--regular malou-text-15--regular">
                        {{ 'login.forgot_password_confirm.to_mail' | translate: { email } }}
                    </h2>
                    <button class="malou-btn-flat malou-text-10--semibold" mat-button (click)="goToForgotPassword()">
                        {{ 'common.edit' | translate }}
                    </button>
                </span>
                <button class="malou-btn-raised--primary !mb-6 !h-11 w-full" mat-raised-button (click)="goHome()">
                    {{ 'login.forgot_password.back_to_home' | translate }}
                </button>
                <div class="malou-text-10 mb-2 italic">* {{ 'login.forgot_password.if_has_account' | translate }}</div>
            </div>
        </div>
    </div>
    <div class="w-2/5 items-center justify-center md:hidden md:w-0">
        <div class="flex h-full flex-col items-center justify-around">
            <p class="malou-text-title md:malou-text-section-title malou-color-white mt-4 px-14 text-center">
                {{ 'login.digital_for_growth' | translate }}
            </p>
            <img class="px-2" alt="Screen illustration" [src]="'Screen' | illustrationPathResolver" />
            <div class="malou-color-white malou-text-20--regular mb-4">
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.time' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.visibility' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.clients' | translate }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>
