export enum ChecklistType {
    OLYMPICS = 'olympics',
}
export enum ChecklistTaskName {
    FOREIGN_LANGUAGE_KEYWORDS = 'foreign_language_keywords',
    CREATE_ENGLISH_POSTS = 'create_english_posts',
    ADAPT_CONTENT_TO_OLYMPICS = 'adapt_content_to_olympics',
    CONFIRM_YOUR_HOURS = 'confirm_your_hours',
    COLLECT_REVIEWS_WITH_BOOSTER_PACK = 'collect_reviews_with_booster_pack',
    BROADCAST_SPORT_ATTRIBUTE = 'broadcast_sport_attribute',
}
