import { Signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';

export const runOptimistic = <ObservableResult = any, SignalType = any>({
    state,
    observable,
    optimisticUpdate,
    onError,
}: {
    state: WritableSignal<SignalType> | Signal<SignalType>;
    observable: Observable<ObservableResult>;
    optimisticUpdate: Function;
    onError?: (error: any, previousState: WritableSignal<SignalType> | Signal<SignalType>) => void;
}): void => {
    const prevState = state;
    optimisticUpdate();
    observable.subscribe({
        error: (err) => {
            console.error(err);
            if (onError) {
                onError(err, prevState);
                if (isWritableSignal(state)) {
                    state.set(prevState());
                }
            }
        },
    });
};

function isWritableSignal<T>(signal: Signal<T> | WritableSignal<T>): signal is WritableSignal<T> {
    return (signal as WritableSignal<T>).set !== undefined;
}
