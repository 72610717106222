import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { PostDatePickerSize } from ':modules/posts-v2/post-date-picker/post-date-picker.interface';
import { MenuButtonV2Component } from ':shared/components/menu-button-v2/menu-button-v2.component';
import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';

@Component({
    selector: 'app-upsert-social-post-modal-footer',
    templateUrl: './upsert-social-post-modal-footer.component.html',
    styleUrls: ['./upsert-social-post-modal-footer.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, TranslateModule, MenuButtonV2Component, PostDatePickerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalFooterComponent {
    // TODO maybe not an input but directly from the context?
    readonly selectedDate = input<Date | null>(new Date());
    readonly cancel = output<void>();

    readonly MenuButtonSize = MenuButtonSize;
    readonly PostDatePickerSize = PostDatePickerSize;

    onCancel(): void {
        this.cancel.emit();
    }
}
