import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AI_HARD_LIMIT_CALL_COUNT } from '@malou-io/package-utils';

import * as PermissionsActions from ':core/credentials/store/permissions.actions';
import { AiService } from ':core/services/ai.service';
import { ChatbotService } from ':core/services/chatbot.service';
import { HeapService } from ':core/services/heap.service';
import * as UserFiltersActions from ':core/user-filters/store/user-filters.actions';
import * as GalleryActions from ':modules/gallery/gallery.actions';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { ReviewsDisplayMode } from ':modules/reviews/store/reviews.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { Restaurant } from ':shared/models';

import { RestaurantsService } from '../services/restaurants.service';

@Injectable()
export class RestaurantResolver {
    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _heapService: HeapService,
        private readonly _store: Store,
        private readonly _aiService: AiService,
        private readonly _chatbotService: ChatbotService
    ) {}

    resolve(route: ActivatedRouteSnapshot): any {
        this._restaurantsService.restaurantLoading$.next(true);
        const restaurantId = route.params.restaurant_id;
        this._store.dispatch({ type: PermissionsActions.loadPermissions.type, restaurantId });
        this._store.dispatch({ type: GalleryActions.resetGalleryFilters.type });
        this._store.dispatch(ReviewsActions.editReviewsFiltersCurrentView({ currentView: ReviewsDisplayMode.SINGLE_RESTAURANT }));

        return combineLatest([this._restaurantsService.show(restaurantId), this._store.select(selectUserInfos)]).pipe(
            take(1),
            map(([restaurantResponse, currentUser]) => {
                const restaurant: Restaurant = restaurantResponse.data;
                this._store.dispatch({ type: UserFiltersActions.initializeRestaurantFilters.type, userId: currentUser?._id, restaurantId });
                this._aiService.setAiRemainingCredits(AI_HARD_LIMIT_CALL_COUNT - (restaurant.ai?.monthlyCallCount || 0));
                const { name, address } = restaurant;
                this._heapService.addEventProperties({
                    restaurant: name + '__' + address?.formattedAddress,
                    userId: currentUser?._id,
                    userName: currentUser?.name,
                    restaurantId: restaurant._id,
                    userRestaurantsCount: currentUser?.restaurants.length,
                    organisations: currentUser?.organizations.map((org) => org.name).join(', '),
                    role: currentUser?.role,
                    userCaslRole: currentUser?.caslRole,
                    userEmail: currentUser?.email,
                    restaurantOrganisation: restaurant.organization?.name,
                    mobileAppSession: false,
                    allBusinesses: false,
                });
                this._restaurantsService.restaurantLoading$.next(false);
                this._restaurantsService.setSelectedRestaurant(restaurant);
                this._chatbotService.initChatbot();
                return restaurant;
            }),
            catchError(() => {
                this._restaurantsService.restaurantLoading$.next(true);
                return of(null);
            })
        );
    }
}
