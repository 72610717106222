<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div>{{ 'admin.users.change_password' | translate }}</div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-y-6">
            <app-input-text
                [formControl]="passwordFormControl"
                [title]="'admin.users.new_password' | translate"
                [placeholder]="'admin.users.new_password' | translate"
                [required]="true">
            </app-input-text>
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button
            class="malou-btn-raised--primary !h-10 md:grow"
            mat-raised-button
            [disabled]="passwordFormControl.invalid"
            (click)="submit()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
