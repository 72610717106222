/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, computed, DestroyRef, Input, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { interval, startWith } from 'rxjs';

import { isNotNil, TimeInMilliseconds } from '@malou-io/package-utils';

import { MAX_PROGRESS } from ':shared/components/loader-page/loader-page.component';
import { LoaderProgressComponent } from ':shared/components/loader-progress/loader-progress.component';
import { Restaurant } from ':shared/models/restaurant';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import { KeywordsGenerationStateWithRestaurant } from '../keywords-generating-footer-popin.component';

@Component({
    selector: 'app-keywords-generating-footer-popin-item',
    templateUrl: './keywords-generating-footer-popin-item.component.html',
    styleUrls: ['./keywords-generating-footer-popin-item.component.scss'],
    standalone: true,
    imports: [LoaderProgressComponent, ApplySelfPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsGeneratingFooterPopinItemComponent {
    @Input() set keywordsGenerationStateWithRestaurant(value: KeywordsGenerationStateWithRestaurant) {
        this.generationStartDate.set(value.generationStartDate);
        this.restaurant.set(value.restaurant);
        this.generationEstimatedTime.set(value.generationEstimatedTime);

        const message = value.wasLastResultSeen ? this._translateService.instant('common.seen') : undefined;
        this.generationCompletedText.set(message);
    }

    readonly restaurant = signal<Restaurant | null>(null);
    readonly generationStartDate = signal<Date | null>(null);
    readonly generationEstimatedTime = signal<number>(Number.MAX_SAFE_INTEGER);

    readonly generationProgress = computed(() => {
        const generationStartDate = this.generationStartDate();
        if (!generationStartDate) {
            return 100;
        }
        if (isNotNil(this._interval())) {
            const now = new Date();
            const progressTime = now.getTime() - generationStartDate.getTime();
            const progressPercentage = Math.round((progressTime / this.generationEstimatedTime()) * 100);
            return Math.min(progressPercentage, MAX_PROGRESS);
        }
        return 0;
    });

    readonly redirectToKeywordsView = (): void => {
        const restaurant = this.restaurant();
        if (restaurant) {
            this._router.navigate(['restaurants', restaurant._id, 'resources', 'keywords', 'list']);
        }
    };

    readonly generationCompletedText = signal(undefined);
    readonly generationCompletedAction = signal({
        message: this._translateService.instant('common.see'),
        action: this.redirectToKeywordsView,
    });

    private readonly _interval$ = interval(1 * TimeInMilliseconds.SECOND).pipe(startWith(0), takeUntilDestroyed(this._destroyRef));
    private readonly _interval = toSignal(this._interval$);

    constructor(
        private readonly _destroyRef: DestroyRef,
        private readonly _translateService: TranslateService,
        private readonly _router: Router
    ) {}
}
