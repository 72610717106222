import { z } from 'zod';

import { ReportType } from '@malou-io/package-utils';

export const updateReportValidator = z.object({
    id: z.string().optional(),
    active: z.boolean(),
    configurations: z.array(
        z.object({
            id: z.string(),
            recipients: z.array(z.string()),
            restaurantsIds: z.array(z.string()),
        })
    ),
    type: z.nativeEnum(ReportType),
    userId: z.string(),
});

export type UpdateReportDto = z.infer<typeof updateReportValidator>;

export const unsubscribeFromReportParamsValidator = z
    .object({
        report_id: z.string(),
    })
    .transform((value) => ({ reportId: value.report_id }));

export type UnsubscribeFromReportParamsDto = z.infer<typeof unsubscribeFromReportParamsValidator>;

export const unsubscribeFromReportBodyValidator = z.object({
    email: z.string().email(),
    hash: z.string(),
    index: z.number().gte(0),
});
export type UnsubscribeFromReportBodyDto = z.infer<typeof unsubscribeFromReportBodyValidator>;

export const unsubscribeFromReportV2QueryValidator = z.object({
    hash: z.string(),
});
export type UnsubscribeFromReportV2QueryDto = z.infer<typeof unsubscribeFromReportV2QueryValidator>;

export const testReportConfigurationBodyValidator = z.object({
    reportId: z.string(),
    configurationId: z.string(),
    reportType: z.nativeEnum(ReportType),
});

export type TestReportConfigurationBodyDto = z.infer<typeof testReportConfigurationBodyValidator>;

export const reportEmailOpenedQueryValidator = z.object({
    receiverEmail: z.string(),
    reportId: z.string(),
    reportType: z.nativeEnum(ReportType),
});

export type ReportEmailOpenedQueryDto = z.infer<typeof reportEmailOpenedQueryValidator>;

export const fillUserConfigurationsBodyValidator = z.object({
    restaurantsIds: z.array(z.string()),
});

export type FillUserConfigurationsBodyDto = z.infer<typeof fillUserConfigurationsBodyValidator>;
