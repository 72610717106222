import { Routes } from '@angular/router';

import { GeneralInformationComponent } from './general-information.component';

export const INFORMATIONS_ROUTES: Routes = [
    {
        path: '',
        component: GeneralInformationComponent,
    },
];
