export interface ImageEdition {
    labels?: ImageLabel[];
    backgroundColor?: string;
    isNew: boolean;
}

const DEFAULT_BACKGROUND_COLOR = '#000000';

export const DEFAULT_IMAGE_EDITION: Required<ImageEdition> = {
    labels: [],
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    isNew: false,
};

export interface ImageLabel {
    text: string;
    style: string;
    top: number;
    left: number;
    malouTagId: string;
}
