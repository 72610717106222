import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { FacebookInstagramConnectionStep0Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-0/facebook-instagram-connection-step-0.component';
import { FacebookInstagramConnectionStep1Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-1/facebook-instagram-connection-step-1.component';
import { FacebookInstagramConnectionStep2Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-2/facebook-instagram-connection-step-2.component';
import { FacebookInstagramConnectionStep3Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-3/facebook-instagram-connection-step-3.component';
import { FacebookInstagramConnectionStep4Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-4/facebook-instagram-connection-step-4.component';
import { FacebookInstagramConnectionStep5Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-step-5/facebook-instagram-connection-step-5.component';
import {
    PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
    PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
    ParentStepperComponentDialogData,
    PlatformsConnectionParentStepperComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-parent-stepper.component';
import { createStep } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/step.interface';
import { GetStepIndexFromCredentialService } from ':modules/platforms/platforms-connection-modals/shared/services/get-step-index-from-credential.service';
import { SelectCredentialAndBusinessStepComponent } from ':modules/platforms/platforms-connection-modals/shared/steps/select-credential-and-business-step/select-credential-and-business-step.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export type FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE = PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM;
const NOT_CONNECTED_STEP_INDEX = 0;
const CONNECTED_STEP_INDEX = 6;

export interface FacebookInstagramConnectionModalResult {
    hasDataChanged?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FacebookInstagramConnectionModalService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _getStepIndexFromCredentialService = inject(GetStepIndexFromCredentialService);

    open(
        platformKey: FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE,
        selectedAuthId?: string
    ): Observable<FacebookInstagramConnectionModalResult | undefined> {
        return this._getStepIndexFromCredentialService.execute(PlatformKey.FACEBOOK, NOT_CONNECTED_STEP_INDEX, CONNECTED_STEP_INDEX).pipe(
            switchMap((stepIndex) =>
                this._customDialogService
                    .open<
                        PlatformsConnectionParentStepperComponent,
                        ParentStepperComponentDialogData<FACEBOOK_INSTAGRAM_SHARED_DATA_TYPE, FacebookInstagramConnectionModalResult>,
                        FacebookInstagramConnectionModalResult
                    >(PlatformsConnectionParentStepperComponent, {
                        height: PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
                        width: PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
                        data: {
                            sharedData: platformKey,
                            steps: [
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_0.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep0Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_1.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep1Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_2.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep2Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_3.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep3Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_4.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep4Component,
                                    componentInputs: {},
                                },
                                {
                                    stepName: this._translateService.instant(
                                        'platforms.connection_new.facebook_instagram.step_5.step_name'
                                    ),
                                    component: FacebookInstagramConnectionStep5Component,
                                    componentInputs: {},
                                },
                                createStep(
                                    this._translateService.instant(
                                        `platforms.connection_new.facebook_instagram.step_6.${platformKey}.step_name`
                                    ),
                                    SelectCredentialAndBusinessStepComponent,
                                    {
                                        titleTranslationKey: this._translateService.instant(
                                            `platforms.connection_new.facebook_instagram.step_6.${platformKey}.step_name`
                                        ),
                                        goToStepParam: { type: 'history', value: 1 },
                                        platformKey: platformKey,
                                        showNewOauthButton: true,
                                        selectedAuthId: selectedAuthId,
                                        showPlatformIconInModalTitle: true,
                                    }
                                ),
                            ],
                            stepperTitle: this._translateService.instant('platforms.connection_new.facebook_instagram.stepper.title'),
                            stepIndex: stepIndex,
                        },
                    })
                    .afterClosed()
            )
        );
    }
}
