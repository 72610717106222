<ng-container [ngTemplateOutlet]="isInitialized() ? mainTemplate : loadingTemplate"></ng-container>

<ng-template #mainTemplate>
    <ng-container [ngTemplateOutlet]="isPreview() || isPhoneScreen() ? phoneTemplate : desktopTemplate"></ng-container>
</ng-template>

<ng-template #phoneTemplate>
    <div
        class="hide-scrollbar relative h-screen overflow-x-hidden bg-malou-color-background-light px-3 pt-5"
        [ngClass]="{
            '!h-full !rounded-b-3xl': isPreview(),
            'min-h-[750px] w-screen': !isPreview() && selectedRestaurantId(),
            '!overflow-hidden': currentPlayWheelStep() === PlayWheelStep.WHEEL_TURNING,
        }">
        <div
            class="cover bg-center-top flex h-full w-full flex-col justify-between bg-contain bg-no-repeat"
            [ngStyle]="{
                'background-image': 'url(' + ('stars' | imagePathResolver: { folder: 'wheels-of-fortune' }) + ')',
            }">
            @if (selectedRestaurantId() || isPreview()) {
                <div class="flex w-full flex-col items-center justify-between gap-y-4" [ngClass]="{ 'h-[28%]': !isPreview() }">
                    @if (displayedWheelOfFortune()?.parameters?.media || selectedRestaurant()?.logo) {
                        <img
                            class="max-w-[130px]"
                            [ngClass]="{ 'mt-10 max-h-[65px]': !isPreview(), 'max-h-[45px]': isPreview() }"
                            [src]="
                                (displayedWheelOfFortune() ? (displayedWheelOfFortune() | applySelfPure: 'getLogoUrl') : null) ||
                                (selectedRestaurant() ? (selectedRestaurant() | applySelfPure: 'getLogoUrl') : null)
                            " />
                    } @else {
                        @if (selectedRestaurant()) {
                            <span class="malou-text-22--bold malou-color-text-1 mt-4 w-[65%] p-2.5 text-center">{{
                                selectedRestaurant()?.name
                            }}</span>
                        } @else {
                            <div [ngClass]="isPreview() ? 'h-[40px]' : 'h-[65px]'"></div>
                        }
                    }
                    @switch (currentPlayWheelStep()) {
                        @case (PlayWheelStep.LEAVE_REVIEW) {
                            <app-play-wheel-of-fortune
                                ctaId="tracking_wof_play_cta"
                                [wheelOfFortune]="displayedWheelOfFortune"
                                [regularTitle]="'play_wheel_of_fortune.turn_wheel_and' | translate"
                                [highlightedTitle]="'play_wheel_of_fortune.discover_gift' | translate"
                                [isCtaLoading]="isCtaLoading"
                                [ctaText]="'play_wheel_of_fortune.play' | translate"
                                (onCtaClick)="openLeaveReviewModal()"></app-play-wheel-of-fortune>
                        }
                        @case (PlayWheelStep.START_WHEEL) {
                            <app-play-wheel-of-fortune
                                ctaId="tracking_wof_spin_the_wheel_cta"
                                [wheelOfFortune]="displayedWheelOfFortune"
                                [regularTitle]="'play_wheel_of_fortune.turn_wheel_and' | translate"
                                [highlightedTitle]="'play_wheel_of_fortune.discover_gift' | translate"
                                [isPreview]="isPreview"
                                [ctaText]="'play_wheel_of_fortune.start_wheel' | translate"
                                (onCtaClick)="currentPlayWheelStep.set(PlayWheelStep.WHEEL_TURNING)"></app-play-wheel-of-fortune>
                        }
                        @case (PlayWheelStep.WHEEL_TURNING) {
                            <app-play-wheel-of-fortune
                                [wheelOfFortune]="displayedWheelOfFortune"
                                [highlightedTitle]="'play_wheel_of_fortune.wait' | translate"
                                [isWheelTurning]="true"
                                (onCtaClick)="spinWheel()"></app-play-wheel-of-fortune>
                        }
                    }
                </div>
            } @else {
                <div class="flex h-[72%] w-full flex-col items-center justify-between gap-y-4">
                    <img
                        class="mt-10 max-h-[65px] max-w-[130px]"
                        [src]="displayedWheelOfFortune() ? (displayedWheelOfFortune() | applySelfPure: 'getLogoUrl') : null" />
                    <ng-container [ngTemplateOutlet]="imageWithBackground" [ngTemplateOutletContext]="{ img: 'house' }"></ng-container>
                    <div class="malou-color-text-1 text-center">
                        <p class="malou-text-22--bold">
                            <span>{{ 'play_wheel_of_fortune.select_restaurant.before_playing' | translate }}</span>
                            <br />
                            <span [ngStyle]="{ color: displayedWheelOfFortune()?.parameters?.primaryColor }">{{
                                'play_wheel_of_fortune.select_restaurant.select_restaurant' | translate
                            }}</span>
                        </p>
                        <p class="malou-text-15--regular malou-color-text-2 mt-2 italic">
                            {{ 'play_wheel_of_fortune.select_restaurant.know_where_to_pick_up' | translate }}
                        </p>
                    </div>
                    <div
                        class="m-4 flex min-h-[50px] w-[90%] cursor-pointer items-center justify-between rounded border-[1px] border-solid bg-white p-4"
                        [ngStyle]="{ 'border-color': '#ebe9ff' }"
                        (click)="openSelectRestaurantModal()">
                        <span class="malou-text-11--semibold malou-color-text-1">{{
                            displayedWheelOfFortune()?.restaurants?.[0]?.name
                        }}</span>
                        <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.CHEVRON_DOWN"> </mat-icon>
                    </div>
                </div>
            }
            @if (selectedRestaurantId()) {
                <span
                    class="malou-text-12--semibold malou-color-text-1 left-0 mb-4 w-full text-center"
                    [ngClass]="isPreview() ? 'cursor-default' : 'cursor-pointer'"
                    (click)="isPreview() ? null : openGameRules()"
                    >{{ 'play_wheel_of_fortune.rules.title' | translate }}</span
                >
            }
        </div>
    </div>
</ng-template>

<ng-template let-img="img" #imageWithBackground>
    <div class="relative mb-8 ml-5">
        <div
            class="absolute bottom-0 right-[20px] h-[140px] w-[140px] rounded-full"
            [ngStyle]="{ 'background-color': displayedWheelOfFortune()?.parameters?.primaryColor }"></div>
        <img class="relative w-[140px]" [alt]="img" [src]="img | imagePathResolver: { folder: 'wheels-of-fortune' }" />
    </div>
</ng-template>

<ng-template #desktopTemplate>
    <app-redirect-wheel-of-fortune-mobile [logoUrl]="logo"></app-redirect-wheel-of-fortune-mobile>
</ng-template>

<ng-template #loadingTemplate>
    <div class="flex h-screen w-full items-center justify-center">
        <app-malou-spinner size="large" color="primary"></app-malou-spinner>
    </div>
</ng-template>
