import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { isNumber, max } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ViewBy } from ':shared/enums/view-by.enum';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorLighterBlue, malouChartColorPink } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

interface TicksSetup {
    max: number;
    min: number;
    stepSize: number;
}

export interface SplittedEngagementData {
    facebookData: ChartDataArray;
    instagramData: ChartDataArray;
    total: ChartDataArray;
}

export enum EngagementDataType {
    ENGAGEMENT = 'engagement',
    IMPRESSIONS = 'impressions',
}

export interface EngagementData {
    [EngagementDataType.ENGAGEMENT]: SplittedEngagementData;
    [EngagementDataType.IMPRESSIONS]: SplittedEngagementData;
    postsCount: SplittedEngagementData;
}

@Component({
    selector: 'app-engagement-chart',
    templateUrl: './engagement-chart.component.html',
    styleUrls: ['./engagement-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementChartComponent implements OnChanges {
    @Input() engagementData: EngagementData;
    @Input() labels: Date[];
    @Input() viewBy: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() hiddenDatasetIndexesChange = new EventEmitter<number[]>();

    readonly _FACEBOOK_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key');
    readonly _INSTAGRAM_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.INSTAGRAM, 'platform_key');
    readonly _NO_DATA_TRAD = this._translateService.instant('common.no_data');

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: Date[];
    chartOption: ChartOptions<BarChartType>;

    private _ticksSetup: TicksSetup[];
    private _hiddenDatasetIndexes: number[] = [];

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'engagementData')) {
            this.chartDataSets = this._computeChartData(this.engagementData);
            // needed to align zeros in multiple axis chart
            this._ticksSetup = this._computeTicksSetup(this.chartDataSets);
            if (this.hiddenDatasetIndexes.length) {
                this.chartDataSets = this.chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes.includes(index));
            }
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'labels')) {
            this.chartLabels = this._computeChartLabels(this.labels);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'viewBy', 'engagementData')) {
            this.chartOption = this._computeChartOptions();
        }
    }

    private _computeChartData(data: EngagementData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('statistics.social_networks.engagement.impressions'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: data.impressions.total,
            },
            {
                label: this._translateService.instant('statistics.social_networks.engagement.engagement_rate'),
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                data: data.engagement.total,
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            this._hiddenDatasetIndexes.push(index);
                            legendItem.hidden = true;
                        } else {
                            ci.show(index);
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(this.viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(this.viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis1: {
                    axis: 'y',
                    type: 'linear',
                    min: this._ticksSetup[0].min,
                    max: this._ticksSetup[0].max,
                    ticks: {
                        stepSize: this._ticksSetup[0].stepSize,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number, { fixNumber: 0 }),
                    },
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                },
                yAxis2: {
                    axis: 'y',
                    type: 'linear',
                    min: this._ticksSetup[1].min,
                    max: this._ticksSetup[1].max,
                    ticks: {
                        stepSize: this._ticksSetup[1].stepSize,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number, { content: '%', fixNumber: 1 }),
                    },
                    position: 'right',
                },
            },
        };
    }

    private _computeTicksSetup(datasets: ChartDataset<BarChartType, ChartDataArray>[]): TicksSetup[] {
        return datasets.map((dataset) => this._getTicksSetup(dataset));
    }

    private _getTicksSetup(dataset: ChartDataset<BarChartType, ChartDataArray>, ticksNumber = 8): TicksSetup {
        const maxData: number = max(dataset.data) || 1;
        const maxDataRounded = Math.ceil(maxData);
        return {
            max: maxDataRounded,
            min: 0,
            stepSize: maxDataRounded / ticksNumber,
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[]): string {
        const title = item[0].label;
        const postCount = this.engagementData.postsCount.total[item[0].dataIndex];
        const post =
            (postCount || 0) > 1
                ? this._translateService.instant('statistics.social_networks.engagement.posts')
                : this._translateService.instant('statistics.social_networks.engagement.post');
        if (this.viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.social_networks.engagement.week_of');
            return `${weekOf} ${title} : ${postCount} ${post}`;
        }
        return `${title} : ${postCount} ${post}`;
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string | string[] | undefined {
        if (item.datasetIndex === 0) {
            return this._computeTooltipLabelByKey(item, EngagementDataType.IMPRESSIONS);
        }
        if (item.datasetIndex === 1) {
            return this._computeTooltipLabelByKey(item, EngagementDataType.ENGAGEMENT);
        }
    }

    private _computeTooltipLabelByKey(item: TooltipItem<BarChartType>, key: EngagementDataType): string[] {
        const data: SplittedEngagementData = this.engagementData[key];
        const postsCountData: SplittedEngagementData = this.engagementData.postsCount;
        const isEngagement = key === EngagementDataType.ENGAGEMENT;

        const facebookLabel = this._computeTooltipLabelLine(data, postsCountData, PlatformKey.FACEBOOK, item.dataIndex, isEngagement);
        const instagramLabel = this._computeTooltipLabelLine(data, postsCountData, PlatformKey.INSTAGRAM, item.dataIndex, isEngagement);

        return [facebookLabel, instagramLabel];
    }

    private _computeTooltipLabelLine(
        data: SplittedEngagementData,
        postsCount: SplittedEngagementData,
        platformKey: PlatformKey,
        itemIndex: number,
        isEngagement = false
    ): string {
        const detailsText = isEngagement
            ? this._translateService.instant('statistics.social_networks.engagement.engagement_detail')
            : this._translateService.instant('statistics.social_networks.engagement.impressions_detail');

        const platformText = platformKey === PlatformKey.INSTAGRAM ? this._INSTAGRAM_KEY_TRAD : this._FACEBOOK_KEY_TRAD;
        const platformDataKey = `${platformKey.toLowerCase()}Data`;

        let valueText = '';
        const hasData = !!postsCount[platformDataKey][itemIndex];
        if (!hasData) {
            valueText = '-';
        } else {
            const value = data[platformDataKey][itemIndex] as number;
            const prettyValue = parseFloat(value.toFixed(2));
            const suffix = isEngagement ? '%' : '';
            valueText = `${prettyValue ?? this._NO_DATA_TRAD} ${suffix}`;
        }

        return `  ${detailsText} ${platformText} : ${valueText}`;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }
}
