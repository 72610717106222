import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { Message } from ':shared/models/message';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-message-notification-snackbar',
    templateUrl: './message-notification-snackbar.component.html',
    styleUrls: ['./message-notification-snackbar.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, PlatformLogoComponent],
})
export class MessageNotificationSnackbarComponent {
    readonly SvgIcon = SvgIcon;
    constructor(
        private readonly _router: Router,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _matDialog: MatDialog,
        @Inject(MAT_SNACK_BAR_DATA) public data: Message,
        private readonly _snackbarRef: MatSnackBarRef<MessageNotificationSnackbarComponent>
    ) {}

    close(): void {
        this._snackbarRef.dismiss();
    }

    navigateToMessages(): void {
        this._matDialog.closeAll();
        this._router.navigate(['restaurants', this._restaurantsService.currentRestaurant._id, 'interactions', 'messages']);
        this._snackbarRef.dismiss();
    }
}
