@if (isDownloading()) {
    <div class="w-100 mt-4 flex justify-center">
        <div class="malou-card max-w-xl !p-0 !py-4">
            <div class="malou-text-12--bold mb-2 pl-4 text-malou-color-text-1">
                {{ 'gallery.download_in_progress' | translate }}
            </div>
            <hr class="w-full border-malou-color-background-dark" />
            <div class="flex items-center gap-x-4 px-4 pt-2">
                <app-circle-progress [progress$]="downloadProgress$"></app-circle-progress>
                <div class="malou-text-12--regular text-malou-color-text-1">
                    {{ 'gallery.download_in_progress_description' | translate: { length: filesToDownloadLength() } }}
                </div>
            </div>
        </div>
    </div>
}
