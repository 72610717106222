@if (!!checklist() && checklist()!.isDisplayed) {
    <div class="fixed bottom-0 right-16 z-[2]">
        <div class="malou-box-shadow mx-auto max-w-[24rem] rounded-t-lg bg-white shadow-md">
            <div class="malou-background-light flex cursor-pointer items-center justify-between rounded-t-lg p-4" (click)="toggleMinify()">
                <div class="flex items-center">
                    <span class="malou-text-14--bold">Checklist JO 2024</span>
                    <span class="malou-text-14 ml-2 mr-2 italic"> ({{ completedTasks() }}/ {{ checklist()!.tasks.length }})</span>
                </div>
                <div class="mr-2 h-2 w-24 overflow-hidden rounded-full bg-malou-color-background-pinky-light">
                    <div class="malou-bg-gradient h-full" [style.width.%]="(completedTasks()! / checklist()!.tasks.length) * 100"></div>
                </div>
                @if (!!minified()) {
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CHEVRON_UP"></mat-icon>
                } @else {
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
                }
                <mat-icon class="small-icon opacity-30" color="primary" [svgIcon]="SvgIcon.CROSS" (click)="toggleClose()"></mat-icon>
            </div>

            @if (!minified()) {
                <div class="flex max-h-[80vh] flex-col space-y-1 overflow-scroll p-4">
                    @for (task of checklist()!.tasks; track task; let idx = $index) {
                        @if (taskMapper(task.name).title) {
                            <div class="malou-border-color-dark rounded-md border px-4 py-4">
                                <div class="mb-2 flex items-center justify-between space-x-2">
                                    <div class="flex items-center justify-between space-x-2">
                                        <div>
                                            @if (task.completed) {
                                                <div
                                                    class="grid !h-5 !w-5 cursor-pointer place-items-center rounded-full bg-malou-color-primary"
                                                    [id]="'tracking_checklist_checkbox_' + idx"
                                                    (click)="toggleTaskCompletion(task)">
                                                    <mat-icon class="!h-3.5 !w-3.5 !text-white" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                                                </div>
                                            } @else {
                                                <div
                                                    class="malou-color-border--secondary grid h-5 w-5 cursor-pointer place-items-center rounded-full border"
                                                    [id]="'tracking_checklist_checkbox_' + idx"
                                                    (click)="toggleTaskCompletion(task)"></div>
                                            }
                                        </div>
                                        <div class="malou-text-12--bold malou-color-text-2">{{ taskMapper(task.name).title }}</div>
                                    </div>
                                    <a
                                        class="malou-text-12--medium malou-color-primary"
                                        [id]="'tracking_checklist_navigation_' + idx"
                                        (click)="navigateTo(taskMapper(task.name).actionButtonLink)">
                                        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                                    </a>
                                </div>
                                <i>
                                    @for (subtitle of taskMapper(task.name).subtitle; track $index) {
                                        <li class="malou-text-10--medium malou-color-leslie-subtitle">{{ subtitle }}</li>
                                    }
                                </i>
                            </div>
                        }
                    }
                </div>
            }
        </div>
    </div>
}
