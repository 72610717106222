import { createAction, props } from '@ngrx/store';

import { KeywordStatsV3Dto, ReviewResponseDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { ReviewsEvolutionWithRange, ReviewsRating } from ':modules/reviews/reviews.interface';
import {
    DatesAndPeriod,
    InsightsByPlatform,
    Keyword,
    MalouTimeScalePeriod,
    PostsWithInsightsByPlatforms,
    Review,
    ReviewWithAnalysis,
    TimeScaleToMetricToDataValues,
} from ':shared/models';
import { UserRestaurantFilters } from ':shared/models/user-filters.model';

import { BoostersStatisticsData } from '../boosters/boosters.component';
import { StoriesAndInsights } from '../social-networks/posts-insights-table/stories/stories.component';
import { PlatformFilterPage } from './statistics.interface';

export const initializeState = createAction('[Statistics] Initialize_State', props<{ data: UserRestaurantFilters['statisticsFilters'] }>());
export const editDates = createAction('[Statistics] Edit_Dates', props<{ dates: DatesAndPeriod }>());
export const editPlatforms = createAction('[Statistics] Edit_Platforms', props<{ page: PlatformFilterPage; platforms: PlatformKey[] }>());
export const editTotems = createAction('[Statistics] Edit_Totems', props<{ totemIds: string[] }>());
export const editTimeScale = createAction('[Statistics] Edit_Time_Scale', props<{ data: MalouTimeScalePeriod }>());
export const resetState = createAction('[Statistics] Reset_State');

export const editKeywords = createAction('[Statistics] Edit_Keywords', props<{ keywords: Keyword[]; stats: KeywordStatsV3Dto[] }>());
export const editActionsRawData = createAction(
    '[Statistics] Edit_Actions_Raw_Data',
    props<{ data: TimeScaleToMetricToDataValues | undefined }>()
);

export const editReviewsRawData = createAction('[Statistics] Edit_Reviews_Raw_Data', props<{ data: Review[] }>());

export const editPostsWithInsightsRawData = createAction(
    '[Statistics] Edit_Posts_With_Insights_Raw_Data',
    props<{ data: PostsWithInsightsByPlatforms }>()
);

export const editFollowersRawData = createAction('[Statistics] Edit_Followers_Raw_Data', props<{ data: InsightsByPlatform }>());

export const editStoriesAndInsightsRawData = createAction(
    '[Statistics] Edit_Stories_And_Insights_Raw_Data',
    props<{ data: StoriesAndInsights }>()
);
export const editReviewsWithAnalysisData = createAction(
    '[Statistics] Edit_Reviews_With_Analysis_Data',
    props<{ data: ReviewWithAnalysis[] }>()
);

export const editPlatformsRatingsData = createAction(
    '[Statistics] Edit_Platforms_Ratings_Data',
    props<{ data: InsightsByPlatform | undefined }>()
);

export const editReviewsRatingsEvolutionData = createAction(
    '[Statistics] Edit_Reviews_Ratings_Evolution_Data',
    props<{ data: ReviewsEvolutionWithRange }>()
);

export const editReviewsRatingsTotalData = createAction('[Statistics] Edit_Reviews_Ratings_Total_Data', props<{ data: ReviewsRating[] }>());

export const editBoosterStatsData = createAction('[Statistics] Edit_Booster_Stats_Data', props<{ data: BoostersStatisticsData }>());

export const editPrivateReviewData = createAction('[Statistics] Edit_Private_Review_Data', props<{ data: ReviewResponseDto[] }>());
