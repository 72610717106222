import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';

import { isNotNil, PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { MediaItemComponent } from ':shared/components/media-item/media-item.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-social-post-item',
    templateUrl: './social-post-item.component.html',
    styleUrls: ['./social-post-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        MediaItemComponent,
        PlatformLogoComponent,
        PostDatePickerComponent,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostItemComponent {
    readonly post = input.required<SocialPostItem>();

    private readonly _translateService = inject(TranslateService);

    readonly postDate = computed((): Date => {
        const post = this.post();
        return post.published === PostPublicationStatus.PUBLISHED && post.socialCreatedAt
            ? post.socialCreatedAt
            : post.plannedPublicationDate;
    });

    readonly canOpenPostSocialLink = computed((): boolean => {
        const post = this.post();
        return post.published === PostPublicationStatus.PUBLISHED && isNotNil(post.socialLink);
    });

    readonly canEditPost = computed((): boolean => {
        const post = this.post();

        return (
            post.published !== PostPublicationStatus.PUBLISHED ||
            (isEqual(post.platformKeys, [PlatformKey.FACEBOOK]) && post.postType !== PostType.REEL)
        );
    });

    readonly canDeletePost = computed((): boolean => {
        const post = this.post();
        return post.published !== PostPublicationStatus.PUBLISHED;
    });

    readonly postTypeIcon = computed((): SvgIcon | undefined => {
        const postType = this.post().postType;
        switch (postType) {
            case PostType.IMAGE:
                return SvgIcon.IMAGE;
            case PostType.VIDEO:
            case PostType.REEL:
                return SvgIcon.VIDEO;
            case PostType.CAROUSEL:
                return SvgIcon.IMAGES;
            default:
                return SvgIcon.IMAGE;
        }
    });

    readonly textAndHashtags = computed((): string => {
        const post = this.post();
        return `${post.text} ${post.getHashtagsText()}`;
    });

    readonly feedbackCountTag = computed((): string | undefined => {
        const feedbackCount = this.post().feedbackMessagesCount;
        return feedbackCount ? this._translateService.instant('social_post.feedback_count_tag', { feedbackCount }) : undefined;
    });

    readonly SvgIcon = SvgIcon;
    readonly PostPublicationStatus = PostPublicationStatus;

    onOpenSocialLink(): void {
        const link = this.post().socialLink;
        if (link) {
            window.open(link, '_blank');
        }
    }

    onEditPost(): void {
        console.log('Edit post is not implemented yet');
    }

    onDeletePost(): void {
        console.log('Delete post is not implemented yet');
    }

    onRefreshPost(): void {
        console.log('Refresh post is not implemented yet');
    }

    onFeedbackCountTagClick(): void {
        console.log('Feedback count tag click is not implemented yet');
    }
}
