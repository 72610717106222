import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

export enum ShowMoreMode {
    EXPAND,
    TOOLTIP,
}
@Component({
    selector: 'app-single-post-legend',
    templateUrl: './single-post-legend.component.html',
    styleUrls: ['./single-post-legend.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, MatButtonModule, TranslateModule],
    providers: [ShortTextPipe],
})
export class SinglePostLegendComponent implements OnInit {
    @Input() text: string;
    @Input() hasInstagramServerError?: boolean;
    @Input() showMoreMode = ShowMoreMode.TOOLTIP;
    @Output() toggledTextSize = new EventEmitter();
    displayedText = '';
    showMoreLessButtons = false;
    showMoreLabel = true;
    ShowMoreMode = ShowMoreMode;

    constructor(private readonly _shortTextPipe: ShortTextPipe) {}

    ngOnInit(): void {
        if (this.getEndsWithThreeDotSize() < this.text?.length) {
            this.showMoreLessButtons = true;
        }
        this.displayedText = this.text;
        this.toggleTextSize();
    }

    getEndsWithThreeDotSize(): number {
        return this.hasInstagramServerError ? 100 : 160;
    }

    toggleTextSize(): void {
        this.toggledTextSize.emit();
        if (this.displayedText === this.text) {
            this.displayedText = this._shortTextPipe.transform(this.text, this.getEndsWithThreeDotSize());
            this.showMoreLabel = true;
            return;
        }
        this.displayedText = this.text;
        this.showMoreLabel = false;
    }
}
