<app-modal-structure
    [title]="'platforms.connection_new.facebook_instagram.step_2.title' | translate"
    [primaryButtonText]="'platforms.connection_new.facebook_instagram.step_2.primary_button' | translate"
    [secondaryButtonText]="'platforms.connection_new.facebook_instagram.step_2.secondary_button' | translate"
    [buttonStyle]="ButtonStyle.LONG_TEXT"
    (close)="close.emit({})"
    (primaryClick)="goToStep.emit({ type: 'relative', value: 2 })"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: 1 })">
    <div class="malou-color-text-2 malou-text-13--medium">
        {{ 'platforms.connection_new.facebook_instagram.step_2.description' | translate }}
    </div>
</app-modal-structure>
