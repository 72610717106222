import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { uniqBy } from 'lodash';

import { ConversationWithMessage, ConversationWithMessages } from ':shared/models/conversation';
import { Message } from ':shared/models/message';

import * as MessagesActions from './messages.actions';

export interface MessagesState {
    conversationsWithMessages: ConversationWithMessages[];
    unreadConversations: number;
    currentConversationWithMessages: ConversationWithMessages | null;
}

export const initialState: MessagesState = {
    conversationsWithMessages: [],
    unreadConversations: 0,
    currentConversationWithMessages: null,
};

const messagesReducer = createReducer(
    initialState,
    on(MessagesActions.receiveNewMessage, (state, { conversationWithMessage }) => receiveNewMessage(state, { conversationWithMessage })),
    on(MessagesActions.deleteMessage, (state, { conversationWithMessage }) => updateMessage(state, { conversationWithMessage })),
    on(MessagesActions.receiveNewReaction, (state, { conversationWithMessage }) => updateMessage(state, { conversationWithMessage })),
    on(MessagesActions.sendNewMessage, (state, { message }) => sendNewMessage(state, { message })),
    on(MessagesActions.loadMoreMessages, (state, { conversationWithMessages }) => loadMoreMessages(state, { conversationWithMessages })),
    on(MessagesActions.loadMoreConversations, (state, { conversationsWithMessages }) => ({
        ...state,
        conversationsWithMessages: uniqBy(
            state.conversationsWithMessages.concat(conversationsWithMessages),
            (item) => item.conversation._id
        ),
    })),
    on(MessagesActions.editConversations, (state, { conversationsWithMessages }) => ({ ...state, conversationsWithMessages })),
    on(MessagesActions.editConversationStatus, (state, { conversation, status }) =>
        editConversationStatus(state, { conversation, status })
    ),
    on(MessagesActions.editConversationFavorite, (state, { conversation, favorite }) =>
        editConversationFavorite(state, { conversation, favorite })
    ),
    on(MessagesActions.editConversationArchived, (state, { conversation, archived }) =>
        editConversationArchived(state, { conversation, archived })
    ),
    on(MessagesActions.editMessageAfterSend, (state, { message, correctDbId }) => editMessageAfterSend(state, { message, correctDbId })),
    on(MessagesActions.editConversationTypedText, (state, { text }) => editConversationTypedText(state, { text })),
    on(MessagesActions.editCount, (state, { unreadConversationCount }) => ({ ...state, unreadConversations: unreadConversationCount })),
    on(MessagesActions.changeCurrentConversation, (state, { conversationWithMessages }) => ({
        ...state,
        currentConversationWithMessages: conversationWithMessages,
    }))
);

export const selectMessagesState = createFeatureSelector<MessagesState>('messages');

export const selectUnreadConversations = createSelector(selectMessagesState, (state: MessagesState) => state.unreadConversations);

export const selectCurrentConversationWithMessages = createSelector(
    selectMessagesState,
    (state: MessagesState) => state.currentConversationWithMessages
);

export function reducer(state: MessagesState | undefined, action: Action): MessagesState {
    return messagesReducer(state, action);
}

const receiveNewMessage = (
    state: MessagesState,
    { conversationWithMessage }: { conversationWithMessage: ConversationWithMessage }
): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversation) => conversation?.conversation._id === conversationWithMessage?.conversation._id
    );
    if (conversationIndex === -1) {
        const newConversationWithMessages = new ConversationWithMessages({
            ...conversationWithMessage,
            messages: [conversationWithMessage.message],
        });
        const newConversationsWithMessages = [newConversationWithMessages, ...state.conversationsWithMessages];
        return {
            ...state,
            unreadConversations: newConversationsWithMessages.filter((conv) => conv.getConversationStatus() === 'UNREAD')?.length ?? 0,
            conversationsWithMessages: newConversationsWithMessages,
        };
    }
    const conversationWithMessagesToUpdate = new ConversationWithMessages({
        ...conversationWithMessage,
        messages: uniqBy([...state.conversationsWithMessages[conversationIndex].messages, conversationWithMessage.message], '_id'),
    });
    const shouldUpdateCurrentConversation =
        state?.currentConversationWithMessages?.conversation._id === conversationWithMessage?.conversation._id;
    const allConversationsWithMessages = [
        conversationWithMessagesToUpdate,
        ...state.conversationsWithMessages.filter((cwm) => cwm.conversation._id !== conversationWithMessagesToUpdate.conversation._id),
    ];
    return {
        ...state,
        currentConversationWithMessages: shouldUpdateCurrentConversation
            ? conversationWithMessagesToUpdate
            : state.currentConversationWithMessages,
        unreadConversations: allConversationsWithMessages.filter((conv) => conv.getConversationStatus() === 'UNREAD')?.length ?? 0,
        conversationsWithMessages: allConversationsWithMessages,
    };
};

const updateMessage = (
    state: MessagesState,
    { conversationWithMessage }: { conversationWithMessage: ConversationWithMessage }
): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversation) => conversation?.conversation._id === conversationWithMessage?.conversation._id
    );
    if (conversationIndex === -1) {
        const newConversationWithMessages = new ConversationWithMessages({
            ...conversationWithMessage,
            messages: [conversationWithMessage.message],
        });
        return {
            ...state,
            conversationsWithMessages: [newConversationWithMessages, ...state.conversationsWithMessages],
        };
    }
    const conversationWithMessagesToUpdate = new ConversationWithMessages({
        conversation: state.conversationsWithMessages[conversationIndex].conversation,
        messages: [
            ...state.conversationsWithMessages[conversationIndex].messages.map((m) =>
                m._id === conversationWithMessage.message._id ? new Message(conversationWithMessage.message) : m
            ),
        ],
    });
    const shouldUpdateCurrentConversation =
        state?.currentConversationWithMessages?.conversation._id === conversationWithMessage?.conversation._id;
    return {
        ...state,
        currentConversationWithMessages: shouldUpdateCurrentConversation
            ? conversationWithMessagesToUpdate
            : state.currentConversationWithMessages,
        conversationsWithMessages: [
            conversationWithMessagesToUpdate,
            ...state.conversationsWithMessages.filter((cwm) => cwm.conversation._id !== conversationWithMessagesToUpdate.conversation._id),
        ],
    };
};

const sendNewMessage = (state: MessagesState, { message }: { message: Partial<Message> }): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversation) => conversation.conversation._id === state.currentConversationWithMessages?.conversation._id
    );

    const conversationWithMessagesToUpdate = new ConversationWithMessages({
        conversation: {
            ...state.conversationsWithMessages[conversationIndex].conversation,
            latestMessageAt: message.socialCreatedAt || '',
        },
        messages: [...state.conversationsWithMessages[conversationIndex].messages, message],
    });
    return {
        ...state,
        currentConversationWithMessages: conversationWithMessagesToUpdate,
        conversationsWithMessages: [
            conversationWithMessagesToUpdate,
            ...state.conversationsWithMessages.filter((cwm) => cwm.conversation._id !== conversationWithMessagesToUpdate.conversation._id),
        ],
    };
};

const loadMoreMessages = (
    state: MessagesState,
    { conversationWithMessages }: { conversationWithMessages: ConversationWithMessages }
): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (cwm) => cwm.conversation._id === conversationWithMessages.conversation._id
    );
    const conversationWithMessagesToUpdate = new ConversationWithMessages({
        ...state.conversationsWithMessages[conversationIndex],
        conversation: {
            ...state.conversationsWithMessages[conversationIndex].conversation,
        },
        messages: [...conversationWithMessages.messages, ...state.conversationsWithMessages[conversationIndex].messages],
    });

    const conversations = [
        ...state.conversationsWithMessages.map((cwm) =>
            cwm.conversation._id === conversationWithMessagesToUpdate.conversation._id ? conversationWithMessagesToUpdate : cwm
        ),
    ];

    return {
        ...state,
        currentConversationWithMessages: conversationWithMessagesToUpdate,
        conversationsWithMessages: [...conversations],
    };
};

const editConversationStatus = (state: MessagesState, { conversation, status }): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversationWithMessages) => conversationWithMessages.conversation._id === conversation._id
    );
    if (conversationIndex === -1) {
        return state;
    }
    const conversations = [
        ...state.conversationsWithMessages.map((cwm) =>
            cwm.conversation._id === conversation._id
                ? new ConversationWithMessages({ ...cwm, conversation: { ...cwm.conversation, status } })
                : cwm
        ),
    ];

    return {
        ...state,
        unreadConversations: status === 'UNREAD' ? state.unreadConversations + 1 : state.unreadConversations - 1,
        conversationsWithMessages: [...conversations],
    };
};

const editConversationFavorite = (state: MessagesState, { conversation, favorite }): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversationWithMessages) => conversationWithMessages.conversation._id === conversation._id
    );
    if (conversationIndex === -1) {
        return state;
    }
    const conversations = [
        ...state.conversationsWithMessages.map((cwm) =>
            cwm.conversation._id === conversation._id
                ? new ConversationWithMessages({ ...cwm, conversation: { ...cwm.conversation, favorite } })
                : cwm
        ),
    ];

    return {
        ...state,
        conversationsWithMessages: [...conversations],
    };
};

const editConversationArchived = (state: MessagesState, { conversation, archived }): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversationWithMessages) => conversationWithMessages.conversation._id === conversation._id
    );
    if (conversationIndex === -1) {
        return state;
    }
    const conversations = [
        ...state.conversationsWithMessages.map((cwm) =>
            cwm.conversation._id === conversation._id
                ? new ConversationWithMessages({ ...cwm, conversation: { ...cwm.conversation, archived } })
                : cwm
        ),
    ];

    return {
        ...state,
        conversationsWithMessages: [...conversations],
    };
};

const editMessageAfterSend = (
    state: MessagesState,
    { message, correctDbId }: { message: Partial<Message>; correctDbId: string }
): MessagesState => {
    const conversationIndex = state.conversationsWithMessages.findIndex(
        (conversation) => conversation.conversation._id === state.currentConversationWithMessages?.conversation._id
    );
    const conversationWithMessagesUpdated = new ConversationWithMessages({
        conversation: {
            ...state.conversationsWithMessages[conversationIndex].conversation,
            latestMessageAt: message.socialCreatedAt || '',
        },
        messages: state.currentConversationWithMessages?.messages
            .filter((m) => !!m._id)
            .map((m) => (m._id === message._id ? { ...message, _id: correctDbId } : m)),
    });
    return {
        ...state,
        currentConversationWithMessages: conversationWithMessagesUpdated,
        conversationsWithMessages: [
            conversationWithMessagesUpdated,
            ...state.conversationsWithMessages.filter((cwm) => cwm.conversation._id !== conversationWithMessagesUpdated.conversation._id),
        ],
    };
};

const editConversationTypedText = (state: MessagesState, { text }): MessagesState => {
    const conversations = [
        ...state.conversationsWithMessages.map((cwm) =>
            cwm.conversation._id === state.currentConversationWithMessages?.conversation._id
                ? new ConversationWithMessages({ ...cwm, typedText: text })
                : cwm
        ),
    ];
    return {
        ...state,
        conversationsWithMessages: [...conversations],
    };
};
