<app-modal-structure
    [title]="'platforms.connection_new.google.step_0.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="true"
    [secondaryButtonText]="'common.cancel' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="close.emit({})"
    (secondaryClick)="close.emit({})">
    <div class="flex h-full items-center justify-center"><app-malou-spinner></app-malou-spinner></div>
</app-modal-structure>
