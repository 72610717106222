import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { RestaurantAttributeValue } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-comparison-attributes',
    templateUrl: './comparison-attributes.component.html',
    styleUrls: ['./comparison-attributes.component.scss'],
    standalone: true,
    imports: [NgStyle, NgTemplateOutlet, MatIconModule, ApplyPurePipe],
})
export class ComparisonAttributesComponent implements OnInit {
    @Input() public attributes: any;

    readonly SvgIcon = SvgIcon;
    readonly RestaurantAttributeValue = RestaurantAttributeValue;

    ngOnInit(): void {
        this.attributes = this.attributes.filter((attribute: any) => typeof attribute === 'string' || attribute.text);
    }

    public getAttributeText(attribute: any): string {
        if (typeof attribute === 'string') {
            return attribute;
        }

        return attribute.text;
    }
}
