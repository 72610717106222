import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, forkJoin, map, of, switchMap, take } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { LocalStorageKey } from ':shared/enums/local-storage-key';

import { selectOwnRestaurants } from '../restaurant-list.reducer';
import { RestaurantsModalsService } from './restaurants.modals.service';

@Component({
    selector: 'app-restaurants',
    templateUrl: './restaurants.component.html',
    styleUrls: ['./restaurants.component.scss'],
    standalone: true,
    imports: [],
})
export class RestaurantsComponent implements OnInit {
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _restaurantsModalsService = inject(RestaurantsModalsService);

    readonly restaurants$ = this._store.select(selectOwnRestaurants);

    ngOnInit(): void {
        this._activatedRoute.queryParams
            .pipe(
                switchMap((params) => {
                    if (params.hasOpenedModal) {
                        return forkJoin({ user: of(null), hasOpenedConnectModal: of(true) });
                    }
                    if (params.auth_id) {
                        const hasOpenedConnectModal = this._checkIfShouldOpenConnectRestaurantModal(params.auth_id);
                        if (hasOpenedConnectModal) {
                            return forkJoin({ user: of(null), hasOpenedConnectModal: of(true) });
                        }
                    }
                    return forkJoin({
                        user: this._store.select(selectUserInfos).pipe(
                            filter(isNotNil),
                            filter((user) => user.role !== 'malou-free'),
                            take(1)
                        ),
                        hasOpenedConnectModal: of(false),
                    });
                }),
                map(({ hasOpenedConnectModal, user }: { hasOpenedConnectModal: boolean; user: User }): string | null => {
                    if (hasOpenedConnectModal) {
                        return null;
                    }
                    const restaurantIdToRedirectTo: string | null =
                        (user.lastVisitedRestaurantId || user.restaurants?.[0]?.restaurantId) ?? null;
                    if (restaurantIdToRedirectTo) {
                        this._router.navigate([`restaurants/${restaurantIdToRedirectTo}`]);
                        return restaurantIdToRedirectTo;
                    }
                    this._restaurantsModalsService.openAddNewRestaurantModal();
                    return null;
                }),
                switchMap((restaurantId) =>
                    restaurantId ? this._restaurantsService.show(restaurantId).pipe(map((res) => res.data)) : of(null)
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (restaurant) => {
                    if (restaurant) {
                        this._restaurantsService.setSelectedRestaurant(restaurant);
                    }
                },
                error: (err) => console.warn('err :>>', err),
            });
    }

    private _checkIfShouldOpenConnectRestaurantModal(authId: string): boolean {
        const restaurantCreationFormInProgressAsString = LocalStorage.getItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
        LocalStorage.removeItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
        if (!restaurantCreationFormInProgressAsString) {
            console.error('Key RESTAURANT_CREATION_FORM_IN_PROGRESS not found in localstorage');
            return false;
        }
        const { organizationId, restaurantType } = JSON.parse(restaurantCreationFormInProgressAsString);
        if (!restaurantType) {
            console.error('No restaurantType in LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS');
            return false;
        }

        this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { hasOpenedModal: true } });

        setTimeout(() => {
            this._restaurantsModalsService.openConnectRestaurantModal({
                authId,
                hasBeenRedirected: true,
                organizationId,
                restaurantType,
            });
        });
        return true;
    }
}
