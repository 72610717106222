<div class="flex gap-x-2">
    <!-- Aspect ratio buttons -->
    <div
        class="flex h-[50px] items-center gap-x-2 rounded-md border border-malou-color-border-secondary bg-malou-color-background-dark px-3">
        <mat-icon class="!h-[16px] !w-[16px] text-malou-color-primary" [svgIcon]="SvgIcon.DIMENSION"></mat-icon>
        @for (data of displayedAspectRatios; track data.aspectRatio) {
            <div
                class="malou-text-11--medium cursor-pointer rounded-md px-2 py-1 text-malou-color-border-secondary"
                [ngClass]="{ 'malou-color-primary bg-white': currentAspectRatio() === data.aspectRatio }"
                (click)="aspectRatioChange.emit(data.aspectRatio)">
                {{ data.i18nKey | translate }}
            </div>
        }
    </div>

    <!-- Rotation buttons -->
    <button
        class="flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-md border border-malou-color-background-dark bg-white hover:bg-malou-color-background-dark"
        (click)="onRotate(RotationDirection.COUNTERCLOCKWISE)">
        <mat-icon class="!h-[16px] !w-[16px] -scale-x-100 text-malou-color-primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
    </button>
    <button
        class="flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-md border border-malou-color-background-dark bg-white hover:bg-malou-color-background-dark"
        (click)="onRotate(RotationDirection.CLOCKWISE)">
        <mat-icon class="!h-[16px] !w-[16px] text-malou-color-primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
    </button>
</div>
