<app-modal-structure
    [title]="'platforms.connection_new.tiktok.step_0.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="false"
    [secondaryButtonText]="'common.cancel' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="false"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="close.emit({})">
    <div class="flex h-full flex-col items-center justify-center gap-y-4">
        @if (qrCodeImage(); as qrCodeImage) {
            <img class="h-[175px] w-[175px]" [src]="qrCodeImage" />
        }
        <button
            class="malou-text-13--semibold relative flex w-[315px] max-w-full items-center justify-center rounded border border-malou-color-border-primary p-3 text-malou-color-text-1"
            (click)="onConnectWithTikTok()">
            <img
                class="absolute left-2 top-[10px] h-6 w-6"
                alt=""
                [src]="'tiktok-connect-button-logo' | imagePathResolver: { folder: 'platforms-connection' }" />
            <span>{{ 'platforms.connection_new.tiktok.step_0.cta' | translate }}</span>
        </button>
    </div>
</app-modal-structure>
