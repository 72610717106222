<div class="relative h-full w-full">
    <ng-container *ngTemplateOutlet="!currentMedia() ? uploadTemplate : reelView"></ng-container>

    @if (computedProgress() !== 1) {
        <div
            class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center border border-malou-color-background-dark bg-malou-color-background-light px-12">
            <mat-progress-bar color="primary" mode="determinate" [value]="computedProgress() * 100"></mat-progress-bar>
        </div>
    }
</div>

<ng-template #reelView>
    <div class="flex h-full w-full flex-col gap-y-4">
        <div class="relative flex w-full flex-[5] justify-center overflow-hidden" id="reelContainer">
            <div class="flex items-center justify-center overflow-hidden">
                <!-- we emulate `object-fit: contain` with max-w-[100%] max-h-[100%] instead of
                 using `object-fit: contain` directly because we want round corners -->
                <video
                    class="videoPlayer h-auto max-h-[100%] w-auto max-w-[100%] rounded-xl"
                    id="videoPlayer"
                    muted
                    loop
                    playsinline
                    [src]="currentMediaUrl"
                    #videoPlayer></video>
            </div>

            <div
                class="absolute left-0 top-0 grid h-full w-full cursor-pointer place-items-center transition-all"
                [ngClass]="{ 'opacity-0 hover:opacity-100': isVideoPlaying() }"
                (click)="playVideo()">
                <div class="grid h-24 w-24 place-items-center rounded-full bg-white bg-opacity-70">
                    <mat-icon class="!h-9 !w-9" color="primary" [svgIcon]="isVideoPlaying() ? 'pause' : 'play'"></mat-icon>
                </div>
            </div>

            <div class="absolute bottom-3 left-0 flex flex-col gap-y-3">
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="removeMedia()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="muteUnmuteVideo()">
                    <mat-icon color="primary" [svgIcon]="isVideoMuted() ? 'sound-off' : 'sound-on'"></mat-icon>
                </button>
            </div>

            <div class="absolute left-0 top-0 w-full p-3">
                <div class="flex w-full flex-col gap-y-2">
                    @for (item of currentMedia()?.getErrors() ?? []; track item) {
                        <div
                            class="malou-text-10--regular w-full rounded-[10px] bg-malou-color-background-error px-4 py-3 italic text-malou-color-state-error">
                            {{ item }}
                        </div>
                    }
                </div>
            </div>
        </div>
        @if (currentMedia(); as media) {
            <app-media-thumbnail-picker
                [media]="media"
                [thumbnail]="thumbnailObject()"
                (thumbnailChange)="onThumbnailChange($event)"
                (onLoading)="onThumbnailPickerLoading($event)"></app-media-thumbnail-picker>
        }
    </div>
</ng-template>

<ng-template #uploadTemplate>
    <app-media-file-uploader
        [accept]="'video/*'"
        [multiple]="false"
        [disabled]="false"
        [maxVideoSize]="maxVideoSize"
        [maxNumberOfFiles]="maxNumberOfFiles"
        (fileProcessed)="onUploadFinished($event)"
        (startReadingFile)="onUploadStarted()"
        (processError)="processError($event)"
        (dragEnter)="dragOver()"
        (dragLeave)="dragLeave()"
        (progressChanged)="onUploadProgressChanged($event)"
        #mediaUploader>
        <div
            class="grid h-full w-full place-items-center rounded-[10px] border border-malou-color-background-dark bg-malou-color-background-light"
            [ngClass]="{ 'border-dashed border-malou-color-primary': draggingOver() }"
            (click)="$event.stopPropagation()">
            <div class="flex flex-col items-center gap-y-4">
                <img class="h-[100px] w-[100px]" [src]="'Goggles' | illustrationPathResolver" />
                <div class="malou-text-12--semibold text-malou-color-text-1">
                    {{ 'edit_image.drag_drop_file' | translate }}
                </div>
                <button class="malou-btn-flat" data-testid="upload-file-btn" (click)="mediaUploader.openFilePicker()">
                    {{ 'edit_image.upload' | translate }}
                </button>
                <button
                    class="malou-btn-flat"
                    data-testid="choose-from-gallery-btn"
                    (click)="openMediaPickerModal(); $event.stopPropagation()">
                    {{ 'edit_image.browse' | translate }}
                </button>
            </div>
        </div>
    </app-media-file-uploader>
</ng-template>
