import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, RoiTip } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';

@Component({
    selector: 'app-tip-item',
    templateUrl: './tip-item.component.html',
    styleUrls: ['./tip-item.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule],
})
export class TipItemComponent {
    isActionPressed = output<boolean>();

    roiTip = input.required<RoiTip>();
    emoji = input.required<string>();
    title = input.required<string>();
    details = input.required<string>();
    buttonText = input.required<string>();
    buttonLink = input.required<string>();

    private readonly _heapService = inject(HeapService);
    private readonly _router = inject(Router);

    redirectToPage(): void {
        this._heapService.track(HeapEventName.TRACKING_ROI_TIP, {
            roiTip: this.roiTip,
        });
        this._router.navigate([this.buttonLink()]);
        this.isActionPressed.emit(true);
    }
}
