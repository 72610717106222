import { Action, createReducer, on } from '@ngrx/store';

import { NfcActions } from './nfc.actions';

export interface NfcState {
    nfcCount: number;
}

const initialState: NfcState = {
    nfcCount: 0,
};

const nfcReducer = createReducer(
    initialState,
    on(NfcActions.setNfcCount, (state, { nfcCount }) => ({ ...state, nfcCount }))
);

export function reducer(state: NfcState, action: Action): NfcState {
    return nfcReducer(state, action);
}
