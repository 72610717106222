import { createAction, props } from '@ngrx/store';

import { Keyword } from ':shared/models';

export const editKeywordsByRestaurantId = createAction(
    '[Keywords] Edit_Keywords_By_Restaurant_Id',
    props<{ restaurantId: string; keywords: Keyword[] }>()
);

export const editSelectedRestaurantId = createAction('[Keywords] Edit_Selected_Restaurant_Id', props<{ restaurantId: string }>());

export const loadKeywords = createAction('[Keywords] Load_Keywords', props<{ restaurantId: string }>());
