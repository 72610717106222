<div class="flex flex-col px-10">
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'statistics_pdf.boosters_pdf.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantTitle$ | async | statisticsPdfRestaurantsFormatter }}
        </span>
    </div>

    <div class="flex flex-col gap-4 px-8.5 py-4">
        @if ((displayedCharts | includes: InsightsChart.BOOSTERS_SCAN_COUNT) || scanCountHasData) {
            <div class="break-inside-avoid">
                <app-statistics-boosters-scan-count
                    [data$]="filteredData$"
                    [isParentLoading]="isLoadingBoosters()"
                    [isParentError]="isErrorBoosters()"
                    [hiddenDatasetIndexes]="chartOptions[InsightsChart.BOOSTERS_SCAN_COUNT]?.hiddenDatasetIndexes ?? []"
                    [viewBy]="chartOptions[InsightsChart.BOOSTERS_SCAN_COUNT]?.viewBy"
                    (hasDataChange)="scanCountHasData = $event"></app-statistics-boosters-scan-count>
            </div>
        }

        @if (
            displayedCharts
                | includes
                    : InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT ||
                          (boostersEstimatedReviewCountHasData && wheelOfFortuneEstimatedReviewCountHasData)
        ) {
            <div class="mt-6 break-inside-avoid">
                <app-statistics-totems-estimated-review-count
                    [totemData$]="totemData$"
                    [wheelOfFortuneData$]="wheelOfFortuneData$"
                    [isParentLoading]="isLoadingBoosters() || isLoadingGifts()"
                    [isParentError]="isErrorBoosters() || isErrorGifts()"
                    [hiddenDatasetIndexes]="chartOptions[InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT]?.hiddenDatasetIndexes ?? []"
                    (hasDataChange)="boostersEstimatedReviewCountHasData = $event"></app-statistics-totems-estimated-review-count>
            </div>
        }

        @if (displayedCharts | includes: InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION) {
            <div class="mt-6 break-inside-avoid">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'statistics.totems.scan_count.wheel_of_fortune' | translate }}
                </div>
                @if (wheelOfFortuneGiftsKpisHasData) {
                    <div class="mb-6">
                        @if ((giftsData$ | async) !== null) {
                            <app-wheel-of-fortune-gifts-kpis
                                [data$]="giftsData$"
                                [isParentLoading]="isLoadingGifts()"
                                [isParentError]="isErrorGifts()"
                                (hasDataChange)="wheelOfFortuneGiftsKpisHasData = $event"></app-wheel-of-fortune-gifts-kpis>
                        }
                    </div>
                }
                <div class="flex gap-4 md:flex-col">
                    @if (wheelOfFortuneGiftsDistributionHasData) {
                        <div class="min-w-0 flex-1">
                            <app-wheel-of-fortune-gifts-distribution
                                class="flex h-[500px]"
                                [data$]="giftsData$"
                                [isParentLoading]="isLoadingGifts()"
                                [isParentError]="isErrorGifts()"
                                [tableSortBy]="chartOptions[InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION]?.tableSortOptions"
                                (hasDataChange)="wheelOfFortuneGiftsDistributionHasData = $event"></app-wheel-of-fortune-gifts-distribution>
                        </div>
                    }
                </div>
            </div>
        }

        <div class="mt-6 break-inside-avoid">
            @if (displayedCharts | includes: InsightsChart.BOOSTERS_PRIVATE_REVIEWS_COUNT || boostersPrivateReviewCountHasData) {
                <ng-container [ngTemplateOutlet]="totemsStatisticsTemplate"></ng-container>
            }
        </div>
    </div>
</div>

<ng-template #totemsStatisticsTemplate>
    <div class="malou-text-18--bold text-malou-color-text-1">
        {{ 'statistics.totems.totems' | translate }}
    </div>
    <div class="flex break-inside-avoid gap-4 md:flex-col">
        <div class="min-w-0 flex-1">
            <app-statistics-totems-private-review-count
                [data$]="privateReviewData$"
                [isParentLoading]="isLoadingBoosters() || isPrivateReviewsLoading()"
                [isParentError]="isErrorBoosters() || isPrivateReviewsError()"
                (hasDataChange)="boostersPrivateReviewCountHasData = $event"></app-statistics-totems-private-review-count>
        </div>
    </div>
</ng-template>
