import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

const restaurantAiSettingsDuplicationDataValidator = z.object({
    restaurantId: objectIdValidator,
    restaurantName: z.string(),
    signature: z.string(),
});
export type RestaurantAiSettingsDuplicationDataDto = z.infer<typeof restaurantAiSettingsDuplicationDataValidator>;

export const duplicateRestaurantAiSettingsBodyValidator = z.object({
    duplicationData: z.array(restaurantAiSettingsDuplicationDataValidator),
});
export type DuplicateRestaurantAiSettingsBodyDto = z.infer<typeof duplicateRestaurantAiSettingsBodyValidator>;

export const duplicateRestaurantAiSettingsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ fromRestaurantId: data.restaurant_id }));
export type DuplicateRestaurantAiSettingsParamsDto = z.infer<typeof duplicateRestaurantAiSettingsParamsValidator>;
