export enum SesEventType {
    BOUNCE = 'Bounce',
    COMPLAINT = 'Complaint',
    DELIVERY = 'Delivery',
    OPEN = 'Open',
    CLICK = 'Click',
    SEND = 'Send',
}

export enum SesBounceType {
    PERMANENT = 'Permanent',
    TRANSIENT = 'Transient',
    UNDETERMINED = 'Undetermined',
}

export interface SesMailData {
    eventType: SesEventType;
    mail: { messageId: string };
    bounce?: { bounceType: SesBounceType };
}
