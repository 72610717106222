import { createAction, props } from '@ngrx/store';

import { PostsFilters } from ':shared/models';

export const editSocialPostsFilters = createAction('[Posts] Edit_Social_Posts_Filters', props<{ filters: PostsFilters }>());

export const editSocialPostsFiltersPlatforms = createAction(
    '[Posts] Edit_Social_Posts_Filters_Platforms',
    props<{ platforms: string[] }>()
);

export const setSocialPostsFiltersPlatforms = createAction('[Posts] Set_Social_Posts_Filters_Platforms', props<{ platforms: string[] }>());

export const changeSocialSortOrder = createAction('[Posts] Change_Social_Sort_Order');

export const resetSocialPostsFilters = createAction('[Posts] Reset_Social_Posts_Filters');

export const editHasSyncState = createAction(
    '[Posts] Edit_Has_Sync_State',
    props<{
        postsSync: {
            loading?: boolean;
            completed?: boolean;
        };
    }>()
);
