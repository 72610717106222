import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidXUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!(isFromDomain({ url, domain: SocialNetworkKey.X }) || isFromDomain({ url, domain: 'twitter' }))) {
            return false;
        }

        // Try to extract x username
        const userName = getXUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getXUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = decodeURIComponent(trimSlashes(parsedUrl.pathname)); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters and underscores are allowed for x usernames
        const userNameRegex = /^[a-zA-ZÀ-ž0-9_]+$/;

        // /username
        return pathname && userNameRegex.test(pathname) ? encodeURIComponent(pathname) : null;
    } catch {
        return null;
    }
}

export function transformXUrl(url: string): string | null {
    if (!isValidXUrl(url)) return null;
    const userName = getXUserName(url);
    return `https://www.x.com/${userName}`;
}
