/* eslint-disable @typescript-eslint/member-ordering */
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, omit } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';

import { GetKeywordRankingsForOneRestaurantV3ResponseDto } from '@malou-io/package-dto';
import { ApplicationLanguage, GeoSamplePlatform, isNotNil, RestaurantRankingFormatWithScore } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { KeywordsGenerationState, KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { GeneratorComponent } from ':modules/generator/generator.component';
import * as GenerationFormsActions from ':modules/generator/store/generation-forms.actions';
import { DEFAULT_GENERATION_FORM, GenerationForm } from ':modules/generator/store/generation-forms.interface';
import { selectCurrentForm } from ':modules/generator/store/generation-forms.selector';
import { getMostRecentRank } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import { FooterPopinService } from ':shared/components/footer-popin/footer-popin.service';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { LoaderPageComponent } from ':shared/components/loader-page/loader-page.component';
import { DialogVariant, MalouDialogComponent } from ':shared/components/malou-dialog/malou-dialog.component';
import { RestaurantsSelectionModalComponent } from ':shared/components/restaurants-selection-modal/restaurants-selection-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { buildGenerationForm } from ':shared/helpers/generation-form';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Brick, Keyword, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { KeywordsValidationModalComponent } from '../keywords-validation-modal/keywords-validation-modal.component';
import { KeywordsGeneratingFooterPopinComponent } from './keywords-generating-footer-popin/keywords-generating-footer-popin.component';
import { KeywordsGenerationFormAnswersComponent } from './keywords-generation-form-answers/keywords-generation-form-answers.component';
import { RankingPositionOutOf, RankingTableDataRow } from './keywords-list.component.interface';
import { SelectedKeywordsListComponent } from './selected-keywords-list/selected-keywords-list.component';

export enum SortBy {
    Keywords = 'keywords',
    Language = 'language',
    SearchVolume = 'searchVolume',
    Ranking = 'ranking',
}

export interface SortOption {
    value: SortBy;
    text: string;
}

export interface KeywordsWithCompetitorsTableDataRow extends RankingTableDataRow {
    currentPosition?: RankingPositionOutOf;
    ranking: RestaurantRankingFormatWithScore[];
}

@Component({
    selector: 'app-keywords-list',
    templateUrl: './keywords-list.component.html',
    styleUrls: ['./keywords-list.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        LazyLoadImageModule,
        IllustrationPathResolverPipe,
        LoaderPageComponent,
        KeywordsGenerationFormAnswersComponent,
        KeywordsPopularityComponent,
        SelectedKeywordsListComponent,
        SkeletonComponent,
    ],
    providers: [PluralTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsListComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    restaurant: Restaurant;
    readonly dataSource: WritableSignal<MatTableDataSource<KeywordsWithCompetitorsTableDataRow>> = signal(
        new MatTableDataSource<KeywordsWithCompetitorsTableDataRow>()
    );
    readonly hashRankingTableDataRowFn = (item: KeywordsWithCompetitorsTableDataRow): string =>
        `${item.keywordId}_${item.restaurantKeywordId}`;
    readonly selection: WritableSignal<SelectionModel<KeywordsWithCompetitorsTableDataRow>> = signal(
        new SelectionModel<KeywordsWithCompetitorsTableDataRow>(this.hashRankingTableDataRowFn)
    );
    readonly selectionIsEmpty$ = this.selection()
        .getCount$()
        .pipe(map((count) => count === 0));
    readonly selectionIsEmpty = toSignal(this.selectionIsEmpty$, { initialValue: true });

    readonly hasFetchedRankings = signal(false);

    readonly applicationLanguages: ApplicationLanguage[] = Object.values(ApplicationLanguage);

    readonly generationStartDate$ = this._initGenerationStartDate$();
    readonly generationStartDate = toSignal(this.generationStartDate$, { initialValue: null });
    readonly generationEstimatedTime$ = this._initGenerationEstimatedTime$();
    readonly generationEstimatedTime = toSignal(this.generationEstimatedTime$, { initialValue: Number.MAX_SAFE_INTEGER });
    readonly isGeneratingKeywords$ = this.generationStartDate$.pipe(map((date) => !!date));
    readonly isGeneratingKeywords = toSignal(this.isGeneratingKeywords$, { initialValue: false });
    readonly wasLastResultSeen$ = this._initWasLastResultSeen$();
    readonly generationLanguages$ = this._initGenerationLanguages$();
    readonly generationLanguages = toSignal(this.generationLanguages$, { initialValue: [] });

    readonly hasSelectedKeywords = computed(() => this.dataSource().data.length > 0);

    readonly generatorFormAnswers$ = this._store.select(selectCurrentForm).pipe(
        filter(Boolean),
        map((answers) => omit(answers, 'restaurantId', 'postalCode', 'apiLocationId')),
        map((answers) => {
            const keys = Object.keys(answers);
            for (const key of keys) {
                if (Array.isArray(answers[key])) {
                    answers[key] = answers[key].filter((value: Brick) => !!value?.getDisplayedValue());
                }
            }
            return answers;
        }),
        takeUntilDestroyed(this._destroyRef)
    ) as Observable<GenerationForm>;
    readonly generatorFormAnswers: Signal<GenerationForm> = toSignal(this.generatorFormAnswers$, {
        initialValue: DEFAULT_GENERATION_FORM,
    });
    readonly hasAnsweredToForm = computed(() =>
        Object.values(this.generatorFormAnswers()).some((value) => Array.isArray(value) && value.length > 0)
    );

    readonly keywords = signal<Keyword[]>([]);

    constructor(
        private readonly _keywordsService: KeywordsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _router: Router,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _destroyRef: DestroyRef,
        private readonly _footerPopinService: FooterPopinService
    ) {
        if (this._restaurantsService.currentRestaurant.isBrandBusiness()) {
            this._router.navigate(['/404']);
        }
    }

    ngOnInit(): void {
        this.wasLastResultSeen$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((wasLastResultSeen) => {
            if (!wasLastResultSeen) {
                this.openValidationModal(this.generationLanguages());
            }
        });

        window.scrollTo(0, 0);
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(Boolean),
                tap(() => {
                    this._initGeneratorForm();
                }),
                filter((restaurant) => !restaurant.isBrandBusiness()),
                switchMap(() => {
                    this.selection().unselectAll();
                    this.hasFetchedRankings.set(false);
                    const startDate = DateTime.now().minus({ month: 1 }).toJSDate();
                    const endDate = DateTime.now().toJSDate();
                    return forkJoin([
                        this._keywordsService.getKeywordsByRestaurantId(this.restaurant._id).pipe(map((res) => res.data)),
                        this._keywordsService.handleGetKeywordRankingsForOneRestaurantV3({
                            restaurantId: this.restaurant._id,
                            startDate,
                            endDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                        }),
                    ]);
                }),
                tap(([keywords, keywordsStats]) => {
                    this.keywords.set(keywords);
                    this.dataSource.update((currentDataSource) => {
                        currentDataSource.data = this._mapToKeywordsWithCompetitorsDataRow(keywords, keywordsStats);
                        return cloneDeep(currentDataSource);
                    });
                }),
                catchError((error) => {
                    console.warn(error);
                    if (error?.error?.message?.match(/Place ID is no longer valid/) || error?.error?.errorData === 'missing_lat_or_lng') {
                        console.warn('Il manque la latitude ou la longitude');
                        this._toastService.openErrorToast(this._translate.instant('keywords.missing_lat_or_lng'));
                        return of([]);
                    } else {
                        return throwError(() => error);
                    }
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: () => {
                    this.hasFetchedRankings.set(true);
                },
                error: (err) => {
                    this.hasFetchedRankings.set(true);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    openValidationModal(langs?: ApplicationLanguage[]): void {
        const languages = langs ?? Array.from(new Set(this.keywords().map((kw) => kw.language)));
        this._customDialogService
            .open(KeywordsValidationModalComponent, {
                width: '100%',
                panelClass: 'malou-dialog-panel--full',
                data: { languages },
            })
            .afterClosed()
            .subscribe({
                next: (res) => {
                    if (res) {
                        this._restaurantsService.reloadSelectedRestaurant();
                        this._toastService.openSuccessToast(this._translate.instant('keywords.validation_success'));
                    }
                },
            });
    }

    openGeneratorFormModal(): void {
        this._customDialogService
            .open(GeneratorComponent, {
                panelClass: 'malou-dialog-panel',
                height: 'unset',
            })
            .afterClosed()
            .subscribe((startedGeneration) => {
                if (startedGeneration) {
                    this._footerPopinService.open(KeywordsGeneratingFooterPopinComponent, {});
                    this._restaurantsService.reloadSelectedRestaurant();
                }
            });
    }

    openDuplicateKeywordsModal(): void {
        this._customDialogService
            .open(RestaurantsSelectionModalComponent, {
                data: {
                    skipOwnRestaurant: true,
                    withoutBrandBusiness: true,
                },
            })
            .afterClosed()
            .pipe(
                map((res: { ids: string[] }) => res?.ids),
                filter(Boolean),
                switchMap((selectedRestaurantIds: string[]) =>
                    concat(
                        ...selectedRestaurantIds.map((restaurantId) =>
                            this._keywordsService
                                .getKeywordsCountForRestaurant(restaurantId)
                                .pipe(map((res) => ({ id: restaurantId, selectedKeywordCount: res.data })))
                        )
                    )
                ),
                toArray()
            )
            .subscribe({
                next: (selectedRestaurantIdsAndKeywordCount: { id: string; selectedKeywordCount: number }[]) => {
                    if (selectedRestaurantIdsAndKeywordCount.length > 0) {
                        const doesSomeRestaurantHaveKeywords = selectedRestaurantIdsAndKeywordCount.some(
                            (selectedRestaurantIdAndKeywordCount) => selectedRestaurantIdAndKeywordCount.selectedKeywordCount > 0
                        );
                        const selectedRestaurantIds = selectedRestaurantIdsAndKeywordCount.map((res) => res.id);
                        if (!doesSomeRestaurantHaveKeywords) {
                            this._duplicateKeywords(selectedRestaurantIds);
                            return;
                        }
                        this._openDuplicateKeywordsWarningModal()
                            .afterClosed()
                            .pipe(filter((shouldDuplicate) => shouldDuplicate))
                            .subscribe({
                                next: () => {
                                    this._duplicateKeywords(selectedRestaurantIds);
                                },
                            });
                    }
                },
            });
    }

    private _mapToKeywordsWithCompetitorsDataRow(
        keywords: Keyword[],
        keywordsStats: GetKeywordRankingsForOneRestaurantV3ResponseDto
    ): KeywordsWithCompetitorsTableDataRow[] {
        return keywords
            .filter((k) => k.selected)
            .map((keyword): KeywordsWithCompetitorsTableDataRow => {
                const stats = keywordsStats.keywords.find((k) => k.keywordId === keyword.keywordId);
                const mostRecentRank = stats ? getMostRecentRank(stats) : undefined;
                return {
                    keywordId: keyword.keywordId,
                    restaurantKeywordId: keyword.restaurantKeywordId,
                    language: keyword.language,
                    keyword: keyword.text,
                    volumeFromAPI: keyword.volume,
                    volume: keyword.getNotNullVolume(),
                    currentPosition: mostRecentRank?.position ?? undefined,
                    ranking:
                        stats?.localCompetitorsPodium.map(
                            (c) => new RestaurantRankingFormatWithScore(c.score, c.name, c.placeId, c.address, c.address)
                        ) ?? [],
                    lastRefresh: keyword.lastRefresh,
                    isWaiting: false,
                    shouldRefetchVolume: keyword.shouldRefetchVolume(),
                    popularity: keyword.getPopularity(keywords),
                };
            });
    }

    private _initGeneratorForm(): void {
        this._restaurantsService.restaurantSelected$.subscribe({
            next: (restaurant) => {
                if (!restaurant) {
                    return;
                }
                this.restaurant = restaurant;
                const restaurantBricks = this.restaurant.bricks.map((b) => new Brick(b, this._translate.currentLang));
                this._store.dispatch({
                    type: GenerationFormsActions.editGenerationForm.type,
                    generationForm: buildGenerationForm(this.restaurant, restaurantBricks),
                });
                this._store.dispatch(GenerationFormsActions.selectForm({ formId: this.restaurant._id }));
            },
        });
    }

    private _openDuplicateKeywordsWarningModal(): MatDialogRef<MalouDialogComponent, any> {
        return this._dialogService.open({
            variant: DialogVariant.ALERT,
            title: this._translate.instant('keywords.warning_overwrite_keywords'),
            message: this._translate.instant('keywords.warning_overwrite_keywords_message'),
            primaryButton: {
                label: this._translate.instant('keywords.continue_and_replace'),
                action: () => true,
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
        });
    }

    private _duplicateKeywords(selectedRestaurantIds: string[]): void {
        const selectedKeywordIds = this.selection()
            .getSelection()
            .map((kw) => kw.keywordId);
        const selectedKeywords = this.keywords().filter((kw) => selectedKeywordIds.includes(kw.keywordId));
        this._keywordsService.duplicateKeywordsForRestaurants(this.restaurant._id, selectedKeywords, selectedRestaurantIds).subscribe({
            next: () => {
                this.selection().unselectAll();
                this._toastService.openSuccessToast(
                    this._pluralTranslatePipe.transform('keywords.duplicate_success', selectedKeywords.length)
                );
            },
            error: (err) => {
                console.warn(err);
                this._toastService.openErrorToast(this._translate.instant('keywords.duplicate_error'));
            },
        });
    }

    private _initGenerationStartDate$(): Observable<Date | null> {
        return combineLatest([this._keywordsService.keywordsGenerationState$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([_, restaurant]) => isNotNil(restaurant)),
            map(
                ([generationCurrentState, restaurant]: [
                    {
                        [restaurantId: string]: KeywordsGenerationState;
                    },
                    Restaurant,
                ]) => generationCurrentState[restaurant._id]?.generationStartDate ?? null
            )
        );
    }

    private _initGenerationEstimatedTime$(): Observable<number> {
        return combineLatest([this._keywordsService.keywordsGenerationState$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([_, restaurant]) => isNotNil(restaurant)),
            map(
                ([generationCurrentState, restaurant]: [
                    {
                        [restaurantId: string]: KeywordsGenerationState;
                    },
                    Restaurant,
                ]) => generationCurrentState[restaurant._id]?.generationEstimatedTime ?? Number.MAX_SAFE_INTEGER
            )
        );
    }

    private _initWasLastResultSeen$(): Observable<boolean> {
        return combineLatest([this._keywordsService.keywordsGenerationState$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(
                ([generationCurrentState, restaurant]) =>
                    isNotNil(restaurant) && Object.keys(generationCurrentState).includes(restaurant._id)
            ),
            map(
                ([generationCurrentState, restaurant]: [
                    {
                        [restaurantId: string]: KeywordsGenerationState;
                    },
                    Restaurant,
                ]) => generationCurrentState[restaurant._id].wasLastResultSeen
            )
        );
    }

    private _initGenerationLanguages$(): Observable<ApplicationLanguage[]> {
        return combineLatest([this._keywordsService.keywordsGenerationState$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([_, restaurant]) => isNotNil(restaurant)),
            map(
                ([generationCurrentState, restaurant]: [
                    {
                        [restaurantId: string]: KeywordsGenerationState;
                    },
                    Restaurant,
                ]) => generationCurrentState[restaurant._id]?.languages?.filter((value) => this.applicationLanguages.includes(value)) ?? []
            )
        );
    }
}
