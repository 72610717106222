import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    input,
    output,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import Cropper from 'cropperjs';
import { debounceTime, Subject } from 'rxjs';

import { AspectRatio } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/upload-and-edit-medias/components/edit-media-modal/edit-media-modal.component';

export interface TransformData {
    left: number;
    top: number;
    width: number;
    height: number;
    rotate: number;
}

interface Size {
    width: number;
    height: number;
}

interface Area {
    left: number;
    top: number;
    width: number;
    height: number;
}

/**
 * https://github.com/fengyuanchen/cropperjs/blob/main/docs/images/layers.jpg
 * Lexicon :
 *   - size : check the interface Size
 *   - area : check the interface Area
 *   - Container : size in pixels of the parent container.
 *   - Canvas : area of the image (if not rotated it's size equal to the image, if rotated it's the bounding box of the image). origin : container
 *   - Image : size in pixels of the image (+ some other information)
 *   - CropBox : area in pixels of the crop box. origin : container
 *   - natural : when the value is relative to the real image size
 */
@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrl: './image-editor.component.scss',
    standalone: true,
    imports: [NgOptimizedImage],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditorComponent implements AfterViewInit {
    readonly url = input.required<string>();
    readonly initialTransformData = input<TransformData | undefined>(); // only used when url change
    readonly rotation = input<number | undefined>(undefined);
    readonly cropBoxAspectRatio = input<AspectRatio | undefined>(undefined);
    readonly transformDataChange = output<TransformData>();
    readonly cropBoxAspectRatioComputed = output<AspectRatio>();

    readonly imageElementRef = viewChild.required<ElementRef<HTMLImageElement>>('image');

    private _cropper: Cropper | null = null;
    private _cropperDataSubject = new Subject<Cropper.Data>();
    isCropperInitializing = signal(true);
    private _isFirstUrlChange = true;

    constructor() {
        this._emitTransformDataOnChange();
        this._hardReloadCropperOnUrlChange();
        this._hotReloadCropperOnRotationChange();
        this._hotReloadCropperOnAspectRatioChange();
    }

    ngAfterViewInit(): void {
        const options: Cropper.Options<HTMLImageElement> = {
            viewMode: 1,
            dragMode: 'move',
            autoCrop: false,
            background: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            ready: (event) => {
                this._updateCropperData(
                    event.currentTarget.cropper,
                    this.initialTransformData(),
                    undefined,
                    this.initialTransformData()?.rotate
                );
                this.isCropperInitializing.set(false);
            },
            // We prefer to listen to cropend + zoom events instead of crop event because crop event is triggered too many times
            cropend: () => {
                // This condition prevents this event to be processed if the cropper is initializing
                // We untrack this signal because the caller is an effect
                if (untracked(() => this.isCropperInitializing())) {
                    return;
                }
                this._cropperDataSubject.next(this._cropper!.getData());
            },
            zoom: () => {
                // This condition prevents this event to be processed if the cropper is initializing
                // We untrack this signal because the caller is an effect
                if (untracked(() => this.isCropperInitializing())) {
                    return;
                }
                this._cropperDataSubject.next(this._cropper!.getData());
            },
        };
        this._cropper = new Cropper(this.imageElementRef().nativeElement, options);
    }

    private _emitTransformDataOnChange(): void {
        this._cropperDataSubject.pipe(debounceTime(200), takeUntilDestroyed()).subscribe((data) =>
            this.transformDataChange.emit({
                left: data.x,
                top: data.y,
                width: data.width,
                height: data.height,
                rotate: data.rotate,
            })
        );
    }

    private _hardReloadCropperOnUrlChange(): void {
        effect(
            () => {
                const url = this.url();
                if (!this._cropper || this._isFirstUrlChange) {
                    this._isFirstUrlChange = false;
                    return;
                }
                this.isCropperInitializing.set(true);
                // This will emit the ready event when ready
                this._cropper.replace(url);
            },
            { allowSignalWrites: true }
        );
    }

    private _hotReloadCropperOnRotationChange(): void {
        effect(
            () => {
                const rotationInDeg = this.rotation();
                const isCropperInitializing = untracked(() => this.isCropperInitializing());
                if (!this._cropper || isCropperInitializing || rotationInDeg === undefined) {
                    return;
                }
                const cropBoxAspectRatio = untracked(() => this.cropBoxAspectRatio());
                this.isCropperInitializing.set(true);
                this._updateCropperData(this._cropper, undefined, cropBoxAspectRatio, rotationInDeg);
                this.isCropperInitializing.set(false);
            },
            { allowSignalWrites: true }
        );
    }

    private _hotReloadCropperOnAspectRatioChange(): void {
        effect(
            () => {
                const cropBoxAspectRatio = this.cropBoxAspectRatio();
                const isCropperInitializing = untracked(() => this.isCropperInitializing());
                if (!this._cropper || isCropperInitializing || cropBoxAspectRatio === undefined) {
                    return;
                }
                const rotationInDeg = untracked(() => this.rotation());
                this.isCropperInitializing.set(true);
                this._updateCropperData(this._cropper, undefined, cropBoxAspectRatio, rotationInDeg);
                this.isCropperInitializing.set(false);
            },
            { allowSignalWrites: true }
        );
    }

    private async _updateCropperData(
        cropper: Cropper,
        initialCropBoxArea: Area | undefined,
        cropBoxAspectRatio: AspectRatio | undefined,
        rotation: number | undefined
    ): Promise<void> {
        cropper.reset();

        // Base data
        const containerSize = cropper.getContainerData();
        const naturalImageSize: Size = {
            width: cropper.getImageData().naturalWidth,
            height: cropper.getImageData().naturalHeight,
        };

        // Compute the crop box
        const initialCropBoxAspectRatio = initialCropBoxArea ? initialCropBoxArea.width / initialCropBoxArea.height : undefined;
        const naturalImageSizeAspectRatio = naturalImageSize.width / naturalImageSize.height;
        const inputAspectRatio = cropBoxAspectRatio ?? initialCropBoxAspectRatio ?? naturalImageSizeAspectRatio;
        const cropBoxAspectRatioComputed = this._computeCloserAspectRatio(inputAspectRatio, [
            AspectRatio.SQUARE,
            AspectRatio.PORTRAIT,
            AspectRatio.LANDSCAPE,
        ]);
        // This component conveniently emit the crop box aspect ratio because it load and has access to the image
        // and we need the image to compute this
        this.cropBoxAspectRatioComputed.emit(cropBoxAspectRatioComputed);

        const cropBoxArea = this._getCropBoxArea(containerSize, cropBoxAspectRatioComputed);

        // Compute the zoom ratio to apply to the canvas
        let zoomRatio: number | undefined;
        if (initialCropBoxArea) {
            // If the image has already been cropped,
            // we compute the zoom ratio between the natural crop size and the crop box area
            zoomRatio = this._getZoomRatio(initialCropBoxArea, cropBoxArea);
        } else {
            // If the image has naver been cropped,
            // we compute the zoom ratio between the natural image size and the crop box area
            zoomRatio = this._getZoomRatio(naturalImageSize, cropBoxArea);
        }

        cropper.zoomTo(zoomRatio);

        cropper.rotateTo(rotation ?? 0);

        if (initialCropBoxArea) {
            const canvasArea = cropper.getCanvasData();
            const originX = initialCropBoxArea.left * (canvasArea.width / naturalImageSize.width);
            const originY = initialCropBoxArea.top * (canvasArea.height / naturalImageSize.height);
            const newCanvasArea = {
                left: cropBoxArea.left - originX - 1,
                top: cropBoxArea.top - originY,
            };
            cropper.setCanvasData(newCanvasArea);
        }

        // This line show the cropper with default position that we will override next line
        cropper.crop();
        // This need to be the last operation because of ViewMode: 1 that restrict the crop box depending on the canvas
        // So we first place the canvas and then add the crop box
        cropper.setCropBoxData(cropBoxArea);
        this._cropperDataSubject.next(cropper.getData());
    }

    /**
     * Basically, it compute an area inside the container with a specific aspect ratio and with the css behavior object-fit: contains.
     */
    private _getCropBoxArea(containerSize: Size, cropBoxAspectRatio: number): Area {
        const cropBoxArea = { left: 0, top: 0, width: 0, height: 0 };
        const containerAspectRatio = containerSize.width / containerSize.height;
        if (containerAspectRatio > cropBoxAspectRatio) {
            cropBoxArea.top = 0;
            cropBoxArea.height = containerSize.height;
            cropBoxArea.width = cropBoxArea.height * cropBoxAspectRatio;
            cropBoxArea.left = (containerSize.width - cropBoxArea.width) / 2;
        } else if (containerAspectRatio < cropBoxAspectRatio) {
            cropBoxArea.left = 0;
            cropBoxArea.width = containerSize.width;
            cropBoxArea.height = cropBoxArea.width / cropBoxAspectRatio;
            cropBoxArea.top = (containerSize.height - cropBoxArea.height) / 2;
        } else {
            cropBoxArea.left = 0;
            cropBoxArea.width = containerSize.width;
            cropBoxArea.top = 0;
            cropBoxArea.height = containerSize.height;
        }
        return cropBoxArea;
    }

    /**
     * This function compute the zoom to apply to the first param to ba able to entirely cover the area of the second param (like object-fit: cover)
     */
    private _getZoomRatio(naturalImageSize: Size, cropBoxArea: Area): number {
        const naturalImageAspectRatio = naturalImageSize.width / naturalImageSize.height;
        const cropBoxAreaAspectRatio = cropBoxArea.width / cropBoxArea.height;

        const sideToFit = naturalImageAspectRatio > cropBoxAreaAspectRatio ? 'height' : 'width';
        return cropBoxArea[sideToFit] / naturalImageSize[sideToFit];
    }

    private _computeCloserAspectRatio(inputAspectRatio: number, aspectRatios: AspectRatio[]): AspectRatio {
        return aspectRatios.reduce((acc, cur) => (Math.abs(inputAspectRatio - acc) < Math.abs(inputAspectRatio - cur) ? acc : cur));
    }
}
