import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
})
export class ConfirmModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    illustration = Illustration;

    constructor(
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dialogRef: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            userId: string;
            token: string;
        },
        public readonly _clipboard: Clipboard,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this._activatedRoute.queryParams.subscribe((params) => {
            const lang = params['lang'];
            if (lang) {
                this._translateService.use(lang);
            }
        });
    }

    goToResetPassword(): void {
        this._router.navigate(['auth/password/new/', this.data.userId, this.data.token], {
            queryParams: { lang: this._translateService.currentLang, firstTime: true },
        });
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }
}
