import { Action, createFeatureSelector, createReducer, createSelector, MemoizedSelector, on } from '@ngrx/store';

import { KeywordsScoreDto } from '@malou-io/package-dto';

import { editKeywordsScore } from './keywords-score.actions';
import { KeywordsScoreState } from './keywords-score.interface';

export const initialState: KeywordsScoreState = {}; // A mapping of hashed keywords score params to their respective score

const keywordsScoreReducer = createReducer(
    initialState,
    on(editKeywordsScore, (state, keyvalue) => {
        const { type: _, ...paramsToScoreMap } = keyvalue;
        return {
            ...state,
            ...paramsToScoreMap,
        };
    })
);

export const selectKeywordsScoreState = createFeatureSelector<KeywordsScoreState>('keywordsScore');

export const selectKeywordsScore = (
    key: string
): MemoizedSelector<object, KeywordsScoreDto, (s1: KeywordsScoreState) => KeywordsScoreDto> =>
    createSelector(selectKeywordsScoreState, (state: KeywordsScoreState) => state[key]);

export function reducer(state: KeywordsScoreState | undefined, action: Action): KeywordsScoreState {
    return keywordsScoreReducer(state, action);
}
