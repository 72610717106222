import { z } from 'zod';

import {
    averageRatingPropsValidator,
    BaseEmailProps,
    BaseReportEmailProps,
    ctaValidator,
    notAnsweredReviewsPropsValidator,
    ratingPropsValidator,
    restaurantHeaderPropsValidator,
    restaurantPlatformDataValidator,
    semanticAnalysisPropsValidator,
} from '../common';

/** */

export const weeklyReviewsPropsValidator = z.object({
    stats: z.object({
        rating: ratingPropsValidator,
        averageRating: averageRatingPropsValidator.optional(),
        notAnsweredReviews: notAnsweredReviewsPropsValidator.optional(),
        cta: ctaValidator,
        noReviewsCta: ctaValidator,
    }),
    restaurantCount: z.number(),
});

export type WeeklyReviewsProps = z.infer<typeof weeklyReviewsPropsValidator>;

/** */

export const weeklyRestaurantReviewsPropsValidator = z.object({
    platforms: z.array(restaurantPlatformDataValidator).optional(),
    restaurantHeader: restaurantHeaderPropsValidator.optional(),
    semanticAnalysis: semanticAnalysisPropsValidator.optional(),
    noReviewsCta: ctaValidator,
    platformsCta: ctaValidator,
});

export type WeeklyRestaurantReviewsProps = z.infer<typeof weeklyRestaurantReviewsPropsValidator>;

/** */

export const weeklyReviewsReportPropsValidator = z.object({
    reviewsStats: weeklyReviewsPropsValidator,
    restaurantsReviews: z.array(weeklyRestaurantReviewsPropsValidator).optional(),
});

export type WeeklyReviewsReportProps = z.infer<typeof weeklyReviewsReportPropsValidator> & BaseEmailProps & BaseReportEmailProps;

/** */

export const NoWeeklyReviewsReportPropsValidator = z.object({
    link: z.string(),
});

export type NoWeeklyReviewsReportProps = z.infer<typeof NoWeeklyReviewsReportPropsValidator> & BaseEmailProps & BaseReportEmailProps;

/** */
