import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResult } from ':shared/models';
import { CalendarEvent } from ':shared/models/calendar-event';

@Injectable({
    providedIn: 'root',
})
export class CalendarEventsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/calendar-events`;

    constructor(private readonly _http: HttpClient) {}

    createCalendarEvent(restaurantId: string, event: Partial<CalendarEvent>): Observable<ApiResult<CalendarEvent>> {
        return this._http.post<ApiResult<CalendarEvent>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, event);
    }

    deleteCalendarEvent(restaurantId: string, eventId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/${eventId}`);
    }

    getCalendarEventsBetweenDates(restaurantId: string, startDate: string, endDate: string): Observable<ApiResult<CalendarEvent[]>> {
        return this._http
            .get<ApiResult<CalendarEvent[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/start/${startDate}/end/${endDate}`)
            .pipe(
                map((res) => {
                    res.data = res.data.map((evt) => new CalendarEvent(evt));
                    return res;
                })
            );
    }
}
