<ng-template #hoveredposts>
    @if (hoveredPosts.length && showHover) {
        <div
            class="malou-card !absolute !z-20 w-[290px] cursor-auto"
            [id]="'hoveredPostsDiv-' + post.socialId"
            [ngStyle]="{ display: showHover ? 'flex' : 'none', top: '50px', left: hoveredModalLeftOffsetInPx }">
            <div class="mb-4 flex">
                <div class="flex items-center">
                    <div class="py-1">
                        <app-platform-logo imgClasses="h-10 w-10" [logo]="post.key"></app-platform-logo>
                    </div>
                    <p class="malou-text-12--semibold malou-color-text-2 ml-3">{{ '@' }}{{ post.username }}</p>
                </div>
            </div>
            <div class="hovered-grid mb-4 grid gap-1">
                @for (post of hoveredPosts; track trackByUrlFn($index, post)) {
                    <div class="post-image">
                        <img
                            class="h-20 w-20 rounded-[4px] object-cover"
                            alt="Image du post"
                            [defaultImage]="'default-picture-grey' | imagePathResolver"
                            [lazyLoad]="post.url"
                            [errorImage]="'default-picture-grey' | imagePathResolver" />
                    </div>
                }
            </div>
            <div class="flex justify-between">
                <div class="malou-background-light insights-card-border mr-1 flex h-[75px] w-1/2 flex-col justify-center rounded-[10px]">
                    <p class="malou-text-14--semibold malou-color-text-1 text-center">
                        {{ post | applySelfPure: 'getNbFollowers' | shortNumber }}
                    </p>
                    <p class="malou-text-10--regular malou-color-text-2 text-center">Followers</p>
                </div>
                <div class="malou-background-light insights-card-border ml-1 flex h-[75px] w-1/2 flex-col justify-center rounded-[10px]">
                    <p class="malou-text-14--semibold malou-color-text-1 text-center">
                        {{ post.accountEngagement | percent: '1.1-1' : 'fr' }}
                    </p>
                    <p class="malou-text-10--regular malou-color-text-2 text-center">{{ 'post.commitment' | translate }}</p>
                </div>
            </div>
        </div>
    }
</ng-template>

<div class="malou-card !m-0">
    <div class="mb-5 flex justify-between">
        <div class="flex">
            <div class="py-1">
                <app-platform-logo imgClasses="h-10 w-10" [logo]="post.key" [withLazyLoading]="true"></app-platform-logo>
            </div>
            <div
                class="relative ml-3 flex flex-col justify-around"
                [id]="'hoverPostsDiv-' + post.socialId"
                [class.cursor-pointer]="hoveredPosts.length"
                (click)="hovered($event)">
                <ng-container *ngTemplateOutlet="hoveredposts"></ng-container>
                <p class="malou-text-12--semibold malou-color-text-2">{{ '@' }}{{ post.username }}</p>
                <p class="malou-text-10--regular malou-color-text-2">{{ post | applySelfPure: 'displayDate' }}</p>
            </div>
        </div>
        @if (displaySaveIcon) {
            <button
                class="malou-btn-icon--secondary btn-xl"
                mat-icon-button
                [class.tracking_inspirations_unsave_button]="isSaved"
                [class.tracking_inspirations_save_button]="!isSaved"
                [ngClass]="{ '!border-malou-color-purple--light !bg-malou-color-background-dark': isSaved }"
                [matTooltip]="(!isSaved ? 'post.saved' : 'post.unsaved') | translate"
                (click)="savePost(post, hoveredPosts)">
                <mat-icon [svgIcon]="SvgIcon.MEDAL"></mat-icon>
            </button>
        }
    </div>
    <div class="post-picture mb-3.5 flex cursor-pointer" (click)="goToLink(post.permalink)">
        <app-social-post-media
            [postType]="post.postType"
            [workingMedia]="post.url || post.backupUrl"
            [backupMediaUrl]="post.backupUrl"
            [firstAttachmentType]="post.carouselUrls && post.carouselUrls.length > 0 ? post.carouselUrls[0].type : undefined"
            [thumbnailUrl]="post.thumbnail"
            [customIconStyle]="{ top: '0px', right: '10px' }"
            [customMediaStyle]="{ height: '220px', 'border-radius': '9px' }"></app-social-post-media>
    </div>
    <div class="mb-3 flex">
        <p
            class="malou-text-10--regular malou-color-text-2 h-[50px]"
            [matTooltip]="post.caption && post.caption.length > MAX_CAPTION_DISPLAY_LENGTH ? post.caption : ''">
            {{ post.caption | shortText: MAX_CAPTION_DISPLAY_LENGTH }}
        </p>
    </div>
    <div class="mb-3 flex">
        <button class="malou-btn-raised--secondary btn-normal !h-11 grow" mat-raised-button (click)="copy(post.caption)">
            {{ 'post.copy_caption' | translate }}
        </button>
    </div>
    <div class="mb-[7px] flex justify-between">
        <div class="malou-background-light insights-card-border mr-1 flex h-[75px] w-1/2 flex-col justify-center rounded-[10px]">
            <p class="malou-text-14--semibold malou-color-text-1 text-center">
                {{ (post | applySelfPure: 'getLikes') || 0 | shortNumber }}
            </p>
            <p class="malou-text-10--regular malou-color-text-2 text-center">{{ 'post.like' | translate }}</p>
        </div>
        <div class="malou-background-light insights-card-border ml-1 flex h-[75px] w-1/2 flex-col justify-center rounded-[10px]">
            <p class="malou-text-14--semibold malou-color-text-1 text-center">
                {{ (post | applySelfPure: 'getComments') || 0 | shortNumber }}
            </p>
            <p class="malou-text-10--regular malou-color-text-2 text-center">{{ 'post.comments' | translate }}</p>
        </div>
    </div>
    <div class="malou-background-light insights-card-border flex h-[75px] grow flex-col justify-center rounded-[10px]">
        <p class="malou-text-14--semibold malou-color-text-1 text-center">
            {{ post | applySelfPure: 'getEngagementRate' | shortNumber: { content: '%' } }}
        </p>
        <p class="malou-text-10--regular malou-color-text-2 text-center">{{ 'post.commitment' | translate }}</p>
    </div>
</div>
