import { DOCUMENT } from '@angular/common';
import { ElementRef, EventEmitter, inject, Injectable } from '@angular/core';

import { BetterDragEventsDirective } from ':shared/directives/better-drag-events.directive';

export type DropHandler = (files: File) => any;

/**
 * This service expose drag and drop events from the body element.
 *
 * Currently exposing :
 *    - dragEnter event (from BetterDragEventsDirective)
 *    - dragLeave event (from BetterDragEventsDirective)
 *    - dragOver event
 *    - drop event
 */
@Injectable({ providedIn: 'root' })
export class BodyDragAndDropEventsService {
    private readonly _document = inject(DOCUMENT);

    private readonly _bodyElement = this._document.body;

    readonly dragEnter = new EventEmitter<DragEvent>();
    readonly dragLeave = new EventEmitter<DragEvent>();
    readonly dragOver = new EventEmitter<DragEvent>();
    readonly drop = new EventEmitter<DragEvent>();

    constructor() {
        this._bodyElement.addEventListener('drop', (event) => this.drop.emit(event));
        this._bodyElement.addEventListener('dragover', (event) => this.dragOver.emit(event));

        const directive = new BetterDragEventsDirective(new ElementRef(this._bodyElement));
        directive.betterDragEnter.subscribe((event) => this.dragEnter.emit(event));
        directive.betterDragLeave.subscribe((event) => this.dragLeave.emit(event));
    }

    /**
     * @deprecated Kept for compatibility with posts v1
     */
    getNativeElement(): HTMLElement {
        return this._bodyElement;
    }
}
