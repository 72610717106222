import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

import { Nfc } from ':shared/models';

@Injectable({ providedIn: 'root' })
export class NfcNameToCsvNameMapper {
    constructor(private readonly _translateService: TranslateService) {}

    public mapNfcName(nfc: Nfc): string {
        const { chipName, name } = nfc;
        if (name) {
            return this._preserveNumberStringFormat(name);
        }
        if (chipName) {
            return this._preserveNumberStringFormat(chipName);
        }
        return this._translateService.instant('admin.nfcs.type.sticker');
    }

    private _preserveNumberStringFormat(text: string): string {
        if (text && _.toNumber(text)) {
            return `'${text}'`;
        }
        return text;
    }
}
