import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { round, sum } from 'lodash';
import { of } from 'rxjs';

import { RoiAdditionalCustomersDto } from '@malou-io/package-dto';
import { PlatformCategory, PlatformKey } from '@malou-io/package-utils';

import { selectCurrentPlatformKeys } from ':modules/platforms/store/platforms.reducer';
import { RoiAdditionalCustomersDetails, RoiAdditionalCustomersRow } from ':shared/models/roi-additional-customers.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { EstimatedCustomerPanelComponent } from './estimated-customer-panel/estimated-customer-panel.component';

interface AdditionalCustomersPanel {
    panelTitle: string;
    explanation: string;
    currentCustomerRange: number[];
    customerItems: RoiAdditionalCustomersRow[];
    previousCustomersEvolution: number;
}

@Component({
    selector: 'app-estimated-customers-details-modal',
    templateUrl: './estimated-customers-details-modal.component.html',
    styleUrls: ['./estimated-customers-details-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, EstimatedCustomerPanelComponent, TranslateModule],
})
export class EstimatedCustomersDetailsModalComponent implements OnInit {
    private readonly _dialogRef = inject(MatDialogRef<EstimatedCustomersDetailsModalComponent>);
    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly data = inject<{
        additionalCustomers: RoiAdditionalCustomersDto;
        isAggregatedView?: boolean;
    }>(MAT_DIALOG_DATA);

    readonly estimatedCustomersDetailsTabs: WritableSignal<AdditionalCustomersPanel[]> = signal([]);

    ngOnInit(): void {
        this._initCustomersDetails();
    }

    close(): void {
        this._dialogRef.close();
    }

    private _initCustomersDetails(): void {
        const platformsKeys$ = this.data.isAggregatedView
            ? of([PlatformKey.GMB, PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM])
            : this._store.select(selectCurrentPlatformKeys);
        platformsKeys$.subscribe({
            next: (platformKeys) => {
                const customersDetails = new RoiAdditionalCustomersDetails(
                    this.data.additionalCustomers ?? {},
                    platformKeys
                ).buildRoiAdditionalCustomersDetails(this._translateService);
                const seoDetails = customersDetails.filter(({ type }) => type === PlatformCategory.SEO);
                const seoMinTotal = sum(seoDetails.map(({ minCustomers }) => minCustomers));
                const seoMaxTotal = sum(seoDetails.map(({ maxCustomers }) => maxCustomers));

                const socialDetails = customersDetails.filter(({ type }) => type === PlatformCategory.SOCIAL);
                const socialMinTotal = sum(socialDetails.map(({ minCustomers }) => minCustomers));
                const socialMaxTotal = sum(socialDetails.map(({ maxCustomers }) => maxCustomers));

                const panels: AdditionalCustomersPanel[] = [];
                if (platformKeys.includes(PlatformKey.GMB)) {
                    panels.push({
                        panelTitle: this._translateService.instant('roi.estimated_customers.seo'),
                        explanation: this._translateService.instant('roi.estimated_customers.gmb_explanations'),
                        currentCustomerRange: [seoMinTotal, seoMaxTotal],
                        customerItems: seoDetails,
                        previousCustomersEvolution: round(this.data.additionalCustomers.previousPeriodEvolutionSeoCustomers ?? 0, 0),
                    });
                }
                if (platformKeys.includes(PlatformKey.FACEBOOK) || platformKeys.includes(PlatformKey.INSTAGRAM)) {
                    panels.push({
                        panelTitle: this._translateService.instant('roi.estimated_customers.social'),
                        explanation: this._translateService.instant('roi.estimated_customers.social_explanation'),
                        currentCustomerRange: [socialMinTotal, socialMaxTotal],
                        customerItems: socialDetails,
                        previousCustomersEvolution: round(this.data.additionalCustomers.previousPeriodEvolutionSocialCustomers ?? 0, 0),
                    });
                }
                this.estimatedCustomersDetailsTabs.set(panels);
            },
        });
    }
}
