import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

type FrenchGenre = 'masculine' | 'feminine';

@Pipe({
    name: 'toOrdinal',
    pure: true,
    standalone: true,
})
export class ToOrdinalPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(value: number, frenchGenre: FrenchGenre): string {
        const lang = this._translate.currentLang;

        switch (lang) {
            case ApplicationLanguage.EN:
                return this._getEnglishTranslation(value);
            case ApplicationLanguage.FR:
                return this._getFrenchTranslation(value, frenchGenre);
            default:
                return this._getDefaultTranslation(value);
        }
    }

    private _getEnglishTranslation(value: number): string {
        const roundedValue = Math.round(value).toString();
        const unit = roundedValue.at(-1);
        const tens = roundedValue.at(-2);
        const suffix = tens === '1' ? 'th' : unit === '1' ? 'st' : unit === '2' ? 'nd' : unit === '3' ? 'rd' : 'th';
        return value + suffix;
    }

    private _getFrenchTranslation(value: number, frenchGenre: FrenchGenre): string {
        const roundedValue = Math.round(value);
        switch (roundedValue) {
            case 1:
                return `${value}er${frenchGenre === 'feminine' ? 'e' : ''}`;
            default:
                return `${value}ème`;
        }
    }
    private _getDefaultTranslation(value: number): string {
        return `${value}°`;
    }
}
