import { SocialNetworkKey } from '../../../constants';
import { isFromDomain, isValidUrl, trimSlashes } from '../common';

export function isValidInstagramUrl(url: string): boolean {
    try {
        if (!isValidUrl(url)) {
            return false;
        }

        if (!isFromDomain({ url, domain: SocialNetworkKey.INSTAGRAM })) {
            return false;
        }

        // Try to extract instagram username
        const userName = getInstagramUserName(url);
        if (!userName) {
            return false;
        }

        return true;
    } catch {
        return false;
    }
}

export function getInstagramUserName(url: string): string | null {
    try {
        // Extract path / query params and hash
        const parsedUrl = new URL(url);
        const pathname = decodeURIComponent(trimSlashes(parsedUrl.pathname)); // Remove beginning and trailing slashes if they exist

        // Only alphanumeric characters, dots and underscores are allowed for instagram usernames
        const userNameRegex = /^[a-zA-ZÀ-ž0-9_\.]+$/;

        // /username
        return pathname && userNameRegex.test(pathname) ? encodeURIComponent(pathname) : null;
    } catch {
        return null;
    }
}

export function transformInstagramUrl(url: string): string | null {
    if (!isValidInstagramUrl(url)) return null;
    const userName = getInstagramUserName(url);
    return `https://www.instagram.com/${userName}`;
}
