import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { uniq } from 'lodash';

import * as FooterManagerActions from './footer-manager.actions';
import { FooterManagerState } from './footer-manager.interface';

export const initialState: FooterManagerState = {
    openedFootersOrder: [],
};

const footerManagerReducer = createReducer(
    initialState,
    on(FooterManagerActions.setFooterVisibility, (state, { footerType, isFooterVisible }) => ({
        ...state,
        openedFootersOrder: isFooterVisible
            ? uniq([...state.openedFootersOrder, footerType])
            : state.openedFootersOrder.filter((v) => v !== footerType),
    }))
);

export const selectFooterManagerState = createFeatureSelector<FooterManagerState>('footerManager');

export const selectOpenedFootersOrder = createSelector(selectFooterManagerState, (state: FooterManagerState) => state.openedFootersOrder);

export const selectOpenedFooterCount = createSelector(
    selectFooterManagerState,
    (state: FooterManagerState) => state.openedFootersOrder.length
);

export function reducer(state: FooterManagerState | undefined, action: Action): FooterManagerState {
    return footerManagerReducer(state, action);
}
