import { Pipe, PipeTransform } from '@angular/core';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'hasReply',
    standalone: true,
})
export class HasReplyPipe implements PipeTransform {
    transform(review: Review | PrivateReview): boolean {
        return review.hasReply();
    }
}
