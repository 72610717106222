import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reviewShortTextLength',
    standalone: true,
})
export class ReviewShortTextLengthPipe implements PipeTransform {
    transform(type: 'text' | 'comment' = 'text', { shouldDisplayFullText = false }): number {
        if (shouldDisplayFullText) {
            return 10_000;
        }
        return this._getThreeDotLength(type);
    }

    private _getThreeDotLength(type: 'text' | 'comment' = 'text'): number {
        const isText = type === 'text';
        return isText ? this._getThreeDotLengthForText() : this._getThreeDotLengthForComment();
    }

    private _getThreeDotLengthForText(): number {
        return 140;
    }

    private _getThreeDotLengthForComment(): number {
        return 110;
    }
}
