import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartColorBlue,
    malouChartColorBluePurple,
    malouChartColorLighterPink,
    malouChartColorLightPurple,
    malouChartColorPink,
    malouChartColorPurple,
} from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

export interface GmbActionsData {
    websiteClicks: ChartDataArray;
    phoneClicks: ChartDataArray;
    drivingClicks: ChartDataArray;
    bookingClicks: ChartDataArray;
    menuClicks: ChartDataArray;
    foodOrderClicks: ChartDataArray;
}

@Component({
    selector: 'app-gmb-actions-chart',
    templateUrl: './gmb-actions-chart.component.html',
    styleUrls: ['./gmb-actions-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [ShortNumberPipe],
})
export class GmbActionsChartComponent implements OnChanges {
    @Input() gmbActionsData: GmbActionsData;
    @Input() labels: Date[];
    @Input() viewBy: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() hiddenDatasetIndexesChange = new EventEmitter<number[]>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: Date[];
    chartOption: ChartOptions<BarChartType>;
    showChartAnnotationTooltip = false;
    private _hiddenDatasetIndexes: number[] = [];

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'gmbActionsData')) {
            this.chartDataSets = this._computeChartData(this.gmbActionsData);
            if (this.hiddenDatasetIndexes.length) {
                this.chartDataSets = this.chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes.includes(index));
            }
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'labels')) {
            this.chartLabels = this._computeChartLabels(this.labels);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'viewBy')) {
            this.chartOption = this._computeChartOptions();
        }
    }

    private _computeChartData(data: GmbActionsData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.visit_website'),
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.websiteClicks,
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.phone_call'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.phoneClicks,
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.itinerary_request'),
                borderColor: malouChartColorLightPurple,
                backgroundColor: malouChartColorLightPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.drivingClicks,
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.menu_click'),
                borderColor: malouChartColorLighterPink,
                backgroundColor: malouChartColorLighterPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.menuClicks,
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.booking_click'),
                borderColor: malouChartColorPurple,
                backgroundColor: malouChartColorPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.bookingClicks,
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_actions.food_order_click'),
                borderColor: malouChartColorBlue,
                backgroundColor: malouChartColorBlue,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.foodOrderClicks,
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(this.viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(this.viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[]): string {
        const title: string = item[0].label;
        if (this.viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.seo.gmb_actions.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }
}
