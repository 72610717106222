import { Directive, ElementRef, OnDestroy, output } from '@angular/core';

@Directive({
    selector: '[appBetterDragEvents]',
    standalone: true,
})
/**
 * Compared to html dragenter and dragleave events, these events only track when drag enter and leave the parent element where the directive is.
 */
export class BetterDragEventsDirective implements OnDestroy {
    readonly betterDragEnter = output<DragEvent>();
    readonly betterDragLeave = output<DragEvent>();

    constructor(private readonly _element: ElementRef<HTMLElement>) {
        this._element.nativeElement.addEventListener('dragenter', this._onDragEnter);
        this._element.nativeElement.addEventListener('dragleave', this._onDragLeave);
    }

    ngOnDestroy(): void {
        this._element.nativeElement.removeEventListener('dragenter', this._onDragEnter);
        this._element.nativeElement.removeEventListener('dragleave', this._onDragLeave);
    }

    private _onDragEnter = (event: DragEvent): void => {
        const from = event.relatedTarget instanceof Node ? event.relatedTarget : null;
        const to = event.target instanceof Node ? event.target : null;
        const isFromOutsideParent = !this._isContainedInParent(from);
        const isToParent = this._isContainedInParent(to);
        if (isFromOutsideParent && isToParent) {
            this.betterDragEnter.emit(event);
        }
    };

    private _onDragLeave = (event: DragEvent): void => {
        const from = event.target instanceof Node ? event.target : null;
        const to = event.relatedTarget instanceof Node ? event.relatedTarget : null;
        const isFromParent = this._isContainedInParent(from);
        const isToOutsideParent = !this._isContainedInParent(to);
        if (isFromParent && isToOutsideParent) {
            this.betterDragLeave.emit(event);
        }
    };

    /**
     * Note that the a node contains itself,
     * so it return true if this._element.nativeElement and htmlElement are the same
     */
    private _isContainedInParent(node: Node | null): boolean {
        if (!node) {
            return false;
        }
        return this._element.nativeElement.contains(node);
    }
}
