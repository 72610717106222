import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import {
    InformationUpdateErrorReason,
    InformationUpdatesStatus,
    InformationUpdateStateError,
    informationUpdateSupportedKeyMap,
    InformationUpdateSupportedPlatformKey,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { InformationUpdatesStateService } from ':modules/informations/informations-updates-state/information-updates-state.service';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Component({
    selector: 'app-information-update-detail-error',
    standalone: true,
    imports: [NgTemplateOutlet, TranslateModule, IncludesPipe, PlatformNamePipe, ApplyPurePipe],
    templateUrl: './information-update-detail-error.component.html',
    styleUrl: './information-update-detail-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateDetailErrorComponent {
    readonly InformationUpdatesStatus = InformationUpdatesStatus;
    readonly InformationUpdateErrorReason = InformationUpdateErrorReason;
    readonly informationUpdateSupportedKeyMap = informationUpdateSupportedKeyMap;
    readonly platformAccessStatus = input.required<InformationUpdatesStatus>();
    readonly platformKey = input.required<InformationUpdateSupportedPlatformKey>();
    readonly errors = input<InformationUpdateStateError[]>([]);
    readonly close = output<void>();

    readonly _router = inject(Router);
    readonly _currentRestaurant = inject(RestaurantsService).restaurantSelected$;
    readonly _informationUpdatesStateService = inject(InformationUpdatesStateService);

    goToPlatforms(): void {
        this.close.emit();
        this._router.navigate([`/restaurants/${this._currentRestaurant.value!._id}/settings/platforms/connection`]);
    }

    goToGmbProfile(): void {
        window.open('https://business.google.com/locations', '_blank');
    }

    getErrorMessage = (error: InformationUpdateStateError): string =>
        this._informationUpdatesStateService.displayInformationUpdatesStateError(error);
}
