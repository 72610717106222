<div class="malou-dialog">
    <div class="malou-dialog__body relative !flex items-center !p-8">
        <div class="absolute right-[25px] top-[25px] cursor-pointer" (click)="logout()">
            <div class="malou-text-12--semibold">
                {{ 'header.profile_menu.logout' | translate }}
            </div>
        </div>

        <img class="mr-20 h-96" alt="Chef illustration" [src]="'Chef' | illustrationPathResolver" />
        <ng-container [ngTemplateOutlet]="welcomeTextTemplate"></ng-container>
    </div>
</div>

<ng-template #welcomeTextTemplate>
    <div class="flex w-[40%] flex-col items-start justify-between gap-y-8">
        <div class="mt-4 flex flex-col font-passion-one text-malou-color-text-1">
            <span class="malou-text-50--bold">{{ 'restaurants_list.welcome_modal.welcome' | translate }}</span>
            <span class="malou-text-24--bold mt-[-15px]">{{ 'restaurants_list.welcome_modal.at_malou' | translate }}</span>
        </div>

        <span class="malou-text-14--regular text-malou-color-text-2">{{
            'restaurants_list.welcome_modal.ready_to_start' | translate
        }}</span>
        <span class="malou-text-14--regular text-malou-color-text-2">{{ 'restaurants_list.welcome_modal.follow_steps' | translate }}</span>
        <app-button
            buttonClasses="!h-12 mt-4"
            [text]="'restaurants_list.welcome_modal.add_first_business' | translate"
            (buttonClick)="close()"></app-button>
    </div>
</ng-template>
